import {
  GET_CITY_BY_STATE_ID,
  GET_CITY_BY_STATE_ID_SUCCESS,
  GET_ALL_STATE,
  GET_ALL_STATE_SUCCESS,
  STORE_MEDIA_FILE,
  STORE_MEDIA_FILE_SUCCESS,
  TOAST_STATUS,
  TOAST_STATUS_SUCCESS,
  UPDATE_PROFILE,UPDATE_PROFILE_SUCCESS,
  AUTOFILL_CITY_STATE,
  AUTOFILL_CITY_STATE_SUCCESS,
  ADD_DEVICE_ID,
  ADD_DEVICE_ID_SUCCESS,
  GET_DATA_FROM_ID_SUCCESS,
  GET_DATA_FROM_ID,
  DELETE_DEVICE_ID,
  DELETE_DEVICE_ID_SUCCESS,
  GET_SIGNED_URL,
  GET_SIGNED_URL_SUCCESS,
  FLEET_EDIT_STATUS,
  FLEET_EDIT_STATUS_SUCCESS,
  DRIVER_EDIT_STATUS,
  DRIVER_EDIT_STATUS_SUCCESS,
  GET_PINCODE,
GET_PINCODE_SUCCESS
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  allState: [],
  cityByStateId: [],
  uploadType: "",
  //toastLoading: false
  toastObject: {
    toastStatus: false,
    message: "",
    status: true,
    link : "",
    invoice : false,
    pincode : {}
  },
  deviceToken:""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // case TOAST_STATUS: {
    //   return {
    //     ...state,
    //     toastLoading: !toastLoading,
    //   };
    // }
    case DELETE_DEVICE_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case DELETE_DEVICE_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_DATA_FROM_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_DATA_FROM_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case ADD_DEVICE_ID: {
      return {
        ...state,
        loader: true,
        deviceToken:action?.payload?.deviceId
      };
    }
    case ADD_DEVICE_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_CITY_BY_STATE_ID: {
      return {
        ...state,
        loader: true,
        cityByStateId: [],
      };
    }
    case GET_CITY_BY_STATE_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
        cityByStateId: action.payload,
      };
    }
    case GET_ALL_STATE: {
      return {
        ...state,
        loader: true,
        allState: [],
      };
    }
    case GET_ALL_STATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        allState: action.payload,
      };
    }

    case STORE_MEDIA_FILE: {
      return {
        ...state,
        loader: true,
        uploadType: action.payload.uploadType,
      };
    }
    case STORE_MEDIA_FILE_SUCCESS: {
      return {
        ...state,
        loader: false,
        uploadType: "",
      };
    }

    case TOAST_STATUS: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case TOAST_STATUS_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
        // uploadType: "",

        toastObject: {
          toastStatus: action.payload.toastStatus,
          message: action.payload.message,
          status: action.payload.status,
          link : action.payload?.link ? action.payload?.link : "",
          invoice : action.payload?.invoice ? action.payload?.invoice : false,
          callBack : action.payload?.callBack ? action.payload?.callBack : ()=>{},
          rediractionLink : action.payload?.rediractionLink ? action.payload?.rediractionLink : '',
          mobileNumber : action.payload?.mobileNumber ? action.payload?.mobileNumber : '',
          emailId : action.payload?.emailId ? action.payload?.emailId : '',
        },
      };
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case AUTOFILL_CITY_STATE: {
      return {
        ...state,
        loader: true,
      };
    }
    case AUTOFILL_CITY_STATE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_SIGNED_URL: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_SIGNED_URL_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case FLEET_EDIT_STATUS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FLEET_EDIT_STATUS_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case DRIVER_EDIT_STATUS: {
      return {
        ...state,
        loader: true,
      };
    }
    case DRIVER_EDIT_STATUS_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_PINCODE: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_PINCODE_SUCCESS: {
      return {
        ...state,
        loader: false,
        pincode : action.payload
      };
    }
    default: {
      return state;
    }
  }
};
