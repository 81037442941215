import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { DatePickerField } from "../../core/inputs/input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { Formik, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import moment from "moment";
import { addVehicle } from "../../../services/Store/Actions/manageVehicleAction";
import {
  sendAnalitics,
  storeMedia,
} from "../../../services/Store/Actions/appActions";
import MaskInput from "react-maskinput";
import { ClipLoader } from "react-spinners";

const vehCatData = [
  { name: "Two Wheeler", value: 1, label: "VC1" },
  { name: "Three Wheeler - Passenger", value: 2, label: "VC2" },
  { name: "Three Wheeler - Freight", value: 3, label: "VC3" },
  { name: "Car / Jeep / Van", value: 4, label: "VC4" },
  { name: "Light Commercial Vehicle - 2 Axle", value: 5, label: "VC5" },
  { name: "Light Commercial Vehicle - 3 Axle", value: 6, label: "VC6" },
  { name: "Bus 2 Axle", value: 7, label: "VC7" },
  { name: "Bus 3 Axle", value: 8, label: "VC8" },
  { name: "Mini Bus", value: 9, label: "VC9" },
  { name: "Truck 2 Axle", value: 10, label: "VC10" },
  { name: "Truck 3 Axle", value: 11, label: "VC11" },
  { name: "Truck 4 Axle", value: 12, label: "VC12" },
  { name: "Truck 5 Axle", value: 13, label: "VC13" },
  { name: "Truck 6 Axle", value: 14, label: "VC14" },
  { name: "Truck Multi Axle ( 7 and above )", value: 15, label: "VC15" },
  { name: "Earth moving machinery", value: 16, label: "VC16" },
  { name: "Heavy Construction machinery", value: 17, label: "VC17" },
  { name: "Tractor", value: 18, label: "VC18" },
  { name: "Tractor with trailor", value: 19, label: "VC19" },
  {
    name: "Tata Ace or similar mini light commercial vehicle",
    value: 20,
    label: "VC20",
  },
];

const validationSchema = Yup.object().shape({
  vehicleNumber: Yup.string().required("Required"),
  vehicleLinkedFastagID: Yup.string()
    .required("Required")
    .matches(
      /^([0-9]{6})-([0-9]{3})-([0-9]{7})$/,
      "Please enter correct barcode of Fastag ID"
    ),
  vehicleCategory: Yup.string().required("Required"),
  vehicleEngineNumber: Yup.string().required("Required"),
  vehicleRegistrationDate: Yup.date().required("Required"),
  // vehicleRcPhoto:Yup.required("Required")
  // fullName: Yup.string().required('Required'),
  // businessName: Yup.string().required('Required'),
  // noOfTrucks: Yup.number().required('Required'),
  // email: Yup.string()
  //   .required('Required')
  //   .matches(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/, 'Please enter valid Email'),
  // password: Yup.string()
  //   .matches(
  //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/,
  //     'Password must have at least 1 Lowercase, 1 Uppercase, 1 Numeric and 1 Special character.',
  //   )
  //   .min(7, 'Password must have 7 character.')
  //   .required('Required'),
});

function LinkFastagToAddedVehicle(props) {
  const [status, setStatus] = useState(false);
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    amount,
    fleetId,
    vehCat,
    regNumber,
    setDisplayName,
    displayName,
    reIssue,
    bank,
    setReissueData,
    handleShowReissue,
  } = props;

  const { loader } = useSelector((state) => state?.manageVehicle);
  const [imageLoader, setImageLoader] = useState(false);
  const reissueStatus = useSelector(
    (state) => state?.fastag?.isReissueAvailable?.status
  );
  //const [fleetId, setFieldId] = useState("");

  const dispatch = useDispatch();

  const vehicleClass = vehCatData?.find((e) => vehCat == e.value)?.name;

  // const barCodeMask = [
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   '-',
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   '-',
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   /\d/,
  // ];
  //console.log("flee", vehicleClassName);

  const datepickerRef = useRef();
  const handleClickDatepickerIcon = () => {
    const datepickerElement = datepickerRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Vehicle Details</p>
            <div className="padding16" style={{ paddingTop: "20px" }}>
              <SvgImage
                name={bank === "YesBank" ? "yesBankTag" : "idfcBankTag"}
              />
            </div>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                if (bank === "YesBank") {
                  dispatch(sendAnalitics("Close_YBL_AddVeh_MV_FW_Web "));
                } else {
                  dispatch(sendAnalitics("Close_IDFC_AddVeh_MV_FW_Web "));
                }
                setStatus(false);
                toggleModal();
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                vehicleNumber: regNumber,
                vehicleLinkedFastagID: "",
                vehicleCategory: "",
                vehicleRegistrationDate: "",
                vehicleEngineNumber: "",
                pickedImage: "",
                docs: "",
              }}
              onSubmit={(values, actions) => {
                console.log("-------^^^^^---------", values);
                const vehicleClassName = vehCatData?.find(
                  (e) => values.vehicleCategory == e.value
                )?.label;

                const payload = {
                  // tagId: '34161FA820327FA40' + values.tagId,
                  //regDate: moment(values.regDate).format("DD-MM-YYYY"),
                  regDate: values.vehicleRegistrationDate,
                  docUrl: values.pickedImage,
                  docNumber:
                    reissueStatus === 2 || reIssue === 1 ? "" : regNumber,
                  engineNo: values.vehicleEngineNumber,
                  fleetId: fleetId,
                  tagId: values.vehicleLinkedFastagID,
                  type: bank,
                  vehicleCategory: vehicleClassName,
                  vrn: regNumber,
                };
                console.log("values onSubmit", payload);
                dispatch(
                  addVehicle(payload, (res) => {
                    console.log("inside resp1", res);
                    actions.setSubmitting(false);
                    if (res?.data?.type == "success") {
                      console.log("inside resp2", res);
                      toggleModal();
                    }
                    if (res?.status === 420) {
                      setReissueData({
                        regDate: values.regDate,
                        fleetId: fleetId,
                        barcode: values.tagId,
                        vrn: values.vrn,
                        // docNumber: values.docNumber,
                        docUrl: values.pickedImage,
                        vehicleCategory: values.vehicleCategory,
                        requestId: res?.data?.requestId,
                        txnNo: res?.data?.txnNo,
                        txnTime: res?.data?.txnTime,
                      });
                      handleShowReissue();
                    }
                  })
                );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <label className="sub-head-3 ">
                        {reissueStatus === 2 || reIssue === 1
                          ? "Chassis Number"
                          : "Vehicle Number"}
                      </label>
                    </div>
                    {reissueStatus === 2 || reIssue === 1 ? (
                      <input
                        type="text"
                        className="input-body-3"
                        name="vehicleNumber"
                        onChange={handleChange("vehicleNumber")}
                        value={values.vehicleNumber}
                        onBlur={handleBlur("vehicleNumber")}
                        touched={touched.vehicleNumber}
                        errors={errors.vehicleNumber}
                        placeholder="Enter fastag ID"
                        // pattern="^([0-9]{6})-([0-9]{3})-([0-9]{7})$"
                        // title="Please add - in the fastag ID"
                      />
                    ) : (
                      <p className="input-body-3">{regNumber}</p>
                    )}
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.vehicleNumber && touched.vehicleNumber ? (
                        <div>*{errors.vehicleNumber}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <label className="sub-head-3 ">Enter Barcode</label>
                    </div>

                    {/* <input
                      type="text"
                      id="vehicleLinkedFastagID"
                      className="input-body-3"
                      name="vehicleLinkedFastagID"
                      onChange={handleChange("vehicleLinkedFastagID")}
                      value={values.vehicleLinkedFastagID}
                      onBlur={handleBlur("vehicleLinkedFastagID")}
                      touched={touched.vehicleLinkedFastagID}
                      errors={errors.vehicleLinkedFastagID}
                      placeholder="Enter fastag ID"
                      // pattern="^([0-9]{6})-([0-9]{3})-([0-9]{7})$"
                      // title="Please add - in the fastag ID"
                    ></input> */}
                    <MaskInput
                      // editable={editable}
                      // numberOfLines={numberOfLines}
                      // value={values.vehicleLinkedFastagID}
                      // style={[
                      //   Styles.input,
                      //   {
                      //     borderColor:
                      //       touched || value?.length > 0
                      //         ? appTheme.colors.textBoxBorderFocused
                      //         : appTheme.colors.textBoxBorder,
                      //     color: colors.heading,
                      //   },
                      // ]}
                      type="text"
                      id="vehicleLinkedFastagID"
                      className="input-body-3"
                      name="vehicleLinkedFastagID"
                      onChange={handleChange("vehicleLinkedFastagID")}
                      value={values.vehicleLinkedFastagID}
                      onBlur={() => {
                        if (bank === "YesBank") {
                          dispatch(
                            sendAnalitics("BCode_YBL_AddVeh_MV_FW_Web", {
                              barCode_YB: values.vehicleLinkedFastagID,
                            })
                          );
                        } else {
                          dispatch(
                            sendAnalitics("BCode_IDFC_AddVeh_MV_FW_Web", {
                              barCode_IDFC: values.vehicleLinkedFastagID,
                            })
                          );
                        }
                        handleBlur("vehicleLinkedFastagID");
                      }}
                      touched={touched.vehicleLinkedFastagID}
                      errors={errors.vehicleLinkedFastagID}
                      placeholder="Enter barcode"
                      mask="000000-000-0000000"
                    />
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.vehicleLinkedFastagID &&
                      touched.vehicleLinkedFastagID ? (
                        <div>*{errors.vehicleLinkedFastagID}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">3</span>
                      <label
                        htmlFor="state"
                        className="sub-title-edit-vehicle-detial "
                      >
                        Select Vehicle Category
                      </label>
                    </div>
                    <Field
                      component="select"
                      placeholder={"--Select--"}
                      class="form-select select-option"
                      aria-label="Default select example"
                      name="vehicleCategory"
                      options={vehCatData}
                      onChange={(e) => {
                        if (bank === "YesBank") {
                          dispatch(
                            sendAnalitics("VC_YBL_AddVeh_MV_FW_Web", {
                              barCode_YB: e.target.value,
                            })
                          );
                        } else {
                          dispatch(
                            sendAnalitics("VC_IDFC_AddVeh_MV_FW_Web ", {
                              barCode_IDFC: e.target.value,
                            })
                          );
                        }
                        setFieldValue("vehicleCategory", e.target.value, true);
                      }}
                      value={values.vehicleCategory}
                      onBlur={handleBlur("vehicleCategory")}
                      errors={errors.vehicleCategory}
                      touched={touched.vehicleCategory}
                    >
                      <option value="" disabled selected>
                        '--Select--'
                      </option>
                      {vehCatData?.map((opt) => {
                        return <option value={opt?.value}>{opt?.name}</option>;
                      })}
                    </Field>
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.vehicleCategory && touched.vehicleCategory ? (
                        <div>*{errors.vehicleCategory}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">4</span>
                      <label className="sub-head-3 ">
                        Vehicle Engine Number
                      </label>
                    </div>

                    <input
                      type="text"
                      // id="html"
                      className="input-body-3"
                      // name="fav_language"
                      placeholder="Enter Vehicle Engine Number"
                      id="vehicleEngineNumber"
                      name="vehicleEngineNumber"
                      onChange={handleChange("vehicleEngineNumber")}
                      value={values.vehicleEngineNumber}
                      onBlur={() => {
                        if (bank === "YesBank") {
                          dispatch(
                            sendAnalitics("EngineNum_YBL_AddVeh_MV_FW_Web", {
                              vehicleEngineNumber_YB:
                                values.vehicleEngineNumber,
                            })
                          );
                        } else {
                          dispatch(
                            sendAnalitics("EngineNum_IDFC_AddVeh_MV_FW_Web", {
                              vehicleEngineNumber_IDFC:
                                values.vehicleEngineNumber,
                            })
                          );
                        }
                        handleBlur("vehicleEngineNumber");
                      }}
                      touched={touched.vehicleEngineNumber}
                      errors={errors.vehicleEngineNumber}
                    ></input>
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.vehicleEngineNumber &&
                      touched.vehicleEngineNumber ? (
                        <div>*{errors.vehicleEngineNumber}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">5</span>
                      <label className="sub-head-3 ">
                        Enter Vehicle Registration Date
                      </label>
                    </div>
                    <DatePickerField
                      name="vehicleRegistrationDate"
                      header="Select Registration date"
                      value={values.vehicleRegistrationDate}
                      onChange={(v) => {
                        if (bank === "YesBank") {
                          dispatch(
                            sendAnalitics("RegDate_YBL_AddVeh_MV_FW_Web ", {
                              vehicleRegistrationDate_YB: v,
                            })
                          );
                        } else {
                          dispatch(
                            sendAnalitics("RegDate_IDFC_AddVeh_MV_FW_Web ", {
                              vehicleRegistrationDate_IDFC: v,
                            })
                          );
                        }
                        setFieldValue("vehicleRegistrationDate", v, true);
                      }}
                      maxDate={new Date()}
                      ref1={datepickerRef}
                    />
                    <label
                      className="date-input"
                      style={{
                        position: "relative",
                        top: "-2rem",
                        left: "-1.4rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickDatepickerIcon();
                      }}
                    >
                      {/* <DatePicker className="input-body-3" /> */}
                      <SvgImage
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        classname="date-logo"
                      />
                    </label>
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.vehicleRegistrationDate &&
                      touched.vehicleRegistrationDate ? (
                        <div>*{errors.vehicleRegistrationDate}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="row">
                      <div className="col-10 row">
                        <div className="sub-title-1 col-12">
                          <span class="circle-logo">6</span>
                          <label className="sub-head-3 ">Upload RC Photo</label>
                        </div>
                        <div className="col-12">
                          <p className="sub-head-body mt-0">
                            Upload format must be in jpg, png or jpeg
                          </p>
                        </div>
                      </div>

                      <div className="col-2">
                        {!status ? (
                          <Button
                            className="add-attach-for-fastag-linked-vehicle"
                            type="button"
                          >
                            {imageLoader ? (
                              <div style={{ zIndex: 10 }}>
                                <ClipLoader
                                  color={"#485B7C"}
                                  loading={true}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </div>
                            ) : (
                              <div>
                                <SvgImage
                                  name="AddAttachment"
                                  height="36"
                                  width="36"
                                />

                                <input
                                  type="file"
                                  // multiple
                                  // accept="image/*"
                                  accept="image/*"
                                  className="add-attachment-btn"
                                  onChange={(e) => {
                                    const img = e?.target.files[0];
                                    console.log("image", img?.name);
                                    

                                    const tempArray = Object.assign(
                                      [],
                                      values.docs
                                    );
                                    console.log("sel", tempArray);

                                    if (img == null) {
                                      //clear image
                                      setFieldValue("pickedImage", "", true);
                                      //for now  only one supported
                                    } else {
                                      console.log("upload", {
                                        img: img,
                                        uploadType: "aadharFront",
                                        isRes: true,
                                      });
                                      setImageLoader(true)
                                      dispatch(
                                        storeMedia(
                                          {
                                            img: img,
                                            uploadType: "aadharFront",
                                            isRes: false,
                                          },
                                          (res) => {
                                            setImageLoader(false)
                                            setStatus(true);
                                            setDisplayName(
                                              (prev) => [{ name: img?.name }]
                                              //  [
                                              //   ...prev,
                                              //   { name: img?.name },
                                              // ]
                                            );
                                            if (res?.url) {
                                              if (bank === "YesBank") {
                                                dispatch(
                                                  sendAnalitics(
                                                    "UplRC_YBL_AddVeh_MV_FW_Web",
                                                    {
                                                      RCImageURL:
                                                        res?.data?.Location,
                                                    }
                                                  )
                                                );
                                              } else {
                                                dispatch(
                                                  sendAnalitics(
                                                    "UplRC_IDFC_AddVeh_MV_FW_Web",
                                                    {
                                                      RCImageURL:
                                                        res?.data?.Location,
                                                    }
                                                  )
                                                );
                                              }
                                              console.log("opt", res);
                                              tempArray.push(
                                                res?.data?.Location
                                              );
                                              setFieldValue(
                                                "pickedImage",
                                                res?.url,
                                                true
                                              );
                                              // setFieldValue('docs', tempArray, true);
                                              //for now  only one supported
                                              setFieldValue(
                                                "docs",
                                                [res?.data?.Location],
                                                true
                                              );
                                            }
                                          }
                                        )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                margin: "0px -16px 2px -614px",
                                color: "red",
                              }}
                            >
                              {errors.vehicleRcPhoto &&
                              touched.vehicleRcPhoto ? (
                                <div>*{errors.vehicleRcPhoto}</div>
                              ) : null}
                            </div>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="selected-img">
                        {status ? (
                          displayName?.map((d) => {
                            return (
                              <div className="row">
                                <div
                                  className="col-10"
                                  style={{ paddingTop: "0.3rem" }}
                                >
                                  <p
                                    style={{
                                      margin: "0 0 0 96px",
                                      color: "black",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={() => {
                                    setStatus(false);
                                  }}
                                  style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage name="CloseButtonLogo" />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <Button
                      type="submit"
                      className="butt-4"
                      disabled={loader ? true : false}
                      onClick={() => {
                        if (bank === "YesBank") {
                          dispatch(
                            sendAnalitics("SubRC_YBL_AddVeh_MV_FW_Web", {
                              regDate: values.vehicleRegistrationDate,
                              docUrl: values.pickedImage,
                              docNumber:
                                reissueStatus === 2 || reIssue === 1
                                  ? ""
                                  : regNumber,
                              engineNo: values.vehicleEngineNumber,
                              fleetId: fleetId,
                              tagId: values.vehicleLinkedFastagID,
                              type: bank,
                              vehicleCategory: values.vehicleCategory,
                              vrn: regNumber,
                            })
                          );
                        } else {
                          dispatch(
                            sendAnalitics("SubRC_IDFC_AddVeh_MV_FW_Web", {
                              regDate: values.vehicleRegistrationDate,
                              docUrl: values.pickedImage,
                              docNumber:
                                reissueStatus === 2 || reIssue === 1
                                  ? ""
                                  : regNumber,
                              engineNo: values.vehicleEngineNumber,
                              fleetId: fleetId,
                              tagId: values.vehicleLinkedFastagID,
                              type: bank,
                              vehicleCategory: values.vehicleCategory,
                              vrn: regNumber,
                            })
                          );
                        }
                      }}
                    >
                      {/* Link FASTag */}
                      {loader ? "Please Wait" : "Submit"}
                    </Button>
                  </FormGroup>
                  <div></div>
                </Form>
              )}
            </Formik>
            {/* <div className="space-div"></div> */}
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default LinkFastagToAddedVehicle;
