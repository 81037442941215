
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  bulkTransferSampleApi,
  bulkTransferUploadApi,
  deleteExpenseApi,
  editExpenseApi,
  getExpenseImageAPI,
  getWalletTransactionHistoryApi,
  kycDoneApi,
  qrcodeApi
} from '../../Utils/Apis';
import {processErrorAndRespond} from '../../Utils/functions';
import { toastStatus } from '../Actions/appActions';
import {
  bulkTransferSampleSuccess,
  deleteExpenseActionSuccess,
  editExpenseActionSuccess,
  getExpenseImageSuccessAction,
  getFuelWalletbalSuccess,
  getWalletTransactionHistorySuccess,
  kycDoneActionSuccess,
  qrcodeActionSuccess
} from '../Actions/walletAction';
import {
  WALLET_TRANSACTION_HISTORY,
  KYC_DONE,
  GET_EXPENSE_IMAGE,
  DELETE_EXPENSE,
  EDIT_EXPENSE,
  QR_CODE,
  BULK_TRANSFER_SAMPLE,
  BULK_TRANSFER_UPLOAD,

} from '../storeTypes';


function* getWalletTransactionHistorySaga(action) {
  try {
    const response = yield call(getWalletTransactionHistoryApi, action.payload);
    console.log("getWalletTransactionHistorySaga >>> ", response?.data);
    if (response.data.type == 'success') {
      console.log("success",response?.data?.data );
      yield put(getWalletTransactionHistorySuccess(response?.data?.data));
    }
  } catch (error) {
    console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* kycDoneSaga(action) {
  try {
    const response = yield call(kycDoneApi, action.payload);
    console.log("data", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(kycDoneActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* getExpenseImageSaga(action) {
  try {
    const response = yield call(getExpenseImageAPI, action.typeExp, action.id);
    console.log("data", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(getExpenseImageSuccessAction(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    // yield put(
    //   toastStatus({
    //     toastStatus: true,
    //     message: `Error : ${error?.response?.data?.message}`,
    //     status: false,
    //   })
    // );
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* deleteExpenseSaga(action) {
  try {
    const response = yield call(deleteExpenseApi, action.payload);
    console.log("rrrrrrrrrrrr ", response);
    if (response.data.type == 'success') {
      console.log("success",response?.data?.data );
      yield put(deleteExpenseActionSuccess(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message:"Expense data deleted successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    console.log('Error.Response:- ', error.response);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data}`,
        status: false,
      })
    );
    //Alert.alert('Error', error.response.data.message);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}


function* editExpenseSaga(action) {
  try {
    const response = yield call(editExpenseApi, action.id, action.payload);
    //console.log("rrrrrrrrrrrr ", response);
    if (response.data.type == 'success') {
      //console.log("success",response?.data?.data );
      yield put(editExpenseActionSuccess(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message:"Expense data was edited successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    console.log('Error.Response:- ', error.response);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data}`,
        status: false,
      })
    );
    //Alert.alert('Error', error.response.data.message);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}


function* qrcodeSaga(action) {
  try {
    const response = yield call(qrcodeApi, action.payload);
    console.log("data", response);
    if (response.data.type == "success") {
      // action.callback(response);
      yield put(qrcodeActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* bulkTransferSampleSaga(action) {
  try {
    const response = yield call(bulkTransferSampleApi, action.payload);
    // if (response.type == "success") {
      action.callback(response);
      yield put(bulkTransferSampleSuccess(response));
    // }
  } catch (error) {
    console.log('Error.Response:- ', error);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* bulkTransferUploadSaga(action) {
  try {
    console.log("saga aaya");
    const response = yield call(bulkTransferUploadApi, action.payload);
    // if (response.type == "success") {
      action.callback(response);
      yield put(bulkTransferSampleSuccess(response));
    // }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    action.callback(error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


export function* watchWalletSagas() {
    yield takeEvery(WALLET_TRANSACTION_HISTORY, getWalletTransactionHistorySaga);
    yield takeEvery(KYC_DONE, kycDoneSaga);
    yield takeEvery(GET_EXPENSE_IMAGE,getExpenseImageSaga);
    yield takeEvery(DELETE_EXPENSE,deleteExpenseSaga);
    yield takeEvery(EDIT_EXPENSE,editExpenseSaga);
    yield takeEvery(QR_CODE,qrcodeSaga);
    yield takeEvery(BULK_TRANSFER_SAMPLE,bulkTransferSampleSaga);
    yield takeEvery(BULK_TRANSFER_UPLOAD,bulkTransferUploadSaga);

}
