import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import RecentTransactionBox from "../../../subComponents/recentTransaction";
// import "./index.css";
import moment from "moment";
import {
  cleanVehicleHistory,
  vehicleHistory,
} from "../../../../services/Store/Actions/manageVehicleAction";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { getFastagTransactionHistory } from "../../../../services/Store/Actions/fastagAction";
import { expenseSumm } from "../../../../services/Store/Actions/driverslistActions";
import { getTripLedger } from "../../../../services/Store/Actions/TripsAction";

function TripLedger(props) {
  const { itemTemp } = props;
  const expenseSummDetail = useSelector((state) => state?.driverlist);

  const { fastagTransactionHistory } = useSelector((state) => state?.fastag);
  const { tripLedgerList, tripLedgerCount } = useSelector(
    (state) => state?.trip
  );
  const historyData = tripLedgerList;
  //   const fastagTransactionHistory?.count = useSelector(
  //     (state) => state?.manageVehicle?.vehicleReceivableHistory?.fleets?.length
  //   );
  const pageCount =
    tripLedgerCount % 10 === 0
      ? tripLedgerCount / 10
      : Math.floor(tripLedgerCount / 10) + 1;
  const dispatch = useDispatch();

  const [offset, setOffset] = useState(0);
  const handlePageClick = (event) => {
    console.log("Clickess");
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    let dataHit = {
      offset: 0,
      search: "",
      sortAs: "",
      sortBy: "",
    };
    if (itemTemp?.startTime == null || itemTemp?.startTime == undefined) {
    } else {
      dataHit["from"] = moment(itemTemp?.startTime).format("YYYY-MM-DD");
    }

    if (itemTemp?.endTime == null || itemTemp?.endTime == undefined) {
    } else {
      dataHit["to"] = moment(itemTemp?.endTime).format("YYYY-MM-DD");
    }
    dataHit["offset"] = newOffset;
    dispatch(getTripLedger(itemTemp?.tripId, dataHit));
    setOffset(newOffset);
  };

  let { id } = useParams();
  useEffect(() => {
    setOffset(0);
    console.log("users as props", props.user);
    // if (id !== undefined) {

    let dataHit = {
      offset: 0,
      search: "",
      sortAs: "",
      sortBy: "",
    };
    if (itemTemp?.startTime == null || itemTemp?.startTime == undefined) {
    } else {
      dataHit["from"] = moment(itemTemp?.startTime).format("YYYY-MM-DD");
    }

    if (itemTemp?.endTime == null || itemTemp?.endTime == undefined) {
    } else {
      dataHit["to"] = moment(itemTemp?.endTime).format("YYYY-MM-DD");
    }
    dispatch(getTripLedger(itemTemp?.tripId, dataHit));
    //   dispatch(vehicleHistory(id));
    // } else {
    //   dispatch(cleanVehicleHistory());
    // }
    // dispatch(vehicleHistory(props.user));
  }, [id]);

  return (
    <div className="main-vehicle-assigned-history">
      <div className="table-for-vehicle-receivable">
        <Table className="vehicleAssignedHistory">
          <thead>
            <tr className="table-header-customer-details">
              <th>Date & Time</th>
              <th>TxnRefNo</th>
              <th>Description</th>
              <th>TransferType</th>
              <th>Amount</th>
              <th>Reciept</th>
            </tr>
          </thead>
          {/* <div className="tbodyVehicle-div"> */}
          <tbody className="tbodyVehicle" style={{ textDecoration: "none" }}>
            {historyData?.map((items, i) => {
              console.log("-----------", i);
              return (
                <>
                  {
                    // (i-offset)<10 && (i-offset)>=0?
                    <tr className="table-row-customer-details">
                      <td>{items?.createdAt}</td>
                      <td>{items?.transferId || '-'}</td>
                      <td>
                        {items?.remarks ? items?.remarks : items?.description}
                      </td>
                      <td>{items?.transferType}</td>
                      <td>{items?.amount}</td>
                      <td>{items?.reciept || "N/A"}</td>
                    </tr>
                    // :<></>
                  }
                </>
              );
            })}
          </tbody>
          {/* </div> */}
        </Table>
        {/* {
          olderHistoryStatus?
          <div className="olderTransactions-div" onClick={handleOlderTransactions}>
          Older History
          </div>:
        <></>
        } */}
        {historyData && historyData?.length == 0 ? (
          <div className="olderTransactions-div" style={{ marginTop: "1rem" }}>
            No Transaction
          </div>
        ) : (
          <></>
        )}

        {pageCount > 1 ? (
          <div className="reactPagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
export default TripLedger;
