import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import SvgImage from "../../../../../../Icons/Icons";
import { Button } from "reactstrap";
import AddNewTrip from "./AddNewTrip";
import ManageDriverAddNewExpense from "../../../../../../Modals/ManageDriverAddNewExpense";
import { useParams } from "react-router-dom";
import { fastagVehicleTransactionByFleet, getCashTransactions, getM2pCardTransactions, getTransactionsAndExpense } from "../../../../../../../services/Store/Actions/TripsAction";


const LastTrip = (props) => {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const toggleModal = () => {
        setOpen(!open);
    }

    const showModal = () => {
        setOpen(true);
    }

    const  tripList  = useSelector((state) => state?.trip?.tripsByUserId?.rows);
    const {cashTransactions, fastagVehicleTransaction, cardTransactionsList} = useSelector(state=>state?.trip)
    const totalCashback = parseInt(fastagVehicleTransaction?.cashback || 0);
    const { id } = useParams();
    const dispatch = useDispatch()
    const reqData = {
        fleetId: tripList?.[0]?.fleetId,
        userId: tripList?.[0]?.userId,
        startTime: tripList?.[0]?.startTime || '',
        endTime: tripList?.[0]?.isActive > 0 ? new Date() : tripList?.[0]?.endTime || '',
        // endTime: endTime || "",
        transType: 'debit',
        limit: 2,
        cardUserId: tripList?.[0]?.cardUserId,
        offset: 0,
    };
    const totalExp= tripList?.[0]?.cardExpanse?tripList?.[0]?.cardExpanse:0+tripList?.[0]?.cashExpanse?tripList?.[0]?.cashExpanse:0+tripList?.[0]?.fastagExpanse?tripList?.[0]?.fastagExpanse:0;
    //const totalExp2;
    console.log('Last Trip', cashTransactions, fastagVehicleTransaction, cardTransactionsList)
    console.log('Last Trip', tripList?.[0]?.cardType)
    useEffect(() => {
        dispatch(getCashTransactions(reqData));
        dispatch(fastagVehicleTransactionByFleet(reqData));
        if (parseInt(tripList?.[0]?.cardType) ==1) {
            dispatch(getM2pCardTransactions(reqData));
        } else dispatch(getTransactionsAndExpense(reqData));
    }, [])

    const finalExpense = parseInt(cashTransactions?.sum?cashTransactions?.sum:0 )+ parseInt(fastagVehicleTransaction?.expense?fastagVehicleTransaction?.expense:0);
    return (
        <>
            <div className="padding16 row margin0 lowerBorderLine">
                <div className="col-1">
                    <SvgImage name="gpsLogo" />
                </div>
                <div className="col-3">{tripList?.[0]?.source}</div>
                <div className="col-4 greenDotted"></div>
                <div className="col-1">
                    <SvgImage name="gpsLogo" />
                </div>
                <div className="col-3">{tripList?.[0]?.destination}</div>
            </div>
            <div className="row margin0 padding16 lowerBorderLine">
                <div className="col-6">
                    <div className="semiBold heading3 greyFont padding16B">Cash Balance: </div>
                    <div className="semiBold heading3 greyFont padding16B">Start KM:</div>
                    <div className="semiBold heading3 greyFont">End KM:</div>
                </div>
                <div className="col-6 rightAlignment">
                    <div className="semiBold heading3 greyFont padding16B">{cashTransactions?.balance || '0'}</div>
                    <div className="semiBold heading3 greyFont padding16B">{tripList?.[0]?.startKmReading ? tripList?.[0]?.startKmReading : 0} KM</div>
                    <div className="semiBold heading3 greyFont">{tripList?.[0]?.endKmReading ? tripList?.[0]?.endKmReading : 0} KM</div>
                </div>
            </div>
            <div className="padding16">
                <div className="Bold heading2 padding16B">Expense summary</div>
                <div className="row margin0">
                    <div className="col-6">
                        <div className="smalBox flexStyling">
                            <SvgImage name="expenseIcon" />
                            <div className="padding8L">
                                <div className="greyFont heading4 Medium">
                                    Total Expense
                                </div>
                                <div className=" heading4 Medium">₹ {finalExpense}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="smalBox flexStyling">
                            <SvgImage name="savingsIcon" />
                            <div className="padding8L">
                                <div className="greyFont heading4 Medium">
                                    Savings
                                </div>
                                <div className=" heading4 Medium">₹ {totalCashback || '0'}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="padding16 padding8B" onClick={showModal}>
                    <Button className="semiBold heading2 notGenerateOpt">Add Expense</Button>
                </div>
            </div>
            <ManageDriverAddNewExpense
                userId={id}
                open={open}
                toggleModal={toggleModal}
                title="Logipe Account"
                handleClose={handleClose}
            />
        </>
    )
}

export default LastTrip;