import {
  FT_REPORT_FILTER,
  FT_REPORT_FILTER_SUCCESS,
  MA_REPORT_FILTER,
  MA_REPORT_FILTER_SUCCESS,
  WALLET_REPORT_FILTER,
  WALLET_REPORT_FILTER_SUCCESS,
  CASH_EXPENSE_STATEMENT,
  CASH_EXPENSE_STATEMENT_SUCCESS,
  MANAGER_REPORT,
  MANAGER_REPORT_SUCCESS
} from "../storeTypes";

export const filterExpenseReportAction = (id, data, callback) => {
  return {
    type: CASH_EXPENSE_STATEMENT,
    userId:id,
    payload: data,
    callback,
  };
};

export const filterExpenseReportActionSuccess = (data) => {
  return {
    type: CASH_EXPENSE_STATEMENT_SUCCESS,
    payload: data,
  };
};


export const filterMAReportAction = (data, callback) => {
  return {
    type: MA_REPORT_FILTER,
    payload: data,
    callback,
  };
};

export const filterMAReportActionSuccess = (data) => {
  return {
    type: MA_REPORT_FILTER_SUCCESS,
    payload: data,
  };
};

export const filterFastagReportAction = (data, callback) => {
  return {
    type: FT_REPORT_FILTER,
    
    payload: data,
    callback,
  };
};

export const filterFastagReportActionSuccess = (data) => {
  return {
    type: FT_REPORT_FILTER_SUCCESS,
    payload: data,
  };
};

export const getManagerReport = (data, callback) => {
  return {
    type: MANAGER_REPORT,
    
    payload: data,
    callback,
  };
};

export const getManagerReportSuccess = (data) => {
  return {
    type: MANAGER_REPORT_SUCCESS,
    payload: data,
  };
};

export const filterWalletReportAction = (id, data, callback) => {
  return {
    type: WALLET_REPORT_FILTER,
    payload: data,
    userId:id,
    callback,
  };
};

export const filterWalletReportActionSuccess = (data) => {
  return {
    type: WALLET_REPORT_FILTER_SUCCESS,
    payload: data,
  };
};
