import React, { useEffect, useState } from "react";
import "./index.css";
import { Modal, ModalBody, Table } from "reactstrap";
import SvgImage from "../../Icons/Icons";
import { FcManager } from "react-icons/fc";
import SearchBar from "../../../Subcomponent/PGCollectionSearchBar";
import PGCollectionBar from "../../../Subcomponent/PGCollectionBar";
import PGCollectionCard from "../../../Subcomponent/PGCollectionCard";
import AllTransactionDetailsCard from "../../../Subcomponent/AllTransactionDetailsCard";
import moment from "moment";
import {
  cleanFastagWalletTransaction,
  getFastagWalletStatement,
  getFastagWalletTransaction,
} from "../../../services/Store/Actions/fastagAction";
import {
  cleantransactionHistory,
  transactionHistory,
} from "../../../services/Store/Actions/masterWalletActions";
import {
  getWalletTransactionHistory,
  cleanWalletTransactionHistory,
} from "../../../services/Store/Actions/walletAction";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import AddingFilter from "../../Modals/addingFilter";
import { getMasterAccountStatement } from "../../../services/Store/Actions/fleetActions";
import ReactPaginate from "react-paginate";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";

function CardWalletAllTransactionWallet(props) {
  const { open, handleClose, toggleModal, Title, history, userId } = props;
  const [offsetMaster, setOffsetMaster] = useState(0);
  const [offset, setOffset] = useState(0);
  //const [offsetFastag, setOffsetFastag]= useState(0);
  //console.log("history", history);
  const dispatch = useDispatch();
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [check, setCheck] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [managerId, setManagerId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { masterAccountStatement } = useSelector((state) => state?.fleet);
  console.log("Yeah", masterAccountStatement);
  const { fastagWalletStatement } = useSelector((state) => state?.fastag);
  console.log("Yeah", fastagWalletStatement);
  const closing1 = () => {
    setActive1(false);
  };

  const toggling1 = () => {
    setActive1(!active1);
  };

  const showing1 = () => {
    setActive1(true);
  };

  const closing2 = () => {
    setActive2(false);
  };

  const toggling2 = () => {
    setActive2(!active2);
  };

  const showing2 = () => {
    setActive2(true);
  };

  const filterFunc = async (startDate, endDate) => {
    setOffset(0);
    setOffsetMaster(0);
    setCheck(true);
    setStart(startDate);
    setEnd(endDate);
    if (Title === "Master Account") {
      dispatch(cleanWalletTransactionHistory());
      // dispatch(getMasterAccountStatement(
      //   {
      //     from: moment(startDate).format("YYYY-MM-DD"),
      //     to: moment(endDate).format("YYYY-MM-DD"),
      //     userId: managerId,

      //   }
      // )
      // );
      dispatch(
        getWalletTransactionHistory({
          offset: 0,
          limit: "",
          from: moment(startDate).format("YYYY-MM-DD"),
          to: moment(endDate).format("YYYY-MM-DD"),
          userId: managerId,
        })
      );
    }

    if (Title === "Fastag Wallet") {
      dispatch(cleanFastagWalletTransaction());
      dispatch(
        getFastagWalletTransaction({
          offset: 0,
          limit: "",
          fromDate: moment(startDate).format("YYYY-MM-DD"),
          toDate: moment(endDate).format("YYYY-MM-DD"),
          sortBy: "",
          sortAs: "",
          userId: managerId,
        })
      );
    }
  };

  const downloadFunc = (startDate, endDate, mode) => {
    if (Title == "Master Account") {
      console.log("entered here hi");
      dispatch(
        getMasterAccountStatement(
          {
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
            mode: mode,
            userId: managerId,
          },
          (res) => {
            if (res?.data?.type === "success") {
              if (res?.data?.data?.url) {
                window.open(res?.data?.data?.url);
              }
            }
          }
        )
      );
    }

    if (Title == "Fastag Wallet") {
      dispatch(
        getFastagWalletStatement(
          {
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
            mode: mode,
            userId: managerId,
          },
          (res) => {
            if (res?.data?.type === "success") {
              console.log("rrrrrrrrrrrrrrrr", res);
              window.open(res?.data?.data?.url);
              // window.location.replace(res?.data?.data?.link);
              //window.open();
              //window.location.reload();
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    setOffset(0);
    setOffsetMaster(0);
    setCheck(false);
  }, []);

  // lists for history of transactions
  const { fastagTransaction, fastagTransactionList, count } = useSelector(
    (state) => state?.fastag
  );

  const { walletTransactionHistory, walletTransactionHistoryList } =
    useSelector((state) => state?.wallet);
  const masterHistory = walletTransactionHistory?.instrument_statement?.records;
  console.log("usssssssssssssss", walletTransactionHistory);
  const master_has_more =
    walletTransactionHistory?.instrument_statement?.has_more;
  const RecentCardTransaction = useSelector(
    (state) => state?.manageCard?.recentCardTransactions?.Transaction
  );
  const historyList =
    Title === "Driver cards"
      ? RecentCardTransaction
      : Title === "Fastag Wallet"
      ? fastagTransaction
      : masterHistory;

  const totalExpenseCount =
    Title === "Driver cards" ? 10 : Title === "Fastag Wallet" ? count : 10;

  const pageCount =
    totalExpenseCount % 10 === 0
      ? totalExpenseCount / 10
      : Math.floor(totalExpenseCount / 10) + 1;

  const { loader1 } = useSelector((state) => state?.fastag);
  const { loader2 } = useSelector((state) => state?.wallet);

  const handleClosing = () => {
    setOffset(0);
    setCheck(false);
    setOffsetMaster(0);
    dispatch(sendAnalitics("Cut_Txn_MA_Web"));
    props.handleClose();
  };

  const handlePageClick = (event) => {
    console.log("Clickess");
    dispatch(
      sendAnalitics("PageClick_Txn_FW_Web", {
        pageClick: event.selected,
      })
    );
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    if (!check) {
      console.log("fastag-> no filter");
      dispatch(
        getFastagWalletTransaction({
          offset: newOffset,
          limit: "",
          fromDate: "",
          toDate: "",
          userId: managerId,
        })
      );
    } else {
      console.log("fastag-> filter", start, end);
      dispatch(
        getFastagWalletTransaction({
          offset: newOffset,
          limit: "",
          fromDate: moment(start).format("YYYY-MM-DD"),
          toDate: moment(end).format("YYYY-MM-DD"),
          userId: managerId,
        })
      );
    }
    setOffset(newOffset);
  };

  const handlePrevOnclick = () => {
    if (offsetMaster > 0) {
      dispatch(sendAnalitics("LeftNav_Txn_MA_Web"));
      setOffsetMaster(offsetMaster - 10);
      console.log("offset", offsetMaster);
      dispatch(cleanWalletTransactionHistory());
      if (check) {
        dispatch(
          getWalletTransactionHistory({
            offset: offsetMaster - 10,
            limit: "",
            from: moment(start).format("YYYY-MM-DD"),
            to: moment(end).format("YYYY-MM-DD"),
            userId: managerId,
          })
        );
      } else
        dispatch(
          getWalletTransactionHistory({
            offset: offsetMaster - 10,
            limit: "",
            from: "",
            to: "",
          })
        );
    }
    console.log("X--");
  };

  const handleNextClick = () => {
    if (master_has_more) {
      dispatch(sendAnalitics("RightNav_Txn_MA_Web"));
      setOffsetMaster(offsetMaster + 10);
      console.log("offset", offset);
      dispatch(cleanWalletTransactionHistory());
      if (check) {
        dispatch(
          getWalletTransactionHistory({
            offset: offsetMaster + 10,
            limit: "",
            from: moment(start).format("YYYY-MM-DD"),
            to: moment(end).format("YYYY-MM-DD"),
            userId: managerId,
          })
        );
      } else
        dispatch(
          getWalletTransactionHistory({
            offset: offsetMaster + 10,
            limit: "",
            from: "",
            to: "",
            userId: managerId,
          })
        );
    }
    console.log("X++");
  };

  const handlingToggle = () => {
    setOffset(0);
    setCheck(false);
    setOffsetMaster(0);
    props.toggleModal();
  };

  const disableFilter = () => {
    setOffset(0);
    setCheck(false);
    setOffsetMaster(0);
    setManagerId("");
    setStart("");
    setEnd("");
    if (Title === "Master Account") {
      //console.log("TEST@@",check)
      dispatch(cleanWalletTransactionHistory());
      dispatch(
        getWalletTransactionHistory({
          offset: 0,
          limit: "",
          from: "",
          to: "",
          userId: managerId,
        })
      );
    }

    if (Title === "Fastag Wallet") {
      dispatch(cleanFastagWalletTransaction());
      dispatch(
        getFastagWalletTransaction({
          offset: 0,
          limit: "",
          fromDate: "",
          toDate: "",
          sortBy: "",
          sortAs: "",
          userId: managerId,
        })
      );
    }
  };

  return (
    <Modal
      isOpen={open}
      onHide={handleClosing}
      className="all-transaction-main-modal"
      toggle={handlingToggle}
      aria-labelledby="myModalLabel2"
    >
      <ModalBody className="transactionModalBody">
        <div className="PGCollectionBox">
          <div className=/*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction">
            <div
              className="closeEffect"
              onClick={handleClosing}
              // // className=""
            >
              <SvgImage
                classname="close-btn-text"
                style={{ width: "10%", height: "10%" }}
                name="CloseButtonLogo"
                width="20%"
                height="20%"
              />
            </div>
          </div>
          <div className="row">
            <div className=" col-2">
              <SvgImage
                classname="bank-logo-for-transaction"
                name={
                  Title === "Master Account"
                    ? "BankLogo"
                    : Title === "Card Wallet"
                    ? "cardWalletLogo"
                    : "tollBooth"
                }
                height="43.81"
                width="43.81"
              />
              {/* <SvgImage
                name="BankLogo"
   
              />
              </div> */}
            </div>

            <div className="PGCollectiontText col-9">{Title}</div>
          </div>
          <div className=" flexStyling new-row-for-download padding16L padding16R">
            <div className="flex1"></div>
            <div className="filterText" onClick={showing1}>
              Filter
              <div className="filterImg">
                <SvgImage name="filterIcon" />
              </div>
            </div>
            {
              <AddingFilter
                active={active1}
                toggleModal={toggling1}
                handleClose={closing1}
                func={filterFunc}
                name="Filter"
                mixPanelName={
                  Title === "Master Account"
                    ? "Filter_Txn_MA_Web"
                    : "Filter_Txn_FW_Web"
                }
                MP_sDate={
                  Title === "Master Account"
                    ? "SDate_Filter_Txn_MA_Web"
                    : "SD_Filter_Txn_FW_Web"
                }
                MP_eDate={
                  Title === "Master Account"
                    ? "EDate_Filter_Txn_MA_Web"
                    : "ED_Filter_Txn_FW_Web"
                }
                MP_close={
                  Title === "Master Account"
                    ? "Cut_Filter_Txn_MA_Web"
                    : "Close_Filter_Txn_FW_Web"
                }
                MP_apply={
                  Title === "Master Account"
                    ? "Apply_Filter_Txn_MA_Web"
                    : "Apply_Filter_Txn_FW_Web"
                }
                ma={true}
                managerId={managerId}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setManagerId={setManagerId}
              />
            }
            <div className=" downloadText" onClick={showing2}>
              Download Statement
              <div className="padding8L">
                <SvgImage name="downloadImg" />
              </div>
            </div>
            {
              <AddingFilter
                active={active2}
                toggleModal={toggling2}
                handleClose={closing2}
                func={downloadFunc}
                name="Download History"
                ma={true}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setManagerId={setManagerId}
                managerId={managerId}
                mixPanelName={
                  Title === "Master Account"
                    ? "Download_Txn_MA_Web"
                    : "Dwnl_Txn_FW_Web "
                }
                MP_sDate={
                  Title === "Master Account"
                    ? "SDate_Download_Txn_MA_Web"
                    : "SD_Dwnl_Txn_FW_Web"
                }
                MP_eDate={
                  Title === "Master Account"
                    ? "EDate_Download_Txn_MA_Web"
                    : "ED_Dwnl_Txn_FW_Web"
                }
                MP_close={
                  Title === "Master Account"
                    ? "Cut_Download_Txn_MA_Web"
                    : "Close_Dwnl_Txn_FW_Web "
                }
                MP_apply={
                  Title === "Master Account"
                    ? "Apply_Download_Txn_MA_Web"
                    : "Apply_Download_Txn_FW_Web"
                }
                MP_format={
                  Title === "Master Account" ? "Format_Download_Txn_MA_Web" : ""
                }
                MP_PDF={
                  Title === "Master Account"
                    ? "PDF_Format_Download_Txn_MA_Web"
                    : "PDF_Dwnl_Txn_FW_Web"
                }
                MP_excel={
                  Title === "Master Account"
                    ? "Excel_Format_Download_Txn_MA_Web"
                    : "Excel_Dwnl_Txn_FW_Web"
                }
              />
            }
          </div>
          <div className="flexStyling new-row-for-download padding32L padding32R">
            <div className="flex1"></div>
            <div className="filterDates">
              <div className="heading3 Bold">Filter Applied: </div>
              <div className="FontNB" style={{ paddingLeft: "8px" }}>
                {moment(start).format("DD-MM-YYYY") == "Invalid date"
                  ? "None"
                  : moment(start).format("DD-MM-YYYY") + " to"}{" "}
                {moment(end).format("DD-MM-YYYY") == "Invalid date"
                  ? ""
                  : moment(end).format("DD-MM-YYYY")}
              </div>
              <div
                style={{
                  paddingLeft: "8px",
                  position: "relative",
                  top: "-3px",
                  cursor: "pointer",
                  visibility:
                    moment(start).format("DD-MM-YYYY") == "Invalid date"
                      ? "hidden"
                      : "visible",
                }}
                onClick={disableFilter}
              >
                <SvgImage
                  classname="close-btn-text"
                  // style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo1"
                  width="20px"
                  height="20px"
                />
              </div>
            </div>
          </div>
          <div className="transitionContainer">
            <div className="tableContaincer">
              <Table className="MasterFastagTable">
                <thead>
                  <tr className="table-header-customer-details">
                    <th>
                      {" "}
                      <div style={{ marginLeft: "1.5rem" }}>
                        Transactions
                      </div>{" "}
                    </th>
                    <th>Amount</th>
                    <th>Remarks</th>
                    <th> Date and Time</th>
                  </tr>
                </thead>
                <tbody>
                  {historyList?.map((data) => {
                    let description =
                      Title === "Master Account"
                        ? data?.remarks
                        : data.description;
                    let merchantName =
                      Title === "Master Account"
                        ? data?.transaction_event_type
                        : data.transferType;
                    let remarks =
                      data?.userRemarks == "undefined" ||
                      data?.userRemarks == null ||
                      data?.userRemarks == "NA"
                        ? ""
                        : data?.userRemarks;
                    let amount = data?.amount !== null ? data.amount : 0;
                    let date =
                      Title === "Card Wallet"
                        ? moment(data?.transactionDate).format("DD/MM/YYYY")
                        : Title === "Fastag Wallet"
                        ? moment(data?.createdAt).format("DD/MM/YYYY")
                        : moment(data?.transaction_time).format("DD/MM/YYYY");
                    let time =
                      Title === "Card Wallet"
                        ? moment(data?.transactionDate).format("hh:mm:ss a")
                        : Title === "Fastag Wallet"
                        ? moment(data?.createdAt).format("hh:mm:ss a")
                        : moment(data?.transaction_time).format("hh:mm:ss a");

                    let descrip =
                      description == null ? "NO DESCRIPTION" : description;
                    let words = descrip.split(" ");
                    let lastWord = words[words.length - 1];
                    let cases1 =
                      descrip == "Transfer to FASTag wallet"
                        ? "1"
                        : words[0] == "Transfer" && lastWord == "card"
                        ? "2"
                        : words[0] == "Withdraw" && lastWord == "Card"
                        ? "3"
                        : words[0] == "Upi"
                        ? "4"
                        : words[0] == "Bank"
                        ? "5"
                        : words[0] == "Master"
                        ? "6"
                        : words[0] == "redeem"
                        ? "7"
                        : "8";
                    let cases2 =
                      descrip == "tag issue"
                        ? "1"
                        : descrip == "MASTER ACCOUNT to Wallet Transfer"
                        ? "2"
                        : words[0] == "UPI"
                        ? "3"
                        : words[0] == "Recharge"
                        ? "4"
                        : lastWord == "Recharged"
                        ? "5"
                        : words[0] == "Auto"
                        ? "6"
                        : words[0] == "test"
                        ? "7"
                        : "8";
                    return (
                      <>
                        <tr>
                          <td
                            style={{ display: "flex", marginLeft: "-2.5rem" }}
                          >
                            <div>
                              {Title === "Master Account" ? (
                                <SvgImage
                                  name={
                                    cases1 == "1"
                                      ? "transferWalletIcon"
                                      : cases1 == "2"
                                      ? "transferCardIcon"
                                      : cases1 == "3"
                                      ? "withdrawCardIcon"
                                      : cases1 == "4"
                                      ? "upiIcon"
                                      : cases1 == "5"
                                      ? "bankTransferIcon"
                                      : cases1 == "6"
                                      ? "masterRechargeIcon"
                                      : cases1 == "7"
                                      ? "redeemTransactionIcon"
                                      : "masterRechargeIcon"
                                  }
                                />
                              ) : (
                                <SvgImage
                                  name={
                                    cases2 == "1"
                                      ? "tagIssueIcon"
                                      : cases2 == "2"
                                      ? "bankTransferIcon"
                                      : cases2 == "3"
                                      ? "upiIcon"
                                      : cases2 == "4"
                                      ? "masterRechargeIcon"
                                      : "masterRechargeIcon"
                                  }
                                />
                              )}
                            </div>
                            <div
                              className="padding8L"
                              style={{ margin: "auto 0px" }}
                            >
                              {descrip}
                            </div>
                          </td>
                          <td
                            className={
                              merchantName === "DEBIT"
                                ? "redColor"
                                : merchantName === "debit"
                                ? "redColor"
                                : "greenColor"
                            }
                          >
                            {"₹" + amount.toFixed(2)}
                          </td>
                          <td>{remarks}</td>
                          <td>
                            <div>{date}</div>
                            <div>{time}</div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            {Title === "Master Account" ? (
              <div
                className="nextRow"
                style={{ paddingTop: "4px", paddingBottom: "0px" }}
              >
                <div className="nextRowCont ">
                  <button
                    className="PrevButton"
                    onClick={handlePrevOnclick}
                    style={{ opacity: offsetMaster > 0 ? "1" : "0.5" }}
                  >
                    <SvgImage name="PrevArrow" heigth="12px" width="12px" />
                  </button>
                  <button
                    className="nextButton"
                    onClick={handleNextClick}
                    style={{ opacity: master_has_more ? "1" : "0.5" }}
                  >
                    <SvgImage name="NextArrow" heigth="12px" width="12px" />
                  </button>
                </div>
              </div>
            ) : !check ? (
              pageCount > 1 ? (
                <div className="modalPagination">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
export default CardWalletAllTransactionWallet;
