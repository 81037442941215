import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "./index.css";
import moment from "moment";
import { Button, Table } from "reactstrap";
import NoTransaction from "./NoTransaction";
import NeocredCardTable from "./NeocredCardTable";
import M2PCardTable from "./M2PCardTable";
import HPCLFuelCardTable from "./HPCLFuelCardTable";
import SvgImage from "../../../../../Icons/Icons";
import AddingFilter from "../../../../../Modals/addingFilter";


const CardsTable = (props) => {
    const{ cardType}= props;
    const [cardChosen, setCardChosen] = useState(0);
    const cardExpense = useSelector((state) => state?.driverlist);

    let { id } = useParams();
    const dispatch= useDispatch();

    

  return (
    <>
    {/* {
        cardChosen==0?
    <div className=" flexStyling new-row-for-download padding16R padding16T" >
        <div className="Bold heading2 padding16 " style={{paddingLeft:"0"}}>Transaction History</div>
        <div className="flex1"></div>
        <div className="filterText" >
            Filter
            <div className="filterImg">
                <SvgImage name="filterIcon" />
            </div>
        </div>
        {
        <AddingFilter
            
        />
        }
        <div className=" downloadText" >
            Download Statement
            <div className="padding8L" >
            <SvgImage name="downloadImg" />
            </div>
            
        </div>
        {
        <AddingFilter
            
        />
        }   
    </div>
    : <div className=" flexStyling new-row-for-download padding16R padding16T" >
        <div className="Bold heading2 padding16 " style={{paddingLeft:"0"}}>Transaction History</div>
        <div className="flex1"></div>
        
        </div>
        } */}
    {/* <div className="row margin0 padding16B padding0L">
        <div className="col-2n5 padding0L" onClick={()=>{
            setCardChosen(0)
        }}>
            <Button className={cardChosen==0?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
                Pre-paid Card</Button>
        </div>
        <div className="col-2n5"  onClick={()=>{
            setCardChosen(1)
        }}>
            <Button className={cardChosen==1?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
                HPCL Fuel Card</Button>
        </div>
    </div> */}
    {
        cardChosen==0?
            cardType==1?
            <NeocredCardTable 
                setCardChosen={setCardChosen} 
                cardChosen={cardChosen}
                />
            :<M2PCardTable 
                setCardChosen={setCardChosen}
                cardChosen={cardChosen}
                />
        :<HPCLFuelCardTable 
            setCardChosen={setCardChosen}
            cardChosen={cardChosen}
            />
    }
    </>
  );
};

export default CardsTable;


