import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";
import SvgImage from "../../../../Icons/Icons";
import AddNewAddress from "./AddNewAddress";
import { getAddressListAction } from "../../../../../services/Store/Actions/ecomAction";

function AddressDetails(props){

    const {handleClose,open, toggleModal, setAddressId, setAddress}= props;

    // open add address details modal
    const [openAdd, setOpenAdd]= useState(false);

    const closeModalAdd=()=>{
    setOpenAdd(false);
    }

    const showModalAdd=()=>{
    setOpenAdd(true);
    }

    const toggleModalAdd=()=>{
    setOpenAdd(!openAdd);
    }

    const {addressList}= useSelector((state)=>state?.ecom);
    const dispatch= useDispatch();
    useEffect(()=>{
        dispatch(getAddressListAction((res)=>{
            console.log("res", res);
            // if(address?.pincode==undefined){
            //   setAddress(res?.data?.data?.data[0])
            //   setAddressId(res?.data?.data?.data[0]?.addressId)
            //}
        }))
    },[open])

    const handleSelect=(addressId, item)=>{
        setAddress(item);
        setAddressId(addressId);
        toggleModal();
    }
    return (
        <div>
            <Modal
            isOpen={open}
            onHide={()=>{
            //   setStatus(false);
                handleClose();
            }}
            className="positon settingModalMargin"
            toggle={()=>{
            //   setStatus(false);
                toggleModal();
            }}
            aria-labelledby="myModalLabel2"
            >
            <div
                className="modal-content"
                style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
            >
                <div className="modal-head-part">
                <p className="Add-balance-title">Address Details</p>
                <Button
                    type="button"
                    className="close-btn"
                    onClick={()=>{
                    // dispatch(sendAnalitics("close_OwnerAcc_Home_web"));
                    // setStatus(false);
                    toggleModal();
                    }}
                    aria-label="Close"
                >
                    <SvgImage name="CloseButtonLogo" />
                </Button>
                </div>
                <ModalBody className="padding8 padding0L ">
                <div className="padding16"  style={{marginLeft:"0.7rem"}}>
                    <div className="cartItem flexStyling padding16 Medium heading3" style={{borderRadius:"0.5rem"}} 
                    onClick={showModalAdd}>
                        <SvgImage name="gpsLogo" />
                        <div className="padding16L padding32R Medium heading3">Add new address</div>
                        <div style={{cursor:"pointer", position:"absolute", right:"2.5rem"}}>
                            <SvgImage name="nextArrow" />
                        </div>
                    </div>
                    <div style={{padding:"10px"}}>
                    {
                        addressList?.map((item,i)=>{
                            return(
                                <div className="padding16T Medium heading3">
                                <div className="row cartItem padding16 borderRad7"  key={i}>
                                    <div className="col-9">
                                        <div className="simpleText">{item?.address1+", "}</div>
                                        <div className="simpleText">{item?.city+" - "+item?.pincode}</div>
                                        <div className="simpleText">{item?.state}</div>
                                    </div>
                                    <div className="col-3" >
                                        <Button className="greenOutlineButton Medium text2 padding16L padding16R"
                                        onClick={()=>{
                                            handleSelect(item?.addressId, item)
                                            }}>Select</Button>
                                    </div>
                                </div>
                                </div>
                            )
                        })
                    }</div>
                </div>
            </ModalBody>
        </div>
        </Modal >
        <AddNewAddress 
            open={openAdd}
            onHide={closeModalAdd}
            toggleModal={toggleModalAdd}
            handleClose={closeModalAdd}
        />
    </div>
    )
}

export default AddressDetails;