import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { Button, Modal, ModalBody } from "reactstrap";
import SvgImage from "../../../../Icons/Icons";
import * as Yup from "yup";
import axios from "axios";
import { Formik } from "formik";
import { Form, FormGroup, Table } from "react-bootstrap";
import PricingDetails from "./PricingDetails";
import EmiForm from "./EmiForm";
import PinInput from "react-pin-input";
import { createOrderAction, orderPaymentAction } from "../../../../../services/Store/Actions/ecomAction";
import { sendAnalitics } from "../../../../../services/Store/Actions/appActions";

function PaymentMasterAccount(props) {
  const {
    handleClose,
    open,
    toggleModal,
    toggleModalCart,
    toggleModalPay,
    addressId,
    coinStatus,
  } = props;
  const {loader}= useSelector((state)=>state?.ecom);
  const validationSchema = Yup.object().shape({
   pin: Yup.string()
      .required("Required")
      .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
  });
  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.userId;
  const dispatch = useDispatch();

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={() => {
          //   setStatus(false);
          handleClose();
        }}
        className="positon settingModalMargin"
        toggle={() => {
          //   setStatus(false);
          toggleModal();
        }}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Pay From Master Account</p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                toggleModal();
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody className="padding8 padding0L ">
          <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                pin: "",
              }}
              onSubmit={(values, actions) => {
                console.log("values onSubmit", values);
                let values1 = {
                    "userId": userIdSession,
                    "addressId": addressId,
                    "isLogicoins": coinStatus ? 1 : 0,
                    // "productId": "any",
                    // "quantity": "any"
                    }
                dispatch(
                      sendAnalitics("Pay_logimart_fromMA_APIWeb", {
                        userId: userIdSession,
                        addressId: addressId,
                        isLogicoins: coinStatus ? 1 : 0,
                      })
                    );
                dispatch(createOrderAction(values1, (res) => {
                    console.log("create Order res", res);
                    if (res?.status == 200){
                        let obj2={
                            "userId": userIdSession,
                            "orderId": res?.data?.data?.orderId,
                            "pin": values?.pin
                        }
                        dispatch(
                          sendAnalitics("Success1_Pay_logimart_fromMA_Web", {
                            userId: userIdSession,
                            addressId: addressId,
                            isLogicoins: coinStatus ? 1 : 0,
                            orderId: res?.data?.data?.orderId
                          })
                        );
                        dispatch(orderPaymentAction(obj2
                            , (res)=>{
                                if (res?.status == 200) {
                                    toggleModalCart();
                                    toggleModalPay();
                                    toggleModal();
                                    dispatch(
                                      sendAnalitics("Success2_Pay_logimart_fromMA_Web", {
                                        userId: userIdSession,
                                        addressId: addressId,
                                      
                                      })
                                    );
                                }
                            }
                        ))
                    }}
                ))
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
                setFieldError,
              }) => (
                <Form onSubmit={handleSubmit} className="padding24T padding24">
                  <FormGroup>
                    <label
                      htmlFor="fullName"
                      className="semiBold heading2 blockDiv padding16B padding8L"
                    >
                      Pin 
                    </label>
                    <p className="sub-head-body" style={{marginLeft:"0.5rem"}}>
                      Security pin is needed to confirm payment
                    </p>
                    <PinInput
                      length={4}
                      initialValue=""
                      secret
                      onChange={handleChange("pin")}
                      values={values.pin}
                      //handleChange={handleChange("code")}
                      type="numeric"
                      inputMode="number"
                      style={{
                        padding: "10px",
                        marginLeft: "8px",
                        marginTop: "28px",
                      }}
                      inputStyle={{
                        border: "1px solid #A7A7A7",
                        borderRadius: "8px",
                        marginRight: "20px",
                      }}
                      //onComplete={(value, index) => {}}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      onClick={()=>{
                        // dispatch(sendAnalitics("Pin_DC_MA_Web",{
                        //   pin_MA_to_DriverCard:values.pin
                        // }));
                      }}
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.pin && touched.pin ? (
                        <div>*{errors.pin}</div>
                      ) : null}
                    </div>
                  </FormGroup>      
                  <FormGroup className="padding16T">
                    <button
                      type="submit"
                      className="quoteButtonDark"
                      disabled={loader ? true : false}
                      style={{
                        padding:"1rem",
                        background:
                          values.pin.length<4 
                            ? "#EFEFEF"
                            : loader
                            ? "#EFEFEF"
                            : "#485B7C",
                        color:
                          values.pin.length<4 
                            ? "#B5B5B5"
                            : loader
                            ? "#B5B5B5"
                            : "#ffffff",
                      }}
                      onClick={() => {
                        dispatch(
                          sendAnalitics("Pay_logimart_fromMA_Web", {
                            userId: userIdSession,
                            addressId: addressId,
                            isLogicoins: coinStatus ? 1 : 0,
                          })
                        );
                      }}
                    >
                      {loader ? "Please Wait" : "Make Payment"}
                    </button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default PaymentMasterAccount;
