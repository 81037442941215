import {
    GET_MASTERWALLET_BALANCE,
    GET_MASTERWALLET_BALANCE_SUCCESS,
    MASTERWALLET_TRANSACTION_HISTORY,
    MASTERWALLET_TRANSACTION_HISTORY_SUCCESS,
    CLEAN_MASTERWALLET_TRANSACTION_HISTORY,
    LINK_BANK_ACCOUNT_TO_LOGIPE,
    LINK_BANK_ACCOUNT_TO_LOGIPE_SUCCESS,
    CLEAN_MASTER_WALLET_REDUCER,
    NEFT_LINKED_ACCOUNTS_LIST,
    NEFT_LINKED_ACCOUNTS_LIST_SUCCESS
  } from '../storeTypes';
  
  const INIT_STATE = {
    loader: false,
    balance : 0,
    masterWalletTransactionHistory : [],
    masterWalletTransactionHistoryList:[],
    neftLinkedAccountsList:[],
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case LINK_BANK_ACCOUNT_TO_LOGIPE:{
        return {
          ... state, 
          loader:true
        }; 
      }

      case LINK_BANK_ACCOUNT_TO_LOGIPE_SUCCESS:{
        return {
          ... state, 
          loader:false
        };
      }
      case GET_MASTERWALLET_BALANCE: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_MASTERWALLET_BALANCE_SUCCESS: {
        return {
          ...state,
          loader: false,
          balance : action.payload,
        };
      }
      case MASTERWALLET_TRANSACTION_HISTORY: {
        return {
          ...state,
          loader: true,
        };
      }
      case MASTERWALLET_TRANSACTION_HISTORY_SUCCESS: {
        console.log("reducer");
        return {
          ...state,
          loader: false,
          masterWalletTransactionHistory : action.payload.rows,
          masterWalletTransactionHistoryList:[...state.masterWalletTransactionHistoryList, ...action?.payload?.rows], 

        };
      }
      case CLEAN_MASTERWALLET_TRANSACTION_HISTORY:{
        return{
          ...state,
          loader:false,
          masterWalletTransactionHistory:{},
          masterWalletTransactionHistoryList:[]
        }
      }
      case CLEAN_MASTER_WALLET_REDUCER:{
        return{
          ...state,
          loader:false,
        }
      }
      case NEFT_LINKED_ACCOUNTS_LIST:{
        return {
          ... state, 
          loader:true
        }; 
      }
      case NEFT_LINKED_ACCOUNTS_LIST_SUCCESS:{
        return {
          ... state, 
          loader:false,
          neftLinkedAccountsList:action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  