import React, { useEffect, useState } from "react";
import SvgImage from "../../../Components/Icons/Icons";
import "./index.css";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const SuccessScreen = (props) => {
  let { fleetId } = useParams();
  // const { loader } = useSelector((state) => state?.loginReducer);
  const dispatch = useDispatch();
  console.log("props?.item",props?.item);
  return (
    <div className="Login-Screen_box">
      <div className="padding16">
        <div className="row" id="loginDesktop">
          <div style={{ textAlign: "center", padding: "2.5rem" }}>
            <div className="Bold heading4">
              Please use Mobile For Transaction
            </div>
          </div>
        </div>
        <div id="loginMobile" className="h-full mb-5 mt-2">
          <div className="textLast">
            <SvgImage name="AppLogo" />
          </div>
          <div className="text-center my-5">
            <SvgImage name="successLogo" />
            <div
              className="SuccessText"
              style={{
                margin: 20,
              }}
            >
              Success!
            </div>
            <div className="mx-5">
              Recharge of Rs. {props?.item?.amount || 0} is succesful on your
              vehicle {fleetId}
            </div>
          </div>
          <div className="greenBg ">
            <div>
              {/* <SvgImage name={"PhoneAndLP"} /> */}
              <div className="textClassRecharge my-3 mb-5">
                Recharge and check you Fastag balance easily on Logipe App
              </div>
              <SvgImage name="playStoreLogin" />
              <div className="textClassRecharge mt-5">
                Easily Manage your Driver and fleet expenses with Logipe
                Starting today
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
