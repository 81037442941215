import {
    CLEAN_ECOM_LOADER,
    GET_CART_DETAILS,
    GET_CART_DETAILS_SUCCESS,
    ADD_TO_CART,
    ADD_TO_CART_SUCCESS,
    REMOVE_TO_CART,
    REMOVE_TO_CART_SUCCESS,
    GET_ORDER_LIST ,
    GET_ORDER_LIST_SUCCESS,
    CREATE_ORDER,
    CREATE_ORDER_SUCCESS,
    GET_ORDER,
    GET_ORDER_SUCCESS,
    ADD_ADDRESS_DETAILS,
    ADD_ADDRESS_DETAILS_SUCCESS,
    GET_ADDRESS_LIST,
    GET_ADDRESS_LIST_SUCCESS,
    GET_ADDRESS_DETAILS,
    GET_ADDRESS_DETAILS_SUCCESS,
    GET_COINS_AVAILABLE,
    GET_COINS_AVAILABLE_SUCCESS,
    SUPPORT_TICKET,
    SUPPORT_TICKET_SUCCESS,
    GENERATE_RECEIPT,
    GENERATE_RECEIPT_SUCCESS,
    GET_PRODUCT_DETAILS,
    GET_PRODUCT_DETAILS_SUCCESS,
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,
    FRESHDESK_TICKET,
    FRESHDESK_TICKET_SUCCESS,
    ORDER_PAYMENT,
    ORDER_PAYMENT_SUCCESS,
    FRESHDESK_TICKET_ECOM,
    FRESHDESK_TICKET_ECOM_SUCCESS
  } from "../storeTypes";
  
  // export const changeToastStatus = () => {
  //   return {
  //     type: TOAST_STATUS,
  //   };
  // };

  export const ticketWithoutAuthAction = (data, callback) => {
    console.log("hi1--")
    return {
      type: FRESHDESK_TICKET_ECOM,
      payload: data,
      callback
    };
  };


  export const ticketWithoutAuthActionSuccess = (data) => {
    console.log("hi6--")
    return {
      type: FRESHDESK_TICKET_ECOM_SUCCESS,
      payload: data,
    };
  };

  export const orderPaymentAction = (data, callback) => {
    console.log("hi1")
    return {
      type: ORDER_PAYMENT,
      payload: data,
      callback
    };
  };


  export const orderPaymentActionSuccess = (data) => {
    console.log("hi6")
    return {
      type: ORDER_PAYMENT_SUCCESS,
      payload: data,
    };
  };

  export const freshDeskTicketAction = (data, callback) => {
    console.log("hi1")
    return {
      type: FRESHDESK_TICKET,
      payload: data,
      callback
    };
  };


  export const freshDeskTicketActionSuccess = (data) => {
    console.log("hi6")
    return {
      type: FRESHDESK_TICKET_SUCCESS,
      payload: data
    };
  };

  export const getProductListingAction = (data,callBack) => {
    console.log("hi1")
    return {
      type: GET_PRODUCT_LIST,
      payload: data,
      callBack
    };
  };
  
  export const getProductListingActionSuccess = (data) => {
    console.log("hi6")
    return {
      type: GET_PRODUCT_LIST_SUCCESS,
      payload: data,
    };
  };

  export const getProductDetailAction = (data, callback) => {
    console.log("hi1")
    return {
      type: GET_PRODUCT_DETAILS,
      id: data,
      callback
    };
  };
  
  export const getProductDetailActionSuccess = (data) => {
    console.log("hi6")
    return {
      type: GET_PRODUCT_DETAILS_SUCCESS,
      payload: data,
    };
  };


  export const generateRecieptAction = (data, callback) => {
    console.log("hi1")
    return {
      type: GENERATE_RECEIPT,
      payload: data,
      callback
    };
  };
  
  export const generateRecieptActionSuccess = (data) => {
    console.log("hi6")
    return {
      type: GENERATE_RECEIPT_SUCCESS,
      payload: data,
    };
  };
  export const getInTouchAction = (id,data,callback) => {
    console.log("hi1")
    return {
      type: SUPPORT_TICKET,
      id:id,
      payload: data,
      callback
    };
  };
  
  export const getInTouchActionSuccess = (data) => {
    console.log("hi6")
    return {
      type: SUPPORT_TICKET_SUCCESS,
      payload: data,
    };
  };

  export const getLogicoinsAction = (data) => {
    console.log("hi1")
    return {
      type: GET_COINS_AVAILABLE,
      payload: data,
    };
  };
  
  export const getLogicoinsSuccessAction = (data) => {
    console.log("hi6")
    return {
      type: GET_COINS_AVAILABLE_SUCCESS,
      payload: data,
    };
  };

  export const cleanEcomLoader = (data) => {
    return {
      type: CLEAN_ECOM_LOADER,
      payload: data,
    };
  };

  export const getCartDetailsAction = (callback) => {
    //console.log("Action for login 1", data)
    return {
      type: GET_CART_DETAILS,
      callback
    };
  };

  export const getCartDetailsActionSuccess = (data) => {
    return {
      type: GET_CART_DETAILS_SUCCESS,
      payload: data,
    };
  };

  export const addToCartAction = (data, callback) => {
    console.log("Action for add cart 1", data)
    return {
      type: ADD_TO_CART,
      payload: data,
      callback
    };
  };

  export const addToCartActionSuccess = (data) => {
    return {
      type: ADD_TO_CART_SUCCESS,
      payload: data,
    };
  };

  export const removeToCartAction = (id,data,callback) => {
    //console.log("Action for login 1", data)
    return {
      type: REMOVE_TO_CART,
      id:id,
      payload: data,
      callback
    };
  };

  export const removeToCartActionSuccess = (data) => {
    return {
      type: REMOVE_TO_CART_SUCCESS,
      payload: data,
    };
  };

  export const getOrderListAction = (data, callback) => {
    console.log("order list 1", data)
    return {
      type: GET_ORDER_LIST,
      payload: data,
      callback
    };
  };

  export const getOrderListActionSuccess = (data) => {
    return {
      type: GET_ORDER_LIST_SUCCESS,
      payload: data,
    };
  };

    export const createOrderAction = (data,callback) => {
      //console.log("Action for login 1", data)
      return {
        type: CREATE_ORDER,
        payload: data,
        callback
      };
    };
  
    export const createOrderActionSuccess = (data) => {
      return {
        type: CREATE_ORDER_SUCCESS,
        payload: data,
      };
    };

    export const getOrderAction = (data,callback) => {
      //console.log("Action for login 1", data)
      return {
        type: GET_ORDER,
        payload: data,
        callback
      };
    };
  
    export const getOrderActionSuccess = (data) => {
      return {
        type: GET_ORDER_SUCCESS,
        payload: data,
      };
    };

    export const addAddressDetailsAction = (data,callback) => {
      //console.log("Action for login 1", data)
      return {
        type: ADD_ADDRESS_DETAILS,
        payload: data,
        callback
      };
    };
  
    export const addAddressDetailsActionSuccess = (data) => {
      return {
        type: ADD_ADDRESS_DETAILS_SUCCESS,
        payload: data,
      };
    };

    export const getAddressListAction = (callback) => {
      //console.log("Action for login 1", data)
      return {
        type: GET_ADDRESS_LIST,
        callback
      };
    };
  
    export const getAddressListActionSuccess = (data) => {
      return {
        type: GET_ADDRESS_LIST_SUCCESS,
        payload: data,
      };
    };

     export const getAddressDetailsAction = (data,callback) => {
      //console.log("Action for login 1", data)
      return {
        type: GET_ADDRESS_DETAILS,
        payload: data,
        callback
      };
    };
  
    export const getAddressDetailsActionSuccess = (data) => {
      return {
        type: GET_ADDRESS_DETAILS_SUCCESS,
        payload: data,
      };
    };

