import "./index.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { useNavigate } from "react-router-dom";

const CountAndLinkBox = (props) => {
  const navigate = useNavigate();
  const { subHead, SvgName2, text } = props;
  const totalDrivers = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.count
  );
  const { ownerAccountDetails } = useSelector((state) => state?.loginReducer);
  // const { allCustomers } = useSelector((state) => state?.receivable);
  const { customerNo } = useSelector(
    (state) => state?.customer
  );
  const homeAllDrivers = useSelector((state) => state?.driverlist?.homeAllDrivers);
  const driversCount = homeAllDrivers?.divers?.count;
  const number =
    subHead === "Manage Drivers"
      ? driversCount === undefined
        ? 0
        : driversCount
      : subHead === "Manage Vehicles"
      ? ownerAccountDetails?.vehicleCount === undefined
        ? 0
        : ownerAccountDetails?.vehicleCount
      : customerNo > 0
      ? customerNo
      : 0;

  //console.log("vallllllllllllllllllllllllll",ownerAccountDetails?.vehicleCount);
  const handleClick = () => {
    if (subHead == "Manage Customers") {
      navigate("/Customers");
      // window.location.href = "/Customers";
    } else if (subHead == "Manage Vehicles") {
      navigate("/Vehicles");
    } else {
      navigate("/Drivers");
    }
  };

  return (
    <div className="CountBox padding16 pointing" onClick={handleClick}>
      <div className="row">
        <div className="col-1 padding8L">
          <SvgImage name={SvgName2} />
        </div>
        <div className="col-10 heading2 Bold padding16L">{subHead}</div>
        <div className="col-1 pointing">
          <SvgImage name="linkArrow" />
        </div>
      </div>
      <div className="padding16T text3 Regular secondaryBlack">{text}:</div>
      <div className="Bold heading1">{number}</div>
    </div>
  );
};

export default CountAndLinkBox;
