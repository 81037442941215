import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { DatePickerField } from "../../core/inputs/input";
import SvgImage from "../../Icons/Icons";
import "./index.css";
import * as Yup from "yup";
import { getVehicleListAction } from "../../../services/Store/Actions/manageVehicleAction";
import {
  filterFastagReportAction,
  getManagerReport,
} from "../../../services/Store/Actions/reportActions";
import Toast from "../../subComponents/toast";
import moment from "moment";
import { toastStatus } from "../../../services/Store/Actions/appActions";
import { getManagerList } from "../../../services/Store/Actions/UserManagementAction";

const ManagerReport = (props) => {
  const [selectAll, setSelectAll] = useState(true);
  const [selectAllVehicles, setSelectAllVehicles] = useState(false);
  const [search, setSearch] = useState("");
  const optionList = [
    "Credit",
    "Debit",
    "Closing Balance",
    "Toll transaction",
    "Chargebacks",
    "Debit Adjustment",
    "Manual Cr/Dr",
    "Tag Issue & Replacement",
    "External Tag Recharge",
  ];
  const [open, setOpen] = useState(false);
  const { loader } = useSelector((state) => state?.report);
  const [checkedState, setCheckedState] = useState(
    new Array(optionList.length).fill(true)
  );

  const handleSelect = (position) => {
    if (selectAll) {
      setSelectAll(false);
    }
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const toastObject = useSelector((state) => state?.app?.toastObject);

  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    //fleetIDVal: Yup.string().required("Please select vehicle registration number from list."),
    mode: Yup.string().test(
      "noDefaultMode",
      "Please select download format",
      (val) => val != "default"
    ),
  });

  const { managerListing, managerList } = useSelector(
    (state) => state?.userManagement
  );
  const datepickerRefStart = useRef();
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };

  const dispatch = useDispatch();
  const allFleets = useSelector(
    (state) => state?.manageVehicle?.allFleets?.rows
  );
  const vehCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );

  const fastVeh =
    vehCount > 0 ? allFleets?.find((ele) => ele?.Fastag !== null) : "value";
  console.log("error", fastVeh);
  useEffect(() => {
    console.log("sjbsjbsjbsjb");
    dispatch(
      getManagerList({
        offset: "",
        search: search,
        sortAs: "",
        sortBy: "",
      })
    );
  }, [search]);
  console.log("managerListmanagerList", managerListing, managerList);
  return (
    <div className="whiteBackground containerShadow" style={{ height: "100%" }}>
      <Formik
        enableReinitialize
        initialValues={{
          startDate: "",
          endDate: "",
          mode: "default",
          fieldVal: "",
          vehicleRN: "",
          fleetIDVal: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log("values 1", values, selectAllVehicles);
          console.log("values 2", selectAll, optionList, checkedState);
          const checkList = checkedState.find((ele) => ele == true);
          console.log("values 3", checkList);
          let from = moment(values?.startDate).format("YYYY-MM-DD");
          let to = moment(values?.endDate).format("YYYY-MM-DD");

          let payload = {
            from: from,
            to: to,
            userId: values?.fleetIDVal,
            check: selectAllVehicles,
            mode: values?.mode,
          };

          console.log("payloadpayloadpayload", payload);

          dispatch(
            getManagerReport(payload, (res) => {
              if (res?.data?.type === "success") {
                console.log("sucessrrrrrrrrr", res?.data?.data);
                if (res?.data?.data?.url) {
                  window.open(res?.data?.data?.url);
                }
                else{
                  
                }
              }
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <FormGroup className="padding24 padding0B">
              <div className="d-flex">
                <div className="heading4 Regular padding8T padding8B">
                  Select Manager
                </div>
                <div className="flexStyling padding8 ms-2">
                  <input
                    type="checkbox"
                    checked={selectAllVehicles}
                    value="allOptions"
                    onChange={(e) => {
                      const { checked } = e.target;
                      setSelectAllVehicles(checked);
                    }}
                    className="form-check-input"
                  />
                  <div className="heading4 semiBold padding8L">
                    Download Report For All Manager{" "}
                  </div>
                </div>
              </div>

              <div
                className="searchBox"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <input
                  className="searchBar-input"
                  type="text"
                  id="vehicleRN"
                  name="vehicleRN"
                  touched={touched.vehicleRN}
                  errors={errors.vehicleRN}
                  onBlur={handleBlur("vehicleRN")}
                  style={{ width: "80%" }}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    setFieldValue("vehicleRN", event.target.value, true);
                  }}
                  value={values.vehicleRN}
                  autoFocus
                  placeholder="Enter Vehicle Registration number"
                />
              </div>
              <div style={{ position: "relative" }}>
                {open ? (
                  <div className="driverSearchContainer containerShadow">
                    <div
                      style={{
                        position: "absolute",
                        top: "0.3rem",
                        right: "0.3rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <SvgImage name="CloseButtonLogo" />
                    </div>
                    {managerListing?.map((item, i) => {
                      console.log("sbjsbjsb", item);
                      return (
                        <>
                          {item?.User !== null ? (
                            <div
                              key={i}
                              className="flexStyling padding8 "
                              onClick={(event) => {
                                console.log("------->>>>>", item?.User);
                                setOpen(false);
                                // setUserId(event.target.value);
                                setFieldValue(
                                  "vehicleRN",
                                  item?.User?.fullName,
                                  true
                                );
                                setFieldValue(
                                  "fleetIDVal",
                                  item?.User?.userId,
                                  true
                                );
                              }}
                            >
                              <input
                                type="radio"
                                name="vehicleRN"
                                value={item?.User?.fullName}
                                id={item?.User?.userId}
                              />
                              <div
                                className="padding16L"
                                value={item?.User?.userId}
                              >
                                {item?.User?.fullName}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                    {vehCount == 0 ? (
                      <div className="heading3 semiBold padding16 padding24B">
                        No Vehicle Available
                      </div>
                    ) : fastVeh == undefined ? (
                      <div className="heading3 semiBold padding16 padding24B">
                        No Vehicle Available
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {/* <div className="heading4 Regular"
                        style={{ margin: "7px 0 0px 0", color: "red" }}>
                            {errors.fleetIDVal && touched.fleetIDVal ? (
                                <div>*{errors.fleetIDVal}</div>
                            ) : null}
                    </div> */}
            </FormGroup>
            <FormGroup
              className="padding24 padding0T"
              style={{ paddingBottom: "0.5rem" }}
            >
              <div className="row ">
                <div className="col-5">
                  <div className="heading4 Regular padding8T padding8B">
                    Starting Date
                  </div>
                  <div className="searchBox" style={{ width: "100%" }}>
                    <DatePickerField
                      classname="searchBar-input"
                      name="startDate"
                      header="Select Start date"
                      value={values.startDate}
                      onChange={(v) => {
                        setFieldValue("startDate", v, true);
                      }}
                      maxDate={
                        values.endDate === "" ? new Date() : values.endDate
                      }
                      ref1={datepickerRefStart}
                    />
                    <label
                      className="date-input"
                      style={{
                        position: "relative",
                        top: "-5px",
                        left: "-1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickDatepickerIcon1();
                      }}
                    >
                      <SvgImage
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        // classname="date-logo"
                      />
                    </label>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.startDate && touched.startDate ? (
                      <div>*{errors.startDate}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-5">
                  <div className="heading4 Regular padding8T padding8B">
                    Ending Date
                  </div>
                  <div className="searchBox" style={{ width: "100%" }}>
                    <DatePickerField
                      classname="searchBar-input"
                      name="endDate"
                      header="Select End date"
                      value={values.endDate}
                      onChange={(v) => {
                        setFieldValue("endDate", v, true);
                      }}
                      //onChange={(v) => setFieldValue("endDate", v, true)}
                      minDate={values.startDate === "" ? "" : values.startDate}
                      maxDate={new Date()}
                      ref1={datepickerRefEnd}
                    />
                    <label
                      className="date-input"
                      for="endDate"
                      style={{
                        position: "relative",
                        top: "-5px",
                        left: "-1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickDatepickerIcon2();
                      }}
                    >
                      <SvgImage
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        // classname="date-logo"
                      />
                    </label>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.endDate && touched.endDate ? (
                      <div>*{errors.endDate}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup
              className="padding24  padding0T padding0B"
              style={{ height: "79%" }}
            >
              <div className="row" style={{ height: "100%" }}>
                {/* <div className="col-6" style={{ height: "100%" }}>
                  <div className="heading2 Bold padding16B padding24T">
                    Select Required Parameters
                  </div>
                  <div className="flexStyling padding8">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      value="allOptions"
                      onChange={(e) => {
                        const { value, checked } = e.target;
                        console.log("selected", value, checked);
                        setSelectAll(checked);
                        const newState = new Array(optionList.length).fill(
                          checked
                        );
                        setCheckedState(newState);
                      }}
                      className="form-check-input"
                    />
                    <div className="heading4 semiBold padding8L">
                      Select All{" "}
                    </div>
                  </div>
                  {optionList.map((item, i) => {
                    return (
                      <div className="flexStyling padding8 LowBorder" key={i}>
                        <input
                          type="checkbox"
                          value={item}
                          checked={selectAll || checkedState[i]}
                          className="form-check-input"
                          onChange={() => {
                            handleSelect(i);
                          }}
                        />
                        <div className="heading4 semiBold padding8L">
                          {item}
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.fieldVal && touched.fieldVal ? (
                      <div>*{errors.fieldVal}</div>
                    ) : null}
                  </div>
                </div> */}
                <div
                  className="col-6  padding24L"
                  style={{ height: "100%" }}
                  onChange={(event) => {
                    console.log("valllll", event.target.value);
                    setFieldValue("mode", event.target.value, true);
                  }}
                >
                  <div className="heading2 Bold padding16B padding24T">
                    Select Report Format
                  </div>
                  <div className="flexStyling padding8 form-check LowBorder">
                    <input type="radio" id="pdfOpt" name="format" value="pdf" />
                    <label
                      className="heading4 semiBold padding8L form-check-label"
                      for="pdfOpt"
                    >
                      PDF
                    </label>
                  </div>
                  <div className="flexStyling padding8 form-check">
                    <input
                      type="radio"
                      id="excelOpt"
                      name="format"
                      value="csv"
                    />
                    <label
                      className="heading4 semiBold padding8L form-check-label"
                      for="excelOpt"
                    >
                      Excel Sheet
                    </label>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.mode && touched.mode ? (
                      <div>*{errors.mode}</div>
                    ) : null}
                  </div>
                  <div className="padding24T">
                    <Button
                      className="generateOtp semiBold heading2"
                      style={{
                        width: "70%",
                        background: loader ? "#EFEFEF" : "#485B7C",
                        color: loader ? "#B5B5B5" : "#ffffff",
                      }}
                      disabled={loader ? true : false}
                    >
                      {loader ? "Please Wait" : "Download Report"}
                    </Button>
                  </div>
                </div>
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>

      {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={!toastObject?.toastStatus}
          isOpen={toastObject?.toastStatus}
          name="home"
          handleClose={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
                message: "",
                status: true,
              })
            );
            // if (!toastObject?.toastStatus)
            //window.location = "/";
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ManagerReport;
