import "./index.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import AddBalanceButton from "../../Buttons/addBalancebutton";
import DriverRequestFrame from "../../../../Subcomponent/DriverRequestFrame/DriverRequestFrame";
import RedeemCashback from "../../../Modals/RedeemCashback";
import { ToastContainer, toast } from 'react-toastify';
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";

const CashbackDiv = (props) => {
  const { cashBack } = useSelector((state) => state?.manageCard);
  const cashBackBalance = cashBack?.data?.balance === undefined ? 0.00 : (cashBack?.data?.balance).toFixed(2);
  const redeemedBalance = cashBack?.data?.redeem;
  const remainingBal = (cashBackBalance - redeemedBalance).toFixed(2);
  console.log("hello", redeemedBalance);
  const handleShow1 = () => { };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sendAnalitics("CB_Web"));
  }, [])



  // for the cashback transaction modal
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const handleClose = () => {
    setOpen(false);
    //setProceed(0);
    //console.log("closed");
  };

  function toggleModal() {
    setOpen(!open);
    //setProceed(0);
  }

  const handleShow = () => {
    dispatch(sendAnalitics("History_CB_Web"));
    setOpen(true);
    //console.log(a);
  };

  // this is for the redeem modal

  const [open2, setOpen2] = useState(false);

  const toggleModal2 = () => {
    setOpen2(!open2);
  };

  const handleClose2 = () => {
    setOpen2(false);
    //console.log("closed");
  };

  const handleShow2 = () => {
    // disabled redeem cashback
    setOpen2(true);
    dispatch(sendAnalitics("Redeem_CB_Web"));
    notify();
  };



  //toast for disabling redeem cashback button
  const customId = "custom-id-yes-main page";
  const notify = () => toast.error("Kindly use LogiPe app to redeem cashback.", {
    toastId: customId
  });

  return (
    <div className=" ">
      <div className="row">
        <div className="col-1">
          <SvgImage name="HomeCashback" />
        </div>
        <div className="col-6 heading2 Bold">Cashback</div>
        <div
          className="col-5 flexStyling greenFont heading3 semiBold pointing padding0R padding32L"
          // style={{ paddingLeft: "1.4rem" }}
          onClick={() => handleShow()}
        >
          Cashback History
          <div style={{ marginTop: "-0.2rem" }}>
            <SvgImage name="ForwardArrow" height="32" width="32" />
          </div>
        </div>
        {
          <DriverRequestFrame
            handleClose={handleClose}
            isOpen={open}
            onHide={handleClose}
            toggle={toggleModal}
            setOffset={setOffset}
            offset={offset}
          />
        }
      </div>
      <div className="row padding16T">
        <div className="col-4">
          <div className="textDiv Regular">Total Cashback Earned</div>
          <div className="text1 semiBold greenColor ">₹{cashBackBalance}</div>
        </div>
        <div className="col-4">
          <div className="textDiv Regular">To be Redeemed</div>
          <div className="text1 semiBold ">₹{remainingBal}</div>
        </div>
        <div className="col-3 padding16T">
          <AddBalanceButton
            Value="Redeem Cashback"
            title="Master Account"
            onclick={handleShow2}
          />
        </div>
      </div>
      {
        <RedeemCashback
          open={open2}
          toggleModal={toggleModal2}
          handleClose={handleClose2}
          remainingBal={remainingBal}
          color="title-color2"
        />
      }
    </div>
  );
};

export default CashbackDiv;
