import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { cleanExpenseWalletSummary, getExpenseWalletSummary } from "../../../../../../services/Store/Actions/driverslistActions";
import "./index.css";
import moment from "moment";
import { Button, Table } from "reactstrap";
import NoTransaction from "./NoTransaction";
import TripCashTable from "./TripCashTable";
import TripCardTable from "./TripCardTable";
import TripfastagTable from "./TripFastagTable";
import SvgImage from "../../../../../Icons/Icons";
import AddingFilter from "../../../../../Modals/addingFilter";


const TripTable = (props) => {
  const [cardChosen, setCardChosen] = useState(0);

  
  return (
    <>
    {/* <div className=" flexStyling new-row-for-download padding16R padding16T" >
        <div className="Bold heading2 padding16 " style={{paddingLeft:"0"}}>Transaction History</div>
        <div className="flex1"></div>
        <div className="filterText" >
            Filter
            <div className="filterImg">
                <SvgImage name="filterIcon" />
            </div>
        </div>
        {
        <AddingFilter
            
        />
        }
        <div className=" downloadText" >
            Download Statement
            <div className="padding8L" >
            <SvgImage name="downloadImg" />
            </div>
            
        </div>
        {
        <AddingFilter
            
        />
        }   
    </div> */}
    {/* <div className="row margin0 padding16B padding0L">
        <div className="col-2n5 padding0L" onClick={()=>{
            setCardChosen(0)
        }}>
            <Button className={cardChosen==0?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
            Card Transaction</Button>
        </div>
        <div className="col-2n5"  onClick={()=>{
            setCardChosen(1)
        }}>
            <Button className={cardChosen==1?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
            Cash Transaction</Button>
        </div>
        <div className="col-3"  onClick={()=>{
            setCardChosen(2)
        }}>
            <Button className={cardChosen==2?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
            FASTag Transaction</Button>
        </div>
    </div> */}
    {
        cardChosen==0?
          <TripCardTable 
          setCardChosen={setCardChosen}
          cardChosen={cardChosen}
          />
        :cardChosen==1?
          <TripCashTable 
          setCardChosen={setCardChosen}
          cardChosen={cardChosen}
          />
       :<TripfastagTable 
            setCardChosen={setCardChosen}
            cardChosen={cardChosen}
          />
    }
    </>
  );
};

export default TripTable;


