import React from 'react'
import "./index.css"
function DetailsCard(props) {
    const {key1, key2, key3, key4,key5,key6 ,value1, value2, value3, value4,value5,value6} = props;
  return (
    <div className='card-12 expenseCard2' >
        <div className='keys' >
            <div className='text2 Regular normalGrey'>{key1}</div>
            <div className='text2 Regular normalGrey'>{key2}</div>
            <div className='text2 Regular normalGrey'>{key3}</div>
            <div className='text2 Regular normalGrey'>{key4}</div>
            <div className='text2 Regular normalGrey'>{key5}</div>
            <div className='text2 Regular normalGrey'>{key6}</div>
        </div>
        <div className='values'>
            <div className='text2 Regular normalGrey'>{value1}</div>
            <div className='text2 Regular normalGrey'>{value2}</div>
            <div className='text2 Regular normalGrey'>{value3}</div>
            <div className='text2 Regular normalGrey'>{value4}</div>
            <div className='text2 Regular normalGrey'>{value5}</div>
            <div className='text2 Regular normalGrey'>{value6}</div>
        </div>
        {/* <div>hello</div> */}
    </div>
  )
}

export default DetailsCard