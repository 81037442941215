import React, { Component, useEffect, useState } from "react";
import SvgImage from "../../../../Icons/Icons";


const LoginHeaderSmall=()=>{
  const [cart, setCart]=useState();
  

  // const data=[{
  //   name:"pr1"
  // },{
  //   name:"pr1"
  // },{
  //   name:"pr1"
  // }]
  
  return (
    <div className="row loginHeaderContainer col-12" >
        <div className="col-8 loginHeader padding32 padding16L padding0R">
            <div>Digitise your truck business</div>
            <div>in one click</div>
            <div className="headerSubtext">Made in INDIA for Indian Truck Owners <SvgImage name="heartFlag" /></div>
        </div>
        <div className="col-4 loginImage">
            <SvgImage name="loginScreenTruck" />
        </div>
    </div>
  );
}


export default LoginHeaderSmall