import React from "react";
import { Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import SvgImage from "../../Components/Icons/Icons";
import "./routerbutton.css";

const RouterButton = (props) => {
  //    const [buttonTextClass,setbuttonTextClass] = useState("buttonTextNonClicked");

  //console.log("location ---- ",location.pathname);

  //const navigate = useNavigate();

  // function handleClick(){
  //    console.log("I am clicked")
  //    if (buttonClass === "nonClicked"){
  //        setButtonClass("Clicked")
  //        setbuttonTextClass("buttonTextClicked")
  //    }Manage
  //        setButtonClass("nonClicked")
  //        setbuttonTextClass("buttonTextNonClicked")
  //    }

  // };

  // const name = props.name==null?"button":props.name

  const {title}= props;
  return (
    <NavLink to={`/${props.link}`} style={{ textDecoration: "none", width:"100%", height: title=="AppLogo"?"40px":"100%" }} onClick={props.closeSideBar}>
      <div
        className="button"
        // onClick={handleClick}
        style={{height:title=="AppLogo"?"40px":"70px"}}
      >
        <Button
          className={
            props.active ? "nonClicked Clicked" : "nonClicked notClicked"
          }
        >
          <div style={{ width: title=="AppLogo"?"130px":"105px",}}>
            <SvgImage
              //   height="400"
              name={props.title}
              color={props.active ? "white" : "black"}
            />
          </div>
          {
            props.Title ?<p
            className={
              props.active
                ? "buttonTextNonClicked text-Click"
                : "buttonTextNonClicked text-not-clicked"
            }
            style={{width:props.Title === 'Trips'?"95%":"100%"}}
          >
            {props.Title}
          </p>:null
          }
          
        </Button>
      </div>
    </NavLink>
  );
};
export default RouterButton;
