import { put, takeEvery, call } from "redux-saga/effects";
import {
  vehicleHistoryApis,
  rechargeFastagAPI,
  addVehicleAPI,
  getFleet,
} from "../../Utils/Apis";
import {
  VEHICLE_HISTORY,
  FULL_VEHICLES_LIST,
  RECHARGE_FASTAG,
  ADD_VEHICLE,
} from "../storeTypes";
import { processErrorAndRespond } from "../../Utils/functions";
import {
  vehicleHistorySuccess,
  getVehicleListSuccess,
  rechargeFastagSuccess,
  addVehicleSuccess,
  fleetDetailsHomeSuccess,
  fleetDetailsRechargeSuccess,
} from "../Actions/manageVehicleAction";
import { toastStatus } from "../../../services/Store/Actions/appActions";






function* vehicleHistoryIsSaga(action) {
  try {
    // console.log("yaha tak aa rha hai kya bhai saga<><><><<><><><><><><<><><>");
    const response = yield call(vehicleHistoryApis, action.payload);
    //console.log("asdfghjklqwertyuiopzxcvbnmsdfghjksdfghj", response);
    if (response.data.type == "success") {
      // action.callback(response);
      yield put(vehicleHistorySuccess(response.data.data));
    }
  } catch (error) {
    console.log("vehicleHistorySuccess Error.Response:- ", error.response.data);
    /// action.callback(error.response.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* fullVehicleListIsSaga(action) {
  try {
    const response = yield call(getFleet, action.payload);
    console.log("fullVehicleListIsSaga", response?.data);
    if (response?.data?.type == "success") {
      // action.callback(response);
      yield put(getVehicleListSuccess(response?.data?.data?.fleets));
    }
  } catch (error) {
    console.log("vehicleHistorySuccess Error.Response:- ", error.response.data);
    // action.callback(error.response.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* rechargeFastagIsSaga(action) {
  try {
    console.log("REMINDER", action.payload);
    const response = yield call(rechargeFastagAPI, action.payload);
    console.log('ress ka here', response)
    //console.log("RECHARGE FASTAG", response);
    if (response?.data?.type == "success") {
      action.callback(response?.data);
      yield put(rechargeFastagSuccess(response?.data?.data));
      yield put(
        toastStatus({
        toastStatus: true,
        message: "Fastag recharge was successful",
        status: true,
      }))
    }
  } catch (error) {
    console.log("vehicleHistorySuccess Error.Response:- ", error.response.data);
    yield put(
      toastStatus({
      toastStatus: true,
      message: `Error : ${error?.response?.data.message}`,
      status: false,
    }))
    //alert(`Error : ${error?.response?.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* addVehicleIsSaga(action) {
  //const dispatch = useDispatch();
  try {
    const response = yield call(addVehicleAPI, action.payload);
    console.log("checkkkkkkkkkkkkkkkkkkk",response);
    if (response?.data?.type == "success") {
      action.callback(response);
      console.log(";;;;;;;;;;;;;;;;;;",response);
      yield put(addVehicleSuccess(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message:"Fastag linked successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //yield put(fastagError());
    console.log('Error.Response:- ', error?.response);
    if (error?.response?.status == 400) {
      yield put(
        toastStatus({
          toastStatus: true,
          message:"Sorry, Failed to link fastag. "+error?.response?.data?.message,
          status: false,
        })
      );
      // action.callBack({
      //   message: error?.response?.data?.message,
      //   status:400,
      // });
      // alert(
      //   `Error : ${error?.response?.data.message}`
      // );
    } 
    else if (error?.response?.status == 420){
        action.callback(
          {
            status: error?.response?.status,
            data: error?.response?.data?.data?.otpData,
          }
        )
    }
    else {
      yield put(
        toastStatus({
          toastStatus: true,
          message:"Sorry, Failed to link fastag. "+ error?.response?.data?.message,
          status: false,
        })
      );
      // action.callBack({
      //   message: error?.response?.data?.error
      // });
      //action.callBack(error?.response?.data);
    }
  }
  // try {
  //   console.log("let me see if it is reaching out here");
  //   const response = yield call(addVehicleAPI, action.payload);
  //   console.log("add vehicle response", response);
  //   if (response?.data?.type == "success") {
  //     yield put(addVehicleSuccess(response?.data?.data));
  //   }
  // } catch (error) {
  //   console.log("API Error :- ", processErrorAndRespond(error));
  // }
}
export function* watchVehicleTransactionHistory() {
  yield takeEvery(VEHICLE_HISTORY, vehicleHistoryIsSaga);
  yield takeEvery(FULL_VEHICLES_LIST, fullVehicleListIsSaga);
  yield takeEvery(RECHARGE_FASTAG, rechargeFastagIsSaga);
  yield takeEvery(ADD_VEHICLE, addVehicleIsSaga);
}
