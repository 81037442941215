import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import SvgImage from "../../Icons/Icons";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "./index.css";
import { DatePickerField } from "../../core/inputs/input";

import { useDispatch } from "react-redux";
import {
  addMoney,
  expenseSumm,
} from "../../../services/Store/Actions/driverslistActions";
import moment from "moment";
// import { allCardsBalance } from "../../../services/Store/Actions/cardWalletAction";
import { getMasterWalletBalance } from "../../../services/Store/Actions/masterWalletActions";
import { fetchBalance } from "../../../services/Store/Actions/manageCardActions";
import { transferToCard } from "../../../services/Store/Actions/manageCardActions";
import { addDriverExpense } from "../../../services/Store/Actions/TripsAction";
import SelectVendorModal from "../../Vendor/Components/Modal/SelectVendorModal";

import {
  sendAnalitics,
  storeMedia,
} from "../../../services/Store/Actions/appActions";

import { ClipLoader } from "react-spinners";
import { Switch } from "@material-ui/core";
const DriverDashboardAddExpense = (props) => {
  const dispatch = useDispatch();
  let {
    open,
    toggleModal,
    handleClose,
    name,
    userId,
    driverName,
    openChooseMethod,
    itemTemp,
    accountStatus,
    modalName,
    value,
    id,
    transferType,
    fleetId,
    showCard,
    settleAmount,
    tripKey,
    remarks,
    expenseData,
    expType,
    vendorId,
  } = props;

  // useEffect(() => {
  //   dispatch(fetchBalance());
  // }, [userId]);
  const { loader } = useSelector((state) => state?.manageCard);
  const { ownerBalance } = useSelector((state) => state?.manageCard); //LOgipe CARD BALANCE

  const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == null
      ? 0
      : ownerBalance?.instrument_balance?.balance;
  // useEffect(() => {}, [props]);
  const [statusPod, setStatusPod] = useState(false);
  const [imageLoaderPod, setImageLoaderPod] = useState(false);
  const [displayNamePod, setDisplayNamePod] = useState([]);
  const [card, setCard] = useState(false);
  const [openType, setOpenType] = useState(true);
  const [selectVendorModal, setSelectVendorModal] = useState(false);
  const [search, setSearch] = useState("");
  // console.log("ManageDriverAddBalancesingleCashData",  expenseData,   expenseData?.Type , expenseData?.vendor?.name,    modalName   );
  const datepickerRef = useRef();
  const handleClickDatepickerIcon = () => {
    const datepickerElement = datepickerRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  // const expenseType = [
  //   { value: "Food" },
  //   { value: "Service" },
  //   { value: "Fuel" },
  //   { value: "Salary" },
  //   { value: "Fastag" },
  //   { value: "Others" },
  // ];
  // useEffect(() => {}, [userId]);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">{name}</p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                if (!loader) {
                  toggleModal();
                }
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="card-wallet-icon-body2">
              <SvgImage
                name="cardWalletLogo"
                height="43.81"
                width="43.81"
                classname="card-wallet-logo-BankLogo"
              />
              <div>
                <p className="body-sub-part1-ab">Master Account Balance</p>
                <p className="body-sub-part2-ab">₹{LogipeAccounBal}</p>
              </div>
            </div>
            {showCard ? (
              <div
                className="flexStyling sub-head-3"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                <div> Cash</div>
                <div style={{ marginTop: "-7px" }}>
                  <Switch
                    checked={accountStatus == 4 ? card : false}
                    onChange={(e) => {
                      if (accountStatus == 4) {
                        setCard(!card);
                      }
                    }}
                    handleDiameter={20}
                    height={15}
                    width={40}
                    id="material-switch"
                  />
                </div>
                <div>Card</div>
              </div>
            ) : (
              <></>
            )}

            {itemTemp?.tripId ? (
              <div
                className="flexStyling sub-head-3"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                Trip : {itemTemp?.tripNo}
              </div>
            ) : (
              <></>
            )}
            <Formik
              // enableReinitialize
              initialValues={{
                amount: settleAmount ? settleAmount?.toString() : "",
                pin: "",
                name: value,
                remark: remarks ? remarks : "",
                tripId: itemTemp?.tripId || "",
                type: "",
                podUrl: "",
                givenTo: "",
                vendorId: vendorId ? vendorId : "",
                vendorName: "",
                dob: new Date(),
              }}
              validationSchema={Yup.object().shape({
                amount: Yup.number()
                  .typeError("Only Numbers are allowed")
                  .min(1, "Amount should be greater than 1")
                  .required("Amount is required"),
                pin: Yup.string()
                  .required("* Required")
                  .matches(/^[0-9]{4}$/, "* Pin Must be exactly 4 digits"),
                type: Yup.string().required("* Expense Type required"),
                // dob: Yup.string()
                //   .required('Expense Date is required'),
                // password: Yup.string()
                //   .min(6, 'Password must be at least 6 characters')
                //   .required('Password is required'),
                // confirmPassword: Yup.string()
                //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
                //   .required('Confirm Password is required')
              })}
              //validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                console.log("jjjjkkkkkk", values);
                if (card && userId) {
                  dispatch(
                    transferToCard(
                      {
                        amount: values.amount,
                        pin: values.pin,
                        remark: values.remark,
                        tripId: values?.tripId ? values?.tripId : "",
                      },
                      userId,
                      (res) => {
                        // actions.setSubmitting(false);
                        if (res?.status == 200) {
                          //console.log("successful");
                          dispatch(sendAnalitics("Success_close_DC_MA_Web"));
                          dispatch(
                            sendAnalitics("Success_Add_Bal_DC_MA_Web", {
                              amt: values.amount,
                              pin: values.pin,
                              remark: values.remark,
                              userId: userId,
                              tripKey: tripKey ? tripKey : "",
                            })
                          );
                          //navigate("/Customers")
                          //dispatch(expenseSumm(userId));
                          // window.location.reload();
                          toggleModal();
                        }
                      }
                    )
                  );
                } else if (userId || fleetId || vendorId || values?.givenTo ) {
                  if (expenseData) {
                    expenseData["totalExpense"] =
                      parseFloat(expenseData["totalExpense"]) +
                      parseFloat(values?.amount);
                    if (tripKey == "final") expenseData["expactedStatus"] = 1;
                    if (tripKey == "advance") expenseData["advanceStatus"] = 1;
                  }
                  if (values?.givenTo) {
                    if (values?.givenTo == "Driver") userId = itemTemp?.userId;
                    if (values?.givenTo == "Vehicle_Expense")
                      fleetId = itemTemp?.fleetId;
                    if (values?.givenTo == "Vendor" && values?.vendorId)
                      vendorId = values?.vendorId;
                  }
                  let finalData = {
                    amount: values?.amount,
                    url: values.podUrl ? values.podUrl : "",
                    userId: userId ? userId : null,
                    fleetId: fleetId ? fleetId : null,
                    vendorId: vendorId ? vendorId : null,
                    expanseTag: values.type,
                    pin: values?.pin,
                    remarks: values?.remark,
                    expanseType: "2",
                    transferType: transferType ? transferType : "",
                    tripKey: tripKey ? tripKey : "",
                    expenseData: expenseData ? expenseData : null,
                    expDate: values?.dob
                      ? moment(values?.dob).format("YYYY-MM-DD")
                      : "",
                  };
                  if (values?.tripId) {
                    finalData["tripId"] = values?.tripId;
                  }

                  // if (values?.otherRemarks) {
                  //   finalData["otherRemarks"] = values?.otherRemarks;
                  // }
                  dispatch(
                    addDriverExpense(finalData, (res) => {
                      actions.setSubmitting(false);
                      // dispatch(
                      //   sendAnalitics("Success_Add_Bal_DriverCard_Web", {
                      //     amt: values.amount,
                      //     pin: values.pin,
                      //     remark: values.remark,
                      //     userId: userId,
                      //   })
                      // );
                      if (res?.status == 200) {
                        console.log("successful");
                        //navigate("/Customers")
                        // dispatch(expenseSumm(userId));
                        // window.location.reload();
                        toggleModal();
                      }
                    })
                  );
                } else {
                  toast.error("please select user again.");
                }

                //   // addMoney(values, userId, (res) => {
                //   //   actions.setSubmitting(false);
                //   //   if (res?.data?.type === "success") {
                //   //     console.log("successful");
                //   //     //navigate("/Customers")
                //   //     dispatch(expenseSumm(userId));
                //   //     toggleModal();
                //   //   }
                //   // })
                // );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <label className="sub-head-3 ">Enter Amount</label>
                    </div>

                    <input
                      type="text"
                      pattern="[0-9]*"
                      id="amount"
                      className={
                        "input-body-3 form-control" +
                        (errors.amount && touched.amount ? " is-invalid" : "")
                      }
                      // style={{ marginLeft: "94px" }}
                      name="amount"
                      value={values.amount}
                      touched={touched.amount}
                      errors={errors.amount}
                      onBlur={handleBlur("amount")}
                      onChange={handleChange("amount")}
                      placeholder="Enter the amount"
                    ></input>
                    {/* <div className="input-amount-for-link"></div> */}
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>
                    {/* <ErrorMessage
                      name="amount"
                      component="div"
                      className="invalid-feedback"
                    /> */}
                  </FormGroup>

                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-head-3">{modalName}</p>
                    </div>

                    <input
                      type="text"
                      id="name"
                      readonly="readonly"
                      className={
                        "input-body-1 form-control" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                      name="name"
                      value={values.name}
                      // touched={touched.name}
                      // errors={errors.name}
                      // onBlur={handleBlur("name")}
                      // onChange={handleChange("name")}
                    />
                    <div className="input-amount"></div>
                    {/* <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    /> */}
                  </FormGroup>

                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">3</span>
                      <p className="sub-head-3">Enter remarks</p>
                    </div>
                    <p className="sub-head-body">
                      Enter the the remarks/comments
                    </p>

                    <input
                      type="text"
                      id="remark"
                      className={
                        "input-body-1 form-control" +
                        (errors.remark && touched.remark ? " is-invalid" : "")
                      }
                      name="remark"
                      value={values.remark}
                      touched={touched.remark}
                      errors={errors.remark}
                      onBlur={handleBlur("remark")}
                      onChange={handleChange("remark")}
                    ></input>
                    <div className="input-amount"></div>
                    <ErrorMessage
                      name="remark"
                      component="div"
                      className="invalid-feedback"
                    />
                  </FormGroup>

                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">4</span>
                      <p className="sub-head-3">Expense Type / Cash given</p>
                    </div>
                    <select
                      name="type"
                      class="form-select select-option"
                      aria-label="Default select example"
                      className={
                        "input-body-3 form-control" +
                        (errors.type && touched.type ? " is-invalid" : "")
                      }
                      value={values.type}
                      defaultValue={values.type}
                      onChange={(e) =>
                        setFieldValue("type", e.target.value, true)
                      }
                    >
                      <option value="" disabled selected>
                        --Select--
                      </option>
                      <option value="CASH GIVEN">CASH GIVEN</option>
                      <option value="Food">Food</option>
                      <option value="Fuel">Fuel</option>
                      <option value="Loading">Loading</option>
                      <option value="Unloading">Unloading</option>
                      <option value="Oil">Oil</option>
                      <option value="Repair">Repair</option>
                      <option value="RTO">RTO</option>
                      <option value="Toll">Toll</option>
                      <option value="Tyre">Tyre</option>
                      <option value="Salary">Salary</option>
                      <option value="Incentive">Incentive</option>
                      {/* <option value="3">Others</option> */}
                    </select>
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.type && touched.type ? (
                        <div>*{errors.type}</div>
                      ) : null}
                    </div>
                    {/* <ErrorMessage
                      name="expenseType"
                      component="div"
                      className="invalid-feedback"
                    /> */}
                  </FormGroup>
                  {expType == "trip" ? (
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">5</span>
                        <p className="sub-head-3">Given To</p>
                      </div>
                      <select
                        name="givenTo"
                        class="form-select select-option"
                        aria-label="Default select example"
                        className={
                          "input-body-3 form-control" +
                          (errors.givenTo && touched.givenTo
                            ? " is-invalid"
                            : "")
                        }
                        value={values.givenTo}
                        defaultValue={values.givenTo}
                        onChange={(e) => {
                          setFieldValue("givenTo", e.target.value, true);
                          // if (e.target.value == "Driver") {
                          //   setFieldValue("id", userId, true);
                          // }
                        }}
                      >
                        <option value="" disabled selected>
                          --Select--
                        </option>
                        <option value="Driver">Driver</option>
                        <option value="Vehicle_Expense">Vehicle Expense</option>
                        <option value="Vendor">Vendor</option>
                        {/* <option value="3">Others</option> */}
                      </select>
                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.givenTo && touched.givenTo ? (
                          <div>*{errors.givenTo}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  ) : (
                    <></>
                  )}
                  {values.givenTo == "Vendor" ? (
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo-2"></span>
                        <label className="sub-head-3 ">Vendor</label>
                      </div>
                      <input
                        type="text"
                        id="vendorName"
                        className={
                          "input-body-3 form-control" +
                          (errors.vendorName && touched.vendorName
                            ? " is-invalid"
                            : "")
                        }
                        // style={{ marginLeft: "94px" }}
                        name="vendorName"
                        value={values.vendorName}
                        touched={touched.vendorName}
                        errors={errors.vendorName}
                        onBlur={handleBlur("vendorName")}
                        onChange={handleChange("vendorName")}
                        placeholder="Select A Vendor"
                        autoFocus
                        onClick={() => {
                          setSelectVendorModal(true);
                        }}
                      />

                      {/* <div className="input-id-for-link"></div> */}
                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.vendorName && touched.vendorName ? (
                          <div>*{errors.vendorName}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  ) : <></>}
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">
                        {" "}
                        {expType == "trip" ? 6 : 5}
                      </span>
                      <label className="sub-head-3 ">Expense Date</label>
                    </div>
                    <DatePickerField
                      name="dob"
                      className={
                        "input-body-3 form-control" +
                        (errors.dob && touched.dob ? " is-invalid" : "")
                      }
                      value={values.dob}
                      header="Select booking date"
                      onChange={(v) => setFieldValue("dob", v, true)}
                      maxDate={new Date()}
                      ref1={datepickerRef}
                    />
                    <label
                      className="date-input"
                      style={{
                        color: "black",
                        position: "relative",
                        top: "-2rem",
                        left: "-1.4rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickDatepickerIcon();
                      }}
                    >
                      <SvgImage
                        style={{ marginRight: "1rem" }}
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        classname="date-logo"
                      />
                    </label>

                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.dob && touched.dob ? (
                        <div>*{errors.dob}</div>
                      ) : null}
                    </div>
                    {/* <ErrorMessage
                      name="dob"
                      component="div"
                      className="invalid-feedback"
                    /> */}
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">
                        {expType == "trip" ? 7 : 6}
                      </span>
                      <p className="sub-head-3">Upload Bill (Optional)</p>
                    </div>
                    <FormGroup
                      style={{ marginLeft: "90px", marginTop: "20px" }}
                    >
                      <div className="row">
                        {!statusPod ? (
                          <div className="col-4">
                            <p className="heading4 Regular" style={{}}>
                              Upload Document
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                        {!statusPod ? (
                          <>
                            {imageLoaderPod ? (
                              <div style={{ zIndex: 10 }}>
                                <ClipLoader
                                  color={"#2E7C7B"}
                                  loading={true}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </div>
                            ) : (
                              <div className="col-8">
                                <SvgImage
                                  name="AddAttachment"
                                  height="25"
                                  width="25"
                                />
                                <input
                                  type="file"
                                  // multiple
                                  // accept="image/*"
                                  accept="image/jpeg,image/gif,image/png,application/pdf"
                                  className="add-lr-attachment"
                                  onChange={(e) => {
                                    const img = e?.target.files[0];
                                    console.log("image", img?.name);

                                    const tempArray = Object.assign(
                                      [],
                                      values.docs
                                    );
                                    console.log("sel", tempArray);

                                    if (img == null) {
                                      //clear image
                                      setFieldValue("podUrl", "", true);
                                      //for now  only one supported
                                    } else {
                                      console.log("upload", {
                                        img: img,
                                        uploadType: "aadharFront",
                                        isRes: true,
                                      });
                                      setImageLoaderPod(true);
                                      dispatch(
                                        storeMedia(
                                          {
                                            img: img,
                                            uploadType: "aadharFront",
                                            isRes: false,
                                          },
                                          (res) => {
                                            setStatusPod(true);
                                            setDisplayNamePod((prev) => [
                                              { name: img?.name },
                                            ]);
                                            setImageLoaderPod(false);
                                            if (res?.url) {
                                              console.log("opt", res);
                                              tempArray.push(
                                                res?.data?.Location
                                              );
                                              setFieldValue(
                                                "podUrl",
                                                res?.url,
                                                true
                                              );
                                              // setFieldValue('docs', tempArray, true);
                                              //for now  only one supported
                                              setFieldValue(
                                                "docsPod",
                                                [res?.data?.Location],
                                                true
                                              );
                                            }
                                          }
                                        )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                margin: "0px -16px 2px -614px",
                                color: "red",
                              }}
                            >
                              {errors.podUrl && touched.podUrl ? (
                                <div>*{errors.podUrl}</div>
                              ) : null}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="selected-img">
                          {statusPod ? (
                            displayNamePod?.map((d) => {
                              return (
                                <div className="row">
                                  <div className="col-9">
                                    <p
                                      style={{
                                        color: "black",
                                        margin: "5px 0 0",
                                        // padding: "0",
                                      }}
                                    >
                                      {d.name}
                                    </p>
                                  </div>
                                  <div
                                    className="col-2"
                                    onClick={() => {
                                      setStatusPod(false);
                                    }}
                                  >
                                    <text>Change</text>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </FormGroup>
                  </FormGroup>

                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">
                        {expType == "trip" ? 8 : 7}
                      </span>
                      <p className="sub-head-4">Enter 4-digit Security Pin</p>
                    </div>
                    <p className="sub-head-body">
                      Security pin is needed to confirm payment
                    </p>
                    <PinInput
                      name="pin"
                      length={4}
                      initialValue=""
                      secret
                      onChange={(value, index) =>
                        setFieldValue("pin", value, true)
                      }
                      type="numeric"
                      inputMode="number"
                      style={{
                        padding: "10px",
                        marginLeft: "96px",
                        marginTop: "28px",
                      }}
                      inputStyle={{
                        border: "1px solid #A7A7A7",
                        borderRadius: "8px",
                        marginRight: "20px",
                      }}
                      onComplete={(value, index) => {}}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    <div
                      style={{
                        color: "#dc3545",
                        fontSize: "14px",
                        marginLeft: "6.5rem",
                      }}
                      className={
                        +(errors.pin && touched.pin ? " is-invalid" : "")
                      }
                    >
                      {" "}
                      <ErrorMessage className="is-invalid" name="pin" />
                    </div>

                    <Button
                      type="submit"
                      className="butt4"
                      disabled={loader ? true : false}
                      onClick={() => {
                        dispatch(
                          sendAnalitics("Add_Bal_DriverCard_fromMA_Web", {
                            amt: values.amount,
                            pin: values.pin,
                            remark: values.remark,
                            userId: userId,
                          })
                        );
                      }}
                    >
                      {loader ? "Please Wait" : "ADD"}
                    </Button>
                  </FormGroup>
                  <div></div>
                  {console.log("jjkkkkkkk",values)}
                  {selectVendorModal && (
                    <SelectVendorModal
                      open={selectVendorModal}
                      onHide={() => {
                        setSelectVendorModal(false);
                      }}
                      toggleModal={() => {
                        setSelectVendorModal(!selectVendorModal);
                      }}
                      handleClose={() => {
                        setSelectVendorModal(false);
                      }}
                      onClickItem={(data) => {
                        // setVendorData({});
                        // setVendorData(data);
                        console.log("dadatata", data);
                        setFieldValue("vendorName", data?.name, false);
                        setFieldValue("vendorId", data?.id, false);
                        setSelectVendorModal(false);
                      }}
                      search={search}
                      setSearch={setSearch}
                    />
                  )}
                </Form>
              )}
            </Formik>

            {/* <div className="space-div"></div> */}
          </ModalBody>
        </div>
        <ToastContainer
          theme="light"
          position="top-right"
          closeOnClick
          pauseOnHover
          autoClose={4000}
        />
      </Modal>
    </div>
  );
};
export default DriverDashboardAddExpense;
