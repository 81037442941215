import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import moment from "moment";
import {
  editCustomerAction,
  getCustomerAction,
} from "../../../../services/Store/Actions/customerAction";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import ToastV2 from "../../../subComponents/toastV2";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import { useNavigate } from "react-router-dom";
import {
  findTripAddressV2,
  getSavedAddress,
} from "../../../../services/Store/Actions/TripsAction";
import AddAddress from "../../../LoadReceipt/Components/AddAddress";
import { getBranch, setBranchLimit } from "../../../../services/Store/Actions/UserManagementAction";

function ChangeLimit(props) {
  const { toggle, modal, setModal, customerId,branchId,limit } = props;
  const [openToast, setOpenToast] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toastObject = useSelector((state) => state?.app?.toastObject);
  const { customer } = useSelector((state) => state?.customer);
  const { vendorDetails } = useSelector((state) => state?.beneficiary);
  const [display1, setDisplay1] = useState(false);
  const [search1, setSearch1] = useState("");
  const [color1, setColor1] = useState(-1);
  const [address, setAddress] = useState(false);
  const [assignedAssigned,setAsssignedStatus] = useState(0)
  const addAddress = () => setAddress(!address);
  const { savedAddresses } = useSelector((state) => state?.trip);
  console.log("====>>customer>>>.........", branchId);
  const validationSchema = Yup.object().shape({
    limit: Yup.string().required("Please enter your Customer name"),
    // phone: Yup.string()
    //   .required("Phone number must contain 10 digits")
    //   .matches(
    //     /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    //     "Phone number must contain 10 digits"
    //   ),
    address: Yup.string().required("Please enter your address"),
  });

  const handleClose = () => {
    setModal(!modal);
  };
  useEffect(() => {
    if (display1 == true) {
      // dispatch(findTripAddressV2({},(res)=>{
      //   console.log("findTripAddressV2",res);
      // }))
      dispatch(
        getSavedAddress({ address: search1 }, (res) => {
          console.log("getSavedAddress", res);
        })
      );
    }
  }, [display1, search1]);
  const findSearch1 = () => {
    setColor1(-1);
    dispatch(
      findTripAddressV2({
        offset: 0,
        search: search1,
      })
    );
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            style={{ marginTop: " 30px" }}
            //   className="PGCollectionBox"
          >
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px",marginBottom:"20px" }}
            >
              Set Limit
            </div>
            <div style={{
              marginLeft: "40px",marginBottom:"-20px"
            }}>
            <button
              className={`Medium textDiv1 ${
                assignedAssigned==0 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: "-16px",
                marginBottom:"10px"
              }}
              onClick={(e) => {
                setAsssignedStatus(0);
              }}
            >
             One Time Limit
            </button>
            <button
              className={`Medium textDiv1 ${
                assignedAssigned==1 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: "-16px",
                marginBottom:"10px"
              }}
              onClick={(e) => {
                setAsssignedStatus(1);
              }}
            >
             Daily Limit
            </button>
            <button
              className={`Medium textDiv1 ${
                assignedAssigned==2 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: "-16px",
                marginBottom:"10px"
              }}
              onClick={() => {
                console.log("snjssbbb12222")
                setAsssignedStatus(2);
              }}
            >
              Monthly Limit
            </button>
            </div>
            

            <Formik
              // isValid='true'
              enableReinitialize
              //   validationSchema={validationSchema}
              initialValues={{
                // limit: limit?limit:"0",
              }}
              onSubmit={(values) => {
                console.log("===>>>>init", {
                  branchId:branchId,
                  limit:values?.limit,
                  type:assignedAssigned
                });
                // setOpenToast(true);
                dispatch(setBranchLimit({
                  branchId:branchId,
                  limit:values?.limit,
                  type:assignedAssigned
                },(res)=>{
                  console.log("===>>>>setOpenToastsssss",res)
                  dispatch(
                    getBranch({}, (res) => {
                      console.log("snjsbsbs", res);
                    })
                  );
                  handleClose();
                }))
                
                // handleClose();
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "40px", padding: "0 0px 0 55px" }}
                >
                  <FormGroup>
                 
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Limit
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="limit"
                            className={`inputBoxAddCust ${
                              touched.limit && errors.limit
                                ? "is-invalid"
                                : ""
                            }`}
                            name="limit"
                            value={values.limit}
                            onChange={handleChange("limit")}
                            onBlur={handleBlur("limit")}
                            touched={touched.limit}
                            errors={errors.limit}
                            placeholder={limit || '0'}
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.limit && touched.limit ? (
                              <div>*{errors.limit}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      
                    </div>

                  </FormGroup>
                  <Button
                      type="submit"
                      style={{ width: "160px", borderRadius: "5px" }}
                      className="generateOtp textAddCust Medium btn btn-secondary"
                      // onClick={addFreight}
                    >
                      Done
                    </Button>
                 
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>

      {toastObject.toastStatus &&
      toastObject?.message == "Customer Edit Successfully" ? (
        <ToastV2
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            setOpenToast(false);
          }}
          isOpen={toastObject?.toastStatus}
          name="driver"
          handleClose={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
                message: "",
                status: true,
              })
            );
            window.location = "/Customers";
            // navigate("/Customers");
          }}
        />
      ) : (
        <></>
      )}
      <AddAddress
        toggle={addAddress}
        address={address}
        setAddress={setAddress}
      />
    </div>
  );
}

export default ChangeLimit;
