import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../index.css";
import { useParams } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import { AiOutlinePlus } from "react-icons/ai";
import { Button, Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import {
  getLedgerListAction,
  clearAllTrips,
} from "../../../../services/Store/Actions/customerAction";
import {
  getLRList,
  tripBulkActions,
} from "../../../../services/Store/Actions/TripsAction";
import AddNewTrip from "../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/RightBoxComponents/TripSubComponents/AddNewTrip";
import TripModalV2 from "../../../TripsMainV2/Components/CreateTripV2";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";
import ActionModal from "../ActionsModal";
import { useNavigate } from "react-router-dom";
import EditTripV2 from "../../../TripsMainV2/Components/EditTripV2";
import { getVehicleListAction } from "../../../../services/Store/Actions/manageVehicleAction";
import EditFreight from "../../../TripsMainV2/Components/EditFreight";
import AddFreight from "../../../TripsMainV2/Components/AddFreight";
import CreateLRModal from "../../../LoadReceipt/Components/CreateLRModal";
import { getSignedUrlApiAction } from "../../../../services/Store/Actions/appActions";
import AddLr from "../../../TripsMainV2/Components/AddLr";

const listing = ["Details", "Edit", "Delete"];
function TripsComponent(props) {
  let { customerId, setTripNos, activeTab, customer } = props;
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [offset, setOffset] = useState(0);
  const [color, setColor] = useState(-1);
  const [vehicleNo, setVehicleNo] = useState("");
  const [isCheckAllTrips, setIsCheckAllTrips] = useState(false);
  const [displayAction, setDisplayAction] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [method, setMethod] = useState(false);
  const [isCheckTrips, setIsCheckTrips] = useState({});
  const [isCheckTripsLength, setIsCheckTripsLength] = useState(0);
  const [open5, setOpen5] = useState(false);
  const [itemTemp, setItemTemp] = useState({});
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [fleetId, setFleetId] = useState("");
  const [modal, setModal] = useState(false);
  const createLR = () => setModal(!modal);
  const { freightListCount, freightList } = useSelector((state) => state?.trip);
  console.log("=======>>>freightList>>>>", freightListCount, freightList);
  const [tripType, setTripType] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [editFreight, setEditFreight] = useState(false);
  const [vrn, setVrn] = useState("");
  const [invoice, setInvoice] = useState("");
  const allFleets = useSelector((state) => state?.manageVehicle?.allFleets);
  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips, setIsTrips] = useState(0);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  useEffect(() => {
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
  }, [authUser, managerControlList]);
  console.log("ffdfdfdfdfd", allFleets);
  const vehCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );
  const fastVeh =
    vehCount > 0
      ? allFleets?.rows?.find((ele) => ele?.Fastag !== null)
      : "value";
  const pageCount = freightListCount / 10;
  const handleActionOpenClose = () => {
    setOpenAction(false);
  };
  const toggleActionOpen = () => {
    setOpenAction(!openAction);
  };
  const toggleModal5 = () => {
    setOpen5(!open5);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };
  const editFreightDetails = () => {
    setEditFreight(true);
  };

  const toggleModalEditFreight = () => {
    setEditFreight(!editFreight);
  };
  const handleSelection = (i, item) => {
    if (
      (isCheckTrips.length > 1 && (i == 0 || i == 1)) ||
      isCheckTrips.length == 0
    ) {
      //disable edit button
    } else if (isCheckTrips.length == 1 && i == 0) {
      console.log(
        "nmnmnmnmnmnmn",
        freightList.find((li) => li?.Trip?.tripId == isCheckTrips[0])
      );
      onSelect(
        freightList.find((li) => li?.Trip?.tripId == isCheckTrips[0])?.Trip
      );
    } else if (isCheckTrips.length == 1 && i == 1) {
      editFreightDetails();
    } else {
      setColor(i);
      setMethod(listing[i]);
      toggleActionOpen();
    }
  };
  const handlePageClick = (event) => {
    console.log("Clickess");
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };
  // const tripData = [];
  let { id } = useParams();

  useEffect(() => {
    if (id !== undefined && id !== "") {
    }
  }, [id]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleClose = () => {
    setOpen2(false);
  };

  const toggleModal = () => {
    setOpen2(!open2);
  };
  useEffect(() => {
    dispatch(
      getLRList({
        customerId: customerId,
        invoice: invoice || undefined,
        offset: offset,
        // search: search,
        fleetId: fleetId,
        from: from || "",
        to: to || "",
        sortBy: "",
        // isActive: tripType,
        status: tripType,
        type: activeTab ? 1 : 0,
      })
    );
    return function cleanUp() {
      dispatch(clearAllTrips());
    };
  }, [customerId, tripType, offset, activeTab, search, from, to, invoice]);
  useEffect(() => {
    dispatch(
      getVehicleListAction({
        offset: "",
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [search]);
  const openActions = () => {
    setDisplayAction(!displayAction);
  };

  console.log("window.innerWidth", freightList, window.innerWidth);
  const navigate = useNavigate();
  const onSelect = (row) => {
    // storeDataToAsyncStorage("row", row);
    navigate(`/lr/${row.lrNo}`, { state: { lr: row, offset: offset } });
    console.log("dsadasd", row);

    // setItem(row);
    console.log("thissssssssssssss", row);
  };
  return (
    <div className="box2">
      <div className="box3 d-flex">
        <div>
          <div className="headingText">Vehicle No:</div>
          <input
            type="text"
            className="text-input-placeholder mt-2"
            placeholder={"Enter vehicle number"}
            onChange={(event) => {
              setOpen(true);
              setSearch(event.target.value);
              if(event.target.value == ''){
                setFleetId('');
              }
            }}
            onFocus={() => {
              setOpen(true);
            }}
            onBlur={() => {
              setOpen(false);
            }}
            value={search}
            // onkeypress={setStorage}
          />
          <div style={{ position: "relative" }}>
            {open ? (
              <div
                className="driverSearchContainer containerShadow"
                style={{
                  width: "100%",
                  marginTop: "5px",
                  borderRadius: "8px",
                  border: "1px solid #E2E2E2",
                }}
              >
                {/* <div
                          style={{
                            position: "absolute",
                            top: "0.3rem",
                            right: "0.5rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <SvgImage name="CloseButtonLogo" />
                        </div> */}
                {allFleets?.rows?.map((item, i) => {
                  return (
                    <>
                      {/* {item?.Fastag !== null ? ( */}
                      <div
                        key={i}
                        className="flexStyling padding8 "
                        onClick={(event) => {
                          setOpen(false);
                          setFleetId(item?.fleetId);
                          setSearch(item?.regNumber);
                          // setUserId(event.target.value);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        <input
                          type="radio"
                          name="vrn"
                          value={item?.fleetId}
                          id={item?.fleetId}
                        />
                        <div className="padding16L" value={item?.fleetId}>
                          {item?.regNumber}
                        </div>
                      </div>
                      {/* ) : (
                                    <></>
                                  )} */}
                    </>
                  );
                })}
                {vehCount == 0 ? (
                  <div className="heading3 semiBold padding16 padding24B">
                    No Vehicle Available
                  </div>
                ) : fastVeh == undefined ? (
                  <div className="heading3 semiBold padding16 padding24B">
                    No Vehicle Available
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* <div className="ms-4">
          <div className="headingText">Date Range:</div>
          <input
            type="text"
            className="text-input-placeholder mt-2"
            placeholder={"Enter Date Range"}
            onChange={() => {}}
            value={""}
            // onkeypress={setStorage}
          />
        </div> */}
        <div className="ms-4">
          <div className="headingText">From:</div>
          <input
            type="date"
            className="text-input-placeholder mt-2"
            placeholder={"Select Date"}
            onChange={(event) => {
              setFrom(event.target.value);
            }}
            max={to}
            value={from}
            // onkeypress={setStorage}
          />
        </div>
        <div className="ms-4">
          <div className="headingText">To:</div>
          <input
            type="date"
            className="text-input-placeholder mt-2"
            placeholder={"Select Date"}
            onChange={(event) => {
              setTo(event.target.value);
            }}
            min={from}
            value={to}
            // onkeypress={setStorage}
          />
        </div>
      </div>
      <div className="d-flex box3 pt-0 justify-content-between">
        <div className="d-flex">
          <button
            className={`Medium heading3 ${
              tripType == null ? "greenButton" : "greenOutlineButton"
            } rounded-pill`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType(null);
              setOffset(0);
              setInvoice("");
            }}
          >
            All Freights
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "2" ? "greenButton" : "greenOutlineButton"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("2");
              setOffset(0);
            }}
          >
            Completed
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "1" ? "greenButton" : "greenOutlineButton"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("1");
              setOffset(0);
            }}
          >
            Running
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "0" ? "greenButton" : "greenOutlineButton"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("0");
              setOffset(0);
            }}
          >
            Pending
          </button>
          <button
            className={`Medium heading3 ${
              invoice == "1" ? "greenButton" : "greenOutlineButton"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setInvoice("1");
              setOffset(0);
            }}
          >
            Invoiced
          </button>
          <button
            className={`Medium heading3 ${
              invoice == "0" ? "greenButton" : "greenOutlineButton"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setInvoice("0");
              setOffset(0);
            }}
          >
            Uninvoiced
          </button>
          {/* <button
            className={`Medium heading3 ${
              tripType == "2" ? "greenButton" : "greenOutlineButton"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("2");
              setOffset(0);
            }}
          >
            Invoiced
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "3" ? "greenButton" : "greenOutlineButton"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("3");
              setOffset(0);
            }}
          >
            Not Invoiced
          </button> */}
        </div>
        <div className="d-flex align-items-center">
          <div>
            {/* <button
              className={
                "Medium text2 greenOutlineButton d-flex align-items-center"
              }
              style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
              onClick={openActions}
            >
              <div
                className="me-2 "
                style={{
                  fontWeight: 500,
                }}
              >
                Actions
              </div>
              <div>
                <SvgImage name="downArrow2" />
              </div>
            </button> */}
            {displayAction ? (
              <div className={"dropDownDivActions"} style={{ height: `110px` }}>
                <div style={{ marginTop: "12px" }}>
                  {listing.length > 0 ? (
                    listing?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="row listItems DriverName"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => {
                            handleSelection(i, item);
                          }}
                          style={{ padding: "0 0 10px 0" }}
                        >
                          {isCheckTrips.length == 0 ? (
                            <div className="col-9" style={{ color: "#c4c4c4" }}>
                              {item}
                            </div>
                          ) : color === i &&
                            isCheckTrips.length > 1 &&
                            (i == 0 || i == 1) ? (
                            <div className="col-9" style={{ color: "#c4c4c4" }}>
                              {item}
                            </div>
                          ) : color === i ? (
                            <div
                              className="col-9"
                              style={{
                                color: "white",
                                backgroundColor: "#2e7c7b",
                                border: "1px solid #2e7c7b",
                                borderRadius: "6px",
                              }}
                            >
                              {item}
                            </div>
                          ) : isCheckTrips.length > 1 && (i == 0 || i == 1) ? (
                            <div className="col-9" style={{ color: "#c4c4c4" }}>
                              {item}
                            </div>
                          ) : (
                            <div className="col-9" style={{ color: "#2e7c7b" }}>
                              {item}
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <button
            className={
              "Medium text2 greenButton ms-3 d-flex align-items-center"
            }
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={() => {
              if (["3", 3].includes(isTrips)) {
                createLR(true);
              }
            }}
          >
            <AiOutlinePlus color="#fff" size={16} />
            <div
              className="ms-2 "
              style={{
                fontWeight: 500,
              }}
            >
              Create Freight
            </div>
          </button>

          {/* <div className=" d-flex align-items-center text2 ms-3 headingText">
            <div className="padding8R">
              <SvgImage name="downloadImgV2" />
            </div>
            Download
          </div> */}
        </div>
      </div>
      <div className="table-for-vehicle-receivable">
        <Table className="vehicleAssignedHistory">
          <thead
            className="th align-middle text-center"
            style={{ zIndex: 99, textAlignLast: "center" }}
          >
            <tr className="table-header-customer-details">
              <th className="p-0" style={{ width: "7%" }}>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={isCheckAllTrips}
                  id="selectAll"
                  onChange={(e) => {
                    // setIsCheckAllTrips(!isCheckAllTrips);
                    // setIsCheckTrips(freightList?.map((li) => li?.Trip?.tripId));
                    // // setTripSelect(freightList?);
                    // if (isCheckAllTrips) {
                    //   console.log("===<<<<<initttt");
                    //   setIsCheckTrips([]);
                    //   // setTripSelect([]);
                    // }
                    // console.log(
                    //   "===<<<<<nmtknikn1",
                    //   isCheckAllTrips,
                    //   isCheckTrips
                    // );
                    // console.log("====>>>>>>>1", tripSelect);
                  }}
                  disabled={true}
                />
              </th>

              <th className="p-0" style={{ width: "10%" }}>
                Lr No
              </th>
              <th className="p-0">Vehicle No</th>

              {/* <th className="p-0">Tonnage</th> */}
              <th className="p-0">From</th>
              <th className="p-0">To</th>
              <th className="p-0">Weight</th>
              <th className="p-0">Freight Amount</th>
              {/* <th className="p-0">Trip No</th> */}
              {["1", "2"].includes(tripType) && <th className="p-0">pod</th>}
              {tripType == "2" && <th className="p-0">invoice</th>}
              {tripType == "2" && <th className="p-0">payment</th>}
              {tripType == null && <th className="p-0">Status</th>}
            </tr>
          </thead>
          {/* <div className="tbodyVehicle-div"> */}
          {freightListCount == 0 ? (
            <div className="padding32 textCenter">
              <SvgImage name="TripsMap" />
              <div className="Bold heading1 padding24T padding16B FontSuperBold primaryBlack">
                Create Freight
              </div>
              <div className="greyFont heading3">
                Create to manage expense easily
              </div>
              <div className="greyFont heading3 padding32B">
                Get Freight sheet in 1-click
              </div>
              <button
                className={"Medium heading3 greenButton"}
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                onClick={() => {
                  if (["3", 3].includes(isTrips)) {
                    createLR();
                  }
                }}
              >
                Create Freight
              </button>
            </div>
          ) : (
            <tbody className="tbodyVehicle" style={{ textDecoration: "none" }}>
              {freightList?.map((items, i) => {
                console.log("setIsCheckTrips", isCheckTrips, items);
                return (
                  <>
                    <tr className="table-row-customer-details2">
                      <td style={{ width: "7%" }}>
                        <div
                          onClick={() => {
                            console.log("adsfghwerter");
                          }}
                        >
                          {console.log("tt?.[0]?.fleetId ----- ", isCheckTrips)}
                          <input
                            type="checkbox"
                            name="trips2"
                            id={items?.freightId}
                            checked={
                              isCheckTrips?.[items?.freightId]?.freightId
                            }
                            onChange={(e) => {
                              console.log(
                                "===<<<<<nmtknikn1",
                                items?.freightId
                              );
                              const { id, checked } = e.target;
                              console.log("===<<<<<nmtknikn1", id, checked);
                              let x = isCheckTrips;
                              if (checked) {
                                x[items?.freightId] = items;
                                setIsCheckTripsLength(isCheckTripsLength + 1);
                              } else {
                                delete x[items?.freightId];
                                setIsCheckTripsLength(isCheckTripsLength - 1);
                              }
                              setIsCheckTrips(x);
                            }}
                            disabled={
                              Object.values(isCheckTrips) == 0
                                ? false
                                : items.fleetId !=
                                  Object.values(isCheckTrips)?.[0]?.fleetId
                            }
                          />
                        </div>
                      </td>

                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                        style={{ width: "10%" }}
                      >
                        {items?.lrNo}
                      </td>
                      {/* <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                      >
                        {items?.Trip?.Fleet?.regNumber}
                      </td> */}
                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                      >
                        {items?.Fleet?.regNumber}
                      </td>

                      {/* <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items?.Trip);
                        }}
                      >
                        {items?.tonnage}
                      </td> */}

                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                      >
                        {/* {items?.fromAddress?.address},{" "} */}
                        {items?.fromAddress?.cityName}
                      </td>
                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                      >
                        {/* {items?.toAddress?.address},{" "} */}
                        {items?.toAddress?.cityName}
                      </td>
                      {["1", "2"].includes(tripType) && (
                        <td className="p-3">
                          {items?.podUrl ? (
                            <button
                              style={{
                                border: 0,
                                backgroundColor: "#fff",
                              }}
                              onClick={() => {
                                // setDownloadLoader2(true);
                                // setLoaderInvoiceId(items.invoiceId);
                                dispatch(
                                  getSignedUrlApiAction(
                                    { key: items?.podUrl },
                                    (res) => {
                                      // setDownloadLoader2(false);
                                      // setLoaderInvoiceId("");
                                      if (res.status == 200) {
                                        window.open(res?.data?.data);
                                      }
                                    }
                                  )
                                );
                              }}
                            >
                              <SvgImage name="viewInvoices" />
                            </button>
                          ) : (
                            <SvgImage
                              name="cancelOrder"
                              height="21"
                              width="21"
                            />
                          )}
                        </td>
                      )}

                      {tripType == "2" && (
                        <td className="p-3">
                          {items?.invoice?.isActive ? (
                            <button
                              style={{
                                border: 0,
                                backgroundColor: "#fff",
                              }}
                              onClick={() => {
                                // setDownloadLoader2(true);
                                // setLoaderInvoiceId(items.invoiceId);
                                dispatch(
                                  getSignedUrlApiAction(
                                    { key: items?.invoice?.Ledger?.receipt },
                                    (res) => {
                                      // setDownloadLoader2(false);
                                      // setLoaderInvoiceId("");
                                      if (res.status == 200) {
                                        window.open(res?.data?.data);
                                      }
                                    }
                                  )
                                );
                              }}
                            >
                              <SvgImage name="viewInvoices" />
                            </button>
                          ) : (
                            <SvgImage
                              name="cancelOrder"
                              height="21"
                              width="21"
                            />
                          )}
                        </td>
                      )}
                      {tripType == "2" && (
                        <td className="p-3">
                          {items?.invoice?.cleared ? (
                            <SvgImage
                              name="savingsIcon"
                              height="21"
                              width="21"
                            />
                          ) : (
                            <SvgImage
                              name="cancelOrder"
                              height="21"
                              width="21"
                            />
                          )}
                        </td>
                      )}

                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                      >
                        {items?.weight}
                      </td>
                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items?.Trip);
                        }}
                      >
                        {items?.freightAmount}
                      </td>
                      {tripType == null && (
                        <td
                          className="p-3 align-items-center"
                          onClick={() => {
                            onSelect(items?.Trip);
                          }}
                        >
                          <div
                            className="statusBtn"
                            style={{
                              backgroundColor:
                                items?.status == "2"
                                  ? "#09bd7b"
                                  : items?.status == "1"
                                  ? "red"
                                  : "#0d6efd",
                            }}
                          >
                            {items?.status == "2"
                              ? "Completed"
                              : items?.status == "1"
                              ? "Running"
                              : "Not Started"}
                          </div>
                        </td>
                      )}
                    </tr>
                  </>
                );
              })}
            </tbody>
          )}

          {/* </div> */}
        </Table>
        {/* {
          olderHistoryStatus?
          <div className="olderTransactions-div" onClick={handleOlderTransactions}>
          Older History
          </div>:
        <></>
        } */}
        {pageCount > 1 ? (
          <div className="reactPagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <TripModalV2
        toggle={toggleModal}
        modal={open2}
        setModal={toggleModal}
        location={"/Customers/" + customerId}
        isEdit={false}
        freightId={""}
      />

      <ActionModal
        isOpen={openAction}
        modal={openAction}
        setModal={toggleActionOpen}
        location={"/Customers/" + customerId}
        toggleModal={toggleActionOpen}
        method={method}
        handleClose={toggleActionOpen}
        onPress={() => {
          const data = {
            tripId: isCheckTrips,
            action: method == "Delete" ? 1 : "",
          };
          console.log("theEndIsHere");
          dispatch(
            tripBulkActions(data, (res) => {
              console.log("theEndIsHere2", res);
              if (res?.data?.type == "success") {
                console.log("theEndIsHere3");
                // dispatch(getTrips({ search: "", limit: 3, offset: 0 }));

                toggleActionOpen();
                dispatch(
                  getLRList({
                    customerId: customerId,
                    offset: offset,
                    fleetId: fleetId,
                    from: from || "",
                    to: to || "",
                    sortBy: "",
                    isActive: tripType,
                    type: activeTab ? 1 : 0,
                  })
                );
              }
            })
          );
        }}
      />
      <EditTripV2
        toggle={toggleModal5}
        modal={open5}
        setModal={setOpen5}
        tripId={isCheckTrips}
        tripData={
          freightList?.find((li) => li?.Trip?.tripId == isCheckTrips[0])?.Trip
        }
      />
      <EditFreight
        toggle={toggleModalEditFreight}
        freight={editFreight}
        freightDetails={itemTemp}
        // setFreight={setFreight}
      />
      {/* <AddFreight
        toggle={addFreight}
        freight={freight}
        tripId={itemTemp?.tripId}
        location={"/trips/" + id}
        // setFreight={setFreight}
      /> */}
      <CreateLRModal
        toggle={createLR}
        modal={modal}
        setModal={setModal}
        location={"/lr/"}
        customerId={customerId}
      />
    </div>
  );
}
export default TripsComponent;
