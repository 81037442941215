import React from "react";
import moment from "moment";
import "./index.css";
function AllTransactionDetailsCard(props) {
  const { description, amount, date, merchantName,time } = props;

  const descrip = description == null ? 'NO DESCRIPTION' : description

  return (
    <div>
      <div className="row AllTransactionBox">
        <div className="col-5 AllTransactionDetailsCard1">
          {/* <div>{merchantName}</div> */}
          <div className=" AllTransactionDetailsCardRemarks">{descrip}</div>
        </div>

        <div className="col-4 AllTransactionDetailsCard2"
        style={{
          color:merchantName==="DEBIT"?"#FE2950":
          merchantName==="debit"?"#FE2950":"#09BD7B"
        }}>{"₹" + amount.toFixed(2)}</div>
        <div className="col-2 AllTransactionDetailsCard3">{date}</div>
      </div>
      <div className="AllTransactionDetailsTime">{time}</div>
      <div className="AllTransactionEndBar"></div>
    </div>
  );
}
export default AllTransactionDetailsCard;
