import React, { useState } from "react";
import { Button, Modal, ModalBody, FormGroup, Form } from "reactstrap";
import "./index.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { storeMedia } from "../../../../services/Store/Actions/appActions";
import { addFleet } from "../../../../services/Store/Actions/fleetActions";
import { getVehicleListAction } from "../../../../services/Store/Actions/manageVehicleAction";

function AddVehicleV2(props) {
  const { toggle, freight, setFreight } = props;
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [displayName, setDisplayName] = useState([]);
  const closeBtn = (
    <button
      className="close"
      style={{
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        backgroundColor: "#FFF",
        borderColor: "#2E7C7B",
        display: "flex",
        justifyContent: "space-evenly",
      }}
      onClick={toggle}
      type="button"
    >
      <div className="x">X</div>
    </button>
  );
  const validationSchema = Yup.object().shape({
    vrn: Yup.string().required("Required"),
  });
  const handleCloseFreight = () => {
    setFreight(!freight);
  };

  return (
    <div>
      <Modal
        isOpen={freight}
        onHide={handleCloseFreight}
        className="all-transaction-main-modal"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px" }}
        >
          <div className="PGCollectionBox1">
            <div className=/*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction">
              <div className="closeEffect" onClick={toggle}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
            </div>
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Add Vehicle
            </div>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                vrn: "",
              }}
              onSubmit={(values) => {
                dispatch(
                  addFleet(
                    {
                      vehicleNumber: values?.vrn,
                    },
                    (response) => {
                      // actions.setSubmitting(false);
                      console.log("respppppppppp", response);
                      if (response?.type === "success") {
                        dispatch(
                          getVehicleListAction({
                            offset: "",
                            minKyc: "",
                            sortAs: "",
                            sortBy: "",
                            limit: 10,
                          })
                        );
                        props.toggle();
                      }
                    }
                  )
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "50px", padding: "0 75px 0 50px" }}
                >
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "30px",
                    }}
                  >
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Vehicle Number:
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          type="text"
                          id="vrn"
                          className={`form-control-1 ${
                            touched.vrn && errors.vrn ? "is-invalid" : ""
                          }`}
                          name="vrn"
                          onChange={handleChange("vrn")}
                          value={values.vrn}
                          // onBlur={() => {
                          //   dispatch(
                          //     sendAnalitics("Enter_Amt_Web", {
                          //       upi_netBank_amount: values.amount,
                          //     })
                          //   );
                          //   handleBlur("amount");
                          // }}
                          touched={touched.vrn}
                          errors={errors.vrn}
                          placeholder="Registration Number"
                        />
                      </div>
                    </FormGroup>
                    {/* <FormGroup style={{ marginRight: "90px" }}>
                      <label for="vrn" className="heading3 semiBold">
                        Vehicle Type:
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          type="text"
                          id="vrn"
                          className={`form-control-1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          name="vrn"
                          // onChange={handleChange("amount")}
                          value={values.amount}
                          // onBlur={() => {
                          //   dispatch(
                          //     sendAnalitics("Enter_Amt_Web", {
                          //       upi_netBank_amount: values.amount,
                          //     })
                          //   );
                          //   handleBlur("amount");
                          // }}
                          touched={touched.amount}
                          errors={errors.amount}
                          placeholder="Enter Name"
                        />
                      </div>
                    </FormGroup> */}
                  </FormGroup>

                  {/* <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "25px",
                    }}
                  >
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="vrn" className="heading3 semiBold">
                        RC Front
                        <p
                          className="heading3 Regular"
                          style={{ margin: "10px 0 5px" }}
                        >
                          Upload Document
                        </p>
                      </label>
                      {!status ? (
                        <Button
                          className="add-attach-for-fastag-linked-vehicle-new"
                          type="button"
                        >
                          <SvgImage
                            name="AddAttachment"
                            height="25"
                            width="25"
                          />
                          <input
                            type="file"
                            // multiple
                            // accept="image/*"
                            accept="image/*"
                            className="add-lr-attachment"
                            onChange={(e) => {
                              const img = e?.target.files[0];
                              console.log("image", img?.name);
                              setStatus(true);
                              setDisplayName(
                                (prev) => [{ name: img?.name }]
                                //  [
                                //   ...prev,
                                //   { name: img?.name },
                                // ]
                              );

                              const tempArray = Object.assign([], values.docs);
                              console.log("sel", tempArray);

                              if (img == null) {
                                //clear image
                                setFieldValue("pickedImage", "", true);
                                //for now  only one supported
                              } else {
                                console.log("upload", {
                                  img: img,
                                  uploadType: "aadharFront",
                                  isRes: true,
                                });

                                dispatch(
                                  storeMedia(
                                    {
                                      img: img,
                                      uploadType: "aadharFront",
                                      isRes: false,
                                    },
                                    (res) => {
                                      if (res?.url) {
                                        console.log("opt", res);
                                        tempArray.push(res?.data?.Location);
                                        setFieldValue(
                                          "pickedImage",
                                          res?.url,
                                          true
                                        );
                                        // setFieldValue('docs', tempArray, true);
                                        //for now  only one supported
                                        setFieldValue(
                                          "docs",
                                          [res?.data?.Location],
                                          true
                                        );
                                      }
                                    }
                                  )
                                );
                              }
                            }}
                          />
                          <div
                            style={{
                              margin: "0px -16px 2px -614px",
                              color: "red",
                            }}
                          >
                            {errors.vehicleRcPhoto && touched.vehicleRcPhoto ? (
                              <div>*{errors.vehicleRcPhoto}</div>
                            ) : null}
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <div className="selected-img">
                        {status ? (
                          displayName?.map((d) => {
                            return (
                              <div className="row">
                                <div className="col-10">
                                  <p
                                    style={{
                                      color: "black",
                                      margin: "5px 0 0",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={() => {
                                    setStatus(false);
                                  }}
                                  // style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage
                                    name="CloseButtonLogo"
                                    height="25"
                                    width="25"
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="vrn" className="heading3 semiBold">
                        RC Back
                        <p
                          className="heading3 Regular"
                          style={{ margin: "10px 0 5px" }}
                        >
                          Upload Document
                        </p>
                      </label>
                      {!status ? (
                        <Button
                          className="add-attach-for-fastag-linked-vehicle-new"
                          type="button"
                        >
                          <SvgImage
                            name="AddAttachment"
                            height="25"
                            width="25"
                          />
                          <input
                            type="file"
                            // multiple
                            // accept="image/*"
                            accept="image/*"
                            className="add-lr-attachment"
                            onChange={(e) => {
                              const img = e?.target.files[0];
                              console.log("image", img?.name);
                              setStatus(true);
                              setDisplayName(
                                (prev) => [{ name: img?.name }]
                                //  [
                                //   ...prev,
                                //   { name: img?.name },
                                // ]
                              );

                              const tempArray = Object.assign([], values.docs);
                              console.log("sel", tempArray);

                              if (img == null) {
                                //clear image
                                setFieldValue("pickedImage", "", true);
                                //for now  only one supported
                              } else {
                                console.log("upload", {
                                  img: img,
                                  uploadType: "aadharFront",
                                  isRes: true,
                                });

                                dispatch(
                                  storeMedia(
                                    {
                                      img: img,
                                      uploadType: "aadharFront",
                                      isRes: false,
                                    },
                                    (res) => {
                                      if (res?.url) {
                                        console.log("opt", res);
                                        tempArray.push(res?.data?.Location);
                                        setFieldValue(
                                          "pickedImage",
                                          res?.url,
                                          true
                                        );
                                        // setFieldValue('docs', tempArray, true);
                                        //for now  only one supported
                                        setFieldValue(
                                          "docs",
                                          [res?.data?.Location],
                                          true
                                        );
                                      }
                                    }
                                  )
                                );
                              }
                            }}
                          />
                          <div
                            style={{
                              margin: "0px -16px 2px -614px",
                              color: "red",
                            }}
                          >
                            {errors.vehicleRcPhoto && touched.vehicleRcPhoto ? (
                              <div>*{errors.vehicleRcPhoto}</div>
                            ) : null}
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <div className="selected-img">
                        {status ? (
                          displayName?.map((d) => {
                            return (
                              <div className="row">
                                <div className="col-10">
                                  <p
                                    style={{
                                      color: "black",
                                      margin: "5px 0 0",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={() => {
                                    setStatus(false);
                                  }}
                                  // style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage
                                    name="CloseButtonLogo"
                                    height="25"
                                    width="25"
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "25px",
                    }}
                  >
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="vrn" className="heading3 semiBold">
                        Insurance Page 1
                        <p
                          className="heading3 Regular"
                          style={{ margin: "10px 0 5px" }}
                        >
                          Upload Document
                        </p>
                      </label>
                      {!status ? (
                        <Button
                          className="add-attach-for-fastag-linked-vehicle-new"
                          type="button"
                        >
                          <SvgImage
                            name="AddAttachment"
                            height="25"
                            width="25"
                          />
                          <input
                            type="file"
                            // multiple
                            // accept="image/*"
                            accept="image/*"
                            className="add-lr-attachment"
                            onChange={(e) => {
                              const img = e?.target.files[0];
                              console.log("image", img?.name);
                              setStatus(true);
                              setDisplayName(
                                (prev) => [{ name: img?.name }]
                                //  [
                                //   ...prev,
                                //   { name: img?.name },
                                // ]
                              );

                              const tempArray = Object.assign([], values.docs);
                              console.log("sel", tempArray);

                              if (img == null) {
                                //clear image
                                setFieldValue("pickedImage", "", true);
                                //for now  only one supported
                              } else {
                                console.log("upload", {
                                  img: img,
                                  uploadType: "aadharFront",
                                  isRes: true,
                                });

                                dispatch(
                                  storeMedia(
                                    {
                                      img: img,
                                      uploadType: "aadharFront",
                                      isRes: false,
                                    },
                                    (res) => {
                                      if (res?.url) {
                                        console.log("opt", res);
                                        tempArray.push(res?.data?.Location);
                                        setFieldValue(
                                          "pickedImage",
                                          res?.url,
                                          true
                                        );
                                        // setFieldValue('docs', tempArray, true);
                                        //for now  only one supported
                                        setFieldValue(
                                          "docs",
                                          [res?.data?.Location],
                                          true
                                        );
                                      }
                                    }
                                  )
                                );
                              }
                            }}
                          />
                          <div
                            style={{
                              margin: "0px -16px 2px -614px",
                              color: "red",
                            }}
                          >
                            {errors.vehicleRcPhoto && touched.vehicleRcPhoto ? (
                              <div>*{errors.vehicleRcPhoto}</div>
                            ) : null}
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <div className="selected-img">
                        {status ? (
                          displayName?.map((d) => {
                            return (
                              <div className="row">
                                <div className="col-10">
                                  <p
                                    style={{
                                      color: "black",
                                      margin: "5px 0 0",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={() => {
                                    setStatus(false);
                                  }}
                                  // style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage
                                    name="CloseButtonLogo"
                                    height="25"
                                    width="25"
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="vrn" className="heading3 semiBold">
                        Insurance Page 2
                        <p
                          className="heading3 Regular"
                          style={{ margin: "10px 0 5px" }}
                        >
                          Upload Document
                        </p>
                      </label>
                      {!status ? (
                        <Button
                          className="add-attach-for-fastag-linked-vehicle-new"
                          type="button"
                        >
                          <SvgImage
                            name="AddAttachment"
                            height="25"
                            width="25"
                          />
                          <input
                            type="file"
                            // multiple
                            // accept="image/*"
                            accept="image/*"
                            className="add-lr-attachment"
                            onChange={(e) => {
                              const img = e?.target.files[0];
                              console.log("image", img?.name);
                              setStatus(true);
                              setDisplayName(
                                (prev) => [{ name: img?.name }]
                                //  [
                                //   ...prev,
                                //   { name: img?.name },
                                // ]
                              );

                              const tempArray = Object.assign([], values.docs);
                              console.log("sel", tempArray);

                              if (img == null) {
                                //clear image
                                setFieldValue("pickedImage", "", true);
                                //for now  only one supported
                              } else {
                                console.log("upload", {
                                  img: img,
                                  uploadType: "aadharFront",
                                  isRes: true,
                                });

                                dispatch(
                                  storeMedia(
                                    {
                                      img: img,
                                      uploadType: "aadharFront",
                                      isRes: false,
                                    },
                                    (res) => {
                                      if (res?.url) {
                                        console.log("opt", res);
                                        tempArray.push(res?.data?.Location);
                                        setFieldValue(
                                          "pickedImage",
                                          res?.url,
                                          true
                                        );
                                        // setFieldValue('docs', tempArray, true);
                                        //for now  only one supported
                                        setFieldValue(
                                          "docs",
                                          [res?.data?.Location],
                                          true
                                        );
                                      }
                                    }
                                  )
                                );
                              }
                            }}
                          />
                          <div
                            style={{
                              margin: "0px -16px 2px -614px",
                              color: "red",
                            }}
                          >
                            {errors.vehicleRcPhoto && touched.vehicleRcPhoto ? (
                              <div>*{errors.vehicleRcPhoto}</div>
                            ) : null}
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <div className="selected-img">
                        {status ? (
                          displayName?.map((d) => {
                            return (
                              <div className="row">
                                <div className="col-10">
                                  <p
                                    style={{
                                      color: "black",
                                      margin: "5px 0 0",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={() => {
                                    setStatus(false);
                                  }}
                                  // style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage
                                    name="CloseButtonLogo"
                                    height="25"
                                    width="25"
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </FormGroup>
                  </FormGroup> */}
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "25px",
                      marginTop: "100px",
                    }}
                  >
                    <Button
                      style={{ width: "25%" }}
                      className="notGenerateOpt text4 Medium btn btn-secondary"
                      onClick={props.toggle}
                    >
                      Back
                    </Button>
                    <Button
                      style={{ width: "25%" }}
                      className="generateOtp text3 Medium btn btn-secondary"
                      onClick={""}
                    >
                      Next
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>

      {/* <AddVehicle1
        toggle = {toggleAddVehicle1}
        modal ={vehicle}
      //   className={className}
      /> */}
      {/* <AddDriverV2
         toggle = {toggleAddDriverV2}
         modal  = {driverV2}
      />
      <FreightDetails
          toggle = {toggleFreightDetails}
          modal  = {fr_Details}
      /> */}
    </div>
  );
}

export default AddVehicleV2;
