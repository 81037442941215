import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, Table } from "reactstrap";
import "./index.css";
import SvgImage from "../../../Icons/Icons";
import SearchBar from "../../../subComponents/searchbar";
import { getVendorAction } from "../../../../services/Store/Actions/beneficiaryAction";

function SelectVendorModal(props) {
  const { open, onHide, toggleModal, handleClose, path, onClickItem,setSearch,search,vendorType } = props;
  const [offset, setOffset] = useState(0);
  const { vendorList, vendor } = useSelector((state) => state?.beneficiary);
  const count = vendor?.count;
  const dispatch = useDispatch();
  console.log("vendorListvendorList",vendor);
  function findSearch() {}
  const handlePrev = () => {
    if (offset > 0) {
      setOffset(offset - 4);
    }
  };
  const handleNext = () => {
    if (offset + 4 < count) {
      setOffset(offset + 4);
      console.log("vendorList?.length", vendor?.rows?.length, offset);
      if (vendor?.rows?.length == offset + 4) {
        dispatch(
          getVendorAction({
            isLogipeVendor: path == "0" || path == "1" || path == "2" ? "1" : "0",
            limit: 4,
            offset: offset + 4,
            search: search,
            // vendorType : vendorType ? vendorType : 1
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(
      getVendorAction({
        isLogipeVendor: path == "0" || path == "1" || path == "2" ? "1" : "0",
        limit: 4,
        offset: 0,
        search: search,
        // vendorType : vendorType ? vendorType : 1
      })
    );
  },[search])
  return (
    <Modal
      isOpen={open}
      onHide={onHide}
      toggle={toggleModal}
      className="Select-Vendor-Modal settingModalMargin"
      //   toggle={toggle}
      aria-labelledby="myModalLabel2"
    >
      <div
        className="closeRemove"
        onClick={() => {
          // Router.reload();
          handleClose();
        }}
      >
        <SvgImage name="CloseButtonLogo" />
      </div>
      <div className="padding24" style={{ textAlign: "center" }}>
        <div className="ENT-Security-Pin-Title">Select vendor</div>
        <div className="FUllListSearchBar">
          <SearchBar
            placeholder="Search for vendors"
            height="46"
            value={search}
            setSearch={setSearch}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            findSearch={findSearch}
          />
        </div>
        <div className="table-for-Vendor mt-0">
          <Table className="vehicleAssignedHistory">
            <thead className="th" style={{ zIndex: 99 }}>
              <tr className="table-header-vendor-details">
                <th className="vendorList-Header p-2 ps-4">Company / Vendor Name</th>
                <th className="vendorList-Header p-2 ps-4">Phone No.</th>
              </tr>
            </thead>
            {/* <div className="tbodyVehicle-div"> */}
            <tbody className="tbodyVendor" style={{ textDecoration: "none" }}>
              {vendor?.rows?.map((items, i) => {
                console.log("-----------", i, items);
                return (
                  <>
                    {i - offset < 4 && i - offset >= 0 ? (
                      <tr
                        className="table-row-vendor-details"
                        onClick={() => {
                          onClickItem(items);
                        }}
                      >
                        <td
                          className="pb-0 pt-0"
                          style={{ verticalAlign: "middle" }}
                        >
                          {items?.name || "---"}
                        </td>
                        <td
                          className="pb-0 pt-0"
                          style={{ verticalAlign: "middle" }}
                        >
                          {items?.phone || "---"}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </tbody>
            {/* </div> */}
          </Table>
          {/* {
            olderHistoryStatus?
            <div className="olderTransactions-div" onClick={handleOlderTransactions}>
            Older History
            </div>:
          <></>
          } */}
          <div className="row margin0 padding16">
            <div
              className="col-2"
              style={{
                textAlign: "right",
                paddingTop: "0.1rem",
                cursor: offset > 0 ? "pointer" : "no-drop",
                opacity: offset > 0 ? "1" : "0.5",
              }}
              onClick={handlePrev}
            >
              <SvgImage name="PrevArrow" />
            </div>
            <div
              className="col-2 greenFont semiBold heading4 padding0L"
              style={{
                textAlign: "right",
                paddingTop: "0.3rem",
                cursor: offset > 0 ? "pointer" : "no-drop",
                opacity: offset > 0 ? "1" : "0.5",
              }}
              onClick={handlePrev}
            >
              Previous
            </div>
            <div className="col-4 row margin0 justify-content-center p-0">
              <div
                className="greenBoxCount Medium heading4 col-4"
                style={{
                  width: "2rem",
                  height: "2rem",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {offset / 4 + 1}
              </div>
              <div
                className=" col-4 padding0R"
                style={{ paddingTop: "0.3rem", textAlign: "right" }}
              >
                of{" "}
                {count % 4 == 0
                  ? Math.floor(count / 4)
                  : Math.floor(count / 4 + 1)}
              </div>
            </div>
            <div
              className="col-2 greenFont semiBold heading4 padding0R"
              style={{
                textAlign: "left",
                paddingTop: "0.3rem",
                cursor: count - offset > 4 ? "pointer" : "no-drop",
                opacity: count - offset > 4 ? "1" : "0.5",
              }}
              onClick={handleNext}
            >
              Next
            </div>
            <div
              className="col-2 padding0L"
              style={{
                textAlign: "left",
                marginLeft: "-1rem",
                paddingTop: "0.1rem",
                cursor: count - offset > 4 ? "pointer" : "no-drop",
                opacity: count - offset > 4 ? "1" : "0.5",
              }}
              onClick={handleNext}
            >
              <SvgImage name="NextArrow" />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SelectVendorModal;
