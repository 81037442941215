import React, { useState, useEffect } from "react";
import "./index.css";
import { Routes, Route, Navigate, Switch, Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginScreenCreateAccount from "./LoginScreenCreateAccount";
import { useSelector,useDispatch } from "react-redux";
import LoginScreenMobile from "./LoginScreenMobile";
import { sendAnalitics } from "../services/Store/Actions/appActions";
import NewDesktopLogin from "./NewDesktopLogin";
import { storeDataToAsyncStorage } from "../services/Utils/functions";
import axios from "axios";


function LoginScreen(props) {
  console.log("Prpsdjfd", props);
  const [btn, setBtn] = useState(true);
  const [phoneNo, setPhoneNo] = useState("");
  const dispatch = useDispatch();
  useEffect (()=>{
    dispatch(sendAnalitics("Login_page"));
  },[]);
  console.log("HERE", props.authUser);
  let user = sessionStorage.getItem("_user");
  console.log(user)
  if (user !== "null" && user!=undefined) {
    return <Navigate to="/home" />;
  }
  return (
    <div className="Login-Screen_box">
      <div className="row" id="loginDesktop">
        <NewDesktopLogin />
      </div>
      <div id="loginMobile">
        <LoginScreenMobile />
      </div>
    </div>
  );
}
export default LoginScreen;
