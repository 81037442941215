import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Button,
  Col,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import SvgImage from "../../Icons/Icons";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getVirtuAccount } from "../../../services/Store/Actions/beneficiaryAction";
import NEFTComponent from "../../Homepage/SubComponents/NEFTComponent";
import UpiAddBalance from "../../Homepage/SubComponents/upiAddBalance";

import "./index.css";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";
import QRAddBalance from "../../Homepage/SubComponents/QRAddBalance";

function LogipeAccountAddBalanceModal(props) {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    proceed,
    nextSection,
    ownerLogipeBalance,
    copyIcon,
    setCopyIcon,
    copyIconIfsc,
    setCopyIconIfsc,
  } = props;

  //console.log ("in the neft component-----------",ownerLogipeBalance);
  const dispatch = useDispatch();
  const { virtuAccount } = useSelector((state) => state?.beneficiary);

  const accountStatus = virtuAccount == null ? 0 : 1;
  //console.log("acc---->>>>",virtuAccount)

  useEffect(() => {
    dispatch(getVirtuAccount());
  }, []);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className={"positons settingModalMargin"}
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-content" style={{width:"530px", overflowY:"scroll", overflowX:"hidden"}} >
        <div className="modal-head-part">
          <p className="Add-balance-title">{title} - Add Balance</p>
          <Button
            type="button"
            className="close-btn"
            onClick={()=>{
              dispatch(sendAnalitics("Cut_MA_Web"));
              toggleModal();
            }}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          {btn === 0 ? (
            <NEFTComponent
              copyIcon={copyIcon}
              setCopyIcon={setCopyIcon}
              copyIconIfsc={copyIconIfsc}
              setCopyIconIfsc={setCopyIconIfsc}
              virtuAccount={virtuAccount}
              ownerLogipeBalance={ownerLogipeBalance}
              handleClick={handleClick}
              accountStatus={accountStatus}
              btn={btn}
              handleClose={handleClose}
            />
          ) : 
          btn === 1 ? (
            <QRAddBalance
              copyIcon={copyIcon}
              setCopyIcon={setCopyIcon}
              handleClick={handleClick}
              btn={btn}
              ownerLogipeBalance={ownerLogipeBalance}
              />
          )
          :(
            <UpiAddBalance
              ownerLogipeBalance={ownerLogipeBalance}
              handleClick={handleClick}
              btn={btn}
            />
          )}

          {/* <div className="space-div"></div> */}
        </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default LogipeAccountAddBalanceModal;
