import {
  GET_VIRTU_ACCOUNT,
  GET_VIRTU_ACCOUNT_SUCCESS,
  BANK_DIRECT_TRANSFER,
  BANK_DIRECT_TRANSFER_SUCCESS,
  BANK_TRANSFER_TO_ADDED_BENEFICIARY,
  BANK_TRANSFER_TO_ADDED_BENEFICIARY_SUCCESS,
  BANK_TRANSFER_TO_ADDED_BENEFICIARY_ERROR,
  BANK_DIRECT_TRANSFER_ERROR,
  BANK_TRANSFER_BENEFICIARY_ADD,
  BANK_TRANSFER_BENEFICIARY_ADD_SUCCESS,
  BANK_TRANSFER_BENEFICIARY_ERROR,
  GET_BENEFICIARY,
  GET_BENEFICIARY_SUCCESS,
  GET_COLLECTIONS,
  GET_COLLECTIONS_SUCCESS,
  GET_SETTLEMENTS,
  GET_SETTLEMENTS_SUCCESS,
  CLEAN_COLLECTIONS,
  CLEAN_BENEFICIARY_REDUCER,
  CREATE_VENDOR,
  CREATE_VENDOR_SUCCESS,
  GET_LOGIPE_VENDOR_DETAILS,
  GET_LOGIPE_VENDOR_DETAILS_SUCCESS,
  EDIT_VENDOR,
  EDIT_VENDOR_SUCCESS,
  GET_VENDOR,
  GET_VENDOR_SUCCESS,
  VENDOR_TRANSACTION,
  VENDOR_TRANSACTION_SUCCESS,
  VENDOR_UPLOAD_BILL,
  VENDOR_UPLOAD_BILL_SUCCESS,
  VENDOR_GET_SIGNED_URL,
  VENDOR_GET_SIGNED_URL_SUCCESS,
  GENERATE_STATEMENT_FOR_VENDOR,
  GENERATE_STATEMENT_FOR_VENDOR_SUCCESS,
  TRANSFER_TO_VENDOR,
  TRANSFER_TO_VENDOR_SUCCESS,
  CLEAN_VENDOR_LIST,
  CLEAN_VENDOR_TRANSACTION,
  GET_VENDOR_DETAILS,
  GET_VENDOR_DETAILS_SUCCESS
} from "../storeTypes";

export const getVirtuAccount = () => {
  return {
    type: GET_VIRTU_ACCOUNT,
  };
};

export const getVirtuAccountSuccess = (payload) => {
  return {
    type: GET_VIRTU_ACCOUNT_SUCCESS,
    payload,
  };
};
export function bankDirectTransfer(obj, callBack) {
  return {
    type: BANK_DIRECT_TRANSFER,
    payload: obj,
    callBack,
  };
}
export function bankDirectTransferSuccess(data) {
  return { type: BANK_DIRECT_TRANSFER_SUCCESS, payload: data };
}


export function bankDirectTransferError(err) {
  return { type: BANK_DIRECT_TRANSFER_ERROR, payload: err };
}


export function bankBeneficiaryTransfer(obj, callBack){
  console.log("bankBeneficiaryTransfer");
  return {
    type: BANK_TRANSFER_TO_ADDED_BENEFICIARY,
    payload: obj,
    callBack,
  };
}

export function bankBeneficiaryTransferSuccess(data) {
  console.log("bankBeneficiaryTransfer- success");
  return { type: BANK_TRANSFER_TO_ADDED_BENEFICIARY_SUCCESS, payload: data };
}

export function bankTransferToBeneficiaryError(err) {
  console.log("bankBeneficiaryTransfer- error");
  return { type: BANK_TRANSFER_TO_ADDED_BENEFICIARY_ERROR, payload: err };
}

export function bankTransferBeneficiaryAdd(obj, callBack) {
  return {
    type: BANK_TRANSFER_BENEFICIARY_ADD,
    payload: obj,
    callBack,
  };
}
export function bankTransferBeneficiaryAddSuccess(data) {
  return {type: BANK_TRANSFER_BENEFICIARY_ADD_SUCCESS, payload: data};
}

export function bankTransferBeneficiaryError(err) {
  return {type: BANK_TRANSFER_BENEFICIARY_ERROR, payload: err};
}

export const getBeneficiary = () => {
  return {
    type: GET_BENEFICIARY,
  };
};
export const getBeneficiarySuccess = payload => {
  return {
    type: GET_BENEFICIARY_SUCCESS,
    payload,
  };
};


export function getCollections(data) {

  console.log("action-> all cards", data);
  return {
    type: GET_COLLECTIONS,
    payload:data
  };
}

export function getCollectionsSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: GET_COLLECTIONS_SUCCESS, payload: data };
}

export function getSettlements(data) {
  console.log("action-> all cards", data);
  return {
    type: GET_SETTLEMENTS,
    payload:data
  };
}

export function getSettlementsSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: GET_SETTLEMENTS_SUCCESS, payload: data };
}

export const cleanCollection = () => {
  return {
    type: CLEAN_COLLECTIONS,
  };
};

export const cleanBeneficiaryLoader = () => {
  return {
    type: CLEAN_BENEFICIARY_REDUCER,
  };
};

export function createVendorAction(data,callBack) {
  console.log("createVendorAction", data);
  return {
    type: CREATE_VENDOR,
    payload:data,
    callBack
  };
}

export function createVendorSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: CREATE_VENDOR_SUCCESS, payload: data };
}
export function getLogipeVendorDetailsAction(data) {
  console.log("action-> all cards", data);
  return {
    type: GET_LOGIPE_VENDOR_DETAILS,
    payload:data
  };
}

export function getLogipeVendorDetailsSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: GET_LOGIPE_VENDOR_DETAILS_SUCCESS, payload: data };
}
export function editVendorAciton(data,callBack) {
  console.log("action-> all cards", data);
  return {
    type: EDIT_VENDOR,
    payload:data,
    callBack
  };
}

export function editVendorSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: EDIT_VENDOR_SUCCESS, payload: data };
}
export function getVendorAction(data,callBack=()=>{}) {
  console.log("action-> getVendorAction", data);
  return {
    type: GET_VENDOR,
    payload:data,
    callBack
  };
}

export function getVendorSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: GET_VENDOR_SUCCESS, payload: data };
}
export function vendorTransactionAction(data) {
  console.log("action-> all cards", data);
  return {
    type: VENDOR_TRANSACTION,
    payload:data
  };
}

export function vendorTransactionSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: VENDOR_TRANSACTION_SUCCESS, payload: data };
}
export function vendorUploadBillAction(data,callBack) {
  console.log("action-> all cards", data);
  return {
    type: VENDOR_UPLOAD_BILL,
    payload:data,
    callBack
  };
}

export function vendorUploadBillSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: VENDOR_UPLOAD_BILL_SUCCESS, payload: data };
}
export function vendorGetSignedUrlAction(data,callBack) {
  console.log("action-> all cards", data);
  return {
    type: VENDOR_GET_SIGNED_URL,
    payload:data,
    callBack
  };
}

export function vendorGetSignedUrlSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: VENDOR_GET_SIGNED_URL_SUCCESS, payload: data };
}
export function generateStatementForVendorAction(data,callBack) {
  console.log("action-> all cards", data);
  return {
    type: GENERATE_STATEMENT_FOR_VENDOR,
    payload:data,
    callBack
  };
}

export function generateStatementForVendorSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: GENERATE_STATEMENT_FOR_VENDOR_SUCCESS, payload: data };
}
export function transferTorVendorAction(data,callBack) {
  console.log("action-> all cards", data);
  return {
    type: TRANSFER_TO_VENDOR,
    payload:data,
    callBack
  };
}

export function transferTorVendorSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: TRANSFER_TO_VENDOR_SUCCESS, payload: data };
}
export function cleanVendorList() {
  return { type: CLEAN_VENDOR_LIST,};
}
export function cleanVendorTransaction() {
  return { type: CLEAN_VENDOR_TRANSACTION };
}
export function getVendorDetailsAction(data) {
  return {
    type: GET_VENDOR_DETAILS,
    payload:data
  };
}

export function getVendorDetailsSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: GET_VENDOR_DETAILS_SUCCESS, payload: data };
}