import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import PinInput from "react-pin-input";
import { Formik } from "formik";
import SvgImage from "../../Icons/Icons";
import { transferWalletTransferAmount } from "../../../services/Store/Actions/cardWalletAction";
import { transferFastagTransferAmount } from "../../../services/Store/Actions/fastagAction";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import * as Yup from "yup";
import { redeemCashbackAction } from "../../../services/Store/Actions/cashbackAction";
import { transferToCard } from "../../../services/Store/Actions/manageCardActions";
import {
  getBalanceDetails,
  getWalletBalAction,
} from "../../../services/Store/Actions/LogipeCardActions";
import SearchBar from "../../subComponents/searchbar";
import {
  clearAllDrivers,
  clearAllDriversByVrn,
  getDriverList,
  searchByVrnAction,
  getAdvanceDriverList,
  cleanAdvanceDriverList
} from "../../../services/Store/Actions/driverslistActions";
import { M2pUserDetailsAction } from "../../../services/Store/Actions/m2pAction";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";
import ClipLoader from "react-spinners/ClipLoader";
import ListModal from "../../subComponents/ListModal"

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError("Only Numbers are allowed")
    .required("Required")
    .min(1, "Amount should be greater than 1"),
  pin: Yup.string()
    .required("Required")
    .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
  driverName: Yup.string().required("Required"),
});

const DriverCardModal = (props) => {
  const {
    open,
    toggleModal,
    handleClose,
    cashback,
    refreshCashback,
    remainingBal,
  } = props;
  const [color1, setColor1] = useState(-1);
  const [color2, setColor2] = useState(-1);
  const [userId, setUserId] = useState("");
  const [search1, setSearch1] = useState("");
  const [display1, setDisplay1] = useState(false);
  const [search2, setSearch2] = useState("");
  const [display2, setDisplay2] = useState(false);
  const [driverColor, setDriverColor] = useState(-1);
  const [vehicleColor, setVehicleColor] = useState(-1);
  // const [loading, setLoading] = useState(false);
  // neocred=0,
  const [cardType, setcardType] = useState("0");
  // count of drivers with wallet coming as result of search
  const [driverWithWallet, setDriverWithWallet] = useState(0);
  const [displayDrivers, setDisplayDrivers] = useState(false);
  const [displayVehicles, setDisplayVehicles] = useState(false);
  const { loader } = useSelector((state) => state?.manageCard);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("====<>nsnan",userId)
    dispatch(getWalletBalAction(userId));
    // else if (cardType === 2) dispatch(M2pUserDetailsAction(userId));
  }, [userId]);

  useEffect(() => {
    setUserId("");
    setSearch1("");
    setSearch2("");
    setDriverColor(-1);
    setDisplayDrivers(false)
    setDisplayVehicles(false)
    setVehicleColor(-1)
  }, []);
  // const ownerBalance = ownerLogipeBalance === "" ? 0 : ownerLogipeBalance;
  //console.log("blanace", ownerBalance);
  const { ownerBalance } = useSelector((state) => state?.manageCard);
  const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == null
      ? 0.0
      : ownerBalance?.instrument_balance?.balance;

  //   const { cashBack } = useSelector((state) => state?.manageCard);
  //   const cashbackRedeem = cashBack?.data?.redeem;
  //   const availableBal= (cashback-cashbackRedeem);

  // for neoCred cards
  const balanceRecord = useSelector(
    (state) => state?.LogipeCard?.balanceRecord
  );
  const bal = balanceRecord?.balance === null ? 0.0 : balanceRecord?.balance;

  // for m2p cards
  const m2pBal = useSelector((state) => state?.m2p?.userDetails?.balance);

  const loader1  = useSelector((state) => state?.LogipeCard?.loader);
  const loader2  = useSelector((state) => state?.m2p?.loader);
  const { allDriversAdvance, allDriverListAdvance } = useSelector(
    (state) => state?.driverlist
  );
  let loading = useSelector(
    (state) => state?.driverlist?.loader
  );
  let nkWallet = allDriverListAdvance.filter((e,i) => e.walletAccountStatus == 4)
  let nkVehicle = allDriverListAdvance.filter((e,i) => e.vrn != null && e.walletAccountStatus == 4)
  console.log("sande1111111", cardType,loader1,balanceRecord,bal);
  const findSearch1 = () => {
    setDriverWithWallet(0);
    setColor1(-1);
    dispatch(
      getAdvanceDriverList({
        offset: 0,
        search: search1,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: "",
        assignedStatus: "",
      })
    );
  };

  const findSearch2 = () => {
    setDriverWithWallet(0);
    setColor2(-1);
    dispatch(
      getAdvanceDriverList({
        offset: 0,
        search: search2,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: "",
        assignedStatus: "",
      })
    );
  };

  useEffect(() => {
    setDriverWithWallet(0);
    dispatch(sendAnalitics("Trns_DC_MA_Web"));
    setColor1(-1);
    dispatch(
      getAdvanceDriverList({
        offset: 0,
        search: search1,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: "",
        assignedStatus: "",
      })
    );
    // setLoading(false);
  }, [search1]);

  useEffect(() => {
    setDriverWithWallet(0);
    dispatch(sendAnalitics("Trns_DC_MA_Web"));
    setColor2(-1);
    dispatch(
      getAdvanceDriverList({
        offset: 0,
        search: search2,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: "",
        assignedStatus: "",
      })
    );
    // setLoading(false);
  }, [search2]);

  useEffect(() => {
    dispatch(
      getAdvanceDriverList({
        offset: 0,
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: "",
        assignedStatus: "",
      })
    );
  }, []);

  const openBox1 = () => {
      // console.log("nananannana",displayDrivers)
      setDisplayDrivers(true)
  }
  const openBox2 = () => {
    // console.log("nananannana",displayDrivers)
    setDisplayVehicles(true)
}

const closeBox1 = () => {
  // console.log("nananannana",displayDrivers)
  setDisplayDrivers(false)
}
const closeBox2 = () => {
// console.log("nananannana",displayDrivers)
setDisplayVehicles(false)
}

  const handleKeyPress = (newSearchVal, divName) => {
    //alert("search again", newSearchVal,divName);
    console.log("search1", newSearchVal);
    console.log("search2", divName);
    dispatch(
      getAdvanceDriverList({
        offset: 0,
        search: newSearchVal.trim(),
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: "",
        assignedStatus: "",
      })
    );
  };

  // const { allDrivers } = useSelector((state) => state?.driverlist);
  // const driversByVrn = useSelector((state) => state?.driverlist?.driversByVrn);
  // const driversList = allDrivers?.divers?.rows;
  // console.log("drivers listing", driversByVrn);
  // console.log("userId", userId, balanceRecord?.balance);

  // const handleSelection = (i, item) => {
  //   //setSelect(i);
  //   setColor1(i);
  //   setDisplay1(false);
  // };

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon sideModal settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{
            width: "500px",
            backgroundColor: "white",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Driver Card - Add Balance</p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                setUserId("");
                setDriverWithWallet(0);
                setSearch1("");
                setColor1(-1);
                setSearch2("");
                setColor2(-1);
                handleClose();
                setDisplay1(false);
                setDisplay2(false);
                dispatch(getWalletBalAction(""));
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="card-wallet-main-body">
              <div
                className="card-wallet-body-new row greenModalBackground"
                style={{ paddingLeft: "10px", paddingTop: "20px" }}
              >
                <div classname=" col-6" style={{ width: "30%" }}>
                  <SvgImage name="modalBank" />
                </div>
                <div
                  className="col-5"
                  style={{
                    position: "relative",
                    left: "-15px",
                    width: "160px",
                  }}
                >
                  <p className="card-wallet-sub-body-new">Master Account</p>
                  <p className="card-wallet-sub-body2">
                    {"₹" + LogipeAccounBal}
                  </p>
                </div>
              </div>

              <div
                className={"card-wallet-body2 row orangeModalBAckground"}
                style={{ paddingLeft: "15px", paddingTop: "20px" }}
              >
                <div classname=" col-4" style={{ width: "30%" }}>
                  <SvgImage name="modalWallet" classname="bank-card-wallet" />
                </div>
                <div
                  className="col-5"
                  style={{
                    position: "relative",
                    left: "-15px",
                    width: "9.4rem",
                  }}
                >
                  <p className="card-wallet-sub-body-new">Driver Card</p>
                  <p className="card-wallet-sub-body2">
                    {cardType == 0 ? (
                      loader1 ? (
                        <ClipLoader
                          color={"#485B7C"}
                          loading={loader1}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      ) : bal == undefined ? (
                        "Select Driver"
                      ) : bal == null ? (
                        "₹0.00"
                      ) : (
                        "₹ " + bal
                      )
                    ) : loader2 ? (
                        <ClipLoader
                          color={"#485B7C"}
                          loading={loader2}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      ):
                     m2pBal === undefined ? (
                      "Select Driver"
                    ) : m2pBal == null ? (
                      "₹0.00"
                    ) : (
                      "₹ " + m2pBal
                    )}
                  </p>
                </div>
              </div>

              <span class="circle-logo-forward-arrow">
                <SvgImage name="ForwardArrow" height="36" width="36" />
              </span>
            </div>

            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                amount: "",
                pin: "",
                driverName: "",
                vrn: "",
                remark: "",
                userId : ""
              }}
              onSubmit={(values, actions) => {
                //console.log("inside submit-------------", cardType);
                dispatch(
                  sendAnalitics("Add_Bal_DC_MA_API_Web", {
                    amt: values.amount,
                    pin: values.pin,
                    remark:values.remark,
                    userId:values.userId
                  })
                );
                if (cardType === 1) {
                  dispatch(
                    transferToCard(
                      {
                        amount: values.amount,
                        pin: values.pin,
                        remark: values.remark,
                      },
                      values.userId,
                      (res) => {
                        // actions.setSubmitting(false);
                        if (res?.status == 200) {
                          //console.log("successful");
                          dispatch(sendAnalitics("Success_close_DC_MA_Web"));
                          dispatch(
                            sendAnalitics("Success_Add_Bal_DC_MA_Web", {
                              amt: values.amount,
                              pin: values.pin,
                              remark:values.remark,
                              userId:values.userId
                            })
                          );
                          //navigate("/Customers")
                          //dispatch(expenseSumm(userId));
                          // window.location.reload();
                          toggleModal();
                        }
                      }
                    )
                  );
                } else {
                  dispatch(
                    transferToCard(
                      {
                        amount: values.amount,
                        pin: values.pin,
                        walletCategory: 2,
                        remark: values.remark,
                      },
                      values.userId,
                      (res) => {
                        // actions.setSubmitting(false);
                        if (res?.status == 200) {
                          //console.log("successful");
                          dispatch(sendAnalitics("Success_close_DC_MA_Web"));
                          dispatch(
                            sendAnalitics("Success_Add_Bal_DC_MA_Web", {
                              amt: values.amount,
                              pin: values.pin,
                              remark:values.remark,
                              userId:values.userId
                            })
                          );
                          //navigate("/Customers")
                          //dispatch(expenseSumm(userId));
                          // window.location.reload();
                          toggleModal();
                        }
                      }
                    )
                  );
                }

                toggleModal();
              }}
              //   console.log("values onSubmit", values);
              //   dispatch(
              //     redeemCashbackAction({
              //         amount:values.amount
              //     })
              //   )
              //   refreshCashback();

              //   if (title === "Card Wallet") {
              //     dispatch(
              //       transferWalletTransferAmount(values, (res) => {
              //         console.log("transfer wallet call back", res.data);
              //         actions.setSubmitting(false);
              //         if (res?.data?.type === "success") {

              //           handleClose();
              //           //alert("Money added succesfully")

              //         }
              //       })
              //     );
              //   }
              //   if (title === "Fastag Wallet") {
              //     dispatch(
              //       transferFastagTransferAmount(values, (res) => {
              //         console.log("transfer fastag call back", res.data);
              //         actions.setSubmitting(false);
              //         if (res?.data?.type === "success") {
              //           handleClose();
              //           //alert("Money added succesfully")

              //         }
              //       })
              //     );
              //   }
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <p className="sub-head-3">Search Driver</p>
                    </div>
                    <label for="html" className="label-body"></label>
                    <input
                      type="text"
                      id="driverName"
                      className="input-body-3"
                      name="driverName"
                      onFocus={openBox1}
                      style={{ marginLeft: "0" }}
                      onChange={(event) => {
                        setDisplayDrivers(true);
                        // dispatch(clearAllDrivers());
                        dispatch(cleanAdvanceDriverList());
                        dispatch(
                          sendAnalitics("Search_DC_MA_Web", {
                            searchDriverName: event.target.value,
                          })
                        );
                        console.log("eeeeeeeeeeeeeeeee", event.target.value);
                        // setDisplay(true);
                        setSearch1(event.target.value);
                        // setLoading(true);
                        setFieldValue("driverName", event.target.value, true);
                      }}
                      value={values.driverName}
                      onBlur={() => {
                        console.log("===>>>>>kkkkk")
                        handleBlur("driverName")
                        closeBox1()
                      }}
                      touched={touched.driverName}
                      errors={errors.driverName}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") {
                          findSearch1();
                        }
                      }}
                      autocomplete="off"
                      placeholder="Search driver's name"
                    />
                    <div className="input-amount">
                      {
                        displayDrivers ? 
                        <ListModal
                        placeholder="Search"
                        handleKeyPress={handleKeyPress}
                        divName="driver"
                        // text="Select by driver name"
                        listing={nkWallet}
                        // handlingDivSelect={handlingDivSelect}
                        displayDrivers={displayDrivers}
                        setDisplayDrivers={setDisplayDrivers}
                        // displayVehicles={displayVehicles}
                        // setDisplayVehicles={setDisplayVehicles}
                        color={driverColor}
                        setColor={setDriverColor}
                        loading = {loading}
                        setFieldValue = {setFieldValue}
                        userId = {userId}
                        setUserId = {setUserId}
                      /> : 
                      <></>
                      }

                    </div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.driverName && touched.driverName ? (
                        <div>*{errors.driverName}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-head-3">Search Vehicle Number</p>
                    </div>
                    <label for="html" className="label-body"></label>
                    <input
                      type="text"
                      id="vrn"
                      className="input-body-3"
                      name="vrn"
                      style={{ marginLeft: "0" }}
                      autocomplete="off"
                      onFocus={openBox2}
                      onBlur={closeBox2}
                      onChange={(event) => {
                        setDisplay2(true);
                        // dispatch(clearAllDrivers());
                        dispatch(clearAllDriversByVrn());
                        dispatch(
                          sendAnalitics("Search_DC_MA_Web", {
                            searchDriverName: event.target.value,
                          })
                        );
                        console.log("eeeeeeeeeeeeeeeee", event.target.value);
                        // setDisplay(true);
                        setSearch2(event.target.value);
                        setFieldValue("vrn", event.target.value, true);
                      }}
                      value={values.vrn}
                      // onBlur={handleBlur("vrn")}
                      touched={touched.vrn}
                      errors={errors.vrn}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") {
                          findSearch1();
                        }
                      }}
                      placeholder="Search Vehicle's Number"
                    />
                    <div className="input-amount">
                    {
                        displayVehicles ? 
                        <ListModal
                        placeholder="Search"
                        handleKeyPress={handleKeyPress}
                        divName="vehicle"
                        // text="Select by driver name"
                        listing={nkVehicle}
                        // handlingDivSelect={handlingDivSelect}
                        displayDrivers={displayVehicles}
                        setDisplayDrivers={setDisplayVehicles}
                        // displayVehicles={displayVehicles}
                        // setDisplayVehicles={setDisplayVehicles}
                        color={vehicleColor}
                        setColor={setVehicleColor}
                        loading = {loading}
                        setFieldValue = {setFieldValue}
                        userId = {userId}
                        setUserId = {setUserId}
                      /> : 
                      <></>
                      }
                    </div>

                    {/* <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.vrn && touched.vrn ? (
                        <div>*{errors.vrn}</div>
                      ) : null}
                    </div> */}
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">3</span>
                      <p className="sub-head-3">Enter amount</p>
                    </div>
                    <p className="sub-head-body">
                      Amount will be added to driver’s LogiPe Card
                    </p>

                    <label for="html" className="label-body">
                      ₹
                    </label>
                    <input
                      type="text"
                      id="amount"
                      className={`input-body `}
                      name="amount"
                      onChange={handleChange("amount")}
                      value={values.amount}
                      onBlur={() => {
                        dispatch(
                          sendAnalitics("Amt_DC_MA_Web", {
                            amt_MA_to_DriverCard: values.amount,
                          })
                        );
                        handleBlur("amount");
                      }}
                      touched={touched.amount}
                      errors={errors.amount}
                      placeholder="Enter amount"
                    />
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">4</span>
                      <p className="sub-head-3">Enter Remarks/comment</p>
                    </div>
                    {/* <p className="sub-head-body">
                      Amount will be added to driver’s LogiPe Card
                    </p> */}

                    <label for="html" className="label-body"></label>
                    <input
                      type="text"
                      id="remark"
                      className={`input-body `}
                      name="remark"
                      onChange={handleChange("remark")}
                      value={values.remark}
                      onBlur={() => {
                        dispatch(
                          sendAnalitics("remark_DC_MA_Web", {
                            amt_MA_to_DriverCard: values.remark,
                          })
                        );
                        handleBlur("remark");
                      }}
                      touched={touched.remark}
                      errors={errors.remark}
                      placeholder="Enter remarks"
                    />
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.remark && touched.remark ? (
                        <div>*{errors.remark}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">5</span>
                      <p className="sub-head-4">Enter 4-digit Security Pin</p>
                    </div>
                    <p className="sub-head-body">
                      Security pin is needed to confirm payment
                    </p>
                    <PinInput
                      length={4}
                      initialValue=""
                      secret
                      onChange={handleChange("pin")}
                      values={values.pin}
                      //handleChange={handleChange("code")}
                      type="numeric"
                      inputMode="number"
                      style={{
                        padding: "10px",
                        marginLeft: "96px",
                        marginTop: "28px",
                      }}
                      inputStyle={{
                        border: "1px solid #A7A7A7",
                        borderRadius: "8px",
                        marginRight: "20px",
                      }}
                      //onComplete={(value, index) => {}}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      onClick={() => {
                        dispatch(
                          sendAnalitics("Pin_DC_MA_Web", {
                            pin_MA_to_DriverCard: values.pin,
                          })
                        );
                      }}
                    />
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.pin && touched.pin ? (
                        <div>*{errors.pin}</div>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      className="butt4"
                      disabled={loader ? true : false}
                      onClick={() => {
                        dispatch(
                          sendAnalitics("Add_Bal_DC_MA_Web", {
                            amt: values.amount,
                            pin: values.pin,
                            remark:values.remark,
                            userId:values.userId
                          })
                        );
                      }}
                    >
                      {loader ? "Please Wait" : "Add Balance to Driver Card"}
                    </button>
                  </FormGroup>
                  {/* <div className="space-div"></div> */}
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default DriverCardModal;
