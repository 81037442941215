import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../Icons/Icons";
import DatePicker from "react-datepicker";
import { deleteCustomRequest, getCustomRequest, updateCustomRequest } from "../../../services/Store/Actions/fastagAction";
import { colors } from "@material-ui/core";
import { numberToInr } from "../../../services/Utils/functions";
import { getCardRequest, updateCardRequest } from "../../../services/Store/Actions/cardWalletAction";
import ManageDriverAddBalance from "../../Modals/ManageDriverAddBalance";


function Requests(props) {
  const { toggle, modal, setModal, regNo, title, fleetId } = props;
  const [requestType, setrequestType] = useState(0);
  const { customRequest } = useSelector((state) => state.fastag);
  console.log("customRequestcustomRequest", customRequest);
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [loader,setLoader] = useState(false);
  const [loader2,setLoader2] = useState(false);
  const [loader3,setLoader3] = useState(false);
  const [userId,setUserId] = useState('');
  const [driverBalance,setDriverBalance] = useState('')
  const [amount,setAmount] = useState('')
  const [driverName,setDriverName] = useState('')
  const [requestId,setRequestId] = useState('')
  const cardRequest = useSelector(
    (state) => state?.cardWallet?.cardRequest
  );
  console.log("cardRequestcardRequestcardRequest",cardRequest)
  const [paymentRequestList, setPaymentRequestList] = useState([{
    amount:1
  },{
    amount:1
  }]);
  const [refreshing, setRefreshing] = useState(false);
  const [open, setOpen] = useState(false);
  
  const toggleModal = () => {
    setOpen(!open);
  };

 

  useEffect(() => {
    // Fetch payment request data here using useEffect or any other method
    // Example: fetchPaymentRequestData();
    console.log("sbshvbsjvsjvsjv")
    
  }, []);

  const onRefresh = () => {
    setRefreshing(true);
    // Perform refresh action here
    // Example: fetchPaymentRequestData();
    setRefreshing(false);
  };

  const renderFooter = () => {
    // Implement your ListFooterComponent logic here
    return null;
  };

  const handleClose = () => {
    setModal(false);
  };

  useEffect(() => {
    dispatch(
      getCustomRequest({
        type: type,
        status: 3,
        isOwner: 1,
      })
    );
    setLoader(false);
    setLoader2(false);
    setLoader3(false)
    return function cleanUp() {};
  }, [requestType, type]);
  useEffect(() => {
    dispatch(
      getCustomRequest({
        type: 'OMCREQ',
        status: 3,
        isOwner: 1,
      })
    );
    return function cleanUp() {};
  }, []);
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
          // style={{height : "75vh"}}
        >
          <div>
            <div className="closeBtnWithBackground" onClick={handleClose}>
              <SvgImage
                classname="close-btn-text"
                style={{ width: "10%", height: "10%" }}
                name="crossBtnWIthBackground"
                width="20%"
                height="20%"
              />
            </div>
            <div
              style={{ marginTop: " 10px" ,marginBottom:"10px" }}
              className="row"
              //   className="PGCollectionBox"
            >
              <div className="showroomText col-12">Requests</div>
            </div>
           
          </div>

        {
           <>
           {cardRequest?.data?.map((item, index) => {
            console.log("hvhvhvhvhvhv",item)
            console.log("nsjbjzbjzxbxjzb",typeof item?.body)
            let listObj = item?.body
            console.log("listObj", listObj);
           
            return (
              <>
                <div className="detailImgContainer row">
                 
                  <div className="col-6">
                  <a
                      style={{
                        color: "Blue",
                      }}
                    >
                      {"Status: "}
                    </a>
                    <a style={{
                      color: item?.status == '1' ? '#F6BE00' : item?.status == '2' ? 'green' : 'red',
                    }}>
                    {item?.status == '1' ? 'Pending' : item?.status == '2' ? 'Accepted' : 'Rejected'}
                    </a>
                   
                    <br />
                  
                    <a
                      style={{
                        color: "Blue",
                      }}
                    >
                      {" Driver Name: "}
                    </a>
                    {item?.driverName}
                    <br />

                    <a
                      style={{
                        color: "Blue",
                      }}
                    >
                      {" Amount: "}
                    </a>
                    {item?.amount}
                    <br />
                   
                    <a
                      style={{
                        color: "Blue",
                      }}
                    >
                      {" Requested At: "}
                    </a>
                    {item?.createdAt}
                    <br />

                    

                    {(item?.status==2||item?.status==3)?<button disabled={loader} onClick={()=>{
                      console.log("clicked deleted")
                      setLoader(true)
                      dispatch(updateCardRequest({
                        requestId:item.requestId,
                        status:4,
                        obj:{
                          "amountPaid":item?.amount
                        }
                      },(res)=>{
                        console.log("svhagfhgsvhssghv",res)
                        dispatch(getCardRequest({},()=>{}))
                        setLoader(false)
                      }))
                    }}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      Delete
                    </button>:<>
                      <button
                      style={{
                        backgroundColor:loader2?null:"green",
                        borderRadius: "5px",
                        color: loader2?'black':"white",
                        marginTop: "10px"
                      }}
                      // disabled={loader2}
                      onClick={()=>{
                        console.log("clicked Accept",item?.Card);
                        setUserId(item?.Card?.userId)
                        setDriverBalance(item?.Card?.balance)
                        setDriverName(item?.driverName)
                        setAmount(item?.amount)
                        setRequestId(item?.requestId)

                        setOpen(true);
                        // setLoader2(true)
                        // setLoader3(false)
                        // dispatch(updateCustomRequest({
                        //   requestId: item?.requestId,
                        //   status: 1
                        // }, (res) => {
                        //   console.log('requestId:item?.requestId', res)
                        //   setLoader2(false)
                        //   dispatch(getCustomRequest({
                        //     type: type,
                        //     status: 3,
                        //     isOwner: 1
                        //   }))
                        // }))
                      }}
                    >
                     { loader2?"Wait":"Accept"}
                    </button>
                    <button
                      style={{
                        backgroundColor: loader3?null:"red",
                        marginLeft: "10px",
                        borderRadius: "5px",
                        color: loader3?'black':"white",
                        marginTop: "10px"
                      }}
                      // disabled={loader3}
                      onClick={()=>{
                        console.log("clicked Reject");
                        setLoader3(true)
                        dispatch(updateCardRequest({
                          requestId:item.requestId,
                          status:3,
                          obj:{
                            "amountPaid":item?.amount
                          }
                        },(res)=>{
                          console.log("svhagfhgsvhssghv",res)
                          dispatch(getCardRequest({},()=>{}))
                          setLoader3(false)
                        }))
                        
                      }}
                    >
                      { loader3?"Wait": "Reject"}
                    </button>
                    </>}


                   
                  </div>
                </div>
                {console.log("snshshshshhs",item)}
                <ManageDriverAddBalance
            open={open}
            toggleModal={toggleModal}
            handleClose={handleClose}
            name="Add Balance"
            userId={userId}
            driverBal={driverBalance}
            cardType={1}
            driverName={driverName}
            offset={0}
            search={0}
            amount={amount}
            apiCall={true}
            requestId={requestId}
          />
              </>
              
            );
          })}
         </>
        }
        </ModalBody>
      </Modal>
      {/* <CreateInvoice
        toggle={createInvoice}
        modal={invoiceModal}
        setModal={setInvoiceModal}
        customerId={customerId}
        isCheckAll={isCheckAll}
        setIsCheckAll={setIsCheckAll}
        isCheck={isCheck}
        setIsCheck={setIsCheck}
        customer={customer}
      />
      <AddInvoice
        toggle={addInvoiceFunc}
        modal={addInvoice}
        setModal={setAddInvoice}
      /> */}
    </div>
  );
}

export default Requests;
