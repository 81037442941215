import React from "react";
import "./index.css";
import { IoIosAddCircle } from "react-icons/io";

function AddNewCustomerButton(props) {
  const name = props.name == null ? "Add New Customer" : props.name;
  return (
    <div className="AddNewCustomerBox">
      <div className="AddNewCustomerIcon">
        <div>
          <IoIosAddCircle size={28} color="#485B7C" />
        </div>
      </div>
      <div className="AddNewCustomerText FontNB2" style={{ color: "#485B7C" }}>
        {name}
      </div>
    </div>
  );
}
export default AddNewCustomerButton;
