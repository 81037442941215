import React, { useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import SvgImage from "../../Icons/Icons";
import "./index.css";

import { useDispatch } from "react-redux";
import {
  withdrawMoneyFromWallet,
  expenseSumm,
  editCardControl,
  getCardControl,
} from "../../../services/Store/Actions/driverslistActions";

const EnterPinModal = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    toggleModal,
    handleClose,
  } = props;
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-head-part">
          <p className="Add-balance-title">Enter Pin</p>
          <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={{
              userId: props.userId,
              PIN: "",
              // limitAmount: item.Remarks,
              expenseType:
                props.itemName == "ATM Withdrawal"
                  ? "atm"
                  : props.itemName == "Fuel Transaction"
                  ? "fuel"
                  : props.itemName == "Online Transaction"
                  ? "online"
                  : props.itemName == "Other POS"
                  ? "pos"
                  : null,
              isActive: !props.itemStatus,
            }}
            validationSchema={Yup.object().shape({
              //   amount: Yup.number()
              //     .min(1, "Amount should be greater than 1")
              //     .required("Amount is required"),
              PIN: Yup.string()
                .required("* Required")
                .matches(/^[0-9]{4}$/, "* PIN Must be exactly 4 digits"),
              // dob: Yup.string()
              //   .required('Expense Date is required'),
              // password: Yup.string()
              //   .min(6, 'Password must be at least 6 characters')
              //   .required('Password is required'),
              // confirmPassword: Yup.string()
              //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
              //   .required('Confirm Password is required')
            })}
            //validationSchema={validationSchema}
            onSubmit={(values) => {
              // values.bookingDate = moment(values.bookingDate).format(
              //   "DD-MM-YYYY"
              // );
              //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
              dispatch(
                editCardControl(
                  //   {
                  //     userId: props.userId,
                  //     PIN: "3333",
                  //     // limitAmount: item.Remarks,
                  //     expenseType:
                  //       item.Name == "ATM Withdrawal"
                  //         ? "atm"
                  //         : item.Name == "Fuel Transaction"
                  //         ? "fuel"
                  //         : item.Name == "Online Transaction"
                  //         ? "online"
                  //         : item.Name == "Other POS"
                  //         ? "pos"
                  //         : null,
                  //     isActive: !item.status,
                  //   },
                  values,
                  (res) => {
                    console.log("bhai here", res);
                    if (res?.status == 200) {
                      // console.log("successfullll******");

                      //navigate("/Customers")
                      // toggleModal();
                      //props.setActiveStatus(!val)

                      dispatch(getCardControl(props.userId));
                      toggleModal();
                      //console.log("After submit", cardDetails?.driverlist?.cardControl?.card?.isActive);
                    }
                  }
                )
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  {/* <div className="sub-title-1">
                    <span class="circle-logo">1</span>
                    <p className="sub-head-3">Enter amount</p>
                  </div>
                  <p className="sub-head-body">
                    Amount will be deducted from owner’s logipe account
                  </p> */}

                  {/* <label for="html" className="label-body">
                    ₹
                  </label> */}
                  {/* <input
                    type="text"
                    id="html"
                    className={
                      "input-body-1 form-control" +
                      (errors.amount && touched.amount ? " is-invalid" : "")
                    }
                    name="amount"
                    value={values.amount}
                    touched={touched.amount}
                    errors={errors.amount}
                    onBlur={handleBlur("amount")}
                    onChange={handleChange("amount")}
                  ></input> */}
                  {/* <div className="input-amount"></div>
                  <ErrorMessage
                    name="amount"
                    component="div"
                    className="invalid-feedback"
                  /> */}
                </div>
                <div>
                  <div className="sub-title-1">
                    <span class="circle-logo">1</span>
                    <p className="sub-head-4">Enter 4-digit Security Pin</p>
                  </div>
                  <p className="sub-head-body">
                    Security PIN is needed to confirm payment
                  </p>
                  <PinInput
                    length={4}
                    initialValue=""
                    secret
                    onChange={(value, index) =>
                      setFieldValue("PIN", value, true)
                    }
                    type="numeric"
                    inputMode="number"
                    style={{
                      padding: "10px",
                      marginLeft: "96px",
                      marginTop: "28px",
                    }}
                    inputStyle={{
                      border: "1px solid #A7A7A7",
                      borderRadius: "8px",
                      marginRight: "20px",
                    }}
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  <div
                    style={{
                      color: "#dc3545",
                      fontSize: "14px",
                      marginLeft: "6.5rem",
                    }}
                    className={
                      +(errors.PIN && touched.PIN ? " is-invalid" : "")
                    }
                  >
                    {" "}
                    <ErrorMessage className="is-invalid" name="PIN" />
                  </div>
                  <button type="submit" className="butt4">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <div className="space-div"></div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default EnterPinModal;
