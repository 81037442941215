import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { autoRechargeToggleAction } from "../../../../services/Store/Actions/fastagAction";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import SvgImage from "../../../Icons/Icons";
import { toast } from "react-toastify";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import {
  editControlManager,
  managerGetControlAction,
} from "../../../../services/Store/Actions/UserManagementAction";
import ChangeTransferLimit from "../ChangeTransferLimit";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { ClipLoader } from "react-spinners";
function CustomerManagerDetails(props) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(-1);
  const [open, setOpen] = useState(false);
  const { managerControlList, loader: managerLoader } = useSelector(
    (state) => state?.userManagement
  );
  console.log(managerControlList);
  const [trip, setTrip] = useState(managerControlList?.tripAccess);
  const [customer, setCustomer] = useState(managerControlList?.customerAccess);
  const [vendor, setVendor] = useState(managerControlList?.vendorAccess);
  let { id } = useParams();
  useEffect(() => {
    if (id !== undefined) {
      dispatch(managerGetControlAction({ userId: id }));
    } else {
    }
  }, [props.user]);

  const marks = [
    {
      value: 0,
      label: "disable",
    },
    {
      value: 1,
      label: "view",
    },
    {
      value: 2,
      label: "edit",
    },
    {
      value: 3,
      label: "create",
    },
  ];
  function valuetext(value) {
    return `${
      value == 0
        ? "disable"
        : value == 1
        ? "view"
        : value == 2
        ? "edit"
        : value == 3
        ? "create"
        : "disable"
    }`;
  }

  const ManagerName = id ? props?.user?.fullName : "Select Manager";

  function handleClose() {
    setOpen(false);
  }
  function handleShow() {
    setOpen(true);
  }
  function toggleModal() {
    setOpen(!open);
  }
  const handleToggle = (e, item) => {
    if (loader == -1) {
      setLoader(item.type);
      dispatch(
        editControlManager(
          {
            userId: id,
            type: item.type,
          },
          (res) => {
            console.log("res", res);
            setLoader(-1);
            if (res?.type == "success") {
              dispatch(managerGetControlAction({ userId: id }));
            }
          }
        )
      );
    }
  };
  const handleSlide = (e, item) => {
    if (loader == -1) {
      setLoader(item.type);
      dispatch(
        editControlManager(
          {
            userId: id,
            type: item.type,
            value: e.target.value,
          },
          (res) => {
            console.log("res", res);
            setLoader(-1);
            if (res?.type == "success") {
              dispatch(managerGetControlAction({ userId: id }));
            }
          }
        )
      );
    }
  };
  const controls = [
    {
      title: "Auto Approval",
      svgName: "accountStatus",
      width: 36,
      height: 36,
      type: "transfer",
    },
    {
      title: "Master Account",
      svgName: "ManagerMasterAcc",
      width: 36,
      height: 36,
      type: "masterAccount",
    },
    {
      title: "Card",
      svgName: "ManagerCard",
      width: 36,
      height: 36,
      type: "wallet",
    },
    {
      title: "FASTag",
      svgName: "ManagerFastag",
      width: 36,
      height: 36,
      type: "fastag",
    },
    {
      title: "UPI",
      svgName: "UPI",
      width: 22,
      height: 22,
      type: "upi",
    },
    {
      title: "Cashback",
      svgName: "ManagerCashback",
      width: 36,
      height: 36,
      type: "cashBack",
    },
    // {
    //   title: 'Customers',
    //   svgName: 'ManagerCustomers',
    //   width: 36,
    //   height: 36,
    //   type: 'customer',
    // },
    {
      title: "Scan And Pay",
      svgName: "ManagerUPI",
      width: 36,
      height: 36,
      type: "scanAndPay",
    },
    {
      title: "Trips",
      svgName: "ManagerTrips",
      width: 18,
      height: 20,
      type: "trips",
    },
    // {
    //   title: "Insurance",
    //   svgName: "insuranceV2",
    //   width: 21,
    //   height: 18,
    //   type: "insurance",
    // },
    {
      title: "Logimart",
      svgName: "logimartV2",
      width: 18,
      height: 18,
      type: "ecom",
    },
    {
      title: "Load Money",
      svgName: "loadMoney",
      width: 18,
      height: 18,
      type: "loadMoney",
    },
    {
      title: "Customers",
      svgName: "customerV3",
      width: 18,
      height: 18,
      type: "customer",
    },
    {
      title: "Salary Wallet",
      svgName: "salaryWallet",
      width: 18,
      height: 18,
      type: "salaryWallet",
    },
    {
      title: "Trips/LR Access",
      svgName: "stepView",
      width: 18,
      height: 18,
      type: "tripAccess",
    },
    {
      title: "Customer/Invoice Access",
      svgName: "stepView",
      width: 18,
      height: 18,
      type: "customerAccess",
    },
    {
      title: "Vendor Access",
      svgName: "stepView",
      width: 18,
      height: 18,
      type: "vendorAccess",
    },
    {
      title: "Admin Access",
      svgName: "stepView",
      width: 18,
      height: 18,
      type: "admin",
    },
    // {
    //   title: 'Trips',
    //   svgName: 'ManagerTrips',
    //   width: 36,
    //   height: 36,
    //   type: 'trips',
    // },
  ];
  useEffect(() => {}, [ManagerName]);
  return (
    <div
      className="main-customer-vehicle-detail"
      style={{ opacity: ManagerName === "Select Manager" ? "0.5" : "1" }}
    >
      <div className="ManageCustomerDashboardContactCard">
        <div className="contentMiddle flexRow">
          <div className="main-vehcile-No ">
            <div className="vehicle-no FontSuperBold">
              {ManagerName == "" || ManagerName == null
                ? "Select Manager"
                : ManagerName}
            </div>
          </div>
          <div className="Bar"></div>
          <div className="number FontNB">
            Phone :{" "}
            {props?.user?.phone === "" || props?.user?.phone == null
              ? "N/A"
              : props?.user?.phone}
          </div>
          <div className="flex1 d-flex justify-content-end">
            <button
              className="d-flex"
              style={{
                marginRight: 36,
                backgroundColor: "white",
                borderWidth: 0,
              }}
              onClick={handleShow}
            >
              <div className="number FontNB me-1" style={{ color: "#2E7C7B" }}>
                {managerControlList?.transferLimit}
              </div>
              <SvgImage name="editLogo" width="22" height="22" />
            </button>
          </div>
        </div>
      </div>
      <div className="padding16 row">
        <div className="main-container-vehicle-details d-flex flex-wrap justify-content-around">
          {controls?.map((item, index) => {
            return (
              <div
                className="auto-recharge padding16 "
                style={{
                  marginTop: "1rem",
                  opacity:
                    managerControlList?.[item.type] == undefined ? "0.5" : "1",
                  width: "45%",
                }}
              >
                <div className="row">
                  <div
                    className="col-6 semiBold heading2"
                    style={{ display: "flex", direction: "row" }}
                  >
                    <div style={{ paddingRight: "8px" }}>
                      <SvgImage name="svgName" />
                    </div>
                    {item.title}
                  </div>
                  <div
                    className={
                      managerControlList?.[item.type]
                        ? "col-6 row fontCardControl btnActive"
                        : "col-6 row fontCardControl btnInactive"
                    }
                    style={{
                      color: "#2E7C7B",
                      alignItems: "right",
                      // paddingLeft: "20px",
                      // marginTop: "10px",
                    }}
                  >
                    <div className="col-8"></div>
                    {console.log(
                      "dasfghwqeqwewqeqwewq",
                      item?.type,
                      managerControlList?.[item?.type],
                      loader
                    )}
                    {item?.svgName == "stepView" ? (
                      <Box sx={{ width: 200 }}>
                        {loader != item?.type ? (
                          managerControlList?.[item?.type] ? (
                            <Slider
                              aria-label="Always visible"
                              // defaultValue={managerControlList?.[item?.type]}
                              // getAriaValueText={valuetext}
                              onChange={(val) => {
                                console.log(val, "vaaaaa");
                                // setTrip(val.target.value);
                                handleSlide(val, item);
                              }}
                              value={managerControlList?.[item?.type]}
                              min={0}
                              max={3}
                              // name={marks.filter((i) => i.value == trip)[0]?.label}
                              step={1}
                              marks={marks}
                              classes={{
                                colorPrimary: "rgb(46, 124, 123)",
                                thumbColorPrimary: "rgb(46, 124, 123)",
                              }}
                              // valueLabelDisplay="on"
                              size={"large"}
                              sx={{
                                "& .MuiSlider-thumb": {
                                  color: "rgb(46, 124, 123)",
                                },
                                "& .MuiSlider-track": {
                                  color: "rgb(46, 124, 123)",
                                },
                                "& .MuiSlider-rail": {
                                  color: "rgb(46, 124, 123)",
                                },
                                "& .MuiSlider-active": {
                                  color: "rgb(46, 124, 123)",
                                },
                              }}
                            />
                          ) : (
                            <Slider
                              aria-label="Always visible"
                              // defaultValue={managerControlList?.[item?.type]}
                              // getAriaValueText={valuetext}
                              onChange={(val) => {
                                console.log(val, "vaaaaa");
                                // setTrip(val.target.value);
                                handleSlide(val, item);
                              }}
                              value={0}
                              min={0}
                              max={3}
                              // name={marks.filter((i) => i.value == trip)[0]?.label}
                              step={1}
                              marks={marks}
                              classes={{
                                colorPrimary: "rgb(46, 124, 123)",
                                thumbColorPrimary: "rgb(46, 124, 123)",
                              }}
                              // valueLabelDisplay="on"
                              size={"large"}
                              sx={{
                                "& .MuiSlider-thumb": {
                                  color: "rgb(46, 124, 123)",
                                },
                                "& .MuiSlider-track": {
                                  color: "rgb(46, 124, 123)",
                                },
                                "& .MuiSlider-rail": {
                                  color: "rgb(46, 124, 123)",
                                },
                                "& .MuiSlider-active": {
                                  color: "rgb(46, 124, 123)",
                                },
                              }}
                            />
                          )
                        ) : (
                          // <Slider
                          //   aria-label="Always visible"
                          //   // defaultValue={managerControlList?.[item?.type]}
                          //   // getAriaValueText={valuetext}
                          //   onChange={(val) => {
                          //     console.log(val, "vaaaaa");
                          //     // setTrip(val.target.value);
                          //   }}
                          //   min={0}
                          //   max={3}
                          //   // name={marks.filter((i) => i.value == trip)[0]?.label}
                          //   step={1}
                          //   marks={marks}
                          //   classes={{
                          //     colorPrimary: "rgb(46, 124, 123)",
                          //     thumbColorPrimary: "rgb(46, 124, 123)",
                          //   }}
                          //   // valueLabelDisplay="on"
                          //   size={"large"}
                          //   sx={{
                          //     "& .MuiSlider-thumb": {
                          //       color: "rgb(46, 124, 123)",
                          //     },
                          //     "& .MuiSlider-track": {
                          //       color: "rgb(46, 124, 123)",
                          //     },
                          //     "& .MuiSlider-rail": {
                          //       color: "rgb(46, 124, 123)",
                          //     },
                          //     "& .MuiSlider-active": {
                          //       color: "rgb(46, 124, 123)",
                          //     },
                          //   }}
                          // />
                          <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        )}
                      </Box>
                    ) : (
                      <Switch
                        checked={managerControlList?.[item.type]}
                        onChange={(e) => {
                          handleToggle(e, item);
                        }}
                        onColor="#EBF2F2"
                        onHandleColor="#2E7C7B"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        height={15}
                        width={40}
                        className="react-switch col-4"
                        id="material-switch"
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <ChangeTransferLimit
            open={open}
            handleClose={handleClose}
            toggleModal={toggleModal}
            user={props.user}
          />
        </div>
      </div>
    </div>
  );
}
export default CustomerManagerDetails;
