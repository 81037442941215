import React, { useEffect, useState } from "react";
// import SvgImage from "../../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { sendOtp } from "../../../../services/Store/Actions/LoginAction";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import SvgImage from "../../../../Components/Icons/Icons";


const EnterNumber=(props)=>{
    const validationSchema = Yup.object().shape({
        phoneNumber: Yup.string()
          .required("Phone number must contain 10 digits")
          .matches(
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            "Phone number must contain 10 digits"
          ),
      });

    const { loader } = useSelector((state) => state?.loginReducer);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(sendAnalitics("LoginPage_web"));
      },[])

    return (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            phoneNumber: "",
          }}
          onSubmit={(values, actions) => {
            //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
            console.log("valuessss", values);
            dispatch(sendAnalitics("GetOTP_web", values));
            dispatch(
              sendOtp(
                {
                  phone: values.phoneNumber,
                  resend: "any",
                },
                (res) => {
                  //actions.setSubmitting(false);
                  console.log("SUCCESS", res);
                  if (res?.data?.type === "success") {
                    console.log("SUCCESS otp send");
                    props.setPhone(values.phoneNumber);
                    props.setStatus("1");
                  }
                }
              )
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit} className="InputForm1">
              <FormGroup className="formPosition">
                <div className="heading1 semiBold">Enter your phone number</div>
                <div className="Regular text3 padding24T headText2">
                  Phone number
                </div>
                <div className="padding16" style={{paddingLeft:"0.7rem"}}>
                  <div className="row phoneNumberBox padding8">
                    <div
                      className="col-2 padding0L boxWithBorder"
                      style={{ textAlign: "center" }}
                    >
                      <SvgImage name="countryCode" />
                    </div>
                    <div className="col-9 padding0L" style={{ marginLeft: "1rem" }}>
                      <input
                        type="text"
                        id="phoneNumber"
                        className="input-body-4 Regular text3"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        touched={touched.phoneNumber}
                        errors={errors.phoneNumber}
                        onBlur={()=>{
                          dispatch(sendAnalitics("EnterPhone_web", values));
                          handleBlur("phoneNumber");
                        }}
                        onChange={handleChange("phoneNumber")}
                        style={{
                          textAlign: "left",
                          marginLeft: "0px",
                          width: "210px",
                        }}
                        placeholder="Enter phone number"
                        maxLength="10"
                        autofocus
                      ></input>
                    </div>
                  </div>
                </div>
                <div
                  className="text4"
                  style={{
                    // margin: "7px 0 0px 50px",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {errors.phoneNumber && touched.phoneNumber ? (
                    <div>{errors.phoneNumber}</div>
                  ) : null}
                </div>
                <Button
                  type="submit"
                  className="loginButton semiBold text2"
                  style={{
                    marginTop: "1rem",
                    background:
                      values.phoneNumber.length < 10
                        ? "#EFEFEF"
                        : loader
                        ? "#EFEFEF"
                        : "#485B7C",
                    color:
                      values.phoneNumber.length < 10
                        ? "#B5B5B5"
                        : loader
                        ? "#B5B5B5"
                        : "#ffffff",
                  }}
                  disabled={loader ? true : false}
                >
                  {loader ? "Please Wait" : "Send OTP"}
                </Button>
                {/* <div className="bottomTextLogin padding16T Regular text4">
                  <span
                    className="bottomTextSpan Medium"
                    onClick={() => {
                      props.handleTermShow();
                    }}
                  >
                    T&C{" "}
                  </span>{" "}
                  and{" "}
                  <span
                    className="bottomTextSpan Medium"
                    onClick={() => {
                      props.handlePrivacyShow();
                    }}
                  >
                    Privacy Policy
                  </span>{" "}
                  apply
                </div> */}
              </FormGroup>
            </Form>
          )}
        </Formik>
      );  
}

export default EnterNumber;