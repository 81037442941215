import React, { useState, useEffect } from "react";
import "./index.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehicleListAction,
  clearAllFleets,
} from "../../../../services/Store/Actions/manageVehicleAction";
import AddNewCustomerButtonV2 from "../../../../Subcomponent/AddCustomerButtonV2";
import SearchBar from "../../../subComponents/searchbar/index";
import FullVehicleListSwitchBar from "../FullVehicleListSwitchBar/index";
import ManageVehicleDetailsCard from "../ManageVehicleDetailsCard/index";
import ManageVehicleListBar from "../ManageVehicleListBar/index";
import AddNewVehicleModal from "../../../Modals/addNewVehicle/index";
import { Table } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import AutorechargeHome from "../../../Modals/AutorechargeHome";
import AutorechargeSelectVehicles from "../../../Modals/AutorechargeSelectVehicles";
import AutorechargeConfirm from "../../../Modals/AutorechargeConfirm";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { advancedVehicleListAction } from "../../../../services/Store/Actions/fleetActions";
import Requests from "../../../Modals/Requests";
import { useNavigate } from "react-router-dom";
function FullVehicleList(props) {
  const navigate= useNavigate();

  const [open, setOpen] = useState(false);
  //const { handleUser } = props;
  const [show, setShow] = useState(false);
  const [btn, setBtn] = useState(false);
  // const [search, setSearch] = useState("");
  const [proceed, setProceed] = useState(0);
  const [fstOpen, setFstOpen] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);
  const [assignedStatus, setAsssignedStatus] = useState(3);
  const { allFleets, allFleetsList } = useSelector(
    (state) => state?.manageVehicle
  );
  const { advancedVehicleList, advancedVehicleListCount } = useSelector(
    (state) => state?.fleet
  );
  function toggleModal() {
    setOpen(!open);
    setProceed(0);
  }

  // const handleUserId = (val) => {
  //   handleUser(val);
  //   //onsole.log("useState change ho rha hai kya yahi ", val);
  // };

  const urlSearchParams = new URLSearchParams(window.location.search);

  const params = Object.fromEntries(urlSearchParams.entries());
  // console.log("url split", searchParam);

  let searchParam = params.search;

  console.log("url split", searchParam);

  const [search, setSearch] = useState(
    searchParam == undefined ? "" : searchParam
  );

  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);
  const [offset, setOffset] = useState(offsetVal);

  const dispatch = useDispatch();
  const [rechargeRank, setRechargeRank] = useState(0);
  const findSearch = () => {
    // dispatch(
    //   getVehicleListAction({
    //     offset: 0,
    //     search: search.trim(),
    //     limit: 15,
    //     minKyc: "",
    //     sortAs: "",
    //     sortBy: "",
    //   })
    // );
    dispatch(
      advancedVehicleListAction({
        offset: offsetVal,
        search: search.trim(),
        limit: 15,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: activeStatus,
        assignedStatus: assignedStatus,
      })
    );
  };

  useEffect(() => {
    // dispatch(
    //   getVehicleListAction({
    //     offset: offsetVal,
    //     search: search.trim(),
    //     limit: 15,
    //     minKyc: "",
    //     sortAs: "",
    //     sortBy: "",
    //     activeStatus: activeStatus,
    //     assignedStatus: assignedStatus,
    //   })
    // );
    dispatch(
      advancedVehicleListAction({
        offset: offsetVal,
        search: search.trim(),
        limit: 15,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: activeStatus,
        assignedStatus: assignedStatus,
      })
    );
  }, [search, activeStatus, assignedStatus]);
  //console.log("Data here is >>>>>>>>>",allFleetsList)
  //console.log(Data[0])
  function handleClick() {
    setBtn(!btn);
  }
  // const handleLoadMore = async () => {
  //     if (allFleetsList?.length < allFleets?.count)
  //     {
  //       setFetchMoreData(true);
  //       setOffset(offset + 10);
  //       dispatch(
  //         getVehicleListAction({
  //           offset: offset ,
  //           search: '',
  //           minKyc: '',
  //           sortAs: '',
  //           sortBy: '',
  //         })
  //       );

  //     }
  //     else
  //     {
  //       setFetchMoreData(false)
  //     }
  //   };
  //console.log("FleetID Testing",Data[0]?.fleetId)
  const handleClose = () => {
    setOpen(false);
    setProceed(0);
  };
  const handleShow = () => {
    dispatch(sendAnalitics("AddVeh_MV_FW_Web"));
    setOpen(true);
    //console.log("sdsdsdsdsdsdddddddddd");
  };

  useEffect(() => {
    dispatch(sendAnalitics("MV_FW_Web"));
    return function cleanUp() {
      dispatch(clearAllFleets());
      setOffset(0);
      dispatch(clearAllFleets());
    };
  }, []);

  function handleClick(data) {
    props.handleUser(data.Fastag.fleetId);
    //console.log("new Meta ",)
    props.handleFastagBal(data.Fastag.balance);
    props.handleTagId(data.Fastag.tagId);
    props.handleRegNo(data.regNumber);
    //console.log("single fleet data",data)
  }
  //console.log("NEWMOON",Data[0].regNumber)

  const [openLink, setOpenLink] = useState(false);
  const [fleetId, setFleetId] = useState("");
  const [vehCat, setVehCat] = useState("1");
  const [regNumber, setRegNumber] = useState("");
  const [color, setColor] = useState(-1);
  //const [autoRechargeState, setAutoRechargeState] = useState("On");

  // auto recharge home page
  const [openAutoRecharge, setOpenAutoRecharge] = useState(false);

  const showRecharge = () => {
    setOpenAutoRecharge(true);
  };

  const toggleRecharge = () => {
    setOpenAutoRecharge(!openAutoRecharge);
    if (openAutoRecharge) {
      // dispatch(
      //   getVehicleListAction({
      //     offset: offsetVal,
      //     search: search.trim(),
      //     limit: 15,
      //     minKyc: "",
      //     sortAs: "",
      //     sortBy: "",
      //   })
      // );
      dispatch(
        advancedVehicleListAction({
          offset: offsetVal,
          search: search.trim(),
          limit: 15,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          activeStatus: activeStatus,
          assignedStatus: assignedStatus,
        })
      );
    }
  };

  const closeRecharge = () => {
    setOpenAutoRecharge(false);
    // dispatch(
    //   getVehicleListAction({
    //     offset: offsetVal,
    //     search: search.trim(),
    //     limit: 15,
    //     minKyc: "",
    //     sortAs: "",
    //     sortBy: "",
    //   })
    // );
    dispatch(
      advancedVehicleListAction({
        offset: offsetVal,
        search: search.trim(),
        limit: 15,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: activeStatus,
        assignedStatus: assignedStatus,
      })
    );
  };

  // auto recharge select vehicles
  const [openRechargeVehicles, setOpenRechargeVehicles] = useState(false);

  const showRechargeVeh = () => {
    setOpenRechargeVehicles(true);
  };

  const toggleRechargeVeh = () => {
    setOpenRechargeVehicles(!openRechargeVehicles);
    if (openRechargeVehicles) {
      // dispatch(
      //   getVehicleListAction({
      //     offset: offsetVal,
      //     search: search.trim(),
      //     limit: 15,
      //     minKyc: "",
      //     sortAs: "",
      //     sortBy: "",
      //   })
      // );
      dispatch(
        advancedVehicleListAction({
          offset: offsetVal,
          search: search.trim(),
          limit: 15,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          activeStatus: activeStatus,
          assignedStatus: assignedStatus,
        })
      );
    }
  };

  const closeRechargeVeh = () => {
    setOpenRechargeVehicles(false);
    // dispatch(
    //   getVehicleListAction({
    //     offset: offsetVal,
    //     search: search.trim(),
    //     limit: 15,
    //     minKyc: "",
    //     sortAs: "",
    //     sortBy: "",
    //   })
    // );
    dispatch(
      advancedVehicleListAction({
        offset: offsetVal,
        search: search.trim(),
        limit: 15,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: activeStatus,
        assignedStatus: assignedStatus,
      })
    );
  };

  // auto recharge specific vehicle
  const [openRechargeOne, setOpenRechargeOne] = useState(false);
  const [rechargeFleetId, setRechargeFleetId] = useState("");
  const showRechargeOne = (fleetId) => {
    setRechargeFleetId(fleetId);
    setOpenRechargeOne(true);
  };

  const toggleRechargeOne = () => {
    setOpenRechargeOne(!openRechargeOne);
  };

  const closeRechargeOne = () => {
    setOpenRechargeOne(false);
  };

  const [vehNo, setVehNo] = useState("");
  const [limit, setLimit] = useState(0);
  const [showroomModal, setShowroomModal] = useState(false);
  const [showRequest,setshowRequest] = useState(false);
  const showroomModalFunc = () => {
    console.log("invoiceModal", showroomModal);
    setShowroomModal(!showroomModal);
  };

  const showRequests = () => {
    console.log("invoiceModal", showroomModal);
    setshowRequest(!showRequest);
  };
  const vehiclehistory = ()=>{
    navigate("/VehicleHistory",{state:{
      path: 'all',
    }});
  }

  const { fastagWallet } = useSelector((state) => state?.fastag);
  console.log("-----------------", fastagWallet);
  const autoRechargeState = fastagWallet?.autoRecharge;

  const counting = advancedVehicleListCount;
  return (
    <div className="FullCustomerListBox" >
      <div className="FullCustomerListTopBoxV2">
        <div className="FullCustomerListHeader fontNB20 col-6" style={{textAlign: "center"}}>
          Vehicles
        </div>
        
        <div onClick={handleShow} className="col-6">
          <AddNewCustomerButtonV2 name="New Vehicle" />
        </div>
      </div>
      {counting !== 0 || !(activeStatus == 3 && assignedStatus == 3) ? (
        <>
          <div className="FUllListSearchBar">
            <SearchBar
              placeholder="Search for vehicles"
              height="40"
              value={search}
              setSearch={setSearch}
              onChange={(event) => {
                //dispatch(clearAllDrivers());
                dispatch(
                  sendAnalitics("SearchBar_MV_FW_Web", {
                    searchVeh: event.target.value,
                  })
                );
                setSearch(event.target.value);
              }}
              findSearch={findSearch}
            />
          </div>
          <div className="" style={{ marginTop: -16 }}>
            <button
              className={`Medium textDiv1 ${
                activeStatus == 3 && assignedStatus == 3
                  ? "greenButton"
                  : "greenOutlineButton"
              } rounded-pill ms-3`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setActiveStatus(3);
                setAsssignedStatus(3);
              }}
            >
              All
            </button>
            <button
              className={`Medium textDiv1 ${
                activeStatus == 1 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setActiveStatus(1);
              }}
            >
              Active
            </button>
            <button
              className={`Medium textDiv1 ${
                activeStatus == 0 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setActiveStatus(0);
              }}
            >
              InActive
            </button>
            <button
              className={`Medium textDiv1 ${
                assignedStatus == 0 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setAsssignedStatus(0);
              }}
            >
              Not Assigned
            </button>
            <button
              className={`Medium textDiv1 ${
                assignedStatus == 1 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setAsssignedStatus(1);
              }}
            >
              Assigned
            </button>
          </div>
          <div className="customer-list" style={{marginTop:"15px"}}>
            <ManageVehicleDetailsCard
              search={search}
              offset={offset}
              setOffset={setOffset}
              nextTrigger={props.nextTrigger}
              allowTrigger={props.allowTrigger}
              openLink={openLink}
              setOpenLink={setOpenLink}
              fleetId={fleetId}
              setFleetId={setFleetId}
              vehCat={vehCat}
              setVehCat={setVehCat}
              regNumber={regNumber}
              setRegNumber={setRegNumber}
              color={color}
              setColor={setColor}
              setVehRank={props.setVehRank}
              activeStatus={activeStatus}
              assignedStatus={assignedStatus}
            ></ManageVehicleDetailsCard>
          </div>

          <AddNewVehicleModal
            open={open}
            onHide={handleClose}
            toggleModal={toggleModal}
            setOffset={setOffset}
            setRegNumber={setRegNumber}
            setVehCat={setVehCat}
            setFleetId={setFleetId}
            setColor={setColor}
            search={search}
            setOpenLink={setOpenLink}
          />
          {openAutoRecharge ? (
            <AutorechargeHome
              open={openAutoRecharge}
              onHide={closeRecharge}
              toggleModal={toggleRecharge}
              autoState={autoRechargeState ? true : false}
              showRechargeVeh={showRechargeVeh}
              showRechargeOne={showRechargeOne}
              setVehNo={setVehNo}
              setLimit={setLimit}
              setVehRank={setRechargeRank}
            />
          ) : null}
          {openRechargeVehicles ? (
            <AutorechargeSelectVehicles
              open={openRechargeVehicles}
              onHide={closeRechargeVeh}
              toggleModal={toggleRechargeVeh}
            />
          ) : null}

          <AutorechargeConfirm
            open={openRechargeOne}
            onHide={closeRechargeOne}
            toggleModal={toggleRechargeOne}
            vehNo={vehNo}
            limit={limit}
            vehRank={rechargeRank}
            fleetId={rechargeFleetId}
          />
        </>
      ) : (
        <div className="padding32 " style={{ paddingBottom: "30rem" }}>
          <div className="autoRechargeBtn padding16 heading3 Medium">
            No vehicle present. Please add them.
          </div>
        </div>
      )}
       <Requests
     toggle={showRequests}
     modal={showRequest}
     setModal={setshowRequest}
     regNo={props.regNo}
     fleetId={fleetId}
     // customerId={customerId}
   />
    </div>
    
  );

 
  // return (
  //   <div className="FullVehicleListBox">
  //     <div className="FullVehiclesListTopBox">
  //       <div className="FullVehiclesListHeader">Full Vehicles List</div>
  //       <div className="FullVehiclesListAddButton" onClick={handleShow}>
  //         <AddNewCustomerButton name="Add New Vehicle" />
  //         {
  //           <AddNewVehicleModal
  //             open={open}
  //             onHide={handleClose}
  //             toggleModal={toggleModal}
  //           />
  //         }
  //       </div>
  //     </div>
  //     <div className="FullVehiclesListSearchBar">
  //       <SearchBar
  //         placeholder="Search for Vehicles"
  //         height="46"
  //         findSearch={findSearch}
  //         onChange={(event) => {
  //           //dispatch(clearAllFleets());
  //           setSearch(event.target.value);
  //         }}
  //       />
  //     </div>
  //     <div className=""></div>
  //     <div className="FullVehicleDetailsListBar">
  //       <ManageVehicleListBar />
  //     </div>
  //     <div className="FullVehicleDetailsList_driverListTable">
  //       <div>
  //         <ManageVehicleDetailsCard
  //           //handleUserId={handleUserId}

  //         />
  //       </div>
  //     </div>
  //   </div>
  // );
}
export default FullVehicleList;
