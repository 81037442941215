import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { cleanExpenseSumm, cleanExpenseWalletSummary, expenseSumm, getExpenseWalletSummary } from "../../../../../../services/Store/Actions/driverslistActions";
import "./index.css";
import moment from "moment";
import { Button, Table } from "reactstrap";
import NoTransaction from "./NoTransaction";
import SvgImage from "../../../../../Icons/Icons";
import { cleanOmcTransactionsAction, omcVehicleTransactionAction } from "../../../../../../services/Store/Actions/omcActions";


const HPCLFuelCardTable = (props) => {

  const{setCardChosen, cardChosen}= props;
  const [offset, setOffset] = useState(0);
  // const [filter, setFilter] = useState(0);
  // const [start, setStart]= useState("");
  // const [end, setEnd]= useState("");
  const omcTransaction = useSelector((state) => state?.omc?.omcTransaction);
  const trxnCount =
  omcTransaction?.count === undefined
      ? 0
      : omcTransaction?.count;
  //const allExpensesList = expenseSummDetail?.expense;
  const newExpensesFetched = omcTransaction?.rows;


  let { id } = useParams();
  const dispatch= useDispatch();

  useEffect(() => {
    if (id !== "" && id !== undefined && id !== null) {
      setOffset(0);
      dispatch(cleanOmcTransactionsAction());
      dispatch(
        omcVehicleTransactionAction(id, {
          offset: 0,
          // fromDate: "",
          // toDate: "",
          sortAs: "",
          sortBy: "",
        })
      );
    } else {
      setOffset(0);
      dispatch(cleanOmcTransactionsAction());
    }
  }, [id]);

  const handlePrev=()=>{
    if(offset>0){
      dispatch(
        omcVehicleTransactionAction(id, {
          offset: offset-10,
          // sortAs: "",
          // sortBy: "",
        }, (res)=>{
          if(res?.status==200){
            setOffset(offset-10);
          }
        })
      );
    }
  }

  const handleNext=()=>{
    if(trxnCount-offset>10){
      dispatch(
        omcVehicleTransactionAction(id, {
          offset:offset+10,
          // fromDate: "",
          // toDate: "",
        }, (res)=>{
          console.log()
          if(res?.status==200){
            setOffset(offset+10);
          }
        })
      );
    }
  }

console.log("omc transactions",omcTransaction);
  return (
    <>
    <div className=" flexStyling new-row-for-download padding16R padding16T" >
        <div className="Bold heading2 padding16 " style={{paddingLeft:"0"}}>Transaction History</div>
        <div className="flex1"></div>
      </div>
      <div className="row margin0 padding16B padding0L">
          <div className="col-2n5 padding0L" onClick={()=>{
              setCardChosen(0)
          }}>
              <Button className={cardChosen==0?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
                  Pre-paid Card</Button>
          </div>
          {/* <div className="col-2n5"  onClick={()=>{
              setCardChosen(1)
          }}>
              <Button className={cardChosen==1?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
                  HPCL Fuel Card</Button>
          </div> */}
        </div>
    {
      trxnCount==undefined?
        <NoTransaction />
      :   trxnCount==0?
        <NoTransaction />
      :
      <div>
     <Table className="DriverSectionTable" bordered="true" id="driver_tables">
        <thead >
        <tr className="table-header-customer-details-dr">
            <th style={{width:"19%"}} >Date & Time</th>
            <th > <div style={{marginLeft:"rem"}}>Expense Type</div> </th>
            {/* <th>Remark</th> */}
            <th style={{width:"15%"}}>Debit (INR)</th>
            <th style={{width:"15%"}}>Credit (INR)</th>
            <th style={{width:"19%"}}>Closing Balance (INR)</th>
        </tr>
        </thead>
        <tbody className="lowerBorderLine">
        {newExpensesFetched?.map((d) => {

            // let remarks=d?.description;
            let closingBal=d?.balance;
            let details= d?.description;
            let amount=d?.amount;
            let type=d?.transferType;
            let date=moment(d?.transactionDate.split(" ")[0], [
              "YYYY-MM-DD",
            ]).format("DD-MM-YYYY");
            let time=moment(d?.transactionDate.split(" ")[1], [
              "hh:mm:ss",
            ]).format("hh:mm:ss a");

              return (
                <tr className="table-row-manage-driver-new text4">
                  <td style={{width:"19%"}}>
                    <div>{date}</div>
                    <div>{time}</div>
                  </td>
                  <td>{details}</td>
                  {/* <td>{remarks}</td> */}
                  <td style={{width:"15%"}}>{type=="debit"?amount:"-"}</td>
                  <td style={{width:"15%"}}>{type=="credit"?amount:"-"}</td>
                  <td style={{width:"19%"}}>{closingBal}</td>
                </tr>
              );
            })} 
        </tbody>
      </Table>
      <div className="row margin0 padding16" >
          <div className="col-4" style={{textAlign:"right", paddingTop:"0.1rem", cursor:offset>0?"pointer":"no-drop" , opacity:offset>0?"1":"0.5"}}
            onClick={handlePrev}
            >
            <SvgImage name="PrevArrow" />
          </div>
          <div className="col-1 greenFont semiBold heading4 padding0L" style={{textAlign:"right", paddingTop:"0.3rem", cursor:offset>0?"pointer":"no-drop", opacity:offset>0?"1":"0.5"}} 
          onClick={handlePrev}>
              Previous
          </div>
          <div className="col-2 row margin0">
            <div className="greenBoxCount Medium heading4 col-4" style={{width:"2rem", height:"2rem", alignItems:"center", textAlign:"center"}}>{(offset/10)+1}</div>
            <div className=" col-8 padding0R"  style={{ paddingTop:"0.3rem", textAlign:"right"}}>of  {trxnCount%10==0?Math.floor((trxnCount/10)):Math.floor((trxnCount/10)+1)}</div>
          </div>
          <div className="col-1 greenFont semiBold heading4 padding0R" style={{textAlign:"left", paddingTop:"0.3rem", cursor:trxnCount-offset>10?"pointer":"no-drop",opacity:trxnCount-offset>10?"1":"0.5"}}
            onClick={handleNext}>
              Next
          </div>
          <div className="col-4 padding0L" style={{textAlign:"left", marginLeft:"-1rem", paddingTop:"0.1rem", cursor:trxnCount-offset>10?"pointer":"no-drop",opacity:trxnCount-offset>10?"1":"0.5"}}
              onClick={handleNext}>
            <SvgImage name="NextArrow" />
          </div>
      </div>
      </div>
      }
    </>
  );
};

export default HPCLFuelCardTable;
