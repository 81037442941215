import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { Button } from "reactstrap";
import SvgImage from "../../../../../../Icons/Icons";
import { M2PCardDetailsAction } from "../../../../../../../services/Store/Actions/m2pAction";
import CardControlsModal from "../../../../../../Modals/CardControlsModal";
import ClipLoader from "react-spinners/ClipLoader";


const M2PCardNew=(props)=>{

    const { driverDetails, driverCardStatus, userId}= props;
    const status = driverCardStatus===4?true:false;
    const m2pCardDetails= useSelector((state)=>state?.m2p?.m2pCardDetails);

    const m2pLoader= useSelector((state)=>state?.m2p?.loader);
    const dispatch= useDispatch();
    useEffect(() => {
        dispatch(M2PCardDetailsAction(userId));
      }, [userId,status]);


    const handleClick=()=>{
    window.open(m2pCardDetails?.data?.result);
    window.location.reload();
    }  

    const drName=driverDetails?.name==undefined?"No Driver":driverDetails?.name;

    const [open, setOpen]= useState(false);

    const handleClose=()=>{
        setOpen(false);
    }

    const toggleModal=()=>{
        setOpen(!open);
    }

    const showModal=()=>{
        setOpen(true);
    }
    return(
        <>
        <div className="cardContainerNew " style={{position:"relative"}}>
            <div className="row">
                <div className="col-10 cardNameDr Medium heading3 padding16T">{drName.length>30?drName.split(0,30)+"...":drName}</div>
                <div className="col-2 padding16T" style={{cursor:"pointer"}} onClick={showModal}>
                    <SvgImage name="cardSettingOpt" />
                </div>
            </div>
            <div className="whiteText Medium heading3 padding24T padding8B">Kit No: {
                m2pLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={m2pLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:m2pCardDetails?.kitNo}</div>
            <div className="row padding32B">
                <div className="col-8 padding16B padding16T" style={{cursor:"pointer"}}>
                    <div className="cardNameDr text4" onClick={handleClick}>Click to view card details</div>
                    {/* <div className="whiteText Medium heading3">02/27</div> */}
                </div>
            </div>
            <div style={{position:"absolute", bottom:"0", right:"0", zIndex:"300"}}>
                <SvgImage name="titledRect1" />
            </div>
            <div style={{position:"absolute", bottom:"0", right:"0",  zIndex:"300"}}>
                <SvgImage name="titledRect2" />
            </div>
        </div>
        {
        <CardControlsModal
        userId={userId}
        open={open}
        toggleModal={toggleModal}
        title="Logipe Account"
        handleClose={handleClose}
        cardType={props.cardType}
        />
        }
        </>
    )
}

export default M2PCardNew;