import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup } from "reactstrap";
import PinInput from "react-pin-input";
import { Formik } from "formik";
import SvgImage from "../../../Icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import * as Yup from "yup";
import {
  createPaymentTokenAction,
  virtualAccountCredit,
} from "../../../../services/Store/Actions/receivableActions";
import { Link } from "react-router-dom";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";

import { cashfreeProd } from 'cashfree-pg-sdk-javascript'
import { Helmet } from "react-helmet";

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError("Only Numbers are allowed")
    .required("Required")
    .min(1, "Amount should be greater than 1"),
});

const UpiAddBalance = (props) => {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    amount,
    ownerLogipeBalance,
    color,
  } = props;

  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state?.receivable);
  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.userId;
  const phoneSession = obj?.user?.phone;
  // const ownerBalance = ownerLogipeBalance === "" ? 0 : ownerLogipeBalance;
  //console.log("blanace", ownerBalance);
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;

    document.body.appendChild(script);
    dispatch(sendAnalitics("PG_MA_Web"));

    return () => {
      document.body.removeChild(script);
    }

  }, []);

  return (
    <div>
      {/* <Helmet>
        <script src="" type="text/javascript" />
      </Helmet> */}
      <div className="card-wallet-icon-body1">
        <SvgImage
          name="BankLogo"
          height="43.81"
          width="43.81"
          classname="card-wallet-logo-BankLogo"
        />
        <div>
          <p className="body-sub-part1">Owner’s Master Account Balance</p>
          <p className="body-sub-part2">{"₹" + ownerLogipeBalance}</p>
        </div>
      </div>

      <div className="sub-title-1">
        <span class="circle-logo">1</span>
        <p className="sub-head1">Add Balance Via</p>
      </div>

      <div style={{ marginLeft: "5rem", display: "flex" }}>
        <div style={{ padding: "0.5rem 0.3rem" }}>
          <Button
            className={btn === 0 ? "btnSelect" : "btnNotSelect"}
            onClick={() => handleClick(0)}
          >
            NEFT/RTGS
          </Button>
        </div>
        <div style={{ padding: "0.5rem 0.3rem" }}>
          <Button
            className={btn === 1 ? "btnSelect" : "btnNotSelect"}
            onClick={() => handleClick(1)}
          >
            UPI
          </Button>
        </div>
        <div style={{ padding: "0.5rem 0.3rem" }}>
          <Button
            className={btn === 2 ? "btnSelect" : "btnNotSelect"}
            onClick={() => handleClick(2)}
          >
            Net Banking/ Debit Card
          </Button>
        </div>
      </div>

      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          amount: "",
          typeOfOrder: "2",
          recieverUserId: userIdSession,
          customerPhone: phoneSession,
          remarks: "",
        }}
        onSubmit={(values, actions) => {
          console.log("values onSubmit", values);
          // dispatch(
          //   virtualAccountCredit(values, (res) => {
          //     actions.setSubmitting(false);
          //     //console.log("dte", res);
          //     if (res?.data?.type === "success") {
          //       console.log("ressspp", res?.data?.data?.link);
          //       // window.location.replace(res?.data?.data?.link);
          //       window.open(res?.data?.data?.link);
          //       window.location.reload();
          //     }
          //   })
          // );
          dispatch(
            createPaymentTokenAction(values,async (res) => {
              actions.setSubmitting(false);
              dispatch(
                    sendAnalitics("add_bal_LPAcc2_Web", {
                      netBank_amount: values.amount,
                      remarks: values.remarks,
                    })
                  );
              //console.log("dte", res);
              if (res?.data?.type === "success") {
                console.log("ressspp 1", res);
                console.log("ressspp 2", res?.data?.data?.payment_session_id);
                const paymentSessionId = res?.data?.data?.payment_session_id;
                console.log("ressspp 3", typeof paymentSessionId);
                const cashfree = new window.Cashfree({
                  mode : "production"
                });
              
            console.log("cahss", cashfree)
            let checkoutOptions = {
              paymentSessionId: paymentSessionId,
              redirectTarget: "_blank	" //optional (_self or _blank)
          }
          
          cashfree.checkout(checkoutOptions).then(function(result){
            if(result.error){
              alert(result.error.message)
            }
            if(result.redirect){
              console.log("Redirection")
            }
          });

                // console.log("ressspp 4", cashfree1);
                // window.open(cashfree.serverURL)
                // cashfree.redirect();
                //console.log("ressspp 2", res?.data?.data?.payment_link);

                // window.location.replace(res?.data?.data?.link);
                // window.open(cashfree);
                // window.location.reload();
              }
            })
          );
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">2</span>
                <p className="sub-head-3">Enter amount</p>
              </div>
              <p className="sub-head-body">
                Amount will be added to owner’s LogiPe account
              </p>

              <label for="html" className="label-body">
                ₹
              </label>
              <input
                type="text"
                id="amount"
                className={`input-body ${touched.amount && errors.amount ? "is-invalid" : ""
                  }`}
                name="amount"
                onChange={handleChange("amount")}
                value={values.amount}
                onBlur={() => {
                  dispatch(
                    sendAnalitics("Enter_Amt_Web", {
                      upi_netBank_amount: values.amount,
                    })
                  );
                  handleBlur("amount");
                }}
                touched={touched.amount}
                errors={errors.amount}
                placeholder="Enter amount"
              />
              <div className="input-amount"></div>
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.amount && touched.amount ? (
                  <div>*{errors.amount}</div>
                ) : null}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">3</span>
                <p className="sub-head-3">Enter Remarks/comment</p>
              </div>
              {/* <p className="sub-head-body">
                      Amount will be added to driver’s LogiPe Card
                    </p> */}

              <label for="html" className="label-body"></label>
              <input
                type="text"
                id="remarks"
                className={`input-body `}
                name="remarks"
                onChange={handleChange("remarks")}
                value={values.remarks}
                onBlur={() => {
                  dispatch(
                    sendAnalitics("remark_DC_MA_Web", {
                      remark_DC_MA_Web: values.remarks,
                    })
                  );
                  handleBlur("remarks");
                }}
                touched={touched.remarks}
                errors={errors.remarks}
                placeholder="Enter remarks"
              />
              <div className="input-amount"></div>
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.remarks && touched.remarks ? (
                  <div>*{errors.remarks}</div>
                ) : null}
              </div>
            </FormGroup>
            <FormGroup>
              <button
                type="submit"
                className="butt4"
                disabled={loader ? true : false}
                onClick={() => {
                  dispatch(
                    sendAnalitics("add_bal_LPAcc1_Web", {
                      netBank_amount: values.amount,
                      remarks: values.remarks,
                    })
                  );
                }}
              >
                {loader ? "Please Wait" : "Add Balance to LogiPe Account"}
              </button>
            </FormGroup>

            <div className="space-div"></div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpiAddBalance;
