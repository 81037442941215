import React, { useState, useEffect, useRef } from "react";
import SvgImage from "../../../../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import {
  cleanLoginLoader,
  handleLoginSuccess,
  sendOtp,
  verifyOtp,
} from "../../../../services/Store/Actions/LoginAction";
import { useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { Mixpanel } from "../../../../Mixpanel";
import { cleanOnboardingLoaderAction, downloadAadharXMLAction, getAadharOtpAction } from "../../../../services/Store/Actions/onboardingAction";


const KYCPart2=(props)=>{
    const validationSchema = Yup.object().shape({
        otp: Yup.string()
        .required("OTP is required")
        .matches(/^[0-9]{6}$/, "OTP must be exactly 6 digits"),
      });
    
    const dispatch = useDispatch();
    const { loader } = useSelector((state) => state?.onboarding);
    const [timer, setTimer] = useState("00:00");
    const [error, setError] = useState(false);
    const Ref = useRef(null);
    const {aadhaarNo, requestId, setRequestId, setRegStatus} = props;
    const Navigate = useNavigate();
  

    const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    //const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
        total,
        minutes,
        seconds,
    };
    };
    const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
        setTimer(
        //(hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : "0" + minutes) +
            ":" +
            (seconds > 9 ? seconds : "0" + seconds)
        );
    }
    };  


    const clearTimer = (e, val) => {
    setTimer(val);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
        startTimer(e);
    }, 1000);
    Ref.current = id;
    };

    const getDeadTime = (value) => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + value);
    return deadline;
    };

    useEffect(() => {
    clearTimer(getDeadTime(30), "00:30");
    dispatch(cleanOnboardingLoaderAction());
    }, []);


    function handleResend() {
        //console.log ("yeh ph no", props.phoneNo);
        dispatch(sendAnalitics("ResendOTP_web"));
        setTimer("00:00");
        dispatch(
          getAadharOtpAction(aadhaarNo, (res) => {
            console.log("chhhhhhhhh", res);
            if(res?.status==400){

            }
            else if (res?.data?.type === "success") {
              console.log("resend ho gaya");
              setRequestId(res?.data?.data?.requestId)
            }
          })
        );
        clearTimer(getDeadTime(60), "00:60");
      }


      return (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            otp: "",
          }}
          onSubmit={(values, actions) => {
            //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
            console.log("valuessss", values);
            // dispatch(sendAnalitics("Submit_web", values));
            dispatch(
              downloadAadharXMLAction({
                "requestId": requestId,
                "otp": values.otp,
                "aadhar": aadhaarNo
              }, async (res) => {
                if (res.status == 401) {
                  actions.setFieldError("otp", "Incorrect OTP. Please check again.");
                  dispatch(cleanOnboardingLoaderAction());
                } else if (res?.data?.type === "success") {
                  setRegStatus(5);
                }else{
                  actions.setFieldError("otp", "Incorrect OTP. Please check again.");
                  dispatch(cleanOnboardingLoaderAction());
                }
              })
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit} className="InputForm2">
              <FormGroup className="formPosition">
                <div className="heading1 semiBold">Aadhar OTP verification</div>
                <div className="Regular text3 padding8T headText2" s>
                Enter the OTP sent to aadhaar registered number 
                </div>
                <div className="otpInput" style={{marginLeft:"-24px"}}>
                    <PinInput
                      length={6}
                      initialValue=""
                      secret={false}
                      onChange={handleChange("otp")}
                      type="numeric"
                      value={values.otp}
                      onBlur={handleBlur("otp")}
                      touched={touched.otp}
                      errors={errors.otp}
                      inputMode="number"
                      style={{
                        padding: "10px 0px 10px 0px",
                      }}
                      inputStyle={{
                        borderBottom: "1px solid #C8C8CA",
                        borderTop:"none",
                        borderRight:"none",
                        borderLeft:"none",
                        marginLeft: "25px",
                        marginRight:"0px",
                        width:"40px"
                      }}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      focus={true}
                    />
                    {/* <input
                      type="text"
                      id="otp"
                      className="input-body-3"
                      name="otp"
                      value={values.otp}
                      touched={touched.otp}
                      errors={errors.otp}
                      onBlur={handleBlur("otp")}
                      onChange={handleChange("otp")}
                      style={{ textAlign: "left", marginLeft: "0px" }}
                      placeholder="Phone Number"
                      maxLength="10"
                    ></input> */}
                </div>
                <div className="padding16T text4">
                  Resend OTP in <span className="greenText">{timer}</span>
                </div>
                <div
                  className="text4"
                  style={{
                    margin: "0.3rem 0 0 0",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {errors.otp && touched.otp ? <div>{errors.otp}</div> : null}
                </div>
                {/* <div className="redColor" style={{visibility:error?"visible":"hidden"}}>
                  {error?"Cannot login through Driver's number":null}</div> */}
                <Button
                  type="submit"
                  className="loginButton"
                  style={{
                    marginTop: "1.5rem",
                    background: values.otp.length < 6 ? "#EFEFEF" : (loader ? "#EFEFEF":"#485B7C") ,
                    color: values.otp.length < 6 ? "#B5B5B5" : (loader ? "#B5B5B5":"#ffffff"),
                  }}
                  disabled={loader ? true : false}
                >
                  {loader ? "Please Wait" : "Verify OTP"}
                </Button>
                <div
                  className="bottomTextLogin padding16T Regular text4"
                  style={{ color: "#25252D" }}
                >
                  Didn’t recieve the code?
                  <span
                    style={{
                      color: timer === "00:00" ? "#2E7C7B" : "#B5B5B5",
                      cursor: timer === "00:00" ?"pointer":"default",
                      paddingLeft: "0.5rem",
                    }}
                    className="Medium"
                    onClick={handleResend}
                  >
                    Resend
                  </span>
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      );
}

export default KYCPart2;