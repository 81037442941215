import React, { useState,useEffect } from "react";
import copy from "copy-to-clipboard";
import { useDispatch } from "react-redux";
import "./index.css";
import AddNewAccount from "../../../../Modals/addNewAccount";
import SvgImage from "../../../../Icons/Icons";
import { sendAnalitics } from "../../../../../services/Store/Actions/appActions";
import { Button } from "reactstrap";

function CompanyAccountNotConnected(props) {
  const [addBeneficiary, setAddBeneficiary] = useState(false);
  function handleAddBeneficiary(opt) {
    setAddBeneficiary(true);
    //toggleModal();
  }
  function handleCloseAddBeneficiary() {
    setAddBeneficiary(false);
  }
  function toggleModalAddBeneficiary() {
    setAddBeneficiary(!addBeneficiary);
  }

  const {
    virtuAccount,
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    amount,
    ownerLogipeBalance,
    accountStatus,
    color,
    copyIcon,
    setCopyIcon,
    copyIconIfsc,
    setCopyIconIfsc,
  } = props;

  //console.log ("in the neft component-----------",ownerLogipeBalance);
  const dispatch = useDispatch();
  // const ownerBalance = ownerLogipeBalance === "" ? 0 : ownerLogipeBalance;
  console.log("blanace", virtuAccount);

  useEffect(()=>{
    dispatch(sendAnalitics("NEFT/RTGS_MA_Web"));
  },[]);

  return (
    <div>
      <div className="heading3 padding24 padding8T padding8R Medium">
        <div className="extraInfo padding8">
          Please make transactions below ₹10,00,000 at once
        </div>
      </div>
      <div
        className="sub-head1 padding0T padding16B"
        style={{ paddingBottom: "2rem", marginTop:"0px" }}
      >
        Add Balance Via
      </div>
      <div style={{marginLeft:"5rem", display:"flex",paddingTop:"1rem"}}>
      <div  style={{padding:"0.5rem 0.3rem"}}>
          <Button className={btn === 0?"btnSelect":"btnNotSelect"} 
            onClick={() => handleClick(0)}>
            NEFT/RTGS
          </Button>
        </div>
        <div style={{padding:"0.5rem 0.3rem"}}>
          <Button className={btn === 1?"btnSelect":"btnNotSelect"} 
            onClick={() => handleClick(1)}>
            UPI
          </Button>
        </div>
        <div style={{padding:"0.5rem 0.3rem"}}>
          <Button className={btn === 2?"btnSelect":"btnNotSelect"} 
            onClick={() => handleClick(2)}>
            Net Banking/ Debit Card
          </Button>
        </div>
      </div>
      <div style={{paddingBottom:"1rem"}} >
        <div className="masterAccountImage">
          <SvgImage name="newMasterAccount" />
        </div>
        <div className="Regular heading3 greyFont masterAccountAddText">
          To add balance through NEFT/RTGS please enter your company’s current
          account details with LogiPe
        </div>
        <button type="button" className="butt4" onClick={handleAddBeneficiary}
          style={{marginTop:"1.5rem"}}>
          Connect Company’s Current Account
        </button>
        {
          <AddNewAccount
            open={addBeneficiary}
            toggleModal={toggleModalAddBeneficiary}
            handleClose={handleCloseAddBeneficiary}
            //handleClick={handleClick}
            //btn={btn}
            ownerLogipeBalance={ownerLogipeBalance}
            caller="neftBankLink"
          />
        }
      </div>
    </div>
  );
}

export default CompanyAccountNotConnected;
