import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import NoBeneficiaryInfo from "./NoBeneficiaryInfo";
import SvgImage from "../../../../../../../Icons/Icons";
import { getDriverBeneficiaryAction } from "../../../../../../../../services/Store/Actions/omcActions";
import { useParams } from "react-router-dom";
import ExistingBeneficiaryInfo from "./ExistingBeneficiaryInfo";
import BankTransferData from "./BankTransferData";




const BankTransferModal=(props)=>{
  const { open, toggleModal, handleClose } = props;

  const dispatch = useDispatch();
  const [status, setStatus]= useState(0);
  const { loader } = useSelector((state) => state?.omc);
  console.log("button status", loader);

  const {id}= useParams();
  useEffect(()=>{
    dispatch(getDriverBeneficiaryAction({
      offset: 0,
      search: "",
      limit:10
    }))
  },[id])

  useEffect(()=>{
    setStatus(0)
  },[open])
  const driverBeneficiaryList=useSelector((state)=>state?.omc?.driverBeneficiaryList);
  console.log("bene data", driverBeneficiaryList?.beneficiaries?.count);
  console.log("bene data", driverBeneficiaryList);


  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Bank Transfer</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody style={{position:"relative"}}>
            {
              driverBeneficiaryList?.count==0?
              <NoBeneficiaryInfo 
              newToggleModal={toggleModal}
              />
              : 
                status==0?
                <ExistingBeneficiaryInfo 
                  setStatus={setStatus}
                />
                :<BankTransferData 
                toggleModal={toggleModal}
                  />
            }
            
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default BankTransferModal;
