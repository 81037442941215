import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Field, ErrorMessage } from "formik";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { useSelector, useDispatch } from "react-redux";
import {
  registerYesbankAction,
  reissueAvailableAction,
  sendFastagOTPAction,
} from "../../../services/Store/Actions/fastagAction";
import { useParams } from "react-router-dom";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";

function LinkFastagHome(props) {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    handleShowOTP,
    handleShowIssue,
    reIssue,
    setBank,
  } = props;

  const vehicleData = useSelector((state) => state?.manageVehicle);
  const regNumber = vehicleData?.vehicleReceivableHistory?.fleet?.regNumber;
  const customerId = useSelector(
    (state) => state?.fastag?.fastagWallet?.customerId
  );
  const customerIdM2p = useSelector(
    (state) => state?.fastag?.fastagWallet?.customerIdM2P
  );
  const isReissueAvailable = useSelector(
    (state) => state?.fastag?.isReissueAvailable
  );
  console.log("reissue", isReissueAvailable);
  console.log("customerId", customerIdM2p);
  //console.log("isCorporate",typeof isCorporate);
  const hotlistStatus = isReissueAvailable?.npciStatus;
  const reissueStatus = isReissueAvailable?.status;
  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  console.log("vvvvvvvvvvvvvvvvvvvvvvvv", obj);
  const mob = obj?.user?.phone;
  const companyId= obj?.user?.companyId;
  const userId=obj?.user?.userId;
  const dispatch = useDispatch();
  //console.log("val--------------", userId);
  const handleClickYesBank = () => {
    dispatch(sendAnalitics("YBL_AddVeh_MV_FW_Web"));
    setBank("YesBank");
    if (customerIdM2p !== "LOGIPE") {
      dispatch(
        registerYesbankAction({
          userId:userId,
          companyId:companyId
        },(res)=>{
          console.log("res", res);
              if (res?.data?.type === "success") {
                
              }
        })
      );
      
    } else handleShowIssue("kyc done");
  };

  const handleClickIDFC0 = () => {
    dispatch(sendAnalitics("IDFC_AddVeh_MV_FW_Web"));
    setBank("idfc");
    if (customerId == null) {
      dispatch(
        sendFastagOTPAction({
          mobNo: mob,
        })
      );
      handleShowOTP();
    } else handleShowIssue("kyc done");
  };

  let { id } = useParams();
  useEffect(() => {
    if(id){
      if(regNumber){
        dispatch(
          reissueAvailableAction({
            fleetId: id,
            vrn: regNumber,
          })
        );
      }
    }
  }, []);

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Issue Fastag</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            {/* <div className="yesBank padding32" style={{ paddingBottom: "0" }}>
              {customerIdM2p !== null ? (
                <div className="kyc FontSmall"> KYC Done</div>
              ) : (
                <></>
              )}
              <div
                className="row fastagHomeBox padding8"
                onClick={handleClickYesBank}
                style={{ cursor: "pointer" }}
              >
                <div className="col-2" style={{ paddingTop: "0.2rem" }}>
                  <SvgImage name="yesBankLogo" />
                </div>
                <div className="col-8 padding16T padding16B">
                  On VRN - YES Bank
                </div>
                <div className="col-2 padding16T padding16B ">
                  <SvgImage name="BankArrow" />
                </div>
              </div>
            </div>
            {reissueStatus === 0 ? (
              <div
                className="idfcOnVRN padding32"
                style={{ paddingBottom: "0" }}
              >
                {customerId !== null ? (
                  <div className="kyc FontSmall"> KYC Done</div>
                ) : (
                  <></>
                )}
                <div
                  className="row fastagHomeBox padding8"
                  onClick={handleClickIDFC0}
                  style={{ cursor: "pointer" }}
                >
                  <div className="col-2" style={{ paddingTop: "0.2rem" }}>
                    <SvgImage name="idfcBankLogo" />
                  </div>
                  <div className="col-8 padding16T padding16B">
                    On VRN - IDFC Bank
                  </div>
                  <div className="col-2 padding16T padding16B ">
                    <SvgImage name="BankArrow" />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {reissueStatus === 1 && reIssue === 0 ? (
              <div
                className="idfcRe-issue padding32T"
                style={{
                  paddingBottom: "0",
                  paddingLeft: "1.2rem",
                  paddingRight: "1.2rem",
                }}
              >
                {customerId !== null ? (
                  <div className="kyc FontSmall"> KYC Done</div>
                ) : (
                  <></>
                )}
                <div className="fastagHomeBox" onClick={handleClickIDFC0}>
                  <div className="row  padding8 ">
                    <div
                      className="col-2 padding0R"
                      style={{ paddingTop: "0.2rem", textAlign: "right" }}
                    >
                      <SvgImage name="idfcBankLogo" />
                    </div>
                    <div className="col-8 padding16T padding16B padding16L">
                      Re-issue on VRN - IDFC Bank
                    </div>
                    <div className="col-2 padding16T padding16B padding8L">
                      <SvgImage name="BankArrow" />
                    </div>
                  </div>
                  <div className="line-for-separation"></div>
                  <div className="row padding8">
                    <div
                      className="col-1 padding0R padding32L"
                      style={{ textAlign: "right" }}
                    >
                      <SvgImage name="remarkExclamation" />
                    </div>
                    <div
                      className="col-8 FontSmall"
                      style={{ paddingTop: "0.2rem" }}
                    >
                      Only use FASTag mapped for Re-issue
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {reissueStatus === 2 || reIssue === 1 ? (
              <div
                className="idfconChassis padding32"
                style={{
                  paddingBottom: "0",
                  paddingLeft: "1.2rem",
                  paddingRight: "1.2rem",
                }}
              >
                {customerId !== null ? (
                  <div className="kyc1 FontSmall"> KYC Done</div>
                ) : (
                  <></>
                )}
                <div className="fastagHomeBox " onClick={handleClickIDFC0}>
                  <div className="row padding8">
                    <div
                      className="col-2 padding0R"
                      style={{ paddingTop: "0.2rem", textAlign: "right" }}
                    >
                      <SvgImage name="idfcBankLogo" />
                    </div>
                    <div className="col-8 padding16T padding16B padding16L">
                      On Chassis - IDFC Bank
                    </div>
                    <div className="col-2 padding16T padding16B padding8L">
                      <SvgImage name="BankArrow" />
                    </div>
                  </div>
                  <div className="line-for-separation"></div>
                  <div className="row padding8">
                    <div
                      className="col-1 padding0R padding32L"
                      style={{ textAlign: "right" }}
                    >
                      <SvgImage name="remarkExclamation" />
                    </div>
                    <div
                      className="col-11\ FontSmall"
                      style={{ paddingTop: "0.2rem" }}
                    >
                      We request you to close the previous tag issued on VRN
                      within 45 days to continue using LogiPe FASTag.
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )} */}
            <div className="row" style={{textAlign : "center"}}>
              <div className="col-12">
                <img src = {require('../../../assets/images/Logipe_App_qr.png')} className="qrImg"/>
              </div>
              <div className="col-12 qrText">
                Please Scan The QR
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
export default LinkFastagHome;
