import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import AddFreight from "../AddFreight";
import { getVehicleListAction } from "../../../../services/Store/Actions/manageVehicleAction";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import {
  createTrip,
  findTripAddress,
  getCityAndState,
  getTripsNew,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
import AddDriverV2 from "../AddDriverV2";
import AddVehicleV2 from "../AddVehicleV2";
import Toast from "../../../subComponents/toast";
import AddAddress from "../AddAddress";
import ReactPaginate from "react-paginate";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
function TripModalV2(props) {
  const {
    toggle,
    modal,
    setModal,
    location,
    customer,
    lr,
    toggleOpenLrList,
    chooseRouteModalToggle,
    setNewLr,
  } = props;
  const [openToast, setOpenToast] = useState(false);
  const [openToastFailure, setOpenToastFailure] = useState(false);
  const [openToastFailureText, setOpenToastFailureText] = useState(
    "Error in creating trips"
  );
  const [tripIdTemp, setTripIdTemp] = useState("");

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [freight, setFreight] = useState(false);
  const [driver, setdriver] = useState(false);
  const [vehicle, setVehicle] = useState(false);
  const [address, setAddress] = useState(false);

  const addFreight = () => {
    console.log("kkkkkkkllll", freight);
    setFreight(!freight);
    setOpenToast(false);
    // setModal(false);
  };
  const addDriver = () => setdriver(!driver);
  const addVehicle = () => setVehicle(!vehicle);

  const addAddress = () => setAddress(!address);
  const dispatch = useDispatch();
  const datepickerRefEnd = useRef();
  const datepickerRefStart = useRef();
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [searchDriver, setSearchDriver] = useState("");

  const [input, setInput] = useState("");
  const [cityId1, setcityId1] = useState("");
  const [cityId2, setcityId2] = useState("");
  const [sourceName, setSourceName] = useState("");
  const [destName, setDestName] = useState("");
  const [cityList, setCityList] = useState({});
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [color1, setColor1] = useState(-1);
  const [color2, setColor2] = useState(-1);
  const [data, setData] = useState();

  // useEffect(() => {
  //   dispatch(findTripAddress())
  //   }, []);
  const tripAddress = useSelector((state) => state?.trip?.tripAddressDetails);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  console.log("trip address here", tripAddress);
  const defaultStates = [
    {
      cityId: 1130,
      cityName: "Delhi",
      cityCode: "",
      stateId: 32,
      lat: "28.557163",
      long: "77.163665",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Delhi",
      },
    },

    {
      cityId: 295,
      cityName: "Bengaluru",
      cityCode: "",
      stateId: 11,
      lat: "12.96557",
      long: "77.60625",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Karnataka",
      },
    },

    {
      cityId: 853,
      cityName: "Chennai",
      cityCode: "",
      stateId: 23,
      lat: "13.066682",
      long: "80.224741",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Tamil Nadu",
      },
    },
    {
      cityId: 383,
      cityName: "Kochi",
      cityCode: "",
      stateId: 12,
      lat: "9.956297",
      long: "76.332486",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Kerala",
      },
    },
    {
      cityId: 1750,
      cityName: "Kolkata",
      cityCode: null,
      stateId: 28,
      lat: "22.51829",
      long: "88.362951",
      createdAt: "2022-09-20 10:11:47",
      State: {
        stateName: "West Bengal",
      },
    },
    {
      cityId: 1083,
      cityName: "Chandigarh",
      cityCode: "",
      stateId: 30,
      lat: "30.728092",
      long: "76.7784",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Chandigarh",
      },
    },
    {
      cityId: 1670,
      cityName: "Hyderabad",
      cityCode: null,
      stateId: 24,
      lat: "17.390617",
      long: "78.470769",
      createdAt: "2022-09-20 10:11:47",
      State: {
        stateName: "Telangana",
      },
    },
    {
      cityId: 566,
      cityName: "Pune",
      cityCode: "",
      stateId: 14,
      lat: "18.519479",
      long: "73.870703",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Maharashtra",
      },
    },
    {
      cityId: 155,
      cityName: "Surat",
      cityCode: "",
      stateId: 7,
      lat: "21.196102",
      long: "72.815766",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Gujarat",
      },
    },
    {
      cityId: 154,
      cityName: "Ahmedabad",
      cityCode: "",
      stateId: 7,
      lat: "23.012369",
      long: "72.561743",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Gujarat",
      },
    },
    {
      cityId: 1658,
      cityName: "Madurai",
      cityCode: null,
      stateId: 23,
      lat: "9.93447",
      long: "78.133299",
      createdAt: "2022-09-20 10:11:47",
      State: {
        stateName: "Tamil Nadu",
      },
    },
    {
      cityId: 1641,
      cityName: "Jaipur",
      cityCode: null,
      stateId: 21,
      lat: "26.894094",
      long: "75.79277",
      createdAt: "2022-09-20 10:11:47",
      State: {
        stateName: "Rajasthan",
      },
    },
    {
      cityId: 1450,
      cityName: "Hisar",
      cityCode: null,
      stateId: 8,
      lat: "29.147086",
      long: "75.733876",
      createdAt: "2022-09-20 10:11:46",
      State: {
        stateName: "Haryana",
      },
    },
  ];
  const { cityAndStateList } = useSelector((store) => store?.trip);

  const allFleets = useSelector(
    (state) => state?.manageVehicle?.allFleets?.rows
  );
  const vehCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );
  console.log("allFlallFleetseets", allFleets);
  const fastVeh =
    vehCount > 0 ? allFleets?.find((ele) => ele?.Fastag !== null) : "value";
  const driverList = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.rows
  );
  const driverCount = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.count
  );
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const [offset, setOffset] = useState(0);
  const closeBtn = (
    <button
      className="close"
      style={{
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        backgroundColor: "#FFF",
        borderColor: "#2E7C7B",
        display: "flex",
        justifyContent: "space-evenly",
      }}
      onClick={toggle}
      type="button"
    >
      <div className="x">X</div>
    </button>
  );

  const validationSchema = Yup.object().shape({
    // tripNo: Yup.string()
    //     .required("TripNo is required"),
    source: Yup.string().required("Source is required"),

    destination: Yup.string().required("Destination is required"),

    fleetId: Yup.string().required("Vehicle is required"),

    driverUserId: Yup.string().required("Driver is required"),

    startDate: Yup.string().required("StartDate is required"),
  });

  const handleClose = () => {
    setModal(!modal);
  };
  useEffect(() => {
    dispatch(
      getVehicleListAction({
        offset: offset,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [search, offset]);
  useEffect(() => {
    dispatch(
      getDriverList({
        offset: "",
        search: searchDriver,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [searchDriver]);
  const pageCount =
    vehCount % 10 === 0 ? vehCount / 10 : Math.floor(vehCount / 10) + 1;
  const handlePageClick = (event) => {
    console.log("Clickess");
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    dispatch(
      getVehicleListAction({
        offset: newOffset,
        limit: 10,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
    setOffset(newOffset);
  };
  const handlePrev = () => {
    if (offset > 0) {
      setOffset(offset - 10);
    }
  };
  const handleNext = () => {
    if (vehCount > offset) {
      setOffset(offset + 10);
    }
  };
  useEffect(() => {
    if (search == "") {
      setCityList(tripAddress?.rows);
    } else {
      dispatch(findTripAddress());
      setCityList(tripAddress?.rows);
    }
  }, []);
  useEffect(() => {
    setColor1(-1);
    dispatch(
      findTripAddress({
        offset: 0,
        search: search1,
      })
    );
  }, [search1]);
  useEffect(() => {
    setColor2(-1);
    dispatch(
      findTripAddress({
        offset: 0,
        search: search2,
      })
    );
  }, [search2]);
  const cityStateList = useSelector((state) => state?.omc?.cityStateList);
  const findSearch1 = () => {
    setColor1(-1);
    dispatch(
      findTripAddress({
        offset: 0,
        search: search1,
        // minKyc: "",
        // sortAs: "",
        // sortBy: "",
      })
    );
  };
  const findSearch2 = () => {
    setColor2(-1);
    dispatch(
      findTripAddress({
        offset: 0,
        search: search2,
        // minKyc: "",
        // sortAs: "",
        // sortBy: "",
      })
    );
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // scrollable={true}
        centered={true}
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px" }}
        >
          <div className="">
            <div
              className="closeBtnWithBackgroundForTrip"
              onClick={handleClose}
            >
              <SvgImage
                classname="close-btn-text"
                style={{ width: "10%", height: "10%" }}
                name="crossBtnWIthBackground"
                width="20%"
                height="20%"
              />
            </div>
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Create Trip
            </div>

            <Formik
              // isValid='true'
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                source: "",
                destination: "",
                startDate: "",
                endDate: "",
                LRNumber: "",
                tripNo: "",
                vrn: "",
                driver: "",
                fleetId: "",
                userId: "",
                sourceId: "",
                destId: "",
                driverUserId: "",
                remark: "",
              }}
              onSubmit={(values) => {
                let id1 = defaultStates?.find(
                  (data) => data?.cityName == sourceName
                );
                let id2 = defaultStates?.find(
                  (data) => data?.cityName == destName
                );
                let objVal = {
                  // userIdForIM: "any",
                  source: String(cityId1),
                  destination: String(cityId2),
                  fleetId: values?.fleetId,
                  userId: values?.driverUserId,
                  startTime: moment(values.startDate).format("MM/DD/YYYY"),
                  tripNo: values?.tripNo ? "TR-" + values?.tripNo : "",
                  remark: values?.remark,
                  // endTime: moment(values.endDate).format("MM/DD/YYYY"),
                  // userId: item?.userId,
                  // userId: "ff486f22-6919-496b-bac9-d7cb091c32b8",
                  // lrNumber: values.LRNumber,
                  // cardUserId: values?.userId,
                  // cardType: "any",
                  // tripId: values?.tripId,
                  // endKmReading: values.endKm,
                  // Company: values.Company,
                };

                console.log("dfsada", values?.endDate);
                if (values?.endDate != "") {
                  objVal["endTime"] = moment(values.endDate).format(
                    "MM/DD/YYYY"
                  );
                }
                // setData({
                //   source: cityId1,
                //   destination: cityId2,
                //   startDate: values.startDate,
                //   endDate: values.endDate,
                //   LRNumber: values.LRNumber,
                //   Company: values.Company,
                // });
                console.log("submittttttttt hereerere", objVal);
                dispatch(
                  createTrip(objVal, (res) => {
                    if (res?.status == 200) {
                      // console.log('rererere',res)
                      dispatch(
                        toastStatus({
                          callBack: () => {
                            toggle();
                            setFreight(!freight);
                            dispatch(
                              getTripsNew({
                                offset: 0,
                                limit: 10,
                                fleetId: "",
                                driverId: "",
                                startTime: "",
                                endTime: "",
                                source: "",
                                destination: "",
                                tripNo: "",
                              })
                            );
                            console.log("asdfgcxvbcxv cxvxzcfd");
                          },
                          toastStatus: true,
                          message: `Trip has been created successfully`,
                          status: true,
                        })
                      );

                      setOpenToast(true);
                      setTripIdTemp(res?.data?.data?.tripId);

                      //
                      console.log("...............", res);
                      // alert("success");
                    } else {
                      if (location == "/trips/") {
                        setOpenToastFailure(true);
                        setOpenToastFailureText(res?.data?.message);
                      }
                    }
                  })
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "50px", padding: "0 75px 0 50px" }}
                >
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold col-12">
                        Trip No:
                      </label>
                      <div className="custNamepadd3">
                        <input
                          type="text"
                          id="tripNo"
                          className={`inputBoxCust1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          name="tripNo"
                          onChange={(event) => {
                            // setSearch(event.target.value);
                            setFieldValue("tripNo", event.target.value, true);
                          }}
                          value={values.tripNo}
                          touched={touched.tripNo}
                          errors={errors.tripNo}
                          placeholder="TR-12345"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="vrn" className="heading3 semiBold col-12">
                        Remarks:
                      </label>
                      <div className="custNamepadd3">
                        <input
                          type="text"
                          id="remark"
                          className={`inputBoxCust1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          name="remark"
                          onChange={(event) => {
                            // setSearch(event.target.value);
                            setFieldValue("remark", event.target.value, true);
                          }}
                          value={values.remark}
                          touched={touched.remark}
                          errors={errors.remark}
                          placeholder="remarks"
                        />
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "30px",
                    }}
                  >
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Vehicle No:
                      </label>
                      <div
                        className="custNamepadd3"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <input
                          className={`inputBoxCust1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          type="text"
                          id="vrn"
                          name="vrn"
                          touched={touched.vrn}
                          errors={errors.vrn}
                          onBlur={handleBlur("vrn")}
                          onChange={(event) => {
                            setSearch(event.target.value);
                            setFieldValue("vrn", event.target.value, true);
                          }}
                          value={values.vrn}
                          autoFocus
                          placeholder="Select Vehicle"
                        />
                        <span
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={addVehicle}
                        >
                          + Add Vehicle
                        </span>
                      </div>
                      <div style={{ position: "relative" }}>
                        {open ? (
                          <div className="driverSearchContainer2 containerShadow">
                            <div
                              style={{
                                position: "absolute",
                                top: "0.3rem",
                                right: "0.3rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpen(false);
                              }}
                            >
                              <SvgImage name="CloseButtonLogo" />
                            </div>
                            {allFleets?.map((item, i) => {
                              return (
                                <>
                                  {/* {item?.Fastag !== null ? ( */}
                                  <div
                                    key={i}
                                    className="flexStyling padding8"
                                    onClick={(event) => {
                                      setOpen(false);
                                      // setUserId(event.target.value);
                                      setFieldValue(
                                        "vrn",
                                        item?.regNumber,
                                        true
                                      );
                                      setFieldValue(
                                        "fleetId",
                                        item?.fleetId,
                                        true
                                      );
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name="vrn"
                                      value={item?.fleetId}
                                      id={item?.fleetId}
                                    />
                                    <div
                                      className="padding16L"
                                      value={item?.fleetId}
                                    >
                                      {item?.regNumber}
                                    </div>
                                    {/* <div
                                      className="padding16L"
                                      style={{
                                        color:'blue',

                                      }}
                                    >
                                      3 Days ago

                                  </div> */}
                                  </div>
                                  {/* ) : (
                                    <></>
                                  )} */}
                                </>
                              );
                            })}

                            <div className="row margin0 padding16">
                              <div
                                className="col-2"
                                style={{
                                  textAlign: "right",
                                  paddingTop: "0.1rem",
                                  cursor: offset > 0 ? "pointer" : "no-drop",
                                  opacity: offset > 0 ? "1" : "0.5",
                                }}
                                onClick={handlePrev}
                              >
                                <SvgImage name="PrevArrow" />
                              </div>
                              <div
                                className="col-2 greenFont semiBold heading4 padding0L"
                                style={{
                                  textAlign: "right",
                                  paddingTop: "0.3rem",
                                  cursor: offset > 0 ? "pointer" : "no-drop",
                                  opacity: offset > 0 ? "1" : "0.5",
                                }}
                                onClick={handlePrev}
                              >
                                Previous
                              </div>
                              <div className="col-4 row margin0 justify-content-center p-0">
                                <div
                                  className="greenBoxCount Medium heading4 col-4"
                                  style={{
                                    width: "2rem",
                                    height: "2rem",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  {offset / 10 + 1}
                                </div>
                                <div
                                  className=" col- padding0R"
                                  style={{
                                    paddingTop: "0.3rem",
                                    textAlign: "right",
                                  }}
                                >
                                  of{" "}
                                  {vehCount % 10 == 0
                                    ? Math.floor(vehCount / 10)
                                    : Math.floor(vehCount / 10 + 1)}
                                </div>
                              </div>
                              <div
                                className="col-2 greenFont semiBold heading4 padding0R"
                                style={{
                                  textAlign: "left",
                                  paddingTop: "0.3rem",
                                  cursor:
                                    vehCount - offset > 10
                                      ? "pointer"
                                      : "no-drop",
                                  opacity: vehCount - offset > 10 ? "1" : "0.5",
                                }}
                                onClick={handleNext}
                              >
                                Next
                              </div>
                              <div
                                className="col-2 padding0L"
                                style={{
                                  textAlign: "left",
                                  // marginLeft: "-1rem",
                                  paddingTop: "0.1rem",
                                  cursor:
                                    vehCount - offset > 10
                                      ? "pointer"
                                      : "no-drop",
                                  opacity: vehCount - offset > 10 ? "1" : "0.5",
                                }}
                                onClick={handleNext}
                              >
                                <SvgImage name="NextArrow" />
                              </div>
                            </div>
                            {vehCount == 0 ? (
                              <div className="heading3 semiBold padding16 padding24B">
                                No Vehicle Available
                              </div>
                            ) : fastVeh == undefined ? (
                              <div className="heading3 semiBold padding16 padding24B">
                                No Vehicle Available
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        <div style={{ color: "red" }}>
                          {errors.fleetId && touched.fleetId ? (
                            <div>*{errors.fleetId}</div>
                          ) : null}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Driver:
                      </label>
                      <div
                        className="custNamepadd3"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                        onClick={() => {
                          setOpen1(true);
                        }}
                      >
                        <input
                          className={`inputBoxCust1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          type="text"
                          id="driver"
                          name="driver"
                          touched={touched.driver}
                          errors={errors.driver}
                          onBlur={handleBlur("driver")}
                          onChange={(event) => {
                            setSearchDriver(event.target.value);
                            setFieldValue("driver", event.target.value, true);
                          }}
                          value={values.driver}
                          autoFocus
                          placeholder="Select Driver"
                        />
                        <span
                          id="addDriver"
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={addDriver}
                        >
                          + Add Driver
                        </span>
                      </div>
                      <div style={{ position: "relative" }}>
                        {open1 ? (
                          <div className="driverSearchContainer containerShadow">
                            <div
                              style={{
                                position: "absolute",
                                top: "0.3rem",
                                right: "0.3rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpen1(false);
                              }}
                            >
                              <SvgImage name="CloseButtonLogo" />
                            </div>
                            {driverList?.map((item, i) => {
                              return (
                                <>
                                  {item?.Fastag !== null ? (
                                    <div
                                      key={i}
                                      className="flexStyling padding8 "
                                      onClick={(event) => {
                                        setOpen1(false);
                                        setFieldValue(
                                          "driver",
                                          item?.User?.fullName,
                                          true
                                        );
                                        console.log("itemadas", item);
                                        setFieldValue(
                                          "driverUserId",
                                          item?.User?.userId,
                                          true
                                        );
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="driver"
                                        value={item?.User?.userId}
                                        id={item?.User?.userId}
                                      />
                                      <div className="padding16L">
                                        {item?.User?.fullName.length > 35
                                          ? item?.User?.fullName.split(0, 35) +
                                            "..."
                                          : item?.User?.fullName}
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                            {driverCount == 0 ? (
                              <div className="heading3 semiBold padding16 padding24B">
                                No driver Available
                              </div>
                            ) : driverCount == undefined ? (
                              <div className="heading3 semiBold padding16 padding24B">
                                No driver Available
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div style={{ color: "red" }}>
                          {errors.driverUserId && touched.driverUserId ? (
                            <div>*{errors.driverUserId}</div>
                          ) : null}
                        </div>
                      </div>
                    </FormGroup>
                  </FormGroup>

                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormGroup>
                      <label for="from" className="heading3 semiBold">
                        From
                      </label>
                      <div
                        className="custNamepadd3"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "flex-start",
                          // marginTop: "30px",
                        }}
                        // onClick={() => {
                        //   setOpen1(true);
                        // }}
                      >
                        <input
                          type="text"
                          name="source"
                          id="source"
                          placeholder={"Source City"}
                          className={`inputBoxCust1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          aria-label="Default select example"
                          onChange={(e) => {
                            setDisplay1(true);
                            setSearch1(e.target.value);
                            setFieldValue("source", e.target.value, true);
                          }}
                          onClick={() => {
                            setDisplay1(true);
                          }}
                          value={values.source}
                          onBlur={handleBlur("source")}
                          errors={errors.source}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              findSearch1();
                            }
                          }}
                        ></input>
                        <span
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={addAddress}
                        >
                          + Add Address
                        </span>
                      </div>

                      {display1 ? (
                        <div
                          className="padding16 newCashback whiteBackground"
                          style={{
                            position: "absolute",
                            width: "350px",
                            height: "275px",
                            zIndex: "90",
                          }}
                        >
                          <div
                            onClick={() => {
                              setDisplay1(false);
                              setSearch1("");
                              //dispatch(clearAllDriversByVrn());
                              setFieldValue("source", "", true);
                            }}
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            <SvgImage name="CloseButtonLogo" />
                          </div>
                          <div
                            style={{
                              overflowY: "scroll",
                              overflowX: "hidden",
                              height: "240px",
                            }}
                          >
                            {search1 != "" ? (
                              tripAddress?.rows?.map((data, i) => {
                                let city = data?.cityName;
                                let state = data?.stateName;
                                return (
                                  <div
                                    key={i}
                                    className="padding8 row"
                                    onClick={() => {
                                      setcityId1(data?.cityName);
                                      setColor1(i);
                                      setDisplay1(false);
                                      //setUserId(data?.User?.userId);
                                      setFieldValue(
                                        "source",
                                        data?.cityName + "-" + data?.stateName,
                                        true
                                      );
                                    }}
                                  >
                                    {color1 === i ? (
                                      <div className="col-1">
                                        <SvgImage name="SelectedRadio" />
                                      </div>
                                    ) : (
                                      <div className="col-1">
                                        <SvgImage name="UnselectedRadio" />
                                      </div>
                                    )}
                                    {/* <div className="col-1">
                          <SvgImage name="UnselectedRadio" />
                        </div> */}
                                    <div
                                      className="col-11"
                                      style={{ textAlign: "left" }}
                                    >
                                      {city} - {state}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                {tripAddress?.rows?.map((data, i) => {
                                  let city = data?.cityName;
                                  let state = data?.stateName;

                                  console.log("check status", city, state);
                                  // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                                  return (
                                    <div
                                      key={i}
                                      className="padding8 row"
                                      onClick={() => {
                                        setcityId1(data?.cityName);
                                        setColor1(i);
                                        setDisplay1(false);
                                        //setUserId(data?.User?.userId);
                                        setFieldValue(
                                          "source",
                                          data?.cityName +
                                            "-" +
                                            data?.stateName,
                                          true
                                        );
                                      }}
                                    >
                                      {color1 === i ? (
                                        <div className="col-1">
                                          <SvgImage name="SelectedRadio" />
                                        </div>
                                      ) : (
                                        <div className="col-1">
                                          <SvgImage name="UnselectedRadio" />
                                        </div>
                                      )}
                                      <div
                                        className="col-11"
                                        style={{ textAlign: "left" }}
                                      >
                                        {city} - {state}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={{ color: "red" }}>
                        {errors.source && touched.source ? (
                          <div>*{errors.source}</div>
                        ) : null}
                      </div>
                    </FormGroup>

                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="to" className="heading3 semiBold">
                        To
                      </label>
                      <div className="custNamepadd3">
                        <input
                          type="text"
                          name="destination"
                          id="destination"
                          placeholder={"Destination City"}
                          className={`inputBoxCust1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          aria-label="Default select example"
                          onChange={(e) => {
                            setDisplay2(true);
                            setSearch2(e.target.value);
                            setFieldValue("destination", e.target.value, true);
                          }}
                          onClick={() => {
                            setDisplay2(true);
                          }}
                          value={values.destination}
                          onBlur={handleBlur("destination")}
                          errors={errors.destination}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              findSearch2();
                            }
                          }}
                        ></input>
                      </div>

                      {display2 ? (
                        <div
                          className="padding16 newCashback whiteBackground"
                          style={{
                            position: "absolute",
                            width: "350px",
                            height: "275px",
                            zIndex: "90",
                          }}
                        >
                          <div
                            onClick={() => {
                              setDisplay2(false);
                              setSearch2("");
                              //dispatch(clearAllDriversByVrn());
                              setFieldValue("destination", "", true);
                            }}
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            <SvgImage name="CloseButtonLogo" />
                          </div>
                          <div
                            style={{
                              overflowY: "scroll",
                              overflowX: "hidden",
                              height: "240px",
                            }}
                          >
                            {search2 != "" ? (
                              tripAddress?.rows?.map((data, i) => {
                                let city = data?.cityName;
                                let state = data?.stateName;
                                return (
                                  <div
                                    key={i}
                                    className="padding8 row"
                                    onClick={() => {
                                      setcityId2(data?.cityName);
                                      setColor2(i);
                                      setDisplay2(false);
                                      //setUserId(data?.User?.userId);
                                      setFieldValue(
                                        "destination",
                                        data?.cityName + "-" + data?.stateName,
                                        true
                                      );
                                    }}
                                  >
                                    {color2 === i ? (
                                      <div className="col-1">
                                        <SvgImage name="SelectedRadio" />
                                      </div>
                                    ) : (
                                      <div className="col-1">
                                        <SvgImage name="UnselectedRadio" />
                                      </div>
                                    )}
                                    {/* <div className="col-1">
                          <SvgImage name="UnselectedRadio" />
                        </div> */}
                                    <div
                                      className="col-11"
                                      style={{ textAlign: "left" }}
                                    >
                                      {city} - {state}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                {tripAddress?.rows?.map((data, i) => {
                                  let city = data?.cityName;
                                  let state = data?.stateName;

                                  console.log("check status", city, state);
                                  // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                                  return (
                                    <div
                                      key={i}
                                      className="padding8 row"
                                      onClick={() => {
                                        setcityId2(data?.cityName);
                                        setColor2(i);
                                        setDisplay2(false);
                                        //setUserId(data?.User?.userId);
                                        setFieldValue(
                                          "destination",
                                          data?.cityName +
                                            "-" +
                                            data?.stateName,
                                          true
                                        );
                                      }}
                                    >
                                      {color2 === i ? (
                                        <div className="col-1">
                                          <SvgImage name="SelectedRadio" />
                                        </div>
                                      ) : (
                                        <div className="col-1">
                                          <SvgImage name="UnselectedRadio" />
                                        </div>
                                      )}
                                      <div
                                        className="col-11"
                                        style={{ textAlign: "left" }}
                                      >
                                        {city} - {state}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={{ color: "red" }}>
                        {errors.destination && touched.destination ? (
                          <div>*{errors.destination}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  </FormGroup>

                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Start Date
                      </label>
                      <div
                        className="custNamepadd3 row"
                        style={{ width: "200px" }}
                      >
                        <div className="col-9">
                          <DatePickerField
                            classname="searchBar-input1"
                            name="startDate"
                            header="Select Start date"
                            value={values.startDate}
                            onChange={(v) => {
                              setFieldValue("startDate", v, true);
                            }}
                            //onChange={(v) => setFieldValue("endDate", v, true)}
                            maxDateDate={
                              values.endDate === "" ? "" : values.endDate
                            }
                            // maxDate={new Date()}
                            ref1={datepickerRefEnd}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="col-3">
                          <label
                            className="date-input"
                            for="startDate"
                            style={{
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleClickDatepickerIcon2();
                            }}
                          >
                            <SvgImage
                              name="CalenderLogo"
                              width="32"
                              height="32"
                              // classname="date-logo"
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        className="heading4 Regular"
                        style={{ margin: "7px 0 0px 0", color: "red" }}
                      >
                        {errors.startDate && touched.startDate ? (
                          <div>*{errors.startDate}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="vrn" className="heading3 semiBold">
                        End Date
                      </label>
                      <div
                        className="custNamepadd3 row"
                        style={{ width: "200px" }}
                      >
                        <div className="col-9">
                          <DatePickerField
                            classname="searchBar-input1"
                            name="endtDate"
                            header="Select End date"
                            value={values.endDate}
                            onChange={(v) => {
                              setFieldValue("endDate", v, true);
                            }}
                            //onChange={(v) => setFieldValue("endDate", v, true)}
                            minDate={
                              values.startDate === "" ? "" : values.startDate
                            }
                            // maxDate={new Date()}
                            ref1={datepickerRefStart}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="col-3">
                          <label
                            className="date-input"
                            for="endDate"
                            style={{
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleClickDatepickerIcon1();
                            }}
                          >
                            <SvgImage
                              name="CalenderLogo"
                              width="32"
                              height="32"
                              // classname="date-logo"
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        className="heading4 Regular"
                        style={{ margin: "7px 0 0px 0", color: "red" }}
                      >
                        {errors.endDate && touched.endDate ? (
                          <div>*{errors.endDate}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  </FormGroup>

                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "25px",
                      marginTop: "100px",
                    }}
                  >
                    <Button
                      style={{ width: "25%" }}
                      className="notGenerateOpt text4 Medium btn btn-secondary"
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      style={{ width: "25%" }}
                      className="generateOtp text3 Medium btn btn-secondary"
                      // onClick={addFreight}
                    >
                      Next
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      {toastObject.toastStatus && location == "/trips/" ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            console.log("nmnnnnnnnnnmmmm", toastObject?.message);
          }}
          toggleModal_newFleetError={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            console.log("qwertqewrth", toastObject?.message);
          }}
          isOpen={toastObject?.toastStatus}
          name="trips"
          handleClose={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );

            console.log("nmnnnnnnnnnmmmm", toastObject?.message);
            if (freight) {
              addFreight();
            } else if (driver) {
              addDriver();
            } else if (vehicle) {
              addVehicle();
            } else if (address) {
              addAddress();
            } else if (
              toastObject?.message == "Freight details saved successfully"
            ) {
              addFreight();
              toggle();
            } else if (
              toastObject?.message == "Trip has been created successfully"
            ) {
              addFreight();
              toggle();
            } else if (
              toastObject?.message == "LR has been created successfully"
            ) {
              console.log("kkljjirsnfire",lr)
              if(lr == "1"){
                // setNewLr(false);
                toggleOpenLrList();
                chooseRouteModalToggle();
              }
              // addFreight();
              // toggle();
            }
            //  else {
            //   setOpenToast(false);
            // }
            // window.location='/trips/';
            // toggle()
            //alert("close button clicked");
          }}
        />
      ) : (
        <></>
      )}
      {/* <AddFreight
        toggle={addFreight}
        freight={freight}
        tripId={tripIdTemp}
        location={location}
        customer={customer}
        // setFreight={setFreight}
      /> */}
      <AddDriverV2 toggle={addDriver} freight={driver} />
      <AddVehicleV2 toggle={addVehicle} freight={vehicle} />
      <AddAddress
        toggle={addAddress}
        address={address}
        setAddress={setAddress}
        location={location}
      />
    </div>
  );
}

export default TripModalV2;
