import React, { useEffect, useState } from "react";
import "./index.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import SvgImage from "../../../../Components/Icons/Icons";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { sendOtp } from "../../../../services/Store/Actions/LoginAction";
import ForgotPasswordPhoneNumber from "./ForgotPasswordPhoneNumber";
import ForgotPasswordOtpVerify from "./ForgotPasswordOtpVerify";
import ResetPasswordForm from "./ResetPasswordForm";

const ForgotPassword = (props) => {
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required("Phone number must contain 10 digits")
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Phone number must contain 10 digits"
      ),
  });

  const [forgotStatus, setForgotStatus] = useState("0");
  const [validationData, setValidationData] = useState("");
  const [phone, setPhone] = useState("");
  const { handleSignup } = props;
  const { loader } = useSelector((state) => state?.loginReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sendAnalitics("LoginPage_forgotPassword"));
  }, []);

  return (
    <div className="InputForm3">
      <div className="formPosition">
        <div className="Regular text3 greyFont">Welcome! 👋</div>
        <div className="heading1 semiBold">Sign in to reset password</div>
      </div>
      {forgotStatus == "0" ? (
        <ForgotPasswordPhoneNumber
          setForgotStatus={setForgotStatus}
          setPhone={setPhone}
        />
      ) : forgotStatus == "1" ? (
        <ForgotPasswordOtpVerify
          setForgotStatus={setForgotStatus}
          phone={phone}
          setValidationData={setValidationData}
        />
      ) : (
        <ResetPasswordForm validationData={validationData} />
      )}
      <div className="formPosition">
        <div className="row padding16 padding8T padding8B">
          <hr className="col-5 margin10T" />
          <div className="col-1 padding0 centerText1">OR</div>
          <hr className="col-5 margin10T marginLeftVal" />
        </div>
        <button
          type="button"
          className="newUserBtn semiBold text2 padding8"
          onClick={() => {
            console.log("HEY 2");
            props.setIsRegistered(true);
            props.setStatus("0");
            // props.setRegStatus(0);
            // setIsRegistered(false);
            // setStatus("0");
            // props.signUpHandle()
          }}
        >
          Login With OTP
        </button>
      </div>

      <div
        className="Regular heading4 col-12 padding16"
        style={{ textAlign: "center" }}
      >
        Don't have an account?{" "}
        <span
          className="semiBold "
          style={{ color: "#485B7C", cursor: "pointer" }}
          onClick={() => {
            props.setIsRegistered(false);
            props.setStatus("0");
          }}
        >
          Sign Up
        </span>
      </div>
      {/* <div className="bottomTextLogin padding16T Regular text4">
          <span
            className="bottomTextSpan Medium"
            onClick={() => {
              props.handleTermShow();
            }}
          >
            T&C{" "}
          </span>{" "}
          and{" "}
          <span
            className="bottomTextSpan Medium"
            onClick={() => {
              props.handlePrivacyShow();
            }}
          >
            Privacy Policy
          </span>{" "}
          apply
        </div> */}
    </div>
  );
};

export default ForgotPassword;
