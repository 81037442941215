import {
    DOWNLOAD_AADHAAR,
    DOWNLOAD_AADHAAR_SUCCESS,
    PAN_AUTH,
    PAN_AUTH_SUCCESS,
    GET_AADHAAR_OTP,
    GET_AADHAAR_OTP_SUCCESS,
    UPDATE_PAN_AADHAAR,
    UPDATE_PAN_AADHAAR_SUCCESS,
    BUSINESS_DETAILS,
    BUSINESS_DETAILS_SUCCESS,
    OWNER_DETAILS,
    OWNER_DETAILS_SUCCESS,
    CLEAN_ONBOARDING_LOADER,
    SET_PIN,
    SET_PIN_SUCCESS,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS
  } from "../storeTypes";
  
  export const cleanOnboardingLoaderAction = () => {
    console.log("cleaning")
    return {
      type: CLEAN_ONBOARDING_LOADER,
    };
  };

  export const refreshTokenAction = (obj, callback) => {
    return {
      type: REFRESH_TOKEN,
      payload: obj,
      callback,
    };
  };
    
  export const refreshTokenActionSuccess = (obj) => {
    return {
      type: REFRESH_TOKEN_SUCCESS,
      payload: obj,
    };
  };

  export const setPinRegAction = (obj, callback) => {
    return {
      type: SET_PIN,
      payload: obj,
      callback,
    };
  };
    
  export const setPinRegActionSuccess = (obj) => {
    return {
      type: SET_PIN_SUCCESS,
      payload: obj,
    };
  };

  export const ownerDetailsAction = (obj, callback) => {
    return {
      type: OWNER_DETAILS,
      payload: obj,
      callback,
    };
  };
    
  export const ownerDetailsActionSuccess = (obj) => {
    return {
      type: OWNER_DETAILS_SUCCESS,
      payload: obj,
    };
  };

  export const businessDetailsAction = (obj, callback) => {
    return {
      type: BUSINESS_DETAILS,
      payload: obj,
      callback,
    };
  };

  export const businessDetailsActionSuccess = (obj) => {
    return {
      type: BUSINESS_DETAILS_SUCCESS,
      payload: obj,
    };
  };

  export const updatePanAadharAction = (obj, callback) => {
    return {
      type: UPDATE_PAN_AADHAAR,
      payload: obj,
      callback,
    };
  };
    
  export const updatePanAadharActionSuccess = (obj) => {
    return {
      type: UPDATE_PAN_AADHAAR_SUCCESS,
      payload: obj,
    };
  };



  export const getAadharOtpAction = (obj, callback) => {
    console.log("payload", callback);
    return {
      type: GET_AADHAAR_OTP,
      payload: obj,
      callback,
    };
  };
    
  export const getAadharOtpActionSuccess = (obj) => {
    return {
      type: GET_AADHAAR_OTP_SUCCESS,
      payload: obj,
    };
  };

  export const panAuthAction = (obj, callback) => {
    return {
      type: PAN_AUTH,
      payload: obj,
    //   callback,
    };
  };
    
  export const panAuthActionSuccess = (obj) => {
    return {
      type: PAN_AUTH_SUCCESS,
      payload: obj,
    };
  };


  export const downloadAadharXMLAction = (obj, callback) => {
    return {
      type: DOWNLOAD_AADHAAR,
      payload: obj,
      callback,
    };
  };
    
  export const downloadAadharXMLActionSuccess = (obj) => {
    return {
      type: DOWNLOAD_AADHAAR_SUCCESS,
      payload: obj,
    };
  };
