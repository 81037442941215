import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../../Icons/Icons";
import "./index.css";
import { toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";


function ImagePopup(props){
    
    const {handleClose,open, toggleModal, image,text}= props;

    const productDetails= useSelector((state)=>state?.ecom?.productDetails);

    console.log("productDetails", text)
    const images= productDetails?.images?.[0]?.productUrl;



    const dispatch= useDispatch();


    const customId = "custom-id-not-login page";
    const notify = () => toast.error("Kindly login to add items in the cart.",{
      toastId:customId
    });


    // open success modal
    const [openSuccess, setOpenSuccess]= useState(false);

    const closeModalSuccess=()=>{
    setOpenSuccess(false);
    }

    const showModalSuccess=()=>{
    setOpenSuccess(true);
    }

    const toggleModalSuccess=()=>{
    setOpenSuccess(!openSuccess);
    }

    // open customer support modal
    const [openContact, setOpenContact]= useState(false);

    const closeModalContact=()=>{
      setOpenContact(false);
    }

    const showModalContact=()=>{
      setOpenContact(true);
    }

    const toggleModalContact=()=>{
      setOpenContact(!openContact);
    }







    return (
        <div>
          <Modal
            isOpen={open}
            onHide={()=>{
            //   setStatus(false);
              handleClose();
            }}
            className="positon settingModalMargin"
            toggle={()=>{
            //   setStatus(false);
              toggleModal();
            }}
            aria-labelledby="myModalLabel2"
          >
            <div
              className="modal-content"
              style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
            >
              <div className="modal-head-part">
                <p className="Add-balance-title">{text}</p>
                <Button
                  type="button"
                  className="close-btn"
                  onClick={()=>{
                    // dispatch(sendAnalitics("close_OwnerAcc_Home_web"));
                    // setStatus(false);
                    toggleModal();
                  }}
                  aria-label="Close"
                >
                  <SvgImage name="CloseButtonLogo" />
                </Button>
              </div>
              <ModalBody className="padding0L">
                 <div>
                    <div className="detailImgContainer" >
                        <div className="imageColumn">
                            <img src={image} className="imgTyre"
                            style={{ height: "100%", alignItems: "centre" }} />
                        </div>
                    </div>
                    
                </div>
              </ModalBody>
            </div>
          </Modal>
          
         
        </div>
      );    
}

export default ImagePopup;