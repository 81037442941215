import React, { useState } from 'react'
import SvgImage from '../../Icons/Icons'
import { downloadSalaryAction, downloadSalaryCreditSheet, uploadSalaryAction, uploadSalaryCreditSheet } from '../../../services/Store/Actions/driverslistActions'
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { Button, Form, FormGroup } from 'reactstrap';
import BulkSalaryPreviewModal from '../../Modals/BulkSalaryModal';
import { sendAnalitics } from '../../../services/Store/Actions/appActions';
import * as XLSX from 'xlsx';
import { Formik } from 'formik';
import { downloadBulkTransferAction,bulkTransferUpload } from '../../../services/Store/Actions/walletAction';
function BulkTransfer() {
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [fileSave, setFileSave] = useState(null);
    const [fileUp, setFileUp] = useState(false);
    const [loader, setLoader] = useState(false); 
    const [excelData, setExcelData] = useState(null); 
    const [bulkTransfer, setBulkTransfer] = useState(0); 
    const [bulkTransferId, setBulkTransferId] = useState(null); 
    
    const Title="Bulk Transfer"
    const history =
    Title === "Bulk Transfer"
    const dispatch = useDispatch();
    const customId1 = "custom-id-download page";
    const notify1 = () => toast.success("Driver Transfer data was downloaded successfully.", {
        toastId: customId1,
        pauseOnHover: false,
    });
    const customId2 = "custom-id-upload page";
    const notify2 = () => toast.success("Driver Transfer data was uploaded successfully.", {
        toastId: customId2,
        pauseOnHover: false,
  });
    const customId4 = "custom-id-upload page";
    const notify4 = () => toast.success("Transfer file uploaded successfully.", {
        toastId: customId4,
        pauseOnHover: false,
  });
  const customId3 = "custom-id-Salary-dispersed";
  const notify3 = () => toast.success("Amount transfered successfully to drivers.", {
    toastId: customId3,
    pauseOnHover: false,
    });
    const downloadSal = () => {
        dispatch(downloadBulkTransferAction("", (res) => {
          console.log("downloadSalaryAction", res?.data)
          if (res?.status == 200) {
            const blob = new Blob([res?.data])
            const link = document.createElement("a");
           link.href = window.URL.createObjectURL(blob);
           link.download = `file.xls`; //name to changed here
           link.click();
            notify1();
          }
        }))
      }

const bulkTransferCall = ()=>{
        setLoader(true);
        dispatch(bulkTransferUpload({file:fileSave,bulkTransferId},(res)=>{
            console.log(res);
            setLoader(false);
            // setBulkTransfer(0);
            
            if(res.status==200){
                const blob = new Blob([res?.data])
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `responseFile.xls`; //name to changed here
                link.click();
                notify3();
            }else {
                toast.error(res.data?.message||"some error occured")
            }
            
        }))
}
  return (
    <div>
        <div className='bulkTransfer' >
            <div className='bankDetails'>
                <div className='Bold heading1'>
                    Bulk Transfer Drivers
                </div>
                <div className='boxShadowIntro container2' style={{padding:'24px 0 18px', margin:'20px 0'}}>
                    <div className='flexStyling1'>
                                <div className='svgTextParent' disabled={loader} onClick={downloadSal}>
                                    <div className='svgContainer' style={{margin:'12px 0'}}>
                                        <SvgImage name='Download' />
                                    </div>
                                    <div className='Bold heading3'>Download Template</div>
                                </div>

                                <div className='svgTextParent' style={{position:'relative'}}>
                                    
                                            <label for="file-upload" class="custom-file-upload" style={{width:'100%', display:'flex',flexDirection:'column',justifyContent:'center', alignItems:'center'}}>
                                                <input

                                                    id='file-upload'
                                                    type="file"
                                                    disabled={loader}
                                                    // accept="image/*"
                                                    // className='add-attachment-btn-2'
                                                    onChange={async (e) => {
                                                        console.log("img aaya");
                                                    const img = e?.target.files[0];
                                                    // setFileUp(img);
                                                    console.log("img aaya",img);
                                                    notify4();
                                                    setFileSave(img)
                                                    setBulkTransfer(1);
                                                    // dispatch(bulkTransferUpload({file:img},(data)=>{
                                                    //     console.log(data);
                                                    // }))
                                                    // console.log("image", img?.name);
                                                    }}
                                                />
                                                <div className='svgContainerUp' style={{margin:'12px 0',backgroundColor:bulkTransfer>0?"antiquewhite":null}}>
                                                    <SvgImage name='Upload' />
                                                </div>
                                        Upload for bulk Transfers
                                    </label>        
                                </div>
                    </div>
                    
                </div>
                
            </div>
            {
                        bulkTransfer==1&&
                    <div className='boxShadowIntro container2'
                    style={{justifyContent:"right"}}
                    >
                            <a 
                            disabled={loader}
                            style={{flex:"auto","marginLeft":"2rem"}}
                            href={fileSave} >{fileSave?.name||"test"}</a> 

                        <div
                        style={{margin:"1rem"}}
                        >
                            {/* <text
                            style={{"margin-right":"1rem"}}
                            >
                                Bulk TransferId: 
                            </text> */}
                        <input
                        type='text'
                        className='form-control'
                        name='bulkTransferId'
                        placeholder='Enter Unique TransferId'
                        disabled={loader}
                        onChange={(e)=>{
                            console.log(e.target.value);
                            setBulkTransferId(e.target.value);
                        }}
                        />
                        </div>
                        <Button
                        className='greenButton'
                        style={{margin:"1rem"}}
                        onClick={bulkTransferCall}
                        disabled={loader}
                        >Transfer</Button>
                    </div>
                    }
            <div className='flexStyling' style={{justifyContent:'space-evenly', alignItems:'center'}}>
            </div>
            
        </div>
    </div>
  )
}

export default BulkTransfer