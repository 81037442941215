import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import {
  Form,
  FormGroup,
  Button,
  Col,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import * as Yup from "yup";
import AddNewAccount from "../../../../Modals/addNewAccount";
import SvgImage from "../../../../Icons/Icons";
import { neftLinkedAccountsListAction } from "../../../../../services/Store/Actions/masterWalletActions";
import { sendAnalitics } from "../../../../../services/Store/Actions/appActions";
import { toast } from "react-toastify";

function CompanyAccountConnected(props) {
  const [addBeneficiary, setAddBeneficiary] = useState(false);
  function handleAddBeneficiary(opt) {
    setAddBeneficiary(true);
    //toggleModal();
  }
  function handleCloseAddBeneficiary() {
    setAddBeneficiary(false);
  }
  function toggleModalAddBeneficiary() {
    setAddBeneficiary(!addBeneficiary);
  }

  const {
    virtuAccount,
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    amount,
    ownerLogipeBalance,
    accountStatus,
    color,
    copyIcon,
    setCopyIcon,
    copyIconIfsc,
    setCopyIconIfsc,
  } = props;

  //console.log ("in the neft component-----------",ownerLogipeBalance);
  const dispatch = useDispatch();
  // const ownerBalance = ownerLogipeBalance === "" ? 0 : ownerLogipeBalance;
  console.log("blanace", virtuAccount);

  const customId1 = "custom-id-accountCopy page";
  const notify1 = () => toast.success("Bank Account Number was successfully copied.",{
    toastId:customId1
  });

  const customId2 = "custom-id-ifscCopy page";
  const notify2 = () => toast.success("IFSC Code was successfully copied.",{
    toastId:customId2
  });


  const copyToClipboard = () => {
    dispatch(sendAnalitics("Copy_Ac_Web"));
    copy(virtuAccount?.bankAccountNumber);
    notify1();
    // setCopyIcon(1);
    // setCopyIconIfsc(0);
    dispatch(sendAnalitics("Copy_Ac_Web"));
    //alert(`You have copied "${link}"`);
  };

  const copyToClipboardIfsc = () => {
    dispatch(sendAnalitics("Copy_IFSC_Web"));
    copy(virtuAccount?.ifsc);
    // setCopyIconIfsc(1);
    // setCopyIcon(0);
    notify2();
    //alert(`You have copied "${link}"`);
  };

  // useEffect(()=>{
  //   dispatch(neftLinkedAccountsListAction());
  //   dispatch(sendAnalitics("NEFT/RTGS_MA_Web"));
  // },[]);

  const neftLinkedAccountsList= useSelector((state)=>state?.masterWallet?.neftLinkedAccountsList);
  //const AccountsList= neftLinkedAccountsList?.[0]==undefined?
  console.log("neftLinkedAccountsList", neftLinkedAccountsList, neftLinkedAccountsList?.[0]);
  const [display, setDisplay]= useState(false);
  return (
    <div>
      <div className="body-part">
        <SvgImage
          name="BankLogo"
          height="43.81"
          width="43.81"
          classname="modal-bank-logo"
        />
        <p className="body-sub-part1">Owner’s Master Account Balance</p>
        <p className="body-sub-part2">{"₹" + ownerLogipeBalance}</p>
      </div>
      <div className="sub-title-1">
        <span class="circle-logo">1</span>
        <p className="sub-head1">Add Balance Via</p>
      </div>

      <div className=" " style={{marginLeft:"5rem", display:"flex"}}>
        {/* <button
          type="button"
          className={btn ? "set-btn-1" : "butt1"}
          onClick={() => handleClick(0)}
        >
          {btn === 0 ? <SvgImage name="TickLogo" /> : <></>}
          NEFT/RTGS
        </button>

        <button
          type="button"
          className={btn ? "set-btn-2" : "butt2-new"}
          onClick={() => handleClick(1)}
        >
          {btn === 1 ? <SvgImage name="TickLogo" /> : <></>}
          UPI/Net Banking/ Debit Card
        </button> */}
        <div  style={{padding:"0.5rem 0.3rem"}}>
          <Button className={btn === 0?"btnSelect":"btnNotSelect"} 
            onClick={() => handleClick(0)}>
            NEFT/RTGS
          </Button>
        </div>
        <div style={{padding:"0.5rem 0.3rem"}}>
          <Button className={btn === 1?"btnSelect":"btnNotSelect"} 
            onClick={() => handleClick(1)}>
            UPI
          </Button>
        </div>
        <div style={{padding:"0.5rem 0.3rem"}}>
          <Button className={btn === 2?"btnSelect":"btnNotSelect"} 
            onClick={() => handleClick(2)}>
            Net Banking/ Debit Card
          </Button>
        </div>
      </div>
      <div style={{paddingBottom:"2rem"}}>
        <div>
          <div>
            <div className="sub-title-1">
              <span class="circle-logo">2</span>
              <p className="transfer-head">
                Add funds to bank details mentioned below
              </p>
            </div>
            <Card className="bottom-card-neft">
              <CardBody>
                <CardTitle className="bottom-card-title">
                  LogiPe Bank Account
                </CardTitle>
                <div className="card-border"></div>
                <p className="card-text-1" style={{ marginTop: "17px" }}>
                  Account Name
                </p>
                <p className="card-text-2">LogiPe Fintech Private Limited</p>
                <div className="row">
                  <div
                    // className="card-copied-logo"
                    className="col-12 row"
                  >
                    <div
                      // className="card-copied-body"
                      className="col-9"
                    >
                      <p className="card-text-1">Account Number</p>
                      <p className="card-text-2">
                        {virtuAccount?.bankAccountNumber}
                      </p>
                    </div>
                    <div className="col-3" onClick={copyToClipboard} style={{cursor:"pointer"}}>
                      {copyIcon === 0 ? (
                        <SvgImage
                          name="CopyLogo"
                          // classname="copied-logo"
                        />
                      ) : (
                        <SvgImage
                          name="CopiedLogo"
                          // classname="copied-logo"
                        />
                      )}
                    </div>
                  </div>
                  <div
                    // className="card-copied-logo"
                    className="col-12 row"
                  >
                    <div
                      // className="card-copied-body"
                      className="col-9"
                    >
                      <p className="card-text-1">Bank IFSC</p>
                      <p className="card-text-2">{virtuAccount?.ifsc}</p>
                    </div>
                    <div className="col-3" onClick={copyToClipboardIfsc} style={{cursor:"pointer"}}>
                      {copyIconIfsc === 0 ? (
                        <SvgImage
                          name="CopyLogo"
                          // classname="copied-logo"
                        />
                      ) : (
                        <SvgImage
                          name="CopiedLogo"
                          // classname="copied-logo"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            {/* <div className="impInfoDiv">
                <div className="impInfo row semiBold heading3 padding8">
                    <div className="col-10 padding8L">Important Information</div>
                    <div onClick={()=>{
                        setDisplay(!display);
                    }} className="col-2"><SvgImage name="orangeDownArrow" /></div>
                {
                    display?
                    <div className="impInfo text3 Regular padding8">Currently, we are only approving amount sent from linked company or personal current accounts. 
                        Kindly add your bank details before transferring.</div>
                    :<></>
                }</div>
            </div> */}
          </div>
          {/* <div>
            <div className="sub-title-1">
              <span class="circle-logo">3</span>
              <p className="transfer-head">Linked Accounts</p>
            </div>
            <div className="sub-head2-body">
              Select any one or add new bank details for adding balance to
              owner’s logipe account
            </div>
            <div className="linkedAccountsDiv padding16T">
            {
                neftLinkedAccountsList.map((data,i)=>{
                    const name=data?.fullName;
                    const account=data?.bankAccountNumber;
                    const initials= data?.fullName?.[0];
                    return(
                        <div className="linkedAccounts Regular padding8">
                            <div className="circleForList semiBold">{initials}</div>
                            <div className="padding8L">{name+", "}</div>
                            <div className="padding8L">{account}</div>
                        </div>
                    )
                })
            }
            </div>
          </div> */}
        </div>

        {/* <button type="button" className="butt4" onClick={handleAddBeneficiary}>
        Add new Account Details
        </button>
        {
          <AddNewAccount
            open={addBeneficiary}
            toggleModal={toggleModalAddBeneficiary}
            handleClose={handleCloseAddBeneficiary}
            //handleClick={handleClick}
            //btn={btn}
            ownerLogipeBalance={ownerLogipeBalance}
            caller="neftBankLink"
          />
        } */}
      </div>
    </div>
  );
}

export default CompanyAccountConnected;
