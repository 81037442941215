import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
// import CreateCustomerV2 from "./SubComponents/CreateCustomerV2";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustomersAction,
  clearAllCustomers,
} from "../../../../services/Store/Actions/customerAction";
import SvgImage from "../../../Icons/Icons";
import "./index.css";
import { BallTriangle } from "react-loader-spinner";
import {
  cleangetDriverVehicleHistory,
  getDriverVehicleHistory,
} from "../../../../services/Store/Actions/fastagAction";
import ProductDetails from "../../../LogiMart/Components/ProductDetails";
import ImagePopup from "./Image";

const moment = require("moment");

function VehicleHistory(props) {
  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);
  const { sideBar, salesHome, setSalesHome, id, path } = props;
  console.log("shjbshjbsbs", props, path, id);
  const { state } = useLocation();
  console.log("sbsjbjsb", state);
  const [createCust, setCreateCust] = useState(false);
  const [offset, setOffset] = useState(offsetVal);
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [text, setText] = useState("");

  const closeModal = () => {
    setOpen(false);
  };

  // const showModal=()=>{
  // setOpen(true);
  // }

  const toggleModal = () => {
    setOpen(!open);
  };

  const { driverVehicleHistory, driverVehicleHistoryList } = useSelector(
    (state) => state?.fastag
  );
  if (
    driverVehicleHistory?.count &&
    driverVehicleHistory?.count != "" &&
    driverVehicleHistory?.count != 0
  ) {
    setSalesHome(false);
  }
  console.log(
    "===pppp>>>>susuhs",
    driverVehicleHistory,
    driverVehicleHistoryList
  );

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // dispatch(
    //   getDriverVehicleHistory({
    //     id: '57d65c3e-e376-4a4e-9e29-96e165f026c6',
    //     type: 2,
    //   })
    // );
    return function cleanUp() {
      console.log("sbhsbsbjsbjsb");
      dispatch(cleangetDriverVehicleHistory());
      setOffset(0);
    };
  }, []);

  useEffect(() => {
    console.log("companyNamecompanyName", companyName, id, path);
    console.log("jbsjbs", {
      id: state?.path == "all" ? "all" : state?.id,
      type: state?.path == "all" ? 3 : state?.path == "fleet" ? "2" : "1",
      offset: 0,
      search: companyName,
    });
    dispatch(
      getDriverVehicleHistory({
        id: state?.path == "all" ? "all" : state?.id,
        type: state?.path == "all" ? 3 : state?.path == "fleet" ? "2" : "1",
        offset: 0,
        search: companyName,
      })
    );
    return function cleanUp() {
      dispatch(cleangetDriverVehicleHistory());
      setOffset(0);
    };
  }, [companyName]);

  const handlePrevButton = () => {
    if (offset > 0) {
      setOffset(offset - 10);

      dispatch(
        getDriverVehicleHistory({
          id: state?.path == "all" ? "all" : state?.id,
          type: state?.path == "all" ? 3 : state?.path == "fleet" ? "2" : "1",
          offset: offset - 10,
          search: companyName,
        })
      );
    }
  };

  const handleNextButton = () => {
    if (driverVehicleHistory?.count - offset > 10) {
      setOffset(offset + 10);
      dispatch(
        getDriverVehicleHistory({
          id: state?.path == "all" ? "all" : state?.id,
          type: state?.path == "all" ? 3 : state?.path == "fleet" ? "2" : "1",
          offset: offset + 10,
          search: companyName,
        })
      );
    }
  };

  return (
    <div className="topMargin">
      <div
        className="container sideBarMargin"
        style={{
          marginLeft: sideBar ? "230px" : "100px",
          maxWidth: sideBar ? windowWidth - 260 : windowWidth - 130,
          // height: "100vh",
          backgroundColor: "#FFFFFF",
        }}
      >
        <div>
          <div className="custText">Vehicle History</div>
          <div className="d-flex justify-content-between mx-4">
            <div className="d-flex">
              <div className="col-6">
                <div className="row">
                  <div className="col-12 custNamepadd">search</div>
                  <div className="col-12 custNamepadd2">
                    <input
                      className="inputBoxCust"
                      placeholder="Driver/vrn"
                      value={companyName}
                      autoFocus={companyName != "" ? true : false}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cusTableDiv">
            <Table
              className="CustSectionTable"
              // id="CustSectionTable1"
              bordered="true"
            >
              <thead>
                <tr className="table-header-cust">
                  <th style={{ width: "9%" }}>S. No</th>
                  <th style={{ width: "10%" }}>
                    <div>VRN</div>{" "}
                  </th>
                  <th style={{ width: "7%" }}>Name</th>
                  <th style={{ width: "9%" }}>Start Time</th>
                  <th style={{ width: "8%" }}>Reading</th>
                  <th style={{ width: "8%" }}>End Time</th>
                  <th style={{ width: "12%" }}>Reading</th>
                  <th style={{ width: "6%" }}>Total</th>
                  <th style={{ width: "6%" }}>Fuel</th>
                  <th style={{ width: "6%" }}>FasTag</th>
                  <th style={{ width: "6%" }}>UPI</th>
                  <th style={{ width: "6%" }}>Salary</th>
                </tr>
              </thead>
              <tbody
                className="lowerBorderLineCust"
                style={{ height: "560px" }}
              >
                {console.log("=====>>>", driverVehicleHistory)}
                {driverVehicleHistory?.rows?.map((data, idx) => (
                  <tr
                    className="table-row-manage-cust-new text4"
                    key={idx}
                    onClick={() => {
                      // window.location = "/Customers/" + 1;
                      // navigate("/Customers/" + data?.customerId);
                    }}
                  >
                    <td
                      style={{
                        width: "2%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      <div>{idx + 1}</div>
                    </td>
                    <td
                      style={{
                        width: "9%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      {data?.vrn}
                    </td>
                    <td
                      style={{
                        width: "10%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      {data?.driverName}
                    </td>
                    <td
                      style={{
                        width: "9%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      {data?.createdAt
                        ? moment(data?.createdAt).format("DD-MM-YYYY HH:mm:ss")
                        : "N/A"}
                    </td>
                    <td
                      style={{
                        width: "7%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                        flex: "row",
                      }}
                    >
                      {data?.initialValue || "N/A"}
                      <br />
                      <a
                        onClick={() => {
                          console.log("mess");
                          setUrl(data?.initialImage);
                          setText("Initial Image");
                          console.log(data?.initialImage);
                          setOpen(true);
                        }}
                        style={{
                          color: "blue",
                        }}
                      >
                        {"View Image"}
                      </a>
                    </td>

                    <td
                      style={{
                        width: "9%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      {data?.logout
                        ? moment(data?.createdAt).format("DD-MM-YYYY HH:mm:ss")
                        : "Runing"}
                    </td>
                    <td
                      style={{
                        width: "8%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      {data?.finalValue || "N/A"}
                      <br />
                      <a
                        onClick={() => {
                          console.log("mess");
                          setUrl(data?.finalImage);
                          setText("Final Image");
                          setOpen(true);
                        }}
                        style={{
                          color: "blue",
                        }}
                      >
                        {"View Image"}
                      </a>
                    </td>

                    <td
                      style={{
                        width: "6%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      {data?.total || "N/A"}
                    </td>
                    <td
                      style={{
                        width: "6%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      {data?.fuel || "N/A"}
                    </td>
                    <td
                      style={{
                        width: "6%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      {data?.wage || "N/A"}
                    </td>
                    <td
                      style={{
                        width: "6%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      {data?.wage || "N/A"}
                    </td>
                    <td
                      style={{
                        width: "6%",
                        borderLeft: "1px solid transparent",
                        borderRight: " 1px solid transparent",
                      }}
                    >
                      {data?.wage || "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="nextRow ">
              <div className="nextRowCont ">
                <button
                  className="PrevButton"
                  onClick={handlePrevButton}
                  style={{ opacity: offset > 0 ? "1" : "0.5" }}
                >
                  <SvgImage name="PrevArrow" heigth="12px" width="12px" />
                </button>
                <button
                  className="nextButton"
                  onClick={handleNextButton}
                  style={{
                    opacity:
                      driverVehicleHistory?.count - offset > 10 ? "1" : "0.5",
                  }}
                >
                  <SvgImage name="NextArrow" heigth="12px" width="12px" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="imageDiv">
                            <img src={'https://storage.googleapis.com/logipe-assets/9483532972/1705909499677-.jpg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=storage%40server-369506.iam.gserviceaccount.com%2F20240124%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240124T104845Z&X-Goog-Expires=5400&X-Goog-SignedHeaders=host&X-Goog-Signature=364fb2d59183d3105b94a44e19462f416485034e52c51a36be5c798dfb2215d1b035de8d269a4dae8225b21f1e88fdb0346ed90b7b2e63d00a8fed88396a94d20fff4161d7b6a71c74691d4bdf7c02443840335ef3dc873d79532122396d4916f78dd9a7f040ae25d03d2386daf775c901afbdd3bdfdd0d83c06dede07ec7961772bbf313ff505ffc6b6dfa969f295a11b26595c461ab2cc7d851c9e7f0a0f1ae0d30f347d4e033b4f0d4b84d8c2c9aeaa00e5bc518d3affedc9ad0af657e5a0e2ea579bbe910fe3afb38d4798a876fce8b98a3da46062ced50f4c719a26a37383a8264b83540c71160332f5ab576584096ae2c12ddf58e16ccbca9d111a71d2'} className="imgTyre" alt="shape-dot10" 
                            style={{height:"10%", alignItems:"centre"}}/>
                        </div> */}
        <ImagePopup
          open={open}
          onHide={closeModal}
          toggleModal={toggleModal}
          handleClose={closeModal}
          image={url}
          text={text}
        />

        {/* <CreateCustomerV2
                toggle={toggleCreateCust}
                modal={createCust}
                setModal={setCreateCust}
              /> */}
        {/* <PaymentsModal 
          toggle={toggleCreateCust} modal={createCust} setModal={setCreateCust} 
        /> */}
      </div>
    </div>
  );
}
export default VehicleHistory;
