import {
  OMC_BALANCE,
  OMC_BALANCE_SUCCESS,
  OMC_LOAD_MONEY,
  OMC_LOAD_MONEY_SUCCESS,
  OMC_TRANSACTIONS,
  OMC_TRANSACTIONS_SUCCESS,
  OMC_VEHICLE_LIST,
  OMC_VEHICLE_LIST_SUCCESS,
  OMC_VEH_STATUS,
  OMC_VEH_STATUS_SUCCESS,
  OMC_WITHDRAW_MONEY,
  OMC_WITHDRAW_MONEY_SUCCESS,
  OMC_DRIVER_DATA,
  OMC_DRIVER_DATA_SUCCESS,
  OMC_NUMBER_CHANGE_SUCCESS,
  OMC_NUMBER_CHANGE,
  OMC_REGISTER_DRIVER_SUCCESS,
  OMC_REGISTER_DRIVER,
  CLEAN_OMC_TRANSACTIONS,
  DRIVER_ADD_BENEFICIARY,
  DRIVER_ADD_BENEFICIARY_SUCCESS,
  GET_DRIVER_BENEFICIARY,
  GET_DRIVER_BENEFICIARY_SUCCESS,
  GET_CITY_STATE,
  GET_CITY_STATE_SUCCESS,
  DRIVER_BANK_TRANSFER,
  DRIVER_BANK_TRANSFER_SUCCESS,
  ADD_DRIVER_CARD_BENEFICIARY,
  ADD_DRIVER_CARD_BENEFICIARY_SUCCESS,
  CLEAN_OMC_LOADER,
  FUEL_WALLET_RECHARGE,
  FUEL_WALLET_RECHARGE_SUCCESS,
  GET_FUEL_WALLET_BAL,
  GET_FUEL_WALLET_BAL_SUCCESS,
  GET_FUEL_WALLET_HISTORY,
  GET_FUEL_WALLET_HISTORY_SUCCESS,
  FUEL_WALLET_LIMIT_UPDATE,
  FUEL_WALLET_LIMIT_UPDATE_SUCCESS,
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  omc_balace: {},
  omcTransaction: {},
  omc_veh_status: {},
  omc_vehicles: {},
  omcDriverData: {},
  driverBeneficiaryList: {},
  cityStateList: {},
  fuelWalletBalance: "",
  fuelWalletNumbers: "",
  FuelWalletTransaction:{}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DRIVER_BANK_TRANSFER: {
      return {
        ...state,
        loader: true,
      };
    }
    case DRIVER_BANK_TRANSFER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case ADD_DRIVER_CARD_BENEFICIARY: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_DRIVER_CARD_BENEFICIARY_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case DRIVER_ADD_BENEFICIARY: {
      return {
        ...state,
        loader: true,
      };
    }
    case DRIVER_ADD_BENEFICIARY_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_DRIVER_BENEFICIARY: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_DRIVER_BENEFICIARY_SUCCESS: {
      return {
        ...state,
        loader: false,
        driverBeneficiaryList: action?.payload,
      };
    }
    case GET_CITY_STATE: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_CITY_STATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        cityStateList: action?.payload,
      };
    }
    case CLEAN_OMC_TRANSACTIONS: {
      return {
        ...state,
        omcTransaction: {},
      };
    }
    case OMC_REGISTER_DRIVER: {
      return {
        ...state,
        loader: true,
      };
    }
    case OMC_REGISTER_DRIVER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case OMC_NUMBER_CHANGE: {
      return {
        ...state,
        loader: true,
      };
    }
    case OMC_NUMBER_CHANGE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case CLEAN_OMC_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case OMC_BALANCE: {
      return {
        ...state,
        loader: true,
      };
    }
    case OMC_BALANCE_SUCCESS: {
      return {
        ...state,
        loader: false,
        omc_balace: action.payload,
      };
    }
    case OMC_LOAD_MONEY: {
      return {
        ...state,
        loader: true,
      };
    }
    case OMC_LOAD_MONEY_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    ////

    case FUEL_WALLET_RECHARGE: {
      return {
        ...state,
        loader: true,
      };
    }
    case FUEL_WALLET_RECHARGE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    ////
    case OMC_TRANSACTIONS: {
      return {
        ...state,
        loader: true,
      };
    }
    case OMC_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        loader: false,
        omcTransaction: action?.payload,
      };
    }
    case OMC_VEH_STATUS: {
      return {
        ...state,
        loader: true,
      };
    }
    case OMC_VEH_STATUS_SUCCESS: {
      return {
        ...state,
        loader: false,
        omc_veh_status: action.payload,
      };
    }
    case OMC_VEHICLE_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case OMC_VEHICLE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        omc_vehicles: action.payload,
      };
    }
    case OMC_WITHDRAW_MONEY: {
      return {
        ...state,
        loader: true,
      };
    }
    case OMC_WITHDRAW_MONEY_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case OMC_DRIVER_DATA: {
      console.log("api - step getting info--3.0", action);
      return {
        ...state,
        loader: true,
      };
    }
    case OMC_DRIVER_DATA_SUCCESS: {
      console.log("api - step getting info--3.1", action?.payload);
      return {
        ...state,
        loader: false,
        omcDriverData: action?.payload,
      };
    }

    case GET_FUEL_WALLET_BAL: {
      console.log("GET_FUEL_WALLET_BAL", action);
      return {
        ...state,
        loader: true,
      };
    }
    case GET_FUEL_WALLET_BAL_SUCCESS: {
      console.log("GET_FUEL_WALLET_BAL_SUCCESS", action?.payload);
      return {
        ...state,
        loader: false,
        fuelWalletBalance: action?.payload?.fuelWallet,
        fuelWalletNumbers: action?.payload?.numbers,
      };
    }
    case GET_FUEL_WALLET_HISTORY: {
      console.log("GET_FUEL_WALLET_BAL", action);
      return {
        ...state,
        loader: true,
      };
    }
    case GET_FUEL_WALLET_HISTORY_SUCCESS: {
      console.log("GET_FUEL_WALLET_BAL_SUCCESS", action?.payload);
      return {
        ...state,
        loader: false,
        FuelWalletTransaction: action?.payload,
      };
    }
    case FUEL_WALLET_LIMIT_UPDATE: {
      console.log("GET_FUEL_WALLET_BAL", action);
      return {
        ...state,
        loader: true,
      };
    }
    case FUEL_WALLET_LIMIT_UPDATE_SUCCESS: {
      console.log("GET_FUEL_WALLET_BAL_SUCCESS", action?.payload);
      return {
        ...state,
        loader: false,
      };
    }

    default: {
      return state;
    }
  }
};
