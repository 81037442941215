import moment from "moment";

function ViewTripCard(props) {
  const { tripData } = props;
  console.log("tripDataViewTripCardtripData", tripData);
  return (
    <div className={"endTripCard"} style={{ padding: "12px" }}>
      <div
        style={{
          alignSelf: "center",
          fontWeight: 600,
        }}
      >
        <text>Trip Details {tripData?.tripNo}</text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "10px",
        }}
      >
        <div style={{ marginRight: "40px" }}>
          <div style={{ fontWeight: 600 }}> Vehicle: </div>
          <div>{tripData?.Fleet?.regNumber} </div>
          <div style={{ fontWeight: 600 }} className="mt-3">
            {" "}
            From:{" "}
          </div>
          <div>
            {tripData?.sourceAddress
              ? tripData?.sourceAddress?.cityName +
                ", " +
                tripData?.sourceAddress?.stateName
              : tripData?.source}{" "}
          </div>
          <div style={{ fontWeight: 600 }} className="mt-3">
            {" "}
            StartDate (actual):{" "}
          </div>
          <div>
            {tripData?.startTime || tripData?.expectedStartTime
              ? moment(
                  tripData?.startTime || tripData?.expectedStartTime || ""
                ).format("DD/MM/YYYY HH:mm")
              : "--"}{" "}
          </div>
        </div>
        <div>
          <div style={{ fontWeight: 600 }}> Driver: </div>
          <div>
            {tripData?.User
              ? tripData?.User?.fullName + ", " + tripData?.User?.phone
              : "-"}{" "}
          </div>
          <div style={{ fontWeight: 600 }} className="mt-3">
            {" "}
            To:{" "}
          </div>
          <div>
            {tripData?.destAddress
              ? tripData?.destAddress?.cityName +
                ", " +
                tripData?.destAddress?.stateName
              : tripData?.destination}{" "}
          </div>
          <div style={{ fontWeight: 600 }} className="mt-3">
            {" "}
            EndDate (Expacted):{" "}
          </div>
          <div>
            {tripData?.expectedEndTime
              ? moment(tripData?.expectedEndTime || "").format(
                  "DD/MM/YYYY HH:mm"
                )
              : "--"}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ViewTripCard;
