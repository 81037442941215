import {
    GET_CARD_DETAILS,
    GET_CARD_DETAILS_SUCCESS,
    GET_EXPENSE_BALANCE,
    GET_EXPENSE_BALANCE_SUCCESS,
    MASTERWALLET_TRANSACTION_HISTORY,
    MASTERWALLET_TRANSACTION_HISTORY_SUCCESS,
    WALLET_SUMMARY,
    WALLET_SUMMARY_SUCCESS,
    GET_CARD_CONTROL,
    GET_CARD_CONTROL_SUCCESS,
    EDIT_CARD_CONTROL,
  EDIT_CARD_CONTROL_SUCCESS,
  WALLET_ADD_MONEY,
  WALLET_ADD_MONEY_SUCCESS,
  WALLET_WITHDRAW_MONEY,
  WALLET_WITHDRAW_MONEY_SUCCESS,
  CHANGE_CARD_STATUS,
  CHANGE_CARD_STATUS_SUCCESS,
  GET_BALANCE_DETAILS,
  GET_BALANCE_DETAILS_SUCCESS,
  CLEAN_WALLET_SUMMARY, 
  NEW_CARD_BAL,
  NEW_CARD_BAL_SUCCESS,
 } from "../storeTypes";
const INIT_STATE = {
    loader:false,
    cardDetails:{},
    expenseBal:{},
    transactionHistoryList: [],
    walletSummary: {},
    cardContolDetails:{},
    balanceDetails:{},
    walletSummaryList: [],
    balanceRecord:{}
}
export default (state=INIT_STATE,action)=>{
           switch(action.type){
            case GET_BALANCE_DETAILS:{
                //console.log(action)
              return{
                ...state,
                loader:true,
              }
            }
            case GET_BALANCE_DETAILS_SUCCESS:{
              return{
                ...state,
                loader:false,
                balanceDetails:action.payload
              }
            }
            case WALLET_ADD_MONEY: {
                return {
                  ...state,
                  loader: true,
                };
              }
              case WALLET_ADD_MONEY_SUCCESS: {
                return {
                  ...state,
                  loader: false,
                };
              }
              case WALLET_WITHDRAW_MONEY: {
                return {
                  ...state,
                  loader: true,
                };
              }
              case WALLET_WITHDRAW_MONEY_SUCCESS: {
                return {
                  ...state,
                  loader: false,
                };
              }
              //  case GET_CARD_DETAILS:{
              //      return{
              //       ...state,
              //       loader:true,
              //      }
              //  }
              //  case GET_CARD_DETAILS_SUCCESS:{
              //      return{
              //          ...state,
              //          loader:true,
              //          cardDetails:action.payload,
              //      }
              //  }
               case GET_EXPENSE_BALANCE:{
                   return{
                       ...state,
                       loader:true,
                   }
                }
                case GET_EXPENSE_BALANCE_SUCCESS:{
                    return{
                       ...state,
                       loader:true,
                       expenseBal:action.payload
                    }
                }
                case MASTERWALLET_TRANSACTION_HISTORY: {
                    return {
                      ...state,
                      loader: true,
                    };
                  }
                  case MASTERWALLET_TRANSACTION_HISTORY_SUCCESS: {
                    return {
                      ...state,
                      loader: false,
                      transactionHistory: action.payload,
                      transactionHistoryList: [
                        ...state.transactionHistoryList,
                        ...action?.payload?.rows,
                      ],
                    };
                  }
                  case WALLET_SUMMARY: {
                    return {
                      ...state,
                      loader: true,
                    };
                  }
                  case WALLET_SUMMARY_SUCCESS: {
                    return {
                      ...state,
                      loader: false,
                      walletSummary: action.payload,
                      walletSummaryList: [...state.walletSummaryList, ...action?.payload?.rows],                    };
                  }
                  case CLEAN_WALLET_SUMMARY:{
                    return{
                      ...state,
                      loader:false,
                      walletSummary:{},
                      walletSummaryList:[]

                    }
                  }
                  case NEW_CARD_BAL: {
                    return {
                      ...state,
                      loader: true,
                    };
                  }
                  case NEW_CARD_BAL_SUCCESS: {
                    return {
                      ...state,
                      loader: false,
                      balanceRecord: action.payload,
                    };
                  }
               default:{
                   return state;
               }
           }
}