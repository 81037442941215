import {
  ADD_CASH_GIVEN,
  ADD_CASH_GIVEN_SUCCESS,
  CALCULATE_TRIP_TOLL,
  CALCULATE_TRIP_TOLL_SUCCESS,
  CASH_TRANSACTIONS,
  CASH_TRANSACTIONS_SUCCESS,
  CLEAN_TRANSACTION_LIST,
  CLEAR_TRIP_DETAILS,
  CLEAR_TRIP_LIST,
  CREATE_TRIP,
  CREATE_TRIP_SUCCESS,
  DELETE_TRIP,
  DELETE_TRIP_SUCCESS,
  EDIT_CASH_GIVEN,
  EDIT_CASH_GIVEN_SUCCESS,
  END_TRIP,
  END_TRIP_SUCCESS,
  FASTAG_VEHICLE_TRANSACTION_BY_FLEET,
  FASTAG_VEHICLE_TRANSACTION_BY_FLEET_SUCCESS,
  GET_CITY_AND_STATE,
  GET_CITY_AND_STATE_SUCCESS,
  GET_ESTIMATED_FUEL,
  GET_ESTIMATED_FUEL_SUCCESS,
  GET_M2P_CARD_TRANSACTIONS,
  GET_M2P_CARD_TRANSACTIONS_SUCCESS,
  GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION,
  GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION_SUCCESS,
  GET_TRANSACTIONS_AND_EXPENSE,
  GET_TRANSACTIONS_AND_EXPENSE_SUCCESS,
  GET_TRIPS,
  GET_TRIPS_BY_FLEET,
  GET_TRIPS_BY_FLEET_SUCCESS,
  GET_TRIPS_ERROR,
  GET_TRIPS_SUCCESS,
  GET_WEATHER_DATA,
  GET_WEATHER_DATA_SUCCESS,
  NO_CARD_FOR_DRIVER,
  NO_FASTAG_FOR_FLEET,
  UPDATE_TRIP,
  UPDATE_TRIP_SUCCESS,
  CALCULATE_TRIP_TOLL_INTERNAL,
  CALCULATE_TRIP_TOLL_INTERNAL_SUCCESS,
  GET_TRIP_TRANSACTION,
  GET_TRIP_TRANSACTION_SUCCESS,
  GET_TRIPS_BY_USERID,
  GET_TRIPS_BY_USERID_SUCCESS,
  CLEAN_TRIPS_BY_USERID,
  TRIP_REPORT,
  TRIP_REPORT_SUCCESS,
  CLEAN_TRIP_REPORT,
  ADD_DRIVERV2_SUCCESS,
  ADD_DRIVERV2,
  ADD_FREIGHT,
  ADD_FREIGHT_SUCCESS,
  GET_TRIPS_NEW,
  GET_TRIPS_NEW_SUCCESS,
  GET_TRIPS_NEW_ERROR,
  EDIT_FREIGHT,
  EDIT_FREIGHT_SUCCESS,
  GET_FREIGHT_DETAILS,
  GET_FREIGHT_DETAILS_SUCCESS,
  FIND_ADDRESS_TRIP_SUCCESS,
  FIND_ADDRESS_TRIP,
  ADD_TRIP_ADDRESS,
  ADD_TRIP_ADDRESS_SUCCESS,
  TRIP_BULK_ACTIONS,
  TRIP_BULK_ACTIONS_SUCCESS,
  GET_ADDRESS_FROM_PINCODE,
  GET_ADDRESS_FROM_PINCODE_SUCCESS,
  CREATE_LR,
  CREATE_LR_SUCCESS,
  GET_LR_LIST,
  GET_LR_LIST_SUCCESS,
  EDIT_LR,
  EDIT_LR_SUCCESS,
  GET_PINCODE_DATA,
  GET_PINCODE_DATA_SUCCESS,
  MARK_INVOICE_AS_PAID,
  MARK_INVOICE_AS_PAID_SUCCESS,
  GEN_LR_NO,
  GEN_LR_NO_SUCCESS,
  FIND_ADDRESS_TRIP_V2,
  FIND_ADDRESS_TRIP_V2_SUCCESS,
  GET_SAVED_ADDRESS,
  GET_SAVED_ADDRESS_SUCCESS,
  ADD_CASH_EXPANSE,
  ADD_CASH_EXPANSE_SUCCESS,
  GET_ROUTES,
  GET_ROUTES_SUCCESS,
  ADD_ROUTE,
  ADD_ROUTE_SUCCESS,
  GET_DRIVER_LEDGER,
  CLEAR_DRIVER_LEDGER,
  GET_DRIVER_LEDGER_SUCCESS,
  GET_VEHICLE_LEDGER,
  CLEAR_VEHICLE_LEDGER,
  GET_VEHICLE_LEDGER_SUCCESS,
  GET_TRIP_LEDGER,
  CLEAR_TRIP_LEDGER,
  GET_TRIP_LEDGER_SUCCESS,
  CREATE_TRIP_EXPENSE,
  CREATE_TRIP_EXPENSE_SUCCESS,
  GET_EACH_ROUTE,
  GET_EACH_ROUTE_SUCCESS,
  ADD_DRIVER_EXPENSE,
  ADD_DRIVER_EXPENSE_SUCCESS,
  GET_VENDOR_LEDGER,
  GET_VENDOR_LEDGER_SUCCESS,
  EDIT_LEDGER_EXPENSE,
  EDIT_LEDGER_EXPENSE_SUCCESS,
  GET_VEHICLE_LAST_TRIP,
  GET_VEHICLE_LAST_TRIP_SUCCESS,
  EDIT_TRIP_EXPENSE,
  EDIT_TRIP_EXPENSE_SUCCESS,
  GET_TRIP_EXPENSE,
  GET_TRIP_EXPENSE_SUCCESS,
  START_TRIP_EXPENSE,
  START_TRIP_EXPENSE_SUCCESS,
  GET_SIGNED_URL_WITH_KEY,
  GET_SIGNED_URL_WITH_KEY_SUCCESS,
  UPDATE_LR_IN_TRIP,
  UPDATE_LR_IN_TRIP_SUCCESS,
  CLEAR_VENDOR_LEDGER,
  BULK_FUEL_TRANSFER_UPLOAD,
  BULK_FUEL_TRANSFER_UPLOAD_SUCCESS,
  CLEAR_LR_LIST,
  ADD_ROUTE_BULK_EXCEL_UPLOAD,
ADD_ROUTE_BULK_EXCEL_UPLOAD_SUCCESS,
ADD_ROUTE_BULK_EXCEL,
ADD_ROUTE_BULK_EXCEL_SUCCESS,
ADD_TRIP_BULK_EXCEL_UPLOAD,
ADD_TRIP_BULK_EXCEL_UPLOAD_SUCCESS,
ADD_TRIP_BULK_EXCEL,
ADD_TRIP_BULK_EXCEL_SUCCESS
} from "../storeTypes";

export function getTripsByUserId(data) {
  return {
    type: GET_TRIPS_BY_USERID,
    payload: data,
  };
}
export function getTripsByUserIdSuccess(data) {
  return {
    type: GET_TRIPS_BY_USERID_SUCCESS,
    payload: data,
  };
}
export function cleanTripsByUserId() {
  return {
    type: CLEAN_TRIPS_BY_USERID,
  };
}
export function createTrip(data, callback) {
  console.log("CREATETRIPS", data);
  return {
    type: CREATE_TRIP,
    payload: data,
    callback,
  };
}
export function createTripSuccess(data) {
  return {
    type: CREATE_TRIP_SUCCESS,
    // payload: data,
  };
}

export function getTrips(data) {
  console.log("in TripsAction");
  return {
    type: GET_TRIPS,
    payload: data,
  };
}
export function getTripsSuccess(data) {
  return {
    type: GET_TRIPS_SUCCESS,
    payload: data,
  };
}
export function getRoute(data) {
  console.log("in TripsAction");
  return {
    type: GET_ROUTES,
    payload: data,
  };
}
export function getRouteSuccess(data) {
  return {
    type: GET_ROUTES_SUCCESS,
    payload: data,
  };
}

export function addRoute(data, callBack) {
  console.log("add TripsAction");
  return {
    type: ADD_ROUTE,
    payload: data,
    callBack,
  };
}
export function addRouteSuccess(data) {
  return {
    type: ADD_ROUTE_SUCCESS,
    payload: data,
  };
}

export function getTripsError() {
  return {
    type: GET_TRIPS_ERROR,
  };
}
export function getTripsNew(data) {
  console.log("in TripsAction");
  return {
    type: GET_TRIPS_NEW,
    payload: data,
  };
}
export function getTripsNewSuccess(data) {
  return {
    type: GET_TRIPS_NEW_SUCCESS,
    payload: data,
  };
}
export function getTripsNewError() {
  return {
    type: GET_TRIPS_NEW_ERROR,
  };
}

export function getTripsByFleet(id) {
  return {
    type: GET_TRIPS_BY_FLEET,
    payload: id,
  };
}
export function getTripsByFleetSuccess(data) {
  return {
    type: GET_TRIPS_BY_FLEET_SUCCESS,
    payload: data,
  };
}

export function clearTripList() {
  return {
    type: CLEAR_TRIP_LIST,
  };
}

export function endTrip(id, callback) {
  console.log("time to end");
  return {
    type: END_TRIP,
    payload: id,
    callback,
  };
}
export function endTripSuccess() {
  return {
    type: END_TRIP_SUCCESS,
    // payload: data,
    // callback,
  };
}

export function deleteTrip(data, callback) {
  return {
    type: DELETE_TRIP,
    payload: data,
    // callback,
  };
}

export function deleteTripSuccess(data) {
  return {
    type: DELETE_TRIP_SUCCESS,
    payload: data,
  };
}

export function updateTrip(data, callback) {
  return {
    type: UPDATE_TRIP,
    payload: data,
    callback,
  };
}

export function updateTripSuccess(data) {
  return {
    type: UPDATE_TRIP_SUCCESS,
    // payload: data,
    // callback,
  };
}

export function fastagVehicleTransactionByFleet(data) {
  return {
    type: FASTAG_VEHICLE_TRANSACTION_BY_FLEET,
    payload: data,
  };
}

export function fastagVehicleTransactionByFleetSuccess(data) {
  return {
    type: FASTAG_VEHICLE_TRANSACTION_BY_FLEET_SUCCESS,
    payload: data,
  };
}

export function getTransactionsAndExpense(data) {
  return {
    type: GET_TRANSACTIONS_AND_EXPENSE,
    payload: data,
  };
}

export function getTransactionsAndExpenseSuccess(data) {
  return {
    type: GET_TRANSACTIONS_AND_EXPENSE_SUCCESS,
    payload: data,
  };
}

export function getCashTransactions(data) {
  return {
    type: CASH_TRANSACTIONS,
    payload: data,
  };
}

export function getCashTransactionsSuccess(data) {
  return {
    type: CASH_TRANSACTIONS_SUCCESS,
    payload: data,
  };
}

export function addCashGiven(data, callback) {
  return {
    type: ADD_CASH_GIVEN,
    payload: data,
    callback,
  };
}

export function addCashGivenSuccess() {
  return {
    type: ADD_CASH_GIVEN_SUCCESS,
    // payload: data,
    // callback,
  };
}

export function editCashGiven(data) {
  return {
    type: EDIT_CASH_GIVEN,
    payload: data,
  };
}

export function editCashGivenSuccess(data, callback) {
  return {
    type: EDIT_CASH_GIVEN_SUCCESS,
    payload: data,
    callback,
  };
}

export function cleanTransactionList(data) {
  return {
    type: CLEAN_TRANSACTION_LIST,
  };
}

export function getCityAndState(data) {
  console.log("GETCITY", data);
  return {
    type: GET_CITY_AND_STATE,
    payload: data,
  };
}

export function getCityAndStateSuccess(data) {
  return {
    type: GET_CITY_AND_STATE_SUCCESS,
    payload: data,
  };
}

export function noFastagForFleet() {
  return {
    type: NO_FASTAG_FOR_FLEET,
  };
}

export function noCardForDriver() {
  return {
    type: NO_CARD_FOR_DRIVER,
  };
}

export function getWeatherData(data) {
  return {
    type: GET_WEATHER_DATA,
    payload: data,
  };
}

export function getWeatherDataSuccess(data) {
  return {
    type: GET_WEATHER_DATA_SUCCESS,
    payload: data,
  };
}

export function getEstimatedFuel(data) {
  return {
    type: GET_ESTIMATED_FUEL,
    payload: data,
  };
}
export function getEstimatedFuelSuccess(data) {
  return {
    type: GET_ESTIMATED_FUEL_SUCCESS,
    payload: data,
  };
}

export function getTollBetweenSourceAndDestination(data) {
  return {
    type: GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION,
    payload: data,
  };
}

export function getTollBetweenSourceAndDestinationSuccess(data) {
  return {
    type: GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION_SUCCESS,
    payload: data,
  };
}

export function getM2pCardTransactions(data) {
  return {
    type: GET_M2P_CARD_TRANSACTIONS,
    payload: data,
  };
}

export function getM2pCardTransactionsSuccess(data) {
  return {
    type: GET_M2P_CARD_TRANSACTIONS_SUCCESS,
    payload: data,
  };
}

export function calculateTripToll(payload) {
  return {
    type: CALCULATE_TRIP_TOLL,
    payload,
  };
}
export function calculateTripTollSuccess(payload) {
  return {
    type: CALCULATE_TRIP_TOLL_SUCCESS,
    payload,
  };
}

export function calculateTripTollInternal(payload) {
  console.log("internal toll payload=>", payload);
  return {
    type: CALCULATE_TRIP_TOLL_INTERNAL,
    payload,
  };
}
export function calculateTripTollInternalSuccess(payload) {
  return {
    type: CALCULATE_TRIP_TOLL_INTERNAL_SUCCESS,
    payload,
  };
}
export function clearTripDetails() {
  return {
    type: CLEAR_TRIP_DETAILS,
  };
}

export function getTripTransaction(payload) {
  console.log("HERE in TRIP TR", payload);
  return {
    type: GET_TRIP_TRANSACTION,
    payload,
  };
}
export function getTripTransactionSuccess(payload) {
  return {
    type: GET_TRIP_TRANSACTION_SUCCESS,
    payload,
  };
}

export function tripReport(payload, callback) {
  return {
    type: TRIP_REPORT,
    payload,
    callback,
  };
}

export function tripReportSuccess(payload) {
  console.log("split the Earth");
  return {
    type: TRIP_REPORT_SUCCESS,
    payload,
  };
}

export function cleanReport() {
  return {
    type: CLEAN_TRIP_REPORT,
  };
}

export function addDriverV2(payload, callback) {
  return {
    type: ADD_DRIVERV2,
    payload,
    callback,
  };
}

export function addDriverV2Success(payload) {
  return {
    type: ADD_DRIVERV2_SUCCESS,
    payload,
  };
}
export function addFreight(payload, callback) {
  console.log("callback freightka", callback);
  return {
    type: ADD_FREIGHT,
    payload,
    callback,
  };
}

export function addFreightSuccess(payload) {
  return {
    type: ADD_FREIGHT_SUCCESS,
    //   payload,
  };
}

export function editFreight(payload, callback) {
  console.log("callback freightka", callback);
  return {
    type: EDIT_FREIGHT,
    payload,
    callback,
  };
}

export function editFreightSuccess(payload) {
  return {
    type: EDIT_FREIGHT_SUCCESS,
    //   payload,
  };
}

export function getFreightDetails(payload, callback) {
  console.log("callback freightka", callback);
  return {
    type: GET_FREIGHT_DETAILS,
    payload,
    callback,
  };
}

export function getFreightDetailsSuccess(payload) {
  return {
    type: GET_FREIGHT_DETAILS_SUCCESS,
    payload,
  };
}

export function findTripAddress(payload) {
  // console.log('callback freightka', callback)
  return {
    type: FIND_ADDRESS_TRIP,
    payload,
    //   callback,
  };
}

export function findTripAddressSuccess(payload) {
  return {
    type: FIND_ADDRESS_TRIP_SUCCESS,
    payload,
  };
}

export function addTripAddress(payload, callback) {
  console.log("addTripAddress");
  return {
    type: ADD_TRIP_ADDRESS,
    payload,
    callback,
  };
}

export function addTripAddressSuccess(payload) {
  return {
    type: ADD_TRIP_ADDRESS_SUCCESS,
    payload,
  };
}

export function tripBulkActions(payload, callback) {
  // console.log('callback freightka', callback)
  return {
    type: TRIP_BULK_ACTIONS,
    payload,
    callback,
  };
}

export function tripBulkActionsSuccess(payload) {
  return {
    type: TRIP_BULK_ACTIONS_SUCCESS,
    payload,
  };
}
export function getAddressFromPincodeAction(payload, callBack) {
  // console.log('callback freightka', callback)
  return {
    type: GET_ADDRESS_FROM_PINCODE,
    payload,
    callBack,
  };
}

export function getAddressFromPincodeSuccess(payload) {
  return {
    type: GET_ADDRESS_FROM_PINCODE_SUCCESS,
    payload,
  };
}

export function createLR(payload, callback) {
  console.log("callback freightka", payload);
  return {
    type: CREATE_LR,
    payload,
    callback,
  };
}

export function createLRSuccess(payload) {
  return {
    type: CREATE_LR_SUCCESS,
    payload,
  };
}

export function getLRList(payload, callback) {
  console.log("callback freightka", payload);
  return {
    type: GET_LR_LIST,
    payload,
    callback,
  };
}
export function clearLRList() {
  return {
    type: CLEAR_LR_LIST
  };
}

export function getLRListSuccess(payload) {
  return {
    type: GET_LR_LIST_SUCCESS,
    payload,
  };
}
export function editLR(payload, callback) {
  console.log("callback freightka", payload);
  return {
    type: EDIT_LR,
    payload,
    callback,
  };
}

export function editLRSuccess(payload) {
  return {
    type: EDIT_LR_SUCCESS,
    payload,
  };
}
export function getPinCodeData(payload, callback) {
  console.log("callback getPinCodeData", payload, callback);
  return {
    type: GET_PINCODE_DATA,
    payload,
    callback,
  };
}

export function getPinCodeDataSuccess(payload) {
  return {
    type: GET_PINCODE_DATA_SUCCESS,
    payload,
  };
}

export function markInvoiceAsPaid(payload, callback) {
  return {
    type: MARK_INVOICE_AS_PAID,
    payload,
    callback,
  };
}
export function markInvoiceAsPaidSuccess(payload) {
  return {
    type: MARK_INVOICE_AS_PAID_SUCCESS,
    payload,
  };
}

export function generateLrNo(payload, callback) {
  console.log("callback generateLrNo", payload, callback);
  return {
    type: GEN_LR_NO,
    payload,
    callback,
  };
}
export function generateLrNoSuccess(payload) {
  return {
    type: GEN_LR_NO_SUCCESS,
    payload,
  };
}

export function findTripAddressV2(payload) {
  // console.log('callback freightka', callback)
  return {
    type: FIND_ADDRESS_TRIP_V2,
    payload,
    //   callback,
  };
}

export function findTripAddressV2Success(payload) {
  return {
    type: FIND_ADDRESS_TRIP_V2_SUCCESS,
    payload,
  };
}

export function getSavedAddress(payload) {
  // console.log('callback freightka', callback)
  return {
    type: GET_SAVED_ADDRESS,
    payload,
    //   callback,
  };
}

export function getSavedAddressSuccess(payload) {
  return {
    type: GET_SAVED_ADDRESS_SUCCESS,
    payload,
  };
}
export function addCashExpense(payload, callBack) {
  // console.log('callback freightka', callback)
  return {
    type: ADD_CASH_EXPANSE,
    payload,
    callBack,
  };
}

export function addCashExpenseSuccess(payload) {
  return {
    type: ADD_CASH_EXPANSE_SUCCESS,
    payload,
  };
}

export function clearDriverLedger() {
  return {
    type: CLEAR_DRIVER_LEDGER,
  };
}

export function getDriverLedger(userId, payload, callback) {
  console.log("callback getDriverLedger", userId, payload, callback);
  return {
    type: GET_DRIVER_LEDGER,
    userId: userId,
    payload,
    callback,
  };
}
export function getDriverLedgerSuccess(payload) {
  return {
    type: GET_DRIVER_LEDGER_SUCCESS,
    payload,
  };
}

export function clearVehicleLedger() {
  return {
    type: CLEAR_VEHICLE_LEDGER,
  };
}

export function getVehicleLedger(fleetId, payload, callback) {
  console.log("callback getVehicleLedger", fleetId, payload, callback);
  return {
    type: GET_VEHICLE_LEDGER,
    fleetId: fleetId,
    payload,
    callback,
  };
}
export function getVehicleLedgerSuccess(payload) {
  return {
    type: GET_VEHICLE_LEDGER_SUCCESS,
    payload,
  };
}
export function clearTripLedger() {
  return {
    type: CLEAR_TRIP_LEDGER,
  };
}
export function getTripLedger(tripId, payload) {
  console.log("callback getTripLedger", tripId, payload);
  return {
    type: GET_TRIP_LEDGER,
    tripId: tripId,
    payload,
  };
}
export function getTripLedgerSuccess(response, payload) {
  return {
    type: GET_TRIP_LEDGER_SUCCESS,
    response,
    payload,
  };
}
export function createTripExpense(payload, callBack) {
  return {
    type: CREATE_TRIP_EXPENSE,
    payload,
    callBack,
  };
}

export function createTripExpenseSuccess(payload) {
  return {
    type: CREATE_TRIP_EXPENSE_SUCCESS,
    payload,
  };
}
export function getEachRoute(payload) {
  return {
    type: GET_EACH_ROUTE,
    payload,
  };
}

export function getEachRouteSuccess(payload) {
  return {
    type: GET_EACH_ROUTE_SUCCESS,
    payload,
  };
}

export function addDriverExpense(payload, callBack) {
  return {
    type: ADD_DRIVER_EXPENSE,
    payload,
    callBack,
  };
}

export function addDriverExpenseSuccess(payload) {
  return {
    type: ADD_DRIVER_EXPENSE_SUCCESS,
    payload,
  };
}

export function getVendorLedger(vendorId,payload) {
  return {
    type: GET_VENDOR_LEDGER,
    vendorId,
    payload,
  };
}

export function getVendorLedgerSuccess(payload) {
  return {
    type: GET_VENDOR_LEDGER_SUCCESS,
    payload,
  };
}

export function clearVendorLedger() {
  return {
    type: CLEAR_VENDOR_LEDGER,
  };
}

export function editLedgerExpense(payload, callBack) {
  return {
    type: EDIT_LEDGER_EXPENSE,
    payload,
    callBack,
  };
}

export function editLedgerExpenseSuccess(payload) {
  return {
    type: EDIT_LEDGER_EXPENSE_SUCCESS,
    payload,
  };
}
export function getVehicleLastTripAction(payload, callBack) {
  return {
    type: GET_VEHICLE_LAST_TRIP,
    payload,
    callBack,
  };
}

export function getVehicleLastTripSuccess(payload) {
  return {
    type: GET_VEHICLE_LAST_TRIP_SUCCESS,
    payload,
  };
}
export function editTripExpenseAction(payload, callBack) {
  return {
    type: EDIT_TRIP_EXPENSE,
    payload,
    callBack,
  };
}

export function editTripExpenseSuccess(payload) {
  return {
    type: EDIT_TRIP_EXPENSE_SUCCESS,
    payload,
  };
}
export function getTripExpenseAction(payload, callBack = () => {}) {
  return {
    type: GET_TRIP_EXPENSE,
    payload,
    callBack,
  };
}

export function getTripExpenseSuccess(payload) {
  return {
    type: GET_TRIP_EXPENSE_SUCCESS,
    payload,
  };
}
export function startTripExpenseAction(payload, callBack) {
  return {
    type: START_TRIP_EXPENSE,
    payload,
    callBack,
  };
}

export function startTripExpenseSuccess(payload) {
  return {
    type: START_TRIP_EXPENSE_SUCCESS,
    payload,
  };
}
export const signedUrlWithKey = (payload, callBack) => {
  return {
    type: GET_SIGNED_URL_WITH_KEY,
    payload,
    callBack,
  };
};
export const signedUrlWithKeySuccess = (payload) => {
  return {
    type: GET_SIGNED_URL_WITH_KEY_SUCCESS,
    payload,
  };
};
export const updateTripInLrAction = (payload, callBack) => {
  return {
    type: UPDATE_LR_IN_TRIP,
    payload,
    callBack,
  };
};
export const updateTripInLrSuccess = (payload) => {
  return {
    type: UPDATE_LR_IN_TRIP_SUCCESS,
    payload,
  };
};

export const bulkFuelTransferUpload = (payload,callback) => {
  return {
    type: BULK_FUEL_TRANSFER_UPLOAD,
    payload,
    callback
  };
};
export const bulkFuelTransferUploadSuccess = (payload) => {
  return {
    type: BULK_FUEL_TRANSFER_UPLOAD_SUCCESS,
    payload,
  };
};

export const addRouteBulkExcelUpload = (payload,callback) => {
  return {
    type: ADD_ROUTE_BULK_EXCEL_UPLOAD,
    payload,
    callback
  };
};
export const addRouteBulkExcelUploadSuccess = (payload) => {
  return {
    type: ADD_ROUTE_BULK_EXCEL_UPLOAD_SUCCESS,
    payload,
  };
};

export const addRouteBulkExcel = (payload,callback) => {
  return {
    type: ADD_ROUTE_BULK_EXCEL,
    payload,
    callback
  };
};
export const addRouteBulkExcelSuccess = (payload) => {
  return {
    type: ADD_ROUTE_BULK_EXCEL_SUCCESS,
    payload,
  };
};

export const addTripBulkExcelUpload = (payload,callback) => {
  return {
    type: ADD_TRIP_BULK_EXCEL_UPLOAD,
    payload,
    callback
  };
};
export const addTripBulkExcelUploadSuccess = (payload) => {
  return {
    type: ADD_TRIP_BULK_EXCEL_UPLOAD_SUCCESS,
    payload,
  };
};

export const addTripBulkExcel = (payload,callback) => {
  return {
    type: ADD_TRIP_BULK_EXCEL,
    payload,
    callback
  };
};
export const addTripBulkExcelSuccess = (payload) => {
  return {
    type: ADD_TRIP_BULK_EXCEL_SUCCESS,
    payload,
  };
};
