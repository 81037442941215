import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SvgImage from "../../../../Icons/Icons"


const NoDriverSelected=(props)=>{
    return (
        <div>
            <SvgImage name={props?.name ? props?.name : 'noDriverSelect'} />
        </div>
    )
}


export default NoDriverSelected;