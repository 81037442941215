import { call, put, takeEvery } from "redux-saga/effects";
import {
  // sendOtpApi,
  // verifyOtpApi,
  // registerUserApi,
  // autoFillCityStateApi,
  // toastStatus,
  // toastStatusSuccess,
  getCartDetailsApi,
  addToCartApi,
  removeToCartApi,
  getOrderListApi,
  createOrderApi,
  getOrderApi,
  addAddressDetailsApi,
  getAddressListApi,
  getAddressDetailsApi,
  getLogiCoinsApi,
  // createPaymentTokenApi,
  getInTouchApi,
  generateRecieptApi,
  getProductDetailApi,
  getProductListingApi,
  freshDeskTicketApi,
  orderPaymentApi,
  ticketWithoutAuthApi
} from "../../Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import { toastStatus } from "../Actions/appActions";
import {
  // sendOtpSuccessAction,
  // verifyOtpSuccessAction,
  // registerUserSuccessAction,
  // autoFillCityStateActionSuccess,
  getCartDetailsActionSuccess,
  addToCartActionSuccess,
  removeToCartActionSuccess,
  getOrderListActionSuccess,
  createOrderActionSuccess,
  getOrderActionSuccess,
  addAddressDetailsActionSuccess,
  getAddressListActionSuccess,
  getAddressDetailsActionSuccess,
  getLogicoinsSuccessAction,
  // createPaymentTokenSuccessAction,
  // stateofLogInButtonSuccess,
  getInTouchActionSuccess,
  generateRecieptActionSuccess,
  getProductDetailAction,
  getProductDetailActionSuccess,
  getProductListingActionSuccess,
  freshDeskTicketActionSuccess,
  orderPaymentActionSuccess,
  ticketWithoutAuthActionSuccess
} from "../Actions/ecomAction";
import {
  // SEND_OTP,
  // VERIFY_OTP,
  // REGISTER_USER,
  // AUTOFILL_CITY_STATE,
  // TOAST_STATUS,
  // LOG_IN_BUTTON,
  GET_CART_DETAILS,
  ADD_TO_CART,
  REMOVE_TO_CART,
  GET_ORDER_LIST,
  CREATE_ORDER,
  GET_ORDER,
  ADD_ADDRESS_DETAILS,
  GET_ADDRESS_LIST,
  GET_ADDRESS_DETAILS,
  GET_COINS_AVAILABLE,
  // CREATE_PAYMENT_TOKEN,
  SUPPORT_TICKET,
  GENERATE_RECEIPT,
  GET_PRODUCT_DETAILS,
  FRESHDESK_TICKET,
  ORDER_PAYMENT,
  FRESHDESK_TICKET_ECOM,
  GET_PRODUCT_LIST
} from "../storeTypes";



// function* sendOtpSaga(action) {
//   try {
//     const response = yield call(sendOtpApi, action?.payload);
//     if (response?.status == 200) {
//       console.log("otp resp", response);
//       action?.callback(response);
//       yield put(sendOtpSuccessAction(response?.data));
//     }
//   } catch (error) {
//     console.log("Error :- ", error?.response);
//     console.log("API Error:- ", processErrorAndRespond(error));
//   }
// }



// function* verifyOtpSaga(action) {
//   try {
//     const response = yield call(verifyOtpApi, action?.payload);
//     if (response?.status == 200) {
//       action?.callback(response);
//       yield put(verifyOtpSuccessAction(response?.data));
//     }
//   } catch (error) {
//     console.log("Error :- ", error?.response);
//     console.log("API Error:- ", processErrorAndRespond(error));
//   }
// }


// function* registerUserSaga(action) {
//   try {
//     const response = yield call(registerUserApi, action?.payload);
//     if (response?.status == 200) {
//       action?.callback(response);
//       yield put(registerUserSuccessAction(response?.data));
//     }
//   } catch (error) {
//     console.log("Error :- ", error?.response);
//     console.log("API Error:- ", processErrorAndRespond(error));
//   }
// }

// function* toastStatusSaga(action) {
//   try {
//     console.log("toast saga", action.payload);
//     //console.log("yahan", action.payload);
//     // const response = yield call(storeMediaFileApi, action.payload);
//     // if (response.type == "success") {
//     yield put(toastStatusSuccess(action.payload));
//     // action.callBack(response.data);
//     //ToastAndroid.show('Your media has been uploaded', ToastAndroid.SHORT);
//   } catch (error) {
//     // if (error?.response?.status == 400) {
//     //   action.callBack({
//     //     message: error?.response?.data.message,
//     //   });
//     // } else {
//     //   action.callBack(error?.response?.data);
//     // }
//     //ToastAndroid.show(error?.response?.data.message, ToastAndroid.SHORT);
//     console.log("Error :- ", error?.response?.data);
//     console.log("API Error:- ", processErrorAndRespond(error));
//   }
// }

// function* autoFillCityStateSaga(action) {
//   try {
//     const response = yield call(
//       autoFillCityStateApi,
//       action?.payload
//     );
//     console.log("saga-> fleet-- ", response);
//     if (response?.data?.type == "success") {
//       action.callback(response);
//       yield put(autoFillCityStateActionSuccess(response?.data?.data));
//     }
//   } catch (error) {
//     yield put(
//       toastStatus({
//         toastStatus: true,
//         message: `Error : ${error?.response?.data.message}`,
//         status: false,
//       })
//     );
//     //console.log("Error.Response:- ", error.response.data);
//     //Alert.alert('Error', error.response.data.message);
//     console.log("API Error in m2p transactions:-  ", processErrorAndRespond(error));
//   }
// }

// function* logInStatusSaga(action) {
//   try {
//     console.log("log in button saga", action.payload);
//     yield put(stateofLogInButtonSuccess(action.payload));
//   } catch (error) {
//     //console.log("Error.Response:- ", error.response.data);
//     //Alert.alert('Error', error.response.data.message);
//     console.log("Error in log in:-  ", processErrorAndRespond(error));
//   }
// }

function* getCartDetailsSaga(action) {
  try {
    const response = yield call(getCartDetailsApi);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(getCartDetailsActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* addToCartSaga(action) {
  try {
    console.log("ADD_TO_CART 3", action);
    const response = yield call(addToCartApi, action?.payload);
    console.log("ADD_TO_CART 4", response);
    if (response?.status == 200) {
      yield put(addToCartActionSuccess(response?.data));
      action?.callback(response);
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* removeToCartSaga(action) {
  try {
    const response = yield call(removeToCartApi, action?.id, action?.payload);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(removeToCartActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getOrderListSaga(action) {
  try {
    console.log("order list saga ", action);
    const response = yield call(getOrderListApi, action?.payload);
    if (response?.status == 200) {
      console.log("order list saga ", response);
      action?.callback(response);
      yield put(getOrderListActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* createOrderSaga(action) {
  try {
    const response = yield call(createOrderApi, action?.payload);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(createOrderActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getOrderSaga(action) {
  try {
    const response = yield call(getOrderApi, action?.payload);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(getOrderActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* addAddressDetailsSaga(action) {
  try {
    const response = yield call(addAddressDetailsApi, action?.payload);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(addAddressDetailsActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getAddressListSaga(action) {
  try {
    console.log("saga list--", action)
    const response = yield call(getAddressListApi);
    console.log("response list--", response)
    if (response?.status == 200) {
      action?.callback(response);
      yield put(getAddressListActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getAddressDetailsSaga(action) {
  try {
    const response = yield call(getAddressDetailsApi, action?.payload);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(getAddressDetailsActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getLogicoinsSaga(action) {
  try {
    const response = yield call(getLogiCoinsApi);
    if (response?.status == 200) {
      //action?.callback(response);
      yield put(getLogicoinsSuccessAction(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* getInTouchSaga(action) {
  try {
    const response = yield call(getInTouchApi, action?.id, action?.payload);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(getInTouchActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


// function* createPaymentTokenSaga(action) {
//   try {
//     const response = yield call(createPaymentTokenApi, action.payload);
//     console.log("billDetailsSaga", response);
//     if (response?.data?.type == "success") {
//       console.log("billDetailsSaga", response);
//       action?.callback(response);
//       yield put(createPaymentTokenSuccessAction());
//     }else if(response?.message){
//       action?.callback(response?.message);
//     }
//   } catch (error) {
//     action?.callback(error);
//     console.log("createPaymentLinkSaga Error.Response:- ", error);
//     // yield put(
//     //   toastStatus({
//     //     toastStatus: true,
//     //     message: `Error : ${error?.response?.data?.message}`,
//     //     status: false,
//     //   })
//     // );
//     //action.callback(error.response.data);
//     //Alert.alert('Error', error.response.data.message);
//     console.log("API Error:- ", processErrorAndRespond(error));
//   }
// }



function* generateRecieptSaga(action) {
  try {
    const response = yield call(generateRecieptApi, action?.payload);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(generateRecieptActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* getProductDetailSaga(action) {
  try {
    const response = yield call(getProductDetailApi, action?.id);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(getProductDetailActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getProductListingSaga(action) {
  try {
    const response = yield call(getProductListingApi, action?.payload);
    if (response?.status == 200) {
      action?.callBack(response);
      yield put(getProductListingActionSuccess(response?.data));
    }
  } catch (error) {
    action?.callBack(error?.response);
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* freshDeskTicketSaga(action) {
  try {
    const response = yield call(freshDeskTicketApi, action?.payload);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(freshDeskTicketActionSuccess(response?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Thank you for applying for EMI. We will get back to you soon.",
          status: true,
        })
      );
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* ticketWithoutAuthSaga(action) {
  try {
    const response = yield call(ticketWithoutAuthApi, action?.payload);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(ticketWithoutAuthActionSuccess(response?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Thank you for applying for EMI. We will get back to you soon.",
          status: true,
        })
      );
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* orderPaymentSaga(action) {
  try {
    const response = yield call(orderPaymentApi, action?.payload);
    if (response?.status == 200) {
      action?.callback(response);
      yield put(orderPaymentActionSuccess(response?.data));
      toastStatus({
        toastStatus: true,
        message: "Order Placed Successfully !!",
        status: true,
      })
    }
  } catch (error) {
    if (error.response.data.error.status!==undefined)
    {
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Error : ${error.response.data.message}`,
          status: false,
        })
      );
    }
    else{
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Error : ${error.response.data.error}`,
          status: false,
        })
      );
    }
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
export function* ecomSaga() {
  
  yield takeEvery(GET_CART_DETAILS, getCartDetailsSaga);
  yield takeEvery(ADD_TO_CART, addToCartSaga);
  yield takeEvery(REMOVE_TO_CART, removeToCartSaga);
  yield takeEvery(GET_ORDER_LIST, getOrderListSaga);
  yield takeEvery(CREATE_ORDER, createOrderSaga);
  yield takeEvery(GET_ORDER, getOrderSaga);
  yield takeEvery(ADD_ADDRESS_DETAILS, addAddressDetailsSaga);
  yield takeEvery(GET_ADDRESS_LIST, getAddressListSaga);
  yield takeEvery(GET_ADDRESS_DETAILS, getAddressDetailsSaga);
  yield takeEvery(GET_COINS_AVAILABLE, getLogicoinsSaga);
  // yield takeEvery(CREATE_PAYMENT_TOKEN, createPaymentTokenSaga);
  yield takeEvery(SUPPORT_TICKET, getInTouchSaga);
  yield takeEvery(GENERATE_RECEIPT, generateRecieptSaga);
  yield takeEvery(GET_PRODUCT_DETAILS, getProductDetailSaga);
  yield takeEvery(  GET_PRODUCT_LIST,getProductListingSaga );
  yield takeEvery( FRESHDESK_TICKET, freshDeskTicketSaga);
  yield takeEvery( ORDER_PAYMENT, orderPaymentSaga);
  yield takeEvery( FRESHDESK_TICKET_ECOM, ticketWithoutAuthSaga);
}
