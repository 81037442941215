import { call, put, takeEvery } from "redux-saga/effects";
import {
  addCashGivenApi,
  calculateTripTollApi,
  cashTransactionsApi,
  createTripApi,
  deleteTripApi,
  editCashGivenApi,
  endTripApi,
  fastagVehicleTransactionByFleetApi,
  getCityAndStateApi,
  getM2pCardTransactionsApi,
  getTransactionsAndExpenseApi,
  getTripsApi,
  getTripsByFleetApi,
  getWeatherDataApi,
  updateTripApi,
  calculateTripTollInternalApi,
  getTripTransactionApi,
  getTripsByUserId,
  getTripReportApi,
  addDriverV2Api,
  addFreightApi,
  getTripsNewApi,
  editFreightApi,
  getFreightDetailsApi,
  findTripAddressApi,
  addTripAddressApi,
  tripActionBulkApi,
  getStateFromPincodeApi,
  createLRApi,
  editLRApi,
  getLRListApi,
  getPinCodeDataApi,
  markInvoiceAsPaidApi,
  generateLrNoApi,
  findTripAddressV2Api,
  getSavedAddressApi,
  addCashExpanseApi,
  getRoutesApi,
  addRouteApi,
  getTripLedgerApi,
  getVehicleLedgerApi,
  getDriverLedgerApi,
  createTripExpanseApi,
  getEachRouteApi,
  addDriverExpanseApi,
  getVendorLedgerApi,
  editLedgerExpenseAPi,
  signedUrlWithKeyApi,
  getVehicleLastTripAPi,
  editTripExpenseApi,
  getTripExpenseApi,
  startTripExpenseApi,
  updateLrInTripApi,
  bulkFuelTransferUploadApi,
  addRouteBulkExcelUploadApi,
addRouteBulkExcelApi,
addTripBulkExcelUploadApi,
addTripBulkExcelApi
} from "../../Utils/Apis";
import {
  addCashGivenSuccess,
  calculateTripTollSuccess,
  clearTripList,
  createTripSuccess,
  deleteTripSuccess,
  editCashGivenSuccess,
  endTripSuccess,
  fastagVehicleTransactionByFleetSuccess,
  getCashTransactionsSuccess,
  getCityAndStateSuccess,
  getM2pCardTransactionsSuccess,
  getTransactionsAndExpenseSuccess,
  getTripsByFleetSuccess,
  getTripsError,
  getTripsSuccess,
  getWeatherDataSuccess,
  noCardForDriver,
  noFastagForFleet,
  updateTripSuccess,
  calculateTripTollInternalSuccess,
  getTripTransactionSuccess,
  getTripsByUserIdSuccess,
  tripReportSuccess,
  getTripsNewSuccess,
  addFreightSuccess,
  editFreightSuccess,
  getFreightDetailsSuccess,
  addDriverV2Success,
  findTripAddressSuccess,
  addTripAddressSuccess,
  tripBulkActionsSuccess,
  getAddressFromPincodeSuccess,
  createLRSuccess,
  getLRListSuccess,
  editLRSuccess,
  getPinCodeDataSuccess,
  markInvoiceAsPaidSuccess,
  generateLrNoSuccess,
  findTripAddressV2Success,
  getSavedAddressSuccess,
  addCashExpenseSuccess,
  getRouteSuccess,
  addRouteSuccess,
  getTripLedgerSuccess,
  getVehicleLedgerSuccess,
  getDriverLedgerSuccess,
  createTripExpenseSuccess,
  getEachRouteSuccess,
  addDriverExpenseSuccess,
  getVendorLedgerSuccess,
  editLedgerExpenseSuccess,
  signedUrlWithKeySuccess,
  getVehicleLastTripSuccess,
  editTripExpenseSuccess,
  getTripExpenseSuccess,
  startTripExpenseSuccess,
  updateTripInLrSuccess,
  bulkFuelTransferUploadSuccess,
  clearLRList,
  addRouteBulkExcelUploadSuccess,
  addRouteBulkExcelSuccess,
  addTripBulkExcelUploadSuccess,
  addTripBulkExcelSuccess
} from "../Actions/TripsAction";
import {
  ADD_CASH_GIVEN,
  CALCULATE_TRIP_TOLL,
  CALCULATE_TRIP_TOLL_INTERNAL,
  CASH_TRANSACTIONS,
  CREATE_TRIP,
  DELETE_TRIP,
  EDIT_CASH_GIVEN,
  END_TRIP,
  FASTAG_VEHICLE_TRANSACTION_BY_FLEET,
  GET_CITY_AND_STATE,
  GET_M2P_CARD_TRANSACTIONS,
  GET_TRANSACTIONS_AND_EXPENSE,
  GET_TRIPS,
  GET_TRIPS_BY_FLEET,
  GET_TRIPS_BY_USERID,
  GET_TRIP_TRANSACTION,
  GET_WEATHER_DATA,
  UPDATE_TRIP,
  TRIP_REPORT,
  ADD_DRIVERV2,
  ADD_FREIGHT,
  GET_TRIPS_NEW,
  EDIT_FREIGHT,
  GET_FREIGHT_DETAILS,
  FIND_ADDRESS_TRIP,
  ADD_TRIP_ADDRESS,
  TRIP_BULK_ACTIONS,
  GET_ADDRESS_FROM_PINCODE,
  CREATE_LR,
  GET_LR_LIST,
  EDIT_LR,
  GET_PINCODE_DATA,
  MARK_INVOICE_AS_PAID,
  GEN_LR_NO,
  FIND_ADDRESS_TRIP_V2,
  GET_SAVED_ADDRESS,
  ADD_CASH_EXPANSE,
  GET_ROUTES,
  ADD_ROUTE,
  GET_TRIP_LEDGER,
  GET_DRIVER_LEDGER,
  GET_VEHICLE_LEDGER,
  CREATE_TRIP_EXPENSE,
  GET_EACH_ROUTE,
  ADD_DRIVER_EXPENSE,
  GET_VENDOR_LEDGER,
  EDIT_LEDGER_EXPENSE,
  GET_SIGNED_URL_WITH_KEY,
  GET_VEHICLE_LAST_TRIP,
  EDIT_TRIP_EXPENSE,
  GET_TRIP_EXPENSE,
  START_TRIP_EXPENSE,
  UPDATE_LR_IN_TRIP,
  BULK_FUEL_TRANSFER_UPLOAD,
  ADD_ROUTE_BULK_EXCEL_UPLOAD,
ADD_ROUTE_BULK_EXCEL,
ADD_TRIP_BULK_EXCEL_UPLOAD,
ADD_TRIP_BULK_EXCEL,
} from "../storeTypes";
import { processErrorAndRespond } from "../../Utils/functions";
import { toastStatus } from "../Actions/appActions";
function* createTripSaga(action) {
  try {
    console.log("actionadas", action);
    const response = yield call(createTripApi, action.payload);
    console.log("TheDATA", response?.data);
    action.callback(response);

    if (response?.data?.type == "success") {
      yield put(createTripSuccess(response?.data?.data));
      // yield put(
      //     toastStatus({
      //       toastStatus: true,
      //       message: `Trip has been created successfully`,
      //       status: true,
      //     })
      //   );
    }
  } catch (error) {
    console.log("createTripSaga Error.Response:- ", error?.response?.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    if (error?.response?.data?.message && error?.response?.status != "403") {
      console.log("API Error:- ", processErrorAndRespond(error));
    }
  }
}
function* getTripsByUserIdSaga(action) {
  // if(search && search.length > 0){
  //     yield put(clearTripList());
  // }

  try {
    const response = yield call(getTripsByUserId, action.payload);
    if (response?.data?.type == "success") {
      yield put(getTripsByUserIdSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getTripsByUserIdSaga Error.Response:-", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      yield put(getTripsError());
    console.log("API Error:-", processErrorAndRespond(error));
  }
}
function* getTripsSaga(action) {
  const { search, clear } = action.payload;
  // if(search && search.length > 0){
  //     yield put(clearTripList());
  // }

  try {
    const response = yield call(getTripsApi, action.payload);
    console.log("tripsData", response?.data);
    if (response?.data?.type == "success") {
      yield put(getTripsSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getTripsSaga Error.Response:-", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      yield put(getTripsError());
    console.log("API Error:-", processErrorAndRespond(error));
  }
}

function* getRouteSaga(action) {
  const { search, clear } = action.payload;
  // if(search && search.length > 0){
  //     yield put(clearTripList());
  // }

  try {
    const response = yield call(getRoutesApi, action.payload);
    console.log("routeData", response?.data);
    if (response?.data?.type == "success") {
      yield put(getRouteSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getTripsSaga Error.Response:-", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      yield put(getTripsError());
    console.log("API Error:-", processErrorAndRespond(error));
  }
}
function* addRouteSaga(action) {
  try {
    const response = yield call(addRouteApi, action.payload);
    console.log("addRoute", response?.data);
    action.callBack(response);
    if (response?.data?.type == "success") {
      yield put(addRouteSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getTripsSaga Error.Response:-", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      yield put(getTripsError());
    console.log("API Error:-", processErrorAndRespond(error));
  }
}
function* getTripsNewSaga(action) {
  // const {search, clear} = action.payload
  // if(search && search.length > 0){
  yield put(clearTripList());
  // }
  try {
    const response = yield call(getTripsNewApi, action.payload);
    console.log("tripsData", response?.data);
    if (response?.data?.type == "success") {
      yield put(getTripsNewSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getTripsSaga Error.Response:-", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      yield put(getTripsError());
    console.log("API Error:-", processErrorAndRespond(error));
  }
}

function* endTripSaga(action) {
  try {
    console.log("endAction", action);
    const response = yield call(endTripApi, action.payload);
    console.log("YIELD", response?.data);
    action.callback(response);
    if (response?.data?.type == "success") {
      yield put(endTripSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("endTripSaga Error.Response:- ", error);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getTripsByFleetSaga(action) {
  try {
    const response = yield call(getTripsByFleetApi, action.payload);
    if (response?.data?.type == "success") {
      yield put(getTripsByFleetSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getTripsByFleetSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* deleteTripSaga(action) {
  try {
    const response = yield call(deleteTripApi, action.payload);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(deleteTripSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("deleteTripSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* updateTripSaga(action) {
  try {
    const response = yield call(updateTripApi, action.payload);
    action.callback(response);
    if (response?.data?.type == "success") {
      yield put(updateTripSuccess(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Trip has been updated successfully`,
          status: true,
        })
      );
    }
  } catch (error) {
    console.log("updateTripSaga Error.Response:- ", error?.response?.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
  }
}

function* fastagVehicleTransactionByFleetSaga(action) {
  try {
    const response = yield call(
      fastagVehicleTransactionByFleetApi,
      action.payload
    );
    if (response?.data?.type == "success") {
      yield put(fastagVehicleTransactionByFleetSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "fastagVehicleTransactionByFleetSaga Error.Response:- ",
      error?.response?.data
    );
    if (error?.response?.data?.message === "No fastag for this fleet Id") {
      yield put(noFastagForFleet());
    }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getTransactionAndExpenseSaga(action) {
  try {
    const response = yield call(getTransactionsAndExpenseApi, action.payload);
    if (response?.data?.type == "success") {
      yield put(getTransactionsAndExpenseSuccess(response?.data?.data));
    }
  } catch (error) {
    yield put(noCardForDriver());
    console.log("no card for driver-->", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* cashTransactionsSaga(action) {
  try {
    const response = yield call(cashTransactionsApi, action.payload);
    if (response?.data?.type == "success") {
      yield put(getCashTransactionsSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("cashTransactionsSaga Error.Response:-", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* addCashGivenSaga(action) {
  try {
    const response = yield call(addCashGivenApi, action.payload);
    action.callback(response);
    if (response?.data?.type == "success") {
      yield put(addCashGivenSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("addCashGivenSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* editCashGivenSaga(action) {
  try {
    const response = yield call(editCashGivenApi, action.payload);
    action.callback(response?.data);
    if (response?.data?.type == "success") {
      yield put(editCashGivenSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("editCashGivenSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getCityAndStateSaga(action) {
  try {
    const response = yield call(getCityAndStateApi, action.payload);
    console.log("ohhShitHere", action);
    if (response?.data?.type == "success") {
      yield put(getCityAndStateSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getCityAndStateSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* findTripAddressSaga(action) {
  try {
    const response = yield call(findTripAddressApi, action.payload);
    console.log("ohhShitHere", action);
    if (response?.data?.type == "success") {
      yield put(findTripAddressSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getCityAndStateSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* addTripAddressSaga(action) {
  try {
    const response = yield call(addTripAddressApi, action.payload);
    console.log("response here", response);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(addTripAddressSuccess(response?.data?.data));
      // yield put(
      //     toastStatus({
      //       toastStatus: true,
      //       message: `${response?.data?.message}`,
      //       status: true,
      //     }))
    }
  } catch (error) {
    console.log("erere", error?.response);
    action.callback(error?.response);
    // yield put(
    //     toastStatus({
    //       toastStatus: true,
    //       message: `Error : ${error?.response?.data?.message}`,
    //       status: false,
    //     }))
    console.log("getCityAndStateSaga Error.Response:- ", error?.response?.data);
    // if(error?.response?.data?.message  && error?.response?.status != '403')
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}
function* getWeatherData(action) {
  try {
    const response = yield call(getWeatherDataApi, action.payload);
    if (response?.data?.type == "success") {
      yield put(getWeatherDataSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response weatherdata:- ", error?.response?.data);
    console.log("API Error for weatherdata:- ", processErrorAndRespond(error));
  }
}

function* getM2pCardTransactions(action) {
  console.log("M2PPPPPPPP", action);
  try {
    const response = yield call(getM2pCardTransactionsApi, action.payload);
    console.log("m2p response", response?.data);
    if (response?.data?.type == "success") {
      yield put(getM2pCardTransactionsSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "M2P TRANSACTION RES Error.Response :- ",
      error?.response?.data
    );
    console.log("API Error for :- ", processErrorAndRespond(error));
  }
}

function* calculateTripTollSaga(action) {
  try {
    console.log("saga start");
    const response = yield call(calculateTripTollApi, action?.payload);
    console.log("response=>", response?.data);
    if (response?.data?.type == "success") {
      yield put(calculateTripTollSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(("calculateTripTollApi =>", error?.response?.data));
  }
}

function* calculateTripTollInternalSaga(action) {
  try {
    console.log("saga start");
    const response = yield call(calculateTripTollInternalApi, action?.payload);
    console.log("internal toll response=>", response?.data);
    if (response?.data?.type == "success") {
      yield put(calculateTripTollInternalSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(("calculateTripTollInternalApi =>", error?.response?.data));
    console.log(("calculateTripTollInternalApi =>", error?.response?.data));
  }
}

function* getTripTransactionSaga(action) {
  try {
    const response = yield call(getTripTransactionApi, action?.payload);
    if (response?.data?.type == "success") {
      yield put(getTripTransactionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "getTripTransactionSaga Error.Response:- ",
      error?.response?.data
    );
    if (error?.response?.data?.message === "No fastag for this fleet Id") {
      yield put(noFastagForFleet());
    }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* tripReportSaga(action) {
  try {
    const response = yield call(getTripReportApi, action?.payload);
    console.log("reportShit", response?.data);
    action.callback(response);
    if (response?.data?.type == "success") {
      yield put(tripReportSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "getTripTransactionSaga Error.Response:- ",
      error?.response?.data
    );
    if (error?.response?.data?.message === "No fastag for this fleet Id") {
      yield put(noFastagForFleet());
    }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* addDriverV2Saga(action) {
  try {
    const response = yield call(addDriverV2Api, action?.payload);
    console.log("reportShit addDriverV2Saga", response?.data);
    action.callback(response);
    if (response?.data?.type == "success") {
      yield put(addDriverV2Success(response?.data?.data));
    }
  } catch (error) {
    console.log("addDriverV2 Error.Response:- ", error?.response?.data);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* addFreightSaga(action) {
  try {
    console.log("dasdad", action);
    const response = yield call(addFreightApi, action?.payload);
    console.log("reportShit", response?.data);
    action.callback(response);
    if (response?.data?.type == "success") {
      yield put(addFreightSuccess(response?.data));
    }
  } catch (error) {
    // console.log
    action.callback(error?.response);
    console.log("addFreight Error.Response:- ", error?.response);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* editFreightSaga(action) {
  try {
    console.log("dasdad", action);
    const response = yield call(editFreightApi, action?.payload);
    console.log("reportShit", response?.data);
    action.callback(response);
    if (response?.data?.type == "success") {
      yield put(editFreightSuccess(response?.data));
    }
  } catch (error) {
    // console.log
    action.callback(error?.response);
    console.log("addFreight Error.Response:- ", error?.response);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getFreightDetailsSaga(action) {
  try {
    console.log("dasdad", action);
    const response = yield call(getFreightDetailsApi, action?.payload);
    console.log("reportShit fefe", response?.data);
    action.callback(response);
    if (response?.data?.type == "success") {
      yield put(getFreightDetailsSuccess(response?.data));
    }
  } catch (error) {
    // console.log
    action.callback(error?.response);
    console.log("addFreight Error.Response:- ", error?.response);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* tripBulkActionsSaga(action) {
  try {
    console.log("dasdad", action);
    const response = yield call(tripActionBulkApi, action?.payload);
    console.log("reportShit fefe", response?.data);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(tripBulkActionsSuccess(response?.data));
    }
  } catch (error) {
    // console.log
    action.callback(error?.response);
    console.log("addFreight Error.Response:- ", error?.response);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getAddressFromPincodeSaga(action) {
  try {
    console.log("dasdad", action);
    const response = yield call(getStateFromPincodeApi, action?.payload);
    console.log("reportShit fefe", response?.data);
    action?.callBack(response);
    if (response?.data?.type == "success") {
      // action?.callback(response)
      yield put(getAddressFromPincodeSuccess(response?.data?.data));
    }
  } catch (error) {
    // console.log
    action?.callBack(error?.response);
    console.log("addFreight Error.Response:- ", error?.response);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* createLRSaga(action) {
  try {
    console.log("dasdad", action);
    const response = yield call(createLRApi, action?.payload);
    console.log("createLRSaga fefe", response?.data);
    if (response?.data?.type == "success") {
      yield put(
        toastStatus({
          toastStatus: true,
          message: `LR has been created successfully`,
          status: true,
        })
      );
      action?.callback(response);
      yield put(createLRSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("createLRSaga Error.Response:- ", error);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    action?.callback(error?.response);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getLRListSaga(action) {
  yield put(clearLRList());
  try {
    console.log("dasdad", action);
    const response = yield call(getLRListApi, action?.payload);
    console.log("createLRSaga fefe", response?.data);
    if (response?.data?.type == "success") {
      yield put(getLRListSuccess(response?.data?.data));
    }
  } catch (error) {
    // console.log
    console.log("createLRSaga Error.Response:- ", error?.response);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* editLRSaga(action) {
  try {
    console.log("dasdad", action);
    const response = yield call(editLRApi, action?.payload);
    console.log("createLRSaga fefe", response?.data);
    if (response?.data?.type == "success") {
      if (response?.status == 200) {
        yield put(
          toastStatus({
            toastStatus: true,
            message: `LR has been edited successfully`,
            status: true,
          })
        );
      }
      if (response?.status == 201) {
        yield put(
          toastStatus({
            toastStatus: true,
            message: `Can Not Edit This Lr`,
            status: false,
          })
        );
      }
      action?.callback(response);
      yield put(editLRSuccess(response?.data?.data));
    }
  } catch (error) {
    if (error?.response?.status == 201) {
      yield put(
        toastStatus({
          toastStatus: true,
          message: `LR can not be edited after invoice is created`,
          status: false,
        })
      );
    }
    else{
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Error : ${error?.response?.data?.message}`,
          status: false,
        })
      );
    }
    // console.log
    
    action?.callback(error?.response);
    console.log("createLRSaga Error.Response:- ", error?.response,error);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getPinCodeDataSaga(action) {
  try {
    console.log("getPinCodeDataSaga", action);
    const response = yield call(getPinCodeDataApi, action?.payload);
    console.log("getPinCodeDataSaga fefe", response?.data);
    if (response?.data?.type == "success") {
      action?.callback(response);
      yield put(getPinCodeDataSuccess(response?.data?.data));
    }
  } catch (error) {
    // console.log
    action?.callback(error?.response);
    console.log("getPinCodeDataSaga Error.Response:- ", error?.response);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* markInvoiceAsPaidSaga(action) {
  try {
    console.log("dasdad", action);
    const response = yield call(markInvoiceAsPaidApi, action?.payload);
    console.log("createLRSaga fefe", response?.data);
    if (response?.data?.type == "success") {
      action?.callback(response);
      yield put(markInvoiceAsPaidSuccess(response?.data?.data));
    }
  } catch (error) {
    // console.log
    action?.callback(error?.response);
    console.log("markInvoiceAsPaid Error.Response:- ", error?.response);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* generateLrNoSaga(action) {
  try {
    console.log("generateLrNoSaga", action);
    const response = yield call(generateLrNoApi);
    console.log("generateLrNoSaga fefe", response?.data);
    if (response?.data?.type == "success") {
      action?.callback(response);
      yield put(generateLrNoSuccess(response?.data));
    }
  } catch (error) {
    // console.log
    action?.callback(error?.response);
    console.log("generateLrNoSaga Error.Response:- ", error?.response);
    // if(error?.response?.data?.message === "No fastag for this fleet Id"){
    //     yield put(noFastagForFleet());
    // }
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* findTripAddressSagaV2(action) {
  try {
    const response = yield call(findTripAddressV2Api, action.payload);
    console.log("findTripAddressSagaV2", action);
    if (response?.data?.type == "success") {
      yield put(findTripAddressV2Success(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "findTripAddressSagaV2 Error.Response:- ",
      error?.response?.data
    );
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getSavedAddressSaga(action) {
  try {
    const response = yield call(getSavedAddressApi, action.payload);
    console.log("getSavedAddressSaga", action);
    if (response?.data?.type == "success") {
      yield put(getSavedAddressSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getSavedAddressSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getDriverLedgerSaga(action) {
  try {
    const response = yield call(
      getDriverLedgerApi,
      action.userId,
      action.payload
    );
    console.log("getDriverLedgerSaga", action);
    if (response?.data?.type == "success") {
      yield put(getDriverLedgerSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getDriverLedgerSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getVehicleLedgerSaga(action) {
  try {
    const response = yield call(
      getVehicleLedgerApi,
      action.fleetId,
      action.payload
    );
    console.log("getVehicleLedgerSaga", action);
    if (response?.data?.type == "success") {
      yield put(getVehicleLedgerSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "getVehicleLedgerSaga Error.Response:- ",
      error?.response?.data
    );
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getTripLedgerSaga(action) {
  try {
    const response = yield call(
      getTripLedgerApi,
      action.tripId,
      action.payload
    );
    console.log("getTripLedgerSaga", action);
    if (response?.data?.type == "success") {
      yield put(getTripLedgerSuccess(response?.data?.data, action.payload));
    }
  } catch (error) {
    console.log("getTripLedgerSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* createTripExpanseSaga(action) {
  try {
    const response = yield call(createTripExpanseApi, action.payload);
    action?.callBack(response);
    if (response?.data?.type == "success") {
      yield put(createTripExpenseSuccess(response?.data?.data));
    }
  } catch (error) {
    action?.callBack(error?.response);
    console.log(
      "createTripExpanseSaga Error.Response:- ",
      error?.response?.data
    );
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* addDriverExpenseSaga(action) {
  try {
    const response = yield call(addDriverExpanseApi, action.payload);
    action?.callBack(response);
    console.log("addDriverExpenseSaga", response);
    if (response?.data?.type == "success") {
      yield put(addDriverExpenseSuccess(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Transfer successfully`,
          status: true,
        })
      );
    }
  } catch (error) {
    console.log(
      "addDriverExpenseSaga Error.Response:- ",
      error?.response?.data
    );
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getVendorLedgerSaga(action) {
  try {
    const response = yield call(
      getVendorLedgerApi,
      action.vendorId,
      action.payload
    );
    // action?.callBack(response);
    if (response?.data?.type == "success") {
      yield put(getVendorLedgerSuccess(response?.data?.data));
    }
  } catch (error) {
    // action?.callBack(error?.response);
    console.log("getVendorLedgerSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getEachRouteSaga(action) {
  try {
    const response = yield call(getEachRouteApi, action.payload);
    if (response?.data?.type == "success") {
      yield put(getEachRouteSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getEachRouteSaga Error.Response:- ", error?.response?.data);
    if (error?.response?.data?.message && error?.response?.status != "403")
      console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* editLedgerExpenseSaga(action) {
  try {
    const response = yield call(editLedgerExpenseAPi, action.payload);
    action?.callBack(response);
    if (response?.data?.type == "success") {
      yield put(editLedgerExpenseSuccess(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: `edit successfully`,
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getVehicleLastTripSaga(action) {
  try {
    const response = yield call(getVehicleLastTripAPi, action.payload);
    action?.callBack(response);
    if (response?.data?.type == "success") {
      yield put(getVehicleLastTripSuccess(response?.data?.data));
    }
  } catch (error) {
    action?.callBack(error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* editTripExpenseSaga(action) {
  try {
    const response = yield call(editTripExpenseApi, action.payload);
    action?.callBack(response);
    if (response?.data?.type == "success") {
      yield put(editTripExpenseSuccess(response?.data?.data));
    }
  } catch (error) {
    action?.callBack(error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getTripExpenseSaga(action) {
  try {
    const response = yield call(getTripExpenseApi, action?.payload);
    action?.callBack(response);
    if (response?.data?.type == "success") {
      yield put(getTripExpenseSuccess(response?.data?.data));
    }
  } catch (error) {
    action?.callBack(error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* startTripExpenseSaga(action) {
  try {
    const response = yield call(startTripExpenseApi, action?.payload);
    action?.callBack(response);
    if (response?.data?.type == "success") {
      yield put(startTripExpenseSuccess(response?.data?.data));
    }
  } catch (error) {
    action?.callBack(error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
///////
function* signedUrlWithKeySaga(action) {
  try {
    console.log("upcoming", action.payload);
    const response = yield call(signedUrlWithKeyApi, action.payload);
    console.log("resss", response);
    if (response?.data?.type == "success") {
      console.log("data from rc", response.data);
      yield put(signedUrlWithKeySuccess());
      action.callBack(response.data);
      //ToastAndroid.show('Your media has been uploaded', ToastAndroid.SHORT);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.SHORT);
    console.log("Error :- ", error?.response?.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* updateLrInTripSaga(action) {
  try {
    const response = yield call(updateLrInTripApi, action.payload);
    action.callBack(response);
    if (response?.data?.type == "success") {
      yield put(updateTripInLrSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
    console.log("Error :- ", error?.response?.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* bulkFuelTransferUploadSaga(action) {
  try {
    console.log("saga aaya");
    const response = yield call(bulkFuelTransferUploadApi, action.payload);
    // if (response.type == "success") {
    action.callback(response);
    yield put(bulkFuelTransferUploadSuccess(response));
    // }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    action.callback(error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* addRouteBulkExcelUploadSaga(action) {
  try {
    console.log("saga aaya");
    const response = yield call(addRouteBulkExcelUploadApi, action.payload);
    // if (response.type == "success") {
    action.callback(response);
    yield put(addRouteBulkExcelUploadSuccess(response));
    // }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    action.callback(error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* addRouteBulkExcelSaga(action) {
  try {
    console.log("saga aaya");
    const response = yield call(addRouteBulkExcelApi, action.payload);
    // if (response.type == "success") {
    action.callback(response);
    yield put(addRouteBulkExcelSuccess(response));
    // }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    action.callback(error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* addTripBulkExcelUploadSaga(action) {
  try {
    console.log("saga aaya");
    const response = yield call(addTripBulkExcelUploadApi, action.payload);
    // if (response.type == "success") {
    action.callback(response);
    yield put(addTripBulkExcelUploadSuccess(response));
    // }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    action.callback(error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* addTripBulkExcelSaga(action) {
  try {
    console.log("saga aaya");
    const response = yield call(addTripBulkExcelApi, action.payload);
    // if (response.type == "success") {
    action.callback(response);
    yield put(addTripBulkExcelSuccess(response));
    // }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    action.callback(error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
//////

export function* watchTripSagas() {
  yield takeEvery(ADD_FREIGHT, addFreightSaga);
  yield takeEvery(EDIT_FREIGHT, editFreightSaga);
  yield takeEvery(GET_FREIGHT_DETAILS, getFreightDetailsSaga);
  yield takeEvery(FIND_ADDRESS_TRIP, findTripAddressSaga);
  yield takeEvery(ADD_TRIP_ADDRESS, addTripAddressSaga);
  yield takeEvery(ADD_DRIVERV2, addDriverV2Saga);
  yield takeEvery(GET_TRIPS, getTripsSaga);
  yield takeEvery(GET_ROUTES, getRouteSaga);
  yield takeEvery(ADD_ROUTE, addRouteSaga);
  yield takeEvery(GET_TRIPS_NEW, getTripsNewSaga);
  yield takeEvery(CREATE_TRIP, createTripSaga);
  yield takeEvery(END_TRIP, endTripSaga);
  yield takeEvery(GET_TRIPS_BY_FLEET, getTripsByFleetSaga);
  yield takeEvery(DELETE_TRIP, deleteTripSaga);
  yield takeEvery(UPDATE_TRIP, updateTripSaga);
  yield takeEvery(
    FASTAG_VEHICLE_TRANSACTION_BY_FLEET,
    fastagVehicleTransactionByFleetSaga
  );
  yield takeEvery(GET_TRANSACTIONS_AND_EXPENSE, getTransactionAndExpenseSaga);
  yield takeEvery(ADD_CASH_GIVEN, addCashGivenSaga);
  yield takeEvery(EDIT_CASH_GIVEN, editCashGivenSaga);
  yield takeEvery(CASH_TRANSACTIONS, cashTransactionsSaga);
  yield takeEvery(GET_CITY_AND_STATE, getCityAndStateSaga);
  yield takeEvery(GET_WEATHER_DATA, getWeatherData);
  yield takeEvery(GET_M2P_CARD_TRANSACTIONS, getM2pCardTransactions);
  yield takeEvery(CALCULATE_TRIP_TOLL, calculateTripTollSaga);
  yield takeEvery(CALCULATE_TRIP_TOLL_INTERNAL, calculateTripTollInternalSaga);
  yield takeEvery(GET_TRIP_TRANSACTION, getTripTransactionSaga);
  yield takeEvery(GET_TRIPS_BY_USERID, getTripsByUserIdSaga);
  yield takeEvery(TRIP_REPORT, tripReportSaga);
  yield takeEvery(TRIP_BULK_ACTIONS, tripBulkActionsSaga);
  yield takeEvery(GET_ADDRESS_FROM_PINCODE, getAddressFromPincodeSaga);
  yield takeEvery(CREATE_LR, createLRSaga);
  yield takeEvery(GET_LR_LIST, getLRListSaga);
  yield takeEvery(EDIT_LR, editLRSaga);
  yield takeEvery(GET_PINCODE_DATA, getPinCodeDataSaga);
  yield takeEvery(GEN_LR_NO, generateLrNoSaga);
  yield takeEvery(FIND_ADDRESS_TRIP_V2, findTripAddressSagaV2);
  yield takeEvery(GET_SAVED_ADDRESS, getSavedAddressSaga);
  yield takeEvery(MARK_INVOICE_AS_PAID, markInvoiceAsPaidSaga);
  yield takeEvery(GET_EACH_ROUTE, getEachRouteSaga);
  yield takeEvery(GET_TRIP_LEDGER, getTripLedgerSaga);
  yield takeEvery(GET_DRIVER_LEDGER, getDriverLedgerSaga);
  yield takeEvery(GET_VEHICLE_LEDGER, getVehicleLedgerSaga);
  yield takeEvery(CREATE_TRIP_EXPENSE, createTripExpanseSaga);
  yield takeEvery(ADD_DRIVER_EXPENSE, addDriverExpenseSaga);
  yield takeEvery(GET_VENDOR_LEDGER, getVendorLedgerSaga);
  yield takeEvery(EDIT_LEDGER_EXPENSE, editLedgerExpenseSaga);
  yield takeEvery(GET_VEHICLE_LAST_TRIP, getVehicleLastTripSaga);
  yield takeEvery(EDIT_TRIP_EXPENSE, editTripExpenseSaga);
  yield takeEvery(GET_TRIP_EXPENSE, getTripExpenseSaga);
  yield takeEvery(START_TRIP_EXPENSE, startTripExpenseSaga);
  yield takeEvery(GET_SIGNED_URL_WITH_KEY, signedUrlWithKeySaga);
  yield takeEvery(UPDATE_LR_IN_TRIP, updateLrInTripSaga);
  yield takeEvery(BULK_FUEL_TRANSFER_UPLOAD, bulkFuelTransferUploadSaga);
  yield takeEvery(ADD_ROUTE_BULK_EXCEL_UPLOAD, addRouteBulkExcelUploadSaga);
  yield takeEvery(ADD_ROUTE_BULK_EXCEL, addRouteBulkExcelSaga);
  yield takeEvery(ADD_TRIP_BULK_EXCEL_UPLOAD, addTripBulkExcelUploadSaga);
  yield takeEvery(ADD_TRIP_BULK_EXCEL, addTripBulkExcelSaga);

}
