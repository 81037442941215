import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import {
  getCartDetailsAction,
  removeToCartAction,
} from "../../../../../services/Store/Actions/ecomAction";
import SvgImage from "../../../../Icons/Icons";
import "./index.css";

function AddToCartModal(props) {
  const { open, onHide, toggleModal, handleClose } = props;
  const dispatch = useDispatch();
  

  return (
    <Modal
      isOpen={open}
      onHide={onHide}
      toggle={toggleModal}
      className="RemoveDiv1 settingModalMargin"
      //   toggle={toggle}
      aria-labelledby="myModalLabel2"
    >
      <div
        className="closeRemove"
        onClick={() => {
          // Router.reload();
          handleClose();
        }}
      >
        <SvgImage name="CloseButtonLogo" />
      </div>
      <div className="padding32" style={{textAlign:"center"}}>
        {/* <div className=" centreClass">
                    <img src="/images/ecom/failedCross.svg" className="" />
                    </div> */}

        <div>
          <SvgImage name="successTick" />
        </div>
        <div className=" Medium heading2 padding16T">1 item added in your cart</div>
        {/* <div className="padding32T ">
          Complete your payment in the new tab and then return here.
        </div>
        <div className=" greyFont padding16B">
          We are waiting for your payment. If the new tab doesn't open, please
          turn-off Pop-up blocker on your browser and try again.
        </div> */}
      </div>
    </Modal>
  );
}

export default AddToCartModal;
