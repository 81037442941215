import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import {
  getCartDetailsAction,
  getInTouchAction,
  removeToCartAction,
  ticketWithoutAuthAction,
} from "../../../../../services/Store/Actions/ecomAction";
import SvgImage from "../../../../Icons/Icons";
import "./index.css";
import { Field, Formik } from "formik";
import { Form, FormGroup, Table } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { storeMedia } from "../../../../../services/Store/Actions/appActions";
import { ClipLoader } from "react-spinners";

function ContactUs(props) {
  const { open, onHide, toggleModal, handleClose, prodId, prodName } = props;
  const dispatch = useDispatch();
  const [imageLoader,setImageLoader] = useState(false)
  const { loader } = useSelector((state) => state?.ecom);
  const [status, setStatus] = useState(false);
  const [displayName, setDisplayName] = useState([]);

  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.userId;

  console.log("iiiiiiii", userIdSession);

  const validationSchema = Yup.object().shape({
    item: Yup.string().required("Please enter the name of product"),
    address: Yup.string()
      .required("Please enter the delivery address"),
    price: Yup.number().typeError("Only Numbers are allowed").min(1, "Amount should be greater than 1"),
    prodUrl: Yup.string().required("Please upload image of product"),
      })


    const customId = "custom-id-raise-ticket page";
    const notify = () => toast.success("Ticket has been raised successfully for enquiry.",{
    toastId:customId
    });
  return (
    <Modal
      isOpen={open}
      onHide={onHide}
      toggle={toggleModal}
      className="RemoveDiv2 settingModalMargin"
      //   toggle={toggle}
      aria-labelledby="myModalLabel2"
      style={{height:userIdSession?"43rem":"35rem"}}
    >
        <p className="Add-balance-title" style={{textAlign:"center"}}>Contact Us</p>
      <div
        className="closeRemove"
        onClick={() => {
          // Router.reload();
          handleClose();
        }}
        style={{zIndex:"1000",cursor:"pointer"}}
      >
        <SvgImage name="CloseButtonLogo" />
      </div>
      <div className="padding16 padding0T" >
      <ModalBody className="padding8 padding0L ">
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                item: prodName,
                address: "",
                price: "",
                prodUrl:"",
              }}
              onSubmit={(values, actions) => {
                console.log("values onSubmit", values);
                const obj={
                    productId:prodId,
                    expectedPrice: values.price,
                    address: values.address,
                    item:values.item,
                    prodUrl:values.prodUrl
                }
                const payload = {
                  "title": "Query for product on webportal",
                  "phone": "NA",
                  "description": JSON.stringify(obj)
                }
                if(userIdSession){
                  dispatch(getInTouchAction("enquiry ",obj,(res)=>{
                    if (res?.status == 200) {
                        notify();
                        toggleModal();
                    }
                  }))
                }else{
                  dispatch(ticketWithoutAuthAction(payload, (res)=>{
                    console.log("emi resp", res)
                        if (res?.status == 200) {
                          notify();
                          toggleModal();
                        }
                    }))

                }
                
                
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
                setFieldError,
              }) => (
                <Form onSubmit={handleSubmit} className="padding0T padding24">
                  <FormGroup>
                    <label
                      htmlFor="fullName"
                      className="semiBold heading3 blockDiv padding16B padding8L"
                    >
                      Item Name
                    </label>
                    <input
                      type="text"
                      id="item"
                      className={`input-body-new`}
                      name="item"
                      onChange={handleChange("item")}
                      value={values.item}
                      onBlur={handleBlur("item")}
                      touched={touched.item}
                      errors={errors.item}
                      placeholder="Enter the name of product"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.item && touched.item ? (
                        <div>*{errors.item}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="companyName"
                      className="semiBold heading3 blockDiv padding16B padding8L padding8T"
                    >
                      Delivery Location
                    </label>
                    <input
                      type="text"
                      id="address"
                      className={`input-body-new`}
                      name="address"
                      onChange={handleChange("address")}
                      value={values.address}
                      onBlur={handleBlur("address")}
                      touched={touched.address}
                      errors={errors.address}
                      placeholder="Enter the delivery address"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.address && touched.address ? (
                        <div>*{errors.address}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="pan"
                      className="semiBold heading3 blockDiv padding16B padding8L padding8T"
                    >
                      Expected Price (Optional)
                    </label>
                    <input
                      type="text"
                      id="price"
                      className={`input-body-new`}
                      name="price"
                      onChange={handleChange("price")}
                      value={values.price}
                      onBlur={handleBlur("price")}
                      touched={touched.price}
                      errors={errors.price}
                      placeholder="Enter the expected price"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.price && touched.price ? (
                        <div>*{errors.price}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  {
                    (userIdSession)?
                  
                  <FormGroup>
                    <label
                      htmlFor="ProdUrl"
                      className="semiBold heading3 blockDiv padding16B padding8L padding8T"
                    >
                     Upload Product Image
                    </label>
                    <div className="d-flex">
                    <div
                        className="add-attach-for-fastag-linked-vehicle d-flex flex-wrap"
                        // type="submit"
                        style={{top:"0", paddingTop:"0"}}
                        // disabled={Formik.setSubmitting}
                      >
                        <div className="padding16T padding16B padding32L padding32R uploadContain" style={{borderColor:status?"#2E7C7B":"#C4C4C4", backgroundColor:status?"rgba(130, 176, 176, 0.1)":"#FFFFFF"}}>
                          <SvgImage name="uploadImg" color={status?"#2E7C7B":"#C4C4C4"} />
                          <div className="Medium heading3" style={{color:status?"#2E7C7B":"#C4C4C4"}}>Image</div>
                        </div>
                        
                        {
                          imageLoader ? 
                          <div style={{zIndex:10,position:"absolute", right:"0", top:"1.8rem"}}>
                            <ClipLoader
                              color={"#485B7C"}
                              loading={true}
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </div>
                         
                          : 
                          <div>
                            <div style={{position:"absolute", right:"-0.7rem", top:"1rem"}}>
                              <SvgImage name="AddAttachment" height="36" width="36" />
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              // multiple
                              // accept="image/*"
                              className="add-attachment-btn-3"
                              onChange={(e) => {
                                const img = e?.target.files[0];
                                console.log("image", img?.name);
                                // e.preventDefault();
                                

                                // e.preventDefault();
                                

                                const tempArray = Object.assign([], values.docs);
                                console.log("sel", tempArray);

                                if (img == null) {
                                  //clear image
                                  setFieldValue("prodUrl", "", true);
                                  //for now  only one supported
                                } else {
                                  console.log("upload", {
                                    img: img,
                                    uploadType: "aadharFront",
                                    isRes: true,
                                  });
                                  // e.preventDefault();
                                  // e.setSubmitting(false);
                                  // e.preventDefault();
                                  setImageLoader(true)
                                  dispatch(
                                    storeMedia(
                                      {
                                        img: img,
                                        uploadType: "aadharFront",
                                        isRes: false,
                                      },
                                      (res) => {
                                        setImageLoader(false)
                                        if (res?.url) {
                                          console.log("opt", res);
                                          tempArray.push(res?.data?.Location);
                                          console.log("opt1", tempArray);
                                          //setFieldValue("urlBack", res?.url, true);
                                          setFieldValue(
                                            "prodUrl",
                                            res?.data?.Location,
                                            true
                                          );
                                          setDisplayName(
                                            (prev) => [{ name: img?.name }]
                                            //  [
                                            //   ...prev,
                                            //   { name: img?.name },
                                            // ]
                                          );
                                          setStatus(true);
                                          // setFieldValue('docs', tempArray, true);
                                          //for now  only one supported
                                          //setFieldValue("docs", tempArray, true);
                                        }
                                      }
                                    )
                                  );
                                  

                                  // setFieldValue("amount", values.amount, true);
                                }
                              }}
                            />
                          </div>
                        }
                        

                        <div
                          style={{
                            margin: "7px 0 0px 5px",
                            color: "red",
                          }}
                        >
                          {errors.prodUrl && touched.prodUrl ? (
                            <div>*{errors.prodUrl}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                      
                    
                    {status?
                    
                        displayName?.map((d) => {
                            return (
                              <div className="row">
                                <div
                                  className="col-10 heading4 Regular"
                                //   style={{ paddingTop: "0.3rem" }}
                                >
                                  <p
                                    style={{
                                      margin: "0 0 0 0.5rem",
                                      color: "black",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={(e) => {
                                    setStatus(false);
                                    setFieldValue(
                                      "prodUrl",
                                      "",
                                      true
                                    );
                                    // e.preventDefault();
                                  }}
                                  style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage name="CloseButtonLogo" />
                                </div>
                              </div>
                            );
                          })
                          :<></>
                    }
                    {/* <div className="padding16B padding8L padding16T">
                      ( Upload format must be in jpg/png/ jpeg )
                    </div> */}
                  </FormGroup>
                  :<></>
                  }
                  <FormGroup className="" style={{paddingTop:userIdSession?"1rem":"5rem"}}>
                    <button
                      type="submit"
                      className="quoteButtonDark"
                      disabled={loader ? true : false}
                      style={{
                        background:
                           values.address == ""
                            ? "#EFEFEF"
                            : values.item == ""
                            ? "#EFEFEF"
                            : loader
                            ? "#EFEFEF"
                            : "#485B7C",
                        color:
                           values.address == ""
                            ? "#B5B5B5"
                            : values.item == ""
                            ? "#B5B5B5"
                            : loader
                            ? "#B5B5B5"
                            : "#ffffff",
                      }}
                    >
                      {loader ? "Please Wait" : "Contact Us"}
                    </button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ModalBody>

        
        
      </div>
    </Modal>
  );
}

export default ContactUs;
