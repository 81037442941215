export const URLS = {
  getPendingDriversRequestsList: "balance/balanceRequestList",
  addCustomer: "recievables/customer/add",
  getCustomers: "recievables/get/customers",
  logipeAccount: "recievables/logipe-account",
  driverBal: "expense/balance",
  expenseSumm: "expense/summary",
  GET_CUSTOMERS_BY_ID: "recievables/customer-account/",
  getCustomerRecievables: "recievables",
  getDrivers: "driver/getDriversList",
  // getDrivers: "advance/get/drivers",
  billDetails: "recievables/getBill/",
  createPaymentLink: "payments/createPaymentLink",
  virtualAccountCredit: "payments/vaCredit",
  updateBillDetails: "recievables/edit/bill",
  addBookingBill: "recievables/bill/add",
  getFleet: "fleet/get/fleets",
  getFleetByFleetId: "fleet/get/fleet",
  addMoney: "wallet/addMoney",
  withdrawMoneyFromWallet: "card/transaction/withdraw",
  getCardDetails: "wallet/getCardDetails",
  expenseWalletSummary: "expense/wallet/summary",
  addExpenseToWallet: "expense/add",
  getMasterWalletBalance: "MasterWallet/balance",
  transactionHistory: "MasterWallet/transactionHistory",
  FETCH_BALANCE: "transfer/fetchBalance/",
  getWalletTransactionHistory: "transfer/statement",
  GET_FASTAG_WALLET_BALANCE: "fastag/walletBalance",
  GET_FASTAG_WALLET_TRANSACTION: "fastag/fastagWalletTransaction",
  transferCollections: "transfer/collections",
  getCardControl: "wallet/getCardControl",
  editCardControl: "wallet/editCardControl",
  getExpenseDetails: "expense/balance/",
  //  transactionHistory:'MasterWallet/transactionHistory',
  getWalletSummary: "expense/wallet/summary/",

  walletAddMoney: "wallet/addMoney/",
  walletWithdrawMoney: "wallet/withdrawMoney/",
  changeCardStatus: "wallet/changeCardStatus/",
  //  billDetails: 'recievables/getBill/',
  // createPaymentLink: 'payments/createPaymentLink',
  // updateBillDetails : 'recievables/edit/bill',

  vehicleHistory: "fleet/get/fleet",
  rechargeFastag: "fastag/rechargeFastag",
  addVehicle: "fastag/addVehicle",
  getBalanceDetail: "expense/balance/",
  //  billDetails: 'recievables/getBill/',
  // createPaymentLink: 'payments/createPaymentLink',
  // updateBillDetails : 'recievables/edit/bill',
  //Login Page
  sendOtp: "login/send/otp",
  verifyOtp: "login/verify/otp",
  loginWithPassword: "login/loginWithPassword",
  ownerAccountDetails: "owner/account/details",
  getProfile: "owner/editProfile",
  logIn: "login/verifyPin",
  linkFleet: "fleet/link/fleet",

  // Card Wallet
  transferWalletTransferAmount: "transfer/wallet/Transfer/",
  cardRequests: "request/getRequest/",
  updateCardRequests: "request/editRequest/",

  //CashBAck
  FETCH_CASH_BACK_BALANCE: "cashback/balance",
  getownerCardDetails: "expense/wallet/getDetail",

  //beneficiary
  GET_VIRTU_ACCOUNT: "onboarding/get/virtuAccount",
  BANK_DIRECT_TRANSFER: "transfer/requestDirectTransfer",
  BANK_TRANSFER_BENEFICIARY_ADD: "beneficiary/add/beneficiary",
  getBeneficiary: "beneficiary/get/beneficiary",
  BANK_TRANSFER_TO_ADDED_BENEFICIARY: "transfer/requestTransfer",

  // APP APIS
  getState: "utility/states",
  getCity: "utility/city/",
  STORE_MEDIA_FILE: "media/upload",

  // fleets
  ADD_FLEET: "fleet/add/fleet",

  //fastag
  getFastageVehicleTransactionHistory: "fastag/fastagVehicleTransaction/",
  transferFastagTransferAmount: "transfer/fastag/Transfer",
  GET_VEHICLE_DETAILS_BY_FLEET_ID: "fastag/getVehicleDetails/",
  getVehicleDetails: "fastag/getTagBalance/",
  autoRechargeToggle: "autorecharge/toggle",

  //linking bank account to LogiPe Account
  linkBankToLogipeAccount: "onboarding/link/BankAccount",

  // transfer to fastag wallet

  allCardBalance: "wallet/allCardsBalance",
  getCollections: "transfer/getCollections",
  getSettlements: "transfer/settelments",
  getCashbackList: "cashback/list",
  transferToCard: "account/transaction/card/transfer",
  withdrawFromCard: "wallet/withdrawMoneyToMasterAccount",
  setPin: "wallet/setCardPin",

  // driver card transactions

  getRecentCardTransaction: "wallet/getRecentCardTransaction",

  redeemCashback: "redeem/redeemAmount",

  generateMasterAccountStatement: "report/generateStatement",
  generateFastagWalletStatement: "report/generateStatement",
  generateFastagVehicleStatement: "report/GeneratefastagTxStatements",
  generateDriverListStatement: "report/allDrivers/",

  // new Urls for the card session

  getCardBal: "wallet/walletBalance",
  newCardControl: "card/getCardControl",
  newEditCardControl: "card/editCardControl",

  // get drivers kyc done
  kycPending: "wallet/pendingKyc",

  //download expense
  getExpenseImage: "expense/getTransaction",
  isReissueAvailable: "fastag/isReissueAvailable",
  sendFastagOTP: "fastag/getOtp",
  verifyFastagOTP: "fastag/verifyOtp",
  fastagAddCustomer: "fastag/addCustomer",
  vehicleTagReissue: "fastag/vehicleTagReissue",

  // m2p apis
  userDetails: "user/userDetails",
  ownerCard: "owner/ownerCard",
  cardDetails: "card/getCardDetails",
  withdrawFromM2pCard: "transaction/refundMoney",
  m2pCardControls: "card/getCardControl",
  m2pEditControl: "card/editCardControl",
  m2pSetPin: "transaction/set-pin",
  m2pTransactionHistory: "user/getTransactionHistory/",
  m2pOTP: "onboarding/kyc/customer/generate/otp",
  m2pContactInfo: "onboarding/kyc/customer/contactInfo",
  m2pRegister: "onboarding/kyc/customer/register",
  m2pVKYC: "videoKYC/GenerateVCIPLink",
  m2pOwnerAddress: "onboarding/kyc/customer/getOwnerAddress",

  //receivable url
  updatePayment: "recievables/cash/payment/add",

  //fastag
  registerYesBank: "fastag/registerFleetOwner",

  //update profile
  updateProfile: "owner/updateProfile",

  //expense
  deleteExpense: "expense/delete/",
  editExpense: "expense/edit/",

  //pre-prod api for user management
  managerList: "manager/managerList",

  //auto-fill city and state
  autoFillCityState: "postal/getPostalDetails",

  //for monthly spending
  m2pMonthlyBalance: "masterWallet/balance",

  // m2p download history
  m2pWalletHistoryDownload: "report/generateStatement/",

  // list of accounts linked for master account neft
  neftAccountList: "onboarding/get/BankAccount",

  //UPI, net-banking, etc transfer
  createPaymentToken: "payments/createPaymentToken",

  //url for driverlist with vrn
  getDriversListWithVrn: "driver/getDriversListWithVrn",

  //qr code
  qrcode: "onboarding/get/qrcode",

  //test for notifications
  addDeviceId: "notification/web/addDeviceId",
  getDeviceIdData: "notification/web/getNotiSetting",
  deleteDeviceId: "notification/web/logout/",

  //onboarding
  ownerDetails: "owner/registration/ownerDetails",
  businessDetails: "owner/registration/businessDetails",
  updatePanAadhar: "owner/registration/updatePanAadhar",
  getAadharOtp: "karza/getAadharOtp/",
  panAuth: "karza/panAuth/",
  downloadAadharXML: "karza/downloadAadharXML",
  setPinReg: "owner/registor/setPin",
  refreshToken: "login/refresh/token",

  //ecom
  getCartDetails: "cart/getCartDetails",
  addToCart: "cart/addToCart",
  removeToCart: "cart/removeToCart/",
  getOrderList: "order/getOrderList",
  createOrder: "order/createOrder",
  getOrder: "order/getOrder/",
  addAddressDetails: "address/addAddressDetails",
  getAddressList: "address/getAddressDetails",
  getAddressDetails: "address/getAddressDetails/",
  getProductDetail: "product/getProductDetail/",
  getLogicoins: "coins/getCoinBalance",
  getInTouch: "order/getOrderTouch/",
  generateReciept: "reciept/recieptPdf",
  getProductListing: "product/getProductListing",
  freshDeskTicket: "tickets/generateFreshdeskTicket2",
  ticketWithoutAuth: "tickets/ticketWithoutAuth",
  orderPayment: "order/orderPayment",
  uploadSalary: "wallet/createSalaryWalletEXL",
  downloadSalary: "wallet/getSampleSalaryWallet",
  managerGetControl: "manager/getControl",
  CHANGE_UPI_MANAGER: "manager/editLimit",
  GET_BRANCH:'branch/getBranch',
  ADD_BRANCH:'branch/addBranch',
  LINK_BRANCH_MANAGER:'branch/linkManager',
  LINK_BRANCH_FLEET:'branch/linkFleet',
  LINK_BRANCH_DRIVER:'branch/linkDriver',
  GET_BRANCH_DRIVER:'branch/getDriver',
  GET_BRANCH_FLEET:'branch/getVehicle',
  GET_BRANCH_MANAGER:'branch/getManager',
  GET_BRANCH_ID:'branch/getBranchbyId',
  SET_BRANCH_LIMIT:'branch/setLimit',

  addNickname: "driver/addNickname",
  resetPassword: "login/setPassword",

  filterMAReport: "report/generateStatement", //to be changed
  filterWalletReport: "report/allDrivers", //to be changed
  filterFastagReport: "report/GeneratefastagTxStatementsFilter/",
  filterExpenseReport: "report/generateExpenseStatementFilter/",
  managerReport: "manager/report",
  getOmcVehiclesList: "omcVehicle/getOmcVehiclesList",
  omcVehicleBalance: "omcVehicle/omcWalletBalance/",
  omcVehicleTransaction: "omcVehicle/omcVehicleTransaction/",
  omcLoadMoney: "transfer/transferToOmcWallet",
  omcWithdrawMoney: "omcVehicle/withdrawMoney",
  omcGetVehicleStatus: "omcVehicle/getVehicleStatus/",
  omcDriverData: "driver/",
  omcChangeNum: "omcVehicle/mapVehicleForTxn",
  //Trips
  createTrip: "trip/createtrip",
  getTrips: "trip/getTrips",
  getRoutes: "trip/getAllRoute",
  getEachRoutes: "trip/eachRoute",
  addRoutes: "trip/saveRoute",
  getTripsNew: "trip/getTripsNew",
  getTripsByFleet: "trip/getTripsByFleet/",
  endTrip: "trip/endTrip/",
  deleteTrip: "trip/delteTrip/",
  updateTrip: "trip/updateTripNew",
  // fastagVehicleTransactionByFleet: 'fastag/fastagVehicleTransaction/',
  getTransactionsAndExpense: "trip/getTransactionsAndExpense/",
  cashTransactions: "cashTransactions/get/transactions",
  addCashGiven: "trip/addCashGiven/",
  editCashGiven: "trip/editCashGiven/",
  getCityAndState: "utility/getCityAndState",
  getWeatherData: "tripForecast/getWeatherData",
  getEstimatedFuel: "tripForecast/getEstimatedFuel/",
  getTollBetweenSourceAndDestination:
    "tripForecast/getTollBetweenSourceAndDestination",
  getTripTransaction: "trip/getTripTransaction/",
  MAP_GET_BALANCE: "maps/getBalance",
  M2P_CARD_TRANSACTIONS: "user/getTransactionHistory/",
  CALCULATE_TRIP_TOLL: "trip/caluculateToll",
  CALCULATE_TRIP_TOLL_INTERNAL: "trips/caluculateToll",
  GET_TRIP_REPORT: "trip/tripReport",
  TRIP_BY_USERID: "trip/getTripsByUserId/",

  omcRegisterDriver: "omcVehicle/registerDriver/",
  driverAddBeneficiary: "beneficiary/add",
  getDriverBeneficiary: "beneficiary/beneficiaryData",
  // getDriverBeneficiary:"beneficiary/wallet/all",
  driverTransferToBeneficiary: "wallet/transferMoney/",
  addCardBeneficiary: "beneficiary/addBeneficiaryForCardTransfer",
  downloadSalaryCreditSheet: "wallet/getSampleSalaryDispersal",
  uploadSalaryCreditSheet: "card/transaction/salaryDispersalEXL",
  salaryWalletDetails: "wallet/getSalaryWallet",
  editSalaryWalletDetails: "wallet/editSalaryWallet",
  createSalaryWallet: "wallet/createSalaryWallet",
  createVendor: "vendor/createVendor",
  getLogipeVendorDetails: "vendor/getLogipeVendorDetails/",
  editVendor: "vendor/editVendor",
  getVendor: "vendor/getVendor",
  vendorTransactions: "vendor/transactions",
  vendorUploadBill: "vendor/uploadBill",
  vendorGetSignedUrl: "vendor/getSignedUrl",
  reportGenerateStatementForVendor: "report/generateStatementForVendor",
  transferToVendor: "transfer/transferToVendor",
  updateFleet: "fleet/updateFleetDetails",
  getFleetTracker: "advance/get/fleets/tracker",
  payDriverSalary: "wallet/payDriverSalary",
  addDriverV2: "driver/driverRegistration",
  addFreight: "freight/saveFreightDetails",

  bulkTransferSheet: "card/transaction/download/bulk/driverlist",
  bulkTransferSheetUpload: "card/transaction/download/bulk/driverlistBal",
  tripBulkActions: "trip/tripBulkActions",
  //sales
  getAllCustomers: "sales/getAllCustomers",
  createCustomer: "sales/createCustomer",
  getCustomer: "sales/getCustomer",
  getLedgerList: "sales/getLedgerList",
  getInvoiceList: "sales/getInvoiceList",
  deleteLedger: "sales/deleteLedger",
  createLogipeInvoice: "sales/createLogipeInvoice",
  createExternalInvoice: "sales/createExternalInvoice",
  downloadInvoice: "sales/downloadInvoice",
  getInvoice: "sales/getInvoice",
  editLogipeInvoice: "sales/editLogipeInvoice",
  editExternalInvoice: "sales/editExternalInvoice",
  uploadRecipt: "sales/uploadRecipt",
  addPaymentRecived: "sales/addPaymentRecived",
  downloadLedger: "sales/downloadLedger",
  GET_SIGNED_URL: "media/signedUrlWithKey",
  getAllTrips: "sales/getAllTrips",
  collectPayment: "sales/collectPayment",
  editCustomer: "sales/editCustomer",
  ADVANCED_DRIVERLIST_URL: "advance/get/drivers",
  ADVANCED_VEHICLE_LIST_URL: "advance/get/fleets",
  FIND_TRIP_ADDRESS: "trip/findAddress",
  ADD_TRIP_ADDRESS: "trip/addAddress",
  addVendorPayment: "sales/addVendorPayment",
  getVendorDetails: "vendor/getVendorDetails",
  fleetEditStatus: "fleet/edit/status",
  driverEditStatus: "driver/edit/status",
  EDIT_CONTROL_MANAGER: "manager/editControl",
  TRANSACTION_RECHARGE_FASTAG_WALLET: "account/transaction/rechargeFW",
  TRANSACTION_CARD_TRANSFER: "account/transaction/card/transfer/",
  TRANSACTION_RECHARGE_FASTAG: "account/transaction/fastag/recharge/tag",
  TRANSACTION_MASTER_ACCOUNT_UPI: "account/transaction/ma/upi",
  TRANSACTION_OMC_TRANSFER: "account/transaction/omc/transfer",
  TRANSACTION_FASTAG_RECHARGE_FROM_FW:"fastag/transaction/wallet/fastag/recharge",
  TRANSACTION_CARD_UPI: "card/transaction/transfer/upi",
  TRANSACTION_CREATE_ORDER_EASE: "orders/create/order/easebuzz",
  TRANSACTION_CREATE_ORDER_CASHFREE: "orders/create/order/cashfree",
  TRANSACTION_CREATE_ORDER_IDFC: "orders/create/order/idfc",
  Transfer_To_Vendor: "account/transaction/vendor/transfer",
  getStateFromPincode: "trip/getStateFromPincode",
  createLR: "freight/saveFreightDetails",
  getLRList: "freight/getFreightDetails",
  editLR: "freight/editFreightDetails",
  getPinCodeData: "trip/getPinCodeData",
  markInvoiceAsPaid: "sales/markInvoiceAsPaid",
  generateLrNo: "freight/generateLrNo",
  getSavedAddress: "trip/findAddress",
  ADD_CASH_EXPANSE: "trip/addCashExpense",
  ADD_VENDOR_PAYMENT: "vendor/addVendorPayment",

  getTripLedger: "trip/tripLedger",
  getVehicleLedger: "trip/vehicleLedger",
  getDriverLedger: "trip/driverLedger",
  CREATE_TRIP_EXPENSE: "trip/createTripExpense",
  ADD_DRIVER_EXPENSE: "trip/addDriverExpense",
  GET_VENDOR_LEDGER: "trip/vendorLedger",
  EDIT_LEDGER_EXPENSE: "trip/editExpense",
  GET_VEHICLE_LAST_TRIP: "trip/getVehicleLastTrip",
  EDIT_TRIP_EXPENSE: "trip/editTripExpense",
  GET_TRIP_EXPENSE: "trip/getTripExpense",
  START_TRIP_EXPENSE: "trip/startTripExpense",
  UPDATE_LR_IN_TRIP: "freight/updateLrInTrip",
  SALARY_WALLET_LIST: 'wallet/getAllSalaryWallet',
  SALARY_WALLET_SUMMARY: 'wallet/salaryWalletTransaction',
  IDFC_KYC_SEND_OTP: "idfcKyc/sendOtp",
  IDFC_KYC_VERIFY_OTP: "idfcKyc/verifyOtp ",
  IDFC_KYC_SAVE_IMAGE: "idfcKyc/saveImages",
  GET_TRIP_BY_TRIPID: "trip/getTripByTripId",
  fuelWalletREcharge: "account/transaction/fuelWallet/transfer",
  TRANSACTION_CARD_UPI: 'card/transaction/transfer/upi',
  TRANSACTION_CREATE_ORDER_EASE: 'orders/create/order/easebuzz',
  TRANSACTION_CREATE_ORDER_CASHFREE: 'orders/create/order/cashfree',
  TRANSACTION_CREATE_ORDER_IDFC: 'orders/create/order/idfc',
  Transfer_To_Vendor: 'account/transaction/vendor/transfer',
  SALARY_WALLET_LIST: 'wallet/getAllSalaryWallet',
  SALARY_WALLET_SUMMARY: 'wallet/salaryWalletTransaction',
  DRIVER_VEHICLE_HISTORY: 'VehicleData/getDriverVehicleHistory',
  GET_DOCUMENT:'VehicleData/getDocument',
  GET_CUSTOM_REQUEST: 'request/getCustomRequest/',
  DELETE_CUSTOM_REQUEST: 'request/deleteRequests/',
  EDIT_MANAGER_REQUEST: 'request/editRequest/',
  UPDATE_CUSTOM_REQUEST: 'request/action/update/',
  bulkFuelTransferSheetUpload: "trip/vendorFuelPayment",
  DELETE_BRANCH: "branch/deleteBranch",
  addRouteBulkExcelUpload: "sales/addRouteBulkExcelUpload",
  addRouteBulkExcel : "sales/addRouteBulkExcel",
  addTripBulkExcelUpload : "sales/addTripBulkExcelUpload",
  addTripBulkExcel: "sales/addTripBulkExcel"
};
