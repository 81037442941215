import React, { Component, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import PinInput from "react-pin-input";
import { cleanLoginLoader, handleLoginSuccess, sendOtp, verifyOtp } from "../../../../../services/Store/Actions/LoginAction";
import { Mixpanel } from "../../../../../Mixpanel";
import { ownerDetailsAction, refreshTokenAction } from "../../../../../services/Store/Actions/onboardingAction";
import { useNavigate } from "react-router-dom";
import { storeDataToAsyncStorage } from "../../../../../services/Utils/functions";
import SvgImage from "../../../../Icons/Icons";

const EnterOtps=(props)=>{

    const {firstName, middleName, lastName, toggle}= props;
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("OTP is Required")
  });
  
  const [cart, setCart] = useState();

  const { loader } = useSelector((state) => state?.ecom)
  console.log("loader----", loader)
  const dispatch = useDispatch();

  //code for timer for otp
  const [timer, setTimer] = useState("00:00");
  const Ref = useRef(null);

  const Navigate= useNavigate();
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    //const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        //(hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e, val) => {
    setTimer(val);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = (value) => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + value);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime(30), "00:30");
  }, []);

  function handleResend() {
    //console.log ("yeh ph no", props.phoneNo);
    setTimer("00:00");
    dispatch(
      sendOtp({
        phone: props.phone,
        resend: "any"
      }, (res) => {
        if (res?.data?.type === "success") {
          console.log("resend ho gaya");
        }
      })
    );
    clearTimer(getDeadTime(60), "00:60");
  }

  // const data=[{
  //   name:"pr1"
  // },{
  //   name:"pr1"
  // },{
  //   name:"pr1"
  // }]

  return (
    <div className="row whiteBackground col-12" style={{ margin: "0" }}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        className="col-12"
        initialValues={{
          otp: "",
          phone: props.phone,
        }}
        onSubmit={(values, actions) => {
          //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
          console.log("valuessss", values);
          dispatch(
            verifyOtp(
              values,
             (res) => {
                //actions.setSubmitting(false);
                console.log("SUCCESS 1", res);
                if (res?.status == 401) {
                  actions.setFieldError("otp", "Incorrect OTP. Please check again.");
                  dispatch(cleanLoginLoader());
                }else if (res?.data?.data?.user?.userId) {
                    console.log("TOKEN>>>", res.data?.data?.user?.type);
                    if (res.data?.data?.user?.type === "2") {
                      storeDataToAsyncStorage("_token", res.data?.data.token);
                      storeDataToAsyncStorage("_user", res.data?.data);
                      storeDataToAsyncStorage(
                        "_refreshToken",
                        res.data?.data.refreshToken
                      );
                      const x =  Mixpanel.getDistinctId();
                      if (x != res?.data?.data?.user?.userId) {
                        Mixpanel.alias(res?.data?.data?.user?.userId, x);
                      }
                      Mixpanel.identify(res.data?.data?.user?.userId);
                      Mixpanel
                        .people.set({
                          'Phone': res.data?.data?.user?.phone,
                          '$name': res.data?.data?.user?.fullName,
                          'userId': res?.data?.data?.user?.userId,
                        });
                      //dispatch(getOwnerAccountDetails(),res => {});
                      dispatch(handleLoginSuccess(res.data?.data));
                      //dispatch(getOwnerDetails());
                    
                      Navigate("/logimart");
                      toggle();
                    } else if (res.data?.data?.user?.type === "1") {
                      //setError(true);
                      actions.setFieldError("otp", "Cannot login through Driver's number");
                    } 
                  }else {
                  storeDataToAsyncStorage("_token", res?.data?.data?.token);
                  storeDataToAsyncStorage(
                    "_refreshToken",
                    res?.data?.data?.refreshToken
                  );
                  console.log("entered the new user", res, values);
                  dispatch(ownerDetailsAction({
                    "firstName": firstName.trim(),
                    "lastName": lastName.trim(),
                    "middleName": middleName.trim()
                  }, (res) => {

                    if (res?.data?.type === "success") {
                        console.log("SUCCESS", res);
                        let refreshToken= sessionStorage.getItem("_refreshToken");
                        let tokenOld=JSON.parse(refreshToken)
                        dispatch(refreshTokenAction({
                          "refreshToken":tokenOld
                        },(res)=>{
                          console.log("new token", res);
                          storeDataToAsyncStorage("_token", res.data?.data.token);
                          storeDataToAsyncStorage("_user", res.data?.data);
                          storeDataToAsyncStorage(
                            "_refreshToken",
                            res.data?.data.refreshToken
                          );
                          dispatch(handleLoginSuccess(res.data?.data));
                          //dispatch(getOwnerDetails());
                          Navigate("/logimart");
                          toggle();
                        }))
                      }
                    
                  }))
                }
              }
            )
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} className="InputForm" >
            <FormGroup className="formPosition">
              <div className="Regular text3 greyFont">Welcome! 👋</div>
              <div className="heading1 semiBold">OTP verification</div>
              <div className="Regular text3 padding8T headText2" >
                Enter the OTP sent to +91 {props.phone}
                <div className="editIcon" onClick={() => {
                  props.setLoginStatus(false);
                }}>
                  <SvgImage name="editLogo" width="18" height="18"/>
                </div>
              </div>
              <div className="otpInput">
                <PinInput
                  length={4}
                  initialValue=""
                  secret={false}
                  onChange={handleChange("otp")}
                  type="numeric"
                  value={values.otp}
                  onBlur={handleBlur("otp")}
                  touched={touched.otp}
                  errors={errors.otp}
                  inputMode="number"
                  style={{
                    padding: "10px 0px 10px 0px",
                  }}
                  inputStyle={{
                    borderBottom: "1px solid #C8C8CA",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    marginLeft: "45px",
                    marginRight: "0px",
                    width: "35px",
                    textAlign: "left"
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  focus={true}
                />
                {/* <input
                  type="text"
                  id="otp"
                  className="input-body-3"
                  name="otp"
                  value={values.otp}
                  touched={touched.otp}
                  errors={errors.otp}
                  onBlur={handleBlur("otp")}
                  onChange={handleChange("otp")}
                  style={{ textAlign: "left", marginLeft: "0px" }}
                  placeholder="Phone Number"
                  maxLength="10"
                ></input> */}
              </div>
              <div className="padding16T text4">
                Resend OTP in <span className="greenText">{timer}</span>
              </div>
              <div
                className="text4"
                style={{
                  margin: "0.3rem 0 0 0",
                  color: "red",
                  textAlign: "left",
                }}
              >
                {errors.otp && touched.otp ? <div>{errors.otp}</div> : null}
              </div>
              <Button
                type="submit"
                className="loginButton semiBold text2"
                style={{
                  marginTop: "1.5rem",
                  background: values.otp.length < 4 ? "#485B7C" : (loader ? "#EFEFEF" : "#2e7c7b"),
                  color: values.otp.length < 4 ? "#B5B5B5" : (loader ? "#B5B5B5" : "#ffffff"),
                }}
                disabled={loader ? true : false}
              >
                {loader ? "Please Wait" : "Verify OTP"}
              </Button>
              <div
                className="bottomTextLogin padding16T Regular text4"
                style={{ color: "#25252D" }}
              >
                Didn’t recieve the code?
                <span
                  style={{
                    color: timer === "00:00" ? "#485B7C" : "#B5B5B5",
                    cursor: timer === "00:00" ? "pointer" : "default",
                    paddingLeft: "0.5rem",
                  }}
                  className="Medium"
                  onClick={handleResend}
                >
                  Resend
                </span>
              </div>
              {/* <div className="bottomTextLogin padding16T Regular text4">
              <span
                className="bottomTextSpan Medium"
                onClick={() => {
                  props.handleTermShow();
                }}
              >
                T&C{" "}
              </span>{" "}
              and{" "}
              <span
                className="bottomTextSpan Medium"
                onClick={() => {
                  props.handlePrivacyShow();
                }}
              >
                Privacy Policy
              </span>{" "}
              apply
            </div> */}
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
}



export default EnterOtps;