import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import { Table } from "reactstrap";
import { IoIosCloseCircleOutline } from "react-icons/io";
import PGCollectionButton from "../../../Subcomponent/PGCollectionButton";
import SearchBar from "../../subComponents/searchbar";
import ManageDriverVehicleListBar from "../../../Subcomponent/ManageDriverVehicleListBar";
import ManageDriverVehicleDetailsCard from "../../../Subcomponent/ManageDriverVehicleDetailsCard";
import {
  getFleet,
  cleanFleet,
} from "../../../services/Store/Actions/driverslistActions";
import { padding } from "@mui/system";
import { Button } from "reactstrap";
import SvgImage from "../../Icons/Icons";
import ReactPaginate from "react-paginate";
import { getVehicleListAction } from "../../../services/Store/Actions/manageVehicleAction";

const VehicleList = (props) => {
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const { loader } = useSelector((state) => state?.driverlist);
  const dispatch = useDispatch();
  const fleetList = useSelector((state) => state?.manageVehicle?.allFleets?.rows);
  const totalExpenseCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );
  const pageCount =
    totalExpenseCount % 10 === 0
      ? totalExpenseCount / 10
      : Math.floor(totalExpenseCount / 10) + 1;

  const findSearch = () => {
    dispatch(
      getVehicleListAction({
        offset: 0,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };

  useEffect(() => {
    console.log("useEffect 1");
    setOffset(0);
    //console.log ("useEffect 2")
    //console.log("sss", search);
    dispatch(cleanFleet());
    //console.log("sss", search);
    dispatch(
      getVehicleListAction({
        offset: 0,
        limit: 10,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, [search]);

  // useEffect(() => {
  //   setOffset(0);
  //   console.log ("useEffect 2")
  //   //console.log("sss", search);
  //   dispatch(cleanFleet());
  //   dispatch(
  //     getFleet({
  //       offset: 0,
  //       search: "",
  //       minKyc: "",
  //       sortAs: "",
  //       sortBy: "",
  //     })
  //   );
  // }, []);

  // useEffect(() => {
  //   return function cleanUp() {
  //     dispatch(cleanFleet());
  //     setOffset(0);
  //     dispatch(cleanFleet());
  //   };
  // }, []);
  console.log("***************idhr brp999", search);

  // const data = [
  //   {
  //     ID: "384748434911",
  //     name: "Abhishek K.",
  //     number: "KA25 BU3389",
  //     status: "Available",
  //     color: "#2E7C7B",
  //   },
  //   {
  //     ID: "384748434911",
  //     name: "Shoubhik K.",
  //     number: "KA25 BU3389",
  //     status: "Available",
  //     color: "#2E7C7B",
  //   },
  //   {
  //     ID: "384048434911",
  //     name: "Abhishek K.",
  //     number: "KA25 BU3389",
  //     status: "Available",
  //     color: "#2E7C7B",
  //   },
  //   {
  //     ID: "384748434911",
  //     name: "Abhishek K.",
  //     number: "KA25 BU3389",
  //     status: "Available",
  //     color: "#2E7C7B",
  //   },
  // ];
  let a = 250;
  let b = 0;

  const handleOlderTransaction = () => {
    setOffset(offset + 10);
    dispatch(
      getVehicleListAction({
        offset: offset + 10,
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };

  const handlePageClick = (event) => {
    console.log("Clickess");
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    dispatch(
      getVehicleListAction({
        offset: newOffset,
        limit: 10,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
    setOffset(newOffset);
  };

  return (
      <>
        {/* <div
        className="VehicleListclose"
        onClick={props.toggleModal}
        style={{ cursor: "pointer" }}
      > */}
        {/* <div className="VehicleListCloseIcon" 
        >
          <IoIosCloseCircleOutline size={27} 
          onClick={props.toggleModal}
        style={{ cursor: "pointer" }}/>
        </div> */}

        {/* <div className="VehicleListCloseText">Close</div>
      </div> */}
        <div className="row padding32L ">
          <div className="col-1 padding16 padding32L">
            <img
              src={
                require("../../../web-assets/images/noun-truck-2300420 1.svg")
                  .default
              }
              width="35"
              heihgt="35"
            />
          </div>
          <div className="padding16 col-5 Bold heading1">Full Vehicle List</div>
          {/* <div className="VehicleListButton">
          <PGCollectionButton name="Add New Vehicle" />
        </div> */}
        </div>
        {/* <div className="VehicleListCloseIcon" 
        >
          <IoIosCloseCircleOutline size={27} 
          onClick={props.toggleModal}
        style={{ cursor: "pointer" }}/>
        </div> */}
        <div className="padding32" style={{ paddingLeft: "3rem", paddingTop:"1rem", paddingBottom:"1rem" }}>
          <SearchBar
            placeholder="Search for Vehicles"
            // style={{ width: "828px" }}
            width={820}
            height="46"
            value={search}
            onChange={(event) => {
              dispatch(cleanFleet());
              setSearch(event.target.value);
              console.log("32645235273", search);
            }}
            findSearch={findSearch}
          />
        </div>
        {/* <div className="VehicleListBar">
        <ManageDriverVehicleListBar />
      </div> */}
        {/* <div
          className=""
          style={{ zIndex: 90, paddingLeft: "3rem", paddingRight: "3rem" }}
        >
          <div
            className="row padding16 padding32L Bold text3"
            style={{
              height: "50px",
              zIndex: 50,
              background: "rgba(46, 124, 123, 0.1)",
            }}
          >
            <div className="col-2 padding0">Vehicle Number</div>
            <div className="col-3 padding0">FASTag ID</div>
            <div className="col-3 padding0">Assigned Driver</div>
            <div className="col-2 padding0">Status</div>
            <div className="col-1 padding0 ">Action</div>
          </div>
        </div> */}
        <div className="transitionContainerDrivers">
        <div className="tableContaincerDrivers" >
          <Table className="driverTable">
            <thead>
              <tr className="table-header-customer-details">
                <th >Vehicle Number</th>
                <th >FASTag ID</th>
                <th >Assigned Driver</th>
                <th >Status</th>
                <th >Action</th>
              </tr>
            </thead>
            <tbody>
              {fleetList?.map((items, i) => {
                return (
                  <ManageDriverVehicleDetailsCard
                  setVehicleNumber={props.setVehicleNumber}
                  toggleModal={props.toggleModal}
                  fleetID={items.fleetId}
                  driverID={props.driverID}
                  name={
                    items?.FleetDriverMapHistories?.[0]?.Driver?.User?.fullName
                  }
                  ID={items?.Fastag?.tagId}
                  number={items.regNumber}
                  color={items.color}
                  status={
                    items?.FleetDriverMapHistories[0] !== undefined
                      ? "Assigned"
                      : "Available"
                  }
                />
                );
              })}
            </tbody>
          </Table>
          {pageCount > 1 ? (
          <div className="modalPagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          <></>
        )}
        </div>
        </div>

        {/* <div
        style={{
          padding: "11rem 3.3rem 0"
          // marginTop: "14.5rem",
          // marginLeft: "5.7rem",
          // marginRight: "5.7rem",
        }}
      >
        <Table className="table-borderless">
          <thead>
            <tr
              className="table-header-customer-details row"
              style={{
                // marginLeft: "10rem",
                textAlign:"center"
              }}
            >
              <th className="col-2">vehicle Number</th>
              <th className="col-3">Fastag ID</th>
              <th className="col-2">Assigned Driver</th>
              <th className="col-3">Status</th>
              <th className="col-2">Action</th> */}

        {/* <th
                style={{
                  padding: "0 0px 16px 10px",
                  textAlign: "left",
                  
                }}
              >
                vehicle Number{" "}
              </th>
              <th style={{ padding: "0px 0 16px 25px", textAlign: "left" }}>
                Fastag ID
              </th>
              <th style={{ padding: "0 0px 16px 0" }}>Assigned Driver </th>
              <th style={{ padding: "0 37px 16px 10px" }}>Status</th>

              <th
                style={{
                  padding: "0 15px 16px 25px",
                  right: "1rem",
                  
                }}
              >
                Action
              </th> */}
        {/* </tr>
          </thead> */}

        {/* <tbody>
              {fleetList?.map((data,i) => {
                return (
                  <tr className="table-row-customer-details" key = {i}>
                    <td style={{ padding: "28px 0 0 0" }}>
                      <div className="row">
                        <div className="col-12">
                          <p>{data.mode !== 2 ? "Booking" : "Payment"}</p>
                        </div>
                        <div className="col-12">
                          <p>ID :</p>
                        </div>
                      </div>
                    </td>
                    <td style={{ padding: "28px 0 0 0" }}>
                      {moment(data.date).format("DD-MM-YYYY")}
                    </td>
                    <td style={{ padding: "28px 0 0 0" }}>
                      <div className="row">
                        <div className="col-12">
                          <p>{"₹" + data.amount + ".00"}</p>
                        </div>
                        <div className="col-6">
                          <p style={{ position: "relative", left: "42px" }}>
                            mode :
                          </p>
                        </div>
                        <div className="col-6">
                          <p style={{ position: "relative", right: "38px" }}>
                            {data?.mode !== 2 ? "Cash" : "Payment Link"}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td style={{ padding: "28px 0 0 0" }}> N/A</td>
                    <td style={{ padding: "28px 0 0 0" }}>
                      {data.mode !== 2 ? (
                        <div
                          onClick={edit === i ? () => updateClose() : () => update(data?.id,i)}
                          style={{ cursor: "pointer" }}
                          className="row"
                        >
                          <div className={edit === i ? "col-6" : ""}>
                            {edit === i ? (
                              <div className="edit-btn-div row" onClick={() => updateModalOpen()} >
                                <div className="col-3">
                                  <SvgImage name="EditLogo" />
                                </div>

                                <div className="col-9">
                                  <p >edit</p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className={edit === i ? "col-5" : ""} >
                            <SvgImage name="ThreeDot" />
                          </div>
                        </div>
                      ) : (
                        "Action not applied"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody> */}
        {/* </Table>
      </div> */}
        {/* <div className="vehicleAssign-scroll">
      {fleetList?.map((items) => {
        a = a + 68;
        b = a + "px";
        return (
          <div className="VehicleListCard" style={{ top: b }}>
            <ManageDriverVehicleDetailsCard
              setVehicleNumber={props.setVehicleNumber}
              toggleModal={props.toggleModal}
              fleetID={items.fleetId}
              driverID={props.driverID}
              name={items?.FleetDriverMapHistories[0]?.Driver?.User?.fullName}
              ID={items?.Fastag?.tagId}
              number={items.regNumber}
              color={items.color}
              status={(items?.FleetDriverMapHistories[0]!==undefined) ? "Assigned" : "Available"}
            />
          </div>
        );
      })}
      </div> */}

        {/* <div
          className="AllTransactionDetailsBar all-transaction-history-box1"
          // style={{marginTop:"-20px"}}
        >
          {fleetList?.map((items, i) => {
            return (
              <div className="" key={i} style={{ marginTop: "7px" }}>
                <ManageDriverVehicleDetailsCard
                  setVehicleNumber={props.setVehicleNumber}
                  toggleModal={props.toggleModal}
                  fleetID={items.fleetId}
                  driverID={props.driverID}
                  name={
                    items?.FleetDriverMapHistories[0]?.Driver?.User?.fullName
                  }
                  ID={items?.Fastag?.tagId}
                  number={items.regNumber}
                  color={items.color}
                  status={
                    items?.FleetDriverMapHistories[0] !== undefined
                      ? "Assigned"
                      : "Available"
                  }
                />
              </div>
            );
          })}
        </div> */}
        {/* <div
          className="view-all-transaction-customer-receeivabl"
          onClick={handleOlderTransaction}
          // style={{paddingBottom:"1.5rem", marginTop:"10rem"}}
        > */}
        {/* {totalExpenseCount - offset > 10 ? (
          <div
            className="OlderHistoryButton"
            onClick={handleOlderTransaction}
            style={{ top: "0", paddingTop: "1rem" }}
          >
            <Button
              disabled={loader ? true : false}
              className="transactionButton"
            >
              {loader ? "Please Wait" : "Other Vehicles"}
              <div style={{ paddingLeft: "8px" }}>
                <SvgImage name="downArrow" />
              </div>
            </Button>
          </div>
        ) : (
          <></>
        )} */}
        {/* {pageCount > 1 ? (
          <div className="modalPagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          <></>
        )} */}

        {/* </div> */}
      
    </>
  );
};
export default VehicleList;
