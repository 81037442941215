import React, { useState } from "react";
import SvgImage from "../../Components/Icons/Icons";
import CarousalElements from "./CarousalElements";
import "./index.css";

const RightInfoPanel = (props) => {
  const companyServices = [
    {
      text1: "LogiPe Driver Card for Expenses, Salary and Much More…",
      text2: "Get Cards today Or know more",
      svgName: "loginCardIcon",
      urlLink: "https://www.logipe.com/fleet-card",
    },
    {
      text1: "LogiPe FasTags for a Smooth Vehicle Journey",
      text2: "Get FasTags today Or know more",
      svgName: "loginFastagIcon",
      urlLink: "https://www.logipe.com/fastag",
    },
    {
      text1: "LogiPe Membership for More Savings…",
      text2: "Buy LogiPe Membership for Additional Benefits",
      svgName: "loginReferIcon",
      urlLink: "https://www.logipe.com",
    },
    {
      text1:
        "Loan, Insurance, Tyres - Get Loans, Tyre , Lubricants , Insurance all at one place.",
      text2: "Enquire today for Anything You Need..",
      svgName: "loginLogiSureIcon",
      urlLink: "https://www.logipe.com/logisure-fleet-insurance",
    },
    {
      text1: "Create Trips, Manage Expenses , Collect Payments on Logipe.",
      text2: "Contact to know More.",
      svgName: "loginCollectionIcon",
      urlLink: "https://www.logipe.com/collection",
    },
  ];
  return (
    <div >
      {/* <div className="fadeOuterDiv">
        <div id="fadeDesign">
          <SvgImage name="fadeDesign" />
        </div>
      </div> */}
      <div className=" padding16 padding0R CarouselTopDiv" style={{position:"relative", }}>
        <div
          id="carouselServicesDisplay"
          className="carousel slide paddingTextCarousel"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {companyServices.map((slide, i) => {
              return <CarousalElements slide={slide} i={i} />;
            })}
          </div>
          <div className="carousel-indicators" >
            <button
              type="button"
              data-bs-target="#carouselServicesDisplay"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselServicesDisplay"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselServicesDisplay"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselServicesDisplay"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselServicesDisplay"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
          </div>
        </div>
      </div>
      <div className="copyRightText row text4">
        <div className="col-3 padding8R" style={{textAlign:"right"}}>
          <SvgImage name="copyrightIcon" />
        </div>
        <div className="col-9" style={{textAlign:"left"}}>Copyright, LogiPe 2022. All rights reserved.</div>
      </div>
      <div className="shadowBottom">
      </div>
    </div>
  );
};

export default RightInfoPanel;
