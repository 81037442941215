//Staging
// export const BASE_URL = "https://beta.logipe.com/api/v1/";
// export const BASE_ACCOUNT_URL = "https://account-staging.logipe.com/api/v1/";
// export const BASE_WALLET_URL = "https://wallet-staging.logipe.com/api/v1/";
// export const BASE_FASTAG_URL = "https://fastag-staging.logipe.com/api/v1/";
// export const BASE_CASH_BACK_URL = 'https://cashback-staging.logipe.com/api/v1/';

//Production

const storedAPIs = sessionStorage.getItem("_apiList");
// console.log("!!!!!!!!!", storedAPIs);
// let list = JSON.parse(storedAPIs);
// console.log("yukta--------", list)

// console.log("yukta--------", list)
// let list={account:
// "http://localhost:9002/account/api",
// api
// :
// "https://gke-apip.logipe.com/api/api",
// cashback
// :
// "https://gke-apip.logipe.com/cashback/api",
// ecom
// :
// "https://gke-apip.logipe.com/ecom/api",
// fastag
// :
// "https://gke-apip.logipe.com/fastag/api",
// internal
// :
// "https://gke-apip.logipe.com/internal/internal",
// m2pWallet
// :
// "https://gke-apip.logipe.com/m2pwallet/api",
// wallet
// :
// list?.wallet}
// console.log("TRY----",list)
let list = {
  account: "https://gke-apip.logipe.com/account/api",
  api: "https://gke-apip.logipe.com/api/api",
  cashback: "https://gke-apip.logipe.com/cashback/api",
  ecom: "https://gke-apip.logipe.com/ecom/api",
  fastag: "https://gke-apip.logipe.com/fastag/api",
  internal: "https://gke-apip.logipe.com/internal/internal",
  m2pWallet: "https://gke-apip.logipe.com/m2pwallet/api",
  others: {
    1234567890: {
      key: "https://gke-apip.logipe.com/api/api/v1/media/upload1",
      value: "https://api.logipe.com/api/v1/media/upload",
    },
    upload: "https://api.logipe.com/api/v1/media/",
  },
  wallet: list?.wallet,
};
export const BASE_URL_3 = list?.api + "/v3/";
// export const BASE_URL_5 = list?.api + "/v5/";

//pre-production

//ecom
export const ecom_BASE_URL = list?.ecom + "/v1/";

//pre-prod
export const PrProd_BASE_URL_3 = "https://gke-apip.logipe.com/api/api/v3/";
export const PrProd_BASE_URL_1 = "https://gke-apip.logipe.com/api/api/v1/";

//prod
export const Prod_BASE_URL_3 = "https://gke-apip.logipe.com/api/api/v3/";
export const Prod_BASE_URL_1 = "https://gke-apip.logipe.com/api/api/v1/";

//  //pre-production
//  export const PreProd_BASE_URL = list?.preProd==undefined?'https://apip.logipe.com/api/v1/':list?.preProd+"/v1/";

//  //ecom
//  export const ecom_BASE_URL = list?.ecom==undefined?'https://ecom-api.com/api/v1/':list?.ecom+"/v1/";

//  //internal
//  export const BASE_INTERNAL_URL_PREPROD = 'https://gke-apip.logipe.com/internal/internal/';
//  export const BASE_INTERNAL_URL = list?.internal+"/";

export const BASE_URL = list?.api + "/v1/";
export const BASE_URL_V2 = list?.api + "/v2/";
export const BASE_URL_V3 = list?.api + "/v3/";
export const BASE_URL_V2_L = list?.api + "/v2/";
export const BASE_URL_V4 = list?.api + "/v4/";
export const BASE_URL_V5 = list?.api + "/v5/";
export const BASE_URL_V6 = list?.api + "/v6/";
export const BASE_ACCOUNT_URL = list?.account + "/v1/";
export const BASE_WALLET_URL = list?.wallet + "/v1/";
export const BASE_FASTAG_URL = list?.fastag + "/v1/";
export const BASE_CASH_BACK_URL = list?.cashback + "/v1/";
export const BASE_M2P_URL = list?.m2pWallet + "/v1/";

export const BASE_WALLET_URL_2 = list?.wallet + "/v2/";
export const BASE_WALLET_URL_3 = list?.wallet + "/v3/";
export const BASE_ACCOUNT_URL_2 = list?.account + "/v2/";
export const BASE_ACCOUNT_URL_3 = list?.account + "/v3/";

export const BASE_URL_2 = list?.api + "/v2/";
export const BASE_URL_4 = list?.api + "/v4/";
// export const BASE_URL_5 = list?.api + "/v5/";

export const BASE_FASTAG_URL_2 = list?.fastag + "/v2/";
export const BASE_FASTAG_URL_3 = list?.fastag + "/v3/";
export const BASE_FASTAG_URL_4 = list?.fastag + "/v4/";
export const BASE_FASTAG_URL_5 = list?.fastag + "/v5/";
export const BASE_FASTAG_URL_7 = list?.fastag + "/v7/";

export const BASE_OMC_URL = list?.omc + "/v1/";

//pre-production
export const PreProd_BASE_URL =
  list?.preProd == undefined
    ? "https://aks-api.logipe.com/api/api/v1/"
    : list?.preProd + "/v1/";
export const PreProd_BASE_URL_2 =
  list?.preProd == undefined
    ? "https://aks-api.logipe.com/api/api/v2/"
    : list?.preProd + "/v2/"; //TODO:change
export const PreProd_BASE_URL_account =
  "https://aks-api.logipe.com/account/api/v1/";
export const PreProd_BASE_URL_wallet =
  "https://aks-api.logipe.com/wallet/api/v1/";
export const PreProd_BASE_URL_wallet_2 =
  "https://aks-api.logipe.com/wallet/api/v2/";
export const PreProd_BASE_URL_fastag =
  "https://aks-api.logipe.com/fastag/api/v1/";
export const Prod_BASE_URL_TRANSACTIONS =
  "https://aks-api.logipe.com/transactions/api/v1/";
export const PreProd_BASE_URL_account_2 =
  "https://aks-api.logipe.com/account/api/v2/";

//ecom
export const BASE_URL_ECOM = list?.ecom + "/v1/";

//internal
export const BASE_INTERNAL_URL_PREPROD =
  "https://aks-api.logipe.com/internal/internal/";
export const BASE_INTERNAL_URL = list?.internal + "/";

//Pre-prod
export const BASE_FLEET_URL = "https://aks-api.logipe.com/api/api/v1/";
// export const BASE_ACCOUNT_URL = 'https://gke-apip.logipe.com/account/api/v1/';
// export const BASE_WALLET_URL = 'https://gke-apip.logipe.com/wallet/api/v1/';
// export const BASE_FASTAG_URL = 'https://gke-apip.logipe.com/fastag/api/v1/';
// export const BASE_CASH_BACK_URL = 'https://gke-apip.logipe.com/cashback/api/v1/';
// export const BASE_M2P_URL ='https://gke-apip.logipe.com/m2pwallet/api/v1/';

// export const BASE_WALLET_URL_2= 'https://gke-apip.logipe.com/wallet/api/v2/';
// export const BASE_ACCOUNT_URL_2= 'https://gke-apip.logipe.com/account/api/v2/';
// export const BASE_URL_2= 'https://gke-apip.logipe.com/api/api/v2/';
// export const BASE_URL_4= 'https://gke-apip.logipe.com/api/api/v4/';

// export const BASE_FASTAG_URL_2 = 'https://gke-apip.logipe.com/fastag/api/v2/';
// export const BASE_FASTAG_URL_3 = 'https://gke-apip.logipe.com/fastag/api/v3/';
// export const BASE_FASTAG_URL_4 = 'https://gke-apip.logipe.com/fastag/api/v4/';
// export const BASE_FASTAG_URL_5 = 'https://gke-apip.logipe.com/fastag/api/v5/';

// export const BASE_URL = 'https://gke-apip.logipe.com/api/api/v1/';
// export const BASE_URL_V2 = 'https://gke-apip.logipe.com/api/api/v2/';
// export const BASE_URL_V3 = 'https://gke-apip.logipe.com/api/api/v3/';
// export const BASE_URL_V4 = 'https://gke-apip.logipe.com/api/api/v4/';
// export const BASE_ACCOUNT_URL = 'https://gke-apip.logipe.com/account/api/v1/';
// export const BASE_ACCOUNT_URL_V2 ='https://gke-apip.logipe.com/account/api/v2/';
// export const BASE_WALLET_URL = 'https://gke-apip.logipe.com/wallet/api/v1/';
// export const BASE_WALLET_URL_V2 = 'https://gke-apip.logipe.com/wallet/api/v2/';
// export const BASE_FASTAG_URL = 'https://gke-apip.logipe.com/fastag/api/v1/';
// export const BASE_FASTAG_URL_V2 = 'https://gke-apip.logipe.com/fastag/api/v2/';
// export const BASE_FASTAG_URL_V3 = 'https://gke-apip.logipe.com/fastag/api/v3/';
// export const BASE_FASTAG_URL_V4 = 'https://gke-apip.logipe.com/fastag/api/v4/';
// export const BASE_FASTAG_URL_V5 = 'https://gke-apip.logipe.com/fastag/api/v5/';
// export const BASE_FASTAG_URL_V6 = 'https://gke-apip.logipe.com/fastag/api/v6/';
// export const BASE_FASTAG_URL_V7 = 'https://gke-apip.logipe.com/fastag/api/v7/';
// export const BASE_CASH_BACK_URL ='https://gke-apip.logipe.com/cashback/api/v1/';
// export const BASE_URL_M2P = 'https://gke-apip.logipe.com/m2pwallet/api/v1/';
// export const BASE_INTERNAL_URL ='https://gke-apip.logipe.com/internal/internal/';
// export const BASE_LOGIMART_URL = 'https://gke-apip.logipe.com/ecom/api/v1/';

// export const BASE_URL = 'http://localhost:9001/api/api/v1/';
// export const BASE_URL_V5 = 'http://localhost:9001/api/api/v5/';
export const BASE_URL_5 = "http://localhost:9001/api/api/v5/";
// export const BASE_URL_V2 = 'https://gke-apip.logipe.com/api/api/v2/';
// export const BASE_URL_V3 = 'https://gke-apip.logipe.com/api/api/v3/';
// export const BASE_URL_V4 = 'https://gke-apip.logipe.com/api/api/v4/';
// export const BASE_URL_V5 = 'https://gke-apip.logipe.com/api/api/v5/';
// export const BASE_ACCOUNT_URL = 'https://gke-apip.logipe.com/account/api/v1/';
// export const BASE_ACCOUNT_URL_V2 ='https://gke-apip.logipe.com/account/api/v2/';
// export const BASE_WALLET_URL = 'https://gke-apip.logipe.com/wallet/api/v1/';
// export const BASE_WALLET_URL_V2 = 'https://gke-apip.logipe.com/wallet/api/v2/';
// export const BASE_FASTAG_URL = 'https://gke-apip.logipe.com/fastag/api/v1/';
// export const BASE_FASTAG_URL_V2 = 'https://gke-apip.logipe.com/fastag/api/v2/';
// export const BASE_FASTAG_URL_V3 = 'https://gke-apip.logipe.com/fastag/api/v3/';
// export const BASE_FASTAG_URL_V4 = 'https://gke-apip.logipe.com/fastag/api/v4/';
// export const BASE_FASTAG_URL_V5 = 'https://gke-apip.logipe.com/fastag/api/v5/';
// export const BASE_FASTAG_URL_V6 = 'https://gke-apip.logipe.com/fastag/api/v6/';
// export const BASE_FASTAG_URL_V7 = 'https://gke-apip.logipe.com/fastag/api/v7/';
// export const BASE_CASH_BACK_URL ='https://gke-apip.logipe.com/cashback/api/v1/';
// export const BASE_URL_M2P = 'https://gke-apip.logipe.com/m2pwallet/api/v1/';
// export const BASE_INTERNAL_URL ='https://gke-apip.logipe.com/internal/internal/';
// export const BASE_LOGIMART_URL = 'https://gke-apip.logipe.com/ecom/api/v1/';
