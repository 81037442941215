import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import ToastV2 from "../../../subComponents/toastV2";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import { useNavigate } from "react-router-dom";
import {
  createVendorAction,
  editVendorAciton,
} from "../../../../services/Store/Actions/beneficiaryAction";
import "./index.css";
function EditBankDetails(props) {
  const { toggle, modal, setModal, customerId } = props;
  const [openToast, setOpenToast] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toastObject = useSelector((state) => state?.app?.toastObject);
  const { customer } = useSelector((state) => state?.customer);
  const { vendorDetails } = useSelector((state) => state?.beneficiary);
  console.log("====>>customer>>>", customer);
  const validationSchema = Yup.object().shape({
    // state: Yup.string().required("Please enter your state"),
    // city: Yup.string().required("Please enter your city"),
    // address: Yup.string().required("Please enter your address"),
  });

  const handleClose = () => {
    setModal(!modal);
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            className="boxContainer2"
            style={{ marginTop: " 30px" }}
            //   className="PGCollectionBox"
          >
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Edit Bank Details
            </div>

            <Formik
              // isValid='true'
              enableReinitialize
              //   validationSchema={validationSchema}
              initialValues={{
                companyName: customer.companyName ? customer?.companyName : "",
                address: customer?.TripAddress?.address ? customer?.TripAddress?.address : "",
                pincode: customer.pincode ? customer?.pincode : "",
                phone: customer?.phone,
                panNo: customer.panNo ? customer?.panNo : "",
                gstin: customer.gstin ? customer?.gstin : "",
                city: customer?.TripAddress?.city ? customer?.TripAddress?.city : "",
                contactName: customer.contactName ? customer?.contactName : "",
                email: customer.email ? customer?.email : "",
                state: customer?.TripAddress?.state ? customer?.TripAddress?.state : "",
                accountNo: vendorDetails?.bankAccountNumber
                  ? vendorDetails?.bankAccountNumber
                  : "",
                ifsc: vendorDetails.ifsc ? vendorDetails?.ifsc : "",
                upiId: vendorDetails.vpaId ? vendorDetails?.vpaId : "",
              }}
              onSubmit={(values) => {
                console.log("===>>>>init", values);
                // setOpenToast(true);
                values["customerId"] = customerId;
                let payload = {
                  vpaId: values?.upiId == "" ? undefined : values?.upiId,
                  bankAccountNumber:
                    values?.accountNo == ""
                      ? undefined
                      : values?.accountNo?.toString(),
                  isLogipeVendor: "1",
                  phone:
                    values?.phone?.toString() == ""
                      ? undefined
                      : values?.phone?.toString(),
                  name: values?.companyName == "" ? null : values?.companyName,
                  bankAccountName:
                    values?.companyName == "" ? undefined : values?.companyName,
                  ifsc: values?.ifsc == "" ? undefined : values?.ifsc,
                  gstNumber: values?.gstin == "" ? undefined : values?.gstin,
                  address: values?.address == "" ? undefined : values?.address,
                };
                console.log("payload", payload);
                payload["customerId"] = customer.customerId;
                if (vendorDetails?.bankAccountNumber) {
                  payload["id"] = vendorDetails?.vendorId?.toString();
                  dispatch(
                    editVendorAciton(payload, (res) => {
                      // setLoader(false);
                      console.log("asdasdasdsad ");
                      if (res?.status == 200) {
                        // setSuccessModal(true);
                      } else {
                      }
                    })
                  );
                } else {
                  dispatch(
                    createVendorAction(payload, (res) => {
                      // console.log("asdasdasdsad ");
                      // if (res?.status == 200) {
                      //   setSuccessModal(true);
                      // } else {
                      //   setFailedError(res?.data?.message)
                      //   setFailedModal(true);
                      // }
                    })
                  );
                }
                handleClose();
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "40px", padding: "0 0px 0 0px" }}
                >
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div>
                      <div className="">
                        <div className="">
                          <label for="vrn" className="heading3 semiBold">
                            Bank Account Number
                          </label>
                        </div>
                        <div className="" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.accountNo && errors.accountNo
                                ? "is-invalid"
                                : ""
                            }`}
                            name="accountNo"
                            value={values.accountNo}
                            onChange={handleChange("accountNo")}
                            onBlur={handleBlur("accountNo")}
                            touched={touched.accountNo}
                            errors={errors.accountNo}
                            placeholder="Bank Account Number"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.accountNo && touched.accountNo ? (
                              <div>*{errors.accountNo}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          <label for="vrn" className="heading3 semiBold">
                            Ifsc Code
                          </label>
                        </div>
                        <div className="" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="ifsc"
                            // readonly=""
                            className={`inputBoxAddCust ${
                              touched.ifsc && errors.ifsc ? "is-invalid" : ""
                            }`}
                            // style={{ background: "#2E7C7B0D" }}
                            name="ifsc"
                            value={values.ifsc}
                            onChange={handleChange("ifsc")}
                            onBlur={handleBlur("ifsc")}
                            touched={touched.ifsc}
                            errors={errors.ifsc}
                            placeholder="ifsc"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.ifsc && touched.ifsc ? (
                              <div>*{errors.ifsc}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          <label for="vrn" className="heading3 semiBold">
                            Upi Id
                          </label>
                        </div>
                        <div className="" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="upiId"
                            className={`inputBoxAddCust ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                            name="upiId"
                            value={values.upiId}
                            onChange={handleChange("upiId")}
                            onBlur={handleBlur("upiId")}
                            touched={touched.upiId}
                            errors={errors.upiId}
                            placeholder="Upi Id"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.upiId && touched.upiId ? (
                              <div>*{errors.upiId}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    style={{
                      padding: "25px 0 0 0",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{ width: "160px", borderRadius: "5px" }}
                      className="generateOtp textAddCust Medium btn btn-secondary"
                      // onClick={addFreight}
                    >
                      Done
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default EditBankDetails;
