import {
    DOWNLOAD_AADHAAR,
    DOWNLOAD_AADHAAR_SUCCESS,
    PAN_AUTH,
    PAN_AUTH_SUCCESS,
    GET_AADHAAR_OTP,
    GET_AADHAAR_OTP_SUCCESS,
    UPDATE_PAN_AADHAAR,
    UPDATE_PAN_AADHAAR_SUCCESS,
    BUSINESS_DETAILS,
    BUSINESS_DETAILS_SUCCESS,
    OWNER_DETAILS,
    OWNER_DETAILS_SUCCESS,
    CLEAN_ONBOARDING_LOADER,
    SET_PIN,
    SET_PIN_SUCCESS,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS
  } from "../storeTypes";

  const INIT_STATE = {
    loader: false,
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      // case TOAST_STATUS: {
      //   return {
      //     ...state,
      //     toastLoading: !toastLoading,
      //   };
      // }
      case CLEAN_ONBOARDING_LOADER: {
        console.log("cleaning2")
        return {
          ...state,
          loader: false,
        };
      }
      case REFRESH_TOKEN: {
        return {
          ...state,
          loader: true,
        };
      }
      case REFRESH_TOKEN_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case SET_PIN: {
        return {
          ...state,
          loader: true,
        };
      }
      case SET_PIN_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case OWNER_DETAILS: {
        return {
          ...state,
          loader: true,
        };
      }
      case OWNER_DETAILS_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case BUSINESS_DETAILS: {
        return {
          ...state,
          loader: true,
        };
      }
      case BUSINESS_DETAILS_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case UPDATE_PAN_AADHAAR: {
        return {
          ...state,
          loader: true,
        };
      }
      case UPDATE_PAN_AADHAAR_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case GET_AADHAAR_OTP: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_AADHAAR_OTP_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case PAN_AUTH: {
        return {
          ...state,
          loader: true,
        };
      }
      case PAN_AUTH_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
  
      case DOWNLOAD_AADHAAR: {
        return {
          ...state,
          loader: true,
        };
      }
      case DOWNLOAD_AADHAAR_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      default: {
        return state;
      }
    }
  };
  