import { call, put, takeEvery } from "redux-saga/effects";
import {
  getFastagWalletBalanceApi,
  getFastagWalletTransactionApi,
  transferFastagTransferAmountApi,
  getFastagTransactionHistoryAPI,
  getVehicleDetailsByFleetIdApi,
  getFastagWalletReportApi,
  getVehicleDetailsApi,
  isReissueAvailableAPI,
  sendFastagOTPAPI,
  verifyFastagOTPAPI,
  fastagAddCustomerAPI,
  vehicleTagReissueAPI,
  registerYesbankApi,
  toggleAutorechargeApi,
  autorechargeMultipleApi,
  createOrderForFleetApi,
  idfcKycSendOtpApi,
  idfcKycVerifyOtpApi,
  idfcKycSaveImagesApi,
  driverVehicleHistoryApi,
  getDocumentApi,
  getCustomRequestApi,
  updateCustomRequestApi,
  deleteCustomRequestApi,
} from "../../Utils/Apis";
import {
  getFastagWalletBalanceSuccess,
  getFastagWalletTransactionSuccess,
  transferFastagTransferAmountSuccess,
  getFastagTransactionHistorySuccess,
  getVehicleDetailsByFleetIdSuccess,
  getFastagWalletStatementSuccess,
  getVehicleDetailsSuccess,
  reissueAvailableActionSuccess,
  sendFastagOTPActionSuccess,
  verifyFastagOTPActionSuccess,
  fastagAddCustomerActionSuccess,
  vehicleTagReissueActionSuccess,
  registerYesbankActionSuccess,
  autoRechargeToggleActionSuccess,
  autoRechargeMultipleActionSuccess,
  createOrderFleetActionSuccess,
  idfcKycSendOtpActionSuccess,
  idfcKycVerifyOtpActionSuccess,
  idfcKycSaveImageActionSuccess,
  getDriverVehicleHistorySuccess,
  getDocumentSuccess,
  updateCustomRequestSuccess,
  deleteCustomRequestSuccess,
  getCustomRequestSuccess,
} from "../Actions/fastagAction";
import {
  GET_FASTAG_WALLET_BALANCE,
  GET_FASTAG_WALLET_TRANSACTION,
  TRANSFER_FASTAG_AMOUNT,
  GET_FASTAG_TRANSACTION_HISTORY,
  GET_VEHICLE_DETAILS_BY_VEHICLE_ID,
  GENERATE_FASTAG_HISTORY_STATEMENT,
  GET_VEHICLE_DETAILS,
  REISSUE_AVAILABLE,
  SEND_FASTAG_OTP,
  VERIFY_FASTAG_OTP,
  FASTAG_ADD_CUSTOMER,
  TAG_REISSUE,
  REGISTER_YESBANK,
  TOGGLE_AUTO_RECHARGE,
  AUTO_RECHARGE_MULTIPLE,
  CREATE_ORDER_FOR_FLEET,
  IDFC_KYC_SEND_OTP,
  IDFC_KYC_VERIFY_OTP,
  IDFC_KYC_SAVE_IMAGE,
  DRIVER_VEHICLE_HISTORY,
  GET_TOOL,
  GET_DOCUMENT,
  GET_CUSTOM_REQUEST,
  UPDATE_CUSTOM_REQUEST,
  DELETE_CUSTOM_REQUEST,
} from "../storeTypes";
import { processErrorAndRespond } from "../../Utils/functions";
import { toastStatus } from "../Actions/appActions";

function* getFastagWalletBalanceSaga(action) {
  try {
    const response = yield call(getFastagWalletBalanceApi, action.payload);
    if (response.data.type == "success") {
      yield put(getFastagWalletBalanceSuccess(response?.data?.data));
    }
  } catch (error) {
    //yield put(fastagError());
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('get fasttag balance Error', error.response.data.message);
  }
}
function* getFastagWalletTransactionSaga(action) {
  try {
    const response = yield call(getFastagWalletTransactionApi, action.payload);
    console.log("RCBFANHERE", response?.data);
    if (response.data.type == "success") {
      yield put(getFastagWalletTransactionSuccess(response?.data?.data));
    }
  } catch (error) {
    //yield put(fastagError());
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('get fasttag Transaction Error', error.response.data.message);
  }
}
function* transferFastagTransferAmountSaga(action) {
  try {
    const response = yield call(
      transferFastagTransferAmountApi,
      action.payload
    );
    console.log("transferFastagTransferAmountSaga **", response.data);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(transferFastagTransferAmountSuccess());
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Transfer to fastag wallet was successful",
          status: true,
        })
      );
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response.data.message}`,
        status: false,
      })
    );
    // alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getFastagTransactionHistorySaga(action) {
  try {
    const response = yield call(
      getFastagTransactionHistoryAPI,
      action.fleetId,
      action.payload
    );
    if (response?.data?.type == "success") {
      yield put(getFastagTransactionHistorySuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error?.response?.data);
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.SHORT);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getVehicleDetailsByFleetIdSaga(action) {
  try {
    const response = yield call(getVehicleDetailsByFleetIdApi, action.payload);
    if (response?.data?.type == "success") {
      yield put(getVehicleDetailsByFleetIdSuccess(response?.data?.data));
    }
  } catch (error) {
    //yield put(fastagError());
    console.log("Error.Response:- ", error?.response?.data);
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.SHORT);
  }
}

function* getVehicleDetailsSaga(action) {
  try {
    const response = yield call(getVehicleDetailsApi, action.payload);
    if (response?.data?.type == "success") {
      yield put(getVehicleDetailsSuccess(response?.data?.data));
    }
  } catch (error) {
    //yield put(fastagError());
    console.log("Error.Response:- ", error?.response?.data);
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.SHORT);
  }
}

function* getFastagWalletAccountSaga(action) {
  try {
    const response = yield call(getFastagWalletReportApi, action.payload);
    console.log("saga-> fleet-- ", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(getFastagWalletStatementSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* isReissueAvailableSaga(action) {
  try {
    const response = yield call(isReissueAvailableAPI, action.payload);
    if (response.data.type == "success") {
      yield put(reissueAvailableActionSuccess(response?.data?.data));
      console.log("heluuuuuuuuuuu", response);
    }
  } catch (error) {
    //yield put(fastagError());
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('get fasttag balance Error', error.response.data.message);
  }
}

function* sendFastagOTPSaga(action) {
  try {
    const response = yield call(sendFastagOTPAPI, action.payload);
    if (response.data.type == "success") {
      yield put(sendFastagOTPActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //yield put(fastagError());
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('get fasttag balance Error', error.response.data.message);
  }
}

function* verifyFastagOTPSaga(action) {
  try {
    const response = yield call(verifyFastagOTPAPI, action.payload);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(verifyFastagOTPActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //yield put(fastagError());
    if (error.response.data.error.status !== undefined) {
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Error : ${error.response.data.message}`,
          status: false,
        })
      );
    } else {
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Error : ${error.response.data.error}`,
          status: false,
        })
      );
    }

    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('get fasttag balance Error', error.response.data.message);
  }
}

function* fastagAddCustomerSaga(action) {
  try {
    const response = yield call(fastagAddCustomerAPI, action.payload);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(fastagAddCustomerActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //yield put(fastagError());
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response.data.message}`,
        status: false,
      })
    );
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('get fasttag balance Error', error.response.data.message);
  }
}

function* vehicleTagReissueSaga(action) {
  try {
    const response = yield call(vehicleTagReissueAPI, action.payload);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(vehicleTagReissueActionSuccess(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "FASTag re-issued successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //yield put(fastagError());
    action.callback(error.response);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response.data.message}`,
        status: false,
      })
    );
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('get fasttag balance Error', error.response.data.message);
  }
}

function* registerYesbankSaga(action) {
  try {
    const response = yield call(registerYesbankApi, action.payload);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(registerYesbankActionSuccess(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "KYC for Yesbank was successful",
          status: true,
        })
      );
    }
  } catch (error) {
    //yield put(fastagError());
    action.callback(error.response);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response.data.message}`,
        status: false,
      })
    );
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('get fasttag balance Error', error.response.data.message);
  }
}

function* autoRechargeToggleSaga(action) {
  try {
    const response = yield call(toggleAutorechargeApi, action.payload);
    console.log("msssssssss0", response);
    action.callBack(response);
    if (response.data.type == "success") {
      //action.callback(response);
      yield put(autoRechargeToggleActionSuccess(response?.data));
      //console.log("msssssssss1", action.payload.minAmount);
      //console.log("msssssssss2", response?.data?.data);
      // yield put(
      //   toastStatus({
      //     toastStatus: true,
      //     message: response?.data?.message,
      //     //message: action.payload.minAmount==null?"Status of Auto Recharge was successfully updated":"Auto Recharge limit was updated successfully",
      //     status: true,
      //   })
      // );
    }
  } catch (error) {
    action.callBack(error.response);
    //yield put(fastagError());
    //action.callback(error.response);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response?.data?.message}`,
        status: false,
      })
    );
    console.log("Error.Response:- ", error.response);
    //Alert.alert('get fasttag balance Error', error.response.data.message);
  }
}

function* autoRechargeMultipleSaga(action) {
  try {
    const response = yield call(autorechargeMultipleApi, action.payload);
    console.log("msssssssss0", response);
    if (response.data.type == "success") {
      //action.callback(response);
      yield put(autoRechargeMultipleActionSuccess(response?.data));
      //console.log("msssssssss1", action.payload.minAmount);
      //console.log("msssssssss2", response?.data?.data);
      yield put(
        toastStatus({
          toastStatus: true,
          message: response?.data?.message,
          //message: action.payload.minAmount==null?"Status of Auto Recharge was successfully updated":"Auto Recharge limit was updated successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //yield put(fastagError());
    //action.callback(error.response);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response?.data?.message}`,
        status: false,
      })
    );
    console.log("Error.Response:- ", error.response);
    //Alert.alert('get fasttag balance Error', error.response.data.message);
  }
}
function* createOrderForFleetSaga(action) {
  try {
    const response = yield call(createOrderForFleetApi, action.payload);
    action?.callBack(response);
    if (response.data.type == "success") {
      yield put(createOrderFleetActionSuccess(response?.data));
    }
  } catch (error) {
    action?.callBack(error?.response);
  }
}
function* idfcKycSendOtpSaga(action) {
  try {
    const response = yield call(idfcKycSendOtpApi, action.payload);
    action?.callBack(response);
    if (response.data.type == "success") {
      yield put(idfcKycSendOtpActionSuccess(response?.data));
    }
  } catch (error) {
    action?.callBack(error?.response);
  }
}
function* idfcKycVerifyOtpSaga(action) {
  try {
    const response = yield call(idfcKycVerifyOtpApi, action.payload);
    action?.callBack(response);
    if (response.data.type == "success") {
      yield put(idfcKycVerifyOtpActionSuccess(response?.data));
    }
  } catch (error) {
    action?.callBack(error?.response);
  }
}
function* idfcKycSaveImagesSaga(action) {
  try {
    const response = yield call(idfcKycSaveImagesApi, action.payload);
    action?.callBack(response);
    if (response.data.type == "success") {
      yield put(idfcKycSaveImageActionSuccess(response?.data));
    }
  } catch (error) {
    action?.callBack(error?.response);
  }
}

function* driverVehicleHistory(action) {
  try {
    const response = yield call(driverVehicleHistoryApi, action?.payload);
    console.log('getPaymentRequestSaga response ', response);
    if (response?.status == 200) {
      yield put(getDriverVehicleHistorySuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      'getPaymentRequestSaga error .Response:- ',
      error?.response?.data,
    );
    // yield put(getPaymentRequestSuccess([]))
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response.data.message}`,
        status: false,
      })
    );
    
  }
}

function* getDocument(action) {
  try {
    const response = yield call(getDocumentApi, action?.payload);
    console.log('getDocumentApi response ', response);
    if (response?.status == 200) {
      yield put(getDocumentSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      'getPaymentRequestSaga error .Response:- ',
      error?.response?.data,
    );
    // yield put(getPaymentRequestSuccess([]))
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response.data.message}`,
        status: false,
      })
    );
    
  }
}

function* getCustomRequest(action) {
  try {
    const response = yield call(getCustomRequestApi, action?.payload);
    console.log('RESPONSEGETALLREQUEST123', response?.data);
    if (response?.data?.type == 'success') {
      yield put(getCustomRequestSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      'getPaymentRequestSaga error .Response:- ',
      error?.response?.data,
    );
    // yield put(getPaymentRequestSuccess([]))
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response.data.message}`,
        status: false,
      })
    );
    
  }
}

function* updateCustomRequest(action) {
  try {
    const response = yield call(updateCustomRequestApi, action?.payload);
    console.log('updateCustomRequestupdateCustomRequest', response?.data);
    if (response?.data?.type == 'success') {
      yield put(updateCustomRequestSuccess(response?.data?.data));
      action.callBack(response?.data);
    }
  } catch (error) {
    console.log(
      'getPaymentRequestSaga error .Response:- ',
      error?.response?.data,
    );
    // yield put(getPaymentRequestSuccess([]))
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response.data.message}`,
        status: false,
      })
    );
    
  }
}
function* deleteRequest(action) {
  try {
    const response = yield call(deleteCustomRequestApi, action?.payload);
    console.log('deleteCustomRequestApideleteCustomRequestApi', response?.data);
    if (response?.data?.type == 'success') {
      yield put(deleteCustomRequestSuccess(response?.data?.data));
      action.callBack(response?.data);
    }
  } catch (error) {
    console.log(
      'getPaymentRequestSaga error .Response:- ',
      error?.response?.data,
    );
    // yield put(getPaymentRequestSuccess([]))
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error.response.data.message}`,
        status: false,
      })
    );
    
  }
}

export function* watchFastagSagas() {
  yield takeEvery(GET_DOCUMENT, getDocument);
  yield takeEvery(GET_FASTAG_WALLET_BALANCE, getFastagWalletBalanceSaga);
  yield takeEvery(
    GET_FASTAG_WALLET_TRANSACTION,
    getFastagWalletTransactionSaga
  );
  yield takeEvery(TRANSFER_FASTAG_AMOUNT, transferFastagTransferAmountSaga);
  yield takeEvery(
    GET_FASTAG_TRANSACTION_HISTORY,
    getFastagTransactionHistorySaga
  );
  yield takeEvery(
    GET_VEHICLE_DETAILS_BY_VEHICLE_ID,
    getVehicleDetailsByFleetIdSaga
  );
  yield takeEvery(
    GENERATE_FASTAG_HISTORY_STATEMENT,
    getFastagWalletAccountSaga
  );
  yield takeEvery(GET_VEHICLE_DETAILS, getVehicleDetailsSaga);
  yield takeEvery(REISSUE_AVAILABLE, isReissueAvailableSaga);
  yield takeEvery(SEND_FASTAG_OTP, sendFastagOTPSaga);
  yield takeEvery(VERIFY_FASTAG_OTP, verifyFastagOTPSaga);
  yield takeEvery(FASTAG_ADD_CUSTOMER, fastagAddCustomerSaga);
  yield takeEvery(TAG_REISSUE, vehicleTagReissueSaga);
  yield takeEvery(REGISTER_YESBANK, registerYesbankSaga);
  yield takeEvery(TOGGLE_AUTO_RECHARGE, autoRechargeToggleSaga);
  yield takeEvery(AUTO_RECHARGE_MULTIPLE, autoRechargeMultipleSaga);
  yield takeEvery(CREATE_ORDER_FOR_FLEET, createOrderForFleetSaga);
  yield takeEvery(IDFC_KYC_SEND_OTP, idfcKycSendOtpSaga);
  yield takeEvery(IDFC_KYC_VERIFY_OTP, idfcKycVerifyOtpSaga);
  yield takeEvery(IDFC_KYC_SAVE_IMAGE, idfcKycSaveImagesSaga);
  yield takeEvery(DRIVER_VEHICLE_HISTORY, driverVehicleHistory);
  yield takeEvery(GET_CUSTOM_REQUEST, getCustomRequest);
    yield takeEvery(UPDATE_CUSTOM_REQUEST, updateCustomRequest);
    yield takeEvery(DELETE_CUSTOM_REQUEST, deleteRequest);
}
