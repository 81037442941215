import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../../../../../Icons/Icons";
import PinInput from "react-pin-input";
import { driverTransferToBeneficiaryAction } from "../../../../../../../../services/Store/Actions/omcActions";
import { useParams } from "react-router-dom";
import { sendAnalitics } from "../../../../../../../../services/Store/Actions/appActions";



function BankTransferData(props) {
  
    const dispatch = useDispatch();
  const [status, setStatus]= useState(0);
  const { loader } = useSelector((state) => state?.omc);
  console.log("button status", loader);
  const driverBeneficiaryList=useSelector((state)=>state?.omc?.driverBeneficiaryList);
  const beneficiaryId=driverBeneficiaryList?.rows?.[0]?.beneficiaryId;
  const validationSchema = Yup.object().shape({
    amount: Yup.number().typeError("Only Numbers are allowed")
    .required("Required")
    .min(1, "Amount should be greater than 1"),
    // driverName:Yup.string().required("Required"),
    pin: Yup.string()
    .required("Required")
    .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
  });


  const {id}= useParams();
  return (
    <Formik

        enableReinitialize
        initialValues={{
        amount:"",
        remark: "",
        pin:""
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
            dispatch(
                sendAnalitics("BankTransfer_DC_API_Web", {
                amt: values.amount,
                pin: values.pin,
                remark:values.remark,
                beneficiaryId:String(beneficiaryId),
                DriverUserId:id
                })
            );
            dispatch(driverTransferToBeneficiaryAction(String(beneficiaryId), {
                "pin": values?.pin,
                "amount": values?.amount,
                "remark": values?.remark,
                "userId": id
            }, (res)=>{
                dispatch(
                    sendAnalitics("Success_BankTransfer_DC_Web", {
                    amt: values.amount,
                    pin: values.pin,
                    remark:values.remark,
                    beneficiaryId:String(beneficiaryId),
                    DriverUserId:id
                    })
                );
                if(res?.status==200){

                }
            }))
        }}
    >
        {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
        }) => (
        <Form onSubmit={handleSubmit} >
            <FormGroup>
            <div className="sub-title-1">
                <span class="circle-logo">1</span>
                <label className="sub-head-3 ">Amount</label>
            </div>
            <label for="html" className="label-body">
                ₹
            </label>
            <input
                type="text"
                id="amount"
                className={`input-body `}
                name="amount"
                onChange={handleChange("amount")}
                value={values.amount}
                onBlur={()=>{
                // dispatch(sendAnalitics("Amt_DC_MA_Web", {
                //   amt_MA_to_DriverCard:values.amount
                // }));
                handleBlur("amount");
                }}
                touched={touched.amount}
                errors={errors.amount}
                placeholder="Enter amount"
            />
            <div className="input-amount"></div>
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.amount && touched.amount ? (
                <div>*{errors.amount}</div>
                ) : null}
            </div>
            </FormGroup>
            <FormGroup>
            <div className="sub-title-1">
                <span class="circle-logo">2</span>
                <label className="sub-head-3 ">Remark</label>
            </div>
            <input
                type="text"
                id="remark"
                className={`input-body-3 `}
                name="remark"
                onChange={handleChange("remark")}
                value={values.remark}
                onBlur={()=>{
                // dispatch(sendAnalitics("remark_DC_MA_Web", {
                //   amt_MA_to_DriverCard:values.remark
                // }));
                handleBlur("remark");
                }}
                touched={touched.remark}
                errors={errors.remark}
                placeholder="Enter remark"
            />
            <div className="input-amount"></div>
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.remark && touched.remark ? (
                <div>*{errors.remark}</div>
                ) : null}
            </div>
            </FormGroup>
            <FormGroup>
            <div className="sub-title-1">
                <span class="circle-logo">3</span>
                <p className="sub-head-4 ">Enter 4-digit Security Pin</p>
            </div>
            <p className="sub-head-body">
            Security pin is needed to confirm payment
            </p>
            <PinInput
            length={4}
            initialValue=""
            secret
            onChange={handleChange("pin")}
            type="numeric"
            value={values.pin}
            onBlur={handleBlur("pin")}
            touched={touched.pin}
            errors={errors.pin}
            inputMode="number"
            style={{
                padding: "10px",
                marginLeft: "96px",
                marginTop: "20px",
            }}
            inputStyle={{
                border: "1px solid #A7A7A7",
                borderRadius: "8px",
                marginRight: "20px",
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
            {errors.pin && touched.pin ? (
                <div>*{errors.pin}</div>
            ) : null}
            </div>
            </FormGroup>
            <FormGroup style={{position:"absolute", bottom:"1rem"}}>
            <button
                type="submit"
                className="butt-4"
                style={{
                margin: "15% 0 0 0",
                background: loader ? "grey" : "#2e7c7b",
                }}
                disabled={loader ? true : false}
                onClick={() => {
                    dispatch(
                        sendAnalitics("BankTransfer_DC_Web", {
                        amt: values.amount,
                        pin: values.pin,
                        remark:values.remark,
                        beneficiaryId:String(beneficiaryId),
                        DriverUserId:id
                        })
                    );
                }}
            >
                {loader ? "Please Wait" : "Submit"}
            </button>
            </FormGroup>
            <div></div>
        </Form>
        )}
    </Formik>
  );
}

export default BankTransferData;
