import { call, put, takeEvery } from "redux-saga/effects";
import {
  addDeviceIdApi,
  autoFillCityStateApi,
  deleteDeviceIdApi,
  driverEditStatusApi,
  fleetEditStatusApi,
  getAllStatepApi,
  getCityByStateIDApi,
  getDeviceIdDataApi,
  getSignedUrlApi,
  storeMediaFileApi,
  updateProfileAPI,
  getPincodeApi
} from "../../Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import {
  addDeviceIdActionSuccess,
  autoFillCityStateActionSuccess,
  deleteDeviceIdActionSuccess,
  driverEditStatusActionSuccess,
  fleetEditStatusActionSuccess,
  getAllStateSuccess,
  getCityByStateIDSuccess,
  getDeviceIdDataActionSuccess,
  getSignedUrlApiActionSuccess,
  storeMediaSuccess,
  toastStatus,
  toastStatusSuccess,
  updateProfileSuccessAction,
  getPincodeSuccess
} from "../Actions/appActions";
import {
  ADD_DEVICE_ID,
  AUTOFILL_CITY_STATE,
  DELETE_DEVICE_ID,
  DRIVER_EDIT_STATUS,
  FLEET_EDIT_STATUS,
  GET_ALL_STATE,
  GET_CITY_BY_STATE_ID,
  GET_DATA_FROM_ID,
  GET_SIGNED_URL,
  SEND_ANALITICS,
  STORE_MEDIA_FILE,
  TOAST_STATUS,
  UPDATE_PROFILE,
  GET_PINCODE
} from "../storeTypes";
import { Mixpanel } from "../../../Mixpanel";

function* getAllStateSaga() {
  try {
    const response = yield call(getAllStatepApi);
    if (response?.data?.type == "success") {
      yield put(getAllStateSuccess(response.data?.data?.states));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getCityByStateIDSaga(action) {
  try {
    const response = yield call(getCityByStateIDApi, action.id);
    if (response?.data?.type == "success") {
      yield put(getCityByStateIDSuccess(response.data?.data?.city));
    }
  } catch (error) {
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* storeMediaFileSaga(action) {
  try {
    console.log("upcoming", action.payload);
    const response = yield call(storeMediaFileApi, action.payload);
    console.log("after api 1", response);
    if (response.type == "success") {
      console.log("after api 2", response);
      yield put(storeMediaSuccess(response));
      action.callBack(response.data);
      //ToastAndroid.show('Your media has been uploaded', ToastAndroid.SHORT);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.SHORT);
    console.log("Error :- ", error?.response?.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* toastStatusSaga(action) {
  try {
    console.log("toast saga", action.payload);
    //console.log("yahan", action.payload);
    // const response = yield call(storeMediaFileApi, action.payload);
    // if (response.type == "success") {
    yield put(toastStatusSuccess(action.payload));
    // action.callBack(response.data);
    //ToastAndroid.show('Your media has been uploaded', ToastAndroid.SHORT);
  } catch (error) {
    // if (error?.response?.status == 400) {
    //   action.callBack({
    //     message: error?.response?.data.message,
    //   });
    // } else {
    //   action.callBack(error?.response?.data);
    // }
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.SHORT);
    console.log("Error :- ", error?.response?.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* updateProfileSaga(action) {
  try {
    const response = yield call(updateProfileAPI, action.payload);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(updateProfileSuccessAction(response.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Profile details updated successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    console.log("Error :- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* autoFillCityStateSaga(action) {
  try {
    const response = yield call(autoFillCityStateApi, action?.payload);
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(autoFillCityStateActionSuccess(response?.data?.data));
    }
  } catch (error) {
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    //console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log(
      "API Error in m2p transactions:-  ",
      processErrorAndRespond(error)
    );
  }
}

function* addDeviceIdSaga(action) {
  try {
    const response = yield call(addDeviceIdApi, action?.payload);
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      //action.callback(response);
      yield put(addDeviceIdActionSuccess(response?.data?.data));
    }
  } catch (error) {
    // yield put(
    //   toastStatus({
    //     toastStatus: true,
    //     message: `Error : ${error?.response?.data.message}`,
    //     status: false,
    //   })
    // );
    console.log(
      "API Error in notifications:-  ",
      processErrorAndRespond(error)
    );
  }
}

function* deleteDeviceIdSaga(action) {
  try {
    console.log("saga delete action", action?.id);
    const response = yield call(deleteDeviceIdApi, action?.id);
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      //action.callback(response);
      yield put(deleteDeviceIdActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "API Error in notifications:-  ",
      processErrorAndRespond(error)
    );
  }
}

function* getDeviceIdDataSaga(action) {
  try {
    const response = yield call(getDeviceIdDataApi);
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      //action.callback(response);
      yield put(getDeviceIdDataActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "API Error in notifications:-  ",
      processErrorAndRespond(error)
    );
  }
}

function* sendAnaliticsSaga(action) {
  try {
    if (action?.payload) {
      Mixpanel.track(action.title, action.payload);
    } else {
      Mixpanel.track(action.title);
    }
  } catch (error) {
    console.log("sendAnaliticsSaga Error :- ", error);
  }
}
function* getSignedUrlSaga(action) {
  try {
    const response = yield call(getSignedUrlApi, action.payload);
    console.log("saga-> getSignedUrlSaga-- ", response);
    action.callBack(response);
    if (response?.data?.type == "success") {
      //action.callback(response);
      yield put(getSignedUrlApiActionSuccess());
    }
  } catch (error) {
    action.callBack(error.response);
    console.log(
      "API Error in notifications:-  ",
      processErrorAndRespond(error)
    );
  }
}
function* fleetEditStatusSaga(action) {
  try {
    const response = yield call(fleetEditStatusApi, action.payload);
    console.log("saga-> fleetEditStatusSaga-- ", response);
    action.callBack(response);
    if (response?.data?.type == "success") {
      //action.callback(response);
      yield put(fleetEditStatusActionSuccess(response?.data));
    }
  } catch (error) {
    action.callBack(error.response);
    console.log(
      "API Error in notifications:-  ",
      processErrorAndRespond(error)
    );
  }
}
function* driverEditStatusSaga(action) {
  try {
    const response = yield call(driverEditStatusApi, action.payload);
    console.log("saga-> getSignedUrlSaga-- ", response);
    action.callBack(response);
    if (response?.data?.type == "success") {
      //action.callback(response);
      yield put(driverEditStatusActionSuccess(response?.data));
    }
  } catch (error) {
    action.callBack(error.response);
    console.log(
      "API Error in notifications:-  ",
      processErrorAndRespond(error)
    );
  }
}

function* getPincodeSaga(action) {
  try {
    const response = yield call(getPincodeApi, action.payload);
    console.log("saga-> getSignedUrlSaga-- ", response);
    if (response?.data?.type == "success") {
      //action.callback(response);
      yield put(getPincodeSuccess(response?.data));
    }
  } catch (error) {
    action.callBack(error.response);
    console.log(
      "API Error in notifications:-  ",
      processErrorAndRespond(error)
    );
  }
}

export function* appSaga() {
  yield takeEvery(GET_ALL_STATE, getAllStateSaga);
  yield takeEvery(GET_CITY_BY_STATE_ID, getCityByStateIDSaga);
  yield takeEvery(STORE_MEDIA_FILE, storeMediaFileSaga);
  yield takeEvery(TOAST_STATUS, toastStatusSaga);
  yield takeEvery(UPDATE_PROFILE, updateProfileSaga);
  yield takeEvery(AUTOFILL_CITY_STATE, autoFillCityStateSaga);
  yield takeEvery(SEND_ANALITICS, sendAnaliticsSaga);
  yield takeEvery(ADD_DEVICE_ID, addDeviceIdSaga);
  yield takeEvery(GET_DATA_FROM_ID, getDeviceIdDataSaga);
  yield takeEvery(DELETE_DEVICE_ID, deleteDeviceIdSaga);
  yield takeEvery(GET_SIGNED_URL, getSignedUrlSaga);
  yield takeEvery(FLEET_EDIT_STATUS, fleetEditStatusSaga);
  yield takeEvery(DRIVER_EDIT_STATUS, driverEditStatusSaga);
  yield takeEvery(GET_PINCODE, getPincodeSaga);

}
