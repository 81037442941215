import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { getCustomerAction } from "../../../../services/Store/Actions/customerAction";
import EditFreight from "../EditFreight";
import { BallTriangle } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import {
  getLRList,
  updateTripInLrAction,
} from "../../../../services/Store/Actions/TripsAction";
import CreateLRModal from "../../../LoadReceipt/Components/CreateLRModal";
import Toast from "../../../subComponents/toast";
function AddLr(props) {
  const [page, setPage] = useState(0);
  const {
    toggle,
    freight,
    setFreight,
    tripId,
    onSubmit,
    onSkip,
    fleetId,
    location,
    trip,
    onSubmit2,
    tripData
  } = props;
  const dispatch = useDispatch();
  const [editFreight, setEditFreight] = useState(false);
  const [offset, setOffset] = useState(0);
  const [toastModal, setToastModal] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastStatus, setToastStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const createLR = () => setModal(!modal);
  let { freightList, freightListCount, tripExpense } = useSelector(
    (store) => store?.trip
  );
  let totalExpenseCount = freightListCount === undefined ? 0 : freightListCount;
  let pageCount =
    totalExpenseCount % 10 === 0
      ? totalExpenseCount / 10
      : Math.floor(totalExpenseCount / 10) + 1;
  const toggleModalEditFreight = () => {
    setEditFreight(!editFreight);
  };
  const handleCloseFreight = () => {
    setFreight(!freight);
  };
  let columns;
  {
    freightList.map((i, k) => {
      console.log(i, k);
      columns = [
        {
          name: "LR No",
          selector: (i) => i.lrNo,
        },
        {
          name: "Vehicle No",
          selector: (i) => i?.Fleet?.regNumber,
        },
        {
          name: "From",
          selector: (i) => `${i?.consignorName}, ${i?.fromAddress?.cityName}`,
        },
        {
          name: "To",
          selector: (i) => `${i?.consigneeName}, ${i?.toAddress?.cityName}`,
        },
        {
          name: "Amount",
          selector: (i) => i?.freightAmount,
        },
        {
          name: "Weight",
          selector: (i) => i?.weight,
        },
        {
          name: "Payor",
          selector: (i) =>
            i?.customerId == i?.transportorId
              ? i?.transportorName
              : i?.customerId == i?.consignorId
              ? i?.consignorName
              : i?.consigneeName,
        },
      ];
    });
  }
  console.log("freisghtdddd", tripData);
  useEffect(() => {
    dispatch(
      getLRList({
        offset: 0,
        limit: 10,
        fleetId: fleetId,
        trip: 0,
      })
    );
  }, []);
  const handlePageClick = (event) => {
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    console.log("event heree", event.selected);
    dispatch(
      getLRList({
        offset: newOffset,
        limit: 10,
        fleetId: fleetId,
        // isActive: "",
        // fleetId: fleetIdTemp,
        // driverId: driverIdTemp,
        // tripNo: tripNoTemp,
        // startTime: moment(startDate).format("MM/DD/YYYY"),
        // endTime: moment(endDate).format("MM/DD/YYYY"),
        // source: searchSource,
        // destination: searchDestination,
      })
    );
    setOffset(newOffset);
  };
  return (
    <div>
      <Modal
        isOpen={freight}
        onHide={handleCloseFreight}
        className="modal-dialog-centered model-width-2"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody className="transactionModalBody" style={{ margin: "20px" }}>
          <div className="PGCollectionBox">
            {/* <div className="closeEffectRow close-btn-for-transaction">
              <div className="closeEffect" onClick={toggle}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
            </div> */}
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: "20px" }}
            >
              <div className="d-flex">
                <div
                  className="PGCollectiontText"
                  style={{ marginLeft: "0px" }}
                >
                 { tripData ? `${tripData?.Fleet?.regNumber} - Freight Details` : "Freight Details"}
                </div>
                <button
                  className="btn-edit-details "
                  style={{ marginLeft: "20px" }}
                  onClick={createLR}
                >
                  Create New Lr
                </button>
              </div>
              {location == "/trips/" ? (
                <div className="d-flex justify-content-right">
                  <div
                    className="Medium text2  padding16L padding16R greenFont"
                    onClick={() => {
                      onSkip();
                    }}
                    style={{
                      borderWidth: 1,
                      borderColor: "#2e7c7b",
                      borderStyle: "solid",
                      borderRadius: 12,
                      alignSelf: "center",
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 10,
                      paddingBottom: 10,
                      cursor: "pointer",
                    }}
                  >
                    Skip
                  </div>
                </div>
              ) : (
                <div
                  className="closeEffect"
                  onClick={toggle}
                  style={{ cursor: "pointer" }}
                >
                  <SvgImage
                    classname="close-btn-text"
                    style={{ width: "10%", height: "10%" }}
                    name="CloseButtonLogo"
                    width="20%"
                    height="20%"
                  />
                </div>
              )}
            </div>

            {freightListCount == undefined ||
            (freightListCount > 0 && freightList?.length == 0) ? (
              <div
                className="mt-2 boxContainer"
                style={{
                  padding: 70,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BallTriangle
                  height={70}
                  width={70}
                  radius={5}
                  color="#2e7c7b"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={freightList}
                // fixedHeader
                fixedHeaderScrollHeight="450px"
                selectableRows
                selectableRowsNoSelectAll
                selectableRowsHighlight={true}
                highlightOnHover
                // pagination
                onRowClicked={(row) => {
                  // onSelect(row);
                }}
                onSelectedRowsChange={(selected) => {
                  if (selected.selectedCount > 0) {
                    setSelectedRow(selected.selectedRows);
                  }
                  if (selected.selectedCount == 0) {
                    setSelectedRow([]);
                  }
                }}
                selectableRowDisabled={(row) => {
                  if (row?.tripId) return true;
                  console.log("selectedRow?.length", selectedRow?.length);
                  if (tripId) return row.fleetId != fleetId;
                  if (selectedRow == 0) return false;
                  return row.fleetId != selectedRow?.[0]?.fleetId;
                }}
              />
            )}

            {/* { */}
            <div className="reactPagination leftAlign">
              <ReactPaginate
                breakLabel="..."
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn-edit-details "
              onClick={() => {
                if (location == "/trips/") {
                  onSubmit(selectedRow);
                } else {
                  dispatch(
                    updateTripInLrAction(
                      {
                        selectedLr: selectedRow,
                        status: 1,
                        tripId: tripId,
                      },
                      (res) => {
                        if (res?.status == 200) {
                          setToastMessage("Lorrie Recipt Added Successfully");
                          setToastModal(true);
                          setToastStatus(true);
                        } else {
                          setToastMessage(
                            res?.data?.message || "Error in Lorrie Recipt"
                          );
                          setToastModal(true);
                          setToastStatus(false);
                        }
                      }
                    )
                  );
                }
              }}
            >
              Submit
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* <EditFreight
        toggle={toggleModalEditFreight}
        freight={editFreight}
        page={page}
        tripId={tripId}
        // setFreight={setFreight}
      /> */}
      {modal ? (
        <CreateLRModal toggle={createLR} modal={modal} setModal={setModal} trip={trip ? trip : null} onSubmit = {onSubmit2} tripId={tripId} tripData={tripData}/>
      ) : null}
      {toastModal ? (
        <Toast
          success={toastStatus}
          message={toastMessage}
          toggleModal={() => {
            setToastModal(false);
            // dispatch(
            //   getLRList({
            //     offset: 0,
            //     limit: 10,
            //     fleetId: fleetId,
            //   })
            // );
          }}
          isOpen={toastModal}
          name="trips"
          handleClose={() => {
            handleCloseFreight();
            setToastModal(false);
            console.log("sdasdasdasd");
            // dispatch(
            //   getLRList({
            //     offset: 0,
            //     limit: 10,
            //     fleetId: fleetId,
            //   })
            // );
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default AddLr;
