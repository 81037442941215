import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import PinInput from "react-pin-input";
import SvgImage from "../../Icons/Icons";
import { Formik } from "formik";
import { useDispatch,useSelector } from "react-redux";
import * as Yup from "yup";
import { bankDirectTransfer } from "../../../services/Store/Actions/beneficiaryAction";
import { sendAnalitics, toastStatus } from "../../../services/Store/Actions/appActions";


const DirectTransfer=(props)=>{

    const {
        open,
        toggleModal,
        title,
        handleClick,
        handleClose,
        btn,
        ownerLogipeBalance,
        handleNewProceed,
        newProceed, setNewProceed,
        setEnterButton, enterButton
      } = props;
    const { loader } = useSelector((state) => state?.beneficiary);
    const [formPart1,setFormPart1]=useState({});
    const dispatch = useDispatch();

    const validationSchema1 = Yup.object().shape({
      fullName: Yup.string().required("Required"),
      acNo: Yup.number().typeError("Only Numbers are allowed").required("Required"),
      bankAccountNumber: Yup.number().typeError("Only Numbers are allowed").oneOf([Yup.ref('acNo'), null], "Does not match with above account number").required("Required"),
      ifsc: Yup.string().required("Required"),
    });

    const validationSchema2 = Yup.object().shape({
      amount: Yup.number().typeError("Only Numbers are allowed")
      .required("Required")
      .min(1, "Amount should be greater than 1"),
    pin: Yup.string()
      .required("Required")
      .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
    });

    useEffect (()=>{
      console.log("this is enterbutton ", enterButton);
      dispatch(sendAnalitics("Direct_Bene_Trns_BA_MA_Web"));
    },[])
    
    return (
      enterButton=== 0 ?
      (
        <Formik
            enableReinitialize
            validationSchema={validationSchema1}
            initialValues={{
              fullName: "",
              acNo: "",
              bankAccountNumber: "",
              ifsc: "",
            }}
            onSubmit={(values, actions) => {
              console.log("values onSubmit", values);
              setFormPart1(values);
              handleNewProceed(1, values.fullName);
                  // console.log('resresresres', res);

                
             
              // console.log("value +", driverUserId);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">1</span>
                        <p className="sub-head-3">Bank Account Number</p>
                      </div>

                      <label for="html" className="label-body"></label>
                      <input
                        type="text"
                        id="acNo"
                        className="input-body-1"
                        name="acNo"
                        onChange={handleChange("acNo")}
                        value={values.acNo}
                        onBlur={handleBlur("acNo")}
                        touched={touched.acNo}
                        errors={errors.acNo}
                        placeholder="Enter account number"
                        minLength="8"
                        maxlength = "16"
                      ></input>
                      <div className="input-amount"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.acNo && touched.acNo ? (
                          <div>*{errors.acNo}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">2</span>
                        <p className="sub-head-3">
                          Re-enter Bank Account Number
                        </p>
                      </div>

                      <label for="html" className="label-body"></label>
                      <input
                        type="text"
                        id="bankAccountNumber"
                        className="input-body-1"
                        name="bankAccountNumber"
                        onChange={handleChange("bankAccountNumber")}
                        value={values.bankAccountNumber}
                        onBlur={handleBlur("bankAccountNumber")}
                        touched={touched.bankAccountNumber}
                        errors={errors.bankAccountNumber}
                        placeholder="Re-enter account number"
                        maxlength = "16"
                        minLength="8"
                      ></input>
                      <div className="input-amount"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.bankAccountNumber &&
                        touched.bankAccountNumber ? (
                          <div>*{errors.bankAccountNumber}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">3</span>
                        <p className="sub-head-3">Bank IFSC</p>
                      </div>

                      <label for="html" className="label-body"></label>
                      <input
                        type="text"
                        id="ifsc"
                        className="input-body-1"
                        name="ifsc"
                        onChange={handleChange("ifsc")}
                        value={values.ifsc}
                        onBlur={handleBlur("ifsc")}
                        touched={touched.ifsc}
                        errors={errors.ifsc}
                        maxLength="11"
                        minLength="11"
                        placeholder="Enter Bank IFSC"
                      ></input>
                      <div className="input-amount"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.ifsc && touched.ifsc ? (
                          <div>*{errors.ifsc}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">4</span>
                        <p className="sub-head-3">Account Holder Name</p>
                      </div>

                      <label for="html" className="label-body"></label>
                      <input
                        type="text"
                        id="fullName"
                        className="input-body-1"
                        name="fullName"
                        onChange={handleChange("fullName")}
                        value={values.fullName}
                        onBlur={handleBlur("fullName")}
                        touched={touched.fullName}
                        errors={errors.fullName}
                        placeholder="Enter account holder name"
                      ></input>
                      <div className="input-amount"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.fullName && touched.fullName ? (
                          <div>*{errors.fullName}</div>
                        ) : null}
                      </div>
                      <button
                        className="butt4"
                        type="submit"
                      >
                        Proceed
                      </button>
                    </FormGroup>
                  </Form>
                  )}
                </Formik>
      ):
      (

        <Formik
        enableReinitialize
        validationSchema={validationSchema2}
        initialValues={{
          amount: "",
          remarks: "",
          pin: "",
        }}
        onSubmit={(values, actions) => {
          console.log("values onSubmit", values);
          dispatch(
            sendAnalitics("Direct_Bene_Trns_from_MA_API_Web", {
              directTrxn_amount: values.amount,
              remarks: values.remarks,
            })
          );
          dispatch(
            bankDirectTransfer({
              amount:values.amount,
              remarks: values.remarks,
          pin: values.pin,
          fullName: formPart1.fullName,
              acNo: formPart1.acNo,
              bankAccountNumber: formPart1.bankAccountNumber,
              ifsc: formPart1.ifsc,
            }, res => {
              actions.setSubmitting(false);
              console.log("bankDirectTransfer", res);
              if (res?.data?.type == 'success') {
                handleNewProceed(0,"--");
                toggleModal();
                dispatch(
                    sendAnalitics("Success_Direct_Bene_Trns_from_MA_Web", {
                      directTrxn_amount: values.amount,
                      remarks: values.remarks,
                    })
                  );
                dispatch(
                  toastStatus({
                    toastStatus: true,
                    message: "Direct Money transferred successfully",
                    status: true,
                  })
                );
                //alert("Direct Money transfer successfully");
              }
              else if(res?.status===400){
                dispatch(
                  toastStatus({
                    toastStatus: true,
                    message:"Sorry, Direct Money transfer failed. "+res?.message,
                    status: false,
                  })
                );
              }
              else {
                dispatch(
                  toastStatus({
                    toastStatus: true,
                    message:"Sorry, Direct Money transfer failed. "+res?.message,
                    status: false,
                  })
                );
              }
              // console.log('resresresres', res);

            }),
          );
          // console.log("value +", driverUserId);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
                <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">1</span>
                        <p className="sub-head-3">Enter amount</p>
                      </div>
                      <p className="sub-head-body">
                        Amount will be deducted from owner’s LogiPe account
                      </p>

                      <label for="html" className="label-body">
                        ₹
                      </label>
                      <input
                        type="text"
                        id="amount"
                        className="input-body-1"
                        name="amount"
                        onChange={handleChange("amount")}
                        value={values.amount}
                        onBlur={handleBlur("amount")}
                        touched={touched.amount}
                        errors={errors.amount}
                        placeholder="Enter amount"
                      ></input>
                      <div className="input-amount"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.amount && touched.amount ? (
                          <div>*{errors.amount}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">2</span>
                        <p className="sub-head-3">Enter Remarks/comment</p>
                      </div>
                      {/* <p className="sub-head-body">
                              Amount will be added to driver’s LogiPe Card
                            </p> */}

                      <label for="html" className="label-body"></label>
                      <input
                        type="text"
                        id="remarks"
                        className={`input-body `}
                        name="remarks"
                        onChange={handleChange("remarks")}
                        value={values.remarks}
                        onBlur={() => {
                          dispatch(
                            sendAnalitics("remark_Direct_Bene_MA_Web", {
                              remark_DC_MA_Web: values.remarks,
                            })
                          );
                          handleBlur("remarks");
                        }}
                        touched={touched.remarks}
                        errors={errors.remarks}
                        placeholder="Enter remarks"
                      />
                      <div className="input-amount"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.remarks && touched.remarks ? (
                          <div>*{errors.remarks}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">3</span>
                        <p className="sub-head-4">Enter 4-digit Security Pin</p>
                      </div>
                      <p className="sub-head-body">
                        Security pin is needed to confirm payment
                      </p>
                      <PinInput
                        length={4}
                        initialValue=""
                        secret
                        onChange={handleChange("pin")}
                        type="numeric"
                        value={values.pin}
                        onBlur={handleBlur("pin")}
                        touched={touched.pin}
                        errors={errors.pin}
                        inputMode="number"
                        style={{
                          padding: "10px",
                          marginLeft: "96px",
                          marginTop: "28px",
                        }}
                        inputStyle={{
                          border: "1px solid #A7A7A7",
                          borderRadius: "8px",
                          marginRight: "20px",
                        }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.pin && touched.pin ? (
                          <div>*{errors.pin}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <div>
                  <button
                    type="submit"
                    className="butt-recharge butt4"
                    disabled={loader ? true : false}
                    onClick={()=>{
                      dispatch(
                    sendAnalitics("Direct_Bene_Trns_from_MA_Web", {
                      directTrxn_amount: values.amount,
                      remarks: values.remarks,
                    })
                  );
                    }}
                  >
                    {loader
                      ? "Please Wait"
                      : "Transfer To Account"}
                  </button>
                </div>
              </Form>
              )}
            </Formik>
  )
        
                
                       
                
    )

}

export default DirectTransfer;