import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../../../../../Icons/Icons";
import AddNewAccount from "../../../../../../../Modals/addNewAccount";



function NoBeneficiaryInfo(props) {

    const [open, setOpen]= useState(false);

    const handleClose=()=>{
        setOpen(false);
    }

    const toggleModal=()=>{
        setOpen(!open);
    }

    const showModal=()=>{
        setOpen(true);
    }
    const { ownerBalance } = useSelector((state) => state?.manageCard);
    const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == null
      ? 0
      : ownerBalance?.instrument_balance?.balance;
  return (
    <div className="centralItems" style={{padding:"8rem 0"}}>
      <SvgImage name="noBeneficiaryLogo" />
      <div className="padding16 Bold heading2">No Beneficiary Added</div>
      <div>You can add a beneficiary for seamless transfer</div>
      <div className="padding24B">of money from your card</div>
      <div style={{padding:"0 8rem"}} onClick={showModal}> 
        <Button className="generateOtp semiBold heading3">Add Beneficiary</Button>
      </div>
      {
        <AddNewAccount
        open={open}
        toggleModal={toggleModal}
        handleClose={handleClose}
        //handleClick={handleClick}
        //btn={btn}
        newToggleModal={props.newToggleModal}
        ownerLogipeBalance={LogipeAccounBal}
        caller="driverBeneficiary"
      />
      }
    </div>
  );
}

export default NoBeneficiaryInfo;
