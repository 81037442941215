import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../../../../../Icons/Icons";
import PinInput from "react-pin-input";
import { addCardBeneficiaryAction, driverTransferToBeneficiaryAction } from "../../../../../../../../services/Store/Actions/omcActions";
import { getDriverList2 } from "../../../../../../../../services/Store/Actions/driverslistActions";
import { useParams } from "react-router-dom";
import { sendAnalitics } from "../../../../../../../../services/Store/Actions/appActions";



const Card2CardTransferModal=(props)=>{
  const { open, toggleModal, handleClose } = props;

  const dispatch = useDispatch();
  const [beneUserId, setBeneUserId]= useState("");
  const [name, setName]= useState("");
  const [color1, setColor1] = useState(-1);
  const [search1, setSearch1] = useState("");
  const [display1, setDisplay1] = useState(false);
  const {id} =useParams();

  const findSearch1 = () => {
    setColor1(-1);
    dispatch(
      getDriverList2({
        offset: 0,
        search: search1,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };

  useEffect(() => {
    setColor1(-1);
    dispatch(
      getDriverList2({
        offset: 0,
        search: search1,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, [search1]);

  const newDriverList= useSelector((state)=>state?.driverlist?.newDriverList);
  const driverCount = newDriverList?.divers?.count;
  const driverListing = newDriverList?.divers?.rows;

  const { loader } = useSelector((state) => state?.omc);
  console.log("button status", loader);

  const validationSchema = Yup.object().shape({
    amount: Yup.number().typeError("Only Numbers are allowed")
    .required("Required")
    .min(1, "Amount should be greater than 1"),
    driverName:Yup.string().required("Required"),
    pin: Yup.string()
    .required("Required")
    .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
  });

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          {/* <div className="modal-head-part">
            <p className="Add-balance-title">Card To Card Transfer</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div> */}
          <ModalBody style={{position:"relative"}}>
            <Formik
              enableReinitialize
              initialValues={{
                amount:"",
                driverName: "",
                pin:"",
                remark:""
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                dispatch(
                    sendAnalitics("Card2CardTransfer_DC_API_Web", {
                    amt: values.amount,
                    pin: values.pin,
                    remark:values.remark,
                    beneficiaryId:String(beneUserId),
                    DriverUserId:id,
                    "beneficiaryName":name,
                    })
                );
                dispatch(addCardBeneficiaryAction({
                  "beneficiaryName": name,
                  "userId": id,
                  "beneUserId": beneUserId,
                },(res)=>{
                  console.log("card2card", res);
                  dispatch(driverTransferToBeneficiaryAction(res?.data?.data?.beneficiaryId,{
                    "pin": values?.pin,
                    "amount": values?.amount,
                    "remark": values?.remark,
                    "userId": id
                  },(res)=>{
                    console.log("transfer", res);
                    dispatch(
                    sendAnalitics("Success_Card2CardTransfer_DC_Web", {
                    amt: values.amount,
                    pin: values.pin,
                    remark:values.remark,
                    beneficiaryId:String(beneUserId),
                    DriverUserId:id,
                    "beneficiaryName":name,
                    })
                );
                  }))
                }))
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit} >
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <label className="sub-head-3 ">Amount</label>
                    </div>
                    <label for="html" className="label-body">
                      ₹
                    </label>
                    <input
                      type="text"
                      id="amount"
                      className={`input-body `}
                      name="amount"
                      onChange={handleChange("amount")}
                      value={values.amount}
                      onBlur={()=>{
                        // dispatch(sendAnalitics("Amt_DC_MA_Web", {
                        //   amt_MA_to_DriverCard:values.amount
                        // }));
                        handleBlur("amount");
                      }}
                      touched={touched.amount}
                      errors={errors.amount}
                      placeholder="Enter amount"
                    />
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                 <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">2</span>
                <p className="sub-head-3">Select Card</p>
              </div>
              <label for="html" className="label-body"></label>
              <input
                type="text"
                id="driverName"
                className="input-body-3"
                name="driverName"
                style={{marginLeft:"0"}}
                onChange={(event) => {
                  setDisplay1(true);
                  console.log("eeeeeeeeeeeeeeeee",event.target.value);
                    // setDisplay(true);
                  setSearch1(event.target.value);
                  setFieldValue(
                    "driverName",
                    event.target.value,
                    true
                  );
                }}
                value={values.driverName}
                onBlur={handleBlur("source")}
                touched={touched.driverName}
                errors={errors.driverName}
                onKeyUp={(e)=>{
                  if (e.key === "Enter") {
                    findSearch1();
                }}}
                placeholder="--- Select ---"
              />
              <div className="input-amount"></div>
              {display1 ? (
                <div
                  className="padding16 newCashback whiteBackground"
                  style={{
                    position: "absolute",
                    width: "350px",
                    height: "330px",
                    marginLeft: "95px",
                    zIndex: "90",
                  }}
                >
                  <div onClick={()=>{
                    setDisplay1(false);
                    setSearch1("");
                    //dispatch(clearAllDriversByVrn());
                    setFieldValue(
                      "driverName",
                      "",
                      true
                    );
                  }}
                  style={{position:"absolute", right:"0.5rem", top:"0.5rem", cursor:"pointer"}}>
                      <SvgImage name="CloseButtonLogo" />
                    </div>
                  <div
                    style={{
                      overflowY: "scroll",
                      overflowX: "hidden",
                      height: "240px",
                    }}
                  >
                    { (driverCount==undefined)?
                    <></>:
                    driverCount!=0?
                    driverListing?.map((data, i) => {
                      let name = data?.User?.fullName.length>30?data?.User?.fullName.split(0,30)+"...":data?.User?.fullName;
                      let phone = data?.User?.phone;
                      
                      //console.log ("check status",city, state);  
                      // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                      return  (
                        <div
                          key={i}
                          className="padding8 row"
                          onClick={() => {
                            // setcityId1(data?.cityId);
                            setColor1(i);
                            setDisplay1(false);
                            setBeneUserId(data?.User?.userId);
                            setName(data?.User?.fullName)
                            setFieldValue(
                              "driverName",
                              name+"-"+phone,
                              true
                            );
                          }}
                        >
                          {color1 === i ? (
                            <div className="col-1">
                              <SvgImage name="SelectedRadio" />
                            </div>
                          ) : (
                            <div className="col-1">
                              <SvgImage name="UnselectedRadio" />
                            </div>
                          )}
                          {/* <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div> */}
                          <div
                            className="col-11"
                            style={{ textAlign: "left" }}
                          >
                            {name} - {phone}
                          </div>
                        </div>
                      ) ;
                    })
                    :<div>No Driver Found</div>}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.driverName && touched.driverName ? (
                  <div>*{errors.driverName}</div>
                ) : null}
              </div>
            </FormGroup>
            <FormGroup>
            <div className="sub-title-1">
                <span class="circle-logo">3</span>
                <label className="sub-head-3 ">Remark</label>
            </div>
            <input
                type="text"
                id="remark"
                className={`input-body-3 `}
                name="remark"
                onChange={handleChange("remark")}
                value={values.remark}
                onBlur={()=>{
                // dispatch(sendAnalitics("remark_DC_MA_Web", {
                //   amt_MA_to_DriverCard:values.remark
                // }));
                handleBlur("remark");
                }}
                touched={touched.remark}
                errors={errors.remark}
                placeholder="Enter remark"
            />
            <div className="input-amount"></div>
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.remark && touched.remark ? (
                <div>*{errors.remark}</div>
                ) : null}
            </div>
            </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">4</span>
                      <p className="sub-head-4 ">Enter 4-digit Security Pin</p>
                    </div>
                    <p className="sub-head-body">
                    Security pin is needed to confirm payment
                    </p>
                  <PinInput
                    length={4}
                    initialValue=""
                    secret
                    onChange={handleChange("pin")}
                    type="numeric"
                    value={values.pin}
                    onBlur={handleBlur("pin")}
                    touched={touched.pin}
                    errors={errors.pin}
                    inputMode="number"
                    style={{
                      padding: "10px",
                      marginLeft: "96px",
                      marginTop: "20px",
                    }}
                    inputStyle={{
                      border: "1px solid #A7A7A7",
                      borderRadius: "8px",
                      marginRight: "20px",
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.pin && touched.pin ? (
                      <div>*{errors.pin}</div>
                    ) : null}
                  </div>
                  </FormGroup>
                  <FormGroup >
                  <button type="submit" className="butt4" disabled={loader?true:false} 
                  onClick={() => {
                    dispatch(
                        sendAnalitics("Card2CardTransfer_DC_Web", {
                        amt: values.amount,
                        pin: values.pin,
                        remark:values.remark,
                        beneficiaryId:String(beneUserId),
                        DriverUserId:id,
                        "beneficiaryName":name,
                        })
                    );
                }}
                >
                  {loader ? "Please Wait" : "Submit"}
                    </button>
                    
                  </FormGroup>
                  <div></div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default Card2CardTransferModal;
