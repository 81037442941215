import {
  WALLET_TRANSACTION_HISTORY,
  WALLET_TRANSACTION_HISTORY_SUCCESS,
  CLEAN_WALLET_TRANSACTION_HISTORY,
  KYC_DONE,
  KYC_DONE_SUCCESS,
  GET_EXPENSE_IMAGE,
  GET_EXPENSE_IMAGE_SUCCESS,
  DELETE_EXPENSE,
  DELETE_EXPENSE_SUCCESS,
  EDIT_EXPENSE_SUCCESS,
  EDIT_EXPENSE,
  QR_CODE,
  QR_CODE_SUCCESS,
  BULK_TRANSFER_SAMPLE,
  BULK_TRANSFER_SAMPLE_SUCCESS,
  BULK_TRANSFER_UPLOAD_SUCCESS,
  BULK_TRANSFER_UPLOAD,
} from "../storeTypes";



export const qrcodeAction = (data) => {
  console.log("step-a");
  return {
    type: QR_CODE,
    payload: data,
  };
};
export const qrcodeActionSuccess = (payload) => {
  return {
    type: QR_CODE_SUCCESS,
    payload,
  };
};

export const bulkTransferSampleSuccess = (payload) => {
  return {
    type: BULK_TRANSFER_SAMPLE_SUCCESS,
    payload,
  };
};

export const bulkTransferUpload = (payload,callback) => {
  return {
    type: BULK_TRANSFER_UPLOAD,
    payload,
    callback
  };
};
export const bulkTransferUploadSuccess = (payload) => {
  return {
    type: BULK_TRANSFER_UPLOAD_SUCCESS,
    payload,
  };
};


export const getWalletTransactionHistory = (data) => {
  console.log("step-a");
  return {
    type: WALLET_TRANSACTION_HISTORY,
    payload: data,
  };
};
export const getWalletTransactionHistorySuccess = (payload) => {
  return {
    type: WALLET_TRANSACTION_HISTORY_SUCCESS,
    payload,
  };
};
export const cleanWalletTransactionHistory = () => {
  return {
    type: CLEAN_WALLET_TRANSACTION_HISTORY,
  };
};

export const kycDoneAction = (obj, callback) => {
  return {
    type: KYC_DONE,
    payload: obj,
    callback,
  };
};
export const kycDoneActionSuccess = (data) => {
  console.log("step-a")
  return {
    type: KYC_DONE_SUCCESS,
    payload: data,
  };
};

export const getExpenseImageAction = (typeExp, id, callback) => {
  //console.log("step-a")
  return {
    type: GET_EXPENSE_IMAGE,
    typeExp: typeExp,
    id: id,
    callback,
  };
};
export const getExpenseImageSuccessAction = (payload) => {
  return {
    type: GET_EXPENSE_IMAGE_SUCCESS,
    payload,
  };
};

export function deleteExpenseAction(id) {
  return {
    type: DELETE_EXPENSE,
    payload: id,
  };
}
export function deleteExpenseActionSuccess(data) {
  return {
    type: DELETE_EXPENSE_SUCCESS,
    payload: data,
  };
  
}

export function editExpenseAction(id, data) {
  return {
    type: EDIT_EXPENSE,
    id:id,
    payload:data,
  };
}
export function editExpenseActionSuccess(data) {
  return {
    type: EDIT_EXPENSE_SUCCESS,
    payload: data,
  };  
}

export function downloadBulkTransferAction(data,callback) {
  return {
    type: BULK_TRANSFER_SAMPLE,
    payload: data,
    callback
  };  
}




