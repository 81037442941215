import React, { useEffect, useState } from "react";
import Home from "./Components/Homepage/home/index";
import Header from "./Components/subComponents/header";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import FullDriverDashboardManageExpense from "./Components/ManageDriver/ManageDriver/index";
import DriverDashboard from "./Components/ManageDriverV2/ManageDriver/index";
import FullManageLogipeCard from "./Components/ManageLogipeCard/LogipeCard";
import ManageCustomer from "./Components/ManageCustomers";
import ManageCustomerV2 from "./Components/ManageCustomersV2";
import ManageVehicles from "./Components/ManageVehicles";
import LoginScreen from "./LoginScreen";
import {
  getDataFromAsyncStorage,
  storeDataToAsyncStorage,
} from "./services/Utils/functions";
import { useSelector, useDispatch } from "react-redux";
import { handleLoginSuccess } from "./services/Store/Actions/LoginAction";
//import Toast from "./Components/subComponents/toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideBar from "./Components/subComponents/sideBar/index";
import NewHome from "./Components/Homepage/NewHome";
import ProctedRout from "./protectedHoc";
import UnproctedRoute from "./unprotectedHoc";
import axios from "axios";
import SignUp from "./Components/SignUp";
import LogiMart from "./Components/LogiMart";
import Report from "./Components/Report";
import Trips from "./Components/Trips";
import Salary from "./Components/Salary/index";
import Vendor from "./Components/Vendor";
import Tracker from "./Components/Tracker";
import { io } from "socket.io-client";
import TripsMainV2 from "./Components/TripsMainV2";
import TripDetailsV2 from "./Components/TripsDetailsV2";
import LoadReceipt from "./Components/LoadReceipt";
import ManageCustomersDetailsV2 from "./Components/ManageCustomersDetailsV2";
import DirectRecharge from "./DirectRecharge";
import UserManagement from "./Components/UserManagement";
import SuccessScreen from "./DirectRecharge/SubComponent/SuccessScreen";
import EndTrip from "./Components/EndTrip";
import RoutesComponent from "./Components/Routes";
import LoadReceiptDetail from "./Components/LoadReceiptDetail";
import VehicleDashboard from "./Components/ManageVehiclesV2";
import DirectFastagKyc from "./DirectFastagKyc";
import FuelMain from "./Components/ManageFuel/FuelMain";
import VehicleHistory from "./Components/ManageVehicles/SubComponents/VehicleHistoryShowroom";
import FullManageLogipeCardDriver from "./Components/ManageLogipeCard/LogipeCardDriver";
import Branches from "./Components/Branches";
import GpsTracker from "./Components/GPSTracker";

// import { addDeviceIdAction } from "./services/Store/Actions/appActions";

//toast.configure()
function MainApp(props) {
  const { location, deviceToken, isTokenFound } = props;
  const dispatch = useDispatch();
  const { authUser, otpStatus } = useSelector((state) => state?.loginReducer);
  const { customerList, customerNo } = useSelector((state) => state?.customer);
  const [sideBar, setSideBar] = useState(false);
  const [salesHome, setSalesHome] = useState(false);
  const [act2, setAct2] = useState(4);
  const [item, setItem] = useState({});

  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips, setIsTrips] = useState(null);
  const [isCustomers, setIsCustomers] = useState(null);
  const [isVendor, setIsVendor] = useState(null);
  const [isGps, setIsGps] = useState(0);
  useEffect(() => {
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
    setIsGps(
      authUser?.company?.customFeatures?.includes(7)
        ? 1
        : 0
    );
  }, [authUser, managerControlList]);
  console.log("auth", isTrips, isCustomers, isVendor);
  let socket;
  useEffect(() => {
    let token2 = sessionStorage.getItem("_token");
    token2 = JSON.parse(token2);
    socket = io("https://gke-socket.logipe.com/", {
      query: { token: token2 },
      transports: ["websocket"],
    });
    socket.on("ping", (data) => {
      console.log("pinnnng");
    });
    socket.on("vehicleTrackingResponse", (data1) => {});
    socket.on("connect", (data) => {
      console.log("connect");
      global.socket = socket;
    });

    if (!customerNo || customerNo == "" || customerNo == 0) {
      setSalesHome(true);
    } else {
      setSalesHome(false);
    }
  }, []);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let data = sessionStorage.getItem("_user");
      if (data != null && data != "") {
        dispatch(handleLoginSuccess(JSON.parse(data)));
      }
    }

    async function settingApi() {
      try {
        const headers = {
          "api-key":
            "pyM61IesQNDIvLYKUql2WWg2ze3AtyRB6oKM2zTuzpMDVtlPD1aLAMNcHj2RKPl1",
          // 'Access-Control-Request-Headers':'*',
          // 'Content-Type':'application/json',
          // 'origin':'https://portal.logipe.com'
          // 'origin':
        };

        // const body={
        //   "collection":"apis",
        //   "database":"dashboard",
        //   "dataSource":"Cluster0"
        // }
        const config = {
          method: "GET",
          url: "https://logipe-notifications-default-rtdb.asia-southeast1.firebasedatabase.app/urls.json?auth=k6QaoJ1qcJPBZsCUfg06UoEr98SidseXyU7ZYn4r",
          headers: headers,
          // data:body
        };

        const Response = await axios(config);
        //console.log("mmmmmmmm-------", Response);
        if (Response?.status == 200) {
          //console.log("gggggggg-------", Response?.data?.['-NKlu0HL9npM5Cz46Iwi']);
          storeDataToAsyncStorage(
            "_apiList",
            Response?.data?.["-NKlu0HL9npM5Cz46Iwi"]
          );
          window.location.reload();
        }
      } catch (err) {
        console.log("Err for api list", err);
      }
    }
    const storedAPIs = sessionStorage.getItem("_apiList");
    if (!storedAPIs) {
      settingApi();
    }
  }, []);
  let user = sessionStorage.getItem("_user");
  const ProtectedLoginRoute = ({ redirectPath = "/", children }) => {
    console.log("abnmc", window.location.pathname);
    // console.log("@@protective", authUser,redirectPath,window.location.pathname);
    //redirectPath = window.location.pathname;
    let user = sessionStorage.getItem("_user");
    return user !== "null" && user != undefined ? (
      <Outlet />
    ) : (
      <Navigate to={redirectPath} />
    );
  };
  // let socket
  // useEffect(()=>{
  //   let token2 = sessionStorage.getItem("_token");
  //   token2 = JSON.parse(token2);
  //   socket = io("https://gke-socket.logipe.com/", {
  //     query: {token:token2},
  //     transports : ["websocket"]
  //   });
  //   socket.on('ping',(data)=>{
  //     console.log("pinnnng");
  //     // if(y?.length!=0){
  //     //   socket.emit("vehicleTracking", y);
  //     // }
  //   })
  //   socket.on('vehicleTrackingResponse',(data1)=>{
  //     // console.log("xxxxxxxxxx",y,y[data1?.fleetId],data1);
  //     //  y[data1?.fleetId] = {
  //     //     ...y[data1?.fleetId],
  //     //     ...data1
  //     //  }
  //     //  console.log("qwewqqeqweqwe",y,Object.values(y));
  //     //  setVehicleData(Object.values(y))
  //   })
  //   socket.on('connect',(data)=>{
  //     console.log("connect");
  //     // if(y?.length!=0){
  //     //   socket.emit("vehicleTracking", y);
  //     // }
  //   })
  //   setTimeout(()=>{
  //     global.socket = socket
  //   },1000)
  // },[])
  // styling and variables for adding toast
  // const [ toastOpen, setToastOpen] = useState(false);
  // const [successStatus, setSuccessStatus] = useState (false);
  // const [toastMsg, setToastMsg]= useState("");
  // const toggleToastModal=()=>{
  //   setToastOpen(!toastOpen);
  // }
  // const handleToastClose = () => {
  //   setToastOpen(false);
  //   console.log("closed");
  // };
  // const handleToastShow = (a) => {
  //   setToastOpen(true);
  //   console.log(a);
  // };
  return (
    <div
      className="main-app-container"
      // style={{ overflowY: "hidden", overflowX: "hidden" }}
      style={{ background: "#E572311A" }}
    >
      {authUser ? (
        <>
          <Header accountName="" accountTitle="" companyName="" />
          <SideBar
            setSideBar={setSideBar}
            sideBar={sideBar}
            setAct2={setAct2}
            act2={act2}
            authUser={JSON.parse(user)}
          ></SideBar>
        </>
      ) : null}
      <Routes location={location}>
        <Route
          exact
          path="/"
          element={
            <UnproctedRoute
              component={<LoginScreen authUser={authUser} />}
              user={user}
            />
          }
        />
        <Route
          path="/recharge/:fleetId"
          element={
            <UnproctedRoute
              component={
                <DirectRecharge authUser={authUser} setItem={setItem} />
              }
              user={null}
            />
          }
        />
        <Route
          path="/recharge/"
          element={
            <UnproctedRoute
              component={
                <DirectRecharge authUser={authUser} setItem={setItem} />
              }
              user={null}
            />
          }
        />
        <Route
          path="/recharge/:fleetId/success"
          element={
            <UnproctedRoute
              component={<SuccessScreen authUser={authUser} item={item} />}
              user={null}
            />
          }
        />
        <Route
          path="/idfcKyc/"
          element={
            <UnproctedRoute
              component={
                <DirectFastagKyc authUser={authUser} setItem={setItem} />
              }
              user={null}
            />
          }
        />
        <Route
          path="/idfcKyc/:fleetId"
          element={
            <UnproctedRoute
              component={
                <DirectFastagKyc authUser={authUser} setItem={setItem} />
              }
              user={null}
            />
          }
        />
        <Route
          path="SignUp"
          element={<ProctedRout component={<SignUp />} user={user} />}
        />
        <Route
          path="home"
          element={
            <ProctedRout
              component={
                <NewHome
                  deviceToken={deviceToken}
                  isTokenFound={isTokenFound}
                  sideBar={sideBar}
                />
              }
              user={user}
            />
          }
        />

        {/* <Route
          path="salary/:id"
          element={
            <ProctedRout component={<Salary sideBar={sideBar} />} user={user} />
          }
        />
        <Route
          path="salary/bulkSalary"
          element={
            <ProctedRout component={<Salary sideBar={sideBar} />} user={user} />
          }
        />
        <Route
          path="Salary"
          element={
            <ProctedRout component={<Salary sideBar={sideBar} />} user={user} />
          }
        /> */}

        <Route
          path="LogipeCard"
          element={
            <ProctedRout
              component={<FullManageLogipeCard sideBar={sideBar} />}
              user={user}
            />
          }
        />
        <Route
          path="fuelMain"
          element={
            <ProctedRout
              component={<FuelMain sideBar={sideBar} />}user={user}
            />
          }
        />
        <Route
          path="Customers/:id"
          element={
            <ProctedRout
              component={<ManageCustomersDetailsV2 sideBar={sideBar} />}
              user={user}
              disable={isCustomers == null ? false : !isCustomers}
            />
          }
        />
        <Route
          path="Customers"
          element={
            <ProctedRout
              component={
                <ManageCustomerV2
                  sideBar={sideBar}
                  salesHome={salesHome}
                  setSalesHome={setSalesHome}
                />
              }
              user={user}
              disable={isCustomers == null ? false : !isCustomers}
            />
          }
        />
          <Route
          path="Branches"
          element={
            <ProctedRout
              component={
                <Branches
                  sideBar={sideBar}
                  salesHome={salesHome}
                  setSalesHome={setSalesHome}
                />
              }
              user={user}
            />
          }
        />
         <Route
          path="VehicleHistory"
          element={
            <ProctedRout
              component={
                <VehicleHistory
                  sideBar={sideBar}
                  salesHome={salesHome}
                  setSalesHome={setSalesHome}
                />
              }
              user={user}
            />
          }
        />
        <Route
          path="vehicles/:id"
          element={
            <ProctedRout
              component={<ManageVehicles sideBar={sideBar} />}
              user={user}
            />
          }
        />
        <Route
          path="vehicles"
          element={
            <ProctedRout
              component={<ManageVehicles sideBar={sideBar} />}
              user={user}
            />
          }
        />
        {/* //// */}
        
        {/* //// */}
        <Route
          path="manager/:id"
          element={
            <ProctedRout
              component={<UserManagement sideBar={sideBar} />}
              user={user}
            />
          }
        />
        <Route
          path="manager"
          element={
            <ProctedRout
              component={<UserManagement sideBar={sideBar} />}
              user={user}
            />
          }
        />
        <Route
          path="Drivers/:id"
          element={
            <ProctedRout
              component={<FullDriverDashboardManageExpense sideBar={sideBar} />}
              user={user}
            />
          }
        />
        <Route
          path="drivers/bulktransfer"
          element={
            <ProctedRout
              component={<FullDriverDashboardManageExpense sideBar={sideBar} />}
              user={user}
            />
          }
        />
        <Route
          path="Drivers"
          element={
            <ProctedRout
              component={<FullDriverDashboardManageExpense sideBar={sideBar} />}
              user={user}
            />
          }
        />
        <Route
          path="logimart"
          element={<LogiMart user={user} sideBar={sideBar} />}
        />
        {/* <Route
          path="trips/:id"
          element={
            <ProctedRout component={<Trips sideBar={sideBar} setItem={setItem} item={item}/>} user={user} />
          }
        />
        <Route
          path="trips"
          element={
            <ProctedRout component={<Trips sideBar={sideBar} setItem={setItem} item={item} />} user={user}/>
          }
        /> */}

        <Route
          path="trips/:id"
          element={
            <ProctedRout
              component={<TripDetailsV2 sideBar={sideBar} />}
              user={user}
              disable={isTrips == null ? false : !isTrips}
            />
          }
        />

        <Route
          path="trips"
          element={
            <ProctedRout
              component={<TripsMainV2 sideBar={sideBar} />}
              user={user}
              disable={isTrips == null ? false : !isTrips}
            />
          }
        />
        <Route
          path="lr"
          element={
            <ProctedRout
              component={<LoadReceipt sideBar={sideBar} />}
              user={user}
              disable={isTrips == null ? false : !isTrips}
            />
          }
        />
        <Route
          path="lr/:id"
          element={
            <ProctedRout
              component={<LoadReceiptDetail sideBar={sideBar} />}
              user={user}
              disable={isTrips == null ? false : !isTrips}
            />
          }
        />
        <Route
          path="report"
          element={
            <ProctedRout
              component={<Report user={user} sideBar={sideBar} />}
              user={user}
            />
          }
        />
        <Route
          path="vendor"
          element={
            <ProctedRout
              component={<Vendor user={user} sideBar={sideBar} path={act2} />}
              user={user}
              disable={isVendor == null ? false : !isVendor}
            />
          }
        />
        <Route
          path="tracker"
          element={
            <ProctedRout
              component={
                <Tracker
                  user={user}
                  sideBar={sideBar}
                  setItem={setItem}
                  item={item}
                  socket={global.socket}
                />
              }
              user={user}
            />
          }
        />
        <Route
          path="endTrip/:id"
          element={
            <ProctedRout
              component={
                <EndTrip sideBar={sideBar}/>
              }
              user={user}
              disable={isTrips == null ? false : !isTrips}
            />
          }
        />
        <Route
          path="Routes"
          element={
            <ProctedRout
              component={
                <RoutesComponent
                  sideBar={sideBar}
                  salesHome={salesHome}
                  setSalesHome={setSalesHome}
                />
              }
              user={user}
              disable={isTrips == null ? false : !isTrips}
            />
          }
        />

        <Route
          path="DriversV2"
          element={
            <ProctedRout
              component={
                <DriverDashboard
                  sideBar={sideBar}
                />
              }
              user={user}
              // disable={isTrips == null ? false : !isTrips}
            />
          }
        />
        <Route
          path="DriversV2/:id"
          element={
            <ProctedRout
              component={<DriverDashboard sideBar={sideBar} />}
              user={user}
              // disable={isTrips == null ? false : !isTrips}
            />
          }
        />
        <Route
          path="DriversV2/control/:id"
          element={
            <ProctedRout
              component={<FullManageLogipeCardDriver sideBar={sideBar} />}
              user={user}
              // disable={isTrips == null ? false : !isTrips}
            />
          }
        />
        <Route
          path="VehiclesV2/:id"
          element={
            <ProctedRout
              component={<VehicleDashboard sideBar={sideBar} />}
              user={user}
              // disable={isTrips == null ? false : !isTrips}
            />
          }
        />
        <Route
          path="VehiclesV2"
          element={
            <ProctedRout
              component={<VehicleDashboard sideBar={sideBar} />}
              user={user}
              // disable={isTrips == null ? false : !isTrips}
            />
          }
        />
        <Route
          path="GPS"
          element={
            <ProctedRout
              component={<GpsTracker sideBar={sideBar} />}
              user={user}
              disable={isGps == null ? false : !isGps}
            />
          }
        />
                <Route
          path="GPS/:id"
          element={
            <ProctedRout
              component={<GpsTracker sideBar={sideBar} />}
              user={user}
              disable={isTrips == null ? false : !isTrips}
            />
          }
        />
      </Routes>
    </div>
  );
}
export default MainApp;
