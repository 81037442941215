import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import RecentTransactionBox from "../../../subComponents/recentTransaction";
import "./index.css";
import moment from "moment";
import {
  cleanVehicleHistory,
  vehicleHistory,
} from "../../../../services/Store/Actions/manageVehicleAction";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import SearchBar from "../../../subComponents/searchbar";
import { DatePickerField } from "../../../core/inputs/input";
import AddNewVendor from "../AddNewVendor";
import PinModal from "../Modal/PinModal";
import SuccessModal from "../Modal/SuccessModal";
import {
  cleanVendorTransaction,
  generateStatementForVendorAction,
  transferTorVendorAction,
  vendorGetSignedUrlAction,
  vendorTransactionAction,
  vendorUploadBillAction,
} from "../../../../services/Store/Actions/beneficiaryAction";
import PaymentModal from "../Modal/PaymentModal";
import AddFleet from "../Modal/AddFleet";
import Toast from "../../../subComponents/toast";
import { wait } from "@testing-library/user-event/dist/utils";
import NoTransaction from "../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/TabularData/NoTransaction";
import { ClipLoader } from "react-spinners";
import VendorTransactions from "../Modal/VendorHistory";
import LedgerHistory from "../Modal/LedgerHistory";
import { editLedgerExpense, getTripLedger, getVendorLedger } from "../../../../services/Store/Actions/TripsAction";
import BulkFuelModal from "../BulkFuel";

function VendorDetails(props) {
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState(0);
  const [offset, setOffset] = useState(0);
  const [enterPin, setEnterPin] = useState(false);
  const [paymentData, setPaymentData] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [failedMsg, setFailedMsg] = useState();
  const [editDetails, setEditDetails] = useState(false);
  const [paymetModal, setPaymentModal] = useState(false);
  const [fleetModal, setFleetModal] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [pinLoader, setPinLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [show, setShow] = useState(true);
  const [selected, setSelected] = useState(0)
  const [bulkFuel, setBulkFuel] = useState(false);


  const { vendorTransactionList, vendorTransaction } = useSelector(
    (state) => state?.beneficiary
  );
  const { vendorData, path, setShowVendor } = props;
  const isLogipeVendor = path == "0" || path == "1" || path == "2" ? "1" : "0";

  const dispatch = useDispatch();
  const trxnCount = vendorTransaction?.count;
  console.log("vendorTransactionList", show,vendorTransactionList);
  
  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips,setIsTrips] = useState(0); 
  const [isCustomers,setIsCustomers] = useState(0); 
  const [isVendor,setIsVendor] = useState(0); 
  useEffect(()=>{
    setIsTrips(authUser?.company?.isTrips && authUser?.user?.type==2?3: authUser?.company?.isTrips && managerControlList?.tripAccess?managerControlList?.tripAccess:0)
    setIsCustomers(authUser?.company?.isCustomers && authUser?.user?.type==2?3: authUser?.company?.isCustomers && managerControlList?.customerAccess?managerControlList?.customerAccess:0)
    setIsVendor(authUser?.company?.isVendor && authUser?.user?.type==2?3: authUser?.company?.isVendor && managerControlList?.vendorAccess?managerControlList?.vendorAccess:0)
  },[authUser,managerControlList])

  // const handlePrev = () => {
  //   if (offset > 0) {
  //     dispatch(cleanVendorTransaction());
  //     setOffset(offset - 5);
  //     dispatch(
  //       vendorTransactionAction({
  //         limit: 5,
  //         offset: offset - 5,
  //         from: startDate || "",
  //         to: endDate || "",
  //         vendorId: vendorData?.id,
  //       })
  //     );
  //   }
  // };
  // const handleNext = () => {
  //   console.log("offset,trxnCount", offset, trxnCount);
  //   if (offset + 5 < trxnCount) {
  //     dispatch(cleanVendorTransaction());
  //     setOffset(offset + 5);
  //     dispatch(
  //       vendorTransactionAction({
  //         limit: 5,
  //         offset: offset + 5,
  //         from: startDate || "",
  //         to: endDate || "",
  //         vendorId: vendorData?.id,
  //       })
  //     );
  //   }
  // };
  useEffect(() => {
    if(show){
      console.log("lllllkkkkkkkkk1",show)
      dispatch(
        vendorTransactionAction({
          limit: 5,
          offset: offset,
          from: "",
          to: "",
          vendorId: vendorData?.id,
        })
      );
      // return () => {
      //   dispatch(cleanVendorTransaction());
      // };
    }
    else{
      console.log("lllllkkkkkkkkk2",show)
      dispatch(
        getVendorLedger(vendorData?.id,{
          limit: 5,
          offset: offset,
        })
      );
      // return () => {
      //   dispatch(cleanVendorTransaction());
      // };
    }
  }, [show]);
  const datepickerRefStart = useRef();
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  function findSearch() {}
  if (["2","3",2,3].includes(isVendor) && editDetails) {
    return (
      <AddNewVendor
        backPress={() => {
          setEditDetails(false);
          setShowVendor(false);
        }}
        oldDetails={vendorData}
        path={path}
      />
    );
  } else {
    return (
      <div className="vendorBg containerShadow" style={{ paddingTop: "1px" }}>
        {fleetModal && (
          <AddFleet
            open={fleetModal}
            mode={mode}
            setMode={setMode}
            onHide={() => {
              setFleetModal(false);
            }}
            toggleModal={() => {
              setFleetModal(!paymetModal);
            }}
            handleClose={() => {
              setFleetModal(false);
            }}
            onSubmit={(data) => {
              setFleetModal(false);
              setEnterPin(true);
              setPaymentData(data);
              console.log("modeooooooo", mode);
            }}
            vendorData={vendorData}
          />
        )}
        {paymetModal && (
          <PaymentModal
            open={paymetModal}
            mode={mode}
            setMode={setMode}
            onHide={() => {
              setPaymentModal(false);
            }}
            toggleModal={() => {
              setPaymentModal(!paymetModal);
            }}
            handleClose={() => {
              setPaymentModal(false);
            }}
            onSubmit={(data) => {
              setPaymentModal(false);
              setEnterPin(true);
              setPaymentData(data);
              console.log("modeooooooo", mode);
            }}
            vendorData={vendorData}
          />
        )}
        {enterPin && (
          <PinModal
            open={enterPin}
            onHide={() => {
              setEnterPin(false);
            }}
            toggleModal={() => {
              setEnterPin(!enterPin);
            }}
            handleClose={() => {
              setEnterPin(false);
            }}
            pinLoader={pinLoader}
            onSubmit={(data) => {
              // console.log("nanananana",isLogipeVendor,vendorData?.vpaId,vendorData?.bankAccountNumber,isLogipeVendor == "0" && (vendorData?.vpaId != null || vendorData?.vpaId != "")&& (vendorData?.bankAccountNumber != "" || vendorData?.bankAccountNumber != null))
              setPinLoader(true);
              dispatch(
                transferTorVendorAction(
                  {
                    mode:
                      isLogipeVendor == "0" &&
                      (vendorData?.vpaId != null || vendorData?.vpaId != "") &&
                      (vendorData?.bankAccountNumber != "" ||
                        vendorData?.bankAccountNumber != null)
                        ? mode == 0
                          ? "BANK_TRANSFER"
                          : "UPI"
                        : vendorData?.vpaId == "" || vendorData?.vpaId == null
                        ? "BANK_TRANSFER"
                        : "UPI",
                    pin: data?.pin?.toString(),
                    amount: paymentData?.amount?.toString(),
                    remark: paymentData?.remarks,
                    vendorId: vendorData?.id?.toString(),
                  },
                  (res) => {
                    setPinLoader(false);
                    console.log("res?.datatransferTorVendorAction", res);
                    if (res?.status == 200) {
                      setSuccessModal(true);
                      setSuccessText(
                        `₹${paymentData?.amount} has been payed to your vendor from Master Account.`
                      );
                      setEnterPin(false);
                      wait(1000).then(() => {
                        dispatch(cleanVendorTransaction());
                        dispatch(
                          vendorTransactionAction({
                            limit: 5,
                            offset: offset,
                            from: "",
                            to: "",
                            vendorId: vendorData?.id,
                          })
                        );
                      });
                    } else {
                      setFailedMsg(res?.data?.message);
                      setSuccessModal(false);
                      setEnterPin(false);
                      setFailedModal(true);
                    }
                  }
                )
              );
            }}
            data={paymentData}
            isLogipeVendor={
              path == "0" || path == "1" || path == "2" ? "1" : "0"
            }
          />
        )}
        {successModal && (
          <SuccessModal
            open={successModal}
            onHide={() => {
              setSuccessModal(false);
              setPaymentData({});
              setSuccessText("");
            }}
            toggleModal={() => {
              setSuccessModal(!successModal);
              setPaymentData({});
              setSuccessText("");
            }}
            handleClose={() => {
              setSuccessModal(false);
              setPaymentData({});
              setSuccessText("");
            }}
            text={successText}
          />
        )}
        {failedModal && (
          <Toast
            success={0}
            message={failedMsg || "Failed to Transfer Amount"}
            toggleModal={() => {
              setFailedModal(false);
              setFailedMsg();
            }}
            isOpen={failedModal}
            name="vendor"
            handleClose={() => {
              setFailedModal(false);
              setFailedMsg();
            }}
          />
        )}
        <div className="Vendor-Details-Section">
          <div className="row">
            <div className="col-9">
              <div className="row Vendor-Details-Section-3">
                <div className="col-6 Vendor-Details-Section-2" style={{}}>
                  <div className="row">
                    <div className="col-3 mt-2">
                      <SvgImage name={"userImage"} />
                    </div>

                    <div className="col-8">
                      <p className="Vendor-Details-Heading">
                        {vendorData?.name || "---"}
                      </p>
                      <p className="Vendor-Details-SubHeading">
                        Contact: {vendorData?.phone || "---"}
                      </p>
                      <p className="Vendor-Details-SubHeading">
                        GST No: {vendorData?.gstNumber || "---"}
                      </p>
                      <p className="Vendor-Details-SubHeading">
                        Address: {vendorData?.address || "---"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 Vendor-Details-Section-2" style={{}}>
                  <div className="row">
                    <div className="col-3 mt-2">
                      <SvgImage name={"BankDetails"} />
                    </div>

                    <div className="col-8">
                      <p className="Vendor-Details-Heading">Bank Details</p>
                      <p className="Vendor-Details-SubHeading">
                        Account Name: {vendorData?.bankAccountName || "---"}
                      </p>
                      <p className="Vendor-Details-SubHeading">
                        Account No: {vendorData?.bankAccountNumber || "---"}
                      </p>
                      <p className="Vendor-Details-SubHeading">
                        IFSC Code: {vendorData?.ifsc || "---"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row Vendor-Details-Section-4">
                <div
                  className={`${
                    vendorData?.vendorType == "2" ? "col-4" : "col-6"
                  } transfer-box-3`}
                >
                  <div className="Transfer-To-Logipe-Vendor-Bg-2 pt-2">
                    <SvgImage name={"Editing"} />
                  </div>
                  <div>
                    <button
                      className="greenOutlineButton"
                      onClick={() => {
                        setEditDetails(true);
                      }}
                      style={{
                        marginTop: "12px",
                        flex: 1,
                        height: "36px",
                        width: "100%",
                      }}
                    >
                      <text className="Edit-Vendor-Details" style={{}}>
                        Edit Details
                      </text>
                    </button>
                  </div>
                </div>
                <div
                  className={`transfer-box-3 ${
                    vendorData?.vendorType == "2" ? "col-4" : "col-6"
                  } `}
                >
                  <div className="Transfer-To-Logipe-Vendor-Bg-2 pt-2">
                    <SvgImage name={"Cash"} />
                  </div>
                  <div>
                    <button
                      className="greenButton"
                      style={{
                        marginTop: "12px",
                        flex: 1,
                        height: "36px",
                        width: "100%",
                      }}
                      onClick={() => {
                        if(["2","3",2,3].includes(isVendor)) setPaymentModal(true);
                      }}
                    >
                      <text className="Pay-Vendor-Details" style={{}}>
                        Pay Vendor
                      </text>
                    </button>
                  </div>
                </div>
                {vendorData?.vendorType == "2" ? (
                  <div className="col-4 transfer-box-3">
                    <div className="Transfer-To-Logipe-Vendor-Bg-2 pt-2">
                      <SvgImage name={"truckIcon"} />
                    </div>
                    <div>
                      <button
                        className="greenButton"
                        style={{
                          marginTop: "12px",
                          flex: 1,
                          height: "36px",
                          width: "100%",
                        }}
                        onClick={() => {
                          setFleetModal(true);
                        }}
                      >
                        <text className="Pay-Vendor-Details" style={{}}>
                          Add Vehicle
                        </text>
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-3 FullDriverDashboardBox"
          style={{ background: "#FFFFFF", borderRadius: "10px 0px 0px 0px" }}
        >
          <div
            className="FUllListSearchBar leftTopDiv"
            onClick={() => {
              console.log("bulkSalarybulkSalary11", bulkFuel);
              setBulkFuel(!bulkFuel);
            }}
            style={{ margin: "10px 12px 20px 11px", cursor: "pointer" }}
          >
            <div className="bulkSalarySvgHeading">
              <div
                style={{
                  backgroundColor: "#FFF",
                  padding: "4px",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
              >
                <SvgImage name="payBulkSalaryLogo" />
              </div>
              <div className="FontNS18">Bulk Vendor Fuel</div>
            </div>
            <SvgImage name="rightArrow" />
          </div>
          {/* {bulkSalary ? <BulkTransfer /> : <></>} */}
          {/* <DriverList /> */}
        </div>
        {bulkFuel ? <BulkFuelModal vendorId = {vendorData?.id}  /> : <></>}


          <div className="row align-items-end" style={{margin: "15px 0 0 15px"}}>
            <div className="col-9 row">
                    {/* <button className={selected==0 ? "ButtonVendorTransaction": "ButtonVendorTransactionInactive"} onClick={()=> setSelected(0)}>
                    Transaction History
                    </button>
                    <button className={selected==1 ? "ButtonVendorTransaction": "ButtonVendorTransactionInactive"} onClick={()=> setSelected(1)}>
                    Ledger
                    </button > */}
              {/* <div className="col-4">
                <Button className="vendor-List-title1 pt-0" onClick={() => {setShow(true)}}>
                  Transaction History
                </Button>
              </div>
              <div className="col-4">
                <Button className="vendor-List-title1 pt-0" onClick={() => {setShow(false)}}>
                  Ledger
                </Button>
              </div> */}
                                  <button className={selected==0 ? "ButtonVendorTransaction": "ButtonVendorTransactionInactive"} onClick={()=> {setSelected(0);setShow(true)}}>
                    Transaction History
                    </button>
                    <button className={selected==1 ? "ButtonVendorTransaction": "ButtonVendorTransactionInactive"} onClick={()=> {setSelected(1);setShow(false)}}>
                    Ledger
                    </button >
            </div>
            <div className="col-3 align-self-center">
              {trxnCount != 0 && (
                <>
                  {downloadLoader ? (
                    <div className=" downloadText">
                      <ClipLoader
                        color={"#485B7C"}
                        loading={true}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />{" "}
                      downloading ...
                    </div>
                  ) : (
                    <div
                      className=" downloadText"
                      onClick={() => {
                        setDownloadLoader(true);
                        dispatch(
                          generateStatementForVendorAction(
                            {
                              from: startDate || "",
                              to: endDate || "",
                              vendorId: vendorData?.id,
                            },
                            (res) => {
                              setDownloadLoader(false);
                              if (res?.status) {
                                window.open(res?.data?.data?.url);
                              }
                            }
                          )
                        );
                      }}
                    >
                      <div className="" style={{ marginRight: "8px" }}>
                        <SvgImage name="downloadImgV2" />
                      </div>
                      Download Statement
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        {
          show ? <VendorTransactions/> : <LedgerHistory/>
        }
      </div>
    );
  }
}
export default VendorDetails;
