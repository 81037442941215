import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useRef } from "react";

import "./index.css";

import SvgImage from "../Icons/Icons";
import { sendAnalitics } from "../../services/Store/Actions/appActions";
import Toast from "../subComponents/toast";
import {
  toastStatus,
  storeMedia,
} from "../../services/Store/Actions/appActions";
import Collapsible from "react-collapsible";
import DataTable from "react-data-table-component";
import {
  getLRList,
  getCashTransactions,
} from "../../services/Store/Actions/TripsAction";
import ManageDriverAddNewExpense from "../Modals/ManageDriverAddNewExpense";
import FreightUpdateRender from "./Components/FreightUpdateRender";
import ViewTripCard from "./Components/ViewTripCard";
import TripExpanseSummary from "./Components/TripExpanseSummary";
function EndTrip(props) {
  const { sideBar, } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  let { id } = useParams();
  let { state, location } = useLocation();

  console.log("state?.tripDatastate?.tripData", state?.tripData);
  const [isOpenFASTag, setIsOpenFASTag] = useState(false);
  const [isOpenFuel, setIsOpenFuel] = useState(false);
  const [isOpenCard, setIsOpenCard] = useState(false);
  const [isOpenCash, setIsOpenCash] = useState(false);

  const [openFreight, setOpenFreight] = useState(false);

  const [openAddExpanse, setOpenAddExpanse] = useState(false);
  const closeAddExpense = () => {
    setOpenAddExpanse(false);
  };
  const toggleAddExpense = () => {
    setOpenAddExpanse(!openAddExpanse);
    dispatch(
      getCashTransactions({
        userId: state?.tripData?.userId,
        startTime: state?.tripData?.startTime,
        endTime:
          state?.tripData?.isActive > 0
            ? new Date().toJSON()
            : state?.tripData?.endTime || "",
        cardUserId: state?.tripData?.cardUserId,
        limit: 10,
        offset: 0,
      })
    );
  };
  const [openViewFreight, setOpenViewFreight] = useState(false);

  const toggleViewFreight = () => {
    setOpenViewFreight(!openViewFreight);
  };
  const [next, setNext] = useState(false);
  const [count, setCount] = useState(null);
  const [view, setView] = useState(false);

  const toastObject = useSelector((state) => state?.app?.toastObject);
  const {
    fastagVehicleTransaction,
    cashTransactions,
    freightList,
    freightListCount,
  } = useSelector((state) => state?.trip);
  console.log("freightListfreightList", freightList);

  useEffect(() => {
    console.log("getLRListgetLRList", id);
    if (id && id != "undefined") {
      dispatch(
        getLRList(
          {
            tripId: id,
          },
          (res) => {
            console.log("getLRListgetLRList", res);
          }
        )
      );
      dispatch(
        getCashTransactions({
          userId: state?.tripData?.userId,
          startTime: state?.tripData?.startTime,
          endTime:
            state?.tripData?.isActive > 0
              ? new Date().toJSON()
              : state?.tripData?.endTime || "",
          cardUserId: state?.tripData?.cardUserId,
          limit: 10,
          offset: 0,
        })
      );
      // dispatch(sendAnalitics(""));
    }
  }, [id]);

  const Expenses = [
    {
      type: "FASTag",
      txnCount: fastagVehicleTransaction?.transHistory?.count || 13,
      totalExpense: fastagVehicleTransaction?.transHistory?.sum || 12050,
      txns: [],
      open: () => setIsOpenFASTag(!isOpenFASTag),
      isOpen: isOpenFASTag,
    },
    {
      type: "Fuel",
      txnCount: fastagVehicleTransaction?.transHistory?.count || 5,
      totalExpense: fastagVehicleTransaction?.transHistory?.sum || 42050,
      txns: [],
      open: () => setIsOpenFuel(!isOpenFuel),
      isOpen: isOpenFuel,
    },
    {
      type: "Card",
      txnCount: fastagVehicleTransaction?.transHistory?.count || 4,
      totalExpense: fastagVehicleTransaction?.transHistory?.sum || 22050,
      txns: [],
      open: () => setIsOpenCard(!isOpenCard),
      isOpen: isOpenCard,
    },
    {
      type: "Cash",
      txnCount: fastagVehicleTransaction?.transHistory?.count || 6,
      totalExpense: fastagVehicleTransaction?.transHistory?.sum || 5000,
      txns: cashTransactions?.data?.rows ? cashTransactions?.data?.rows : [],
      open: () => setIsOpenCash(!isOpenCash),
      isOpen: isOpenCash,
    },
  ];
  const openFreightForm = () => {
    console.log("openFreightFormopenFreightFormopenFreightForm");
    setOpenFreight(!openFreight);
  };

  const txnListRender = (list) => {
    console.log("txnListRendertxnListRendertxnListRender");
    let columns = [];
    list.map((i, k) => {
      console.log(i, k);
      columns = [
        {
          name: "Transaction Date",
          selector: (i) => i.paymentDate,
        },
        {
          name: "Amount",
          selector: (i) => i.amount,
        },
        {
          name: "Type",
          selector: (i) => i.expenseType,
        },
        {
          name: "Description",
          selector: (i) => i.description,
        },
        // {
        //   name: "Status",
        //   cell: (i) => (
        //     <button
        //       type="button"
        //       // className="btn btn-success"
        //       style={{  }}
        //       // onClick={() => alert("The Trip is Completed")}
        //     >
        //       {i.invoiceId == null ? "UnInvoiced" : "Invoiced"}
        //     </button>
        //   ),
        // },
        // {
        //   name: "Trip",
        //   cell: (i) => (
        //     <button
        //       type="button"
        //       // className="btn btn-success"
        //       style={{  }}
        //       // onClick={() => alert("The Trip is Completed")}
        //     >
        //       {i.tripId == null ? "Unlinked" : "linked"}
        //     </button>
        //   ),
        // },
        {
          // name: "Edit",
          // cell: (i) => (
          //   <button
          //     type="button"
          //     className="btn btn-success"
          //     style={{}}
          //     onClick={() => {
          //       console.log("editLReditLReditLR", i);
          //       setFreightId(i.freightId);
          //       setIsEdit(true);
          //       editLR();
          //     }}
          //     disabled={i?.invoiceId != null}
          //   >
          //     {i?.invoiceId == null ? "" : "invoiced"}
          //   </button>
          // ),
        },
      ];
    });
    return (
      <div>
        <DataTable
          columns={columns}
          data={list}
          // fixedHeader
          // fixedHeaderScrollHeight='450px'
          // selectableRows
          // selectableRowsHighlight={true}
          highlightOnHover
          // pagination
          onRowClicked={(row) => {
            // onSelect(row);
          }}
          // onSelectedRowsChange={(selected) => {
          // if (selected.selectedCount > 0) {
          //   setCreateInvoice(true);
          //   // setCustomerIdInvoice(selected.selectedRows[0].customerId)
          // }
          // if (selected.selectedCount == 0) {
          //   setCreateInvoice(false);
          //   // setCustomerIdInvoice("")
          // }
          // console.log("onSelectedRowsChange", selected.selectedRows);
          // }}
          // selectableRowDisabled={(row) => {
          //   if( !createInvoice ) return false
          //   console.log("selectableRowDisabled",row);
          //   return (row.customerId != customerIdInvoice)
          // }}
        />
      </div>
    );
  };

  const freightViewRender = (freight) => {
    return (
      <div>
        freightViewRenderfreightViewRenderfreightViewRenderfreightViewRender
      </div>
    );
  };
  return (
    <div
      className="container topMargin sideBarMargin"
      style={{
        // paddingTop:"5.5rem",
        marginLeft: sideBar ? "220px" : "100px",
        maxWidth: sideBar ? windowWidth - 240 : windowWidth - 130,
      }}
    >
      <div style={{ marginLeft: "20px" }}>
        <div className="">
          <div className="PGCollectiontText col-9" style={{}}>
            End Trip
          </div>
          <hr style={{ border: "2px solid #2E7C7B" }}></hr>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <ViewTripCard tripData={state?.tripData} />
          <TripExpanseSummary tripData={state?.tripData} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          {next ? (
            <div
              style={{
                width: "50px",
                display: "flex",
                justifyContent: "flex-start",
                marginRight: "10px",
              }}
              onClick={() => setNext(!next)}
            >
              <text>Prev</text>
            </div>
          ) : (
            <></>
          )}
          <div style={{ width: "1200px" }}>
            {!next ? (
              <div style={{ marginLeft: "50px" }}>
                <div
                  className="PGCollectiontText"
                  style={{ justifyContent: "center" }}
                >
                  Expenses
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    background: " rgb(225, 228, 227)",
                  }}
                >
                  <span>Expense - Type</span>
                  <span>No of Transactions</span>
                  <span>Total Expanse</span>
                  <span></span>
                  <span></span>
                </div>
                {Expenses.map((elem) => {
                  return (
                    <div>
                      <Collapsible
                        trigger={
                          <div
                            className="trigger"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: "75px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            <span>{elem.type}</span>
                            <span>{elem.txnCount}</span>
                            <span>{elem.totalExpense}</span>
                            <span>
                              {/* <button 
                          style={{width: "60px", height:"25px",borderRadius: "5px",backgroundColor: "#FFF",borderColor: "#2E7C7B",}}
                          onClick={() => {
                            console.log("onClickonClickonClick")
                            setIsOpen(!isOpen)}}
                        >
                        <text>View</text>
                        </button> */}
                              {elem.type == "Cash" ? (
                                <button
                                  className="btn btn-primary"
                                  // style={{width: "60px", height:"25px",borderRadius: "5px",backgroundColor: "#FFF",borderColor: "#2E7C7B",}}
                                  onClick={() => toggleAddExpense()}
                                >
                                  <text>Add</text>
                                </button>
                              ) : (
                                <></>
                              )}
                            </span>
                          </div>
                        }
                        // triggerSibling="hskdg shkdg"
                        onOpening={() => elem.open()}
                      >
                        <div style={{ margin: "10px" }}>
                          {elem.isOpen ? txnListRender(elem.txns) : <></>}
                        </div>
                      </Collapsible>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}

            {next ? (
              <div style={{ marginRight: "50px" }}>
                <div
                  className="PGCollectiontText"
                  style={{ justifyContent: "center" }}
                >
                  Freights
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    background: "rgb(225, 228, 227)",
                  }}
                >
                  <td>Source</td>
                  <td>Destination</td>
                  <td>Start Date</td>
                  <td>Amount</td>
                  <td>Weight</td>
                  <td>Pay By</td>
                  <td>{}</td>
                  <td>{}</td>
                </div>
                {freightList.map((elem, i) => {
                  const payBy =
                    elem.customerId == elem.consignorId
                      ? elem.consignorName
                      : elem.customerId == elem.consigneeId
                      ? elem.consigneeName
                      : elem.transporterName;
                  return (
                    <div>
                      <Collapsible
                        trigger={
                          <div
                            className="trigger"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: "75px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            <td>{elem?.fromAddress?.cityName}</td>
                            <td>{elem?.toAddress?.cityName}</td>
                            <td>{elem?.startDate}</td>
                            <td>{elem?.freightAmount}</td>
                            <td>{elem?.weight}</td>
                            <td>{payBy}</td>
                            <td style={{ marginBottom: "20px" }}>
                              {/* <button 
                          style={{width: "60px", height:"25px",borderRadius: "5px",backgroundColor: "#FFF",borderColor: "#2E7C7B",}}
                          onClick={() => {
                            console.log("onClickonClickonClick")
                            setView(true)}}
                        >
                        <text>View</text>
                        </button> */}
                              <button
                                style={{
                                  width: "60px",
                                  height: "25px",
                                  borderRadius: "5px",
                                  backgroundColor: "#FFF",
                                  borderColor: "#2E7C7B",
                                }}
                                onClick={() => {
                                  setView(false);
                                  setCount(i);
                                  // if(openFreight)
                                  //   setOpenFreight(false)
                                  // setOpenFreight(true)
                                }}
                              >
                                <text>POD</text>
                                <SvgImage
                                  name="AddAttachment"
                                  height="20"
                                  width="20"
                                  marginBottom="3px"
                                />
                              </button>
                            </td>
                          </div>
                        }
                        // onOpening={()=> {
                        //   setCount(i)
                        // }}
                      >
                        <div style={{ marginTop: "2px" }}>
                          {console.log(
                            "openFreightCollapsibleCollapsibleopenFreight",
                            count
                          )}
                          {count === i ? (
                            <FreightUpdateRender freight={elem} view={view} />
                          ) : (
                            <></>
                          )}
                        </div>
                      </Collapsible>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>

          {!next ? (
            <div
              style={{
                width: "50px",
                display: "flex",
                justifyContent: "flex-end",
              }}
              onClick={() => setNext(!next)}
            >
              <text>Next</text>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {toastObject.toastStatus && location == "/trips/" ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            console.log("nmnnnnnnnnnmmmm", toastObject?.message);
          }}
        />
      ) : (
        <></>
      )}
      <ManageDriverAddNewExpense
        userId={state?.tripData?.userId}
        setDisplayName={"Add Expense"}
        displayName={"Add Expense"}
        open={openAddExpanse}
        toggleModal={toggleAddExpense}
        title="Logipe Account"
        handleClose={closeAddExpense}
        trip={true}
        itemTemp={state?.tripData}
        color={
          "LogipeAccount" === "Card Wallet" ? "title-color1" : "title-color2"
        }
      />
    </div>
  );
}

export default EndTrip;
