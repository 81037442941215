import React, { useEffect, useState } from "react";
import "./index.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { getManagerList } from "../../../../services/Store/Actions/UserManagementAction";

function ManagerDetailsCard(props) {
  const { color, setColor } = props;
  const dispatch = useDispatch();

  const { managerListing, managerList } = useSelector(
    (state) => state?.userManagement
  );
  const navigate = useNavigate();

  const handleClick = (e, i) => {
    props.allowTrigger();

    navigate(
      `/manager/${e.User.userId}?search=${props.search}&offset=${props.offset}`
    );
  };

  let { id } = useParams();

  useEffect(() => {
    let urlId = window.location.pathname.split("/");
    if (urlId.length === 3) {
      if (urlId[2] != undefined && urlId[2] != "") {
        let i = managerListing?.findIndex(
          (element) => element.User?.userId === urlId[2]
        );
        setColor(i);
      }
    }
  }, [id, managerListing]);

  const handlePrevButton = () => {
    if (props.offset > 0) {
      dispatch(sendAnalitics("LN_MV_FW_Web"));
      props.nextTrigger();
      props.setOffset(props.offset - 15);
      dispatch(
        getManagerList({
          offset: props.offset,
          search: props.search,
          sortAs: "",
          sortBy: "",
        })
      );
    }
    console.log("X--");
  };

  const handleNextButton = () => {
    if (managerList - props.offset > 15) {
      dispatch(sendAnalitics("RN_MV_FW_Web"));
      props.nextTrigger();
      props.setOffset(props.offset + 15);
      dispatch(
        getManagerList({
          offset: props.offset,
          search: props.search,
          sortAs: "",
          sortBy: "",
        })
      );
    }
    console.log("X++");
  };

  return (
    <div className="main-all-customer-list">
      <div id="scrollableDivCustomer" className="div-table">
        <div className="div-table-row">
          <div className="listHeaderCont">
            <div className="div-table-col width50 FontBold">Manager</div>
          </div>
        </div>

        {managerListing?.map((item, i) => {
          return (
            <div
              className={
                color === i
                  ? "div-table-row actve-button-chosen-for-customer"
                  : "div-table-row"
              }
              key={i}
            >
              <div className="rowCont " onClick={() => handleClick(item, i)}>
                <div class="div-table-col width50 FontNB d-flex MasterFastagTable">
                  {/* <div data-letters={firstName_initials.toUpperCase()}> */}
                  <div data-letters-vehicle={props.offset + i + 1}></div>
                  <div>
                    <span className=" FontNB">{item?.User?.fullName}</span>
                    {/* <p className="FontNB"> {regName}</p> */}
                    <div class=" FontSmall">{item?.User?.phone}</div>
                  </div>
                </div>

                <div class="div-table-col width50 FontSmall d-flex MasterFastagTable align-items-center">
                  <div style={{ color: "#485B7C", marginRight: 5 }}>
                    Manage Access
                  </div>
                  <SvgImage name="editLogo" width="18" height="18" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="nextRow ">
        <div className="nextRowCont ">
          <button
            className="PrevButton"
            onClick={handlePrevButton}
            style={{ opacity: props.offset > 0 ? "1" : "0.5" }}
          >
            <SvgImage name="PrevArrow" heigth="12px" width="12px" />
          </button>
          <button
            className="nextButton"
            onClick={handleNextButton}
            style={{
              opacity: managerList - props.offset > 15 ? "1" : "0.5",
            }}
          >
            <SvgImage name="NextArrow" heigth="12px" width="12px" />
          </button>
        </div>
      </div>
    </div>
  );
}
export default ManagerDetailsCard;
