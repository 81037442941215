import React, { useState } from "react";
import { Button, Modal, ModalBody, FormGroup, Form } from "reactstrap";
import "./index.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import {
  storeMedia,
  toastStatus,
} from "../../../../services/Store/Actions/appActions";
import { addFleet } from "../../../../services/Store/Actions/fleetActions";
import { fleetDetails } from "../../../../services/Store/Actions/manageVehicleAction";
import {
  addTripAddress,
  findTripAddress,
  getPinCodeData,
  getSavedAddress,
} from "../../../../services/Store/Actions/TripsAction";
import Toast from "../../../subComponents/toast";

function AddAddress(props) {
  const { toggle, address, setAddress } = props;
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [displayName, setDisplayName] = useState([]);
  const closeBtn = (
    <button
      className="close"
      style={{
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        backgroundColor: "#FFF",
        borderColor: "#2E7C7B",
        display: "flex",
        justifyContent: "space-evenly",
      }}
      onClick={toggle}
      type="button"
    >
      <div className="x">X</div>
    </button>
  );
  //   const validationSchema = Yup.object().shape({
  //     vrn: Yup.string()
  //       .required("Required")

  //   });
  const handleCloseFreight = () => {
    setAddress(!address);
  };

  return (
    <div>
      <Modal
        isOpen={address}
        onHide={handleCloseFreight}
        className="modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody style={{}}>
          <div>
            <div>
              <div className="closeBtn-small-modal" onClick={toggle}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="crossBtnWIthBackground"
                  width="20%"
                  height="20%"
                />
              </div>
            </div>
            <div className="small-modal-heading">Add Address</div>
            <hr
              style={{ marginLeft: "25px", border: "2px solid #2E7C7B" }}
            ></hr>
            <Formik
              enableReinitialize
              //   validationSchema={validationSchema}
              initialValues={{
                address: "",
                cityName: "",
                stateName: "",
                pincode: "",
                postOffice: "",
              }}
              onSubmit={(values) => {
                dispatch(
                  addTripAddress(
                    {
                      address: values?.addressName + " " + values?.postOffice,
                      cityName: values?.cityName,
                      stateName: values?.stateName,
                      pincode: values?.pincode,
                    },
                    (response) => {
                      // actions.setSubmitting(false);
                      console.log("respppppppppp", response);
                      if (response?.data?.type === "success") {
                        dispatch(
                          getSavedAddress({}, (res) => {
                            console.log("getSavedAddress", res);
                          })
                        );
                        props.toggle();
                      }
                    }
                  )
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "50px", padding: "0 75px 0 50px" }}
                >
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "30px",
                    }}
                  >
                    <FormGroup>
                      <label for="pincode" className="heading3 semiBold">
                        Pincode:
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          type="text"
                          id="pincode"
                          className={`form-control-2 ${
                            touched.pincode && errors.pincode
                              ? "is-invalid"
                              : ""
                          }`}
                          name="pincode"
                          onChange={(event) => {
                            setFieldValue("pincode", event.target.value, true);
                            if (event.target.value.length == 6) {
                              dispatch(
                                getPinCodeData(
                                  { pincode: event.target.value },
                                  (res) => {
                                    console.log(
                                      "getPinCodeData",
                                      res?.data?.data
                                    );
                                    setFieldValue(
                                      "cityName",
                                      res?.data?.data?.city,
                                      true
                                    );
                                    setFieldValue(
                                      "stateName",
                                      res?.data?.data?.state,
                                      true
                                    );
                                    if (
                                      res?.data?.data?.addresses?.rows
                                        ?.length == 1
                                    )
                                      setFieldValue(
                                        "postOffice",
                                        res?.data?.data?.addresses?.rows?.[0]
                                          ?.postOffice,
                                        true
                                      );
                                  }
                                )
                              );
                            }
                          }}
                          value={values.pincode}
                          touched={touched.pincode}
                          errors={errors.pincode}
                          placeholder="pincode"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label for="addressName" className="heading3 semiBold">
                        Address :
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          type="text"
                          id="addressName"
                          className={`form-control-2 ${
                            touched.addressName && errors.addressName
                              ? "is-invalid"
                              : ""
                          }`}
                          name="addressName"
                          onChange={handleChange("addressName")}
                          value={values.addressName}
                          touched={touched.addressName}
                          errors={errors.addressName}
                          placeholder="address"
                        />
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // marginTop: "30px",
                    }}
                  >
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Post Office :
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        {values?.postOffice}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label for="city" className="heading3 semiBold">
                        City Name :
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        {values?.cityName}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label for="state" className="heading3 semiBold">
                        State Name :
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        {values?.stateName}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "25px",
                    }}
                  >
                    <Button
                      style={{ width: "25%" }}
                      className="notGenerateOpt text4 Medium btn btn-secondary"
                      onClick={props.toggle}
                    >
                      Back
                    </Button>
                    <Button
                      style={{ width: "25%" }}
                      className="generateOtp text3 Medium btn btn-secondary"
                      onClick={""}
                    >
                      Next
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>

      {/* <AddVehicle1
        toggle = {toggleAddVehicle1}
        modal ={vehicle}
      //   className={className}
      /> */}
      {/* <AddDriverV2
         toggle = {toggleAddDriverV2}
         modal  = {driverV2}
      />
      <FreightDetails
          toggle = {toggleFreightDetails}
          modal  = {fr_Details}
      /> */}
    </div>
  );
}

export default AddAddress;
