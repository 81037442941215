import SvgImage from "../../Icons/Icons";
import "./index.css";
import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddBalanceButton from "../Buttons/addBalancebutton";
import MasterAccount from "../SubComponents/MasterAccount";
import { getDriverList, getDriverListHome } from "../../../services/Store/Actions/driverslistActions";
import { fetchBalance, fetchCashBackBalance, getRecentCardTransaction } from "../../../services/Store/Actions/manageCardActions";
import { getMasterWalletBalance } from "../../../services/Store/Actions/masterWalletActions";
import { cleanFastagWalletTransaction, getFastagWalletBalance, getFastagWalletTransaction } from "../../../services/Store/Actions/fastagAction";
import { getTransferCollection } from "../../../services/Store/Actions/transferAction";
import { allCardsBalance } from "../../../services/Store/Actions/cardWalletAction";
import { getCustomers, getLogiPeAccount } from "../../../services/Store/Actions/receivableActions";
import TransferAmount from "../SubComponents/TransferAmount";
import CashbackDiv from "../SubComponents/CashbackDiv";
import CollectionDiv from "../SubComponents/CollectionDiv";
import LowerDiv from "../SubComponents/LowerDivs";
import LogipeAccountButtons from "../../Modals/logipeAccount";
import Toast from "../../subComponents/toast";
import OwnerCardModal from "../../Modals/OwnerCardModal";
import DriverCardModal from "../../Modals/DriverCardModal";
import { addDeviceIdAction, sendAnalitics } from "../../../services/Store/Actions/appActions";
import { ToastContainer, toast } from 'react-toastify';
import {
  getAllCustomersAction,
  clearAllCustomers,
} from "../../../services/Store/Actions/customerAction";
import CompleteKYC from "./Banners/CompleteKYC";
import { managerGetControlAction } from "../../../services/Store/Actions/UserManagementAction";
import OnVendor from "../../Modals/onVendors";


function NewHome(props) {

  const {deviceToken, isTokenFound, sideBar }= props;
  const userr = sessionStorage.getItem("_user");
  const storedAPIs=sessionStorage.getItem("_apiList");
  console.log("gggggggg-------", storedAPIs);
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.userId;
  const fullnameSession = obj?.user?.fullName;
  const phoneSession = obj?.user?.phone;
  const userStatus = obj?.user?.status;
  const { authUser } = useSelector((state) => state?.loginReducer);

  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [btn, setBtn] = useState(0);
  const [active, setActive] = useState(0);
  const [comp, setComp] = useState("Master Account");
  const [proceed, setProceed] = useState(0);
  const dispatch = useDispatch();
  const [displayName, setDisplayName] = useState([]);
  const [copyIcon, setCopyIcon] = useState(0);
  const [copyIconIfsc, setCopyIconIfsc] = useState(0);
  const [proceed1, setProceed1] = useState(0);
  const [enterButton, setEnterButton] = useState(0);
  const [disable, setDisable] = useState(true);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const[nkCount,setNkCount]=useState(0);
  const[nkDriver,setNkDriver]=useState([]);
  const[nkDriverVehicle,setNkDriverVehicle]=useState([]);
  const handleShow = () => {
    //dispatch(actions());
    // dispatch(toastStatus(true));
    dispatch(sendAnalitics("Add_Bal_MA_Web"));
    setOpen(true);
  };

  function nextSection() {
    setProceed(1);
  }

  // this is used to set the active buttons out of 3
  function handleActiveButton(val) {
    //console.log(val);
    setActive(val);
    setEnterButton(0);
    setDisplayScreen(0);
    setShowList(0);
    setProceed1(0);
    //setNewProceed (0);
  }

  // this is used to set values after the close button is clicked
  function toggleModal2() {
    setDisplayName(displayName.filter((e) => e.name === ""));
    setShow(!show);
    setShowList(0);
    setDisplayScreen(0);
    setProceed1(0);
    // setNewProceed (0);
    setEnterButton(0);
    //alert("1");
  }

  //const OwnerBalance = amount == null ? 0 : amount;
  const [showList, setShowList] = useState(0);
  const [displayScreen, setDisplayScreen] = useState(0);

  const handleModale2Close = () => {
    setDisplayName(displayName.filter((e) => e.name === ""));
    setShow(false);
    setShowList(0);
    setDisplayScreen(0);
  };

  const handleModale2Show = (opt) => {
    //console.log(opt);
    setShow(true);

    // opt === "card wallet"
    //   ? setActive(0)
    //   : opt === "fastag wallet"
    //   ? setActive(1)
    //   : opt === "bank account"
    //   ? setActive(2)
    //   : opt === "Transfer Balance"
    //   ? setActive(3)
    //   : opt === "Withdraw Balance"
    //   ? setActive(4)
    //   : opt === "Link New Fastag"
    //   ? setActive(5)
    //   : setActive(6);
      opt === "FASTag Wallet"
      ? setActive(1)
      :setActive(2)
  };

  function toggleModal() {
    setCopyIconIfsc(0);
    setCopyIcon(0);
    setOpen(!open);
    setProceed(0);
  }

  function handleClick(val) {
    if (btn !== val) {
      setBtn(val);
    }
  }

  const handleClose = () => {
    setCopyIconIfsc(0);
    setCopyIcon(0);
    setOpen(false);
    setProceed(0);
    //alert("2");
  };

  const details = useSelector((state) => state?.loginReducer?.authUser?.user);
  const company = useSelector((state) => state?.loginReducer?.authUser);
  const {  allDriverListAdvance } = useSelector(
    (state) => state?.driverlist
  );
  // console.log("====>>>>>",customerList,customerNo);
  const[ownerCardStatus, setOwnerCardStatus]= useState("0");
  const [ownerCardType, setOwnerCardType]=useState("0");
    useEffect(() => {
      if(isTokenFound){
        if(deviceToken!==""){
          if(deviceToken!==undefined){
            console.log("device id", deviceToken)
            dispatch(addDeviceIdAction({
              deviceId:deviceToken
            }))
          }
        }
      }
      dispatch(sendAnalitics("Home_Page_web",{
        userId:userIdSession,
        name:fullnameSession,
        phone:phoneSession
      }));
        // dispatch (getDriverList29)
        dispatch(
          getDriverListHome({
            offset: "",
            search: "",
            minKyc: "",
            sortAs: "",
            sortBy: "",
          })
        );
        dispatch(
          getRecentCardTransaction({
            userId: userIdSession,
            companyId: details?.companyId,
          })
        );
        dispatch(managerGetControlAction({ userId: userIdSession }));
        dispatch(getMasterWalletBalance());
        //dispatch(transactionHistory());
        // dispatch(
        //   transactionHistory({
        //     limit: "",
        //     search: "",
        //     minKyc: "",
        //     sortAs: "",
        //     sortBy: "",
        //   })
        // );
        dispatch(fetchBalance());
        //dispatch(getWalletTransactionHistory());  //Logipe Account Transaction history
        dispatch(getFastagWalletBalance());
        // dispatch(
        //   getFastagWalletTransaction({
        //     limit: "",
        //     search: "",
        //     minKyc: "",
        //     sortAs: "",
        //     sortBy: "",
        //   })
        // );
        dispatch(getTransferCollection());
        dispatch(allCardsBalance());
        dispatch(
          getCustomers({
            offset: 0,
            search: "",
            minKyc: "",
            sortAs: "",
            sortBy: "",
          })
        );
    
        //dispatch(getFastagWalletBalance());
    
        // dispatch(
        //   getCustomers({
        //     offset: 0,
        //     search: '',
        //     minKyc: "",
        //     sortAs: "",
        //     sortBy: "",
        //   })
        // );
    
        dispatch(fetchCashBackBalance());
        //dispatch(getOwnerDetails());
        dispatch(getLogiPeAccount());

        dispatch(
          getAllCustomersAction({
            offset: "",
            search: "",
            minKyc: "",
            sortAs: "",
            sortBy: "",
          })
        );
        return function cleanUp() {
          dispatch(clearAllCustomers());
          dispatch(clearAllCustomers());
        };
      }, []); 
      
  const data=[
    {
        Title:"Driver Cards",
        SvgName1:"HomeDriver",
        SvgName2:"ManageDrivers",
        subHead:"Manage Drivers",
        text:"Total number of Drivers",
        text1:"Total Balance",
        text2:"Spent This Month",
        type:'wallet'
    },
    {
        Title:"FASTag Wallet",
        SvgName1:"HomeFastag",
        SvgName2:"ManageVehicles",
        subHead:"Manage Vehicles",
        text:"Total number of Vehicles",
        text1:"Total Balance",
        text2:"",
        type:'fastag'
    },
    {
        Title:"Customers",
        SvgName1:"HomeCustomers",
        SvgName2:"ManageCustomers",
        subHead:"Manage Customers",
        text:"Active Customers",
        text1:"Amount Received",
        text2:"Pending Balance",
        type:'customer'
    }
  ]  
  

  // variables for the transfers to cards
  const [openModal, setOpenModal] = useState(false);
  
  const closeModal = () => {
    setOpenModal(false);
    //setProceed(0);
    //console.log("closed");
  };

  function handleTOggle() {

    setOpenModal(!openModal);
    //setProceed(0);
  }

  const showModal = (opt) => {
    opt === "Personal Card"
      ? setActive(3)
      : setActive(4);
      setNkDriver(allDriverListAdvance.filter((e) => 
        e?.WalletAccount?.neoKred?.accountStatus == 4
      ));
      setNkCount(allDriverListAdvance.filter((e) => 
      e?.WalletAccount?.neoKred?.accountStatus == 4
    ).length);
    setNkDriverVehicle(allDriverListAdvance.filter((e) => 
    e?.vrn != null
  ));
    setOpenModal(true);
    //console.log(a);
  };

  useEffect(()=>{
    // dispatch(
    //   getDriverList({
    //     offset: "",
    //     search: "",
    //     minKyc: "",
    //     sortAs: "",
    //     sortBy: "",
    //   })
    // );
  },[openModal])
  
  console.log("sandeep1",userIdSession);
  return (
    <div div className="main-home sideBarMargin" style={{marginLeft: sideBar? '240px' : ''}}>
      <div className="row">
        <div className="col-lg-7 col-md-12 paddingR" style={{}}>
          {
            (userStatus===0)?
              <CompleteKYC />
              :(userStatus===1)?
              <CompleteKYC />
              :(userStatus===2)?
              <CompleteKYC />
              :
            <div className="row padding16 whiteBackground roundCorner"  disabled={authUser?.user?.type==3 && !managerControlList?.masterAccount}>
              <div className="col-7">
                <MasterAccount handleShow={handleShow}
                  copyIconIfsc={copyIconIfsc}
                  setCopyIconIfsc={setCopyIconIfsc}
                  copyIcon={copyIcon}
                  setCopyIcon={setCopyIcon}
                  open={open}
                  toggleModal={toggleModal}
                  title="Master Account"
                  handleClick={handleClick}
                  handleClose={handleClose}
                  btn={btn}
                  nextSection={nextSection}
                  proceed={proceed}
                  show={show}
                />
              </div>
              <div className="col-5 whiteBackground">
                  <TransferAmount onClickFunc={handleModale2Show} clickFunc={showModal} userId={userIdSession}
                    setOwnerCardType={setOwnerCardType} />
              </div>
              {
                active===1?
                <LogipeAccountButtons
                  open={show}
                  toggleModal={toggleModal2}
                  title="fastag wallet"
                  handleClick={handleActiveButton}
                  handleClose={handleModale2Close}
                  btn={active}
                  
                  showList={showList}
                  setShowList={setShowList}
                  setDisplayScreen={setDisplayScreen}
                  displayScreen={displayScreen}
                  proceed1={proceed1}
                  setProceed1={setProceed1}
                />
                :active===2?
                <OnVendor
                  isOpen={show}
                  toggleModal={toggleModal2}
                  title="bank account"
                  handleClick={handleActiveButton}
                  handleClose={handleModale2Close}
                  btn={active}
                  company={company && company!=null ? company : false}
                  showList={showList}
                  setShowList={setShowList}
                  setDisplayScreen={setDisplayScreen}
                  displayScreen={displayScreen}
                  proceed1={proceed1}
                  setProceed1={setProceed1}
                  enterButton={enterButton}
                  setEnterButton={setEnterButton}
                  // company={company}
                />
                
                // <LogipeAccountButtons
                //   open={show}
                //   toggleModal={toggleModal2}
                //   title="bank account"
                //   handleClick={handleActiveButton}
                //   handleClose={handleModale2Close}
                //   btn={active}
                  
                //   showList={showList}
                //   setShowList={setShowList}
                //   setDisplayScreen={setDisplayScreen}
                //   displayScreen={displayScreen}
                //   proceed1={proceed1}
                //   setProceed1={setProceed1}
                //   enterButton={enterButton}
                //   setEnterButton={setEnterButton}
                // />
                :active===3?
                <OwnerCardModal
                  open={openModal}
                  toggleModal={handleTOggle}
                  handleClose={closeModal}
                  userId={userIdSession}
                  ownerCardType={ownerCardType}
                />
                :<DriverCardModal
                open={openModal}
                toggleModal={handleTOggle}
                handleClose={closeModal}
                userId={userIdSession}
                nkDriver = {nkDriver}
                setNkDriver = {setNkDriver}
                nkCount = {nkCount}
                nkSetCount = {setNkCount}
                nkDriverVehicle = {nkDriverVehicle}
                setNkDriverVehicle = {setNkDriverVehicle}
              />
              }
            </div>
            }
        </div>
        <div className="col-lg-5 col-md-12 padding0L padding16R">
            <div className="whiteBackground roundCorner " style={{padding:"0.5rem 1rem"}}>
                <CashbackDiv />
            </div>
            <div className="whiteBackground roundCorner" style={{marginTop:"1rem",height: "45%"}} >
                <CollectionDiv />
            </div>
        </div>
      </div>
      <div className="row padding16T">
        {
            data?.map((item)=>{
                return (
                    <div className="col-lg-4 col-md-12 padding8T padding16R padding0L">
                        <div className="padding16 whiteBackground roundCorner" disabled={authUser?.user?.type==3 && !managerControlList?.[item?.type]}>
                        <LowerDiv
                            Title={item?.Title}
                            SvgName1={item?.SvgName1}
                            SvgName2={item?.SvgName2}
                            subHead={item?.subHead}
                            text={item?.text}
                            text1={item?.text1}
                            text2={item?.text2}

                        />
                        </div>
                    </div>
                )
            })
        }
      </div>
      {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={!toastObject?.toastStatus}
          isOpen={toastObject?.toastStatus}
          name="home"
          handleClose={() => {
            // dispatch(
            //   toastStatus({
            //     toastStatus: false,
            //     message: "",
            //     status: true,
            //   })
            // );
            // if (!toastObject?.toastStatus)
            window.location = "/";
          }}
        />
      ) : (
        <></>
      )}
      <ToastContainer theme="dark" position="bottom-right"
          closeOnClick pauseOnHover
          autoClose={4000}/>
    </div>
  );
}

export default memo(NewHome);
