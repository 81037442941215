import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleListAction } from "../../../../services/Store/Actions/manageVehicleAction";
import { getLRList } from "../../../../services/Store/Actions/TripsAction";

function TripInvoice(props) {
  const {
    isCheckAll,
    setIsCheckAll,
    isCheck,
    setIsCheck,
    tripSelect,
    setTripSelect,
    customerId
  } = props;
  const { freightList, freightListCount } = useSelector((state) => state?.trip);
  const allFleets = useSelector((state) => state?.manageVehicle?.allFleets);
  const vehCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );
  const fastVeh =
    vehCount > 0
      ? allFleets?.rows?.find((ele) => ele?.Fastag !== null)
      : "value";
  console.log("====>>>>>>>", freightList, freightListCount);
  const [vrn, setVrn] = useState("");
  const [fleetId, setFleetId] = useState("");
  const [open, setOpen] = useState("");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getVehicleListAction({
        offset: "",
        search: vrn,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [vrn]);
  useEffect(() => {
    dispatch(
      getLRList({
        offset: 0,
        limit: 10,
        fleetId: fleetId,
        from: from || "",
        to: to || "",
        customerId: customerId,
      })
    );
  }, [fleetId, from, to]);
  return (
    <div>
      <div
        className="d-flex"
        style={{ marginLeft: "50px", marginBottom: "24px" }}
      >
        <div>
          <div className="headingText">Vehicle No:</div>
          <input
            type="text"
            className="text-input-placeholder mt-2"
            placeholder={"Enter vehicle number"}
            onChange={(event) => {
              setOpen(true);
              setVrn(event.target.value);
              if (event.target.value == "") {
                setFleetId("");
              }
            }}
            onFocus={() => {
              setOpen(true);
            }}
            onBlur={() => {
              setOpen(false);
            }}
            value={vrn}
            // onkeypress={setStorage}
          />
          <div style={{ position: "relative" }}>
            {open ? (
              <div
                className="driverSearchContainer containerShadow"
                style={{
                  // width: "100%",
                  marginTop: "5px",
                  borderRadius: "8px",
                  border: "1px solid #E2E2E2",
                }}
              >
                {/* <div
                          style={{
                            position: "absolute",
                            top: "0.3rem",
                            right: "0.5rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <SvgImage name="CloseButtonLogo" />
                        </div> */}

                {allFleets?.rows?.map((item, i) => {
                  return (
                    <>
                      {/* {item?.Fastag !== null ? ( */}
                      <div
                        key={i}
                        className="flexStyling padding8 "
                        onClick={(event) => {
                          setOpen(false);
                          setFleetId(item?.fleetId);
                          setVrn(item?.regNumber);
                          // setUserId(event.target.value);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        <input
                          type="radio"
                          name="vrn"
                          value={item?.fleetId}
                          id={item?.fleetId}
                        />
                        <div className="padding16L" value={item?.fleetId}>
                          {item?.regNumber}
                        </div>
                      </div>
                      {/* ) : (
                                    <></>
                                  )} */}
                    </>
                  );
                })}
                {vehCount == 0 ? (
                  <div className="heading3 semiBold padding16 padding24B">
                    No Vehicle Available
                  </div>
                ) : fastVeh == undefined ? (
                  <div className="heading3 semiBold padding16 padding24B">
                    No Vehicle Available
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="ms-4">
          <div className="headingText">From:</div>
          <input
            type="date"
            className="text-input-placeholder mt-2"
            placeholder={"Select Date"}
            onChange={(event) => {
              setFrom(event.target.value);
            }}
            max={to}
            value={from}
            // onkeypress={setStorage}
          />
        </div>
        <div className="ms-4">
          <div className="headingText">To:</div>
          <input
            type="date"
            className="text-input-placeholder mt-2"
            placeholder={"Select Date"}
            onChange={(event) => {
              setTo(event.target.value);
            }}
            min={from}
            value={to}
            // onkeypress={setStorage}
          />
        </div>
      </div>

      <Table
        className="TripSectionTable"
        bordered="true"
        id="driver_tables" //
        style={{ height: "40vh" }}
      >
        <thead>
          <tr className="table-header-trip p-0 align-middle text-center">
            <th className="p-0" style={{ width: "7%" }}>
              <input
                type="checkbox"
                name="selectAll"
                style={{ marginLeft: "10px" }}
                checked={isCheckAll}
                id="selectAll"
                onChange={(e) => {
                  setIsCheckAll(!isCheckAll);
                  setIsCheck(
                    freightList.map((li) => (li?.freightId).toString())
                  );
                  setTripSelect(freightList);
                  if (isCheckAll) {
                    console.log("===<<<<<initttt");
                    setIsCheck([]);
                    setTripSelect([]);
                  }
                  console.log("===<<<<<nmtknikn1", isCheckAll, isCheck);
                  console.log("====>>>>>>>1", tripSelect);
                }}
              />
            </th>
            <th className="p-0" style={{ width: "16%" }}>
              <div>Lr NO</div>{" "}
            </th>
            <th className="p-0" style={{ width: "14%" }}>
              <div>Vehicle Number</div>{" "}
            </th>
            <th className="p-0" style={{ width: "16%" }}>
              From
            </th>
            <th className="p-0" style={{ width: "16%" }}>
              To
            </th>
            <th className="p-0" style={{ width: "16%" }}>
              Completed On
            </th>
            {/* <th className="p-0" style={{ width: "14%" }}>
              Tonnage
            </th> */}
            <th className="p-0" style={{ width: "16%" }}>
              Freight Amount
            </th>
          </tr>
        </thead>
        <tbody className="lowerBorderLineCust">
          {freightList?.map((items, i) => {
            console.log("saqwretyadfsdghadsfgbnhgfascdvfbgngdfsdfggfds", items);
            return (
              <tr
                className="table-row-manage-trip-new text4 align-middle text-center p-0"
                onClick={() => {
                  // window.location = "/Customers/" + 1;
                  //   navigate("/Customers/" + 1);
                }}
              >
                <td
                  style={{
                    width: "7%",
                    borderLeft: "1px solid transparent",
                    borderRight: " 1px solid transparent",
                  }}
                  className="p-0"
                >
                  <input
                    type="checkbox"
                    name="trips2"
                    id={(items?.freightId).toString()}
                    style={{ marginLeft: "10px" }}
                    checked={isCheck.includes((items?.freightId).toString())}
                    onChange={(e) => {
                      console.log("===<<<<<nmtknikn1");
                      const { id, checked } = e.target;
                      console.log("===<<<<<nmtknikn1", id, checked, isCheck);
                      let d = freightList?.filter(
                        (li) => (li?.freightId).toString() == id
                      )[0];
                      setIsCheck([...isCheck, id]);
                      setTripSelect([...tripSelect, d]);
                      if (!checked) {
                        setIsCheck(isCheck.filter((item) => item != id));
                        setTripSelect(
                          tripSelect.filter(
                            (item) => (item?.freightId).toString() != id
                          )
                        );
                      }
                      console.log("====>>>>>>>2", tripSelect);
                    }}
                  />
                </td>
                <td
                  style={{
                    width: "16%",
                    borderLeft: "1px solid transparent",
                    borderRight: " 1px solid transparent",
                  }}
                  className="p-0"
                >
                  {items?.lrNo}
                </td>
                <td
                  style={{
                    width: "16%",
                    borderLeft: "1px solid transparent",
                    borderRight: " 1px solid transparent",
                  }}
                  className="p-0"
                >
                  {items?.Fleet?.regNumber}
                </td>
                <td
                  style={{
                    width: "16%",
                    borderLeft: "1px solid transparent",
                    borderRight: " 1px solid transparent",
                  }}
                  className="p-0"
                >
                  {items?.fromAddress?.address}, {items?.fromAddress?.cityName}
                </td>
                <td
                  style={{
                    width: "16%",
                    borderLeft: "1px solid transparent",
                    borderRight: " 1px solid transparent",
                  }}
                  className="p-0"
                >
                  {items?.toAddress?.address}, {items?.toAddress?.cityName}
                </td>
                <td
                  style={{
                    width: "16%",
                    borderLeft: "1px solid transparent",
                    borderRight: " 1px solid transparent",
                  }}
                  className="p-0"
                >
                  {items?.endDate || "-"}
                </td>
                <td
                  style={{
                    width: "14%",
                    borderLeft: "1px solid transparent",
                    borderRight: " 1px solid transparent",
                  }}
                  className="p-0"
                >
                  {items?.freightAmount}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default TripInvoice;
