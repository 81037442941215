import React, { useState, useRef } from "react";
import { DatePickerField } from "../../core/inputs/input";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { ToggleSlider } from "react-toggle-slider";
import moment from "moment";
import AddingFilter from "../../Modals/addingFilter";
import PinInput from "react-pin-input";
import {
  addExpenseToWallet,
  getDriverBal,
} from "../../../services/Store/Actions/driverslistActions";
import { useDispatch, useSelector } from "react-redux";
import { storeMedia } from "../../../services/Store/Actions/appActions";
import InputFils from "./inputFils";
import {
  addDriverExpense,
  getCashTransactions,
} from "../../../services/Store/Actions/TripsAction";
import SelectVendorModal from "../../Vendor/Components/Modal/SelectVendorModal";

function ManageDriverAddNewExpense(props) {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    userId,
    expenseType,
    itemTemp,
    remarks,
    amount,
    fleetId,
    tripKey
  } = props;
  console.log(userId);
  const dispatch = useDispatch();
  const [selectVendorModal, setSelectVendorModal] = useState(false);
  const [vendorData, setVendorData] = useState(false);
  const { loader } = useSelector((state) => state?.driverlist);
  console.log("button status", loader);
  const [search, setSearch] = useState("");
  const datepickerRef = useRef();
  const handleClickDatepickerIcon = () => {
    const datepickerElement = datepickerRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Add New Expense</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          {/* <div>
          <div className="sub-title-1">
            <span class="circle-logo">1</span>
            <p className="sub-head-3">Select One</p>
          </div>
          <button
            type="button"
            className={btn === 5 ? "butt1 mt-0" : "set-btn-1 mt-0"}
            onClick={(e) => handleClick(5)}
          >
            {btn === 5 ? <SvgImage name="TickLogo" /> : <></>}Add Expense
          </button>
          <button
            type="button"
            className={btn === 6 ? "set-btn-2 mt-0" : "butt2 mt-0"}
            onClick={() => handleClick(6)}
          >
            {btn === 6 ? <SvgImage name="TickLogo" /> : <></>}Cash Given
          </button>
        </div> */}
          <ModalBody>
            <Formik
              // enableReinitialize
              initialValues={{
                amount: amount || "",
                transferType: "debit",
                expenseType: expenseType || "default",
                dob: new Date(),
                remarks: remarks || "",
                docs: [],
                givenTo: "",
                id: "",
                vendor: "",
                pin: "",
                // pickedImage: "",
              }}
              validationSchema={Yup.object().shape({
                amount: Yup.number()
                  .typeError("Only Numbers are allowed")
                  .min(1, "Amount should be greater than 1")
                  .required("Amount is required"),
                expenseType: Yup.string()
                  .required("Expense Type is required")
                  .test(
                    "noDefaultValue",
                    "Please select expense type",
                    (val) => val != "default"
                  ),
                dob: Yup.string().required("Expense Date is required"),
                remarks: Yup.string().required("Remark is required"),
                pin: Yup.string().required("Pin is required"),
                // password: Yup.string()
                //   .min(6, 'Password must be at least 6 characters')
                //   .required('Password is required'),
                // confirmPassword: Yup.string()
                //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
                //   .required('Confirm Password is required')
              })}
              onSubmit={(values, actions) => {
                // console.log("values here", initialValues);
                values.dob = moment(values.dob);
                //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
                console.log("valuesrrrrrrrsss", values);
                let payload = {
                  amount: values.amount,
                  transferType: 0,
                  expanseTag: values.expenseType,
                  dob: values.dob,
                  remarks: values.remarks,
                  docs: values.docs,
                  expanseType: "2",
                  pin: values?.pin,
                  tripId: itemTemp?.tripId
                };
                if (values?.givenTo == "Vendor") {
                  payload["vendorId"] = values.id;
                } else if (values?.givenTo == "Driver") {
                  payload["userId"] = values.id;
                }
                if (values?.givenTo == "Vehicle") {
                  payload["fleetId"] = fleetId;
                }
                if (tripKey) {
                  payload["tripKey"] = tripKey;
                }
                console.log(
                  "valuesrrrrrrrsss",
                  values,
                  payload,
                  values.expenseType
                );
                // console.log("valuessss", values);
                dispatch(
                  addDriverExpense(payload, (res) => {
                    actions.setSubmitting(false);
                    console.log("addDriverExpense res", res);
                    // if (userId == " " || userId == null) {
                    //   <Alert>Please select the driver</Alert>;
                    // }
                    if (res?.status == 200) {
                      console.log("successful vaiii");
                      //navigate("/Customers")
                      if (props?.type) {
                        const reqData = {
                          fleetId: props?.item?.fleetId,
                          userId: props?.item?.userId,
                          startTime: props?.item?.startTime,
                          endTime:
                            props?.item?.isActive > 0
                              ? new Date()
                              : props?.item?.endTime || "",
                          // endTime: endTime || "",
                          transType: "debit",
                          limit: 2,
                          cardUserId: props?.item?.cardUserId,
                          offset: 0,
                        };
                        dispatch(getCashTransactions(reqData));
                      }
                      toggleModal();
                    } else {
                      console.log("failed to add");
                      toggleModal();
                    }
                  })
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <label className="sub-head-3 ">Enter Amount</label>
                    </div>

                    <input
                      type="text"
                      pattern="[0-9]*"
                      id="amount"
                      className={
                        "input-body-3 form-control" +
                        (errors.amount && touched.amount ? " is-invalid" : "")
                      }
                      // style={{ marginLeft: "94px" }}
                      name="amount"
                      value={values.amount}
                      touched={touched.amount}
                      errors={errors.amount}
                      onBlur={handleBlur("amount")}
                      onChange={handleChange("amount")}
                      placeholder="Enter the amount"
                    ></input>
                    {/* <div className="input-amount-for-link"></div> */}
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>
                    {/* <ErrorMessage
                      name="amount"
                      component="div"
                      className="invalid-feedback"
                    /> */}
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-head-3">Expense Type / Cash given</p>
                    </div>
                    <select
                      name="expenseType"
                      class="form-select select-option"
                      aria-label="Default select example"
                      className={
                        "input-body-3 form-control" +
                        (errors.expenseType && touched.expenseType
                          ? " is-invalid"
                          : "")
                      }
                      value={values.expenseType}
                      defaultValue={values.expenseType}
                      onChange={(e) =>
                        setFieldValue("expenseType", e.target.value, true)
                      }
                    >
                      <option
                        value="default"
                        disabled
                        hidden
                        className="greyFontImp"
                        style={{ color: "#6C6C6C" }}
                      >
                        --Select--
                      </option>
                      <option value="CASH GIVEN">CASH GIVEN</option>
                      <option value="Food">Food</option>
                      <option value="Fuel">Fuel</option>
                      <option value="Loading">Loading</option>
                      <option value="Unloading">Unloading</option>
                      <option value="Oil">Oil</option>
                      <option value="Repair">Repair</option>
                      <option value="RTO">RTO</option>
                      <option value="Toll">Toll</option>
                      <option value="Tyre">Tyre</option>
                      <option value="Salary">Salary</option>
                      <option value="Incentive">Incentive</option>
                      {/* <option value="3">Others</option> */}
                    </select>
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.expenseType && touched.expenseType ? (
                        <div>*{errors.expenseType}</div>
                      ) : null}
                    </div>
                    {/* <ErrorMessage
                      name="expenseType"
                      component="div"
                      className="invalid-feedback"
                    /> */}
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">3</span>
                      <p className="sub-head-3">Given To</p>
                    </div>
                    <select
                      name="givenTo"
                      class="form-select select-option"
                      aria-label="Default select example"
                      className={
                        "input-body-3 form-control" +
                        (errors.givenTo && touched.givenTo ? " is-invalid" : "")
                      }
                      value={values.givenTo}
                      defaultValue={values.givenTo}
                      onChange={(e) => {
                        setFieldValue("givenTo", e.target.value, true);
                        if (e.target.value == "Driver") {
                          setFieldValue("id", userId, true);
                        }
                      }}
                    >
                      {/* <option
                        value="default"
                        disabled
                        hidden
                        className="greyFontImp"
                        style={{ color: "#6C6C6C" }}
                      >
                        Vehicle Expense
                      </option> */}
                      <option value="Driver">Driver</option>
                      <option value="Vehicle_Expense">Vehicle Expense</option>
                      <option value="Vendor">Vendor</option>
                      {/* <option value="3">Others</option> */}
                    </select>
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.givenTo && touched.givenTo ? (
                        <div>*{errors.givenTo}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  {values.givenTo == "Vendor" ? (
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">4</span>
                        <label className="sub-head-3 ">Vendor</label>
                      </div>
                      <input
                        type="text"
                        id="vendor"
                        className={
                          "input-body-3 form-control" +
                          (errors.vendor && touched.vendor ? " is-invalid" : "")
                        }
                        // style={{ marginLeft: "94px" }}
                        name="vendor"
                        value={values.vendor}
                        touched={touched.vendor}
                        errors={errors.vendor}
                        onBlur={handleBlur("vendor")}
                        onChange={handleChange("vendor")}
                        placeholder="Select A Vendor"
                        autoFocus
                        onClick={() => {
                          setSelectVendorModal(true);
                        }}
                      ></input>

                      {/* <div className="input-id-for-link"></div> */}
                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.vendor && touched.vendor ? (
                          <div>*{errors.vendor}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  ) : null}

                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">4</span>
                      <label className="sub-head-3 ">Expense Date</label>
                    </div>
                    <DatePickerField
                      name="dob"
                      className={
                        "input-body-3 form-control" +
                        (errors.dob && touched.dob ? " is-invalid" : "")
                      }
                      value={values.dob}
                      header="Select booking date"
                      onChange={(v) => setFieldValue("dob", v, true)}
                      maxDate={new Date()}
                      ref1={datepickerRef}
                    />
                    <label
                      className="date-input"
                      style={{
                        color: "black",
                        position: "relative",
                        top: "-2rem",
                        left: "-1.4rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickDatepickerIcon();
                      }}
                    >
                      <SvgImage
                        style={{ marginRight: "1rem" }}
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        classname="date-logo"
                      />
                    </label>

                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.dob && touched.dob ? (
                        <div>*{errors.dob}</div>
                      ) : null}
                    </div>
                    {/* <ErrorMessage
                      name="dob"
                      component="div"
                      className="invalid-feedback"
                    /> */}
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">5</span>
                      <label className="sub-head-3 ">Remarks</label>
                    </div>
                    <input
                      type="text"
                      id="remarks"
                      className={
                        "input-body-3 form-control" +
                        (errors.amount && touched.amount ? " is-invalid" : "")
                      }
                      // style={{ marginLeft: "94px" }}
                      name="remarks"
                      value={values.remarks}
                      touched={touched.remarks}
                      errors={errors.remarks}
                      onBlur={handleBlur("remarks")}
                      onChange={handleChange("remarks")}
                      placeholder="Enter your remarks"
                    ></input>
                    {/* <div className="input-amount-for-link"></div> */}
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.remarks && touched.remarks ? (
                        <div>*{errors.remarks}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  {/* <FormGroup>
                    <InputFils
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                    />
                  </FormGroup> */}
                  <FormGroup>
                    <div>
                      <div className="sub-title-1">
                        <span class="circle-logo">6</span>
                        <p className="sub-head-4">Enter 4-digit Security Pin</p>
                      </div>
                      <p className="sub-head-body">
                        Security pin is needed to confirm payment
                      </p>
                      <PinInput
                        name="pin"
                        length={4}
                        initialValue=""
                        secret
                        onChange={(value, index) =>
                          setFieldValue("pin", value, true)
                        }
                        type="numeric"
                        inputMode="number"
                        style={{
                          padding: "10px",
                          marginLeft: "96px",
                          marginTop: "28px",
                        }}
                        inputStyle={{
                          border: "1px solid #A7A7A7",
                          borderRadius: "8px",
                          marginRight: "20px",
                        }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: "14px",
                          marginLeft: "6.5rem",
                        }}
                        className={
                          +(errors.pin && touched.pin ? " is-invalid" : "")
                        }
                      >
                        {" "}
                        <ErrorMessage className="is-invalid" name="pin" />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <button
                      type="submit"
                      className="butt-4"
                      style={{
                        margin: "15% 0 0 0",
                        background: loader ? "grey" : "#485B7C",
                      }}
                      disabled={loader ? true : false}
                    >
                      {/* {console.log(values)} */}
                      {loader ? "Please Wait" : "Add New Expense"}
                    </button>
                  </FormGroup>
                  <div></div>
                  {selectVendorModal && (
                    <SelectVendorModal
                      open={selectVendorModal}
                      onHide={() => {
                        setSelectVendorModal(false);
                      }}
                      toggleModal={() => {
                        setSelectVendorModal(!selectVendorModal);
                      }}
                      handleClose={() => {
                        setSelectVendorModal(false);
                      }}
                      onClickItem={(data) => {
                        // setVendorData({});
                        // setVendorData(data);
                        console.log("dadatata", data);
                        setFieldValue("id", data?.id, false);
                        setFieldValue("vendor", data?.name, false);
                        setSelectVendorModal(false);
                      }}
                      search={search}
                      setSearch={setSearch}
                    />
                  )}
                </Form>
              )}
            </Formik>
            {/* <div className="space-div"></div> */}
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default ManageDriverAddNewExpense;
