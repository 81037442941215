import React, { useState, useRef, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { DatePickerField } from "../../core/inputs/input";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { ToggleSlider } from "react-toggle-slider";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewBookingBill,
  getLogiPeAccount,
} from "../../../services/Store/Actions/receivableActions";
import {
  sendAnalitics,
  storeMedia,
} from "../../../services/Store/Actions/appActions";
import * as Yup from "yup";
import { getManagerList } from "../../../services/Store/Actions/UserManagementAction";

function AddingFilter(props) {
  const { active, toggleModal, name, handleClose, func, ma = false } = props;
  const { loader } = useSelector((state) => state?.receivable);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [display, setDisplay] = useState("");
  const [search, setSearch] = useState("");
  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required"),
  });
  const findSearch = (val) => {
    dispatch(
      getManagerList({
        offset: 0,
        search: search == "" ? val : search,
        sortAs: "",
        sortBy: "",
      })
    );
  };
  console.log(" here is name", name);
  const datepickerRefStart = useRef();
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const [color, setColor] = useState(-1);
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const { managerListing, managerList } = useSelector(
    (state) => state?.userManagement
  );
  useEffect(() => {
    console.log("mixPanelName---", props.mixPanelName);
    dispatch(
      getManagerList({
        offset: 0,
        search: "",
        sortAs: "",
        sortBy: "",
      })
    );

    if (props.mixPanelName !== undefined) {
      dispatch(sendAnalitics(props.mixPanelName));
    }
  }, []);
  // const managerCount = []
  // const managerListing = []
  console.log("managerList", managerList, managerListing);
  const managerName = managerListing?.filter(
    (data) => data?.User?.userId == props?.managerId
  );
  console.log("selectedManager", managerName[0]);
  let managerN = managerName?.[0]
    ? managerName?.[0]?.User?.fullName + "-" + managerName?.[0]?.User?.phone
    : "";
  return (
    <Modal
      isOpen={active}
      onHide={handleClose}
      className="positons settingModalMargin"
      toggle={toggleModal}
      aria-labelledby="myModalLabel2"
      style={{ position: "fixed", top: "0", right: "0", margin: "0" }}
    >
      <div
        className="modal-content"
        style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
      >
        <div className="modal-head-part">
          <p className="Add-balance-title">{name}</p>
          <Button
            type="button"
            className="close-btn"
            onClick={() => {
              if (props.MP_close !== undefined)
                dispatch(sendAnalitics(props.MP_close));
              toggleModal();
            }}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={{
              startDate: props?.startDate || "",
              endDate: props?.endDate || "",
              mode: name === "Download History" ? "default" : "L",
              manager:
                managerN != undefined && managerN != null && managerN != ""
                  ? managerN
                  : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              if (name === "Download History")
                func(values.startDate, values.endDate, values.mode);
              else func(values.startDate, values.endDate);
              toggleModal();
              //   const db = moment(values.bookingDate).format("DD-MM-YYYY");
              //   // console.log("date",moment(values.bookingDate).format(
              //   //     "DD-MM-YYYY"
              //   //   ))
              //   console.log ("date got", db);
              //   const payload = {
              //     amount: values.amount,
              //     description: "null",
              //     bookingDate: db,
              //     customerId: customerId,
              //     docs: values.docs,
              //   };
              //   // values.bookingDate = moment(values.bookingDate).format(
              //   //   "DD-MM-YYYY"
              //   // );
              //   //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
              //   console.log("valuessss", payload);
              //   // console.log("v", displayName);
              //   dispatch(
              //     addNewBookingBill(payload, (res) => {
              //       //actions.setSubmitting(false);
              //       if (res?.data?.type == "success") {
              //         //alert("successful");
              //         //navigate("/Customers")

              //         toggleModal();
              //       }
              //     })
              //   );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">1</span>
                    <label className="sub-head-3 ">Start Date</label>
                  </div>
                  <DatePickerField
                    name="startDate"
                    header="Select Start date"
                    value={values.startDate}
                    onChange={(v) => {
                      if (props.MP_sDate !== undefined)
                        dispatch(sendAnalitics(props.MP_sDate), {
                          startDate: v,
                        });
                      if (props?.ma) {
                        props?.setStartDate(v);
                      }
                      setFieldValue("startDate", v, true);
                    }}
                    maxDate={
                      values.endDate === "" ? new Date() : values.endDate
                    }
                    ref1={datepickerRefStart}
                  />
                  <label
                    className="date-input"
                    style={{
                      position: "relative",
                      top: "-2rem",
                      left: "-1.4rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleClickDatepickerIcon1();
                    }}
                  >
                    <SvgImage
                      name="CalenderLogo"
                      width="32"
                      height="32"
                      classname="date-logo"
                    />
                  </label>

                  <div className="input-amount-for-link"></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.startDate && touched.startDate ? (
                      <div>*{errors.startDate}</div>
                    ) : null}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">2</span>
                    <label className="sub-head-3 ">End Date</label>
                  </div>
                  <DatePickerField
                    name="endDate"
                    header="Select End date"
                    value={values.endDate}
                    onChange={(v) => {
                      if (props.MP_eDate !== undefined)
                        dispatch(sendAnalitics(props.MP_eDate), {
                          endDate: v,
                        });
                      if (props?.ma) {
                        props?.setEndDate(v);
                      }

                      setFieldValue("endDate", v, true);
                    }}
                    //onChange={(v) => setFieldValue("endDate", v, true)}
                    minDate={values.startDate === "" ? "" : values.startDate}
                    maxDate={new Date()}
                    ref1={datepickerRefEnd}
                  />
                  <label
                    className="date-input"
                    style={{
                      position: "relative",
                      top: "-2rem",
                      left: "-1.4rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleClickDatepickerIcon2();
                    }}
                  >
                    <SvgImage
                      name="CalenderLogo"
                      width="32"
                      height="32"
                      classname="date-logo"
                    />
                  </label>

                  <div className="input-amount-for-link"></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.endDate && touched.endDate ? (
                      <div>*{errors.endDate}</div>
                    ) : null}
                  </div>
                </FormGroup>

                {name == "Download History" && (
                  <>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">3</span>
                        <label className="sub-head-3 ">Select Manager</label>
                      </div>
                      <label for="html" className="label-body"></label>
                      <input
                        type="text"
                        id="manager"
                        className="input-body-3"
                        name="manager"
                        style={{ marginLeft: "0" }}
                        onClick={() => {
                          setDisplay(true);
                        }}
                        onChange={(event) => {
                          setDisplay(true);
                          console.log("eeeeeeeeeeeeeeeee", event.target.value);
                          // setDisplay(true);
                          setSearch(event.target.value);
                          setFieldValue("manager", event.target.value, true);
                          findSearch(event.target.value);
                        }}
                        value={values.manager}
                        onBlur={handleBlur("source")}
                        touched={touched.manager}
                        errors={errors.manager}
                        onKeyUp={(e) => {
                          console.log("ENTERED", e.key);
                          // if (e.key === "Enter") {
                          findSearch();
                          // }
                        }}
                        placeholder="--- Select ---"
                      />
                      <div className="input-amount"></div>
                      {display ? (
                        <div
                          className="padding16 newCashback whiteBackground"
                          style={{
                            position: "absolute",
                            width: "350px",
                            height: "330px",
                            marginLeft: "95px",
                            zIndex: "90",
                          }}
                        >
                          <div
                            onClick={() => {
                              setDisplay(false);
                              setSearch("");
                              //dispatch(clearAllDriversByVrn());
                              setFieldValue("manager", "", true);
                              props?.setManagerId("");
                            }}
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            <SvgImage name="CloseButtonLogo" />
                          </div>
                          <div
                            style={{
                              overflowY: "scroll",
                              overflowX: "hidden",
                              height: "240px",
                            }}
                          >
                            {managerList == undefined ? (
                              <></>
                            ) : managerList != 0 ? (
                              managerListing?.map((data, i) => {
                                let name =
                                  data?.User?.fullName.length > 30
                                    ? data?.User?.fullName.split(0, 30) + "..."
                                    : data?.User?.fullName;
                                let phone = data?.User?.phone;

                                console.log("checkstatus69", data);
                                // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                                return (
                                  <div
                                    key={i}
                                    className="padding8 row"
                                    onClick={() => {
                                      // setcityId1(data?.cityId);
                                      setColor(i);
                                      setDisplay(false);
                                      setFieldValue(
                                        "manager",
                                        name + "-" + phone,
                                        true
                                      );
                                      props?.setManagerId(data?.User?.userId);
                                    }}
                                  >
                                    {color === i ? (
                                      <div className="col-1">
                                        <SvgImage name="SelectedRadio" />
                                      </div>
                                    ) : (
                                      <div className="col-1">
                                        <SvgImage name="UnselectedRadio" />
                                      </div>
                                    )}
                                    {/* <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div> */}
                                    <div
                                      className="col-11"
                                      style={{ textAlign: "left" }}
                                    >
                                      {name} - {phone}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div>No Manager Found</div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {name === "Download History" ? (
                        <FormGroup>
                          <div className="sub-title-1">
                            <span class="circle-logo">4</span>
                            <p className="sub-head-3">Download Format</p>
                          </div>
                          <select
                            name="mode"
                            class="form-select select-option"
                            aria-label="Default select example"
                            className="input-body-3 "
                            value={values.mode}
                            defaultValue={values.mode}
                            onChange={(e) => {
                              if (props.MP_format !== undefined)
                                dispatch(sendAnalitics(props.MP_format), {
                                  downloadFormat: e.target.value,
                                });
                              setFieldValue("mode", e.target.value, true);
                            }}
                            placeholder="Select here"
                          >
                            <option value="default" disabled hidden>
                              Select below
                            </option>
                            <option
                              value=""
                              onClick={() => {
                                if (props.MP_PDF !== undefined)
                                  dispatch(sendAnalitics(props.MP_PDF));
                              }}
                            >
                              PDF
                            </option>
                            <option
                              value="csv"
                              onClick={() => {
                                if (props.MP_excel !== undefined)
                                  dispatch(sendAnalitics(props.MP_excel));
                              }}
                            >
                              Excel
                            </option>
                          </select>
                          <div className="input-amount-for-link"></div>
                          <div
                            style={{ margin: "7px 0 0px 96px", color: "red" }}
                          >
                            {errors.mode && touched.mode ? (
                              <div>*{errors.mode}</div>
                            ) : null}
                          </div>
                          <label for="html" className="label-body"></label>
                        </FormGroup>
                      ) : (
                        <></>
                      )}
                    </FormGroup>
                  </>
                )}

                <FormGroup>
                  <button
                    type="submit"
                    className="butt4"
                    onClick={() => {
                      if (props.MP_apply !== undefined)
                        dispatch(sendAnalitics(props.MP_apply));
                    }}
                  >
                    {name === "Filter" ? "Apply Filter" : "Download"}
                  </button>
                </FormGroup>
              </Form>
            )}
          </Formik>
          <div className="space-div"></div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default AddingFilter;
