import {
    MANAGER_LIST,
    MANAGER_LIST_SUCCESS, 
    MANAGER_GET_CONTROL, 
    MANAGER_GET_CONTROL_SUCCESS,
    EDIT_CONTROL_MANAGER,
    EDIT_CONTROL_MANAGER_SUCCESS,
    CHANGE_UPI_MANAGER,
    CHANGE_UPI_MANAGER_SUCCESS,
    GET_BRANCH,
    GET_BRANCH_SUCCESS,
    ADD_BRANCH,
    ADD_BRANCH_SUCCESS,
    LINK_BRANCH_MANAGER_SUCCESS,
    LINK_BRANCH_MANAGER,
    LINK_BRANCH_FLEET,
    LINK_BRANCH_FLEET_SUCCESS,
    LINK_BRANCH_DRIVER,
    LINK_BRANCH_DRIVER_SUCCESS,
    GET_BRANCH_DRIVER,
    GET_BRANCH_DRIVER_SUCCESS,
    GET_BRANCH_FLEET,
    GET_BRANCH_FLEET_SUCCESS,
    GET_BRANCH_MANAGER,
    GET_BRANCH_MANAGER_SUCCESS,
    GET_BRANCH_ID,
    GET_BRANCH_ID_SUCCESS,
    SET_BRANCH_LIMIT,
    SET_BRANCH_LIMIT_SUCCESS,
    DELETE_BRANCH,
    DELETE_BRANCH_SUCCESS
  } from '../storeTypes';
  
  const INIT_STATE = {
    loader: false,
    managerList :{},
    managerControlList:{},
    managerListing:[]
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case MANAGER_LIST: {
        console.log("getManagerList->reducer");
        return {
          ...state,
          loader: true,
        };
      }
      case MANAGER_LIST_SUCCESS: {
        return {
          ...state,
          loader: false,
          managerList:action?.payload?.count,
          // allManagersList:[
          //   ...state.allManagersList,
          //   ...action?.payload?.rows,
          // ],
          managerListing:action?.payload?.rows
          //  _.unionBy(
          //   state.allManagersList,
          //   action?.payload?.rows,
          //   'managerId',
          // )
        };
      }
      case MANAGER_GET_CONTROL: {
        console.log("getManagerList->reducer");
        return {
          ...state,
          loader: true,
        };
      }
      case MANAGER_GET_CONTROL_SUCCESS: {
        return {
          ...state,
          loader: false,
          managerControlList : action?.payload,
        };
      }
      case EDIT_CONTROL_MANAGER: {
        return {
          ...state,
          loader: true,
        };
      }
      case EDIT_CONTROL_MANAGER_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case CHANGE_UPI_MANAGER: {
        return {
          ...state,
          loader: true,
        };
      }
      case CHANGE_UPI_MANAGER_SUCCESS: {
        return {
          ...state,
          loader: false,
          managerControlList : action?.payload,
        };
      }


      case GET_BRANCH: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_BRANCH_SUCCESS: {
        return {
          ...state,
          loader: false,
          branchData : action?.payload,
        };
      }

      case DELETE_BRANCH: {
        return {
          ...state,
          loader: true,
        };
      }
      case DELETE_BRANCH_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }


      case ADD_BRANCH: {
        return {
          ...state,
          loader: true,
        };
      }
      case ADD_BRANCH_SUCCESS: {
        return {
          ...state,
          loader: false,
          addBranchResponse : action?.payload,
        };
      }


      case LINK_BRANCH_MANAGER: {
        return {
          ...state,
          loader: true,
        };
      }
      case LINK_BRANCH_MANAGER_SUCCESS: {
        return {
          ...state,
          loader: false,
          linkManager : action?.payload,
        };
      }


      case LINK_BRANCH_FLEET: {
        return {
          ...state,
          loader: true,
        };
      }
      case LINK_BRANCH_FLEET_SUCCESS: {
        return {
          ...state,
          loader: false,
          linkBranchFleet : action?.payload,
        };
      }

      case SET_BRANCH_LIMIT: {
        return {
          ...state,
          loader: true,
        };
      }
      case SET_BRANCH_LIMIT_SUCCESS: {
        return {
          ...state,
          loader: false,
          branchLimit : action?.payload,
        };
      }


      case LINK_BRANCH_DRIVER: {
        return {
          ...state,
          loader: true,
        };
      }
      case LINK_BRANCH_DRIVER_SUCCESS: {
        return {
          ...state,
          loader: false,
          linkBranchDriver : action?.payload,
        };
      }


      case GET_BRANCH_DRIVER: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_BRANCH_DRIVER_SUCCESS: {
        return {
          ...state,
          loader: false,
          getBranchDriver : action?.payload,
        };
      }


      case GET_BRANCH_FLEET: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_BRANCH_FLEET_SUCCESS: {
        return {
          ...state,
          loader: false,
          getBranchFleet : action?.payload,
        };
      }


      case GET_BRANCH_MANAGER: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_BRANCH_MANAGER_SUCCESS: {
        return {
          ...state,
          loader: false,
          branchManager : action?.payload,
        };
      }

      case GET_BRANCH_ID: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_BRANCH_ID_SUCCESS: {
        return {
          ...state,
          loader: false,
          branchByid : action?.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  