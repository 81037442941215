import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import PinInput from "react-pin-input";
import SvgImage from "../../../Icons/Icons";
import "./index.css";
import * as Yup from 'yup';

import { useDispatch, useSelector } from "react-redux";
import { addMoney, expenseSumm } from "../../../../services/Store/Actions/driverslistActions";
import {transferToCard, withdrawFromCard} from "../../../../services/Store/Actions/manageCardActions";
import { getBalanceDetails, getCardDetails, getWalletBalAction } from "../../../../services/Store/Actions/LogipeCardActions";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
const OwnerAddCardBalanceModal = (props) => {
  const dispatch = useDispatch();

  // useEffect(() => {}, [props]);

  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    name,
    userId,
  } = props;

  const {loader} = useSelector((state) => state?.manageCard);
  const balanceRecord= useSelector ((state)=>state?.LogipeCard?.balanceRecord);
  const bal= balanceRecord?.balance===null?0:balanceRecord?.balance;
  useEffect(()=>{
    dispatch(
        getWalletBalAction(props.userId)
    )
},[])
  // useEffect(() => {}, [userId]);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-content" style={{width:"530px", overflowY:"scroll", overflowX:"hidden"}} >
        <div className="modal-head-part">
          <p className="Add-balance-title">{name}</p>
          <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          <div className="card-wallet-icon-body2">
            <SvgImage
              name="cardWalletLogo"
              height="43.81"
              width="43.81"
              classname="card-wallet-logo-BankLogo"
            />
            <div>
              <p className="body-sub-part1">Owner's Card Balance</p>
              <p className="body-sub-part2">₹{bal==null?0:bal}</p>
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              amount: "",
              pin: "",
              driverName: props?.fullName || "Owner",
              remark: "",
            }}

 validationSchema={Yup.object().shape({
              amount: Yup.number().typeError("Only Numbers are allowed")
                .min(1, "Amount should be greater than 1")
                .required('Amount is required'),
              pin: Yup.string()
                .required("* Required")
                .matches(/^[0-9]{4}$/, "* Pin Must be exactly 4 digits"),
              // dob: Yup.string()
              //   .required('Expense Date is required'),
              // password: Yup.string()
              //   .min(6, 'Password must be at least 6 characters')
              //   .required('Password is required'),
              // confirmPassword: Yup.string()
              //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
              //   .required('Confirm Password is required')
            })}            
            onSubmit={(values, actions) => {
              // values.bookingDate = moment(values.bookingDate).format(
              //   "DD-MM-YYYY"
              // );
              //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))

              console.log("valuessss", values);
              dispatch(
                sendAnalitics("OwnerAddCardBal_fromMA_API_Web", {
                  amt: values.amount,
                  pin: values.pin,
                  remark:values.remark,
                  userId:userId
                })
              );
              dispatch(
                transferToCard(values, userId, (res) => {
                  actions.setSubmitting(false);
                  if (res?.data?.type === "success") {
                    console.log("successful");
                    dispatch(
                      sendAnalitics("Success_OwnerAddCardBal_fromMA_Web", {
                        amt: values.amount,
                        pin: values.pin,
                        remark:values.remark,
                        userId:userId
                      })
                    );
                    //navigate("/Customers")
                    toggleModal();
                    dispatch(getBalanceDetails(userId))
                  //   dispatch(
                  //     getCardDetails(userId)
                  // )
                              }
                  else{
                    toggleModal();
                    dispatch(getBalanceDetails(userId))
                  //   dispatch(
                  //     getCardDetails(userId)
                  // )
            
                  }
                })
              );
              // dispatch (cleanExpenseSumm());
              // dispatch(expenseSumm(userId,{
              //   offset: 0 ,
              //   fromDate: '',
              //   toDate: '',
              //   sortAs: '',
              //   sortBy: '',
              // }));
              toggleModal();
            }
          }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  <div className="sub-title-1">
                    <span class="circle-logo">1</span>
                    <p className="sub-head-3">Enter amount</p>
                  </div>
                  <p className="sub-head-body">
                    Amount will be deducted from owner's LogiPe account
                  </p>

                  {/* <label for="html" className="label-body">
                    ₹
                  </label> */}
                  <input
                    type="text"
                    id="html"
                    className={'input-body-1 form-control' + (errors.amount && touched.amount ? ' is-invalid' : '')}
                    name="amount"
                    value={values.amount}
                    touched={touched.amount}
                    errors={errors.amount}
                    onBlur={handleBlur("amount")}
                    onChange={handleChange("amount")}
                  ></input>
                  <div className="input-amount"></div>
                  <ErrorMessage name="amount" component="div" className="invalid-feedback" />

                </div>
                <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-head-3">Enter remarks</p>
                    </div>
                    <p className="sub-head-body">
                      Enter the the remarks/comments
                    </p>

                    <input
                      type="text"
                      id="html"
                      className={
                        "input-body-1 form-control" +
                        (errors.remark && touched.remark ? " is-invalid" : "")
                      }
                      name="remark"
                      value={values.remark}
                      touched={touched.remark}
                      errors={errors.remark}
                      onBlur={handleBlur("remark")}
                      onChange={handleChange("remark")}
                    ></input>
                    <div className="input-amount"></div>
                    <ErrorMessage
                      name="remark"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                <div>
                  <div className="sub-title-1">
                    <span class="circle-logo">3</span>
                    <p className="sub-head-4">Enter 4-digit Security Pin</p>
                  </div>
                  <p className="sub-head-body">
                    Security pin is needed to confirm payment
                  </p>
                  <PinInput
                    name="pin"
                    length={4}
                    initialValue=""
                    secret

                    onChange={(value, index) =>
                      setFieldValue("pin", value, true)
                    }
                    type="numeric"
                    inputMode="number"
                    style={{
                      padding: "10px",
                      marginLeft: "96px",
                      marginTop: "28px",
                    }}
                    inputStyle={{
                      border: "1px solid #A7A7A7",
                      borderRadius: "8px",
                      marginRight: "20px",
                    }}
                    onComplete={(value, index) => { }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  <button type="submit" className="butt4" disabled={loader?true:false}
                  onClick={() => {
                        dispatch(
                          sendAnalitics("OwnerAddCardBal_fromMA_Web", {
                            amt: values.amount,
                            pin: values.pin,
                            remark:values.remark,
                            userId:userId
                          })
                        );
                      }}
                      >
                  {loader?"Please Wait":"Add Card Balance"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          {/* <div className="space-div"></div> */}
        </ModalBody>
        </div>
      </Modal>
    </div>
  );
};
export default OwnerAddCardBalanceModal;