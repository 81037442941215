import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Modal, ModalBody } from "reactstrap";
import "./index.css";
import SvgImage from "../../../../Icons/Icons";
import { Formik } from "formik";
import * as Yup from "yup";
import SuccessModal from "../SuccessModal";
import { addFleet } from "../../../../../services/Store/Actions/fleetActions";
import { useNavigate } from "react-router-dom";
import { getVehicleListAction,clearAllFleets } from "../../../../../services/Store/Actions/manageVehicleAction";
import ListModalV2 from "../../../../subComponents/ListModalV2";

const validationSchema = Yup.object().shape({
  amount: Yup.string()
    .required("Required")
    .matches(/^[0-9]$/, "amount must be a number"),
});
function AddFleet(props) {
  const {
    open,
    onHide,
    toggleModal,
    handleClose,
    vendorData,
    onSubmit,
    mode,
    setMode,
  } = props;
  const { ownerBalance } = useSelector((state) => state?.manageCard);
  const [selectAll, setSelectAll] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [amount, setAmount] = useState("");
  const [add, setAdd] = useState(true);
  const [search, setSearch] = useState("");
  const [display, setDisplay] = useState(false);
  const [color, setColor] = useState(-1);
  const optionList = [
    "Credit",
    "Debit",
    "Closing Balance",
    "Remarks",
    "Fastag Wallet",
    "Card",
    "UPI",
    "LogiMart",
    "Insurance",
    "Subscription Charges",
    "Recharge",
    "Cashback Redeemed",
  ];
  console.log("pppp000", vendorData);
  const allFleets = useSelector(
    (state) => state?.manageVehicle?.allFleets?.rows
  );
  const vehCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );
  let loading = useSelector((state) => state?.manageVehicle?.loader);
  console.log("allFlallFleetseets", allFleets);
  //   useEffect(() => {
  //     dispatch(fetchBalance());
  //   }, []);

  const [checkedState, setCheckedState] = useState(
    new Array(optionList.length).fill(true)
  );

  const openBox1 = () => {
    setDisplay(true);
  };
  const closeBox1 = () => {
    setDisplay(false);
  };

  useEffect(() => {
    // setUserId("");
    setSearch("");
    // setSearch2("");
    setColor(-1);
    // setDisplayDrivers(false)
    setDisplay(false);
    // setVehicleColor(-1)
  }, []);

  useEffect(() => {
    dispatch(
      getVehicleListAction({
        offset: 0,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 3,
      })
    );
  }, [search]);

  const handleKeyPress = (newSearchVal, divName) => {
    //alert("search again", newSearchVal,divName);
    console.log("search1", newSearchVal);
    console.log("search2", divName);
    dispatch(
      getVehicleListAction({
        offset: 0,
        search: newSearchVal,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 3,
      })
    );
  };

  const handleSelect = (position) => {
    if (selectAll) {
      setSelectAll(false);
    }
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };
  return (
    <Modal
      isOpen={open}
      onHide={onHide}
      toggle={toggleModal}
      className="Payment-Amount-Modal settingModalMargin"
      //   toggle={toggle}
      aria-labelledby="myModalLabel2"
    >
      <div
        className="closeRemove"
        onClick={() => {
          // Router.reload();
          handleClose();
        }}
      >
        <SvgImage name="CloseButtonLogo" />
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          regNumber: "",
        }}
        // validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log("herere", values);

          dispatch(
            addFleet(
              {
                vehicleNumber: values?.regNumber,
              },
              (response) => {
                actions.setSubmitting(false);
                console.log("respppppppppp", response);
                if (response?.type === "success") {
                  toggleModal();
                  navigate(`/vendor`);
                  props.setOpenLink(true);

                  //window.location.reload();
                } else {
                  //alert(response);
                }
              }
            )
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div className="Ent-Pin-Box " style={{ textAlign: "center" }}>
                <SvgImage name={"truckIcon"} />
                <div className="ENT-Security-Pin-Title">{"Add Vehicle"}</div>
                <div className="ENT-Security-Pin-Title row">
                  <div className="col-6" style={{ textAlign: "end" }}>
                    <Button
                      style={{ background: "rgb(72, 91, 124)" }}
                      onClick={() => {
                        setAdd(true);
                      }}
                    >
                      New Vehicle
                    </Button>
                  </div>
                  <div className="col-6" style={{ textAlign: "initial" }}>
                    <Button
                      style={{ background: "rgb(72, 91, 124)" }}
                      onClick={() => {
                        setAdd(false);
                      }}
                    >
                      Existing Vehicle
                    </Button>
                  </div>
                </div>

                {/* <p className="ENT-Security-Pin-subTitle">{vendorData?.phone}</p> */}
                {/* <p className="heading2">
                  Account Balance: ₹{ownerBalance?.instrument_balance?.balance}
                </p> */}
                <div>
                  {add ? (
                    <input
                      type={"text"}
                      id={`regNumber`}
                      className="Enter-Fleet-Number"
                      name={`regNumber`}
                      value={values?.regNumber}
                      touched={touched?.regNumber}
                      errors={errors?.regNumber}
                      onBlur={() => {
                        //   dispatch(sendAnalitics("EnterPhone_web", values));
                        handleBlur("regNumber");
                      }}
                      onChange={handleChange(`regNumber`)}
                      placeholder={"New Vehicle Number"}
                      autocomplete="off"
                      autofocus
                    />
                  ) : (
                    <div>
                      <input
                        type={"text"}
                        id={`regNumber`}
                        className="Enter-Fleet-Number"
                        name={`regNumber`}
                        touched={touched?.regNumber}
                        errors={errors?.regNumber}
                        // onBlur={() => {
                        //   //   dispatch(sendAnalitics("EnterPhone_web", values));
                        //   handleBlur("regNumber");
                        // }}
                        onFocus={openBox1}
                        onChange={(event) => {
                          setDisplay(true);
                          dispatch(clearAllFleets());
                          // dispatch(cleanAdvanceDriverList());
                          console.log("eeeeeeeeeeeeeeeee", event.target.value);
                          // setDisplay(true);
                          setSearch(event.target.value);
                          // setLoading(true);
                          setFieldValue("regNumber", event.target.value, true);
                        }}
                        value={values.regNumber}
                        onBlur={() => {
                          console.log("===>>>>>kkkkk")
                          handleBlur("regNumber")
                          closeBox1()
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            // findSearch1();
                          }
                        }}
                        autocomplete="off"
                        placeholder="Search Vehicle Number"
                      />
                      <div className="input-amount111">
                        {display ? (
                          <ListModalV2
                            placeholder="Search"
                            handleKeyPress={handleKeyPress}
                            divName="vehicle"
                            // text="Select by driver name"
                            listing={allFleets}
                            parameter="regNumber"
                            minWidth = {"311"}
                            // handlingDivSelect={handlingDivSelect}
                            display={display}
                            setDisplay={setDisplay}
                            // displayVehicles={displayVehicles}
                            // setDisplayVehicles={setDisplayVehicles}
                            color={color}
                            setColor={setColor}
                            loading={loading}
                            setFieldValue={setFieldValue}
                            setFieldParameter = {"regNumber"}
                            // userId={userId}
                            // setUserId={setUserId}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </FormGroup>

            <FormGroup
              style={{
                justifyContent: "center",
                textAlignLast: "center",
                margin: "175px",
              }}
            >
              <button
                type="submit"
                className="greenButton Verify-Pin-Btn"
                style={{
                  margin: "0 0 0 0",
                  background:
                    loader ||
                    ownerBalance?.instrument_balance?.balance < values.amount
                      ? "grey"
                      : "#485B7C",
                }}
                disabled={
                  loader ||
                  ownerBalance?.instrument_balance?.balance < values.amount
                    ? true
                    : false
                }
                onClick={handleSubmit}
                onSubmit={handleSubmit}
              >
                {loader ? "Please Wait" : "Submit"}
              </button>
            </FormGroup>
            <div></div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AddFleet;
