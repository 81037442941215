import { combineReducers } from "redux";
import appReducer from "./appReducer";
import manageCardReducer from "./manageCardReducer";
import receivableReducer from "./receivableReducer";
import driverlistReducer from "./driverslistReducer";
import masterWalletReducer from "./masterWalletReducer";
import walletReducer from "./walletReducer";
import fastagReducer from "./fastagReducer";
import transferReducer from "./transferReducer";
import LogipeCardReducer from "./LogipeCardReducer";
import manageVehicleReducer from "./manageVehicleReducer"
import cardWalletReducer from "./cardWalletReducer";
import beneficiaryReducer from "./beneficiaryReducer";
import fleetReducer from "./fleetReducer";
import m2pReducer from "./m2pReducer";
import onboardingReducer from "./onboardingReducer";
import loginReducer from "./loginReducer";
import cashbackReducer from "./cashbackReducer";
import UserManagementReducer from "./UserManagementReducer";
import ecomReducer from "./ecomReducer";
import reportReducer from "./reportReducer";
import omcReducer from "./omcReducer";
import tripsReducer from './TripsReducer';
import customerReducer from "./customerReducer";

export const rootReducer = combineReducers({
    app: appReducer,
    receivable: receivableReducer,
    masterWallet: masterWalletReducer,
    driverlist: driverlistReducer,
    manageCard: manageCardReducer,
    wallet: walletReducer,
    fastag: fastagReducer,
    transfer: transferReducer,
    LogipeCard: LogipeCardReducer,
    manageVehicle: manageVehicleReducer,
    loginReducer:loginReducer,
    cardWallet: cardWalletReducer,
    beneficiary: beneficiaryReducer,
    fleet: fleetReducer,
    cashback:cashbackReducer,
    m2p: m2pReducer,
    userManagement: UserManagementReducer,
    onboarding:onboardingReducer,
    ecom: ecomReducer,
    report:reportReducer,
    omc:omcReducer,
    trip: tripsReducer,
    customer: customerReducer,
});