import React, { useEffect, useState } from "react";
import SvgImage from "../../../../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { sendOtp } from "../../../../services/Store/Actions/LoginAction";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import KYCPart1 from "./KYC_part1";
import KYCPart2 from "./KYC_part2";


const KYC=(props)=>{
    const {setRegStatus, panCard, setPanCard,aadhaarNo,setAadhaarNo}= props;
    const [requestId, setRequestId]= useState("");
    const [status, setStatus]= useState(0);

    return(
        <>
        {
            (status==0)?
            <KYCPart1 
            setPanCard={setPanCard}
            panCard={panCard}
            aadhaarNo={aadhaarNo}
            setAadhaarNo={setAadhaarNo}
            setStatus={setStatus}
            setRegStatus={setRegStatus}
            setRequestId={setRequestId}
            />
            :
            <KYCPart2 
            aadhaarNo={aadhaarNo}
            requestId={requestId}
            setRequestId={setRequestId}
            setRegStatus={setRegStatus}
             />
        }
        </>
        
    )

}

export default KYC;