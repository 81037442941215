import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { Button, Modal, ModalBody } from "reactstrap";
import SvgImage from "../../../../Icons/Icons";
import {
  addAddressDetailsAction,
  getAddressListAction,
} from "../../../../../services/Store/Actions/ecomAction";
import * as Yup from "yup";
import axios from "axios";
import { Formik } from "formik";
import { Form, FormGroup, Table } from "react-bootstrap";

function AddNewAddress(props) {
  const { handleClose, open, toggleModal } = props;

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Please enter your full name"),
    phone: Yup.string()
      .required("Phone number must contain 10 digits")
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Phone number must contain 10 digits"
      ),
    pincode: Yup.number()
      .typeError("Only Numbers are allowed")
      .required("Pin code is required"),
    state: Yup.string().required("Please enter your state"),
    city: Yup.string().required("Please enter your city"),
    address: Yup.string().required("Please enter your address"),
  });

  const dispatch = useDispatch();
  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.userId;

  const { loader } = useSelector((state) => state?.ecom);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={() => {
          //   setStatus(false);
          handleClose();
        }}
        className="positon settingModalMargin"
        toggle={() => {
          //   setStatus(false);
          toggleModal();
        }}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Add New Address</p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                toggleModal();
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody className="padding8 padding0L ">
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                fullName: "",
                phone: "",
                pincode: "",
                state: "",
                city: "",
                address: "",
              }}
              onSubmit={(values, actions) => {
                console.log("values onSubmit", values);
                dispatch(
                  addAddressDetailsAction(
                    {
                      userId: userIdSession,
                      address1: values.address,
                      city: values.city,
                      state: values.state,
                      pincode: values.pincode,
                      address2: "any",
                    },
                    (res) => {
                      console.log("res", res);
                      if (res?.status == 200) {
                        dispatch(
                          getAddressListAction((res) => {
                            console.log("res", res);
                            toggleModal();
                            // if(address?.pincode==undefined){
                            //   setAddress(res?.data?.data?.data[0])
                            //   setAddressId(res?.data?.data?.data[0]?.addressId)
                            //}
                          })
                        );
                        // props.setAddress(res?.data?.data);
                        // props.setAddressId(res?.data?.data?.addressId);
                        // props.setStatus(1);
                      }
                    }
                  )
                );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
                setFieldError,
              }) => (
                <Form onSubmit={handleSubmit} className="padding24T padding16">
                  <FormGroup>
                    <label
                      htmlFor="fullName"
                      className="Medium heading3 blockDiv padding16B padding8L"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      className={`input-body-new`}
                      name="fullName"
                      onChange={handleChange("fullName")}
                      value={values.fullName}
                      onBlur={handleBlur("fullName")}
                      touched={touched.fullName}
                      errors={errors.fullName}
                      placeholder="Write your full name"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.fullName && touched.fullName ? (
                        <div>*{errors.fullName}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="phone"
                      className="Medium heading3 blockDiv padding16B padding8L padding16T"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      id="phone"
                      className={`input-body-new`}
                      name="phone"
                      onChange={handleChange("phone")}
                      value={values.phone}
                      onBlur={handleBlur("phone")}
                      touched={touched.phone}
                      errors={errors.phone}
                      placeholder="Write your phone number"
                      maxLength="10"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.phone && touched.phone ? (
                        <div>*{errors.phone}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="row">
                      <div className="col-6">
                        <label
                          htmlFor="pincode"
                          className="Medium heading3 blockDiv padding16B padding8L padding16T"
                        >
                          Pincode
                        </label>
                        <input
                          type="text"
                          id="pincode"
                          className={`input-body-new`}
                          name="pincode"
                          onChange={handleChange("pincode")}
                          value={values.pincode}
                          onBlur={handleBlur("pincode")}
                          touched={touched.pincode}
                          errors={errors.pincode}
                          placeholder="Write your pincode"
                          maxLength="6"
                          onKeyUp={async (e) => {
                            if (values.pincode.length == 6) {
                              try {
                                const config = {
                                  method: "POST",
                                  url: "https://aks-api.logipe.com/api/api/v1/postal/getPostalDetails",
                                  data: {
                                    pincode: values.pincode,
                                  },
                                };
                                const res = await axios(config);
                                console.log("pin codeeeeeeee", res?.data?.data);
                                if (res?.data?.data) {
                                  setFieldValue(
                                    "city",
                                    res?.data?.data?.District,
                                    true
                                  );
                                  setFieldValue(
                                    "state",
                                    res?.data?.data?.State,
                                    true
                                  );
                                }
                              } catch (err) {
                                console.log(
                                  "err pincode in delivery address",
                                  err
                                );
                              }
                            } else {
                              setFieldError(
                                "pincode",
                                "Pincode must be 6 digits"
                              );
                            }
                          }}
                        />
                        <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                          {errors.pincode && touched.pincode ? (
                            <div>*{errors.pincode}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-6">
                        <label
                          htmlFor="state"
                          className="Medium heading3 blockDiv padding16B padding8L padding16T"
                        >
                          State
                        </label>
                        <input
                          type="text"
                          id="state"
                          className={`input-body-new`}
                          name="state"
                          onChange={handleChange("state")}
                          value={values.state}
                          onBlur={handleBlur("state")}
                          touched={touched.state}
                          errors={errors.state}
                          placeholder="Write your state"
                        />
                        <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                          {errors.state && touched.state ? (
                            <div>*{errors.state}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="city"
                      className="Medium heading3 blockDiv padding16B padding8L padding16T"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      id="city"
                      className={`input-body-new`}
                      name="city"
                      onChange={handleChange("city")}
                      value={values.city}
                      onBlur={handleBlur("city")}
                      touched={touched.city}
                      errors={errors.city}
                      placeholder="Write your city"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.city && touched.city ? (
                        <div>*{errors.city}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="address"
                      className="Medium heading3 blockDiv padding16B padding8L padding16T"
                    >
                      Address line
                    </label>
                    <textarea
                      rows="4"
                      id="address"
                      className={`input-body-new`}
                      name="address"
                      onChange={handleChange("address")}
                      value={values.address}
                      onBlur={handleBlur("address")}
                      touched={touched.address}
                      errors={errors.address}
                      placeholder="Write your address"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.address && touched.address ? (
                        <div>*{errors.address}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup className="padding16T">
                    <button
                      type="submit"
                      className="quoteButtonDark"
                      disabled={loader ? true : false}
                      style={{
                        background:
                          values.fullName == ""
                            ? "#EFEFEF"
                            : values.phone == ""
                            ? "#EFEFEF"
                            : values.pincode == ""
                            ? "#EFEFEF"
                            : values.state == ""
                            ? "#EFEFEF"
                            : values.city == ""
                            ? "#EFEFEF"
                            : values.address == ""
                            ? "#EFEFEF"
                            : loader
                            ? "#EFEFEF"
                            : "#485B7C",
                        color:
                          values.fullName == ""
                            ? "#B5B5B5"
                            : values.phone == ""
                            ? "#B5B5B5"
                            : values.pincode == ""
                            ? "#B5B5B5"
                            : values.state == ""
                            ? "#B5B5B5"
                            : values.city == ""
                            ? "#B5B5B5"
                            : values.address == ""
                            ? "#B5B5B5"
                            : loader
                            ? "#B5B5B5"
                            : "#ffffff",
                      }}
                    >
                      {loader ? "Please Wait" : "Save Address"}
                    </button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default AddNewAddress;
