import { call, put, takeEvery } from "redux-saga/effects";
import {
    redeemCashbackApi
} from "../..//Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import {
    redeemCashbackSuccessAction
} from "../Actions/cashbackAction";
import {
    REDEEM_CASHBACK
} from "../storeTypes";

import { toastStatus } from "../../../services/Store/Actions/appActions";


function* redeemCashbackSaga(action) {
    try {
      console.log("redeem saga");
      const response = yield call(redeemCashbackApi, action?.payload);
      console.log("response", response);
      if (response?.data?.type == "success") {
        console.log ("resp in redeem ", response);
        action.callback(response);
        yield put(redeemCashbackSuccessAction(response?.data?.data));
        yield put(
            toastStatus({
            toastStatus: true,
            message: "Cashback was successfully redeemed",
            status: true,
          }))
      }
    } catch (error) {
    //   console.log(
    //     "getOwnerCardDetailsSuccess Error.Response:- ",
    //     error?.response?.data
    //   );
      yield put(
        toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      }))
      console.log("API Error:- ", processErrorAndRespond(error));
    }
  }


  export function* watchCashbackSaga() {
    yield takeEvery(REDEEM_CASHBACK, redeemCashbackSaga);
  }  