import React, { useEffect, useState } from "react";
import "./index.css";
import SvgImage from "../../../Icons/Icons";
import {
  cleanVendorList,
  getVendorAction,
} from "../../../../services/Store/Actions/beneficiaryAction";
import { wait } from "@testing-library/user-event/dist/utils";
import { useDispatch, useSelector } from "react-redux";

const VendorEmpty = (props) => {
  const { onPress, path, setShowEmptyScreen } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { vendorList } = useSelector((state) => state?.beneficiary);
  const dispatch = useDispatch();
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="vendorBg containerShadow">
      <div className="noVendorBg" style={{ position: "relative" }}>
        <SvgImage name="emptyVendor" />
        <div className="absPos" style={{ top: "30%", left: "34%" }}>
          <div className="">
            <SvgImage name="vendorCash" />
          </div>
          <div className="Bold heading2 centrPos" style={{ marginTop: "24px" }}>
            Manage Vendors
          </div>
          <div
            className="em heading2 centrPos "
            style={{ color: "#6C6C6C", marginTop: "12px" }}
          >
            Your vendor list is empty.
            <br /> Add new vendor to manage all in one place.
          </div>
          <div className="">
            <button
              className="greenButton"
              onClick={onPress}
              style={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
                marginTop: "24px",
              }}
            >
              <text
                style={{
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                Add New Vendor
              </text>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorEmpty;
