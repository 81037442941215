import {
  TRANSFER_WALLET_AMOUNT,
  TRANSFER_WALLET_AMOUNT_SUCCESS,
  ALL_CARD_BALANCE,
  ALL_CARD_BALANCE_SUCCESS,
  CARD_REQUESTS,
  CARD_REQUESTS_SUCCESS,
  UPDATE_CARD_REQUESTS_SUCCESS,
  UPDATE_CARD_REQUESTS
} from '../storeTypes';

const INIT_STATE = {
  loader: false,
  allCardBalance:"0"
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TRANSFER_WALLET_AMOUNT: {
      return {
        ...state,
        loader: true,
      };
    }
    case TRANSFER_WALLET_AMOUNT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case CARD_REQUESTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CARD_REQUESTS_SUCCESS: {
      console.log('action?.payloadaction?.payload',action?.payload)
      return {
        ...state,
        loader: false,
        cardRequest: action?.payload
      };
    }

    case UPDATE_CARD_REQUESTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_CARD_REQUESTS_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }


    case ALL_CARD_BALANCE: {
      console.log ("all cards-reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case ALL_CARD_BALANCE_SUCCESS: {
      console.log ("all cards- reducer success");
      return {
        ...state,
        loader: false,
        allCardBalance: action?.payload
      };
    }
    default: {
      return state;
    }
  }
};
