import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addFleet } from "../../../services/Store/Actions/fleetActions";
import { useNavigate } from "react-router-dom";
// import { fleetDetailsSuccess } from "../../../services/Store/Actions/fleetActions";
import Switch from "react-switch";
import SearchBar from "../../subComponents/searchbar";
import { autoRechargeToggleAction } from "../../../services/Store/Actions/fastagAction";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";

function AutorechargeConfirm(props) {
  const { open, toggleModal, handleClose, vehNo, limit, vehRank, fleetId } =
    props;
  const validationSchema = Yup.object().shape({
    amount: Yup.number().typeError("Only Numbers are allowed").required("Required"),
    // vehicleCategory: Yup.string().required("Required"),
  });
  const { loader } = useSelector((state) => state?.fastag);

  const [selectedAll, setSelectedAll] = useState(false);
  //console.log("fleet added",fleetAdded);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   setOffset(0);
  //   dispatch(
  //     getVehicleListAction({
  //       offset: "",
  //       limit: 50,
  //       search: "",
  //       minKyc: "",
  //       sortAs: "",
  //       sortBy: "",
  //       fleetType:"1"
  //     })
  //   );
  // }, [open]);

  const { fastagWallet } = useSelector((state) => state?.fastag);
  const fastagAutorechargeAmount =fastagWallet?.autoRechargeAmount;

  useEffect(()=>{
    dispatch(sendAnalitics("LimitSet_OneVeh_ChangeLimit_AR_MV_web"));
  },[])

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Auto Recharge : Set Limit</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody style={{ padding: "0" }}>
            <div
              className="padding16 padding32L"
              style={{ backgroundColor: "#96A9CA" }}
            >
              <div className="heading3 semiBold padding16B">
                Set Limit for FASTag
              </div>
              <div className="FontSmall">
                Minimum balance for the FASTag would be maintained to the limit
                set
              </div>
            </div>
            <div class="div-table-col FontNB col-12 padding8 padding32B padding16L">
              {/* <div data-letters={firstName_initials.toUpperCase()}> */}
              <div data-letters-recharge={vehRank}>
                <span className=" FontNB">{vehNo}</span>
                {/* <p className="FontNB"> {regName}</p> */}
              </div>
              <div class="vehSubInfo FontSmall">Limit Set : ₹{limit=="500"?fastagAutorechargeAmount:limit}</div>
            </div>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                amount: "",
                pin: "",
              }}
              onSubmit={(values, actions) => {
                dispatch(
                  autoRechargeToggleAction({
                    fleetId: [fleetId],
                    minAmount: String(values.amount),
                  })
                );
                dispatch(sendAnalitics("Success_OneVeh_ChangeLimit_AR_MV_web"));
                toggleModal();
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="heading2 semiBold padding16 padding8B padding32L">
                      Set Amount
                    </div>
                    <label for="html" className="label-body" style={{margin: "10px 9px 0 35px"}}>
                      ₹
                    </label>
                    <input
                      type="number"
                      id="amount"
                      style={{ marginLeft: "0", fontSize: "16px" }}
                      className={`input-body`}
                      name="amount"
                      onChange={handleChange("amount")}
                      value={values.amount}
                      onBlur={()=>{
                        dispatch(sendAnalitics("Amt_OneVeh_ChangeLimit_AR_MV_web",{
                          amount:values.amount
                        }));
                        handleBlur("amount");
                      } }
                      touched={touched.amount}
                      errors={errors.amount}
                      placeholder="Enter limit amount"
                    />
                    <div className="input-amount-lining"></div>
                    <div style={{ margin: "7px 0 0px 30px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>
                    <div
                      className="line-separation"
                      style={{ marginTop: "2rem" }}
                    ></div>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      type="submit"
                      className="butt-4"
                      style={{ marginTop: "4rem", marginLeft: "-27px" }}
                      disabled={loader ? true : false}
                      onClick={()=>{
                        dispatch(sendAnalitics("Confirm_OneVeh_ChangeLimit_AR_MV_web",{
                          amount:values.amount
                        }));
                      }}
                    >
                      {loader ? "Please Wait" : "Confirm"}
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default AutorechargeConfirm;
