import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";
import { getCartDetailsAction, removeToCartAction } from "../../../../../services/Store/Actions/ecomAction";
import SvgImage from "../../../../Icons/Icons";
import "./index.css";

function RemoveItem(props){
    
    const { isOpen, onHide, toggle, handleClose,prodId  } = props;
    const dispatch= useDispatch();
    const yesRemove=()=>{
        let userIdObj = localStorage.getItem("_userId");
        console.log("userIdObj", userIdObj)
        let userId= JSON.parse(userIdObj)
        dispatch(removeToCartAction(
        prodId,{
        userId:userId
        },(res)=>{
        if(res?.status==200){
            dispatch(getCartDetailsAction((res)=>{
            toggle();
            }))
        }
        }))
    }


    return (
        <Modal
          isOpen={isOpen}
          onHide={onHide}
          toggle={toggle}
          className="RemoveDiv settingModalMargin"
        //   toggle={toggle}
          aria-labelledby="myModalLabel2"
          
        >
          <div
              className="closeRemove"
              onClick={() => {
                // Router.reload();
                handleClose();
              }}
            >
                <SvgImage name="CloseButtonLogo" />
              </div>
            <div className="padding16">
                    {/* <div className=" centreClass">
                    <img src="/images/ecom/failedCross.svg" className="" />
                    </div> */}
                <div className="padding8T padding8B centreClass Bold heading1 bottomLine" >Remove item?</div>
                <div className="padding16T  centreClass greyFont Medium heading2">Are you sure you want to remove this item from cart?</div>
                <div className="row padding24LR padding24T padding16B ">
                    <div className="col-6 padding8 ">
                    <button className="buttonLightSmall" style={{width:"100%"}}
                    onClick={yesRemove}>YES</button>
                    </div>
                    <div className="col-6 padding8">
                    <button className="quoteButtonDark"
                        style={{color:"#ffffff", backgroundColor:"#485B7C"}}
                        onClick={toggle}>NO</button>
                    </div>
                </div>
                </div>
           
          
        </Modal>
      );
}

export default RemoveItem;


