import {
  CLEAR_AUTH_STORE,
  LOGIN_SUCCESS,
  LOGIN,
  SEND_OTP,
  SEND_OTP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  GET_OWNER_ACCOUNT_DETAILS,
  GET_OWNER_ACCOUNT_DETAILS_SUCCESS,
  GET_OWNER_DETAILS,
  GET_OWNER_DETAILS_SUCCESS,
  PIN_VERIFY_SUCCESS,
  REQUEST_LOGIN_SUCCESS,
  CLEAN_LOGIN_LOADER,
  LOGIN_WITH_PASSWORD,
  LOGIN_WITH_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
} from "../storeTypes";
const INIT_STATE = {
  authErrors: {},
  otpStatus: null,
  loader: false,
  authUser: null,
  token: null,
  ownerAccountDetails: {},
  ownerDetails: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_PASSWORD: {
      console.log("otp-> reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case LOGIN: {
      return {
        ...state,
        loader: true,
      };
    }

    case REQUEST_LOGIN_SUCCESS: {
      console.log("AUTHUSER", action?.payload);
      return {
        ...state,
        loader: false,
        otpStatus: action?.payload?.token ? 1 : null,
        authUser: action?.payload,
        token: action?.payload?.token || null,
      };
    }

    case SEND_OTP: {
      console.log("otp-> reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case SEND_OTP_SUCCESS: {
      return {
        ...state,
        otpStatus: 1,
        loader: false,
      };
    }

    case LOGIN_WITH_PASSWORD: {
      console.log("login-> reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case LOGIN_WITH_PASSWORD_SUCCESS: {
      console.log("AUTH USER", action?.payload?.data?.token);
      return {
        ...state,
        //otpStatus : 1,
        loader: false,
      };
    }
    case VERIFY_OTP: {
      return {
        ...state,
        loader: true,
      };
    }
    case VERIFY_OTP_SUCCESS: {
      console.log("AUTH USER", action?.payload?.data?.token);
      return {
        ...state,
        loader: false,
      };
    }

    case PIN_VERIFY_SUCCESS: {
      return {
        ...state,
        loader: false,
        pinVerify: action?.payload,
      };
    }

    case GET_OWNER_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_OWNER_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        ownerDetails: action.payload,
      };
    }

    case GET_OWNER_ACCOUNT_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_OWNER_ACCOUNT_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        ownerAccountDetails: action?.payload,
      };
    }
    case CLEAN_LOGIN_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    // case CLEAR_AUTH_STORE: {
    //   return {
    //     ...state,
    //     authErrors: {},
    //     loader: false,
    //     authUser: null,
    //     ownerDetails: null,
    //     token: null,
    //     ownerAccountDetails: null,
    //     pinVerify: false,
    //   };
    // }

    default: {
      return state;
    }
  }
};
