import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { useParams } from "react-router-dom";
import "./index.css";
const ChooseMethodModal = (props) => {
  const {
    open,
    toggleModal,
    handleClose,
    handleCashPayment,
    handleCardPayment,
  } = props;

  const dispatch = useDispatch();

  const { loader } = useSelector((state) => state?.omc);
  console.log("button status", loader);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Only Numbers are allowed")
      .required("Required")
      .min(1, "Amount should be greater than 1"),
    pin: Yup.string()
      .required("Required")
      .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
  });

  let { id } = useParams();
  console.log("nnmnmmnmnm", id);
  // if(props.userId){
  //   id = props.userId
  // }

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">
              Choose Payment Method for salary
            </p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody style={{ position: "relative" }}>
            <div
              className="pay-card-modal"
              style={{ cursor: "pointer" }}
              onClick={handleCardPayment}
            >
              <SvgImage name={"ccard"} />
              <div className="ms-3 FontSMedium greenFont">Pay in Card</div>
            </div>
            <div
              className="pay-card-modal"
              style={{ cursor: "pointer" }}
              onClick={handleCashPayment}
            >
              <SvgImage name={"cash1"} />
              <div className="ms-3 FontSMedium greenFont">Pay Through Cash</div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default ChooseMethodModal;
