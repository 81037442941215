import {
  WALLET_TRANSACTION_HISTORY,
  WALLET_TRANSACTION_HISTORY_SUCCESS,
  CLEAN_WALLET_TRANSACTION_HISTORY,
  KYC_DONE,
  KYC_DONE_SUCCESS,
  GET_EXPENSE_IMAGE,
  GET_EXPENSE_IMAGE_SUCCESS,
  DELETE_EXPENSE,
  DELETE_EXPENSE_SUCCESS,
  EDIT_EXPENSE_SUCCESS,
  EDIT_EXPENSE,
  QR_CODE_SUCCESS,
  QR_CODE
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  walletTransactionHistory: [],
  walletTransactionHistoryList: [],
  kycDone: 0,
  imageUrlObj: {},
  editExpense:{},
  qrCode:""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case QR_CODE: {
      console.log("step-b");
      return {
        ...state,
        loader: true,
      };
    }
    case QR_CODE_SUCCESS: {
      console.log("successssssss", action.payload);
      return {
        ...state,
        loader: false,
        qrCode:action?.payload?.qrCode,
      };
    }
    case WALLET_TRANSACTION_HISTORY: {
      console.log("step-b");
      return {
        ...state,
        loader: true,
      };
    }
    case WALLET_TRANSACTION_HISTORY_SUCCESS: {
      console.log("successssssss", action.payload);
      return {
        ...state,
        loader: false,
        walletTransactionHistory: action.payload,
        walletTransactionHistoryList: [
          ...state.walletTransactionHistoryList,
          ...action?.payload?.instrument_statement?.records,
        ],
      };
    }
    case CLEAN_WALLET_TRANSACTION_HISTORY: {
      console.log("running clean");
      return {
        ...state,
        loader: false,
        walletTransactionHistory: [],
        walletTransactionHistoryList: [],
      };
    }
    case KYC_DONE: {
      return {
        ...state,
        loader: true,
      };
    }
    case KYC_DONE_SUCCESS: {
      return {
        ...state,
        loader: false,
        kycDone: action.payload,
      };
    }
    case GET_EXPENSE_IMAGE: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_EXPENSE_IMAGE_SUCCESS: {
      return {
        ...state,
        loader: false,
        imageUrlObj: action.payload,
      };
    }
    case DELETE_EXPENSE: {
      return {
        ...state,
        loader: true,
      };
    }
    case DELETE_EXPENSE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case EDIT_EXPENSE:{
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_EXPENSE_SUCCESS: {
      return {
        ...state,
        loader: false,
        editExpense: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
