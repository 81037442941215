import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { Button, Modal, ModalBody } from "reactstrap";
import SvgImage from "../../../../Icons/Icons";
import {
  addAddressDetailsAction,
  cleanEcomLoader,
  createOrderAction,
  freshDeskTicketAction,
  getAddressListAction,
  ticketWithoutAuthAction,
} from "../../../../../services/Store/Actions/ecomAction";
import * as Yup from "yup";
import axios from "axios";
import { Field, Formik } from "formik";
import { Form, FormGroup, Table } from "react-bootstrap";
import {
  getAllState,
  getCityByStateId,
  storeMedia,
} from "../../../../../services/Store/Actions/appActions";
import { DatePickerField } from "../../../../core/inputs/input";
import { ClipLoader } from "react-spinners";

function EmiForm(props) {
  const { handleClose, open, toggleModal, toggleModalCart, toggleModalPay,addressId,coinStatus } =
    props;
  const [status, setStatus] = useState(false);
  const [displayName, setDisplayName] = useState([]);
  const [imageLoader,setImageLoader] = useState(false)

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Please enter your full name"),
    phone: Yup.string()
      .required("Phone number must contain 10 digits")
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Phone number must contain 10 digits"
      ),
    state: Yup.string().required("Please enter your state"),
    city: Yup.string().required("Please enter your city"),
    companyName: Yup.string().required("Please enter your company name"),
    pan: Yup.string().required("Pan number is mandatory").matches(
        /^([0-9]){12}|([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
        'Incorrect PAN number',
    ),
    gst: Yup.string().matches(
            /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
            'Incorrect GST number',
        ),
    Dob: Yup.string().required("Date of birth is mandatory"),
    email: Yup.string().required("Email is mandatory").email(),
    panUrl:  Yup.string().required("PAN image is mandatory"),
  });

  const dispatch = useDispatch();

  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.userId;

  const { allState, cityByStateId } = useSelector((state) => state?.app);
  useEffect(() => {
    dispatch(getAllState());
    dispatch(cleanEcomLoader());
  }, []);

  const { loader } = useSelector((state) => state?.ecom);
  const datepickerRefStart = useRef();
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={() => {
          //   setStatus(false);
          handleClose();
        }}
        className="positon settingModalMargin"
        toggle={() => {
          //   setStatus(false);
          toggleModal();
        }}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">EMI Form</p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                toggleModal();
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody className="padding8 padding0L ">
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                fullName: "",
                companyName: "",
                pan: "",
                gst: "",
                phone: "",
                Dob: "",
                email: "",
                state: "",
                city: "",
                panUrl: "",
              }}
              onSubmit={(values, actions) => {
                console.log("values onSubmit", values);
                let values1 = {
                    "userId": userIdSession,
                    "addressId": addressId,
                    "isLogicoins": coinStatus ? 1 : 0,
                    // "productId": "any",
                    // "quantity": "any"
                    }
                let orderId = ""
                dispatch(createOrderAction(values1, (res) => {
                    console.log("create Order res", res);
                    if (res?.status == 200) {
                      const obj = {
                        orderId:res?.data?.data?.orderId,
                        fullName: values?.fullName,
                        companyName: values?.companyName,
                        pan: values?.pan,
                        gst: values?.gst,
                        phone: values?.phone,
                        Dob: values?.Dob,
                        email: values?.email,
                        state: values?.state,
                        city: values?.city,
                        panUrl: values?.panUrl,
                      }
                      const payload = {
                        "title": "EMI Query for logimart",
                        "phone": values?.phone,
                        "description": JSON.stringify(obj)
                      }
                        dispatch(ticketWithoutAuthAction(
                          payload, (res)=>{
                            console.log("emi resp", res)
                                if (res?.status == 200) {
                                    toggleModalCart();
                                    toggleModalPay();
                                    toggleModal();
                                }
                            }
                        ))
                    }}
                ))
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
                setFieldError,
              }) => (
                <Form onSubmit={handleSubmit} className="padding24T padding24">
                  <FormGroup>
                    <label
                      htmlFor="fullName"
                      className="semiBold heading3 blockDiv padding16B padding8L"
                    >
                      Full Name (as per PAN)
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      className={`input-body-new`}
                      name="fullName"
                      onChange={handleChange("fullName")}
                      value={values.fullName}
                      onBlur={handleBlur("fullName")}
                      touched={touched.fullName}
                      errors={errors.fullName}
                      placeholder="Your full name"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.fullName && touched.fullName ? (
                        <div>*{errors.fullName}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="companyName"
                      className="semiBold heading3 blockDiv padding16B padding8L padding8T"
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      className={`input-body-new`}
                      name="companyName"
                      onChange={handleChange("companyName")}
                      value={values.companyName}
                      onBlur={handleBlur("companyName")}
                      touched={touched.companyName}
                      errors={errors.companyName}
                      placeholder="Your company name"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.companyName && touched.companyName ? (
                        <div>*{errors.companyName}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="pan"
                      className="semiBold heading3 blockDiv padding16B padding8L padding8T"
                    >
                      PAN Number
                    </label>
                    <input
                      type="text"
                      id="pan"
                      className={`input-body-new`}
                      name="pan"
                      onChange={handleChange("pan")}
                      value={values.pan}
                      onBlur={handleBlur("pan")}
                      touched={touched.pan}
                      errors={errors.pan}
                      placeholder="Your PAN Number"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.pan && touched.pan ? (
                        <div>*{errors.pan}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="gst"
                      className="semiBold heading3 blockDiv padding16B padding8L padding8T"
                    >
                      GSTN
                    </label>
                    <input
                      type="text"
                      id="gst"
                      className={`input-body-new`}
                      name="gst"
                      onChange={handleChange("gst")}
                      value={values.gst}
                      onBlur={handleBlur("gst")}
                      touched={touched.gst}
                      errors={errors.gst}
                      placeholder="Your GST Number"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.gst && touched.gst ? (
                        <div>*{errors.gst}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="phone"
                      className="semiBold heading3 blockDiv padding16B padding8L padding16T"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      id="phone"
                      className={`input-body-new`}
                      name="phone"
                      onChange={handleChange("phone")}
                      value={values.phone}
                      onBlur={handleBlur("phone")}
                      touched={touched.phone}
                      errors={errors.phone}
                      placeholder="Your phone number"
                      maxLength="10"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.phone && touched.phone ? (
                        <div>*{errors.phone}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup className="dobEmi">
                    <label
                      htmlFor="Dob"
                      className="semiBold heading3 blockDiv padding16B padding8L padding16T"
                    >
                      Date of Birth (as per PAN)
                    </label>
                    <DatePickerField
                      name="Dob"
                      header="Select Date of Birth"
                      className="Regular heading4"
                      value={values.Dob}
                      onChange={(v) => {
                        setFieldValue("Dob", v, true);
                      }}
                      maxDate={new Date()}
                      ref1={datepickerRefStart}
                    />
                    <label
                      className="date-input"
                      style={{
                        position: "relative",
                        top: "-3rem",
                        left: "-2rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickDatepickerIcon1();
                      }}
                    >
                      <SvgImage
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        classname="date-logo"
                      />
                    </label>
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.Dob && touched.Dob ? (
                        <div>*{errors.Dob}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="email"
                      className="semiBold heading3 blockDiv padding16B padding8L padding16T"
                    >
                      Email ID
                    </label>
                    <input
                      type="email"
                      id="email"
                      className={`input-body-new`}
                      name="email"
                      onChange={handleChange("email")}
                      value={values.email}
                      onBlur={handleBlur("email")}
                      touched={touched.email}
                      errors={errors.email}
                      placeholder="Your email ID"
                    />
                    <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                      {errors.email && touched.email ? (
                        <div>*{errors.email}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="row">
                      <div className="col-6">
                        <label
                          htmlFor="state"
                          className="semiBold heading3 blockDiv padding16B padding8L padding16T"
                        >
                          State
                        </label>
                        <Field
                          component="select"
                          placeholder={"--Select--"}
                          class="input-body-new"
                          aria-label="Default select example"
                          name="state"
                          options={allState}
                          onChange={(e) => {
                            const valueSelect = e.target.value;

                            const stateId = allState?.find(
                              (opt) => opt.stateName === valueSelect
                            )?.stateId;
                            console.log("select", stateId);

                            dispatch(getCityByStateId(stateId));
                            setFieldValue("state", e.target.value, true);
                          }}
                          value={values.state}
                          onBlur={handleBlur("state")}
                          errors={errors.state}
                        >
                          <option value="" disabled selected>
                            --Select--
                          </option>
                          {allState?.map((opt) => {
                            return (
                              <option
                                value={opt?.stateName}

                                // onClick = {handleCity(opt?.stateId)}
                              >
                                {opt.stateName}
                              </option>
                            );
                          })}
                        </Field>
                        <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                          {errors.state && touched.state ? (
                            <div>*{errors.state}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-6">
                        <label
                          htmlFor="city"
                          className="semiBold heading3 blockDiv padding16B padding8L padding16T"
                        >
                          City
                        </label>
                        <Field
                          component="select"
                          placeholder={"--Select--"}
                          class="input-body-new"
                          aria-label="Default select example"
                          name="city"
                          onChange={handleChange("city")}
                          value={values.city}
                          onBlur={handleBlur("city")}
                          errors={errors.city}
                        >
                          <option value="" disabled selected>
                            --Select--
                          </option>
                          {cityByStateId?.map((opt) => {
                            return (
                              <option
                                value={opt?.cityName}
                                onChange={(v) => {
                                  setFieldValue("city", v, true);
                                }}
                              >
                                {opt?.cityName}
                              </option>
                            );
                          })}
                        </Field>
                        <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                          {errors.city && touched.city ? (
                            <div>*{errors.city}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    className="outlineDot padding8"
                    style={{ marginTop: "1rem", marginLeft: "0.5rem" }}
                  >
                    <label
                      htmlFor="panUrl"
                      className="semiBold heading3 blockDiv padding16B padding8L padding16T"
                    >
                      Attach PAN image
                    </label>
                    {!status ? (
                      <Button
                        className="add-attach-for-fastag-linked-vehicle"
                        type="submit"
                        style={{top:"0"}}
                        // disabled={Formik.setSubmitting}
                      >
                        {
                          imageLoader ? 
                          <div style={{zIndex:10}}>
                            <ClipLoader
                              color={"#485B7C"}
                              loading={true}
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </div>
                          : <div>
                            <SvgImage name="AddAttachment" height="36" width="36" />

                              <input
                                type="file"
                                accept="image/*"
                                // multiple
                                // accept="image/*"
                                className="add-attachment-btn"
                                onChange={(e) => {
                                  const img = e?.target.files[0];
                                  console.log("image", img?.name);
                                  // e.preventDefault();
                                  

                                  // e.preventDefault();
                                  

                                  const tempArray = Object.assign([], values.docs);
                                  console.log("sel", tempArray);

                                  if (img == null) {
                                    //clear image
                                    setFieldValue("panUrl", "", true);
                                    //for now  only one supported
                                  } else {
                                    console.log("upload", {
                                      img: img,
                                      uploadType: "aadharFront",
                                      isRes: true,
                                    });
                                    // e.preventDefault();
                                    // e.setSubmitting(false);
                                    // e.preventDefault();
                                    setImageLoader(true)
                                    dispatch(
                                      storeMedia(
                                        {
                                          img: img,
                                          uploadType: "aadharFront",
                                          isRes: false,
                                        },
                                        (res) => {
                                          setImageLoader(false)
                                          if (res?.url) {
                                            console.log("opt", res);
                                            tempArray.push(res?.data?.Location);
                                            console.log("opt1", tempArray);
                                            //setFieldValue("urlBack", res?.url, true);
                                            setFieldValue(
                                              "panUrl",
                                              res?.data?.Location,
                                              true
                                            );
                                            setDisplayName(
                                              (prev) => [{ name: img?.name }]
                                              //  [
                                              //   ...prev,
                                              //   { name: img?.name },
                                              // ]
                                            );
                                            setStatus(true);
                                            // setFieldValue('docs', tempArray, true);
                                            //for now  only one supported
                                            //setFieldValue("docs", tempArray, true);
                                          }
                                        }
                                      )
                                    );
                                    console.log(
                                      "FORM data",
                                      values.amount,
                                      values.bookingDate
                                    );

                                    // setFieldValue("amount", values.amount, true);
                                  }
                                }}
                              />
                          </div>
                        }
                        

                        <div
                          style={{
                            margin: "7px 0 0px 5px",
                            color: "red",
                          }}
                        >
                          {errors.panUrl && touched.panUrl ? (
                            <div>*{errors.panUrl}</div>
                          ) : null}
                        </div>
                      </Button>
                    ) : (
                        displayName?.map((d) => {
                            return (
                              <div className="row">
                                <div
                                  className="col-10 heading4 Regular"
                                //   style={{ paddingTop: "0.3rem" }}
                                >
                                  <p
                                    style={{
                                      margin: "0 0 0 0.5rem",
                                      color: "black",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={(e) => {
                                    setStatus(false);
                                    
                                    // e.preventDefault();
                                  }}
                                  style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage name="CloseButtonLogo" />
                                </div>
                              </div>
                            );
                          })
                    )}
                    <div className="padding16B padding8L padding16T">
                      ( Upload format must be in jpg/png/ jpeg )
                    </div>
                    {/* <div className="selected-img">
                      {status ? (
                        displayName?.map((d) => {
                          return (
                            <div className="row">
                              <div
                                className="col-10"
                                style={{ paddingTop: "0.3rem" }}
                              >
                                <p
                                  style={{
                                    margin: "0 0 0 96px",
                                    color: "black",
                                  }}
                                >
                                  {d.name}
                                </p>
                              </div>
                              <div
                                className="col-2"
                                onClick={(e) => {
                                  setStatus(false);
                                  
                                  // e.preventDefault();
                                }}
                                style={{ paddingBottom: "0.5rem" }}
                              >
                                <SvgImage name="CloseButtonLogo" />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div> */}
                  </FormGroup>
                  <FormGroup className="padding16T">
                    <button
                      type="submit"
                      className="quoteButtonDark"
                      // disabled={loader ? true : false}
                      style={{
                        // color:"#ffffff",
                        // background:"#2e7c7b",
                        background:
                          values.fullName == ""
                            ? "#EFEFEF"
                            : values.phone == ""
                            ? "#EFEFEF"
                            : values.companyName == ""
                            ? "#EFEFEF"
                            : values.state == ""
                            ? "#EFEFEF"
                            : values.city == ""
                            ? "#EFEFEF"
                            : values.pan == ""
                            ? "#EFEFEF"
                            : values.panUrl == ""
                            ? "#EFEFEF"
                            : values.Dob == ""
                            ? "#EFEFEF"
                            : values.email == ""
                            ? "#EFEFEF"
                            : loader
                            ? "#EFEFEF"
                            : "#485B7C",
                        color:
                          values.fullName == ""
                            ? "#B5B5B5"
                            : values.phone == ""
                            ? "#B5B5B5"
                            : values.companyName == ""
                            ? "#B5B5B5"
                            : values.state == ""
                            ? "#B5B5B5"
                            : values.city == ""
                            ? "#B5B5B5"
                            : values.pan == ""
                            ?"#B5B5B5"
                            : values.panUrl == ""
                            ? "#B5B5B5"
                            : values.Dob == ""
                            ? "#B5B5B5"
                            : values.email == ""
                            ? "#B5B5B5"
                            : loader
                            ? "#B5B5B5"
                            : "#ffffff",
                      }}
                    >
                      {loader ? "Please Wait" : "Apply for EMI"}
                    </button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default EmiForm;
