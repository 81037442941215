import React, { useEffect, useState } from "react";
import "./index.css";
import { Table } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAdvanceDriverList } from "../../../../services/Store/Actions/driverslistActions";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../../../subComponents/searchbar";
import ManageDriverDetailsCard from "../../../../Subcomponent/ManageDriverDetailsListCard";
import ManageVehicleListBar from "../../../ManageVehicles/SubComponents/ManageVehicleListBar";
import ManageDriverVehicleListBar from "../../../../Subcomponent/ManageDriverVehicleListBar";
import { padding } from "@mui/system";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";
import { driverEditStatusAction } from "../../../../services/Store/Actions/appActions";
import ClipLoader from "react-spinners/ClipLoader";

const DriverListAdvance = (props) => {
  //const [offset, setOffset] = useState(0);
  const { showingModal, openVKyc, activeStatus, assignedStatus } = props;
  const [search, setSearch] = useState("");
  const [fetchMoreData, setFetchMoreData] = useState(true);
  const [rcolor, setRColor] = useState(-1);
  const [loader, setLoader] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allDriversAdvance, allDriverListAdvance, driverListLoader } =
    useSelector((state) => state?.driverlist);
  console.log(
    "asdsadsakhdsgfkwiweasdas",
    allDriverListAdvance,
    allDriversAdvance
  );
  console.log("asdsadsakhdsgfkwiwe");
  const handlePrevButton = () => {
    if (props.offset > 0) {
      props.nextTrigger();
      props.setOffset(props.offset - 15);
      //console.log("offset", offset);
      dispatch(
        getAdvanceDriverList({
          offset: props.offset - 15,
          search: props.search.trim(),
          minKyc: "",
          sortAs: "",
          sortBy: "",
          activeStatus: activeStatus,
          assignedStatus: assignedStatus,
        })
      );
    }
    //props.allowTrigger();
    console.log("X--");
  };
  const handleNextButton = () => {
    //props.nextTrigger();
    if (allDriversAdvance?.count - props.offset > 15) {
      props.nextTrigger();
      props.setOffset(props.offset + 15);
      //console.log("offset", offset);
      dispatch(
        getAdvanceDriverList({
          offset: props.offset + 15,
          search: props.search.trim(),
          minKyc: "",
          sortAs: "",
          sortBy: "",
          activeStatus: activeStatus,
          assignedStatus: assignedStatus,
        })
      );
    }
    //props.allowTrigger();
    console.log("X++");
  };

  const handleClick = (e, i) => {
    props.allowTrigger();
    console.log(
      "drivers ki list",
      `/Drivers/${e.userId}?search=${props.search}&offset=${props.offset}`
    );

    storeDataToAsyncStorage("_driverId", e.driverId);
    navigate(
      `/Drivers/${e.userId}?search=${props.search}&offset=${props.offset}`
    );
  };

  let { id } = useParams();

  useEffect(() => {
    let urlId = window.location.pathname.split("/");

    if (urlId.length === 3) {
      if (urlId[2] != undefined && urlId[2] != "") {
        let i = allDriverListAdvance?.findIndex(
          (element) => element.userId === urlId[2]
        );

        setRColor(i);
      }
    }
  }, [id, allDriversAdvance]);

  return (
    <div className="main-all-customer-list">
      <div id="scrollableDivCustomer" className="div-table">
        <div className="div-table-row">
          <div className="listHeaderCont">
            <div className="div-table-col width50 FontBold"> Driver Name</div>

            <div class="div-table-col width50 FontBold"> Total Balance</div>
          </div>
        </div>
        {driverListLoader ? (
          <div>
            <div
              className="text-center"
              style={{
                padding: 16,
              }}
            >
              <ClipLoader
                color={"#485B7C"}
                loading={true}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        ) : (
          <>
            {allDriverListAdvance?.map((data, i) => {
              console.log(data);

              let color = " ";

              // no wallet=0, neoKred=1, m2p= 2
              let accountType = Number(data?.AccountType);
              let bal = data?.walletBalance?.toFixed(2) || "N/A";
              let accountStatus = data?.walletAccountStatus;
              // if (accountStatus < 3 || accountStatus == undefined) {
              //   color = "red";
              // } else {
              //   color = "green";
              // }
              if (accountStatus < 3 || accountStatus > 4) {
                color = "#0d6efd";
              } else {
                color = "green";
              }
              console.log(bal);

              let totalBalVal =
                accountType === 0
                  ? "No card"
                  : accountType === 5
                  ? data?.walletAccountStatus === 0
                    ? "No card"
                    : bal === ""
                    ? "₹ 0"
                    : "₹ " + bal
                  : data?.walletAccountStatus < 3
                  ? "No card"
                  : data?.walletAccountStatus == 3
                  ? "Card Not Linked"
                  : bal === null
                  ? "₹ 0"
                  : "₹ " + bal;

              // let totalBalVal =
              //   data?.User?.WalletAccount?.accountStatus < 3
              //     ? "KYC Pending"
              //     : data?.User?.WalletAccount?.accountStatus == 3
              //     ? "Card Not Linked"
              //     : data?.User?.WalletAccount?.accountStatus == undefined
              //     ? "KYC Pending"
              //     : bal === null
              //     ? "₹ 0"
              //     : "₹" + bal;
              const trimmedName = data.fullName.trim();

              const name = trimmedName.split(" ");
              const d = {
                firstName: name[0],
                lastName: name[name.length - 1],
              };
              const firstName_initials = d.firstName[0];
              const lastName_initials = d.lastName[0] + ".";
              return (
                <div
                  className={
                    rcolor === i
                      ? "div-table-row actve-button-chosen-for-customer"
                      : data?.isActive == 1
                      ? "div-table-row"
                      : "div-table-row disabledRow"
                  }
                  key={i}
                >
                  <div className="rowCont" onClick={() => handleClick(data, i)}>
                    <div class="div-table-col width50 FontNB">
                      <div data-letters={firstName_initials.toUpperCase()}>
                        <span className=" FontNB">
                          {d.firstName.length > 10
                            ? d.firstName.substring(0, 8) + ".."
                            : d.firstName}
                        </span>
                        <span className="FontNB">
                          {" "}
                          {lastName_initials.toUpperCase()}
                        </span>
                      </div>
                      <div class="nameSubInfo FontSmall">
                        {data?.vrn === null ? "no vehicle" : data?.vrn}
                      </div>
                    </div>

                    <div
                      class={
                        totalBalVal == "Issue card"
                          ? "div-table-col width30 FontNB"
                          : "div-table-col width30 FontNB"
                      }
                    >
                      {accountType === 5 ? (
                        <>
                          <div
                            style={{
                              color: color,
                              paddingRight: "1rem",
                              width: "55%",
                              borderRadius: "4px",
                            }}
                            onClick={() => {
                              // if(totalBalVal=="Issue card")
                              // showingModal();
                            }}
                            className={
                              totalBalVal == "Issue card"
                                ? "issueCardLink backFocus"
                                : ""
                            }
                          >
                            {totalBalVal}
                          </div>
                          {data?.walletAccountStatus === "1" ? (
                            <div
                              className="redColor FontSmall backFocusVKYC"
                              onClick={() => {
                                // openVKyc();
                              }}
                              style={{ padding: "0.3rem" }}
                            >
                              V-KYC Pending
                            </div>
                          ) : data?.walletAccountStatus === "3" ? (
                            <div
                              className="redColor FontSmall backFocusVKYC"
                              onClick={() => {
                                // openVKyc();
                              }}
                              style={{ padding: "0.3rem" }}
                            >
                              V-KYC Rejected
                            </div>
                          ) : data?.walletAccountStatus === "5" ? (
                            <div
                              className="redColor FontSmall backFocusVKYC"
                              onClick={() => {
                                // openVKyc();
                              }}
                              style={{ padding: "0.3rem" }}
                            >
                              V-KYC Rejected
                            </div>
                          ) : data?.walletAccountStatus === "2" ? (
                            <div
                              className="redColor FontSmall backFocusVKYC"
                              onClick={() => {
                                // openVKyc();
                              }}
                              style={{ padding: "0.3rem" }}
                            >
                              V-KYC Pending
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <div
                          style={{
                            color: color,
                            width: "55%",
                            borderRadius: "4px",
                          }}
                          onClick={() => {
                            // if(totalBalVal=="Issue card")
                            //   showingModal();
                          }}
                          className={
                            totalBalVal == "Issue card"
                              ? "issueCardLink  backFocus"
                              : ""
                          }
                        >
                          {totalBalVal}
                        </div>
                      )}
                    </div>
                    <div className="div-table-col width20">
                      {loader == data?.userId ? (
                        <div
                          className=""
                          style={{
                            padding: 6,
                          }}
                        >
                          <ClipLoader
                            color={"#485B7C"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        <button
                          style={{
                            backgroundColor:
                              rcolor === i
                                ? "#f3f5fd"
                                : data?.isActive == 1
                                ? "white"
                                : "#F2F2F2",
                            border: 1,
                            padding: 6,
                          }}
                          onClick={() => {
                            setLoader(data?.userId);
                            dispatch(
                              driverEditStatusAction(
                                {
                                  userId: data?.userId,
                                  isActive: data?.isActive == 0 ? "1" : "0",
                                },
                                (res) => {
                                  setLoader("");
                                  if (res.status == 200) {
                                    dispatch(
                                      getAdvanceDriverList({
                                        offset: 0,
                                        search: search.trim(),
                                        minKyc: "",
                                        sortAs: "",
                                        sortBy: "",
                                        activeStatus: activeStatus,
                                        assignedStatus: assignedStatus,
                                      })
                                    );
                                  }
                                }
                              )
                            );
                          }}
                        >
                          {data?.isActive == 1 ? (
                            <SvgImage name="deleteIcon2" />
                          ) : (
                            <SvgImage name="restore" />
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="nextRow ">
        <div className="nextRowCont ">
          <button
            className="PrevButton"
            onClick={handlePrevButton}
            style={{ opacity: props.offset > 0 ? "1" : "0.5" }}
          >
            <SvgImage name="PrevArrow" heigth="12px" width="12px" />
          </button>
          <button
            className="nextButton"
            onClick={handleNextButton}
            style={{
              opacity:
                allDriversAdvance?.count - props.offset > 15 ? "1" : "0.5",
            }}
          >
            <SvgImage name="NextArrow" heigth="12px" width="12px" />
          </button>
        </div>
      </div>
    </div>

    //   <div className="FullDriverListBarLoc" id="scrollableDivDriverm">

    //     <div>
    //       <div className="FullVehicleDetailsListBar">
    // <ManageDriverVehicleListBar />
    //       </div>

    //       <div id="vehicleScroller" className="scrollVehicleList">

    //         {allDriversAdvance?.rows?.map((data, i) => {
    //           let color = " ";
    //           let bal = data?.User?.WalletAccount?.balance;
    //           let accountStatus = data?.User?.WalletAccount?.accountStatus;
    //           if (accountStatus < 3 || accountStatus == undefined) {
    //             color = "red";
    //           } else {
    //             color = "green";
    //           }
    //           console.log(bal);

    //           let totalBalVal =
    //             data?.User?.WalletAccount?.accountStatus < 3
    //               ? "KYC Pending"
    //               : data?.User?.WalletAccount?.accountStatus == 3
    //               ? "Card Not Linked"
    //               : data?.User?.WalletAccount?.accountStatus == undefined
    //               ? "KYC Pending"
    //               : bal === null
    //               ? "₹ 0"
    //               : "₹" + bal;
    //           return (
    //             <div
    //               className="row row-for-vehicle-list"
    //               onClick={() => handleClick(data, i)}
    //               style={{ cursor: "pointer" }}
    //             >
    //               <div
    //                 className={
    //                   rcolor === i ? " col-1 actve-button-chosen " : "col-1"
    //                 }
    //               ></div>
    //               <div
    //                 className={
    //                   rcolor === i
    //                     ? " col-11 row active-button-list active-div "
    //                     : "col-11 row active-div"
    //                 }

    //               >
    //                 <div className="col-4 row">
    //                   <div className="col-4">
    //                     <div
    //                       className="profile-img-driver-list text-center "
    //                       style={{
    //                         postion: "relative",
    //                         top: "13px",
    //                         left: "-17px",
    //                       }}
    //                     >
    //                       <span className="text-in-circle">
    //                         {data.User?.fullName?.substring(0, 1)}
    //                       </span>
    //                     </div>
    //                   </div>
    //                   <div
    //                     className="col-8"
    //                     style={{
    //                       position: "relative",

    //                       top: "9px",
    //                     }}
    //                   >
    //                     <p>{data.User?.fullName}</p>
    //                   </div>
    //                 </div>
    //                 <div
    //                   className="col-4"
    //                   style={{
    //                     position: "relative",
    //                     left: "52px",
    //                     top: "19px",
    //                   }}
    //                 >
    //                   <p>
    //                     {" "}
    //                     {data?.User?.fleet === null
    //                       ? "no vehicle"
    //                       : data?.User?.fleet}
    //                   </p>
    //                 </div>
    //                 <div
    //                   className="col-4"
    //                   style={{
    //                     position: "relative",
    //                     left: "59px",
    //                     top: totalBalVal === "KYC Pending" ? "9px" : "19px",
    //                     color: color,
    //                   }}
    //                 >
    //                   <p>{totalBalVal}</p>
    //                 </div>
    //               </div>

    //             </div>

    //           );
    //         })}

    //       </div>

    //     </div>

    //   </div>
  );
};
export default DriverListAdvance;
