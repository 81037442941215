import React, { useState } from 'react'
import SvgImage from '../../../Icons/Icons'
import "./index.css";
import { downloadSalaryAction, downloadSalaryCreditSheet, uploadSalaryAction, uploadSalaryCreditSheet } from '../../../../services/Store/Actions/driverslistActions'
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { bulkTransferUpload, downloadBulkTransferAction } from '../../../../services/Store/Actions/walletAction';
import { FormCheck } from 'react-bootstrap';
import { bulkFuelTransferUpload } from '../../../../services/Store/Actions/TripsAction';
function BulkFuelModal(props) {
    const [fileSave, setFileSave] = useState(null);
    const [loader, setLoader] = useState(false); 
    const [bulkTransfer, setBulkTransfer] = useState(0); 
    const [bulkTransferId, setBulkTransferId] = useState(null); 
    const [isSalary, setIsSalary] = useState(false)
    const dispatch = useDispatch();
    console.log("downloadSalaryActioeeen",)
    const customId1 = "custom-id-download page";
    const notify1 = () => toast.success("Template was downloaded successfully.", {
        toastId: customId1,
        pauseOnHover: false,
    });
    const customId2 = "custom-id-upload page";
    const notify2 = () => toast.success("Transfers data was uploaded successfully.", {
        toastId: customId2,
        pauseOnHover: false,
    });
    const customId3 = "custom-id-Salary-dispersed";
    const notify3 = () => toast.success("Amount dispersed successfully to drivers.", {
        toastId: customId3,
        pauseOnHover: false,
    });

    const downloadSalDisbursement = () => {
        // dispatch(downloadBulkTransferAction("", (res) => {
        //     console.log("downloadSalaryAction", res?.data)
        //     if (res?.status == 200) {
              const blob = new Blob(["Vehicle Number"+"\t"+"amount"+"\t"+"litres"+"\t"+"date(DD-MM-YYYY)"+"\t"+"remarks"+"\n"])
              const link = document.createElement("a");
             link.href = window.URL.createObjectURL(blob);
             link.download = `file.xls`; //name to changed here
             link.click();
              notify1();
        //     }
        //   }))
    }

    const bulkTransferCall = ()=>{
        setLoader(true);
        dispatch(bulkFuelTransferUpload({file:fileSave,vendorId : props?.vendorId},(res)=>{
            console.log(res);
            setLoader(false);
            if(res.status==200){
                const blob = new Blob([res?.data])
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `responseFile.xls`; //name to changed here
                link.click();
                notify3();
            }else {
                toast.error(res.data?.message||"some error occured")
            }
            
        }))
}
return (
    <div style={{width: "300px"}}>
        <div>
            <div style={{display: "flex",flexDirection: "row",  marginTop: "-10px"}}>
                {/* <FormCheck 
                    label={'Salary'}
                    onChange={()=>{
                        setIsSalary(!isSalary)
                    }}
                    value={isSalary}
                > 
                </FormCheck> */}
            </div>
            <div style={{display:"flex",flexDirection:"row"}}>
                <div className="col-lg-6" style={{padding:"10px"}} onClick={downloadSalDisbursement}>
                    <div className='svgContainerUp' style={{margin:'0 23px'}}>
                        <SvgImage name='Download' />
                    </div>
                    <div className='Bold300' style={{textAlign: "center"}}>Download Template</div>
                </div>
                <div className="col-lg-6" style={{padding:"10px"}} >
                    <label for="file-upload2" class="custom-file-upload3" >
                        <input
                            id='file-upload2'
                            type="file"
                            onChange={async (e) => {
                            const img = e?.target.files[0];
                            setFileSave(img);
                            console.log("image", img?.name);
                            setBulkTransfer(1);
                            notify2();
                            }}
                        />
                        <div className='svgContainerUp' style={{margin:'0px 23px', backgroundColor:bulkTransfer>0?"antiquewhite":null}}>
                            <SvgImage name='Upload'/>
                        </div>
                        <div className='Bold300' style={{textAlign: "center"}}>
                        Upload for bulk Transfers
                        </div>
                    </label>        
                </div>
            </div>
        </div>
        { bulkTransfer == 1 &&
                    <div style={{display: "flex",flexDirection: "column", alignItems: "center", marginTop: "-10px"}}>
                        <div style={{margin:".5rem"}}>
                            <a 
                                disabled={loader}
                                style={{margin:".5rem"}}
                                href={fileSave} >{fileSave?.name||"test"}
                            </a> 
                        </div>
                        <div style={{}} >
                            <input
                            type='text'
                            className=''
                            name='bulkTransferId'
                            placeholder='Enter Unique TransferId'
                            disabled={loader}
                            onChange={(e)=>{
                                console.log(e.target.value);
                                setBulkTransferId(e.target.value);
                            }}
                            />
                        </div>
                        <div>
                            <Button
                                className='greenButton'
                                style={{margin:".5rem"}}
                                onClick={bulkTransferCall}
                                disabled={loader} > Transfer
                            </Button>
                        </div>
                    </div>
                    }
    </div>
        
  )
}

export default BulkFuelModal