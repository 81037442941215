import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Modal, ModalBody } from "reactstrap";
import "./index.css";
import SvgImage from "../../../Icons/Icons";
function SuccessModal(props) {
  const { open, onHide, toggleModal, handleClose, text } = props;
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={open}
      onHide={onHide}
      toggle={toggleModal}
      className="Success-Modal settingModalMargin"
      //   toggle={toggle}
    >
      <div
        className="closeRemove"
        onClick={() => {
          // Router.reload();
          handleClose();
        }}
      >
        <SvgImage name="CloseButtonLogo" />
      </div>
      <div
        className="closeRemove"
        onClick={() => {
          // Router.reload();
          handleClose();
        }}
      >
        <SvgImage name="CloseButtonLogo" />
      </div>
      <div className="padding32" style={{ textAlign: "center" }}>
        {/* <div className=" centreClass">
                    <img src="/images/ecom/failedCross.svg" className="" />
                    </div> */}

        <div>
          <SvgImage name="successTick" />
        </div>
        <div className=" Medium heading2 padding16T">
          {text || 'Success'}
        </div>
      </div>
    </Modal>
  );
}

export default SuccessModal;
