import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Table,
} from "reactstrap";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import SvgImage from "../Icons/Icons";
import { getCustomerAction } from "../../services/Store/Actions/customerAction";
import {
  getLRList,
  getTripsNew,
  signedUrlWithKey,
} from "../../services/Store/Actions/TripsAction";
import CreateLRModal from "../LoadReceipt/Components/CreateLRModal";
import { storeDataToAsyncStorage } from "../../services/Utils/functions";
import { getSignedUrlApiAction } from "../../services/Store/Actions/appActions";
import moment from "moment";

function LoadReceiptDetail(props) {
  const { sideBar, setItem, item } = props;
  const [formValues, setFormValues] = useState([
    { desc: "", quantity: "", weightPerQuantity: "", amountPerQuantity: "" },
  ]);
  const [lrData, setLrData] = useState([]);
  const [lrChargesData, setLrChargesData] = useState([]);
  let { tripsListNew } = useSelector((store) => store?.trip);
  const {state} = useLocation()
  const [freightData, setFreightData] = useState();
  console.log("statestatestatestate", state);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [lrImages, setlrImages] = useState(-1);
  const [podImages, setPodImages] = useState(-1);
  const [consineeDetails, setConsineeDetails] = useState("");
  const [consinorDetails, setConsinorDetails] = useState("");
  const [transporterDetails, setTransporterDetails] = useState("");
  const [reload, setReload] = useState(false);

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips, setIsTrips] = useState(0);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  let { freightList } = useSelector((store) => store?.trip);
  useEffect(() => {
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
  }, [authUser, managerControlList]);
  const createLR = () => setModal(!modal);
  const editLR = () => setModal(!modal);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    console.log("users as props", state);
    if (id !== undefined && freightData) {
      dispatch(
        getCustomerAction({ customerId: freightData?.consigneeId }, (res) => {
          console.log("consigneeIdconsigneeIdconsigneeId", res);
          setConsineeDetails(res?.data?.customer);
        })
      );
      dispatch(
        getCustomerAction({ customerId: freightData?.consignorId }, (res) => {
          console.log("consignorIdconsignorIdconsignorId", res);
          setConsinorDetails(res?.data?.customer);
        })
      );
      dispatch(
        getCustomerAction({ customerId: freightData?.transportorId }, (res) => {
          console.log("transportorIdtransportorIdtransportorIdad", res);
          setTransporterDetails(res?.data?.customer);
        })
      );

      setLrData(freightData?.lrData);
      setLrChargesData(freightData?.chargesData);
      dispatch(
        signedUrlWithKey(
          {
            key: freightData?.lrUrl,
          },
          (res) => {
            if (res?.data) {
              console.log("dddddfddimg");
              setlrImages(res?.data);
            }
          }
        ),
        dispatch(
          signedUrlWithKey(
            {
              key: freightData?.podUrl,
            },
            (res) => {
              if (res?.data) {
                console.log("dddddfddimg");
                setPodImages(res?.data);
              }
            }
          )
        )
      );
      if (freightData?.tripId) {
        dispatch(
          getTripsNew({
            offset: 0,
            limit: 10,
            tripId: freightData?.tripId,
          })
        );
      }

     
    } else {
    }
  }, [id,freightData]);
  useEffect(()=>{
    dispatch(
      getLRList(
        {
          offset: state?.offset || 0,
          limit: 10,
          fleetId: state?.fleetIdTemp,
          driverId: state?.driverIdTemp,
          tripNo: state?.tripNoTemp,
          lrNo: state?.lrNoTemp,
          startTime: state?.startDate,
          endTime: state?.endDate,
          source: state?.searchSource,
          destination: state?.searchDestination,
          ...state?.payload,
        },
        () => {}
      )
    );
  },[id])
  useEffect(() => {
    if (freightList?.length > 0) {
      let filter = freightList?.find(
        (elem) => elem.freightId == state?.freightId
      );
      setFreightData(filter);
      console.log("statestatestatestate 222 ", filter, state?.freightId);
    }
  }, [freightList]);
  const [editLRecDetails, setEditLRecDetails] = useState(false);
  const [download, setDownload] = useState(false);
  const TogglEditLoadRec = () => {
    console.log("editLrDetails");
    setEditLRecDetails(!editLRecDetails);
  };

  // console.log('totalAmmount'+totalAmmount);

  let finalAmount = 0;
  let finalCharges = 0;
  if (lrData?.length > 0) {
    lrData.map(
      (element) =>
        (finalAmount =
          finalAmount +
          parseInt(
            element?.amountPerQuantity == "" ? 0 : element?.amountPerQuantity
          ) *
            parseInt(element?.quantity == "" ? 0 : element?.quantity))
    );
  }
  if (lrChargesData?.length > 0)
    lrChargesData.map(
      (elem) =>
        (finalCharges =
          finalCharges + parseInt(elem?.amount == "" ? 0 : elem?.amount))
    );

  const printDocument = () => {
    setDownload(true);
    html2canvas(document.getElementById("capture"), {
      useCORS: true,
      allowTaint: true,
      logging: true,
      letterRendering: 1,
    }).then((canvas) => {
      console.log("canvascanvascanvas", canvas);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProperties = pdf.getImageProperties(imgData);
      console.log("canvascanvascanvas222", imgProperties);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      console.log("canvascanvascanvas444", pdfHeight, pdfWidth);

      pdf.addImage(imgData, "PNG", 5, 5, pdfWidth - 10, pdfHeight - 10);
      pdf.save("download.pdf");
    });
    setDownload(false);
  };
  return (
    <div
      className="container topMargin sideBarMargin"
      style={{
        // paddingTop:"5.5rem",
        marginLeft: sideBar ? "220px" : "100px",
        maxWidth: sideBar ? windowWidth - 240 : windowWidth - 130,
        backgroundColor: "rgb(255, 255, 255)",
      }}
    >
      <div id="capture">
        {true ? (
          <div className="row semibold">
            <div className="col-3">
              <div
                className="flexStyling mb-3 driverHeader"
                style={{
                  justifyContent: "space-around",
                  paddingRight: "20px",
                  marginLeft: "20px",
                }}
              >
                <div className="">Company Name:</div>
                <div className="">{authUser?.company?.companyName}</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="flexStyling mb-3"
                style={{
                  justifyContent: "space-around",
                  paddingRight: "20px",
                  marginLeft: "20px",
                }}
              >
                <div className="driverHeader">Address:</div>
                <div className="">
                  {authUser?.company?.address1 + " " + authUser?.company?.city}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="flexStyling mb-3"
                style={{
                  justifyContent: "space-around",
                  paddingRight: "20px",
                  marginLeft: "20px",
                }}
              >
                <div className="driverHeader">Contact:</div>
                <div className="">{authUser?.user?.phone}</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="flexStyling mb-3"
                style={{
                  justifyContent: "space-around",
                  paddingRight: "20px",
                  marginLeft: "20px",
                }}
              >
                <div className="driverHeader">GSTIN:</div>
                <div className="">{authUser?.company?.gstin}</div>
              </div>
            </div>
            <hr style={{ border: "3px solid" }}></hr>
          </div>
        ) : (
          <div></div>
        )}
        <div className="flexStyling d-flex justify-content-between">
          <div
            className=" cardInfo"
            style={{ flex: 1 }}
            onClick={() => {
              navigate("/Customers/" + consinorDetails?.customerId);
            }}
          >
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="driverHeader">Consignor Details</div>
              <div>
                {" "}
                <SvgImage
                  name={
                    freightData?.customerId == consinorDetails?.customerId
                      ? "payIcon2"
                      : "rightArrow"
                  }
                />
              </div>
            </div>
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Name</div>
              <div className="">{freightData?.consignorName}</div>
            </div>
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Address </div>
              <div className=""> {consinorDetails?.TripAddress?.address}</div>
            </div>
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Phone</div>
              <div className="">{consinorDetails?.phone}</div>
            </div>
          </div>
          <div
            className=" cardInfo"
            style={{ flex: 1 }}
            onClick={() => {
              navigate("/Customers/" + consineeDetails?.customerId);
            }}
          >
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="driverHeader">Consignee Details</div>
              <div>
                <SvgImage
                  name={
                    freightData?.customerId == consineeDetails?.customerId
                      ? "payIcon2"
                      : "rightArrow"
                  }
                />
              </div>
            </div>
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Name</div>
              <div className="">{freightData?.consigneeName}</div>
            </div>
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Address</div>
              <div className=""> {consineeDetails?.TripAddress?.address}</div>
            </div>
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Phone</div>
              <div className="">{consineeDetails?.phone}</div>
            </div>
          </div>
          <div
            className=" cardInfo"
            style={{ flex: 1 }}
            onClick={() => {
              navigate("/Customers/" + transporterDetails?.customerId);
            }}
          >
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="driverHeader">Transporter Details </div>
              <div>
                <SvgImage
                  name={
                    freightData?.customerId == transporterDetails?.customerId
                      ? "payIcon2"
                      : "rightArrow"
                  }
                />
              </div>
            </div>
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Name</div>
              <div className="">{freightData?.transportorName}</div>
            </div>
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Address</div>
              <div className="">
                {" "}
                {transporterDetails?.TripAddress?.address}
              </div>
            </div>
            <div
              className="flexStyling"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Phone</div>
              <div className="">{transporterDetails?.phone}</div>
            </div>
          </div>
        </div>
        <div className="flexStyling mb-3 d-flex justify-content-between">
          <div className="cardInfoLR mb-2" style={{ minWidth: "35%" }}>
            <div
              className="flexStyling mb-2"
              style={{ justifyContent: "space-between" }}
            >
              <div className="driverHeader">LR Details</div>
            </div>
            <div
              className="flexStyling mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">LR No</div>
              <div className="">{freightData?.lrNo}</div>
            </div>
            <div
              className="flexStyling mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Payor</div>
              <div className="">
                {freightData?.customerId
                  ? freightData?.customerId == freightData?.consigneeId
                    ? freightData?.consigneeName
                    : freightData?.customerId == freightData?.consignorId
                    ? freightData?.consignorName
                    : freightData?.transportorName
                  : "N/A"}
              </div>
            </div>
            <div
              className="flexStyling mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Vehicle</div>
              <div className="">{freightData?.Fleet?.regNumber}</div>
            </div>
            <div
              className="flexStyling mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Driver</div>
              <div className="">{freightData?.User?.fullName}</div>
            </div>
            {/* <div className="flexStyling mb-3" style={{justifyContent:"space-between"}}>
                          <div className="">Trip ID</div>
                          <div className="">{freightData?.tripId}</div>
                      </div> */}
            <div
              className="flexStyling mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">From</div>
              <div className="" style={{ textAlign: "end" }}>
                {freightData?.fromAddress?.address}
              </div>
            </div>
            <div
              className="flexStyling mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">To</div>
              <div className="" style={{ textAlign: "end" }}>
                {freightData?.toAddress?.address}
              </div>
            </div>
            {/* {freightData?.tripId ? ( */}
            {freightData?.tripId ? (
              <div
                className="flexStyling mb-3"
                style={{ justifyContent: "space-between" }}
              >
                <div className="">Trip No.</div>
                <div
                  className=""
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                    color: "blue",
                  }}
                  onClick={() => {
                    storeDataToAsyncStorage("row", tripsListNew?.[0]);
                    navigate(`/trips/${tripsListNew?.[0]?.tripId}`);
                  }}
                >
                  {tripsListNew?.[0]?.tripNo}
                </div>
              </div>
            ) : null}
            <div
              className="flexStyling mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <div className="">Create Date</div>
              <div>{freightData?.startDate}</div>
            </div>
            {/* <div className="flexStyling driverHeader"  style={{ justifyContent: "end" , marginTop:"20px"}}>
                <div ><SvgImage name="downloadImg" width="24" height="24" /></div>
                <div  onClick={printDocument}>Download Reciept</div>
              </div> */}
          </div>
          <div className="cardInfoLR">
            <div>
              <div className="driverHeader  mb-4">
                Lorry Receipt
                <div className="flexStyling" style={{ justifyContent: "end" }}>
                  <div onClick={printDocument}>
                    <SvgImage name="downloadImg" width="24" height="24" />
                  </div>
                  <div
                    onClick={() => {
                      // console.log("editLReditLReditLR", i);
                      // setFreightId(freightData?.id);
                      if (["2", "3", 2, 3].includes(isTrips)) {
                        setIsEdit(true);
                        editLR();
                      }
                    }}
                  >
                    <SvgImage name="editLogo" width="25" height="25" />
                  </div>
                </div>
              </div>
              <div>
                {lrData?.length &&
                lrData?.length > 0 &&
                lrData[0]?.amountPerQuantity != "" ? (
                  <Table
                    className=""
                    // bordered="true"
                    id="driver_tables"
                    // style={{ marginTop: "16px" }}
                  >
                    <thead>
                      <tr className="table-header-trip p-0 align-middle text-center">
                        <th className="p-0" style={{ width: "10%" }}>
                          <div style={{ marginLeft: "10px" }}>S.No</div>
                        </th>
                        <th
                          className="p-0"
                          style={{
                            width: "30%",
                            // textAlignLast: "center",
                          }}
                        >
                          <div>Description</div>{" "}
                        </th>
                        <th
                          className="p-0"
                          style={{
                            width: "16%",
                            // textAlignLast: "center",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          className="p-0"
                          style={{
                            width: "25%",
                            // textAlignLast: "center",
                          }}
                        >
                          Weight/Quantity (kgs)
                        </th>
                        <th
                          className="p-0"
                          style={{
                            width: "20%",
                            // textAlignLast: "center",
                          }}
                        >
                          Amount/Quantity
                        </th>
                        <th
                          className="p-0"
                          style={{
                            width: "14%",
                            textAlignLast: "center",
                          }}
                        >
                          Total Amount
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      {lrData.map((element, index) => (
                        <tr
                          className="table-row-manage-trip-new text4 align-middle text-center p-0"
                          id="addr0"
                        >
                          <td
                            style={{
                              width: "10%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                            className="p-0"
                          >
                            <div style={{ marginLeft: "10px" }}>
                              {index + 1}
                            </div>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                            className="p-0"
                          >
                            {element?.desc}
                          </td>
                          <td
                            style={{
                              width: "16%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                            className="p-0"
                          >
                            {element?.quantity?.toLocaleString("en-IN") || "-"}
                          </td>
                          <td
                            style={{
                              width: "25%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                            className="p-0"
                          >
                            {element?.weightPerQuantity?.toLocaleString(
                              "en-IN"
                            ) || "-"}
                          </td>
                          <td
                            style={{
                              width: "20%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                            className="p-0"
                          >
                            {element?.amountPerQuantity?.toLocaleString(
                              "en-IN"
                            ) || "-"}
                          </td>
                          <td
                            style={{
                              width: "14%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                              textAlignLast: "center",
                            }}
                            className="p-0"
                          >
                            {"₹ " +
                              (
                                parseInt(
                                  element?.amountPerQuantity == ""
                                    ? 0
                                    : element?.amountPerQuantity
                                ) *
                                parseInt(
                                  element?.quantity == ""
                                    ? 0
                                    : element?.quantity
                                )
                              )?.toLocaleString("en-IN")}
                          </td>
                        </tr>
                      ))}
                    </thead>
                  </Table>
                ) : null}

                <div style={{ paddingTop: "10px" }}>
                  {lrChargesData?.length &&
                  lrChargesData?.length != 0 &&
                  lrChargesData[0]?.amount != "" ? (
                    <Table
                      className=""
                      // bordered="true"
                      id="driver_tables"
                      // style={{ marginTop: "16px" }}
                    >
                      <div
                        className="semibold"
                        style={{ textAlignLast: "center" }}
                      >
                        Penalty/Charges
                        {/* <button type="buttonlr" className="left-btn" onClick={() => TogglEditLoadRec()}>Edit Details</button> */}
                      </div>
                      <thead>
                        <tr className="table-header-trip p-0 align-middle text-center">
                          <th className="p-0" style={{ width: "10%" }}>
                            <div style={{ marginLeft: "10px" }}>S.No</div>
                          </th>

                          <th
                            className="p-0"
                            style={{
                              width: "60%",
                              textAlignLast: "center",
                            }}
                          >
                            Charges Remarks
                          </th>
                          <th
                            className="p-0"
                            style={{
                              width: "14%",
                              textAlignLast: "center",
                            }}
                          >
                            <div>Amount</div>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="lowerBorderLineCust">
                        {lrChargesData.map((element, index) =>
                          element?.amount != "" ? (
                            <tr
                              className="table-row-manage-trip-new text4 align-middle text-center p-0"
                              id="addr0"
                            >
                              <td
                                style={{
                                  width: "10%",
                                  borderLeft: "1px solid transparent",
                                  borderRight: " 1px solid transparent",
                                  textAlignLast: "center",
                                }}
                                className="p-0"
                              >
                                <div style={{ marginLeft: "10px" }}>
                                  {index + 1}
                                </div>
                              </td>

                              <td
                                style={{
                                  width: "60%",
                                  borderLeft: "1px solid transparent",
                                  borderRight: " 1px solid transparent",
                                  textAlignLast: "center",
                                }}
                                className="p-0"
                              >
                                {element?.remarks || "-"}
                              </td>
                              <td
                                style={{
                                  width: "14%",
                                  borderLeft: "1px solid transparent",
                                  borderRight: " 1px solid transparent",
                                  textAlignLast: "center",
                                }}
                                className="p-0"
                              >
                                {"₹ " +
                                  parseFloat(element?.amount)?.toLocaleString(
                                    "en-IN"
                                  )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )
                        )}
                      </tbody>
                    </Table>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="row">
                  <div
                    className="semibold col-6"
                    style={{ paddingTop: "10px", textAlignLast: "right" }}
                  >
                    Total
                  </div>
                  <div
                    className="semibold col-6"
                    style={{
                      paddingTop: "10px",
                      textAlignLast: "right",
                      paddingRight: "50px",
                    }}
                  >
                    {"₹ " +
                      (
                        parseFloat(finalAmount) - parseFloat(finalCharges)
                      )?.toLocaleString("en-IN")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CreateLRModal
            toggle={createLR}
            modal={modal}
            setModal={setModal}
            location={"/lr/"}
            isEdit={isEdit}
            freightId={freightData?.freightId}
            tripId={freightData?.tripId}
            setReload={setReload}
            setState={setFreightData}
          />
        </div>
      </div>

      <div className="imgStyle row" style={{ margin: "10px" }}>
        <div
          className="cardInfoLRNew "
          style={{ textAlign: "center", width: "49%", alignItems: "center" }}
        >
          <h6>
            <b>LR Image</b>
          </h6>
          {lrImages !== -1 ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                // dispatch(
                // getSignedUrlApiAction({ key: lrImages }, (res) => {
                //   // setDownloadLoader2(false);
                //   // setLoaderInvoiceId("");
                //   if (res.status == 200) {
                window.open(lrImages);
                //     }
                //   })
                // );
              }}
            >
              <img src={lrImages} loading="eager" />
            </div>
          ) : (
            <div style={{ marginTop: 50 }}>Lr Image Not Uploaded</div>
          )}
        </div>
        <div
          className="cardInfoLRNew "
          style={{ textAlign: "center", width: "49%", alignItems: "center" }}
        >
          <h6>
            <b>POD Image</b>
          </h6>
          {podImages !== -1 ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                // dispatch(
                //   getSignedUrlApiAction({ key: podImages }, (res) => {
                //     // setDownloadLoader2(false);
                //     // setLoaderInvoiceId("");
                //     if (res.status == 200) {
                window.open(podImages);
                //     }
                //   })
                // );
              }}
            >
              <img src={podImages} loading="eager" />
            </div>
          ) : (
            <div style={{ marginTop: 50 }}>Pod Image Not Uploaded</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoadReceiptDetail;
