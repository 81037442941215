import {
  TRANSFER_WALLET_AMOUNT,
  TRANSFER_WALLET_AMOUNT_SUCCESS,
  ALL_CARD_BALANCE,
  ALL_CARD_BALANCE_SUCCESS,
  CARD_REQUESTS,
  CARD_REQUESTS_SUCCESS,
  UPDATE_CARD_REQUESTS,
  UPDATE_CARD_REQUESTS_SUCCESS
} from '../storeTypes';
export const transferWalletTransferAmount = (obj, callback) => {
  return {
    type: TRANSFER_WALLET_AMOUNT,
    payload: obj,
    callback,
  };
};
export const transferWalletTransferAmountSuccess = msg => {
  return {
    type: TRANSFER_WALLET_AMOUNT_SUCCESS,
    msg,
  };
};


export const getCardRequest = (obj, callback) => {
  
  return {
    type: CARD_REQUESTS,
    payload: obj,
    callback,
  };
};
export const getCardRequestSuccess = msg => {
  console.log("CARD_REQUESTSCARD_REQUESTS",msg)
  return {
    type: CARD_REQUESTS_SUCCESS,
    msg,
    payload:msg
  };
};


export const updateCardRequest = (obj, callback) => {
  return {
    type: UPDATE_CARD_REQUESTS,
    payload: obj,
    callback,
  };
};
export const updateCardRequestSuccess = msg => {
  return {
    type: UPDATE_CARD_REQUESTS_SUCCESS,
    msg,
  };
};


export function allCardsBalance(data) {

  console.log("action-> all cards", data);
  return {
    type: ALL_CARD_BALANCE,
    payload:data
  };
}

export function allCardsBalanceSuccess(data) {
  console.log("action-> userdetails-- success", data);
  return { type: ALL_CARD_BALANCE_SUCCESS, payload: data };
}

