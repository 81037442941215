import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import AddFreight from "../../../TripsMainV2/Components/AddFreight";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import {
  createTrip,
  getCityAndState,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import ToastV2 from "../../../subComponents/toastV2";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import CollectPayment from "../CollectPayment";
import AddPayment from "../AddPayment";
import CreateInvoice from "../CreateInvoice";
import AddInvoice from "../AddInvoice";

function InvoiceModal(props) {
  const { toggle, modal, setModal, customerId } = props;
  const [openToast, setOpenToast] = useState(false);
  const [collectPay, setCollectPay] = useState(false);
  const [addPayment, setAddPayment] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [addInvoice, setAddInvoice] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { customer } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    tripNo: Yup.string().required("TripNo is required"),
    source: Yup.string().required("Source is required"),

    destination: Yup.string().required("Destination is required"),

    fleetId: Yup.string().required("Vehicle is required"),

    userId: Yup.string().required("Driver is required"),

    startDate: Yup.string().required("StartDate is required"),
  });

  const handleClose = () => {
    setModal(!modal);
  };

  const createInvoice = () => {
    console.log("invoiceModal", invoiceModal);
    setIsCheck([]);
    setInvoiceModal(!invoiceModal);
  };
  const addInvoiceFunc = () => {
    console.log("addInvoice", addInvoice);
    setIsCheck([]);
    setAddInvoice(!addInvoice);
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
          // style={{height : "75vh"}}
        >
          <div>
            <div className="closeBtnWithBackground" onClick={handleClose}>
              <SvgImage
                classname="close-btn-text"
                style={{ width: "10%", height: "10%" }}
                name="crossBtnWIthBackground"
                width="20%"
                height="20%"
              />
            </div>
            <div
              style={{ marginTop: " 90px" }}
              className="row"
              //   className="PGCollectionBox"
            >
              <div className="PaymentsText col-12">Add / Create Invoice</div>
              <div className="col-12 row">
                <div
                  className="col-6"
                  style={{ padding: "75px 0 150px 25%" }}
                  onClick={addInvoiceFunc}
                >
                  <div className="squareDiv row">
                    <div
                      className="col-12"
                      style={{ textAlign: "center", paddingTop: "35px" }}
                    >
                      <SvgImage name="paymentReceived" />
                    </div>
                    <div className="col-12 addPayText">Add Invoice</div>
                  </div>
                </div>
                <div
                  className="col-6"
                  style={{ padding: "75px 0 150px 10%" }}
                  onClick={createInvoice}
                >
                  <div className="squareDiv row">
                    <div
                      className="col-12"
                      style={{ textAlign: "center", paddingTop: "35px" }}
                    >
                      <SvgImage name="collectPaymentLogo" />
                    </div>
                    <div className="col-12 addPayText">
                      Create Logipe Invoice
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <CreateInvoice
        toggle={createInvoice}
        modal={invoiceModal}
        setModal={setInvoiceModal}
        customerId={customerId}
        isCheckAll={isCheckAll}
        setIsCheckAll={setIsCheckAll}
        isCheck={isCheck}
        setIsCheck={setIsCheck}
        customer={customer}
      />
      <AddInvoice
        toggle={addInvoiceFunc}
        modal={addInvoice}
        setModal={setAddInvoice}
      />
    </div>
  );
}

export default InvoiceModal;
