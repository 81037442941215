import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import FastagDetails from "../fastagDetails/index";
import OmcVehicleDetail from "../omcVehicleDetail";
import OmcOthersDetails from "../OmcOthersDetails";
import VehicleDetails from "../vehicleDetails/index";
import FASTagTable from "../FASTagTable";
import FuelTable from "../FuelTable";
import TripsTable from "../TripsTable";
import LedgerTable from "../LedgerTable";
import VehicleAssignedHistory from "../vehicleAssignedHistory/index";
import "./index.css";
import {
  vehicleHistory,
  cleanVehicleHistory,
} from "../../../../services/Store/Actions/manageVehicleAction";
import {
  autoRechargeToggleAction,
  cleanVehicleDetails,
  getFastagWalletBalance,
  getVehicleDetails,
} from "../../../../services/Store/Actions/fastagAction";
import RechargeFastagButttonModal from "../../../Modals/rechargeFastagButton/index";
import { getVehicleDetailsByFleetId } from "../../../../services/Store/Actions/fastagAction";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import SvgImage from "../../../Icons/Icons";
import AutorechargeConfirm from "../../../Modals/AutorechargeConfirm";
import { ToastContainer, toast } from "react-toastify";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import {
  omcVehicleBalanceAction,
  omcVehicleTransactionAction,
} from "../../../../services/Store/Actions/omcActions";
import OMCChangeOTPNum from "../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCChangeOTPNum";
import OMCLoadModal from "../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCLoadModal";
import ShowroomModal from "../../../Modals/ShowRoom";
import Requests from "../../../Modals/Requests";

function CustomerVehicleDetail(props) {
  console.log("this are props", props);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state, location } = useLocation();
  const { loader } = useSelector((state) => state?.fastag);
  //const customerData = useSelector((state)=>state)
  console.log("LKKKKKKKKKKKKKKKKKKKK", props.user);
  const vehicleData = useSelector((state) => state?.manageVehicle);
  const omc_balace = useSelector((state) => state?.omc?.omc_balace);
  let jio = omc_balace?.omcVehicles?.find((e) => e?.omcType == "jio");
  let bpcl = omc_balace?.omcVehicles?.find((e) => e?.omcType == "bpcl");
  let hpcl = omc_balace?.omcVehicles?.find((e) => e?.omcType == "hpcl");

  console.log("users as jio", omc_balace, omc_balace?.omcVehicles, jio);
  //console.log("Vehicle Data>>>>",vehicleData?.manageVehicle?.vehicleReceivableHistory.fleets)
  //console.log ("value--------", assignedDriversCount);
  //const name = " "
  //let name =" "
  let { id } = useParams();
  console.log("PropellerPropellerPropeller", id, state?.vehicle);

  useEffect(() => {
    console.log("users as props", props.user);
    if (id !== undefined) {
      //dispatch(vehicleHistory(id));
      dispatch(getVehicleDetailsByFleetId(id));
      dispatch(cleanVehicleDetails());
      dispatch(getVehicleDetails(id));
      if (props.omcBal) {
        dispatch(omcVehicleBalanceAction(id));
        dispatch(
          omcVehicleTransactionAction(id, {
            offset: "",
            limit: 10,
            search: "",
            minKyc: "",
            sortAs: "",
            sortBy: "",
          })
        );
      }
    } else {
      //dispatch(cleanVehicleHistory());
    }
    // dispatch(vehicleHistory(props.user));
    dispatch(getFastagWalletBalance());
  }, [props.user]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const { fastagWallet } = useSelector((state) => state?.fastag);

  const fastagWalletAmount =
    fastagWallet.balance == null ? 0 : fastagWallet.balance;

  const vehicleDetails = useSelector((state) => state?.fastag?.vehicleDetails);
  console.log("indoooooooooooo", vehicleDetails);
  //const vehicleInfo= vehicleDetails?.vehicle?.regDate;

  //console.log("regno<><><><><><><><>><><><>><><><><><>", historyData);
  function handleClose() {
    setOpen(false);
  }
  function handleShow() {
    setOpen(true);
  }
  function toggleModal() {
    setOpen(!open);
  }
  const [rechargeOmc, setRechargeOmc] = useState(false);
  const [mappingOmc, setMappingOmc] = useState(false);
  function closeRechargeOmc() {
    setRechargeOmc(false);
  }
  function openRechargeOmc() {
    setRechargeOmc(true);
  }
  function toggleRechargeOmc() {
    setRechargeOmc(!rechargeOmc);
  }
  function closeMappingOmc() {
    setMappingOmc(false);
  }
  function openMappingOmc() {
    setMappingOmc(true);
  }
  function toggleMappingOmc() {
    setMappingOmc(!mappingOmc);
  }
  const { advancedVehicleList, advancedVehicleListCount } = useSelector(
    (state) => state?.fleet
  );
  const val = advancedVehicleList?.filter(
    (element) => element.fleetId == id
  )[0];
  console.log("valllllllllllllllllll", val, id);

  const handleToggle = () => {
    if (!loader) {
      dispatch(
        autoRechargeToggleAction({
          fleetId: [id],
        })
      );
    }
  };
  const [openRechargeOne, setOpenRechargeOne] = useState(false);

  const showRechargeOne = () => {
    setOpenRechargeOne(true);
  };

  const toggleRechargeOne = () => {
    setOpenRechargeOne(!openRechargeOne);
  };

  const closeRechargeOne = () => {
    setOpenRechargeOne(false);
  };
  const autoRechargeState = fastagWallet?.autoRecharge;
  const fastagAutorechargeAmount = fastagWallet?.autoRechargeAmount;

  const customId = "custom-id-yes-main page";
  const notify = () =>
    toast.error(
      "You have disabled FASTag auto-recharge for all vehicles, kindly enable it!",
      {
        toastId: customId,
      }
    );

  const vehRegNo =
    !id || !state?.vehicle?.fleetId
      ? "Select Vehicle"
      : state?.vehicle?.regNumber;
  const tagId = state?.vehicle?.tagId;

  const [showroomModal, setShowroomModal] = useState(false);
  const [showRequest,setshowRequest] = useState(false);
  const showroomModalFunc = () => {
    console.log("invoiceModal", showroomModal);
    setShowroomModal(!showroomModal);
  };

  const showRequests = () => {
    console.log("invoiceModal", showroomModal);
    setshowRequest(!showRequest);
  };
  const navigateFunc = () => {
    console.log("idissss",id)
    console.log('fleedId...',id);
    navigate("/VehicleHistory",{state:{
      path: 'fleet',
      id: id,
    }});
  };
  
  return (
    <>
      {!props.user || props.user == "" ? (
        <div class="whiteBackground">
          <SvgImage name="vehicleHomePage" />
        </div>
      ) : (
        <div
          className="main-customer-vehicle-detail"
          style={{
            opacity: vehRegNo === "Select Vehicle" ? "0.5" : "1",
            padding: "12px 0 0 25px",
          }}
        >
          <div>
            <div className="row" >
            <div
              className="fontNB24 col-md-4"
              style={{ textTransform: "uppercase", padding: "5px" }}
            >
              {vehRegNo}
              <span>
                {state?.vehicle?.driverName ? (
                  <text> ({state?.vehicle?.driverName} )</text>
                ) : (
                  ""
                )}
              </span>
            </div>
            <div className="col-md-8">
              <div  className="row" >
                {(
                    <div
                      className="ManageDriverCardType1Box"
                      style={{ width: "170px",marginLeft:"5px",marginRight:"5px" }}
                      onClick={showRequests}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <div className="row" style={{alignItems:"center"}}>
                        <div
                          className="col-5"
                          style={{  }}
                        >
                          <div
                            className="outside-Truck-logo-for-total-vehicle"
                            style={{
                              textAlign: "center",
                              paddingTop: "3px",
                              marginLeft: "3px",
                              marginTop: "7px"
                            }}
                          >
                            <SvgImage
                              name="cash1"
                              classname="Truck-logo-for-total-vehicle"
                            />
                          </div>
                        </div>
                        <div
                          className="showroom-for-vehicle-text col-6"
                          style={{  }}
                        >
                          {"Requests"}
                        </div>
                      </div>
                    </div>
                  ) }
                  {vehRegNo ? (
                    <div
                      className="ManageDriverCardType1Box"
                      style={{ width: "170px" }}
                      onClick={showroomModalFunc}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <div className="row center" style={{alignItems:"center"}}>
                        <div
                          className="col-5"
                          style={{  }}
                        >
                          <div
                            className="outside-Truck-logo-for-total-vehicle"
                            style={{
                              textAlign: "center",
                              // paddingTop: "3px",
                            }}
                          >
                            <SvgImage
                              name="gaurage"
                              classname="Truck-logo-for-total-vehicle"
                            />
                          </div>
                        </div>
                        <div
                          className="showroom-for-vehicle-text col-6"
                          style={{  }}
                        >
                          {"Showroom"}
                        </div>
                      </div>
                    </div>
                  ) : null}
                   {vehRegNo ? (
                    <div
                      className="ManageDriverCardType1Box"
                      style={{ width: "170px",marginLeft:"10px"}}
                      onClick={navigateFunc}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <div className="row center" style={{alignItems:"center"}}>
                        <div
                          className="col-5"
                          style={{  }}
                        >
                          <div
                            className="outside-Truck-logo-for-total-vehicle"
                            style={{
                              textAlign: "center",
                              // paddingTop: "3px",
                              marginLeft: "3px",
                              // marginTop: "7px"
                            }}
                          >
                            <SvgImage
                              name="newCalendar"
                              classname="Truck-logo-for-total-vehicle"
                            />
                          </div>
                        </div>
                        <div
                          className="showroom-for-vehicle-text col-6"
                          style={{ }}
                        >
                          {"History"}
                        </div>
                      </div>
                    </div>
                  ) : null}
              </div>
            </div>
            
            </div>
            
            <div className="row" style={{ margin: "15px 0 25px 0" }}>
              <div className="col-md-4" style={{ padding: "5px" }}>
                <div className="cardInfo3">
                  <div
                    className="flexStyling"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="driverHeader" style={{ color: "#4D5073" }}>
                      FASTag Balance
                    </div>
                    <div style={{ fontSize: 12 }}>
                      Details
                      <SvgImage name="rightArrow" />
                    </div>
                  </div>
                  <div
                    className="flexStyling"
                    style={{ marginLeft: "10px", fontSize: "20px" }}
                  >
                    {tagId ? (
                      <div className="col-lg-12 BoldText">
                        {" "}
                        ₹ {vehicleDetails?.Response?.availableBal}
                      </div>
                    ) : (
                      <div className="col-lg-12" style={{ color: "#E5723180" }}>
                        NO FASTAG
                      </div>
                    )}
                    {/* <div
                      className="col-lg-7"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {" "}
                      {val?.autoRecharge && autoRechargeState ? (
                        <div>
                          Limit: ₹ {vehicleDetails?.vehicle?.autoRechargeAmount}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div> */}
                  </div>

                  <div className="flexStyling" style={{}}>
                    <div className="col-lg-5">
                      <button
                        className={"cardButton2"}
                        onClick={handleShow}
                        disabled={tagId == null}
                      >
                        Recharge
                      </button>
                    </div>
                    <div
                      className="flexStyling col-lg-7"
                      style={{
                        justifyContent: "space-evenly",
                        margin: "10px 0 0 10px",
                      }}
                    >
                      <div>Auto Recharge</div>
                      <div>
                        <Switch
                          checked={val?.autoRecharge && autoRechargeState}
                          disabled={tagId == null}
                          onChange={(e) => {
                            dispatch(
                              sendAnalitics(
                                "Toggle_OneVeh_ChangeLimit_AR_MV_web"
                              )
                            );
                            if (
                              val?.autoRecharge != undefined &&
                              autoRechargeState
                            )
                              handleToggle();
                            else if (!autoRechargeState) notify();
                          }}
                          onColor="#EBF2F2"
                          onHandleColor="#2E7C7B"
                          handleDiameter={10}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          height={10}
                          width={25}
                          id="material-switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4" style={{ padding: "5px" }}>
                <div className="cardInfo3">
                  <div
                    className="flexStyling"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="driverHeader" style={{ color: "#4D5073" }}>
                      Fuel Balance{" "}
                    </div>
                    <div style={{ fontSize: 12 }}>
                      Details
                      <SvgImage name="rightArrow" />
                    </div>
                  </div>
                  <div
                    className="flexStyling"
                    style={{
                      marginLeft: "15px",
                      justifyContent: "space-between",
                    }}
                  >
                    {state?.vehicle?.omcBalance ? (
                      <div className=" BoldText">
                        {" "}
                        ₹ {state?.vehicle?.omcBalance}
                      </div>
                    ) : (
                      <div className="col-lg-7" style={{ color: "#E5723180" }}>
                        NOT ACTIVATED
                      </div>
                    )}
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {" "}
                      {state?.vehicle?.omcBalance ? (
                        <div>| OTP sent on {state?.vehicle?.mappedPhone}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div
                    className="flexStyling"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <button
                        className={"cardButton2"}
                        onClick={openRechargeOmc}
                        disabled={state?.vehicle?.omcBalance == null}
                      >
                        Recharge
                      </button>
                    </div>

                    <div>
                      <button
                        className={"cardButton2"}
                        onClick={openMappingOmc}
                        disabled={state?.vehicle?.omcBalance == null}
                      >
                        Change Phone
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4" style={{ padding: "5px" }}>
                <div
                  className="cardInfo3"
                  // style={{
                  //   margin: "20px 0px 30px 10px",
                  //   width: props.sideBar ? "280px" : "315px",
                  // }}
                >
                  <div className="row">
                    <div
                      className="vehicleHeaderV2 col-8"
                      style={{ paddingLeft: "15px", color: "#4D5073" }}
                    >
                      Performance{" "}
                      {props.sideBar ? (
                        <></>
                      ) : (
                        <span style={{ fontSize: 11, padding: "5px" }}>
                          (this month)
                        </span>
                      )}
                    </div>
                    <div className="col-4" style={{ fontSize: 12 }}>
                      Details
                      <SvgImage name="rightArrow" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">Total Trips</div>
                    <div className="col-4">14</div>
                  </div>
                  <div className="row">
                    <div className="col-8">Payout Done</div>
                    <div className="col-4">34500</div>
                  </div>
                  <div className="row">
                    <div className="col-8">Kms Travelled</div>
                    <div className="col-4">100</div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 cardInfo" style={{margin: "20px 0px 30px 0px"}} >
                    <div className="flexStyling" style={{justifyContent:"space-between"}}>
                        <div className="driverHeader" style={{color:"#4D5073"}}>FASTag Balance</div>
                        <div style={{fontSize: 12}}>Details<SvgImage name="rightArrow" /></div>
                    </div>
                    <div className="flexStyling" style={{marginLeft:"10px",fontSize:"20px"}}>
                      {tagId ? <div className="col-lg-5 BoldText"> ₹ {vehicleDetails?.Response?.availableBal}</div>: <div className="col-lg-5" style={{color: "#E5723180"}}>NO FASTAG</div>}
                      <div className="col-lg-7" style={{display:"flex", justifyContent:"center"}}> {val?.autoRecharge && autoRechargeState ? <div>Limit:  ₹  {vehicleDetails?.vehicle?.autoRechargeAmount}</div> : <></>}</div>
                    </div>
                    
                    <div  className="flexStyling" style={{}}>
                        <div className="col-lg-5">
                          <button className={"cardButton2"} onClick={handleShow} disabled={tagId == null} >
                          Recharge
                          </button>
                        </div>
                        <div  className="flexStyling col-lg-7" style={{justifyContent:"space-evenly", margin:"10px 0 0 10px"}}>
                            <div>Auto Recharge</div>
                            <div>
                              <Switch
                                checked={val?.autoRecharge && autoRechargeState}
                                disabled={tagId == null}
                                onChange={(e) => {
                                  dispatch(
                                    sendAnalitics("Toggle_OneVeh_ChangeLimit_AR_MV_web")
                                  );
                                  if (val?.autoRecharge != undefined && autoRechargeState)
                                    handleToggle();
                                  else if (!autoRechargeState) notify();
                                }}
                                onColor="#EBF2F2"
                                onHandleColor="#2E7C7B"
                                handleDiameter={10}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                height={10}
                                width={25}
                                id="material-switch"
                              />
                            </div>
                           
                        </div>
                    </div>
                </div> */}
              {/* <div
                className="col-lg-4 cardInfo"
                style={{ margin: "20px 0px 30px 10px" }}
              >
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="driverHeader" style={{ color: "#4D5073" }}>
                    Fuel Balance{" "}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    Details
                    <SvgImage name="rightArrow" />
                  </div>
                </div>
                <div
                  className="flexStyling"
                  style={{
                    marginLeft: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  {state?.vehicle?.omcBalance ? (
                    <div className=" BoldText">
                      {" "}
                      ₹ {state?.vehicle?.omcBalance}
                    </div>
                  ) : (
                    <div className="col-lg-7" style={{ color: "#E5723180" }}>
                      NOT ACTIVATED
                    </div>
                  )}
                  <div
                    className=""
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {" "}
                    {state?.vehicle?.omcBalance ? (
                      <div>| OTP sent on {state?.vehicle?.mappedPhone}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>
                    <button
                      className={"cardButton2"}
                      onClick={openRechargeOmc}
                      disabled={state?.vehicle?.omcBalance == null}
                    >
                      Recharge
                    </button>
                  </div>

                  <div>
                    <button
                      className={"cardButton2"}
                      onClick={openMappingOmc}
                      disabled={state?.vehicle?.omcBalance == null}
                    >
                      Change Phone
                    </button>
                  </div>
                </div>
              </div> */}
              {/* <div
                className="col-lg-4 cardInfo"
                style={{
                  margin: "20px 0px 30px 10px",
                  width: props.sideBar ? "280px" : "315px",
                }}
              >
                <div className="row">
                  <div
                    className="vehicleHeaderV2 col-8"
                    style={{ paddingLeft: "15px", color: "#4D5073" }}
                  >
                    Performance{" "}
                    {props.sideBar ? (
                      <></>
                    ) : (
                      <span style={{ fontSize: 12, padding: "5px" }}>
                        (this month)
                      </span>
                    )}
                  </div>
                  <div className="col-4" style={{ fontSize: 12 }}>
                    Details
                    <SvgImage name="rightArrow" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">Total Trips</div>
                  <div className="col-4">14</div>
                </div>
                <div className="row">
                  <div className="col-8">Payout Done</div>
                  <div className="col-4">34500</div>
                </div>
                <div className="row">
                  <div className="col-8">Kms Travelled</div>
                  <div className="col-4">100</div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="flexStyling">
            <button
              className={selected == 0 ? "ButtonNew" : "ButtonNewInactive"}
              onClick={() => setSelected(0)}
            >
              FASTag
            </button>
            <button
              className={selected == 1 ? "ButtonNew" : "ButtonNewInactive"}
              onClick={() => setSelected(1)}
            >
              Fuel
            </button>
            <button
              className={selected == 2 ? "ButtonNew" : "ButtonNewInactive"}
              onClick={() => setSelected(2)}
            >
              Trips
            </button>
            <button
              className={selected == 3 ? "ButtonNew" : "ButtonNewInactive"}
              onClick={() => setSelected(3)}
            >
              Ledger
            </button>
          </div>
          <div className="boxTxn1" style={{ marginRight: "15px" }}>
            {selected === 0 ? (
              <FASTagTable selected={selected} userId={id} />
            ) : selected === 1 ? (
              <FuelTable selected={selected} userId={id} />
            ) : selected === 2 ? (
              <TripsTable selected={selected} userId={id} />
            ) : selected === 3 ? (
              <LedgerTable
                selected={selected}
                fleetId={props.user}
                vehRegNo={vehRegNo}
              />
            ) : (
              <></>
            )}
          </div>
          <RechargeFastagButttonModal
            open={open}
            handleClose={handleClose}
            toggleModal={toggleModal}
            balance={fastagWalletAmount}
            fastagBal={props.bal}
            no={props.regNo}
            fleetId={props.user}
          />
          <AutorechargeConfirm
            open={openRechargeOne}
            onHide={closeRechargeOne}
            toggleModal={toggleRechargeOne}
            vehNo={state?.vehicle?.regNumber}
            limit={val?.autoRechargeAmount}
            vehRank={props.vehRank}
            fleetId={id}
          />
          <OMCChangeOTPNum
            open={mappingOmc}
            toggleModal={toggleMappingOmc}
            handleClose={closeMappingOmc}
          />
          <OMCLoadModal
            open={rechargeOmc}
            toggleModal={toggleRechargeOmc}
            handleClose={closeRechargeOmc}
          />
          <ToastContainer
            theme="dark"
            position="bottom-right"
            closeOnClick
            pauseOnHover
            autoClose={4000}
          />
           <ShowroomModal
          toggle={showroomModalFunc}
          modal={showroomModal}
          setModal={setShowroomModal}
          regNo={props.regNo}
          fleetId={id}
          // customerId={customerId}
        />

      <Requests
          toggle={showRequests}
          modal={showRequest}
          setModal={setshowRequest}
          regNo={props.regNo}
          fleetId={id}
          // customerId={customerId}
        />
        
        </div>
      )}
    </>
  );
}
export default CustomerVehicleDetail;
