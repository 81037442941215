import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import { Button } from "reactstrap";
import SvgImage from "../../../../../../Icons/Icons";


const CardLinkNot=(props)=>{

    return(<div>
        <div className="centralEle " style={{padding:"4.5rem 0 4.5rem"}}>
            <div className="padding32T">
                <SvgImage name="kycLockLogo" />
            </div>
            <div className="Medium heading3 padding16T greyFont">KYC Complete but no </div>
            <div className="Medium heading3 greyFont padding32B">card Linked to this driver.</div>
        </div>
    </div>)
}

export default CardLinkNot;
