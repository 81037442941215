import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  LOGIPE_ACCOUNT,
  LOGIPE_ACCOUNT_SUCCESS,
  GET_CUSTOMERS_BY_ID,
  GET_CUSTOMERS_BY_ID_SUCCESS,
  GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID,
  GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID_SUCCESS,
  ADD_NEW_BOOKING_BILL,
  ADD_NEW_BOOKING_BILL_SUCCESS,
  BILL_DETAILS,
  BILL_DETAILS_SUCCESS,
  CREATE_PAYMENT_LINK,
  CREATE_PAYMENT_LINK_SUCCESS,
  UPDATE_BILL_DETAILS,
  UPDATE_BILL_DETAILS_SUCCESS,
  CLEAN_CUSTOMERS,
  CLEAR_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID,
  VIRTUAL_ACCOUNT_CREDIT,
  VIRTUAL_ACCOUNT_CREDIT_SUCCESS,
  CLEAN_CUSTOMER_LOADER,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_TOKEN,
  CREATE_PAYMENT_TOKEN_SUCCESS,
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  logipeAccount: {},
  billDetails: {},
  allCustomers: [],
  allCustomersList: [],
  customerById: [],
  customerRecievables: {},
  customerRecievablesList: [],
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_CUSTOMER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_CUSTOMER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case GET_CUSTOMERS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        loader: false,
        allCustomers: action?.payload,
        allCustomersList: [...state.allCustomersList, ...action?.payload?.rows],
      };
    }
    case CLEAN_CUSTOMERS: {
      return {
        ...state,
        loader: false,
        allCustomers: [],
        allCustomersList: [],
      };
    }

    case LOGIPE_ACCOUNT: {
      return {
        ...state,
        loader: true,
      };
    }
    case LOGIPE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loader: false,
        logipeAccount: action.payload,
      };
    }

    case GET_CUSTOMERS_BY_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_CUSTOMERS_BY_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
        customerById: action.payload,
      };
    }

    case GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
        customerRecievables: action.payload,
        customerRecievablesList: [
          ...state.customerRecievablesList,
          ...action?.payload?.rows,
        ],
      };
    }
    case CLEAR_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID: {
      return {
        ...state,
        loader: false,
        customerRecievables: {},
        customerRecievablesList: [],
        paymentLink: {},
      };
    }

    case ADD_NEW_BOOKING_BILL: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_NEW_BOOKING_BILL_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case BILL_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case BILL_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        billDetails: action.payload,
      };
    }

    case CREATE_PAYMENT_LINK: {
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_PAYMENT_LINK_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case VIRTUAL_ACCOUNT_CREDIT: {
      return {
        ...state,
        loader: true,
      };
    }
    case VIRTUAL_ACCOUNT_CREDIT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case UPDATE_BILL_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_BILL_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case CLEAN_CUSTOMER_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case UPDATE_PAYMENT: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case CREATE_PAYMENT_TOKEN: {
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_PAYMENT_TOKEN_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    default: {
      return state;
    }
  }
};
