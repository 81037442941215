import { call, put, takeEvery } from "redux-saga/effects";
import {
  addFleetApi,
  getMasterAccountReportApi,
  getFastagVehicleReportApi,
  updateFleetApi,
  advancedVehicleListApi,
} from "../../Utils/Apis";
import {
  addFleetSuccess,
  getMasterAccountStatementSuccess,
  getFastagVehicleHistoryStatementSuccess,
  updateFleetSuccess,
  advancedVehicleListSuccess,
} from "../Actions/fleetActions";
import {
  ADD_FLEET,
  GENERATE_MASTER_ACCOUNT_STATEMENT,
  GENERATE_FASTAG_VEHICLE_STATEMENT,
  UPDATE_FLEET,
  ADVANCED_VEHICLE_LIST,
} from "../storeTypes";
import { processErrorAndRespond } from "../../Utils/functions";
import {
  toastStatus,
  toastStatusSuccess,
} from "../../../services/Store/Actions/appActions";

function* addFleetsaga(action) {
  try {
    const response = yield call(addFleetApi, action.payload);
    console.log("addFleetsaga", response?.data);
    if (response?.data?.type == "success") {
      action.callBack(response?.data);
      console.log("step-1");
      yield put(addFleetSuccess(response.data?.data));
      console.log("step-2");
      yield put(
        toastStatusSuccess({
          toastStatus: true,
          message: "New vehicle was added successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    // if (error.response?.status == 401) {
    //    yield put(
    //   toastStatus({
    //   toastStatus: true,
    //   message: `Error : ${error?.response?.data?.message}`,
    //   status: false,
    // }));
    //   action.callBack(error.response?.data?.message);
    // } else if (error?.response?.status == 400) {
    //   action.callBack({
    //     message: error?.response?.data.message,
    //   });
    // } else {
    //   action.callBack(error?.response?.data?.error);
    //   alert(`error :${error?.response?.data?.message} `);

    // }
    //console.log(typeof error.response?.status);
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.SHORT);
    console.log("Error.Response addFleetsaga:- ", error.response?.data);
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* getMasterAccountReportSaga(action) {
  try {
    const response = yield call(getMasterAccountReportApi, action.payload);
    console.log("saga-> fleet-- ", response);
    if (response.data.type == "success") {
      action.callback(response);
      console.log('DATA123',response?.data)
      let status = response?.data?.data? true: false
      yield put(getMasterAccountStatementSuccess(response?.data?.data));

      yield put(
        toastStatus({
          toastStatus: true,
          message: response?.data?.message,
          status: status,
        }))
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getFastagVehicleReportSaga(action) {
  try {
    const response = yield call(
      getFastagVehicleReportApi,
      action.userId,
      action.payload
    );
    console.log("saga-> fleet-- ", response?.data);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(getFastagVehicleHistoryStatementSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* updateFleetSaga(action) {
  try {
    const response = yield call(
      updateFleetApi,
      action.payload
    );
    console.log("updateFleetSaga response",response);
    if (response.data.type == "success") {

      yield put(updateFleetSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* advancedVehicleListSaga(action){
  try{
    const response = yield call(advancedVehicleListApi,action?.payload)
    console.log('ADVANCEDVEHICLELIST',response?.data)
    if(response?.data?.type == 'success'){
      yield put(advancedVehicleListSuccess(response?.data?.data))
    }
  }
  catch(error){
    console.log('ERRORADVAPI',error)

  }
}
export function* watchFleetSagas() {
  yield takeEvery(ADD_FLEET, addFleetsaga);
  yield takeEvery(
    GENERATE_MASTER_ACCOUNT_STATEMENT,
    getMasterAccountReportSaga
  );
  yield takeEvery(
    GENERATE_FASTAG_VEHICLE_STATEMENT,
    getFastagVehicleReportSaga
  );
  yield takeEvery(UPDATE_FLEET, updateFleetSaga);
  yield takeEvery(ADVANCED_VEHICLE_LIST,advancedVehicleListSaga)
}