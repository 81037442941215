import React, { useState } from "react";
import SvgImage from "../../../Components/Icons/Icons";
import "../index.css";



const OnboardingBar=(props)=>{

    const {regStatus}= props;

    return(
    <div className="row semiBold heading3 margin16L padding16T">
        <div className="col-2 padding0">
            <div className="statusElement"> 
            <div>User Verification</div>
            <div><SvgImage name="nextArrow" /></div>
            </div>
            <div className={regStatus==0?"statusBar":"statusBarLeft"} ></div>
        </div>
        <div className=" col-2 padding0" style={{opacity:regStatus<1?"0.2":"1"}}>
            <div className="statusElement"> 
            <div>Owner Details</div>
            <div><SvgImage name="nextArrow" /></div>
            </div>
            <div className={regStatus==1?"statusBarRight":"statusBar1"} ></div>
        </div>
        <div className=" col-2 padding0" style={{opacity:regStatus<2?"0.2":"1"}}>
            <div className="statusElement"> 
            <div>Business details</div>
            <div><SvgImage name="nextArrow" /></div>
            </div>
            <div className={regStatus==2?"statusBarRight":"statusBar1"}></div>
        </div>
        <div className=" col-3 padding0"  style={{opacity:regStatus<3?"0.2":"1"}} >
            <div className="statusElement" style={{paddingLeft:"1.5rem"}}> 
            <div>Business Documents</div>
            <div><SvgImage name="nextArrow" /></div>
            </div>
            <div className={regStatus==3?"statusBarRight":"statusBar1"}></div>
        </div>
        <div className=" col-1 padding0" style={{opacity:regStatus<4?"0.2":"1"}}>
            <div className="statusElement"> 
            <div>KYC</div>
            <div><SvgImage name="nextArrow" /></div>
            </div>
            <div className={regStatus==4?"statusBarRight":"statusBar1"}></div>
        </div>
        <div className=" col-2 padding0" style={{opacity:regStatus<5?"0.2":"1"}}>
            <div className="statusElement"  style={{paddingLeft:"1.5rem"}}> 
            <div>Set Pin</div>
            <div><SvgImage name="nextArrow" /></div>
            </div>
            <div className="statusBarRight"></div>
        </div>
    </div>
    )
}


export default OnboardingBar;