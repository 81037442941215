import React from "react";
import './index.css';
import SvgImage from '../../../Icons/Icons';
import { Modal, ModalBody } from "reactstrap";
import { useDispatch } from "react-redux";

const ActionModal = (props) => {
    const { success, message, isOpen, onHide, toggleModal, handleClose, name, onPress,method } = props;
    const dispatch = useDispatch()
    
    console.log("mesaage-------------", message);
    return (
        <Modal
            isOpen={isOpen}
            onHide={onHide}
            className=" toastDiv settingModalMargin"
            toggle={toggleModal}
            aria-labelledby="myModalLabel2"
            style={{ width: "33rem", borderRadius: "10px", backgroundColor: 'white', alignSelf: 'center' }}
        >
            <div className="modal-content" style={{ width: '15rem', backgroundColor: 'white', }}>
                <text className="FontBold" style={{
                    paddingTop: '1.5rem', lineHeight: '32px',
                    fontSize: '24px', fontFamily: 'nunito-regular', color: '#2E7C7B', paddingBottom: '1.5rem', alignSelf: 'center'
                }}>
                    Important !
                </text>
                <div style={{ width: '100%', height: '1px', border: '.5px solid #6C6C6C33' }}></div>
                <div className="FontMedium" style={{
                    alignItems: 'center', marginTop: '2rem', marginBottom: '2rem',
                    fontSize: '20px', lineHeight: '26px', alignSelf: 'center'
                }}>
                    {`Are you sure you want to ${method} the trip?`}
                </div>
                <div
                onClick={onPress} 
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <div 
                    
                    style={{
                        padding: '4rem', backgroundColor: '#2E7C7B', color: 'white',
                        paddingTop: '.5rem', paddingBottom: '.5rem', borderRadius: '10px',
                        cursor:'pointer'
                    }}>Yes</div>
                    <div 
                    onClick={handleClose}
                    style={{
                        padding: '4rem', backgroundColor: 'white', color: '#2E7C7B',
                        paddingTop: '.5rem', paddingBottom: '.5rem', borderRadius: '10px', border: '.5px solid #2E7C7B',
                        cursor:'pointer'
                    }}
                    >No</div>
                </div>
            </div>
        </Modal>
    )
}
export default ActionModal