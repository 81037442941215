import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FastagDetails from "../fastagDetails/index";
import OmcVehicleDetail from "../omcVehicleDetail";
import OmcOthersDetails from "../OmcOthersDetails";
import VehicleDetails from "../vehicleDetails/index";
import VehicleAssignedHistory from "../vehicleAssignedHistory/index";
import "./index.css";
import {
  vehicleHistory,
  cleanVehicleHistory,
} from "../../../../services/Store/Actions/manageVehicleAction";
import {
  autoRechargeToggleAction,
  cleanVehicleDetails,
  getFastagWalletBalance,
  getVehicleDetails,
} from "../../../../services/Store/Actions/fastagAction";
import RechargeFastagButttonModal from "../../../Modals/rechargeFastagButton/index";
import { getVehicleDetailsByFleetId } from "../../../../services/Store/Actions/fastagAction";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import SvgImage from "../../../Icons/Icons";
import AutorechargeConfirm from "../../../Modals/AutorechargeConfirm";
import { ToastContainer, toast } from "react-toastify";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import {
  omcVehicleBalanceAction,
  omcVehicleTransactionAction,
} from "../../../../services/Store/Actions/omcActions";

function CustomerVehicleDetail(props) {
  console.log("this are props", props);
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state?.fastag);
  //const customerData = useSelector((state)=>state)
  //console.log("LKKKKKKKKKKKKKKKKKKKK",props.user)
  const vehicleData = useSelector((state) => state?.manageVehicle);
  const omc_balace = useSelector((state) => state?.omc?.omc_balace);
  let jio = omc_balace?.omcVehicles?.find((e) => e?.omcType == "jio");
  let bpcl = omc_balace?.omcVehicles?.find((e) => e?.omcType == "bpcl");
  let hpcl = omc_balace?.omcVehicles?.find((e) => e?.omcType == "hpcl");

  console.log("users as jio", omc_balace, omc_balace?.omcVehicles, jio);
  //console.log("Vehicle Data>>>>",vehicleData?.manageVehicle?.vehicleReceivableHistory.fleets)
  //console.log ("value--------", assignedDriversCount);
  //const name = " "
  //let name =" "
  //console.log("Propeller", props.user);
  let { id } = useParams();
  useEffect(() => {
    console.log("users as props", props.user);
    if (id !== undefined) {
      //dispatch(vehicleHistory(id));
      dispatch(getVehicleDetailsByFleetId(id));
      dispatch(cleanVehicleDetails());
      dispatch(getVehicleDetails(id));
      if (props.omcBal) {
        dispatch(omcVehicleBalanceAction(id));
        dispatch(
          omcVehicleTransactionAction(id, {
            offset: "",
            limit: 10,
            search: "",
            minKyc: "",
            sortAs: "",
            sortBy: "",
          })
        );
      }
    } else {
      //dispatch(cleanVehicleHistory());
    }
    // dispatch(vehicleHistory(props.user));
    dispatch(getFastagWalletBalance());
  }, [props.user]);
  const [open, setOpen] = useState(false);

  const { fastagWallet } = useSelector((state) => state?.fastag);

  const fastagWalletAmount =
    fastagWallet.balance == null ? 0 : fastagWallet.balance;

  const vehicleDetails = useSelector((state) => state?.fastag?.vehicleDetails);
  console.log("indoooooooooooo", vehicleDetails);
  //const vehicleInfo= vehicleDetails?.vehicle?.regDate;

  //console.log("regno<><><><><><><><>><><><>><><><><><>", historyData);
  function handleClose() {
    setOpen(false);
  }
  function handleShow() {
    setOpen(true);
  }
  function toggleModal() {
    setOpen(!open);
  }
  const regNumber = vehicleData?.vehicleReceivableHistory?.fleet?.regNumber;
  const { type, receiver } = props;
  const vehRegNo =
    regNumber === null || regNumber == "" || regNumber == undefined
      ? "Select Vehicle"
      : regNumber.length > 10
      ? regNumber?.substring(0, 7) + ".."
      : regNumber;

  useEffect(() => {
    if (vehRegNo === "Select Vehicle") dispatch(cleanVehicleDetails());
  }, [vehRegNo]);

  const isCorporate = useSelector(
    (state) => state?.fastag?.fastagWallet?.isCorporate
  );
  console.log("vehRegNo", isCorporate);

  const allFleets = useSelector((state) => state?.manageVehicle?.allFleets);
  const { advancedVehicleList, advancedVehicleListCount } = useSelector(
    (state) => state?.fleet
  );
  const val = advancedVehicleList?.filter(
    (element) => element.fleetId == id
  )[0];
  //console.log("valllllllllllllllllll", val, id);
  const handleToggle = () => {
    if (!loader) {
      dispatch(
        autoRechargeToggleAction({
          fleetId: [id],
        })
      );
    }
  };

  // handle individual vehicle fastag auto recharge limit
  const [openRechargeOne, setOpenRechargeOne] = useState(false);

  const showRechargeOne = () => {
    setOpenRechargeOne(true);
  };

  const toggleRechargeOne = () => {
    setOpenRechargeOne(!openRechargeOne);
  };

  const closeRechargeOne = () => {
    setOpenRechargeOne(false);
  };

  console.log(
    "ooopppppooooo",
    vehRegNo,
    vehRegNo == "Select Vehicle",
    props.user
  );
  const autoRechargeState = fastagWallet?.autoRecharge;
  // wallet auto recharge value
  const fastagAutorechargeAmount = fastagWallet?.autoRechargeAmount;

  //toast for auto - recharge
  const customId = "custom-id-yes-main page";
  const notify = () =>
    toast.error(
      "You have disabled FASTag auto-recharge for all vehicles, kindly enable it!",
      {
        toastId: customId,
      }
    );

  return (
    <>
      {!props.user || props.user == "" ? (
        <div class="whiteBackground">
          <SvgImage name="vehicleHomePage" />
        </div>
      ) : (
        <div
          className="main-customer-vehicle-detail"
          style={{ opacity: vehRegNo === "Select Vehicle" ? "0.5" : "1" }}
        >
          <div className="ManageCustomerDashboardContactCard">
            <div className="contentMiddle flexRow flex-wrap">
              <div className="main-vehcile-No ">
                <div className="vehicle-no FontSuperBold">
                  {vehRegNo == "" || vehRegNo == null
                    ? "Select Vehicle"
                    : vehRegNo}
                </div>
              </div>
              <div className="Bar"></div>
              <div className="number FontNB">
                FASTag ID :{" "}
                {props?.tagId === "" || props?.tagId == null
                  ? "N/A"
                  : props?.tagId}
              </div>
              {props.sub ? (
                <>
                  <div className="Bar"></div>
                  <div style={{ paddingLeft: "8px" }}>
                    <SvgImage name="LogiPro" />
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="flex1"></div>
              {props?.tagId === "" || props?.tagId == null ? (
                <></>
              ) : isCorporate === 0 ? (
                <div className="" style={{paddingRight:"7px"}}>
                  <button
                    className=" recharge-fastagId-button"
                    onClick={handleShow}
                  >
                    Recharge Fastag
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="padding16 row">
            <div className="col-lg-6 main-container-fastag">
              <FastagDetails
                bal={props.bal}
                tagId={props?.tagId}
                fleetId={id}
                regNumber={regNumber}
                vehRegNo={vehRegNo}
              />
            </div>
            <div className="col-lg-6 main-container-vehicle-details">
              <VehicleDetails
                fleetId={id}
                vehicleNumber={regNumber}
                linkedFastag={props.tagId}
                assignedDrive={props.name}
                registrationDate="01-12-2009"
                rcPhoto=""
                fastagType={props.fastagType}
              />
              <div
                className="auto-recharge padding16 "
                style={{
                  marginTop: "1rem",
                  opacity: val?.autoRecharge == undefined ? "0.5" : "1",
                }}
              >
                <div className="row padding32B">
                  <div
                    className="col-6 semiBold heading2"
                    style={{ display: "flex", direction: "row" }}
                  >
                    <div style={{ paddingRight: "8px" }}>
                      <SvgImage name="rechargeIcon2" />
                    </div>
                    Auto Recharge
                  </div>
                  <div
                    className={
                      val?.autoRecharge
                        ? "col-6 row fontCardControl btnActive"
                        : "col-6 row fontCardControl btnInactive"
                    }
                    style={{
                      color: "#485B7C",
                      alignItems: "right",
                      // paddingLeft: "20px",
                      // marginTop: "10px",
                    }}
                  >
                    <div className="col-8"></div>
                    <Switch
                      checked={val?.autoRecharge && autoRechargeState}
                      onChange={(e) => {
                        dispatch(
                          sendAnalitics("Toggle_OneVeh_ChangeLimit_AR_MV_web")
                        );
                        if (val?.autoRecharge != undefined && autoRechargeState)
                          handleToggle();
                        else if (!autoRechargeState) notify();
                      }}
                      onColor="#EBF2F2"
                      onHandleColor="#485B7C"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      height={15}
                      width={40}
                      className="react-switch col-4"
                      id="material-switch"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 padding0R">Limit :</div>
                  <div className="col-8 row padding0L">
                    <div
                      className="col-8"
                      style={{
                        display: "flex",
                        direction: "row",
                        color: "#485B7C",
                      }}
                    >
                      <div
                        style={{
                          paddingRight: "8px",
                          marginTop: "-2px",
                          cursor:
                            val?.autoRechargeAmount !== undefined
                              ? "pointer"
                              : "default",
                        }}
                        onClick={() => {
                          dispatch(
                            sendAnalitics("OneVeh_ChangeLimit_AR_MV_web")
                          );
                          if (val?.autoRechargeAmount !== undefined)
                            showRechargeOne();
                        }}
                      >
                        <SvgImage name="penNew" />
                      </div>
                      {val?.autoRechargeAmount == undefined
                        ? "N/A"
                        : "₹ " +
                          (val?.autoRechargeAmount == "500"
                            ? fastagAutorechargeAmount
                            : val?.Fastag?.autoRechargeAmount)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.omcBal ? (
            <div className="padding16 row">
              <div className="col-lg-6 main-container-fastag">
                <OmcVehicleDetail
                  bal={props.omcBal}
                  tagId={props?.tagId}
                  fleetId={id}
                  regNumber={regNumber}
                />
              </div>
              <div className="col-lg-6 main-container-vehicle-details">
                <OmcOthersDetails
                  fleetId={id}
                  vehicleNumber={regNumber}
                  linkedFastag={props.tagId}
                  assignedDrive={props.name}
                  registrationDate="01-12-2009"
                  rcPhoto=""
                  fastagType={props.fastagType}
                />
                <div
                  className="auto-recharge padding16"
                  style={{
                    marginTop: "1rem",
                    height: "115px",
                  }}
                >
                  <div className="row">
                    {/* <div
                className="col-6 semiBold heading2"
                style={{ display: "flex", direction: "row" }}
              >
                <div style={{ paddingRight: "8px" }}>
                  <SvgImage name="rechargeIcon2" />
                  
                </div>
                Auto Recharge
              </div> */}
                    <div
                      className={
                        val?.autoRecharge
                          ? "col-6 row fontCardControl btnActive"
                          : "col-6 row fontCardControl btnInactive"
                      }
                      style={{
                        color: "#2E7C7B",
                        alignItems: "right",
                        // paddingLeft: "20px",
                        // marginTop: "10px",
                      }}
                    >
                      <div className="col-8"></div>
                      {/* <Switch
                  checked={val?.autoRecharge && autoRechargeState}
                  onChange={(e) => {
                    dispatch(sendAnalitics("Toggle_OneVeh_ChangeLimit_AR_MV_web"));
                    if(val?.autoRecharge != undefined && autoRechargeState)
                      handleToggle();
                    else if (!autoRechargeState)
                      notify();
                    }}
                  onColor="#EBF2F2"
                  onHandleColor="#2E7C7B"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  height={15}
                  width={40}
                  className="react-switch col-4"
                  id="material-switch"
                /> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className="omcBox row">
                        <div className="col-12">
                          <SvgImage name="hpclIcon" />
                        </div>
                        <div className="col-12">
                          {hpcl ? "Active" : "Not Active"}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="omcBox row">
                        <div className="col-12">
                          <SvgImage name="jioIcon" />
                        </div>
                        <div className="col-12">
                          {jio ? "Active" : "Not Active"}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="omcBox row">
                        <div className="col-12">
                          <SvgImage name="bpclIcon" />
                        </div>
                        <div className="col-12">
                          {bpcl ? "Active" : "Not Active"}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-2 padding0R">Limit :</div>
              <div className="col-8 row padding0L">
                <div
                  className="col-8"
                  style={{
                    display: "flex",
                    direction: "row",
                    color: "#2E7C7B",
                  }}
                >
                  <div
                    style={{
                      paddingRight: "8px",
                      marginTop: "-2px",
                      cursor:
                        val?.autoRechargeAmount !== undefined
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => {
                      dispatch(sendAnalitics("OneVeh_ChangeLimit_AR_MV_web"));
                      if (val?.autoRechargeAmount !== undefined)
                        showRechargeOne();
                    }}
                  >
                    <SvgImage name="penNew" />
                  </div>
                  {val?.autoRechargeAmount == undefined
                    ? "N/A"
                    : "₹ " + (val?.autoRechargeAmount=="500"?fastagAutorechargeAmount:val?.Fastag?.autoRechargeAmount)}
                </div>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            <VehicleAssignedHistory userId={props.userId} />
          </div>
          {
            <RechargeFastagButttonModal
              open={open}
              handleClose={handleClose}
              toggleModal={toggleModal}
              balance={fastagWalletAmount}
              fastagBal={props.bal}
              no={props.regNo}
              fleetId={props.user}
            />
          }
          <AutorechargeConfirm
            open={openRechargeOne}
            onHide={closeRechargeOne}
            toggleModal={toggleRechargeOne}
            vehNo={regNumber}
            limit={val?.autoRechargeAmount}
            vehRank={props.vehRank}
            fleetId={id}
          />
          <ToastContainer
            theme="dark"
            position="bottom-right"
            closeOnClick
            pauseOnHover
            autoClose={4000}
          />
        </div>
      )}
    </>
  );
}
export default CustomerVehicleDetail;
