import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../Icons/Icons";
import DatePicker from "react-datepicker";
import { deleteCustomRequest, getCustomRequest, updateCustomRequest } from "../../../services/Store/Actions/fastagAction";

// import { DatePickerField } from "../../../core/inputs/input";
// import { useRef } from "react";
// import AddFreight from "../../../TripsMainV2/Components/AddFreight";
// import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
// import {
//   createTrip,
//   getCityAndState,
// } from "../../../../services/Store/Actions/TripsAction";
// import moment from "moment";
// import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
// import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
// import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
// import ToastV2 from "../../../subComponents/toastV2";
// import { toastStatus } from "../../../../services/Store/Actions/appActions";
// import CollectPayment from "../CollectPayment";
// import AddPayment from "../AddPayment";
// import CreateInvoice from "../CreateInvoice";
// import AddInvoice from "../AddInvoice";

function Requests(props) {
  const { toggle, modal, setModal, regNo, title, fleetId } = props;
  const [requestType, setrequestType] = useState(0);
  const { customRequest } = useSelector((state) => state.fastag);
  console.log("customRequestcustomRequest", customRequest);
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [loader,setLoader] = useState(false);
  const [loader2,setLoader2] = useState(false);
  const [loader3,setLoader3] = useState(false);

  const handleClose = () => {
    setModal(false);
  };

  useEffect(() => {
    dispatch(
      getCustomRequest({
        type: type,
        status: 3,
        isOwner: 1,
      })
    );
    setLoader(false);
    setLoader2(false);
    setLoader3(false)
    return function cleanUp() {};
  }, [requestType, type]);
  useEffect(() => {
    dispatch(
      getCustomRequest({
        type: 'OMCREQ',
        status: 3,
        isOwner: 1,
      })
    );
    return function cleanUp() {};
  }, []);
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
          // style={{height : "75vh"}}
        >
          <div>
            <div className="closeBtnWithBackground" onClick={handleClose}>
              <SvgImage
                classname="close-btn-text"
                style={{ width: "10%", height: "10%" }}
                name="crossBtnWIthBackground"
                width="20%"
                height="20%"
              />
            </div>
            <div
              style={{ marginTop: " 10px" }}
              className="row"
              //   className="PGCollectionBox"
            >
              <div className="showroomText col-12">Requests</div>
            </div>
            <div
              style={{ marginTop: " 10px", marginLeft: "25px" }}
              className="row col-12"
              //   className="PGCollectionBox"
            >
              <Button
                className="row col-6"
                style={{
                  marginBottom: "10px",
                  backgroundColor: requestType == 0 ? "#ED752E" : null,
                }}
                onClick={() => {
                  setrequestType(0);
                  setType("OMCREQ");
                }}
              >
                Fuel
              </Button>
              <Button
                className="row col-6"
                style={{
                  marginBottom: "10px",
                  backgroundColor: requestType == 1 ? "#ED752E" : null,
                }}
                onClick={() => {
                  setrequestType(1);
                  setType("UPIREQ");
                }}
              >
                Upi
              </Button>
            </div>
          </div>

          {customRequest?.rows?.map((item, index) => {
            console.log("hvhvhvhvhvhv",item)
            console.log("nsjbjzbjzxbxjzb",typeof item?.body)
            let listObj = item?.body
            console.log("listObj", listObj);
            return (
              <>
                <div className="detailImgContainer row">
                  <div className="imageColumn col-6">
                    <img
                      src={item?.data}
                      className="imgTyre"
                      style={{ height: "100%", alignItems: "centre" }}
                    />
                  </div>
                  <div className="col-6">
                  <a
                      style={{
                        color: "Blue",
                      }}
                    >
                      {"Status: "}
                    </a>
                    <a style={{
                      color: item?.status == '0' ? '#F6BE00' : item?.status == '1' ? 'green' : 'red',
                    }}>
                    {item?.status == '0' ? 'Pending' : item?.status == '1' ? 'Accepted' : 'Rejected'}
                    </a>
                   
                    <br />
                    <a
                      style={{
                        color: "Blue",
                      }}
                    >
                      {"Vehicle Number: "}
                    </a>
                    {item?.id}
                    <br />
                    <a
                      style={{
                        color: "Blue",
                      }}
                    >
                      {" Driver Name: "}
                    </a>
                    {item?.fullName}
                    <br />

                    <a
                      style={{
                        color: "Blue",
                      }}
                    >
                      {" Amount: "}
                    </a>
                    {item?.amount}
                    <br />
                    {type == "UPIREQ" ? (
                      <>
                        <a
                          style={{
                            color: "Blue",
                          }}
                        >
                          {" Vpa: "}
                        </a>
                        {listObj?.vpa}
                        <br />
                      </>
                    ) : null}

                    <a
                      style={{
                        color: "Blue",
                      }}
                    >
                      {" Remark: "}
                    </a>
                    {listObj?.remarks}
                    <br />
                    <a
                      style={{
                        color: "Blue",
                      }}
                    >
                      {" Requested At: "}
                    </a>
                    {item?.createdAt}
                    <br />

                    <a
                      style={{
                        color: "Blue",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        console.log("12.909169,77.6643821");
                        window.open(
                          `https://www.google.com/maps/search/${listObj?.lat},${listObj?.long}`
                        );
                      }}
                    >
                      {" Location: "}
                    </a>
                    {"click"}
                    <br />

                    {(item?.status==1||item?.status==2)?<button disabled={loader} onClick={()=>{
                      console.log("clicked deleted")
                      setLoader(true)
                      dispatch(deleteCustomRequest({
                        requestId: item?.requestId,
                      }, res => {
                        console.log('dispatchdispatchdispatch', res)
                        setLoader(false)
                        dispatch(getCustomRequest({
                          type: type,
                          status: 3,
                          isOwner: 1
                        }))
                      }))
                    }}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      Delete
                    </button>:<>
                      <button
                      style={{
                        backgroundColor:loader2?null:"green",
                        borderRadius: "5px",
                        color: loader2?'black':"white",
                        marginTop: "10px"
                      }}
                      // disabled={loader2}
                      onClick={()=>{
                        console.log("clicked Accept");
                        setLoader2(true)
                        // setLoader3(false)
                        dispatch(updateCustomRequest({
                          requestId: item?.requestId,
                          status: 1
                        }, (res) => {
                          console.log('requestId:item?.requestId', res)
                          setLoader2(false)
                          dispatch(getCustomRequest({
                            type: type,
                            status: 3,
                            isOwner: 1
                          }))
                        }))
                      }}
                    >
                     { loader2?"Wait":"Accept"}
                    </button>
                    <button
                      style={{
                        backgroundColor: loader3?null:"red",
                        marginLeft: "10px",
                        borderRadius: "5px",
                        color: loader3?'black':"white",
                        marginTop: "10px"
                      }}
                      // disabled={loader3}
                      onClick={()=>{
                        console.log("clicked Reject");
                        setLoader3(true)
                        dispatch(updateCustomRequest({
                          requestId: item?.requestId,
                          status: 2
                        }, (res) => {
                          console.log('requestId:item?.requestId', res)
                          setLoader3(false)
 
                          dispatch(getCustomRequest({
                            type: type,
                            status: 3,
                            isOwner: 1
                          }))
                        }))
                        
                      }}
                    >
                      { loader3?"Wait": "Reject"}
                    </button>
                    </>}


                   
                  </div>
                </div>
              </>
            );
          })}
        </ModalBody>
      </Modal>
      {/* <CreateInvoice
        toggle={createInvoice}
        modal={invoiceModal}
        setModal={setInvoiceModal}
        customerId={customerId}
        isCheckAll={isCheckAll}
        setIsCheckAll={setIsCheckAll}
        isCheck={isCheck}
        setIsCheck={setIsCheck}
        customer={customer}
      />
      <AddInvoice
        toggle={addInvoiceFunc}
        modal={addInvoice}
        setModal={setAddInvoice}
      /> */}
    </div>
  );
}

export default Requests;
