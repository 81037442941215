import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import SvgImage from "../../Icons/Icons";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "./index.css";

import { useDispatch } from "react-redux";
import {
  addMoney,
  expenseSumm,
} from "../../../services/Store/Actions/driverslistActions";
import moment from "moment";
// import { allCardsBalance } from "../../../services/Store/Actions/cardWalletAction";
import { getMasterWalletBalance } from "../../../services/Store/Actions/masterWalletActions";
import { fetchBalance } from "../../../services/Store/Actions/manageCardActions";
import { transferToCard } from "../../../services/Store/Actions/manageCardActions";
import { editLedgerExpense } from "../../../services/Store/Actions/TripsAction";
import {
  sendAnalitics,
  storeMedia,
} from "../../../services/Store/Actions/appActions";

import { ClipLoader } from "react-spinners";
import { Switch } from "@material-ui/core";
const EditLedgerExpense = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    toggleModal,
    handleClose,
    name,
    fleetId,
    vehRegNo,
    openChooseMethod,
    itemTemp,
    ledgerData
  } = props;

  useEffect(() => {
    dispatch(fetchBalance());
  }, [fleetId]);
  const { loader } = useSelector((state) => state?.manageCard);
  const { ownerBalance } = useSelector((state) => state?.manageCard); //LOgipe CARD BALANCE

  const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == null
      ? 0
      : ownerBalance?.instrument_balance?.balance;
  // useEffect(() => {}, [props]);
  console.log("ManageDriverAddBalance", ledgerData);
  const [statusPod, setStatusPod] = useState(false);
  const [imageLoaderPod, setImageLoaderPod] = useState(false);
  const [displayNamePod, setDisplayNamePod] = useState([]);
  const [card, setCard] = useState(true);
  const [openType, setOpenType] = useState(true);
  const expenseType = [
    { value: "Food" },
    { value: "Service" },
    { value: "Fuel" },
    { value: "Salary" },
    { value: "Fastag" },
    { value: "Others" },
  ];
  const transferType = [
    { value: "credit" },
    { value: "debit" },
  ];
  // useEffect(() => {}, [fleetId]);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">{name}</p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                if (!loader) {
                  toggleModal();
                }
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="card-wallet-icon-body2">
              <SvgImage
                name="cardWalletLogo"
                height="43.81"
                width="43.81"
                classname="card-wallet-logo-BankLogo"
              />
              <div>
                <p className="body-sub-part1-ab">Master Account Balance</p>
                <p className="body-sub-part2-ab">₹{LogipeAccounBal}</p>
              </div>
            </div>
            {/* <div
              className="flexStyling sub-head-3"
              style={{ justifyContent: "center", marginTop: "20px" }}
            >
              <div> Cash</div>
              <div style={{ marginTop: "-7px" }}>
                <Switch
                  checked={card}
                  onChange={(e) => {
                    setCard(!card);
                  }}
                  handleDiameter={20}
                  height={15}
                  width={40}
                  id="material-switch"
                />
              </div>
              <div>Card</div>
            </div> */}
            <Formik
              enableReinitialize
              initialValues={{
                amount: ledgerData?.amount || "",
                pin: "",
                tripId: ledgerData?.tripId || "",
                remark: ledgerData?.remarks ||  "",
                type: ledgerData?.expanseTag || "",
                transferType: ledgerData?.transferType ||  "",
                isDeleted : false,
              }}
            //   validationSchema={Yup.object().shape({
            //     amount: Yup.number()
            //       .typeError("Only Numbers are allowed")
            //       .min(1, "Amount should be greater than 1")
            //       .required("Amount is required"),
            //     pin: Yup.string()
            //       .required("* Required")
            //       .matches(/^[0-9]{4}$/, "* Pin Must be exactly 4 digits"),
            //     type: Yup.string().required("* Expense Type required"),
            //     // dob: Yup.string()
            //     //   .required('Expense Date is required'),
            //     // password: Yup.string()
            //     //   .min(6, 'Password must be at least 6 characters')
            //     //   .required('Password is required'),
            //     // confirmPassword: Yup.string()
            //     //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
            //     //   .required('Confirm Password is required')
            //   })}
              //validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                // values.bookingDate = moment(values.bookingDate).format(
                //   "DD-MM-YYYY"
                // );
                //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
                if (ledgerData?.tripLedgerId) {
                  console.log("kkkkknnannaann", values);
                    let finalData = {
                      tripLedgerId : ledgerData?.tripLedgerId,
                      amount: values?.amount ? values?.amount : "",
                      tripId : values.tripId ? values.tripId : "",
                      expanseTag : values.type ? values.type : "",
                      transferType: values?.transferType ? values?.transferType : "",
                      isDeleted: values?.isDeleted,
                    };
                    // if (values?.tripId) {
                    //   finalData["tripId"] = values?.tripId;
                    // }

                    // if (values?.otherRemarks) {
                    //   finalData["otherRemarks"] = values?.otherRemarks;
                    // }
                    dispatch(
                        editLedgerExpense(finalData, (res) => {
                        actions.setSubmitting(false);
                        // dispatch(
                        //   sendAnalitics("Success_Add_Bal_DriverCard_Web", {
                        //     amt: values.amount,
                        //     pin: values.pin,
                        //     remark: values.remark,
                        //     fleetId: fleetId,
                        //   })
                        // );
                        if (res?.status == 200) {
                          console.log("successful");
                          //navigate("/Customers")
                          // dispatch(expenseSumm(fleetId));
                          // window.location.reload();
                          toggleModal();
                        }
                      })
                    );
                  
                } else {
                  toast.error("please select user again.");
                }

                //   // addMoney(values, fleetId, (res) => {
                //   //   actions.setSubmitting(false);
                //   //   if (res?.data?.type === "success") {
                //   //     console.log("successful");
                //   //     //navigate("/Customers")
                //   //     dispatch(expenseSumm(fleetId));
                //   //     toggleModal();
                //   //   }
                //   // })
                // );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <div className="sub-title-1">
                      {/* <span class="circle-logo">1</span> */}
                    <input
                    type="checkbox"
                    className=" form-check-input circle-logo"
                    checked={values.isDeleted}
                    value="allOptions"
                    onChange={(e) => {
                        // console.log("kkkkknnannaann",e.target)
                      const { checked } = e.target;
                      setFieldValue("isDeleted", checked, true);
                    //   setSelectAllDrivers(checked);
                    }}
                    // className="form-check-input"
                  />
                      <p className="sub-head-3">Do you want to Delete Expense</p>
                    </div>
                  </div>
                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <p className="sub-head-3">Enter amount</p>
                    </div>
                    <p className="sub-head-body">
                      Amount will be deducted from owner’s LogiPe account
                    </p>

                    <input
                      type="text"
                      id="html"
                      className={
                        "input-body-1 form-control" +
                        (errors.amount && touched.amount ? " is-invalid" : "")
                      }
                      name="amount"
                      value={values.amount}
                      touched={touched.amount}
                      errors={errors.amount}
                      onBlur={handleBlur("amount")}
                      onChange={handleChange("amount")}
                    ></input>
                    <div className="input-amount"></div>
                    <ErrorMessage
                      name="amount"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-head-3">Trip Number (Optional)</p>
                    </div>
                    <p className="sub-head-body">
                      Enter Trip Number to Add This Expense
                    </p>

                    <input
                      type="text"
                      id="tripId"
                      readonly="readonly"
                      className={
                        "input-body-1 form-control" +
                        (errors.tripId && touched.tripId
                          ? " is-invalid"
                          : "")
                      }
                      name="tripId"
                      value={values.tripId}
                      // touched={touched.vehRegNo}
                      // errors={errors.vehRegNo}
                      // onBlur={handleBlur("vehRegNo")}
                      // onChange={handleChange("vehRegNo")}
                    />
                    <div className="input-amount"></div>
                    {/* <ErrorMessage
                      name="vehRegNo"
                      component="div"
                      className="invalid-feedback"
                    /> */}
                  </div>

                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">3</span>
                      <p className="sub-head-3">Enter remarks</p>
                    </div>
                    <p className="sub-head-body">
                      Enter the the remarks/comments
                    </p>

                    <input
                      type="text"
                      id="html"
                      className={
                        "input-body-1 form-control" +
                        (errors.remark && touched.remark ? " is-invalid" : "")
                      }
                      name="remark"
                      value={values.remark}
                      touched={touched.remark}
                      errors={errors.remark}
                      onBlur={handleBlur("remark")}
                      onChange={handleChange("remark")}
                    ></input>
                    <div className="input-amount"></div>
                    <ErrorMessage
                      name="remark"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div>
                    <div className="flexStyling">
                      <div className="sub-title-1">
                        <span class="circle-logo">4</span>
                        <p className="sub-head-3">Type</p>
                      </div>
                      <div className="sub-head-3">
                        <div className="">
                          <Field
                            component="select"
                            placeholder={"--Select--"}
                            class={
                              "form-select select-option2 broderForSelect" +
                              (errors.type && touched.type ? " is-invalid" : "")
                            }
                            aria-label="Default select example"
                            name="type"
                            onChange={handleChange(`type`)}
                            value={values.type}
                            onBlur={handleBlur("type")}
                            errors={errors.type}
                            touched={touched.type}
                          >
                            <option value="" disabled selected>
                              --Select--
                            </option>
                            {expenseType?.map((opt) => {
                              // console.log("lllllkkkkk",values)
                              return (
                                <option
                                  value={opt?.value}
                                  // onChange={(v) => {
                                  //   console.log("pooppppppp", v);
                                  //   // setSourceName(v?.city);
                                  //   setFieldValue(
                                  //     "vendorType",
                                  //     v?.cityName,
                                  //     true
                                  //   );
                                  //   setFieldValue(
                                  //     "vendorType",
                                  //     (v?.cityId).toString(),
                                  //     true
                                  //   );
                                  // }}
                                >
                                  {opt?.value}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                        <ErrorMessage
                            name="type"
                            component="div"
                            className="invalid-type"
                          />
                      </div>
                      {/* <div className="" style={{ position: "relative" }}>
                    {openType ? (
                      <div
                        className="containerShadow"
                        style={{
                          width: "100%",
                          marginTop: "5px",
                          borderRadius: "8px",
                          border: "1px solid #E2E2E2",
                        }}
                      >
                        {expenseType?.map((item, i) => {
                          return (
                            <>
                              <div
                                key={i}
                                className="flexStyling padding8 "
                                onClick={(event) => {
                                  setOpenType(false);
                                  // setUserId(event.target.value);
                                  setFieldValue(
                                    "type",
                                    event.target.value,
                                    true
                                  );
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                <input
                                  type="radio"
                                  name="driver"
                                  value={item?.value}
                                  id={item?.fleetId}
                                />
                                <div className="padding16L">
                                  {item?.value}
                                </div>
                              </div>
                            
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                    </div> */}
                    </div>

                    <ErrorMessage
                      name="vehRegNo"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>


                  <div>
                  <div>
                    <div className="flexStyling">
                      <div className="sub-title-1">
                        <span class="circle-logo">4</span>
                        <p className="sub-head-3">Transfer Type</p>
                      </div>
                      <div className="sub-head-3">
                        <div className="">
                          <Field
                            component="select"
                            placeholder={"--Select--"}
                            class={
                              "form-select select-option2 broderForSelect" +
                              (errors.transferType && touched.transferType ? " is-invalid" : "")
                            }
                            aria-label="Default select example"
                            name="transferType"
                            onChange={handleChange(`transferType`)}
                            value={values.transferType}
                            onBlur={handleBlur("transferType")}
                            errors={errors.transferType}
                            touched={touched.transferType}
                          >
                            <option value="" disabled selected>
                              --Select--
                            </option>
                            {transferType?.map((opt) => {
                              // console.log("lllllkkkkk",values)
                              return (
                                <option
                                  value={opt?.value}
                                  // onChange={(v) => {
                                  //   console.log("pooppppppp", v);
                                  //   // setSourceName(v?.city);
                                  //   setFieldValue(
                                  //     "vendorType",
                                  //     v?.cityName,
                                  //     true
                                  //   );
                                  //   setFieldValue(
                                  //     "vendorType",
                                  //     (v?.cityId).toString(),
                                  //     true
                                  //   );
                                  // }}
                                >
                                  {opt?.value}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                        <ErrorMessage
                            name="type"
                            component="div"
                            className="invalid-type"
                          />
                      </div>
                      {/* <div className="" style={{ position: "relative" }}>
                    {openType ? (
                      <div
                        className="containerShadow"
                        style={{
                          width: "100%",
                          marginTop: "5px",
                          borderRadius: "8px",
                          border: "1px solid #E2E2E2",
                        }}
                      >
                        {expenseType?.map((item, i) => {
                          return (
                            <>
                              <div
                                key={i}
                                className="flexStyling padding8 "
                                onClick={(event) => {
                                  setOpenType(false);
                                  // setUserId(event.target.value);
                                  setFieldValue(
                                    "type",
                                    event.target.value,
                                    true
                                  );
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                <input
                                  type="radio"
                                  name="driver"
                                  value={item?.value}
                                  id={item?.fleetId}
                                />
                                <div className="padding16L">
                                  {item?.value}
                                </div>
                              </div>
                            
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                    </div> */}
                    </div>

                    <ErrorMessage
                      name="vehRegNo"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                    <Button
                      type="submit"
                      className="butt4"
                      disabled={loader ? true : false}
                      onClick={() => {
                        dispatch(
                          sendAnalitics("Add_Bal_DriverCard_fromMA_Web", {
                            amt: values.amount,
                            pin: values.pin,
                            remark: values.remark,
                            fleetId: fleetId,
                          })
                        );
                      }}
                    >
                      {loader ? "Please Wait" : "Add Card Balance"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>

            {/* <div className="space-div"></div> */}
          </ModalBody>
        </div>
        <ToastContainer
          theme="light"
          position="top-right"
          closeOnClick
          pauseOnHover
          autoClose={4000}
        />
      </Modal>
    </div>
  );
};
export default EditLedgerExpense;
