import React, { useEffect } from "react";
import { useState } from "react";
import SvgImage from "../../../Icons/Icons";
import { ToggleSlider } from "react-toggle-slider";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getCardControl,
  editCardControl,
  newGetCardControlAction,
  newEditCardControlAction,
} from "../../../../services/Store/Actions/driverslistActions";
import "./index.css";
import Switch from "react-switch";

import CardControlsEditModal from "../../../Modals/CardControlsEditModal";
import { ListItemSecondaryAction } from "@material-ui/core";
import { changeCardStatus } from "../../../../services/Store/Actions/LogipeCardActions";
import { changePin } from "../../../../services/Store/Actions/manageCardActions";

function ManageLogipeCardRight(props) {
  const { loader } = useSelector((state) => state?.driverlist);
  const dispatch = useDispatch();
  const [automation, setAutomation] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const userId = props.userId;
  const allStar = useSelector((state) => state);
  const newCardInfo = useSelector(
    (state) => state?.driverlist?.newCardInfo?.walletControl
  );
  console.log("hhhhhhhhhhhh", newCardInfo);

  useEffect(() => {
    dispatch(newGetCardControlAction(props.userId));
    // dispatch(changePin(props.userId));
  }, []);
  const url = useSelector((state) => state?.manageCard?.url?.details?.url);
  console.log("urls", url);

  const handleSetPin = () => {
    dispatch(changePin(props.userId, (res) => {
      if (res?.data?.type === "success") {
        console.log("rrrrrrrrrrrrrrrr", res);
        window.open(res?.data?.data?.details?.url);
        // window.location.replace(res?.data?.data?.link);
        //window.open();
        //window.location.reload();
      }
    }));
    //window.open(url);
  };

  //const idOfUser="http://wallet-api.logipe.com/api/v1/wallet/setCardPin/"+props.userId;
  console.log("NEWMOON", allStar);
  const cardControl = allStar?.driverlist?.cardControl?.card;
  //console.log(cardControl)
  const disabledStyle = { pointerEvents: "none", opacity: "0.4" };
  const [open, setOpen] = useState(false);
  function handleShow(title) {
    setOpen(true);
    setEditTitle(title);
  }
  function handleClose() {
    setOpen(false);
  }
  function toggleModal() {
    setOpen(!open);
  }
  //const value = useSelector(state=>state?.driverlist?.cardControl?.card?.isActive)
  const cardStatus = useSelector(
    (state) => state?.manageCard?.OwnercardDetails?.accountStatus
  );
  console.log(",,,,,,,,,,", cardStatus);
  const value = cardStatus === 4 ? true : false;

  function handleToggal(val, title, e) {
    if (value) {
      let TransactionType = title.split(" ");
      TransactionType = TransactionType[0];
      TransactionType = TransactionType.toLowerCase();
      if (!loader) {
        dispatch(
          newEditCardControlAction(
            userId,
            {
              userId: userId,
              txnType: TransactionType,
              limitValue: "0",
            },
            (res) => {
              console.log("Testing Card Controls", res);
              if (res?.data?.type === "success") {
                console.log("success");
                dispatch(newGetCardControlAction(userId));
              }
            }
          )
        );
      }
    }
  }
  console.log("card control", cardControl);
  const data = [
    {
      Name: "ATM Withdrawal",
      Edit: "Edit",
      logo: "atmWithdrawl",
      Remarks: newCardInfo?.atmTransactionLimit,
      RightElement: "Toggle",
      status: newCardInfo?.atmTransactionStatus,
    },

    // {
    //     Name:"Fuel Transaction",
    //     Edit:"Edit",logo:"fuelTransactionLogo",
    //     Remarks:cardControl?.fuelTransactionLimit,
    //     RightElement:"Toggle",
    //     status:cardControl?.fuelTransactionStatus

    // },
    {
      Name: "Online Transaction",
      Edit: "Edit",
      logo: "onlineTransactionLogo",
      Remarks: newCardInfo?.onlineTransactionLimit,
      RightElement: "Toggle",
      status: newCardInfo?.onlineTransactionStatus,
    },

    {
      Name: "POS",
      Edit: "Edit",
      logo: "otherPosLogo",
      Remarks: newCardInfo?.posTransactionLimit,
      RightElement: "Toggle",
      status: newCardInfo?.posTransactionStatus,
    },
  ];
  // function handleChange(val){
  //   dispatch(changeCardStatus(props.userId,res=>{
  //     console.log(res)
  //     if(res?.type=='success'){
  //       dispatch(getCardControl(props.userId))
  //       props.setStatus(!val)
  //     }
  //   }))
  // }

  //console.log("asmi---",props.userId);
  //console.log("asmi---",idOfUser);

  return (
    <div
      className="ManageLogipeCardControlsBox padding16"
      style={{ opacity: value ? "1" : "0.5" }}
    >
      <div className="row padding8">
        <div className="col-2">
          <SvgImage name="settingsLogo" />
        </div>
        <div className="col-8 headerTextCardControls">LogiPe Card Controls</div>
      </div>
      <div className="ManageLogipeCardRightBox">
        {/* <div className="row" style={{background:"rgba(46, 124, 123, 0.1)",width:"100%",height:"100px",marginLeft:"0px",paddingTop:"25px"}}>
                  <div  className = 'col-3' style={{marginLeft:"20px"}}>
                  <SvgImage name = 'CardLogo' height = '43.81' width = '43.81'/>
                    
                  </div>
                  <div className="col-4 changeATMpin" style={{width:"auto"}}>
                    <div className="fontCardControl " style ={{marginLeft:""}}>
                                Card Status
                    </div>
                    <div className="activeCardControld">
                              {value?"Active":"Not Active"} 
                    </div>
                  </div>
                  <div className="col-3 fontCardControl " style={{color:"#2E7C7B",paddingLeft:"100px",position:"relative",top:"-15px"}}>
                    <Switch
                       onChange={()=>handleChange(value)}
                       checked={value}
                       checkedIcon={false}
                       uncheckedIcon={false}
                       onHandleColor="#2E7C7B"
                       offColor="#ffff"
                       onColor="#ffff"
                       className="react-switch"
                    />

                    </div>

                    </div> */}
        <div
          className="row"
          style={{
            background: "#fff",
            // width: "420px",
            // marginLeft: "10px",
            marginTop: "40px",
          }}
        >
          <div className="col-2">
            <SvgImage name="changeAtmPin" height="43.81" width="43.81" />
          </div>

          <div className="col-6">
            <div className="fontCardControl " style={{ marginLeft: "" }}>
              Change ATM Pin
            </div>
            <div>
              {/* <div className="dateCardControld">Last updated on 31-03-2021</div> */}
            </div>
          </div>
          {value ? (
            <div
              className="col-4 changePinCardControl"
              style={{
                color: "#ED752E",
                // paddingLeft: "30px",
                // marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={handleSetPin}
            >
              Change PIN
            </div>
          ) : (
            <div
              className="col-4 changePinCardControl"
              style={{
                color: "#ED752E",
                // paddingLeft: "30px",
                // marginTop: "10px",
                cursor: "default",
              }}
            >
              Change PIN
            </div>
          )}
        </div>
        {data.map((item) => {
          //console.log("ITEM STATUS<<<<<<>>>>>",item.status)
          return (
            <>
              <div
                className="row "
                style={{
                  background: "#fff",
                  // width: "420px",
                  // marginLeft: "10px",
                  marginTop: "60px",
                }}
              >
                <div className="col-2 ">
                  <SvgImage
                    name={item.logo}
                    height="43.81"
                    width="43.81"
                    classname=""
                  />
                </div>
                <div className="col-7">
                  <div className="fontCardControl ">{item.Name}</div>
                  <div style={{ display: "flex", marginTop: "0px" }}>
                    <div className="amountFontCardControl">₹{item.Remarks}</div>
                    {/* <div
                      style={{
                        marginLeft: "5px",
                        cursor: value ? (item.status? "pointer":"default") : "default",
                      }}
                      onClick={() => {
                        if(item.status)
                        handleShow(item.Name)}}
                    >
                      <SvgImage name="penLogo" />
                    </div> */}
                    {/* <div
                      className="cardControlEdit "
                      onClick={() => {
                        if(item.status)
                        handleShow(item.Name)}}
                      style={{ cursor: value ? (item.status? "pointer":"default") : "default" }}
                    >
                      {item.Edit}
                      {value ? (
                        <CardControlsEditModal
                          toggleModal={toggleModal}
                          handleClose={handleClose}
                          open={open}
                          title={editTitle}
                          setAutomation={setAutomation}
                          automation={automation}
                          userId={props.userId}
                        />
                      ) : (
                        <></>
                      )} */}
                      {/* {

                                  "ATM Withdrawal"===item.name?<CardControlsEditModal
                                  toggleModal={toggleModal}
                                  handleClose={handleClose}
                                  open={open} 
                                  title={item.Name}
                                  />:"Fuel Transaction"===item.Name?<CardControlsEditModal
                                  toggleModal={toggleModal}
                                  handleClose={handleClose}
                                  open={open} 
                                  title={item.Name}
                                  />:"Online Transaction"===item.Name?<CardControlsEditModal
                                  toggleModal={toggleModal}
                                  handleClose={handleClose}
                                  open={open} 
                                  title={item.Name}
                                  />:"Other POS"===item.Name?<CardControlsEditModal
                                  toggleModal={toggleModal}
                                  handleClose={handleClose}
                                  open={open} 
                                  title={item.Name}
                                  />:null
                                  }  */}
                    {/* </div> */}
                  </div>
                </div>
                <div
                  className={
                    value
                      ? "col-3 fontCardControl btnActive"
                      : "col-3 fontCardControl btnInactive"
                  }
                  style={{
                    color: "#485B7C",
                    // paddingLeft: "20px",
                    // marginTop: "10px",
                  }}
                >
                  <Switch
                    checked={item.status}
                    onChange={(e) => handleToggal(item.status, item.Name, e)}
                    onColor="#96A9CA"
                    onHandleColor="#485B7C"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    height={15}
                    width={40}
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
export default ManageLogipeCardRight;
