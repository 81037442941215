import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import SvgImage from "../../../../../../Icons/Icons";
import { Button } from "reactstrap";
import AddNewTrip from "./AddNewTrip";


const NoTripData=(props)=>{

    const [open, setOpen]= useState(false);

    const handleClose=()=>{
        setOpen(false);
    }

    const toggleModal=()=>{
        setOpen(!open);
    }

    const showModal=()=>{
        setOpen(true);
    }

    return(
        <div className="padding32 textCenter">
            <SvgImage name="mapLogo" />
            <div className="Bold heading2 padding16T padding16B">No ongoing trips</div>
            <div className="greyFont heading3">Start using logipe trips and enjoy hasselfree</div>
            <div className="greyFont heading3 padding32B">expense management</div>
            <div style={{textAlign:"center"}} onClick={showModal}>
                <Button className="generateOtp heading3 semiBold" style={{width:"40%"}}>Create Trip</Button>
            </div>
            <AddNewTrip 
                open={open}
                toggleModal={toggleModal}
                handleClose={handleClose}
            />
        </div>
    )
}

export default NoTripData;