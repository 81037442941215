import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { Checkbox } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import AddFreight from "../../../TripsMainV2/Components/AddFreight";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import { toast, ToastContainer } from "react-toastify";
import {
  createTrip,
  getCityAndState,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import InvoiceToast from "../../../subComponents/InvoiceToast";
import {
  storeMedia,
  toastStatus,
} from "../../../../services/Store/Actions/appActions";
import ManuallyInvoice from "../ManualInvoice";
import TripInvoiceDetail from "../tripInvoiceDetail";
import { useNavigate } from "react-router-dom";
import {
  addPaymentRecivedAction,
  getInvoiceListAction,
  getLedgerListAction,
} from "../../../../services/Store/Actions/customerAction";
import { ClipLoader } from "react-spinners";

function AddPayment(props) {
  const { toggle, modal, setModal, offset, vendorData } = props;
  const [openToast, setOpenToast] = useState(false);
  const [status, setStatus] = useState(false);
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [imageLoader, setImageLoader] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [markedInvoices, setMarkCleared] = useState({});
  const [offset2, setOffset2] = useState(0);
  const { customer, invoiceList, invoiceCount } = useSelector(
    (state) => state.customer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("amount is required"),
    paymentDate: Yup.string().required("paymentDate is required"),

    // receipt: Yup.string().required("receipt is required"),

    // txnNumber: Yup.string().required("txnNumber is required"),

    // remarks: Yup.string().required("remarks is required"),
  });
  const handleClose = () => {
    setModal(!modal);
  };
  const handlePrevButton = () => {
    //props.nextTrigger();
    if (offset2 > 0) {
      setOffset2(offset2 - 5);
      dispatch(
        getInvoiceListAction({
          customerId: customer.customerId,
          offset: offset2 - 5,
          search: "",
          transferType: "unpaid",
          sortAs: "",
          sortBy: "",
          from: "",
          to: "",
        })
      );
    }
    //console.log("X--");
  };

  const handleNextButton = () => {
    if (invoiceCount - offset2 > 5) {
      setOffset2(offset2 + 5);
      dispatch(
        getInvoiceListAction({
          customerId: customer.customerId,
          offset: offset2 + 5,
          search: "",
          transferType: "unpaid",
          sortAs: "",
          sortBy: "",
          from: "",
          to: "",
        })
      );
    }
    //console.log("X++");
  };
  useEffect(() => {
    dispatch(
      getInvoiceListAction({
        customerId: customer.customerId,
        offset: 0,
        search: "",
        transferType: "unpaid",
        sortAs: "",
        sortBy: "",
        from: "",
        to: "",
      })
    );
  }, []);

  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          // style={{ height: "80vh" }}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            style={{ marginTop: " 30px" }}
            //   className="PGCollectionBox"
          >
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Add Payments Received{" "}
            </div>

            <Formik
              // isValid='true'
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                amount: "",
                paymentDate: "",
                receipt: "",
                txnNumber: "",
                remarks: "",
              }}
              onSubmit={(values) => {
                console.log("===>>>>init", values);

                let markedInvoicesArr = Object.values(markedInvoices);
                console.log(
                  "markedInvoicesArr",
                  markedInvoicesArr,
                  markedInvoices
                );
                dispatch(
                  addPaymentRecivedAction(
                    {
                      ...values,
                      customerId: customer.customerId,
                      markPaidInvoice: markedInvoicesArr,
                    },
                    (res) => {
                      console.log("addPaymentRecivedAction", res);
                      if (res.status == 200) {
                        setOpenToast(true);
                        dispatch(
                          toastStatus({
                            toastStatus: true,
                            message: "Payment Added Successfully",
                            status: true,
                            callBack: () => {
                              dispatch(
                                getLedgerListAction({
                                  customerId: customer.customerId,
                                  offset: offset || 0,
                                  search: "",
                                  sortAs: "",
                                  sortBy: "",
                                  from: "",
                                  to: "",
                                })
                              );
                              handleClose();
                            },
                          })
                        );
                      }
                    }
                  )
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "60px", padding: "0 55px 0 55px" }}
                >
                  <div style={{}} className="d-flex">
                    <div style={{ width: "100%" }}>
                      <FormGroup>
                        <div className="row">
                          <div className="col-6 row">
                            <div className="col-12">
                              <label for="vrn" className="heading3 semiBold">
                                Customer Name
                              </label>
                            </div>
                            <div className="col-12" style={{ padding: "8px" }}>
                              <div
                                className="col-12"
                                style={{ padding: "5px" }}
                              >
                                {customer?.companyName}
                              </div>
                            </div>
                          </div>
                          <div className="col-6 row">
                            <div className="col-12">
                              <label for="vrn" className="heading3 semiBold">
                                GSTIN
                              </label>
                            </div>
                            <div className="col-12" style={{ padding: "8px" }}>
                              <div
                                className="col-12"
                                style={{ padding: "5px" }}
                              >
                                {customer?.gstin || "NA"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup
                        // style={{
                        //   display: "flex",
                        //   alignItems: "center",
                        //   justifyContent: "space-between",
                        //   marginTop: "30px",
                        // }}
                        style={{
                          padding: "20px 0 0 0",
                        }}
                      >
                        <div className="row">
                          <div className="col-6 row">
                            <div className="col-12">
                              <label for="vrn" className="heading3 semiBold">
                                Total Amount
                              </label>
                            </div>
                            <div className="col-12" style={{ padding: "8px" }}>
                              <input
                                type="number"
                                invoiceId="amount"
                                className={`inputBoxAddCust ${
                                  touched.amount && errors.amount
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="amount"
                                onChange={(event) => {
                                  // setSearch(event.target.value);
                                  setFieldValue(
                                    "amount",
                                    event.target.value,
                                    true
                                  );
                                }}
                                value={values.amount}
                                touched={touched.amount}
                                errors={errors.amount}
                                placeholder="enter amount"
                              />
                              <div
                                style={{
                                  margin: "7px 0 0px 5px",
                                  color: "red",
                                }}
                              >
                                {errors.amount && touched.amount ? (
                                  <div>*{errors.amount}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-6 row">
                            <div className="col-12">
                              <label for="vrn" className="heading3 semiBold">
                                Payment Date
                              </label>
                            </div>
                            <div className="col-12" style={{ padding: "8px" }}>
                              <input
                                type="date"
                                invoiceId="paymentDate"
                                className={`inputBoxAddCust ${
                                  touched.paymentDate && errors.paymentDate
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="paymentDate"
                                onChange={(event) => {
                                  // setSearch(event.target.value);
                                  setFieldValue(
                                    "paymentDate",
                                    event.target.value,
                                    true
                                  );
                                }}
                                value={values.paymentDate}
                                touched={touched.paymentDate}
                                errors={errors.paymentDate}
                                placeholder="enter Date"
                              />
                              <div
                                style={{
                                  margin: "7px 0 0px 5px",
                                  color: "red",
                                }}
                              >
                                {errors.paymentDate && touched.paymentDate ? (
                                  <div>*{errors.paymentDate}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup
                        // style={{
                        //   display: "flex",
                        //   alignItems: "center",
                        //   justifyContent: "space-between",
                        //   marginTop: "30px",
                        // }}
                        style={{
                          padding: "20px 0 0 0",
                        }}
                      >
                        <div className="row">
                          <div className="col-6 row">
                            <div className="col-12">
                              <label for="vrn" className="heading3 semiBold">
                                Transaction Number
                              </label>
                            </div>
                            <div className="col-12" style={{ padding: "8px" }}>
                              <input
                                type="text"
                                invoiceId="txnNumber"
                                className={`inputBoxAddCust ${
                                  touched.txnNumber && errors.txnNumber
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="txnNumber"
                                onChange={(event) => {
                                  // setSearch(event.target.value);
                                  setFieldValue(
                                    "txnNumber",
                                    event.target.value,
                                    true
                                  );
                                }}
                                value={values.txnNumber}
                                touched={touched.txnNumber}
                                errors={errors.txnNumber}
                                placeholder="Enter Transaction Number"
                              />
                              <div
                                style={{
                                  margin: "7px 0 0px 5px",
                                  color: "red",
                                }}
                              >
                                {errors.txnNumber && touched.txnNumber ? (
                                  <div>*{errors.txnNumber}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-6 row">
                            <div className="col-12">
                              <label for="vrn" className="heading3 semiBold">
                                Remarks
                              </label>
                            </div>
                            <div className="col-12" style={{ padding: "8px" }}>
                              <input
                                type="text"
                                invoiceId="remarks"
                                className={`inputBoxAddCust ${
                                  touched.remarks && errors.remarks
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="remarks"
                                onChange={(event) => {
                                  // setSearch(event.target.value);
                                  setFieldValue(
                                    "remarks",
                                    event.target.value,
                                    true
                                  );
                                }}
                                value={values.remarks}
                                touched={touched.remarks}
                                errors={errors.remarks}
                                placeholder="Enter remarks"
                              />
                              <div
                                style={{
                                  margin: "7px 0 0px 5px",
                                  color: "red",
                                }}
                              >
                                {errors.remarks && touched.remarks ? (
                                  <div>*{errors.remarks}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup
                        // style={{
                        //   display: "flex",
                        //   alignItems: "center",
                        //   justifyContent: "space-between",
                        //   marginTop: "30px",
                        // }}
                        style={{
                          padding: "20px 0 0 0",
                        }}
                      >
                        <div className="col-6 row">
                          <div className="col-12">
                            <label for="vrn" className="heading3 semiBold">
                              Payment Receipt
                            </label>
                          </div>

                          <div className="d-flex">
                            {status ? (
                              <div className="row">
                                <div
                                  className="col-9"
                                  style={{ paddingTop: "0.3rem" }}
                                >
                                  <p
                                    style={{
                                      margin: "0 0 0 10px",
                                      color: "black",
                                    }}
                                  >
                                    {displayName}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={(e) => {
                                    setStatus(false);
                                    // e.preventDefault();
                                    console.log("dsadsadsadsadsad", values);
                                    setFieldValue("receipt", "", true);
                                  }}
                                  style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage name="CloseButtonLogo" />
                                </div>
                              </div>
                            ) : (
                              <Button
                                className="add-attach-for-fastag-linked-vehicle-new me-3"
                                type="button"
                              >
                                {imageLoader ? (
                                  <div style={{ zIndex: 10 }}>
                                    <ClipLoader
                                      color={"#485B7C"}
                                      loading={true}
                                      size={20}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <SvgImage
                                      name="AddAttachment"
                                      height="25"
                                      width="25"
                                    />
                                    <input
                                      type="file"
                                      // multiple
                                      // accept="image/*"
                                      value={values.receipt}
                                      accept="image/*"
                                      className="add-lr-attachment"
                                      onChange={(e) => {
                                        const img = e?.target.files[0];
                                        console.log("image", img?.name);
                                        // e.preventDefault();

                                        if (img == null) {
                                          //clear image
                                          setFieldValue("receipt", "", true);
                                          //for now  only one supported
                                        } else {
                                          console.log("upload", {
                                            img: img,
                                            uploadType: "receipt",
                                            isRes: true,
                                          });
                                          setImageLoader(true);
                                          dispatch(
                                            storeMedia(
                                              {
                                                img: img,
                                                uploadType: "receipt",
                                                isRes: false,
                                              },
                                              (res) => {
                                                setImageLoader(false);
                                                setStatus(true);

                                                // e.preventDefault();
                                                setDisplayName(img?.name);
                                                if (res?.url) {
                                                  console.log("opt", res);
                                                  setFieldValue(
                                                    "receipt",
                                                    res?.url,
                                                    true
                                                  );
                                                }
                                              }
                                            )
                                          );
                                          // setFieldValue("amount", values.amount, true);
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              </Button>
                            )}
                            {!status ? (
                              <p
                                className="heading3 Regular"
                                style={{ margin: "12px 0px 5px" }}
                              >
                                Upload
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div
                            style={{
                              // margin: "0px -16px 2px -614px",
                              color: "red",
                            }}
                          >
                            {errors.receipt && touched.receipt ? (
                              <div>*{errors.receipt}</div>
                            ) : null}
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div style={{ width: "60%" }}>
                      {invoiceCount == 0 ? (
                        <div>
                          <div className="FontNB semiBold">
                            No Invoice Available to be marked as paid
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="FontNB1 semiBold mb-2">
                            Select invoice for marking as cleared
                          </div>
                          <FormGroup className="">
                            <div className="FontNB semiBold">All Invoices</div>
                          </FormGroup>
                          <div className="">
                            {invoiceList?.map((item, i) => {
                              return (
                                <FormGroup
                                  key={offset2 + i + 1}
                                  style={{ marginBottom: 0 }}
                                  className="mbImportant"
                                >
                                  <div
                                    className="rowCont row"
                                    style={{
                                      cursor: "default",
                                    }}
                                  >
                                    <div class="div-table-col width70 FontNB col-6">
                                      {/* <div data-letters={firstName_initials.toUpperCase()}> */}
                                      <div
                                        data-letters-recharge={offset2 + i + 1}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          paddingBottom: 0,
                                          paddingTop: 0,
                                        }}
                                      >
                                        <span className=" FontNB">
                                          {item?.Invoice?.invoiceDate}
                                        </span>
                                      </div>
                                      <div class="vehSubInfo FontSmall">
                                        ₹{item?.amount}
                                      </div>
                                    </div>
                                    <Checkbox
                                      className="col-3"
                                      value={item?.invoiceId}
                                      checked={
                                        markedInvoices?.[item?.invoiceId]
                                          ?.amount != undefined
                                      }
                                      sx={{
                                        color: "#6c6c6c",
                                        "&.Mui-checked": {
                                          color: "#2E7C7B",
                                        },
                                      }}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          let temp = {
                                            ...markedInvoices,
                                          };
                                          temp[item?.invoiceId] = {
                                            ...item,
                                          };
                                          setMarkCleared(temp);
                                        } else {
                                          let temp = {
                                            ...markedInvoices,
                                          };
                                          temp[item?.invoiceId] = null;
                                          setMarkCleared(temp);
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="endingLine"></div>
                                </FormGroup>
                              );
                            })}
                          </div>
                          <div className="row padding8B">
                            <div className="col-8"></div>
                            <div
                              className="col-1 padding24L"
                              onClick={handlePrevButton}
                              style={{
                                opacity: offset2 > 0 ? "1" : "0.5",
                                cursor: "pointer",
                              }}
                            >
                              <SvgImage name="prevArrowSmall" />
                            </div>
                            <div
                              className="col-2"
                              onClick={handleNextButton}
                              style={{
                                opacity:
                                  invoiceCount - offset2 > 5 ? "1" : "0.5",
                                cursor: "pointer",
                              }}
                            >
                              <SvgImage name="nextArrowSmall" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <FormGroup
                    style={{
                      padding: "5% 0 0 0",
                      textAlign: "center",
                      // backgroundColor: "red",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{
                        width: "160px",
                        borderRadius: "5px",
                        // bottom: 20,
                        // position: "absolute",
                      }}
                      className="generateOtp textAddCust Medium btn btn-secondary"
                      // onClick={addFreight}
                    >
                      Next
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>

      {/* <InvoiceToast
        success={true}
        message={"Customer Created Successfully "}
        toggleModal={() => {
          setOpenToast(false);
        }}
        isOpen={openToast}
        name="driver"
        handleClose={() => {
          setOpenToast(false);
          //   window.location = "/Customers";
          navigate("/Customers/" + customer.customerId);
          dispatch(
            getLedgerListAction({
              customerId: customer.customerId,
              offset: offset,
              search: "",
              transferType: "",
              sortAs: "",
              sortBy: "",
            })
          );
        }}
      /> */}
      <ToastContainer
        theme="dark"
        position="bottom-right"
        closeOnClick
        pauseOnHover
        autoClose={4000}
      />
    </div>
  );
}

export default AddPayment;
