import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { useLocation, useParams } from "react-router-dom";
// import Toast from "../subComponents/toast";
import TripDetails from "../Trips/Components/TripDetails";
import TripList from "../Trips/Components/TripList";
import SvgImage from "../Icons/Icons";
import DetailsCard from "./Components/DetailsCard";
import ExpenseCard from "./Components/ExpenseCards";
import FeatureCard from "./Components/FeatureCard";
import { Table } from "reactstrap";
import AddNewTrip from "../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/RightBoxComponents/TripSubComponents/AddNewTrip";
import {
  cleanTransactionList,
  endTrip,
  fastagVehicleTransactionByFleet,
  getCashTransactions,
  getFreightDetails,
  getM2pCardTransactions,
  getTransactionsAndExpense,
  tripReport,
  getLRList,
  getTripLedger,
  getEachRoute,
  getTripExpenseAction,
  updateTripInLrAction,
} from "../../services/Store/Actions/TripsAction";
import { sendAnalitics } from "../../services/Store/Actions/appActions";
import ManageDriverAddBalance from "../Modals/ManageDriverAddBalance";
import RechargeFastagButttonModal from "../Modals/rechargeFastagButton";
import ManageDriverAddNewExpense from "../Modals/ManageDriverAddNewExpense";
import {
  cleanOmcTransactionsAction,
  omcVehicleBalanceAction,
  omcVehicleTransactionAction,
} from "../../services/Store/Actions/omcActions";
import {
  cleanExpenseSumm,
  cleanExpenseWalletSummary,
  expenseSumm,
  getExpenseWalletSummary,
} from "../../services/Store/Actions/driverslistActions";
import OMCLoadModal from "../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCLoadModal";
import Toast from "../subComponents/toast";
import { toastStatus } from "../../services/Store/Actions/appActions";
import moment from "moment";
import {
  getFastagTransactionHistory,
  getFastagWalletBalance,
  getVehicleDetails,
} from "../../services/Store/Actions/fastagAction";
import {
  getDataFromAsyncStorage,
  msToTime,
} from "../../services/Utils/functions";
import {
  clearCustomerRecievables,
  getCustomersByID,
} from "../../services/Store/Actions/receivableActions";
import FastagVehicleTransactionTrip from "./Components/TransactionHistory/FastagVehicleTransactionTrip";
import CardTransactionTrip from "./Components/TransactionHistory/CardTransactionTrip";
import FuelTransactionTrip from "./Components/TransactionHistory/FuelTransactionTrip";
import CashTransactionTrip from "./Components/TransactionHistory/CashTransactionTrip";
import AddFreight from "../TripsMainV2/Components/AddFreight";
import EditFreight from "../TripsMainV2/Components/EditFreight";
import EditTripV2 from "../TripsMainV2/Components/EditTripV2";
import { getCustomerAction } from "../../services/Store/Actions/customerAction";
import ViewFreight from "../TripsMainV2/Components/ViewFreight";
import EndTripModal from "./Components/EndTrip/index";
import ViewLr from "../TripsMainV2/Components/ViewLr";
import CreateLRModal from "../LoadReceipt/Components/CreateLRModal";
import OMCChangeOTPNum from "../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCChangeOTPNum";
import AddLr from "../TripsMainV2/Components/AddLr";
import ChooseMethodModal from "./Components/ChooseMethodModal";
import TripLedger from "./Components/TransactionHistory/TripLedger";
import PaymentDetails from "../ManageCustomersDetailsV2/SubComponents/PaymentDetails";
import SattlePaymentDetails from "../ManageCustomersDetailsV2/SubComponents/SattlePaymentDetails";
import { getWalletBalAction } from "../../services/Store/Actions/LogipeCardActions";
import { getVendorAction } from "../../services/Store/Actions/beneficiaryAction";
import DriverDashboardAddExpense from "../Modals/DriverDashboardAddExpense";
// import { expenseSumm } from "../../services/Utils/Apis";
function TripDetailsV2(props) {
  let { sideBar, item } = props;
  let { id } = useParams();
  let {
    tripsList,
    loader,
    fetchingTrips,
    tripsByUserId,
    tripsCount,
    transactionsAndExpenses,
    fastagVehicleTransaction,
    cashTransactions,
    tripExpense,
  } = useSelector((store) => store?.trip);

  console.log("dasfafafds", transactionsAndExpenses, cashTransactions);

  const [itemTemp, setItemTemp] = useState({});
  // console.log(
  //   "fastagVehicleTritemTempansaction",
  //   itemTemp
  // );
  const [fuelVendor, setFuelVendor] = useState({});
  const [fleetVendor, setFleetVendor] = useState({});
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [editFreight, setEditFreight] = useState(false);
  const [routeData, setRouteData] = useState({});
  const [paymentDetailsModal, setPaymentDetailsModal] = useState(false);
  const [sattlePaymentDetailsModal, setSattlePaymentDetailsModal] =
    useState(false);
  const { customer } = useSelector((state) => state?.customer);
  const [reqData, setReqData] = useState({});
  const omc_balace = useSelector((state) => state?.omc?.omc_balace);
  // const [totalExpense, setTotalExpense] = useState()
  let totalExpense;
  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips, setIsTrips] = useState(0);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  useEffect(() => {
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
  }, [authUser, managerControlList]);

  // useEffect(() => {
  //   dispatch(
  //     getLRList(
  //       {
  //         tripId: props?.item?.tripId,
  //       },
  //       (res) => {
  //         console.log("getLRListgetLRList", res);
  //       }
  //     )
  //   );
  // }, [props?.item?.tripId]);

  // const tripsdetails = useSelector((state)=>state?.trip)
  // console.log('ttrrr',tripsdetails?.freightDetails)
  // console.log("tripDtripDtripDataataata", tripData);
  const paymentDetailsModalToggle = () =>
    setPaymentDetailsModal(!paymentDetailsModal);
  const sattlePaymentDetailsModalToggle = () =>
    setSattlePaymentDetailsModal(!sattlePaymentDetailsModal);
  useEffect(() => {
    if (JSON.stringify(itemTemp) == "{}") {
      let tempo = sessionStorage.getItem("row");
      console.log("useEffect again3", JSON.parse(tempo));
      setReqData({
        fleetId: JSON.parse(tempo)?.fleetId,
        userId: JSON.parse(tempo)?.userId,
        startTime: JSON.parse(tempo)?.startTime,
        endTime:
          JSON.parse(tempo)?.isActive != 3
            ? new Date()
            : JSON.parse(tempo)?.endTime || "",
        // endTime: endTime || "",
        transType: "debit",
        limit: 2,
        cardUserId: JSON.parse(tempo)?.cardUserId,
        offset: 0,
      });
      setItemTemp(JSON.parse(tempo));
      dispatch(getTripLedger(JSON.parse(tempo)?.tripId, {}));
      dispatch(getEachRoute({ routeId: JSON.parse(tempo)?.routeId }));
      dispatch(getWalletBalAction(JSON.parse(tempo)?.userId));
      dispatch(getVehicleDetails(JSON.parse(tempo)?.fleetId));
      dispatch(sendAnalitics("Page_visit_TripDetails"));
    } else {
      dispatch(getWalletBalAction(itemTemp?.userId));
      dispatch(getVehicleDetails(itemTemp?.fleetId));
      dispatch(getTripLedger(itemTemp?.tripId, {}));
      dispatch(omcVehicleBalanceAction(itemTemp?.fleetId));
      dispatch(sendAnalitics("Page_visit_TripDetails"));
    }
  }, [itemTemp?.tripId,id]);

  useEffect(() => {
    dispatch(clearCustomerRecievables());
    if (
      itemTemp?.freightData?.[0]?.customerId !== undefined &&
      itemTemp?.freightData?.[0]?.customerId !== ""
    ) {
      dispatch(
        getCustomerAction({
          customerId: itemTemp?.freightData?.[0]?.customerId,
        })
      );
    }
  }, [itemTemp?.freightData?.[0]?.customerId, itemTemp?.tripId]);

  useEffect(() => {
    console.log("useEffect again", itemTemp?.tripNo);

    if (itemTemp?.tripId !== undefined && itemTemp?.tripId !== "") {
      dispatch(
        getLRList({ tripId: itemTemp?.tripId }, (res) => {
          console.log("rerere", res);
        })
      );
      dispatch(
        getTripExpenseAction({ tripId: itemTemp?.tripId }, (res) => {
          if (res?.status == 200) {
            console.log("useEffect again2", res?.data?.data?.trip);
            if (res?.data?.data?.trip) {
              setItemTemp(res?.data?.data?.trip);
            }
          }
        })
      );
      dispatch(getWalletBalAction(itemTemp?.userId));
      // dispatch(
      //   getCustomerRecievables({
      //     customerId: id,
      //     offset: 0,
      //   })
      // );
    }
  }, [itemTemp?.tripId,id]);
  const freightDetails = useSelector((state) => state?.trip?.freightList);
  console.log("fasdfhhe", freightDetails);

  const { customerById } = useSelector((state) => state?.receivable);
  console.log("adfaeqfqf", customer?.phone);

  console.log("itemTemp here", itemTemp);
  const dataVal = sessionStorage.getItem("_trips");
  const tripData = dataVal ? JSON.parse(dataVal) : null;
  const [tripId, setTripId] = useState("");
  const [offset, setOffset] = useState(tripData?.offset ? tripData?.offset : 0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [open5, setOpen5] = useState(false);
  const [tripLoader, setTripLoader] = useState(false);
  const dispatch = useDispatch();
  var date1 = new Date(itemTemp?.expectedStartTime);
  var date2 = new Date(itemTemp?.expectedEndTime);
  var diffDays = date2.getDate() - date1.getDate();
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open, setOpen] = useState(false);
  const [all, setAll] = useState(false);
  const [cash, setCash] = useState(false);
  const [fuel, setFuel] = useState(false);
  const [card, setCard] = useState(false);
  const [tripLedger, setTripLedger] = useState(true);
  const [fastag, setFastag] = useState(false);
  const [openFuel, setOpenFuel] = useState(false);
  const [openChooseMethod, setOpenChooseModal] = useState(false);
  const [openOMCPhone, setOpenOMCPhone] = useState(false);
  const [freight, setFreight] = useState(false);
  const [viewFreight, setViewFreight] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openEndTripModal, setOpenEndTripModal] = useState(false);
  const toggleEndTripModal = () => {
    if (["2", "3", 2, 3].includes(isTrips))
      setOpenEndTripModal(!openEndTripModal);
  };
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const balanceRecord = useSelector(
    (state) => state?.LogipeCard?.balanceRecord
  );
  const vehicleDetails = useSelector((state) => state?.fastag?.vehicleDetails);
  console.log("adasbalanceRecorddas", balanceRecord);
  const bal =
    !balanceRecord?.balance && balanceRecord?.balance === null
      ? "N/A"
      : balanceRecord?.balance;
  useEffect(() => {
    if (open4) {
      dispatch(getFastagWalletBalance());
    }
  }, [open4]);
  const { fastagWallet } = useSelector((state) => state?.fastag);
  const handleClose4 = () => {
    setOpen4(false);
  };
  const toggleModal4 = () => {
    setOpen4(!open4);
  };
  const toggleModal5 = () => {
    setOpen5(!open5);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };
  const toggleModal3 = () => {
    setOpen3(!open3);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const rechargeCard = () => {
    setOpen3(true);
  };
  const rechargeFastag = () => {
    setOpen4(true);
  };
  const addExpense = () => {
    setOpen(true);
  };
  const handleClose2 = () => {
    setOpen(false);
  };
  const toggleModal2 = () => {
    setOpen(!open);
  };
  const loadOmc = () => {
    setOpenFuel(true);
  };
  const handleCloseFuel = () => {
    setOpenFuel(false);
  };
  const toggleModalFuel = () => {
    setOpenFuel(!openFuel);
  };
  const chooseModal = () => {
    setOpenChooseModal(true);
  };
  const handleChooseModal = () => {
    setOpenChooseModal(false);
  };
  const toggleChooseModal = () => {
    setOpenChooseModal(!openChooseMethod);
  };
  const mapOmcPhone = () => {
    setOpenOMCPhone(true);
  };
  const handleCloseOmcPhone = () => {
    setOpenOMCPhone(false);
  };
  const toggleModalOmcPhone = () => {
    setOpenOMCPhone(!openOMCPhone);
  };
  const addFreight = () => {
    if (["2", "3", 2, 3].includes(isTrips)) setFreight(!freight);
  };
  const viewFreightfunc = () => {
    if (["1", "2", "3", 1, 2, 3].includes(isTrips))
      setViewFreight(!viewFreight);
  };
  const filterList = (k) => {
    if (k === "all") {
      setAll(true);
      setCard(false);
      setCash(false);
      setFastag(false);
      setFuel(false);
      setTripLedger(false);
    } else if (k === "card") {
      setCard(true);
      setAll(false);
      setCash(false);
      setFastag(false);
      setFuel(false);
      setTripLedger(false);
      // cardTransactionsData();
    } else if (k === "cash") {
      setCash(true);
      setAll(false);
      setCard(false);
      setFastag(false);
      setFuel(false);
      setTripLedger(false);
      // cashTransactionFunc()
    } else if (k === "fastag") {
      setFastag(true);
      setAll(false);
      setCard(false);
      setCash(false);
      setFuel(false);
      setTripLedger(false);
      // fastagVehicleTransactionFunc()
    } else if (k === "fuel") {
      setFuel(true);
      setAll(false);
      setCard(false);
      setCash(false);
      setFastag(false);
      setTripLedger(false);
      // omcTransactionData();
    } else if (k === "ledger") {
      setFuel(false);
      setAll(false);
      setCard(false);
      setCash(false);
      setFastag(false);
      setTripLedger(true);
      // omcTransactionData();
    }
  };

  const { fastagTransactionHistory } = useSelector((state) => state?.fastag);
  const expenseSummDetail = useSelector((state) => state?.driverlist);

  console.log("expenseSummDetail?.allexpense", expenseSummDetail?.allexpense);

  console.log("ddd", fastagTransactionHistory);
  const omcTransaction = useSelector((state) => state?.omc?.omcTransaction);
  const cardExpense = useSelector((state) => state?.driverlist);
  const newExpensesFetched = fuel
    ? omcTransaction?.rows
    : card
    ? cardExpense?.expenseWalletSummary?.rows
    : fastag
    ? fastagTransactionHistory?.rows
    : cash
    ? expenseSummDetail?.allexpense?.rows
    : null;

  console.log("fasdafa", newExpensesFetched);
  const fastagWalletAmount =
    fastagWallet.balance == null ? 0 : fastagWallet.balance;
  console.log("ITEMS", itemTemp);

  console.log("sanjkfa", transactionsAndExpenses);
  useEffect(() => {
    const dataVal = sessionStorage.getItem("_trips");
    const tripData = dataVal ? JSON.parse(dataVal) : null;
    console.log("stores session", tripData);
    setOffset(tripData?.offset ? tripData?.offset : 0);
    for (let index = 0; index < itemTemp?.freightData?.length; index++) {
      setTotalEarnings(
        totalEarnings + itemTemp?.freightData?.[index]?.freightAmount
      );
    }
  }, []);
  let Earnings = 0;
  for (let index = 0; index < freightDetails?.length; index++) {
    Earnings = Earnings + freightDetails?.[index]?.freightAmount;
  }
  let Shortage = 0;
  for (let index = 0; index < freightDetails?.length; index++) {
    Shortage = Shortage + (freightDetails?.[index]?.shortage || 0);
  }
  let Penalties = 0;
  for (let index = 0; index < freightDetails?.length; index++) {
    Penalties = Penalties + freightDetails?.[index]?.charges;
  }

  totalExpense =
    parseInt(tripExpense?.totalCardExpense || 0) +
    parseInt(tripExpense?.totalCashExpense || 0) +
    parseInt(tripExpense?.totalDriverExpense || 0) +
    parseInt(tripExpense?.totalFastagExpense || 0) +
    parseInt(tripExpense?.totalFuelExpense || 0) +
    parseInt(tripExpense?.totalVendorExpense || 0);

  let totalExpenseExpacted = 0;
  totalExpenseExpacted +=
    parseInt(tripExpense?.driverTotal || 0) +
    parseInt(tripExpense?.fastagTotal || 0) +
    parseInt(tripExpense?.fuelTotal || 0) +
    parseInt(tripExpense?.cashTotal || 0) +
    parseInt(tripExpense?.vendorTotal || 0);
  const editFreightDetails = () => {
    setEditFreight(true);
  };

  const toggleModalEditFreight = () => {
    setEditFreight(!editFreight);
  };
  const onHide = () => {
    setIsOpen(false);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    console.log("useEffectfreight", itemTemp?.tripId);
    if (freight) {
    } else {
      if (itemTemp?.tripId) {
        dispatch(
          getLRList(
            {
              offset: 0,
              limit: 10,
              tripId: itemTemp?.tripId,
            },
            (res) => {
              console.log("getLRListgetLRList", res);
            }
          )
        );
      }
    }
  }, [freight]);
  useEffect(() => {
    console.log("tripExpense?.fuelVendorId", tripExpense);
    if (tripExpense?.fuelVendorId) {
      dispatch(
        getVendorAction(
          {
            limit: 1,
            offset: 0,
            isLogipeVendor:0,
            vendorId: tripExpense?.fuelVendorId,
          },
          (res) => {
            if (res?.status == 200) {
              console.log("getVendorAction", res?.data?.data);
              setFuelVendor(res?.data?.data?.vendorList?.rows?.[0]);
            }
          }
        )
      );
    }
  }, [tripExpense]);
  useEffect(() => {
    console.log("asdaeewerdsfadfaz", itemTemp);
    if (itemTemp?.fleet?.vendorId) {
      dispatch(
        getVendorAction(
          {
            limit: 1,
            offset: 0,
            isLogipeVendor:0,
            vendorId: itemTemp?.fleet?.vendorId,
          },
          (res) => {
            if (res?.status == 200) {
              console.log("getVendorAction", res?.data?.data);
              setFleetVendor(res?.data?.data?.vendorList?.rows?.[0]);
            }
          }
        )
      );
    }
  }, [itemTemp?.tripId]);
  return (
    <div
      className="container topMargin sideBarMargin"
      style={{
        marginLeft: sideBar ? "220px" : "100px",
        maxWidth: sideBar ? windowWidth - 240 : windowWidth - 130,
      }}
    >
      <div className="main1" style={{ backgroundColor: "white" }}>
        <div className="col-lg-12 con1">
          <div className="vrnRow">
            <div>
              <div className="headingLow semiBold">
                {" "}
                {itemTemp?.Fleet?.regNumber}
              </div>
              <div className="heading2 Bold" style={{ marginTop: "8px" }}>
                Trip No. - {itemTemp?.tripNo}
              </div>
            </div>

            <button
              className="btn-edit-details"
              style={{ marginLeft: "20px" }}
              onClick={() => {
                if (["2", "3", 2, 3].includes(isTrips)) setOpen5(true);
              }}
            >
              Edit Details
            </button>
            {itemTemp?.isActive == 2 ? (
              <button
                className="btn-edit-details"
                style={{ marginLeft: "20px" }}
                onClick={toggleEndTripModal}
              >
                <text style={{ color: "white" }}>End Trip</text>
              </button>
            ) : null}
            <button
              className="btn-edit-details"
              style={{ marginLeft: "20px" }}
              onClick={viewFreightfunc}
            >
              View Freights
            </button>
            <button
              className="btn-edit-details"
              style={{ marginLeft: "20px" }}
              onClick={addFreight}
            >
              Add Freight
            </button>
            {itemTemp?.isActive != 1 ? (
              <button
                className="btn-edit-details"
                style={{ marginLeft: "20px", width: "150px" }}
                onClick={() => {
                  if (["1", "2", "3", 1, 2, 3].includes(isTrips))
                    setSattlePaymentDetailsModal(true);
                }}
              >
                Settle Payments
              </button>
            ) : null}
          </div>
          <div className="vrnRowRight">
            <button
              className="btn-edit-details"
              style={{
                marginLeft: "20px",
                borderRadius: "15px",
                background: "#09BD7B",
              }}
            >
              {itemTemp?.isActive == 2
                ? "Running"
                : itemTemp?.isActive == 1
                ? "Not Started"
                : "Completed"}
            </button>
          </div>
        </div>

        <div className="detailsCard ">
          <DetailsCard
            key1={"From"}
            key2={"To"}
            key3={"Driver"}
            key4={"Vehicle"}
            key5={"Start Date"}
            key6={"End Date"}
            value1={
              itemTemp?.sourceAddress
                ? itemTemp?.sourceAddress?.cityName +
                  ", " +
                  itemTemp?.sourceAddress?.stateName
                : itemTemp?.source
            }
            value2={
              itemTemp?.destAddress
                ? itemTemp?.destAddress?.cityName +
                  ", " +
                  itemTemp?.destAddress?.stateName
                : itemTemp?.destination
            }
            value3={itemTemp?.User?.fullName || "-"}
            value4={
              itemTemp?.Fleet?.regNumber != undefined
                ? itemTemp?.Fleet?.regNumber
                : "-"
            }
            value5={moment(itemTemp?.expectedStartTime).format(
              "DD-MM-YYYY HH:mm"
            )}
            value6={
              itemTemp?.expectedEndTime == null
                ? "-"
                : moment(itemTemp?.expectedEndTime).format("DD-MM-YYYY HH:mm")
            }
          />
          <DetailsCard
            key1={"Freights"}
            key2={"Freight Amount"}
            key3={"Pending PODs"}
            key4={"Start Odometer"}
            key5={"End Odometer"}
            key6={"Earning/KM"}
            value1={
              freightDetails.length
            }
            value2={
              Earnings != undefined
                ? "₹ " + Earnings?.toLocaleString('en-IN') || "₹ 0"
                : "-"
            }
            value3={freightDetails.filter(x=> !x.podUrl).length||0}
            value4={
              itemTemp?.startKmReading || "NA"
            }
            value5={
              itemTemp?.endKmReading || "NA"
            }
            value6={
              itemTemp?.expectedDistanceKM
                ? "₹ " + (
                    parseInt(Earnings || 0) /
                    parseInt(itemTemp?.expectedDistanceKM || 1)
                  )?.toLocaleString('en-IN') || "-"
                : "-"
            }
          />
          <DetailsCard
            key1={"Total Expense"}
            key2={"Driver"}
            key3={"Fuel"}
            key4={"Fastag"}
            key5={"Others"}
            key6={"Expense/KM"}
            value1={"₹ " + (totalExpense?.toLocaleString('en-IN') || "0")}
            value2={"₹ " + (tripExpense?.totalCardExpense?.toLocaleString('en-IN') || "0")}
            value3={"₹ " + (tripExpense?.totalFuelExpense?.toLocaleString('en-IN') || "0")}
            value4={"₹ " + (tripExpense?.totalFastagExpense?.toLocaleString('en-IN') || "0")}
            value5={"₹ " + (tripExpense?.totalCashExpense?.toLocaleString('en-IN') || "0")}
            value6={"₹ " + itemTemp?.expectedDistanceKM
            ? (
                parseInt(totalExpense || 0) /
                parseInt(itemTemp?.expectedDistanceKM || 1)
              ).toFixed(2) || "-"
            : "-" || "0"}
          />
        </div>
        {itemTemp?.idleReason ? (
          <div
            style={{
              marginTop: "24px",
              fontSize: "14px",
              backgroundColor: "#FFFACD",
              color: "#FF8C00",
              borderWidth: "1px",
              borderColor: "#FF8C00",
              borderStyle: "solid",
              borderRadius: "12px",
              padding: "12px",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Idle Reason : {itemTemp?.idleReason}
          </div>
        ) : null}

        <div className="expenseCards">
          {/* <div>Expected</div> */}
          <ExpenseCard
            svgName={"money"}
            name={"Net Earnings"}
            data={"₹ " + Earnings?.toLocaleString('en-IN')}
          />
          <ExpenseCard
            svgName={"distanceMoney"}
            name={"Net Earning/KM"}
            data={
              itemTemp?.expectedDistanceKM
                ? "₹ " + (
                    parseInt(Earnings || 0) /
                    parseInt(itemTemp?.expectedDistanceKM || 1)
                  )?.toLocaleString('en-IN') || "-"
                : "-"
            }
          />
          
          <ExpenseCard
            svgName={"distance"}
            name={"Total Distance"}
            data={parseInt(itemTemp?.expectedDistanceKM || 0) || "-"}
          />
          <ExpenseCard
            svgName={"time"}
            name={"Duration"}
            // data={itemTemp?.endTime
            // ? diffDays * 24 + " Hrs."
            // : itemTemp?.startTime + " Hrs."}
            data={
              itemTemp?.expectedEndTime
                ? msToTime(
                    new Date(itemTemp?.expectedEndTime) -
                      new Date(itemTemp?.expectedStartTime)
                  ) + " Hrs."
                : msToTime(new Date() - new Date(itemTemp?.expectedStartTime)) +
                  " Hrs."
            }
          />
          <div
          onClick={addExpense}
          >
          <ExpenseCard
            name={`Add Cash Expense`}
            textColor={{color:"#E57231",textAlign:"center",width:"100px"}}
          />
          </div>
          <div
          onClick={loadOmc}
          >
          <ExpenseCard
            name={"Recharge Fuel Wallet"}
            textColor={{color:"#E57231",textAlign:"center",width:"150px"}}
          />
          </div>
          <div
          onClick={rechargeCard}
          >
          <ExpenseCard
            name={"Pay Driver"}
            textColor={{color:"#E57231",textAlign:"center",justifyContent:"center"}}
          />
          </div>
        </div>

        {itemTemp?.isActive == 1 ? (
          <div
            style={{
              marginTop: "24px",
            }}
          >
            <div className="padding32 textCenter">
              <SvgImage name="TripsMap" />
              <div className="Bold heading1 padding24T padding16B FontSuperBold primaryBlack">
                Start Trip
              </div>
              <div
                style={{
                  marginBottom: "16px",
                }}
                className="greyFont heading3"
              >
                Start to manage expense easily
              </div>
              <button
                className={"Medium heading3 greenButton"}
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                onClick={() => {
                  setPaymentDetailsModal(true);
                }}
              >
                Start Trip Now
              </button>
            </div>
          </div>
        ) : (
          <div>
            {/* <div
              className="d-flex align-items-center"
              style={{ marginTop: "24px" }}
            >
              <div>{itemTemp?.isActive == 3 ? "Total" : "Till Now"}</div>
              <div style={{ flex: 1, marginLeft: "24px" }}>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginBottom: "24px", gap: "20px" }}
                >
                  <ExpenseCard
                    svgName={"expense"}
                    name={"Total Expense"}
                    data={"₹ " + (totalExpense || "0")}
                  />
                  <ExpenseCard
                    svgName={"time"}
                    name={"Start Time"}
                    data={moment(itemTemp?.startTime).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                  />
                  <ExpenseCard
                    svgName={"time"}
                    name={"Duration"}
                    data={
                      itemTemp?.endTime
                        ? msToTime(
                            new Date(itemTemp?.endTime) -
                              new Date(itemTemp?.startTime)
                          ) + " Hrs."
                        : msToTime(new Date() - new Date(itemTemp?.startTime)) +
                          " Hrs."
                    }
                  />
                  <ExpenseCard
                    svgName={"distance"}
                    name={"Start KM Reading"}
                    data={itemTemp?.startKmReading}
                  />
                  <ExpenseCard
                    svgName={"time"}
                    name={"End Time"}
                    data={
                      itemTemp?.endTime
                        ? moment(itemTemp?.endTime).format("DD-MM-YYYY HH:mm")
                        : "Trip Running"
                    }
                  />
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ gap: "20px" }}
                >
                  <ExpenseCard
                    svgName={"distance"}
                    name={"End KM Reading"}
                    data={itemTemp?.endKmReading || "Trip Running"}
                  />
                  <ExpenseCard
                    svgName={"distance"}
                    name={"Total Distance"}
                    data={
                      itemTemp?.startKmReading && itemTemp?.endKmReading
                        ? itemTemp?.endKmReading - itemTemp?.startKmReading
                        : "Trip Running"
                    }
                  />

                  <ExpenseCard
                    svgName={"expense"}
                    name={"Shortage"}
                    data={"₹ " + (Shortage || "0")}
                  />
                  <ExpenseCard
                    svgName={"expense"}
                    name={"Charges"}
                    data={"₹ " + (Penalties || "0")}
                  />
                  <ExpenseCard
                    svgName={"money"}
                    name={"Incentive"}
                    data={"₹ " + (tripExpense?.totalIncentive || "0")}
                  />
                </div>
              </div>
            </div>
            <div className="featureCards">
              <FeatureCard
                item={itemTemp}
                svgName={"ccard"}
                title={"Card"}
                expenseExpacted={tripExpense?.driverTotal || 0}
                balance={bal === null ? " 0" : bal}
                expense={tripExpense?.totalCardExpense || 0}
                action={rechargeCard}
              />
              <FeatureCard
                item={itemTemp}
                svgName={"toll"}
                title={"Fastag"}
                balance={vehicleDetails?.Response?.availableBal || "0"}
                expense={tripExpense?.totalFastagExpense || "0"}
                expenseExpacted={tripExpense?.fastagTotal || "0"}
                action={rechargeFastag}
              />
              <FeatureCard
                item={itemTemp}
                svgName={"cash1"}
                title={"Cash"}
                balance={tripExpense?.totalCashExpense || "0"}
                expense={tripExpense?.cashTotal || "0"}
                expenseExpacted={tripExpense?.cashTotal || "0"}
                action={addExpense}
                expenseTitle={"Expected"}
                balanceTitle={"Total Given"}
              />
              <FeatureCard
                item={itemTemp}
                svgName={"ccard"}
                title={"Fuel"}
                balance={omc_balace?.balance || "0"}
                expense={tripExpense?.totalFuelExpense || "0"}
                expenseExpacted={tripExpense?.fuelTotal || "0"}
                action={loadOmc}
              />
              <FeatureCard
                item={itemTemp}
                svgName={"cash1"}
                title={"Driver Salary"}
                balance={balanceRecord?.totalSalaryGiven || "0"}
                expense={balanceRecord?.salary || "0"}
                action={chooseModal}
                expenseTitle={"Salary"}
                balanceTitle={"Total Given"}
              />
            </div> */}

            <div className="transactions">
              <div className="heading2 Bold">Transactions</div>
              <div className="d-flex justify-content-between">
                <div className="txnCategory">
                  {/* <button onClick={() => filterList('all')} className={all ? "btnCatSelected heading4 Medium" : 'btnCat heading4 Medium'}>All</button> */}
                  <button
                    onClick={() => filterList("ledger")}
                    className={
                      tripLedger
                        ? "btnCatSelected heading4 Medium"
                        : "btnCat heading4 Medium"
                    }
                  >
                    Ledger
                  </button>
                  <button
                    onClick={() => filterList("card")}
                    className={
                      card
                        ? "btnCatSelected heading4 Medium"
                        : "btnCat heading4 Medium"
                    }
                  >
                    Card
                  </button>
                  <button
                    onClick={() => filterList("fastag")}
                    className={
                      fastag
                        ? "btnCatSelected heading4 Medium"
                        : "btnCat heading4 Medium"
                    }
                  >
                    FasTag
                  </button>
                  <button
                    onClick={() => filterList("cash")}
                    className={
                      cash
                        ? "btnCatSelected heading4 Medium"
                        : "btnCat heading4 Medium"
                    }
                  >
                    Cash
                  </button>
                  <button
                    onClick={() => filterList("fuel")}
                    className={
                      fuel
                        ? "btnCatSelected heading4 Medium"
                        : "btnCat heading4 Medium"
                    }
                  >
                    Fuel
                  </button>
                </div>
                <div
                  className="heading3 semiBold"
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={() => {
                    console.log("clicked");
                    setTripLoader(true);
                    dispatch(
                      tripReport({ tripId: itemTemp?.tripId }, (res) => {
                        console.log("OSKSS", res);
                        if (res?.data?.type == "success") {
                          setTripLoader(false);

                          console.log("OPEININGDPF", res);
                          window.open(res?.data?.data?.url);
                        }
                      })
                    );
                  }}
                >
                  <SvgImage name="downloadImgGreen" />
                  <span style={{ marginLeft: "10px" }}>Download Report</span>
                </div>
              </div>

              {fastag ? (
                <FastagVehicleTransactionTrip itemTemp={itemTemp} />
              ) : card ? (
                <CardTransactionTrip itemTemp={itemTemp} />
              ) : fuel ? (
                <FuelTransactionTrip itemTemp={itemTemp} />
              ) : cash ? (
                <CashTransactionTrip itemTemp={itemTemp} />
              ) : tripLedger ? (
                <TripLedger itemTemp={itemTemp} />
              ) : (
                ""
              )}
              {/* <Table style={{marginTop:'12px'}}>
                    <thead>
                      <tr className="table-header-customer-details">
                        <th>Date & Time</th>
                        <th>TxnRefNo</th>
                        <th>Description</th>
                        <th>TransferType</th>
                        <th>Amount</th>
                        <th>Reciept</th>
                      </tr>
                    </thead>
                    <tbody>
                        {newExpensesFetched && newExpensesFetched.map((entry, k)=>{
                            return(<tr>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">{entry.transactionDate ?entry.transactionDate : entry.paymentDate}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">{entry.txnRefNo}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">{entry.description}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">{entry.mcc ? entry.mcc : entry?.transferType|| "N/A"}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">₹ {entry.amount}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">N/A</p>
                                  </div>
                              </td>
                              </tr>)
                            
                          })                       
                      }
                      
                    </tbody>
                    </Table> */}
            </div>
          </div>
        )}
      </div>
      {/* <ManageDriverAddNewExpense
        userId={itemTemp?.userId}
        openChooseMethod={openChooseMethod}
        fleetId={itemTemp?.fleetId}
        // expenseType={"Salary"}
        remarks={`Cash Expanse for Trip ${itemTemp?.tripNo}`}
        setDisplayName={"Add Expense"}
        displayName={"Add Expense"}
        open={open}
        toggleModal={toggleModal2}
        title="Logipe Account"
        handleClose={handleClose2}
        trip={true}
        itemTemp={itemTemp}
        color={
          "LogipeAccount" === "Card Wallet" ? "title-color1" : "title-color2"
        }
      /> */}
            <DriverDashboardAddExpense
        open={open}
        toggleModal={toggleModal2}
        handleClose={handleClose2}
        name="Add Balance"
        // userId={itemTemp?.userId}
        driverBal={"SCDa"}
        cardType={1}
        driverName={props.driverName}
        accountStatus={props.accountStatus}
        modalName="Trip Number"
        itemTemp={itemTemp}
        value={itemTemp?.tripNo}
        transferType="2"
        expType = {"trip"}
      />
      {/* <AddNewTrip
              open={open5}
              toggleModal={toggleModal5}
              handleClose={handleClose5}
              itemTemp={itemTemp}
              type={"1"}
            /> */}
      <EditTripV2
        toggle={toggleModal5}
        modal={open5}
        setModal={setOpen5}
        tripId={itemTemp?.tripId}
        tripData={itemTemp}
        tripExpense={tripExpense}
        setItemTemp={setItemTemp}
      />

      <ManageDriverAddBalance
        open={open3}
        toggleModal={toggleModal3}
        remarks={`Payment for Trip ${itemTemp?.tripNo}`}
        title="Logipe Account"
        handleClose={handleClose3}
        color={
          "LogipeAccount" === "Card Wallet" ? "title-color1" : "title-color2"
        }
        name="Add Balance"
        userId={itemTemp?.userId}
        cardType={itemTemp?.cardType}
        trip={true}
        driverName={itemTemp?.User?.fullName}
        itemTemp={itemTemp}
      />
      <RechargeFastagButttonModal
        open={open4}
        handleClose={handleClose4}
        toggleModal={toggleModal4}
        remarks={`Recharge for Trip ${itemTemp?.tripNo}`}
        balance={fastagWalletAmount}
        no={itemTemp?.Fleet?.regNumber}
        fleetId={itemTemp?.fleetId}
        trip={true}
        itemTemp={itemTemp}
      />
      <OMCLoadModal
        userId={itemTemp?.userId}
        open={openFuel}
        toggleModal={toggleModalFuel}
        handleClose={handleCloseFuel}
        fleetId = {itemTemp?.fleetId}
        trip = {1}
      />
      <ChooseMethodModal
        tripData={itemTemp}
        open={openChooseMethod}
        toggleModal={toggleChooseModal}
        handleClose={handleChooseModal}
        handleCashPayment={addExpense}
        handleCardPayment={rechargeCard}
      />
      <OMCChangeOTPNum
        open={openOMCPhone}
        toggleModal={toggleModalOmcPhone}
        handleClose={handleCloseOmcPhone}
      />
      <EditFreight
        toggle={toggleModalEditFreight}
        freight={editFreight}
        tripId={itemTemp?.tripId}
        page={0}

        // setFreight={setFreight}
      />
      {viewFreight ? (
        <ViewLr
          toggle={viewFreightfunc}
          freight={viewFreight}
          setFreight={setViewFreight}
          tripId={itemTemp?.tripId}
          location={"/trips/" + id}
          // onSubmit={(lrList) => {
          //   // chooseRouteModalToggle();
          //   // toggleOpenLrList();
          //   // setSelectedLr(lrList);
          //   dispatch(
          //     updateTripInLrAction(
          //       {
          //         selectedLr: lrList,
          //         status: 0,
          //         tripId: itemTemp?.tripId,
          //       },
          //       (res) => {
          //         if (res?.status == 200) {
          //           dispatch(
          //             toastStatus({
          //               toastStatus: true,
          //               message: `Lorrie Receit Removed Successfully`,
          //               status: true,
          //             })
          //           );
          //         } else {
          //           dispatch(
          //             toastStatus({
          //               toastStatus: true,
          //               message:
          //                 res?.data?.message ||
          //                 `Error in Removing Lorrie Receit`,
          //               status: false,
          //             })
          //           );
          //         }
          //       }
          //     )
          //   );
          // }}
          // setFreight={setFreight}
        />
      ) : null}

      {/* <AddLr
        toggle={viewFreightfunc}
        freight={viewFreight}
        tripId={itemTemp?.tripId}
        location={"/trips/" + id}
        // setFreight={setFreight}
      /> */}
      {freight ? (
        <AddLr
          toggle={addFreight}
          setFreight={setFreight}
          freight={freight}
          tripId={itemTemp?.tripId}
          location={"/trips/" + id}
          fleetId={itemTemp?.fleetId}
          onSkip={() => {
            // setChooseRouteModal(true);
            // toggleOpenLrList();
          }}
          tripData={itemTemp}
          // setFreight={setFreight}
        />
      ) : null}

      {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
          }}
          isOpen={toastObject?.toastStatus}
          name="trips"
          handleClose={() => {
            window.location = "/trips/" + id;
            //alert("close button clicked");
          }}
        />
      ) : (
        <></>
      )}
      {/* <EndTripModal
        isOpen={isOpen}
        onHide={onHide}
        toggleModal={toggleModal}
        handleClose={handleClose}
        onPress={() => {
          const data = {
            tripId: props?.item?.tripId,
            userId: props?.item?.userId,
            cardExpanse: transactionsAndExpenses?.expense || "0.00",
            fastagExpanse: fastagVehicleTransaction?.expense || "0.00",
            cashExpanse: cashTransactions?.sum || "0.00",
            endKmReading: props?.item?.endKmReading,
          };
          console.log("theEndIsHere");
          dispatch(
            endTrip(data, (res) => {
              console.log("theEndIsHere2", res);

              if (res?.data?.type == "success") {
                console.log("theEndIsHere3");
                // dispatch(getTrips({ search: "", limit: 3, offset: 0 }));
                window.location = "/trips/";
                toggleModal(!open);
              }
            })
          );
        }}
      /> */}
      <EndTripModal
        toggle={toggleEndTripModal}
        modal={openEndTripModal}
        setModal={setOpenEndTripModal}
        location={"/trips/"}
        tripId={props?.item?.tripId}
        tripData={itemTemp}
        setItemTemp={setItemTemp}
        sattlePayment={sattlePaymentDetailsModalToggle}
      />
      {paymentDetailsModal ? (
        <PaymentDetails
          toggle={paymentDetailsModalToggle}
          tripData={itemTemp}
          modal={paymentDetailsModal}
          setModal={setPaymentDetailsModal}
          tripExpense={tripExpense}
          setRouteData={setRouteData}
          startTrip={true}
          setItemTemp={setItemTemp}
          // location={"/lr/"}
        />
      ) : null}
      {sattlePaymentDetailsModal ? (
        <SattlePaymentDetails
          toggle={sattlePaymentDetailsModalToggle}
          tripData={itemTemp}
          modal={sattlePaymentDetailsModal}
          setModal={setSattlePaymentDetailsModal}
          tripExpense={tripExpense}
          setItemTemp={setItemTemp}
          fuelVandor={fuelVendor}
          fleetVendor={fleetVendor}
          // location={"/lr/"}
        />
      ) : null}
    </div>
  );
}
export default TripDetailsV2;
