import {
  GET_CARD_DETAILS,
  GET_EXPENSE_BALANCE,
  MASTERWALLET_TRANSACTION_HISTORY,
  WALLET_SUMMARY,
  WALLET_ADD_MONEY,
  WALLET_WITHDRAW_MONEY,
  CHANGE_CARD_STATUS,
  GET_BALANCE_DETAILS,
  NEW_CARD_BAL,
} from "../storeTypes";
import {
  getCardDetailsSuccess,
  getBalanceDetailsSuccess,
  changeCardStatusSuccess,
  getExpenseBalanceSuccess,
  transactionHistorySuccess,
  getWalletSummarySuccess,
  walletAddMoneySuccess,
  walletWithdrawMoneySuccess,
  getWalletBalSuccessAction,
} from "../Actions/LogipeCardActions";
import { call, put, takeEvery } from "redux-saga/effects";
import { processErrorAndRespond } from "../../Utils/functions";
import {
  getCardDetailsApi,
  getExpenseDetailsApi,
  transactionHistoryApi,
  ChangeCardStatusAPI,
  getWalletSummaryAPI,
  walletAddMoneyApi,
  walletWithdrawMoneyApi,
  getBalanceDetailsApi,
  getWalletBalApi,
} from "../../Utils/Apis";

function* getBalanceDetailsSaga(action) {
  try {
    console.log("Action");
    const response = yield call(getBalanceDetailsApi, action.payload);
    console.log("balDetails", response);
    if (response.data.type === "success") {
      yield put(getBalanceDetailsSuccess(response.data.data));
    }
  } catch (error) {
    console.log("GET BALANCE DETAILS ERROR", error);
  }
}
function* ChangeCardStatusSaga(action) {
  try {
    const response = yield call(ChangeCardStatusAPI, action.payload);
    //console.log("RESPONSE >>>",response)
    if (response?.data?.type == "success") {
      action.callBack(response?.data);
      yield put(changeCardStatusSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("ChangeCardStatus errpr:- ", error?.response?.data);
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* walletAddMoneySaga(action) {
  try {
    const response = yield call(
      walletAddMoneyApi,
      action.userId,
      action.payload
    );
    // console.log('walletAddMoneySaga ', response.data);
    if (response?.data?.type == "success") {
      action.callBack(response);
      yield put(walletAddMoneySuccess());
    }
  } catch (error) {
    console.log("Error.Response:- ", error?.response?.data);
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data?.error);
    }
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* walletWithdrawMoneySaga(action) {
  try {
    const response = yield call(
      walletWithdrawMoneyApi,
      action.userId,
      action.payload
    );
    console.log("walletWithdrawMoneySaga ", response.data);
    if (response?.data?.type == "success") {
      yield put(walletWithdrawMoneySuccess());
      action.callBack(response);
    }
  } catch (error) {
    console.log("Error.Response:- ", error?.response?.data);
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data?.error);
    }
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getWalletSummarySaga(action) {
  //  console.log(action)
  try {
    console.log("Action walletSummary from saga", action);
    const response = yield call(
      getWalletSummaryAPI,
      action.userId,
      action.payload
    );
    // console.log("WALLETSUMMARY",response)
    if (response?.status == "200") {
      //    console.log("IN")
      yield put(getWalletSummarySuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error?.response?.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* transactionHistorySaga(action) {
  try {
    const response = yield call(transactionHistoryApi, action.payload);
    if (response?.data?.type == "success") {
      yield put(transactionHistorySuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error?.response?.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getExpenseDetailsSaga(action) {
  try {
    //     console.log("<><><><>",action)

    const response = yield call(getExpenseDetailsApi, action.payload);
    if (response.data.type == "success") {
      yield put(getExpenseBalanceSuccess());
    }
  } catch (error) {
    console.log("getExpenseDetails", error);
  }
}
function* getCardDetailsSaga(action) {
  console.log(action);
  try {
    const response = yield call(getCardDetailsApi, action.payload);
    console.log("getCardDetails", response);
    if (response.data.type === "success") {
      console.log("INDIA");
      yield put(getCardDetailsSuccess(response.data.data.details));
    }
  } catch (error) {
    console.log("Error Get CArdDetailsAPI", error);
  }
}

function* getWalletBalSaga(action) {
  try {
    const response = yield call(getWalletBalApi, action.payload);
    if (response?.data?.type == "success") {
      yield put(getWalletBalSuccessAction(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error?.response?.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

export function* watchManageLogipeCardSage() {
  // yield takeEvery(GET_CARD_DETAILS,getCardDetailsSaga)
  yield takeEvery(GET_EXPENSE_BALANCE, getExpenseDetailsSaga);
  yield takeEvery(MASTERWALLET_TRANSACTION_HISTORY, transactionHistorySaga);
  yield takeEvery(WALLET_SUMMARY, getWalletSummarySaga);
  yield takeEvery(WALLET_ADD_MONEY, walletAddMoneySaga);
  yield takeEvery(WALLET_WITHDRAW_MONEY, walletWithdrawMoneySaga);
  yield takeEvery(CHANGE_CARD_STATUS, ChangeCardStatusSaga);
  yield takeEvery(GET_BALANCE_DETAILS, getBalanceDetailsSaga);
  yield takeEvery(NEW_CARD_BAL, getWalletBalSaga);
}
