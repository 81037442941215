import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import PinInput from "react-pin-input";
import { Formik } from "formik";
import SvgImage from "../../Icons/Icons";
import { transferWalletTransferAmount } from "../../../services/Store/Actions/cardWalletAction";
import { transferFastagTransferAmount } from "../../../services/Store/Actions/fastagAction";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import * as Yup from "yup";
import { redeemCashbackAction } from "../../../services/Store/Actions/cashbackAction";
import { transferToCard } from "../../../services/Store/Actions/manageCardActions";
import {
  getBalanceDetails,
  getWalletBalAction,
} from "../../../services/Store/Actions/LogipeCardActions";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";

const validationSchema = Yup.object().shape({
  amount: Yup.number().typeError("Only Numbers are allowed")
    .required("Required")
    .min(1, "Amount should be greater than 1"),
  pin: Yup.string()
    .required("Required")
    .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
});

const OwnerCardModal = (props) => {
  const {
    open,
    toggleModal,
    handleClose,
    color,
    cashback,
    userId,
    refreshCashback,
    remainingBal,
  } = props;
  const { loader } = useSelector((state) => state?.manageCard);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWalletBalAction(userId));
    dispatch(sendAnalitics("Trns_PC_MA_Web"));
  }, []);
  // const ownerBalance = ownerLogipeBalance === "" ? 0 : ownerLogipeBalance;
  //console.log("blanace", ownerBalance);
  const { ownerBalance } = useSelector((state) => state?.manageCard);
  const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == null
      ? 0.0
      : ownerBalance?.instrument_balance?.balance;

  //   const { cashBack } = useSelector((state) => state?.manageCard);
  //   const cashbackRedeem = cashBack?.data?.redeem;
  //   const availableBal= (cashback-cashbackRedeem);
  const balanceRecord = useSelector(
    (state) => state?.LogipeCard?.balanceRecord
  );
  const bal =
    balanceRecord?.balance === null
      ? 0
      : Number(balanceRecord?.balance).toFixed(2);
  console.log("sandeep", userId);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon sideModal settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{
            width: "500px",
            backgroundColor: "white",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Personal Card - Add Balance</p>
            <Button
              type="button"
              className="close-btn"
              onClick={handleClose}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="card-wallet-main-body">
              <div
                className="card-wallet-body-new row greenModalBackground"
                style={{ paddingLeft: "10px", paddingTop: "20px" }}
              >
                <div classname=" col-6" style={{ width: "30%" }}>
                  <SvgImage name="modalBank" />
                </div>
                <div
                  className="col-5"
                  style={{
                    position: "relative",
                    left: "-15px",
                    width: "160px",
                  }}
                >
                  <p className="card-wallet-sub-body-new">Master Account</p>
                  <p className="card-wallet-sub-body2">
                    {"₹" + Number(LogipeAccounBal).toFixed(2)}
                  </p>
                </div>
              </div>

              <div
                className={"card-wallet-body2 row orangeModalBAckground"}
                style={{ paddingLeft: "15px", paddingTop: "20px" }}
              >
                <div classname=" col-4" style={{ width: "30%" }}>
                  <SvgImage name="modalWallet" classname="bank-card-wallet" />
                </div>
                <div
                  className="col-5"
                  style={{
                    position: "relative",
                    left: "-15px",
                    width: "9.4rem",
                  }}
                >
                  <p className="card-wallet-sub-body-new">Personal Card</p>
                  <p className="card-wallet-sub-body2">{"₹" + bal}</p>
                </div>
              </div>

              <span class="circle-logo-forward-arrow">
                <SvgImage name="ForwardArrow" height="36" width="36" />
              </span>
            </div>

            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                amount: "",
                pin: "",
                remark:""
              }}
              onSubmit={(values, actions) => {
                if (props.ownerCardType === "1") {
                  dispatch(sendAnalitics("PC_MA_API_Web",{
                      amount:values.amount,
                      pin:values.pin,
                      remark:values.remark
                    }));
                  dispatch(
                    transferToCard(values, userId, (res) => {
                      actions.setSubmitting(false);
                      if (res?.data?.type === "success") {
                        console.log("successful");
                        dispatch(sendAnalitics("Success_Trns_PC_MA_Web ",{
                      amount:values.amount,
                      pin:values.pin,
                      remark:values.remark
                    }));
                        toggleModal();
                        dispatch(getBalanceDetails(userId));
                      } else {
                        toggleModal();
                        dispatch(getBalanceDetails(userId));
                      }
                    })
                  );
                } else {
                  dispatch(sendAnalitics("PC_MA_API_Web",{
                      amount:values.amount,
                      pin:values.pin,
                      remark:values.remark
                    }));
                  dispatch(
                    transferToCard(
                      {
                        amount: values.amount,
                        pin: values.pin,
                        walletCategory: 2,
                      },
                      userId,
                      (res) => {
                        actions.setSubmitting(false);
                        if (res?.data?.type === "success") {
                          dispatch(sendAnalitics("Success_Trns_PC_MA_Web ",{
                            amount:values.amount,
                            pin:values.pin,
                            remark:values.remark
                          }));
                          console.log("successful");
                          //navigate("/Customers")
                          toggleModal();
                          dispatch(getBalanceDetails(userId));
                          //   dispatch(
                          //     getCardDetails(userId)
                          // )
                        } else {
                          toggleModal();
                          dispatch(getBalanceDetails(userId));
                        }
                      }
                    )
                  );
                }
                // dispatch (cleanExpenseSumm());
                // dispatch(expenseSumm(userId,{
                //   offset: 0 ,
                //   fromDate: '',
                //   toDate: '',
                //   sortAs: '',
                //   sortBy: '',
                // }));
                toggleModal();
              }}
              //   console.log("values onSubmit", values);
              //   dispatch(
              //     redeemCashbackAction({
              //         amount:values.amount
              //     })
              //   )
              //   refreshCashback();

              //   if (title === "Card Wallet") {
              //     dispatch(
              //       transferWalletTransferAmount(values, (res) => {
              //         console.log("transfer wallet call back", res.data);
              //         actions.setSubmitting(false);
              //         if (res?.data?.type === "success") {

              //           handleClose();
              //           //alert("Money added succesfully")

              //         }
              //       })
              //     );
              //   }
              //   if (title === "Fastag Wallet") {
              //     dispatch(
              //       transferFastagTransferAmount(values, (res) => {
              //         console.log("transfer fastag call back", res.data);
              //         actions.setSubmitting(false);
              //         if (res?.data?.type === "success") {
              //           handleClose();
              //           //alert("Money added succesfully")

              //         }
              //       })
              //     );
              //   }
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <p className="sub-head-3">Enter amount</p>
                    </div>
                    <p className="sub-head-body">
                      Amount will be added to owner’s LogiPe Card
                    </p>

                    <label for="html" className="label-body">
                      ₹
                    </label>
                    <input
                      type="text"
                      id="amount"
                      className={`input-body `}
                      name="amount"
                      onChange={handleChange("amount")}
                      value={values.amount}
                      onBlur={()=>{
                        dispatch(sendAnalitics("Amt_PC_MA_Web",{
                          amount:values.amount
                        }));
                        handleBlur("amount");
                      } }
                      touched={touched.amount}
                      errors={errors.amount}
                      placeholder="Enter amount"
                    />
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>
                    <div>
                      {/* <button type="submit" className="butt4" disabled={loader?true:false} >
                      
                      {loader?"Please Wait":`Redeem Cashback`}

                  </button> */}
                    </div>
                  </FormGroup>
                  <FormGroup>
                  <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-head-3">Enter Remarks/comment</p>
                    </div>
                    {/* <p className="sub-head-body">
                      Amount will be added to driver’s LogiPe Card
                    </p> */}

                    <label for="html" className="label-body"></label>
                    <input
                      type="text"
                      id="remark"
                      className={`input-body `}
                      name="remark"
                      onChange={handleChange("remark")}
                      value={values.remark}
                      onBlur={() => {
                        dispatch(
                          sendAnalitics("remark_DC_MA_Web", {
                            amt_MA_to_DriverCard: values.remark,
                          })
                        );
                        handleBlur("remark");
                      }}
                      touched={touched.remark}
                      errors={errors.remark}
                      placeholder="Enter remarks"
                    />
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.remark && touched.remark ? (
                        <div>*{errors.remark}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">3</span>
                      <p className="sub-head-4">Enter 4-digit Security Pin</p>
                    </div>
                    <p className="sub-head-body">
                      Security pin is needed to confirm payment
                    </p>
                    <PinInput
                      length={4}
                      initialValue=""
                      secret
                      onChange={handleChange("pin")}
                      values={values.pin}
                      //handleChange={handleChange("code")}
                      type="numeric"
                      inputMode="number"
                      style={{
                        padding: "10px",
                        marginLeft: "96px",
                        marginTop: "28px",
                      }}
                      inputStyle={{
                        border: "1px solid #A7A7A7",
                        borderRadius: "8px",
                        marginRight: "20px",
                      }}
                      //onComplete={(value, index) => {}}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      onClick={()=>{
                        dispatch(sendAnalitics("Pin_PC_MA_Web",{
                          pin:values.pin
                        }));
                      }}
                    />
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.pin && touched.pin ? (
                        <div>*{errors.pin}</div>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      className="butt4"
                      disabled={loader ? true : false}
                        onClick={()=>{
                          dispatch(sendAnalitics("AddBtn_PC_MA_Web",{
                            amount:values.amount,
                            pin:values.pin,
                            remark:values.remark
                          }));
                        }}
                    >
                      {loader ? "Please Wait" : `Add Balance to Personal Card`}
                    </button>
                  </FormGroup>
                  <div className="space-div"></div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default OwnerCardModal;
