
// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { useParams,useLocation,useNavigate } from "react-router-dom";
// import { BallTriangle } from "react-loader-spinner";
// import DataTable from "react-data-table-component";

// import { omcVehicleTransactionAction,cleanOmcTransactionsAction } from "../../../../services/Store/Actions/omcActions";
// import "./index.css";


// const FuelTable=(props)=>{
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     let { id } = useParams();
//     const [offset, setOffset] = useState(0);
//     const [filter, setFilter] = useState(0);
//     const [loading, setLoading] = useState(false);
//     const [totalRows, setTotalRows] = useState(0);

//     const { omcTransaction } = useSelector(
//         (state) => state?.omc
//       );
//       const totalEntries= omcTransaction?.count;
//       console.log("omcGGGGGGGGG", omcTransaction,totalEntries);
//     useEffect(() => {
//         if (id !== "" && id !== undefined && id !== null) {
//         setOffset(0);
//         dispatch(cleanOmcTransactionsAction());
//         dispatch(
//             omcVehicleTransactionAction(id, {
//             offset: 0,
//             limit:10,
//             // fromDate: "",
//             // toDate: "",
//             // sortAs: "",
//             // sortBy: "",
//             },()=> {setLoading(false)})
//         )
//         setLoading(true);
//         } else {
//         setOffset(0);
//         dispatch(cleanOmcTransactionsAction());
//         }
//     }, [id]);
//     const fetchUsers = async page => {
//         setLoading(true);
//         dispatch(
//             omcVehicleTransactionAction(id, {
//             offset: (page-1)*10,
//             limit:10,
//             // fromDate: "",
//             // toDate: "",
//             // sortAs: "",
//             // sortBy: "",
//             },()=> {setLoading(false)})
//         )
//         setLoading(false);
//       };
//     const handlePageChange = page => {
//         fetchUsers(page);
//     };

//     console.log("FuelTableFuelTableFuelTableFuelTableFuelTable", omcTransaction);

//     const txnCount = omcTransaction?.count;
//     const txnRows = omcTransaction?.rows;
//     let columns;
//     if(txnRows?.length > 0){
//         {
//             txnRows.map((i, k) => {
//             columns = [
//             {
//                 name: "Date & Time",
//                 selector: (i) => i.transactionDate,
//             },
//             {
//                 name: "Transaction details",
//                 selector: (i) => i.description,
//             },
//             {
//                 name: "Remarks",
//                 selector: (i) => i.userRemarks,
//             },
//             {
//                 name: "Debit(INR)",
//                 selector: (i) => (i.transferType == 'debit' ? i.amount : '-'),
//             },
//             {
//                 name: "Credit(INR)",
//                 selector: (i) => (i.transferType == 'credit' ? i.amount : '-'),
//             },
//             ];
//         });
//         }
//     }
    
//     return <div >
//  <div>
//             <div>
                
//             </div>
//          {console.log("txnCount", txnCount)}
//             {txnCount == undefined ||
//             (txnCount > 0 && txnRows?.length == 0) ? (
//                 <div
//                     className="mt-2 boxContainer"
//                     style={{
//                     padding: 70,
//                     justifyContent: "center",
//                     alignItems: "center",
//                     }}
//                 >
//                     <BallTriangle
//                     height={70}
//                     width={70}
//                     radius={5}
//                     color="#2e7c7b"
//                     ariaLabel="ball-triangle-loading"
//                     wrapperClass={{}}
//                     wrapperStyle=""
//                     visible={true}
//                     />
//                 </div>
//             ) : (
//                 <DataTable
//                     columns={columns}
//                     data={txnRows}
//                     highlightOnHover
//                     progressPending={!loading}
//                     pagination
//                     paginationComponentOptions={{noRowsPerPage:true}}
//                     paginationServer 
//                     paginationTotalRows={txnCount}
//                     onChangePage={handlePageChange} 
//                 />
//             )}
//         </div>
//     </div>
// }
// export default FuelTable

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { useParams } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import { AiOutlinePlus } from "react-icons/ai";
import { Button, Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import {
    cleanOmcTransactionsAction,
    omcVehicleTransactionAction
} from "../../../../services/Store/Actions/omcActions";
import { DatePickerField } from "../../../core/inputs/input";

const listing = ["Details", "Edit", "Delete"];
function FuelTable(props) {
  let { customerId, setTripNos, activeTab, customer } = props;
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [offset, setOffset] = useState(0);
  const [color, setColor] = useState(-1);
  const [vehicleNo, setVehicleNo] = useState("");
  const [isCheckAllTrips, setIsCheckAllTrips] = useState(false);
  const [displayAction, setDisplayAction] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [method, setMethod] = useState(false);
  const [isCheckTrips, setIsCheckTrips] = useState({});
  const [isCheckTripsLength, setIsCheckTripsLength] = useState(0);
  const [open5, setOpen5] = useState(false);
  const [itemTemp, setItemTemp] = useState({});
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [tripType, setTripType] = useState("");
  const [open2, setOpen2] = useState(false);
  const [editFreight, setEditFreight] = useState(false);
  const [vrn, setVrn] = useState("");
  const [filter, setFilter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
    const { omcTransaction } = useSelector(
        (state) => state?.omc
      );
  // console.log("FASTagTableFASTagTableFASTagTableFASTagTable",id, fastagTransactionHistory);

  const txnCount = omcTransaction?.count;
  const txnRows = omcTransaction?.rows;
  console.log(
    "ffdfdfdfdfd",
    txnCount,
    txnRows,
    txnCount == undefined,
    txnCount == 0,
    txnCount > 0 && txnRows?.length == 0
  );

  const pageCount =
    txnCount % 10 === 0 ? txnCount / 10 : Math.floor(txnCount / 10) + 1;

  const handlePageClick = (event) => {
    console.log("Clickess", event, pageCount);
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };
  // const tripData = [];
  let { id } = useParams();
  const fetchUsers = async (page) => {
    console.log("kljkkkk",page.selected)
    setLoading(true);
    dispatch(
      omcVehicleTransactionAction(
        id,
        {
          offset: page.selected * 10,
          limit: 10,
          from: from,
          to: to,
          transferType : tripType == "0" ? 2 : tripType == "1" ? 1 : "",
          // fromDate: "",
          // toDate: "",
          // sortAs: "",
          // sortBy: "",
        },
        () => {
          setLoading(false);
        }
      )
    );
    setLoading(false);
  };
  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  useEffect(() => {
    console.log("kkklllll", offset);
    if (id !== "" && id !== undefined && id !== null) {
        setOffset(0);
      dispatch(cleanOmcTransactionsAction());
      dispatch(
        omcVehicleTransactionAction(
          id,
          {
            offset: 0,
            limit: 10,
            from: from,
            to: to,
            transferType : tripType == "0" ? 2 : tripType == "1" ? 1 : "",
            // sortAs: "",
            // sortBy: "",
          },
          (res) => {
            console.log(
              "getFastagTransactionHistorygetFastagTransactionHistorygetFastagTransactionHistorygetFastagTransactionHistory",
              res
            );
            setLoading(false);
          }
        )
      );
      setLoading(true);
    } else {
      setOffset(0);
      dispatch(cleanOmcTransactionsAction());
    }
  }, [id,from,to,tripType]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const onSelect = (row) => {
    // storeDataToAsyncStorage("row", row);
    // navigate(`/trips/${row.tripId}`);
    // console.log("dsadasd", row);
    // // setItem(row);
    // console.log("thissssssssssssss", row);
  };
  return (
    <div className="box2">
      <div className="box3 d-flex">
        {/* <div className="ms-4">
          <div className="headingText">Date Range:</div>
          <input
            type="text"
            className="text-input-placeholder mt-2"
            placeholder={"Enter Date Range"}
            onChange={() => {}}
            value={""}
            // onkeypress={setStorage}
          />
        </div> */}
        <div className="row">
          <div className="headingText col-12">Date Range:</div>
          <div className="col-12 row mt-2">
            <div className="col-5 row">
              {/* <input
                type="date"
                className="text-input-placeholder mt-2"
                placeholder={"Select Date"}
                onChange={(event) => {
                  setFrom(event.target.value);
                }}
                style={{width: "200px"}}
                max={to}
                value={from}
                // onkeypress={setStorage}
              /> */}
              <div className="col-9">
                <DatePickerField
                  classname="searchBar-input1"
                  name="startDate"
                  header="From"
                  value={from}
                  onChange={(v) => {
                    setFrom(v);                  
                }}
                  //onChange={(v) => setFieldValue("endDate", v, true)}
                  // maxDate={
                  //   values.endDate === "" ? "" : values.endDate
                  // }
                  // maxDate={new Date()}
                  // ref1={datepickerRefEnd}
                  style={{ width: "100px" }}
                />
              </div>
              <div className="col-3" style={{ margin: "0 0 0 -12px" }}>
                <label
                  className="date-input"
                  for="startDate"
                  style={{
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    //   handleClickDatepickerIcon2();
                  }}
                >
                  <SvgImage
                    name="CalenderLogo"
                    width="32"
                    height="32"
                    // classname="date-logo"
                  />
                </label>
              </div>
            </div>

            <div className="col-5 row">
              <div className="col-9">
                <DatePickerField
                  classname="searchBar-input1"
                  name="startDate"
                  header="To"
                  value={to}
                  onChange={(v) => {
                    console.log("ppppoooooo",v)
                    setTo(v);                  
                  }}
                  //onChange={(v) => setFieldValue("endDate", v, true)}
                  // maxDate={
                  //   values.endDate === "" ? "" : values.endDate
                  // }
                  // maxDate={new Date()}
                  // ref1={datepickerRefEnd}
                  style={{ width: "100px" }}
                />
              </div>
              <div className="col-3" style={{ margin: "0 0 0 -15px" }}>
                <label
                  className="date-input"
                  for="startDate"
                  style={{
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    //   handleClickDatepickerIcon2();
                  }}
                >
                  <SvgImage
                    name="CalenderLogo"
                    width="32"
                    height="32"
                    // classname="date-logo"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="ms-4">
          <div className="headingText">To:</div>

        </div> */}
      </div>
      <div className="d-flex box3 pt-0 justify-content-between">
        <div className="d-flex">
          <button
            className={`Medium heading3 ${
              tripType == "" ? "greenButtonMini" : "greenOutlineButtonMini"
            } rounded-pill`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("");
              setOffset(0);
            }}
          >
            All
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "0" ? "greenButtonMini" : "greenOutlineButtonMini"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("0");
              setOffset(0);
            }}
          >
            Debit
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "1" ? "greenButtonMini" : "greenOutlineButtonMini"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("1");
              setOffset(0);
            }}
          >
            Credit
          </button>
          {/* <button
            className={`Medium heading3 ${
              tripType == "0" ? "greenButtonMini" : "greenOutlineButtonMini"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("2");
              setOffset(2);
            }}
          >
            Chargebacks
          </button> */}
        </div>
        <div className="d-flex align-items-center">
          <button
            className=" d-flex align-items-center text2 ms-3 headingText"
            style={{
              border: 0,
              // marginTop: "10px",
              backgroundColor: "#FFFFFF",
            }}
            onClick={() => {
              // setDownload(true);
            }}
          >
            <div className="padding8R">
              <SvgImage name="downloadImgV2" />
            </div>
            Download
          </button>
        </div>
      </div>
      <div className="table-for-vehicle-receivable">
        <Table className="vehicleAssignedHistory">
          <thead
            className="th align-middle text-center"
            style={{ zIndex: 99, textAlignLast: "center" }}
          >
            <tr className="table-header-Fastag-details">
              {/* <th className="p-0" style={{ width: "10%" }}>
                Lr No
              </th> */}
              <th className="p-0" style={{ width: "30%" }}>
                Transaction details
              </th>
              <th className="p-0">Credit</th>
              <th className="p-0">Debit</th>
              {/* <th className="p-0">Tonnage</th> */}
              <th className="p-0" style={{ width: "25%" }}>
                Date & Time{" "}
              </th>
              <th className="p-0">Closing Bal.</th>
              {/* <th className="p-0">Trip No</th> */}
              <th className="p-0">Status</th>
            </tr>
          </thead>
          {/* <div className="tbodyVehicle-div"> */}
          {txnCount == undefined ||
          txnCount == 0 ||
          (txnCount > 0 && txnRows?.length == 0) ? (
            <div className="padding32 textCenter">
              <SvgImage name="TripsMap" />
              {/* <div className="Bold heading1 padding24T padding16B FontSuperBold primaryBlack">
                Create Trip
              </div> */}
              <div className="greyFont heading3" style={{ marginTop: "10px" }}>
                There are no records to
              </div>
              <div className="greyFont heading3 padding32B">display</div>
              {/* <button
                className={"Medium heading3 greenButton"}
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                onClick={() => {
                  setOpen2(true);
                }}
              >
                Create Trip
              </button> */}
            </div>
          ) : (
            <tbody className="tbodyVehicle" style={{ textDecoration: "none" }}>
              {txnRows?.map((items, i) => {
                console.log("setIsCheckTrips", isCheckTrips);
                return (
                  <>
                    <tr className="table-row-customer-details2">
                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                        style={{ width: "30%" }}
                      >
                        {items?.description}
                      </td>
                      {/* <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                      >
                        {items?.Trip?.Fleet?.regNumber}
                      </td> */}
                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                      >
                        {items.transferType == "credit"
                          ? "₹" + items.amount
                          : "-"}
                      </td>
                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                      >
                        {items.transferType == "debit"
                          ? "₹" + items.amount
                          : "-"}
                      </td>
                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                        style={{ width: "25%" }}
                      >
                        {items?.transactionDate}
                      </td>
                      {/* <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items?.Trip);
                        }}
                      >
                        {items?.tonnage}
                      </td> */}
                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items?.Trip);
                        }}
                      >
                        {items?.balance}
                      </td>
                      <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                      >
                        {items?.status || "Success"}
                      </td>
                      {/* <td
                        className="p-3 align-items-center"
                        onClick={() => {
                          onSelect(items?.Trip);
                        }}
                      >
                        <div className="statusBtn">
                          {items?.Trip?.isActive == "0"
                            ? "Completed"
                            : "Running"}
                        </div>
                      </td> */}
                    </tr>
                  </>
                );
              })}
            </tbody>
          )}

          {/* </div> */}
        </Table>

        {/* {
          olderHistoryStatus?
          <div className="olderTransactions-div" onClick={handleOlderTransactions}>
          Older History
          </div>:
        <></>
        } */}
        {console.log("pagecunt", pageCount)}
        {pageCount >= 1 ? (
          <div className="reactPagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageChange}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
export default FuelTable;
