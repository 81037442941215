import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { cleanExpenseWalletSummary, getExpenseWalletSummary } from "../../../../../../services/Store/Actions/driverslistActions";
import "./index.css";
import moment from "moment";
import { Table } from "reactstrap";
import NoTransaction from "./NoTransaction";
import { getFastagTransactionHistory } from "../../../../../../services/Store/Actions/fastagAction";
import SvgImage from "../../../../../Icons/Icons";
import { cleanFleetTransactionHistory, getFastagVehicleHistoryStatement } from "../../../../../../services/Store/Actions/fleetActions";
import AddingFilter from "../../../../../Modals/addingFilter";


const FastagTable = (props) => {
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState(0);
  const [start, setStart]= useState("");
  const [end, setEnd]= useState("");
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const cardExpense = useSelector((state) => state?.driverlist);
  const omcDriverData= useSelector((state)=>state?.omc?.omcDriverData);

  let { id } = useParams();
  const dispatch= useDispatch();

  const closing1 = () => {
    setActive1(false);
  };

  const toggling1 = () => {
    setActive1(!active1);
  };

  const showing1 = () => {
    setActive1(true);
  };

  const closing2 = () => {
    setActive2(false);
  };

  const toggling2 = () => {
    setActive2(!active2);
  };

  const showing2 = () => {
    setActive2(true);
  };


  const filterFunc = (startDate, endDate) => {
    setOffset(0);
    setFilter(1);
    setStart(startDate);
    setEnd(endDate);
    console.log("start date", startDate);
    console.log("end date", endDate);
    dispatch(cleanFleetTransactionHistory());
    dispatch(
      getFastagTransactionHistory(fleetId, {
        offset: 0,
        search: '',
        sortAs: '',
        sortBy: '',
        from: moment(startDate).format("YYYY-MM-DD"),
        to: moment(endDate).format("YYYY-MM-DD"),
      }),
    );
  };

  const downloadFunc = (startDate, endDate) => {
    console.log("start date", startDate);
    console.log("end date", endDate);
    dispatch(
      getFastagVehicleHistoryStatement(fleetId, {
        from:  moment(startDate).format("YYYY-MM-DD"),
        to:  moment(endDate).format("YYYY-MM-DD"),
      },(res) => {
        console.log("rrrrrrrrrrrrrrrr", res);
        if (res?.data?.type === "success") {
          window.open(res?.data?.data?.url);
          // window.location.replace(res?.data?.data?.link);
          //window.open();
          //window.location.reload();
        }
      })
    );
    //window.open(fastagVehicleStatement?.url);
  };

  const disableFilter=()=>{
    setOffset(0);
    setFilter(0);
    setStart("");
    setEnd("");
    dispatch(cleanFleetTransactionHistory());
    dispatch(
      getFastagTransactionHistory(fleetId, {
        offset: 0,
        search: '',
        sortAs: '',
        sortBy: '',
        from: '',
        to: '',
      }),
    );
  }
  // useEffect(() => {
  //   if (id !== "" && id !== undefined && id !== null) {
  //     setOffset(0);
  //     dispatch(cleanExpenseWalletSummary());
  //     dispatch(
  //       getExpenseWalletSummary(id, {
  //         offset: 0,
  //         fromDate: "",
  //         toDate: "",
  //         sortAs: "",
  //         sortBy: "",
  //       })
  //     );
  //   } else {
  //     setOffset(0);
  //     dispatch(cleanExpenseWalletSummary());
  //   }
  // }, [id]);
const fleetId= omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.fleetId;
  useEffect(() => {
    if (fleetId !== "" && fleetId !== undefined && fleetId !== null) {
      setOffset(0);
      dispatch(cleanFleetTransactionHistory());
      dispatch(
        getFastagTransactionHistory(fleetId, {
          offset: 0,
          search: '',
          sortAs: '',
          sortBy: '',
          from: '',
          to: '',
        }),
      );
    }else{
      setOffset(0);
      dispatch(cleanFleetTransactionHistory());
    }
  }, [omcDriverData]);


  const {fastagTransactionHistory} = useSelector((state) => state?.fastag);

  const History = fastagTransactionHistory?.rows;
  //const trxnCount= fastagTransactionHistory?.count;
  console.log("ddd",fastagTransactionHistory);
  const trxnCount= fastagTransactionHistory?.count;


  const handlePrev=()=>{
    if(offset>0){
      //setOffset(offset-10);
      if (filter===0){
        // dispatch(cleanFleetTransactionHistory());
        setOffset(offset-10);
        dispatch(
          getFastagTransactionHistory(fleetId, {
            offset: offset-10,
            search: '',
            sortAs: '',
            sortBy: '',
            from: '',
            to: '',
          }),
        );
      }
      else {
        console.log ("fastag-> filter",start,end);
        // dispatch(cleanFleetTransactionHistory());
        setOffset(offset-10);
        dispatch(
          getFastagTransactionHistory(fleetId, {
            offset: offset-10,
            search: '',
            sortAs: '',
            sortBy: '',
            from: moment(start).format("YYYY-MM-DD"),
            to: moment(end).format("YYYY-MM-DD"),
          }),
        );
      }
    }
  }

  const handleNext=()=>{
    if(trxnCount-offset>10){
      if (filter===0){
        // dispatch(cleanFleetTransactionHistory());
        setOffset(offset+10);
        dispatch(
          getFastagTransactionHistory(fleetId, {
            offset: offset+10,
            search: '',
            sortAs: '',
            sortBy: '',
            from: '',
            to: '',
          }),
        );
      }
      else {
        console.log ("fastag-> filter",start,end);
        // dispatch(cleanFleetTransactionHistory());
        setOffset(offset+10);
        dispatch(
          getFastagTransactionHistory(fleetId, {
            offset: offset+10,
            search: '',
            sortAs: '',
            sortBy: '',
            from: moment(start).format("YYYY-MM-DD"),
            to: moment(end).format("YYYY-MM-DD"),
          }),
        );
      }
    }
  }



  return (
    <>
    <div className=" flexStyling new-row-for-download padding16R padding16T" >
        <div className="Bold heading2 padding16 " style={{paddingLeft:"0"}}>Transaction History</div>
        <div className="flex1"></div>
        <div className="filterText" onClick={()=>{
          if(trxnCount!=undefined){
            if(trxnCount!=0){
              showing1();
            }
          }
        }}>
            Filter
            <div className="filterImg">
                <SvgImage name="filterIcon" />
            </div>
        </div>
        {
        <AddingFilter
          active={active1}
          toggleModal={toggling1}
          handleClose={closing1}
          func={filterFunc}
          name="Filter"
        />
        }
        <div className=" downloadText" onClick={()=>{
          if(trxnCount!=undefined){
            if(trxnCount!=0){
              showing2();
            }
          }
        }}>
            Download Statement
            <div className="padding8L" >
            <SvgImage name="downloadImg" />
            </div>
            
        </div>
        {
        <AddingFilter
          active={active2}
          toggleModal={toggling2}
          handleClose={closing2}
          func={downloadFunc}
          name="Download History"
        />
        }   
    </div>
    <div>
    <div className="flexStyling new-row-for-download padding32L padding32R padding16B">
        <div className="flex1"></div>
        <div className="filterDates">
          <div className="heading3 Bold">Filter Applied: </div>
          <div className="FontNB" style={{ paddingLeft: "8px" }}>
            {moment(start).format("DD-MM-YYYY") == "Invalid date"
              ? "None"
              : moment(start).format("DD-MM-YYYY") + " to"}{" "}
            {moment(end).format("DD-MM-YYYY") == "Invalid date"
              ? ""
              : moment(end).format("DD-MM-YYYY")}
          </div>
          <div
            style={{
              paddingLeft: "8px",
              position: "relative",
              top: "-3px",
              cursor: "pointer",
              visibility:moment(start).format("DD-MM-YYYY") == "Invalid date"?"hidden":"visible"
            }}
            onClick={disableFilter}
          >
            <SvgImage
              classname="close-btn-text"
              // style={{ width: "10%", height: "10%" }}
              name="CloseButtonLogo1"
              width="20px"
              height="20px"
            />
          </div>
        </div>
      </div>
    </div>
    {
      trxnCount==undefined?
      <NoTransaction />
      :   trxnCount==0?
      <NoTransaction />
      :
      <div>
     <Table className="DriverSectionTable" bordered="true" id="driver_tables">
        <thead >
        <tr className="table-header-customer-details-dr">
            <th style={{width:"18%"}} >Date & Time</th>
            <th > <div style={{marginLeft:"rem"}}>Transaction Details</div> </th>
            {/* <th>Remark</th> */}
            <th style={{width:"18%"}}>Debit (INR)</th>
            <th style={{width:"18%"}}>Credit (INR)</th>
            {/* <th style={{width:"19%"}}>Closing Balance (INR)</th> */}
        </tr>
        </thead>
        <tbody className="lowerBorderLine">
        {fastagTransactionHistory?.rows?.map((d) => {

            // let remarks=d?.userRemarks;
            let details= d?.description;
            let amount=d?.amount;
            let type=d?.transferType;
            let date=moment(d?.transactionDate.split(" ")[0], [
              "YYYY-MM-DD",
            ]).format("DD-MM-YYYY");
            let time=moment(d?.transactionDate.split(" ")[1], [
              "hh:mm:ss",
            ]).format("hh:mm:ss a");

              return (
                <tr className="table-row-manage-driver-new text4">
                  <td style={{width:"18%"}}>
                    <div>{date}</div>
                    <div>{time}</div>
                  </td>
                  <td>{details}</td>
                  {/* <td>{remarks}</td> */}
                  <td style={{width:"18%"}}>{type=="debit"?amount:"-"}</td>
                  <td style={{width:"18%"}}>{type=="credit"?amount:"-"}</td>
                  {/* <td style={{width:"19%"}}>NA</td> */}
                </tr>
              );
            })} 
        </tbody>
      </Table>
      <div className="row margin0 padding16" >
          <div className="col-4" style={{textAlign:"right", paddingTop:"0.1rem", cursor:offset>0?"pointer":"no-drop" , opacity:offset>0?"1":"0.5"}}
            onClick={handlePrev}
            >
            <SvgImage name="PrevArrow" />
          </div>
          <div className="col-1 greenFont semiBold heading4 padding0L" style={{textAlign:"right", paddingTop:"0.3rem", cursor:offset>0?"pointer":"no-drop", opacity:offset>0?"1":"0.5"}} 
          onClick={handlePrev}>
              Previous
          </div>
          <div className="col-2 row margin0">
            <div className="greenBoxCount Medium heading4 col-4" style={{width:"2rem", height:"2rem", alignItems:"center", textAlign:"center"}}>{(offset/10)+1}</div>
            <div className=" col-8 padding0R"  style={{ paddingTop:"0.3rem", textAlign:"right"}}>of  {trxnCount%10==0?Math.floor((trxnCount/10)):Math.floor((trxnCount/10)+1)}</div>
          </div>
          <div className="col-1 greenFont semiBold heading4 padding0R" style={{textAlign:"left", paddingTop:"0.3rem", cursor:trxnCount-offset>10?"pointer":"no-drop",opacity:trxnCount-offset>10?"1":"0.5"}}
            onClick={handleNext}>
              Next
          </div>
          <div className="col-4 padding0L" style={{textAlign:"left", marginLeft:"-1rem", paddingTop:"0.1rem", cursor:trxnCount-offset>10?"pointer":"no-drop",opacity:trxnCount-offset>10?"1":"0.5"}}
              onClick={handleNext}>
            <SvgImage name="NextArrow" />
          </div>
      </div>
      </div>
      }
    </>
  );
};

export default FastagTable;







