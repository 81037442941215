import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";
import { addToCartAction, getCartDetailsAction } from "../../../../../services/Store/Actions/ecomAction";
import SvgImage from "../../../../Icons/Icons";
import RemoveItem from "./RemoveItem";

function CartItem(props){
    //modal for final status
    const [open, setOpen] = useState(false);
    const coins = Math.floor(0.025 * Number(props?.prodMRP - props?.prodDiscount));
    const dispatch= useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    function toggleModal() {
        setOpen(!open);
    }

    const handleShow = () => {
        setOpen(true);
    };

    const handleSub = () => {
        if (Number(props?.quantity) != 1) {
          let userIdObj = localStorage.getItem("_userId");
          console.log("userIdObj", userIdObj)
          let userId = JSON.parse(userIdObj)
          dispatch(addToCartAction({
            "userId": userId,
            "productId": props.prodId,
            "quantity": String(Number(props?.quantity) - 1)
          }, (res) => {
            console.log("Add to cart resp", res);
            if (res?.status == 200) {
              dispatch(getCartDetailsAction((res)=>{
                console.log("res", res)
              }))
            }
          }))
          
        }
      }

    const handleAdd = () => {
        let userIdObj = localStorage.getItem("_userId");
        console.log("userIdObj", userIdObj)
        let userId = JSON.parse(userIdObj)
        dispatch(addToCartAction({
          "userId": userId,
          "productId": props.prodId,
          "quantity": String(Number(props?.quantity) + 1)
        }, (res) => {
          console.log("Add to cart resp", res);
          if (res?.status == 200) {
            dispatch(getCartDetailsAction((res)=>{
              console.log("res", res)
            }))
            //Router.reload();
            // setCartBtn("Go to Cart");
          }
        }))
      }
    
    const removeFromCart = () => {
    handleShow();
    }  

    return (
    <div className="padding16">
        <div className="row cartItem borderRad7" style={{marginLeft:"0.7rem"}}>
        <div className="col-5" style={{ backgroundColor: "#96A9CA", padding: "0.5rem", height: "11.5rem" }}>
            <img src={props?.prodImg} className="imgTyre"
            style={{ height: "100%", width:"100%", alignItems: "centre" }} />
        </div>
        <div className="col-7 padding16 padding0B" style={{ position: "relative" }}>
            <div className="closeBtn" onClick={removeFromCart}>
                <SvgImage name="cartRemove" />
            </div>
            <div className="modelName padding32R" style={{fontWeight:"550"}}>{props?.prodName}</div>
            <div className="padding16L padding8T">
            <div className="row quantityDiv1">
                <div className="subIcon col-4 btnCursor " onClick={handleSub}><SvgImage name="sub" /></div>
                <div className="col-4 Bold heading3"style={{ textAlign: "center", paddingTop:"2px" }} >{props?.quantity}</div>
                <div className="addIcon col-4 btnCursor " onClick={handleAdd}><SvgImage name="add" /></div>
            </div>
            </div>
            <div className="prodPrices" style={{paddingBottom:"0.3rem"}}>
                <div className="priceSP"> ₹ {(props?.prodMRP - props?.prodDiscount) * (props?.quantity)}</div>
                <div className="priceMRP"> ₹ {(props?.prodMRP) * props?.quantity}</div>
            </div>
            <div className="prCoins padding0BB">Or Pay ₹ {(props?.prodMRP - props?.prodDiscount - coins) * props?.quantity} 
            + <SvgImage name="coins" /> {(coins) * props?.quantity}
             </div>
            <div></div>
        </div>
        </div>
        <RemoveItem
            handleClose={handleClose}
            isOpen={open}
            onHide={handleClose}
            toggle={toggleModal}
            prodId={props?.prodId}
        />
    </div>
    );
}

export default CartItem;


