import React, { useEffect, useState } from "react";
import "./index.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import {
  cleanFleet,
  getFleetTracker,
} from "../../../../services/Store/Actions/driverslistActions";
import moment from "moment"
import ClipLoader from "react-spinners/ClipLoader";

function ManageVehicleDetailsCard(props) {
  const { color, setColor, activeStatus, assignedStatus } = props;
  const dispatch = useDispatch();
  const [fetchMoreData, setFetchMoreData] = useState(true);
  //const [offset, setOffset] = useState(0);
  const [serialNum, setSerialNum] = useState(0);
  const [displayName, setDisplayName] = useState([]);
  const [mapView, setMapView] = useState("-1");

  const { allFleetTrackerList, loader, allFleetTracker } = useSelector(
    (state) => state?.driverlist
  );

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  // const [loader, setLoader] = useState("");
  const [bank, setBank] = useState("YesBank");
  const [reissueData, setReissueData] = useState();

  // to handle success or failure of re-issue. If failed, then state=1, and chasis option available
  const [reIssue, setReIssue] = useState(0);

  function handleShow() {
    setOpen(true);
  }
  const handleLocation = (index) => {
    if (mapView == "-1") setMapView(index);
    else setMapView("-1");
  };

  const handleCloseOpenLink = () => {
    setDisplayName(displayName.filter((e) => e.name === ""));
    props.setOpenLink(false);
  };
  const handletoggleOpenLink = () => {
    setDisplayName(displayName.filter((e) => e.name === ""));
    props.setOpenLink(!props.openLink);
  };
  const handlenShowOpenLink = (id, cat, regNo) => {
    props.setRegNumber(regNo);
    props.setFleetId(id);
    props.setVehCat(cat);
    props.setOpenLink(true);
  };

  const isReissueAvailable = useSelector(
    (state) => state?.fastag?.isReissueAvailable
  );
  const hotlistStatus = isReissueAvailable?.npciStatus;
  const reissueStatus = isReissueAvailable?.status;

  console.log("iiiiiiiiiiiiiiii", isReissueAvailable);
  // function handleClick(Id, i) {
  //   // window.location=`/Vehicles?u=${id}&r=${regNo}&v=${cat}`
  //   console.log("Yaha toh hoga hi bhaiii", window.location.href);
  //   console.log("id@@@", Id);
  //   navigate(`/VehiclesV2/${Id.fleetId}`);
  //   //window.location.href=Id.fleetId
  //   // setColor(i);
  // }

  const handleClick = (e, i) => {
    console.log("hhjjjjjj", e, i);
    props.allowTrigger();
    props.setVehRank(props.offset + i + 1);
    console.log(
      "drivers ki list",
      `/VehiclesV2/${e.fleetId}?search=${props.search}&offset=${props.offset}`
    );

    navigate(
      `/GPS/${e.fleetId}?search=${props.search}&offset=${props.offset}`,
      { state: { vehicle: e } }
    );
    setColor(i);
  };

  let { id } = useParams();
  //console.log("ppp",id)

  // useEffect(() => {
  //   //console.log("Yaha toh hoga hi bhaiii", window.location.href);
  //   let urlId = window.location.pathname.split("/");
  //   //console.log("peterrr",urlId)
  //   if (urlId.length === 3) {
  //     if (urlId[2] != undefined && urlId[2] != "") {
  //       let i = advancedVehicleList?.findIndex(
  //         (element) => element.fleetId === urlId[2]
  //       );
  //       //handleUserId(urlId[2]);
  //       //console.log("han ji",i)
  //       setColor(i);
  //       if (id) {
  //         if (props.regNumber) {
  //           dispatch(
  //             reissueAvailableAction({
  //               fleetId: id,
  //               vrn: props.regNumber,
  //             })
  //           );
  //         }
  //       }
  //     }
  //   }
  // }, [id, advancedVehicleList]);

  console.log("offsettinggggggggggg", props.offset);

  const handlePrevButton = () => {
    //props.nextTrigger();
    if (props.offset > 0) {
      props.nextTrigger();
      props.setOffset(props.offset - 7);
      //setSerialNum(serialNum - 15);
      //console.log("offset", offset);
      // dispatch(
      //   getVehicleListAction({
      //     offset: props.offset - 15,
      //     limit: 15,
      //     search: props.search,
      //     minKyc: "",
      //     sortAs: "",
      //     sortBy: "",
      //   })
      // );
      dispatch(cleanFleet());
      dispatch(
        getFleetTracker({
          offset: props.offset - 7,
          limit: 7,
          search: "",
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    }
    console.log("X--");
  };

  const handleNextButton = () => {
    if (allFleetTracker?.count - props.offset > 7) {
      props.nextTrigger();
      props.setOffset(props.offset + 7);
      //setSerialNum(serialNum + 15);
      //console.log("offset", offset);
      // dispatch(
      //   getVehicleListAction({
      //     offset: props.offset + 15,
      //     limit: 15,
      //     search: props.search,
      //     minKyc: "",
      //     sortAs: "",
      //     sortBy: "",
      //   })
      // );
      dispatch(cleanFleet());
      dispatch(
        getFleetTracker({
          offset: props.offset + 7,
          limit: 7,
          search: "",
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    }
    console.log("X++");
  };

  // for handling otp modal

  const [openOTP, setOpenOTP] = useState(false);
  const handleCloseOTP = () => {
    setOpenOTP(false);
    //setProceed(0);
    //console.log("closed");
  };

  const toggleModalOTP = () => {
    setOpenOTP(!openOTP);
    //setProceed(0);
  };

  const handleShowOTP = () => {
    handletoggleOpenLink();
    setOpenOTP(true);
  };

  // for handling KYC` modal

  const [openKYC, setOpenKYC] = useState(false);
  const handleCloseKYC = () => {
    setOpenKYC(false);
    //setProceed(0);
    //console.log("closed");
  };

  const toggleModalKYC = () => {
    setOpenKYC(!openKYC);
    //setProceed(0);
  };

  const handleShowKYC = () => {
    toggleModalOTP();
    setOpenKYC(true);
  };

  // for handling fastag issue modal

  const [openIssue, setOpenIssue] = useState(false);
  const handleCloseIssue = () => {
    setOpenIssue(false);
    //setProceed(0);
    //console.log("closed");
  };

  const toggleModalIssue = () => {
    setOpenIssue(!openIssue);
    //setProceed(0);
  };

  const handleShowIssue = (kycStatus) => {
    if (kycStatus === "kyc done") {
      handletoggleOpenLink();
    } else {
      toggleModalKYC();
    }
    setOpenIssue(true);
  };

  // for handling reissue otp modal

  const [openReissue, setOpenReissue] = useState(false);
  const handleCloseReissue = () => {
    setOpenReissue(false);
    //setProceed(0);
    //console.log("closed");
  };

  const toggleModalReissue = () => {
    setOpenReissue(!openReissue);
    //setProceed(0);
  };

  const handleShowReissue = () => {
    toggleModalIssue();
    setOpenReissue(true);
  };

  return (
    <div className="main-all-customer-list">
      <div id="scrollableDivCustomer" className="div-table">
        {loader ? (
          <div>
            <div
              className="text-center"
              style={{
                padding: 16,
              }}
            >
              <ClipLoader
                color={"#2E7C7B"}
                loading={true}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        ) : (
          <>
            {allFleetTrackerList?.map((d, i) => {
              const regName =
                d?.driverName === undefined || d?.driverName === null
                  ? "No Driver"
                  : d?.driverName;
              console.log(";;;;;;;;;;;;;;;;;;;;;;", d);

              const firstName_initials = regName[0];
              // const lastName_initials = d.lastName[0] + ".";
              return (
                <div
                  className={
                    color === i
                      ? "div-table-row-gps actve-button-chosen-for-vehicle"
                      : "div-table-row-gps"
                  }
                  style={{
                    border: "0.5px solid #E2E2E2",
                    borderRadius: "0.5rem",
                    margin: "0px 8px 8px",
                    boxShadow: " 0px 4px 20px 0px #25252d1a !important",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick(d, i)}
                  key={i}
                >
                  <div className="row">
                    <div class=" rowContN col-12 FontNBList row">
                      {/* <div data-letters={firstName_initials.toUpperCase()}> */}
                      <div
                        data-letters-vehicle={props.offset + i + 1}
                        style={{ paddingTop: "5px", width: "50px" }}
                        className="col-2"
                      ></div>
                      <div
                        className="col-2 FontNB20 VehicleShortName"
                        style={{ width: "65px" }}
                      >
                        {d?.regNumber.substr(d?.regNumber?.length - 4)}
                      </div>
                      <div className="col-6 FontNB20 VehicleShortName">
                        <span className="">
                          {d?.regNumber?.length > 10
                            ? d?.regNumber.substring(0, 8) + ".."
                            : d?.regNumber}
                        </span>
                        {/* <p className="FontNBList"> {regName}</p> */}
                        {/* <div class=" FontSmall">{regName}</div> */}
                      </div>
                    </div>
                    <div
                      className="col-12 row Driver-gps"
                      style={{ color: "#9d9494" }}
                    >
                      <div
                        className="col-2"
                        style={{ paddingTop: "5px", width: "50px" }}
                      ></div>
                      <div
                        className="col-2"
                        style={{ width: "50px", padding: 0 }}
                      >
                        Driver:
                      </div>
                      <div className="col-6">{regName}</div>
                    </div>
                    <div className="col-12 row">
                      <div className="col-2" style={{ width: "50px" }}>
                        <SvgImage name="speed" />
                      </div>
                      <div
                        className="col-2"
                        style={{ width: "50px", padding: 0 }}
                      >
                        Speed:
                      </div>
                      <div className="col-6">{d?.location?.Speed}</div>
                    </div>
                    <div className="col-12 row">
                      <div className="col-2" style={{ width: "50px" }}>
                        <SvgImage name="locationPin" />
                      </div>
                      <div className="col-10" style={{ padding: 0 }}>
                        {d?.location?.Location}
                      </div>
                    </div>
                    <div className="col-12 row">
                      <div className="col-2" style={{ width: "50px" }}>
                        <SvgImage name="routes" width="22px" height="22px" />
                      </div>
                      <div className="col-10" style={{ padding: 0 }}>
                        Trip : {d?.tripNo || " N/A"} |
                        {d?.tripNo
                          ? ` ${d?.tripSource} To ${d?.tripDesc}`
                          : " N/A"}
                      </div>
                    </div>
                    <div className="col-12 row">
                      <div className="col-2" style={{ width: "50px" }}></div>
                      <div className="col-8" style={{ padding: 0 }}>
                        Start Since : {(d?.tripId && d?.startTime) ? moment(d?.startTime).format("DD-MM-YYYY"): "N/A"}
                      </div>
                    </div>
                    {/* <div className="col-12 row">
                      <div
                        className="col-2"
                        style={{ width: "50px" }}
                      >
                      </div>
                      <div
                        className="col-3"
                        style={{ padding: 0 }}
                      >
                        End Date : 
                      </div>
                    </div> */}
                  </div>

                  {/* <div class="div-table-col col-lg-5 FontNBList right">
                      <>
                        {d?.fastagBalance == null ? (
                          <div
                            to=""
                            onClick={() =>
                              handlenShowOpenLink(
                                d?.fleetId,
                                d?.fleetCategory,
                                d?.regNumber
                              )
                            }
                            className="fastagLinkingN"
                            style={{ marginTop: "-4px" }}
                          >
                            Issue FASTag
                          </div>
                        ) : (
                          <p
                            style={{
                              color: lowBalanceStatus ? "#FE2950" : "#25252D",
                              justifyContent: "center",
                            }}
                          >
                            {"₹ " +
                              (d?.fastagBalance === "" ? 0 : d?.fastagBalance)}
                          </p>
                        )}
                      </>
                    </div> */}

                  {/* <div class="div-table-col col-lg-2">
                      {loader == d?.fleetId ? (
                        <div
                          className=""
                          style={{
                            padding: 6,
                          }}
                        >
                          <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{
                              // backgroundColor:
                              //   color === i
                              //     ? "#FFFFFF"
                              //     : d?.isActive == 1
                              //     ? "white"
                              //     : "#FFFFFF",
                              border: 1,
                              padding: 6,
                            }}
                            onClick={() => {
                              setLoader(d?.fleetId);
                              dispatch(
                                fleetEditStatusAction(
                                  {
                                    fleetId: d?.fleetId,
                                    isActive: d?.isActive == 0 ? "1" : "0",
                                  },
                                  (res) => {
                                    setLoader("");
                                    if (res.status == 200) {
                                      dispatch(
                                        advancedVehicleListAction({
                                          offset: props.offset,
                                          limit: 15,
                                          search: props.search,
                                          minKyc: "",
                                          sortAs: "",
                                          sortBy: "",
                                          activeStatus: activeStatus,
                                          assignedStatus: assignedStatus,
                                        })
                                      );
                                    }
                                  }
                                )
                              );
                            }}
                          >
                            
                            {d?.isActive == 1 ? (
                              <SvgImage name="deleteIcon2" />
                            ) : (
                              <SvgImage name="restore" />
                            )}
                          </div>
                        </div>
                      )}
                    </div> */}
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="nextRow ">
        <div className="nextRowCont ">
          <button
            className="PrevButton"
            onClick={handlePrevButton}
            style={{ opacity: props.offset > 0 ? "1" : "0.5" }}
          >
            <SvgImage name="PrevArrow" heigth="12px" width="12px" />
          </button>
          <button
            className="nextButton"
            onClick={handleNextButton}
            style={{
              opacity: allFleetTracker?.count - props.offset > 15 ? "1" : "0.5",
            }}
          >
            <SvgImage name="NextArrow" heigth="12px" width="12px" />
          </button>
        </div>
      </div>
    </div>
  );
}
export default ManageVehicleDetailsCard;
