import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import "./index.css";
import { toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";
import { addToCartAction, getCartDetailsAction } from "../../../../services/Store/Actions/ecomAction";
import AddToCartModal from "./Components/AddToCartModal";
import ContactUs from "./Components/ContactUs";

function ProductDetails(props){
    
    const {handleClose,open, toggleModal, user, userIdSession, prodId, showModal1, showModal}= props;
    const [itemNo, setItemNo]= useState(1);
    const [cartBtn, setCartBtn]= useState("Add to cart");
    const productDetails= useSelector((state)=>state?.ecom?.productDetails);

    console.log("productDetails", productDetails)
    const images= productDetails?.images?.[0]?.productUrl;
    const productName= productDetails?.product?.productName;
    const mrp= productDetails?.product?.price;
    const discount= productDetails?.product?.discountNumber != null ?productDetails?.product?.discountNumber:0;
    const description= productDetails?.description;
    const [mrpShown, setMrpShown]= useState(mrp);
    const [discountShown, setDiscountShown]= useState(discount);
    
    const dispatch= useDispatch();

    const handleSub=()=>{
      if(itemNo>1){
        setItemNo(itemNo-1);
        setMrpShown((mrp)*(itemNo-1));
        setDiscountShown(((discount)*(itemNo-1)))
      }
    }

    const handleAdd=()=>{
      setItemNo(itemNo+1);
      setMrpShown((mrp)*(itemNo+1));
      setDiscountShown(((discount)*(itemNo+1)))
    }

    const customId = "custom-id-not-login page";
    const notify = () => toast.error("Kindly login to add items in the cart.",{
      toastId:customId
    });


    // open success modal
    const [openSuccess, setOpenSuccess]= useState(false);

    const closeModalSuccess=()=>{
    setOpenSuccess(false);
    }

    const showModalSuccess=()=>{
    setOpenSuccess(true);
    }

    const toggleModalSuccess=()=>{
    setOpenSuccess(!openSuccess);
    }

    // open customer support modal
    const [openContact, setOpenContact]= useState(false);

    const closeModalContact=()=>{
      setOpenContact(false);
    }

    const showModalContact=()=>{
      setOpenContact(true);
    }

    const toggleModalContact=()=>{
      setOpenContact(!openContact);
    }



    const addToCart=()=>{
        if(user != "null" && user != null){
          if (cartBtn == "Add to cart") {
            dispatch(addToCartAction({
              "userId": userIdSession,
              "productId": prodId,
              "quantity": String(itemNo)
            }, (res) => {
              console.log("Add to cart resp", res);
              if (res?.status == 200) {
                dispatch(getCartDetailsAction((res) => {
                  console.log("res", res);
                  if (res?.status == 200) {
                    showModalSuccess();
                    setCartBtn("Go to Cart");
                  }
                }
                ))
              }
            }))
          } else {
            toggleModal();
            showModal1();
          }
        }else{
          // notify();
          showModal();
        }
    }

    const handleBuyNow=()=>{
      if(user != "null" && user != null){
        dispatch(addToCartAction({
          "userId": userIdSession,
          "productId": prodId,
          "quantity": String(itemNo)
        }, (res) => {
          console.log("Add to cart resp", res);
          if (res?.status == 200) {
            dispatch(getCartDetailsAction((res) => {
              console.log("res", res);
              if (res?.status == 200) {
                toggleModal();
                showModal1();
              }
            }
            ))
          }
        }))
        } else{
          // notify();
          showModal();
        }
    }

    useEffect(()=>{
      setItemNo(1);
      setCartBtn("Add to cart");
    },[open])


    return (
        <div>
          <Modal
            isOpen={open}
            onHide={()=>{
            //   setStatus(false);
              handleClose();
            }}
            className="positon settingModalMargin"
            toggle={()=>{
            //   setStatus(false);
              toggleModal();
            }}
            aria-labelledby="myModalLabel2"
          >
            <div
              className="modal-content"
              style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
            >
              <div className="modal-head-part">
                <p className="Add-balance-title">Product Detail</p>
                <Button
                  type="button"
                  className="close-btn"
                  onClick={()=>{
                    // dispatch(sendAnalitics("close_OwnerAcc_Home_web"));
                    // setStatus(false);
                    toggleModal();
                  }}
                  aria-label="Close"
                >
                  <SvgImage name="CloseButtonLogo" />
                </Button>
              </div>
              <ModalBody className="padding0L">
                 <div>
                    <div className="detailImgContainer" >
                        <div className="imageColumn">
                            <img src={images} className="imgTyre"
                            style={{ height: "100%", alignItems: "centre" }} />
                        </div>
                    </div>
                    <div className=" infoColumn">
                    <div className="Bold heading2">{productName}</div>
                    <div className="prodPrices">
                        <div className="Bold heading3"> ₹{(itemNo>1)?(mrpShown - discountShown):(mrp-discount)} </div>
                        <div className="prPrice"> ₹{(itemNo>1)?(mrpShown):mrp} </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-lg-8 col-md-8 col-sm-6 col-6 prCoins Bold">Or Pay ₹{(itemNo>1)?(mrpShown - discountShown - Math.floor(.025 * Number(mrpShown - discountShown))):(mrp - discount - Math.floor(.025 * Number(mrp - discount)))} 
                        + <SvgImage name="coins" /> {(itemNo>1)?Math.floor(.025 * Number(mrpShown - discountShown)):Math.floor(.025 * Number(mrp - discount))}

                        </div> */}
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6 quantityContainer">
                        <div className="row quantityDiv">
                            <div className="subIcon col-4  " onClick={handleSub}><SvgImage name="sub" /></div>
                            <div className="col-4 Bold heading3">{itemNo}</div>
                            <div className="addIcon col-4  " onClick={handleAdd}><SvgImage name="add" /></div>
                        </div>
                        </div>
                    </div>
                    <div className="prDelivery" style={{paddingBottom:"0.5rem"}}>
                        <SvgImage name="deliveryTruck" /> Delivery Available
                    </div>
                    <div className="prDelivery" style={{paddingTop:"0rem", cursor:"pointer"}} onClick={showModalContact}>
                        Have a better price? <span className="greenFont" style={{textDecoration:"underline"}}>Contact us!</span>
                    </div>
                    <div className="prSpec"> Specifications</div>
                    <div className="row specContainer">
                        <table className="specTable">
                          {
                            description?
                            Object.keys(description).map(arr => {
                              return (<tr><th>{arr}</th><td>{description[arr]}</td></tr>)
                          }):<></>
                          }
                        {/* {Object.keys(description).map(arr => {
                            return (<tr><th>{arr}</th><td>{description[arr]}</td></tr>)
                        })} */}
                        </table>
                    </div>
                    {
                        <div className="row btnPadding">
                        <div className="col-6">
                            <button className="quoteButtonLight" onClick={addToCart}>{cartBtn}</button>
                        </div>
                        <div className="col-6">
                            <button className="quoteButtonDark1" onClick={handleBuyNow} >Buy Now</button>
                        </div>
                        </div>
                    }
                    </div>
                </div>
              </ModalBody>
            </div>
          </Modal>
          <AddToCartModal
          open={openSuccess}
          onHide={closeModalSuccess}
          toggleModal={toggleModalSuccess}
          handleClose={closeModalSuccess}
          />
          <ContactUs 
          open={openContact}
          onHide={closeModalContact}
          toggleModal={toggleModalContact}
          handleClose={closeModalContact}
          prodId={prodId}
          prodName={productName}
          />
        </div>
      );    
}

export default ProductDetails;