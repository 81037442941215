import React from "react";
import { useState, useEffect } from "react";
import ManageLogipeCardType1 from "../../ManageLogipeCard/SubComponents/LogipeCardTopBalanceCard";
import SvgImage from "../../Icons/Icons";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { Divider } from "@material-ui/core";
import { Button, Table } from "reactstrap";
import AddNewCustomerButton from "../../../Subcomponent/AddCustomerButton";
import AddFuelNewPhone from "../../Modals/AddFuelNewPhone";
import OMCLoadModal from "../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCLoadModal";
import FuelWalletHistory from "../../../Subcomponent/FuelWalletHistory";
import FuelWalletRecharge from "./FuelWalletRecharge";
import {
  fuelWalletLimitUpdate,
  getFuelWalletBal,
  getFuelWalletHistory,
} from "../../../services/Store/Actions/omcActions";
import Toast from "../../../Components/subComponents/toast";
import { toastStatus } from "../../../services/Store/Actions/appActions";

import Svg from "../../Icons/svg";

let omcData = [
  {
    type: "hpcl",
  },
  {
    type: "jio",
  },
  { type: "bpcl" },
];

function FuelMain(props) {
  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);
  const [offset, setOffset] = useState(offsetVal);
  const [openAddPhone, setOpenAddPhone] = useState(false);
  const [openFuel, setOpenFuel] = useState(false);
  const [limtt, setLimit] = useState(false);
  const [value, setValue] = useState(0);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const [openFuelWalletHistory, setOpenFuelWalletHistory] = useState(false);
  const { fuelWalletBalance, fuelWalletNumbers } = useSelector(
    (state) => state?.omc
  );

  console.log("opopopppppp", toastObject, fuelWalletNumbers);
  const dispatch = useDispatch();

  const toggleFuelWalletHistory = () => {
    setOpenFuelWalletHistory(!false);
  };
  const CloseFuelWalletHistory = () => {
    setOpenFuelWalletHistory(false);
  };
  const handleAddPhoneClose = () => {
    setOpenAddPhone(false);
  };
  const toggleAddPhone = () => {
    setOpenAddPhone(!openAddPhone);
  };
  const handleCloseFuel = () => {
    setOpenFuel(false);
  };

  const toggleModalFuel = () => {
    setOpenFuel(!openFuel);
  };
  const { sideBar } = props;
  const handlePrevButton = () => {
    if (offset > 0) {
      setOffset(offset - 10);
      dispatch();
      // getAllCustomersAction({
      //   offset: offset - 10,
      //   companyName: companyName,
      //   minKyc: "",
      //   sortAs: "",
      //   sortBy: "",
      //   // limit: "12",
      // })
    }
  };

  const handleNextButton = () => {
    // if (customerNo - offset > 10) {
    setOffset(offset + 10);
    dispatch();
    // getFuelWalletBal({
    //   offset: offset + 10,
    //   phone: phone,
    //   minKyc: "",
    //   sortAs: "",
    //   sortBy: "",
    // })
    // }
  };

  useEffect(() => {
    dispatch(getFuelWalletBal({}));
    dispatch(
      getFuelWalletHistory({
        offset: 0,
        limit: 10,
      })
    );
  }, []);

  return (
    <div
      className="main-home topMargin whiteBackground sideBarMargin"
      style={{ marginLeft: sideBar ? "230px" : "100px" }}
    >
      <div className="row">
        <div className="col-lg-12 fulebox-1 col-md-12">
          <div className="topCardContainer row padding16L padding16R">
            <div className="custText">Fuel Wallet</div>
            <div className="col-3">
              <div className="col-12">
                <div
                  className={"card-wallet-body2 row "}
                  style={{ paddingLeft: "15px", paddingTop: "20px" }}
                >
                  <div classname="col-4" style={{ width: "30%" }}>
                    <SvgImage name="modalWallet" classname="bank-card-wallet" />
                  </div>
                  <div
                    className="col-5 row"
                    style={{
                      position: "relative",
                      left: "-10px",
                      width: "9.4rem",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  >
                    <div
                      className="card-wallet-sub-body-new"
                      style={{ height: 0 }}
                    >
                      Wallet Balance{" "}
                    </div>
                    <div className="card-wallet-sub-body-new">
                      {<div>₹{fuelWalletBalance?.balance || "0"}</div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-3 FullCustomerListAddButton"
              onClick={toggleAddPhone}
              style={{ marginTop: "0.8rem" }}
            >
              <AddNewCustomerButton name="Add New Phone" />
            </div>
            <div
              className="col-3 FullCustomerListAddButton"
              onClick={toggleModalFuel}
              style={{ marginTop: "0.8rem" }}
            >
              <AddNewCustomerButton name="  Wallet Recharge" />
            </div>

            <div
              className="col-3  flexStyling heading3 semiBold pointing padding0R padding32L fW-history"
              style={{ paddingRight: "1.4rem", justifyContent: "flex-end" }}
              onClick={() => toggleFuelWalletHistory()}
            >
              <button
                className="greenButton"
                style={{ marginTop: "2rem", marginRight: "2rem" }}
              >
                Wallet History
                <SvgImage
                  style={{ marginTop: "-0.1rem" }}
                  name="ForwardArrowNew"
                  height="32"
                  width="32"
                />
              </button>
            </div>
            <Table
              className="CustSectionTable"
              // id="CustSectionTable1"
              bordered="true"
            >
              <div class="container shadow min-vh-100 py-2">
                <div class="table-responsive">
                  <table class="table accordion">
                    <thead>
                      <tr className="table-header-cust">
                        <th style={{ width: "5.5%" }}>S.No</th>
                        <th style={{ width: "4.9%" }}>Phone </th>
                        <th style={{ width: "4.8%" }}>Name</th>
                        {/* <th style={{ width: "6%" }}>Last Set Limit</th>                       
                                        <th style={{ width: "6%" }}>Available Limit</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {fuelWalletNumbers?.rows?.map((data, idx) => {
                        return (
                          <>
                            <tr data-bs-toggle="collapse" data-bs-target="#r1">
                              <th scope="row">
                                {idx + 1}
                                <i
                                  className="bi bi-chevron-down"
                                  style={{ color: "black" }}
                                >
                                  {" "}
                                </i>
                              </th>
                              <td>{data?.phone}</td>
                              <td>{data?.fullName}</td>
                              {/* <td>500</td>
                                                <td>1500</td> */}
                            </tr>
                            <tr
                              class="collapse accordion-collapse"
                              id="r1"
                              data-bs-parent=".table"
                            >
                              <table>
                                <thead
                                  style={{
                                    backgroundColor: "#dce2ed",
                                    borderRadius: "20px",
                                    border: "none",
                                  }}
                                >
                                  <th>Program</th>
                                  <th>Available Limit</th>
                                  <th>Last Limit</th>
                                  <th>Edit Limit</th>
                                </thead>
                                <tbody>
                                  {omcData?.map((e, idx) => {
                                    return (
                                      <>
                                        <tr>
                                          <th>{e?.type?.toUpperCase()}</th>
                                          <td>
                                            {e?.type == "hpcl"
                                              ? data?.hpcl == "-1"
                                                ? "N/A"
                                                : data?.hpcl
                                              : e?.type == "jio"
                                              ? data?.jio == "-1"
                                                ? "N/A"
                                                : data?.jio
                                              : e?.type == "bpcl"
                                              ? data?.bpcl == "-1"
                                                ? "N/A"
                                                : data?.bpcl
                                              : ""}
                                          </td>
                                          <td>
                                            {e?.type == "hpcl"
                                              ? data?.hpcl == "-1"
                                                ? "N/A"
                                                : data?.hpclLastSet
                                              : e?.type == "jio"
                                              ? data?.jio == "-1"
                                                ? "N/A"
                                                : data?.jioLastSet
                                              : e?.type == "bpcl"
                                              ? data?.bpcl == "-1"
                                                ? "N/A"
                                                : data?.bpclLastSet
                                              : ""}
                                          </td>
                                          <td>
                                            {limtt ? (
                                              <div className="row">
                                                <input
                                                  style={{ width: "9rem" }}
                                                  type="text"
                                                  name="setLimit"
                                                  placeholder="set Limit"
                                                  className="col-6"
                                                  onChange={(e) => {
                                                    setValue(e?.target?.value);
                                                  }}
                                                />
                                                <Button
                                                  className="col-2"
                                                  style={{
                                                    fontSize: "12px",
                                                    backgroundColor: "#FFFFFF",
                                                  }}
                                                  onClick={() => {
                                                    let reqData = {
                                                      phone: data?.phone,
                                                    };
                                                    reqData[e?.type] = value;
                                                    dispatch(
                                                      fuelWalletLimitUpdate(
                                                        reqData,
                                                        (res) => {
                                                          setLimit(false);
                                                          console.log(
                                                            "resssssspppp"
                                                          );
                                                        }
                                                      )
                                                    );
                                                  }}
                                                >
                                                  <SvgImage name="correct" />
                                                </Button>
                                                <Button
                                                  className="col-2"
                                                  style={{
                                                    fontSize: "12px",
                                                    backgroundColor: "#FFFFFF",
                                                  }}
                                                  onClick={() => {
                                                    setLimit(false);
                                                  }}
                                                >
                                                  <SvgImage name="cancel" />
                                                </Button>
                                              </div>
                                            ) : (
                                              <button
                                                className="greenButton"
                                                disabled={
                                                  e?.type == "hpcl" && data?.hpcl == "-1" ? true : 
                                                    e?.type == "jio"
                                                    && data?.jio == "-1"
                                                      ? true : 
                                                    e?.type == "bpcl"
                                                    && data?.bpcl == "-1"
                                                      ? true : false
                                                }
                                                onClick={() => {
                                                  setLimit(true);
                                                }}
                                              >
                                                Edit Limit
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Table>
            <div className="nextRow ">
              <div className="nextRowCont ">
                <button
                  className="PrevButton"
                  onClick={handlePrevButton}
                  style={{ opacity: offset > 0 ? "1" : "0.5" }}
                >
                  <SvgImage name="PrevArrow" heigth="12px" width="12px" />
                </button>
                <button
                  className="nextButton"
                  onClick={handleNextButton}
                  style={
                    {
                      //   opacity: customerNo - offset > 10 ? "1" : "0.5",
                    }
                  }
                >
                  <SvgImage name="NextArrow" heigth="12px" width="12px" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddFuelNewPhone
        toggleModal={toggleAddPhone}
        modal={openAddPhone}
        handleClose={handleAddPhoneClose}
      />
      <FuelWalletRecharge
        // userId={userId}
        open={openFuel}
        toggleModal={toggleModalFuel}
        handleClose={handleCloseFuel}
      />
      <FuelWalletHistory
        handleClose={CloseFuelWalletHistory}
        isOpen={openFuelWalletHistory}
        onHide={CloseFuelWalletHistory}
        toggle={toggleFuelWalletHistory}
        setOffset={setOffset}
        offset={offset}
      />
      {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
          }}
          isOpen={toastObject?.toastStatus}
          name="fuelMain"
          handleClose={() => {
            console.log("jkjkkkkkkk");
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            window.location = "/fuelMain/";
            //alert("close button clicked");
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default FuelMain;
