import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { neftLinkedAccountsListAction } from "../../../../services/Store/Actions/masterWalletActions";
import SvgImage from "../../../Icons/Icons";
import AddNewAccount from "../../../Modals/addNewAccount";

const CompleteKYC=()=>{

    const neftLinkedAccountsList= useSelector((state)=>state?.masterWallet?.neftLinkedAccountsList);
    const navigate= useNavigate();
    const dispatch= useDispatch();
    const handleKycComplete=()=>{
        navigate("/SignUp");
    }

    useEffect(()=>{
        dispatch(neftLinkedAccountsListAction());
      },[]);

    const [addBeneficiary, setAddBeneficiary] = useState(false);
    const handleAddBeneficiary=()=>{
        setAddBeneficiary(true);
        //toggleModal();
    }
    const handleCloseAddBeneficiary=()=>{
        setAddBeneficiary(false);
    }
    const toggleModalAddBeneficiary=()=>{
        setAddBeneficiary(!addBeneficiary);
    }

    console.log("test neftLinkedAccountsList ",neftLinkedAccountsList);
    return (
    <div className="padding16 whiteBackground roundCorner padding24T" style={{height:"100%"}}>
        <div className="headingLow semiBold padding24B">Master Account</div>
        <div className="row">
            <div className={neftLinkedAccountsList?.[0]?" col-12":" col-7 padding8R padding8L"}>
                <div className="yellowBanner">
                    <div className="padding8T">
                        <SvgImage name="completeKYC" />
                    </div>
                    <div className="Bold heading2 padding16T">Unlock all benefits by completing KYC</div>
                    <div className="flexStyling greenFont semiBold heading3 padding8T BtnCursor" onClick={handleKycComplete}>
                    Complete KYC <div className="padding16L"><SvgImage name="linkArrow" /></div>
                    </div>
                    <div className="greyFont text4 padding24R padding8T" style={{textAlign:"right"}}>80% Done</div>
                    <div>
                        <SvgImage name="statusBar" />
                    </div>
                </div>
            </div>
            {
                !neftLinkedAccountsList?.[0]?
                <div  className="col-5 padding8L" style={{textAlign:"center"}}>
                <div className="connectDiv">
                    <div>
                        <SvgImage name="vaultImg" />
                    </div>
                    <div className="greyFont Regular heading4 padding16T">Connect bank account to start</div>
                    <div className="greyFont Regular heading4">collecting funds</div>
                    <div className="padding16T">
                        <button type="button" className="greenButton Medium text3" onClick={handleAddBeneficiary}
                        >
                        Connect bank account
                        </button>
                    </div>
                </div>
                </div>
                :<></>
            }
        </div>
        
        <AddNewAccount
            open={addBeneficiary}
            toggleModal={toggleModalAddBeneficiary}
            handleClose={handleCloseAddBeneficiary}
            //handleClick={handleClick}
            //btn={btn}
            ownerLogipeBalance={"KYC Not Done"}
            caller="neftBankLink"
          />
    </div>
    )
}

export default CompleteKYC;