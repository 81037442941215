import { call, put, takeEvery } from "redux-saga/effects";
import {
  getOwnerFetchBalanceAPI,
  fetchCashBackBalanceAPI,
  getOwnerCardDetailsAPI,
  getCashbackListApi,
  withdrawFromCardApi,
  transferToCardApi,
  changePinApi,
  getRecentCardTransactionApi,
  newWithdrawApi,
  ownerCardTypeApi
} from "../..//Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import {
  fetchBalanceSuccess,
  fetchCashBackBalanceSuccess,
  getOwnerCardDetailsSuccess,
  getCashbackListSuccess,
  withdrawFromCardSuccess,
  transferToCardSuccess,
  changePinSuccess,
  getRecentCardTransactionSuccess,
  newWithdrawMoneySuccessAction,
  ownerCardActionSuccess
} from "../Actions/manageCardActions";
import {
  FETCH_BALANCE,
  FETCH_CASH_BACK_BALANCE,
  OWNER_CARD_DETAILS,
  GET_CASHBACK_LIST,
  TRANSFER_TO_CARD,
  WITHDRAW_FROM_CARD,
  CHANGE_PIN,
  RECENT_CARD_TRANSACTIONS,
  NEW_WITHDRAW_MONEY,
  GET_OWNERCARD,
} from "../storeTypes";

import { toastStatus } from "../../../services/Store/Actions/appActions";

function* getOwnerFetchBalanceSaga() {
  try {
    const response = yield call(getOwnerFetchBalanceAPI);
    if (response.data.type == "success") {
      yield put(fetchBalanceSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "getOwnerFetchBalanceSaga Error.Response:- ",
      error.response.data
    );
    //Alert.alert('get Owner Summary API Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* fetchCashBackBalanceSaga() {
  try {
    const response = yield call(fetchCashBackBalanceAPI);
    if (response?.data?.type == "success") {
      yield put(fetchCashBackBalanceSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("fetchCashBackBalanceSaga errpr:- ", error?.response?.data);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getOwnerCardDetailsSaga(action) {
  try {
    const response = yield call(getOwnerCardDetailsAPI, action?.payload);
    if (response?.data?.type == "success") {
      yield put(getOwnerCardDetailsSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "getOwnerCardDetailsSuccess Error.Response:- ",
      error?.response?.data
    );
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.LONG);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getCashbackListSaga(action) {
  try {
    console.log("inside sage for users", action);
    //console.log("api used", getUserDetailsApi);
    const response = yield call(getCashbackListApi, action?.payload);
    console.log("saga for all cards");
    console.log("response all cards", response);
    if (response?.status == 200) {
      console.log("sagA", response?.data);
      yield put(getCashbackListSuccess(response?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    console.log("inside sage for errors in users");
    console.log("Error.Response getUSerDetails:- ", error.response?.data);
  }
}

function* transferToCardSaga(action) {
  try {
    const response = yield call(transferToCardApi, action.payload, action.ID);
    // console.log("ADD money from saga", response);
    if (response.data.type == "success") {
      //console.log("hurrayyyy");
      action.callback(response);
      yield put(transferToCardSuccess(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Money was added to card successfully`,
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* withdrawFromCardSaga(action) {
  try {
    const response = yield call(withdrawFromCardApi, action.payload, action.ID);
    // console.log("ADD money from saga", response);
    if (response.data.type == "success") {
      //console.log("hurrayyyy");
      action.callback(response);
      yield put(withdrawFromCardSuccess(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Money was withdrawn from card successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* changePinSaga(action) {
  try {
    console.log("change pin saga");
    const response = yield call(changePinApi, action?.payload);
    console.log("response", response);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(changePinSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "getOwnerCardDetailsSuccess Error.Response:- ",
      error?.response?.data
    );
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.LONG);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* recentCardTransactionsSaga(action) {
  try {
    console.log("change pin saga");
    const response = yield call(getRecentCardTransactionApi, action?.payload);
    console.log("response", response);
    if (response?.data?.type == "success") {
      yield put(getRecentCardTransactionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "recentCardTransactionsSaga Error.Response:- ",
      error?.response?.data
    );
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.LONG);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}



function* newWithdrawMoneySaga(action) {
  try {
    const response = yield call(newWithdrawApi, action.payload, action.ID);
    console.log("withdraw money from saga", response);
    if (response.data.type == "success") {
      //console.log("hurrayyyy");
      action.callback(response);
      yield put(newWithdrawMoneySuccessAction(response));
      // yield put(
      //   toastStatus({
      //     toastStatus: true,
      //     message: "Money was withdrawn from card successfully",
      //     status: true,
      //   })
      // );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    // yield put(
    //   toastStatus({
    //     toastStatus: true,
    //     message: `Error : ${error?.response?.data.message}`,
    //     status: false,
    //   })
    // );
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* getOwnerCardTypeSaga(action) {
  try {
    const response = yield call(ownerCardTypeApi, action?.payload);
    if (response?.data?.type == "success") {
      yield put(ownerCardActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.LONG);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


export function* watchManageSummarySagas() {
  yield takeEvery(FETCH_BALANCE, getOwnerFetchBalanceSaga);
  yield takeEvery(FETCH_CASH_BACK_BALANCE, fetchCashBackBalanceSaga);
  yield takeEvery(OWNER_CARD_DETAILS, getOwnerCardDetailsSaga);
  yield takeEvery(GET_CASHBACK_LIST, getCashbackListSaga);
  yield takeEvery(TRANSFER_TO_CARD, transferToCardSaga);
  yield takeEvery(WITHDRAW_FROM_CARD, withdrawFromCardSaga);
  yield takeEvery(CHANGE_PIN, changePinSaga);
  yield takeEvery(RECENT_CARD_TRANSACTIONS, recentCardTransactionsSaga);
  yield takeEvery(NEW_WITHDRAW_MONEY,newWithdrawMoneySaga);
  yield takeEvery(GET_OWNERCARD,getOwnerCardTypeSaga);
}
