import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { M2PTransactionsAction, M2PTransactionsCleanAction, m2pWalletHistoryDownloadAction } from "../../../../../../services/Store/Actions/m2pAction";
import NoTransaction from "./NoTransaction";
import SvgImage from "../../../../../Icons/Icons";
import AddingFilter from "../../../../../Modals/addingFilter";
import ClipLoader from "react-spinners/ClipLoader";

const M2PCardTable=(props)=>{

  const{setCardChosen, cardChosen}= props;
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState(0);
  const [start, setStart]= useState("");
  const [end, setEnd]= useState("");
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const cardExpense = useSelector((state) => state?.driverlist);
  const m2pTransactionLoader = useSelector(
    (state) => state?.m2p?.loader
  );
  const m2pTransactionHistory = useSelector(
    (state) => state?.m2p?.m2pTransactions
  );
  const trxnCount =
    m2pTransactionHistory?.count === undefined
      ? 0
      : m2pTransactionHistory?.count;

  let { id } = useParams();
  const dispatch= useDispatch();

  useEffect(() => {
    if (id !== "" && id !== undefined && id !== null) {
      setOffset(0);
      dispatch(M2PTransactionsCleanAction());
      dispatch(
        M2PTransactionsAction(id, {
          offset: 0,
          startDate: "",
          endDate: "",
          sortAs: "",
          sortBy: "",
        })
      );
    } else {
      setOffset(0);
      dispatch(M2PTransactionsCleanAction());
    }
  }, [id]);


  console.log("mmmmmmmmmmmmm", m2pTransactionHistory);
  const closing1 = () => {
    setActive1(false);
  };

  const toggling1 = () => {
    setActive1(!active1);
  };

  const showing1 = () => {
    setActive1(true);
  };

  const closing2 = () => {
    setActive2(false);
  };

  const toggling2 = () => {
    setActive2(!active2);
  };

  const showing2 = () => {
    setActive2(true);
  };

  const filterFunc = (startDate, endDate) => {
    setOffset(0);
    setFilter(1);
    setStart(startDate);
    setEnd(endDate);
    console.log("start date", startDate);
    console.log("end date", endDate);
      dispatch(M2PTransactionsCleanAction());
      dispatch(
        M2PTransactionsAction(id, {
          offset: 0,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          sortAs: "",
          sortBy: "",
        })
      );
  };


  const downloadFunc = (startDate, endDate, mode) => {
    console.log("start date", startDate);
    console.log("end date", endDate);
  
      dispatch(
        m2pWalletHistoryDownloadAction(
          id,
          {
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
            mode: mode,
          },
          (res) => {
            if (res?.data?.type === "success") {
              window.open(res?.data?.data?.url);
            }
          }
        )
      );
  };


  const disableFilter = () => {
    setOffset(0);
    setFilter(0);
    setStart("");
    setEnd("");
      dispatch(M2PTransactionsCleanAction());
      dispatch(
        M2PTransactionsAction(id, {
          offset: 0,
          startDate: "",
          endDate: "",
          sortAs: "",
          sortBy: "",
        })
      );
  };

  const handlePrev=()=>{
    if(offset>0){
      //setOffset(offset-10);
      if (filter===0){
        // dispatch(cleanFleetTransactionHistory());
        dispatch(
          M2PTransactionsAction(id, {
            offset: offset-10,
            startDate: "",
            endDate: "",
            sortAs: "",
            sortBy: "",
          }, (res)=>{
            if(res?.status==200){
              setOffset(offset-10);
            }
          })
        );
      }
      else {
        console.log ("fastag-> filter",start,end);
        // dispatch(cleanFleetTransactionHistory());
        dispatch(
          M2PTransactionsAction(id, {
            offset:offset-10,
            startDate:moment(start).format("YYYY-MM-DD"),
            endDate:moment(end).format("YYYY-MM-DD"),
          },(res)=>{
            if(res?.status==200){
              setOffset(offset-10);
            }
          })
        );
      }
    }
  }

  const handleNext=()=>{
    if(trxnCount-offset>10){
      if (filter===0){
        // dispatch(cleanFleetTransactionHistory());
        dispatch(
          M2PTransactionsAction(id, {
            offset:offset+10,
            startDate: "",
            endDate: "",
          }, (res)=>{
            if(res?.status==200){
              setOffset(offset+10);
            }
          })
        );
      }
      else {
        console.log ("fastag-> filter",start,end);
        // dispatch(cleanFleetTransactionHistory());
        dispatch(
          M2PTransactionsAction(id, {
            offset:offset+10,
            startDate:moment(start).format("YYYY-MM-DD"),
            endDate:moment(end).format("YYYY-MM-DD"),
          }, (res)=>{
            if(res?.status==200){
              setOffset(offset+10);
            }
          })
        );
      }
    }
  }

  
  return (
    <>
    <div className=" flexStyling new-row-for-download padding16R padding16T" >
        <div className="Bold heading2 padding16 " style={{paddingLeft:"0"}}>Transaction History</div>
        <div className="flex1"></div>
        <div className="filterText" onClick={()=>{
          if(trxnCount!=undefined){
            if(trxnCount!=0){
              showing1();
            }
          }
        }}>
            Filter
            <div className="filterImg">
                <SvgImage name="filterIcon" />
            </div>
        </div>
        {
        <AddingFilter
          active={active1}
          toggleModal={toggling1}
          handleClose={closing1}
          func={filterFunc}
          name="Filter"
        />
        }
        <div className=" downloadText" onClick={()=>{
          if(trxnCount!=undefined){
            if(trxnCount!=0){
              showing2();
            }
          }
        }}>
            Download Statement
            <div className="padding8L" >
            <SvgImage name="downloadImg" />
            </div>
            
        </div>
        {
        <AddingFilter
          active={active2}
          toggleModal={toggling2}
          handleClose={closing2}
          func={downloadFunc}
          name="Download History"
        />
        }   
    </div>
    <div className="row margin0 padding16B padding0L">
        <div className="col-2n5 padding0L" onClick={()=>{
            setCardChosen(0)
        }}>
            <Button className={cardChosen==0?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
                Pre-paid Card</Button>
        </div>
        {/* <div className="col-2n5"  onClick={()=>{
            setCardChosen(1)
        }}>
            <Button className={cardChosen==1?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
                HPCL Fuel Card</Button>
        </div> */}
        <div className="filterDates col-7">
            <div className="heading3 Bold">Filter Applied: </div>
            <div className="FontNB" style={{ paddingLeft: "8px" }}>
              {moment(start).format("DD-MM-YYYY") == "Invalid date"
                ? "None"
                : moment(start).format("DD-MM-YYYY") + " to"}{" "}
              {moment(end).format("DD-MM-YYYY") == "Invalid date"
                ? ""
                : moment(end).format("DD-MM-YYYY")}
            </div>
            <div
              style={{
                paddingLeft: "8px",
                position: "relative",
                top: "-3px",
                cursor: "pointer",
                visibility:moment(start).format("DD-MM-YYYY") == "Invalid date"?"hidden":"visible"
              }}
              onClick={disableFilter}
            >
              <SvgImage
                classname="close-btn-text"
                // style={{ width: "10%", height: "10%" }}
                name="CloseButtonLogo1"
                width="20px"
                height="20px"
              />
            </div>
          </div>
      </div>
    {
      trxnCount==0?
      <NoTransaction />
      :
      m2pTransactionLoader ? (
          <div style={{textAlign:"center"}}>
        <ClipLoader
          color={"#485B7C"}
          loading={m2pTransactionLoader}
          size={40}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        </div>
      ) : trxnCount == undefined ? (
        <NoTransaction />
      ):
      <div>
        
      <Table className="DriverSectionTable" bordered="true" id="driver_tables">
          <thead >
          <tr className="table-header-customer-details-dr">
              <th style={{width:"15%"}} >Date & Time</th>
              <th > <div style={{marginLeft:"rem"}}>Transaction Details</div> </th>
              <th>TxnRefNo</th>
              <th style={{width:"15%"}}>Debit (INR)</th>
              <th style={{width:"15%"}}>Credit (INR)</th>
              {/* <th style={{width:"19%"}}>Closing Balance (INR)</th> */}
          </tr>
          </thead>
          <tbody className="lowerBorderLine">
          {m2pTransactionHistory?.rows?.map((d) => {
            let remarks=d?.txnRefNo;
            let details= d?.description;
            let amount=d?.amount;
            let type=d?.transferType;
            let date=moment(d?.transactionDate.split("T")[0], [
              "YYYY-MM-DD",
            ]).format("DD-MM-YYYY");
            let time=moment(d?.transactionDate.split("T")[1], [
              "hh:mm:ss",
            ]).format("hh:mm:ss a");

              return (
                <tr className="table-row-manage-driver-new text4">
                  <td style={{width:"15%"}}>
                    <div>{date}</div>
                    <div>{time}</div>
                  </td>
                  <td>{details}</td>
                  <td>{remarks}</td>
                  <td style={{width:"15%"}}>{type=="debit"?amount:"-"}</td>
                  <td style={{width:"15%"}}>{type=="credit"?amount:"-"}</td>
                  {/* <td style={{width:"19%"}}>NA</td> */}
                </tr>
              );
            })} 
          </tbody>
        </Table>
        <div className="row margin0 padding16" >
          <div className="col-4" style={{textAlign:"right", paddingTop:"0.1rem", cursor:offset>0?"pointer":"no-drop" , opacity:offset>0?"1":"0.5"}}
            onClick={handlePrev}
            >
            <SvgImage name="PrevArrow" />
          </div>
          <div className="col-1 greenFont semiBold heading4 padding0L" style={{textAlign:"right", paddingTop:"0.3rem", cursor:offset>0?"pointer":"no-drop", opacity:offset>0?"1":"0.5"}} 
          onClick={handlePrev}>
              Previous
          </div>
          <div className="col-2 row margin0">
            <div className="greenBoxCount Medium heading4 col-4" style={{width:"2rem", height:"2rem", alignItems:"center", textAlign:"center"}}>{(offset/10)+1}</div>
            <div className=" col-8 padding0R"  style={{ paddingTop:"0.3rem", textAlign:"right"}}>of  {trxnCount%10==0?Math.floor((trxnCount/10)):Math.floor((trxnCount/10)+1)}</div>
          </div>
          <div className="col-1 greenFont semiBold heading4 padding0R" style={{textAlign:"left", paddingTop:"0.3rem", cursor:trxnCount-offset>10?"pointer":"no-drop",opacity:trxnCount-offset>10?"1":"0.5"}}
            onClick={handleNext}>
              Next
          </div>
          <div className="col-4 padding0L" style={{textAlign:"left", marginLeft:"-1rem", paddingTop:"0.1rem", cursor:trxnCount-offset>10?"pointer":"no-drop",opacity:trxnCount-offset>10?"1":"0.5"}}
              onClick={handleNext}>
            <SvgImage name="NextArrow" />
          </div>
      </div>
        </div>
            }
    </>
  );
}
    


export default M2PCardTable;