import React, { useEffect, useState, useRef } from "react";
import { DatePickerField } from "../../core/inputs/input";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { ToggleSlider } from "react-toggle-slider";
import moment from "moment";
import AddingFilter from "../../Modals/addingFilter";
import { useDispatch, useSelector } from "react-redux";
import { storeMedia } from "../../../services/Store/Actions/appActions";
import UploadBack from "./UploadBack";
import UploadFront from "./UploadFront";
import { fastagAddCustomerAction } from "../../../services/Store/Actions/fastagAction";

function LinkFastagDocDetails(props) {
  const {
    open,
    toggleModal,
    handleClick,
    handleClose,
    btn,
    userId,
    setDisplayName,
    displayName,
    handleShowIssue
  } = props;
  console.log(userId);
  const dispatch = useDispatch();

  const [active, setActive] = useState("1");
  const [form1, setForm1] = useState({});
  const { loader } = useSelector((state) => state?.fastag);
  console.log("button status", loader);
  let title = active == "1" ? "KYC Verification" : "Document Details";

  useEffect(() => {
    console.log("active val", active);
    console.log("Form-----", form1);
  }, [active]);


  const requestId= useSelector((state)=>state?.fastag?.otpFastagVerify?.requestId);
  console.log("requestId-----", requestId);
  const datepickerRef = useRef();
  const handleClickDatepickerIcon=()=>{
    const datepickerElement = datepickerRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  }
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">{title}</p>
            <div className="padding16" style={{paddingTop:"20px"}}>
              <SvgImage name="idfcBankTag" />
            </div>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                setActive("1");
                toggleModal();
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          {/* <div>
          <div className="sub-title-1">
            <span class="circle-logo">1</span>
            <p className="sub-head-3">Select One</p>
          </div>
          <button
            type="button"
            className={btn === 5 ? "butt1 mt-0" : "set-btn-1 mt-0"}
            onClick={(e) => handleClick(5)}
          >
            {btn === 5 ? <SvgImage name="TickLogo" /> : <></>}Add Expense
          </button>
          <button
            type="button"
            className={btn === 6 ? "set-btn-2 mt-0" : "butt2 mt-0"}
            onClick={() => handleClick(6)}
          >
            {btn === 6 ? <SvgImage name="TickLogo" /> : <></>}Cash Given
          </button>
        </div> */}
          <ModalBody>
            {active === "1" ? (
              <Formik
                enableReinitialize
                initialValues={{
                  firstName: "",
                  lastName: "",
                  gender: "default",
                  dob: new Date(),
                  address: "",
                  pincode: "",
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string().required("First name is required"),
                  lastName: Yup.string().required("Last name is required"),
                  gender: Yup.string()
                    .required("Gender is required")
                    .test(
                      "noDefaultGender",
                      "Please select gender",
                      (val) => val != "default"
                    ),
                  dob: Yup.string().required("Date of birth is required"),
                  address: Yup.string().required("Address is required"),
                  pincode: Yup.number().typeError("Only Numbers are allowed").required("Pin code is required"),
                  // password: Yup.string()
                  //   .min(6, 'Password must be at least 6 characters')
                  //   .required('Password is required'),
                  // confirmPassword: Yup.string()
                  //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
                  //   .required('Confirm Password is required')
                })}
                onSubmit={(values, actions) => {
                  console.log("TTTTTTTTTTTTTTTTTTTT");
                  console.log("values", values);
                  setForm1(values);
                  setActive("2");

                  // console.log("values here", initialValues);

                  //   dispatch(
                  //     addExpenseToWallet(values, userId, (res) => {
                  //       actions.setSubmitting(false);
                  //       console.log("HERE", res);
                  //       // if (userId == " " || userId == null) {
                  //       //   <Alert>Please select the driver</Alert>;
                  //       // }
                  //       if (res?.data?.type === "success") {
                  //         console.log("successful vaiii");
                  //         //navigate("/Customers")
                  //         toggleModal();
                  //       } else {
                  //         console.log("failed to add");
                  //         toggleModal();
                  //       }
                  //     })
                  //   );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">1</span>
                        <label className="sub-head-3 ">
                          Enter Your First Name
                        </label>
                      </div>
                      <input
                        type="text"
                        id="firstName"
                        className="input-body-3"
                        // style={{ marginLeft: "94px" }}
                        name="firstName"
                        value={values.firstName}
                        touched={touched.firstName}
                        errors={errors.firstName}
                        onBlur={handleBlur("firstName")}
                        onChange={handleChange("firstName")}
                        placeholder="Enter Your First Name"
                      ></input>
                      {/* <div className="input-amount-for-link"></div> */}
                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.firstName && touched.firstName ? (
                          <div>*{errors.firstName}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">2</span>
                        <label className="sub-head-3 ">
                          Enter Your Last Name
                        </label>
                      </div>
                      <input
                        type="text"
                        id="lastName"
                        className="input-body-3"
                        // style={{ marginLeft: "94px" }}
                        name="lastName"
                        value={values.lastName}
                        touched={touched.lastName}
                        errors={errors.lastName}
                        onBlur={handleBlur("lastName")}
                        onChange={handleChange("lastName")}
                        placeholder="Enter Your Last Name"
                      ></input>
                      {/* <div className="input-amount-for-link"></div> */}
                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.lastName && touched.lastName ? (
                          <div>*{errors.lastName}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">3</span>
                        <p className="sub-head-3">Select Gender</p>
                      </div>
                      <select
                        name="gender"
                        class="form-select select-option"
                        aria-label="Default select example"
                        className="input-body-3 "
                        value={values.gender}
                        defaultValue={values.gender}
                        onChange={(e) =>
                          setFieldValue("gender", e.target.value, true)
                        }
                        placeholder="Select here"
                      >
                        <option value="default" disabled hidden>
                          Select below
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                      </select>
                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.gender && touched.gender ? (
                          <div>*{errors.gender}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">4</span>
                        <label className="sub-head-3 ">Date of Birth</label>
                      </div>
                      <DatePickerField
                        name="dob"
                        className={
                          "input-body-3 form-control" +
                          (errors.dob && touched.dob ? " is-invalid" : "")
                        }
                        value={values.dob}
                        header="Select booking date"
                        onChange={(v) => setFieldValue("dob", v, true)}
                        maxDate={new Date()}
                        ref1={datepickerRef}
                      />
                      <label
                        className="date-input"
                        style={{
                          color: "black",
                          position: "relative",
                          top: "-2rem",
                          left: "-1.4rem",
                          cursor:"pointer"
                        }}
                        onClick={()=>{
                          handleClickDatepickerIcon()}}
                      >
                        <SvgImage
                          style={{ marginRight: "1rem" }}
                          name="CalenderLogo"
                          width="32"
                          height="32"
                          classname="date-logo"
                        />
                      </label>

                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.dob && touched.dob ? (
                          <div>*{errors.dob}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">5</span>
                        <label className="sub-head-3 ">Address</label>
                      </div>
                      <input
                        type="text"
                        id="address"
                        className="input-body-3"
                        // style={{ marginLeft: "94px" }}
                        name="address"
                        value={values.address}
                        touched={touched.address}
                        errors={errors.address}
                        onBlur={handleBlur("address")}
                        onChange={handleChange("address")}
                        placeholder="Enter your address"
                      ></input>
                      {/* <div className="input-amount-for-link"></div> */}
                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.address && touched.address ? (
                          <div>*{errors.address}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">6</span>
                        <label className="sub-head-3 ">Pin Code</label>
                      </div>
                      <input
                        type="text"
                        pattern="[0-9]*"
                        id="pincode"
                        className="input-body-3"
                        // style={{ marginLeft: "94px" }}
                        name="pincode"
                        value={values.pincode}
                        touched={touched.pincode}
                        errors={errors.pincode}
                        onBlur={handleBlur("pincode")}
                        onChange={handleChange("pincode")}
                        placeholder="Enter your pin code"
                      ></input>
                      {/* <div className="input-amount-for-link"></div> */}
                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.pincode && touched.pincode ? (
                          <div>*{errors.pincode}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <Button
                      type="submit"
                      className="butt-4"
                      style={{
                        margin: "15% 0 0 0",
                        //   background: loader ? "grey" : "#2e7c7b",
                      }}
                      // disabled={loader ? true : false}
                    >
                      {/* {loader ? "Please Wait" : "Next"} */}
                      Next
                    </Button>
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                enableReinitialize
                initialValues={{
                  documentType: "default",
                  aadhar: "",
                  urlFront: "",
                  urlBack: "",
                  // pickedImage: "",
                }}
                validationSchema={Yup.object().shape({
                  documentType: Yup.string()
                    .required("Document Type is required")
                    .test(
                      "noDefaultDoc",
                      "Please select document type",
                      (val) => val != "default"
                    ),
                  aadhar: Yup.number().typeError("Only Numbers are allowed").required("Document number is required"),
                  urlFront: Yup.string().required(
                    "Front side of doc is required"
                  ),
                  urlBack: Yup.string().required(
                    "Back side of doc is required"
                  ),
                })}
                onSubmit={(values, actions) => {
                  const payload = {
                    firstName: form1?.firstName,
                    lastName: form1?.lastName,
                    dob: form1?.dob,
                    gender: form1?.gender,
                    address: form1?.address,
                    pincode: form1?.pincode,
                    aadhar: values.aadhar,
                    urlFront: values.urlFront,
                    urlBack: values.urlBack,
                    requestId: requestId,
                    documentType: values.documentType,
                  };

                  dispatch(fastagAddCustomerAction(payload,(res)=>{
                    console.log("HERE", res);
                    if (res?.data?.type === "success") {
                      handleShowIssue("KYC not done");
                    }
                  }))
                  // console.log("values here", initialValues);
                  

                  //   dispatch(
                  //     addExpenseToWallet(values, userId, (res) => {
                  //       actions.setSubmitting(false);
                  //       console.log("HERE", res);
                  //       // if (userId == " " || userId == null) {
                  //       //   <Alert>Please select the driver</Alert>;
                  //       // }
                  //       if (res?.data?.type === "success") {
                  //         console.log("successful vaiii");
                  //         //navigate("/Customers")
                  //         toggleModal();
                  //       } else {
                  //         console.log("failed to add");
                  //         toggleModal();
                  //       }
                  //     })
                  //   );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">1</span>
                        <p className="sub-head-3">Document Type</p>
                      </div>
                      <select
                        name="documentType"
                        class="form-select select-option"
                        aria-label="Default select example"
                        className="input-body-3 "
                        value={values.documentType}
                        defaultValue={values.documentType}
                        onChange={(e) =>
                          setFieldValue("documentType", e.target.value, true)
                        }
                        placeholder="Select here"
                      >
                        <option value="default" disabled hidden>
                          Select below
                        </option>
                        <option value="DLL">Driving License</option>
                        <option value="VID">Voter Card</option>
                        <option value="PAS">Passport</option>
                        <option value="ADD">Aadhaar Card</option>
                      </select>
                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.documentType && touched.documentType ? (
                          <div>*{errors.documentType}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">2</span>
                        <label className="sub-head-3 ">Document Number</label>
                      </div>
                      <input
                        type="text"
                        id="aadhar"
                        className="input-body-3"
                        // style={{ marginLeft: "94px" }}
                        name="aadhar"
                        value={values.aadhar}
                        touched={touched.aadhar}
                        errors={errors.aadhar}
                        onBlur={handleBlur("aadhar")}
                        onChange={handleChange("aadhar")}
                        maxLength={(values.documentType==="Driving License")?"16":values.documentType==="Voter Card"?"10":"12"}
                      ></input>
                      {/* <div className="input-amount-for-link"></div> */}
                      <div className="input-amount-for-link"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.aadhar && touched.aadhar ? (
                          <div>*{errors.aadhar}</div>
                        ) : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <UploadFront
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        number="3"
                        title="Attach Document Front side"
                      />
                    </FormGroup>

                    <FormGroup>
                      <UploadBack
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        number="4"
                        title="Attach Document Back side"
                      />
                    </FormGroup>
                    <FormGroup>
                      <button
                        type="submit"
                        className="butt-4"
                        style={{
                          margin: "15% 0 0 0",
                          background: loader ? "grey" : "#2e7c7b",
                        }}
                        disabled={loader ? true : false}
                      >
                        {/* {console.log(values)} */}
                        {loader ? "Please Wait" : "Next"}
                      </button>
                    </FormGroup>
                    <div></div>
                  </Form>
                )}
              </Formik>
            )}
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default LinkFastagDocDetails;
