import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const UnproctedRoute = (props) => {
  console.log("@yahoo", props);
  if (props.user == "null" || props.user == null) {
    return props.component;
  } else {
    return <Navigate to="/home" />;
  }
};

export default UnproctedRoute;
