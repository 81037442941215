import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  NavLink,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Alert } from "bootstrap";
import AddNewTripForm1 from "./AddNewTripForm1";
import AddNewTripForm2 from "./AddNewTripForm2";
import SvgImage from "../../../../../../Icons/Icons";


const AddNewTrip=(props)=>{
    const { open, toggleModal,  handleClose,item,type,path} = props;
    const [form,setForm]= useState(0);

    const [data, setData]=useState();
    useEffect(()=>{
        setForm(0);
    },[open])
    
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
        style={{ position: "fixed", top: "0", right: "0", margin: "0" }}
      >
        <div className="modal-content" style={{width:"530px", overflowY:"scroll", overflowX:"hidden"}} >
        <div className="modal-head-part">
          <p className="Add-balance-title">{type==1?'Edit Trip':"Create Trip"}</p>

          <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          {
            form==0?
            <AddNewTripForm1 
                setForm={setForm}
                setData={setData}
                item ={item}
                type= {type}
                path={path}
                toggleModal={toggleModal}
                />
            :<AddNewTripForm2 
                toggleModal={toggleModal}
                data={data}
                item ={item}
                type= {type}

                />
          }
        </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default AddNewTrip;
