import React, { useState, useEffect } from "react";
import "./index.css";
import { Routes, Route, Navigate, Switch, Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import SvgImage from "../../Components/Icons/Icons";

function LoginScreenMobile(props) {
  
  const handleGooglePlayButton=()=>{
    window.open("https://play.google.com/store/apps/details?id=com.logipeapp");
  }  
  return (
    <div className="loginMobileContainer" >
        <div className="padding24T padding24L" style={{height:"5%"}}>
            <SvgImage name="loginLogipeIcon" />
        </div>
        <div style={{paddingTop:"3rem", margin:"auto",height:"45%"}}>
            <SvgImage name="loginTruckIcon" />
        </div>
        <div className="padding32T heading3 Regular" style={{textAlign:"center", margin:"auto"}}>
            <div style={{margin:"auto"}}> Login from your Desktop or Laptop</div>
            <div className="padding16" style={{margin:"auto", color:"#949494"}}>OR</div>
            <div style={{margin:"auto"}} onClick={handleGooglePlayButton}>
                <SvgImage name="googlePlay" />
            </div>
        </div>
        <div style={{position:"absolute", bottom:"0px"}}>
            <div>
                <SvgImage name="bottomImage" />
            </div>   
            <div >
                <SvgImage name="rectImage" />
            </div>
                
        </div>
    </div>
  );
}
export default LoginScreenMobile;
