import React, { useEffect, useState } from "react";
import MainApp from "./MainApp";
import { BrowserRouter } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import initStore from "./services/Store/index";
import AppLogout from './Components/subComponents/sessionTimeout/index.js';
import {Button, Row, Col, Toast} from 'react-bootstrap';
import {getTokenFunc , onMessageListener} from './firebase';
import "./App.css";

// import { addDeviceIdAction } from "./services/Store/Actions/appActions";

function App() {
  const store = initStore;
  // const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, setToken] = useState("");
  getTokenFunc(setTokenFound, setToken);
  const [notification, setNotification] = useState({title: '', body: ''});

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log("notificationpayload----->",payload);
  }).catch(err => console.log('failed: ', err));

  // useEffect(()=>{
  //   if(isTokenFound){
  //     if(token!==""){
  //       dispatch(addDeviceIdAction({
  //         deviceId:token
  //       }))
  //     }
  //   }
  // }, [isTokenFound])

  return (
    <div className="App ">
      <Provider store={store}>
        <BrowserRouter>
              <Toast onClose={() => setShow(false)} show={show} delay={100000} autohide animation style={{
                position: 'absolute',
                top: 20,
                right: 20,
              }} 
              className="notifyDiv">
                <Toast.Header style={{position:"relative"}}>
                  <img
                    src="holder.js/20x20?text=%20"
                    className="rounded mr-2"
                    alt=""
                  />
                  <strong className="mr-auto">{notification.title}</strong>
                  {/* <small style={{paddingLeft:"1rem"}}>just now</small> */}
                </Toast.Header>
                <Toast.Body style={{position:"relative", paddingBottom:"1.4rem"}}>{notification.body}
                  <small style={{position:"absolute", bottom:"0.5rem",right:"0.8rem"}}>just now</small>
                </Toast.Body>
              </Toast>
          {/* <div style={{position:"absolute",left:"0px"}}> */}
            {/* <Button onClick={() => { 
              console.log("Print--->");
              setShow(true);
              }}>Show Toast</Button> */}
          {/* </div> */}
          <MainApp  deviceToken={token} 
              isTokenFound={isTokenFound}/>
          <AppLogout />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
