import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Field, ErrorMessage } from "formik";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import { useSelector,useDispatch } from "react-redux";
import { vehicleTagReissueAction, verifyFastagOTPAction } from "../../../services/Store/Actions/fastagAction";

function LinkFastagReissueOTP(props) {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    mobileNo,
    handleShowKYC,
    reissueData,
    setReIssue
  } = props;
  const validationSchema = Yup.object().shape({
    pin: Yup.string()
      .required("* Required")
      .matches(/^[0-9]{6}$/, "* Pin Must be exactly 6 digits"),
  });

  const dispatch = useDispatch();
  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const mob = obj?.user?.phone;

  const otpData = useSelector((state) => state?.fastag?.sendFastagOtp);
  console.log("otp", otpData);

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Issue FASTag</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>

          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                pin: "",
              }}
              onSubmit={(values, actions) => {
                const payload = {
                    regDate: reissueData.regDate,
                    fleetId: reissueData.fleetId,
                    barcode: reissueData.barcode,
                    vrn: reissueData.vrn,
                    // docNumber: values.docNumber,
                    docUrl: reissueData.docUrl,
                    vehicleCategory: reissueData.vehicleCategory,
                    requestId: reissueData.requestId,
                    txnNo: reissueData.txnNo,
                    txnTime: reissueData.txnTime,
                    otp:values.pin
                };
                //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
                console.log("valuessss", values);
                dispatch(
                    vehicleTagReissueAction(payload, (res) => {
                    actions.setSubmitting(false);
                    console.log("HERE", res);
                    if (res?.data?.type === "success") {
                      //console.log("successful vaiii");
                       toggleModal();
                    }
                    else{
                        setReIssue(1);
                        toggleModal();
                    }
                  })
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">1</span>
                        <p className="sub-head-neww">
                          Enter the OTP sent
                        </p>
                      </div>
                      <p className="sub-head-body">
                        OTP is needed to initiate KYC
                      </p>
                      <PinInput
                        length={6}
                        initialValue=""
                        secret
                        onChange={handleChange("pin")}
                        type="numeric"
                        value={values.pin}
                        onBlur={handleBlur("pin")}
                        touched={touched.pin}
                        errors={errors.pin}
                        inputMode="number"
                        style={{
                          padding: "10px",
                          marginLeft: "30px",
                          marginTop: "28px",
                        }}
                        inputStyle={{
                          border: "1px solid #A7A7A7",
                          borderRadius: "8px",
                          marginRight: "20px",
                        }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.pin && touched.pin ? (
                          <div>*{errors.pin}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <button
                      type="submit"
                      className="butt4"
                    >
                      Next
                      {/* {console.log('values h',values)} */}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="space-div"></div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
export default LinkFastagReissueOTP;
