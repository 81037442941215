import React,{useEffect, useState} from "react";
import SvgImage from "../../Icons/Icons";
import { getFastagTransactionHistory } from "../../../services/Store/Actions/fastagAction";
import { useDispatch,useSelector } from "react-redux";
import moment from "moment";
import "./index.css";
import FastagHistory from "../../../Subcomponent/TransactionHistory";
import OMCHistory from "../../../Subcomponent/OMCTransactionHistory";
import {useParams} from 'react-router-dom';

const data = [
  {
    from: "Vehicle Fastag Recharge",
    to: "From logipe account",
    amount: "₹2,000.00",
    days: "5 days ago",
  },
  {
    from: "Vehicle Fastag Recharge",
    to: "From logipe account",
    amount: "₹2,000.00",
    days: "5 days ago",
  },
];
function RecentTransactionBox(props) {
  const { type, receiver,fleetId, regNumber,Data } = props;
  console.log("ff",fleetId);

  let {id} = useParams();
  const dispatch = useDispatch();

  // const {fastagTransactionHistory} = useSelector((state) => state?.fastag);

  const History = Data?.rows;
  console.log("ddd",History);


  const [open, setOpen] = useState(false);

  const handleShow=()=> {
    setOpen(true);
  }
  const handleClose=()=> {
    setOpen(false);
  }
  const toggleModal=()=> {
    setOpen(!open);
  }
  
  return (
    <div className="main-recent-transaction">
      <div className="row">
        <div className="col-6">
          <p className="recent-Trans FontNB1">Recent Transaction</p>
        </div>
        <div className="col-6">
          <div className="row" onClick={handleShow} style={{cursor:"pointer"}}>
            <div className="col-9 padding0R" style={{textAlign:"right" }}>
              <p className="fastag-History">{
                type == "fastag" ? 
              "FASTag History" : "OMC History"
              }</p>
            </div>
            <div className="col-3 paddinf0L">
              <SvgImage
                name="ForwardArrow"
                height="32"
                width="32"
                classname="forward-arrow"
              />
            </div>
          </div>
          {
              type == "fastag" ? <FastagHistory
                open={open}
                toggleModal={toggleModal}
                handleClose={handleClose}
                regNumber={regNumber}
                fleetId={id}
              /> : 
              <OMCHistory
              open={open}
              toggleModal={toggleModal}
              handleClose={handleClose}
              regNumber={regNumber}
              fleetId={id}
              // Data = {Data}
            />
          }
        </div>
      </div>
      <div className="Box row">
        {History?.slice(0, 2).map((record) => {
          return (
            <div className="col-12">
              <div className="row">
                <div className="col-1 green-toll-booth-fastag">
                  <SvgImage name="GreenTollBooth" width="24" height="24" />
                </div>
                <div className="col-8 ">
                  <p className="receiver-fastag">{record?.description}</p>
                  {/* <p className="to-fastag">{"-----"}</p> */}
                </div>
                <div className="col-3 padding0L">
                  <p className="amountt-fastag FontSmall">{'₹' + (record?.amount===null?0.00:Number(record?.amount).toFixed(2))}</p>
                  <p className="days-fastag FontSmall">{moment(record?.transactionDate).fromNow()}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default RecentTransactionBox;
