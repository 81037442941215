import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import SvgImage from "../../Icons/Icons";
import './index.css'

const ReportBtnRows=(props)=>{

    const {optBtn, setOptBtn}= props;

    const data1=[
    {
        name:"Master Account",
        iconName:"MABtnIcon"
    },
    {
        name:"Vehicle",
        iconName:"vehBtnIcon"
    },{
        name:"Driver",
        iconName:"drBtnIcon"
    },
    ///////
    {
        name:"FasTag Wallet",
        iconName:"reportTollBooth"
    },
    {
        name:"Manager",
        iconName:"managerOptIcon"
    }
    ///////
    // {
    //     name:"Card",
    //     iconName:"CardBtnIcon"
    // },
    //{
    //     name:"Trip",
    //     iconName:"TripBtnIcon"
    // },
]

    const selectOtpBtn=(i)=>{
        if(optBtn!==i){
            setOptBtn(i);
        }
    }

    return(
    <div className="col-4 padding8 padding0T padding0B">
        <div className="padding16 whiteBackground containerShadow" style={{height:"100%"}}>
        {
            data1?.map((item,i)=>{
                return(
                    <div className="padding8T padding8B " style={{paddingLeft:"1.3rem"}}>
                        <Button className={i==optBtn?"selectOtp semiBold heading2 row":"notSelectOpt semiBold heading2 row"}
                             onClick={()=>{
                                selectOtpBtn(i)
                             }}>
                            <div className="col-2">
                                <SvgImage name={item?.iconName} color={optBtn==i?"#ffffff":"#485B7C"} />
                            </div>
                            <div className="col-8 padding8L" style={{textAlign:"left"}}>
                                {item?.name}
                            </div>
                            <div className=" col-2 rightAlignment">
                                <SvgImage name="nextArrow1" color={optBtn==i?"#ffffff":"#485B7C"} />
                            </div>
                        </Button>
                    </div>
                )
            })
        }
        </div>
    </div>)
}


export default ReportBtnRows;