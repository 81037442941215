import {
  GET_MASTERWALLET_BALANCE,
  GET_MASTERWALLET_BALANCE_SUCCESS,
  MASTERWALLET_TRANSACTION_HISTORY_SUCCESS,
  MASTERWALLET_TRANSACTION_HISTORY,

  CLEAN_MASTERWALLET_TRANSACTION_HISTORY,
  LINK_BANK_ACCOUNT_TO_LOGIPE,
  LINK_BANK_ACCOUNT_TO_LOGIPE_SUCCESS,
  CLEAN_MASTER_WALLET_REDUCER,
  NEFT_LINKED_ACCOUNTS_LIST,
  NEFT_LINKED_ACCOUNTS_LIST_SUCCESS
} from "../storeTypes";

export function getMasterWalletBalance() {
  return {
    type: GET_MASTERWALLET_BALANCE,
  };
}

export function getMasterWalletBalanceSuccess(data) {
  return { type: GET_MASTERWALLET_BALANCE_SUCCESS, payload: data };
}

export function transactionHistory(obj) {
  console.log("action");
  return {
    type: MASTERWALLET_TRANSACTION_HISTORY,
    payload: obj,
  };
}

export function transactionHistorySuccess(data) {
  return { type: MASTERWALLET_TRANSACTION_HISTORY_SUCCESS, payload: data };
}
export const cleantransactionHistory =()=>{
  console.log("action-> fleet-- clean");
  return {
   type:CLEAN_MASTERWALLET_TRANSACTION_HISTORY,
  };
};

export function linkBankAccountToLogipe(data, callback){
  return {
    type: LINK_BANK_ACCOUNT_TO_LOGIPE,
    payload:data,
    callback
  }
}

export function linkBankAccountToLogipeSuccess(data){
  return {
    type: LINK_BANK_ACCOUNT_TO_LOGIPE_SUCCESS,
    payload:data
  }
}

export function cleanMasterWalletLoader(){
  return {
    type: CLEAN_MASTER_WALLET_REDUCER,
  }
}


export function neftLinkedAccountsListAction(){
  return {
    type: NEFT_LINKED_ACCOUNTS_LIST,
  };
}

export function neftLinkedAccountsListActionSuccess(data){
  return { type: NEFT_LINKED_ACCOUNTS_LIST_SUCCESS, payload: data };
}