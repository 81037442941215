import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import RecentTransactionBox from "../../../subComponents/recentTransaction";
import "./index.css";
import moment from "moment";
import {
  cleanVehicleHistory,
  vehicleHistory,
} from "../../../../services/Store/Actions/manageVehicleAction";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import SearchBar from "../../../subComponents/searchbar";
import AddNewVendor from "../AddNewVendor";
import {
  cleanVendorList,
  getVendorAction,
} from "../../../../services/Store/Actions/beneficiaryAction";
import { wait } from "@testing-library/user-event/dist/utils";

function VendorList(props) {
  const [offset, setOffset] = useState(0);
  const [addNew, setAddNew] = useState(false);
  const [fullVpa, setFullVpa] = useState(false);
  const { path, onClickItem, setSearch,search } = props;
  const { vendorList, vendor } = useSelector((state) => state?.beneficiary);
  const count = vendor?.count;
  const handlePrev = () => {
    if (offset > 0) {
      setOffset(offset - 7);
    }
  };
  const navigate = useNavigate();
  const handleNext = () => {
    if (offset + 7 < count) {
      setOffset(offset + 7);
      console.log("vendorList?.length", vendorList?.length, offset);
      if (vendorList?.length == offset + 7) {
        dispatch(
          getVendorAction({
            isLogipeVendor: path == "0" || path == "1" || path == "2" ? "1" : "0",
            limit: 7,
            offset: offset + 7,
            search: search
          })
        );
      }
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("-------------useEffect", vendorList);
  }, []);
  function findSearch() {

  }
  if (addNew) {
    return (
      <AddNewVendor
        backPress={() => {
          setAddNew(false);
        }}
        path={path}
      />
    );
  } else {
    return (
      <div className="vendorBg containerShadow">
        <div className="col-6">
          <p className="vendor-List-title">Vendor List</p>
        </div>
        <div className="vendor-search-bar-section row">
          <div className="FUllListSearchBar col-9">
            <SearchBar
              placeholder="Search for vendors"
              height="46"
              value={search}
              setSearch={setSearch}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              autoFocus={true}
            />
          </div>
          <button
            className="greenOutlineButton col-3"
            onClick={() => {
              setAddNew(true);
            }}
            style={{
              paddingLeft: "1rem",
              paddingRight: "1rem",
              height: "46px",
            }}
          >
            <text
              style={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
              }}
            >
              + Add New Vendor
            </text>
          </button>
        </div>

        <div className="table-for-Vendor">
          <Table className="vehicleAssignedHistory">
            <thead className="th" style={{ zIndex: 99 }}>
              <tr className="table-header-vendor-details">
                <th className="col-3">Company / Vendor Name</th>
                <th className="ps-0">Phone No.</th>
                <th className="col-3 ps-0">UPI Handle</th>
                <th className="pe-0">Account Number</th>
                <th>GST Number</th>
              </tr>
            </thead>
            {/* <div className="tbodyVehicle-div"> */}
            <tbody className="tbodyVendor" style={{ textDecoration: "none" }}>
              {vendorList?.map((items, i) => {
                console.log("-----------", i, items);
                return (
                  <>
                    {i - offset < 7 && i - offset >= 0 ? (
                      <tr
                        className="table-row-vendor-details"
                        onClick={() => {
                          onClickItem(items);
                        }}
                      >
                        <td
                          className="col-3 pb-0 pt-0 "
                          style={{ verticalAlign: "middle" }}
                        >
                          <div className="row">
                            <div className="col-4 greenCirleInitial">
                              {i + 1}
                            </div>{" "}
                            <div
                              className="col-8"
                              style={{
                                verticalAlign: "middle",
                                alignSelf: "center",
                              }}
                            >
                              {items?.name || "---"}
                            </div>
                          </div>
                        </td>
                        <td
                          className="col-2 pb-0 pt-0 ps-0"
                          style={{ verticalAlign: "middle" }}
                        >
                          {items?.phone || "---"}
                        </td>
                        <td
                          className="pb-0 pt-0 col-3 ps-0"
                          style={{ verticalAlign: "middle" }}
                          onMouseEnter={() => {
                            setFullVpa(i);
                          }}
                          onMouseLeave={() => {
                            setFullVpa();
                          }}
                        >
                          {items?.vpaId
                            ? fullVpa == i
                              ? items?.vpaId
                              : items?.vpaId?.substring(0, 20)
                            : "---"}
                          {items?.vpaId && !fullVpa && items?.vpaId?.length > 20
                            ? "..."
                            : ""}
                        </td>
                        <td
                          className="pb-0 pt-0"
                          style={{ verticalAlign: "middle" }}
                        >
                          {items?.bankAccountNumber || "---"}
                        </td>
                        <td
                          className="pb-0 pt-0"
                          style={{ verticalAlign: "middle" }}
                        >
                          {items?.gstNumber || "---"}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </tbody>
            {/* </div> */}
          </Table>
          {/* {
            olderHistoryStatus?
            <div className="olderTransactions-div" onClick={handleOlderTransactions}>
            Older History
            </div>:
          <></>
          } */}
          <div className="row margin0 padding16">
            <div
              className="col-4"
              style={{
                textAlign: "right",
                paddingTop: "0.1rem",
                cursor: offset > 0 ? "pointer" : "no-drop",
                opacity: offset > 0 ? "1" : "0.5",
              }}
              onClick={handlePrev}
            >
              <SvgImage name="PrevArrow" />
            </div>
            <div
              className="col-1 greenFont semiBold heading4 padding0L"
              style={{
                textAlign: "right",
                paddingTop: "0.3rem",
                cursor: offset > 0 ? "pointer" : "no-drop",
                opacity: offset > 0 ? "1" : "0.5",
              }}
              onClick={handlePrev}
            >
              Previous
            </div>
            <div className="col-2 row margin0 justify-content-center">
              <div
                className="greenBoxCount Medium heading4 col-4"
                style={{
                  width: "2rem",
                  height: "2rem",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {offset / 7 + 1}
              </div>
              <div
                className=" col-4 padding0R"
                style={{ paddingTop: "0.3rem", textAlign: "right" }}
              >
                of{" "}
                {count % 7 == 0
                  ? Math.floor(count / 7)
                  : Math.floor(count / 7 + 1)}
              </div>
            </div>
            <div
              className="col-1 greenFont semiBold heading4 padding0R"
              style={{
                textAlign: "left",
                paddingTop: "0.3rem",
                cursor: count - offset > 7 ? "pointer" : "no-drop",
                opacity: count - offset > 7 ? "1" : "0.5",
              }}
              onClick={handleNext}
            >
              Next
            </div>
            <div
              className="col-4 padding0L"
              style={{
                textAlign: "left",
                marginLeft: "-1rem",
                paddingTop: "0.1rem",
                cursor: count - offset > 7 ? "pointer" : "no-drop",
                opacity: count - offset > 7 ? "1" : "0.5",
              }}
              onClick={handleNext}
            >
              <SvgImage name="NextArrow" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default VendorList;
