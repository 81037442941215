import { Button } from "reactstrap";
import React, { Component, useEffect, useState } from "react";
import SvgImage from "../../Icons/Icons";
import {
  getDataFromAsyncStorage,
  removeItemFromAsyncStorage,
  storeDataToAsyncStorage,
} from "../../../services/Utils/functions";
import {
  handleLoginSuccess,
  getOwnerDetails,
  getOwnerAccountDetails,
} from "../../../services/Store/Actions/LoginAction";

import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import OwnerDetails from "../../Modals/OwnerDetails";
import PrivacyModal from "../../Modals/PrivacyModal";
import TermsConditionsModal from "../../Modals/TermsConditionsModal";
import {
  deleteDeviceIdAction,
  sendAnalitics,
} from "../../../services/Store/Actions/appActions";
import { getBranchManager } from "../../../services/Store/Actions/UserManagementAction";

function HeaderRight(props) {
  const { accountName, img, accountTitle, companyName } = props;

  const { authUser, ownerDetails, ownerAccountDetails } = useSelector(
    (state) => state?.loginReducer
  );
  const { branchData } = useSelector((state) => state?.userManagement);
  const imgName = ownerDetails?.userImage ? true : false;
  const [display1, setDisplay1] = useState(false);
  const [search1, setSearch1] = useState("");
  const [color1, setColor1] = useState(-1);
  console.log("asmita", ownerDetails?.userImage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOwnerDetails());
    dispatch(getOwnerAccountDetails());
    //console.log("peter parker")
  }, []);
  const [branchName, setBranchName] = useState("");
  const [branchId, setBranchId] = useState("");

  // const [count,setCount]= useState (0);
  // const [img1, setImg1] = useState ("");
  // useEffect (()=>{
  //     if (ownerDetails===undefined)
  //     {
  //         dispatch(getOwnerDetails());
  //         setCount(1);
  //         setImg1 (ownerDetails.userImage);
  //         console.log("samurai");
  //     }
  // }, [ownerDetails]);

  // const deviceToken = useSelector((state)=>state?.app?.deviceToken)
  // console.log("samurai", deviceToken);
  const handleLogout = () => {
    // storeDataToAsyncStorage("_token", null);
    // storeDataToAsyncStorage("_user", null);
    // storeDataToAsyncStorage("_refreshToken", null);
    // dispatch()const userr = sessionStorage.getItem("_user");
    const deviceTokenObj = sessionStorage.getItem("_DeviceToken");
    const deviceToken = JSON.parse(deviceTokenObj);
    console.log("samurai 123", deviceToken);

    dispatch(deleteDeviceIdAction(deviceToken));
    removeItemFromAsyncStorage("_token");
    const userInfoObj = sessionStorage.getItem("_userInfo");
    if (userInfoObj) {
      removeItemFromAsyncStorage("_userInfo");
    }
    removeItemFromAsyncStorage("_trips");
    removeItemFromAsyncStorage("_user");
    removeItemFromAsyncStorage("_refreshToken");
    dispatch(handleLoginSuccess(null));
    window.location = "/";
    dispatch(sendAnalitics("Logout_web"));
  };
  const image = ownerDetails === undefined ? "" : ownerDetails.userImage;
  //console.log("image yeh aa rahi", image);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    getDataFromAsyncStorage("_branchName").then((data) => {
      setBranchName(data);
      console.log("branchNamebranchNamebranchNamebranchName", data);
    });

    getDataFromAsyncStorage("_branchId").then((data) => {
      setBranchId(data);
      console.log("jjsjjskkskskks", data);
    });
    console.log("snksnsknsknsknsn", branchName, branchId);
  }, [open]);
  const toggleModal = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleShow = () => {
    console.log("hshshs");
    dispatch(
      getBranchManager({}, (res) => {
        console.log("snjsbsbs", res);
      })
    );
    setOpen(true);
  };

  // for privacy policy modal
  const [openP, setOpenP] = useState(false);
  const togglePrivacyModal = () => {
    setOpenP(!openP);
  };
  const handlePrivacyClose = () => {
    setOpenP(false);
  };
  const handlePrivacyShow = () => {
    setOpenP(true);
  };

  // for terms and conditions modal
  const [openT, setOpenT] = useState(false);
  const toggleTermModal = () => {
    setOpenT(!openT);
  };
  const handleTermClose = () => {
    setOpenT(false);
  };
  const handleTermShow = () => {
    setOpenT(true);
  };

  return (
    <div className="right-info">
      <div>
        <button
          className="notificationLogo"
          onClick={() => {
            setDisplay1(!display1);
          }}
        >
          {/* <SvgImage name="NotificationLogo" /> */}
          <div className="">
            <p className="profile-name" style={{ textAlign: "left" }}>
              {"Branch"}
            </p>
            <div className="profile-title">
              <div>{branchName}</div>
            </div>
          </div>
        </button>
        <div>
          {display1 ? (
            <div
              className="padding16 newCashback whiteBackground"
              style={{
                position: "absolute",
                width: "250px",
                height: "275px",
                zIndex: "90",
              }}
            >
              <div
                onClick={() => {
                  setDisplay1(false);
                  // setSearch1("");
                  //dispatch(clearAllDriversByVrn());
                  // setFieldValue("sourceAddress", "", true);
                }}
                style={{
                  position: "absolute",
                  right: "0.5rem",
                  top: "0.5rem",
                  cursor: "pointer",
                }}
              >
                <SvgImage name="CloseButtonLogo" />
              </div>
              <div
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: "240px",
                }}
              >
                {search1 == "" ? (
                  branchData?.rows?.map((data, i) => {
                    let city = data?.cityName;
                    let state = data?.stateName;
                    let branch = data?.branch;
                    console.log("savedAddresses", data, i);
                    return (
                      <div
                        key={i}
                        className="padding8 row"
                        onClick={() => {
                          // setcityId1(data?.cityName);
                          setColor1(i);
                          setDisplay1(false);
                          //setUserId(data?.User?.userId);
                          // setFieldValue(
                          //   "branch",
                          //   `${data?.branchName}`,
                          //   true
                          // );
                          // setFieldValue("branchId", data?.branchId, true);
                          storeDataToAsyncStorage("_branchId", data?.branchId);
                          storeDataToAsyncStorage(
                            "_branchName",
                            data?.branchName
                          );
                          window.location.reload();
                        }}
                      >
                        {branchId === data?.branchId ? (
                          <div className="col-1">
                            <SvgImage name="SelectedRadio" />
                          </div>
                        ) : (
                          <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div>
                        )}
                        {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                        <div
                          className="col-10"
                          style={{
                            textAlign: "left",
                            marginLeft: "12px",
                            alignItems: "center",
                          }}
                        >
                          {data?.branchName}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    {branchData?.rows?.map((data, i) => {
                      let city = data?.cityName;
                      let state = data?.stateName;
                      let branch = data?.branch;
                      console.log("skksk", data, i);
                      return (
                        <div
                          key={i}
                          className="padding8 row"
                          onClick={() => {
                            // setcityId1(data?.cityName);
                            setColor1(i);
                            setDisplay1(false);
                            //setUserId(data?.User?.userId);
                            // setFieldValue(
                            //   "branch",
                            //   `${data?.branchName}`,
                            //   true
                            // );
                            // setFieldValue("branchId", data?.branchId, true);
                            storeDataToAsyncStorage(
                              "_branchId",
                              data?.branchId
                            );
                            storeDataToAsyncStorage(
                              "_branchName",
                              data?.branchName
                            );
                            window.location.reload();
                          }}
                        >
                          {branchId === data?.branchId ? (
                            <div className="col-1">
                              <SvgImage name="SelectedRadio" />
                            </div>
                          ) : (
                            <div className="col-1">
                              <SvgImage name="UnselectedRadio" />
                            </div>
                          )}
                          {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                          <div className="col-10" style={{ textAlign: "left" }}>
                            {data?.branchName}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div
        className="header-info"
        onClick={handleShow}
        style={{ cursor: "pointer" }}
      >
        <div className="profile-img">
          {console.log("kkkkkkkkkkjjj", ownerDetails?.userImage)}
          {imgName ? (
            // <img
            //   className="profile-img"
            //   src={ownerDetails?.userImage}
            //   alt=" "
            //   width="700"
            //   height="570"
            // />
            <div className="profile-img">
              <div style={{ padding: "3px 0 0 6px" }}>
                <SvgImage name="profileImg" />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="profile-desc">
          <p className="profile-name">{ownerDetails?.fullName}</p>
          <div className="profile-title">
            <div>Owner</div>

            <div style={{ margin: "-3px 0 2px 6px" }}>.</div>
            <div style={{ marginLeft: "0px" }}>
              {ownerDetails?.businessName}
              {/* <br />
              <div
                style={{
                  marginLeft: "-44px",
                  // marginBottom: "10px"
                }}
              >{`Branch Name - ${branchName}`}</div> */}
            </div>
          </div>
        </div>
      </div>

      <Button className="logout-btn" onClick={handleLogout}>
        <SvgImage
          name="LogoutLogo"
          heigth="12px"
          width="12px"
          classname="logout-btn-logo"
        />
        <p className="logout">Logout </p>
      </Button>
      <div>
        <OwnerDetails
          handleClose={handleClose}
          open={open}
          toggleModal={toggleModal}
          handlePrivacyShow={handlePrivacyShow}
          handleTermShow={handleTermShow}
          handleLogout={handleLogout}
        />
      </div>
      <div>
        <PrivacyModal
          handleClose={handlePrivacyClose}
          open={openP}
          toggleModal={togglePrivacyModal}
        />
      </div>
      <div>
        <TermsConditionsModal
          handleClose={handleTermClose}
          open={openT}
          toggleModal={toggleTermModal}
        />
      </div>
    </div>
  );
}
export default HeaderRight;
