import React from "react";
import "./index.css";
import { useState, useEffect } from "react";
import { Button } from "reactstrap";
import ManageDriverAddNewExpense from "../../Components/Modals/ManageDriverAddNewExpense";
import { useDispatch, useSelector } from "react-redux";
import ManageExpense from "../../Components/subComponents/manangeExpense";
import {
  expenseSumm,
  getDriverBal,
} from "../../services/Store/Actions/driverslistActions";

function ManageExpenseManageCardBar(props) {
  const [colorManageCard, setColorManageCard] = useState("#F0F2FD");
  const [colorManageExpense, setColorManageExpense] = useState("#6378E3 ");
  const [expenseClass, setExpenseClass] = useState("ManageExpenseBox-1 col-3");
  const [logipeClass, setLogipeClass] = useState("ManageLogepeCardBox-1 col-3");
  const [endBar, setEndBar] = useState("ExpenseEndBar");
  const [open, setOpen] = useState(false);
  const [btn, setBtn] = useState(false);
  const [clickState, setClickState] = useState(true);
  //const [offset, setOffset] = useState(0);
  const [displayName, setDisplayName] = useState([]);
  const dispatch = useDispatch();
  // const expenseSummDetail = useSelector((state) => state?.driverlist);

  useEffect(() => {
    // dispatch(
    //   expenseSumm(props.userId, {
    //     offset: 0,
    //     search: "",
    //     minKyc: "",
    //     sortAs: 0,
    //     sortBy: 1,
    //   })
    // );
    dispatch(getDriverBal(props.userId));
  }, [props.userId, open]);

  function toggleModal() {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  function handleClick() {
    if (btn) {
      setBtn(false);
    } else {
      setBtn(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleShow = () => {
    setOpen(true);
  };

  const handleClick1 = (val) => {
    setClickState(val);
    // setColorManageExpense(" #F0F2FD");
    // setColorManageCard("#6378E3 ")
  };
  const handleClick2 = () => {
    setClickState(false);
    // setColorManageExpense("#6378E3 ");
    // setColorManageCard(" #F0F2FD ")
  };

  let a = "Manage Expense";
  let b = "Manage LogiPe Card";
  return (
    <>
      <div className="ManageExpenseManageCardBox ">
        <div className="buttonContainer">
          <Button
            className={
              clickState
                ? "ManageExpenseBox-1 mgDriverButSel"
                : "ManageExpenseBox-1 "
            }
            onClick={() => handleClick1(true)}
          >
            {a}
          </Button>
          <Button
            className={
              clickState
                ? "ManageExpenseBox-1"
                : "ManageExpenseBox-1 mgDriverButSel"
            }
            onClick={() => handleClick1(false)}
          >
            {b}
          </Button>
        </div>
      </div>
      <ManageExpense
        setOpen={setOpen}
        open={open}
        setClickState={setClickState}
        clickState={clickState}
        handleShow={handleShow}
        userId={props.userId}
        driverBal={props.driverBal}
        driverDetails={props.driverDetails}
        // expenseSummDetail={expenseSummDetail}
        // setOffset={setOffset}
        // offset={offset}
        driverCardStatus={props.driverCardStatus}
        cardType={props.cardType}
      />
      <ManageDriverAddNewExpense
        userId={props.userId}
        setDisplayName={setDisplayName}
        displayName={displayName}
        open={open}
        toggleModal={toggleModal}
        title="Logipe Account"
        handleClick={handleClick}
        handleClose={handleClose}
        btn={btn}
        color={
          "LogipeAccount" === "Card Wallet" ? "title-color1" : "title-color2"
        }
      />

      <div className={endBar}></div>
    </>
  );
}
export default ManageExpenseManageCardBar;
