import {
  ADD_FLEET,
  ADD_FLEET_SUCCESS,
  GET_FLEET_BY_FLEET_ID,
  GET_FLEET_BY_FLEET_ID_SUCCESS,
  CLEAN_FASTAG_TRANSACTION_HISTORY,
  GENERATE_MASTER_ACCOUNT_STATEMENT,
  GENERATE_MASTER_ACCOUNT_STATEMENT_SUCCESS,
  GENERATE_FASTAG_VEHICLE_STATEMENT,
  GENERATE_FASTAG_VEHICLE_STATEMENT_SUCCESS,
  CLEAN_FLEET_LOADER,
  UPDATE_FLEET,
  UPDATE_FLEET_SUCCESS,
  ADVANCED_VEHICLE_LIST,
  ADVANCED_VEHICLE_LIST_SUCCESS
} from "../storeTypes";

export function addFleet(obj, callBack) {
  return {
    type: ADD_FLEET,
    payload: obj,
    callBack,
  };
}
export function addFleetSuccess(data) {
  return {
    type: ADD_FLEET_SUCCESS,
    payload: data,
  };
}

export function getFleetByID(id) {
  return {
    type: GET_FLEET_BY_FLEET_ID,
    id,
  };
}
export function getFleetByIDSuccess(data) {
  return {
    type: GET_FLEET_BY_FLEET_ID_SUCCESS,
    payload: data,
  };
}

export const cleanFleetTransactionHistory =()=>{
  console.log("action-> fleet-- clean");
  return {
   type:CLEAN_FASTAG_TRANSACTION_HISTORY,
  };
  }
  export function getMasterAccountStatement(data, callback) {
    console.log("api",data)
    return {
      type: GENERATE_MASTER_ACCOUNT_STATEMENT,
      payload:data,
      callback
    };
  }
  export function getMasterAccountStatementSuccess(data) {
    return {
      type: GENERATE_MASTER_ACCOUNT_STATEMENT_SUCCESS,
      payload:data
    };
  };
  export function getFastagVehicleHistoryStatement(userId ,data,callback) {
    return {
      type: GENERATE_FASTAG_VEHICLE_STATEMENT,
      payload:data,
      userId:userId,
      callback
    };
  };
  export function getFastagVehicleHistoryStatementSuccess(data) {
    return {
      type: GENERATE_FASTAG_VEHICLE_STATEMENT_SUCCESS,
      payload:data
    };
  };
export const cleanFleetLoader = () => {
  console.log("mesaage-------------loader action");
    return {
      type:CLEAN_FLEET_LOADER,
    };
  };
  export function getUpdateFleet(data) {
    return {
      type: UPDATE_FLEET,
      payload:data
    };
  }
  export function updateFleetSuccess(data) {
    return {
      type: UPDATE_FLEET_SUCCESS,
      payload:data
    };
  };
  export function advancedVehicleListAction(payload){
    return {
      type:ADVANCED_VEHICLE_LIST,
      payload
    }
  }
  
  export function advancedVehicleListSuccess(payload){
    return {
      type:ADVANCED_VEHICLE_LIST_SUCCESS,
      payload
    }
  }