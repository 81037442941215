import React, { useState } from "react";
import SvgImage from "../../../Components/Icons/Icons";

import "./index.css";

const CarousalElements = (props) => {
  const { slide, i } = props;
  return (
    <div
      className={
        i == 0
          ? "carousel-item active carousel-texts row"
          : "carousel-item carousel-texts row"
      }
      key={i}
      data-bs-interval="2500"
    >
      <div className="col-lg-12 col-md-6 paddingTexts">
        <div className="infoText1">{slide.text1}</div>
        <div
          className="infoText2 paddingText2Top"
          onClick={() => {
            window.open(slide.urlLink);
          }}
        >
          <div className="padding8R">{slide.text2}</div>
          <div>
            <SvgImage name="loginArrow" />
          </div>
        </div>
      </div>

      {/* <div className= {i == 4?"paddingCarouselImage col-lg-12 col-md-6 marginTop1rem":
      i!= 3?"paddingCarouselImage col-lg-12 col-md-6 marginTop3rem":
      "paddingCarouselImage col-lg-12 col-md-6"} 
      style={{ paddingBottom: i == 3 ? "0":"1rem" }}>
        <div className="carouselImageHeight" 
        // style={{ marginTop: i == 0 ? "3rem" : i == 1 ? "3rem" : i == 2 ? "3rem" :i == 4 ? "1rem" :"0" }}
        >
          <div
            className={
              i == 2
                ? "infoLogo padding24T computerIconStyle"
                : i == 3
                ? "infoLogo padding32T padding24L carouselImages"
                : "infoLogo padding24T padding24L carouselImages"
            }
          >
            <SvgImage name={slide.svgName} />
          </div>
        </div>
      </div> */}
      <div
        className={
           "paddingCarouselImage col-lg-12 col-md-6 "
        }
        style={{ paddingBottom: i == 3 ? "0" :  i == 4 ?"0":"1rem" }}
      >
        <div
          className="carouselImageHeight"
          // style={{ marginTop: i == 0 ? "3rem" : i == 1 ? "3rem" : i == 2 ? "3rem" :i == 4 ? "1rem" :"0" }}
        >
          <div
            className={
              i == 2
                ? "infoLogo  computerIconStyle"
                : "infoLogo  padding8L carouselImages"
            }
          >
            <SvgImage name={slide.svgName} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarousalElements;
