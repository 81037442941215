import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import { Button } from "reactstrap";
import SvgImage from "../../../../../../Icons/Icons";
import { getCardDetails } from "../../../../../../../services/Store/Actions/driverslistActions";
import CardControlsModal from "../../../../../../Modals/CardControlsModal";
import ClipLoader from "react-spinners/ClipLoader";


const NeoCredCard=(props)=>{

    const { driverDetails, driverCardStatus, userId}= props;
    const status = driverCardStatus===4||7?true:false;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCardDetails(userId));
      }, [userId,status]);

    const cardDetails = useSelector((state) => state);
    const cardDetailsLoader = useSelector((state) => state?.cardDetails?.loader);

    const expiryDate =
    cardDetails?.driverlist?.cardDetails?.details?.expiryDate == null
      ? "NA"
      : cardDetails?.driverlist?.cardDetails?.details?.expiryDate;

    const cardNumber =
    cardDetails?.driverlist?.cardDetails?.details?.cardNumber == null
    ? "NA"
    : cardDetails?.driverlist?.cardDetails?.details?.cardNumber;  
    const correctCard=cardNumber==="NA"?"NA":cardNumber.substring(0,4)+"-"+
        cardNumber.substring(4,8)+"-"+cardNumber.substring(8,12)+"-"+cardNumber.substring(12,16);

    
    console.log("Card Details ff *****", driverCardStatus,userId, cardDetails );

    const [open, setOpen]= useState(false);

    const handleClose=()=>{
        setOpen(false);
    }

    const toggleModal=()=>{
        setOpen(!open);
    }

    const showModal=()=>{
        setOpen(true);
    }

    return(
    <>
        <div className="cardContainerNew" style={{position:"relative"}}>
            <div className="row">
                <div className="col-10 cardNameDr Medium heading3 padding16T">{driverDetails?.name?.length>30?driverDetails?.name.split(0,30)+"...":driverDetails?.name}</div>
                <div className="col-2 padding16T" style={{cursor:"pointer"}} onClick={showModal}>
                    <SvgImage name="cardSettingOpt" />
                </div>
            </div>
            <div className="whiteText Medium heading3 padding24T padding16B">{cardDetailsLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={cardDetailsLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:correctCard}</div>
            <div className="row padding32B">
                <div className="col-4">
                    <div className="cardNameDr text4">Expiry Date</div>
                    <div className="whiteText Medium heading3">{cardDetailsLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={cardDetailsLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:expiryDate}</div>
                </div>
                <div className="col-4">
                    <div className="cardNameDr text4">CVV</div>
                    <div className="whiteText Medium heading3">XXX</div>
                </div>
            </div>
            <div style={{position:"absolute", bottom:"0", right:"0", zIndex:"300"}}>
                <SvgImage name="titledRect1" />
            </div>
            <div style={{position:"absolute", bottom:"0", right:"0",  zIndex:"300"}}>
                <SvgImage name="titledRect2" />
            </div>
        </div>
        {
        <CardControlsModal
          userId={userId}
          open={open}
          toggleModal={toggleModal}
          title="Logipe Account"
          handleClose={handleClose}
          cardType={props.cardType}
        />
        }
    </>
    )
}

export default NeoCredCard;