import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getVehicleListAction } from "../../../services/Store/Actions/manageVehicleAction";
// import { fleetDetailsSuccess } from "../../../services/Store/Actions/fleetActions";
import Switch from "react-switch";
import SearchBar from "../../subComponents/searchbar";
import {
  autoRechargeToggleAction,
  cleanFastagLoaderAction,
  getFastagWalletBalance,
} from "../../../services/Store/Actions/fastagAction";
import { ToastContainer, toast } from "react-toastify";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";
import { ClipLoader } from "react-spinners";

function AutorechargeHome(props) {
  const {
    open,
    toggleModal,
    handleClose,
    autoState,
    showRechargeVeh,
    setLimit,
    setVehNo,
    showRechargeOne,
    setVehRank,
  } = props;
  const loader = useSelector((state) => state?.fastag?.loader);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [loader2, setLoader] = useState("");
  const [listNo, setListNo] = useState(0);
  const [prevButtonPressed, setPrevButtonPressed] = useState(false);
  const dispatch = useDispatch();
  const customId = "custom-id-yes";
  const customId2 = "custom-id-success";

  useEffect(() => {
    dispatch(sendAnalitics("ARecharge_MV_FW_Web"));
  }, []);

  useEffect(() => {
    setOffset(0);
    dispatch(
      getVehicleListAction({
        offset: "",
        limit: 15,
        search: search.trim(),
        minKyc: "",
        sortAs: "",
        sortBy: "",
        fleetType: "1",
      })
    );
  }, [open, search]);

  useEffect(() => {
    setSearch("");
    dispatch(cleanFastagLoaderAction());
    setListNo(0);
  }, [open]);

  const findSearch = () => {
    dispatch(
      getVehicleListAction({
        offset: 0,
        search: search.trim(),
        limit: 15,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        fleetType: "1",
      })
    );
  };

  const fleetRechargeModal = useSelector(
    (state) => state?.manageVehicle?.allFleets?.rows
  );
  const fleetCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );
  const numberOfVeh =
    fleetRechargeModal != undefined ? fleetRechargeModal.length : 0;
  console.log("fleet added", numberOfVeh, listNo);

  useEffect(() => {
    if (prevButtonPressed) {
      console.log("fleet added subtracting------", numberOfVeh);
      setListNo(listNo - numberOfVeh);
    }
  }, [numberOfVeh]);

  const handlePrevButton = () => {
    //props.nextTrigger();
    if (offset > 0) {
      setOffset(offset - 15);
      //setListNo(listNo- fleetRechargeModal.length);
      //setSerialNum(serialNum - 15);
      //console.log("offset", offset);
      dispatch(
        getVehicleListAction({
          offset: offset - 15,
          limit: 15,
          search: search.trim(),
          minKyc: "",
          sortAs: "",
          sortBy: "",
          fleetType: "1",
        })
      );
      setPrevButtonPressed(true);
    }
    //console.log("X--");
  };

  const handleNextButton = () => {
    if (fleetCount - offset > 15) {
      setPrevButtonPressed(false);
      setOffset(offset + 15);
      setListNo(listNo + fleetRechargeModal.length);
      //setSerialNum(serialNum + 15);
      //console.log("offset", offset);
      dispatch(
        getVehicleListAction({
          offset: offset + 15,
          limit: 15,
          search: search.trim(),
          minKyc: "",
          sortAs: "",
          sortBy: "",
          fleetType: "1",
        })
      );
    }
    //console.log("X++");
  };

  const handleLimitChange = (No, limit, rank, fleetId) => {
    console.log("hhhhhhhhhhhhhhhhhh", No, limit);
    setVehNo(No);
    setLimit(limit);
    setVehRank(rank);
    showRechargeOne(fleetId);
    toggleModal();
  };

  const handleToggleAll = () => {
    dispatch(sendAnalitics("mainToggle_AR_MV_web"));
    if (!loader) {
      setLoader(true);
      dispatch(
        autoRechargeToggleAction({}, (res) => {
          setLoader(false);
          // toggleModal();
          if (res.status == 200) {
            notify2();
            dispatch(getFastagWalletBalance());
          }
        })
      );
    }
  };

  //toast
  const notify1 = () =>
    toast.error(
      "You have disabled FASTag auto-recharge for all vehicles, kindly enable it!",
      {
        closeOnClick: true,
        pauseOnHover: true,
        position: "top-right",
        toastId: customId,
      }
    );
  const notify2 = () =>
    toast.success("Auto Recharge Toggle Successfully!", {
      closeOnClick: true,
      pauseOnHover: true,
      position: "top-right",
      toastId: customId2,
    });

  console.log("loader valllllllll", loader);
  const handleToggleOne = (fleetIdVal) => {
    console.log("autoState valllllllll", autoState);
    console.log("loader valllllllll", loader);
    if (!loader && autoState) {
      // toggleModal();
      setLoader(fleetIdVal);
      dispatch(
        autoRechargeToggleAction(
          {
            fleetId: [fleetIdVal],
          },
          (res) => {
            setLoader("");
            // toggleModal();
            if (res.status == 200) {
              notify2();
              dispatch(
                getVehicleListAction({
                  offset: offset,
                  limit: 15,
                  search: search.trim(),
                  minKyc: "",
                  sortAs: "",
                  sortBy: "",
                  fleetType: "1",
                })
              );
            }
          }
        )
      );
    } else if (!autoState) {
      notify1();
    }
  };

  const { fastagWallet } = useSelector((state) => state?.fastag);
  console.log("sssssssssssss", fastagWallet);
  const fastagAutorechargeAmount = fastagWallet?.autoRechargeAmount;

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">FASTag Auto Recharge</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="padding8 padding16B">
              LogiPe will automatically add money to your FASTags from your
              Master Account when your balance goes low
            </div>
            <div className="row padding8 padding16B">
              <div className="col-7 heading2 semiBold">Auto Recharge</div>
              <div
                className={
                  autoState
                    ? "col-5 row fontCardControl btnActive"
                    : "col-5 row fontCardControl btnInactive"
                }
                style={{
                  color: "#2E7C7B",
                  alignItems: "right",
                  // paddingLeft: "20px",
                  // marginTop: "10px",
                }}
              >
                <div
                  className="col-6 FontSmall"
                  style={{
                    color: "#25252D",
                    paddingRight: "0",
                  }}
                >
                  Enable all
                </div>
                {loader2 == true ? (
                  <div className="react-switch col-4 padding0L">
                    <ClipLoader
                      color={"#485B7C"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  <Switch
                    checked={autoState}
                    onChange={handleToggleAll}
                    onColor="#EBF2F2"
                    onHandleColor="#485B7C"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    height={15}
                    width={40}
                    className="react-switch col-4 padding0L"
                    id="material-switch"
                  />
                )}
              </div>
            </div>
            <div className="line-separation"></div>
            <div className="row FontSmall padding32T padding8">
              <div className="col-7 heading3 semiBold">All FASTags</div>
              <div
                className="col-5"
                style={{ display: "flex", direction: "row" }}
              >
                <div style={{ paddingTop: "4px", color: "#485B7C" }}>
                  Change Limits
                </div>
                <div
                  className="padding16L"
                  onClick={() => {
                    dispatch(sendAnalitics("ChangeLimit_AR_MV_web"));
                    showRechargeVeh();
                    toggleModal();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <SvgImage name="penNewBig" />
                </div>
              </div>
            </div>
            <div className="FUllListSearchBar">
              <SearchBar
                placeholder="Search for vehicles"
                height="46"
                value={search}
                setSearch={setSearch}
                onChange={(event) => {
                  //dispatch(clearAllDrivers());
                  dispatch(sendAnalitics("search_AR_MV_web"));
                  setSearch(event.target.value);
                }}
                findSearch={findSearch}
              />
            </div>
            <div className="padding16 padding0T">
              {fleetRechargeModal?.map((d, i) => {
                let vehNo =
                  d?.fleet?.regNumber != undefined
                    ? d?.fleet?.regNumber?.length > 10
                      ? d?.fleet?.regNumber.substring(0, 8) + ".."
                      : d?.fleet?.regNumber
                    : "";
                // let vehNo = d?.tagId!= undefined?
                //   d?.tagId.length > 10
                //     ?".."+ d?.tagId.substring(d?.tagId.length-10, d?.tagId.length)
                //     : d?.tagId:"";
                let limit = d?.autoRechargeAmount;
                let vehStatus = d?.autoRecharge;
                let bank = d?.fasTagType;
                return (
                  <div className="rowCont row" style={{ cursor: "default" }}>
                    <div class="div-table-col width75 FontNB col-9 ">
                      {/* <div data-letters={firstName_initials.toUpperCase()}> */}
                      <div
                        data-letters-recharge={listNo + i + 1}
                        style={{ display: "flex" }}
                      >
                        <span className=" FontNB">{vehNo}</span>
                        <div
                          style={{
                            paddingLeft: "0.5rem",
                            position: "relative",
                            top: "-5px",
                          }}
                        >
                          <SvgImage
                            name={
                              bank === "YesBank" ? "yesBankTag" : "idfcBankTag"
                            }
                          />
                        </div>
                      </div>
                      <div
                        class="vehSubInfo FontSmall"
                        onClick={() => {
                          handleLimitChange(
                            vehNo,
                            limit,
                            offset + i + 1,
                            d?.fleetId
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Limit Set : ₹
                        {limit == "500" ? fastagAutorechargeAmount : limit}{" "}
                        <SvgImage name="penNew" />
                      </div>
                    </div>
                    <div className="col-3 padding16">
                      {loader2 == d?.fleetId ? (
                        <div className="react-switch col-4 padding0L">
                          <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        <Switch
                          checked={vehStatus && autoState}
                          onChange={(e) => handleToggleOne(d?.fleetId)}
                          onColor="#EBF2F2"
                          onHandleColor="#2E7C7B"
                          handleDiameter={20}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          height={15}
                          width={40}
                          className="react-switch col-4"
                          id="material-switch"
                        />
                      )}
                    </div>
                    <div className="endingLine"></div>
                  </div>
                );
              })}
            </div>
            <div className="row padding8B">
              <div className="col-8"></div>
              <div
                className="col-1 padding24L"
                onClick={handlePrevButton}
                style={{ opacity: offset > 0 ? "1" : "0.5", cursor: "pointer" }}
              >
                <SvgImage name="prevArrowSmall" />
              </div>
              <div
                className="col-2"
                onClick={handleNextButton}
                style={{
                  opacity: fleetCount - offset > 15 ? "1" : "0.5",
                  cursor: "pointer",
                }}
              >
                <SvgImage name="nextArrowSmall" />
              </div>
            </div>
          </ModalBody>
        </div>
        <ToastContainer theme="dark" position="bottom-right"
          newestOnTop={true}
          closeOnClick pauseOnHover
          autoClose={1000}/>
      </Modal>
    </div>
  );
}

export default AutorechargeHome;
