import "./index.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import AddBalanceButton from "../../Buttons/addBalancebutton";
import PGCollection from "../../../../Subcomponent/PGCollection";

const CollectionDiv=(props)=>{

    const collections  = useSelector((state) => state?.transfer?.collections);
    const collectionsAmount = collections === undefined ? 0 : collections;
    console.log ("remaining ", typeof collectionsAmount);
    console.log ("collections-------------- ", collections);
    // this is for the settlement modal
    const [open1, setOpen1]= useState(false);

    const toggleModal1=()=>{
    setOpen1(!open1);
    }

    const handleClose1 = () => {
    setOpen1(false);
    console.log("closed");
    };

    const handleShow1 = (a) => {
    setOpen1(true);
    console.log(a);
    };

    // for the cashback transaction modal
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    //setProceed(0);
    //console.log("closed");
  };

  function toggleModal() {
    setOpen(!open);
    //setProceed(0);
  }

  const handleShow = () => {
    setOpen(true);
    //console.log(a);
  };

    return (
        <div style={{padding : "11px"}}>
            <div className="row">
                <div className="col-1">
                    <SvgImage name="HomeCollection" />
                </div> 
                <div className="col-3 heading2 Bold">
                    Collections
                </div>
                <div className="col-8 text3 Regular disabledBlack" >
                Amount collected from customers through LogiPe payment link.
                </div>
            </div>
            <div className="row padding16T">
                <div className="col-8">
                    <div className="text3 Regular">Amount to be Settled</div>
                    <div className="text1 semiBold">₹  {collectionsAmount.toFixed(2)}</div>
                </div>
                <div className="col-3">
                <AddBalanceButton
                    Value="View Collections"
                    onclick={handleShow}
                />
                </div>
            </div>
            {
                <PGCollection 
                handleClose={handleClose} 
                isOpen={open}
                onHide={handleClose}
                toggle={toggleModal}
                settlementHandleClose={handleClose1}
                settlementIsOpen={open1}
                settlementOnHide={handleClose1}
                settlementToggle={toggleModal1}
                settlementShow={handleShow1}
                />
            }
        </div>
    )


}


export default CollectionDiv;