import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, FormGroup, Table } from "reactstrap";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { BallTriangle } from "react-loader-spinner";
import { getRoute } from "../../../../services/Store/Actions/TripsAction";
import SvgImage from "../../../Icons/Icons";
import CreateRoutes from "../../../Routes/SubComponents/CreateRoutes";
function ChooseRoute(props) {
  const { toggleModal, modal, setModal, setRouteData, createTrip } = props;
  const toastObject = useSelector((state) => state?.app?.toastObject);
  // const [loader, setLoader] = useState(false);
  const [offset, setOffset] = useState(0);
  let { routesList, routesCount, routeLoader } = useSelector(
    (store) => store?.trip
  );
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setModal(!modal);
  };
  const [data, setData] = useState();
  const [createCust, setCreateCust] = useState(false);
  function toggleCreateCust() {
    setData();
    setCreateCust(!createCust);
  }
  useEffect(() => {
    dispatch(
      getRoute({
        offset: "",
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: "10",
      })
    );
    return function cleanUp() {
      setOffset(0);
    };
  }, []);

  const handlePrevButton = () => {
    if (offset > 0) {
      setOffset(offset - 10);
      dispatch(
        getRoute({
          offset: offset - 10,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          limit: "10",
        })
      );
    }
  };

  const handleNextButton = () => {
    console.log("djbdjbdjbdjbd");
    if (routesCount - offset > 10) {
      setOffset(offset + 10);
      dispatch(
        getRoute({
          offset: offset + 10,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          limit: "10",
        })
      );
    }
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleCloseModal}
        className="modal-dialog-centered model-width"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody style={{}}>
          <div className="cusTableDiv">
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: "20px" }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="PGCollectiontText"
                  //
                >
                  Please Select Any Route
                </div>
                <button
                  className="greenButton Medium text3 AddCustStartBtn ms-3"
                  onClick={toggleCreateCust}
                >
                  <div className="AddCust">+ Add Route</div>
                </button>
              </div>

              <div
                className="Medium text2  padding16L padding16R greenFont"
                onClick={() => {
                  createTrip(true);
                  handleCloseModal();
                }}
                style={{
                  borderWidth: 1,
                  borderColor: "#2e7c7b",
                  borderStyle: "solid",
                  borderRadius: 12,
                  alignSelf: "center",
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 10,
                  paddingBottom: 10,
                  cursor: "pointer",
                }}
              >
                Skip
              </div>
            </div>
            {routeLoader ? (
              <>
                <div>
                  <div
                    className="boxContainer"
                    style={{
                      padding: 70,
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: 70,
                    }}
                  >
                    <BallTriangle
                      height={70}
                      width={70}
                      radius={5}
                      color="#2e7c7b"
                      ariaLabel="ball-triangle-loading"
                      wrapperClass={{}}
                      wrapperStyle=""
                      visible={true}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {
                  <div>
                    <div>
                      <Table
                        className="CustSectionTable"
                        // id="CustSectionTable1"
                        bordered="true"
                      >
                        <thead>
                          <tr className="table-header-cust">
                            <th>S. No</th>
                            <th>
                              <div>Route Name</div>{" "}
                            </th>
                            <th>VC</th>
                            <th>from</th>
                            <th>to</th>
                          </tr>
                        </thead>
                        <tbody
                          className="lowerBorderLineCust"
                          style={{ height: "560px" }}
                        >
                          {routesList?.map((data, idx) => (
                            <tr
                              className="table-row-manage-cust-new text4"
                              key={idx}
                              onClick={() => {
                                console.log("press mat ke");
                                setRouteData(data);
                                createTrip(true);
                                handleCloseModal();
                              }}
                            >
                              <td
                                style={{
                                  borderLeft: "1px solid transparent",
                                  borderRight: " 1px solid transparent",
                                }}
                              >
                                {idx + 1}
                              </td>
                              <td
                                style={{
                                  borderLeft: "1px solid transparent",
                                  borderRight: " 1px solid transparent",
                                }}
                              >
                                {data?.name}
                              </td>
                              <td
                                style={{
                                  borderLeft: "1px solid transparent",
                                  borderRight: " 1px solid transparent",
                                }}
                              >
                                {data?.vc}
                              </td>
                              <td
                                style={{
                                  borderLeft: "1px solid transparent",
                                  borderRight: " 1px solid transparent",
                                }}
                              >
                                {data?.fromAddressRoute?.address +
                                  ", " +
                                  data?.fromAddressRoute?.cityName}
                              </td>
                              <td
                                style={{
                                  borderLeft: "1px solid transparent",
                                  borderRight: " 1px solid transparent",
                                }}
                              >
                                {data?.toAddressRoute?.address +
                                  ", " +
                                  data?.toAddressRoute?.cityName}
                              </td>
                            </tr>
                          ))}
                          {routesList?.length == 0 ? (
                            <div
                              className="d-flex justify-content-center"
                              style={{ marginTop: "24px" }}
                            >
                              <div
                                className=""
                                style={{
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <div>No Route Found Please add a new route</div>
                              </div>
                            </div>
                          ) : null}
                        </tbody>
                      </Table>
                      <div className="nextRow ">
                        <div className="nextRowCont ">
                          <button
                            className="PrevButton"
                            onClick={handlePrevButton}
                            style={{ opacity: offset > 0 ? "1" : "0.5" }}
                          >
                            <SvgImage
                              name="PrevArrow"
                              heigth="12px"
                              width="12px"
                            />
                          </button>
                          <button
                            className="nextButton"
                            onClick={handleNextButton}
                            style={{
                              opacity: routesCount - offset > 10 ? "1" : "0.5",
                            }}
                          >
                            <SvgImage
                              name="NextArrow"
                              heigth="12px"
                              width="12px"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
      {createCust ? (
        <CreateRoutes
          toggle={toggleCreateCust}
          modal={createCust}
          setModal={setCreateCust}
          data={data}
        />
      ) : null}
    </div>
  );
}

export default ChooseRoute;
