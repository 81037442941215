import React from "react";
import { useField, useFormikContext } from "formik";
import moment from "moment";
import DatePicker from "react-datepicker";
import "./input.css";

export const DatePickerField = ({
  name,
  value,
  header,
  onChange,
  maxDate,
  minDate,
  ref1,
  classname,
  showTimeInput,
}) => {
  return (
    <DatePicker
      className={classname || "input-body-3"}
      placeholderText={header}
      selected={(value && new Date(value)) || null}
      onChange={onChange}
      maxDate={maxDate}
      minDate={minDate}
      autoclose={true}
      dateFormat={showTimeInput ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      ref={ref1}
      timeFormat="HH:mm"
      showTimeInput={showTimeInput || false}
    />
  );
};
