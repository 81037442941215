
import {call, put, takeEvery} from 'redux-saga/effects';
import {
    businessDetailsApi,
  deleteExpenseApi,
  downloadAadharXMLApi,
  editExpenseApi,
  getAadharOtpApi,
  getExpenseImageAPI,
  getWalletTransactionHistoryApi,
  kycDoneApi,
  ownerDetailsApi,
  panAuthApi,
  refreshTokenApi,
  setPinRegApi,
  updatePanAadharApi
} from '../../Utils/Apis';
import {processErrorAndRespond} from '../../Utils/functions';
import { toastStatus } from '../Actions/appActions';
import { businessDetailsActionSuccess, downloadAadharXMLActionSuccess, getAadharOtpActionSuccess, ownerDetailsActionSuccess, panAuthActionSuccess, refreshTokenActionSuccess, setPinRegActionSuccess, updatePanAadharActionSuccess } from '../Actions/onboardingAction';
import {
  deleteExpenseActionSuccess,
  editExpenseActionSuccess,
  getExpenseImageSuccessAction,
  getWalletTransactionHistorySuccess,
  kycDoneActionSuccess
} from '../Actions/walletAction';
import { BUSINESS_DETAILS, DOWNLOAD_AADHAAR, GET_AADHAAR_OTP, OWNER_DETAILS, PAN_AUTH, REFRESH_TOKEN, SET_PIN, UPDATE_PAN_AADHAAR } from '../storeTypes';


function* ownerDetailsSaga(action) {
  try {
    const response = yield call(ownerDetailsApi, action?.payload);
    // console.log("getWalletTransactionHistorySaga >>> ", response);
    if (response.data.type == 'success') {
    //   console.log("success",response?.data?.data );
        action?.callback(response)
        yield put(ownerDetailsActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* businessDetailsSaga(action) {
  try {
    const response = yield call(businessDetailsApi, action?.payload);
    console.log("data", response);
    if (response.data.type == "success") {
      action?.callback(response);
      yield put(businessDetailsActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* updatePanAadharSaga(action){
  try {
    const response = yield call(updatePanAadharApi, action?.payload);
    console.log("data", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(updatePanAadharActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getAadharOtpSaga(action) {
  try {
    const response = yield call(getAadharOtpApi, action?.payload);
    console.log("rrrrrrrrrrrr ", response);
    if (response.data.type == 'success') {
      console.log("success",response?.data?.data );
      action.callback(response);
      yield put(getAadharOtpActionSuccess(response?.data?.data));
    //   yield put(
    //     toastStatus({
    //       toastStatus: true,
    //       message:"Expense data deleted successfully",
    //       status: true,
    //     })
    //   );
    }
  } catch (error) {
    console.log('Error.Response:- ', error.response);
    if(error?.response?.status==400){
      action.callback(error?.response);
    }
    // yield put(
    //   toastStatus({
    //     toastStatus: true,
    //     message: `Error : ${error?.response?.data}`,
    //     status: false,
    //   })
    // );
    //Alert.alert('Error', error.response.data.message);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}


function* panAuthSaga(action) {
  try {
    const response = yield call(panAuthApi, action?.payload);
    //console.log("rrrrrrrrrrrr ", response);
    if (response.data.type == 'success') {
      //console.log("success",response?.data?.data );
      yield put(panAuthActionSuccess(response?.data?.data));
    //   yield put(
    //     toastStatus({
    //       toastStatus: true,
    //       message:"Expense data was edited successfully",
    //       status: true,
    //     })
    //   );
    }
  } catch (error) {
    console.log('Error.Response:- ', error.response);
    // yield put(
    //   toastStatus({
    //     toastStatus: true,
    //     message: `Error : ${error?.response?.data}`,
    //     status: false,
    //   })
    // );
    //Alert.alert('Error', error.response.data.message);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}



function* downloadAadharXMLSaga(action){
    try {
      const response = yield call(downloadAadharXMLApi, action?.payload);
      console.log("data", response);
      if (response.data.type == "success") {
        action.callback(response);
        yield put(downloadAadharXMLActionSuccess(response?.data?.data));
      }
    } catch (error) {
      console.log("API Error:- ", processErrorAndRespond(error));
      action.callback(error);
    }
  }

  function* setPinRegSaga(action){
    try {
      const response = yield call(setPinRegApi, action?.payload);
      console.log("data", response);
      if (response.data.type == "success") {
        action.callback(response);
        yield put(setPinRegActionSuccess(response?.data?.data));
      }
    } catch (error) {
      console.log("API Error:- ", processErrorAndRespond(error));
    }
  }

  function* refreshTokenSaga(action){
    try {
      const response = yield call(refreshTokenApi, action?.payload);
      console.log("data", response);
      if (response.data.type == "success") {
        action.callback(response);
        yield put(refreshTokenActionSuccess(response?.data?.data));
      }
    } catch (error) {
      console.log("API Error:- ", processErrorAndRespond(error));
    }
  }

export function* watchOnboardingSagas() {
    yield takeEvery(OWNER_DETAILS, ownerDetailsSaga);
    yield takeEvery(BUSINESS_DETAILS, businessDetailsSaga);
    yield takeEvery(UPDATE_PAN_AADHAAR,updatePanAadharSaga);
    yield takeEvery(GET_AADHAAR_OTP,getAadharOtpSaga);
    yield takeEvery(PAN_AUTH,panAuthSaga);
    yield takeEvery(DOWNLOAD_AADHAAR,downloadAadharXMLSaga);
    yield takeEvery(SET_PIN,setPinRegSaga);
    yield takeEvery(REFRESH_TOKEN,refreshTokenSaga);
}
