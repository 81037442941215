import React from "react";
import './index.css'
function ManageDriverVehicleListBar() {
    return (
        <div>
            <div className="row">
                <div className="col-4 Manage-Driver-details-bar-name">
                    Driver Name
                </div>
                <div className="col-4 manage-Driver-details-bar-vehicleno ">
                    Vehicle No.
                </div>
                <div className="col-4 manage-Driver-details-bar-bal">
                    Total Bal.
                </div>
            </div>
        </div>
    )

}
export default ManageDriverVehicleListBar;