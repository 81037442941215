import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import { Formik } from "formik";
// import AddDriverV2 from './addDriverV2';
// import FreightDetails from './FreightDetails';
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import { storeMedia } from "../../../../services/Store/Actions/appActions";
import { useEffect } from "react";
import { addDriverV2 } from "../../../../services/Store/Actions/TripsAction";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";

function AddDriverV2(props) {
  const { toggle, freight, setFreight } = props;
  const dispatch = useDispatch();
  const [status1, setStatus1] = useState(false);
  const [status2, setStatus2] = useState(false);
  const [status3, setStatus3] = useState(false);
  const [status4, setStatus4] = useState(false);
  const [displayName1, setDisplayName1] = useState([]);
  const [displayName2, setDisplayName2] = useState([]);
  const [displayName3, setDisplayName3] = useState([]);
  const [displayName4, setDisplayName4] = useState([]);
  const closeBtn = (
    <button
      className="close"
      style={{
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        backgroundColor: "#FFF",
        borderColor: "#2E7C7B",
        display: "flex",
        justifyContent: "space-evenly",
      }}
      onClick={toggle}
      type="button"
    >
      <div className="x">X</div>
    </button>
  );
  const validationSchema = Yup.object().shape({
    phone: Yup.number(10)
      .required("Required"),
    fullName : Yup.string()
    .required("Required"),
  });
  const handleCloseFreight = () => {
    setFreight(!freight);
  };

  return (
    <div>
      <Modal
        isOpen={freight}
        onHide={handleCloseFreight}
        className="all-transaction-main-modal"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px" }}
        >
          <div className="PGCollectionBox">
            <div className=/*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction">
              <div className="closeEffect" onClick={toggle}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
            </div>
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Add Driver
            </div>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                phone:'',
                fullName:'',
                // dlFront: "",
                // dlBack: "",
                // aadharFront: "",
                // aadharBack: "",
              }}
              onSubmit={(values) => {
                console.log('driverDetails', values);
                dispatch(addDriverV2(values, (res)=>{
                  dispatch(
                    getDriverList({
                      offset: "",
                      // search: searchDriver,
                      minKyc: "",
                      sortAs: "",
                      sortBy: "",
                      limit: 10,
                    })
                  );
                  toggle()
                  console.log('resss herererer', res)
                }));
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "50px", padding: "0 75px 0 50px" }}
                >
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "30px",
                    }}
                  >
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Driver Number:
                      </label>
                        <input
                          type="text"
                          id="phone"
                          className={`form-control-1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          name="phone"
                          onChange={(event) => {
                            setFieldValue("phone", event.target.value, true);
                          }}
                          value={values.phone}
                          // onBlur={() => {
                          //   dispatch(
                          //     sendAnalitics("Enter_Amt_Web", {
                          //       upi_netBank_amount: values.amount,
                          //     })
                          //   );
                          //   handleBlur("amount");
                          // }}
                          touched={touched.phone}
                          errors={errors.phone}
                          placeholder="Phone Number"
                        />
                    </FormGroup>
                    <FormGroup style={{ marginRight: "90px" }}>
                      <label for="vrn" className="heading3 semiBold">
                        Driver Name:
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          type="text"
                          id="fullName"
                          className={`form-control-1 ${
                            touched.fullName && errors.fullName ? "is-invalid" : ""
                          }`}
                          name="fullName"
                          onChange={(event) => {
                            setFieldValue("fullName", event.target.value, true);
                          }}
                          value={values.fullName}
                          touched={touched.fullName}
                          errors={errors.fullName}
                          placeholder="Enter Name"
                        />
                      </div>
                    </FormGroup>
                  </FormGroup>

                  {/* <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "25px",
                    }}
                  >
                    <FormGroup>
                      <label for="dlFront" className="heading3 semiBold">
                        Driver License Front
                        {!status1 ? (
                          <p
                            className="heading3 Regular"
                            style={{ margin: "10px 0 5px" }}
                          >
                            Upload Document
                          </p>
                        ) : (
                          <></>
                        )}
                      </label>
                      {!status1 ? (
                        <Button
                          className="add-attach-for-fastag-linked-vehicle-new"
                          type="button"
                        >
                          <SvgImage
                            name="AddAttachment"
                            height="25"
                            width="25"
                          />
                          <input
                            type="file"
                            // multiple
                            // accept="image/*"
                            accept="image/*"
                            className="add-lr-attachment"
                            onChange={(e) => {
                              const img = e?.target.files[0];
                              console.log("image", img?.name);
                              setStatus1(true);
                              setDisplayName1(
                                (prev) => [{ name: img?.name }]
                                //  [
                                //   ...prev,
                                //   { name: img?.name },
                                // ]
                              );

                              const tempArray = Object.assign([], values.docs);
                              console.log("sel", tempArray);

                              if (img == null) {
                                //clear image
                                setFieldValue("dlFront", "", true);
                                //for now  only one supported
                              } else {
                                console.log("upload", {
                                  img: img,
                                  uploadType: "dlFront",
                                  isRes: true,
                                });

                                dispatch(
                                  storeMedia(
                                    {
                                      img: img,
                                      uploadType: "aadharFront",
                                      isRes: false,
                                    },
                                    (res) => {
                                      if (res?.url) {
                                        console.log("opt", res);
                                        tempArray.push(res?.data?.Location);
                                        setFieldValue(
                                          "dlFront",
                                          res?.url,
                                          true
                                        );
                                        // setFieldValue('docs', tempArray, true);
                                        //for now  only one supported
                                        setFieldValue(
                                          "dlFront",
                                          [res?.data?.Location],
                                          true
                                        );
                                      }
                                    }
                                  )
                                );
                              }
                            }}
                          />
                          <div
                            style={{
                              margin: "0px -16px 2px -614px",
                              color: "red",
                            }}
                          >
                            {errors.vehicleRcPhoto && touched.vehicleRcPhoto ? (
                              <div>*{errors.vehicleRcPhoto}</div>
                            ) : null}
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <div className="selected-img">
                        {status1 ? (
                          displayName1?.map((d) => {
                            return (
                              <div className="row">
                                <div className="col-10">
                                  <p
                                    style={{
                                      color: "black",
                                      margin: "5px 0 0",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={() => {
                                    setStatus1(false);
                                  }}
                                  // style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage
                                    name="CloseButtonLogo"
                                    height="25"
                                    width="25"
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="dlBack" className="heading3 semiBold">
                        Driver License Back
                        {!status2 ? (
                          <p
                            className="heading3 Regular"
                            style={{ margin: "10px 0 5px" }}
                          >
                            Upload Document
                          </p>
                        ) : (
                          <></>
                        )}
                      </label>
                      {!status2 ? (
                        <Button
                          className="add-attach-for-fastag-linked-vehicle-new"
                          type="button"
                        >
                          <SvgImage
                            name="AddAttachment"
                            height="25"
                            width="25"
                          />
                          <input
                            type="file"
                            // multiple
                            // accept="image/*"
                            accept="image/*"
                            className="add-lr-attachment"
                            onChange={(e) => {
                              const img = e?.target.files[0];
                              console.log("image", img?.name);
                              setStatus2(true);
                              setDisplayName2(
                                (prev) => [{ name: img?.name }]
                                //  [
                                //   ...prev,
                                //   { name: img?.name },
                                // ]
                              );

                              const tempArray = Object.assign([], values.docs);
                              console.log("sel", tempArray);

                              if (img == null) {
                                //clear image
                                setFieldValue("dlBack", "", true);
                                //for now  only one supported
                              } else {
                                console.log("upload", {
                                  img: img,
                                  uploadType: "dlBack",
                                  isRes: true,
                                });

                                dispatch(
                                  storeMedia(
                                    {
                                      img: img,
                                      uploadType: "dlBack",
                                      isRes: false,
                                    },
                                    (res) => {
                                      if (res?.url) {
                                        console.log("opt", res);
                                        tempArray.push(res?.data?.Location);
                                        setFieldValue(
                                          "dlBack",
                                          res?.url,
                                          true
                                        );
                                        // setFieldValue('docs', tempArray, true);
                                        //for now  only one supported
                                        setFieldValue(
                                          "dlBack",
                                          [res?.data?.Location],
                                          true
                                        );
                                      }
                                    }
                                  )
                                );
                              }
                            }}
                          />
                          <div
                            style={{
                              margin: "0px -16px 2px -614px",
                              color: "red",
                            }}
                          >
                            {errors.vehicleRcPhoto && touched.vehicleRcPhoto ? (
                              <div>*{errors.vehicleRcPhoto}</div>
                            ) : null}
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <div className="selected-img">
                        {status2 ? (
                          displayName2?.map((d) => {
                            return (
                              <div className="row">
                                <div className="col-10">
                                  <p
                                    style={{
                                      color: "black",
                                      margin: "5px 0 0",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={() => {
                                    setStatus2(false);
                                  }}
                                  // style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage
                                    name="CloseButtonLogo"
                                    height="25"
                                    width="25"
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "25px",
                    }}
                  >
                    <FormGroup>
                      <label for="aadharFront" className="heading3 semiBold">
                        Aadhar Front
                        {!status3 ? (
                          <p
                            className="heading3 Regular"
                            style={{ margin: "10px 0 5px" }}
                          >
                            Upload Document
                          </p>
                        ) : (
                          <></>
                        )}
                      </label>
                      {!status3? (
                        <Button
                          className="add-attach-for-fastag-linked-vehicle-new"
                          type="button"
                        >
                          <SvgImage
                            name="AddAttachment"
                            height="25"
                            width="25"
                          />
                          <input
                            type="file"
                            // multiple
                            // accept="image/*"
                            accept="image/*"
                            className="add-lr-attachment"
                            onChange={(e) => {
                              const img = e?.target.files[0];
                              console.log("image", img?.name);
                              setStatus3(true);
                              setDisplayName3(
                                (prev) => [{ name: img?.name }]
                                //  [
                                //   ...prev,
                                //   { name: img?.name },
                                // ]
                              );

                              const tempArray = Object.assign([], values.docs);
                              console.log("sel", tempArray);

                              if (img == null) {
                                //clear image
                                setFieldValue("aadharFront", "", true);
                                //for now  only one supported
                              } else {
                                console.log("upload", {
                                  img: img,
                                  uploadType: "aadharFront",
                                  isRes: true,
                                });

                                dispatch(
                                  storeMedia(
                                    {
                                      img: img,
                                      uploadType: "aadharFront",
                                      isRes: false,
                                    },
                                    (res) => {
                                      if (res?.url) {
                                        console.log("opt", res);
                                        tempArray.push(res?.data?.Location);
                                        setFieldValue(
                                          "aadharFront",
                                          res?.url,
                                          true
                                        );
                                        // setFieldValue('docs', tempArray, true);
                                        //for now  only one supported
                                        setFieldValue(
                                          "aadharFront",
                                          [res?.data?.Location],
                                          true
                                        );
                                      }
                                    }
                                  )
                                );
                              }
                            }}
                          />
                          <div
                            style={{
                              margin: "0px -16px 2px -614px",
                              color: "red",
                            }}
                          >
                            {errors.vehicleRcPhoto && touched.vehicleRcPhoto ? (
                              <div>*{errors.vehicleRcPhoto}</div>
                            ) : null}
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <div className="selected-img">
                        {status3 ? (
                          displayName3?.map((d) => {
                            return (
                              <div className="row">
                                <div className="col-10">
                                  <p
                                    style={{
                                      color: "black",
                                      margin: "5px 0 0",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={() => {
                                    setStatus3(false);
                                  }}
                                  // style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage
                                    name="CloseButtonLogo"
                                    height="25"
                                    width="25"
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup style={{ marginRight: "120px" }}>
                      <label for="aadharBack" className="heading3 semiBold">
                        Aadhar Back
                        {!status4 ? (
                          <p
                            className="heading3 Regular"
                            style={{ margin: "10px 0 5px" }}
                          >
                            Upload Document
                          </p>
                        ) : (
                          <></>
                        )}
                      </label>
                      {!status4 ? (
                        <Button
                          className="add-attach-for-fastag-linked-vehicle-new"
                          type="button"
                        >
                          <SvgImage
                            name="AddAttachment"
                            height="25"
                            width="25"
                          />
                          <input
                            type="file"
                            // multiple
                            // accept="image/*"
                            accept="image/*"
                            className="add-lr-attachment"
                            onChange={(e) => {
                              const img = e?.target.files[0];
                              console.log("image", img?.name);
                              setStatus4(true);
                              setDisplayName4(
                                (prev) => [{ name: img?.name }]
                                //  [
                                //   ...prev,
                                //   { name: img?.name },
                                // ]
                              );

                              const tempArray = Object.assign([], values.docs);
                              console.log("sel", tempArray);

                              if (img == null) {
                                //clear image
                                setFieldValue("aadharBack", "", true);
                                //for now  only one supported
                              } else {
                                console.log("aadharBack", {
                                  img: img,
                                  uploadType: "aadharBack",
                                  isRes: true,
                                });

                                dispatch(
                                  storeMedia(
                                    {
                                      img: img,
                                      uploadType: "aadharBack",
                                      isRes: false,
                                    },
                                    (res) => {
                                      if (res?.url) {
                                        console.log("opt", res);
                                        tempArray.push(res?.data?.Location);
                                        setFieldValue(
                                          "aadharBack",
                                          res?.url,
                                          true
                                        );
                                        // setFieldValue('docs', tempArray, true);
                                        //for now  only one supported
                                        setFieldValue(
                                          "aadharBack",
                                          [res?.data?.Location],
                                          true
                                        );
                                      }
                                    }
                                  )
                                );
                              }
                            }}
                          />
                          <div
                            style={{
                              margin: "0px -16px 2px -614px",
                              color: "red",
                            }}
                          >
                            {errors.vehicleRcPhoto && touched.vehicleRcPhoto ? (
                              <div>*{errors.vehicleRcPhoto}</div>
                            ) : null}
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <div className="selected-img">
                        {status4 ? (
                          displayName4?.map((d) => {
                            return (
                              <div className="row">
                                <div className="col-10">
                                  <p
                                    style={{
                                      color: "black",
                                      margin: "5px 0 0",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={() => {
                                    setStatus4(false);
                                  }}
                                  // style={{ paddingBottom: "0.5rem" }}
                                >
                                  <SvgImage
                                    name="CloseButtonLogo"
                                    height="25"
                                    width="25"
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </FormGroup>
                  </FormGroup> */}
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "25px",
                      marginTop: "100px",
                    }}
                  >
                    <Button
                      style={{ width: "25%" }}
                      className="notGenerateOpt text4 Medium btn btn-secondary"
                      onClick={toggle}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      style={{ width: "25%" }}
                      className="generateOtp text3 Medium btn btn-secondary"
                      // onClick={toggle}
                    >
                      Next
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>

      {/* <AddVehicle1
        toggle = {toggleAddVehicle1}
        modal ={vehicle}
      //   className={className}
      /> */}
      {/* <AddDriverV2
         toggle = {toggleAddDriverV2}
         modal  = {driverV2}
      />
      <FreightDetails
          toggle = {toggleFreightDetails}
          modal  = {fr_Details}
      /> */}
    </div>
  );
}

export default AddDriverV2;
