import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import { getVehicleListAction } from "../../../../services/Store/Actions/manageVehicleAction";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import {
  createTrip,
  getCityAndState,
  getLRList,
  getSavedAddress,
  getTripsNew,
  getVehicleLastTripAction,
  updateTrip,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
import Toast from "../../../subComponents/toast";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import AddAddress from "../../../LoadReceipt/Components/AddAddress";
import DelayReason from "../../../TripsMainV2/Components/DelayReason";

function CreateTripFromLR(props) {
  const {
    toggle,
    modal,
    setModal,
    selectedRow,
    setSelectedRow,
    setCreateInvoice,
    setCustomerIdInvoice,
    setPaymentDetailsModal,
    routeData,
    setTripData,
  } = props;
  const [openToast, setOpenToast] = useState(false);
  const [openToastFailure, setOpenToastFailure] = useState(false);
  const [openToastFailureText, setOpenToastFailureText] = useState(
    "Error in creating trips"
  );
  const [tripIdTemp, setTripIdTemp] = useState("");

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [freight, setFreight] = useState(false);
  const [driver, setdriver] = useState(false);
  const [vehicle, setVehicle] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const addDriver = () => setdriver(!driver);
  const addVehicle = () => setVehicle(!vehicle);
  const addAddress = () => setAddressModal(!addressModal);
  const dispatch = useDispatch();
  const datepickerRefEnd = useRef();
  const datepickerRefStart = useRef();
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [tripNo, setTripNo] = useState("");
  const [searchDriver, setSearchDriver] = useState("");
  const [vehicleTripDetail, setVehicleTripDetail] = useState({});

  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [openDelayReason, setDelayReason] = useState(false);
  const toggleReason = () => {
    setDelayReason(!openDelayReason);
  };
  const [color1, setColor1] = useState(-1);
  const [color2, setColor2] = useState(-1);
  const { cityAndStateList } = useSelector((store) => store?.trip);
  const { savedAddresses } = useSelector((state) => state?.trip);
  const allFleets = useSelector(
    (state) => state?.manageVehicle?.allFleets?.rows
  );
  const vehCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );
  let { tripsCount, tripsListNew } = useSelector((store) => store?.trip);
  // const fastVeh =
  //   vehCount > 0 ? allFleets?.find((ele) => ele?.Fastag !== null) : "value";
  const driverList = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.rows
  );
  const driverCount = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.count
  );
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const closeBtn = (
    <button
      className="close"
      style={{
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        backgroundColor: "#FFF",
        borderColor: "#2E7C7B",
        display: "flex",
        justifyContent: "space-evenly",
      }}
      onClick={toggle}
      type="button"
    >
      <div className="x">X</div>
    </button>
  );
  let endDate = selectedRow?.[0]?.startDate
    ? new Date(selectedRow?.[0]?.startDate)
    : new Date();
  let newEndDate = routeData?.expectedTimeHR
    ? new Date(endDate.setHours(endDate.getHours() + routeData?.expectedTimeHR))
    : "";
  // console.log(
  //   "newEndDatenewEndDate",
  //   parseFloat(routeData?.distanceCal)?.toLocaleString('en-IN')
  // );
  const validationSchema = Yup.object().shape({
    // tripNo: Yup.string()
    //     .required("TripNo is required"),
    source: Yup.string().required("Source is required"),

    destination: Yup.string().required("Destination is required"),

    fleetId: Yup.string().required("Vehicle is required"),

    driverUserId: Yup.string().required("Driver is required"),

    startDate: Yup.string().required("StartDate is required"),
  });

  const handleClose = () => {
    setModal(!modal);
  };
  useEffect(() => {
    dispatch(
      getVehicleListAction({
        offset: "",
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [search]);
  useEffect(() => {
    dispatch(
      getTripsNew({
        offset: 0,
        limit: 10,
      })
    );
  }, []);
  useEffect(() => {
    tripNumberFromLastTrip();
  }, [tripsListNew]);
  useEffect(() => {
    dispatch(
      getDriverList({
        offset: "",
        search: searchDriver,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [searchDriver]);
  console.log(
    "================================================================"
  );
  console.log(
    "================================================================",
    selectedRow
  );
  console.log(
    "================================================================"
  );
  useEffect(() => {
    if (display1 == true) {
      // dispatch(findTripAddressV2({},(res)=>{
      //   console.log("findTripAddressV2",res);
      // }))
      dispatch(
        getSavedAddress({ address: search1 }, (res) => {
          console.log("getSavedAddress", res);
        })
      );
    }
  }, [display1, search1]);
  useEffect(() => {
    if (display2 == true) {
      dispatch(
        getSavedAddress({ address: search2 }, (res) => {
          console.log("getSavedAddress", res);
        })
      );
    }
  }, [display2, search2]);
  function tripNumberFromLastTrip() {
    let tripNoString = tripsListNew?.[0]?.tripNo;
    let arr = tripNoString?.split("-");
    console.log("tripNumberFromLastTrip", arr);
    if (arr) {
      let finalString = parseInt(arr?.[1]) + 1;
      console.log("tripNumberFromLastTrip", finalString);
      setTripNo(arr?.[0] + "-" + finalString);
    }
  }
  useEffect(() => {
    dispatch(
      getVehicleLastTripAction(
        { fleetId: selectedRow?.[0]?.fleetId },
        (res) => {
          if (res.status == 200) {
            console.log("getVehicleLastTripAction", res?.data?.data);
            setVehicleTripDetail(res?.data?.data);
          }
        }
      )
    );
  }, [selectedRow?.[0]?.fleetId]);
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // scrollable={true}
        centered={true}
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px" }}
        >
          <div className="PGCollectionBox">
            <div className=/*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction">
              <div className="closeEffect" onClick={handleClose}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
            </div>
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Create Trip
            </div>
            {console.log(
              "pppppppppppppppppppppppppppppppppp",
              routeData?.fromAddressRoute?.addressId
                ? `${routeData?.fromAddressRoute?.address}, ${routeData?.fromAddressRoute?.cityName} - ${routeData?.fromAddressRoute?.stateName}`
                : selectedRow?.[0]?.fromAddress?.addressId
                ? `${selectedRow?.[0]?.fromAddress?.address}, ${selectedRow?.[0]?.fromAddress?.cityName} - ${selectedRow?.[0]?.fromAddress?.stateName}` ||
                  ""
                : ""
            )}
            <Formik
              // isValid='true'
              enableReinitialize
              // validationSchema={validationSchema}
              initialValues={{
                source: routeData?.fromAddressRoute?.addressId
                  ? `${routeData?.fromAddressRoute?.address}, ${routeData?.fromAddressRoute?.cityName} - ${routeData?.fromAddressRoute?.stateName}`
                  : selectedRow?.[0]?.fromAddress?.addressId
                  ? `${selectedRow?.[0]?.fromAddress?.address}, ${selectedRow?.[0]?.fromAddress?.cityName} - ${selectedRow?.[0]?.fromAddress?.stateName}` ||
                    ""
                  : "",
                destination: routeData?.toAddressRoute?.addressId
                  ? `${routeData?.toAddressRoute?.address}, ${routeData?.toAddressRoute?.cityName} - ${routeData?.toAddressRoute?.stateName}`
                  : selectedRow?.[0]?.toAddress?.addressId
                  ? `${selectedRow?.[0]?.toAddress?.address}, ${selectedRow?.[0]?.toAddress?.cityName} - ${selectedRow?.[0]?.toAddress?.stateName}` ||
                    ""
                  : "",
                startDate: selectedRow?.[0]?.startDate || "",
                endDate: newEndDate || "",
                tripNo: tripNo || "TR-1",
                vrn: selectedRow?.[0]?.Fleet?.regNumber || "",
                driver: selectedRow?.[0]?.User?.fullName || "",
                fleetId: selectedRow?.[0]?.fleetId || "",
                sourceId: routeData?.fromAddressRoute?.addressId
                  ? routeData?.fromAddressRoute?.addressId
                  : selectedRow?.[0]?.fromAddress?.addressId || "",
                destId: routeData?.toAddressRoute?.addressId
                  ? routeData?.toAddressRoute?.addressId
                  : selectedRow?.[0]?.toAddress?.addressId || "",
                driverUserId: selectedRow?.[0]?.userId || "",
                expectedDistanceKM: routeData?.distanceCal
                  ? parseFloat(routeData?.distanceCal)?.toLocaleString("en-IN")
                  : parseFloat(routeData?.expectedDistanceKM)?.toLocaleString(
                      "en-IN"
                    ) || "",
              }}
              onSubmit={(values) => {
                //create new trip
                let objVal = {
                  source: String(values?.sourceId),
                  destination: String(values?.destId),
                  fleetId: values?.fleetId,
                  userId: values?.driverUserId,
                  expectedStartTime: values.startDate,
                  tripNo: values?.tripNo ? values?.tripNo : "",
                  selectedLr: selectedRow,
                  expectedDistanceKM: values.expectedDistanceKM,
                };

                console.log("dfsada", values?.endDate);
                if (values?.endDate != "") {
                  objVal["expectedEndTime"] = values.endDate;
                }
                // setOpenToast(true);
                // handleClose(false);
                console.log("submittttttttt hereerere", objVal);
                dispatch(
                  createTrip(objVal, (res) => {
                    if (res?.status == 200) {
                      // console.log('rererere',res)
                      setOpenToast(true);
                      // handleClose(false);
                      setTripData(res?.data?.data);
                      setTripIdTemp(res?.data?.data?.tripId);
                      // setFreight(!freight);
                      console.log("...............", res);
                      // alert("success");
                    } else {
                      setOpenToastFailure(true);
                      setOpenToastFailureText(res?.data?.message);
                    }
                  })
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "50px", padding: "0 75px 0 50px" }}
                >
                  <FormGroup>
                    <label for="tripNo" className="">
                      Trip No:
                    </label>
                    <input
                      type="text"
                      id="tripNo"
                      className={`form-control-1 ${
                        touched.amount && errors.amount ? "is-invalid" : ""
                      }`}
                      name="tripNo"
                      onChange={(event) => {
                        // setSearch(event.target.value);
                        setFieldValue("tripNo", event.target.value, true);
                      }}
                      value={values.tripNo}
                      touched={touched.tripNo}
                      errors={errors.tripNo}
                      placeholder="TR-12345"
                    />
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "30px",
                    }}
                  >
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Vehicle No:
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          className={`form-control-1 ${
                            touched.vrn && errors.vrn ? "is-invalid" : ""
                          }`}
                          readonly={values.vrn ? true : false}
                          type="text"
                          id="vrn"
                          name="vrn"
                          touched={touched.vrn}
                          errors={errors.vrn}
                          onClick={() => {
                            setOpen(true);
                          }}
                          onBlur={() => {
                            handleBlur("driver");
                            // setOpen(false);
                          }}
                          onChange={(event) => {
                            setSearch(event.target.value);
                            setFieldValue("vrn", event.target.value, true);
                          }}
                          value={values.vrn}
                          autoComplete="off"
                          autoFocus
                          placeholder="Select Vehicle"
                        />
                        {/* {values.vrn ? null : ( */}
                        <span
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={addVehicle}
                        >
                          + Add Vehicle
                        </span>
                        {/* )} */}
                      </div>
                      <div style={{ position: "relative" }}>
                        {open ? (
                          <div className="driverSearchContainer containerShadow">
                            <div
                              style={{
                                position: "absolute",
                                top: "0.3rem",
                                right: "0.3rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpen(false);
                              }}
                            >
                              <SvgImage name="CloseButtonLogo" />
                            </div>
                            {allFleets?.map((item, i) => {
                              return (
                                <>
                                  {/* {item?.Fastag !== null ? ( */}
                                    <div
                                      key={i}
                                      className="flexStyling padding8 "
                                      onClick={(event) => {
                                        setOpen(false);
                                        setFieldValue(
                                          "vrn",
                                          item?.regNumber,
                                          true
                                        );
                                        setFieldValue(
                                          "fleetId",
                                          item?.fleetId,
                                          true
                                        );
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="vrn"
                                        value={item?.fleetId}
                                        id={item?.fleetId}
                                      />
                                      <div
                                        className="padding16L"
                                        value={item?.fleetId}
                                      >
                                        {item?.regNumber}
                                      </div>
                                    </div>
                                  {/* ) : (
                                    <></>
                                  )} */}
                                </>
                              );
                            })}
                            {vehCount == 0 ? (
                              <div className="heading3 semiBold padding16 padding24B">
                                No Vehicle Available
                              </div>
                            ) 
                            // :
                            //  fastVeh == undefined ? (
                            //   <div className="heading3 semiBold padding16 padding24B">
                            //     No Vehicle Available
                            //   </div>
                            // ) 
                            : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.fleetId && touched.fleetId ? (
                            <div>*{errors.fleetId}</div>
                          ) : null}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Driver:
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          className={`form-control-1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          type="text"
                          id="driver"
                          name="driver"
                          touched={touched.driver}
                          errors={errors.driver}
                          onBlur={() => {
                            handleBlur("driver");
                            // setOpen1(false);
                          }}
                          onClick={() => {
                            setOpen1(true);
                          }}
                          autoComplete="off"
                          onChange={(event) => {
                            setSearchDriver(event.target.value);
                            setFieldValue("driver", event.target.value, true);
                          }}
                          value={values.driver}
                          autoFocus
                          placeholder="Select Driver"
                        />
                        {console.log(
                          "values.drivervalues.driver",
                          values.driver
                        )}
                        <span
                          id="addDriver"
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={addDriver}
                        >
                          + Add Driver
                        </span>
                      </div>
                      <div style={{ position: "relative" }}>
                        {open1 ? (
                          <div className="driverSearchContainer containerShadow">
                            <div
                              style={{
                                position: "absolute",
                                top: "0.3rem",
                                right: "0.3rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpen1(false);
                              }}
                            >
                              <SvgImage name="CloseButtonLogo" />
                            </div>
                            {driverList?.map((item, i) => {
                              return (
                                <>
                                  {item?.Fastag !== null ? (
                                    <div
                                      key={i}
                                      className="flexStyling padding8 "
                                      onClick={(event) => {
                                        setFieldValue(
                                          "driver",
                                          item?.User?.fullName,
                                          true
                                        );
                                        console.log("itemadas", item);
                                        setFieldValue(
                                          "driverUserId",
                                          item?.User?.userId,
                                          true
                                        );
                                        // setOpen1(false);
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="driver"
                                        value={item?.User?.userId}
                                        id={item?.User?.userId}
                                      />
                                      <div className="padding16L">
                                        {item?.User?.fullName.length > 35
                                          ? item?.User?.fullName.split(0, 35) +
                                            "..."
                                          : item?.User?.fullName}
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                            {driverCount == 0 ? (
                              <div className="heading3 semiBold padding16 padding24B">
                                No driver Available
                              </div>
                            ) : driverCount == undefined ? (
                              <div className="heading3 semiBold padding16 padding24B">
                                No driver Available
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.driverUserId && touched.driverUserId ? (
                            <div>*{errors.driverUserId}</div>
                          ) : null}
                        </div>
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormGroup>
                      <label for="from" className="heading3 semiBold">
                        From
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          type="text"
                          name="source"
                          id="source"
                          placeholder={"Source City"}
                          className={`form-control-1 ${
                            touched.source && errors.source ? "is-invalid" : ""
                          }`}
                          aria-label="Default select example"
                          onChange={(e) => {
                            setDisplay1(true);
                            setSearch1(e.target.value);
                            setFieldValue("source", e.target.value, true);
                          }}
                          onClick={() => {
                            setDisplay1(true);
                          }}
                          value={values.source}
                          autoComplete="off"
                          onBlur={handleBlur("source")}
                          errors={errors.source}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              // findSearch1();
                            }
                          }}
                        ></input>
                        <span
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={addAddress}
                        >
                          + Add Address
                        </span>
                      </div>
                      {display1 ? (
                        <div
                          className="padding16 newCashback whiteBackground"
                          style={{
                            position: "absolute",
                            width: "350px",
                            height: "275px",
                            zIndex: "90",
                          }}
                        >
                          <div
                            onClick={() => {
                              setDisplay1(false);
                              setSearch1("");
                              //dispatch(clearAllDriversByVrn());
                              // setFieldValue("source", "", true);
                              // setFieldValue("sourceId", "", true);
                            }}
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            <SvgImage name="CloseButtonLogo" />
                          </div>
                          <div
                            style={{
                              overflowY: "scroll",
                              overflowX: "hidden",
                              height: "240px",
                            }}
                          >
                            {console.log(
                              "savedAddresses11111",
                              search1,
                              savedAddresses
                            )}
                            {search1 == "" ? (
                              savedAddresses?.map((data, i) => {
                                let city = data?.cityName;
                                let state = data?.stateName;
                                let address = data?.address;
                                console.log(
                                  "savedAddresses",
                                  city,
                                  state,
                                  address
                                );
                                return (
                                  <div
                                    key={i}
                                    className="padding8 row"
                                    onClick={() => {
                                      // setcityId1(data?.cityName);
                                      setColor1(i);
                                      setDisplay1(false);
                                      //setUserId(data?.User?.userId);
                                      setFieldValue(
                                        "sourceId",
                                        data?.addressId,
                                        true
                                      );
                                      setFieldValue(
                                        "source",
                                        `${address}, ${city} - ${state}`,
                                        true
                                      );
                                    }}
                                  >
                                    {color1 === i ? (
                                      <div className="col-1">
                                        <SvgImage name="SelectedRadio" />
                                      </div>
                                    ) : (
                                      <div className="col-1">
                                        <SvgImage name="UnselectedRadio" />
                                      </div>
                                    )}
                                    {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                    <div
                                      className="col-11"
                                      style={{ textAlign: "left" }}
                                    >
                                      {address}, {city} - {state}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                {savedAddresses?.map((data, i) => {
                                  let city = data?.cityName;
                                  let state = data?.stateName;
                                  let address = data?.address;

                                  console.log("check status", city, state);
                                  // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                                  return (
                                    <div
                                      key={i}
                                      className="padding8 row"
                                      onClick={() => {
                                        // setcityId1(data?.cityName);
                                        setColor1(i);
                                        setDisplay1(false);
                                        //setUserId(data?.User?.userId);
                                        setFieldValue(
                                          "sourceId",
                                          data?.addressId,
                                          true
                                        );
                                        setFieldValue(
                                          "source",
                                          `${address}, ${city} - ${state}`,
                                          true
                                        );
                                      }}
                                    >
                                      {color1 === i ? (
                                        <div className="col-1">
                                          <SvgImage name="SelectedRadio" />
                                        </div>
                                      ) : (
                                        <div className="col-1">
                                          <SvgImage name="UnselectedRadio" />
                                        </div>
                                      )}
                                      <div
                                        className="col-11"
                                        style={{ textAlign: "left" }}
                                      >
                                        {address}, {city} - {state}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                            {savedAddresses?.length == 0 ? (
                              <div className="">
                                <div style={{ margin: "24px" }}>
                                  You have no addresses to select please add a
                                  new one
                                </div>
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="greenButton d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "200px",
                                      cursor: "pointer",
                                    }}
                                    onClick={addAddress}
                                  >
                                    <div>Add New Address</div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.source && touched.source ? (
                          <div>*{errors.source}</div>
                        ) : null}
                      </div>
                    </FormGroup>

                    <FormGroup style={{}}>
                      <label for="to" className="heading3 semiBold">
                        To
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          type="text"
                          name="destination"
                          id="destination"
                          placeholder={"Destination City"}
                          className={`form-control-1 ${
                            touched.destination && errors.destination
                              ? "is-invalid"
                              : ""
                          }`}
                          aria-label="Default select example"
                          onChange={(e) => {
                            setDisplay2(true);
                          }}
                          onClick={() => {
                            setDisplay2(true);
                          }}
                          value={values.destination}
                          autoComplete="off"
                          onBlur={handleBlur("destination")}
                          errors={errors.destination}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              // findSearch2();
                            }
                          }}
                        ></input>
                        <span
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={addAddress}
                        >
                          + Add Address
                        </span>
                      </div>
                      {display2 ? (
                        <div
                          className="padding16 newCashback whiteBackground"
                          style={{
                            position: "absolute",
                            width: "350px",
                            height: "275px",
                            zIndex: "90",
                          }}
                        >
                          <div
                            onClick={() => {
                              setDisplay2(false);
                              setSearch2("");
                              //dispatch(clearAllDriversByVrn());
                              // setFieldValue("destination", "", true);
                            }}
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            <SvgImage name="CloseButtonLogo" />
                          </div>
                          <div
                            style={{
                              overflowY: "scroll",
                              overflowX: "hidden",
                              height: "240px",
                            }}
                          >
                            {search2 == "" ? (
                              savedAddresses?.map((data, i) => {
                                let city = data?.cityName;
                                let state = data?.stateName;
                                let address = data?.address;
                                return (
                                  <div
                                    key={i}
                                    className="padding8 row"
                                    onClick={() => {
                                      // setcityId2(data?.cityName);
                                      setColor2(i);
                                      setDisplay2(false);
                                      //setUserId(data?.User?.userId);
                                      setFieldValue(
                                        "destination",
                                        `${address}, ${city} - ${state}`,
                                        true
                                      );
                                      setFieldValue(
                                        "destId",
                                        data?.addressId,
                                        true
                                      );
                                    }}
                                  >
                                    {color2 === i ? (
                                      <div className="col-1">
                                        <SvgImage name="SelectedRadio" />
                                      </div>
                                    ) : (
                                      <div className="col-1">
                                        <SvgImage name="UnselectedRadio" />
                                      </div>
                                    )}
                                    {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                    <div
                                      className="col-11"
                                      style={{ textAlign: "left" }}
                                    >
                                      {address}, {city} - {state}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                {savedAddresses?.map((data, i) => {
                                  let city = data?.cityName;
                                  let state = data?.stateName;
                                  let address = data?.address;

                                  console.log("check status", city, state);
                                  // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                                  return (
                                    <div
                                      key={i}
                                      className="padding8 row"
                                      onClick={() => {
                                        // setcityId2(data?.cityName);
                                        setColor2(i);
                                        setDisplay2(false);
                                        //setUserId(data?.User?.userId);
                                        setFieldValue(
                                          "destination",
                                          `${address}, ${city} - ${state}`,
                                          true
                                        );
                                        setFieldValue(
                                          "destId",
                                          data?.addressId,
                                          true
                                        );
                                      }}
                                    >
                                      {color2 === i ? (
                                        <div className="col-1">
                                          <SvgImage name="SelectedRadio" />
                                        </div>
                                      ) : (
                                        <div className="col-1">
                                          <SvgImage name="UnselectedRadio" />
                                        </div>
                                      )}
                                      <div
                                        className="col-11"
                                        style={{ textAlign: "left" }}
                                      >
                                        {address}, {city} - {state}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                            {savedAddresses?.length == 0 ? (
                              <div className="">
                                <div style={{ margin: "24px" }}>
                                  You have no addresses to select please add a
                                  new one
                                </div>
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="greenButton d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "200px",
                                      cursor: "pointer",
                                    }}
                                    onClick={addAddress}
                                  >
                                    <div>Add New Address</div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.destination && touched.destination ? (
                          <div>*{errors.destination}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Start Date
                      </label>
                      <div className="searchBox" style={{ width: "200px" }}>
                        <DatePickerField
                          classname="searchBar-input"
                          name="startDate"
                          header="Select Start date"
                          value={values.startDate}
                          onChange={(v) => {
                            let endDate = v ? new Date(v) : new Date();
                            let newEndDate = routeData?.expectedTimeHR
                              ? new Date(
                                  endDate.setHours(
                                    endDate.getHours() +
                                      routeData?.expectedTimeHR
                                  )
                                )
                              : "";
                            setFieldValue("startDate", v, true);
                            setFieldValue("endDate", newEndDate, true);
                          }}
                          //onChange={(v) => setFieldValue("endDate", v, true)}
                          // minDate={
                          //   values.startDate === "" ? "" : values.startDate
                          // }
                          // maxDate={new Date()}
                          ref1={datepickerRefEnd}
                          style={{ width: "100px" }}
                          showTimeInput={true}
                        />
                        <label
                          className="date-input"
                          for="startDate"
                          style={{
                            position: "relative",
                            top: "-5px",
                            left: "-2rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleClickDatepickerIcon2();
                          }}
                        >
                          <SvgImage
                            name="CalenderLogo"
                            width="32"
                            height="32"
                          />
                        </label>
                      </div>
                      <div
                        className="heading4 Regular"
                        style={{ margin: "7px 0 0px 0", color: "red" }}
                      >
                        {errors.startDate && touched.startDate ? (
                          <div>*{errors.startDate}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="vrn" className="heading3 semiBold">
                        End Date
                      </label>
                      <div className="searchBox" style={{ width: "200px" }}>
                        <DatePickerField
                          classname="searchBar-input"
                          name="endtDate"
                          header="Select End date"
                          value={values.endDate}
                          onChange={(v) => {
                            setFieldValue("endDate", v, true);
                          }}
                          minDate={
                            values.startDate === "" ? "" : values.startDate
                          }
                          ref1={datepickerRefStart}
                          style={{ width: "100px" }}
                          showTimeInput={true}
                        />
                        <label
                          className="date-input"
                          for="endDate"
                          style={{
                            position: "relative",
                            top: "-5px",
                            left: "-2rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleClickDatepickerIcon1();
                          }}
                        >
                          <SvgImage
                            name="CalenderLogo"
                            width="32"
                            height="32"
                          />
                        </label>
                      </div>
                      <div
                        className="heading4 Regular"
                        style={{ margin: "7px 0 0px 0", color: "red" }}
                      >
                        {errors.endDate && touched.endDate ? (
                          <div>*{errors.endDate}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormGroup>
                      <label for="tripNo" className="">
                        Expected Distance(KMs):
                      </label>
                      <input
                        type="text"
                        id="expectedDistanceKM"
                        className={`form-control-1 ${
                          touched.expectedDistanceKM &&
                          errors.expectedDistanceKM
                            ? "is-invalid"
                            : ""
                        }`}
                        name="expectedDistanceKM"
                        onChange={(event) => {
                          // setSearch(event.target.value);
                          setFieldValue(
                            "expectedDistanceKM",
                            event.target.value,
                            true
                          );
                        }}
                        value={values.expectedDistanceKM}
                        touched={touched.expectedDistanceKM}
                        errors={errors.expectedDistanceKM}
                        placeholder="Enter Expected Distance"
                      />
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "25px",
                      marginTop: "30px",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{ width: "25%" }}
                      className="generateOtp text3 Medium btn btn-secondary"
                    >
                      Submit
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <Toast
        success={openToast}
        message={"Trip Has Been created Successfully"}
        isOpen={openToast}
        name="trips"
        handleClose={() => {
          handleClose(false);
          // setTripData({});
          setPaymentDetailsModal(true);
          setSelectedRow([]);
          setOpenToast(false);
          dispatch(
            getLRList({
              offset: 0,
              limit: 10,
            })
          );
        }}
      />
      <AddDriverV2 toggle={addDriver} freight={driver} />
      <AddVehicleV2 toggle={addVehicle} freight={vehicle} />
      <Toast
        success={false}
        message={openToastFailureText}
        isOpen={openToastFailure}
        name="trips"
        handleClose={() => {
          setOpenToast(false);
          handleClose(false);
        }}
      />
      <AddAddress
        toggle={addAddress}
        address={addressModal}
        setAddress={setAddressModal}
      />
    </div>
  );
}

export default CreateTripFromLR;
