import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { storeMedia } from "../../../../services/Store/Actions/appActions";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import SvgImage from "../../../Icons/Icons";
import { propTypes } from "react-bootstrap/esm/Image";
import { ClipLoader } from "react-spinners";

const UploadBack = ({
  values,
  setFieldValue,
  errors,
  touched,
  number,
  title,
}) => {
  const [status, setStatus] = useState(false);
  const [displayName, setDisplayName] = useState([]);
  const [imageLoader, setImageLoader] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className="row">
      <div className="col-10 row">
        <div className="sub-title-1 col-12">
          <span class="circle-logo">{number}</span>
          <label className="sub-head-3 ">{title}</label>
        </div>
        <div className="col-12">
          <p className="sub-head-body mt-0">
            Upload format must be in jpg, png or jpeg
          </p>
        </div>
      </div>

      <div className="col-2">
        {!status ? (
          <Button
            className="add-attach-for-fastag-linked-vehicle"
            type="submit"
            // disabled={Formik.setSubmitting}
          >
            {imageLoader ? (
              <div style={{ zIndex: 10 }}>
                <ClipLoader
                  color={"#485B7C"}
                  loading={true}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div>
                <SvgImage name="AddAttachment" height="36" width="36" />

                <input
                  type="file"
                  accept="image/*"
                  // multiple
                  // accept="image/*"
                  className="add-attachment-btn"
                  onChange={(e) => {
                    const img = e?.target.files[0];
                    console.log("image", img?.name);
                    // e.preventDefault();
                    setStatus(true);

                    // e.preventDefault();
                    setDisplayName(
                      (prev) => [{ name: img?.name }]
                      //  [
                      //   ...prev,
                      //   { name: img?.name },
                      // ]
                    );

                    const tempArray = Object.assign([], values.docs);
                    console.log("sel", tempArray);

                    if (img == null) {
                      //clear image
                      setFieldValue("urlBack", "", true);
                      //for now  only one supported
                    } else {
                      console.log("upload", {
                        img: img,
                        uploadType: "aadharFront",
                        isRes: true,
                      });
                      // e.preventDefault();
                      // e.setSubmitting(false);
                      // e.preventDefault();
                      setImageLoader(true)
                      dispatch(
                        storeMedia(
                          {
                            img: img,
                            uploadType: "aadharFront",
                            isRes: false,
                          },
                          (res) => {
                            setStatus(true);
                            setImageLoader(false)
                            // e.preventDefault();
                            setDisplayName(
                              (prev) => [{ name: img?.name }]
                              //  [
                              //   ...prev,
                              //   { name: img?.name },
                              // ]
                            );
                            if (res?.url) {
                              console.log("opt", res);
                              tempArray.push(res?.data?.Location);
                              console.log("opt1", tempArray);
                              //setFieldValue("urlBack", res?.url, true);
                              setFieldValue(
                                "urlBack",
                                res?.data?.Location,
                                true
                              );
                              // setFieldValue('docs', tempArray, true);
                              //for now  only one supported
                              //setFieldValue("docs", tempArray, true);
                            }
                          }
                        )
                      );
                      console.log(
                        "FORM data",
                        values.amount,
                        values.bookingDate
                      );

                      // setFieldValue("amount", values.amount, true);
                    }
                  }}
                />
              </div>
            )}

            <div
              style={{
                margin: "0px -16px 2px -614px",
                color: "red",
              }}
            >
              {errors.vehicleRcPhoto && touched.vehicleRcPhoto ? (
                <div>*{errors.vehicleRcPhoto}</div>
              ) : null}
            </div>
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="selected-img">
        {status ? (
          displayName?.map((d) => {
            return (
              <div className="row">
                <div className="col-10" style={{ paddingTop: "0.3rem" }}>
                  <p
                    style={{
                      margin: "0 0 0 96px",
                      color: "black",
                    }}
                  >
                    {d.name}
                  </p>
                </div>
                <div
                  className="col-2"
                  onClick={(e) => {
                    setStatus(false);
                    // e.preventDefault();
                  }}
                  style={{ paddingBottom: "0.5rem" }}
                >
                  <SvgImage name="CloseButtonLogo" />
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default UploadBack;
