import React ,{useState}from 'react'
import './index.css'
import SvgImage from '../../../Icons/Icons'
import CardControlsModal from '../../../Modals/CardControlsModal';
import ManageDriverAddBalance from '../../../Modals/ManageDriverAddBalance';
import ManageDriverWithdrawBalance from '../../../Modals/ManageDriverWithdrawBalance';
import OwnerAddCardBalanceModal from '../OwnerAddCardModal';
import OwnerWithdrawCardBalanceModal from "../OwnerWithdrawCardBalanceModal";

export default function ManageLogipeMidCard(props) {


    const name = props.name == null ? "Add Card Balance" : props.name;
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [btn, setBtn] = useState(false);
    const [active, setActive] = useState(0);
    const [comp, setComp] = useState("Logipe Account");
    const [proceed, setProceed] = useState(0);

  console.log("status here", props.activeStatus);
    function toggleModal() {
      setOpen(!open);
      setProceed(0);
    }
  
    function handleClick() {
      setBtn(!btn);
    }
  
    const handleClose = () => {
      setOpen(false);
      setProceed(0);
    };
  
    const handleShow = () => {
      setOpen(true);
    };
 
  //console.log ("props000", props);  
  return (
    <>
    <div className='manageLogipeMidCard padding16'  onClick={handleShow} 
  
    >
        
        <div className='topUpWalletBox'>
            <div className="topUpLogipeCard">
            <SvgImage  name={props.logo} />

            </div>

        </div>
        <div className='manageLogipeCardTitle heading3'>
            {name}
        </div>
        <div className='padding8L' style={{paddingTop:".75rem"}}>
        <SvgImage width ="43" height ="43" name ="vector"/>

        </div>

      
    </div>

    {"Add card Balance" === props.name && (props.activeStatus) ? (
        <OwnerAddCardBalanceModal
        setPOpen={props.setPOpen}
          Popen={props.Popen}
          open={open}
          toggleModal={toggleModal}
          title="Logipe Account"
          handleClick={handleClick}
          handleClose={handleClose}
          btn={btn}
          color={
            "LogipeAccount" === "Card Wallet" ? "title-color1" : "title-color2"
          }
          name="Add Balance"
          userId={props.userId}
          bal={props.bal}
          fullName={props.fullName}
        />
      ) : "Withdraw card Balance" === props.name && (props.activeStatus) ? (
        <OwnerWithdrawCardBalanceModal
        setPOpen={props.setPOpen}
        Popen={props.Popen}
          open={open}
          toggleModal={toggleModal}
          title="Logipe Account"
          handleClick={handleClick}
          handleClose={handleClose}
          btn={btn}
          color={
            "LogipeAccount" === "Card Wallet" ? "title-color1" : "title-color2"
          }
          name="Withdraw Balance"
          userId={props.userId}
          bal={props.bal}
          fullName={props.fullName}
        />
      ) :  null}{" "}
    </>
  )
}
