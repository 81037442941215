import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import { useNavigate, NavLink } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import EditVehicleDetailModal from "../../../Modals/editVehicleDetails";
import { getVehicleDetailsByFleetId } from "../../../../services/Store/Actions/fastagAction";
import "./index.css";
import { useSelect } from "@mui/base";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import OMCChangeOTPNum from "../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCChangeOTPNum";
import OMCLoadModal from "../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCLoadModal";

function OmcOthersDetails(props) {
  const {
    vehicleNumber,
    linkedFastag,
    assignedDrive,
    category,
    registrationDate,
    rcPhoto,
    fleetId,
  } = props;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  function handleClose() {
    setOpen(false);
  }
  function toggleModal() {
    setOpen(!open);
  }
  function handleShow() {
    setOpen(true);
  }
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const handleClose2 = () => {
    setOpen2(false);
  };

  const toggleModal2 = () => {
    setOpen2(!open2);
  };

  const showModal2 = () => {
    setOpen2(true);
  };

  const { vehicleDetailsByVehicleID, loader } = useSelector(
    (state) => state?.fastag
  );

  const omc_balace = useSelector((state) => state?.omc?.omc_balace);
  console.log("ooooooiiiii", omc_balace);
  const regDate = omc_balace?.vehicle?.regDate;
  const vehCategory =
    omc_balace?.vehicle?.vehicleCategory === undefined
      ? "N/A"
      : omc_balace?.vehicle?.vehicleCategory;
  //console.log("vehicle details", vehicleDetails?.vehicle?.regDate.split("T")?.[0]);
  // const regDateActive = vehicleDetailsByVehicleID?.regDate ? true : false

  //console.log("jkjjjkj", vehicleDetailsByVehicleID);

  //console.log("initially", vehicleNumber);
  const LinkFastag =
    linkedFastag == "" || linkedFastag == null ? "N/A" : linkedFastag;
  const VehicleNumber =
    vehicleNumber == "" || vehicleNumber == null ? "N/A" : props.vehicleNumber;
  //console.log("AssignedDriver",typeof assignedDrive)
  const mappedPhone =
    omc_balace?.mappedPhone == "" || omc_balace?.mappedPhone == null
      ? true
      : false;

  const handleViewImage = (val) => {
    console.log("vallllllll", val);
    window.open(val);
  };

  return (
    <div>
      <Card className="main-card-vehicles padding16L padding16R">
        <div className="row">
          <div className="col-8">
            <div
              className="row padding16 padding32T"
              style={{ paddingLeft: "12px" }}
            >
              <div className="col-2">
                <SvgImage name="vehicleDivLogo" />
              </div>

              <div className="col-10">
                <CardTitle className="Bold heading2">OMC Details</CardTitle>
              </div>
            </div>
          </div>
          {/* <div className="col-4 padding16 padding32T padding8R" style={{textAlign:"right"}}>
          <SvgImage name={props.fastagType==="idfc"?"idfcBankTag":props.fastagType==="YesBank"?"yesBankTag":""} />
          </div> */}
          {/* <div className="col-5 main-edit-detail-button">
            <NavLink to="" className="edit-detail-button" onClick={handleShow}>
              Edit Details
            </NavLink>
            <EditVehicleDetailModal open={open} toggleModal={toggleModal} handleClose={handleClose}/>
          </div> */}
        </div>

        {/* <div className="row FontNB paddingBtm">
          <div className="col-6">Vehicle Number</div>
          <div className="col-6" style={{ textAlignLast: "end" }}>
            {VehicleNumber}
          </div>
        </div> */}

        {/* <div className="row FontNB paddingBtm">
          <div className="col-7 ">Linked FASTag ID</div>
          <div className="col-5" style={{ textAlignLast: "end" }}>
            {LinkFastag}
          </div>
        </div> */}

        <div className="row FontNB paddingBtm">
          <div className="col-7 ">OTP sent to</div>
          {mappedPhone ? (
            <div className="col-5" style={{ textAlignLast: "end" }}>
              N/A
            </div>
          ) : (
            <div className="col-5" style={{ textAlignLast: "end" }}>
              {omc_balace?.mappedPhone}
            </div>
          )}
          {/* <div className="col-5" style={{ textAlignLast: "end" }}>
            {AssignedDriver ? (
              <p className="all-value">N/A</p>
            ) : (
              <p
                className="all-value"
                style={{
                  // textDecorationLine: "underline",
                  // color: "#2E7C7B",
                }}
              >
                {assignedDrive}
              </p>
            )}
            
          </div> */}
        </div>

        <div className="row FontNB paddingBtm">
          <div className="col-7 ">Change Mapped Phone</div>
          <div
            className="col-5"
            style={{
              textAlignLast: "end",
              textDecorationLine: "underline",
              color: "#485B7C",
              cursor: "pointer",
            }}
            onClick={showModal2}
          >
            Click Here
          </div>
        </div>

        <div className="row FontNB paddingBtm">
          <div className="col-7 ">Load Money</div>
          <div
            className="col-5"
            style={{
              textAlignLast: "end",
              textDecorationLine: "underline",
              color: "#485B7C",
              cursor: "pointer",
            }}
            onClick={handleShow}
          >
            Click Here
          </div>
        </div>
{/* 
        <div className="row FontNB paddingBtm">
          <div className="col-7 ">
            <p>RC Photo</p>
          </div>
          <div className="col-5" style={{ textAlignLast: "end" }}>
            {linkedFastag != "" && linkedFastag != null ? (
              loader ? (
                <div style={{ margin: "0 0 0 95px" }}></div>
              ) : (
                <div>
                  {LinkFastag ? (
                    <div
                      style={{
                        textDecorationLine: "underline",
                        color: "#2E7C7B",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleViewImage(vehicleDetailsByVehicleID?.rcImage)
                      }
                    >
                      view
                    </div>
                  ) : (
                    "Not added"
                  )}
                </div>
              )
            ) : (
              "Not added"
            )}
          </div>
        </div> */}
      </Card>
      <OMCChangeOTPNum
        open={open2}
        toggleModal={toggleModal2}
        handleClose={handleClose2}
      />
      <OMCLoadModal
        open={open}
        toggleModal={toggleModal}
        handleClose={handleClose}
      />
    </div>
  );
}

export default OmcOthersDetails;
