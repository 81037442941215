import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import AddFreight from "../../../TripsMainV2/Components/AddFreight";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import {
  createTrip,
  getCityAndState,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import InvoiceToast from "../../../subComponents/InvoiceToast";
import {
  storeMedia,
  toastStatus,
} from "../../../../services/Store/Actions/appActions";
import ManuallyInvoice from "../ManualInvoice";
import TripInvoiceDetail from "../tripInvoiceDetail";
import { useNavigate } from "react-router-dom";
import {
  createExternalInvoiceAction,
  getLedgerListAction,
} from "../../../../services/Store/Actions/customerAction";
import { ClipLoader } from "react-spinners";
function AddInvoice(props) {
  const { toggle, modal, setModal } = props;
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [radioBtn, setRadioBtn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customer } = useSelector((state) => state?.customer);
  const validationSchema = Yup.object().shape({
    tripNo: Yup.string().required("TripNo is required"),
    source: Yup.string().required("Source is required"),

    destination: Yup.string().required("Destination is required"),

    fleetId: Yup.string().required("Vehicle is required"),

    userId: Yup.string().required("Driver is required"),

    startDate: Yup.string().required("StartDate is required"),
  });

  const handleClose = () => {
    setModal(!modal);
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          // style={{ height: "80vh" }}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            style={{ marginTop: " 30px" }}
            //   className="PGCollectionBox"
          >
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Add Invoice{" "}
            </div>

            <Formik
              // isValid='true'
              enableReinitialize
              //   validationSchema={validationSchema}
              initialValues={{
                amount: "",
                invoiceNumber: "",
                invoiceDate: "",
                invoiceCopy: "",
                cgst: "",
                sgst: "",
                igst: "",
                discount: "",
                extraCharges: "",
              }}
              onSubmit={(values, action) => {
                console.log("===>>>>init");
                setLoader(true);
                const payload = {
                  ...values,
                  cgst:
                    (((parseFloat(values.amount || 0) -
                      parseFloat(values.discount || 0) +
                      parseFloat(values.extraCharges || 0)) *
                      values.cgst) /
                    100).toString(),
                  igst:
                    (((parseFloat(values.amount || 0) -
                      parseFloat(values.discount || 0) +
                      parseFloat(values.extraCharges || 0)) *
                      values.igst) /
                    100).toString(),
                  sgst:
                    (((parseFloat(values.amount || 0) -
                      parseFloat(values.discount || 0) +
                      parseFloat(values.extraCharges || 0)) *
                      values.sgst) /
                    100).toString(),
                };
                dispatch(
                  createExternalInvoiceAction(
                    { ...payload, customerId: customer.customerId },
                    (res) => {
                      setLoader(false);
                      if (res.status == 200) {
                        setDisplayName("");
                        setStatus(false);
                        dispatch(
                          toastStatus({
                            toastStatus: true,
                            message:
                              "Your Invoice has been created successfully.",
                            status: true,
                            invoice: true,
                            link: res?.data?.data.link,
                            emailId: customer.email
                          })
                        );
                        toggle();
                        dispatch(
                          getLedgerListAction({
                            customerId: customer.customerId,
                            offset: 0,
                            search: "",
                            transferType: "",
                            sortAs: "",
                            sortBy: "",
                            from: "",
                            to: "",
                          })
                        );
                      } else {
                      }
                    }
                  )
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "60px", padding: "0 0px 0 55px" }}
                >
                  <FormGroup>
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Customer Name
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <div className="col-12" style={{ padding: "5px" }}>
                            {customer?.companyName}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            GSTIN
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <div className="col-12" style={{ padding: "5px" }}>
                            {customer?.gstin || "NA"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "20px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Invoice Number
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="invoiceNumber"
                            className={`inputBoxAddCust ${
                              touched.invoiceNumber && errors.invoiceNumber
                                ? "is-invalid"
                                : ""
                            }`}
                            name="invoiceNumber"
                            onChange={(event) => {
                              // setSearch(event.target.value);
                              setFieldValue(
                                "invoiceNumber",
                                event.target.value,
                                true
                              );
                            }}
                            value={values.invoiceNumber}
                            touched={touched.invoiceNumber}
                            errors={errors.invoiceNumber}
                            placeholder="Invoice Number"
                          />
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Invoice Date
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="date"
                            id="invoiceDate"
                            className={`inputBoxAddCust ${
                              touched.amount && errors.amount
                                ? "is-invalid"
                                : ""
                            }`}
                            name="invoiceDate"
                            onChange={(event) => {
                              // setSearch(event.target.value);
                              setFieldValue(
                                "invoiceDate",
                                event.target.value,
                                true
                              );
                            }}
                            value={values.invoiceDate}
                            touched={touched.invoiceDate}
                            errors={errors.invoiceDate}
                            placeholder="Invoice Date"
                          />
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Invoice Copy
                          </label>
                        </div>
                        <div className="d-flex">
                          {status ? (
                            <div className="row">
                              <div
                                className="col-9"
                                style={{ paddingTop: "0.3rem" }}
                              >
                                <p
                                  style={{
                                    margin: "0 0 0 10px",
                                    color: "black",
                                  }}
                                >
                                  {displayName}
                                </p>
                              </div>
                              <div
                                className="col-2"
                                onClick={(e) => {
                                  setStatus(false);
                                  // e.preventDefault();
                                  console.log("dsadsadsadsadsad", values);
                                  setFieldValue("invoiceCopy", "", true);
                                }}
                                style={{ paddingBottom: "0.5rem" }}
                              >
                                <SvgImage name="CloseButtonLogo" />
                              </div>
                            </div>
                          ) : (
                            <div className="add-attach-for-fastag-linked-vehicle-new me-3">
                              {imageLoader ? (
                                <div style={{}}>
                                  <ClipLoader
                                    color={"#485B7C"}
                                    loading={true}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </div>
                              ) : (
                                <div style={{}}>
                                  <SvgImage
                                    name="AddAttachment"
                                    height="25"
                                    width="25"
                                  />
                                  <input
                                    type="file"
                                    // multiple
                                    // accept="image/*"
                                    // value={values.invoiceCopy}
                                    accept="image/*"
                                    className="add-lr-attachment "
                                    style={{
                                      width: "40px",
                                    }}
                                    onChange={(e) => {
                                      const img = e?.target.files[0];
                                      console.log("image", img?.name);
                                      // e.preventDefault();

                                      if (img == null) {
                                        //clear image
                                        setFieldValue("invoiceCopy", "", true);
                                        //for now  only one supported
                                      } else {
                                        console.log("upload", {
                                          img: img,
                                          uploadType: "invoiceCopy",
                                          isRes: true,
                                        });
                                        setImageLoader(true);
                                        dispatch(
                                          storeMedia(
                                            {
                                              img: img,
                                              uploadType: "invoiceCopy",
                                              isRes: false,
                                            },
                                            (res) => {
                                              setImageLoader(false);
                                              setStatus(true);

                                              // e.preventDefault();
                                              setDisplayName(img?.name);
                                              console.log("dsadasdasdsad", res);
                                              if (res?.url) {
                                                console.log("opt", res);
                                                setFieldValue(
                                                  "invoiceCopy",
                                                  res?.url,
                                                  true
                                                );
                                              }
                                            }
                                          )
                                        );

                                        // setFieldValue("amount", values.amount, true);
                                      }
                                    }}
                                  />
                                </div>
                              )}

                              <div
                                style={{
                                  margin: "0px -16px 2px -614px",
                                  color: "red",
                                }}
                              >
                                {errors.invoiceCopy && touched.invoiceCopy ? (
                                  <div>*{errors.invoiceCopy}</div>
                                ) : null}
                              </div>
                            </div>
                          )}
                          {!status ? (
                            <p
                              className="heading3 Regular"
                              style={{ margin: "12px 0px 5px" }}
                            >
                              Upload
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "20px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Amount
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="amount"
                            className={`inputBoxAddCust ${
                              touched.amount && errors.amount
                                ? "is-invalid"
                                : ""
                            }`}
                            name="amount"
                            onChange={(event) => {
                              // setSearch(event.target.value);
                              setFieldValue("amount", event.target.value, true);
                            }}
                            value={values.amount}
                            touched={touched.amount}
                            errors={errors.amount}
                            placeholder="Amount"
                          />
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Discount
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="discount"
                            className={`inputBoxAddCust ${
                              touched.discount && errors.discount
                                ? "is-invalid"
                                : ""
                            }`}
                            name="discount"
                            onChange={(event) => {
                              // setSearch(event.target.value);
                              setFieldValue(
                                "discount",
                                event.target.value,
                                true
                              );
                            }}
                            value={values.discount}
                            touched={touched.discount}
                            errors={errors.discount}
                            placeholder="Discount"
                          />
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Extra charges
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="extraCharges"
                            className={`inputBoxAddCust ${
                              touched.extraCharges && errors.extraCharges
                                ? "is-invalid"
                                : ""
                            }`}
                            name="extraCharges"
                            onChange={(event) => {
                              // setSearch(event.target.value);
                              setFieldValue(
                                "extraCharges",
                                event.target.value,
                                true
                              );
                            }}
                            value={values.extraCharges}
                            touched={touched.extraCharges}
                            errors={errors.extraCharges}
                            placeholder="Extra charges"
                          />
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "20px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-8 row align-items-center">
                        <div className="col-1">
                          <input
                            type="radio"
                            checked={radioBtn}
                            onChange={(e) => {
                              console.log("===<<<<<nmtknikn1");
                              setRadioBtn(true);
                            }}
                          />
                        </div>
                        <div className="col-5 row">
                          <div className="col-12">
                            <label for="vrn" className="heading3 semiBold">
                              CGST (%)
                            </label>
                          </div>
                          <div className="col-12" style={{ padding: "8px" }}>
                            <input
                              type="number"
                              disabled={!radioBtn}
                              id="cgst"
                              className={`inputBoxAddCust ${
                                touched.amount && errors.amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="cgst"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue("cgst", event.target.value, true);
                              }}
                              value={values.cgst}
                              touched={touched.cgst}
                              errors={errors.cgst}
                              placeholder="CGST (%)"
                            />
                          </div>
                        </div>
                        <div className="col-5 row">
                          <div className="col-12">
                            <label for="vrn" className="heading3 semiBold">
                              SGST (%)
                            </label>
                          </div>
                          <div className="col-12" style={{ padding: "8px" }}>
                            <input
                              type="number"
                              id="sgst"
                              disabled={!radioBtn}
                              className={`inputBoxAddCust ${
                                touched.amount && errors.amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="sgst"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue("sgst", event.target.value, true);
                              }}
                              value={values.sgst}
                              touched={touched.sgst}
                              errors={errors.sgst}
                              placeholder="SGST (%)"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-4 row align-items-center">
                        <div className="col-2">
                          <input
                            type="radio"
                            checked={!radioBtn}
                            onChange={(e) => {
                              console.log("===<<<<<nmtknikn1");
                              setRadioBtn(false);
                            }}
                          />
                        </div>
                        <div className="col-10 row">
                          <div className="col-12">
                            <label for="vrn" className="heading3 semiBold">
                              IGST (%)
                            </label>
                          </div>
                          <div className="col-12" style={{ padding: "8px" }}>
                            <input
                              type="number"
                              disabled={radioBtn}
                              id="igst"
                              className={`inputBoxAddCust ${
                                touched.amount && errors.amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="igst"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue("igst", event.target.value, true);
                              }}
                              value={values.igst}
                              touched={touched.igst}
                              errors={errors.igst}
                              placeholder="IGST (%)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup
                    style={{
                      padding: "10% 0 0 0",
                      textAlign: "center",
                    }}
                  >
                    {loader ? (
                      <div
                        className=" generateOtp textAddCust Medium btn btn-secondary"
                        style={{
                          width: "160px",
                          borderRadius: "5px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ClipLoader
                          color={"#FFF"}
                          loading={true}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        style={{ width: "160px", borderRadius: "5px" }}
                        className="generateOtp textAddCust Medium btn btn-secondary"
                        // onClick={addFreight}
                      >
                        Next
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>

      {/* <InvoiceToast
        success={true}
        message={"Customer Created Successfully "}
        toggleModal={() => {
          setOpenToast(false);
        }}
        isOpen={openToast}
        name="driver"
        handleClose={() => {
          setOpenToast(false);
          //   window.location = "/Customers";
          navigate("/Customers/" + customer.customerId);
          dispatch(
            getLedgerListAction({
              customerId: customer.customerId,
              offset: offset,
              search: "",
              transferType: "",
              sortAs: "",
              sortBy: "",
            })
          );
        }}
      /> */}
    </div>
  );
}

export default AddInvoice;
