import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import { Button } from "reactstrap";
import SvgImage from "../../../../../../Icons/Icons";



const KycNotComplete=(props)=>{

    return(<div>
        <div className="centralEle " style={{padding:"4.5rem 0 4.5rem"}}>
            <div className="padding32T">
                <SvgImage name="kycLockLogo" />
            </div>
            <div className="Medium heading3 padding16T greyFont">Purchase a card for your driver using the</div>
            <div className="Medium heading3 greyFont padding32B">LogiPe app for benefits on every transaction!</div>
        </div>
    </div>)
}

export default KycNotComplete;