import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import SvgImage from "../Icons/Icons";
import Toast from "../subComponents/toast";
import CardOptions from "./Components/CardOptions";
import DriverOptions from "./Components/DriverOptions";
import MAOptions from "./Components/MAOptions";
import ReportBtnRows from "./Components/ReportBtnRows";
import TripOptions from "./Components/TripOptions";
import VehicleOptions from "./Components/VehicleOptions";
import './index.css'
import ReportFastagWallet from "./Components/ReportFastagWallet";
import ManagerReport from "./Components/ManagerReport";

const Report=(props)=>{
    const {sideBar} = props;
    const [generateBtn, setGenerateBtn]= useState(0);
    const [optBtn, setOptBtn]= useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth );

    const selectGenerateBtn=(i)=>{
        if(generateBtn!==i){
            setGenerateBtn(i);
        }
    }
    useEffect(() => {
        function handleResize() {
          setWindowWidth(window.innerWidth)
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    return(
    <div className="container topMargin sideBarMargin" style={{height:"100%",marginLeft: sideBar? '210px' : '80px',maxWidth: sideBar ?windowWidth - 240 :windowWidth- 130,paddingTop:"0px"}}>
        <div className="row padding16">
            {/* <div className="col-2 padding8R">
                <Button onClick={()=>{
                    selectGenerateBtn(0);
                }}
                className={generateBtn==0?"generateOtp semiBold heading2":"notGenerateOpt semiBold heading2"}
                >Generate Report</Button>
            </div> */}
            {/* <div className="col-2 padding8L">
                <Button
                onClick={()=>{
                    selectGenerateBtn(1);
                }}
                className={generateBtn==1?"generateOtp semiBold heading2":"notGenerateOpt semiBold heading2"}
                >Generate Invoice</Button>
            </div> */}
        </div>
        <div className="row padding16L" style={{height:"1050px"}}>
            <ReportBtnRows 
                optBtn={optBtn}
                setOptBtn={setOptBtn}
            />
            <div className="col-7 padding8L padding0T" style={{height:"1050px"}}>
                {
                    optBtn==null?
                    <div className="containerShadow" style={{position:"relative"}}>
                        <SvgImage name="noSelectBack" />
                        <div className="absPos" style={{top:"25%", left:"40%"}}>
                            <SvgImage name="analyticIcon" />
                        </div>
                        <div className="Bold heading2 absPos centrPos" style={{top:"53%", left:"35%"}}>
                        Please select one of the following
                        </div>
                    </div>
                    : (optBtn==1)?
                    <VehicleOptions
                    />
                    :(optBtn==0)?
                    <MAOptions 
                    />
                    :(optBtn==2)?
                    <DriverOptions
                    />
                    :(optBtn==3)?
                    <ReportFastagWallet
                    />
                    :(optBtn==4)?
                    <ManagerReport
                    />
                    // :(optBtn==4)?
                    // <TripOptions
                    // />
                    :
                    // <CardOptions 
                    // />
                    <></>
                }
                
            </div>
        </div>
    </div>)
}


export default Report;