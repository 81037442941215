import React, { useState } from "react";
import { Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import PinInput from "react-pin-input";
import { Formik } from "formik";
import SvgImage from "../../Icons/Icons";
import { transferWalletTransferAmount } from "../../../services/Store/Actions/cardWalletAction";
import { transferFastagTransferAmount } from "../../../services/Store/Actions/fastagAction";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import * as Yup from "yup";
import { redeemCashbackAction } from "../../../services/Store/Actions/cashbackAction";
import {
  cleanCashbackList,
  getCashbackList,
} from "../../../services/Store/Actions/manageCardActions";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";

const validationSchema = Yup.object().shape({
  amount: Yup.number().typeError("Only Numbers are allowed")
    .required("Required")
    .min(1, "Amount should be greater than 1"),
  //   pin: Yup.string()
  //     .required("Required")
  //     .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
});

function RedeemCashback(props) {
  const {
    open,
    toggleModal,
    handleClose,
    color,
    cashback,
    refreshCashback,
    remainingBal,
  } = props;
  const [ploader, setPLoader] = useState(false);
  const { loader } = useSelector((state) => state?.cashback);
  console.log("LOADER HEREE", loader);
  const dispatch = useDispatch();
  // const ownerBalance = ownerLogipeBalance === "" ? 0 : ownerLogipeBalance;
  //console.log("blanace", ownerBalance);
  const { ownerBalance } = useSelector((state) => state?.manageCard);
  const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == null
      ? 0
      : ownerBalance?.instrument_balance?.balance;

  const { cashBack } = useSelector((state) => state?.manageCard);
  const cashbackRedeem = cashBack?.data?.redeem;
  const availableBal = cashback - cashbackRedeem;
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon sideModal settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{
            width: "500px",
            backgroundColor: "white",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Cashback</p>
            <Button
              type="button"
              className="close-btn"
              onClick={handleClose}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="card-wallet-main-body">
              <div
                className="card-wallet-body1 row"
                style={{ paddingLeft: "10px", paddingTop: "20px" }}
              >
                <div classname=" col-6" style={{ width: "30%" }}>
                  <SvgImage name="redeemWallet" />
                </div>
                <div
                  className="col-5"
                  style={{
                    position: "relative",
                    left: "-15px",
                    width: "160px",
                  }}
                >
                  <p className="card-wallet-sub-body-new">Available Cashback</p>
                  <p className="card-wallet-sub-body2">{"₹" + remainingBal}</p>
                </div>
              </div>

              <div
                className={"card-wallet-body2 row " + color}
                style={{ paddingLeft: "15px", paddingTop: "20px" }}
              >
                <div classname=" col-4" style={{ width: "30%" }}>
                  <SvgImage name="redeemBank" classname="bank-card-wallet" />
                </div>
                <div
                  className="col-5"
                  style={{
                    position: "relative",
                    left: "-15px",
                    width: "9.4rem",
                  }}
                >
                  <p className="card-wallet-sub-body-new">LogiPe Account</p>
                  <p className="card-wallet-sub-body2">
                    {"₹" + LogipeAccounBal}
                  </p>
                </div>
              </div>

              <span class="circle-logo-forward-arrow">
                <SvgImage name="ForwardArrow" height="36" width="36" />
              </span>
            </div>

            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                amount: "",
                pin: "",
              }}
              onSubmit={(values, actions) => {
                console.log("values onSubmit", values);
                setPLoader(true);
                dispatch(
                  redeemCashbackAction(
                    {
                      amount: values.amount,
                      pin: values.pin
                    },
                    (res) => {
                      console.log("inside resp1", res);
                      actions.setSubmitting(false);
                      setPLoader(false);
                      if (res?.data?.type == "success") {
                        console.log("inside resp2", res);
                        dispatch(sendAnalitics("Success_close_CB_Web"));
                        dispatch(cleanCashbackList());
                        dispatch(
                          getCashbackList({
                            offset: 0,
                            search: "",
                            minKyc: "",
                            sortAs: "",
                            sortBy: "",
                          })
                        );
                        toggleModal();
                      } else {
                        // alert("not success");
                      }
                    }
                  )
                );

                //   if (title === "Card Wallet") {
                //     dispatch(
                //       transferWalletTransferAmount(values, (res) => {
                //         console.log("transfer wallet call back", res.data);
                //         actions.setSubmitting(false);
                //         if (res?.data?.type === "success") {

                //           handleClose();
                //           //alert("Money added succesfully")

                //         }
                //       })
                //     );
                //   }
                //   if (title === "Fastag Wallet") {
                //     dispatch(
                //       transferFastagTransferAmount(values, (res) => {
                //         console.log("transfer fastag call back", res.data);
                //         actions.setSubmitting(false);
                //         if (res?.data?.type === "success") {
                //           handleClose();
                //           //alert("Money added succesfully")

                //         }
                //       })
                //     );
                //   }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <p className="sub-head-3">Enter amount</p>
                    </div>
                    <p className="sub-head-body">
                      Amount will be added to owner’s LogiPe account
                    </p>

                    <label for="html" className="label-body">
                      ₹
                    </label>
                    <input
                      type="text"
                      id="amount"
                      className={`input-body `}
                      name="amount"
                      onChange={handleChange("amount")}
                      value={values.amount}
                      onBlur={() => {
                        dispatch(sendAnalitics("Amt_Redeem_CB_Web ", {
                          amountToRedeem: values.amount
                        }));
                        handleBlur("amount")
                      }
                      }
                      touched={touched.amount}
                      errors={errors.amount}
                      placeholder="Enter amount"
                    />
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>

                    {/* </FormGroup> */}
                    {/* <FormGroup> */}
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-head-4">Enter 4-digit Security Pin</p>
                    </div>
                    <p className="sub-head-body">
                      Security pin is needed to confirm payment
                    </p>
                    <PinInput
                      length={4}
                      initialValue=""
                      secret
                      onChange={handleChange("pin")}
                      values={values.pin}
                      //handleChange={handleChange("code")}
                      type="numeric"
                      inputMode="number"
                      style={{
                        padding: "10px",
                        marginLeft: "96px",
                        marginTop: "28px",
                      }}
                      inputStyle={{
                        border: "1px solid #A7A7A7",
                        borderRadius: "8px",
                        marginRight: "20px",
                      }}
                      //onComplete={(value, index) => {}}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.pin && touched.pin ? (
                        <div>*{errors.pin}</div>
                      ) : null}
                    </div>
                    {/* <button type="submit" className="butt4">
                      Add Balance to {title}
                    </button> */}
                    <div>
                      <button type="submit" className="butt4" disabled={loader ? true : false}
                        onClick={() => {
                          dispatch(sendAnalitics("Redeem_Redeem_CB_Web", {
                            amountToRedeem: values.amount
                          }));
                        }} >
                        {loader ? "Please Wait" : `Redeem Cashback`}
                      </button>
                    </div>
                  </FormGroup>
                  <div className="space-div"></div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default RedeemCashback;
