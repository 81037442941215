import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { cleanFleet, createSalaryWalletSuccesss, getFleet, getFleetTracker } from '../../services/Store/Actions/driverslistActions';
import { IoIosAddCircle } from "react-icons/io";
import ManageDriverAddBalance from '../Modals/ManageDriverAddBalance';
import ManageDriverAddNewExpense from '../Modals/ManageDriverAddNewExpense';
import RechargeFastagButttonModal from '../Modals/rechargeFastagButton';
import GoogleMapReact from 'google-map-react';
import { Button, Input } from 'reactstrap';
import AddNewTrip from '../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/RightBoxComponents/TripSubComponents/AddNewTrip';
import { getUpdateFleet } from '../../services/Store/Actions/fleetActions';
import SvgImage from '../Icons/Icons';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import DriverList from './subComponents';
import { toastStatus } from '../../services/Store/Actions/appActions';
import Toast from "../subComponents/toast";
import OMCLoadModal from '../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCLoadModal';


function Tracker(props) {

    const {sideBar} = props;
    const toastObject = useSelector((state) => state?.app?.toastObject);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth );
    const [vehicleData, setVehicleData] = useState([]);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open, setOpen] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);



    const [offset, setOffset] = useState(0);
    const [open2, setOpen2] = useState(false);
    const [mapView, setMapView] = useState("-1");
    const [userId, setUserId] = useState();
    const [cardType, setCardType] = useState();
    const [fleetId, setFleetId] = useState();
    const [item, setItem] = useState();
    const [item2, setItem2] = useState({});
    const [vrn, setVrn] = useState();
    const [fastagBal,setFastagBal] = useState('')
    const [cardBal, setCardBal] = useState('')
    const {allFleetTrackerList,loader,allFleetTracker} = useSelector((state) => state?.driverlist);
    var y = {}
    const navigate = useNavigate();
    const { fastagWallet } = useSelector((state) => state?.fastag);

    const fastagWalletAmount =
      fastagWallet.balance == null ? 0 : fastagWallet.balance;
    const dispatch = useDispatch();
    var array = Object.values(y)
    function handleClose6() {
      setOpen6(false);
    }
    function handleShow6() {
      setOpen6(true);
    }
    function toggleModal6() {
      setOpen6(!open6);
    }

    function handleClose7() {
      setOpen7(false);
    }
    function handleShow7() {
      setOpen7(true);
    }
    function toggleModal7() {
      setOpen7(!open7);
    }

    function handleClose8() {
      setOpen8(false);
    }
    function handleShow8() {
      setOpen8(true);
    }
    function toggleModal8() {
      setOpen8(!open8);
    }

    const toggleModal3 = () => {
        setOpen3(!open3);
      };
      const handleClose3 = () => {
        setOpen3(false);
      };
    const toggleModal4 = () => {
        setOpen4(!open4);
      };
      const handleClose4 = () => {
        setOpen4(false);
      };
    const toggleModal2 = () => {
        setOpen(!open);
      };
      const handleClose2 = () => {
        setOpen(false);
      };
      const toggleModal9 = () => {
        setOpen9(!open9);
      };
      const handleClose9 = () => {
        setOpen9(false);
      };
    const toggleModal = () => {
        setOpen2(!open2);
        setItem()
        if(!open2){
          dispatch(cleanFleet())
          dispatch(
            getFleetTracker({
              offset: offset,
              limit: 7,
              search: '',
              minKyc: "",
              sortAs: "",
              sortBy: "",
            })
          );
        }
      };
      const handleClose = () => {
        setOpen2(false);
        setItem()
        dispatch(cleanFleet())
      dispatch(
        getFleetTracker({
          offset: offset,
          limit: 7,
          search: '',
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
      };
      const toggleModal5 = () => {
        setOpen5(!open5);
        setItem2()
      };
      const handleClose5 = () => {
        setOpen5(false);
        setItem2()
      };
      const handlePrev = () => {
        if (offset > 0) {
          setOffset(offset - 7);
          dispatch(cleanFleet())
          dispatch(
            getFleetTracker({
              offset: offset - 7,
              limit: 7,
              search: '',
              minKyc: "",
              sortAs: "",
              sortBy: "",
            })
          );
        }
      };
      const handleNext = () => {
        console.log("offset,allFleetTracker.count", offset, allFleetTracker.count);
        if (offset + 7 < allFleetTracker.count) {
          setOffset(offset + 7);
          dispatch(cleanFleet())
          dispatch(
            getFleetTracker({
              offset: offset + 7,
              limit: 7,
              search: '',
              minKyc: "",
              sortAs: "",
              sortBy: "",
            })
          );
        }
      };
      // let socket 
 
    useEffect(()=>{
      dispatch(cleanFleet())
      dispatch(
        getFleetTracker({
          offset: offset,
          limit: 7,
          search: '',
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    },[open2])
    let socket;

    useEffect(()=>{


      
   
    console.log("lllllllkkkkkk")
    
        let token2 = sessionStorage.getItem("_token");
          token2 = JSON.parse(token2);
          socket = io("https://gke-socket.logipe.com/", {
            query: {token:token2},
            transports : ["websocket"]
          });
          console.log("lllllllkkkkkk",socket,token2)

          socket.on('ping',(data)=>{ 
            console.log("pinnnng"); 
     
          })
          socket.on('vehicleTrackingResponse',(data1)=>{
            console.log("vehicleTrackingResponseqkkkkk",data1)
          })
          socket.on('connect',(data)=>{ 
            console.log("connect"); 
            global.socket = socket
       
          })
    
      

      console.log("34444443333",allFleetTrackerList?.length);
      const payload=[]
      const fleetToUser = {}
      if(allFleetTrackerList?.length>0){
        for(let i = 0 ;i<allFleetTrackerList.length;i++ ){
            console.log("fleetIds",allFleetTrackerList[i]);
            if(allFleetTrackerList[i]?.fleetId){
              payload.push({fleetId:allFleetTrackerList[i]?.fleetId,vrn:allFleetTrackerList[i]?.regNumber})
              fleetToUser[allFleetTrackerList[i]?.userId] = allFleetTrackerList[i]?.fleetId
              y[allFleetTrackerList[i]?.fleetId]={
                fleetId:allFleetTrackerList[i]?.fleetId,
                vrn:allFleetTrackerList[i]?.regNumber,
                fastagBalance:allFleetTrackerList[i]?.fastagBalance,
                driverPhone:allFleetTrackerList[i]?.driverPhone,
                userId:allFleetTrackerList[i]?.userId,
                balance:allFleetTrackerList[i]?.walletBalance,
                cashBalance:allFleetTrackerList[i]?.cashBalance,
                driverName:allFleetTrackerList[i]?.driverName,
                plazaName:allFleetTrackerList[i]?.plazaName,
                lat:allFleetTrackerList[i]?.lat,
                long:allFleetTrackerList[i]?.long,
                remark:allFleetTrackerList[i]?.remark,
                fastagStatus:allFleetTrackerList[i]?.fastagStatus,
                status:allFleetTrackerList[i]?.status,
                source:allFleetTrackerList[i]?.source,
                destination:allFleetTrackerList[i]?.destination,
                tripId:allFleetTrackerList[i]?.tripId,
                customerName:allFleetTrackerList[i]?.company,
                omcBalance:allFleetTrackerList[i]?.omcBalance,
                cashExpanse:allFleetTrackerList[i]?.cashExpanse,
                cardExpanse:allFleetTrackerList[i]?.cardExpanse,
                fastagExpanse:allFleetTrackerList[i]?.fastagExpanse,
              }
            }
        }
        console.log("qweqwe111111",Object.values(y),payload);
        // socket.emit("vehicleTracking", payload);

        setVehicleData(Object.values(y))
        let interval
        // useEffect(()=>{
        
          // setTimeout(()=>{
          //   global.socket = socket
          // },1000)
        // },[vehicleData])
        setTimeout(()=>{
        






          socket.on('vehicleTrackingResponse',(data1)=>{
            console.log("vehicleTrackingResponse7777777",data1,y );
            if(data1?.fleetId){
              y[data1?.fleetId] = {
                ...y[data1?.fleetId],
                ...data1
              }
            }
            console.log("qwewqqeqweqwe",y,Object.values(y));
            setVehicleData(Object.values(y))
          })
          socket.on('FastagBalanceTracker',(data1)=>{
            console.log("FastagBalanceTracker",data1);
            if(data1?.fleetId){
              y[data1?.fleetId] = {
                ...y[data1?.fleetId],
                ...data1
              }
            }
            console.log("qwewqqeqweqwe",y,Object.values(y));
            setVehicleData(Object.values(y))
          })
          socket.on('DriverCashUpdateTracker',(data1)=>{
            console.log("DriverCashUpdateTracker",data1);
            if(data1?.fleetId){
              y[data1?.fleetId] = {
                ...y[data1?.fleetId],
                ...data1
              }
            }
            console.log("qwewqqeqweqwe",y,Object.values(y));
            setVehicleData(Object.values(y))
          })
          socket.on('TripCreateTracker',(data1)=>{
            console.log("TripCreateTracker",data1 );
            console.log("xxxxxxxxxx",y,y[data1?.fleetId],data1);
            if(data1?.fleetId){
              y[data1?.fleetId] = {
                ...y[data1?.fleetId],
                ...data1
              }
            }
            console.log("qwewqqeqweqwe",y,Object.values(y));
            setVehicleData(Object.values(y))
          })
          socket.on('TripUpdateTracker',(data1)=>{
            console.log("TripUpdateTracker",data1 );
            console.log("xxxxxxxxxx",y,y[data1?.fleetId],data1);
            if(data1?.fleetId){
              y[data1?.fleetId] = {
                ...y[data1?.fleetId],
                ...data1
              }
            }
            console.log("qwewqqeqweqwe",y,Object.values(y));
            setVehicleData(Object.values(y))
          })
          socket.on('CardBalanceTracker',(data1)=>{
            console.log("CardBalanceTracker",data1 );
            console.log("xxxxxxxxxx",y,y[data1?.userId],data1);
            if(fleetToUser?.[data1?.userId]){
              y[fleetToUser?.[data1?.userId]] = {
                ...y[fleetToUser?.[data1?.userId]],
                ...data1
              }
            }
            console.log("qwewqqeqweqwe",y,Object.values(y));
            setVehicleData(Object.values(y))
          })
          socket.on('OmcBalanceTracker',(data1)=>{
            console.log("OmcBalanceTracker",data1 );
            console.log("xxxxxxxxxx",y,y[data1?.fleetId],data1);
            if(data1?.fleetId){
              y[data1?.fleetId] = {
                ...y[data1?.fleetId],
                ...data1
              }
            }
           
            console.log("qwewqqeqweqwe",y,Object.values(y));
            setVehicleData(Object.values(y))
          })




          console.log("vehicleTracking");
          socket.emit("vehicleTracking", payload);
          interval = setInterval(()=>{
            socket.emit("vehicleTracking", payload);
          },20000)
        },100)
        
        return ()=>{
          clearInterval(interval)
        }
      }
    },[allFleetTrackerList]);
    console.log('cvehicleDatavehicleData',vehicleData);
    const handleLocation = ((index)=>{
        if(mapView=='-1')setMapView(index)
        else setMapView("-1")
    });

    const [status, setStatus] = useState('-1');
    const [remark, setRemark] = useState('-1');
    const handleInputStatus = ((e)=>{
      console.log("--------",e.target.value);
      setStatus(e.target.value);
    });
    const handleInputRemark = ((e)=>{
      setRemark(e.target.value);
    })
    const handleUpdate= ((status, remark,fleetId, values)=>{
      console.log('calling dispatch heeeeeereeee',status,remark,fleetId);
      dispatch(getUpdateFleet({
        status: status,
        remark: remark,
        fleetId: fleetId,
      }))
      values.status = ""
      values.remark = ""
    })

    const handleFastagRechargeModal = (fastagBal, vrn, fleetId)=>{
      setOpen6(true);
      setFastagBal(fastagBal)
      setVrn(vrn)
      setFleetId(fleetId)
    }
    const handleCashGivenModal = (userId)=>{
      setOpen9(true);
      setUserId(userId)
    }
    const handleCardRechargeModal = (balance, userId)=>{
      setOpen7(true);
      setUserId(userId)
      setCardBal(balance)
    }

    const handleOmcRechargeModal = (userId)=>{
      console.log("userId",userId);
      setOpen8(true);
      setUserId(userId)
     
    }



  return (
    <div className='topMargin '>
      <div className="container sideBarMargin" style={{marginLeft: sideBar? '230px' : '100px', maxWidth: sideBar ?windowWidth - 260 :windowWidth- 130,backgroundColor:'white',borderRadius: 16,boxShadow:'0px 4px 20px rgba(0, 0, 0, 0.06' }}>
          <div style={{}}>
              <div  style={{  backgroundColor: 'white' }}>
                  <div style={{ overflowX:'auto', overflowY:'auto', maxHeight:'100%'}}>
                      <table style={{maxWidth:'100%', maxHeight:'100px'}}>
                          <thead>
                              <tr style={{borderBottom:'1px solid #d3d3d3'}}>
                                  <th style={{width:'200px', position:'sticky', left:'0', top:'0', backgroundColor:'white', zIndex:'1', top:'100px'}}>Vehicle No.</th>
                                  <th style={{width:'120px'}}>Driver</th>
                                  <th style={{width:'120px'}}>Phone</th>
                                  <th style={{width:'150px'}}>Fastag Balance</th>
                                  <th style={{width:'150px'}}>Card Balance</th>
                                  <th style={{width:'150px'}}>OMC Balance</th>
                                  <th style={{width:'150px'}}>Driver Cash</th>
                                  <th style={{width:'150px'}}>Trip</th>
                                  <th style={{width:'150px'}}>Location</th>
                                  <th style={{width:'120px'}}>Status</th>
                                  <th style={{width:'150px'}}>Remarks</th>   
                                  <th style={{width:'80px'}}>Fuel</th>
                                  <th style={{width:'80px'}}>GPS</th>
                                  <th style={{width:'80px'}}>Speed</th>
                                  <th style={{width:'150px'}}>Company</th>
                              </tr>
                          </thead>
                          <thead>
                              {vehicleData.map((item,index)=>{
                                console.log("dsad12111111111",item);
                                  const loc = item?.plazaName?.split(",");
                                      return (<>
                                      <Formik
                                      enableReinitialize
                                      initialValues={{
                                        status : item.status,
                                        remark : item.remark,
                                      }}
                                      onSubmit={(values, actions) => {
                                        console.log("values onSubmit", values);
                                      }}
                                    >
                                      {({
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        values,
                                        touched,
                                        isValid,
                                        errors,
                                        setFieldValue,
                                      }) => (
                                        <>
                                        <tr key={index}>
                                          <td style={{width:'200px', position:'sticky', left:'0', backgroundColor:'white'}}>
                                              <span style={{paddingRight:'5px', cursor:'pointer'}} 
                                              onClick={()=>{
                                                setOpen2(true)
                                                console.log("setItem",item);
                                                setItem(item)
                                              }}
                                              >
                                                  <IoIosAddCircle size={28} color="#485B7C" />
                                              </span>
                                              <span>
                                                  {item?.vrn}
                                              </span>
                                          </td>
                                          <td 
                                          style={{width:'120px',cursor:'pointer'}} 
                                          
                                          onClick={()=>{
                                            setItem2(item)
                                            setOpen5(true)
                                          }}>{item?.driverName}</td>
                                          <td style={{width:'120px'}}>{item?.driverPhone  || '--'}</td>
                                          <td style={{width:'150px', marginRight:'0',cursor:'pointer'}} onClick={()=> item.fastagStatus == '1' ? handleFastagRechargeModal(item?.fastagBalance, item?.vrn, item?.fleetId) :''}>
                                              {
                                                item.fastagStatus == '1' ? (
                                                  <div>
                                                    {/* <span style={{padding:'10px', cursor:'pointer'}} 
                                                      onClick={()=>{
                                                        setOpen4(true);
                                                        setVrn(item.vrn);
                                                        setFleetId(item.fleetId);
                                                        }}>
                                                          <IoIosAddCircle size={28} color="#2E7C7B" />
                                                    </span> */}
                                                    <span>
                                                    {item?.fastagBalance}
                                                    </span>
                                                  </div>
                                                ):'---'
                                              } 
                                              
                                              
                                              {
                                                item?.fastagExpanse ? (
                                                  <div style={{color: 'green'}}>
                                                      Expense:  {item?.fastagExpanse}
                                                  </div>
                                                ):null
                                              }
                                              
                                          </td>
                                          <td style={{width:'150px', marginRight:'0',cursor:'pointer'}} onClick={()=> item?.balance != null && item?.balance != undefined ? handleCardRechargeModal(item?.balance, item?.userId) :''} >
                                          
                                            {/* {
                                              item.userId ?  */}
                                              <div>
                                                {/* <span style={{padding:'10px', cursor:'pointer'}} 
                                                onClick={()=>{
                                                  setOpen3(true);
                                                  setUserId(item.userId);
                                                  setCardType(item.cardType);
                                                }}>
                                                    <IoIosAddCircle size={28} color="#2E7C7B" />
                                                </span> */}
                                                <span>
                                                  {item?.balance != null && item?.balance != undefined ? item?.balance : '---'}
                                                </span>
                                              </div>
                                            {/* : '---'
                                            } */}
                                              
                                              
                                              {
                                                item?.cardExpanse ?
                                                <div style={{color: 'green'}}>
                                                    Expense:  {item?.cardExpanse}
                                                </div>:null
                                              }
                                              
                                          </td>
                                          <td style={{width:'150px', marginRight:'0',cursor:'pointer'}} onClick={()=> item?.omcBalance ? handleOmcRechargeModal(item?.userId) :''}  >
                                              {
                                                item.omcBalance ? (
                                                  <div>
                                                    <span>
                                                    {item?.omcBalance || 0}
                                                    </span>
                                                  </div>
                                                ): '---'
                                              }
                                          </td>
                                          <td style={{width:'150px', marginRight:'0',cursor:'pointer'}} onClick={()=> item?.userId != null && item?.userId != undefined ? handleCashGivenModal( item?.userId) :''}>
                                              {
                                                item.tripId ? (
                                                  <div>
                                                    {/* <span style={{padding:'10px', cursor:'pointer'}} 
                                                      onClick={()=>{
                                                        setOpen(true);
                                                        setUserId(item.userId);
                                                        }}>
                                                          <IoIosAddCircle size={28} color="#2E7C7B" />
                                                    </span> */}
                                                    <span>
                                                    {item?.cashBalance || 0}
                                                    </span>
                                                  </div>
                                                ): '---'
                                              }
                                              
                                              
                                              {
                                                item?.cashExpanse ? (
                                                  <div style={{color: 'green'}}>
                                                      Expense: {item?.cashExpanse}
                                                  </div>
                                                ):null
                                              }
                                              
                                          </td>
                                          
                                          <td style={{width:'150px', marginRight:'0',cursor:'pointer'}} >
                                              {
                                                item.tripId ? (
                                                  <div onClick={()=>{
                                                    console.log();
                                                    props.setItem(item)
                                                    navigate(`/trips/${item?.tripId}?search=${''}&offset=${0}`);
                                                  }}>
                                                    {/* <span style={{padding:'10px', cursor:'pointer'}} 
                                                      onClick={()=>{
                                                        setOpen(true);
                                                        setUserId(item.userId);
                                                        }}>
                                                          <IoIosAddCircle size={28} color="#2E7C7B" />
                                                    </span> */}
                                                    <span>
                                                    {`${item?.source} - ${item?.destination}`}
                                                    </span>
                                                  </div>
                                                ): '---'
                                              }
                                          </td>
                                          {
                                            loc?.length > 0 ? (
                                              <td 
                                                style={{width:'150px', cursor:'pointer', color:'blue',textDecoration:'underline'}} 
                                                onClick={()=>(handleLocation(index))}>
                                                  {loc?.length > 1 ?`${loc[loc?.length-2]},${loc[loc?.length-1]}`:loc?.length> 0?`${loc[loc?.length-1]}`:'--'}
                                              </td>
                                            ):<td 
                                                style={{width:'150px'}} >
                                                  --
                                              </td>
                                          }
                                          <td style={{width:'120px'}}>
                                            {/* {item?.Status} */}
                                          <Input
                                            style={{fontSize:10, margin:0, width:'80px'}}
                                            type='text' 
                                            value={values?.status}
                                            onChange={(e)=>{
                                              console.log("e.target.value",e.target.value);
                                              setFieldValue('status',e.target.value,true)
                                            }}
                                            onBlur={(e)=>{
                                              console.log("wqeqeqwrefdsfsdfasfsdfasa status",e,e.target.value);
                                              handleUpdate(e.target.value, values?.remark, item?.fleetId, values);
                                              setFieldValue('status','',true)
                                            }}
                                            />
                                            </td>
                                            <td style={{width:'150px'}}>
                                            <Input
                                            style={{fontSize:12,margin:0, width:'110px'}}
                                            type='text' 
                                            value={values?.remark}                                          
                                            onChange={(e)=>{
                                              console.log("e.target.value",e.target.value);
                                              setFieldValue('remark',e.target.value,true)
                                            }}
                                            onBlur={(e)=>{
                                              console.log("wqeqeqwrefdsfsdfasfsdfasa status",e,e.target.value);
                                              handleUpdate(values?.status, e.target.value,item?.fleetId, values);
                                              setFieldValue('remark','',true)
                                            }}
                                            />
                                          </td>
                                          <td style={{width:'80px'}}>{item?.Fuel}</td>

                                          <td style={{width:'80px'}}>{item?.GPS}</td>
                                          <td style={{width:'80px'}}>{item?.Speed}</td>
                                          
                                        
                                          
                                          <td style={{width:'150px'}}>{item?.company}</td>
                                          

                                          {/* <td style={{width:'150px'}}>Unloading In process</td> */}
                                      </tr>
            </>
            )}
            </Formik>
                                          
                                      {mapView==index && 
                                        <tr style={{padding:'24px'}}>
                                          <td style={{position:'sticky', left:'0', top:'0'}}>
                                            <iframe width='1000px' height='300px' style={{border:'0',margin:'-10px 0 0 100px',padding: '0 50px'}} loading="lazy"
                                              src={parseFloat(item?.lat) ? `https://maps.google.com/maps?q=${item?.lat},${item?.long}&t=&z=15&ie=UTF8&iwloc=&output=embed`:`https://www.google.com/maps/embed/v1/place?key=AIzaSyDY--FHBXDx-Y5R3OxIdrJC1apJhgrNfbg&q=${item?.plazaName}`} >
                                            
                                            </iframe>
                                          </td>
                                        </tr>
                                      }
                                      </>)
                                  })}
                          </thead>
                          
                      </table>
                      
                  </div>
                  {allFleetTracker.count > 0 && (
                    <div className="row margin0 padding16">
                      <div
                        className="col-4"
                        style={{
                          textAlign: "right",
                          paddingTop: "0.1rem",
                          cursor: offset > 0 ? "pointer" : "no-drop",
                          opacity: offset > 0 ? "1" : "0.5",
                        }}
                        onClick={handlePrev}
                      >
                        <SvgImage name="PrevArrow" />
                      </div>
                      <div
                        className="col-1 greenFont semiBold heading4 padding0L"
                        style={{
                          textAlign: "right",
                          paddingTop: "0.3rem",
                          cursor: offset > 0 ? "pointer" : "no-drop",
                          opacity: offset > 0 ? "1" : "0.5",
                        }}
                        onClick={handlePrev}
                      >
                        Previous
                      </div>
                      <div className="col-2 row margin0 justify-content-center">
                        <div
                          className="greenBoxCount Medium heading4 col-4"
                          style={{
                            width: "2rem",
                            height: "2rem",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          {offset / 7 + 1}
                        </div>
                        <div
                          className=" col-4 padding0R"
                          style={{ paddingTop: "0.3rem", textAlign: "right" }}
                        >
                          of{" "}
                          {allFleetTracker?.count % 7 == 0
                            ? Math.floor(allFleetTracker?.count / 7)
                            : Math.floor(allFleetTracker?.count / 7 + 1)}
                        </div>
                      </div>
                      <div
                        className="col-1 greenFont semiBold heading4 padding0R"
                        style={{
                          textAlign: "left",
                          paddingTop: "0.3rem",
                          cursor: allFleetTracker?.count - offset > 7 ? "pointer" : "no-drop",
                          opacity: allFleetTracker?.count - offset > 7 ? "1" : "0.5",
                        }}
                        onClick={handleNext}
                      >
                        Next
                      </div>
                      <div
                        className="col-4 padding0L"
                        style={{
                          textAlign: "left",
                          marginLeft: "-1rem",
                          paddingTop: "0.1rem",
                          cursor: allFleetTracker?.count - offset > 7 ? "pointer" : "no-drop",
                          opacity: allFleetTracker?.count - offset > 7 ? "1" : "0.5",
                        }}
                        onClick={handleNext}
                      >
                        <SvgImage name="NextArrow" />
                      </div>
                    </div>
                  )}
              </div>
              
          </div>     
          <ManageDriverAddBalance
            open={open3}
            toggleModal={toggleModal3}
            title="Logipe Account"
            handleClose={handleClose3}
            color={
              "LogipeAccount" === "Card Wallet"
                ? "title-color1"
                : "title-color2"
            }
            name="Add Balance"
            userId={userId}
            cardType={cardType}
            trip={true}
          />
          <RechargeFastagButttonModal
            open={open4}
            handleClose={handleClose4}
            toggleModal={toggleModal4}
          //   balance={fastagWalletAmount}
            no={vrn}
            fleetId={fleetId}
            trip={true}
          />
          <ManageDriverAddNewExpense
            userId={userId}
            setDisplayName={"Add Expense"}
            displayName={"Add Expense"}
            open={open9}
            toggleModal={toggleModal9}
            title="Logipe Account"
            handleClose={handleClose9}
            trip={true}
            color={
              "LogipeAccount" === "Card Wallet"
                ? "title-color1"
                : "title-color2"
            }
          />
          <AddNewTrip
            open={open2}
            toggleModal={toggleModal}
            handleClose={handleClose}
            path={'tollTracking'}
            item={item}
          />
          <DriverList
            item={item2} 
            open={open5}
            handleClick={handleClose5} 
            toggleModal={toggleModal5}
            offset={offset}
          />
          <RechargeFastagButttonModal
          open={open6}
          handleClose={handleClose6}
          toggleModal={toggleModal6}
          balance={fastagWalletAmount}
          fastagBal={fastagBal}
          no={vrn}
          fleetId={fleetId}
          
          />
            <OMCLoadModal 
            userId ={userId}
              open={open8} 
              toggleModal={toggleModal8} 
              handleClose={handleClose8}
          />
          <ManageDriverAddBalance
          //  loader ={loader}
            open={open7}
            toggleModal={toggleModal7}
            handleClose={handleClose7}
            name="Add Balance"
            userId={userId}
            driverBal={cardBal}
            cardType={1}
          />
        {toastObject.toastStatus ? (
          <Toast
            success={toastObject?.status}
            message={toastObject?.message}
            toggleModal={() => {
              console.log("mesaage-------------other error");
              dispatch(
                toastStatus({
                  toastStatus: false,
                })
              );
              // dispatch(cleanVehicleLoader());
              // dispatch(cleanFastagLoaderAction());
            }}
            // toggleModal_newFleetError={() => {
            //   console.log("mesaage-------------new fleet error");
            //   dispatch(
            //     toastStatus({
            //       toastStatus: false,
            //     })
            //   );
            //   // dispatch(cleanFleetLoader());
            // }}
            isOpen={toastObject?.toastStatus}
            name="tracker"
            handleClose={() => {
              dispatch(
                toastStatus({
                  toastStatus: false,
                  message: "",
                  status: true,
                })
              );
              // dispatch(createSalaryWalletSuccesss('HI'))
              // if (!toastObject?.toastStatus)
              //window.location="/Vehicles"
              //window.location = "/Vehicles/" + id;
              // window.location='/Tracker';
              //alert("close button clicked");
            }}
          />
          
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default Tracker