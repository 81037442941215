//import { Alert } from 'react-native';
import { call, put, takeEvery } from "redux-saga/effects";
import { transferWalletTransferAmountApi, allCardBalanceApi, getCardRequestApi, updateCardRequestApi } from "../../Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import { transferWalletTransferAmountSuccess, allCardsBalanceSuccess, getCardRequestSuccess, updateCardRequestSuccess } from "../Actions/cardWalletAction";
import { TRANSFER_WALLET_AMOUNT, ALL_CARD_BALANCE, CARD_REQUESTS, UPDATE_CARD_REQUESTS } from "../storeTypes";

function* transferWalletTransferAmountSaga(action) {
  try {
    console.log("saga response hereeee", action);
    const response = yield call(
      transferWalletTransferAmountApi,
      action.payload
    );
    console.log("transferWalletTransferAmountSaga ", response?.data);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(transferWalletTransferAmountSuccess());
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* getCardRequest(action) {
  try {
    console.log("saga response hereeesjhdjvjdjhge", action);
    const response = yield call(
      getCardRequestApi,
      action.payload
    );
    console.log("getCardRequestgetCardRequest ", response?.data);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(getCardRequestSuccess(response?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* updateCardRequest(action) {
  try {
    console.log("saga response hereeee", action);
    const response = yield call(
      updateCardRequestApi,
      action.payload
    );
    console.log("transferWalletTransferAmountSaga ", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(updateCardRequestSuccess());
    }
  } catch (error) {
    console.log("there is a errorrrr",error)
    console.log("Error.Response:- ", error?.response?.data);
    alert(`Error : ${error?.response?.data?.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}



function* allCardsBalanceSaga(action) {
  try {
    console.log("inside sage for users", action);
    //console.log("api used", getUserDetailsApi);
    const response = yield call(allCardBalanceApi);
    console.log ("saga for all cards");
    console.log("response all cards", response);
    if (response?.status == 200) {
      console.log("sagA", response?.data);
      yield put(allCardsBalanceSuccess(response?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    console.log("inside sage for errors in users");
    console.log("Error.Response getUSerDetails:- ", error.response?.data);
  }
}



export function* watchCardWalletSagas() {
  yield takeEvery(TRANSFER_WALLET_AMOUNT, transferWalletTransferAmountSaga);
  yield takeEvery(CARD_REQUESTS, getCardRequest);
  yield takeEvery(UPDATE_CARD_REQUESTS, updateCardRequest);
  yield takeEvery(ALL_CARD_BALANCE, allCardsBalanceSaga);
}


