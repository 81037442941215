import React, { useState, useEffect } from "react";
import SvgImage from "../../../Icons/Icons";
import { Table } from "reactstrap";
import moment from "moment";
import "./index.css";
import ReactTable from "react-table";

import {
  cleanWalletSummary,
  getWalletSummary,
} from "../../../../services/Store/Actions/LogipeCardActions";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import DetailCardHeader from "../detailCardHeader";
import { Button } from "reactstrap";
import ReactPaginate from "react-paginate";

function OwnerTransactionHistoryTable(props) {
  const { mode, amount, date, customer, filter, History,start,end } = props;
  const [open, setOpen] = useState(false);
  const [billId, setBillId] = useState("");
  const [active, setActive] = useState(0);
  const [offset, setOffset] = useState(0);
  const [btn, setBtn] = useState(false);
  const [fetchMoreData, setFetchMoreData] = useState(true);
  const { loader } = useSelector((state) => state?.LogipeCard);
  //   const { billDetails } = useSelector(
  //     (state) => state?.receivable
  //   );
  //   console.log('nn',billDetails);
  const dispatch = useDispatch();
  const { walletSummary, walletSummaryList } = useSelector(
    (state) => state?.LogipeCard
  );
  const totalExpenseCount = walletSummary?.count;
  const pageCount =
    totalExpenseCount % 10 === 0
      ? totalExpenseCount / 10
      : Math.floor(totalExpenseCount / 10) + 1;
  console.log("yukta", totalExpenseCount);
  //  useEffect(()=>{
  //   setOffset(0);
  //   // dispatch(cleanWalletSummary());
  //    //console.log("2nd re-rendering");
  //   dispatch(getWalletSummary(props.userId, {
  //     offset: 0 ,
  //     search: '',
  //     minKyc: '',
  //     sortAs: '',
  //     sortBy: '',
  //   }))
  //  },[])

  console.log("userId", props.userId);
  const debounce = (func, delay) => {
    let Timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(Timer);
      console.log("debounce Testing", Timer);

      Timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  //  const handleLoadMore = async () => {
  //   if (walletSummaryList?.length >= walletSummary?.count)
  //   {
  //     setFetchMoreData(false)
  //   }
  //   else
  //   {
  //     setFetchMoreData(true);
  //     setOffset(offset + 10);
  //     dispatch(
  //       getWalletSummary(props.userId, {
  //         offset: offset+10 ,
  //         search: '',
  //         minKyc: '',
  //         sortAs: '',
  //         sortBy: '',
  //       })
  //     );
  //   }
  // };

  console.log("summary", walletSummary);
  //console.log("MAINPAGE",walletSummary.rows)
  //const array = walletSummary?.rows
  // console.log("sdospdopsdopsd",array)

  //console.log("customerId", customerRecievables);
  //   useEffect(() => {
  //     dispatch(
  //       getCustomerRecievables({
  //         customerId: customer,
  //         offset: 0,
  //       })
  //     );
  //   }, [customer, open]);
  function handleClose() {
    setOpen(false);
  }
  function toggleModal() {
    setOpen(!open);
    setBillId("");
  }
  function handleActiveButton(val) {
    //console.log(val);
    setActive(val);
  }
  // function handleClick(){
  //   dispatch(cleanWalletSummary())
  //   setBtn(!btn)
  //   if(btn){
  //     dispatch(getWalletSummary(props.userId, {
  //       offset: 0 ,
  //       search: '',
  //       minKyc: '',
  //       sortAs: 'Asc',
  //       sortBy: 'createdAt',
  //     }))

  //   }
  //   else{
  //     dispatch(getWalletSummary(props.userId, {
  //       offset: 0 ,
  //       search: '',
  //       minKyc: '',
  //       sortAs: '',
  //       sortBy: 'createdAt',
  //     }))

  //   }
  //console.log("sorted")
  // }
  // useEffect(() => {
  //   setFetchMoreData(false);
  // }, [walletSummaryList]);
  //console.log('walletSummary', walletSummary);
  // console.log('pp',receivableData);

  // const handleOlderExpense= async()=>{
  //   setOffset(offset + 10);
  //   dispatch(
  //     getWalletSummary(props.userId, {
  //       offset: offset+10 ,
  //       search: '',
  //       minKyc: '',
  //       sortAs: '',
  //       sortBy: '',
  //     })
  //   );
  // }

  const handlePageClick = (event) => {
    console.log("Clickess");
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    if (filter === 0) {
      dispatch(
        getWalletSummary(props.userId, {
          offset: newOffset,
          fromDate: "",
          toDate: "",
          sortBy: "",
          sortAs: "",
        })
      );
    } else {
      dispatch(
        getWalletSummary(props.userId, {
          offset: newOffset,
          fromDate: moment(start).format("YYYY-MM-DD"),
          toDate: moment(end).format("YYYY-MM-DD"),
          sortBy: "",
          sortAs: "",
        })
      );
    }

    setOffset(newOffset);
  };
  return (
    <div className="">
      {/* <div className="row">
            <div className="col-1">
                <SvgImage name = 'VehicleLogo' classname ='vehicle-logo'/>
            </div>
            <div className="col-6">
                <p className="vehicle-assigned-history-title" style={{marginTop : '9px'}}>
                Vehicle Assigned History
                </p>
            </div>
        </div> */}
      <div
        className=""
        style={{ zIndex: 90, background: "#96A9CA" }}
      >
        <DetailCardHeader />
      </div>
      <div style={{ position: "relative", top: "0px", zIndex: 0 }}>
        {/* <InfiniteScroll
      
      dataLength={walletSummaryList?.length} //This is important field to render the next data
      next={debounce(handleLoadMore,300)}
      hasMore={fetchMoreData}
     // loader={<h4>...</h4>}
    //  scrollThreshold="200px"
      scrollableTarget ="scrollableDiv"
      > */}

        <Table className="fixed-header" style={{}}>
          <div className="tbody padding16">
            {History?.map((data) => {
              return (
                <>
                  <div className="table-row-logipe-details row padding16 bottomBorderStyle ">
                    <div className="col-6 padding8L">
                      <div>{data?.channel}</div>
                      <div>{data?.description}</div>
                    </div>
                    <div
                      className={
                        data?.transferType === "debit"
                          ? "col-3 paddingL redColor"
                          : "col-3 paddingL greenColor"
                      }
                    >
                      ₹{Number(data.amount).toFixed(2)}
                    </div>
                    <div className="col-3 paddingL">
                      <div>
                        {moment(data.transactionDate).format("DD-MM-YYYY")}
                      </div>
                      <div>
                        {moment(data.transactionDate).format("hh:mm:ss a")}
                      </div>
                    </div>
                  </div>
                  {/* <tr>
                  <td>
                    
                  </td>
                </tr> */}
                </>
              );
            })}
          </div>
        </Table>
        {/* </InfiniteScroll> */}
      </div>
      {/* {
        ((totalExpenseCount-offset)>10)?
        <div className="OlderHistoryButton" onClick={handleOlderExpense}
          >
          <Button
              disabled={loader?true:false}
              className="transactionButton"
              
              >
          {loader?"Please Wait":"Older Transactions"}
          <div style={{paddingLeft:"8px"}}><SvgImage
          name="downArrow"
              /></div>
          
              </Button>
      </div>:
          <></>
      } */}

      {pageCount > 1 ? (
        <div className="reactPagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default OwnerTransactionHistoryTable;
