import React from "react";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { Modal, ModalBody, Button } from "reactstrap";
const ToastV2 = (props) => {
  const {
    success,
    message,
    isOpen,
    onHide,
    toggleModal,
    handleClose,
    name,
    toggleModal_newFleetError,
    invoice,
    link,
    rediractionLink,
    mobileNumber,
    emailId,
  } = props;
  const openLink = () => {
    window.open(link, "_blank");
  };
  // console.log(
  //   "mesaage-------------",
  //   message,
  // message.includes("Error : Recharge limit by PG exceeded left")
  // );
  return (
    <Modal
      isOpen={isOpen}
      onHide={onHide}
      className=" modal-dialog-centered modal-dialog model-width-toast"
      // toggle={toggle}
      aria-labelledby="myModalLabel2"
      // style={{borderRadius : "10px"}}
    >
      <ModalBody
      //   className="transactionModalBody"
      //   style={{ marginTop: "25px" }}
      //   style={{borderRadius : "10px"}}
      >
        <div>
          {success ? (
            invoice ? (
              <div className="row">
                <div className="col-12">
                  <div style={{ textAlign: "center", paddingTop: "10px" }}>
                    <SvgImage name="successLogo" />
                  </div>
                </div>
                <div className="col-12 successToastHeader">success!</div>
                <div className="col-12 successToastMsg">{message}</div>
                <div
                  className="col-12 row"
                  style={{
                    textAlign: "center",
                    padding: "30px 0 0 0",
                    marginLeft: "20px",
                  }}
                >
                  <div className="col-6 row " onClick={openLink}>
                    <div className="col-12 " style={{}}>
                      <SvgImage name="eyeIcon" />
                    </div>
                    <div className="col-12 invoiceText mt-2">View Invoice</div>{" "}
                  </div>
                  <div
                    className="col-6 row"
                    onClick={() => {
                      const image = link?.split('?');
                      const image2 = image?.[0]?.replaceAll('%20','%2520')
                      window.open(
                        `mailto:${emailId}?subject=Invoice for Frieght&body=Hi,%0A%0APlease open this link and get invoice,%0A%0A${image2}`
                      );
                    }}
                  >
                    <div className="col-12">
                      {/* {rediractionLink ? ( */}
                      <SvgImage name={"shareIcon"} />
                      {/* ) : null} */}
                      {/* <SvgImage name="downloadInvoice" /> */}
                    </div>
                    <div className="col-12 invoiceText mt-2">Share Invoice</div>
                  </div>
                </div>
                <div
                  className="col-12 successToastMsg"
                  style={{ padding: "45px 0 30px 0" }}
                >
                  <Button
                    type="submit"
                    style={{ width: "160px", borderRadius: "5px" }}
                    className="generateOtp textAddCust Medium btn btn-secondary"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div style={{ textAlign: "center", paddingTop: "10px" }}>
                    <SvgImage name="successLogo" />
                  </div>
                </div>
                <div className="col-12 successToastHeader">success!</div>
                <div className="col-12 successToastMsg">{message}</div>
                {rediractionLink ? (
                  <a
                    className="col-12 successToastMsg"
                    href={rediractionLink}
                    target="_blank"
                  >
                    Link: {rediractionLink}
                  </a>
                ) : null}
                {rediractionLink ? (
                  <div className="col-12 successToastMsg d-flex justify-content-center">
                    <div
                      className="pe-2"
                      onClick={() => {
                        window.open(
                          `https://web.whatsapp.com/send?phone=${mobileNumber}&text=${encodeURI(
                            `Hi, \nPlease Pay using this link \n ${rediractionLink}`
                          )}&app_absent=0`
                        );
                      }}
                    >
                      <SvgImage name={"whatsappIcon"} />
                    </div>

                    <div
                      className="ps-1"
                      onClick={() => {
                        window.open(
                          `mailto:${emailId}?subject=Collect Payment via payment link&body=Hi,\nPlease Pay using this link \n ${rediractionLink}`
                        );
                      }}
                    >
                      <SvgImage name={"mailIcon"} />
                    </div>
                  </div>
                ) : null}
                <div
                  className="col-12 successToastMsg"
                  style={{ padding: "20px 0 30px 0" }}
                >
                  <Button
                    type="submit"
                    style={{ width: "160px", borderRadius: "5px" }}
                    className="generateOtp textAddCust Medium btn btn-secondary"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            )
          ) : (
            <div className="row">
              <div className="col-12">
                <div style={{ textAlign: "center", paddingTop: "10px" }}>
                  <SvgImage name="FailureToast" />
                </div>
              </div>
              <div className="col-12 successToastHeader">Error!</div>
              <div className="col-12 successToastMsg">{message}</div>
              <div
                className="col-12 successToastMsg"
                style={{ padding: "45px 0 30px 0" }}
              >
                <Button
                  type="submit"
                  style={{ width: "160px", borderRadius: "5px" }}
                  className="generateOtp textAddCust Medium btn btn-secondary"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ToastV2;
