import {
  GET_VIRTU_ACCOUNT,
  GET_VIRTU_ACCOUNT_SUCCESS,
  BANK_DIRECT_TRANSFER,
  BANK_DIRECT_TRANSFER_SUCCESS,
  BANK_TRANSFER_TO_ADDED_BENEFICIARY,
  BANK_TRANSFER_TO_ADDED_BENEFICIARY_SUCCESS,
  BANK_TRANSFER_TO_ADDED_BENEFICIARY_ERROR,
  BANK_DIRECT_TRANSFER_ERROR,
  BANK_TRANSFER_BENEFICIARY_ADD,
  BANK_TRANSFER_BENEFICIARY_ADD_SUCCESS,
  BANK_TRANSFER_BENEFICIARY_ERROR,
  GET_BENEFICIARY,
  GET_BENEFICIARY_SUCCESS,
  GET_COLLECTIONS,
  GET_COLLECTIONS_SUCCESS,
  GET_SETTLEMENTS,
  GET_SETTLEMENTS_SUCCESS,
  CLEAN_COLLECTIONS,
  CLEAN_BENEFICIARY_REDUCER,
  CREATE_VENDOR,
  CREATE_VENDOR_SUCCESS,
  GET_LOGIPE_VENDOR_DETAILS,
  GET_LOGIPE_VENDOR_DETAILS_SUCCESS,
  EDIT_VENDOR,
  EDIT_VENDOR_SUCCESS,
  GET_VENDOR,
  GET_VENDOR_SUCCESS,
  VENDOR_TRANSACTION,
  VENDOR_TRANSACTION_SUCCESS,
  VENDOR_UPLOAD_BILL,
  VENDOR_GET_SIGNED_URL,
  VENDOR_UPLOAD_BILL_SUCCESS,
  VENDOR_GET_SIGNED_URL_SUCCESS,
  GENERATE_STATEMENT_FOR_VENDOR,
  GENERATE_STATEMENT_FOR_VENDOR_SUCCESS,
  TRANSFER_TO_VENDOR,
  TRANSFER_TO_VENDOR_SUCCESS,
  CLEAN_VENDOR_TRANSACTION,
  CLEAN_VENDOR_LIST,
  GET_VENDOR_DETAILS,
  GET_VENDOR_DETAILS_SUCCESS,
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  beneficiaryDetails: [],
  bankTransferStatement: [],
  beneficiaryTransactions: [],
  virtuAccount: {},
  collections: [],
  settlements: [],
  LogipeVendorDetails: {},
  vendor: {},
  vendorList: [],
  vendorTransaction: {},
  vendorTransactionList: [],
  vendorCount: 0,
  vendorDetails:{}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VIRTU_ACCOUNT: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_VIRTU_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loader: false,
        virtuAccount: action.payload,
      };
    }
    case BANK_DIRECT_TRANSFER: {
      return {
        ...state,
        loader: true,
      };
    }
    case BANK_DIRECT_TRANSFER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case BANK_DIRECT_TRANSFER_ERROR: {
      return {
        ...state,
        loader: false,
      };
    }

    case BANK_TRANSFER_TO_ADDED_BENEFICIARY: {
      console.log("inside reducer -> BANK_TRANSFER_TO_ADDED_BENEFICIARY");
      return {
        ...state,
        loader: true,
      };
    }
    case BANK_TRANSFER_TO_ADDED_BENEFICIARY_SUCCESS: {
      console.log("inside reducer -> BANK_TRANSFER_TO_ADDED_BENEFICIARY");
      return {
        ...state,
        loader: false,
      };
    }

    case BANK_TRANSFER_TO_ADDED_BENEFICIARY_ERROR: {
      console.log("inside reducer -> BANK_TRANSFER_TO_ADDED_BENEFICIARY");
      return {
        ...state,
        loader: false,
      };
    }

    case BANK_TRANSFER_BENEFICIARY_ADD: {
      return {
        ...state,
        loader: true,
      };
    }
    case BANK_TRANSFER_BENEFICIARY_ADD_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case BANK_TRANSFER_BENEFICIARY_ERROR: {
      return {
        ...state,
        loader: false,
      };
    }

    case GET_BENEFICIARY: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_BENEFICIARY_SUCCESS: {
      return {
        ...state,
        loader: false,
        beneficiaryDetails: action.payload,
      };
    }
    case GET_COLLECTIONS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        loader: false,
        collections: action.payload,
      };
    }
    case GET_SETTLEMENTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_SETTLEMENTS_SUCCESS: {
      return {
        ...state,
        loader: false,
        settlements: action.payload,
      };
    }
    case CLEAN_COLLECTIONS: {
      return {
        ...state,
        driverList: [],
      };
    }
    case CLEAN_BENEFICIARY_REDUCER: {
      return {
        ...state,
        loader: false,
      };
    }

    case CREATE_VENDOR: {
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_VENDOR_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_LOGIPE_VENDOR_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_LOGIPE_VENDOR_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        LogipeVendorDetails: action.payload,
      };
    }
    case EDIT_VENDOR: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_VENDOR_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_VENDOR: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_VENDOR_SUCCESS: {
      return {
        ...state,
        loader: false,
        vendor: action.payload?.vendorList,
        vendorList:  [...state?.vendorList, ...action?.payload?.vendorList?.rows],
        vendorCount: action?.payload?.vendorList?.count
      };
    }
    case VENDOR_TRANSACTION: {
      return {
        ...state,
        loader: true,
      };
    }
    case VENDOR_TRANSACTION_SUCCESS: {
      return {
        ...state,
        loader: false,
        vendorTransaction: action.payload?.data?.vendor_transactions,
        vendorTransactionList: action.payload?.data?.vendor_transactions?.records,
      };
    }
    case VENDOR_UPLOAD_BILL: {
      return {
        ...state,
        loader: true,
      };
    }
    case VENDOR_UPLOAD_BILL_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case VENDOR_GET_SIGNED_URL: {
      return {
        ...state,
        loader: true,
      };
    }
    case VENDOR_GET_SIGNED_URL_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GENERATE_STATEMENT_FOR_VENDOR: {
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_STATEMENT_FOR_VENDOR_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case TRANSFER_TO_VENDOR: {
      return {
        ...state,
        loader: true,
      };
    }
    case TRANSFER_TO_VENDOR_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_VENDOR_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_VENDOR_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        vendorDetails: action?.payload
      };
    }
    case CLEAN_VENDOR_LIST: {
      return {
        ...state,
        loader: false,
        vendorList: [],
        vendor: {},
      };
    }
    case CLEAN_VENDOR_TRANSACTION: {
      return {
        ...state,
        loader: false,
        vendorTransactionList: [],
        vendorTransaction: {},
      };
    }
    default: {
      return state;
    }
  }
};
