import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
import { Button, FormGroup, Form, Table } from "reactstrap";
import { ClipLoader } from "react-spinners";

import "./index.css";
import SvgImage from "../../../Icons/Icons";
import { DatePickerField } from "../../../core/inputs/input";
import {
  toastStatus,
  storeMedia,
  getSignedUrlApiAction,
} from "../../../../services/Store/Actions/appActions";
import moment from "moment";
import {
  editLR,
  getLRList,
} from "../../../../services/Store/Actions/TripsAction";
import ToastV2 from "../../../subComponents/toastV2";

function FreightUpdateRender(props) {
  const { freight, tripId } = props;

  const dispatch = useDispatch();
  const toastObject = useSelector((state) => state?.app?.toastObject);

  const [toast, setToast] = useState(false);
  const [toastStatusv2, setToastStatus] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [imageLoaderPod, setImageLoaderPod] = useState(false);
  const [statusPod, setStatusPod] = useState(false);
  const [displayNamePod, setDisplayNamePod] = useState([]);
  const [view, setView] = useState(false);
  const [chargesFormValues, setChargesFormValues] = useState([]);
  let handleChangeFieldCharges = (i, e) => {
    let newFormValues = [...chargesFormValues];
    newFormValues[i][e.target.name] = e.target.value;
    setChargesFormValues(newFormValues);
  };
  let removeChargesFormFields = (index) => {
    const newFormValues = chargesFormValues.filter((item, i) => {
      return index != i;
    });
    console.log("newFormValues", newFormValues);
    setChargesFormValues(newFormValues);
  };
  let addFormFieldsCharges = () => {
    console.log("===><<<><><", chargesFormValues);
    setChargesFormValues([...chargesFormValues, { remarks: "", amount: "" }]);
  };
  const [imageLoader, setImageLoader] = useState(false);
  console.log("freightRenderfreightRenderfreightRender", freight);
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  useEffect(() => {
    if (freight?.podUrl) {
      dispatch(
        getSignedUrlApiAction({ key: freight?.podUrl }, (res) => {
          // setDownloadLoader2(false);
          // setLoaderInvoiceId("");
          if (res.status == 200) {
            setDisplayNamePod([
              { name: freight?.podUrl, link: res?.data?.data },
            ]);
            setStatusPod(true);
          }
        })
      );
    }
    setChargesFormValues(
      freight?.chargesData?.length > 0 ? freight?.chargesData : []
    );
  }, [freight]);
  const payor =
    freight?.transportorId == freight?.customerId
      ? "Transporter"
      : freight?.consigneeId == freight?.customerId
      ? "Consignee"
      : "Consignor";
  return (
    // view ? <div>khdsvabakdhj dshajvbcskaj sdhkgbc</div> :
    <div style={{}}>
      {view ? (
        <div style={{}}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <div>
              <div style={{ fontWeight: 600 }}> Consignor: </div>
              <div>
                {freight?.consignorName ? freight?.consignorName : "NA"}{" "}
              </div>
              <div style={{ fontWeight: 600 }}> From: </div>
              <div>
                {freight?.fromAddress
                  ? freight?.fromAddress?.cityName +
                    ", " +
                    freight?.fromAddress?.stateName
                  : freight?.source}{" "}
              </div>
            </div>
            <div>
              <div style={{ fontWeight: 600 }}> Consignee: </div>
              <div>
                {freight?.consigneeName ? freight?.consigneeName : "NA"}{" "}
              </div>
              <div style={{ fontWeight: 600 }}> To: </div>
              <div>
                {freight?.toAddress
                  ? freight?.toAddress?.cityName +
                    ", " +
                    freight?.toAddress?.stateName
                  : freight?.destination}{" "}
              </div>
            </div>
            <div>
              <div style={{ fontWeight: 600 }}> Transporter: </div>
              <div>
                {freight?.transportorName ? freight?.transportorName : "NA"}{" "}
              </div>
              <div style={{ fontWeight: 600 }}> Vehicle: </div>
              <div>{freight?.Fleet?.regNumber} </div>
            </div>
            <div>
              <div style={{ fontWeight: 600 }}> Payor: </div>
              <div>{payor ? payor : "NA"} </div>
              <div style={{ fontWeight: 600 }}> Driver: </div>
              <div>
                {freight?.User
                  ? freight?.User?.fullName + "," + freight?.User?.phone
                  : ""}
              </div>
            </div>
            <div>
              <div style={{ fontWeight: 600 }}> StartDate: </div>
              <div>{freight.startDate} </div>
            </div>
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <hr style={{ border: "2px solid #2E7C7B" }}></hr>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        <Formik
          // isValid='true'
          enableReinitialize
          initialValues={{
            freightId: freight ? freight?.freightId : "",
            podUrl: freight ? freight.podUrl : null,
          }}
          onSubmit={(values) => {
            let objVal = {
              freightId: freight ? freight?.freightId : "",
              chargesData: chargesFormValues,
              podUrl: values?.podUrl,
            };
            dispatch(
              editLR(objVal, (res) => {
                if (res?.status == 200) {
                  // console.log('rererere',res)
                  // handleClose();
                  dispatch(
                    getLRList(
                      {
                        offset: 0,
                        limit: 10,
                        tripId: tripId
                      },
                      () => {}
                    )
                  );
                  dispatch(
                    toastStatus({
                      toastStatus: true,
                      message: `LR has been edited successfully`,
                      status: true,
                    })
                  );
                  // setOpenToast(true);
                  console.log("...............", res);
                } else if (res?.status == 201) {
                  // handleClose();
                  dispatch(
                    toastStatus({
                      toastStatus: true,
                      message: `LR can not be edited after invoice is created`,
                      status: false,
                    })
                  );
                }
              })
            );
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form onSubmit={handleSubmit} style={{ padding: "0 50px 0 50px" }}>
              <div>
                <FormGroup>
                  <div>
                    <div className="heading3 semiBold">
                      Penalties / Shortages
                    </div>
                    <Table
                      className="TripSectionTable"
                      bordered="true"
                      id="driver_tables" //
                      // style={{ marginTop: "16px" }}
                    >
                      <thead>
                        <tr className="table-header-trip p-0 align-middle text-center">
                          <th className="p-0" style={{ width: "7%" }}>
                            <div style={{ marginLeft: "10px" }}>S.No</div>
                          </th>
                          <th
                            className="p-0"
                            style={{
                              width: "30%",
                              textAlignLast: "center",
                            }}
                          >
                            <div>Remarks</div>{" "}
                          </th>
                          <th
                            className="p-0"
                            style={{
                              width: "16%",
                              textAlignLast: "center",
                            }}
                          >
                            Amount
                          </th>
                          <th
                            className="p-0"
                            style={{
                              width: "5%",
                              textAlignLast: "center",
                            }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="lowerBorderLineCust">
                        {chargesFormValues?.map((element, index) => (
                          <tr
                            className="table-row-manage-trip-new text4 align-middle text-center p-0"
                            id="addr0"
                            key={index}
                            onClick={() => {
                              // window.location = "/Customers/" + 1;
                              //   navigate("/Customers/" + 1);
                            }}
                          >
                            <td
                              style={{
                                width: "7%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                            >
                              <div style={{ marginLeft: "10px" }}>
                                {index + 1}
                              </div>
                            </td>
                            <td
                              style={{
                                width: "30%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                            >
                              <input
                                type="text"
                                className="minBox3"
                                name="remarks"
                                value={element.remarks || ""}
                                onChange={(e) =>
                                  handleChangeFieldCharges(index, e)
                                }
                              />
                            </td>
                            <td
                              style={{
                                width: "16%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                            >
                              <input
                                type="number"
                                className="minBox3"
                                name="amount"
                                value={element.amount || ""}
                                onChange={(e) =>
                                  handleChangeFieldCharges(index, e)
                                }
                              />
                            </td>
                            <td
                              style={{
                                width: "5%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                              onClick={() => {
                                removeChargesFormFields(index);
                              }}
                            >
                              <SvgImage name="deleteIcon2" />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-center">
                      <div
                        className="addItemDiv"
                        style={{ width: "100%" }}
                        onClick={addFormFieldsCharges}
                      >
                        + Add Item
                      </div>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup style={{ marginRight: "110px" }}>
                  <label for="podUrl" className="heading3 semiBold">
                    Upload Pod
                  </label>
                  <div style={{ display: "flex" }}>
                    {!statusPod ? (
                      <p
                        className="heading4 Regular"
                        style={{ margin: "10px 0 5px" }}
                      >
                        Upload Document
                      </p>
                    ) : (
                      <></>
                    )}
                    {!statusPod ? (
                      <Button
                        className="add-attach-for-fastag-linked-vehicle-new"
                        type="button"
                      >
                        {imageLoader ? (
                          <div style={{ zIndex: 10 }}>
                            <ClipLoader
                              color={"#2E7C7B"}
                              loading={true}
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </div>
                        ) : (
                          <div>
                            <SvgImage
                              name="AddAttachment"
                              height="25"
                              width="25"
                            />
                            <input
                              type="file"
                              style={{ width: "25px" }}
                              // multiple
                              // accept="image/*"
                              accept="image/*"
                              className="add-lr-attachment"
                              onChange={(e) => {
                                const img = e?.target.files[0];
                                console.log("image", img?.name);

                                const tempArray = Object.assign(
                                  [],
                                  values.docs
                                );
                                console.log("sel", tempArray);

                                if (img == null) {
                                  //clear image
                                  setFieldValue("podUrl", "", true);
                                  //for now  only one supported
                                } else {
                                  console.log("upload", {
                                    img: img,
                                    uploadType: "aadharFront",
                                    isRes: true,
                                  });
                                  setImageLoader(true);
                                  dispatch(
                                    storeMedia(
                                      {
                                        img: img,
                                        uploadType: "aadharFront",
                                        isRes: false,
                                      },
                                      (res) => {
                                        setStatusPod(true);
                                        setDisplayNamePod((prev) => [
                                          { name: img?.name },
                                        ]);
                                        setImageLoader(false);
                                        if (res?.url) {
                                          console.log("opt", res);
                                          tempArray.push(res?.data?.Location);
                                          setFieldValue(
                                            "podUrl",
                                            res?.url,
                                            true
                                          );
                                          // setFieldValue('docs', tempArray, true);
                                          //for now  only one supported
                                          setFieldValue(
                                            "docs",
                                            [res?.data?.Location],
                                            true
                                          );
                                        }
                                      }
                                    )
                                  );
                                }
                              }}
                            />
                          </div>
                        )}
                        <div
                          style={{
                            margin: "0px -16px 2px -614px",
                            // color: "red",
                          }}
                        >
                          {errors.podUrl && touched.podUrl ? (
                            <div>*{errors.podUrl}</div>
                          ) : null}
                        </div>
                      </Button>
                    ) : (
                      <></>
                    )}
                    <div className="selected-img">
                      {statusPod ? (
                        displayNamePod?.map((d) => {
                          return (
                            <div className="row">
                              <div className="col-9">
                                <p
                                  style={{
                                    color: "black",
                                    margin: "5px 0 0",
                                    // padding: "0",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    window.open(d.link);
                                  }}
                                >
                                  {d.name}
                                </p>
                              </div>
                              <div
                                className="col-2"
                                onClick={() => {
                                  setStatusPod(false);
                                  setFieldValue("podUrl", "", true);
                                }}
                              >
                                <text>Change</text>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    style={{ width: "70%" }}
                    className="generateOtp text3 Medium btn btn-secondary"
                  >
                    Submit
                  </Button>
                </FormGroup>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <ToastV2
        success={toastStatusv2}
        message={toastMessage}
        toggleModal={() => {
          // dispatch(
          //   toastStatus({
          //     toastStatus: false,
          //   })
          // );
          setToastStatus(false);
          setToast(false);
        }}
        toggleModal_newFleetError={() => {
          // dispatch(
          //   toastStatus({
          //     toastStatus: false,
          //   })
          // );
          setToast(false);
          setToastStatus(false);
        }}
        isOpen={toast}
        name="trips"
        handleClose={() => {
          // dispatch(
          //   toastStatus({
          //     toastStatus: false,
          //   })
          // );
          setToastStatus(false);
          setToast(false);
        }}
      />
    </div>
  );
}
export default FreightUpdateRender;
