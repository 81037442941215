import { 
    GET_CARD_DETAILS,
    GET_CARD_DETAILS_SUCCESS,
    GET_EXPENSE_BALANCE,
    GET_EXPENSE_BALANCE_SUCCESS,
    MASTERWALLET_TRANSACTION_HISTORY,
    MASTERWALLET_TRANSACTION_HISTORY_SUCCESS,
    WALLET_SUMMARY,
    WALLET_SUMMARY_SUCCESS,
    CLEAN_WALLET_SUMMARY,
    GET_CARD_CONTROL,
    GET_CARD_CONTROL_SUCCESS,
    EDIT_CARD_CONTROL,
    EDIT_CARD_CONTROL_SUCCESS,
    WALLET_ADD_MONEY,
    WALLET_ADD_MONEY_SUCCESS,
    WALLET_WITHDRAW_MONEY,
    WALLET_WITHDRAW_MONEY_SUCCESS,
    CHANGE_CARD_STATUS,
    CHANGE_CARD_STATUS_SUCCESS,
    GET_BALANCE_DETAILS,
    GET_BALANCE_DETAILS_SUCCESS,
    NEW_CARD_BAL,
    NEW_CARD_BAL_SUCCESS
 } from "../storeTypes";


 export function getBalanceDetails(payload){
   return{
     type:GET_BALANCE_DETAILS,
     payload:payload,
   }
 }
 export function getBalanceDetailsSuccess(payload){
   return{
    type:GET_BALANCE_DETAILS_SUCCESS,
    payload:payload,
   }
     
 }
 export function changeCardStatus(id, callBack) {
    return {
      type: CHANGE_CARD_STATUS,
      payload: id,
      callBack,
    };
  }
  export function changeCardStatusSuccess(data) {
    return {type: CHANGE_CARD_STATUS_SUCCESS, payload: data};
  }
 export function walletWithdrawMoney(userId, obj, callBack) {
    return {
      type: WALLET_WITHDRAW_MONEY,
      userId,
      payload: obj,
      callBack,
    };
  }
  
  export function walletWithdrawMoneySuccess(data) {
    return {type: WALLET_WITHDRAW_MONEY_SUCCESS, payload: data};
  }
  
  export function walletAddMoney(userId, obj, callBack) {
    return {
      type: WALLET_ADD_MONEY,
      userId,
      payload: obj,
      callBack,
    };
  }
  
  export function walletAddMoneySuccess(data) {
    return {type: WALLET_ADD_MONEY_SUCCESS, payload: data};
  }



export function getCardDetails(payload){
       return{
           type:GET_CARD_DETAILS,
           payload:payload,

       }
}
export function getCardDetailsSuccess(payload){
    return{
        type:GET_CARD_DETAILS_SUCCESS,
        payload:payload,
    }
}
export function getExpenseBalance(payload){
    return{
        type:GET_EXPENSE_BALANCE,
        payload:payload,
    }

}
export function getExpenseBalanceSuccess(payload){
    return{
        type:GET_EXPENSE_BALANCE_SUCCESS,
        payload:payload,
    }
}
export function transactionHistory(obj) {
    return {
      type: MASTERWALLET_TRANSACTION_HISTORY,
      payload: obj,
    };
  }
  
  export function transactionHistorySuccess(data) {
    return {type: MASTERWALLET_TRANSACTION_HISTORY_SUCCESS, payload: data};
  }
    
  export const getWalletSummary = (userId, data) => {
    return {
      type: WALLET_SUMMARY,
      userId:userId,
      payload: data,
    };
  };
  export const getWalletSummarySuccess = payload => {
    return {
      type: WALLET_SUMMARY_SUCCESS,
      payload,
    };
  };   
  export const cleanWalletSummary =()=>{
     return {
      type:CLEAN_WALLET_SUMMARY,
     };
  };

  export function getWalletBalAction(obj) {
    return {
      type: NEW_CARD_BAL,
      payload: obj,
    };
  }
  
  export function getWalletBalSuccessAction(data) {
    return {type: NEW_CARD_BAL_SUCCESS, 
      payload: data};
  }