import React, { useEffect, useState } from "react";
import "./index.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Table } from "reactstrap";
import { getVehicleListAction } from "../../../../services/Store/Actions/manageVehicleAction";
import { useDispatch } from "react-redux";
import LinkFastagToAddedVehicle from "../../../Modals/linkFastagToAddedVehicle";
import { compose } from "redux";
import SvgImage from "../../../Icons/Icons";
import LinkFastagHome from "../../../Modals/LinkFastagHome";
import LinkFastagKycOtp from "../../../Modals/LinkFastagKycOtp";
import LinkFastagDocDetails from "../../../Modals/LinkFastagDocDetails";
import { reissueAvailableAction } from "../../../../services/Store/Actions/fastagAction";
import LinkFastagReissueOTP from "../../../Modals/LinkFastagReissueOTP";
import {
  fleetEditStatusAction,
  sendAnalitics,
} from "../../../../services/Store/Actions/appActions";
import { advancedVehicleListAction } from "../../../../services/Store/Actions/fleetActions";
import ClipLoader from "react-spinners/ClipLoader";

function ManageVehicleDetailsCard(props) {
  const { color, setColor, activeStatus, assignedStatus } = props;
  const dispatch = useDispatch();
  const [fetchMoreData, setFetchMoreData] = useState(true);
  //const [offset, setOffset] = useState(0);
  const [serialNum, setSerialNum] = useState(0);
  const [displayName, setDisplayName] = useState([]);

  const { advancedVehicleList, advancedVehicleListCount, fleetListLoader } =
    useSelector((state) => state?.fleet);

  const navigate = useNavigate();
  console.log("Fleet list heree", advancedVehicleList);

  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState("");
  const [bank, setBank] = useState("YesBank");
  const [reissueData, setReissueData] = useState();

  // to handle success or failure of re-issue. If failed, then state=1, and chasis option available
  const [reIssue, setReIssue] = useState(0);

  function handleShow() {
    setOpen(true);
  }

  const handleCloseOpenLink = () => {
    setDisplayName(displayName.filter((e) => e.name === ""));
    props.setOpenLink(false);
  };
  const handletoggleOpenLink = () => {
    setDisplayName(displayName.filter((e) => e.name === ""));
    props.setOpenLink(!props.openLink);
  };
  const handlenShowOpenLink = (id, cat, regNo) => {
    dispatch(
      sendAnalitics("IssueFTButton_MV_FW_Web", {
        regNo: regNo,
        fleetId: id,
      })
    );
    props.setRegNumber(regNo);
    props.setFleetId(id);
    props.setVehCat(cat);
    props.setOpenLink(true);
  };
  console.log("jjjjjjjjjj", advancedVehicleList);

  const isReissueAvailable = useSelector(
    (state) => state?.fastag?.isReissueAvailable
  );
  const hotlistStatus = isReissueAvailable?.npciStatus;
  const reissueStatus = isReissueAvailable?.status;

  console.log("iiiiiiiiiiiiiiii", isReissueAvailable);
  // function handleClick(Id, i) {
  //   // window.location=`/Vehicles?u=${id}&r=${regNo}&v=${cat}`
  //   console.log("Yaha toh hoga hi bhaiii", window.location.href);
  //   console.log("id@@@", Id);
  //   navigate(`/VehiclesV2/${Id.fleetId}`);
  //   //window.location.href=Id.fleetId
  //   // setColor(i);
  // }

  const handleClick = (e, i) => {
    props.allowTrigger();
    props.setVehRank(props.offset + i + 1);
    console.log(
      "drivers ki list",
      `/VehiclesV2/${e.fleetId}?search=${props.search}&offset=${props.offset}`
    );

    navigate(
      `/VehiclesV2/${e.fleetId}?search=${props.search}&offset=${props.offset}`,
      { state: { vehicle: e } }
    );
  };

  let { id } = useParams();
  //console.log("ppp",id)

  useEffect(() => {
    //console.log("Yaha toh hoga hi bhaiii", window.location.href);
    let urlId = window.location.pathname.split("/");
    //console.log("peterrr",urlId)
    if (urlId.length === 3) {
      if (urlId[2] != undefined && urlId[2] != "") {
        let i = advancedVehicleList?.findIndex(
          (element) => element.fleetId === urlId[2]
        );
        //handleUserId(urlId[2]);
        //console.log("han ji",i)
        setColor(i);
        if (id) {
          if (props.regNumber) {
            dispatch(
              reissueAvailableAction({
                fleetId: id,
                vrn: props.regNumber,
              })
            );
          }
        }
      }
    }
  }, [id, advancedVehicleList]);

  console.log("offsettinggggggggggg", props.offset);

  const handlePrevButton = () => {
    //props.nextTrigger();
    if (props.offset > 0) {
      dispatch(sendAnalitics("LN_MV_FW_Web"));
      props.nextTrigger();
      props.setOffset(props.offset - 15);
      //setSerialNum(serialNum - 15);
      //console.log("offset", offset);
      // dispatch(
      //   getVehicleListAction({
      //     offset: props.offset - 15,
      //     limit: 15,
      //     search: props.search,
      //     minKyc: "",
      //     sortAs: "",
      //     sortBy: "",
      //   })
      // );
      dispatch(
        advancedVehicleListAction({
          offset: props.offset - 15,
          limit: 15,
          search: props.search,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          activeStatus: activeStatus,
          assignedStatus: assignedStatus,
        })
      );
    }
    console.log("X--");
  };

  const handleNextButton = () => {
    if (advancedVehicleListCount - props.offset > 15) {
      dispatch(sendAnalitics("RN_MV_FW_Web"));
      props.nextTrigger();
      props.setOffset(props.offset + 15);
      //setSerialNum(serialNum + 15);
      //console.log("offset", offset);
      // dispatch(
      //   getVehicleListAction({
      //     offset: props.offset + 15,
      //     limit: 15,
      //     search: props.search,
      //     minKyc: "",
      //     sortAs: "",
      //     sortBy: "",
      //   })
      // );
      dispatch(
        advancedVehicleListAction({
          offset: props.offset + 15,
          limit: 15,
          search: props.search,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          activeStatus: activeStatus,
          assignedStatus: assignedStatus,
        })
      );
    }
    console.log("X++");
  };

  // for handling otp modal

  const [openOTP, setOpenOTP] = useState(false);
  const handleCloseOTP = () => {
    setOpenOTP(false);
    //setProceed(0);
    //console.log("closed");
  };

  const toggleModalOTP = () => {
    setOpenOTP(!openOTP);
    //setProceed(0);
  };

  const handleShowOTP = () => {
    handletoggleOpenLink();
    setOpenOTP(true);
  };

  // for handling KYC` modal

  const [openKYC, setOpenKYC] = useState(false);
  const handleCloseKYC = () => {
    setOpenKYC(false);
    //setProceed(0);
    //console.log("closed");
  };

  const toggleModalKYC = () => {
    setOpenKYC(!openKYC);
    //setProceed(0);
  };

  const handleShowKYC = () => {
    toggleModalOTP();
    setOpenKYC(true);
  };

  // for handling fastag issue modal

  const [openIssue, setOpenIssue] = useState(false);
  const handleCloseIssue = () => {
    setOpenIssue(false);
    //setProceed(0);
    //console.log("closed");
  };

  const toggleModalIssue = () => {
    setOpenIssue(!openIssue);
    //setProceed(0);
  };

  const handleShowIssue = (kycStatus) => {
    if (kycStatus === "kyc done") {
      handletoggleOpenLink();
    } else {
      toggleModalKYC();
    }
    setOpenIssue(true);
  };

  // for handling reissue otp modal

  const [openReissue, setOpenReissue] = useState(false);
  const handleCloseReissue = () => {
    setOpenReissue(false);
    //setProceed(0);
    //console.log("closed");
  };

  const toggleModalReissue = () => {
    setOpenReissue(!openReissue);
    //setProceed(0);
  };

  const handleShowReissue = () => {
    toggleModalIssue();
    setOpenReissue(true);
  };

  return (
    <div className="main-all-customer-list">
      <div id="scrollableDivCustomer" className="div-table">
        {fleetListLoader ? (
          <div>
            <div
              className="text-center"
              style={{
                padding: 16,
              }}
            >
              <ClipLoader
                color={"#2E7C7B"}
                loading={true}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        ) : (
          <>
            {advancedVehicleList?.map((d, i) => {
              const regName =
                d?.driverName === undefined || d?.driverName === null
                  ? "No Driver"
                  : d?.driverName;
              console.log(";;;;;;;;;;;;;;;;;;;;;;", d);
              // const lowBalanceStatus =
              //   (d?.Fastag?.tagId !== "" &&
              //   d?.Fastag?.tagId !== null &&
              //   d?.Fastag?.tagDetails?.minBalAutoAlert >= d?.Fastag?.balance)
              //     ? true
              //     : false;

              const lowBalanceStatus = Number("100") > Number(d?.fastagBalance);
              // const lowBalanceStatus =
              //   Number(d?.Fastag?.autoRechargeAmount) > Number(d?.Fastag?.balance);
              // let color = " ";
              // let bal = data?.User?.WalletAccount?.balance;
              // let accountStatus = data?.User?.WalletAccount?.accountStatus;
              // if (accountStatus < 3 || accountStatus == undefined) {
              //   color = "red";
              // } else {
              //   color = "green";
              // }
              // console.log(bal);

              // let totalBalVal =
              //   data?.User?.WalletAccount?.accountStatus < 3
              //     ? "KYC Pending"
              //     : data?.User?.WalletAccount?.accountStatus == 3
              //     ? "Card Not Linked"
              //     : data?.User?.WalletAccount?.accountStatus == undefined
              //     ? "KYC Pending"
              //     : bal === null
              //     ? "₹ 0"
              //     : "₹" + bal;
              // const name = data.User?.fullName.split(" ");
              // const d = {
              //   firstName: name[0],
              //   lastName: name[name.length - 1],
              // };
              const firstName_initials = regName[0];
              // const lastName_initials = d.lastName[0] + ".";
              return (
                <div
                  className={
                    color === i
                      ? "div-table-row actve-button-chosen-for-vehicle"
                      : d?.isActive == 1
                      ? "div-table-row"
                      : "div-table-row disabledRow"
                  }
                  key={i}
                >
                  <div
                    className="rowContN flexStyling"
                    onClick={() => handleClick(d, i)}
                  >
                    <div class="div-table-col FontNBList ">
                      {/* <div data-letters={firstName_initials.toUpperCase()}> */}
                      <div className="flexStyling">
                        <div
                          data-letters-vehicle={props.offset + i + 1}
                          style={{ paddingTop: "5px" }}
                        ></div>
                        <div style={{ paddingLeft: "5px" }}>
                          <span className="FontNBList">
                            {d?.regNumber?.length > 10
                              ? d?.regNumber.substring(0, 8) + ".."
                              : d?.regNumber}
                          </span>
                          {/* <p className="FontNBList"> {regName}</p> */}
                          <div class=" FontSmall">{regName}</div>
                        </div>
                      </div>
                    </div>

                    {/* <div class="div-table-col col-lg-5 FontNBList right">
                      <>
                        {d?.fastagBalance == null ? (
                          <div
                            to=""
                            onClick={() =>
                              handlenShowOpenLink(
                                d?.fleetId,
                                d?.fleetCategory,
                                d?.regNumber
                              )
                            }
                            className="fastagLinkingN"
                            style={{ marginTop: "-4px" }}
                          >
                            Issue FASTag
                          </div>
                        ) : (
                          <p
                            style={{
                              color: lowBalanceStatus ? "#FE2950" : "#25252D",
                              justifyContent: "center",
                            }}
                          >
                            {"₹ " +
                              (d?.fastagBalance === "" ? 0 : d?.fastagBalance)}
                          </p>
                        )}
                      </>
                    </div> */}

                    {/* <div class="div-table-col col-lg-2">
                      {loader == d?.fleetId ? (
                        <div
                          className=""
                          style={{
                            padding: 6,
                          }}
                        >
                          <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{
                              // backgroundColor:
                              //   color === i
                              //     ? "#FFFFFF"
                              //     : d?.isActive == 1
                              //     ? "white"
                              //     : "#FFFFFF",
                              border: 1,
                              padding: 6,
                            }}
                            onClick={() => {
                              setLoader(d?.fleetId);
                              dispatch(
                                fleetEditStatusAction(
                                  {
                                    fleetId: d?.fleetId,
                                    isActive: d?.isActive == 0 ? "1" : "0",
                                  },
                                  (res) => {
                                    setLoader("");
                                    if (res.status == 200) {
                                      dispatch(
                                        advancedVehicleListAction({
                                          offset: props.offset,
                                          limit: 15,
                                          search: props.search,
                                          minKyc: "",
                                          sortAs: "",
                                          sortBy: "",
                                          activeStatus: activeStatus,
                                          assignedStatus: assignedStatus,
                                        })
                                      );
                                    }
                                  }
                                )
                              );
                            }}
                          >
                            
                            {d?.isActive == 1 ? (
                              <SvgImage name="deleteIcon2" />
                            ) : (
                              <SvgImage name="restore" />
                            )}
                          </div>
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="nextRow ">
        <div className="nextRowCont ">
          <button
            className="PrevButton"
            onClick={handlePrevButton}
            style={{ opacity: props.offset > 0 ? "1" : "0.5" }}
          >
            <SvgImage name="PrevArrow" heigth="12px" width="12px" />
          </button>
          <button
            className="nextButton"
            onClick={handleNextButton}
            style={{
              opacity:
                advancedVehicleListCount - props.offset > 15 ? "1" : "0.5",
            }}
          >
            <SvgImage name="NextArrow" heigth="12px" width="12px" />
          </button>
        </div>
      </div>
      <div>
        <LinkFastagHome
          open={props.openLink}
          toggleModal={handletoggleOpenLink}
          handleClose={handleCloseOpenLink}
          fleetId={props.fleetId}
          vehCat={props.vehCat}
          regNumber={props.regNumber}
          setDisplayName={setDisplayName}
          displayName={displayName}
          handleShowOTP={handleShowOTP}
          handleShowIssue={handleShowIssue}
          reIssue={reIssue}
          setBank={setBank}
        />
      </div>
      <div>
        <LinkFastagKycOtp
          handleClose={handleCloseOTP}
          open={openOTP}
          onHide={handleCloseOTP}
          toggleModal={toggleModalOTP}
          handleShowKYC={handleShowKYC}
        />
      </div>
      <div>
        <LinkFastagDocDetails
          handleClose={handleCloseKYC}
          open={openKYC}
          onHide={handleCloseKYC}
          toggleModal={toggleModalKYC}
          handleShowIssue={handleShowIssue}
        />
      </div>
      <div>
        <LinkFastagToAddedVehicle
          open={openIssue}
          toggleModal={toggleModalIssue}
          handleClose={handleCloseIssue}
          fleetId={props.fleetId}
          vehCat={props.vehCat}
          regNumber={props.regNumber}
          setDisplayName={setDisplayName}
          displayName={displayName}
          reissueStatus={reissueStatus}
          reIssue={reIssue}
          bank={bank}
          setReissueData={setReissueData}
          handleShowReissue={handleShowReissue}
        />
      </div>
      <div>
        <LinkFastagReissueOTP
          open={openReissue}
          toggleModal={toggleModalReissue}
          handleClose={handleCloseReissue}
          reissueData={reissueData}
          setReIssue={setReIssue}
        />
      </div>
    </div>
  );
  // return (
  //   <div id=" vehicleScroller" className="scrollVehicleList">
  //     {allFleets?.rows?.map((d, i) => {
  //       const regName =
  //         d?.FleetDriverMapHistories?.[0]?.Driver?.User?.fullName === undefined
  //           ? "No Driver"
  //           : d?.FleetDriverMapHistories?.[0]?.Driver?.User?.fullName;

  //       //console.log("--------####-------", regName);
  //       const lowBalanceStatus =
  //         d?.Fastag?.tagId !== "" &&
  //         d?.Fastag?.tagId !== null &&
  //         d?.Fastag?.tagDetails?.minBalAutoAlert >= d?.Fastag?.balance
  //           ? true
  //           : false;
  //       return (
  //         <div
  //           className={"row row-for-vehicle-list"}
  //           onClick={() => handleClick(d, i)}
  //           style={{ cursor: "pointer" }}
  //         >
  //           <div
  //             className={color === i ? " col-1 actve-button-chosen " : "col-1"}
  //           ></div>
  //           <div
  //             className={
  //               color === i
  //                 ? " col-11 row active-button-list active-div "
  //                 : "col-11 row active-div"
  //             }
  //             style={{ height: "74px", position: "relative", left: "-14px" }}
  //           >
  //             <div
  //               className="col-2"
  //               style={{ position: "relative", left: "-14px" }}
  //             >
  //               <div
  //                 className="profile-img-vehicle-list "
  //                 style={{ textAlign: "center" }}
  //               >
  //                 <span className="text-in-circle">
  //                   {/* {d?.regNumber?.substring(0, 1)} */}
  //                   {i + 1}
  //                 </span>
  //               </div>
  //             </div>
  //             <div
  //               className="col-6 row"
  //               style={{ position: "relative", left: "-26px", top: "22px" }}
  //             >
  //               <div className="col-12">
  //                 <p
  //                   className=" vehicle-number-in-vehicle-list"
  //                   style={{
  //                     position: "relative",
  //                     top: "4px",
  //                   }}
  //                 >
  //                   {d?.regNumber}
  //                 </p>
  //               </div>
  //               <div>
  //                 <p
  //                   className="vehicle-name-in-vehicle-list"
  //                   style={{ position: "relative", top: "-8px" }}
  //                 >
  //                   {regName}
  //                 </p>
  //               </div>
  //             </div>
  //             <div className="col-4" style={{ textAlignLast: "end" }}>
  //               {d?.Fastag?.balance == null ? (
  //                 <NavLink
  //                   style={{
  //                     position: "relative",
  //                     top: "24px",
  //                     left: "47px",
  //                   }}
  //                   to=""
  //                   className="edit-detail-button"
  //                   onClick={() =>
  //                     handlenShowOpenLink(
  //                       d?.fleetId,
  //                       d?.fleetCategory,
  //                       d?.regNumber
  //                     )
  //                   }
  //                 >
  //                   Link Fastag
  //                 </NavLink>
  //               ) : (
  //                 <div>
  //                   <p
  //                     style={{
  //                       position: "relative",
  //                       top: "24px",
  //                     }}
  //                     className={
  //                       lowBalanceStatus
  //                         ? "vehicle-list-fastag-balance low-balance-css"
  //                         : "vehicle-list-fastag-balance"
  //                     }
  //                   >
  //                     {"₹ " + d?.Fastag?.balance}
  //                   </p>
  //                 </div>
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     })}
  //     <LinkFastagToAddedVehicle
  //       open={openLink}
  //       toggleModal={handletoggleOpenLink}
  //       handleClose={handleCloseOpenLink}
  //       fleetId={fleetId}
  //       vehCat={vehCat}
  //       regNumber={regNumber}
  //       setDisplayName={setDisplayName}
  //       displayName={displayName}
  //     />
  //   </div>
  // );
}
export default ManageVehicleDetailsCard;
