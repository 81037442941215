import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, Form, Table } from "reactstrap";
import "./index.css";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { editTripExpenseAction } from "../../../../services/Store/Actions/TripsAction";
import Toast from "../../../subComponents/toast";
import { getVendorAction } from "../../../../services/Store/Actions/beneficiaryAction";
import SelectVendorModal from "../../../Vendor/Components/Modal/SelectVendorModal";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";
import StartTripModal from "../StartTripModal";
import { v4 as uuid } from "uuid";

function PaymentDetails(props) {
  const {
    toggle,
    modal,
    setModal,
    tripData,
    routeData,
    startTrip,
    setRouteData,
    setItemTemp,
    tripExpense,
    fuelVandor,
    fleetVendor,
  } = props;
  const [state, setState] = useState(0);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectVendorModal, setSelectVendorModal] = useState(false);
  const [vendorData, setVendorData] = useState({});
  const [openPinModal, setOpenPinModal] = useState(false);
  const [startTripModal, setStartTripModal] = useState(false);
  const [payStatus, setPayStatus] = useState("");
  const [status, setStatus] = useState(0);
  const [openToast, setOpenToast] = useState(false);
  const [openFailToast, setOpenFailToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [advancePayment, setAdvancePayment] = useState();
  function startTripModalToggle() {
    setStartTripModal(!startTripModal);
  }
  console.log("tripDtripDtripDataataata", tripExpense);
  useEffect(() => {
    console.log("tripDtripDtripDataataata111", tripExpense);
    let xxx = [
      {
        header: "Fuel",
        type:
          tripExpense?.fuelAdvanceMode == 4 ? "Vendor" : "Diesel Wallet" || "",
        vendor: tripExpense?.fuelAdvanceMode == 4 ? fuelVandor : null,
        expacted: tripExpense?.fuelTotal || routeData?.fuelExpected || "0",
        advance: tripExpense?.fuelAdvance || routeData?.fuelAdvance || "0",
      },
      {
        header: "Driver",
        type: "Card",
        vendor: {},
        expacted: tripExpense?.driverTotal || routeData?.driverExpected || "0",
        advance: tripExpense?.driverAdvance || "0",
      },
      {
        header: "Fastag",
        type: "Fastag",
        vendor: {},
        expacted: tripExpense?.fastagTotal || routeData?.fastagExpected || "0",
        advance: tripExpense?.fastagAdvance || routeData?.fastagExpected || "0",
      },
    ];
    if (fleetVendor?.id) {
      xxx.push({
        header: "Vendor",
        type: "Vendor",
        vendor: fleetVendor,
        expacted: tripExpense?.vendorTotal || routeData?.ownerExpected || "0",
        advance: tripExpense?.vendorAdvance || routeData?.ownerAdvance || "0",
      });
    }
    if (tripExpense?.cashData?.length > 0) {
      console.log(
        "tripExpense?.cashData22",
        xxx,
        tripExpense?.cashData?.length,
        tripExpense?.cashData
      );
      tripExpense?.cashData.map((e) => {
        e["payable"] =
          parseInt(e?.expacted || 0) - parseInt(e?.totalExpense || 0) || "0";
      });
      xxx = [...xxx, ...tripExpense?.cashData];
    } else {
      xxx.push({
        id: uuid(),
        header: "Cash",
        type: "",
        vendor: null,
        givenTo: "",
        expacted: "0",
        advance: "0",
        totalExpense: "0",
        payable: "0",
        key: "",
        expactedStatus: 0,
        advanceStatus: 0,
      });
      console.log(
        "tripExpense?.cashData11",
        xxx,
        tripExpense?.cashData?.length
      );
    }
    setAdvancePayment(xxx);
  }, [tripExpense, routeData]);
  console.log("routeadvancePaymentData", tripData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    setModal(!modal);
    setRouteData({});
    console.log("qweqwertetertreter tert re t re ter");
    setItemTemp(tripData);
    storeDataToAsyncStorage("row", tripData);
    navigate(`/trips/${tripData?.tripId}`);
  };
  const handleToggleModal = () => {
    setOpenPinModal(!openPinModal);
  };
  const handleCloseModal = () => {
    setOpenPinModal(false);
  };
  const paymentType = {
    Fuel: [{ value: "Vendor" }, { value: "Diesel Wallet" }],
    Driver: [{ value: "Card" }],
    Fastag: [{ value: "Fastag" }],
    Vendor: [{ value: "Vendor" }],
    Cash: [
      { value: "Food" },
      { value: "Service" },
      { value: "Fuel" },
      { value: "Salary" },
      { value: "Fastag" },
      { value: "Others" },
    ],
  };
  const givenTo = {
    Cash: [{ value: "Vendor" }, { value: "Driver" }],
  };
  let handleChangeField = (i, e) => {
    let newFormValues = [...advancePayment];
    newFormValues[i][e.target.name] = e.target.value;
    setAdvancePayment(newFormValues);
  };
  let addFormFields = () => {
    setAdvancePayment([
      ...advancePayment,
      {
        id: uuid(),
        header: "Cash",
        type: "",
        vendor: null,
        expacted: "0",
        advance: "0",
        key: "",
        payable: "0",
        totalExpense: "0",
        expactedStatus: 0,
        advanceStatus: 0,
      },
    ]);
  };
  let removeFormFields = (index) => {
    const newFormValues = advancePayment.filter((item, i) => {
      return index != i;
    });
    console.log("newFormValues", newFormValues);
    setAdvancePayment(newFormValues);
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width-2"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // scrollable={true}
        style={{ width: "100%" }}
        centered={true}
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px", width: "100%" }}
        >
          <div>
            <div className=/*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction">
              <div className="closeEffect" onClick={handleClose}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
            </div>
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Payment Details
            </div>
            <Formik
              // isValid='true'
              enableReinitialize
              // validationSchema={validationSchema}
              initialValues={{
                fuelWalletAmount: routeData?.fuelAdvance || "",
                expectedFuelLiter: routeData?.fuelExpected || "",
                fuelPaymentType: "Diesel Wallet",
                advanceDriver: routeData?.sDriverAdvance || "",
                driverSalary: routeData?.sDriverExpected || "",
                driverIncentive: "",
                driverPaymentType: "Salary",
                fastagAmount: routeData?.fastagAdvance || "",
                expactedFastagUses: routeData?.fastagExpected || "",
                vendorAmount: "",
                vendorIncentive: "",
                totalVendorExpanse: "",
                cashExpanceData: [
                  {
                    givenTo: "driver",
                    expanseType: "",
                    amount: routeData?.cashExpected || "",
                  },
                ],
              }}
              onSubmit={(values) => {
                // setState(1);

                console.log("onSubmitonSubmit", values);
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "20px", padding: "0 50px 0 50px" }}
                >
                  <Table
                    className="TripSectionTable"
                    bordered="true"
                    id="driver_tables" //
                    style={{ height: "30vh", marginTop: "20px" }}
                  >
                    <thead>
                      <tr className="table-header-trip p-0 align-middle text-center">
                        <th className="p-0" style={{ width: "15%" }}>
                          <div style={{ textAlignLast: "center" }}>For</div>{" "}
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "25%", textAlignLast: "center" }}
                        >
                          type
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "25%", textAlignLast: "center" }}
                        >
                          Vendor
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "25%", textAlignLast: "center" }}
                        >
                          Expected
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "25%", textAlignLast: "center" }}
                        >
                          Advance
                        </th>
                      </tr>
                    </thead>
                    <tbody className="lowerBorderLineCust">
                      {advancePayment?.map((items, index) => {
                        console.log("---------sdasdadadadasd--", items);
                        let type = items?.type;
                        return (
                          <tr
                            className="table-row-manage-trip-new text4 align-middle text-center p-0"
                            id="addr0"
                            key={index}
                            onClick={() => {
                              // window.location = "/Customers/" + 1;
                              //   navigate("/Customers/" + 1);
                            }}
                          >
                            <td
                              style={{
                                width: "15%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                            >
                              <div
                                className="FontMedium"
                                style={{ textAlignLast: "center" }}
                              >
                                {" "}
                                {items.header}
                              </div>
                            </td>
                            <td
                              style={{
                                width: "25%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                            >
                              <input
                                type="text"
                                className="minBox3"
                                name="type"
                                placeholder="Choose a type"
                                value={type || ""}
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedIndex(index);
                                }}
                                onChange={(e) => handleChangeField(index, e)}
                                autoComplete="off"
                              />
                              {open && selectedIndex == index ? (
                                <div
                                  className="paymentTypeSelect containerShadow"
                                  style={{ width: "200px" }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0.3rem",
                                      right: "0.3rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  >
                                    <SvgImage name="CloseButtonLogo" />
                                  </div>
                                  {paymentType[items.header]?.map((item, i) => {
                                    return (
                                      <>
                                        {/* {item?.Fastag !== null ? ( */}
                                        <div
                                          key={i}
                                          className="flexStyling padding8"
                                          onClick={(event) => {
                                            handleChangeField(index, event);
                                            setOpen(false);
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name="type"
                                            onChange={(e) =>
                                              handleChangeField(index, e)
                                            }
                                            value={item?.value}
                                            checked={item?.value == type}
                                            id={item?.value}
                                            autoComplete={"off"}
                                          />
                                          <div
                                            className="padding16L"
                                            value={item?.value}
                                          >
                                            {item?.value}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td
                              style={{
                                width: "25%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                            >
                              <div
                                className="d-flex"
                                style={{ alignItems: "center" }}
                              >
                                {items.header == "Cash" && type == "Vendor" ? (
                                  <div style={{ marginRight: "6px" }}>
                                    Vendor
                                  </div>
                                ) : null}

                                <input
                                  type="text"
                                  className="minBox3"
                                  name="vendorId"
                                  style={{
                                    width:
                                      items.header == "Cash" && type == "Vendor"
                                        ? "70%"
                                        : "90%",
                                  }}
                                  placeholder={
                                    type == "Vendor"
                                      ? "Pick a vendor"
                                      : "Not Required"
                                  }
                                  onClick={() => {
                                    setSelectVendorModal(true);
                                    setSelectedIndex(index);
                                  }}
                                  disabled={type == "Vendor" ? false : true}
                                  value={items?.vendor?.name || ""}
                                  // onChange={(e) => handleChangeField(index, e)}
                                />
                              </div>
                            </td>
                            <td
                              style={{
                                width: "25%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                            >
                              <input
                                type="number"
                                className="minBox3"
                                name="expacted"
                                placeholder="₹ 0"
                                value={items.expacted || ""}
                                onChange={(e) => handleChangeField(index, e)}
                              />
                            </td>
                            <td
                              style={{
                                width: "20%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                            >
                              <input
                                type="number"
                                className="minBox3"
                                name="advance"
                                placeholder="₹ 0"
                                value={items.advance || ""}
                                onChange={(e) => handleChangeField(index, e)}
                              />
                            </td>
                            {items.header == "Cash" ? (
                              <td
                                style={{
                                  width: "5%",
                                  borderLeft: "1px solid transparent",
                                  borderRight: " 1px solid transparent",
                                }}
                                className="p-0"
                                onClick={() => {
                                  removeFormFields(index);
                                }}
                              >
                                <SvgImage name="deleteIcon2" />
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                      <div className="d-flex justify-content-center">
                        <div
                          className="addItemDiv3"
                          // style={{zIndex:10 }}
                          onClick={addFormFields}
                        >
                          + Add Item For Cash
                        </div>
                      </div>
                    </tbody>
                  </Table>

                  {startTrip ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // gap: "25px",
                        margin: "20px",
                      }}
                    >
                      <Button
                        style={{ width: "30%" }}
                        onClick={() => {
                          // setToastMsg(
                          //   "Advance Payment Details Saved Successfully"
                          // );
                          // setOpenToast(true);
                          // handleClose();
                          console.log(
                            "advancePayment[3].expacted",
                            advancePayment,
                            advancePayment?.[3]?.expacted
                          );
                          let data = {
                            tripId: tripData?.tripId,
                            driverAdvance: advancePayment[1].advance || 0,
                            driverTotal: advancePayment[1].expacted || 0,
                            vendorAdvance: advancePayment[3].advance || 0,
                            vendorTotal: advancePayment[3].expacted || 0,
                            fuelAdvance: advancePayment[0].advance || 0,
                            fuelTotal: advancePayment[0].expacted || 0,
                            fastagTotal: advancePayment[2].expacted || 0,
                            cashTotal: fleetVendor?.id
                              ? advancePayment[4].expacted
                              : advancePayment[3].expacted || 0,
                            fuelAdvanceMode:
                              advancePayment[0].type == "Vendor" ? 4 : 3,
                            fuelVendorId:
                              advancePayment[0].type == "Vendor"
                                ? advancePayment[0]?.vendor?.id
                                : "",
                            // totalCardExpense: advancePayment[1].expacted || 0,
                            // totalCashExpense: advancePayment[4].expacted || 0,
                            // totalFuelExpense: advancePayment[0].expacted || 0,
                            // totalFastagExpense: advancePayment[2].expacted || 0,
                            // totalDriverExpense: advancePayment[1].expacted || 0,
                            cashData: advancePayment?.filter(
                              (item) => item.header == "Cash"
                            ),
                          };

                          dispatch(
                            editTripExpenseAction(data, (res) => {
                              console.log("editTripExpenseAction", res);
                              if (res.status == 200) {
                                setToastMsg(
                                  "Advance Payment Details saved successfully"
                                );
                                // handleClose();
                                setOpenToast(true);
                              }
                            })
                          );
                          // dispatch(createTripExpense(data, () => {}));
                        }}
                        className="greenOutlineButton text3 Medium btn btn-secondary"
                      >
                        Save Details
                      </Button>
                      {/* <Button
                        onClick={() => {
                          startTripModalToggle(true);
                          setPayStatus("1");
                          console.log("dsafgdsfgwer");
                        }}
                        style={{
                          width: "30%",
                          marginRight: "24px",
                          marginLeft: "24px",
                        }}
                        className="generateOtp text3 Medium btn btn-secondary"
                      >
                        Pay & Start Trip
                      </Button> */}
                      <Button
                        onClick={() => {
                          // setToastMsg("Trip Started Successfully");
                          // handleClose();
                          // setOpenToast(true);
                          // let data = {
                          //   tripId: tripData?.tripId,
                          //   driverAdvance: advancePayment[1].advance || 0,
                          //   driverTotal: advancePayment[1].expacted || 0,
                          //   vendorAdvance: advancePayment[3].advance || 0,
                          //   vendorTotal: advancePayment[3].expacted || 0,
                          //   fuelAdvance: advancePayment[0].advance || 0,
                          //   fuelTotal: advancePayment[0].expacted || 0,
                          //   fastagTotal: advancePayment[2].expacted || 0,
                          //   cashTotal: advancePayment[4].expacted || 0,
                          //   totalCardExpense: advancePayment[1].expacted || 0,
                          //   totalCashExpense: advancePayment[4].expacted || 0,
                          //   totalFuelExpense: advancePayment[0].expacted || 0,
                          //   totalFastagExpense: advancePayment[2].expacted || 0,
                          //   totalDriverExpense: advancePayment[1].expacted || 0,
                          //   cashData: advancePayment?.filter(
                          //     (item) => item.header == "Cash"
                          //   ),
                          // };
                          // dispatch(
                          //   editTripExpenseAction(data, (res) => {
                          //     console.log("editTripExpenseAction", res);
                          //     if (res.status == 200) {
                          //       let payload = {
                          //         tripId: tripData?.tripId,
                          //         startDate: new Date(),
                          //         pay: "0",
                          //       };
                          //       dispatch(
                          //         startTripExpenseAction(payload, (res) => {
                          //           console.log("asdfghewrwqertewr",res);
                          //           if (res?.status == 200) {
                          //             setOpenToast(true);
                          //             // handleClose();
                          //             setToastMsg("Trip Started Successfully");
                          //             handleCloseModal();
                          //           } else {
                          //             setOpenFailToast(true);
                          //             setToastMsg(
                          //               res?.data?.message ||
                          //                 "Error in Trip Start"
                          //             );
                          //           }
                          //         })
                          //       );
                          //     } else {
                          //       setOpenFailToast(true);
                          //       setToastMsg(
                          //         res?.data?.message || "Error Save Details"
                          //       );
                          //     }
                          //   })
                          // );
                          setStartTripModal(true);
                          setPayStatus("0");
                          // startTripFun();
                          // dispatch(createTripExpense(data, () => {}));
                        }}
                        style={{ width: "30%", marginLeft: "24px" }}
                        className="generateOtp text3 Medium btn btn-secondary"
                      >
                        Start Trip
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // gap: "25px",
                        margin: "20px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          console.log(
                            "asdasdasdasdasdasdasdas========",
                            openToast
                          );
                          let data = {
                            tripId: tripData?.tripId,
                            driverAdvance: advancePayment[1].advance || 0,
                            driverTotal: advancePayment[1].expacted || 0,
                            vendorAdvance: advancePayment[3].advance || 0,
                            vendorTotal: advancePayment[3].expacted || 0,
                            fuelAdvance: advancePayment[0].advance || 0,
                            fuelTotal: advancePayment[0].expacted || 0,
                            fastagTotal: advancePayment[2].expacted || 0,
                            cashTotal: fleetVendor?.id
                              ? advancePayment[4].expacted
                              : advancePayment[3].expacted || 0,
                            fuelAdvanceMode:
                              advancePayment[0].type == "Vendor" ? 4 : 3,
                            fuelVendorId:
                              advancePayment[0].type == "Vendor"
                                ? advancePayment[0]?.vendor?.id
                                : "",
                            cashData: advancePayment?.filter(
                              (item) => item.header == "Cash"
                            ),
                            // totalCardExpense: advancePayment[1].expacted || 0,
                            // totalCashExpense: advancePayment[4].expacted || 0,
                            // totalFuelExpense: advancePayment[0].expacted || 0,
                            // totalFastagExpense: advancePayment[2].expacted || 0,
                            // totalDriverExpense: advancePayment[1].expacted || 0,
                          };

                          dispatch(
                            editTripExpenseAction(data, (res) => {
                              console.log("editTripExpenseAction", res);
                              if (res.status == 200) {
                                setToastMsg(
                                  "Advance Payment Details saved successfully"
                                );
                                // handleClose();
                                setOpenToast(true);
                              }
                            })
                          );
                          // dispatch(createTripExpense(data, () => {}));
                        }}
                        style={{ width: "70%" }}
                        className="generateOtp text3 Medium btn btn-secondary"
                      >
                        Save Details
                      </Button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <SelectVendorModal
        open={selectVendorModal}
        onHide={() => {
          setSelectVendorModal(false);
        }}
        toggleModal={() => {
          setSelectVendorModal(!selectVendorModal);
        }}
        handleClose={() => {
          setSelectVendorModal(false);
        }}
        onClickItem={(data) => {
          setVendorData({});
          setVendorData(data);
          setSelectVendorModal(false);
          let newFormValues = [...advancePayment];
          newFormValues[selectedIndex]["vendor"] = { ...data };
          setSelectedIndex(0);
          setAdvancePayment(newFormValues);
        }}
        search={search}
        setSearch={setSearch}
      />

      <Toast
        success={true}
        message={toastMsg}
        isOpen={openToast}
        // name="trips"
        handleClose={() => {
          setOpenToast(false);
          handleClose();
          setRouteData({});
          setItemTemp(tripData);
          storeDataToAsyncStorage("row", tripData);
          navigate(`/trips/${tripData?.tripId}`);
        }}
      />
      <Toast
        success={false}
        message={toastMsg}
        isOpen={openFailToast}
        // name="trips"
        handleClose={() => {
          setOpenFailToast(false);
          // handleClose();
          // setRouteData({});
          // storeDataToAsyncStorage("row", tripData);
          // navigate(`/trips/${tripData?.tripId}`);
        }}
      />
      <StartTripModal
        handleCloseAdvance={handleClose}
        toggle={startTripModalToggle}
        modal={startTripModal}
        setModal={setStartTripModal}
        tripData={tripData}
        advancePaymentData={advancePayment}
        status={payStatus}
        setItemTemp={setItemTemp}
        vendorId={fleetVendor?.id}
      />
    </div>
  );
}

export default PaymentDetails;
