import "./index.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { Button } from "reactstrap";
import { getOwnerCardDetails, ownerCardAction } from "../../../../services/Store/Actions/manageCardActions";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";

const TransferAmount = (props) => {
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state?.loginReducer);

  const { managerControlList } = useSelector((state) => state?.userManagement);
  const data1 = [
    {
      svgName: "HomeFastag1",
      title: "FASTag Wallet",
      type:'fastag'
    },
    {
      svgName: "HomeBankTransfer",
      title: "Vendors",
      type:'masterAccount'
    },
  ];
  const data2 = [
    {
      svgName: "HomePersonalCard",
      title: "Personal Card",
      type:'wallet'
    },
    {
      svgName: "homeDriverCard",
      title: "Driver Card",
      type:'wallet'
    },
  ];

  const cardStatus1 = useSelector(
    (state) => state?.manageCard?.ownerCardType
  );
    const neocredStatus = cardStatus1?.[0]?.[0]?.accountStatus;
    const m2pStatus = cardStatus1?.[1]?.[0]?.accountStatus;
    //0:none, 1:neocred, 2:m2p
    const cardType= m2pStatus>=1?"2":(neocredStatus==4?"1":(neocredStatus==7?"1":"0"));
  console.log("7777777777",cardStatus1);
  console.log("7777777777aaaaaaaaaa",neocredStatus);
  console.log("7777777777ssssssssss",m2pStatus);

  // const cardStatus = useSelector(
  //   (state) => state?.manageCard?.OwnercardDetails?.accountStatus
  // );
  //console.log(",,,,,,,,,,", cardStatus);
  const value = neocredStatus == 4 || neocredStatus==7 || m2pStatus>0? true : false;

  
  useEffect(() => {
    //dispatch(getOwnerCardDetails(props.userId));
    dispatch(ownerCardAction());
    
  }, []);

  useEffect(()=>{
    props.setOwnerCardType(cardType);
  },[cardType])
  const homeAllDrivers = useSelector((state) => state?.driverlist?.homeAllDrivers);
  const driversCount = homeAllDrivers?.divers?.count;



  return (
    <>
      <div className="heading2 Bold padding16L">Transfer amount to:</div>
      <div className="padding8" style={{paddingTop:"25px"}}>
        <div className="row">
          {data1?.map((item) => {
            return (
              <div className="col-6 textCenter"  disabled={authUser?.user?.type==3 && !managerControlList?.[item?.type]}>
                <div className="">
                  <Button
                    className="transferButton pointing"
                    onClick={() => {
                      if(item?.title==="FASTag Wallet")
                        dispatch(sendAnalitics("Trns_FT_MA_Web"));
                      else{
                        dispatch(sendAnalitics("Trns_BA_MA_Web"));
                      }
                      props.onClickFunc(item?.title);
                    }}
                  >
                    <SvgImage name={item?.svgName} />
                  </Button>
                </div>
                <div className="headingBottom Regular" style={{paddingTop:"7px"}}>
                  {item?.title}
                </div>
              </div>
            );
          })}
        </div>
        <div className="row">
          {data2?.map((item) => {
            return (
              <div className="padding16 padding0B col-6 textCenter">
                <div className="">
                    {
                        item?.title === "Personal Card" ?
                        ( 
                        value ? (
                            <Button
                              className="transferButton pointing"
                              onClick={() => {
                                props.clickFunc(item?.title);
                              }}
                            >
                              <SvgImage name={item?.svgName} />
                            </Button>
                          ) :
                          <Button
                            className="transferButton"
                            style={{ opacity: "0.5", cursor: "default" }}
                            >
                            <SvgImage name={item?.svgName} />
                            </Button>
                        ):
                        (
                          driversCount===undefined?
                            <Button
                            className="transferButton"
                            style={{ opacity: "0.5", cursor: "default" }}
                            >
                            <SvgImage name={item?.svgName} />
                            </Button>
                            :
                            <Button
                            className="transferButton pointing"
                            onClick={() => {
                              props.clickFunc(item?.title);
                            }}
                          >
                            <SvgImage name={item?.svgName} />
                          </Button>

                        )
                    }
                  {/* {value ? (
                    <Button
                      className="transferButton pointing"
                      onClick={() => {
                        props.clickFunc(item?.title);
                      }}
                    >
                      <SvgImage name={item?.svgName} />
                    </Button>
                  ) : item?.title === "Personal Card" ? (
                    <Button
                      className="transferButton"
                      style={{ opacity: "0.5", cursor: "default" }}
                    >
                      <SvgImage name={item?.svgName} />
                    </Button>
                  ) : (
                    <Button
                      className="transferButton pointing"
                      onClick={() => {
                        props.clickFunc(item?.title);
                      }}
                    >
                      <SvgImage name={item?.svgName} />
                    </Button>
                  )} */}
                </div>
                <div className="headingBottom Regular" style={{paddingTop:"7px"}}>
                  {item?.title}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TransferAmount;
