import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import AddFreight from "../AddFreight";
import { getVehicleListAction } from "../../../../services/Store/Actions/manageVehicleAction";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import {
  createTrip,
  getCityAndState,
  getSavedAddress,
  updateTrip,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
import AddDriverV2 from "../AddDriverV2";
import AddVehicleV2 from "../AddVehicleV2";
import Toast from "../../../subComponents/toast";
import AddAddress from "../AddAddress";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";

function EditTripV2(props) {
  const { toggle, modal, setModal, tripData, setItemTemp, tripExpense } = props;
  const [openToast, setOpenToast] = useState(false);
  const [openToastFailure, setOpenToastFailure] = useState(false);
  const [openToastFailureText, setOpenToastFailureText] = useState(
    "Error in creating trips"
  );
  const [tripIdTemp, setTripIdTemp] = useState("");
  console.log("tripData", tripData);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [freight, setFreight] = useState(false);
  const [driver, setdriver] = useState(false);
  const [vehicle, setVehicle] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const addDriver = () => setdriver(!driver);
  const addVehicle = () => setVehicle(!vehicle);
  const addAddress = () => setAddressModal(!addressModal);
  const dispatch = useDispatch();
  const datepickerRefEnd = useRef();
  const datepickerRefStart = useRef();
  const datepickerRefEnd2 = useRef();
  const datepickerRefStart2 = useRef();
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [searchDriver, setSearchDriver] = useState("");
  const { savedAddresses, searchAddresses } = useSelector(
    (state) => state?.trip
  );
  const [input, setInput] = useState("");
  const [cityId1, setcityId1] = useState("");
  const [cityId2, setcityId2] = useState("");
  const [sourceName, setSourceName] = useState("");
  const [destName, setDestName] = useState("");
  const [cityList, setCityList] = useState({});
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [color1, setColor1] = useState(-1);
  const [color2, setColor2] = useState(-1);

  const driverList = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.rows
  );
  const driverCount = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.count
  );
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    console.log("asdsadsadas");
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClickDatepickerIconEx2 = () => {
    const datepickerElement = datepickerRefEnd2.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClickDatepickerIconEx1 = () => {
    const datepickerElement = datepickerRefStart2.current;
    console.log("asdsadsadas");
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClose = () => {
    setModal(!modal);
  };
  useEffect(() => {
    dispatch(
      getVehicleListAction({
        offset: "",
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [search]);
  useEffect(() => {
    dispatch(
      getDriverList({
        offset: "",
        search: searchDriver,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [searchDriver]);
  useEffect(() => {
    if (display1 == true || display2 == true) {
      // dispatch(findTripAddressV2({},(res)=>{
      //   console.log("findTripAddressV2",res);
      // }))
      dispatch(
        getSavedAddress({}, (res) => {
          console.log("getSavedAddress", res);
        })
      );
    }
  }, [display1, display2]);
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className="modal-dialog-centered model-width-2"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        scrollable={true}
        centered={true}
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px" }}
        >
          <div className="PGCollectionBox">
            <div className=/*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction">
              <div className="closeEffect" onClick={handleClose}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
            </div>
            <div>
              <div className="PGCollectiontText" style={{ marginLeft: "50px" }}>
                Edit Trip
              </div>
              <div className="heading3 semiBold" style={{ marginLeft: "50px" }}>
                Trip No - {tripData?.tripNo}
              </div>
            </div>

            <Formik
              // isValid='true'
              enableReinitialize
              // validationSchema={validationSchema}
              initialValues={{
                source: tripData?.sourceAddress?.addressId
                  ? tripData?.sourceAddress?.address +
                    ", " +
                    tripData?.sourceAddress?.cityName
                  : "",
                destination: tripData?.destAddress?.addressId
                  ? tripData?.destAddress?.address +
                    ", " +
                    tripData?.destAddress?.cityName
                  : "",
                startDate: tripData?.startTime || "",
                endDate: tripData?.endTime || "",
                LRNumber: "",
                tripNo: tripData?.tripNo || "",
                vrn: tripData?.Fleet?.regNumber || "",
                driver: tripData?.User?.fullName || "",
                fleetId: tripData?.fleetId || "",
                userId: tripData?.userId || "",
                sourceId: tripData?.sourceAddress?.addressId
                  ? tripData?.sourceAddress?.addressId
                  : "",
                destId: tripData?.destAddress?.addressId
                  ? tripData?.destAddress?.addressId
                  : "",
                expactedFuelExpanse: tripData?.TripExpense?.fuelTotal || "",
                expactedDriverExpanse: tripData?.TripExpense?.driverTotal || "",
                expactedFastagExpanse: tripData?.TripExpense?.fastagTotal || "",
                expactedVendorExpanse: tripData?.TripExpense?.vendorTotal || "",
                expectedDistanceKM: tripData?.expectedDistanceKM || "",
                endKmReading: tripData?.endKmReading || "",
                expectedEndTime: tripData?.expectedEndTime || "",
                expectedStartTime: tripData?.expectedStartTime || "",
                startKmReading: tripData?.startKmReading || "",
              }}
              onSubmit={(values) => {
                let objVal = {
                  // userIdForIM: "any",
                  tripId: props?.tripId,
                  source: values?.sourceId,
                  destination: values?.destId,
                  fleetId: values?.fleetId,
                  userId: values?.userId,
                  startTime: values.startDate,
                  tripNo: values?.tripNo ? values?.tripNo : "",
                  expectedDistanceKM: values?.expectedDistanceKM || "",
                  expectedEndTime: values?.expectedEndTime || "",
                  expectedStartTime: values?.expectedStartTime || "",
                  endKmReading: values?.endKmReading || "",
                  startKmReading: values?.startKmReading || "",
                };

                if (values?.endDate != "") {
                  objVal["endTime"] = values.endDate;
                }
                dispatch(
                  updateTrip(objVal, (res) => {
                    if (res?.status == 200) {
                      setOpenToast(true);
                      setTripIdTemp(res?.data?.data?.tripId);
                      setItemTemp(res?.data?.data);
                      storeDataToAsyncStorage("row", res?.data?.data);
                      console.log("...............", res);
                    } else {
                      setOpenToastFailure(true);
                      setOpenToastFailureText(res?.data?.message);
                    }
                  })
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "20px", padding: "0 75px 0 50px" }}
                >
                  {/* <FormGroup>
                    <label for="vrn" className="">
                      Trip No:
                    </label>
                    <input
                      readOnly
                      type="text"
                      id="tripNo"
                      className={`form-control-1 ${
                        touched.amount && errors.amount ? "is-invalid" : ""
                      }`}
                      name="tripNo"
                      onChange={(event) => {
                        // setSearch(event.target.value);
                        setFieldValue("tripNo", event.target.value, true);
                      }}
                      value={values.tripNo}
                      touched={touched.tripNo}
                      errors={errors.tripNo}
                      placeholder="TR-12345"
                    />
                  </FormGroup> */}
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "30px",
                    }}
                  >
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Vehicle No:
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          readOnly
                          className={`form-control-1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          type="text"
                          id="vrn"
                          name="vrn"
                          touched={touched.vrn}
                          errors={errors.vrn}
                          value={values.vrn}
                          autoFocus
                          placeholder="Select Vehicle"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Driver:
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                        onClick={() => {
                          setOpen1(true);
                        }}
                      >
                        <input
                          className={`form-control-1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          type="text"
                          id="driver"
                          name="driver"
                          touched={touched.driver}
                          errors={errors.driver}
                          onBlur={() => {
                            handleBlur("driver");
                            // setOpen1(false);
                          }}
                          onChange={(event) => {
                            setSearchDriver(event.target.value);
                            setFieldValue("driver", event.target.value, true);
                          }}
                          value={values.driver}
                          autoFocus
                          placeholder="Select Driver"
                        />
                        <span
                          id="addDriver"
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={addDriver}
                        >
                          + Add Driver
                        </span>
                      </div>

                      <div style={{ position: "relative" }}>
                        {open1 ? (
                          <div className="driverSearchContainer containerShadow">
                            <div
                              style={{
                                position: "absolute",
                                top: "0.3rem",
                                right: "0.3rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpen1(false);
                              }}
                            >
                              <SvgImage name="CloseButtonLogo" />
                            </div>
                            {driverList?.map((item, i) => {
                              return (
                                <>
                                  <div
                                    key={i}
                                    className="flexStyling padding8 "
                                    onClick={(event) => {
                                      setOpen1(false);
                                      setFieldValue(
                                        "driver",
                                        item?.User?.fullName,
                                        true
                                      );
                                      console.log("itemadas", item);
                                      setFieldValue(
                                        "userId",
                                        item?.User?.userId,
                                        true
                                      );
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name="driver"
                                      value={item?.User?.userId}
                                      id={item?.User?.userId}
                                    />
                                    <div className="padding16L">
                                      {item?.User?.fullName.length > 35
                                        ? item?.User?.fullName.split(0, 35) +
                                          "..."
                                        : item?.User?.fullName}
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                            {driverCount == 0 ? (
                              <div className="heading3 semiBold padding16 padding24B">
                                No driver Available
                              </div>
                            ) : driverCount == undefined ? (
                              <div className="heading3 semiBold padding16 padding24B">
                                No driver Available
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.userId && touched.userId ? (
                            <div>*{errors.userId}</div>
                          ) : null}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label for="from" className="heading3 semiBold">
                        From
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          type="text"
                          name="source"
                          id="source"
                          placeholder={"Source City"}
                          className={`form-control-1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          aria-label="Default select example"
                          onChange={(e) => {
                            setDisplay1(true);
                            setSearch1(e.target.value);
                            setFieldValue("source", e.target.value, true);
                          }}
                          onClick={() => {
                            setDisplay1(true);
                          }}
                          value={values.source}
                          autoComplete="off"
                          onBlur={handleBlur("source")}
                          errors={errors.source}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              // findSearch1();
                            }
                          }}
                        ></input>
                        <span
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={addAddress}
                        >
                          + Add Address
                        </span>
                      </div>
                      {display1 ? (
                        <div
                          className="padding16 newCashback whiteBackground"
                          style={{
                            position: "absolute",
                            width: "350px",
                            height: "275px",
                            zIndex: "90",
                          }}
                        >
                          <div
                            onClick={() => {
                              setDisplay1(false);
                              // setSearch1("");
                              // //dispatch(clearAllDriversByVrn());
                              // setFieldValue("source", "", true);
                              // setFieldValue("sourceId", "", true);
                            }}
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            <SvgImage name="CloseButtonLogo" />
                          </div>
                          <div
                            style={{
                              overflowY: "scroll",
                              overflowX: "hidden",
                              height: "240px",
                            }}
                          >
                            {console.log(
                              "savedAddresses11111",
                              search1,
                              savedAddresses
                            )}
                            {search1 == "" ? (
                              savedAddresses?.map((data, i) => {
                                let city = data?.cityName;
                                let state = data?.stateName;
                                let address = data?.address;
                                console.log(
                                  "savedAddresses",
                                  city,
                                  state,
                                  address
                                );
                                return (
                                  <div
                                    key={i}
                                    className="padding8 row"
                                    onClick={() => {
                                      // setcityId1(data?.cityName);
                                      setColor1(i);
                                      setDisplay1(false);
                                      //setUserId(data?.User?.userId);
                                      setFieldValue(
                                        "sourceId",
                                        data?.addressId,
                                        true
                                      );
                                      setFieldValue(
                                        "source",
                                        `${address}, ${city} - ${state}`,
                                        true
                                      );
                                    }}
                                  >
                                    {color1 === i ? (
                                      <div className="col-1">
                                        <SvgImage name="SelectedRadio" />
                                      </div>
                                    ) : (
                                      <div className="col-1">
                                        <SvgImage name="UnselectedRadio" />
                                      </div>
                                    )}
                                    {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                    <div
                                      className="col-11"
                                      style={{ textAlign: "left" }}
                                    >
                                      {address}, {city} - {state}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                {savedAddresses?.map((data, i) => {
                                  let city = data?.cityName;
                                  let state = data?.stateName;
                                  let address = data?.address;
                                  console.log(
                                    "savedAddresses",
                                    city,
                                    state,
                                    address
                                  );
                                  return (
                                    <div
                                      key={i}
                                      className="padding8 row"
                                      onClick={() => {
                                        // setcityId1(data?.cityName);
                                        setColor1(i);
                                        setDisplay1(false);
                                        //setUserId(data?.User?.userId);
                                        setFieldValue(
                                          "sourceId",
                                          data?.addressId,
                                          true
                                        );
                                        setFieldValue(
                                          "source",
                                          `${address}, ${city} - ${state}`,
                                          true
                                        );
                                      }}
                                    >
                                      {color1 === i ? (
                                        <div className="col-1">
                                          <SvgImage name="SelectedRadio" />
                                        </div>
                                      ) : (
                                        <div className="col-1">
                                          <SvgImage name="UnselectedRadio" />
                                        </div>
                                      )}
                                      <div
                                        className="col-11"
                                        style={{ textAlign: "left" }}
                                      >
                                        {address}, {city} - {state}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                            {savedAddresses?.length == 0 ? (
                              <div className="">
                                <div style={{ margin: "24px" }}>
                                  You have no addresses to select please add a
                                  new one
                                </div>
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="greenButton d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "200px",
                                      cursor: "pointer",
                                    }}
                                    onClick={addAddress}
                                  >
                                    <div>Add New Address</div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.source && touched.source ? (
                          <div>*{errors.source}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginRight: "110px",
                    }}
                  >
                    <FormGroup style={{}}>
                      <label for="to" className="heading3 semiBold">
                        To
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          type="text"
                          name="destination"
                          id="destination"
                          placeholder={"Destination City"}
                          className={`form-control-1 ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                          aria-label="Default select example"
                          onChange={(e) => {
                            setDisplay2(true);
                            setSearch2(e.target.value);
                            setFieldValue("destination", e.target.value, true);
                          }}
                          onClick={() => {
                            setDisplay2(true);
                          }}
                          value={values.destination}
                          autoComplete="off"
                          onBlur={handleBlur("destination")}
                          errors={errors.destination}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              // findSearch2();
                            }
                          }}
                        ></input>
                        <span
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={addAddress}
                        >
                          + Add Address
                        </span>
                      </div>
                      {display2 ? (
                        <div
                          className="padding16 newCashback whiteBackground"
                          style={{
                            position: "absolute",
                            width: "350px",
                            height: "275px",
                            zIndex: "90",
                          }}
                        >
                          <div
                            onClick={() => {
                              setDisplay2(false);
                              // setSearch2("");
                              // //dispatch(clearAllDriversByVrn());
                              // setFieldValue("destination", "", true);
                            }}
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            <SvgImage name="CloseButtonLogo" />
                          </div>
                          <div
                            style={{
                              overflowY: "scroll",
                              overflowX: "hidden",
                              height: "240px",
                            }}
                          >
                            {search2 == "" ? (
                              savedAddresses?.map((data, i) => {
                                let city = data?.cityName;
                                let state = data?.stateName;
                                let address = data?.address;
                                return (
                                  <div
                                    key={i}
                                    className="padding8 row"
                                    onClick={() => {
                                      // setcityId2(data?.cityName);
                                      setColor2(i);
                                      setDisplay2(false);
                                      //setUserId(data?.User?.userId);
                                      setFieldValue(
                                        "destination",
                                        `${address}, ${city} - ${state}`,
                                        true
                                      );
                                      setFieldValue(
                                        "destId",
                                        data?.addressId,
                                        true
                                      );
                                    }}
                                  >
                                    {color2 === i ? (
                                      <div className="col-1">
                                        <SvgImage name="SelectedRadio" />
                                      </div>
                                    ) : (
                                      <div className="col-1">
                                        <SvgImage name="UnselectedRadio" />
                                      </div>
                                    )}
                                    {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                    <div
                                      className="col-11"
                                      style={{ textAlign: "left" }}
                                    >
                                      {address}, {city} - {state}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                {savedAddresses?.map((data, i) => {
                                  let city = data?.cityName;
                                  let state = data?.stateName;
                                  let address = data?.address;
                                  console.log(
                                    "savedAddresses",
                                    city,
                                    state,
                                    address
                                  );
                                  return (
                                    <div
                                      key={i}
                                      className="padding8 row"
                                      onClick={() => {
                                        // setcityId1(data?.cityName);
                                        setColor2(i);
                                        setDisplay2(false);
                                        //setUserId(data?.User?.userId);
                                        setFieldValue(
                                          "destId",
                                          data?.addressId,
                                          true
                                        );
                                        setFieldValue(
                                          "destination",
                                          `${address}, ${city} - ${state}`,
                                          true
                                        );
                                      }}
                                    >
                                      {color2 === i ? (
                                        <div className="col-1">
                                          <SvgImage name="SelectedRadio" />
                                        </div>
                                      ) : (
                                        <div className="col-1">
                                          <SvgImage name="UnselectedRadio" />
                                        </div>
                                      )}
                                      <div
                                        className="col-11"
                                        style={{ textAlign: "left" }}
                                      >
                                        {address}, {city} - {state}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                            {savedAddresses?.length == 0 ? (
                              <div className="">
                                <div style={{ margin: "24px" }}>
                                  You have no addresses to select please add a
                                  new one
                                </div>
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="greenButton d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "200px",
                                      cursor: "pointer",
                                    }}
                                    onClick={addAddress}
                                  >
                                    <div>Add New Address</div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.destination && touched.destination ? (
                          <div>*{errors.destination}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Expected Start Date
                      </label>
                      <div className="searchBox" style={{ width: "200px" }}>
                        <DatePickerField
                          showTimeInput={true}
                          classname="searchBar-input"
                          name="expectedStartTime"
                          header="Select Start date"
                          value={values.expectedStartTime}
                          onChange={(v) => {
                            setFieldValue("expectedStartTime", v, true);
                          }}
                          //onChange={(v) => setFieldValue("endDate", v, true)}
                          // minDate={
                          //   values.expectedStartTime === ""
                          //     ? ""
                          //     : values.expectedStartTime
                          // }
                          // maxDate={new Date()}
                          ref1={datepickerRefStart}
                          style={{ width: "100px" }}
                        />
                        <label
                          className="date-input"
                          for="expectedStartTime"
                          style={{
                            position: "relative",
                            top: "-5px",
                            left: "-2rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleClickDatepickerIcon1();
                          }}
                        >
                          <SvgImage
                            name="CalenderLogo"
                            width="32"
                            height="32"
                            // classname="date-logo"
                          />
                        </label>
                      </div>
                      <div
                        className="heading4 Regular"
                        style={{ margin: "7px 0 0px 0", color: "red" }}
                      >
                        {errors.startDate && touched.startDate ? (
                          <div>*{errors.startDate}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Expected End Date
                      </label>
                      <div className="searchBox" style={{ width: "200px" }}>
                        <DatePickerField
                          showTimeInput={true}
                          classname="searchBar-input"
                          name="expectedEndTime"
                          header="Select End date"
                          value={values.expectedEndTime}
                          onChange={(v) => {
                            setFieldValue("expectedEndTime", v, true);
                          }}
                          //onChange={(v) => setFieldValue("expectedEndTime", v, true)}
                          minDate={
                            values.expectedEndTime === ""
                              ? ""
                              : values.expectedEndTime
                          }
                          // maxDate={new Date()}
                          ref1={datepickerRefEnd}
                          style={{ width: "100px" }}
                        />
                        <label
                          className="date-input"
                          for="expectedEndTime"
                          style={{
                            position: "relative",
                            top: "-5px",
                            left: "-2rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleClickDatepickerIcon2();
                          }}
                        >
                          <SvgImage
                            name="CalenderLogo"
                            width="32"
                            height="32"
                            // classname="date-logo"
                          />
                        </label>
                      </div>
                      <div
                        className="heading4 Regular"
                        style={{ margin: "7px 0 0px 0", color: "red" }}
                      >
                        {errors.endDate && touched.endDate ? (
                          <div>*{errors.endDate}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginRight: "110px",
                    }}
                  >
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        Start Date
                      </label>
                      <div className="searchBox" style={{ width: "200px" }}>
                        <DatePickerField
                          classname="searchBar-input"
                          name="startDate"
                          showTimeInput={true}
                          header="Select Start date"
                          value={values.startDate}
                          onChange={(v) => {
                            setFieldValue("startDate", v, true);
                          }}
                          //onChange={(v) => setFieldValue("endDate", v, true)}
                          // minDate={
                          //   values.startDate === "" ? "" : values.startDate
                          // }
                          // maxDate={new Date()}
                          ref1={datepickerRefStart2}
                          style={{ width: "100px" }}
                        />
                        <label
                          className="date-input"
                          for="startDate"
                          style={{
                            position: "relative",
                            top: "-5px",
                            left: "-2rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleClickDatepickerIconEx1();
                          }}
                        >
                          <SvgImage
                            name="CalenderLogo"
                            width="32"
                            height="32"
                            // classname="date-logo"
                          />
                        </label>
                      </div>
                      <div
                        className="heading4 Regular"
                        style={{ margin: "7px 0 0px 0", color: "red" }}
                      >
                        {errors.startDate && touched.startDate ? (
                          <div>*{errors.startDate}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label for="vrn" className="heading3 semiBold">
                        End Date
                      </label>
                      <div className="searchBox" style={{ width: "200px" }}>
                        <DatePickerField
                          classname="searchBar-input"
                          name="endtDate"
                          showTimeInput={true}
                          header="Select End date"
                          value={values.endDate}
                          onChange={(v) => {
                            setFieldValue("endDate", v, true);
                          }}
                          //onChange={(v) => setFieldValue("endDate", v, true)}
                          minDate={values.endDate === "" ? "" : values.endDate}
                          // maxDate={new Date()}
                          ref1={datepickerRefEnd2}
                          style={{ width: "100px" }}
                        />
                        <label
                          className="date-input"
                          for="endDate"
                          style={{
                            position: "relative",
                            top: "-5px",
                            left: "-2rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleClickDatepickerIconEx2();
                          }}
                        >
                          <SvgImage
                            name="CalenderLogo"
                            width="32"
                            height="32"
                            // classname="date-logo"
                          />
                        </label>
                      </div>
                      <div
                        className="heading4 Regular"
                        style={{ margin: "7px 0 0px 0", color: "red" }}
                      >
                        {errors.endDate && touched.endDate ? (
                          <div>*{errors.endDate}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginRight: "110px",
                    }}
                  >
                    <FormGroup>
                      <label for="startKmReading" className="">
                        Start Km Reading
                      </label>
                      <input
                        type="number"
                        id="startKmReading"
                        className={`form-control-1 ${
                          touched.startKmReading && errors.startKmReading
                            ? "is-invalid"
                            : ""
                        }`}
                        name="startKmReading"
                        onChange={(event) => {
                          // setSearch(event.target.value);
                          setFieldValue(
                            "startKmReading",
                            event.target.value,
                            true
                          );
                        }}
                        value={values.startKmReading}
                        touched={touched.startKmReading}
                        errors={errors.startKmReading}
                        placeholder="000000"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label for="endKmReading" className="">
                        End Km Reading
                      </label>
                      <input
                        type="number"
                        id="endKmReading"
                        className={`form-control-1 ${
                          touched.endKmReading && errors.endKmReading
                            ? "is-invalid"
                            : ""
                        }`}
                        name="endKmReading"
                        onChange={(event) => {
                          // setSearch(event.target.value);
                          setFieldValue(
                            "endKmReading",
                            event.target.value,
                            true
                          );
                        }}
                        value={values.endKmReading}
                        touched={touched.endKmReading}
                        errors={errors.endKmReading}
                        placeholder="000000"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label for="expectedDistanceKM" className="">
                        Expected Distance KM
                      </label>
                      <input
                        type="number"
                        id="expectedDistanceKM"
                        className={`form-control-1 ${
                          touched.expectedDistanceKM &&
                          errors.expectedDistanceKM
                            ? "is-invalid"
                            : ""
                        }`}
                        name="expectedDistanceKM"
                        onChange={(event) => {
                          // setSearch(event.target.value);
                          setFieldValue(
                            "expectedDistanceKM",
                            event.target.value,
                            true
                          );
                        }}
                        value={values.expectedDistanceKM}
                        touched={touched.expectedDistanceKM}
                        errors={errors.expectedDistanceKM}
                        placeholder="000"
                      />
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "40px",
                    }}
                  >
                    <FormGroup
                      style={{ marginBottom: 0 }}
                      className="adjustingMargin"
                    >
                      {/* <Button
                    
                      style={{ width: "25%" }}
                      className="notGenerateOpt text4 Medium btn btn-secondary"
                    >
                      Reset
                    </Button> */}
                      <Button
                        type="submit"
                        style={{ width: "200px", marginBottom: 0 }}
                        className="generateOtp text3 Medium btn btn-secondary"
                        // onClick={addFreight}
                      >
                        Submit
                      </Button>
                    </FormGroup>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <Toast
        success={openToast}
        message={"Trip Has Been edited Successfully"}
        // toggleModal={() => {
        //   dispatch(
        //     toastStatus({
        //       toastStatus: false,
        //     })
        //   );
        // }}
        isOpen={openToast}
        name="trips"
        handleClose={() => {
          // setFreight(!freight);
          window.location = `/trips/${props?.tripId}`;
          //alert("close button clicked");
        }}
      />

      <Toast
        success={false}
        message={openToastFailureText}
        // toggleModal={() => {
        //   dispatch(
        //     toastStatus({
        //       toastStatus: false,
        //     })
        //   );
        // }}
        isOpen={openToastFailure}
        name="trips"
        handleClose={() => {
          setOpenToast(false);

          // setFreight(!freight);
          // window.location='/trips/';
          //alert("close button clicked");
        }}
      />
      {/* <AddFreight
        toggle={addFreight}
        freight={freight}
        tripId={tripIdTemp}
        // setFreight={setFreight}
      /> */}
      <AddAddress
        toggle={addAddress}
        address={addressModal}
        setAddress={setAddressModal}
      />
      <AddDriverV2 toggle={addDriver} freight={driver} />
      <AddVehicleV2 toggle={addVehicle} freight={vehicle} />
    </div>
  );
}

export default EditTripV2;
