import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import RecentTransactionBox from "../../../subComponents/recentTransaction";
import "./index.css";
import moment from "moment";
import { cleanVehicleHistory, vehicleHistory } from "../../../../services/Store/Actions/manageVehicleAction";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";

function VehicleAssignedHistory(props) {
  const vehicleData = useSelector((state) => state?.manageVehicle);
  const historyData = vehicleData?.vehicleReceivableHistory?.fleets;
  const assignedDriversCount = useSelector(
    (state) => state?.manageVehicle?.vehicleReceivableHistory?.fleets?.length
  );
  const pageCount =
    assignedDriversCount % 10 === 0
      ? assignedDriversCount / 10
      : Math.floor(assignedDriversCount / 10) + 1;
  const [olderHistoryStatus, setOlderHistoryStatus] = useState(false);

  //console.log("History123", Data);
  const dispatch = useDispatch();

  const handleOlderTransactions = () => {};

  // const vehicleData = useSelector((state) => state);
  // console.log("Vehicle Data>>>>",vehicleData?.manageVehicle?.vehicleReceivableHistoryList)
  // const historyData =
  //   vehicleData?.manageVehicle?.vehicleReceivableHistoryList;

  //console.log("dta",historyData)
  // const handleMore = () => {
  //   dispatch(vehicleHistory(props.userId));
  // }

  // useEffect(() => {
  //   if(props.user){
  //     console.log("abab");
  //     dispatch(vehicleHistory(props.user));
  //   }
  // }, [props.user,historyData]);

  const [offset,setOffset]= useState(0);
  const handlePageClick = (event) => {
    console.log("Clickess");
    const newOffset = ((event.selected) * 10) ;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    // dispatch(
    //   getCustomerRecievables({
    //     customerId: customer,
    //     offset: newOffset,
    //     search: "",
    //     minKyc: "",
    //     sortAs: "",
    //     sortBy: "",
    //   })
    // );
    setOffset(newOffset);
  };

  let { id } = useParams();
  useEffect(() => {
    setOffset(0);
    console.log("users as props", props.user);
    if (id !== undefined) {
      dispatch(vehicleHistory(id));
    } else {
      dispatch(cleanVehicleHistory());
    }
    // dispatch(vehicleHistory(props.user));
  }, [id]);

  return (
    <div className="main-vehicle-assigned-history">
      <div className="row">
        <div className="col-1">
          <SvgImage
            name="VehicleLogo"
            classname="vehicle-logo"
            color={"black"}
          />
        </div>
        <div className="col-6">
          <p
            className="vehicle-assigned-history-title"
            style={{ marginTop: "9px", marginLeft: "-12px" }}
          >
            Vehicle Assigned History
          </p>
        </div>
      </div>
      <div className="table-for-vehicle-receivable">
        <Table className="vehicleAssignedHistory">
          <thead className="th" style={{ zIndex: 99 }}>
            <tr className="table-header-customer-details">
              <th>Driver Name</th>
              <th>Contact Number</th>
              <th>Assigned Date</th>
              <th>Last Date</th>
            </tr>
          </thead>
          {/* <div className="tbodyVehicle-div"> */}
          <tbody
            className="tbodyVehicle"
            style={{ textDecoration: "none" }}
          >
            {historyData?.map((items,i) => {
              console.log("-----------",i);
              return (
                <>
                  {
                    (i-offset)<10 && (i-offset)>=0?
                    <tr className="table-row-customer-details">
                    <td>{items?.Driver?.User?.firstName}</td>
                    <td>{items?.Driver?.User?.phone}</td>
                    <td>{moment(items?.from).format("MM/DD/YYYY")}</td>
                    <td>
                      {items?.to === null ? (
                        <span style={{ color: "#6378E3" }}>Present</span>
                      ) : (
                        moment(items?.to).format("MM/DD/YYYY")
                      )}
                      </td>
                    </tr>
                    :<></>
                  }
                </>
              );
            })}
          </tbody>
          {/* </div> */}
        </Table>
        {/* {
          olderHistoryStatus?
          <div className="olderTransactions-div" onClick={handleOlderTransactions}>
          Older History
          </div>:
        <></>
        } */}
        {pageCount > 1 ? (
          <div className="reactPagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* <div className="view-full-history-vehicle-assign" >
          <p>
          View Full History
          </p>
      </div> */}
    </div>
  );
}
export default VehicleAssignedHistory;
