import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  NavLink,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Alert } from "bootstrap";
import { DatePickerField } from "../../../../../../core/inputs/input";
import SvgImage from "../../../../../../Icons/Icons";
import {  getCityAndStateAction } from "../../../../../../../services/Store/Actions/omcActions";
import { createTrip } from "../../../../../../../services/Store/Actions/TripsAction";

const AddNewTripForm1=(props)=>{
  const { setData, setForm ,type,item,path, toggleModal} = props;
  const {loader} = useSelector((state) => state?.omc);
  const [color1, setColor1] = useState(-1);
  const [color2, setColor2] = useState(-1);
  const [cityId1, setcityId1] = useState("");
  const [cityId2, setcityId2] = useState("");
  const [search1, setSearch1] = useState("");
  const [display1, setDisplay1] = useState(false);
  const [search2, setSearch2] = useState("");
  const [display2, setDisplay2] = useState(false);

  const validationSchema = Yup.object().shape({
    source: Yup.string().required("Required"),
    destination: Yup.string().required("Required"),
    // startDate:Yup.string().required("Required"),
    // endDate: Yup.string().required("Required"),
    // LRNumber: Yup.string().required("Required"),
    // Company: Yup.string().required("Required")
  });
  const defaultStates =
  [
      {
          "cityId": 1130,
          "cityName": "Delhi",
          "cityCode": "",
          "stateId": 32,
          "lat": "28.557163",
          "long": "77.163665",
          "createdAt": "2022-03-20 20:51:24",
          "State": {
              "stateName": "Delhi"
          }
      },

      {
          "cityId": 295,
          "cityName": "Bengaluru",
          "cityCode": "",
          "stateId": 11,
          "lat": "12.96557",
          "long": "77.60625",
          "createdAt": "2022-03-20 20:51:24",
          "State": {
              "stateName": "Karnataka"
          }
      },

      {
          "cityId": 853,
          "cityName": "Chennai",
          "cityCode": "",
          "stateId": 23,
          "lat": "13.066682",
          "long": "80.224741",
          "createdAt": "2022-03-20 20:51:24",
          "State": {
              "stateName": "Tamil Nadu"
          }
      },
      {
          "cityId": 383,
          "cityName": "Kochi",
          "cityCode": "",
          "stateId": 12,
          "lat": "9.956297",
          "long": "76.332486",
          "createdAt": "2022-03-20 20:51:24",
          "State": {
              "stateName": "Kerala"
          }
      },
      {
          "cityId": 1750,
          "cityName": "Kolkata",
          "cityCode": null,
          "stateId": 28,
          "lat": "22.51829",
          "long": "88.362951",
          "createdAt": "2022-09-20 10:11:47",
          "State": {
              "stateName": "West Bengal"
          },
      },
      {
          "cityId": 1083,
          "cityName": "Chandigarh",
          "cityCode": "",
          "stateId": 30,
          "lat": "30.728092",
          "long": "76.7784",
          "createdAt": "2022-03-20 20:51:24",
          "State": {
              "stateName": "Chandigarh"
          }
      },
      {
          "cityId": 1670,
          "cityName": "Hyderabad",
          "cityCode": null,
          "stateId": 24,
          "lat": "17.390617",
          "long": "78.470769",
          "createdAt": "2022-09-20 10:11:47",
          "State": {
              "stateName": "Telangana"
          }
      },
      {
          "cityId": 566,
          "cityName": "Pune",
          "cityCode": "",
          "stateId": 14,
          "lat": "18.519479",
          "long": "73.870703",
          "createdAt": "2022-03-20 20:51:24",
          "State": {
              "stateName": "Maharashtra"
          }
      },
      {
          "cityId": 155,
          "cityName": "Surat",
          "cityCode": "",
          "stateId": 7,
          "lat": "21.196102",
          "long": "72.815766",
          "createdAt": "2022-03-20 20:51:24",
          "State": {
              "stateName": "Gujarat"
          }
      },
      {
          "cityId": 154,
          "cityName": "Ahmedabad",
          "cityCode": "",
          "stateId": 7,
          "lat": "23.012369",
          "long": "72.561743",
          "createdAt": "2022-03-20 20:51:24",
          "State": {
              "stateName": "Gujarat"
          }
      },
      {
          "cityId": 1658,
          "cityName": "Madurai",
          "cityCode": null,
          "stateId": 23,
          "lat": "9.93447",
          "long": "78.133299",
          "createdAt": "2022-09-20 10:11:47",
          "State": {
              "stateName": "Tamil Nadu"
          }
      },
      {
          "cityId": 1641,
          "cityName": "Jaipur",
          "cityCode": null,
          "stateId": 21,
          "lat": "26.894094",
          "long": "75.79277",
          "createdAt": "2022-09-20 10:11:47",
          "State": {
              "stateName": "Rajasthan"
          }
      },
      {
          "cityId": 1450,
          "cityName": "Hisar",
          "cityCode": null,
          "stateId": 8,
          "lat": "29.147086",
          "long": "75.733876",
          "createdAt": "2022-09-20 10:11:46",
          "State": {
              "stateName": "Haryana"
          }
      },
  ]
  const dispatch= useDispatch();
  const datepickerRefStart = useRef();
  const handleClickDatepickerIcon1=()=>{
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  }
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2=()=>{
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  }

  const cityStateList = useSelector((state)=>state?.omc?.cityStateList);
    console.log("city list", cityStateList);

    const findSearch1 = () => {
      setColor1(-1);
      dispatch(
        getCityAndStateAction({
          offset: 0,
          search: search1,
          // minKyc: "",
          // sortAs: "",
          // sortBy: "",
        })
      );
    };
  
    const findSearch2 = () => {
      setColor2(-1);
      dispatch(
        getCityAndStateAction({
          offset: 0,
          search: search2,
          // minKyc: "",
          // sortAs: "",
          // sortBy: "",
        })
      );
    };

    useEffect(() => {
      setColor1(-1);
      dispatch(
        getCityAndStateAction({
          offset: 0,
          search: search1,
          // minKyc: "",
          // sortAs: "",
          // sortBy: "",
        })
      );
    }, [search1]);
  
    useEffect(() => {
      setColor2(-1);
      dispatch(
        getCityAndStateAction({
          offset: 0,
          search: search2,
          // minKyc: "",
          // sortAs: "",
          // sortBy: "",
        })
      );
    }, [search2]);
  return (
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          source: "",
          destination: "",
          startDate: item?.startTime||"",
          endDate:item?.endTime|| "",
          LRNumber:item?.lrNumber|| "",
          Company:item?.Company|| "",
        }}
        onSubmit={(values, actions) => {
          console.log("values onSubmit", values);
          const objVal = {
            "userIdForIM": "any",
            "source":String(cityId1),
            "destination": String(cityId2),
            "fleetId": item?.fleetId,
            "userId": item?.userId,
            "startTime": values.startDate,
            "endTime": values.endDate,
            "lrNumber": values.LRNumber,
            "cardUserId": item?.userId,
            "cardType": "any",
            "tripId": item?.tripId,
            "endKmReading": values.endKm,
            "Company": values.Company,
          };
          setData({
            source: cityId1,
            destination: cityId2,
            startDate: values.startDate,
            endDate: values.endDate,
            LRNumber:values.LRNumber,
            Company:values.Company,
          });
          if(item?.userId){
            console.log("createTripActionobjVal",objVal , item);
            dispatch(createTrip(objVal, (res) => {
              if (res?.status == 200) {
                toggleModal();
              }
            }))
          }
          else{
            setForm(1);
          }
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
            <div className="sub-title-1">
              <span class="circle-logo">1</span>
              <p className="sub-head-3">Select Source</p>
            </div>
            <label for="html" className="label-body"></label>
            <input
              type="text"
              id="source"
              className="input-body-3"
              name="source"
              autocomplete="off"
              style={{marginLeft:"0"}}
              onChange={(event) => {
                setDisplay1(true);
                console.log("eeeeeeeeeeeeeeeee",event.target.value);
                  // setDisplay(true);
                setSearch1(event.target.value);
                setFieldValue(
                  "source",
                  event.target.value,
                  true
                );
              }}
              onClick={()=>{
                setDisplay1(true)
              }}
              value={values.source}
              onBlur={handleBlur("source")}
              touched={touched.source}
              errors={errors.source}
              onKeyUp={(e)=>{
                if (e.key === "Enter") {
                  findSearch1();
              }}}
              placeholder="--- Select ---"
            />
            <div className="input-amount"></div>
            {display1 ? (
              <div
                className="padding16 newCashback whiteBackground"
                style={{
                  position: "absolute",
                  width: "350px",
                  height: "330px",
                  marginLeft: "95px",
                  zIndex: "90",
                }}
              >
                <div onClick={()=>{
                  setDisplay1(false);
                  setSearch1("");
                  //dispatch(clearAllDriversByVrn());
                  setFieldValue(
                    "source",
                    "",
                    true
                  );
                }}
                style={{position:"absolute", right:"0.5rem", top:"0.5rem", cursor:"pointer"}}>
                    <SvgImage name="CloseButtonLogo" />
                  </div>
                <div
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "240px",
                  }}
                >
                  { search1 != '' ?
                  cityStateList?.map((data, i) => {
                    let city = data?.cityName;
                    let state = data?.State?.stateName;
                    
                    console.log ("check status",city, state);  
                    // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                    return  (
                      <div
                        key={i}
                        className="padding8 row"
                        onClick={() => {
                          setcityId1(data?.cityId);
                          setColor1(i);
                          setDisplay1(false);
                          //setUserId(data?.User?.userId);
                          setFieldValue(
                            "source",
                            data?.cityName+"-"+data?.State?.stateName,
                            true
                          );
                        }}
                      >
                        {color1 === i ? (
                          <div className="col-1">
                            <SvgImage name="SelectedRadio" />
                          </div>
                        ) : (
                          <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div>
                        )}
                        {/* <div className="col-1">
                          <SvgImage name="UnselectedRadio" />
                        </div> */}
                        <div
                          className="col-11"
                          style={{ textAlign: "left" }}
                        >
                          {city} - {state}
                        </div>
                      </div>
                    ) ;
                  })
                  :(<>
                    {
                      defaultStates?.map((data,i)=>{
                        let city = data?.cityName;
                    let state = data?.State?.stateName;
                    
                    console.log ("check status",city, state);  
                    // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                    return  (
                      <div
                        key={i}
                        className="padding8 row"
                        onClick={() => {
                          setcityId1(data?.cityId);
                          setColor1(i);
                          setDisplay1(false);
                          //setUserId(data?.User?.userId);
                          setFieldValue(
                            "source",
                            data?.cityName+"-"+data?.State?.stateName,
                            true
                          );
                        }}
                      >
                        {color1 === i ? (
                          <div className="col-1">
                            <SvgImage name="SelectedRadio" />
                          </div>
                        ) : (
                          <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div>
                        )}
                        {/* <div className="col-1">
                          <SvgImage name="UnselectedRadio" />
                        </div> */}
                        <div
                          className="col-11"
                          style={{ textAlign: "left" }}
                        >
                          {city} - {state}
                        </div>
                      </div>
                    ) ;
                      })
                    }
                  </>)}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
              {errors.source && touched.source ? (
                <div>*{errors.source}</div>
              ) : null}
            </div>
            </FormGroup>
            <FormGroup>
            <div className="sub-title-1">
              <span class="circle-logo">2</span>
              <p className="sub-head-3">Select Destination</p>
            </div>
            <label for="html" className="label-body"></label>
            <input
              type="text"
              id="destination"
              autocomplete="off"
              className="input-body-3"
              name="destination"
              style={{marginLeft:"0"}}
              onClick={()=>{
                setDisplay2(true)
              }}
              onChange={(event) => {
                setDisplay2(true);
                console.log("eeeeeeeeeeeeeeeee",event.target.value);
                  // setDisplay(true);
                setSearch2(event.target.value);
                setFieldValue(
                  "destination",
                  event.target.value,
                  true
                );
              }}
              value={values.destination}
              onBlur={handleBlur("destination")}
              touched={touched.destination}
              errors={errors.destination}
              onKeyUp={(e)=>{
                if (e.key === "Enter") {
                  findSearch2();
              }}}
              placeholder="--- Select ---"
            />
            <div className="input-amount"></div>
            {display2 ? (
              <div
                className="padding16 newCashback whiteBackground"
                style={{
                  position: "absolute",
                  width: "350px",
                  height: "330px",
                  marginLeft: "95px",
                  zIndex: "90",
                }}
              >
                <div onClick={()=>{
                  setDisplay2(false);
                  setSearch2("");
                  //dispatch(clearAllDriversByVrn());
                  setFieldValue(
                    "destination",
                    "",
                    true
                  );
                }}
                style={{position:"absolute", right:"0.5rem", top:"0.5rem", cursor:"pointer"}}>
                    <SvgImage name="CloseButtonLogo" />
                  </div>
                <div
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "240px",
                  }}
                >
                  {  search2 != ''  ?
                  cityStateList?.map((data, i) => {
                    let city = data?.cityName;
                    let state = data?.State?.stateName;
                    
                    console.log ("check status",city, state);  
                    // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                    return  (
                      <div
                        key={i}
                        className="padding8 row"
                        onClick={() => {
                          setcityId2(data?.cityId);
                          setColor2(i);
                          setDisplay2(false);
                          //setUserId(data?.User?.userId);
                          setFieldValue(
                            "destination",
                            data?.cityName+"-"+data?.State?.stateName,
                            true
                          );
                        }}
                      >
                        {color2 === i ? (
                          <div className="col-1">
                            <SvgImage name="SelectedRadio" />
                          </div>
                        ) : (
                          <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div>
                        )}
                        {/* <div className="col-1">
                          <SvgImage name="UnselectedRadio" />
                        </div> */}
                        <div
                          className="col-11"
                          style={{ textAlign: "left" }}
                        >
                          {city} - {state}
                        </div>
                      </div>
                    ) ;
                  })
                  :(<>
                    {
                      defaultStates?.map((data, i) => {
                    let city = data?.cityName;
                    let state = data?.State?.stateName;
                    
                    console.log ("check status",city, state);  
                    // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                    return  (
                      <div
                        key={i}
                        className="padding8 row"
                        onClick={() => {
                          setcityId2(data?.cityId);
                          setColor2(i);
                          setDisplay2(false);
                          //setUserId(data?.User?.userId);
                          setFieldValue(
                            "destination",
                            data?.cityName+"-"+data?.State?.stateName,
                            true
                          );
                        }}
                      >
                        {color2 === i ? (
                          <div className="col-1">
                            <SvgImage name="SelectedRadio" />
                          </div>
                        ) : (
                          <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div>
                        )}
                        {/* <div className="col-1">
                          <SvgImage name="UnselectedRadio" />
                        </div> */}
                        <div
                          className="col-11"
                          style={{ textAlign: "left" }}
                        >
                          {city} - {state}
                        </div>
                      </div>
                    ) ;
                  })
                    }
                  </>)}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
              {errors.destination && touched.destination ? (
                <div>*{errors.destination}</div>
              ) : null}
            </div>
            </FormGroup>
            <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">3</span>
                    <label className="sub-head-3 ">Start Date</label>
                  </div>
                  <DatePickerField
                    name="startDate"
                    header="Select Start date"
                    value={values.startDate}
                    onChange={(v) => {
                     
                      setFieldValue("startDate", v, true)
                    }}
                    ref1={datepickerRefStart}
                  />
                  <label
                    className="date-input"
                    style={{
                      position: "relative",
                      top: "-2rem",
                      left: "-1.4rem",
                      cursor:"pointer"
                    }}
                    onClick={()=>{
                      handleClickDatepickerIcon1()}}
                  >
                    <SvgImage
                      name="CalenderLogo"
                      width="32"
                      height="32"
                      classname="date-logo"
                    />
                  </label>

                  <div className="input-amount-for-link"></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.startDate && touched.startDate ? (
                      <div>*{errors.startDate}</div>
                    ) : null}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">4</span>
                    <label className="sub-head-3 ">End Date</label>
                  </div>
                  <DatePickerField
                    name="endDate"
                    header="Select End date"
                    value={values.endDate}
                    onChange={(v) => {
                      
                      setFieldValue("endDate", v, true)
                    }}
                    //onChange={(v) => setFieldValue("endDate", v, true)}
                    minDate={values.startDate === "" ? "" : values.startDate}
                    ref1={datepickerRefEnd}
                  />
                  <label
                    className="date-input"
                    style={{
                      position: "relative",
                      top: "-2rem",
                      left: "-1.4rem",
                      cursor:"pointer"
                    }}
                    onClick={()=>{
                      handleClickDatepickerIcon2()}}
                  >
                    <SvgImage
                      name="CalenderLogo"
                      width="32"
                      height="32"
                      classname="date-logo"
                    />
                  </label>

                  <div className="input-amount-for-link"></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.endDate && touched.endDate ? (
                      <div>*{errors.endDate}</div>
                    ) : null}
                  </div>
                </FormGroup>
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">5</span>
                <label
                  htmlFor="LRNumber"
                  className="sub-title-edit-vehicle-detial "
                >
                  LR Number
                </label>
              </div>
              <input
                type="text"
                id="LRNumber"
                autocomplete="off"
                className="input-body-3"
                name="LRNumber"
                onChange={handleChange("LRNumber")}
                value={values.LRNumber}
                onBlur={handleBlur("LRNumber")}
                touched={touched.LRNumber}
                errors={errors.LRNumber}
                placeholder="Enter LR Number"
              />
              <div className={`input-amount-for-link `}></div>
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.LRNumber && touched.LRNumber ? (
                  <div>*{errors.LRNumber}</div>
                ) : null}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">6</span>
                <label
                  htmlFor="Company"
                  className="sub-title-edit-vehicle-detial "
                >
                  Company
                </label>
              </div>
              <input
                type="text"
                id="Company"
                autocomplete="off"
                className="input-body-3"
                name="Company"
                onChange={handleChange("Company")}
                value={values.Company}
                onBlur={handleBlur("Company")}
                touched={touched.Company}
                errors={errors.Company}
                placeholder="Enter Company"
              />
              <div className={`input-amount-for-link `}></div>
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.Company && touched.Company ? (
                  <div>*{errors.Company}</div>
                ) : null}
              </div>
            </FormGroup>
            <FormGroup>
              <Button
                type="submit"
                className="butt-4"
                style={{ marginTop: "90px" }}               
                
              >
              Next
              </Button>
            </FormGroup>
            <div></div>
          </Form>
        )}
      </Formik>
      
  );
}

export default AddNewTripForm1;
