import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import moment from "moment";
import SvgImage from "../../Icons/Icons";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addFleet } from "../../../services/Store/Actions/fleetActions";
//import { useNavigate } from "react-router-dom";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";
// import { fleetDetailsSuccess } from "../../../services/Store/Actions/fleetActions";

function TermsConditionsModal(props) {
  const { open, toggleModal, handleClose } = props;

  const dispatch= useDispatch();
  return (
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Terms and Conditions</p>
            <Button
              type="button"
              className="close-btn"
              onClick={()=>{
                dispatch(sendAnalitics("Close_T&C_OwnerAcc_Home_web"));
                toggleModal();
              } }
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="FontSmall padding16">
              <div className="padding16B privacyPolicy">
                This LogiPe WEBSITE Terms and Conditions contains the terms and
                conditions that govern your access to and use of the website (as
                defined below) and is an agreement between LogiPe Fintech Pvt.
                Ltd. ("LogiPe," “we,” “us,” or “our”) and you or the entity you
                represent ("you" or "your"). This Agreement takes effect when
                you access the website. These terms and conditions are an
                electronic record in terms of Information Technology Act, 2000
                ("IT Act") and rules thereunder as applicable and the provisions
                pertaining to electronic records in various statutes as amended
                from time to time. You represent and warrant to us that you are
                competent and possess legal capacity to enter into a Contract
                (i.e. you have attained at least 18 years of age, you have
                capacity to enter into agreements as per The Contract Act).
              </div>
              <div className="padding16B">
                <div className="FontNB headingColor padding8B">1. Use of the WEBSITE.</div>
                <div className="privacyPolicy">
                  1.1 Generally. You may access the contents of the Website,
                  products, service or feature in accordance with the following
                  terms and conditions. You undertake to comply with these terms
                  and conditions and all laws, rules and regulations applicable
                  to your use of the Website.1.2 You undertake and warrant that
                  any information provided by you to LogiPe is correct and
                  valid. In case of any wrong information/impersonation/
                  furnishing of wrong information, you shall be liable for
                  consequences and you shall indemnify LogiPe against any such
                  loss, damage, consequences, which may have to be faced by
                  LogiPe due to such breach. In case it is found that the
                  information provided is false, LogiPe may deny access to the
                  Website immediately without prejudice to any of the terms
                  envisaged under these terms and conditions, LogiPe may also
                  initiate any such actions against you as it may deem proper.
                </div>
              </div>
              <div className="padding16B">
                <div className="FontNB headingColor padding8B">2. Changes.</div>
                <div className="privacyPolicy">
                  2.1. To the Website, products, services or features. We may
                  change or discontinue any or all of the Service Offerings or
                  change or remove functionality of any or all of the Service
                  Offerings from time to time.
                </div>
              </div>
              <div className="padding16B">
                <div className="FontNB headingColor padding8B">
                  3. LAWFUL AND/OR PROHIBITED USE OF THE WEBSITE.
                </div>
                <div className="privacyPolicy">
                  3.1 As a condition of Your use of the Website, You shall not
                  use the Website for any purpose(s) that is unlawful or
                  prohibited by the Terms of Use. You shall not use the Website
                  in any manner that could damage, disable, overburden, or
                  impair any LogiPe server, or the network(s) connected to any
                  LogiPe server, or interfere with any other party's use and
                  enjoyment of any services associated with the Website. You
                  shall not attempt to gain unauthorized access to any section
                  of the Website, other accounts, computer systems or networks
                  connected to any LogiPe server or to any of the services
                  associated with the Website, through hacking, password mining
                  or any other means. You shall not obtain or attempt to obtain
                  any Materials or information through any means not
                  intentionally made available through the Website.
                </div>
              </div>
              <div className="padding16B">
                <div className="FontNB headingColor padding8B">4. Proprietary Rights.</div>
                <div className="privacyPolicy">
                  4.1 We own all rights, titles, and interest in and to the
                  Service Offerings, and all related technology and intellectual
                  property rights. Subject to the terms of these terms and
                  conditions, we grant you a limited, revocable, non-exclusive,
                  non-sub licensable, non-transferable license to do the
                  following: (a) access and use the Services solely in
                  accordance with these terms and conditions; You obtain no
                  rights under these terms and conditions from us, our
                  affiliates to the Service Offerings, including any related
                  intellectual property rights.4.2 License Restrictions. You
                  shall use the website in the manner as provided for in these
                  terms and conditions. You shall not use the website for any
                  purpose other than as expressly permitted by these terms and
                  conditions. You will not attempt to (a) modify, distribute,
                  alter, tamper with, repair, or otherwise create derivative
                  works of any Content in the Website, products, services or
                  features, (b) reverse engineer, disassemble, or decompile the
                  Website, products, services or features. You will not
                  misrepresent or embellish the relationship between us and you
                  (including by expressing or implying that we support, sponsor,
                  endorse, or contribute to you or your business endeavours).4.3
                  Suggestions. If you provide any Suggestions to us or our
                  affiliates, we and our affiliates will be entitled to use the
                  Suggestions without restriction. You hereby irrevocably assign
                  to us all right, title, and interest in and to the Suggestions
                  and agree to provide us any assistance we require to document,
                  perfect, and maintain our rights in the Suggestions.
                </div>
              </div>
              <div className="padding16B">
                <div className="FontNB headingColor padding8B">5. Indemnification.</div>
                <div className="privacyPolicy">
                  5.1 General. You will defend, indemnify, and hold harmless us,
                  our affiliates and each of our/ their respective employees,
                  officers, directors, and representatives from and against any
                  Losses arising out of or relating to any third-party claim
                  concerning: (a) your use of the Website, products, services or
                  features; (b) breach of these terms and conditions or
                  violation of applicable law by you; or You will be liable to
                  reimburse us for reasonable attorneys' fees, as well as our
                  employees' and contractors' time and materials spent
                  responding to any third party notices or other compulsory
                  legal order or process associated with third party claims
                  described in (a) through (b) to the tune of loss/damages
                  incurred.
                </div>
              </div>
              <div className="padding16B">
                <div className="FontNB headingColor padding8B">6. Disclaimers.</div>
                <div className="privacyPolicy">
                  Website and its features provided "as is." except to the
                  extent prohibited by law, or to the extent any statutory
                  rights apply that cannot be excluded, limited or waived, we
                  and our affiliates (a) make no representations or warranties
                  of any kind, whether express, implied, statutory or otherwise
                  regarding the service offerings or the third-party content,
                  and (b) disclaim all warranties, including any implied or
                  express warranties (1) of merchantability, satisfactory
                  quality, fitness for a particular purpose, non-infringement,
                  or quiet enjoyment, (ii) arising out of any course of dealing
                  or usage of trade, (iii) that the service offerings or
                  third-party content will be uninterrupted, error free or free
                  of harmful components, and (iv) that any content will be
                  secure or not otherwise lost or altered.
                </div>
              </div>
              <div className="padding16B">
                <div className="FontNB headingColor padding8B">7. Limitations of Liability.</div>
                <div className="privacyPolicy">
                  We and our affiliates will not be liable to you for any
                  direct, indirect, incidental, special, consequential or
                  exemplary damages (including damages for loss of profits,
                  revenues, customers, opportunities, goodwill, use, or data),
                  even if a party has been advised of the possibility of such
                  damages. Further, neither we nor any of our affiliates will be
                  responsible for any compensation, reimbursement, or damages
                  arising in connection with: (a) your inability to use the
                  services, including as a result of any (1) termination or
                  suspension of this agreement or your use of or access to the
                  service offerings, (ii) our discontinuation of any or all of
                  the service offerings, or, (iii) without limiting any
                  obligations under the order level agreements, any
                  unanticipated or unscheduled downtime of all or a portion of
                  the services for any reason; (b) the cost of procurement of
                  substitute goods or services; (c) any investments,
                  expenditures, or commitments by you in connection with this
                  agreement or your use of or access to the service offerings;
                  or (d) any unauthorized access to, alteration of, or the
                  deletion, destruction, damage, loss or failure to store any of
                  your content or other data.
                </div>
              </div>
              <div className="padding16B">
                <div className="FontNB headingColor padding8B">
                  8. Modifications to these Terms and Conditions.
                </div>
                <div className="privacyPolicy">
                  We may modify these Terms and Conditions (including any
                  Policies) at any time by posting a revised version on the
                  LogiPe Website; By continuing to use the Website after the
                  effective date of any modifications to these Terms and
                  Conditions, you agree to be bound by the modified terms. It is
                  your responsibility to check the LogiPe Site regularly for
                  modifications to these Terms and Conditions. These terms and
                  conditions were modified last on the date mention at the end
                  of these Terms and Conditions.
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
  );
}

export default TermsConditionsModal;
