import {
    REDEEM_CASHBACK,
    REDEEM_CASHBACK_SUCCESS
  } from '../storeTypes';

  const INIT_STATE = {
    loader: false,
  };


export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case REDEEM_CASHBACK: {
        console.log("REDEEM-reducer");
        return {
          ...state,
          loader: true,
        };
      }
      case REDEEM_CASHBACK_SUCCESS: {
        console.log("REDEEM- reducer success");
        return {
            ...state,
            loader: false,
        };
      }
      
      default: {
        return state;
      }
    }
  };
  