/* eslint-disable import/no-anonymous-default-export */
import {
  EXPENSE_SUMM,
  EXPENSE_SUMM_SUCCESS,
  CLEAN_EXPENSE_SUMM,
  GET_DRIVERS_LIST,
  GET_DRIVERS_LIST_SUCCESS,
  GET_DRIVER_BAL,
  GET_DRIVER_BAL_SUCCESS,
  ADD_MONEY_TO_WALLET,
  ADD_MONEY_TO_WALLET_SUCCESS,
  WITHDRAW_MONEY_FROM_WALLET,
  WITHDRAW_MONEY_FROM_WALLET_SUCCESS,
  GET_CARD_DETAILS,
  GET_CARD_DETAILS_SUCCESS,
  GET_EXPENSE_WALLET_SUMMARY,
  GET_EXPENSE_WALLET_SUMMARY_SUCCESS,
  CLEAN_EXPENSE_WALLET_SUMMARY,
  ADD_EXPENSE_TO_WALLET,
  ADD_EXPENSE_TO_WALLET_SUCCESS,
  GET_CARD_CONTROL,
  GET_CARD_CONTROL_SUCCESS,
  EDIT_CARD_CONTROL,
  EDIT_CARD_CONTROL_SUCCESS,
  CLEAR_ALL_DRIVERS,
  LINK_FLEET,
  LINK_FLEET_SUCCESS,
  CLEAN_FLEET,
  CHANGE_CARD_STATUS,
  CHANGE_CARD_STATUS_SUCCESS,
  GET_FLEET_BY_FLEET_ID,
  GET_FLEET_BY_FLEET_ID_SUCCESS,
  GET_DRIVER_LIST_2,
  GET_DRIVER_LIST_2_SUCCESS,
  GENERATE_DRIVER_WALLET_STATEMENT,
  GENERATE_DRIVER_WALLET_STATEMENT_SUCCESS,
  NEW_EDIT_CARD_CONTROL,
  NEW_EDIT_CARD_CONTROL_SUCCESS,
  NEW_GET_CARD_CONTROL,
  NEW_GET_CARD_CONTROL_SUCCESS,
  GET_DRIVERS_LIST_HOME,
  GET_DRIVERS_LIST_HOME_SUCCESS,
  SEARCH_BY_VRN,
  SEARCH_BY_VRN_SUCCESS,
  CLEAR_ALL_DRIVERS_BY_VRN,
  DOWNLOAD_SALARY_SUCCESS,
  DOWNLOAD_SALARY,
  UPLOAD_SALARY,
  UPLOAD_SALARY_SUCCESS,
  ADD_NICKNAME_SUCCESS,
  ADD_NICKNAME,
  DOWNLOAD_SALARY_CREDIT_SHEET,
  DOWNLOAD_SALARY_CREDIT_SHEET_SUCCESS,
  UPLOAD_SALARY_CREDIT_SHEET,
  UPLOAD_SALARY_CREDIT_SHEET_SUCCESS,
  GET_SALARY_WALLET_DETAILS,
  GET_SALARY_WALLET_DETAILS_SUCCESS,
  EDIT_SALARY_WALLET_DETAILS,
  EDIT_SALARY_WALLET_DETAILS_SUCCESS,
  CREATE_SALARY_WALLET,
  CREATE_SALARY_WALLET_SUCCESS,
  GET_FLEET_LIST_TRACKER,
  GET_FLEET_LIST_TRACKER_SUCCESS,
  GENERATE_FRESH_DESK_TICKET_V2,
  GENERATE_FRESH_DESK_TICKET_V2_SUCCESS,
  PAY_DRIVER_SALARY,
  PAY_DRIVER_SALARY_SUCCESS,
  EDIT_SALARY_WALLET,
  EDIT_SALARY_WALLET_SUCCESS,
  GENERATE_SALARY_WALLET_STATEMENT,
  GENERATE_SALARY_WALLET_STATEMENT_SUCCESS,
  CLEAN_SALARY_WALLET_DETAILS,
  GET_DRIVERS_LIST_ADVANCE,
  GET_DRIVERS_LIST_ADVANCE_SUCCESS,
  CLEAR_ALL_DRIVERS_ADVANCE,
  GET_ALL_SALARY_WALLET,
GET_ALL_SALARY_WALLET_SUCCESS,
CLEAR_ALL_SALARY_WALLET,
GET_SALARY_WALLET_SUMMARY,
GET_SALARY_WALLET_SUMMARY_SUCCESS,
CLEAN_SALARY_WALLET_SUMMARY
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  user: {},
  bal: {},
  expense: [],
  allexpense: {},
  allFleet: {},
  allFleetTracker: {},
  allFleetTrackerList: [],
  AddMoneyData: {},
  cardDetails: {},
  expenseWalletSummary: [],
  expenseWalletSummaryList: [],
  cardControl: {},
  allDrivers: {},
  allDriverList: [],
  cardStatus: "",
  fleet: {},
  driverList2: [],
  newDriverList: [],
  driverStatementList: {},
  newCardInfo:{},
  homeAllDrivers:{},
  driversByVrn:[],
  salaryWalletDetails:{},
  allDriversAdvance: {},
  allDriverListAdvance: [],
  driverListLoader: [],
  salaryWallets:[],
  salaryWalletsCount:{},
  salaryWalletSummary:[],
  salaryWalletSummaryCount:{},
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NICKNAME: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_NICKNAME_SUCCESS:{
      return {
        ...state,
        loader: false,
      };
    }
    case UPLOAD_SALARY: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPLOAD_SALARY_SUCCESS:{
      return {
        ...state,
        loader: false,
      };
    }
    case DOWNLOAD_SALARY: {
      return {
        ...state,
        loader: true,
      };
    }
    case DOWNLOAD_SALARY_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case SEARCH_BY_VRN: {
      return {
        ...state,
        loader: true,
      };
    }
    case SEARCH_BY_VRN_SUCCESS: {
      console.log("driversByVrn",action.payload )
      return {
        ...state,
        driversByVrn: action.payload?.data?.data?.divers?.rows,
        // allDriverList: [
        //   ...state.allDriverList,
        //   ...action?.payload?.divers?.rows,
        // ],
        loader: false,
      };
    }
    case CLEAR_ALL_DRIVERS_BY_VRN: {
      return {
        ...state,
        driversByVrn: [],
      };
    }
    case CHANGE_CARD_STATUS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CHANGE_CARD_STATUS_SUCCESS: {
      return {
        ...state,
        cardStatus: action.payload.isActive,
      };
    }

    case GET_DRIVERS_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case CLEAR_ALL_DRIVERS: {
      return {
        ...state,
        allDrivers: {},
        allDriverList: [],
      };
    }

    case GET_DRIVERS_LIST_SUCCESS: {
      console.log(action.payload);
      return {
        ...state,
        allDrivers: action.payload,
        allDriverList: [
          ...state.allDriverList,
          ...action?.payload?.divers?.rows,
        ],
        loader: false,
      };
    }
    case GET_DRIVERS_LIST_HOME: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_DRIVERS_LIST_HOME_SUCCESS: {
      console.log(action.payload);
      return {
        ...state,
        homeAllDrivers: action.payload,
        loader: false,
      };
    }
    case GET_DRIVER_BAL: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_DRIVER_BAL_SUCCESS: {
      return {
        ...state,
        loader: false,
        bal: action.payload,
      };
    }
    case EXPENSE_SUMM: {
      return {
        ...state,
        loader: true,
      };
    }

    case EXPENSE_SUMM_SUCCESS: {
      //console.log("jai mata di", action.payload);
      return {
        ...state,
        loader: false,
        expense: [...state.expense, ...action?.payload?.rows],
        allexpense: action.payload,

      };
    }

    case CLEAN_EXPENSE_SUMM: {
      return {
        ...state,
        expense: [],
        allexpense: [],
      };
    }

    case ADD_MONEY_TO_WALLET: {
      // console.log(action);
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_MONEY_TO_WALLET_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case WITHDRAW_MONEY_FROM_WALLET: {
      return {
        ...state,
        loader: true,
      };
    }

    case WITHDRAW_MONEY_FROM_WALLET_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case GET_CARD_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_CARD_DETAILS_SUCCESS: {
      console.log("card details from reducer here", action.payload);
      return {
        ...state,
        loader: false,
        cardDetails: action.payload,
      };
    }

    case GET_EXPENSE_WALLET_SUMMARY: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_EXPENSE_WALLET_SUMMARY_SUCCESS: {
      console.log("data passed --- reducer------>>>>>>>>", action.payload);
      return {
        ...state,
        loader: false,
        expenseWalletSummary: action.payload,
        expenseWalletSummaryList: action?.payload,
      };
    }
    case CLEAN_EXPENSE_WALLET_SUMMARY: {
      return {
        ...state,
        expenseWalletSummary: [],
        expenseWalletSummaryList: [],
      };
    }

    case ADD_EXPENSE_TO_WALLET: {
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_EXPENSE_TO_WALLET_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_CARD_CONTROL: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_CARD_CONTROL_SUCCESS: {
      return {
        ...state,
        loader: false,
        cardControl: action.payload,
      };
    }

    case EDIT_CARD_CONTROL: {
      return {
        ...state,
        loader: true,
      };
    }

    case EDIT_CARD_CONTROL_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case LINK_FLEET: {
      return {
        ...state,
        loader: true,
      };
    }

    case LINK_FLEET_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case CLEAN_FLEET: {
      return {
        ...state,
        allFleet: {},
        allFleetTrackerList: [],
        allFleetTracker: {},
      };
    }

    case GET_FLEET_BY_FLEET_ID: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_FLEET_BY_FLEET_ID_SUCCESS: {
      console.log("from reducer success", action.payload);
      return {
        ...state,
        loader: false,
        fleet: action.payload,
      };
    }
    case GET_DRIVER_LIST_2: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_DRIVER_LIST_2_SUCCESS: {
      console.log(action.payload);
      return {
        ...state,
        newDriverList: action.payload,
        loader: false,
      };
    }
    case GENERATE_DRIVER_WALLET_STATEMENT: {
      console.log("fim", action.userId);
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_DRIVER_WALLET_STATEMENT_SUCCESS: {
      console.log(action.payload);
      return {
        ...state,
        driverStatementList: action.payload,
        loader: false,
      };
    }
    case NEW_GET_CARD_CONTROL: {
      return {
        ...state,
        loader: true,
      };
    }
    case NEW_GET_CARD_CONTROL_SUCCESS: {
      return {
        ...state,
        loader: false,
        newCardInfo: action.payload,
      };
    }

    case NEW_EDIT_CARD_CONTROL: {
      return {
        ...state,
        loader: true,
      };
    }
    case NEW_EDIT_CARD_CONTROL_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case DOWNLOAD_SALARY_CREDIT_SHEET: {
      return {
        ...state,
        loader: true,
      };
    }
    case DOWNLOAD_SALARY_CREDIT_SHEET_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case UPLOAD_SALARY_CREDIT_SHEET: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPLOAD_SALARY_CREDIT_SHEET_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case GET_SALARY_WALLET_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_SALARY_WALLET_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        salaryWalletDetails: action.payload
      };
    }
    case CLEAN_SALARY_WALLET_DETAILS: {
      return {
        ...state,
        loader: false,
        salaryWalletDetails: {}
      };
    }


    case EDIT_SALARY_WALLET_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_SALARY_WALLET_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case CREATE_SALARY_WALLET: {
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_SALARY_WALLET_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_FLEET_LIST_TRACKER: {
      console.log("FROM REDUCER", action.payload);
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_FRESH_DESK_TICKET_V2: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_FLEET_LIST_TRACKER_SUCCESS: {
      // console.log("FROM REDUCER",action.payload)
      return {
        ...state,
        loader: false,
        allFleetTracker: action.payload,
        allFleetTrackerList: [...state.allFleetTrackerList, ...action?.payload?.result],
      };
    }
    case GENERATE_FRESH_DESK_TICKET_V2_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case PAY_DRIVER_SALARY: {
      return {
        ...state,
        loader: true,
      };
    }
    case PAY_DRIVER_SALARY_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case EDIT_SALARY_WALLET: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_SALARY_WALLET_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GENERATE_SALARY_WALLET_STATEMENT: {
      console.log("red", action.payload);
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_SALARY_WALLET_STATEMENT_SUCCESS: {
      //console.log("red-> fleet-- ");
      return {
        ...state,
        loader: false,
        salaryWalletStatement: action.payload,
      };
    }
    case GET_DRIVERS_LIST_ADVANCE: {
      console.log("red", action.payload);
      return {
        ...state,
        loader: true,
        driverListLoader: true,
      };
    }
    case GET_DRIVERS_LIST_ADVANCE_SUCCESS: {
      //console.log("red-> fleet-- ");
      console.log("GET_DRIVERS_LIST_ADVANCE_SUCCESS",action.payload);
      return {
        ...state,
        loader: false,
        driverListLoader: false,
        allDriversAdvance: action.payload,
        allDriverListAdvance: action.payload.result,
      };
    }
    case CLEAR_ALL_DRIVERS_ADVANCE: {
      //console.log("red-> fleet-- ");
      return {
        ...state,
        loader: false,
        allDriversAdvance: {},
        allDriverListAdvance: [],
      };
    }
    case GET_ALL_SALARY_WALLET: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_ALL_SALARY_WALLET_SUCCESS: {
      return {
        ...state,
        loader: false,
        salaryWallets:action.payload?.rows,
        salaryWalletsCount:action.payload?.count
      };
    }

    case CLEAR_ALL_SALARY_WALLET: {
      return {
        ...state,
        salaryWallets:[],
        salaryWalletsCount:{}
      };
    }

    case GET_SALARY_WALLET_SUMMARY: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_SALARY_WALLET_SUMMARY_SUCCESS: {
      return {
        ...state,
        loader: false,
        salaryWalletSummary:action.payload?.rows,
        salaryWalletSummaryCount:action.payload?.count
      };
    }

    case CLEAR_ALL_SALARY_WALLET: {
      return {
        ...state,
        salaryWalletSummary:[],
        salaryWalletSummaryCount:{}
      };
    }

    default: {
      return state;
    }
  }
};
