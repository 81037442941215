import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./index.css";
import { Button } from "reactstrap";
import SvgImage from "../../../../../Icons/Icons";
import ManageDriverAddNewExpense from "../../../../../Modals/ManageDriverAddNewExpense";
import { useParams } from "react-router-dom";


const CashComponent=(props)=>{

    const [open, setOpen]= useState(false);

    const handleClose=()=>{
        setOpen(false);
    }

    const toggleModal=()=>{
        setOpen(!open);
    }

    const showModal=()=>{
        setOpen(true);
    }

    const {id}= useParams();
    const driverBal = useSelector((state) => state?.driverlist?.bal);
    return(

        <div className="padding16 ">
            <div className="flexStyling lowerBorderLine padding16B">
                <SvgImage name="cashDrLogo" color={"#485B7C"} width="48" height="42" />
                <div className="padding8L Bold heading2 flexStyling" style={{alignItems:"center"}}>
                Cash Details
                </div>
            </div>
            <div className="padding8T row greyFont" style={{margin:"0"}}>
                <div className="col-6  semiBold heading3">
                    <div className="padding8 padding0L">Cash Balance: </div>
                    <div className="padding8 padding0L">Monthly cash exp:</div>
                </div>
                <div className="col-6 semiBold heading3 rightAlignment">
                    <div className="padding8 padding0L">₹ {Number(driverBal?.cashBalance).toFixed(2)}</div>
                    <div className="padding8 padding0L">₹ {Number(driverBal?.monthlyCashSpend).toFixed(2)}</div>
                </div>
            </div>
            <div className="padding32T padding16B" style={{width:"40%"}} onClick={showModal}>
                <Button className="generateOtp semiBold heading3">Add Expense</Button>
            </div>
            <ManageDriverAddNewExpense
                userId={id}
                open={open}
                toggleModal={toggleModal}
                title="Logipe Account"
                handleClose={handleClose}
            />
        </div>
    )
}

export default CashComponent;