import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import ToolAndDocs from "../ToolAndDocs";

// import { DatePickerField } from "../../../core/inputs/input";
// import { useRef } from "react";
// import AddFreight from "../../../TripsMainV2/Components/AddFreight";
// import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
// import {
//   createTrip,
//   getCityAndState,
// } from "../../../../services/Store/Actions/TripsAction";
// import moment from "moment";
// import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
// import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
// import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
// import ToastV2 from "../../../subComponents/toastV2";
// import { toastStatus } from "../../../../services/Store/Actions/appActions";
// import CollectPayment from "../CollectPayment";
// import AddPayment from "../AddPayment";
// import CreateInvoice from "../CreateInvoice";
// import AddInvoice from "../AddInvoice";

function ShowroomModal(props) {
  const { toggle, modal, setModal, regNo,fleetId } = props;
  const dispatch = useDispatch();
  const [tollAndDocs,setToolAndDocs] = useState(false);
  const [title,setTitle] = useState('');

  const handleClose = () => {
    setModal(!modal);
  };

  const Tool = () => {
    console.log("clickedTool");
    setTitle('Tool')
    showToolAndDocsFunc(true)
  };
  const Document = () => {
    setTitle('Document')
    console.log("clickedDocument");
    showToolAndDocsFunc(true)
    
  };
  const showToolAndDocsFunc = () => {

    setToolAndDocs(!tollAndDocs)
  };
  
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
          // style={{height : "75vh"}}
        >
          <div>
            <div className="closeBtnWithBackground" onClick={handleClose}>
              <SvgImage
                classname="close-btn-text"
                style={{ width: "10%", height: "10%" }}
                name="crossBtnWIthBackground"
                width="20%"
                height="20%"
              />
            </div>
            <div
              style={{ marginTop: " 90px" }}
              className="row"
              //   className="PGCollectionBox"
            >
              <div className="showroomText col-12">Showroom</div>
              <div className="showroomVehilceText col-12">Vehicle : {regNo}</div>
              <div className="col-12 row">
                <div
                  className="col-6"
                  style={{ padding: "75px 0 150px 25%" }}
                  onClick={Document}
                >
                  <div className="squareDiv row">
                    <div
                      className="col-12"
                      style={{ textAlign: "center", paddingTop: "35px" }}
                    >
                      <SvgImage name="documentIcon" />
                    </div>
                    <div className="col-12 addPayText">Documents</div>
                  </div>
                </div>
                <div
                  className="col-6"
                  style={{ padding: "75px 0 150px 10%" }}
                  onClick={Tool}
                >
                  <div className="squareDiv row">
                    <div
                      className="col-12"
                      style={{ textAlign: "center", paddingTop: "35px" }}
                    >
                      <SvgImage name="toolsIcon" />
                    </div>
                    <div className="col-12 addPayText">
                      Tools
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToolAndDocs
          toggle={showToolAndDocsFunc}
          modal={tollAndDocs}
          setModal={setToolAndDocs}
          regNo={props.regNo}
          title={title}
          fleetId={fleetId}
          isActive={'1'}
          // customerId={customerId}
        />
      {/* <CreateInvoice
        toggle={createInvoice}
        modal={invoiceModal}
        setModal={setInvoiceModal}
        customerId={customerId}
        isCheckAll={isCheckAll}
        setIsCheckAll={setIsCheckAll}
        isCheck={isCheck}
        setIsCheck={setIsCheck}
        customer={customer}
      />
      <AddInvoice
        toggle={addInvoiceFunc}
        modal={addInvoice}
        setModal={setAddInvoice}
      /> */}
    </div>
     
  );

}

export default ShowroomModal;
