import {
    GET_M2P_USER_DETAILS,
    GET_M2P_USER_DETAILS_SUCCESS,
    GET_M2P_CARD_DETAILS,
    GET_M2P_CARD_DETAILS_SUCCESS,
    M2P_TRANSACTION_HISTORY,
    M2P_TRANSACTION_HISTORY_SUCCESS,
    M2P_TRANSACTION_HISTORY_CLEAN,
    M2P_WITHDRAW_FROM_CARD,
    M2P_WITHDRAW_FROM_CARD_SUCCESS,
    GET_M2P_CARD_CONTROLS,
    GET_M2P_CARD_CONTROLS_SUCCESS,
    EDIT_M2PCARD_CONTROLS,
    EDIT_M2PCARD_CONTROLS_SUCCESS,
    M2P_SET_PIN,
    M2P_SET_PIN_SUCCESS,
    M2P_OTP,
    M2P_OTP_SUCCESS,
    M2P_CONTACT_INFO,
    M2P_CONTACT_INFO_SUCCESS,
    M2P_REGISTER,
    M2P_REGISTER_SUCCESS,
    M2P_VKYC_URL_SUCCESS,
    M2P_OWNER_ADDRESS_SUCCESS,
    M2P_OWNER_ADDRESS,
    M2P_VKYC_URL,
    M2P_MONTHLY_BALANCE,
    M2P_MONTHLY_BALANCE_SUCCESS,
    M2P_DOWNLOAD_WALLET_HISTORY,
    M2P_DOWNLOAD_WALLET_HISTORY_SUCCESS,
  } from "../storeTypes";
  
  export const M2pUserDetailsAction = (data) => {
    return {
      type: GET_M2P_USER_DETAILS,
      payload: data,
    };
  };
  
  export const M2pUserDetailsActionSuccess = (data) => {
    return {
      type: GET_M2P_USER_DETAILS_SUCCESS,
      payload: data,
    };
  };

  export const M2PCardDetailsAction = (data) => {
    return {
      type: GET_M2P_CARD_DETAILS,
      payload: data,
    };
  };
  
  export const M2PCardDetailsActionSuccess = (data) => {
    return {
      type: GET_M2P_CARD_DETAILS_SUCCESS,
      payload: data,
    };
  };
  

  export const M2PTransactionsAction = (userId,data, callback) => {
    return {
      type: M2P_TRANSACTION_HISTORY,
      userId:userId,
      payload: data,
      callback
    };
  };
  
  export const M2PTransactionsActionSuccess = (payload) => {
    console.log("action--------------",payload);
    return {
      type: M2P_TRANSACTION_HISTORY_SUCCESS,
      payload,
    };
  };

  export const M2PTransactionsCleanAction = () => {
    return {
      type: M2P_TRANSACTION_HISTORY_CLEAN,
    };
  };

  export const M2pCardWithdrawAction = (data,callback) => {
    return {
      type: M2P_WITHDRAW_FROM_CARD,
      payload: data,
      callback
    };
  };
  
  export const M2pCardWithdrawActionSuccess = (payload) => {
    return {
      type: M2P_WITHDRAW_FROM_CARD_SUCCESS,
      payload,
    };
  };

  export const M2PCardControlAction = (id) => {
    return {
      type: GET_M2P_CARD_CONTROLS,
      payload: id,
    };
  };
  
  export const M2PCardControlActionSuccess = (payload) => {
    return {
      type: GET_M2P_CARD_CONTROLS_SUCCESS,
      payload,
    };
  };

  export const M2pEditCardControlAction = (data,callback) => {
    return {
      type: EDIT_M2PCARD_CONTROLS,
      payload: data,
      callback
    };
  };
  
  export const M2pEditCardControlActionSuccess = (payload) => {
    return {
      type: EDIT_M2PCARD_CONTROLS_SUCCESS,
      payload,
    };
  };

  export const M2PSetPinAction = (data,callback) => {
    return {
      type: M2P_SET_PIN,
      payload: data,
      callback
    };
  };
  
  export const M2PSetPinActionSuccess = (data) => {
    return {
      type: M2P_SET_PIN_SUCCESS,
      payload: data,
    };
  };

  export const m2pOTPAction = (data) => {
    return {
      type: M2P_OTP,
      payload: data,
    };
  };
  
  export const m2pOTPActionSuccess = (data) => {
    return {
      type: M2P_OTP_SUCCESS,
      payload: data,
    };
  };

  export const m2pContactInfoAction = (data,callback) => {
    return {
      type: M2P_CONTACT_INFO,
      payload: data,
      callback
    };
  };
  
  export const m2pContactInfoActionSuccess = (data) => {
    return {
      type: M2P_CONTACT_INFO_SUCCESS,
      payload: data,
    };
  };

  export const m2pRegisterAction = (data,callback) => {
    return {
      type: M2P_REGISTER,
      payload: data,
      callback
    };
  };
  
  export const m2pRegisterActionSuccess = (data) => {
    return {
      type: M2P_REGISTER_SUCCESS,
      payload: data,
    };
  };

  export const m2pVKYCAction = (data,callback) => {
    return {
      type: M2P_VKYC_URL,
      payload: data,
      callback
    };
  };
  
  export const m2pVKYCActionSuccess = (data) => {
    return {
      type: M2P_VKYC_URL_SUCCESS,
      payload: data,
    };
  };

  export const m2pOwnerAddressAction = (data) => {
    return {
      type: M2P_OWNER_ADDRESS,
      payload: data,
    };
  };
  
  export const m2pOwnerAddressActionSuccess = (data) => {
    return {
      type: M2P_OWNER_ADDRESS_SUCCESS,
      payload: data,
    };
  };


  export const m2pMonthlyBalanceAction = (data) => {
    return {
      type: M2P_MONTHLY_BALANCE,
      payload: data,
    };
  };
  
  export const m2pMonthlyBalanceActionSuccess = (data) => {
    return {
      type: M2P_MONTHLY_BALANCE_SUCCESS,
      payload: data,
    };
  };


  export const m2pWalletHistoryDownloadAction = (userId, obj, callback) => {
    return {
      type: M2P_DOWNLOAD_WALLET_HISTORY,
      userId:userId,
      payload: obj,
      callback
    };
  };
  
  export const m2pWalletHistoryDownloadActionSuccess = (data) => {
    return {
      type: M2P_DOWNLOAD_WALLET_HISTORY_SUCCESS,
      payload: data,
    };
  };