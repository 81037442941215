import React from "react";
import "./index.css";
import SvgImage from "../../../Icons/Icons";

function ExpenseCard(props) {
  const { svgName, name, data, recharge, textColor} = props;
  return (
    <div className="expenseCard" style={{ flex: 1 ,...textColor}}>
      <div className="svgBox" style={{ marginRight: "5px" }}>
        <SvgImage name={svgName} />
      </div>
      <div className="textInfo" style={textColor}>
        <div className={`${textColor?"heading3 Bold":"heading3 greyFont2"}`}>{name}</div>
        <div className="heading3 Bold">{data}</div>
      </div>
    </div>
  );
}

export default ExpenseCard;
