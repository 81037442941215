import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import { Button } from "reactstrap";
import NeoCredCard from "./NeoCredCard";
import M2PCardNew from "./M2PCardNew";
import CardLinkNot from "./CardLinkNot";
import KycNotComplete from "./KycNotComplete";
import SvgImage from "../../../../../../Icons/Icons";
import Card2CardTransferModal from "./Modals/Card2CardTransferModal";
import ManageDriverAddBalance from "../../../../../../Modals/ManageDriverAddBalance";
import ManageDriverWithdrawBalance from "../../../../../../Modals/ManageDriverWithdrawBalance";
import { getDriverBal } from "../../../../../../../services/Store/Actions/driverslistActions";
import { useParams } from "react-router-dom";
import BankTransferModal from "./Modals/BankTransferModal";

const CardComponent = (props) => {
  const { cardType, driverDetails, driverCardStatus, userId } = props;
  console.log("bhai idhar", driverDetails);

  const { id } = useParams();
  const driverBal = useSelector((state) => state?.driverlist?.bal);
  //handle card to card transfer
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  const showModal = () => {
    setOpen(true);
  };

  //handle bank transfer
  const [open1, setOpen1] = useState(false);

  const handleClose1 = () => {
    setOpen1(false);
  };

  const toggleModal1 = () => {
    setOpen1(!open1);
  };

  const showModal1 = () => {
    setOpen1(true);
  };

  //handle card withdrawl
  const [open2, setOpen2] = useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };

  const toggleModal2 = () => {
    setOpen2(!open2);
  };

  const showModal2 = () => {
    setOpen2(true);
  };

  //handle add to card transfer
  const [open3, setOpen3] = useState(false);

  const handleClose3 = () => {
    setOpen3(false);
  };

  const toggleModal3 = () => {
    setOpen3(!open3);
  };

  const showModal3 = () => {
    setOpen3(true);
  };

  console.log("checking vallll", userId, id, driverBal);
  return (
    <div className="padding8 padding16B">
      {cardType == 1 ? (
        driverCardStatus == 3 ? (
          <CardLinkNot />
        ) : driverCardStatus < 3 || driverCardStatus == undefined ? (
          <KycNotComplete />
        ) : (
          <div>
            <div className="lowerBorderLine">
              <div className="padding8">
                {
                  <NeoCredCard
                    driverDetails={driverDetails}
                    driverCardStatus={driverCardStatus}
                    userId={id}
                    cardType={cardType}
                  />
                }
              </div>
              <div
                className="row padding16T padding16B"
                style={{ margin: "0" }}
              >
                <div className="col-6">
                  <Button
                    className="generateOtp heading3 semiBold"
                    onClick={showModal3}
                  >
                    Add Balance
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    className="notGenerateOpt heading3 semiBold"
                    onClick={showModal2}
                  >
                    Withdraw Balance
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="row padding16 padding0B">
                            <div className="col-7 greenCircleBack flexStyling">
                                <div className="" onClick={showModal} style={{cursor:"pointer"}}>
                                    <SvgImage name="cardTransferIcon" />
                                </div>
                                <div className="text4 semiBold padding8L" >Card to card transfer</div>
                            </div>
                            <div className="col-5 greenCircleBack flexStyling">
                                <div className="" style={{cursor:"pointer"}} onClick={showModal1} >
                                    <SvgImage name="bankTobankTransfer" />
                                </div>
                                <div className="text4 semiBold padding8L">Bank transfer</div>
                            </div>
                        </div> */}
          </div>
        )
      ) : driverCardStatus === 0 ? (
        <KycNotComplete />
      ) : (
        <div>
          <div className="lowerBorderLine">
            <div className="padding8">
              {
                <M2PCardNew
                  driverDetails={driverDetails}
                  driverCardStatus={driverCardStatus}
                  userId={id}
                  cardType={cardType}
                />
              }
            </div>
            <div className="row padding16T padding16B" style={{ margin: "0" }}>
              <div className="col-6">
                <Button
                  className="generateOtp heading3 semiBold"
                  onClick={showModal3}
                >
                  Add Balance
                </Button>
              </div>
              <div className="col-6">
                <Button
                  className="notGenerateOpt heading3 semiBold"
                  onClick={showModal2}
                >
                  Withdraw Balance
                </Button>
              </div>
            </div>
          </div>
          <div className="row padding16 padding0B">
            <div
              className="col-7 greenCircleBack flexStyling"
              onClick={showModal}
            >
              <div style={{ cursor: "pointer" }}>
                <SvgImage name="cardTransferIcon" />
              </div>
              <div className="text4 semiBold padding8L">
                Card to card transfer
              </div>
            </div>
            <div className="col-5 greenCircleBack flexStyling">
              <div style={{ cursor: "pointer" }} onClick={showModal1}>
                <SvgImage name="bankTobankTransfer" />
              </div>
              <div className="text4 semiBold padding8L">Bank transfer</div>
            </div>
          </div>
        </div>
      )}

      <Card2CardTransferModal
        open={open}
        toggleModal={toggleModal}
        handleClose={handleClose}
      />
      <ManageDriverAddBalance
        open={open3}
        toggleModal={toggleModal3}
        handleClose={handleClose3}
        name="Add Balance"
        userId={id}
        driverBal={driverBal}
        cardType={cardType}
        driverName={driverDetails?.name}
      />

      <ManageDriverWithdrawBalance
        open={open2}
        toggleModal={toggleModal2}
        handleClose={handleClose2}
        name="Withdraw Balance"
        userId={id}
        driverBal={driverBal}
        cardType={cardType}
        driverDetails={driverDetails}
        driverName={driverDetails?.name}
      />

      <BankTransferModal
        open={open1}
        toggleModal={toggleModal1}
        handleClose={handleClose1}
      />
    </div>
  );
};

export default CardComponent;
