import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { Button, Modal, ModalBody } from "reactstrap";
import SvgImage from "../../../../Icons/Icons";
import { addAddressDetailsAction, createOrderAction, getAddressListAction, getOrderAction } from "../../../../../services/Store/Actions/ecomAction";
import * as Yup from "yup";
import axios from "axios";
import { Formik } from "formik";
import { Form, FormGroup, Table } from "react-bootstrap";
import PricingDetails from "./PricingDetails";
import EmiForm from "./EmiForm";
import PaymentMasterAccount from "./PaymentMasterAccount";
import { createPaymentTokenAction } from "../../../../../services/Store/Actions/receivableActions";
import { toast } from "react-toastify";
import LogipeAccountAddBalanceModal from "../../../../Modals/logipeAccountAddBalance";
import { fetchBalance } from "../../../../../services/Store/Actions/manageCardActions";



function Payment(props) {
  const { handleClose, open, toggleModal, finalAmt, coinVal, cartDetails, coinStatus, addressval, addressId} = props;
  const {toggleModalCart,  handleCloseEmi,openEmi, toggleModalEmi, showEmi, onHideEmi}= props;
  const [option, setOption]= useState(0);
  const [btn, setBtn]= useState(0);
  const [copyIcon, setCopyIcon] = useState(0);
  const [copyIconIfsc, setCopyIconIfsc] = useState(0);

  const dispatch= useDispatch();

  useEffect(()=>{
    dispatch(fetchBalance());
  },[open])


  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.userId;
  const phoneSession = obj?.user?.phone;
  const { ownerBalance } = useSelector((state) => state?.manageCard);
  const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == undefined
    ? "KYC Pending"
    :ownerBalance?.instrument_balance?.balance == null
      ? 0
      : ownerBalance?.instrument_balance?.balance;

      // open product details modal
    const [openMas, setOpenMas]= useState(false);

    const closeModalMas=()=>{
    setOpenMas(false);
    }

    const showModalMas=()=>{
    setOpenMas(true);
    }

    const toggleModalMas=()=>{
    setOpenMas(!openMas);
    }

    // open recharge MA modal
    const [openMaRecharge, setOpenMaRecharge]= useState(false);

    const closeModalMaRecharge=()=>{
      setOpenMaRecharge(false);
    }

    const showModalMaRecharge=()=>{
      setOpenMaRecharge(true);
    }

    const toggleModalMaRecharge=()=>{
      setOpenMaRecharge(!openMaRecharge);
    }

    const data=[{
        title:"Master Account",
        value:(LogipeAccounBal!== "KYC Pending"? "Available: ₹ "+LogipeAccounBal:LogipeAccounBal),
        icon:"payIcon1"
    },
    {
        title:"Pay Later",
        value:"Credit card EMI",
        icon:"payIcon2"
    },
    {
        title:"EMI",
        value:"Check EMI availability",
        icon:"payIcon3"
    }]
    const {loader}= useSelector((state)=>state?.ecom);

    // let ntime = 0;

    // function inter(id) {
    //   console.log("enterend here", id)
    //   const interval = setInterval(() => {
    //     // console.log("enterend here", id)
    //     dispatch(
    //       getOrderAction(
    //         {
    //           id: id
    //         },
    //         res => {
  
    //           console.log('LOG  1', res?.data);
  
    //           if (res?.data?.data?.status == '1') {
    //             setOrderStatus(true);
    //             handleClose1();
  
    //             setBtnDeactivated(false);
    //             handleShow();
    //             clearInterval(interval)
    //           }
  
  
    //         }
  
    //       ),
    //     );
    //     console.log("herebhai")
    //     ntime = ntime + 20000
    //     // console.log("bhaiwhat", ntime)
    //     // setTime(ntime);
    //     console.log("time here", ntime)
    //     //time = time + 20000;
    //     if (ntime == 120000) {
    //       clearInterval(interval)
    //       setOrderStatus(false);
    //       handleClose1();
    //       setBtnDeactivated(false);
    //       handleShow();
  
    //     }
  
    //   }, 20000);
    // }

  const customId = "custom-id-KYCPending page";
  const notify = () => toast.error("Kindly complete your KYC to pay from Master Account.",{
    toastId:customId
  });

  const handleClick=(val)=>{
    if (btn !== val) {
      setBtn(val);
    }
  }
    const handleSubmit=()=>{
    if(option==2){
        showEmi()
    }else if(option==0){
        if(LogipeAccounBal=="KYC Pending"){
          notify()
        }
        else if(finalAmt <= LogipeAccounBal){
          showModalMas()
        }else{
          showModalMaRecharge();
        }
        
    }
    else{
        let values1 = {
            "userId": userIdSession,
            "addressId": addressId,
            "isLogicoins": coinStatus ? 1 : 0,
            // "productId": "any",
            // "quantity": "any"
            }
        let orderId = ""
        dispatch(createOrderAction(values1, (res) => {
            console.log("create Order res", res);
            if (res?.status == 200) {
                let values2 = {
                    amount: String(finalAmt),
                    typeOfOrder: "8",
                    recieverUserId: res?.data?.data?.orderId,
                    customerPhone: phoneSession,
                    remark: res?.data?.data?.orderId
                  };

                dispatch(createPaymentTokenAction(values2, (res) => { 
                  console.log("orderid here", res)
                    if (res?.status == 200) {
                        // if (res?.data?.data?.payment_link) {
                            // localStorage.removeItem('_cart');
                            window.open(res?.data?.data?.payments?.url, '_blank');
                            console.log("orderid here", orderId)
                            toggleModalCart();
                            toggleModal();
                            // inter(orderId)()
                            // Router.push(res?.data?.data?.payment_link);
                        //}
                    }
                 }))
                 toggleModalCart();
                  toggleModal();
            }}
        ))
    }

  }
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={() => {
          //   setStatus(false);
          handleClose();
        }}
        className="positon settingModalMargin"
        toggle={() => {
          //   setStatus(false);
          toggleModal();
        }}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Pay From</p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                toggleModal();
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody className="padding8 padding0L ">
            <div className="padding16">
              {
                data?.map((item, i)=>{

                    return (
                    <div className="padding8 padding16L">
                        <div className="row optionBox padding16">
                            <div className="col-2">
                                <SvgImage name={item?.icon} />
                            </div>
                            <div className="col-8">
                                <div className="Bold heading3">{item?.title}</div>
                                <div className="Medium greyFont heading4 padding8T">{item?.value}</div>
                            </div>
                            <div className="col-2" onClick={()=>{
                                setOption(i);
                            }}>
                                <SvgImage name={(i==option)?"SelectedRadio":"UnselectedRadio"} />
                            </div>
                        </div>
                    </div>
                    )
                })
              }
            </div>
            <div className="padding16 padding24L" style={{paddingTop:"3.5rem"}}>
                <PricingDetails
                    HomeMrp={cartDetails?.amount}
                    HomeDiscount={cartDetails?.discount}
                    coinVal={coinVal}
                    coinStatus={coinStatus}
                    />
            </div>
            <div className="padding16 padding8T"  style={{marginLeft:"0.7rem"}}>
                <div className="cartItem flexStyling padding16 Medium heading3" style={{borderRadius:"0.5rem"}} 
                >
                <SvgImage name="gpsLogo" />
                <div className="padding16L padding32R">{addressval.length>40?addressval.substring(0,40)+"...":addressval}</div>
                <div style={{cursor:"pointer", position:"absolute", right:"2.5rem"}}>
                    <SvgImage name="nextArrow" />
                </div>
                </div>
            </div>
            <div className="padding16"  style={{marginLeft:"0.7rem"}}>
                <Button className="butt4" 
                    onClick={handleSubmit}
                    disabled={loader ? true :false}
                    style={{
                    background:
                    loader
                    ? "#EFEFEF"
                    : "#485B7C",
                    color:
                    loader
                    ? "#B5B5B5"
                    : "#ffffff",
                    margin:"0", width:"100%"
                    }}
                    >
                    {loader ? "Please Wait" :option==0? (finalAmt <= LogipeAccounBal? ("Pay ₹ "+ finalAmt):"Recharge Master Account"):("Pay ₹ "+ finalAmt)}
                    </Button>
            </div>
          </ModalBody>
        </div>
      </Modal>
      <EmiForm
        open={openEmi}
        onHide={onHideEmi}
        toggleModal={toggleModalEmi}
        handleClose={handleCloseEmi}
        toggleModalCart={toggleModalCart}
        toggleModalPay={toggleModal}
        addressId={addressId}
        coinStatus={coinStatus}
        />
       <PaymentMasterAccount
        open={openMas}
        onHide={closeModalMas}
        toggleModal={toggleModalMas}
        handleClose={closeModalMas}
        toggleModalCart={toggleModalCart}
        toggleModalPay={toggleModal}
        addressId={addressId}
        coinStatus={coinStatus}
        />
        <LogipeAccountAddBalanceModal
            copyIconIfsc={copyIconIfsc}
            setCopyIconIfsc={setCopyIconIfsc}
            copyIcon={copyIcon}
            setCopyIcon={setCopyIcon}
            open={openMaRecharge}
            toggleModal={toggleModalMaRecharge}
            title="Master Account"
            handleClick={handleClick}
            handleClose={closeModalMaRecharge}
            btn={btn}
            ownerLogipeBalance={LogipeAccounBal}
          />
    </div>
  );
}

export default Payment;
