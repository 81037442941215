import {
  OMC_BALANCE,
  OMC_BALANCE_SUCCESS,
  OMC_LOAD_MONEY,
  OMC_LOAD_MONEY_SUCCESS,
  OMC_TRANSACTIONS,
  OMC_TRANSACTIONS_SUCCESS,
  OMC_VEHICLE_LIST,
  OMC_VEHICLE_LIST_SUCCESS,
  OMC_VEH_STATUS,
  OMC_VEH_STATUS_SUCCESS,
  OMC_WITHDRAW_MONEY,
  OMC_WITHDRAW_MONEY_SUCCESS,
  OMC_DRIVER_DATA_SUCCESS,
  OMC_DRIVER_DATA,
  OMC_NUMBER_CHANGE_SUCCESS,
  OMC_NUMBER_CHANGE,
  OMC_REGISTER_DRIVER_SUCCESS,
  OMC_REGISTER_DRIVER,
  CLEAN_OMC_TRANSACTIONS, 
  CLEAN_OMC_LOADER,
  DRIVER_ADD_BENEFICIARY,
  DRIVER_ADD_BENEFICIARY_SUCCESS,
  GET_DRIVER_BENEFICIARY,
  GET_DRIVER_BENEFICIARY_SUCCESS,
  GET_CITY_STATE,
  GET_CITY_STATE_SUCCESS,
  CREATE_TRIP_SUCCESS,
  DRIVER_BANK_TRANSFER,
  DRIVER_BANK_TRANSFER_SUCCESS,
  ADD_DRIVER_CARD_BENEFICIARY,
  ADD_DRIVER_CARD_BENEFICIARY_SUCCESS,
  FUEL_WALLET_RECHARGE_SUCCESS,
  FUEL_WALLET_RECHARGE,
  GET_FUEL_WALLET_BAL,
  GET_FUEL_WALLET_BAL_SUCCESS,
  GET_FUEL_WALLET_HISTORY,
GET_FUEL_WALLET_HISTORY_SUCCESS,
FUEL_WALLET_LIMIT_UPDATE,
FUEL_WALLET_LIMIT_UPDATE_SUCCESS,
} from "../storeTypes";



export const driverTransferToBeneficiaryAction = (id, data, callback) => {
  return {
    type: DRIVER_BANK_TRANSFER,
    userId:id,
    payload:data,
    callback
  };
};

export const driverTransferToBeneficiaryActionSuccess = (data) => {
  return {
      type: DRIVER_BANK_TRANSFER_SUCCESS,
      payload:data,
  };
};



export const addCardBeneficiaryAction = (data, callback) => {
  return {
    type: ADD_DRIVER_CARD_BENEFICIARY,
    payload:data,
    callback
  };
};

export const addCardBeneficiaryActionSuccess = (data) => {
  return {
      type: ADD_DRIVER_CARD_BENEFICIARY_SUCCESS,
      payload:data,
  };
};


export const driverAddBeneficiaryAction = (data, callback) => {
  return {
    type: DRIVER_ADD_BENEFICIARY,
    payload:data,
    callback
  };
};

export const driverAddBeneficiaryActionSuccess = (data) => {
  return {
      type: DRIVER_ADD_BENEFICIARY_SUCCESS,
      payload:data,
  };
};



export const getDriverBeneficiaryAction = (data) => {
  return {
    type: GET_DRIVER_BENEFICIARY,
    payload:data,
  };
};

export const getDriverBeneficiaryActionSuccess = (data) => {
  return {
      type: GET_DRIVER_BENEFICIARY_SUCCESS,
      payload:data,
  };
};



export const getCityAndStateAction = (data) => {
  return {
    type: GET_CITY_STATE,
    payload:data,
  };
};

export const getCityAndStateActionSuccess = (data) => {
  return {
      type: GET_CITY_STATE_SUCCESS,
      payload:data,
  };
};



export const cleanOmcTransactionsAction = () => {
  return {
    type: CLEAN_OMC_TRANSACTIONS,
  };
};


export const cleanOmcLoaderAction = () => {
  return {
    type: CLEAN_OMC_LOADER,
  };
};

export const omcRegisterDriverAction = (data, callback) => {
  return {
    type: OMC_REGISTER_DRIVER,
    payload:data,
    callback
  };
};

export const omcRegisterDriverActionSuccess = (data) => {
  return {
      type: OMC_REGISTER_DRIVER_SUCCESS,
      payload:data,
  };
};


export const omcChangeNumAction = (data, callback) => {
    return {
      type: OMC_NUMBER_CHANGE,
      payload:data,
      callback
    };
  };
  
export const omcChangeNumActionSuccess = (data) => {
    return {
        type: OMC_NUMBER_CHANGE_SUCCESS,
        payload:data,
    };
};


export const omcDriverDataAction = (id) => {
  console.log("api - step getting info--1",id);
    return {
      type: OMC_DRIVER_DATA,
      driverId:id
    };
  };
  
export const omcDriverDataActionSuccess = (data) => {
    return {
        type: OMC_DRIVER_DATA_SUCCESS,
        payload:data,
    };
};


export const getOmcVehiclesListAction = () => {
  return {
    type: OMC_VEHICLE_LIST,
  };
};

export const getOmcVehiclesListActionSuccess = (data) => {
  return {
    type: OMC_VEHICLE_LIST_SUCCESS,
    payload:data,
  };
};

export const omcVehicleBalanceAction = (id) => {
  console.log("delete action", id);
  return {
    type: OMC_BALANCE,
    userId:id,
  };
};

export const omcVehicleBalanceActionSuccess = (data) => {
  return {
    type: OMC_BALANCE_SUCCESS,
    payload:data,
  };
};

export const omcVehicleTransactionAction = (id, data) => {
  console.log("delete action", id);
  return {
    type: OMC_TRANSACTIONS,
    userId: id,
    payload:data
  };
};

export const omcVehicleTransactionActionSuccess = (payload) => {
  return {
    type: OMC_TRANSACTIONS_SUCCESS,
    payload,
  };
};

export const omcLoadMoneyAction = (data, callback) => {

  return {
    type: OMC_LOAD_MONEY,
    payload: data,
    callback
  };
};

export const omcLoadMoneyActionSuccess = (payload) => {
  return {
    type: OMC_LOAD_MONEY_SUCCESS,
    payload,
  };
};
//////

export const fuelwalletRechargeAction = (data, callback) => {

  return {
    type: FUEL_WALLET_RECHARGE,
    payload: data,
    callback
  };
};

export const fuelwalletRechargeActionSuccess = (payload) => {
  return {
    type: FUEL_WALLET_RECHARGE_SUCCESS,
    payload,
  };
};

/////

export const omcWithdrawMoneyAction = (data, callback) => {

  return {
    type: OMC_WITHDRAW_MONEY,
    payload: data,
    callback
  };
};

export const omcWithdrawMoneyActionSuccess = (payload) => {
  return {
    type: OMC_WITHDRAW_MONEY_SUCCESS,
    payload,
  };
};

export const omcGetVehicleStatusAction = (id) => {
  console.log("delete action", id);
  return {
    type: OMC_VEH_STATUS,
    userId: id,
  };
};

export const omcGetVehicleStatusActionSuccess = (payload) => {
  return {
    type: OMC_VEH_STATUS_SUCCESS,
    payload,
  };
};


// get fuel wallet balance
export function getFuelWalletBal(data){
  return{
    type:GET_FUEL_WALLET_BAL,
    payload: data,
  }
}
export function getFuelWalletbalSuccess(data, callback){
  return{
    type:GET_FUEL_WALLET_BAL_SUCCESS,
    payload: data,
    callback
  }
}

export function getFuelWalletHistory(data){
  return{
    type:GET_FUEL_WALLET_HISTORY,
    payload: data,
  }
}
export function getFuelWalletHistorySuccess(data){
  return{
    type:GET_FUEL_WALLET_HISTORY_SUCCESS,
    payload: data,
  }
}

export function fuelWalletLimitUpdate(data,callback){
  return{
    type:FUEL_WALLET_LIMIT_UPDATE,
    payload: data,
    callback
  }
}
export function fuelWalletLimitUpdateSuccess(data){
  return{
    type:FUEL_WALLET_LIMIT_UPDATE_SUCCESS,
    payload: data
  }
}
