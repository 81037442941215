import React, { useEffect, useState } from "react";
import SvgImage from "../../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { cleanLoginLoader, handleLoginSuccess,loginWithPassword } from "../../services/Store/Actions/LoginAction";
import { sendAnalitics } from "../../services/Store/Actions/appActions";
import { Mixpanel } from "../../Mixpanel";
import { storeDataToAsyncStorage } from "../../services/Utils/functions";
import { useNavigate } from "react-router-dom";


const LoginScreenPassword = (props) => {
  const {firstName, middleName, lastName, toggle, setStatus}= props;
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required("Phone number must contain 10 digits")
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Phone number must contain 10 digits"
      ),
    password:Yup.string()
    .matches(
      /^[A-Za-z0-9]+$/,
      "Password must contain one number and one alphabet. No special character is allowed."
    ).test('passwordLen11', 'Password must have atleast 8 characters', val => val.length>=8),
  });

  const {handleSignup}= props;
  const [pwType, setPwType]= useState("password");
  const [showText, setShowText]= useState("ShowEyeIcon");
  const { loader } = useSelector((state) => state?.loginReducer);
  const dispatch = useDispatch();
  const Navigate= useNavigate();

  useEffect(()=>{
    dispatch(sendAnalitics("LoginPage_web"));
  },[])

  const togglePassword=()=>{
    if(pwType=="password"){
      setPwType("text");
      setShowText("HideEyeIcon")
    }else{
      setPwType("password");
      setShowText("ShowEyeIcon")
    }
  }
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        phoneNumber: "",
        password : ""
      }}
      onSubmit={(values, actions) => {
        //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
        console.log("valuessss", values);
        dispatch(sendAnalitics("GetOTP_web", values));
        dispatch(
          loginWithPassword(
            {
              phone: values.phoneNumber,
              password: values.password,
            },
            (res) => {
              //actions.setSubmitting(false);
              console.log("SUCCESS 1",res?.response?.status, typeof(res?.response?.status), res);
              if (res?.response?.status == 400) {
                actions.setFieldError("password", "Incorrect password. Please check again.");
                dispatch(cleanLoginLoader());
              }else if (res?.data?.data?.user?.userId) {
                  console.log("TOKEN>>>", res.data?.data?.user?.type);
                  if (res.data?.data?.user?.type === "2") {
                    storeDataToAsyncStorage("_token", res.data?.data.token);
                    storeDataToAsyncStorage("_user", res.data?.data);
                    storeDataToAsyncStorage("_branchName", res.data?.data?.branch?.branchName);
                    storeDataToAsyncStorage("_branchId", res.data?.data?.branch?.branchId);
                    storeDataToAsyncStorage(
                      "_refreshToken",
                      res.data?.data.refreshToken
                    );
                    const x =  Mixpanel.getDistinctId();
                    if (x != res?.data?.data?.user?.userId) {
                      Mixpanel.alias(res?.data?.data?.user?.userId, x);
                    }
                    Mixpanel.identify(res.data?.data?.user?.userId);
                    Mixpanel
                      .people.set({
                        'Phone': res.data?.data?.user?.phone,
                        '$name': res.data?.data?.user?.fullName,
                        'userId': res?.data?.data?.user?.userId,
                      });
                    //dispatch(getOwnerAccountDetails(),res => {});
                    dispatch(handleLoginSuccess(res.data?.data));
                    //dispatch(getOwnerDetails());
                  
                    // Navigate("/logimart");
                    Navigate("/home");
                    // toggle();
                  } else if (res.data?.data?.user?.type === "1") {
                    //setError(true);
                    actions.setFieldError("password", "Cannot login through Driver's number");
                  }else if (res.data?.data?.user?.type === "3") {
                      //setError(true);
                      storeDataToAsyncStorage("_token", res.data?.data.token);
                      storeDataToAsyncStorage("_user", res.data?.data);
                      storeDataToAsyncStorage("_branchName", res.data?.data?.branch?.branchName);
                      storeDataToAsyncStorage("_branchId", res.data?.data?.branch?.branchId);
                      storeDataToAsyncStorage(
                        "_refreshToken",
                        res.data?.data.refreshToken
                      );
                      const x =  Mixpanel.getDistinctId();
                      if (x != res?.data?.data?.user?.userId) {
                        Mixpanel.alias(res?.data?.data?.user?.userId, x);
                      }
                      Mixpanel.identify(res.data?.data?.user?.userId);
                      Mixpanel
                        .people.set({
                          'Phone': res.data?.data?.user?.phone,
                          '$name': res.data?.data?.user?.fullName,
                          'userId': res?.data?.data?.user?.userId,
                        });
                      //dispatch(getOwnerAccountDetails(),res => {});
                      dispatch(handleLoginSuccess(res.data?.data));
                      //dispatch(getOwnerDetails());
                    
                      Navigate("/home");
              }  
                }
              
              //             res.data?.data.refreshToken
              //           );
              //           dispatch(handleLoginSuccess(res.data?.data));
              //           //dispatch(getOwnerDetails());
              //           Navigate("/logimart");
              //           toggle();
              //         }))
              //       }
                  
              //   }))
              // }
            }
          )
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} className="InputForm3">
          <FormGroup className="formPosition">
            <div className="Regular text3 greyFont">Welcome! 👋</div>
            <div className="heading1 semiBold">Sign in to your account</div>
            <div className="Regular text3 padding24T headText2">
              Phone number
            </div>
            <div className="padding16 padding8T" style={{paddingLeft:"0.7rem"}}>
              <div className="row phoneNumberBox padding8">
                {/* <div
                  className="col-2 padding0L boxWithBorder"
                  style={{ textAlign: "center" }}
                >
                  <SvgImage name="countryCode" />
                </div> */}
                <div className="col-9 padding0L" style={{ marginLeft: "1rem" }}>
                  <input
                    type="text"
                    id="phoneNumber"
                    className="input-body-4 Regular text3"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    touched={touched.phoneNumber}
                    errors={errors.phoneNumber}
                    onBlur={()=>{
                      dispatch(sendAnalitics("EnterPhone_web", values));
                      handleBlur("phoneNumber");
                    }}
                    onChange={handleChange("phoneNumber")}
                    style={{
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "210px",
                    }}
                    placeholder="Enter phone number"
                    maxLength="10"
                    autofocus
                  ></input>
                </div>
              </div>
            </div>
            <div
              className="text4"
              style={{
                // margin: "7px 0 0px 50px",
                color: "red",
                textAlign: "left",
              }}
            >
              {errors.phoneNumber && touched.phoneNumber ? (
                <div>{errors.phoneNumber}</div>
              ) : null}
            </div>
            <div className="Regular text3 headText2 flexStyling">
              <div>Password</div>
              <div
                  className=" padding0R padding8L Tooltip"
                  style={{ textAlign: "right", cursor:"pointer" }}
                >
                  <SvgImage name="infoIcon" width={18} height={18}  />
                  <span className="tooltiptext" style={{marginLeft:"1rem"}}>Password should be at least 8 characters long and must contain one alphabet and one number. No special character is allowed.</span>
                </div>
            </div>
            <div className="padding16 padding8T padding8B" style={{paddingLeft:"0rem"}}>
              <div className="phoneNumberBox padding8 row margin0" >
                {/* <div
                  className="col-2 padding0L boxWithBorder"
                  style={{ textAlign: "center" }}
                >
                  <SvgImage name="countryCode" />
                </div> */}
                
                  <input
                    type={pwType}
                    id="password"
                    className="input-body-4 Regular text3 col-10"
                    name="password"
                    value={values.password}
                    touched={touched.password}
                    errors={errors.password}
                    onBlur={()=>{
                      dispatch(sendAnalitics("EnterPhone_web", values));
                      handleBlur("password");
                    }}
                    onChange={handleChange("password")}
                    style={{
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "83%",
                    }}
                    placeholder="Enter Password"
                    autofocus
                  ></input>
                <div className="col-2 semiBold showPW1" onClick={togglePassword} ><SvgImage name={showText} /></div>
              </div>
            </div>
            <div
              className="text4"
              style={{
                // margin: "7px 0 0px 50px",
                color: "red",
                textAlign: "left",
              }}
            >
              {errors.password && touched.password ? (
                <div>{errors.password}</div>
              ) : null}
            </div>
            <div className="semiBold text3 forgotText" onClick={()=>{
              setStatus("6");
            }}>Create Password?</div>
            <Button
              type="submit"
              className="loginButton semiBold text2"
              style={{
                marginTop: "1rem",
                background:
                  values.phoneNumber.length < 10
                    ? "#EFEFEF"
                    : loader
                    ? "#EFEFEF"
                    : "#485B7C",
                color:
                  values.phoneNumber.length < 10
                    ? "#B5B5B5"
                    : loader
                    ? "#B5B5B5"
                    : "#ffffff",
              }}
              disabled={loader ? true : false}
            >
              {loader ? "Please Wait" : "Login"}
            </Button>
            <div className="row padding16 padding8T padding8B">
                  <hr className="col-5 margin10T"/><div className="col-1 padding0 centerText1">OR</div><hr className="col-5 margin10T marginLeftVal" />
            </div>
            <button 
            type="button"
            className="newUserBtn semiBold text2 padding8" 
            onClick={()=>{
              console.log("HEY 2");
              props.setIsRegistered(true);
              props.setStatus("0");
              // props.setRegStatus(0);
              // setIsRegistered(false);
              // setStatus("0");
              // props.signUpHandle()
              }} 
              >
              Login With OTP
            </button>
            <div className="Regular heading4 col-12 padding16" style={{textAlign:"center"}}>
                    Don't have an account?{" "}
                    <span
                      className="semiBold "
                      style={{ color: "#485B7C", cursor: "pointer" }}
                      onClick={() => {
                        props.setIsRegistered(false);
                        props.setStatus("0");
                      }}
                    >
                      Sign Up
                    </span>
                  </div>
            {/* <div className="row padding16">
                  <hr className="col-5 margin10T"/><div className="col-1 padding0 centerText1">OR</div><hr className="col-5 margin10T marginLeftVal" />
            </div>
            <button 
            type="button"
            className="newUserBtn semiBold text2 padding8" 
            onClick={()=>{
              console.log("HEY 2");
              handleSignup();
              // setIsRegistered(false);
              // setStatus("0");
              // props.signUpHandle()
              }} 
              >
              Create New Account
            </button> */}
            {/* <div className="bottomTextLogin padding16T Regular text4">
              <span
                className="bottomTextSpan Medium"
                onClick={() => {
                  props.handleTermShow();
                }}
              >
                T&C{" "}
              </span>{" "}
              and{" "}
              <span
                className="bottomTextSpan Medium"
                onClick={() => {
                  props.handlePrivacyShow();
                }}
              >
                Privacy Policy
              </span>{" "}
              apply
            </div> */}
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default LoginScreenPassword;
