import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import { io } from "socket.io-client";

// Fix for default marker icon
// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });
const customIcon = new L.Icon({
  iconUrl: require("../../../assets/images/truck-32.png"), // Adjust the path as needed
  iconSize: [35, 35], // Size of the icon
  iconAnchor: [17, 35], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -35], // Point from which the popup should open relative to the iconAnchor
});

const ZoomToNewRoute = ({ position }) => {
  const map = useMap();
  useEffect(() => {
    if (position) {
      map.flyTo(position, 13, { duration: 1 });
    }
  }, [position, map]);

  return null;
};

function Map(props) {
  const { path, setPath } = props;
  //   const [path, setPath] = useState([[props.latt, props.longg]]);
  console.log("jkkkklllllll", props, path);
  // Simulate live data updates
  let socket;
  var y = {};
  useEffect(() => {
    console.log("lllllllkkkkkk");

    let token2 = sessionStorage.getItem("_token");
    token2 = JSON.parse(token2);
    socket = io("https://gke-socket.logipe.com/", {
      query: { token: token2 },
      transports: ["websocket"],
    });
    console.log("lllllllkkkkkk", socket, token2);

    socket.on("ping", (data) => {
      console.log("pinnnng");
    });
    socket.on("vehicleTrackingResponse", (data1) => {
      console.log("vehicleTrackingResponseqkkkkk", data1);
    });
    socket.on("connect", (data) => {
      console.log("connect");
      global.socket = socket;
    });

    //   console.log("34444443333",allFleetTrackerList?.length);
    const payload = [];
    const fleetToUser = {};
    if (props.fleetId) {
      payload.push({ fleetId: props.fleetId, vrn: props.regNumber });
      //   fleetToUser[allFleetTrackerList[i]?.userId] = allFleetTrackerList[i]?.fleetId
      //   y[allFleetTrackerList[i]?.fleetId]={
      //     fleetId:allFleetTrackerList[i]?.fleetId,
      //     vrn:allFleetTrackerList[i]?.regNumber,
      //     fastagBalance:allFleetTrackerList[i]?.fastagBalance,
      //     driverPhone:allFleetTrackerList[i]?.driverPhone,
      //     userId:allFleetTrackerList[i]?.userId,
      //     balance:allFleetTrackerList[i]?.walletBalance,
      //     cashBalance:allFleetTrackerList[i]?.cashBalance,
      //     driverName:allFleetTrackerList[i]?.driverName,
      //     plazaName:allFleetTrackerList[i]?.plazaName,
      //     lat:allFleetTrackerList[i]?.lat,
      //     long:allFleetTrackerList[i]?.long,
      //     remark:allFleetTrackerList[i]?.remark,
      //     fastagStatus:allFleetTrackerList[i]?.fastagStatus,
      //     status:allFleetTrackerList[i]?.status,
      //     source:allFleetTrackerList[i]?.source,
      //     destination:allFleetTrackerList[i]?.destination,
      //     tripId:allFleetTrackerList[i]?.tripId,
      //     customerName:allFleetTrackerList[i]?.company,
      //     omcBalance:allFleetTrackerList[i]?.omcBalance,
      //     cashExpanse:allFleetTrackerList[i]?.cashExpanse,
      //     cardExpanse:allFleetTrackerList[i]?.cardExpanse,
      //     fastagExpanse:allFleetTrackerList[i]?.fastagExpanse,
      //   }
    }
    console.log("qweqwe111111", Object.values(y), payload);
    // socket.emit("vehicleTracking", payload);

    // setVehicleData(Object.values(y))
    let interval;
    // useEffect(()=>{

    // setTimeout(()=>{
    //   global.socket = socket
    // },1000)
    // },[vehicleData])
    setTimeout(() => {
      console.log("vehicleTrackingResponse77777sdddd77");
      socket.on("vehicleTrackingResponse", (data1) => {
        console.log("vehicleTrackingResponse7777777", data1, y, path);
        console.log("bjsdibibsdiufbibi", data1, path);
        if (data1?.fleetId && data1?.lat && data1?.long) {
          setPath((prevPath) => {
            console.log("kkkkkjjjjjj", data1, prevPath);
            // if(prevPath[prevPath.length-1][0] != data1?.lat && prevPath[prevPath.length-1][1] != data1?.long){
            const newPos = [data1?.lat, data1?.long];
            return [...prevPath, newPos];
            // }
            // const lastPos = prevPath[prevPath.length - 1];
            // Generate a new position by slightly modifying the last position
          });
        }
        console.log("qwewqqeqweqwe", y, Object.values(y));
        // setVehicleData(Object.values(y))
      });

      console.log("vehicleTracking");
      socket.emit("vehicleTracking", payload);
      interval = setInterval(() => {
        socket.emit("vehicleTracking", payload);
        console.log("kjkkkkk", payload);
      }, 20000);
    }, 1000);

    return () => {
      setPath([[]]);
      clearInterval(interval);
    };
  }, [props.fleetId]);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setPath((prevPath) => {
  //         const lastPos = prevPath[prevPath.length - 1];
  //         // Generate a new position by slightly modifying the last position
  //         const newPos = [
  //           lastPos[0] + Math.random() * 0.001 - 0.0005,
  //           lastPos[1] + Math.random() * 0.001 - 0.0005,
  //         ];
  //         return [...prevPath, newPos];
  //       });
  //     }, 1000);

  //     return () => clearInterval(interval);
  //   }, []);

  return (
    <div className="map-container">
      <MapContainer
        center={[props.latt, props.longg]}
        zoom={13}
        style={{ height: "700px", width: "100%" }}
        scrollWheelZoom={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {/* Marker for the latest position */}
        {path?.length > 0 ? (
          <>
            <ZoomToNewRoute position={path[0]} />
            <Marker position={path[path?.length - 1]} icon={customIcon} />
            <Polyline positions={path} color="green" />{" "}
          </>
        ) : (
          <></>
        )}
      </MapContainer>
    </div>
  );
}

export default Map;
