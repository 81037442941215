import React from "react";
import { Formik, ErrorMessage } from "formik";
import { Button, Modal, ModalBody, Form } from "reactstrap";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import SvgImage from "../../../Icons/Icons";
import "./index.css";

import { useDispatch } from "react-redux";

const EnterPinModal = (props) => {
  const dispatch = useDispatch();
  const { open, toggleModal, handleClose, onSubmit } = props;
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="modal-dialog-centered"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-head-part">
          <p className="Add-balance-title">Enter Pin</p>
          <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={{
              PIN: "",
            }}
            validationSchema={Yup.object().shape({
              PIN: Yup.string()
                .required("* Required")
                .matches(/^[0-9]{4}$/, "* PIN Must be exactly 4 digits"),
            })}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <div></div>
                <div>
                  <div className="sub-title-1">
                    <p className="sub-head-4">Enter 4-digit Security Pin</p>
                  </div>
                  <p className="sub-head-body">
                    Security PIN is needed to confirm payment
                  </p>
                  <PinInput
                    length={4}
                    initialValue=""
                    secret
                    onChange={(value, index) =>
                      setFieldValue("PIN", value, true)
                    }
                    type="numeric"
                    inputMode="number"
                    style={{
                      padding: "10px",
                      marginTop: "28px",
                      textAlign: "center",
                    }}
                    inputStyle={{
                      border: "1px solid #A7A7A7",
                      borderRadius: "8px",
                      marginRight: "20px",
                    }}
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  <div
                    style={{
                      color: "#dc3545",
                      fontSize: "14px",
                      marginLeft: "6.5rem",
                    }}
                    className={
                      +(errors.PIN && touched.PIN ? " is-invalid" : "")
                    }
                  >
                    {" "}
                    <ErrorMessage className="is-invalid" name="PIN" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      type="submit"
                      className="butt4"
                      style={{ marginTop: 32, marginBottom: 20, marginLeft: 0 }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default EnterPinModal;
