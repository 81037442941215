import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustomersAction,
  clearAllCustomers,
} from "../../services/Store/Actions/customerAction";
import ToastV2 from "../subComponents/toastV2";
import { toastStatus } from "../../services/Store/Actions/appActions";
import SalesHomePage from "./SubComponents/SalesHomePage";
import SvgImage from "../Icons/Icons";
import "./index.css";
import { BallTriangle } from "react-loader-spinner";
import AddLr from "../TripsMainV2/Components/AddLr";
import { Box } from "@mui/material";
import AssignVehicle from "./SubComponents/AssignVehicle";
import CreateBranch from "./SubComponents/CreateBranch";
import ChangeLimit from "./SubComponents/ChangeLimit";
import AssignDriver from "./SubComponents/AssignDriver";
import AssignManager from "./SubComponents/AssignManager";
import {
  deleteBranch,
  getBranch,
  getBranchDriver,
  getBranchFleet,
  getBranchManager,
} from "../../services/Store/Actions/UserManagementAction";
import ConfirmationModal from "./SubComponents/ConfirmationModal";

const moment = require("moment");
const customerImg = require("../../assets/images/Screenshot 2023-10-07 at 10.06.46 PM.png"); // with require

function Branches(props) {
  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);
  const { sideBar, salesHome, setSalesHome } = props;
  const [createCust, setCreateCust] = useState(false);
  const [limit, setLimit] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [branchId, setBranchId] = useState("");
  const [branchLimit, setBranchLimit] = useState(0);
  const [offset, setOffset] = useState(offsetVal);
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const { customerList, customerNo, newCust } = useSelector(
    (state) => state?.customer
  );
  if (customerNo && customerNo != "" && customerNo != 0) {
    setSalesHome(false);
  }
  const { branchData, loader } = useSelector((state) => state?.userManagement);
  console.log("===pppp>>>>", branchData, loader);
  function toggleCreateCust() {
    setCreateCust(!createCust);
  }

  function toggleSetLimit() {
    setLimit(!limit);
  }

  const [showAssignVehicle, setshowAssignVehicle] = useState(false);
  const [showAssignDriver, setshowAssignDriver] = useState(false);
  const [showAssignManager, setshowAssignManager] = useState(false);

  function toggleAssignVehicle() {
    dispatch(
      getBranchFleet({}, (res) => {
        console.log("nskdnkdndsns", res);
      })
    );
    setshowAssignVehicle(!showAssignVehicle);
  }

  function toggleAssignDriver() {
    dispatch(
      getBranchDriver({}, (res) => {
        console.log("nskdnkdndsns", res);
      })
    );
    setshowAssignDriver(!showAssignDriver);
  }

  function toggleAssignManager() {
    dispatch(
      getBranchManager({}, (res) => {
        console.log("nskdnkdndsns", res);
      })
    );
    setshowAssignManager(!showAssignManager);
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [refresh, setRefresh] = useState(false);
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastModal, setToastModal] = useState(false);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  useEffect(() => {
    console.log("snsbbsbs");
  }, [authUser, managerControlList]);
  console.log("loader && ", loader, companyName == "", phone == "");
  useEffect(() => {
    console.log("ainabs");
    dispatch(
      getBranch({}, (res) => {
        console.log("snjsbsbs", res);
      })
    );

    return function cleanUp() {};
  }, [refresh]);

  const handlePrevButton = () => {
    if (offset > 0) {
      setOffset(offset - 10);
      dispatch(
        getAllCustomersAction({
          offset: offset - 10,
          companyName: companyName,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          // limit: "12",
        })
      );
    }
  };

  return (
    <div className="topMargin">
      {loader && companyName == "" && phone == "" ? (
        <>
          <div
            className="container sideBarMargin"
            style={{
              marginLeft: sideBar ? "230px" : "100px",
              maxWidth: sideBar ? windowWidth - 260 : windowWidth - 130,
              // height: "100vh",
              backgroundColor: "#FFFFFF",
              height: 400,
            }}
          >
            <div
              className="boxContainer"
              style={{
                padding: 70,
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 130,
              }}
            >
              <BallTriangle
                height={70}
                width={70}
                radius={5}
                color="#ED752E"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {
            <div
              className="container sideBarMargin"
              style={{
                marginLeft: sideBar ? "230px" : "100px",
                maxWidth: sideBar ? windowWidth - 260 : windowWidth - 130,
                // height: "100vh",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div>
                <div className="custText">Branches</div>
                <div className="d-flex justify-content-between mx-4">
                  <div className="d-flex">
                    <div className="d-flex">
                      <div className="col-12 custNamepadd" style={{}}>
                        {/* <strong>Branch Name:</strong>
                        <> sjhvjshjsv jshjsh</>
                        <strong
                          style={{
                            marginLeft: "20px",
                          }}
                        >
                          Total Branch:
                        </strong>
                        <> dhd</>
                        <br /> */}
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <button
                            className="greenButton Medium text3 AddCustStartBtn"
                            onClick={toggleAssignVehicle}
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            <div className="AddCust">Assign Vehicle</div>
                          </button>
                          <button
                            className="greenButton Medium text3 AddCustStartBtn"
                            onClick={toggleAssignDriver}
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            <div className="AddCust">Assign Driver</div>
                          </button>
                          <button
                            className="greenButton Medium text3 AddCustStartBtn"
                            onClick={toggleAssignManager}
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            <div className="AddCust">Assign Manager</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="addCustcenter">
                    <button
                      className="greenButton Medium text3 AddCustStartBtn"
                      onClick={toggleCreateCust}
                    >
                      <div className="AddCust">+ Create Branch</div>
                    </button>
                  </div>
                </div>

                {branchData?.rows?.map((data, i) => (
                  <div
                    style={{
                      marginTop: "32px",
                      border: "1px solid rgb(46, 124, 123)",
                      borderRadius: "30px",
                      backgroundColor: "#F2F1EB",
                    }}
                  >
                    <div
                      className="detailImgContainer row"
                      style={{
                        marginBottom: "-32px",
                      }}
                    >
                      <div className="col-12 d-flex justify-content-between align-items-center">
                        <div
                          className="detailImgContainer row"
                          style={
                            {
                              // flexDirection: "row",
                            }
                          }
                        >
                          <div>
                            <div style={{ marginTop: "25px" }}>
                              <a>
                                <strong>Branch Name:</strong>
                              </a>
                              <a>{`    ${data?.branchName}`}</a>
                              <br />
                              <a>
                                <strong>Branch Address:</strong>
                              </a>
                              <a>{`     ${data?.address1 || ""}, ${
                                data?.address2 || ""
                              }, ${data?.city || ""}, ${data?.state || ""}, ${
                                data?.pin || ""
                              }`}</a>
                              <br />
                              {/* <a>
                      <strong>Total Managers:</strong>
                    </a>
                    <a>{"   10"}</a>
                    <br /> */}
                              <a>
                                <strong>Limit type:</strong>
                              </a>
                              <a>{`   ${
                                data?.limitType == 0
                                  ? "One Time"
                                  : data?.limitType == 1
                                  ? "Daily Limit"
                                  : "Monthly Limit"
                              }`}</a>
                              <br />
                              <a>
                                <strong>Limit:</strong>
                              </a>
                              <a>{`   ${data?.limit || "0"}`}</a>
                            </div>
                          </div>

                          <div
                            style={{
                              alignItems: "flex-end",
                              alignContent: "flex-end",
                              marginRight: "-300px",
                              marginTop: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "right",
                                marginTop: "10px",
                                // marginRight: "-100px",
                              }}
                            >
                              <Button
                                type="submit"
                                className="loginButton"
                                style={{
                                  background: "#485B7C",
                                  color: "#ffffff",
                                  marginBottom: "0.5rem",
                                }}
                                disabled={false}
                                onClick={() => {
                                  console.log("s jsnjsnskns", data);
                                  setBranchId(data?.branchId);
                                  setBranchLimit("1000");
                                  toggleSetLimit();
                                }}
                              >
                                {"Set Limit"}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "24px",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setConfirmationModal(true);
                            setBranchId(data?.branchId);
                          }}
                        >
                          Delete Branch
                          <SvgImage name="deleteIcon2" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <CreateBranch
                toggle={toggleCreateCust}
                modal={createCust}
                setModal={setCreateCust}
              />
              <ChangeLimit
                toggle={toggleSetLimit}
                modal={limit}
                setModal={setLimit}
                branchId={branchId}
                limit={branchLimit}
              />

              <AssignVehicle
                toggle={toggleAssignVehicle}
                modal={showAssignVehicle}
                setModal={setshowAssignVehicle}
                branches={["aaa", "bbb", "ccc", "ddd"]}
              />

              <AssignDriver
                toggle={toggleAssignDriver}
                modal={showAssignDriver}
                setModal={setshowAssignDriver}
                branches={["aaa", "bbb", "ccc", "ddd"]}
              />

              <AssignManager
                toggle={toggleAssignManager}
                modal={showAssignManager}
                setModal={setshowAssignManager}
                branches={["aaa", "bbb", "ccc", "ddd"]}
              />

              {/* <PaymentsModal 
          toggle={toggleCreateCust} modal={createCust} setModal={setCreateCust} 
        /> */}
            </div>
          }
        </>
      )}
      <ConfirmationModal
        isOpen={confirmationModal}
        toggleModal={() => {
          setConfirmationModal(!confirmationModal);
        }}
        onHide={() => {
          setConfirmationModal(false);
        }}
        handleClose={() => {
          setConfirmationModal(false);
        }}
        onPress={() => {
          dispatch(
            deleteBranch({ branchId: branchId }, (res) => {
              setConfirmationModal(false);
              if (res?.status == 200) {
                setToastMessage("Branch Deleted successfully");
                setToastModal(true);
                setToastStatus(true);
                setRefresh(!refresh);
              } else {
                setToastModal(true);
                setToastStatus(false);
                setToastMessage(res?.data?.message || "Error in delete Branch");
              }
            })
          );
        }}
      />
      <ToastV2
        success={toastStatus}
        message={toastMessage}
        toggleModal={() => {
          setToastModal(false);
          setToastMessage("");
        }}
        isOpen={toastModal}
        name="driver"
        handleClose={() => {
          setToastModal(false);
          setToastMessage("");
        }}
      />
    </div>
  );
}
export default Branches;
