import React, { useEffect, useState } from "react";
import "./index.css";
import { Table } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getDriverList,
  clearAllDrivers,
  getDriverListHome,
  downloadSalaryAction,
  uploadSalaryAction,
  downloadSalaryCreditSheet,
  getAdvanceDriverList,
  cleanAdvanceDriverList,
} from "../../../../services/Store/Actions/driverslistActions";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../../../subComponents/searchbar";
import ManageDriverDetailsCard from "../../../../Subcomponent/ManageDriverDetailsListCard";
import ManageVehicleListBar from "../../../ManageVehicles/SubComponents/ManageVehicleListBar";
import ManageDriverVehicleListBar from "../../../../Subcomponent/ManageDriverVehicleListBar";
import { padding } from "@mui/system";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DriverList from "../DriverList";
import CardSelectModal from "../../../Modals/CardSelectModal";
import CardDriverDetails from "../../../Modals/cardDriverDetails/CardDriverDetails";
import CardDriverKYCModal from "../../../Modals/cardDriverKYCModal/CardDriverKYCModal";
import ContactSalesAgentToast from "../../../Modals/ContactSalesAgentToast";
import SvgImage from "../../../Icons/Icons";
import Toast from "../../../subComponents/toast";
import { toast } from "react-toastify";
import DriverListAdvance from "../DriverListAdvance";

const FullDriverList = (props) => {
  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);
  const [offset, setOffset] = useState(offsetVal);
  // const [search, setSearch] = useState("");
  const [fetchMoreData, setFetchMoreData] = useState(true);
  const [rcolor, setRColor] = useState(-1);
  const [activeStatus, setActiveStatus] = useState(1);
  const [assignedStatus, setAsssignedStatus] = useState(3);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleLoadMore = () => {
  //   if (allDriverList?.length > allDrivers?.divers?.count) {
  //   } else {
  //     setFetchMoreData(true);
  //     setOffset(offset + 10);
  //     dispatch(
  //       getDriverList({
  //         offset: offset + 10,
  //         search: "",
  //         minKyc: "",
  //         sortAs: "",
  //         sortBy: "",
  //       })
  //     );
  //   }
  // };

  // console.log("yeyey", search);
  const urlSearchParams = new URLSearchParams(window.location.search);
  console.log("uu", window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  // console.log("url split", searchParam);
  let searchParam = params.search;
  console.log("url split", searchParam);

  const [search, setSearch] = useState(
    searchParam == undefined ? "" : searchParam
  );

  const findSearch = () => {
    dispatch(
      getAdvanceDriverList({
        offset: 0,
        search: search.trim(),
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: activeStatus,
        assignedStatus: assignedStatus,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAdvanceDriverList({
        offset: offset,
        search: search.trim(),
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: activeStatus,
        assignedStatus: assignedStatus,
      })
    );
  }, [search, activeStatus, assignedStatus]);

  const handleClick = (e, i) => {
    console.log("drivers ki list", e);

    navigate(`/Drivers/${e.User.userId}`);
  };

  let { id } = useParams();
  const { allDriversAdvance, allDriverListAdvance } = useSelector(
    (state) => state?.driverlist
  );
  useEffect(() => {
    let urlId = window.location.pathname.split("/");

    if (urlId.length === 3) {
      if (urlId[2] != undefined && urlId[2] != "") {
        let i = allDriverListAdvance?.findIndex(
          (element) => element.userId === urlId[2]
        );

        setRColor(i);
      }
    }
  }, [XPathResult, allDriversAdvance]);

  useEffect(() => {
    // dispatch(
    //   getAdvanceDriverList({
    //     offset: 0,
    //     search: search,
    //     minKyc: "",
    //     sortAs: "",
    //     sortBy: "",
    //   })
    // );
    return function cleanUp() {
      dispatch(clearAllDrivers());
      setOffset(0);
      dispatch(cleanAdvanceDriverList());
    };
  }, []);

  // for opening cards select
  const [open, setOpen] = useState(false);

  const showingModal = () => {
    console.log("YALE");
    setOpen(true);
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
  };

  // for opening cards details modal
  const [openDetails, setOpenDetails] = useState(false);

  const showingDetailsModal = () => {
    console.log("YALE");
    setOpenDetails(true);
  };

  const toggleDetailsModal = () => {
    setOpenDetails(!openDetails);
  };

  const closeDetailsModal = () => {
    setOpenDetails(false);
  };

  // for opening cards v-KYC modal
  const [openVKYC, setOpenVKYC] = useState(false);

  const showingVKYC_Modal = () => {
    console.log("YALE");
    setOpenVKYC(true);
  };

  const toggleVKYC_Modal = () => {
    setOpenVKYC(!openVKYC);
  };

  const closeVKYC_Modal = () => {
    setOpenVKYC(false);
  };

  const customId1 = "custom-id-download page";
  const notify1 = () =>
    toast.success("Salary disbursement data was downloaded successfuly.", {
      toastId: customId1,
    });

  const customId2 = "custom-id-upload page";
  const notify2 = () =>
    toast.success("Salary disbursement data was uploaded successfuly.", {
      toastId: customId2,
    });

  // for opening sales agent will contact modal
  const [openSaleToast, setOpenSaleToast] = useState(false);

  const showingSalesToastModal = () => {
    console.log("YALE");
    setOpenSaleToast(true);
  };

  const toggleSalesToastModal = () => {
    setOpenSaleToast(!openSaleToast);
  };

  const closeSalesToastModal = () => {
    setOpenSaleToast(false);
  };
  const driversCount = allDriversAdvance?.count;
  console.log("driversCount", driversCount);

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  const downloadSal = () => {
    dispatch(
      downloadSalaryAction("", (res) => {
        console.log("downloadSalaryAction", res?.data);
        if (res?.status == 200) {
          const blob = new Blob([res?.data]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `file.xls`; //name to changed here
          link.click();
          notify1();
        }
      })
    );
  };

  const downloadSalDisbursement = () => {
    dispatch(
      downloadSalaryCreditSheet("", (res) => {
        console.log("downloadSalaryCreditSheet", res);
        if (res?.status == 200) {
          const blob = new Blob([res?.data]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `file.xls`; //name to changed here
          link.click();
          notify1();
        }
      })
    );
  };

  return (
    <div className="FullCustomerListBox">
      <div className="FullCustomerListTopBox">
        <div className="FullCustomerListHeader FontBold">Full Driver List</div>
        <div className="displayFlex"></div>
      </div>
      {driversCount !== undefined ||
      !(activeStatus == 3 && assignedStatus == 3) ? (
        <>
          {/* <div className="row padding16L padding16R padding8T padding8B">
              <div className="col-6 padding8">
                <button className="salaryBtn flexCls text3" type="button" onClick={downloadSal}>
                  <SvgImage name="downIcon" />
                  <div className="padding8L">
                    Salary Sample
                  </div>
                </button>
              </div>
              <div className="col-6 padding8">
                <button className="salaryBtn flexCls text3" type="button" >
                  <SvgImage name="uploadIcon" />
                  <div className="padding8L">
                    Salary Creation
                  </div>
                  <input
                    type="file"
                    // accept="image/*"
                    className='add-attachment-btn-2'
                    onChange={async (e) => {
                      const img = e?.target.files[0];
                      // setFileUp( e?.target.files[0]);
                      console.log("image", img?.name);
                      if (img !== null) {
                        //notify2();
                        dispatch(uploadSalaryAction({
                          isRes: false,
                          file: e?.target.files[0],
                        }, (res) => {
                          console.log("resp for file upload", res.type);
                          if (res?.type == "success") {
                            console.log("entereed")
                            //handleRefresh();
                            //alert("Data uploaded successfully")
                            notify2();
                          }
                        }))
                      }
                    }}
                  />
                </button>
              </div>
            </div>


             <div className="row padding16L padding16R padding8T padding8B">
              <div className="col-6 padding8">
                <button className="salaryBtn flexCls text3" type="button" onClick={downloadSalDisbursement}>
                  <SvgImage name="downIcon" />
                  <div className="padding8L">
                    Salary Disbursement
                  </div>
                </button>
              </div>
              <div className="col-6 padding8">
                <button className="salaryBtn flexCls text3" type="button" >
                  <SvgImage name="uploadIcon" />
                  <div className="padding8L">
                    Salary Disbursement
                  </div>
                  <input
                    type="file"
                    // accept="image/*"
                    className='add-attachment-btn-2'
                    onChange={async (e) => {
                      const img = e?.target.files[0];
                      // setFileUp( e?.target.files[0]);
                      console.log("image", img?.name);
                      if (img !== null) {
                        //notify2();
                        dispatch(uploadSalaryAction({
                          isRes: false,
                          file: e?.target.files[0],
                        }, (res) => {
                          console.log("resp for file upload", res.type);
                          if (res?.type == "success") {
                            console.log("entereed")
                            //handleRefresh();
                            //alert("Data uploaded successfully")
                            notify2();
                          }
                        }))
                      }
                    }}
                  />
                </button>
              </div>
            </div>  */}
          <div className="FUllListSearchBar">
            <SearchBar
              placeholder="Search for drivers"
              height="46"
              value={search}
              onChange={(event) => {
                dispatch(clearAllDrivers());
                setSearch(event.target.value);
              }}
              findSearch={findSearch}
            />
          </div>
          <div className="" style={{ marginTop: -16 }}>
            <button
              className={`Medium textDiv1 ${
                activeStatus == 3 && assignedStatus == 3
                  ? "greenButton"
                  : "greenOutlineButton"
              } rounded-pill ms-3`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setActiveStatus(3);
                setAsssignedStatus(3);
              }}
            >
              All
            </button>
            <button
              className={`Medium textDiv1 ${
                activeStatus == 1 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setActiveStatus(1);
              }}
            >
              Active
            </button>
            <button
              className={`Medium textDiv1 ${
                activeStatus == 0 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setActiveStatus(0);
              }}
            >
              InActive
            </button>
            <button
              className={`Medium textDiv1 ${
                assignedStatus == 0 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setAsssignedStatus(0);
              }}
            >
              Not Assigned
            </button>
            <button
              className={`Medium textDiv1 ${
                assignedStatus == 1 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setAsssignedStatus(1);
              }}
            >
              Assigned
            </button>
          </div>
          <DriverListAdvance
            search={search}
            offset={offset}
            setOffset={setOffset}
            nextTrigger={props.nextTrigger}
            allowTrigger={props.allowTrigger}
            showingModal={showingModal}
            openVKyc={showingVKYC_Modal}
            activeStatus={activeStatus}
            assignedStatus={assignedStatus}
          />

          <CardSelectModal
            open={open}
            onHide={closeModal}
            handleClose={closeModal}
            toggleModal={toggleModal}
            openM2p={showingDetailsModal}
            showingSalesToastModal={showingSalesToastModal}
          />

          <CardDriverDetails
            open={openDetails}
            onHide={closeDetailsModal}
            handleClose={closeDetailsModal}
            toggleModal={toggleDetailsModal}
            mobileNo={props.driverDetails?.phone}
            openVKyc={showingVKYC_Modal}
          />

          <CardDriverKYCModal
            open={openVKYC}
            onHide={closeVKYC_Modal}
            handleClose={closeVKYC_Modal}
            toggleModal={toggleVKYC_Modal}
          />

          <ContactSalesAgentToast
            isOpen={openSaleToast}
            onHide={closeSalesToastModal}
            handleClose={closeSalesToastModal}
            toggleModal={toggleSalesToastModal}
          />
        </>
      ) : (
        <div className="padding32 " style={{ paddingBottom: "30rem" }}>
          <div className="autoRechargeBtn padding16 heading3 Medium">
            No Driver present. Add them using app.
          </div>
        </div>
      )}
    </div>

    // <div className="FullDriverListBox">
    //   <div className="FullDriverListHeader pl-5">Full Driver List </div>
    //   <div className="FUllDriverListSearchBar">
    //     <SearchBar
    //       placeholder="Search for drivers"
    //       height="46"
    //       value={search}
    //       onChange={(event) => {
    //         dispatch(clearAllDrivers());
    //         setSearch(event.target.value);
    //       }}
    //       findSearch={findSearch}
    //     />
    //   </div>
    //  <DriverList search={search} offset={offset}></DriverList>
    // </div>
  );
};
export default FullDriverList;
