import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./index.css";
import { Form, FormGroup, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import CreateLRModal from "./Components/CreateLRModal/index";
import SvgImage from "../Icons/Icons.js";
import { DatePickerField } from "../core/inputs/input.js";
import {
  findTripAddress,
  getTripsNew,
  getLRList,
} from "../../services/Store/Actions/TripsAction.js";
import Toast from "../subComponents/toast/index.js";
import {
  getSignedUrlApiAction,
  storeMedia,
  toastStatus,
} from "../../services/Store/Actions/appActions.js";
import { getVehicleListAction } from "../../services/Store/Actions/manageVehicleAction.js";
import { getDriverList } from "../../services/Store/Actions/driverslistActions.js";
import moment from "moment";
import { getCityAndStateAction } from "../../services/Store/Actions/omcActions.js";
import {
  removeItemFromAsyncStorage,
  storeDataToAsyncStorage,
} from "../../services/Utils/functions.js";
import { BallTriangle } from "react-loader-spinner";
import { actions } from "react-redux-form";
import { Button } from "reactstrap";
import InvoiceDetail from "./Components/InvoiceDetail/index.js";
import CreateTripFromLR from "../ManageCustomersDetailsV2/SubComponents/CreateTripFromLR/index.js";
import PaymentDetails from "../ManageCustomersDetailsV2/SubComponents/PaymentDetails/index.js";
import ChooseRoute from "./Components/ChooseRoute/index.js";
import { getAllCustomersAction } from "../../services/Store/Actions/customerAction.js";
import { editLR as updateLR } from "../../services/Store/Actions/TripsAction.js";
import { ClipLoader } from "react-spinners";
import UploadPodForLr from "./Components/UploadPodForLr/index.js";
function LoadReceipt(props) {
  const { sideBar, setItem, item } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let {
    freightList,
    freightListCount,
    countAll,
    uninvoicedCount,
    invoicedCount,
    notInTripCount,
    inTripCount,
  } = useSelector((store) => store?.trip);
  console.log("dasdad", freightList);
  const [color1, setColor1] = useState(-1);
  const [color2, setColor2] = useState(-1);
  const [freightId, setFreightId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [vehEdit, setVehEdit] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [loaderLRId, setLoaderLRId] = useState(false);
  const cityStateList = useSelector((state) => state?.omc?.cityStateList);
  console.log("lllllllllllllll", freightList, freightListCount);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  console.log("adasdas", toastObject);
  const [all, setAll] = useState(true);
  const [fleetIdTemp, setFleetIdTemp] = useState("");
  const [driverIdTemp, setDriverIdTemp] = useState("");
  const [consigneeIdTemp, setConsigneeIdTemp] = useState("");
  const [consignorIdTemp, setConsignorIdTemp] = useState("");
  const [payerIdTemp, setPayerIdTemp] = useState("");

  const [unInvoiced, setUnInvoiced] = useState(false);
  const [invoiced, setInvoiced] = useState(false);
  const [unLinked, setUnLinked] = useState(false);
  const [linked, setLinked] = useState(false);
  const [createInvoice, setCreateInvoice] = useState(false);
  const [customerIdInvoice, setCustomerIdInvoice] = useState("");
  const [selectedRow, setSelectedRow] = useState();

  const [trip, setTrip] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [uploadPodModal, setUploadPodModal] = useState(false);
  const [createTripModal, setCreateTripModal] = useState(false);
  const [paymentDetailsModal, setPaymentDetailsModal] = useState(false);
  const [chooseRouteModal, setChooseRouteModal] = useState(false);
  const [routeData, setRouteData] = useState({});
  const [tripData, setTripData] = useState({});
  const { offset, setOffset } = props;
  const createLR = () => setModal(!modal);
  const toggleUploadPodModal = () => setUploadPodModal(!uploadPodModal);
  const editLR = () => setModal(!modal);
  const createTrip = () => setCreateTripModal(!createTripModal);
  const paymentDetailsModalToggle = () =>
    setPaymentDetailsModal(!paymentDetailsModal);
  const chooseRouteModalToggle = () => setChooseRouteModal(!chooseRouteModal);
  const [startDate, setStartdate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reload, setReload] = useState(false);
  const [searchSource, setSearchSource] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [searchDestination, setSearchDestination] = useState("");
  useEffect(() => {
    // dispatch(findTripAddress());
    dispatch(getAllCustomersAction());
  }, []);

  const tripAddress = useSelector((state) => state?.trip?.tripAddressDetails);
  const { customerList } = useSelector((state) => state?.customer);
  console.log("customerList here", customerList, searchCustomer);

  //TODO reload components
  // useEffect(() => {
  //   console.log("customerList here11ooo", reload);
  // }, [reload]);

  // let {
  //   transactionsAndExpenses,
  //   fastagVehicleTransaction,
  //   cashTransactions,
  // } = useSelector((store) => store?.trip);
  // let totalExpense =  parseInt(fastagVehicleTransaction?.expense || 0) +
  //         parseInt(
  //           transactionsAndExpenses?.[
  //             parseInt(item?.cardType) > 0 ? "sumOfDebitEntries" : "expense"
  //           ] || 0
  //         ) +
  //         parseInt(cashTransactions?.sum || 0)

  //         console.log('fdafsa', totalExpense)

  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips, setIsTrips] = useState(0);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  useEffect(() => {
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
  }, [authUser, managerControlList]);

  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [display3, setDisplay3] = useState(false);
  const findSearch1 = () => {
    setColor1(-1);
    dispatch(
      findTripAddress({
        offset: 0,
        search: searchSource,
        // minKyc: "",
        // sortAs: "",
        // sortBy: "",
      })
    );
  };
  const findSearch2 = () => {
    setColor2(-1);
    dispatch(
      findTripAddress({
        offset: 0,
        search: searchDestination,
        // minKyc: "",
        // sortAs: "",
        // sortBy: "",
      })
    );
  };

  const findSearch3 = () => {
    setColor1(-1);
    console.log("searchCustomer", searchCustomer);
    dispatch(
      getAllCustomersAction({
        offset: 0,
        companyName: searchCustomer,
        // minKyc: "",
        // sortAs: "",
        // sortBy: "",
      })
    );
  };

  const datepickerRefEnd = useRef();
  const datepickerRefStart = useRef();
  const [open, setOpen] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);

  const [search, setSearch] = useState("");
  const [searchDriver, setSearchDriver] = useState("");

  const [tripNoTemp, setTripNoTemp] = useState("");
  const [lrNoTemp, setlrNoTemp] = useState("");

  let totalExpenseCount = freightListCount === undefined ? 0 : freightListCount;
  let pageCount =
    totalExpenseCount % 10 === 0
      ? totalExpenseCount / 10
      : Math.floor(totalExpenseCount / 10) + 1;
  console.log("pageeee", pageCount);

  const allFleets = useSelector(
    (state) => state?.manageVehicle?.allFleets?.rows
  );

  console.log("ffdfdfdfdfd", allFleets);
  const vehCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );

  const driverList = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.rows
  );
  const driverCount = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.count
  );
  const fastVeh =
    vehCount > 0 ? allFleets?.find((ele) => ele?.Fastag !== null) : "value";
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };

  // console.log('startDate.', endTime: moment(endDate).format("MM/DD/YYYY"))
  // useEffect(() => {
  //   dispatch(
  //     getTripsNew({
  //       offset: 0,
  //       limit: 10,
  //       fleetId : fleetIdTemp
  //     })
  //   );
  //   function handleResize() {
  //     setWindowWidth(window.innerWidth);
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [fleetIdTemp]);
  useEffect(() => {
    if (modal == false) setFreightId("");
  }, [modal]);
  const handlePageClick = (event) => {
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    console.log("event heree", event.selected);
    let payload = {};
    if (invoiced) {
      payload["invoice"] = 1;
    }
    if (unInvoiced) {
      payload["invoice"] = 0;
    }
    if (unLinked) {
      payload["trip"] = 0;
    }
    if (linked) {
      payload["trip"] = 1;
    }
    dispatch(
      getLRList({
        ...payload,
        offset: newOffset,
        limit: 10,
        isActive: "",
        fleetId: fleetIdTemp,
        driverId: driverIdTemp,
        tripNo: tripNoTemp,
        lrNo: lrNoTemp,
        startTime: moment(startDate).format("MM/DD/YYYY"),
        endTime: moment(endDate).format("MM/DD/YYYY"),
        source: searchSource,
        destination: searchDestination,
      })
    );
    setOffset(newOffset);
    // setDisplay1(false)
    // setDisplay2(false)
    // setDisplay3(false)
  };

  useEffect(() => {
    dispatch(
      getVehicleListAction({
        offset: "",
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [search]);

  useEffect(() => {
    dispatch(
      getDriverList({
        offset: "",
        search: searchDriver,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [searchDriver]);
  console.log("searchdasda", searchDestination, searchSource);

  useEffect(() => {
    // removeItemFromAsyncStorage("row");

    console.log("bhai flee", reload);
    let payload = {};
    if (invoiced) {
      payload["invoice"] = 1;
    }
    if (unInvoiced) {
      payload["invoice"] = 0;
    }
    if (unLinked) {
      payload["trip"] = 0;
    }
    if (linked) {
      payload["trip"] = 1;
    }
    dispatch(
      getLRList({
        ...payload,
        offset: 0,
        limit: 10,
        fleetId: fleetIdTemp,
        lrNo: lrNoTemp,
        driverId: driverIdTemp,
        startTime: moment(startDate).format("MM/DD/YYYY"),
        endTime: moment(endDate).format("MM/DD/YYYY"),
        source: searchSource,
        destination: searchDestination,
        tripNo: tripNoTemp,
        consignorId: consignorIdTemp,
        consigneeId: consigneeIdTemp,
        payerId: payerIdTemp,
      })
    );
  }, [
    reload,
    fleetIdTemp,
    driverIdTemp,
    startDate,
    endDate,
    searchDestination,
    searchSource,
    tripNoTemp,
    consignorIdTemp,
    consigneeIdTemp,
    payerIdTemp,
    uploadPodModal,
    lrNoTemp,
  ]);

  const filterList = (k) => {
    if (k === "all") {
      setAll(true);
      setUnInvoiced(false);
      setUnLinked(false);
      setInvoiced(false);
      setLinked(false);
      dispatch(
        getLRList({
          offset: 0,
          limit: 10,
          fleetId: fleetIdTemp,
          driverId: driverIdTemp,
          startTime: moment(startDate).format("MM/DD/YYYY"),
          endTime: moment(endDate).format("MM/DD/YYYY"),
          source: searchSource,
          destination: searchDestination,
          lrNo: lrNoTemp,
          consignorId: consignorIdTemp,
          consigneeId: consigneeIdTemp,
          payerId: payerIdTemp,
        })
      );
    } else if (k === "unInvoiced") {
      setUnInvoiced(true);
      setAll(false);
      setUnLinked(false);
      setInvoiced(false);
      setLinked(false);
      dispatch(
        getLRList({
          // isActive: "0",
          invoice: 0,
          offset: 0,
          limit: 10,
          fleetId: fleetIdTemp,
          driverId: driverIdTemp,
          startTime: moment(startDate).format("MM/DD/YYYY"),
          endTime: moment(endDate).format("MM/DD/YYYY"),
          source: searchSource,
          destination: searchDestination,
          lrNo: lrNoTemp,
          consignorId: consignorIdTemp,
          consigneeId: consigneeIdTemp,
          payerId: payerIdTemp,
        })
      );
    } else if (k === "invoiced") {
      setUnInvoiced(false);
      setAll(false);
      setUnLinked(false);
      setInvoiced(true);
      setLinked(false);
      dispatch(
        getLRList({
          // isActive: "0",
          invoice: 1,
          offset: 0,
          limit: 10,
          fleetId: fleetIdTemp,
          driverId: driverIdTemp,
          startTime: moment(startDate).format("MM/DD/YYYY"),
          endTime: moment(endDate).format("MM/DD/YYYY"),
          source: searchSource,
          destination: searchDestination,
          lrNo: lrNoTemp,
          consignorId: consignorIdTemp,
          consigneeId: consigneeIdTemp,
          payerId: payerIdTemp,
        })
      );
    } else if (k === "unLinked") {
      setUnLinked(true);
      setAll(false);
      setUnInvoiced(false);
      setInvoiced(false);
      setLinked(false);
      dispatch(
        getLRList({
          // isActive: "1",
          trip: 0,
          offset: 0,
          limit: 10,
          fleetId: fleetIdTemp,
          driverId: driverIdTemp,
          startTime: moment(startDate).format("MM/DD/YYYY"),
          endTime: moment(endDate).format("MM/DD/YYYY"),
          source: searchSource,
          destination: searchDestination,
          lrNo: lrNoTemp,
          consignorId: consignorIdTemp,
          consigneeId: consigneeIdTemp,
          payerId: payerIdTemp,
        })
      );
    } else if (k === "linked") {
      setUnLinked(false);
      setAll(false);
      setUnInvoiced(false);
      setInvoiced(false);
      setLinked(true);
      dispatch(
        getLRList({
          // isActive: "1",
          trip: 1,
          offset: 0,
          limit: 10,
          fleetId: fleetIdTemp,
          driverId: driverIdTemp,
          startTime: moment(startDate).format("MM/DD/YYYY"),
          endTime: moment(endDate).format("MM/DD/YYYY"),
          source: searchSource,
          destination: searchDestination,
          lrNo: lrNoTemp,
          consignorId: consignorIdTemp,
          consigneeId: consigneeIdTemp,
          payerId: payerIdTemp,
        })
      );
    }
  };
  // const onSelect = (row) => {
  //   storeDataToAsyncStorage("row", row);
  //   setItem(row);
  //   navigate(`/trips/${row.tripId}`);
  //   console.log("dsadasd", row);

  //   // setItem(row);
  //   console.log("thissssssssssssss", row);
  // };
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Only Numbers are allowed")
      .required("Required")
      .min(1, "Amount should be greater than 1"),
  });
  let columns;
  {
    freightList.map((i, k) => {
      console.log(i, k);
      columns = [
        {
          name: "LR No",
          selector: (i) => i.lrNo,
          style: {
            // flex:"0 0 0",
            // minWidth:"auto"
          },
          header: {
            style: {
              flex: "0 0 0",
            },
          },
          grow: 0.5,
        },
        {
          name: "Vehicle No",
          selector: (i) => i?.Fleet?.regNumber,
          style: {
            // flex:"0 0 0",
            minWidth: "auto",
          },
        },

        {
          name: "From",
          selector: (i) =>
            `${i?.fromAddress?.cityName ? i?.fromAddress?.cityName : ""}`,
        },
        {
          name: "To",
          selector: (i) =>
            `${i?.toAddress?.cityName ? i?.toAddress?.cityName : ""}`,
        },
        {
          name: "Amount",
          selector: (i) => "₹ " + i?.freightAmount?.toLocaleString("en-IN"),
        },
        {
          name: "pod",
          style: {
            // flex:"0 0 0",
            // minWidth:"auto"
          },
          header: {
            style: {
              // flex:"0 0 0",
              minWidth: "auto",
            },
          },
          selector: (i) =>
            i?.podUrl || (i.freightId == loaderLRId && !downloadLoader) ? (
              <button
                style={{
                  border: 0,
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  toggleUploadPodModal();
                  setFreightId(i.freightId);
                  // setDownloadLoader2(true);
                  // setLoaderInvoiceId(items.invoiceId);
                  // dispatch(
                  //   getSignedUrlApiAction({ key: i?.podUrl }, (res) => {
                  //     // setDownloadLoader2(false);
                  //     // setLoaderInvoiceId("");
                  //     if (res.status == 200) {
                  //       window.open(res?.data?.data);
                  //     }
                  //   })
                  // );
                }}
              >
                <SvgImage name="viewInvoicesBlue" />
              </button>
            ) : downloadLoader && i.freightId == loaderLRId ? (
              <ClipLoader color="#4D5073" size={"21"} />
            ) : (
              <div
                style={{
                  border: 0,
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
                onClick={() => {
                  toggleUploadPodModal();
                  setFreightId(i.freightId);
                }}
              >
                <SvgImage name="uploadInvoices" height="21" width="21" />
                {/* <input
                  type="file"
                  // multiple
                  // accept="image/*"
                  accept="image/*"
                  // className="add-lr-attachment p-0"
                  style={{
                    // height: 16,
                    cursor:"pointer",
                    width:"21px",
                    marginLeft:"-21px",
                    opacity:0,
                  }}
                  onChange={(e) => {
                    // const img = e?.target.files[0];
                    console.log("image", img?.name);
                    // e.preventDefault();
                    // setDownloadLoader(true);
                    // setLoaderLRId(i?.freightId);

                    // e.preventDefault();

                    // if (img == null) {
                    //   //clear image
                    //   //for now  only one supported
                    // } else {
                    //   console.log("upload", {
                    //     img: img,
                    //     uploadType: "invoice",
                    //     isRes: true,
                    //   });
                    //   dispatch(
                    //     storeMedia(
                    //       {
                    //         img: img,
                    //         uploadType: "invoice",
                    //         isRes: false,
                    //       },
                    //       (res) => {
                    //         if (res?.url) {
                    //           console.log("opt", res);
                    //           dispatch(
                    //             updateLR(
                    //               {
                    //                 freightId: i?.freightId,
                    //                 podUrl: res?.url,
                    //               },
                    //               (res) => {
                    //                 // setLoaderLRId("");
                    //                 i.podUrl=res?.url
                    //                 setDownloadLoader(false);
                                    
                    //               }
                    //             )
                    //           );
                    //         }
                    //       }
                    //     )
                    //   );

                    //   // setFieldValue("amount", values.amount, true);
                    // }
                  }}
                /> */}
              </div>
            ),
          grow: 0.5,
        },
        {
          name: "invoice",
          style: {
            // flex:"0 0 0",
            minWidth: "auto",
          },
          header: {
            style: {
              // flex:"0 0 0",
              minWidth: "auto",
            },
          },
          selector: (i) =>
            i?.invoice?.isActive ? (
              <button
                style={{
                  border: 0,
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  // setDownloadLoader2(true);
                  // setLoaderInvoiceId(items.invoiceId);
                  dispatch(
                    getSignedUrlApiAction(
                      { key: i?.invoice?.Ledger?.receipt },
                      (res) => {
                        // setDownloadLoader2(false);
                        // setLoaderInvoiceId("");
                        if (res.status == 200) {
                          window.open(res?.data?.data);
                        }
                      }
                    )
                  );
                }}
              >
                <SvgImage name="viewInvoicesBlue" />
              </button>
            ) : (
              <SvgImage name="cancelOrder" height="21" width="21" />
            ),
          grow: 0.5,
        },
        {
          name: "isPaid",
          style: {
            // flex:"0 0 0",
            minWidth: "auto",
          },
          header: {
            style: {
              // flex:"0 0 0",
              minWidth: "auto",
            },
          },
          selector: (i) =>
            i?.invoice?.cleared ? (
              <SvgImage name="savingsIconNew" height="21" width="21" />
            ) : (
              <SvgImage name="cancelOrder" height="21" width="21" />
            ),
          grow: 0.5,
        },
        {
          name: "Payor",
          selector: (i) =>
            i?.customerId == i?.transportorId
              ? i?.transportorName
              : i?.customerId == i?.consignorId
              ? i?.consignorName
              : i?.customerId == i?.consigneeId
              ? i?.consigneeName
              : "-",
        },
        // {
        //   name: "Status",
        //   cell: (i) => (
        //     <button
        //       type="button"
        //       // className="btn btn-success"
        //       style={{  }}
        //       // onClick={() => alert("The Trip is Completed")}
        //     >
        //       {i.invoiceId == null ? "UnInvoiced" : "Invoiced"}
        //     </button>
        //   ),
        // },
        // {
        //   name: "Trip",
        //   cell: (i) => (
        //     <button
        //       type="button"
        //       // className="btn btn-success"
        //       style={{  }}
        //       // onClick={() => alert("The Trip is Completed")}
        //     >
        //       {i.tripId == null ? "Unlinked" : "linked"}
        //     </button>
        //   ),
        // },
        {
          name: "action",
          cell: (i) => (
            <button
              type="button"
              className="btn btn-success-blue"
              style={{}}
              onClick={() => {
                if (["2", "3", 2, 3].includes(isTrips)) {
                  console.log("editLReditLReditLR", i);
                  setFreightId(i.freightId);
                  setIsEdit(true);
                  if (i?.tripId == null) setVehEdit(true);
                  editLR();
                }
              }}
              disabled={i?.invoiceId != null}
            >
              Edit
            </button>
          ),
        },
      ];
    });
  }
  console.log("qqqqqqqqqqq", item);
  return (
    <div
      className="container topMargin sideBarMargin"
      style={{
        // paddingTop:"5.5rem",
        marginLeft: sideBar ? "220px" : "100px",
        maxWidth: sideBar ? windowWidth - 240 : windowWidth - 130,
      }}
    >
      <div
        className="main1"
        style={{ height: "1200px", backgroundColor: "white" }}
      >
        <h1 className="headingLow semiBold" style={{ marginBottom: "25px" }}>
          Load Receipts
        </h1>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            vrn: "",
            company: "",
            status: "",
            driver: "",
            startDate: "",
            endDate: "",
            from: "",
            to: "",
            source: "",
            destination: "",
            tripNo: "",
            lrNo: "",
            consignor: "",
            consignee: "",
            payor: "",
          }}
          onSubmit={(values, actions) => {
            console.log("sdsadsad", values);
            actions.resetForm();
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "50px",
                }}
              >
                <FormGroup>
                  <label for="vrn" className="heading3 semiBold">
                    Vehicle No:
                  </label>
                  <div className="custNamepadd3">
                    <input
                      className={`inputBoxCust1 ${
                        touched.amount && errors.amount ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="vrn"
                      name="vrn"
                      touched={touched.vrn}
                      errors={errors.vrn}
                      // onBlur={handleBlur("vrn")}
                      onChange={(event) => {
                        setOpen(true);
                        setSearch(event.target.value);
                        setFieldValue("vrn", event.target.value, true);
                        setFleetIdTemp("");
                      }}
                      value={values.vrn}
                      // autoFocus
                      placeholder="Select Vehicle"
                      autocomplete="off"
                      onFocus={() => {
                        setOpen(true);
                      }}
                      onBlur={() => {
                        setOpen(false);
                      }}
                      // placeholder="Enter Vechicle No"
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    {open ? (
                      <div
                        className="driverSearchContainer containerShadow"
                        style={{
                          width: "100%",
                          marginTop: "5px",
                          borderRadius: "8px",
                          border: "1px solid #E2E2E2",
                        }}
                      >
                        {allFleets?.map((item, i) => {
                          return (
                            <>
                              {/* {item?.Fastag !== null ? ( */}
                              <div
                                key={i}
                                className="flexStyling padding8 "
                                onClick={(event) => {
                                  setOpen(false);
                                  // setUserId(event.target.value);
                                  setFieldValue("vrn", item?.regNumber, true);
                                  setFieldValue("fleetId", item?.fleetId, true);
                                  setFleetIdTemp(item?.fleetId);
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                <input
                                  type="radio"
                                  name="vrn"
                                  value={item?.fleetId}
                                  id={item?.fleetId}
                                />
                                <div
                                  className="padding16L"
                                  value={item?.fleetId}
                                >
                                  {item?.regNumber}
                                </div>
                              </div>
                              {/* ) : (
                                    <></>
                                  )} */}
                            </>
                          );
                        })}
                        {vehCount == 0 ? (
                          <div className="heading3 semiBold padding16 padding24B">
                            No Vehicle Available
                          </div>
                        ) : fastVeh == undefined ? (
                          <div className="heading3 semiBold padding16 padding24B">
                            No Vehicle Available
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label for="vrn" className="heading3 semiBold">
                    LR Number
                  </label>
                  <div className="custNamepadd3">
                    <input
                      type="text"
                      id="vrn"
                      className={`inputBoxCust1 ${
                        touched.lrNo && errors.lrNo ? "is-invalid" : ""
                      }`}
                      name="vrn"
                      // onChange={handleChange("lrNo")}
                      value={values.lrNo}
                      onChange={(event) => {
                        console.log(
                          "etTripNoTemp(event.target.value);",
                          event.target.value
                        );
                        setlrNoTemp(event.target.value);
                        setFieldValue("lrNo", event.target.value, true);
                      }}
                      // onBlur={() => {
                      //   dispatch(
                      //     sendAnalitics("Enter_Amt_Web", {
                      //       upi_netBank_tripNo: values.lrNo,
                      //     })
                      //   );
                      //   handleBlur("lrNo");
                      // }}
                      touched={touched.lrNo}
                      errors={errors.lrNo}
                      placeholder="Enter Lr No"
                      autocomplete="off"
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <label for="driver" className="heading3 semiBold">
                    Driver
                  </label>
                  <div
                    // onClick={() => {
                    //   setOpenDriver(true);
                    // }}
                    className="custNamepadd3"
                  >
                    <input
                      className={`inputBoxCust1 ${
                        touched.amount && errors.amount ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="driver"
                      name="driver"
                      touched={touched.driver}
                      errors={errors.driver}
                      // onBlur={handleBlur("driver")}
                      onChange={(event) => {
                        setOpenDriver(true);
                        setSearchDriver(event.target.value);
                        setFieldValue("driver", event.target.value, true);
                        setDriverIdTemp("");
                      }}
                      value={values.driver}
                      // autoFocus
                      placeholder="Select Driver"
                      autocomplete="off"
                      onFocus={() => {
                        setOpenDriver(true);
                      }}
                      onBlur={() => {
                        setOpenDriver(false);
                      }}
                    />
                  </div>

                  <div style={{ position: "relative" }}>
                    {openDriver ? (
                      <div
                        className="driverSearchContainer containerShadow"
                        style={{
                          width: "100%",
                          marginTop: "5px",
                          borderRadius: "8px",
                          border: "1px solid #E2E2E2",
                        }}
                      >
                        {driverList?.map((item, i) => {
                          return (
                            <>
                              {item?.Fastag !== null ? (
                                <div
                                  key={i}
                                  className="flexStyling padding8 "
                                  onClick={(event) => {
                                    setOpenDriver(false);
                                    // setUserId(event.target.value);
                                    setFieldValue(
                                      "driver",
                                      item?.User?.fullName,
                                      true
                                    );
                                    setFieldValue(
                                      "userId",
                                      event.target.value,
                                      true
                                    );

                                    setDriverIdTemp(item?.User?.userId);
                                  }}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  <input
                                    type="radio"
                                    name="driver"
                                    value={item?.User?.userId}
                                    id={item?.User?.userId}
                                  />
                                  <div className="padding16L">
                                    {item?.User?.fullName.length > 35
                                      ? item?.User?.fullName.split(0, 35) +
                                        "..."
                                      : item?.User?.fullName}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                        {driverCount == 0 ? (
                          <div className="heading3 semiBold padding16 padding24B">
                            No driver Available
                          </div>
                        ) : driverCount == undefined ? (
                          <div className="heading3 semiBold padding16 padding24B">
                            No driver Available
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.userId && touched.userId ? (
                        <div>*{errors.userId}</div>
                      ) : null}
                    </div>
                  </div>
                </FormGroup>
                <button
                  className="greenButton"
                  style={{ width: "160px" }}
                  type="button"
                  onClick={() => {
                    setTripNoTemp("");
                    setlrNoTemp("");
                    setDriverIdTemp("");
                    setSearchDriver("");
                    setFleetIdTemp("");
                    setStartdate("");
                    setEndDate("");
                    setSearchDestination("");
                    setSearchSource("");
                    resetForm();
                  }}
                >
                  Clear Filter
                </button>
              </FormGroup>

              <FormGroup
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "50px",
                }}
              >
                {/* <FormGroup>
                  <label for="startDate" className="heading3 semiBold">
                    Start Date
                  </label>
                  <div className="custNamepadd3 row" style={{ width: "200px" }}>
                    <div className="col-9">
                      <DatePickerField
                        classname="searchBar-input1"
                        name="startDate"
                        header="Select Start date"
                        value={values.startDate}
                        onChange={(v) => {
                          setFieldValue("startDate", v, true);
                          setStartdate(v);
                        }}
                        //onChange={(v) => setFieldValue("endDate", v, true)}
                        maxDate={
                          values.endDate === "" ? "" : values.endDate
                        }
                        // maxDate={new Date()}
                        ref1={datepickerRefEnd}
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="col-3">
                      <label
                        className="date-input"
                        for="startDate"
                        style={{
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleClickDatepickerIcon2();
                        }}
                      >
                        <SvgImage
                          name="CalenderLogo"
                          width="32"
                          height="32"
                          // classname="date-logo"
                        />
                      </label>
                    </div>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.endDate && touched.endDate ? (
                      <div>*{errors.endDate}</div>
                    ) : null}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label for="endtDate" className="heading3 semiBold">
                    End Date
                  </label>
                  <div className="custNamepadd3 row" style={{ width: "200px" }}>
                    <div className="col-9">
                      <DatePickerField
                        classname="searchBar-input1"
                        name="endtDate"
                        header="Select End date"
                        value={values.endDate}
                        onChange={(v) => {
                          setFieldValue("endDate", v, true);
                          setEndDate(v);
                        }}
                        //onChange={(v) => setFieldValue("endDate", v, true)}
                        minDate={
                          values.startDate === "" ? "" : values.startDate
                        }
                        // maxDate={new Date()}
                        ref1={datepickerRefStart}
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="col-3">
                      <label
                        className="date-input"
                        for="endDate"
                        style={{
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleClickDatepickerIcon1();
                        }}
                      >
                        <SvgImage
                          name="CalenderLogo"
                          width="32"
                          height="32"
                          // classname="date-logo"
                        />
                      </label>
                    </div>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.endDate && touched.endDate ? (
                      <div>*{errors.endDate}</div>
                    ) : null}
                  </div>
                </FormGroup> */}

                <FormGroup>
                  <label for="source" className="heading3 semiBold">
                    Consignor
                  </label>
                  <div className="custNamepadd3">
                    <input
                      type="text"
                      name="consignor"
                      id="consignor"
                      placeholder={"consignor"}
                      className={`inputBoxCust1 ${
                        touched.consignor && errors.consignor
                          ? "is-invalid"
                          : ""
                      }`}
                      aria-label="Default select example"
                      onChange={(e) => {
                        console.log("e.target.value", e.target.value);
                        setDisplay1(true);
                        setSearchCustomer(e.target.value);
                        setFieldValue("consignor", e.target.value, true);
                        setConsignorIdTemp("");
                      }}
                      onClick={() => {
                        setDisplay1(true);
                      }}
                      onFocus={() => {
                        setDisplay1(true);
                      }}
                      onBlur={() => {
                        setDisplay1(false);
                      }}
                      value={values.consignor}
                      errors={errors.consignor}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          findSearch3();
                        }
                      }}
                    ></input>
                  </div>
                  {display1 ? (
                    <div
                      className="padding16 newCashback whiteBackground"
                      style={{
                        position: "absolute",
                        width: "350px",
                        height: "275px",
                        zIndex: "90",
                      }}
                    >
                      <div
                        onClick={() => {
                          setDisplay1(false);
                          // setSearchCustomer("");
                          // setFieldValue("consignor", "", true);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        style={{
                          position: "absolute",
                          right: "0.5rem",
                          top: "0.5rem",
                          cursor: "pointer",
                        }}
                      >
                        <SvgImage name="CloseButtonLogo" />
                      </div>
                      <div
                        style={{
                          overflowY: "scroll",
                          overflowX: "hidden",
                          height: "240px",
                        }}
                      >
                        {searchCustomer != "" ? (
                          customerList?.map((data, i) => {
                            console.log("customerListcustomerList", data);
                            let name = data?.companyName;

                            return (
                              <div
                                key={i}
                                className="padding8 row"
                                onClick={() => {
                                  setColor1(i);
                                  setDisplay1(false);
                                  console.log(
                                    "data?.customerId",
                                    data?.customerId
                                  );
                                  //setUserId(data?.User?.userId);
                                  setSearchSource(data?.companyName);
                                  setConsignorIdTemp(data?.customerId);
                                  setFieldValue(
                                    "consignor",
                                    data?.companyName,
                                    true
                                  );
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {color1 === i ? (
                                  <div className="col-1">
                                    <SvgImage name="SelectedRadio" />
                                  </div>
                                ) : (
                                  <div className="col-1">
                                    <SvgImage name="UnselectedRadio" />
                                  </div>
                                )}
                                <div
                                  className="col-11"
                                  style={{ textAlign: "left" }}
                                >
                                  {name}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>
                            {customerList?.map((data, i) => {
                              let name = data?.companyName;

                              // console.log("check status", city, state);
                              // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                              return (
                                <div
                                  key={i}
                                  className="padding8 row"
                                  onClick={() => {
                                    setColor1(i);
                                    console.log(
                                      "customerListcustomerList",
                                      data
                                    );
                                    setDisplay1(false);
                                    setConsignorIdTemp(data?.customerId);
                                    setSearchSource(data?.companyName);
                                    setFieldValue(
                                      "consignor",
                                      data?.companyName,
                                      true
                                    );
                                  }}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {color1 === i ? (
                                    <div className="col-1">
                                      <SvgImage name="SelectedRadio" />
                                    </div>
                                  ) : (
                                    <div className="col-1">
                                      <SvgImage name="UnselectedRadio" />
                                    </div>
                                  )}
                                  <div
                                    className="col-11"
                                    style={{ textAlign: "left" }}
                                  >
                                    {data?.companyName}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                        <p></p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </FormGroup>
                <FormGroup>
                  <label for="destination" className="heading3 semiBold">
                    Consignee
                  </label>
                  <div className="custNamepadd3">
                    <input
                      type="text"
                      name="consignee"
                      id="consignee"
                      placeholder={"consignee"}
                      className={`inputBoxCust1 ${
                        touched.consignee && errors.consignee
                          ? "is-invalid"
                          : ""
                      }`}
                      aria-label="Default select example"
                      onChange={(e) => {
                        setDisplay2(true);
                        setSearchCustomer(e.target.value);
                        setFieldValue("consignee", e.target.value, true);
                        setConsigneeIdTemp("");
                      }}
                      onClick={() => {
                        setDisplay2(true);
                      }}
                      value={values.consignee}
                      onBlur={() => {
                        setDisplay2(false);
                      }}
                      errors={errors.consignee}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          findSearch3();
                        }
                      }}
                    ></input>
                  </div>
                  {display2 ? (
                    <div
                      className="padding16 newCashback whiteBackground"
                      style={{
                        position: "absolute",
                        width: "350px",
                        height: "275px",
                        zIndex: "90",
                      }}
                    >
                      <div
                        onClick={() => {
                          setDisplay2(false);
                          // setSearchCustomer("");
                          // setConsigneeIdTemp("");
                          // //dispatch(clearAllDriversByVrn());
                          // setFieldValue("consignee", "", true);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        style={{
                          position: "absolute",
                          right: "0.5rem",
                          top: "0.5rem",
                          cursor: "pointer",
                        }}
                      >
                        <SvgImage name="CloseButtonLogo" />
                      </div>
                      <div
                        style={{
                          overflowY: "scroll",
                          overflowX: "hidden",
                          height: "240px",
                        }}
                      >
                        {searchDestination != "" ? (
                          customerList?.map((data, i) => {
                            return (
                              <div
                                key={i}
                                className="padding8 row"
                                onClick={() => {
                                  setColor2(i);
                                  setDisplay2(false);
                                  //setUserId(data?.User?.userId);
                                  setConsigneeIdTemp(data?.customerId);
                                  setFieldValue(
                                    "consignee",
                                    data?.companyName,
                                    true
                                  );
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {color2 === i ? (
                                  <div className="col-1">
                                    <SvgImage name="SelectedRadio" />
                                  </div>
                                ) : (
                                  <div className="col-1">
                                    <SvgImage name="UnselectedRadio" />
                                  </div>
                                )}
                                {/* <div className="col-1">
                          <SvgImage name="UnselectedRadio" />
                        </div> */}
                                <div
                                  className="col-11"
                                  style={{ textAlign: "left" }}
                                >
                                  {data?.companyName}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>
                            {customerList?.map((data, i) => {
                              // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                              return (
                                <div
                                  key={i}
                                  className="padding8 row"
                                  onClick={() => {
                                    setColor2(i);
                                    setDisplay2(false);
                                    //setUserId(data?.User?.userId);
                                    setSearchCustomer(data?.companyName);
                                    setConsigneeIdTemp(data?.customerId);
                                    setFieldValue(
                                      "consignee",
                                      data?.companyName,
                                      true
                                    );
                                  }}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {color2 === i ? (
                                    <div className="col-1">
                                      <SvgImage name="SelectedRadio" />
                                    </div>
                                  ) : (
                                    <div className="col-1">
                                      <SvgImage name="UnselectedRadio" />
                                    </div>
                                  )}
                                  <div
                                    className="col-11"
                                    style={{ textAlign: "left" }}
                                  >
                                    {data?.companyName}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </FormGroup>
                <FormGroup>
                  <label for="destination" className="heading3 semiBold">
                    Payor
                  </label>
                  <div className="custNamepadd3">
                    <input
                      type="text"
                      name="payor"
                      id="payor"
                      placeholder={"payor"}
                      className={`inputBoxCust1 ${
                        touched.payor && errors.payor ? "is-invalid" : ""
                      }`}
                      aria-label="Default select example"
                      onChange={(e) => {
                        setDisplay3(true);
                        setSearchCustomer(e.target.value);
                        setFieldValue("payor", e.target.value, true);
                      }}
                      onClick={() => {
                        setDisplay3(true);
                      }}
                      value={values.payor}
                      onBlur={() => {
                        setDisplay3(false);
                      }}
                      errors={errors.payor}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          findSearch3();
                        }
                      }}
                    ></input>
                  </div>
                  {display3 ? (
                    <div
                      className="padding16 newCashback whiteBackground"
                      style={{
                        position: "absolute",
                        width: "350px",
                        height: "275px",
                        zIndex: "90",
                      }}
                    >
                      <div
                        onClick={() => {
                          setDisplay3(false);
                          // setSearchCustomer("");
                          // //dispatch(clearAllDriversByVrn());
                          // setFieldValue("payor", "", true);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        style={{
                          position: "absolute",
                          right: "0.5rem",
                          top: "0.5rem",
                          cursor: "pointer",
                        }}
                      >
                        <SvgImage name="CloseButtonLogo" />
                      </div>
                      <div
                        style={{
                          overflowY: "scroll",
                          overflowX: "hidden",
                          height: "240px",
                        }}
                      >
                        {searchCustomer != "" ? (
                          customerList?.map((data, i) => {
                            return (
                              <div
                                key={i}
                                className="padding8 row"
                                onClick={() => {
                                  setColor2(i);
                                  setDisplay3(false);
                                  //setUserId(data?.User?.userId);
                                  setFieldValue(
                                    "payor",
                                    data?.companyName,
                                    true
                                  );
                                  setPayerIdTemp(data?.customerId);
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {color2 === i ? (
                                  <div className="col-1">
                                    <SvgImage name="SelectedRadio" />
                                  </div>
                                ) : (
                                  <div className="col-1">
                                    <SvgImage name="UnselectedRadio" />
                                  </div>
                                )}
                                {/* <div className="col-1">
                          <SvgImage name="UnselectedRadio" />
                        </div> */}
                                <div
                                  className="col-11"
                                  style={{ textAlign: "left" }}
                                >
                                  {data?.companyName}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>
                            {customerList?.map((data, i) => {
                              return (
                                <div
                                  key={i}
                                  className="padding8 row"
                                  onClick={() => {
                                    setColor2(i);
                                    setDisplay3(false);
                                    //setUserId(data?.User?.userId);
                                    setSearchCustomer(data?.companyName);
                                    setPayerIdTemp(data?.customerId);
                                    setFieldValue(
                                      "payor",
                                      data?.companyName,
                                      true
                                    );
                                  }}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {color2 === i ? (
                                    <div className="col-1">
                                      <SvgImage name="SelectedRadio" />
                                    </div>
                                  ) : (
                                    <div className="col-1">
                                      <SvgImage name="UnselectedRadio" />
                                    </div>
                                  )}
                                  <div
                                    className="col-11"
                                    style={{ textAlign: "left" }}
                                  >
                                    {data?.companyName}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </FormGroup>
              </FormGroup>
            </Form>
          )}
        </Formik>
        <div className="listAction">
          <div className="txnCategory">
            <button
              onClick={() => filterList("all")}
              className={
                all
                  ? "greenButtonNew heading4 Medium"
                  : "greenButtonNewNotSelected heading4 Medium"
              }
            >
              {/* All */}
              <div className="d-flex justify-content-between">
                <div style={{ paddingTop: "2px", marginRight: "8px" }}>All</div>
                <div
                  className={`${all ? "countAll" : "countAllInactive"}`}
                  // style={{ alignContent: "center" }}
                >
                  {countAll}
                </div>
              </div>
            </button>
            <button
              onClick={() => filterList("unInvoiced")}
              className={
                unInvoiced
                  ? "greenButtonNew heading4 Medium"
                  : "greenButtonNewNotSelected heading4 Medium"
              }
            >
              <div className="d-flex justify-content-between">
                <div style={{ paddingTop: "2px", marginRight: "8px" }}>
                  Uninvoiced
                </div>
                <div
                  className={`${unInvoiced ? "countAll" : "countAllInactive"}`}
                >
                  {uninvoicedCount}
                </div>
              </div>
            </button>
            <button
              onClick={() => filterList("invoiced")}
              className={
                invoiced
                  ? "greenButtonNew heading4 Medium"
                  : "greenButtonNewNotSelected heading4 Medium"
              }
            >
              <div className="d-flex justify-content-between">
                <div style={{ paddingTop: "2px", marginRight: "8px" }}>
                  Invoiced
                </div>
                <div
                  className={`${invoiced ? "countAll" : "countAllInactive"}`}
                >
                  {invoicedCount}
                </div>
              </div>
            </button>
            <button
              onClick={() => filterList("unLinked")}
              className={
                unLinked
                  ? "greenButtonNew heading4 Medium"
                  : "greenButtonNewNotSelected heading4 Medium"
              }
            >
              <div className="d-flex justify-content-between">
                <div style={{ paddingTop: "2px", marginRight: "8px" }}>
                  Not In Trips
                </div>
                <div
                  className={`${unLinked ? "countAll" : "countAllInactive"}`}
                >
                  {notInTripCount}
                </div>
              </div>
            </button>
            <button
              onClick={() => filterList("linked")}
              className={
                linked
                  ? "greenButtonNew heading4 Medium"
                  : "greenButtonNewNotSelected heading4 Medium"
              }
            >
              <div className="d-flex justify-content-between">
                <div style={{ paddingTop: "2px", marginRight: "8px" }}>
                  In Trips
                </div>
                <div className={`${linked ? "countAll" : "countAllInactive"}`}>
                  {inTripCount}
                </div>
              </div>
            </button>
          </div>
          <div className="rightAction">
            {createInvoice && isTrips == 3 ? (
              <div style={{ marginRight: 16 }}>
                <button
                  type="button"
                  className="greenButtonNew btn-secondary"
                  style={{ width: "160px" }}
                  onClick={chooseRouteModalToggle}
                >
                  + Create Trip
                </button>
              </div>
            ) : (
              <></>
            )}
            <div>
              <button
                type="button"
                className="greenButton btn-secondary"
                style={{ width: "160px" }}
                onClick={() => {
                  if (["3", 3].includes(isTrips)) {
                    setFreightId("");
                    setIsEdit(false);
                    createLR();
                  }
                }}
              >
                + Create New LR
              </button>
            </div>
          </div>
        </div>

        {freightListCount == undefined ||
        (freightListCount > 0 && freightList?.length == 0) ? (
          <div
            className="mt-2 boxContainer"
            style={{
              padding: 70,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BallTriangle
              height={70}
              width={70}
              radius={5}
              color="#2e7c7b"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={freightList}
            customStyles={{
              headRow: {
                style: {
                  backgroundColor: "#ededf1",
                  fontSize: "16px",
                  fontWeight: "bold",
                },
              },
              cells: {
                style: { fontSize: "14px", minHeight: "50px" },
              },
            }}
            // fixedHeader
            // fixedHeaderScrollHeight='450px'
            selectableRows
            selectableRowsHighlight={true}
            highlightOnHover
            // pagination
            onRowClicked={(row) => {
              let payload = {};
              if (invoiced) {
                payload["invoice"] = 1;
              }
              if (unInvoiced) {
                payload["invoice"] = 0;
              }
              if (unLinked) {
                payload["trip"] = 0;
              }
              if (linked) {
                payload["trip"] = 1;
              }
              console.log("statestatestatestate 0000",row.freightId);
              navigate(`/lr/${row.freightId}`, {
                state: {
                  lr: row,
                  payload: payload,
                  offset: offset,
                  fleetId: fleetIdTemp,
                  driverId: driverIdTemp,
                  tripNo: tripNoTemp,
                  lrNo: lrNoTemp,
                  startTime: moment(startDate).format("MM/DD/YYYY"),
                  endTime: moment(endDate).format("MM/DD/YYYY"),
                  source: searchSource,
                  destination: searchDestination,
                  freightId: row.freightId,
                },
              });
            }}
            onSelectedRowsChange={(selected) => {
              if (selected.selectedCount > 0) {
                setCreateInvoice(true);
                setCustomerIdInvoice(selected.selectedRows[0].customerId);
                setSelectedRow(selected.selectedRows);
              }
              if (selected.selectedCount == 0) {
                setCreateInvoice(false);
                setCustomerIdInvoice("");
                setSelectedRow([]);
              }
              console.log("onSelectedRowsChange", selected.selectedRows);
            }}
            selectableRowDisabled={(row) => {
              if (row?.tripId) return true;
              if (!createInvoice) return false;
              console.log("selectableRowDisabled", row);
              return (
                // row.customerId != selectedRow?.[0]?.customerId ||
                row.fleetId != selectedRow?.[0]?.fleetId
              );
            }}
          />
        )}

        {/* { */}
        <div className="reactPagination leftAlign">
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
        {/* } */}
        {/* <InvoiceDetail
          toggle={createLR}
          modal={modal}
          setModal={setModal}
          
          /> */}
        {modal ? (
          <CreateLRModal
            toggle={createLR}
            modal={modal}
            setModal={setModal}
            location={"/lr/"}
            isEdit={isEdit}
            freightId={freightId}
            setReload={setReload}
            reload={reload}
            vehEdit={vehEdit}
          />
        ) : null}
        {uploadPodModal ? (
          <UploadPodForLr
            toggle={toggleUploadPodModal}
            modal={uploadPodModal}
            setModal={setUploadPodModal}
            location={"/lr/"}
            freightId={freightId}
          />
        ) : null}

        {createTripModal ? (
          <CreateTripFromLR
            toggle={createTrip}
            modal={createTripModal}
            setModal={setCreateTripModal}
            location={"/lr/"}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            setCreateInvoice={setCreateInvoice}
            setPaymentDetailsModal={setPaymentDetailsModal}
            setCustomerIdInvoice={setCustomerIdInvoice}
            routeData={routeData}
            setTripData={setTripData}
          />
        ) : null}

        {paymentDetailsModal ? (
          <PaymentDetails
            toggle={paymentDetailsModalToggle}
            tripData={tripData}
            modal={paymentDetailsModal}
            setModal={setPaymentDetailsModal}
            routeData={routeData}
            setRouteData={setRouteData}
            setItemTemp={setTripData}
            // location={"/lr/"}
          />
        ) : null}
        <ChooseRoute
          toggle={chooseRouteModalToggle}
          createTrip={createTrip}
          tripData={tripData}
          modal={chooseRouteModal}
          // setPaymentDetailsModal={setPaymentDetailsModal}
          setModal={setChooseRouteModal}
          setRouteData={setRouteData}
          // location={"/lr/"}
        />
      </div>
    </div>
  );
}

export default LoadReceipt;
