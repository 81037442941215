import React, { useState } from "react";
import AddBalanceButton from "../../Buttons/addBalancebutton/index";
import TransactionBox from "../../../subComponents/Transaction/index";
import CardWallet from "../../Buttons/cardWalletButton/index";
import LogipeAccountAddBalanceModal from "../../../Modals/logipeAccountAddBalance/index";
import CardWalletAddBalanceModal from "../../../Modals/cardWalletAddBalance/index";
import LogipeAccountButtons from "../../../Modals/logipeAccount/index";
import CardWalletButtons from "../../../Modals/cardWallet/index";
import LinkNewFastagButton from "../../../Modals/linkNewFastag/index";
import RechargeFastagButtton from "../../../Modals/rechargeFastag/index";
import SvgImage from "../../../Icons/Icons";
import { useDispatch, useSelector } from "react-redux";

import "./index.css";
import { toastStatus } from "../../../../services/Store/Actions/appActions";

function HomeCard(props) {
  // const {toastOpen, setSuccessStatus,handleToastShow,setToastMsg}= props;

  const {
    title,
    amount,
    type,
    receiver,
    subtitle,
    btn1,
    btn2,
    btn3,
    history,
    ownerLogipeBalance,
  } = props;

  //console.log ("^^in the neft component-----------",ownerLogipeBalance);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [btn, setBtn] = useState(0);
  const [active, setActive] = useState(0);
  const [comp, setComp] = useState(title);
  const [proceed, setProceed] = useState(0);
  const dispatch = useDispatch();
  const [displayName, setDisplayName] = useState([]);
  const [copyIcon, setCopyIcon] = useState(0);
  const [copyIconIfsc, setCopyIconIfsc] = useState(0);
  const [proceed1, setProceed1] = useState(0);
  const [enterButton, setEnterButton] = useState(0);
  const [disable, setDisable] = useState(true);

  function nextSection() {
    setProceed(1);
  }

  // this is used to set the active buttons out of 3
  function handleActiveButton(val) {
    //console.log(val);
    setActive(val);
    setEnterButton(0);
    setDisplayScreen(0);
    setShowList(0);
    setProceed1(0);
    //setNewProceed (0);
  }

  // this is used to set values after the close button is clicked
  function toggleModal2() {
    setDisplayName(displayName.filter((e) => e.name === ""));
    setShow(!show);
    setShowList(0);
    setDisplayScreen(0);
    setProceed1(0);
    // setNewProceed (0);
    setEnterButton(0);
    //alert("1");
  }

  // useEffect(() => {
  //   dispatch(transactionHistory({
  //     limit : 1,
  //     search: '',
  //     minKyc: '',
  //     sortAs: '',
  //     sortBy: '',
  //   }));
  // }, []);

  const OwnerBalance = amount == null ? 0 : amount;
  const [showList, setShowList] = useState(0);
  const [displayScreen, setDisplayScreen] = useState(0);

  const handleModale2Close = () => {
    setDisplayName(displayName.filter((e) => e.name === ""));
    setShow(false);
    setShowList(0);
    setDisplayScreen(0);
  };

  const handleModale2Show = (opt) => {
    //console.log(opt);
    setShow(true);
    opt === "card wallet"
      ? setActive(0)
      : opt === "fastag wallet"
      ? setActive(1)
      : opt === "bank account"
      ? setActive(2)
      : opt === "Transfer Balance"
      ? setActive(3)
      : opt === "Withdraw Balance"
      ? setActive(4)
      : opt === "Link New Fastag"
      ? setActive(5)
      : setActive(6);
  };

  function toggleModal() {
    setCopyIconIfsc(0);
    setCopyIcon(0);
    setOpen(!open);
    setProceed(0);
  }

  function handleClick(val) {
    if (btn !== val) {
      setBtn(val);
    }
  }

  const handleClose = () => {
    setCopyIconIfsc(0);
    setCopyIcon(0);
    setOpen(false);
    setProceed(0);
    //alert("2");
  };

  const handleShow = () => {
    //dispatch(actions());
    // dispatch(toastStatus(true));
    setOpen(true);
  };

  const logipeAccountBalance = ownerLogipeBalance?.balance
    ? ownerLogipeBalance?.balance
    : 0;

  return (
    <div className="mainn card container">
      <div className="row">
        <div className="col-12">
          <h3 className="title card-title">{title}</h3>
          {title !== "Driver cards" ? (
            <AddBalanceButton
              Value="Add Balance"
              title={title}
              onclick={handleShow}
            />
          ) : (
            <></>
          )}
          {title === "Master Account" ? (
            <LogipeAccountAddBalanceModal
              copyIconIfsc={copyIconIfsc}
              setCopyIconIfsc={setCopyIconIfsc}
              copyIcon={copyIcon}
              setCopyIcon={setCopyIcon}
              open={open}
              toggleModal={toggleModal}
              title={title}
              handleClick={handleClick}
              handleClose={handleClose}
              btn={btn}
              nextSection={nextSection}
              proceed={proceed}
              ownerLogipeBalance={ownerLogipeBalance}
            />
          ) : (
            <CardWalletAddBalanceModal
              amount={amount}
              open={open}
              toggleModal={toggleModal}
              title={title}
              handleClick={handleClick}
              handleClose={handleClose}
              ownerLogipeBalance={ownerLogipeBalance}
              btn={btn}
              color={title === "Card Wallet" ? "title-color1" : "title-color2"}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <SvgImage
            name={
              title === "Master Account"
                ? "BankLogo"
                : title === "Driver cards"
                ? "cardWalletLogo"
                : "tollBooth"
            }
            classname="title-logo"
            height={
              title === "Master Account"
                ? "30.48"
                : title === "Driver cards"
                ? "26.66"
                : "28.44"
            }
            width={
              title === "Master Account"
                ? "30.48"
                : title === "Driver cards"
                ? "28.86"
                : "28.44"
            }
          />
          <p className="amount card-subtitle">
            ₹{amount === null ? "0" : amount}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TransactionBox
            openBox={show}
            type={type}
            receiver={receiver}
            Title={title}
            history={history}
          />
        </div>
      </div>
      <div className="options-title row">
        <p>{subtitle}</p>
      </div>
      <div className="options">
        {title === "Master Account" ? (
          <>
            {/* <CardWallet
              title={btn1}
              name="CardLogo"
              onclick={handleModale2Show}
            /> */}

            <CardWallet
              title={btn2}
              name="TollLogo"
              onclick={handleModale2Show}
            />

            <CardWallet
              title={btn3}
              name="GreenBankLogo"
              onclick={handleModale2Show}
            />

            {active === 0 ? (
              <LogipeAccountButtons
                open={show}
                toggleModal={toggleModal2}
                title={btn1}
                handleClick={handleActiveButton}
                handleClose={handleModale2Close}
                btn={active}
                ownerLogipeBalance={ownerLogipeBalance}
                showList={showList}
                setShowList={setShowList}
                setDisplayScreen={setDisplayScreen}
                displayScreen={displayScreen}
                proceed1={proceed1}
                setProceed1={setProceed1}
              />
            ) : active === 1 ? (
              <LogipeAccountButtons
                open={show}
                toggleModal={toggleModal2}
                title={btn2}
                handleClick={handleActiveButton}
                handleClose={handleModale2Close}
                btn={active}
                ownerLogipeBalance={ownerLogipeBalance}
                showList={showList}
                setShowList={setShowList}
                setDisplayScreen={setDisplayScreen}
                displayScreen={displayScreen}
                proceed1={proceed1}
                setProceed1={setProceed1}
              />
            ) : (
              // <LogipeBankAccount
              //   open={show}
              //   toggleModal={toggleModal2}
              //   title={btn3}
              //   handleClick={handleActiveButton}
              //   handleClose={handleModale2Close}
              //   btn={active}
              //   ownerLogipeBalance={ownerLogipeBalance}
              // />
              <LogipeAccountButtons
                open={show}
                toggleModal={toggleModal2}
                title={btn3}
                handleClick={handleActiveButton}
                handleClose={handleModale2Close}
                btn={active}
                ownerLogipeBalance={ownerLogipeBalance}
                showList={showList}
                setShowList={setShowList}
                setDisplayScreen={setDisplayScreen}
                displayScreen={displayScreen}
                proceed1={proceed1}
                setProceed1={setProceed1}
                enterButton={enterButton}
                setEnterButton={setEnterButton}
              />
            )}
          </>
        ) : title === "Driver cards" ? (
          <>
            <CardWallet
              title={btn1}
              name="UpArrowCardLogo"
              onclick={handleModale2Show}
            />
            <CardWallet
              title={btn2}
              name="DownArrowCardLogo"
              onclick={handleModale2Show}
            />
            {active == 3 ? (
              <CardWalletButtons
                open={show}
                toggleModal={toggleModal2}
                title={title}
                handleClick={handleActiveButton}
                handleClose={handleModale2Close}
                btn={active}
                amount={OwnerBalance}
              />
            ) : (
              <CardWalletButtons
                open={show}
                toggleModal={toggleModal2}
                title={title}
                handleClick={handleActiveButton}
                handleClose={handleModale2Close}
                btn={active}
                amount={OwnerBalance}
              />
            )}
          </>
        ) : (
          <>
            <CardWallet
              title={btn1}
              name="LinkLogo"
              onclick={handleModale2Show}
            />
            <CardWallet
              title={btn2}
              name="CurrentLogo"
              onclick={handleModale2Show}
            />
            {active === 5 ? (
              <LinkNewFastagButton
                open={show}
                toggleModal={toggleModal2}
                title={title}
                handleClick={handleActiveButton}
                handleClose={handleModale2Close}
                btn={active}
                amount={OwnerBalance}
                displayName={displayName}
                setDisplayName={setDisplayName}
              />
            ) : (
              <RechargeFastagButtton
                open={show}
                toggleModal={toggleModal2}
                title={title}
                handleClick={handleActiveButton}
                handleClose={handleModale2Close}
                btn={active}
                amount={OwnerBalance}
              />
            )}
          </>
        )}
      </div>
      {/* const {(success, message, isOpen, onHide, toggle, handleClose)}= props;s */}
    </div>
  );
}

export default HomeCard;
