import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
// const { Toast } = bootstrap;
//import * as bootstrap from 'bootstrap';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Field, ErrorMessage } from "formik";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { verifyFastagOTPAction } from "../../../services/Store/Actions/fastagAction";
import { m2pVKYCAction } from "../../../services/Store/Actions/m2pAction";
import copy from "copy-to-clipboard";

function CardDriverKYCModal(props) {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    mobileNo,
    handleShowKYC,
  } = props;

  //const { Toast } = bootstrap;
  const notify = () => toast.success("Copied to clipboard !!");
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("* Required")
      .matches(/^[0-9]{6}$/, "* Otp Must be exactly 6 digits"),
  });

  const dispatch = useDispatch();
  //   const userr = sessionStorage.getItem("_user");
  //   const obj = JSON.parse(userr);
  //   const mob = obj?.user?.phone;

  let { id } = useParams();
  

  useEffect(() => {
    console.log("memememememe--", id);
    if(id!= undefined){
    //   dispatch(m2pVKYCAction(id,(res)=>{
    //     console.log("heyyyyyyyyyyyyyyy2",res);}));
     }
  }, [id]);

  const url = useSelector((state) => state?.m2p?.V_KycUrl);
  console.log("tttttttttttttttttt", id)
  console.log("9999999999999999", url);

  const copyToClipboard = () => {
    copy(url);
    notify();
    // setToast(true);
  };

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Driver's KYC</p>
            <div
              className="padding16"
              style={{ paddingTop: "20px", paddingLeft: "170px" }}
            >
              <SvgImage name="livquikLogo" />
            </div>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
              style={{ top: "25px" }}
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div
              style={{
                margin: "auto",
                paddingTop: "0.5rem",
                textAlign: "center",
              }}
            >
              <SvgImage name="driverKYCImage" />
            </div>
            <div className="kycText1 padding16T Regular heading2">
              <div>Click on start card integration and</div>
              <div>complete your driver KYC to get a</div>
              <div>LogiPe card.</div>
            </div>
            <div>
              <button
                type="submit"
                className="butt4"
                style={{ display: "flex", marginTop: "30px" }}
                onClick={() => {
                  window.open(url);
                }}
              >
                <SvgImage name="vKycIcon" />
                <div className="padding8L">Start V-KYC</div>
              </button>
            </div>
            <div className="row padding24" style={{ color: "#A7A7A7" }}>
              <div className="col-5 line1"></div>
              <div className="col-2 semiBold orText">OR</div>
              <div className="col-5 line2"></div>
            </div>
            <div className="lowerBox padding8" style={{ width: "85%" }}>
              <div className="Bold heading3">
                Share V-KYC link to your driver and follow mentioned
              </div>
              <div className="Bold heading3">
                steps to complete V-KYC process.
              </div>
              <ol className="textDiv Regular padding8T">
                <li>Click on the shared V-KYC link. </li>
                <li>Select language to start V-KYC process.</li>
                <li>
                  Follow instructions from our live agent and complete V-KYC.
                </li>
              </ol>
              <div style={{ margin: "auto" }} onClick={copyToClipboard}>
                <button className="copyButton semiBold" id="liveToastBtn">
                  Copy V-KYC Link
                </button>
              </div>
            </div>
          </ModalBody>
        </div>
        <ToastContainer theme="dark" position="bottom-right"
          closeOnClick pauseOnHover/>
      </Modal>
    </div>
  );
}
export default CardDriverKYCModal;
