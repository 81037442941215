import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import SvgImage from "../../../../Components/Icons/Icons";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { cleanLoginLoader, sendOtp, verifyOtp } from "../../../../services/Store/Actions/LoginAction";
import PinInput from "react-pin-input";
import { Mixpanel } from "../../../../Mixpanel";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";

const ForgotPasswordOtpVerify = (props) => {
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("OTP is Required")
  });

  const {setForgotStatus, setValidationData}= props;
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state?.loginReducer);
  const [timer, setTimer] = useState("00:00");
  const [error, setError] = useState(false);
  const Ref = useRef(null);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    //const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        //(hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e, val) => {
    setTimer(val);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = (value) => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + value);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime(30), "00:30");
  }, []);


  function handleResend() {
    //console.log ("yeh ph no", props.phoneNo);
    dispatch(sendAnalitics("ResendOTP_web"));
    setTimer("00:00");
    dispatch(
      sendOtp({
        phone: props.phone,
        resend: "any"
      }, (res) => {
        if (res?.data?.type === "success") {
          console.log("resend ho gaya");
        }
      })
    );
    clearTimer(getDeadTime(60), "00:60");
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        phone: props.phone,
        otp:""
      }}
      onSubmit={(values, actions) => {
        //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
        console.log("valuessss", values);
        dispatch(sendAnalitics("Submit_web", values));
        dispatch(
          verifyOtp(values, async (res) => {
            if (res.status == 401) {
              actions.setFieldError("otp", "Incorrect OTP. Please check again.");
              dispatch(cleanLoginLoader());
            } else if (res?.data?.data?.user?.userId) {
              console.log("TOKEN>>>", res.data?.data?.user?.type);
              if (res.data?.data?.user?.type === "2") {
                storeDataToAsyncStorage("_token", res.data?.data.token);
                // storeDataToAsyncStorage("_user", res.data?.data);
                setValidationData(res.data?.data);
                storeDataToAsyncStorage(
                  "_refreshToken",
                  res.data?.data.refreshToken
                );
                const x =  Mixpanel.getDistinctId();
                if (x != res?.data?.data?.user?.userId) {
                  Mixpanel.alias(res?.data?.data?.user?.userId, x);
                }
                Mixpanel.identify(res.data?.data?.user?.userId);
                Mixpanel
                  .people.set({
                    'Phone': res.data?.data?.user?.phone,
                    '$name': res.data?.data?.user?.fullName,
                    'userId': res?.data?.data?.user?.userId,
                  });
                  setForgotStatus("2")
                //dispatch(getOwnerAccountDetails(),res => {});
                // dispatch(handleLoginSuccess(res.data?.data));
                //dispatch(getOwnerDetails());
              
                // Navigate("/home");
              } else if (res.data?.data?.user?.type === "1") {
                //setError(true);
                actions.setFieldError("otp", "Cannot login through Driver's number");
              }else if (res.data?.data?.user?.type === "3") {
                //setError(true);
                storeDataToAsyncStorage("_token", res.data?.data.token);
                // storeDataToAsyncStorage("_user", res.data?.data);
                setValidationData(res.data?.data);
                storeDataToAsyncStorage(
                  "_refreshToken",
                  res.data?.data.refreshToken
                );
                const x =  Mixpanel.getDistinctId();
                if (x != res?.data?.data?.user?.userId) {
                  Mixpanel.alias(res?.data?.data?.user?.userId, x);
                }
                Mixpanel.identify(res.data?.data?.user?.userId);
                Mixpanel
                  .people.set({
                    'Phone': res.data?.data?.user?.phone,
                    '$name': res.data?.data?.user?.fullName,
                    'userId': res?.data?.data?.user?.userId,
                  });
                  setForgotStatus("2")
                //dispatch(getOwnerAccountDetails(),res => {});
                // dispatch(handleLoginSuccess(res.data?.data));
                //dispatch(getOwnerDetails());
              
                // Navigate("/home");
              } 
            }  else if(res.status == 429) {
              //setError(true);
              actions.setFieldError(
                "otp",
                "Please Wait 2 minutes and Try Again."
              );
            }
            else {
              //setError(true);
              actions.setFieldError("otp", "Kindly register yourself to enjoy our services.");
            }
          })
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* <FormGroup className="formPosition">
                <div className="Regular text3 greyFont">Welcome! 👋</div>
                <div className="heading1 semiBold">Sign in to resent password</div>
            </FormGroup> */}
          <FormGroup className="formPosition">
            <div className="Regular text3 padding8T headText2" s>
              Enter the OTP sent to +91 {props.phone}
              <div
                className="editIcon"
                onClick={() => {
                  dispatch(sendAnalitics("ChangeNumber_web"));
                  props.setForgotStatus("0");
                }}
              >
                <SvgImage name="editLogo" width="18" height="18" />
              </div>
            </div>
           
                <div className="otpInput">
                  <PinInput
                    length={4}
                    initialValue=""
                    secret={false}
                    onChange={handleChange("otp")}
                    type="numeric"
                    value={values.otp}
                    onBlur={handleBlur("otp")}
                    touched={touched.otp}
                    errors={errors.otp}
                    inputMode="number"
                    style={{
                      padding: "10px 0px 10px 0px",
                    }}
                    inputStyle={{
                      borderBottom: "1px solid #C8C8CA",
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      marginLeft: "45px",
                      marginRight: "0px",
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    focus={true}
                  />
                </div>
                <div className="padding16T text4">
                  Resend OTP in <span className="greenText">{timer}</span>
                </div>
                <div
                  className="text4"
                  style={{
                    margin: "0.3rem 0 0 0",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {errors.otp && touched.otp ? <div>{errors.otp}</div> : null}
                </div>
            <Button
              type="submit"
              className="loginButton"
              style={{
                marginTop: "1.5rem",
                background: values.otp.length < 4 ? "#EFEFEF" : (loader ? "#EFEFEF":"#2e7c7b") ,
                color: values.otp.length < 4 ? "#B5B5B5" : (loader ? "#B5B5B5":"#ffffff"),
              }}
              disabled={loader ? true : false}
            >
              {loader ? "Please Wait" : "Verify OTP"}
            </Button>
            <div
              className="bottomTextLogin padding16T Regular text4"
              style={{ color: "#25252D" }}
            >
              Didn’t recieve the code?
              <span
                style={{
                  color: timer === "00:00" ? "#2E7C7B" : "#B5B5B5",
                  cursor: timer === "00:00" ?"pointer":"default",
                  paddingLeft: "0.5rem",
                }}
                className="Medium"
                onClick={handleResend}
              >
                Resend
              </span>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordOtpVerify;
