import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "reactstrap";
import SvgImage from "../../../../../Icons/Icons";
import {  getVehicleListAction } from "../../../../../../services/Store/Actions/manageVehicleAction";
import { getFastagWalletBalance, getVehicleDetails, getVehicleDetailsByFleetId, reissueAvailableAction } from "../../../../../../services/Store/Actions/fastagAction";
import RechargeFastagButttonModal from "../../../../../Modals/rechargeFastagButton";
import ClipLoader from "react-spinners/ClipLoader";

const FastagComponent=(props)=>{


    const dispatch= useDispatch();
    const omcDriverData= useSelector((state)=>state?.omc?.omcDriverData);
    const [open, setOpen]= useState(false);

    const handleClose=()=>{
        setOpen(false);
    }

    const toggleModal=()=>{
        setOpen(!open);
    }

    const showModal=()=>{
        setOpen(true);
    }

    useEffect(()=>{
    if(omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.regNumber)
    {
        dispatch(
            getVehicleListAction({
                offset: 0,
                search: omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.regNumber,
                limit: 15,
                minKyc: "",
                sortAs: "",
                sortBy: "",
            })
        );
        dispatch(
            reissueAvailableAction({
              fleetId: omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.fleetId,
              vrn: omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.regNumber,
            })
          );
        dispatch(getFastagWalletBalance());
        dispatch(getVehicleDetailsByFleetId(omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.fleetId));
        dispatch(getVehicleDetails(omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.fleetId));
    }
    },[omcDriverData])

    const fleetVal= useSelector((state)=>state?.manageVehicle?.allFleets);
    const fleetValLoader= useSelector((state)=>state?.manageVehicle?.loader);


    const isReissueAvailable = useSelector(
        (state) => state?.fastag?.isReissueAvailable
      );
    const hotlistStatus = isReissueAvailable?.npciStatus;

    const lowBalanceStatus =
    fleetVal?.rows?.[0] &&
    Number(fleetVal?.rows?.[0]?.Fastag?.tagDetails?.minBalAutoAlert) >
      Number(fleetVal?.rows?.[0]?.Fastag?.balance)
      ? true
      : false;

    const isCorporate = useSelector(
    (state) => state?.fastag?.fastagWallet?.isCorporate
    );

    const { fastagWallet } = useSelector((state) => state?.fastag);

    const fastagWalletAmount =
        fastagWallet.balance == null ? 0 : fastagWallet.balance;

    const { vehicleDetailsByVehicleID} = useSelector(
        (state) => state?.fastag
        );   
    
    const handleViewImage = (val) => {
        console.log("vallllllll", val)
        window.open(val);
        };    

    const vehicleDetails = useSelector((state) => state?.fastag?.vehicleDetails);
    const vehicleDetailsLoader = useSelector((state) => state?.fastag?.loader);
    const regDate = vehicleDetails?.vehicle?.regDate;
    const vehCategory =
        vehicleDetails?.vehicle?.vehicleCategory === undefined
        ? "N/A"
        : vehicleDetails?.vehicle?.vehicleCategory;
    return(
        <div className="padding16B">
            {
                omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.regNumber?
                <>
                <div className="row padding16 " style={{margin:"0", paddingLeft:"0.3rem"}}>
                    <div className="col-1 padding8T">
                        <SvgImage name="fastagLogoGreen" />
                    </div>
                    <div className="col-8">
                        <div className="Bold heading2">FASTag Details</div>
                        <div className="Regular heading3">{fleetValLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={fleetValLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:fleetVal?.rows?.[0]?.Fastag?.fleetId?fleetVal?.rows?.[0]?.Fastag?.fleetId:"NA"}</div>
                    </div>
                    {
                        lowBalanceStatus?
                        <div className="col-1 padding8T">
                            <button className="Low-balance-fastag-1 " style={{ textAlign: "center", padding:"1px 6px" }}>Low Balance</button>
                        </div>
                        :<></>
                    }
                </div>
                <div className="row padding8 pinkBackground padding16T padding16B" style={{margin:"0"}}>
                    <div className="col-8">
                        <div className="semiBold heading2" style={{color:lowBalanceStatus?"#E63946":"#000000"}}>{fleetValLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={fleetValLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:"₹"+ Number(fleetVal?.rows?.[0]?.Fastag?.balance).toFixed(2)}</div>
                        <div className="Regular heading4">Current FASTag Balance</div>
                    </div>
                    {
                        isCorporate === 0 ? 
                        <div className="col-4" onClick={showModal}>
                            <Button className="generateOtp heading3 semiBold">Recharge</Button>
                        </div>
                        :<></>
                    }
                    
                </div>
                <div className="row padding8 padding32B" style={{margin:"0"}}>
                    <div className="col-6">
                        <div className="Regular heading4 padding8">Vehicle Number</div>
                        <div className="Regular heading4 padding8">Vehicle Category</div>
                        <div className="Regular heading4 padding8">Registration Date</div>
                        <div className="Regular heading4 padding8">RC Photo</div>
                    </div>
                    <div className="col-6 rightAlignment">
                        <div className="semiBold heading3 padding8 padding7 ">{omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.regNumber}</div>
                        <div className="semiBold heading3 padding8 padding7">{vehicleDetailsLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={vehicleDetailsLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:vehCategory}</div>
                        <div className="semiBold heading3 padding8 padding7">{vehicleDetailsLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={vehicleDetailsLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />: regDate === undefined ? "N/A" : regDate ===null?"N/A" : regDate.split("T")?.[0]}</div>
                        <div className={vehicleDetailsByVehicleID?.rcImage?"semiBold heading3 padding8 padding7 downloadBtn":"semiBold heading3 padding8 padding7"}
                            onClick={() =>
                                handleViewImage(vehicleDetailsByVehicleID?.rcImage)
                              }>{vehicleDetailsByVehicleID?.rcImage?"Download":"NA"}</div>
                    </div>
                </div>
                { <RechargeFastagButttonModal
                    open={open}
                    handleClose={handleClose}
                    toggleModal={toggleModal}
                    balance={fastagWalletAmount}
                    fastagBal={Number(fleetVal?.rows?.[0]?.Fastag?.balance)}
                    no={omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.regNumber}
                    fleetId={omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.fleetId}
                    />}
                </>
                :<div>
                    <div className="centralEle " style={{padding:"4.5rem 0 4.5rem"}}>
                        <div className="padding32T">
                            <SvgImage name="kycLockLogo" />
                        </div>
                        <div className="Medium heading3 padding16T greyFont">Assign a vehicle for your driver to </div>
                        <div className="Medium heading3 greyFont padding32B">view FASTag details</div>
                    </div>
                </div>
            }
            
            
            
        </div>
    )
}

export default FastagComponent;