import { call, put, takeEvery } from "redux-saga/effects";
import {
  getDriversList,
  getDriverBal,
  expenseSumm,
  addMoney,
  withdrawMoneyFromWallet,
  getCardDetails,
  getExpenseWalletSummary,
  addExpenseToWallet,
  getCardControl,
  editCardControl,
  linkFLeet,
  ChangeCardStatusAPI,
  getFleetFleetID,
  getDriversList2Api,
  getDriverListReportApi,
  newCardControlApi,
  newEditCardControlApi,
  getDriversListWithVrnApi,
  downloadSalaryApi,
  uploadSalaryApi,
  addNicknameApi,
  downloadSalaryCreditSheetApi,
  uploadSalaryCreditSheetApi,
  getSalaryWalletDetailsApi,
  editSalaryWalletDetailsApi,
  createSalaryWalletApi,
  getFleetTrackerApi,
  generateFreshdeskTicketV2Api,
  payDriverSalaryApi,
  editSalaryWalletApi,
  getSalaryWalletStatementApi,
  advancedDriverListApi,
  salaryWalletListApi,
  salaryWalletSummaryApi
} from "../../Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import { toastStatus } from "../../../services/Store/Actions/appActions";

// import {
//   addCustomerSuccess,

// } from '../Actions/receivableActions';
import {
  getDriverListSuccess,
  getDriverBalSuccess,
  expenseSummSuccess,
  getFleetSuccess,
  addMoneySuccess,
  withdrawMoneyFromWalletSuccess,
  getCardDetailsSuccess,
  getExpenseWalletSummarySuccess,
  addExpenseToWalletSuccess,
  getCardControlSuccess,
  editCardControlSuccess,
  linkFleetSuccess,
  changeCardStatusSuccess,
  getFleetByFleetIDSuccess,
  getDriverList2Success,
  getDriverListStatement,
  getDriverListStatementSuccess,
  getdriverListStatementSuccess,
  newGetCardControlActionSuccess,
  newEditCardControlActionSuccess,
  getDriverListHomeSuccess,
  searchByVrnAction,
  searchByVrnActionSuccess,
  downloadSalaryActionSuccess,
  uploadSalaryActionSuccess,
  addNicknameActionSuccess,
  downloadSalaryCreditSheetSuccess,
  uploadSalaryCreditSheetSuccess,
  getSalaryWalletDetailsSuccess,
  editSalaryWalletDetailsSuccess,
  createSalaryWalletSuccesss,
  getFleetTrackerSuccess,
  generateFreshdeskTicketV2Success,
  payDriverSalarySuccess,
  editSalaryWalletSuccess,
  getSalaryWalletStatementSuccess,
  getAdvanceDriverListSuccess,
  getSalaryWalletListSuccess,
  getSalaryWalletSummarySuccess
} from "../Actions/driverslistActions";
// import {
//   ADD_CUSTOMER,
// } from '../storeTypes';

import {
  EXPENSE_SUMM,
  GET_DRIVERS_LIST,
  GET_DRIVER_BAL,
  ADD_MONEY_TO_WALLET,
  WITHDRAW_MONEY_FROM_WALLET,
  GET_CARD_DETAILS,
  GET_EXPENSE_WALLET_SUMMARY,
  ADD_EXPENSE_TO_WALLET,
  GET_CARD_CONTROL,
  EDIT_CARD_CONTROL,
  LINK_FLEET,
  CHANGE_CARD_STATUS,
  GET_FLEET_BY_FLEET_ID,
  GET_DRIVER_LIST_2,
  GENERATE_DRIVER_WALLET_STATEMENT,
  NEW_EDIT_CARD_CONTROL,
  NEW_GET_CARD_CONTROL,
  GET_DRIVERS_LIST_HOME,
  SEARCH_BY_VRN,
  UPLOAD_SALARY,
  DOWNLOAD_SALARY,
  ADD_NICKNAME,
  DOWNLOAD_SALARY_CREDIT_SHEET,
  UPLOAD_SALARY_CREDIT_SHEET,
  GET_SALARY_WALLET_DETAILS,
  EDIT_SALARY_WALLET_DETAILS,
  CREATE_SALARY_WALLET,
  GET_FLEET_LIST_TRACKER,
  GENERATE_FRESH_DESK_TICKET_V2,
  PAY_DRIVER_SALARY,
  EDIT_SALARY_WALLET,
  GENERATE_SALARY_WALLET_STATEMENT,
  GET_DRIVERS_LIST_ADVANCE,
  GET_ALL_SALARY_WALLET,
  GET_SALARY_WALLET_SUMMARY
} from "../storeTypes";

function* handleDriverList(action) {
  try {
    //console.log("sdfsfghjhgf",action)
    const response = yield call(getDriversList, action.payload);
    //console.log("IDM",response)

    if (response.data.type == "success") {
      //   action.payload = response;
      yield put(getDriverListSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("driver Error ", processErrorAndRespond(error));
  }
}

function* homeDriverList(action) {
  try {
    //console.log("sdfsfghjhgf",action)
    const response = yield call(getDriversList, action.payload);
    //console.log("IDM",response)
    if (response.data.type == "success") {
      //   action.payload = response;
      yield put(getDriverListHomeSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("driver Error ", processErrorAndRespond(error));
  }
}

function* handleDriverBal(action) {
  try {
    const response = yield call(getDriverBal, action.payload);

    if (response.data.type == "success") {
      yield put(getDriverBalSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* handleExpenseSumm(action) {
  console.log("action ha bhai", action);
  try {
    const response = yield call(expenseSumm, action.payload, action.obj);

    console.log("dat ka reposme", response?.data?.data);
    if (response.data.type == "success") {
      if(action?.callback){
        action?.callback(response);
      }
      yield put(expenseSummSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* handleAddMoney(action) {
  try {
    const response = yield call(addMoney, action.payload, action.ID);
    // console.log("ADD money from saga", response);
    if (response.data.type == "success") {
      //console.log("hurrayyyy");
      action.callback(response);
      yield put(addMoneySuccess(response));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* handleWithdrawMoneyFromWallet(action) {
  try {
    const response = yield call(
      withdrawMoneyFromWallet,
      action.payload,
      action.ID
    );
    // console.log("WITHDRAW money from saga", response);
    if (response.data.type == "success") {
      console.log("hurrayyyy");
      action.callback(response);
      yield put(withdrawMoneyFromWalletSuccess(response));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* handleGetCardDetails(action) {
  try {
    const response = yield call(getCardDetails, action.payload);

    if (response.data.type == "success") {
      yield put(getCardDetailsSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* handleExpenseWalletSummary(action) {
  try {
    console.log("data passed --- saga------>>>>>>>>", action);
    const response = yield call(
      getExpenseWalletSummary,
      action.userId,
      action.payload
    );
    console.log('eeeeeeeeeeeeeeeeeeeeeeee',response)
    if (response.data.type == "success") {
      if(action?.callback){
        action?.callback(response);
      }
      yield put(getExpenseWalletSummarySuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* handleAddExpenseToWallet(action) {
  try {
    const response = yield call(addExpenseToWallet, action.payload, action.ID);
    // console.log("Add expense to wallet from saga", response);
    if (response.data.type == "success") {
      // console.log("mazeeeeee");
      action.callback(response);
      yield put(addExpenseToWalletSuccess(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Addition of new expense was successful",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* handleGetCardControl(action) {
  try {
    const response = yield call(getCardControl, action.payload);

    if (response.data.type == "success") {
      yield put(getCardControlSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* handleEditCardControl(action) {
  try {
    const response = yield call(editCardControl, action.payload);
    // console.log("Add expense to wallet from saga", response);
    if (response.data.type == "success") {
      console.log("mazeeeeee");
      action.callback(response);
      yield put(editCardControlSuccess(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Limit has been updated successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* handleLinkFleet(action) {
  try {
    const response = yield call(linkFLeet, action.payload);
    // console.log("Add expense to wallet from saga", response);
    if (response.data.type == "success") {
      //console.log("mazeeeeee");
      action.callback(response);
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Selected vehicle has been assigned successfully",
          status: true,
        })
      );
      yield put(linkFleetSuccess(response));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* ChangeCardStatusSaga(action) {
  try {
    const response = yield call(ChangeCardStatusAPI, action.payload);
    //console.log("RESPONSE >>>",response)
    if (response?.data?.type == "success") {
      action.callBack(response?.data);
      yield put(changeCardStatusSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("ChangeCardStatus errpr:- ", error?.response?.data);
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* handleGetFleetByFleetID(action) {
  try {
    const response = yield call(getFleetFleetID, action.payload);
    console.log("RESPONSE >>> fleet ka", response);
    if (response?.data?.type == "success") {
      // action.callBack(response?.data);
      console.log("from saga bro", response?.data);
      yield put(getFleetByFleetIDSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("ChangeCardStatus errpr:- ", error?.response?.data);
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* newDriverStatementReportSaga(action) {
  try {
    //console.log("sdfsfghjhgf",action)

    const response = yield call(
      getDriverListReportApi,
      action?.userId,
      action.payload
    );
    //console.log("IDM",response)

    if (response.data.type == "success") {
      //   action.payload = response;
      action.callback(response);
      yield put(getdriverListStatementSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("driver Error ", processErrorAndRespond(error));
  }
}
function* newDriverList(action) {
  try {
    //console.log("sdfsfghjhgf",action)
    const response = yield call(getDriversList, action.payload);
    //console.log("IDM",response)

    if (response.data.type == "success") {
      //   action.payload = response;
      yield put(getDriverList2Success(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("driver Error ", processErrorAndRespond(error));
  }
}

// new sagas for card control

function* newGetCardControlSaga(action) {
  try {
    const response = yield call(newCardControlApi, action.payload);
    if (response.data.type == "success") {
      yield put(newGetCardControlActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* newEditCardControlSaga(action) {
  try {
    console.log("edit in saga", action.userId);
    console.log("edit in saga", action.payload);
    const response = yield call(
      newEditCardControlApi,
      action.userId,
      action.payload
    );
    // console.log("Add expense to wallet from saga", response);
    if (response.data.type == "success") {
      console.log("mazeeeeee");
      action.callback(response);
      yield put(newEditCardControlActionSuccess(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Limit has been updated successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* searchByVrnSaga(action) {
  try {
    // console.log("edit in saga", action.userId);
    // console.log("edit in saga", action.payload);
    const response = yield call(
      getDriversListWithVrnApi,
      action?.payload
    );
    // console.log("Add expense to wallet from saga", response);
    if (response.data.type == "success") {
      console.log("mazeeeeee");
      // action.callback(response);
      yield put(searchByVrnActionSuccess(response));
      // yield put(
      //   toastStatus({
      //     toastStatus: true,
      //     message: "Limit has been updated successfully",
      //     status: true,
      //   })
      // );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    // yield put(
    //   toastStatus({
    //     toastStatus: true,
    //     message: `Error : ${error?.response?.data.message}`,
    //     status: false,
    //   })
    // );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* uploadSalarySaga(action) {
  try {
    const response = yield call(uploadSalaryApi, action.payload);
    console.log("resss", response)
    if (response.status == "200") {
      action.callback(response);
      yield put(uploadSalaryActionSuccess(response?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Salary wallet created successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
    yield put(
      toastStatus({
        toastStatus: true,
        message: error?.response?.data?.message,
        status: false,
      })
    );
  }
}


function* downloadSalarySaga(action) {
  try {
    const response = yield call(downloadSalaryApi, action.payload);
    console.log("hsdbshf", response)
    // if (response.data.type == "success") {
      action.callback(response);
      yield put(downloadSalaryActionSuccess(response));
    
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* addNicknameSaga(action) {
  try {
    const response = yield call(addNicknameApi, action.payload);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Request proccessed successfully",
          status: true,
        })
      );
      yield put(addNicknameActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* downloadSalaryCreditSheetSaga(action) {
  try {
    const response = yield call(downloadSalaryCreditSheetApi, action.payload);
    // if (response.type == "success") {
      action.callback(response);
      yield put(downloadSalaryCreditSheetSuccess(response));
    // }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}




function* uploadSalaryCreditSheetSaga(action) {
  try {
    const response = yield call(uploadSalaryCreditSheetApi, action.payload);
    console.log("resss", response)
    // if(response?.code == 400){
    //   yield put(
    //     toastStatus({
    //       toastStatus: true,
    //       message: response?.message,
    //       status: false,
    //     })
    //   );
    // }
    if (response.status == "200") {
      action.callback(response);
      yield put(uploadSalaryCreditSheetSuccess(response?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Bulk salary paid successfully.",
          status: true,
        })
      );
    }
  } catch (error) {
    console.log('Error.Response:- helloooo ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: error?.response?.data?.message,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* getSalaryWalletDetailsSaga(action) {
  try {
    const response = yield call(getSalaryWalletDetailsApi, action.payload);
    console.log("getSalaryWalletDetailsSaga rrrrrrrrrrrrrrrrrrrrrrrrrrrr", response)
    if (response?.data?.type == "success") {
      console.log("getSalaryWalletDetailsSaga ssssssssssssssssssssssss",response )
      // action.callback(response);
      yield put(getSalaryWalletDetailsSuccess(response?.data?.data));
    }
  } catch (error) {
    // console.log("hiee", error)
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* editSalaryWalletDetailsSaga(action) {
  try {
    const response = yield call(editSalaryWalletDetailsApi, action.payload);
    console.log("resss", response)
    if (response.type == "success") {
      action.callback(response);
      yield put(editSalaryWalletDetailsSuccess(response?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* createSalaryWalletSaga(action) {
  try {
    const response = yield call(createSalaryWalletApi, action.payload);
    console.log("resss", response)
    if (response.type == "success") {
      action.callback(response);
      yield put(createSalaryWalletSuccesss(response?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* handleFleetListTracker(action) {
  try {
    console.log("handleFleetListTracker from saga _____======", action);
    const response = yield call(getFleetTrackerApi, action.payload);
    console.log("Fleet res from saga", response);
    if (response.data.type == "success") {
      yield put(getFleetTrackerSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* generateFreshdeskTicketV2Saga(action) {
  try {
    const response = yield call(generateFreshdeskTicketV2Api, action.payload);
    if (response?.data?.type == 'success') {
      action.callBack(response);
      yield put(generateFreshdeskTicketV2Success());
    }
  } catch (error) {
    // yield put(errorInDriver());
    console.log('generateFreshdeskTicketV2Saga Error.Response:- ', error?.response?.data);
  }
}

function* payDriverSalarySaga(action) {
  try {
    console.log("payDriverSalarySaga action",action);
    const response = yield call(payDriverSalaryApi, action.payload);
    console.log('payDriverSalarySaga', response?.data);
    if (response?.data?.type == 'success') {
      action.callBack(response?.data);
      yield put(payDriverSalarySuccess(response?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Driver's salary paid successfully.",
          status: true,
        })
      );
    }
  } catch (error) {
    console.log('payDriverSalarySaga Error.Response:- ', error);
    // yield put(errorInDriver());
    // action.callBack(error?.response);
    
    if(error?.response?.data?.message  && error?.response?.status != '403')
    // ToastAndroid.show(error?.response?.data?.message, ToastAndroid.SHORT);
    console.log('API Error:- ', processErrorAndRespond(error));
    yield put(
      toastStatus({
        toastStatus: true,
        message: error?.response?.data?.message,
        status: false,
      })
    );
  }
}

function* editSalaryWalletSaga(action) {
  try {
    const response = yield call(editSalaryWalletApi, action.payload);
    console.log('editSalaryWalletSaga', response?.data);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      yield put(editSalaryWalletSuccess());
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Driver's salary successfully changed",
          status: true,
        })
      );
    }
  } catch (error) {
    // yield put(errorInDriver());
    action.callBack(error?.response);
    console.log('editSalaryWalletSaga Error.Response:- ', error?.response?.data);
    if(error?.response?.data?.message  && error?.response?.status != '403')
    // ToastAndroid.show(error?.response?.data?.message, ToastAndroid.SHORT);
    console.log('API Error:- ', processErrorAndRespond(error));
    yield put(
      toastStatus({
        toastStatus: true,
        message: error?.response?.data?.message,
        status: false,
      })
    );
  }
}

function* getSalaryWalletStatementSaga(action) {
  try {
    const response = yield call(getSalaryWalletStatementApi, action.payload);
    console.log("saga-> fleet-- ", response);
    if (response.data.type == "success") {
      action.callback(response);
      console.log('DATA123',response?.data)
      let status = response?.data?.data? true: false
      yield put(getSalaryWalletStatementSuccess(response?.data?.data));

      yield put(
        toastStatus({
          toastStatus: true,
          message: response?.data?.message,
          status: status,
        }))
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* advanceDriverListSaga(action) {
  try {
    //console.log("sdfsfghjhgf",action)
    const response = yield call(advancedDriverListApi, action.payload);
    console.log("advanceDriverListSaga",response)

    if (response.data.type == "success") {
      //   action.payload = response;
      yield put(getAdvanceDriverListSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("driver Error ", processErrorAndRespond(error));
  }
}

function* salaryWalletListSaga(action) {
  try {
    //console.log("sdfsfghjhgf",action)
    const response = yield call(salaryWalletListApi, action.payload);
    console.log("salaryWalletListSaga",response)

    if (response.data.type == "success") {
      //   action.payload = response;
      yield put(getSalaryWalletListSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("driver Error ", processErrorAndRespond(error));
  }
}

function* salaryWalletSummary(action) {
  try {
    console.log("data passed --- saga------>>>>>>>>", action);
    const response = yield call(
      salaryWalletSummaryApi,
      action.phone,
      action.payload
    );
    console.log('eeeeeeeeeeeeeeeeeeeeeeee',response)
    if (response.data.type == "success") {
      if(action?.callback){
        action?.callback(response);
      }
      yield put(getSalaryWalletSummarySuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


export function* watchDriversListSaga() {
  yield takeEvery(GENERATE_SALARY_WALLET_STATEMENT, getSalaryWalletStatementSaga);
  yield takeEvery(GENERATE_FRESH_DESK_TICKET_V2, generateFreshdeskTicketV2Saga);
  yield takeEvery(PAY_DRIVER_SALARY, payDriverSalarySaga);
  yield takeEvery(EDIT_SALARY_WALLET, editSalaryWalletSaga);
  yield takeEvery(GET_DRIVERS_LIST, handleDriverList);
  yield takeEvery(GET_DRIVER_BAL, handleDriverBal);
  yield takeEvery(EXPENSE_SUMM, handleExpenseSumm);
  yield takeEvery(ADD_MONEY_TO_WALLET, handleAddMoney);
  yield takeEvery(WITHDRAW_MONEY_FROM_WALLET, handleWithdrawMoneyFromWallet);
  yield takeEvery(GET_CARD_DETAILS, handleGetCardDetails);
  yield takeEvery(GET_EXPENSE_WALLET_SUMMARY, handleExpenseWalletSummary);
  yield takeEvery(ADD_EXPENSE_TO_WALLET, handleAddExpenseToWallet);
  // yield takeEvery(GET_CARD_CONTROL, handleGetCardControl);
  yield takeEvery(EDIT_CARD_CONTROL, handleEditCardControl);
  yield takeEvery(LINK_FLEET, handleLinkFleet);
  yield takeEvery(CHANGE_CARD_STATUS, ChangeCardStatusSaga);
  yield takeEvery(GET_FLEET_BY_FLEET_ID, handleGetFleetByFleetID);
  yield takeEvery(GET_DRIVER_LIST_2, newDriverList);
  // yield takeEvery(GET_DRIVER_LIST_2, newDriverStatementReportSaga);
  yield takeEvery(
    GENERATE_DRIVER_WALLET_STATEMENT,
    newDriverStatementReportSaga
  );
  yield takeEvery(NEW_GET_CARD_CONTROL, newGetCardControlSaga);
  yield takeEvery(NEW_EDIT_CARD_CONTROL, newEditCardControlSaga);
  yield takeEvery(GET_DRIVERS_LIST_HOME, homeDriverList);
  yield takeEvery(SEARCH_BY_VRN, searchByVrnSaga);
  yield takeEvery(UPLOAD_SALARY, uploadSalarySaga);
  yield takeEvery(DOWNLOAD_SALARY, downloadSalarySaga);
  yield takeEvery(ADD_NICKNAME, addNicknameSaga);
  yield takeEvery(DOWNLOAD_SALARY_CREDIT_SHEET, downloadSalaryCreditSheetSaga);
  yield takeEvery(UPLOAD_SALARY_CREDIT_SHEET, uploadSalaryCreditSheetSaga);
  yield takeEvery(GET_SALARY_WALLET_DETAILS, getSalaryWalletDetailsSaga);
  yield takeEvery(EDIT_SALARY_WALLET_DETAILS, editSalaryWalletDetailsSaga);
  yield takeEvery(CREATE_SALARY_WALLET, createSalaryWalletSaga);
  yield takeEvery(GET_FLEET_LIST_TRACKER, handleFleetListTracker);
  yield takeEvery(GET_DRIVERS_LIST_ADVANCE, advanceDriverListSaga);
  yield takeEvery(GET_ALL_SALARY_WALLET, salaryWalletListSaga);
  yield takeEvery(GET_SALARY_WALLET_SUMMARY, salaryWalletSummary);
  
  
}
