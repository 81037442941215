import React, { useState } from "react";
import SvgImage from "../../Icons/Icons";
import "./index.css";

function SearchBar(props) {
  const {
    width,
    height,
    search,
    onChange,
    findSearch,
    placeholder,
    value,
    setSearch,
    autoFocus
  } = props;
  const [query, setQuery] = useState("");

  const placeholderName =
    placeholder === "" || placeholder === null
      ? "Search customers, vehicles & drivers"
      : placeholder;

  function setValue(val) {
    //console.log(val);
    setQuery(val);
    console.log(query);
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      findSearch();

      console.log("yoo");
      // sessionStorage.setItem("last_entry", value);
      // const urlSearchParams = new URLSearchParams(window.location.search);
      // console.log("uu", window.location.search);
      // const params = Object.fromEntries(urlSearchParams.entries());
      // console.log("url split", params);
      // let searchParam = params.search;
      // setSearch(searchParam);
    }
  }

  // function setStorage(element) {
  //   sessionStorage.setItem("last_entry", element.value);
  // }

  let search_width = width - 70;
  let search_height = height - 10;
  let c = search_width + "px";
  let a = width;
  let b = height + "px";
  let d = search_height + "px";

  return (
    <div className="search-Bar" style={{ width: width, height: b }}>
      <SvgImage name="SerachLogo" classname="search-bar-logo" />
      <input
        type="text"
        className="search-bar-input "
        style={{ width: c }}
        placeholder={props.placeholder}
        onChange={onChange}
        onKeyUp={handleKeyPress}
        value={value}
        autoFocus={autoFocus}
        // onkeypress={setStorage}
      />
    </div>
  );
}
export default SearchBar;
