import {
  GET_CITY_BY_STATE_ID,
  GET_CITY_BY_STATE_ID_SUCCESS,
  GET_ALL_STATE,
  GET_ALL_STATE_SUCCESS,
  STORE_MEDIA_FILE,
  STORE_MEDIA_FILE_SUCCESS,
  TOAST_STATUS,
  TOAST_STATUS_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  AUTOFILL_CITY_STATE,
  AUTOFILL_CITY_STATE_SUCCESS,
  SEND_ANALITICS,
  ADD_DEVICE_ID,
  ADD_DEVICE_ID_SUCCESS,
  GET_DATA_FROM_ID_SUCCESS,
  GET_DATA_FROM_ID,
  DELETE_DEVICE_ID,
  DELETE_DEVICE_ID_SUCCESS,
  GET_SIGNED_URL,
  GET_SIGNED_URL_SUCCESS,
  FLEET_EDIT_STATUS,
  FLEET_EDIT_STATUS_SUCCESS,
  DRIVER_EDIT_STATUS,
  DRIVER_EDIT_STATUS_SUCCESS,
  GET_PINCODE,
GET_PINCODE_SUCCESS
} from "../storeTypes";

// export const changeToastStatus = () => {
//   return {
//     type: TOAST_STATUS,
//   };
// };
export const deleteDeviceIdAction = (id) => {
  console.log("delete action", id);
  return {
    type: DELETE_DEVICE_ID,
    id: id,
  };
};
export const deleteDeviceIdActionSuccess = (payload) => {
  return {
    type: DELETE_DEVICE_ID_SUCCESS,
    payload,
  };
};

export const getDeviceIdDataAction = (data) => {
  return {
    type: GET_DATA_FROM_ID,
  };
};
export const getDeviceIdDataActionSuccess = (payload) => {
  return {
    type: GET_DATA_FROM_ID_SUCCESS,
    payload,
  };
};

export const addDeviceIdAction = (data) => {
  return {
    type: ADD_DEVICE_ID,
    payload: data,
  };
};
export const addDeviceIdActionSuccess = (payload) => {
  return {
    type: ADD_DEVICE_ID_SUCCESS,
    payload,
  };
};

export const getCityByStateId = (id) => {
  return {
    type: GET_CITY_BY_STATE_ID,
    id,
  };
};
export const getCityByStateIDSuccess = (payload) => {
  return {
    type: GET_CITY_BY_STATE_ID_SUCCESS,
    payload,
  };
};

export const getAllState = () => {
  return {
    type: GET_ALL_STATE,
  };
};
export const getAllStateSuccess = (payload) => {
  return {
    type: GET_ALL_STATE_SUCCESS,
    payload,
  };
};

export const storeMedia = (payload, callBack) => {
  return {
    type: STORE_MEDIA_FILE,
    payload,
    callBack,
  };
};
export const storeMediaSuccess = () => {
  return {
    type: STORE_MEDIA_FILE_SUCCESS,
  };
};

export const toastStatus = (payload) => {
  console.log("toast action", payload);
  return {
    type: TOAST_STATUS,
    payload,
  };
};

export const toastStatusSuccess = (data) => {
  console.log("toast action success", data);
  return {
    type: TOAST_STATUS_SUCCESS,
    payload: data,
  };
};

export const updateProfileAction = (data, callback) => {
  return {
    type: UPDATE_PROFILE,
    payload: data,
    callback,
  };
};

export const updateProfileSuccessAction = (payload) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload,
  };
};

export const autoFillCityStateAction = (data, callback) => {
  return {
    type: AUTOFILL_CITY_STATE,
    payload: data,
    callback,
  };
};

export const autoFillCityStateActionSuccess = (data) => {
  return {
    type: AUTOFILL_CITY_STATE_SUCCESS,
    payload: data,
  };
};
export const getSignedUrlApiAction = (data, callBack) => {
  return {
    type: GET_SIGNED_URL,
    payload: data,
    callBack,
  };
};

export const getSignedUrlApiActionSuccess = (data) => {
  return {
    type: GET_SIGNED_URL_SUCCESS,
    payload: data,
  };
};
export const fleetEditStatusAction = (data, callBack) => {
  return {
    type: FLEET_EDIT_STATUS,
    payload: data,
    callBack,
  };
};

export const fleetEditStatusActionSuccess = (data) => {
  return {
    type: FLEET_EDIT_STATUS_SUCCESS,
    payload: data,
  };
};
export const driverEditStatusAction = (data, callBack) => {
  return {
    type: DRIVER_EDIT_STATUS,
    payload: data,
    callBack,
  };
};

export const driverEditStatusActionSuccess = (data) => {
  return {
    type: DRIVER_EDIT_STATUS_SUCCESS,
    payload: data,
  };
};

export const sendAnalitics = (title, payload) => {
  return {
    type: SEND_ANALITICS,
    title,
    payload,
  };
  
};

export const getPincode = (data, callBack) => {
  return {
    type: GET_PINCODE,
    payload: data,
  };
};

export const getPincodeSuccess = (data) => {
  return {
    type: GET_PINCODE_SUCCESS,
    payload: data,
  };
};
