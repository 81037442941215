import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import moment from "moment";
import {
  editCustomerAction,
  getCustomerAction,
} from "../../../../services/Store/Actions/customerAction";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import ToastV2 from "../../../subComponents/toastV2";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import { useNavigate } from "react-router-dom";
import {
  findTripAddressV2,
  getSavedAddress,
} from "../../../../services/Store/Actions/TripsAction";
import AddAddress from "../../../LoadReceipt/Components/AddAddress";
import { linkBranchManager } from "../../../../services/Store/Actions/UserManagementAction";

function AssignManager(props) {
  const { toggle, modal, setModal, navigatedFrom,branches } = props;
  const { getBranchDriver,branchManager,branchData} = useSelector(
    (state) => state?.userManagement
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("navigatedFrom", navigatedFrom);
  const [openToast, setOpenToast] = useState(false);
  const [temp, setTemp] = useState(navigatedFrom);
  const [display1, setDisplay1] = useState(false);
  const [search1, setSearch1] = useState("");
  const [color1, setColor1] = useState(-1);

  console.log("navigatedFrsssom2222", getBranchDriver);


  const { savedAddresses } = useSelector((state) => state?.trip);

  const validationSchema = Yup.object().shape({
    branchName: Yup.string().required("Please select Branch"),
  });
 
  useEffect(() => {
    if (display1 == true) {
      // dispatch(findTripAddressV2({},(res)=>{
      //   console.log("findTripAddressV2",res);
      // }))
      dispatch(
        getSavedAddress({ branchName: search1 }, (res) => {
          console.log("getSavedAddress", res);
        })
      );
    }
  }, [display1, search1]);
  const findSearch1 = () => {
    setColor1(-1);
    dispatch(
      findTripAddressV2({
        offset: 0,
        search: search1,
      })
    );
  };

  const toastObject = useSelector((state) => state?.app?.toastObject);
  const vehicles = [
    { fleetId: "1", vrn: "assas" },
    { fleetId: "2", vrn: "dsdsd" },
    {fleetId:"snjsnbbbs", vrn: "KAw2kk"}
    // Add more vehicles as needed
  ];

  const [selectedVehicles, setSelectedVehicles] = useState([]);
  // Function to handle checkbox change
  const handleCheckboxChange = (fleetId) => {
    // Check if the vehicle is already selected
    const isSelected = selectedVehicles.includes(fleetId);

    // Update the state based on the selection
    if (isSelected) {
      setSelectedVehicles((prevSelected) =>
        prevSelected.filter((id) => id !== fleetId)
      );
    } else {
      setSelectedVehicles((prevSelected) => [...prevSelected, fleetId]);
    }
  };




  console.log("====>>customer>>>.........", selectedVehicles);
 

  const handleClose = () => {
    setModal(!modal);
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            style={{ marginTop: " 30px" }}
            //   className="PGCollectionBox"
          >
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px",marginBottom:"20px" }}
            >
              Assign Manager
            </div>
            <div style={{
              marginLeft: "43px",marginBottom:"10px"
            }}>
           
            </div>
            <div style={{ marginLeft: "10px",marginBottom:"20px" }}>

      <ul>
        {branchManager?.map((data) => (
          <div key={data.userId}>
            <label>
              <input
                type="checkbox"
                checked={selectedVehicles.includes(data.userId)}
                onChange={() => handleCheckboxChange(data.userId)}
                style={{
                  marginLeft:"5px"
                }}
              />
              {`   ${data.fullName}`}
            </label>
          </div>
        ))}
      </ul>
    </div>
    <Formik
              // isValid='true'
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                branchName: ""
              }}
              onSubmit={(values, actions) => {
                console.log("subnshshs",values,selectedVehicles)
                dispatch(linkBranchManager({
                  branchId:values?.branchId,
                  userId:selectedVehicles
                },(res)=>{
                  console.log("sbdjbdjb",res)
                  handleClose()
                }))
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "10px", padding: "0 0px 0 55px" }}
                >
           
                
                  <FormGroup
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <FormGroup>
                      <label for="addressId" className="heading3 semiBold">
                        Branch
                      </label>
                      <div
                        className="custNamepadd3"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          name="branchName"
                          id="branchName"
                          placeholder={"Select Branch"}
                          className={`inputBoxCust1 ${
                            touched.branchName && errors.branchName
                              ? "is-invalid"
                              : ""
                          }`}
                          aria-label="Default select example"
                          onChange={(e) => {
                            setDisplay1(true);
                            setSearch1(e.target.value);
                            setFieldValue("branchName", e.target.value, true);
                          }}
                          onClick={() => {
                            setDisplay1(true);
                          }}
                          value={values.branchName}
                          onBlur={handleBlur("branchName")}
                          errors={errors.branchName}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              findSearch1();
                            }
                          }}
                          autoComplete={"off"}
                        ></input>
                        
                      </div>

                      {display1 ? (
                        <div
                          className="padding16 newCashback whiteBackground"
                          style={{
                            position: "absolute",
                            width: "350px",
                            height: "275px",
                            zIndex: "90",
                          }}
                        >
                          <div
                            onClick={() => {
                              setDisplay1(false);
                              // setSearch1("");
                              //dispatch(clearAllDriversByVrn());
                              // setFieldValue("sourceAddress", "", true);
                            }}
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            <SvgImage name="CloseButtonLogo" />
                          </div>
                          <div
                            style={{
                              overflowY: "scroll",
                              overflowX: "hidden",
                              height: "240px",
                            }}
                          >
                            {search1 == "" ? (
                              branchData?.rows?.map((data, i) => {
                                let city = data?.cityName;
                                let state = data?.stateName;
                                let branchName = data?.branchName;
                                console.log("savedAddresses", data);
                                return (
                                  <div
                                    key={i}
                                    className="padding8 row"
                                    onClick={() => {
                                      // setcityId1(data?.cityName);
                                      console.log("setcityId1", data);
                                      setColor1(i);
                                      setDisplay1(false);
                                      //setUserId(data?.User?.userId);
                                      setFieldValue(
                                        "branchName",
                                        `${data?.branchName}`,
                                        true
                                      );
                                      setFieldValue(
                                        "branchId",
                                        data?.branchId,
                                        true
                                      );
                                    }}
                                  >
                                    {color1 === i ? (
                                      <div className="col-1">
                                        <SvgImage name="SelectedRadio" />
                                      </div>
                                    ) : (
                                      <div className="col-1">
                                        <SvgImage name="UnselectedRadio" />
                                      </div>
                                    )}
                                    {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                    <div
                                      className="col-11"
                                      style={{ textAlign: "left" }}
                                    >
                                      {data?.branchName}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                {branchData?.rows?.map((data, i) => {
                                  let city = data?.cityName;
                                  let state = data?.stateName;
                                  let branchName = data?.branchName;
                                  return (
                                    <div
                                      key={i}
                                      className="padding8 row"
                                      onClick={() => {
                                        // setcityId1(data?.cityName);
                                        console.log("snjsnbjsn",data)
                                        setColor1(i);
                                        setDisplay1(false);
                                        //setUserId(data?.User?.userId);
                                        setFieldValue(
                                          "branchName",
                                          `${data?.branchName}`,
                                          true
                                        );
                                        setFieldValue(
                                          "branchId",
                                          data?.branchId,
                                          true
                                        );
                                      }}
                                    >
                                      {color1 === i ? (
                                        <div className="col-1">
                                          <SvgImage name="SelectedRadio" />
                                        </div>
                                      ) : (
                                        <div className="col-1">
                                          <SvgImage name="UnselectedRadio" />
                                        </div>
                                      )}
                                      {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                      <div
                                        className="col-11"
                                        style={{ textAlign: "left" }}
                                      >
                                        {data?.branchName}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={{ color: "red" }}>
                        {errors.branchName && touched.branchName ? (
                          <div>*{errors.branchName}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      padding: "25px 0 0 0",
                      textAlign: "center",
                    }}
                  >
                    {/* <Button
                      type="submit"
                      style={{ width: "160px", borderRadius: "5px" }}
                      className="generateOtp textAddCust Medium btn btn-secondary"
                      // onClick={addFreight}
                    >
                      Done
                    </Button> */}
                   
                  </FormGroup>
                  <Button
                      type="submit"
                      style={{ width: "160px", borderRadius: "5px",marginLeft:"0px" }}
                      className="generateOtp textAddCust Medium btn btn-secondary"
                      // onClick={addFreight}
                    >
                      Done
                    </Button>
                </Form>
              )}
            </Formik>

  
            

          </div>
        </ModalBody>
      </Modal>

    
      
    </div>
  );
}

export default AssignManager;
