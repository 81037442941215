export const XYZ = "XYZ";

export const XYZ_SUCCESS = "XYZ_SUCCESS";

//Receivables Actions -----

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const LOGIPE_ACCOUNT = "LOGIPE_ACCOUNT";
export const LOGIPE_ACCOUNT_SUCCESS = "LOGIPE_ACCOUNT_SUCCESS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const GET_CUSTOMERS_BY_ID = "GET_CUSTOMERS_BY_ID";
export const GET_CUSTOMERS_BY_ID_SUCCESS = "GET_CUSTOMERS_BY_ID_SUCCESS";
export const GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID =
  "GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID";
export const GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID_SUCCESS =
  "GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID_SUCCESS";
export const ADD_NEW_BOOKING_BILL = "ADD_NEW_BOOKING_BILL";
export const ADD_NEW_BOOKING_BILL_SUCCESS = "ADD_NEW_BOOKING_BILL_SUCCESS";
export const BILL_DETAILS = "BILL_DETAILS";
export const BILL_DETAILS_SUCCESS = "BILL_DETAILS_SUCCESS";
export const CREATE_PAYMENT_LINK = "CREATE_PAYMENT_LINK";
export const CREATE_PAYMENT_LINK_SUCCESS = "CREATE_PAYMENT_LINK_SUCCESS";
export const UPDATE_BILL_DETAILS = "UPDATE_BILL_DETAILS";
export const UPDATE_BILL_DETAILS_SUCCESS = "UPDATE_BILL_DETAILS_SUCCESS";
export const CLEAR_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID =
  "CLEAR_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID";
export const VIRTUAL_ACCOUNT_CREDIT = "VIRTUAL_ACCOUNT_CREDIT";
export const VIRTUAL_ACCOUNT_CREDIT_SUCCESS = "VIRTUAL_ACCOUNT_CREDIT_SUCCESS";

export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";

//Manage drivers
export const CLEAR_ALL_DRIVERS = "CLEAR_ALL_DRIVERS";
export const GET_DRIVERS_LIST = "GET_DRIVERS_LIST";
export const GET_DRIVERS_LIST_SUCCESS = "GET_DRIVERS_LIST_SUCCESS";

export const GET_DRIVER_BAL = "GET_DRIVER_BAL";
export const GET_DRIVER_BAL_SUCCESS = "GET_DRIVER_BAL_SUCCESS";

export const EXPENSE_SUMM = "EXPENSE_SUMM";
export const EXPENSE_SUMM_SUCCESS = "EXPENSE_SUMM_SUCCESS";
export const CLEAN_EXPENSE_SUMM = "CLEAN_EXPENSE_SUMM";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const GET_FLEET_LIST = "GET_FLEET_LIST";
export const GET_FLEET_LIST_SUCCESS = "GET_FLEET_LIST_SUCCESS";

export const ADD_MONEY_TO_WALLET = "ADD_MONEY_TO_WALLET";
export const ADD_MONEY_TO_WALLET_SUCCESS = "ADD_MONEY_TO_WALLET_SUCCESS";

export const WITHDRAW_MONEY_FROM_WALLET = "WITHDRAW_MONEY_FROM_WALLET";
export const WITHDRAW_MONEY_FROM_WALLET_SUCCESS =
  "WITHDRAW_MONEY_FROM_WALLET_SUCCESS";

export const GET_CARD_DETAILS = "GET_CARD_DETAILS";
export const GET_CARD_DETAILS_SUCCESS = "GET_CARD_DETAILS_SUCCESS";

export const GET_EXPENSE_WALLET_SUMMARY = "GET_EXPENSE_WALLET_SUMMARY";
export const GET_EXPENSE_WALLET_SUMMARY_SUCCESS =
  "GET_EXPENSE_WALLET_SUMMARY_SUCCESS";
export const CLEAN_EXPENSE_WALLET_SUMMARY = "CLEAN_EXPENSE_WALLET_SUMMARY";

export const GET_SALARY_WALLET_SUMMARY = "GET_SALARY_WALLET_SUMMARY";
export const GET_SALARY_WALLET_SUMMARY_SUCCESS =
  "GET_SALARY_WALLET_SUMMARY_SUCCESS";
export const CLEAN_SALARY_WALLET_SUMMARY = "CLEAN_SALARY_WALLET_SUMMARY";

export const CLEAN_WALLET_SUMMARY = "CLEAN_WALLET_SUMMARY";
export const ADD_EXPENSE_TO_WALLET = "ADD_EXPENSE_TO_WALLET";
export const ADD_EXPENSE_TO_WALLET_SUCCESS = "ADD_EXPENSE_TO_WALLET_SUCCESS";

export const LINK_FLEET = "LINK_FLEET";
export const LINK_FLEET_SUCCESS = "LINK_FLEET_SUCCESS";

export const CLEAR_ALL_FLEET = "CLEAR_ALL_FLEETS";
//MasterWallet Actions
export const GET_MASTERWALLET_BALANCE = "GET_MASTERWALLET_BALANCE";
export const GET_MASTERWALLET_BALANCE_SUCCESS =
  "GET_MASTERWALLET_BALANCE_SUCCESS";
export const MASTERWALLET_TRANSACTION_HISTORY =
  "MASTERWALLET_TRANSACTION_HISTORY";
export const MASTERWALLET_TRANSACTION_HISTORY_SUCCESS =
  "MASTERWALLET_TRANSACTION_HISTORY_SUCCESS";
export const CLEAN_MASTERWALLET_TRANSACTION_HISTORY =
  "CLEAN_MASTERWALLET_TRANSACTION_HISTORY";

//Transfer Actions

export const FETCH_BALANCE = "FETCH_BALANCE";
export const FETCH_BALANCE_SUCCESS = "FETCH_BALANCE_SUCCESS";

//Wallet Actions

export const WALLET_TRANSACTION_HISTORY = "WALLET_TRANSACTION_HISTORY";
export const WALLET_TRANSACTION_HISTORY_SUCCESS =
  "WALLET_TRANSACTION_HISTORY_SUCCESS";
export const CLEAN_WALLET_TRANSACTION_HISTORY =
  "CLEAN_WALLET_TRANSACTION_HISTORY";

//fastag Actions
export const GET_FASTAG_WALLET_BALANCE = "GET_FASTAG_WALLET_BALANCE";
export const GET_FASTAG_WALLET_BALANCE_SUCCESS =
  "GET_FASTAG_WALLET_BALANCE_SUCCESS";
export const GET_FASTAG_WALLET_TRANSACTION = "GET_FASTAG_WALLET_TRANSACTION";
export const GET_FASTAG_WALLET_TRANSACTION_SUCCESS =
  "GET_FASTAG_WALLET_TRANSACTION_SUCCESS";
export const CLEAN_FASTAG_WALLET_TRANSACTION =
  "CLEAN_FASTAG_WALLET_TRANSACTION";

export const TRANSFER_FASTAG_AMOUNT = "TRANSFER_FASTAG_AMOUNT";
export const TRANSFER_FASTAG_AMOUNT_SUCCESS = "TRANSFER_FASTAG_AMOUNT_SUCCESS";
export const FASTAG_ERROR = "FASTAG_ERROR";
export const GET_FASTAG_TRANSACTION_HISTORY = "GET_FASTAG_TRANSACTION_HISTORY";
export const GET_FASTAG_TRANSACTION_HISTORY_SUCCESS =
  "GET_FASTAG_TRANSACTION_HISTORY_SUCCESS";
export const GET_VEHICLE_DETAILS_BY_VEHICLE_ID =
  "GET_VEHICLE_DETAILS_BY_VEHICLE_ID";
export const GET_VEHICLE_DETAILS_BY_VEHICLE_ID_SUCCESS =
  "GET_VEHICLE_DETAILS_BY_VEHICLE_ID_SUCCESS";

export const GET_VEHICLE_DETAILS = "GET_VEHICLE_DETAILS";
export const GET_VEHICLE_DETAILS_SUCCESS = "GET_VEHICLE_DETAILS_SUCCESS";

export const CLEAN_VEHICLE_DETAILS = "CLEAN_VEHICLE_DETAILS";
export const REGISTER_YESBANK = "REGISTER_YESBANK";
export const REGISTER_YESBANK_SUCCESS = "REGISTER_YESBANK_SUCCESS";

//Transfer Actions
export const GET_TRANSFER_COLLECTIONS = "GET_TRANSFER_COLLECTIONS";
export const GET_TRANSFER_COLLECTIONS_SUCCESS =
  "GET_TRANSFER_COLLECTIONS_SUCCESS";
export const GET_CARD_CONTROL = "GET_CARD_CONTROL";
export const GET_CARD_CONTROL_SUCCESS = "GET_CARD_CONTROL_SUCCESS";

export const EDIT_CARD_CONTROL = "EDIT_CARD_CONTROL";
export const EDIT_CARD_CONTROL_SUCCESS = "EDIT_CARD_CONTROL_SUCCESS";

//Owner Logipe Card
export const GET_EXPENSE_BALANCE = "GET_EXPENSE_BALANCE";
export const GET_EXPENSE_BALANCE_SUCCESS = "GET_EXPENSE_BALANCE_SUCCESS";

export const WALLET_SUMMARY = "WALLET_SUMMARY";
export const WALLET_SUMMARY_SUCCESS = "WALLET_SUMMARY_SUCCESS";

export const WALLET_WITHDRAW_MONEY = "WALLET_WITHDRAW_MONEY";
export const WALLET_WITHDRAW_MONEY_SUCCESS = "WALLET_WITHDRAW_MONEY_SUCCESS";
export const WALLET_ADD_MONEY = "WALLET_ADD_MONEY";
export const WALLET_ADD_MONEY_SUCCESS = "WALLET_ADD_MONEY_SUCCESS";
export const CHANGE_CARD_STATUS = "CHANGE_CARD_STATUS";
export const CHANGE_CARD_STATUS_SUCCESS = "CHANGE_CARD_STATUS_SUCCESS";
export const GET_BALANCE_DETAILS = "GET_BALANCE_DETAILS";
export const GET_BALANCE_DETAILS_SUCCESS = "GET_BALANCE_DETAILS_SUCCESS";

// manageVehicle

export const VEHICLE_HISTORY = "VEHICLE_HISTORY";
export const VEHICLE_HISTORY_SUCCESS = "VEHICLE_HISTORY_SUCCESS";
export const CLEAN_VEHICLE_HISTORY = "CLEAN_VEHICLE_HISTORY";
export const FULL_VEHICLES_LIST = "FULL_VEHICLES_LIST";
export const FULL_VEHICLES_LIST_SUCCESS = "FULL_VEHICLES_LIST_SUCCESS";
export const RECHARGE_FASTAG = "RECHARGE_FASTAG";
export const RECHARGE_FASTAG_SUCCESS = "RECHARGE_FASTAG_SUCCESS";
export const ADD_VEHICLE = "ADD_VEHICLE";
export const ADD_VEHICLE_SUCCESS = "ADD_VEHICLE_SUCCESS";

//Login Actions---
export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const CLEAN_LOGIN_LOADER = "CLEAN_LOGIN_LOADER";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP = "VERIFY_OTP";
export const GET_OWNER_ACCOUNT_DETAILS = "GET_OWNER_ACCOUNT_DETAILS";
export const GET_OWNER_ACCOUNT_DETAILS_SUCCESS =
  "GET_OWNER_ACCOUNT_DETAILS_SUCCESS";
export const GET_OWNER_DETAILS = "GET_OWNER_DETAILS";
export const GET_OWNER_DETAILS_SUCCESS = "GET_OWNER_DETAILS_SUCCESS";
export const CLEAR_AUTH_STORE = "CLEAR_AUTH_STORE";
export const PIN_VERIFY_SUCCESS = "PIN_VERIFY_SUCCESS";
export const REQUEST_LOGIN_SUCCESS = "REQUEST_LOGIN_SUCCESS";

// Card Wallet
export const TRANSFER_WALLET_AMOUNT = "TRANSFER_WALLET_AMOUNT";
export const TRANSFER_WALLET_AMOUNT_SUCCESS = "TRANSFER_WALLET_AMOUNT_SUCCESS";

export const CARD_REQUESTS = "CARD_REQUESTS";
export const CARD_REQUESTS_SUCCESS = "CARD_REQUESTS_SUCCESS";

export const UPDATE_CARD_REQUESTS = "UPDATE_CARD_REQUESTS";
export const UPDATE_CARD_REQUESTS_SUCCESS = "UPDATE_CARD_REQUESTS_SUCCESS";


export const FETCH_CASH_BACK_BALANCE = "FETCH_CASH_BACK_BALANCE";
export const FETCH_CASH_BACK_BALANCE_SUCCESS =
  "FETCH_CASH_BACK_BALANCE_SUCCESS";
export const CLEAN_CUSTOMERS = "CLEAN_CUSTOMERS";

//beneficiary
export const GET_VIRTU_ACCOUNT = "GET_VIRTU_ACCOUNT";
export const GET_VIRTU_ACCOUNT_SUCCESS = "GET_VIRTU_ACCOUNT_SUCCESS";
export const BANK_DIRECT_TRANSFER = "BANK_DIRECT_TRANSFER";
export const BANK_DIRECT_TRANSFER_SUCCESS = "BANK_DIRECT_TRANSFER_SUCCESS";

export const BANK_TRANSFER_TO_ADDED_BENEFICIARY =
  "BANK_TRANSFER_TO_ADDED_BENEFICIARY";
export const BANK_TRANSFER_TO_ADDED_BENEFICIARY_SUCCESS =
  "BANK_TRANSFER_TO_ADDED_BENEFICIARY_SUCCESS";
export const BANK_TRANSFER_TO_ADDED_BENEFICIARY_ERROR =
  "BANK_TRANSFER_TO_ADDED_BENEFICIARY_ERROR";

export const BANK_DIRECT_TRANSFER_ERROR = "BANK_DIRECT_TRANSFER_ERROR";
export const BANK_TRANSFER_BENEFICIARY_ADD = "BANK_TRANSFER_BENEFICIARY_ADD";
export const BANK_TRANSFER_BENEFICIARY_ADD_SUCCESS =
  "BANK_TRANSFER_BENEFICIARY_ADD_SUCCESS";
export const BANK_TRANSFER_BENEFICIARY_ERROR =
  "BANK_TRANSFER_BENEFICIARY_ERROR";
export const GET_BENEFICIARY = "GET_BENEFICIARY";
export const GET_BENEFICIARY_SUCCESS = "GET_BENEFICIARY_SUCCESS";

//APP reducer
export const GET_CITY_BY_STATE_ID = "GET_CITY_BY_STATE_ID";
export const GET_CITY_BY_STATE_ID_SUCCESS = "GET_CITY_BY_STATE_ID_SUCCESS";
export const GET_ALL_STATE = "GET_ALL_STATE";
export const GET_ALL_STATE_SUCCESS = "GET_ALL_STATE_SUCCESS";
export const STORE_MEDIA_FILE = "STORE_MEDIA_FILE";
export const STORE_MEDIA_FILE_SUCCESS = "STORE_MEDIA_FILE_SUCCESS";

export const LINK_BANK_ACCOUNT_TO_LOGIPE = "LINK_BANK_ACCOUNT_TO_LOGIPE";
export const LINK_BANK_ACCOUNT_TO_LOGIPE_SUCCESS =
  "LINK_BANK_ACCOUNT_TO_LOGIPE_SUCCESS";

export const OWNER_CARD_DETAILS = "OWNER_CARD_DETAILS";
export const OWNER_CARD_DETAILS_SUCCESS = "OWNER_CARD_DETAILS_SUCCESS";

// clean fleet
export const CLEAN_FLEET = "CLEAN_FLEET";

//Fleets
export const ADD_FLEET = "ADD_FLEET";
export const ADD_FLEET_SUCCESS = "ADD_FLEET_SUCCESS";
export const GET_FLEET_BY_FLEET_ID = "GET_FLEET_BY_FLEET_ID";
export const GET_FLEET_BY_FLEET_ID_SUCCESS = "GET_FLEET_BY_FLEET_ID_SUCCESS";

//new lists
export const ALL_CARD_BALANCE = "ALL_CARD_BALANCE";
export const ALL_CARD_BALANCE_SUCCESS = "ALL_CARD_BALANCE_SUCCESS";

export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS";

export const GET_SETTLEMENTS = "GET_SETTLEMENTS";
export const GET_SETTLEMENTS_SUCCESS = "GET_SETTLEMENTS_SUCCESS";

export const GET_CASHBACK_LIST = "GET_CASHBACK_LIST";
export const GET_CASHBACK_LIST_SUCCESS = "GET_CASHBACK_LIST_SUCCESS";
export const CLEAN_CASHBACK_LIST = "CLEAN_CASHBACK_LIST";

export const TRANSFER_TO_CARD = "TRANSFER_TO_CARD";
export const TRANSFER_TO_CARD_SUCCESS = "TRANSFER_TO_CARD_SUCCESS";

export const WITHDRAW_FROM_CARD = "WITHDRAW_FROM_CARD";
export const WITHDRAW_FROM_CARD_SUCCESS = "WITHDRAW_FROM_CARD_SUCCESS";

export const CLEAN_COLLECTIONS = "CLEAN_COLLECTIONS";

export const GET_DRIVER_LIST_2 = "GET_DRIVER_LIST_2";
export const GET_DRIVER_LIST_2_SUCCESS = "GET_DRIVER_LIST_2_SUCCESS";

export const CHANGE_PIN = "CHANGE_PIN";
export const CHANGE_PIN_SUCCESS = "CHANGE_PIN_SUCCESS";

//  export const TOAST_STATUS="TOAST_STATUS";

export const CLEAN_FASTAG_TRANSACTION_HISTORY =
  "CLEAN_FASTAG_TRANSACTION_HISTORY";

// For toast
export const TOAST_STATUS = "TOAST_STATUS";
export const TOAST_STATUS_SUCCESS = "TOAST_STATUS_SUCCESS";

// recent card transactions

export const RECENT_CARD_TRANSACTIONS = "RECENT_CARD_TRANSACTIONS";
export const RECENT_CARD_TRANSACTIONS_SUCCESS =
  "RECENT_CARD_TRANSACTIONS_SUCCESS";

export const REDEEM_CASHBACK = "REDEEM_CASHBACK";
export const REDEEM_CASHBACK_SUCCESS = "REDEEM_CASHBACK_SUCCESS";

export const CLEAN_VEHCILE_LOADER = "CLEAN_VEHCILE_LOADER";
export const CLEAN_FLEET_LOADER = "CLEAN_FLEET_LOADER";

//download
export const GENERATE_MASTER_ACCOUNT_STATEMENT =
  "GENERATE_MASTER_ACCOUNT_STATEMENT";
export const GENERATE_MASTER_ACCOUNT_STATEMENT_SUCCESS =
  "GENERATE_MASTER_ACCOUNT_STATEMENT_SUCCESS";

export const GENERATE_SALARY_WALLET_STATEMENT =
  "GENERATE_MASTER_ACCOUNT_STATEMENT";
export const GENERATE_SALARY_WALLET_STATEMENT_SUCCESS =
  "GENERATE_MASTER_ACCOUNT_STATEMENT_SUCCESS";

export const GENERATE_FASTAG_HISTORY_STATEMENT =
  "GENERATE_FASTAG_HISTORY_STATEMENT";
export const GENERATE_FASTAG_HISTORY_STATEMENT_SUCCESS =
  "GENERATE_FASTAG_HISTORY_STATEMENT_SUCCESS";

export const GENERATE_FASTAG_VEHICLE_STATEMENT =
  "GENERATE_FASTAG_VEHICLE_STATEMENT";
export const GENERATE_FASTAG_VEHICLE_STATEMENT_SUCCESS =
  "GENERATE_FASTAG_VEHICLE_STATEMENT_SUCCESS";

export const GENERATE_DRIVER_WALLET_STATEMENT =
  "GENERATE_DRIVER_WALLET_STATEMENT";
export const GENERATE_DRIVER_WALLET_STATEMENT_SUCCESS =
  "GENERATE_DRIVER_WALLET_STATEMENT_SUCCESS";

// new card store variables

export const NEW_WITHDRAW_MONEY = "NEW_WITHDRAW_MONEY";
export const NEW_WITHDRAW_MONEY_SUCCESS = "NEW_WITHDRAW_MONEY_SUCCESS";

export const NEW_GET_CARD_CONTROL = "NEW_GET_CARD_CONTROL";
export const NEW_GET_CARD_CONTROL_SUCCESS = "NEW_GET_CARD_CONTROL_SUCCESS";

export const NEW_EDIT_CARD_CONTROL = "NEW_EDIT_CARD_CONTROL";
export const NEW_EDIT_CARD_CONTROL_SUCCESS = "NEW_EDIT_CARD_CONTROL_SUCCESS";

export const NEW_CARD_BAL = "NEW_CARD_BAL";
export const NEW_CARD_BAL_SUCCESS = "NEW_CARD_BAL_SUCCESS";

export const CLEAN_CUSTOMER_LOADER = "CLEAN_CUSTOMER_LOADER";

export const KYC_DONE = "KYC_DONE";
export const KYC_DONE_SUCCESS = "KYC_DONE_SUCCESS";

export const GET_EXPENSE_IMAGE = "GET_EXPENSE_IMAGE";
export const GET_EXPENSE_IMAGE_SUCCESS = "GET_EXPENSE_IMAGE_SUCCESS";

export const REISSUE_AVAILABLE = "REISSUE_AVAILABLE";
export const REISSUE_AVAILABLE_SUCCESS = "REISSUE_AVAILABLE_SUCCESS";

export const SEND_FASTAG_OTP = "SEND_FASTAG_OTP";
export const SEND_FASTAG_OTP_SUCCESS = "SEND_FASTAG_OTP_SUCCESS";

export const VERIFY_FASTAG_OTP = "VERIFY_FASTAG_OTP";
export const VERIFY_FASTAG_OTP_SUCCESS = "VERIFY_FASTAG_OTP_SUCCESS";

export const FASTAG_ADD_CUSTOMER = "FASTAG_ADD_CUSTOMER";
export const FASTAG_ADD_CUSTOMER_SUCCESS = "FASTAG_ADD_CUSTOMER_SUCCESS";

export const TAG_REISSUE = "TAG_REISSUE";
export const TAG_REISSUE_SUCCESS = "TAG_REISSUE_SUCCESS";

// m2p
export const GET_M2P_USER_DETAILS = "GET_M2P_USER_DETAILS";
export const GET_M2P_USER_DETAILS_SUCCESS = "GET_M2P_USER_DETAILS_SUCCESS";

export const GET_DRIVERS_LIST_HOME = "GET_DRIVERS_LIST_HOME";
export const GET_DRIVERS_LIST_HOME_SUCCESS = "GET_DRIVERS_LIST_HOME_SUCCESS";

//m2p
export const GET_OWNERCARD = "GET_OWNERCARD";
export const GET_OWNERCARD_SUCCESS = "GET_OWNERCARD_SUCCESS";

//update profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

//m2p

export const GET_M2P_CARD_DETAILS = "GET_M2P_CARD_DETAILS";
export const GET_M2P_CARD_DETAILS_SUCCESS = "GET_M2P_CARD_DETAILS_SUCCESS";

export const M2P_TRANSACTION_HISTORY = "M2P_TRANSACTION_HISTORY";
export const M2P_TRANSACTION_HISTORY_SUCCESS =
  "M2P_TRANSACTION_HISTORY_SUCCESS";
export const M2P_TRANSACTION_HISTORY_CLEAN = "M2P_TRANSACTION_HISTORY_CLEAN";

export const M2P_WITHDRAW_FROM_CARD = "M2P_WITHDRAW_FROM_CARD";
export const M2P_WITHDRAW_FROM_CARD_SUCCESS = "M2P_WITHDRAW_FROM_CARD_SUCCESS";

export const GET_M2P_CARD_CONTROLS = "GET_M2P_CARD_CONTROLS";
export const GET_M2P_CARD_CONTROLS_SUCCESS = "GET_M2P_CARD_CONTROLS_SUCCESS";

export const EDIT_M2PCARD_CONTROLS = "EDIT_M2PCARD_CONTROLS";
export const EDIT_M2PCARD_CONTROLS_SUCCESS = "EDIT_M2PCARD_CONTROLS_SUCCESS";

export const M2P_SET_PIN = "M2P_SET_PIN";
export const M2P_SET_PIN_SUCCESS = "M2P_SET_PIN_SUCCESS";


export const FLEET_DETAILS3 = "FLEET_DETAILS3";
export const FLEET_DETAILS3_SUCCESS = "FLEET_DETAILS3_SUCCESS";

export const TOGGLE_AUTO_RECHARGE = "TOGGLE_AUTO_RECHARGE";
export const TOGGLE_AUTO_RECHARGE_SUCCESS = "TOGGLE_AUTO_RECHARGE_SUCCESS";

export const CLEAN_FASTAG_REDUCER = "CLEAN_FASTAG_REDUCER";

export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS";

export const EDIT_EXPENSE = "EDIT_EXPENSE";
export const EDIT_EXPENSE_SUCCESS = "EDIT_EXPENSE_SUCCESS";

export const AUTO_RECHARGE_MULTIPLE = "AUTO_RECHARGE_MULTIPLE";
export const AUTO_RECHARGE_MULTIPLE_SUCCESS = "AUTO_RECHARGE_MULTIPLE_SUCCESS";

export const MANAGER_LIST = "MANAGER_LIST";
export const MANAGER_LIST_SUCCESS = "MANAGER_LIST_SUCCESS";


export const GET_BRANCH = "GET_BRANCH";
export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS";



export const ADD_BRANCH = "ADD_BRANCH";
export const ADD_BRANCH_SUCCESS = "ADD_BRANCH_SUCCESS";



export const LINK_BRANCH_MANAGER = "LINK_BRANCH_MANAGER";
export const LINK_BRANCH_MANAGER_SUCCESS = "LINK_BRANCH_MANAGER_SUCCESS";


export const LINK_BRANCH_FLEET = "LINK_BRANCH_FLEET";
export const LINK_BRANCH_FLEET_SUCCESS = "LINK_BRANCH_FLEET_SUCCESS";


export const LINK_BRANCH_DRIVER = "LINK_BRANCH_DRIVER";
export const LINK_BRANCH_DRIVER_SUCCESS = "LINK_BRANCH_DRIVER_SUCCESS";


export const GET_BRANCH_DRIVER = "GET_BRANCH_DRIVER";
export const GET_BRANCH_DRIVER_SUCCESS = "GET_BRANCH_DRIVER_SUCCESS";


export const GET_BRANCH_FLEET = "GET_BRANCH_FLEET";
export const GET_BRANCH_FLEET_SUCCESS = "GET_BRANCH_FLEET_SUCCESS";


export const GET_BRANCH_MANAGER = "GET_BRANCH_MANAGER";
export const GET_BRANCH_MANAGER_SUCCESS = "GET_BRANCH_MANAGER_SUCCESS";


export const GET_BRANCH_ID = "GET_BRANCH_ID";
export const GET_BRANCH_ID_SUCCESS = "GET_BRANCH_ID_SUCCESS";

export const SET_BRANCH_LIMIT = "SET_BRANCH_LIMIT";
export const SET_BRANCH_LIMIT_SUCCESS = "SET_BRANCH_LIMIT_SUCCESS";




export const M2P_OTP = "M2P_OTP";
export const M2P_OTP_SUCCESS = "M2P_OTP_SUCCESS";

export const M2P_CONTACT_INFO = "M2P_CONTACT_INFO";
export const M2P_CONTACT_INFO_SUCCESS = "M2P_CONTACT_INFO_SUCCESS";

export const M2P_REGISTER = "M2P_REGISTER";
export const M2P_REGISTER_SUCCESS = "M2P_REGISTER_SUCCESS";

export const AUTOFILL_CITY_STATE = "AUTOFILL_CITY_STATE";
export const AUTOFILL_CITY_STATE_SUCCESS = "AUTOFILL_CITY_STATE_SUCCESS";

export const M2P_VKYC_URL = "M2P_VKYC_URL";
export const M2P_VKYC_URL_SUCCESS = "M2P_VKYC_URL_SUCCESS";

export const M2P_OWNER_ADDRESS = "M2P_OWNER_ADDRESS";
export const M2P_OWNER_ADDRESS_SUCCESS = "M2P_OWNER_ADDRESS_SUCCESS";

export const M2P_MONTHLY_BALANCE = "M2P_MONTHLY_BALANCE";
export const M2P_MONTHLY_BALANCE_SUCCESS = "M2P_MONTHLY_BALANCE_SUCCESS";

export const M2P_DOWNLOAD_WALLET_HISTORY = "M2P_DOWNLOAD_WALLET_HISTORY";
export const M2P_DOWNLOAD_WALLET_HISTORY_SUCCESS =
  "M2P_DOWNLOAD_WALLET_HISTORY_SUCCESS";

export const SEND_ANALITICS = "SEND_ANALITICS";

export const CLEAN_BENEFICIARY_REDUCER = "CLEAN_BENEFICIARY_REDUCER";
export const CLEAN_MASTER_WALLET_REDUCER = "CLEAN_MASTER_WALLET_REDUCER";

export const NEFT_LINKED_ACCOUNTS_LIST = "NEFT_LINKED_ACCOUNTS_LIST";
export const NEFT_LINKED_ACCOUNTS_LIST_SUCCESS =
  "NEFT_LINKED_ACCOUNTS_LIST_SUCCESS";

export const CREATE_PAYMENT_TOKEN = "CREATE_PAYMENT_TOKEN";
export const CREATE_PAYMENT_TOKEN_SUCCESS = "CREATE_PAYMENT_TOKEN_SUCCESS";

export const SEARCH_BY_VRN = "SEARCH_BY_VRN";
export const SEARCH_BY_VRN_SUCCESS = "SEARCH_BY_VRN_SUCCESS";

export const CLEAR_ALL_DRIVERS_BY_VRN = "CLEAR_ALL_DRIVERS_BY_VRN";

export const QR_CODE = "QR_CODE";
export const QR_CODE_SUCCESS = "QR_CODE_SUCCESS";

export const ADD_DEVICE_ID = "ADD_DEVICE_ID";
export const ADD_DEVICE_ID_SUCCESS = "ADD_DEVICE_ID_SUCCESS";

export const DELETE_DEVICE_ID = "DELETE_DEVICE_ID";
export const DELETE_DEVICE_ID_SUCCESS = "DELETE_DEVICE_ID_SUCCESS";

export const GET_DATA_FROM_ID = "GET_DATA_FROM_ID";
export const GET_DATA_FROM_ID_SUCCESS = "GET_DATA_FROM_ID_SUCCESS";

export const OWNER_DETAILS = "OWNER_DETAILS";
export const OWNER_DETAILS_SUCCESS = "OWNER_DETAILS_SUCCESS";

export const BUSINESS_DETAILS = "BUSINESS_DETAILS";
export const BUSINESS_DETAILS_SUCCESS = "BUSINESS_DETAILS_SUCCESS";

export const UPDATE_PAN_AADHAAR = "UPDATE_PAN_AADHAAR";
export const UPDATE_PAN_AADHAAR_SUCCESS = "UPDATE_PAN_AADHAAR_SUCCESS";

export const GET_AADHAAR_OTP = "GET_AADHAAR_OTP";
export const GET_AADHAAR_OTP_SUCCESS = "GET_AADHAAR_OTP_SUCCESS";

export const PAN_AUTH = "PAN_AUTH";
export const PAN_AUTH_SUCCESS = "PAN_AUTH_SUCCESS";

export const DOWNLOAD_AADHAAR = "DOWNLOAD_AADHAAR";
export const DOWNLOAD_AADHAAR_SUCCESS = "DOWNLOAD_AADHAAR_SUCCESS";

export const CLEAN_ONBOARDING_LOADER = "CLEAN_ONBOARDING_LOADER";

export const SET_PIN = "SET_PIN";
export const SET_PIN_SUCCESS = "SET_PIN_SUCCESS";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";

export const CLEAN_ECOM_LOADER = "CLEAN_ECOM_LOADER";

export const GET_CART_DETAILS = "GET_CART_DETAILS";
export const GET_CART_DETAILS_SUCCESS = "GET_CART_DETAILS_SUCCESS";

export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";

export const REMOVE_TO_CART = "REMOVE_TO_CART";
export const REMOVE_TO_CART_SUCCESS = "REMOVE_TO_CART_SUCCESS";

export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS";

export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";

export const ADD_ADDRESS_DETAILS = "ADD_ADDRESS_DETAILS";
export const ADD_ADDRESS_DETAILS_SUCCESS = "ADD_ADDRESS_DETAILS_SUCCESS";

export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST";
export const GET_ADDRESS_LIST_SUCCESS = "GET_ADDRESS_LIST_SUCCESS";

export const GET_ADDRESS_DETAILS = "GET_ADDRESS_DETAILS";
export const GET_ADDRESS_DETAILS_SUCCESS = "GET_ADDRESS_DETAILS_SUCCESS";

export const GET_COINS_AVAILABLE = "GET_COINS_AVAILABLE";
export const GET_COINS_AVAILABLE_SUCCESS = "GET_COINS_AVAILABLE_SUCCESS";

// export const CREATE_PAYMENT_TOKEN = "CREATE_PAYMENT_TOKEN";
// export const CREATE_PAYMENT_TOKEN_SUCCESS = "CREATE_PAYMENT_TOKEN_SUCCESS";

export const GET_TRANSACTION_STATUS = "GET_TRANSACTION_STATUS";
export const GET_TRANSACTION_STATUS_SUCCESS = "GET_TRANSACTION_STATUS_SUCCESS";

export const SUPPORT_TICKET = "SUPPORT_TICKET";
export const SUPPORT_TICKET_SUCCESS = "SUPPORT_TICKET_SUCCESS";

export const GENERATE_RECEIPT = "GENERATE_RECEIPT";
export const GENERATE_RECEIPT_SUCCESS = "GENERATE_RECEIPT_SUCCESS";

export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";

export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";


export const FRESHDESK_TICKET = "FRESHDESK_TICKET";
export const FRESHDESK_TICKET_SUCCESS = "FRESHDESK_TICKET_SUCCESS";

export const ORDER_PAYMENT = "ORDER_PAYMENT";
export const ORDER_PAYMENT_SUCCESS = "ORDER_PAYMENT_SUCCESS";

export const FRESHDESK_TICKET_ECOM = "FRESHDESK_TICKET_ECOM";
export const FRESHDESK_TICKET_ECOM_SUCCESS = "FRESHDESK_TICKET_ECOM_SUCCESS";

export const UPLOAD_SALARY = "UPLOAD_SALARY";
export const UPLOAD_SALARY_SUCCESS = "UPLOAD_SALARY_SUCCESS";

export const DOWNLOAD_SALARY = "DOWNLOAD_SALARY";
export const DOWNLOAD_SALARY_SUCCESS = "DOWNLOAD_SALARY_SUCCESS";

export const MANAGER_GET_CONTROL = "MANAGER_GET_CONTROL";
export const MANAGER_GET_CONTROL_SUCCESS = "MANAGER_GET_CONTROL_SUCCESS";

export const ADD_NICKNAME = "ADD_NICKNAME";
export const ADD_NICKNAME_SUCCESS = "ADD_NICKNAME_SUCCESS";

export const LOGIN_WITH_PASSWORD = "LOGIN_WITH_PASSWORD";
export const LOGIN_WITH_PASSWORD_SUCCESS = "LOGIN_WITH_PASSWORD_SUCCESS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const MA_REPORT_FILTER = "MA_REPORT_FILTER";
export const MA_REPORT_FILTER_SUCCESS = "MA_REPORT_FILTER_SUCCESS";

export const WALLET_REPORT_FILTER = "WALLET_REPORT_FILTER";
export const WALLET_REPORT_FILTER_SUCCESS = "WALLET_REPORT_FILTER_SUCCESS";

export const FT_REPORT_FILTER = "FT_REPORT_FILTER";
export const FT_REPORT_FILTER_SUCCESS = "FT_REPORT_FILTER_SUCCESS";

export const MANAGER_REPORT = "MANAGER_REPORT";
export const MANAGER_REPORT_SUCCESS = "MANAGER_REPORT_SUCCESS";

export const CASH_EXPENSE_STATEMENT = "CASH_EXPENSE_STATEMENT";
export const CASH_EXPENSE_STATEMENT_SUCCESS = "CASH_EXPENSE_STATEMENT_SUCCESS";

export const OMC_VEHICLE_LIST = "OMC_VEHICLE_LIST";
export const OMC_VEHICLE_LIST_SUCCESS = "OMC_VEHICLE_LIST_SUCCESS";

export const OMC_BALANCE = "OMC_BALANCE";
export const OMC_BALANCE_SUCCESS = "OMC_BALANCE_SUCCESS";

export const OMC_TRANSACTIONS = "OMC_TRANSACTIONS";
export const OMC_TRANSACTIONS_SUCCESS = "OMC_TRANSACTIONS_SUCCESS";

export const OMC_LOAD_MONEY = "OMC_LOAD_MONEY";
export const OMC_LOAD_MONEY_SUCCESS = "OMC_LOAD_MONEY_SUCCESS";

export const OMC_WITHDRAW_MONEY = "OMC_WITHDRAW_MONEY";
export const OMC_WITHDRAW_MONEY_SUCCESS = "OMC_WITHDRAW_MONEY_SUCCESS";

export const OMC_VEH_STATUS = "OMC_VEH_STATUS";
export const OMC_VEH_STATUS_SUCCESS = "OMC_VEH_STATUS_SUCCESS";

export const CLEAN_OMC_LOADER = "CLEAN_OMC_LOADER";

export const OMC_DRIVER_DATA = "OMC_DRIVER_DATA";
export const OMC_DRIVER_DATA_SUCCESS = "OMC_DRIVER_DATA_SUCCESS";

export const OMC_NUMBER_CHANGE = "OMC_NUMBER_CHANGE";
export const OMC_NUMBER_CHANGE_SUCCESS = "OMC_NUMBER_CHANGE_SUCCESS";

//TRIPS

export const CREATE_TRIP = "CREATE_TRIP";
export const CREATE_TRIP_SUCCESS = "CREATE_TRIP_SUCCESS";

export const GET_TRIPS = "GET_TRIPS";
export const GET_TRIPS_SUCCESS = "GET_TRIPS_SUCCESS";
export const GET_ROUTES = "GET_ROUTES";
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS";

export const ADD_ROUTE = "ADD_ROUTE";
export const ADD_ROUTE_SUCCESS = "ADD_ROUTE_SUCCESS";
 


export const GET_TRIPS_ERROR = "GET_TRIPS_ERROR";

export const GET_TRIPS_NEW = "GET_TRIPS_NEW";
export const GET_TRIPS_NEW_SUCCESS = "GET_TRIPS_NEW_SUCCESS";
export const GET_TRIPS_NEW_ERROR = "GET_TRIPS_NEW_ERROR";

export const CLEAR_TRIP_LIST = "CLEAR_TRIP_LIST";

export const END_TRIP = "END_TRIP";
export const END_TRIP_SUCCESS = "END_TRIP_SUCCESS";

export const GET_TRIPS_BY_FLEET = "GET_TRIPS_BY_FLEET";
export const GET_TRIPS_BY_FLEET_SUCCESS = "GET_TRIPS_BY_FLEET_SUCCESS";

export const DELETE_TRIP = "DELETE_TRIP";
export const DELETE_TRIP_SUCCESS = "DELETE_TRIP_SUCCESS";

export const UPDATE_TRIP = "UPDATE_TRIP";
export const UPDATE_TRIP_SUCCESS = "UPDATE_TRIP_SUCCESS";

export const CALCULATE_TRIP_TOLL = "CALCULATE_TOLL_TOTAL";
export const CALCULATE_TRIP_TOLL_SUCCESS = "CALCULATE_TOLL_TOTAL_SUCCESS";

export const CALCULATE_TRIP_TOLL_INTERNAL = "CALCULATE_TRIP_TOLL_INTERNAL";
export const CALCULATE_TRIP_TOLL_INTERNAL_SUCCESS =
  "CALCULATE_TRIP_TOLL_INTERNAL_SUCCESS";

export const CLEAR_TRIP_DETAILS = "CLEAR_TRIP_DETAILS";

export const GET_TRIP_TRANSACTION = "GET_TRIP_TRANSACTION";
export const GET_TRIP_TRANSACTION_SUCCESS = "GET_TRIP_TRANSACTION_SUCCESS";

export const GET_TRIPS_BY_USERID = "GET_TRIPS_BY_USERID";
export const GET_TRIPS_BY_USERID_SUCCESS = "GET_TRIPS_BY_USERID_SUCCESS";
export const CLEAN_TRIPS_BY_USERID = "CLEAN_TRIPS_BY_USERID";

export const TRIP_REPORT = "TRIP_REPORT";
export const TRIP_REPORT_SUCCESS = "TRIP_REPORT_SUCCESS";
export const CLEAN_TRIP_REPORT = "CLEAN_TRIP_REPORT";

export const ADD_CASH_GIVEN = "ADD_CASH_GIVEN";
export const ADD_CASH_GIVEN_SUCCESS = "ADD_CASH_GIVEN_SUCCESS";

export const CASH_TRANSACTIONS = "CASH_TRANSACTIONS";
export const CASH_TRANSACTIONS_SUCCESS = "CASH_TRANSACTIONS_SUCCESS";
export const CLEAN_TRANSACTION_LIST = "CLEAN_TRANSACTION_LIST";
export const EDIT_CASH_GIVEN = "EDIT_CASH_GIVEN";
export const EDIT_CASH_GIVEN_SUCCESS = "EDIT_CASH_GIVEN_SUCCESS";

export const FASTAG_VEHICLE_TRANSACTION_BY_FLEET =
  "FASTAG_VEHICLE_TRANSACTION_BY_FLEET";
export const FASTAG_VEHICLE_TRANSACTION_BY_FLEET_SUCCESS =
  "FASTAG_VEHICLE_TRANSACTION_BY_FLEET_SUCCESS";

export const GET_CITY_AND_STATE = "GET_CITY_AND_STATE";
export const GET_CITY_AND_STATE_SUCCESS = "GET_CITY_AND_STATE_SUCCESS";
export const GET_ESTIMATED_FUEL = "GET_ESTIMATED_FUEL";
export const GET_ESTIMATED_FUEL_SUCCESS = "GET_ESTIMATED_FUEL_SUCCESS";
export const GET_M2P_CARD_TRANSACTIONS = "GET_M2P_CARD_TRANSACTIONS";

export const GET_M2P_CARD_TRANSACTIONS_SUCCESS =
  "GET_M2P_CARD_TRANSACTIONS_SUCCESS";
export const GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION =
  "GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION";
export const GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION_SUCCESS =
  "GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION_SUCCESS";

export const GET_TRANSACTIONS_AND_EXPENSE = "GET_TRANSACTIONS_AND_EXPENSE";
export const GET_TRANSACTIONS_AND_EXPENSE_SUCCESS =
  "GET_TRANSACTIONS_AND_EXPENSE_SUCCESS";
export const GET_WEATHER_DATA = "GET_WEATHER_DATA";
export const GET_WEATHER_DATA_SUCCESS = "GET_WEATHER_DATA_SUCCESS";
export const NO_CARD_FOR_DRIVER = "NO_CARD_FOR_DRIVER";
export const NO_FASTAG_FOR_FLEET = "NO_FASTAG_FOR_FLEET";

export const OMC_REGISTER_DRIVER = "OMC_REGISTER_DRIVER";
export const OMC_REGISTER_DRIVER_SUCCESS = "OMC_REGISTER_DRIVER_SUCCESS";

export const CLEAN_OMC_TRANSACTIONS = "CLEAN_OMC_TRANSACTIONS";

export const DRIVER_ADD_BENEFICIARY = "DRIVER_ADD_BENEFICIARY";
export const DRIVER_ADD_BENEFICIARY_SUCCESS = "DRIVER_ADD_BENEFICIARY_SUCCESS";

export const GET_DRIVER_BENEFICIARY = "GET_DRIVER_BENEFICIARY";
export const GET_DRIVER_BENEFICIARY_SUCCESS = "GET_DRIVER_BENEFICIARY_SUCCESS";

export const GET_CITY_STATE = "GET_CITY_STATE";
export const GET_CITY_STATE_SUCCESS = "GET_CITY_STATE_SUCCESS";

export const DRIVER_BANK_TRANSFER = "DRIVER_BANK_TRANSFER";
export const DRIVER_BANK_TRANSFER_SUCCESS = "DRIVER_BANK_TRANSFER_SUCCESS";

export const ADD_DRIVER_CARD_BENEFICIARY = "ADD_DRIVER_CARD_BENEFICIARY";
export const ADD_DRIVER_CARD_BENEFICIARY_SUCCESS =
  "ADD_DRIVER_CARD_BENEFICIARY_SUCCESS";

export const DOWNLOAD_SALARY_CREDIT_SHEET = "DOWNLOAD_SALARY_CREDIT_SHEET";
export const DOWNLOAD_SALARY_CREDIT_SHEET_SUCCESS =
  "DOWNLOAD_SALARY_CREDIT_SHEET_SUCCESS";

export const UPLOAD_SALARY_CREDIT_SHEET = "UPLOAD_SALARY_CREDIT_SHEET";
export const UPLOAD_SALARY_CREDIT_SHEET_SUCCESS =
  "UPLOAD_SALARY_CREDIT_SHEET_SUCCESS";

export const GET_SALARY_WALLET_DETAILS = "GET_SALARY_WALLET_DETAILS";
export const CLEAN_SALARY_WALLET_DETAILS = "CLEAN_SALARY_WALLET_DETAILS";
export const GET_SALARY_WALLET_DETAILS_SUCCESS =
  "GET_SALARY_WALLET_DETAILS_SUCCESS";

export const EDIT_SALARY_WALLET_DETAILS = "EDIT_SALARY_WALLET_DETAILS";
export const EDIT_SALARY_WALLET_DETAILS_SUCCESS =
  "EDIT_SALARY_WALLET_DETAILS_SUCCESS";

export const CREATE_SALARY_WALLET = "CREATE_SALARY_WALLET";
export const CREATE_SALARY_WALLET_SUCCESS = "CREATE_SALARY_WALLET_SUCCESS";

export const GET_ALL_SALARY_WALLET = "GET_ALL_SALARY_WALLET";
export const GET_ALL_SALARY_WALLET_SUCCESS = "GET_ALL_SALARY_WALLET_SUCCESS";
export const CLEAR_ALL_SALARY_WALLET = "CLEAR_ALL_SALARY_WALLET";

//vendor
export const CREATE_VENDOR = "CREATE_VENDOR";
export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS";
export const GET_LOGIPE_VENDOR_DETAILS = "GET_LOGIPE_VENDOR_DETAILS";
export const GET_LOGIPE_VENDOR_DETAILS_SUCCESS =
  "GET_LOGIPE_VENDOR_DETAILS_SUCCESS";
export const EDIT_VENDOR = "EDIT_VENDOR";
export const EDIT_VENDOR_SUCCESS = "EDIT_VENDOR_SUCCESS";
export const GET_VENDOR = "GET_VENDOR";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const VENDOR_TRANSACTION = "VENDOR_TRANSACTION";
export const VENDOR_TRANSACTION_SUCCESS = "VENDOR_TRANSACTION_SUCCESS";
export const VENDOR_UPLOAD_BILL = "VENDOR_UPLOAD_BILL";
export const VENDOR_UPLOAD_BILL_SUCCESS = "VENDOR_UPLOAD_BILL_SUCCESS";
export const VENDOR_GET_SIGNED_URL = "VENDOR_GET_SIGNED_URL";
export const VENDOR_GET_SIGNED_URL_SUCCESS = "VENDOR_GET_SIGNED_URL_SUCCESS";
export const GENERATE_STATEMENT_FOR_VENDOR = "GENERATE_STATEMENT_FOR_VENDOR";
export const GENERATE_STATEMENT_FOR_VENDOR_SUCCESS =
  "GENERATE_STATEMENT_FOR_VENDOR_SUCCESS";
export const TRANSFER_TO_VENDOR = "TRANSFER_TO_VENDOR";
export const TRANSFER_TO_VENDOR_SUCCESS = "TRANSFER_TO_VENDOR_SUCCESS";
export const CLEAN_VENDOR_LIST = "CLEAN_VENDOR_LIST";
export const CLEAN_VENDOR_TRANSACTION = "CLEAN_VENDOR_TRANSACTION";

export const UPDATE_FLEET = "UPDATE_FLEET";
export const UPDATE_FLEET_SUCCESS = "UPDATE_FLEET_SUCCESS";

export const GET_FLEET_LIST_TRACKER = "GET_FLEET_LIST_TRACKER";
export const GET_FLEET_LIST_TRACKER_SUCCESS = "GET_FLEET_LIST_TRACKER_SUCCESS";
export const GENERATE_FRESH_DESK_TICKET_V2 = "GENERATE_FRESH_DESK_TICKET_V2";
export const GENERATE_FRESH_DESK_TICKET_V2_SUCCESS =
  "GENERATE_FRESH_DESK_TICKET_V2_SUCCESS";
export const PAY_DRIVER_SALARY = "PAY_DRIVER_SALARY";
export const PAY_DRIVER_SALARY_SUCCESS = "PAY_DRIVER_SALARY_SUCCESS";
export const EDIT_SALARY_WALLET = "EDIT_SALARY_WALLET";
export const EDIT_SALARY_WALLET_SUCCESS = "EDIT_SALARY_WALLET_SUCCESS";

export const ADD_DRIVERV2 = "ADD_DRIVERV2";
export const ADD_DRIVERV2_SUCCESS = "ADD_DRIVERV2_SUCCESS";
export const ADD_FREIGHT = "ADD_FREIGHT";
export const ADD_FREIGHT_SUCCESS = "ADD_FREIGHT_SUCCESS";

export const BULK_TRANSFER_SAMPLE = "BULK_TRANSFER_SAMPLE";
export const BULK_TRANSFER_SAMPLE_SUCCESS = "BULK_TRANSFER_SAMPLE_SUCCESS";
export const BULK_TRANSFER_UPLOAD = "BULK_TRANSFER_UPLOAD";
export const BULK_TRANSFER_UPLOAD_SUCCESS = "BULK_TRANSFER_UPLOAD_SUCCESS";
export const EDIT_FREIGHT = "EDIT_FREIGHT";
export const EDIT_FREIGHT_SUCCESS = "EDIT_FREIGHT_SUCCESS";

export const GET_FREIGHT_DETAILS = "GET_FREIGHT_DETAILS";
export const GET_FREIGHT_DETAILS_SUCCESS = "GET_FREIGHT_DETAILS_SUCCESS";




// Sales

export const GET_ALL_CUSTOMER = "GET_ALL_CUSTOMER";
export const GET_ALL_CUSTOMER_SUCCESS = "GET_ALL_CUSTOMER_SUCCESS";
export const CLEAN_ALL_CUSTOMER = "CLEAN_ALL_CUSTOMER";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";

// Add new phone to fuel wallet
export const ADD_NEW_PHONE_FW = "ADD_NEW_PHONE_FW";
export const ADD_NEW_PHONE_FW_SUCCESS = "ADD_NEW_PHONE_FW_SUCCESS";
//
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const CLEAN_CUSTOMER = "CLEAN_CUSTOMER";
export const GET_LEDGER_LIST = "GET_LEDGER_LIST";
export const GET_LEDGER_LIST_SUCCESS = "GET_LEDGER_LIST_SUCCESS";
export const GET_INVOICES_LIST = "GET_INVOICES_LIST";
export const GET_INVOICES_LIST_SUCCESS = "GET_INVOICES_LIST_SUCCESS";
export const CLEAN_LEDGER_LIST = "CLEAN_LEDGER_LIST";
export const DELETE_LEDGER = "DELETE_LEDGER";
export const DELETE_LEDGER_SUCCESS = "DELETE_LEDGER_SUCCESS";
export const CREATE_LOGIPE_INVOICE = "CREATE_LOGIPE_INVOICE";
export const CREATE_LOGIPE_INVOICE_SUCCESS = "CREATE_LOGIPE_INVOICE_SUCCESS";
export const CREATE_EXTERNAL_INVOICE = "CREATE_EXTERNAL_INVOICE";
export const CREATE_EXTERNAL_INVOICE_SUCCESS =
  "CREATE_EXTERNAL_INVOICE_SUCCESS";
export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE";
export const DOWNLOAD_INVOICE_SUCCESS = "DOWNLOAD_INVOICE_SUCCESS";
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const EDIT_LOGIPE_INVOICE = "EDIT_LOGIPE_INVOICE";
export const EDIT_LOGIPE_INVOICE_SUCCESS = "EDIT_LOGIPE_INVOICE_SUCCESS";
export const EDIT_EXTERNAL_INVOICE = "EDIT_EXTERNAL_INVOICE";
export const EDIT_EXTERNAL_INVOICE_SUCCESS = "EDIT_EXTERNAL_INVOICE_SUCCESS";
export const UPLOAD_RECIPT = "UPLOAD_RECIPT";
export const UPLOAD_RECIPT_SUCCESS = "UPLOAD_RECIPT_SUCCESS";
export const ADD_PAYMENT_RECIVED = "ADD_PAYMENT_RECIVED";
export const ADD_PAYMENT_RECIVED_SUCCESS = "ADD_PAYMENT_RECIVED_SUCCESS";
export const DOWNLOAD_LEDGER = "DOWNLOAD_LEDGER";
export const DOWNLOAD_LEDGER_SUCCESS = "DOWNLOAD_LEDGER_SUCCESS";
export const GET_ALL_TRIPS = "GET_ALL_TRIPS";
export const GET_ALL_TRIPS_SUCCESS = "GET_ALL_TRIPS_SUCCESS";
export const GET_SIGNED_URL = "GET_SIGNED_URL";
export const GET_SIGNED_URL_SUCCESS = "GET_SIGNED_URL_SUCCESS";
export const CLEAN_ALL_TRIP = "CLEAN_ALL_TRIP";


export const COLLECT_PAYMENT = "COLLECT_PAYMENT";
export const COLLECT_PAYMENT_SUCCESS = "COLLECT_PAYMENT_SUCCESS";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";

export const CLEAR_ALL_DRIVERS_ADVANCE = "CLEAR_ALL_DRIVERS_ADVANCE";
export const GET_DRIVERS_LIST_ADVANCE = "GET_DRIVERS_LIST_ADVANCE";
export const GET_DRIVERS_LIST_ADVANCE_SUCCESS = "GET_DRIVERS_LIST_ADVANCE_SUCCESS";

export const FIND_ADDRESS_TRIP = 'FIND_ADDRESS_TRIP'
export const FIND_ADDRESS_TRIP_SUCCESS = 'FIND_ADDRESS_TRIP_SUCCESS'


export const ADD_TRIP_ADDRESS = 'ADD_TRIP_ADDRESS'
export const ADD_TRIP_ADDRESS_SUCCESS = 'ADD_TRIP_ADDRESS_SUCCESS'


export const ADD_VENDOR_PAYMENT = "ADD_VENDOR_PAYMENT";
export const ADD_VENDOR_PAYMENT_SUCCESS = "ADD_VENDOR_PAYMENT_SUCCESS";

export const ADVANCED_VEHICLE_LIST = 'ADVANCED_VEHICLE_LIST';
export const ADVANCED_VEHICLE_LIST_SUCCESS = 'ADVANCED_VEHICLE_LIST_SUCCESS';
export const GET_VENDOR_DETAILS = 'GET_VENDOR_DETAILS';
export const GET_VENDOR_DETAILS_SUCCESS = 'GET_VENDOR_DETAILS_SUCCESS';
export const FLEET_EDIT_STATUS = 'FLEET_EDIT_STATUS';
export const FLEET_EDIT_STATUS_SUCCESS = 'FLEET_EDIT_STATUS_SUCCESS';
export const DRIVER_EDIT_STATUS = 'DRIVER_EDIT_STATUS';
export const DRIVER_EDIT_STATUS_SUCCESS = 'DRIVER_EDIT_STATUS_SUCCESS';
export const EDIT_CONTROL_MANAGER = 'EDIT_CONTROL_MANAGER';
export const EDIT_CONTROL_MANAGER_SUCCESS = 'EDIT_CONTROL_MANAGER_SUCCESS';
export const CHANGE_UPI_MANAGER = 'CHANGE_UPI_MANAGER';
export const CHANGE_UPI_MANAGER_SUCCESS = 'CHANGE_UPI_MANAGER_SUCCESS';
export const CREATE_ORDER_FOR_FLEET = 'CREATE_ORDER_FOR_FLEET';
export const CREATE_ORDER_FOR_FLEET_SUCCESS = 'CREATE_ORDER_FOR_FLEET_SUCCESS';
export const TRIP_BULK_ACTIONS = 'TRIP_BULK_ACTIONS';
export const TRIP_BULK_ACTIONS_SUCCESS = 'TRIP_BULK_ACTIONS_SUCCESS';
export const GET_ADDRESS_FROM_PINCODE = 'GET_ADDRESS_FROM_PINCODE';
export const GET_ADDRESS_FROM_PINCODE_SUCCESS = 'GET_ADDRESS_FROM_PINCODE_SUCCESS';
export const CREATE_LR = 'CREATE_LR';
export const CREATE_LR_SUCCESS = 'CREATE_LR_SUCCESS';
export const GET_LR_LIST = 'GET_LR_LIST';
export const CLEAR_LR_LIST = 'CLEAR_LR_LIST';
export const GET_LR_LIST_SUCCESS = 'GET_LR_LIST_SUCCESS';
export const EDIT_LR = 'EDIT_LR';
export const EDIT_LR_SUCCESS = 'EDIT_LR_SUCCESS';
export const GET_PINCODE_DATA = 'GET_PINCODE_DATA';
export const GET_PINCODE_DATA_SUCCESS = 'GET_PINCODE_DATA_SUCCESS';
export const MARK_INVOICE_AS_PAID = 'MARK_INVOICE_AS_PAID';
export const MARK_INVOICE_AS_PAID_SUCCESS = 'MARK_INVOICE_AS_PAID_SUCCESS';
export const GEN_LR_NO = 'GEN_LR_NO';
export const GEN_LR_NO_SUCCESS = 'GEN_LR_NO_SUCCESS';
export const FIND_ADDRESS_TRIP_V2 = 'FIND_ADDRESS_TRIP_V2';
export const FIND_ADDRESS_TRIP_V2_SUCCESS = 'FIND_ADDRESS_TRIP_V2_SUCCESS';
export const GET_SAVED_ADDRESS = 'GET_SAVED_ADDRESS';
export const GET_SAVED_ADDRESS_SUCCESS = 'GET_SAVED_ADDRESS_SUCCESS';
export const ADD_CASH_EXPANSE = 'ADD_CASH_EXPANSE';
export const ADD_CASH_EXPANSE_SUCCESS = 'ADD_CASH_EXPANSE_SUCCESS';

export const GET_DRIVER_LEDGER = 'GET_DRIVER_LEDGER';
export const CLEAR_DRIVER_LEDGER = 'CLEAR_DRIVER_LEDGER';
export const GET_DRIVER_LEDGER_SUCCESS = 'GET_DRIVER_LEDGER_SUCCESS';
export const GET_VEHICLE_LEDGER = 'GET_VEHICLE_LEDGER';
export const CLEAR_VEHICLE_LEDGER = 'CLEAR_VEHICLE_LEDGER';
export const GET_VEHICLE_LEDGER_SUCCESS = 'GET_VEHICLE_LEDGER_SUCCESS';
export const GET_TRIP_LEDGER = 'GET_TRIP_LEDGER';
export const CLEAR_TRIP_LEDGER = 'CLEAR_TRIP_LEDGER';
export const GET_TRIP_LEDGER_SUCCESS = 'GET_TRIP_LEDGER_SUCCESS';
export const CREATE_TRIP_EXPENSE = 'CREATE_TRIP_EXPENSE';
export const CREATE_TRIP_EXPENSE_SUCCESS = 'CREATE_TRIP_EXPENSE_SUCCESS';
export const GET_EACH_ROUTE = 'GET_EACH_ROUTE';
export const GET_EACH_ROUTE_SUCCESS = 'GET_EACH_ROUTE_SUCCESS';

export const ADD_DRIVER_EXPENSE = "ADD_DRIVER_EXPENSE"
export const ADD_DRIVER_EXPENSE_SUCCESS = "ADD_DRIVER_EXPENSE_SUCCESS"
export const GET_VENDOR_LEDGER = "GET_VENDOR_LEDGER"
export const GET_VENDOR_LEDGER_SUCCESS = "GET_VENDOR_LEDGER_SUCCESS"
export const CLEAR_VENDOR_LEDGER = "CLEAR_VENDOR_LEDGER"
export const EDIT_LEDGER_EXPENSE = "EDIT_LEDGER_EXPENSE"
export const EDIT_LEDGER_EXPENSE_SUCCESS = "EDIT_LEDGER_EXPENSE_SUCCESS"
export const GET_VEHICLE_LAST_TRIP = "GET_VEHICLE_LAST_TRIP"
export const GET_VEHICLE_LAST_TRIP_SUCCESS = "GET_VEHICLE_LAST_TRIP_SUCCESS"
export const EDIT_TRIP_EXPENSE = "EDIT_TRIP_EXPENSE"
export const EDIT_TRIP_EXPENSE_SUCCESS = "EDIT_TRIP_EXPENSE_SUCCESS"
export const GET_TRIP_EXPENSE = "GET_TRIP_EXPENSE"
export const GET_TRIP_EXPENSE_SUCCESS = "GET_TRIP_EXPENSE_SUCCESS"
export const START_TRIP_EXPENSE = "START_TRIP_EXPENSE"
export const START_TRIP_EXPENSE_SUCCESS = "START_TRIP_EXPENSE_SUCCESS"
export const GET_SIGNED_URL_WITH_KEY = "GET_SIGNED_URL_WITH_KEY"
export const GET_SIGNED_URL_WITH_KEY_SUCCESS = "GET_SIGNED_URL_WITH_KEY_SUCCESS" 
export const UPDATE_LR_IN_TRIP = "UPDATE_LR_IN_TRIP"
export const UPDATE_LR_IN_TRIP_SUCCESS = "UPDATE_LR_IN_TRIP_SUCCESS" 

export const IDFC_KYC_SEND_OTP = 'IDFC_KYC_SEND_OTP';
export const IDFC_KYC_SEND_OTP_SUCCESS = 'IDFC_KYC_SEND_OTP_SUCCESS';
export const IDFC_KYC_VERIFY_OTP = 'IDFC_KYC_VERIFY_OTP';
export const IDFC_KYC_VERIFY_OTP_SUCCESS = 'IDFC_KYC_VERIFY_OTP_SUCCESS';
export const IDFC_KYC_SAVE_IMAGE = 'IDFC_KYC_SAVE_IMAGE';
export const IDFC_KYC_SAVE_IMAGE_SUCCESS = 'IDFC_KYC_SAVE_IMAGE_SUCCESS';

// fuel wallet recharge 
export const FUEL_WALLET_RECHARGE = "FUEL_WALLET_RECHARGE";
export const FUEL_WALLET_RECHARGE_SUCCESS = "FUEL_WALLET_RECHARGE_SUCCESS";

// fuelWallets bal 

export const GET_FUEL_WALLET_BAL = 'FUEL_WALLET_BAL';
export const GET_FUEL_WALLET_BAL_SUCCESS = 'FUEL_WALLET_BAL_SUCCESS';
export const GET_FUEL_WALLET_HISTORY = 'FUEL_WALLET_HISTORY';
export const GET_FUEL_WALLET_HISTORY_SUCCESS = 'FUEL_WALLET_HISTORY_SUCCESS';
export const FUEL_WALLET_LIMIT_UPDATE = 'FUEL_WALLET_LIMIT_UPDATE';
export const FUEL_WALLET_LIMIT_UPDATE_SUCCESS = 'FUEL_WALLET_LIMIT_UPDATE_SUCCESS';
export const GET_PINCODE = 'GET_PINCODE';
export const GET_PINCODE_SUCCESS = 'GET_PINCODE_SUCCESS';

export const DRIVER_VEHICLE_HISTORY = 'DRIVER_VEHICLE_HISTORY';
export const DRIVER_VEHICLE_HISTORY_CLEAN = 'DRIVER_VEHICLE_HISTORY_CLEAN';
export const DRIVER_VEHICLE_HISTORY_SUCCESS = 'DRIVER_VEHICLE_HISTORY_SUCCESS';

export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';

export const GET_CUSTOM_REQUEST = 'GET_CUSTOM_REQUEST';
export const GET_CUSTOM_REQUEST_CLEAN = 'GET_CUSTOM_REQUEST_CLEAN';
export const GET_CUSTOM_REQUEST_SUCCESS = 'GET_CUSTOM_REQUEST_SUCCESS';
export const UPDATE_CUSTOM_REQUEST = 'UPDATE_CUSTOM_REQUEST';
export const UPDATE_CUSTOM_REQUEST_SUCCESS = 'UPDATE_CUSTOM_REQUEST_SUCCESS';
export const DELETE_CUSTOM_REQUEST = 'DELETE_CUSTOM_REQUEST';
export const DELETE_CUSTOM_REQUEST_SUCCESS = 'DELETE_CUSTOM_REQUEST_SUCCESS';

export const BULK_FUEL_TRANSFER_UPLOAD = "BULK_FUEL_TRANSFER_UPLOAD";
export const BULK_FUEL_TRANSFER_UPLOAD_SUCCESS = "BULK_FUEL_TRANSFER_UPLOAD_SUCCESS";

export const DELETE_BRANCH = "DELETE_BRANCH";
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS";
export const ADD_ROUTE_BULK_EXCEL_UPLOAD = "ADD_ROUTE_BULK_EXCEL_UPLOAD";
export const ADD_ROUTE_BULK_EXCEL_UPLOAD_SUCCESS = "ADD_ROUTE_BULK_EXCEL_UPLOAD_SUCCESS";
export const ADD_ROUTE_BULK_EXCEL = "ADD_ROUTE_BULK_EXCEL";
export const ADD_ROUTE_BULK_EXCEL_SUCCESS = "ADD_ROUTE_BULK_EXCEL_SUCCESS";
export const ADD_TRIP_BULK_EXCEL_UPLOAD = "ADD_TRIP_BULK_EXCEL_UPLOAD";
export const ADD_TRIP_BULK_EXCEL_UPLOAD_SUCCESS = "ADD_TRIP_BULK_EXCEL_UPLOAD_SUCCESS";
export const ADD_TRIP_BULK_EXCEL = "ADD_TRIP_BULK_EXCEL";
export const ADD_TRIP_BULK_EXCEL_SUCCESS = "ADD_TRIP_BULK_EXCEL_SUCCESS";
