import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Table,
} from "reactstrap";
import "./index.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { useRef } from "react";
import { getVehicleListAction } from "../../../../services/Store/Actions/manageVehicleAction";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import { DatePickerField } from "../../../core/inputs/input";

import {
  getTripsNew,
  createLR,
  editLR,
  getLRList,
  generateLrNo,
  findTripAddressV2,
  getSavedAddress,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import AddDriverV2 from "../AddDriverV2";
import AddVehicleV2 from "../AddVehicleV2";
import Toast from "../../../subComponents/toast";
import AddAddress from "../AddAddress";
import { ClipLoader } from "react-spinners";
import {
  toastStatus,
  storeMedia,
  getSignedUrlApiAction,
} from "../../../../services/Store/Actions/appActions";
import CreateCustomerV2 from "../../../ManageCustomersV2/SubComponents/CreateCustomerV2";
import { getAllCustomersAction } from "../../../../services/Store/Actions/customerAction";
import ToastV2 from "../../../subComponents/toastV2";

function CreateLRModal(props) {
  const {
    toggle,
    modal,
    setModal,
    location,
    isEdit,
    freightId,
    customerId,
    trip,
    setReload,
    reload,
    tripId,
    onSubmit,
    tripData,
    setState,
  } = props;
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [driver, setdriver] = useState(false);
  const [vehicle, setVehicle] = useState(false);
  const [customerAdd, setCustomerAdd] = useState(false);
  const [address, setAddress] = useState(false);
  const [consignorOpen, setConsignorOpen] = useState(false);
  const [consigneeOpen, setConsigneeOpen] = useState(false);
  const [transportorOpen, setTransportorOpen] = useState(false);

  const addDriver = () => setdriver(!driver);
  const addVehicle = () => setVehicle(!vehicle);
  const addCustomer = () => setCustomerAdd(!customerAdd);
  const addAddress = () => setAddress(!address);
  const dispatch = useDispatch();
  const datepickerRefEnd = useRef();
  const datepickerRefStart = useRef();
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [searchDriver, setSearchDriver] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");

  const [sourceName, setSourceName] = useState("");
  const [destName, setDestName] = useState("");
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [color1, setColor1] = useState(-1);
  const [color2, setColor2] = useState(-1);
  const [nextPageOpen, setNextPageOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [displayName, setDisplayName] = useState([]);
  const [status2, setStatus2] = useState(false);
  const [imageLoader2, setImageLoader2] = useState(false);
  const [displayName2, setDisplayName2] = useState([]);
  const [statusPod, setStatusPod] = useState(false);
  const [imageLoaderPod, setImageLoaderPod] = useState(false);
  const [displayNamePod, setDisplayNamePod] = useState([]);
  let [freight, setFreight] = useState({});
  console.log("newFormValuesfreight", freight);

  const [formValues, setFormValues] = useState([
    { desc: "", quantity: "", weightPerQuantity: "", amountPerQuantity: "" },
  ]);
  const [chargesFormValues, setChargesFormValues] = useState([
    { remarks: "", amount: "" },
  ]);
  // const tripAddress = useSelector((state) => state?.trip?.tripAddressDetails);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const lrNo = useSelector((state) => state?.trip?.lrNo);
  const {
    savedAddresses,
    savedAddressCount,
    searchAddresses,
    searchAddressCount,
  } = useSelector((state) => state?.trip);
  let addFormFields = () => {
    console.log("===><<<><><", formValues);
    setFormValues([
      ...formValues,
      { desc: "", quantity: "", weightPerQuantity: "", amountPerQuantity: "" },
    ]);
  };
  let addFormFieldsCharges = () => {
    console.log("===><<<><><", chargesFormValues);
    setChargesFormValues([...chargesFormValues, { remarks: "", amount: "" }]);
  };
  let removeFormFields = (index) => {
    const newFormValues = formValues.filter((item, i) => {
      return index != i;
    });
    console.log("newFormValues", newFormValues);
    setFormValues(newFormValues);
  };
  let removeChargesFormFields = (index) => {
    const newFormValues = chargesFormValues.filter((item, i) => {
      return index != i;
    });
    console.log("newFormValues", newFormValues);
    setChargesFormValues(newFormValues);
  };
  let handleChangeField = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };
  let handleChangeFieldCharges = (i, e) => {
    let newFormValues = [...chargesFormValues];
    newFormValues[i][e.target.name] = e.target.value;
    setChargesFormValues(newFormValues);
  };
  console.log("trip address here", searchAddresses);
  const payorType = [
    {
      name: "Transporter",
      id: "transportorId",
      open: () => {
        setTransportorOpen(true);
      },
      close: () => {
        setTransportorOpen(false);
      },
      openDr: transportorOpen,
      value: "",
    },
    {
      name: "Consignor",
      id: "consignorId",
      open: () => {
        setConsignorOpen(true);
      },
      close: () => {
        setConsignorOpen(false);
      },
      openDr: consignorOpen,
      value: "",
    },
    {
      name: "Consignee",
      id: "consigneeId",
      open: () => {
        setConsigneeOpen(true);
      },
      close: () => {
        setConsigneeOpen(false);
      },
      openDr: consigneeOpen,
      value: "",
    },
  ];
  const { cityAndStateList } = useSelector((store) => store?.trip);

  const allFleets = useSelector(
    (state) => state?.manageVehicle?.allFleets?.rows
  );
  const vehCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );
  console.log("allFlallFleetseets", allFleets);
  const fastVeh =
    vehCount > 0 ? allFleets.find((ele) => ele?.Fastag !== null) : "value";
  const driverList = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.rows
  );
  const driverCount = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.count
  );
  const customerList = useSelector((state) => state?.customer?.customerList);
  const customerCount = useSelector((state) => state?.customer?.customerNo);
  let { freightList, freightListCount } = useSelector((store) => store?.trip);
  let [payorTypeEdit, setPayorTypeEdit] = useState({});
  useEffect(() => {
    if (isEdit && modal) {
      let filter = freightList?.find((elem) => elem.freightId == freightId);
      console.log("freightLisfreightListt", freightList, freightId, filter);
      setFreight(filter);
      setPayorTypeEdit(
        filter?.transportorId == filter?.customerId
          ? "Transporter"
          : filter?.consigneeId == filter?.customerId
          ? "Consignee"
          : "Consignor"
      );
      setFormValues(filter?.lrData);
      setChargesFormValues(filter?.chargesData);
    } else if (!modal) {
      setFormValues([
        {
          desc: "",
          quantity: "",
          weightPerQuantity: "",
          amountPerQuantity: "",
        },
      ]);
    } else if (tripId) {
      console.log("lllltripData", tripData);
      setFreight({
        ...tripData,
        toAddress: tripData?.destAddress,
        fromAddress: tripData?.sourceAddress,
      });
    }
  }, [modal, freightList]);

  useEffect(() => {
    if (display1 == true) {
      // dispatch(findTripAddressV2({},(res)=>{
      //   console.log("findTripAddressV2",res);
      // }))
      dispatch(
        getSavedAddress({ address: search1 }, (res) => {
          console.log("getSavedAddress", res);
        })
      );
    }
  }, [display1, search1]);
  useEffect(() => {
    if (isEdit && freight?.lrUrl) {
      dispatch(
        getSignedUrlApiAction({ key: freight?.lrUrl }, (res) => {
          // setDownloadLoader2(false);
          // setLoaderInvoiceId("");
          if (res.status == 200) {
            setStatus(true);
            setDisplayName((prev) => [{ name: "lr uploaded" }]);
          }
        })
      );
    } else if (!isEdit) {
      console.log(
        "freightfreightfreightfreight",
        freight,
        "freightfreightfreight"
      );

      dispatch(
        generateLrNo({}, (res) => {
          console.log("generateLrNo", res?.data?.data);
        })
      );
    }
  }, []);

  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const [offset, setOffset] = useState(0);
  const [offsetCustomer, setOffsetCustomer] = useState(0);
  const closeBtn = (
    <button
      className="close"
      style={{
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        backgroundColor: "#FFF",
        borderColor: "#2E7C7B",
        display: "flex",
        justifyContent: "space-evenly",
      }}
      onClick={toggle}
      type="button"
    >
      <div className="x">X</div>
    </button>
  );

  const validationSchema = Yup.object().shape({
    sourceAddress: Yup.string().required("Source is required"),
    destAddress: Yup.string().required("Destination is required"),
    // fleetId: Yup.string().required("Vehicle is required"),
    // userId: Yup.string().required("Driver is required"),
    // payor: Yup.string().required("Payor is required"),
  });

  const handleClose = () => {
    setFreight({});
    setModal(!modal);
  };
  useEffect(() => {
    dispatch(
      getVehicleListAction({
        offset: offset,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [search, offset]);
  useEffect(() => {
    dispatch(
      getDriverList({
        offset: "",
        search: searchDriver,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [searchDriver]);
  useEffect(() => {
    dispatch(
      getAllCustomersAction({
        offset: offsetCustomer,
        companyName: searchCustomer,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, [searchCustomer, offsetCustomer]);

  const handlePageClick = (event) => {
    console.log("Clickess");
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    dispatch(
      getVehicleListAction({
        offset: newOffset,
        limit: 10,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
    setOffset(newOffset);
  };
  const handlePrev = () => {
    if (offset > 0) {
      setOffset(offset - 10);
    }
  };
  const handleNext = () => {
    if (vehCount > offset) {
      setOffset(offset + 10);
    }
  };
  const handlePrevCustomer = () => {
    if (offsetCustomer > 0) {
      setOffsetCustomer(offsetCustomer - 10);
    }
  };
  const handleNextCustomer = () => {
    console.log("handleNextCustomer");
    if (customerCount > offsetCustomer) {
      setOffsetCustomer(offsetCustomer + 10);
    }
  };

  useEffect(() => {
    setColor1(-1);
    dispatch(
      findTripAddressV2({
        offset: 0,
        search: search1,
      })
    );
  }, [search1]);
  useEffect(() => {
    setColor2(-1);
    dispatch(
      findTripAddressV2({
        offset: 0,
        search: search2,
      })
    );
  }, [search2]);
  const findSearch1 = () => {
    setColor1(-1);
    dispatch(
      findTripAddressV2({
        offset: 0,
        search: search1,
      })
    );
  };
  const findSearch2 = () => {
    setColor2(-1);
    dispatch(
      findTripAddressV2({
        offset: 0,
        search: search2,
      })
    );
  };
  const closeAllSearches = () => {
    if (open) setOpen(false);
    if (open1) setOpen1(false);
    payorType.forEach((elem) => {
      if (elem.openDr) elem.close();
    });
  };
  useEffect(() => {
    if (display2 == true) {
      dispatch(
        getSavedAddress({ address: search2 }, (res) => {
          console.log("getSavedAddress", res);
        })
      );
    }
  }, [display2, search2]);
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // scrollable={true}

        centered={true}
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px" }}
          onClick={closeAllSearches}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <div
                className="closeBtnWithBackgroundForTrip"
                onClick={handleClose}
              >
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="crossBtnWIthBackground"
                  width="20%"
                  height="20%"
                />
              </div>
              <div
                className="PGCollectiontText col-3 "
                style={{ marginLeft: "50px" }}
              >
                {console.log("isEditisEditisEdit", isEdit)}
                {isEdit && isEdit != "undefined" ? (
                  <text>Edit LR</text>
                ) : (
                  <text>Create LR</text>
                )}
                {/* <text>Create LR</text> */}
              </div>
              <hr
                style={{ marginLeft: "25px", border: "2px solid #2E7C7B" }}
              ></hr>
            </div>
            <Formik
              // isValid='true'
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                consignorName: freight ? freight?.consignorName : "",
                consigneeName: freight ? freight?.consigneeName : "",
                consignorId: freight ? freight?.consignorId : "",
                consigneeId: freight ? freight?.consigneeId : "",
                transportorId: freight ? freight?.transportorId : "",
                transportorName: freight ? freight?.transportorName : "",
                sourceAddress: freight?.fromAddress
                  ? freight?.fromAddress?.cityName +
                    "-" +
                    freight?.fromAddress?.stateName
                  : "",
                destAddress: freight?.toAddress
                  ? freight?.toAddress?.cityName +
                    "-" +
                    freight?.toAddress?.stateName
                  : "",
                startDate: freight ? freight?.startDate : "",
                LRNumber: freight ? freight?.LRNumber : "",
                lrNo: freight?.lrNo
                  ? freight?.lrNo
                  : freight?.freightId
                  ? freight?.freightId
                  : lrNo,
                vrn: freight ? freight?.Fleet?.regNumber : "",
                driver: freight ? freight?.User?.fullName : "",
                fleetId: freight ? freight?.fleetId : "",
                userId: freight?.userId ? freight?.userId : "",
                source: freight ? freight?.source : "",
                destination: freight ? freight?.destination : "",
                remark: freight ? freight?.remark : "",
                payor: freight ? freight?.customerId : "",
                payorType: payorTypeEdit ? payorTypeEdit : "",
                amount: freight ? freight?.freightAmount : "",
                weight: freight ? freight?.weight : "",
                lrUrl: freight ? freight?.lrUrl : "",
                docs: freight ? freight?.docs : "",
                discount: freight ? freight?.discount : "",
                discountRemarks: freight ? freight?.discountRemarks : "",
                charges: freight ? freight?.charges : "",
                chargesRemarks: freight ? freight?.chargesRemarks : "",
                description: freight ? freight?.description : "",
                freightId: freightId ? freightId : "",
                endDate: freight ? freight?.endDate : null,
                podUrl: freight ? freight?.podUrl : null,
                invoiceUrl: freight ? freight?.invoiceUrl : null,
                ewayBillNo: freight ? freight?.ewayBillNo : null,
                valueOfGoods: freight ? freight?.valueOfGoods : null,
              }}
              onSubmit={(values) => {
                let totalFrieghtAmount = 0;
                let totalFrieghtWeight = 0;
                let lrData = [];
                console.log("0000000", formValues, values?.lrNo);
                for (let index = 0; index < formValues?.length; index++) {
                  const element = formValues[index];
                  if (
                    element?.amountPerQuantity != "" &&
                    element?.quantity != ""
                  ) {
                    if (element?.desc == "") {
                      element.desc = values?.lrNo + " Item-" + (index + 1);
                      lrData.push(element);
                    } else lrData.push(element);
                  }
                }
                console.log("0000000lr", lrData);

                for (let index = 0; index < lrData.length; index++) {
                  const element = lrData[index];
                  totalFrieghtAmount +=
                    element.quantity * element.amountPerQuantity;
                  totalFrieghtWeight +=
                    element.quantity * element.weightPerQuantity;
                }
                let totalCharges = 0;
                for (
                  let index = 0;
                  index < chargesFormValues?.length;
                  index++
                ) {
                  const element = chargesFormValues?.[index];
                  totalCharges += parseFloat(element.amount);
                }
                let objVal = {
                  source: values?.source,
                  destination: values?.destination,
                  fleetId: values?.fleetId,
                  userId: values?.userId,
                  consignorName: values?.consignorName,
                  consigneeName: values?.consigneeName,
                  consignorId: values?.consignorId,
                  consigneeId: values?.consigneeId,
                  transportorId: values?.transportorId,
                  transportorName: values?.transportorName,
                  startDate: new Date(),
                  endDate: values?.endDate,
                  weight: totalFrieghtWeight,
                  freightAmount: totalFrieghtAmount,
                  customerId: values?.payor,
                  lrUrl: values?.lrUrl,
                  lrNo: values?.lrNo,
                  discount: values?.discount,
                  discountRemarks: values?.discountRemarks,
                  charges: totalCharges || values?.charges,
                  chargesRemarks: values?.chargesRemarks,
                  description: values?.description,
                  freightId: freightId ? freightId : "",
                  lrData: lrData,
                  chargesData: chargesFormValues,
                  podUrl: values?.podUrl,
                  invoiceUrl: values?.invoiceUrl,
                  ewayBillNo: values?.ewayBillNo,
                  valueOfGoods: values?.valueOfGoods,
                };

                console.log("dfsada", values?.endDate);
                if (values?.endDate != "") {
                  objVal["endTime"] = moment(values.endDate).format(
                    "MM/DD/YYYY"
                  );
                }
                console.log("submittttttttt hereerere", objVal);
                if (isEdit) {
                  dispatch(
                    editLR(objVal, (res) => {
                      if (res?.status == 200) {
                        console.log("rererere", res);
                        setState(res?.data?.data);
                        // handleClose();
                        // dispatch(
                        //   toastStatus({
                        //     toastStatus: true,
                        //     message: `LR has been edited successfully`,
                        //     status: true,
                        //   })
                        // );
                        // setOpenToast(true);
                        console.log(
                          "LR has been esssss",
                          toastObject.toastStatus,
                          location == "/lr" || location == "/lr/"
                        );
                      }
                      // else if (res?.status == 201) {
                      //   handleClose();
                      //   dispatch(
                      //     toastStatus({
                      //       toastStatus: true,
                      //       message: `Can Not Edit This Lr`,
                      //       status: false,
                      //     })
                      //   );
                      // }
                    })
                  );
                } else {
                  dispatch(
                    createLR(objVal, (res) => {
                      if (res?.status == 200) {
                        // console.log('rererere',res)
                        // handleClose();
                        // dispatch(
                        //   toastStatus({
                        //     toastStatus: true,
                        //     message: `LR has been created successfully`,
                        //     status: true,
                        //   })
                        // );
                        console.log(
                          "LR has been esssss",
                          res?.data?.data,
                          toastObject.toastStatus,
                          location == "/lr" || location == "/lr/"
                        );
                        if (trip) {
                          // let x = [];
                          // x.push(res?.data?.data?.rows)
                          onSubmit(res?.data?.data?.rows);
                        }
                        console.log("...............", res);
                      } else {
                        if (location == "/lr/") {
                        }
                      }
                    })
                  );
                }
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
                values,
                touched,
                isValid,
                errors,
                setErrors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ padding: "0 50px 0 50px" }}
                >
                  {!nextPageOpen ? (
                    <div>
                      <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormGroup>
                          <label
                            for="lrNo"
                            className="heading3 semiBold col-12"
                          >
                            LR No:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              type="text"
                              id="lrNo"
                              className={`inputBoxCust1 ${
                                touched.amount && errors.amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="lrNo"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue("lrNo", event.target.value, true);
                              }}
                              value={values.lrNo}
                              touched={touched?.lrNo ? touched?.lrNo : ""}
                              errors={errors.lrNo}
                              placeholder="LR-12345"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <label
                            for="consignorName"
                            className="heading3 semiBold"
                          >
                            Consignor:
                          </label>
                          <div
                            className="custNamepadd3"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                            onClick={() => {
                              setConsignorOpen(true);
                            }}
                          >
                            <input
                              className={`inputBoxCust1 ${
                                touched.amount && errors.amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="text"
                              id="consignorName"
                              name="consignorName"
                              touched={touched.consignorName}
                              errors={errors.consignorName}
                              onBlur={handleBlur("consignorName")}
                              onChange={(event) => {
                                setSearchCustomer(event.target.value);
                                setFieldValue(
                                  "consignorName",
                                  event.target.value,
                                  true
                                );
                                setFieldValue("consignorId", "", true);
                              }}
                              value={values.consignorName}
                              autoFocus
                              autoComplete={"off"}
                              placeholder="Select Consignor"
                            />
                            <div
                              className={`inputBoxCust2`}
                              onClick={addCustomer}
                              style={{ cursor: "pointer", marginLeft: 12 }}
                            >
                              +
                            </div>
                          </div>
                          <div style={{ position: "relative" }}>
                            {consignorOpen ? (
                              <div className="driverSearchContainer2 containerShadow">
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0.3rem",
                                    right: "0.3rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setConsignorOpen(false);
                                  }}
                                >
                                  <SvgImage name="CloseButtonLogo" />
                                </div>
                                {customerList?.map((item, i) => {
                                  console.log("itemcustomerList", item);
                                  return (
                                    <>
                                      {/* {item?.Fastag !== null ? ( */}
                                      <div
                                        key={i}
                                        className="flexStyling padding8"
                                        onClick={(event) => {
                                          setConsignorOpen(false);
                                          // setUserId(event.target.value);
                                          setFieldValue(
                                            "consignorName",
                                            item?.companyName,
                                            true
                                          );
                                          console.log("iteiteitemmm", item);
                                          setFieldValue(
                                            "consignorId",
                                            item?.customerId,
                                            true
                                          );
                                          setFieldValue(
                                            "sourceAddress",
                                            item?.TripAddress?.address,
                                            true
                                          );
                                          setFieldValue(
                                            "source",
                                            item?.TripAddress?.addressId,
                                            true
                                          );
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          name="consignorName"
                                          value={item?.customerId}
                                          id={item?.customerId}
                                          autoComplete={"off"}
                                        />
                                        <div
                                          className="padding16L"
                                          value={item?.customerId}
                                        >
                                          {item?.companyName}
                                        </div>
                                        {/* <div
                                  className="padding16L"
                                  style={{
                                    color:'blue',

                                  }}
                                >
                                  3 Days ago

                              </div> */}
                                      </div>
                                      {/* ) : (
                                <></>
                              )} */}
                                    </>
                                  );
                                })}

                                {/* <div className="row margin0 padding16">
                                  <div
                                    className="col-2"
                                    style={{
                                      textAlign: "right",
                                      paddingTop: "0.1rem",
                                      cursor:
                                        offsetCustomer > 0
                                          ? "pointer"
                                          : "no-drop",
                                      opacity: offsetCustomer > 0 ? "1" : "0.5",
                                    }}
                                    onClick={handlePrevCustomer}
                                  >
                                    <SvgImage name="PrevArrow" />
                                  </div>
                                  <div
                                    className="col-2 greenFont semiBold heading4 padding0L"
                                    style={{
                                      textAlign: "right",
                                      paddingTop: "0.3rem",
                                      cursor:
                                        offsetCustomer > 0
                                          ? "pointer"
                                          : "no-drop",
                                      opacity: offsetCustomer > 0 ? "1" : "0.5",
                                    }}
                                    onClick={handlePrevCustomer}
                                  >
                                    Previous
                                  </div>
                                  <div className="col-4 row margin0 justify-content-center p-0">
                                    <div
                                      className="greenBoxCount Medium heading4 col-4"
                                      style={{
                                        width: "2rem",
                                        height: "2rem",
                                        alignItems: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      {offsetCustomer / 10 + 1}
                                    </div>
                                    <div
                                      className=" col- padding0R"
                                      style={{
                                        paddingTop: "0.3rem",
                                        textAlign: "right",
                                      }}
                                    >
                                      of{" "}
                                      {customerCount % 10 == 0
                                        ? Math.floor(customerCount / 10)
                                        : Math.floor(customerCount / 10 + 1)}
                                    </div>
                                  </div>
                                  <div
                                    className="col-2 greenFont semiBold heading4 padding0R"
                                    style={{
                                      textAlign: "left",
                                      paddingTop: "0.3rem",
                                      cursor:
                                        customerCount - offsetCustomer > 10
                                          ? "pointer"
                                          : "no-drop",
                                      opacity:
                                        customerCount - offsetCustomer > 10
                                          ? "1"
                                          : "0.5",
                                    }}
                                    onClick={handleNextCustomer}
                                  >
                                    Next
                                  </div>
                                  <div
                                    className="col-2 padding0L"
                                    style={{
                                      textAlign: "left",
                                      // marginLeft: "-1rem",
                                      paddingTop: "0.1rem",
                                      cursor:
                                        customerCount - offsetCustomer > 10
                                          ? "pointer"
                                          : "no-drop",
                                      opacity:
                                        customerCount - offsetCustomer > 10
                                          ? "1"
                                          : "0.5",
                                    }}
                                    onClick={handleNextCustomer}
                                  >
                                    <SvgImage name="NextArrow" />
                                  </div>
                                </div> */}
                                {customerCount == 0 ? (
                                  <div className="heading3 semiBold padding16 padding24B">
                                    No Consignor Available
                                  </div>
                                ) : fastVeh == undefined ? (
                                  <div className="heading3 semiBold padding16 padding24B">
                                    No Consignor Available
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}

                            <div style={{ color: "red" }}>
                              {errors.consignorId && touched.consignorId ? (
                                <div>*{errors.consignorId}</div>
                              ) : null}
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <label
                            for="consigneeName"
                            className="heading3 semiBold"
                          >
                            Consignee:
                          </label>
                          <div
                            className="custNamepadd3"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                            onClick={() => {
                              setConsigneeOpen(true);
                            }}
                          >
                            <input
                              className={`inputBoxCust1 ${
                                touched.amount && errors.amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="text"
                              id="consigneeName"
                              name="consigneeName"
                              touched={touched.consigneeName}
                              errors={errors.consigneeName}
                              onBlur={handleBlur("consigneeName")}
                              onChange={(event) => {
                                setSearchCustomer(event.target.value);
                                setFieldValue(
                                  "consigneeName",
                                  event.target.value,
                                  true
                                );
                                setFieldValue("consigneeId", "", true);
                              }}
                              value={values.consigneeName}
                              autoFocus
                              placeholder="Select Consignee"
                              autoComplete={"off"}
                            />
                            <div
                              className={`inputBoxCust2`}
                              onClick={addCustomer}
                              style={{ cursor: "pointer", marginLeft: 12 }}
                            >
                              +
                            </div>
                          </div>
                          <div style={{ position: "relative" }}>
                            {consigneeOpen ? (
                              <div className="driverSearchContainer2 containerShadow">
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0.3rem",
                                    right: "0.3rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setConsigneeOpen(false);
                                  }}
                                >
                                  <SvgImage name="CloseButtonLogo" />
                                </div>
                                {customerList?.map((item, i) => {
                                  console.log("itemcustomerList", item);
                                  return (
                                    <>
                                      {/* {item?.Fastag !== null ? ( */}
                                      <div
                                        key={i}
                                        className="flexStyling padding8"
                                        onClick={(event) => {
                                          setConsigneeOpen(false);
                                          // setUserId(event.target.value);
                                          setFieldValue(
                                            "consigneeName",
                                            item?.companyName,
                                            true
                                          );
                                          setFieldValue(
                                            "consigneeId",
                                            item?.customerId,
                                            true
                                          );
                                          setFieldValue(
                                            "destAddress",
                                            item?.TripAddress?.address,
                                            true
                                          );
                                          setFieldValue(
                                            "destination",
                                            item?.TripAddress?.addressId,
                                            true
                                          );
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          name="consigneeName"
                                          value={item?.customerId}
                                          id={item?.customerId}
                                          autoComplete={"off"}
                                        />
                                        <div
                                          className="padding16L"
                                          value={item?.customerId}
                                        >
                                          {item?.companyName}
                                        </div>
                                        {/* <div
                                  className="padding16L"
                                  style={{
                                    color:'blue',

                                  }}
                                >
                                  3 Days ago

                              </div> */}
                                      </div>
                                      {/* ) : (
                                <></>
                              )} */}
                                    </>
                                  );
                                })}

                                <div className="row margin0 padding16">
                                  <div
                                    className="col-2"
                                    style={{
                                      textAlign: "right",
                                      paddingTop: "0.1rem",
                                      cursor:
                                        offsetCustomer > 0
                                          ? "pointer"
                                          : "no-drop",
                                      opacity: offsetCustomer > 0 ? "1" : "0.5",
                                    }}
                                    onClick={handlePrevCustomer}
                                  >
                                    <SvgImage name="PrevArrow" />
                                  </div>
                                  <div
                                    className="col-2 greenFont semiBold heading4 padding0L"
                                    style={{
                                      textAlign: "right",
                                      paddingTop: "0.3rem",
                                      cursor:
                                        offsetCustomer > 0
                                          ? "pointer"
                                          : "no-drop",
                                      opacity: offsetCustomer > 0 ? "1" : "0.5",
                                    }}
                                    onClick={handlePrevCustomer}
                                  >
                                    Previous
                                  </div>
                                  <div className="col-4 row margin0 justify-content-center p-0">
                                    <div
                                      className="greenBoxCount Medium heading4 col-4"
                                      style={{
                                        width: "2rem",
                                        height: "2rem",
                                        alignItems: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      {offsetCustomer / 10 + 1}
                                    </div>
                                    <div
                                      className=" col- padding0R"
                                      style={{
                                        paddingTop: "0.3rem",
                                        textAlign: "right",
                                      }}
                                    >
                                      of{" "}
                                      {customerCount % 10 == 0
                                        ? Math.floor(customerCount / 10)
                                        : Math.floor(customerCount / 10 + 1)}
                                    </div>
                                  </div>
                                  <div
                                    className="col-2 greenFont semiBold heading4 padding0R"
                                    style={{
                                      textAlign: "left",
                                      paddingTop: "0.3rem",
                                      cursor:
                                        customerCount - offsetCustomer > 10
                                          ? "pointer"
                                          : "no-drop",
                                      opacity:
                                        customerCount - offsetCustomer > 10
                                          ? "1"
                                          : "0.5",
                                    }}
                                    onClick={handleNextCustomer}
                                  >
                                    Next
                                  </div>
                                  <div
                                    className="col-2 padding0L"
                                    style={{
                                      textAlign: "left",
                                      // marginLeft: "-1rem",
                                      paddingTop: "0.1rem",
                                      cursor:
                                        customerCount - offsetCustomer > 10
                                          ? "pointer"
                                          : "no-drop",
                                      opacity:
                                        customerCount - offsetCustomer > 10
                                          ? "1"
                                          : "0.5",
                                    }}
                                    onClick={handleNextCustomer}
                                  >
                                    <SvgImage name="NextArrow" />
                                  </div>
                                </div>
                                {customerCount == 0 ? (
                                  <div className="heading3 semiBold padding16 padding24B">
                                    No Consignee Available
                                  </div>
                                ) : fastVeh == undefined ? (
                                  <div className="heading3 semiBold padding16 padding24B">
                                    No Consignee Available
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}

                            <div style={{ color: "red" }}>
                              {errors.consigneeId && touched.consigneeId ? (
                                <div>*{errors.consigneeId}</div>
                              ) : null}
                            </div>
                          </div>
                        </FormGroup>
                      </FormGroup>
                      <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormGroup style={{}}>
                          <label
                            for="transportorName"
                            className="heading3 semiBold"
                          >
                            Transporter:
                          </label>
                          <div
                            className="custNamepadd3"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <input
                              className={`inputBoxCust1 ${
                                touched.amount && errors.amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="text"
                              id="transportorName"
                              name="transportorName"
                              touched={touched.transportorName}
                              errors={errors.transportorName}
                              onBlur={handleBlur("transportorName")}
                              onChange={(event) => {
                                setSearchCustomer(event.target.value);
                                setFieldValue(
                                  "transportorName",
                                  event.target.value,
                                  true
                                );
                                setFieldValue("transportorId", "", true);
                              }}
                              value={values.transportorName}
                              autoFocus
                              placeholder="Select Transporter"
                              onClick={() => {
                                setTransportorOpen(true);
                              }}
                              autoComplete={"off"}
                            />
                            <div
                              className={`inputBoxCust2`}
                              onClick={addCustomer}
                              style={{ cursor: "pointer", marginLeft: 12 }}
                            >
                              +
                            </div>
                          </div>
                          <div style={{ position: "relative" }}>
                            {transportorOpen ? (
                              <div className="driverSearchContainer2 containerShadow">
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0.3rem",
                                    right: "0.3rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setTransportorOpen(false);
                                  }}
                                >
                                  <SvgImage name="CloseButtonLogo" />
                                </div>
                                {customerList?.map((item, i) => {
                                  console.log("itemcustomerList", item);
                                  return (
                                    <>
                                      {/* {item?.Fastag !== null ? ( */}
                                      <div
                                        key={i}
                                        className="flexStyling padding8"
                                        onClick={(event) => {
                                          setTransportorOpen(false);
                                          // setUserId(event.target.value);
                                          setFieldValue(
                                            "transportorName",
                                            item?.companyName,
                                            true
                                          );
                                          setFieldValue(
                                            "transportorId",
                                            item?.customerId,
                                            true
                                          );
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          name="transportorName"
                                          value={item?.customerId}
                                          id={item?.customerId}
                                          autoComplete={"off"}
                                        />
                                        <div
                                          className="padding16L"
                                          value={item?.customerId}
                                        >
                                          {item?.companyName}
                                        </div>
                                        {/* <div
                                  className="padding16L"
                                  style={{
                                    color:'blue',

                                  }}
                                >
                                  3 Days ago

                              </div> */}
                                      </div>
                                      {/* ) : (
                                <></>
                              )} */}
                                    </>
                                  );
                                })}

                                <div className="row margin0 padding16">
                                  <div
                                    className="col-2"
                                    style={{
                                      textAlign: "right",
                                      paddingTop: "0.1rem",
                                      cursor:
                                        offsetCustomer > 0
                                          ? "pointer"
                                          : "no-drop",
                                      opacity: offsetCustomer > 0 ? "1" : "0.5",
                                    }}
                                    onClick={handlePrevCustomer}
                                  >
                                    <SvgImage name="PrevArrow" />
                                  </div>
                                  <div
                                    className="col-2 greenFont semiBold heading4 padding0L"
                                    style={{
                                      textAlign: "right",
                                      paddingTop: "0.3rem",
                                      cursor:
                                        offsetCustomer > 0
                                          ? "pointer"
                                          : "no-drop",
                                      opacity: offsetCustomer > 0 ? "1" : "0.5",
                                    }}
                                    onClick={handlePrevCustomer}
                                  >
                                    Previous
                                  </div>
                                  <div className="col-4 row margin0 justify-content-center p-0">
                                    <div
                                      className="greenBoxCount Medium heading4 col-4"
                                      style={{
                                        width: "2rem",
                                        height: "2rem",
                                        alignItems: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      {offsetCustomer / 10 + 1}
                                    </div>
                                    <div
                                      className=" col- padding0R"
                                      style={{
                                        paddingTop: "0.3rem",
                                        textAlign: "right",
                                      }}
                                    >
                                      of{" "}
                                      {customerCount % 10 == 0
                                        ? Math.floor(customerCount / 10)
                                        : Math.floor(customerCount / 10 + 1)}
                                    </div>
                                  </div>
                                  <div
                                    className="col-2 greenFont semiBold heading4 padding0R"
                                    style={{
                                      textAlign: "left",
                                      paddingTop: "0.3rem",
                                      cursor:
                                        customerCount - offsetCustomer > 10
                                          ? "pointer"
                                          : "no-drop",
                                      opacity:
                                        customerCount - offsetCustomer > 10
                                          ? "1"
                                          : "0.5",
                                    }}
                                    onClick={handleNextCustomer}
                                  >
                                    Next
                                  </div>
                                  <div
                                    className="col-2 padding0L"
                                    style={{
                                      textAlign: "left",
                                      // marginLeft: "-1rem",
                                      paddingTop: "0.1rem",
                                      cursor:
                                        customerCount - offsetCustomer > 10
                                          ? "pointer"
                                          : "no-drop",
                                      opacity:
                                        customerCount - offsetCustomer > 10
                                          ? "1"
                                          : "0.5",
                                    }}
                                    onClick={handleNextCustomer}
                                  >
                                    <SvgImage name="NextArrow" />
                                  </div>
                                </div>
                                {customerCount == 0 ? (
                                  <div className="heading3 semiBold padding16 padding24B">
                                    No Transporter Available
                                  </div>
                                ) : fastVeh == undefined ? (
                                  <div className="heading3 semiBold padding16 padding24B">
                                    No Transporter Available
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}

                            <div style={{ color: "red" }}>
                              {errors.transportorId && touched.transportorId ? (
                                <div>*{errors.transportorId}</div>
                              ) : null}
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup style={{}}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                marginRight: "50px",
                                marginTop: "25px",
                              }}
                            >
                              <label
                                for="payor"
                                className="heading3 semiBold"
                                style={{ marginTop: "8px" }}
                              >
                                Choose Payor:
                              </label>
                              {payorType?.map((item, i) => {
                                // console.log('itemcpayorType',item, values);
                                let disabled = true;
                                if (values?.[item?.id]) {
                                  disabled = false;
                                }
                                console.log(
                                  "values?.[item?.id]",
                                  item,
                                  values?.[item?.id]
                                );
                                return (
                                  <>
                                    {/* {item?.Fastag !== null ? ( */}
                                    <div
                                      key={i}
                                      className="flexStyling padding8"
                                      disabled={disabled}
                                      onClick={(event) => {
                                        if (values[item?.id] == "") {
                                          item.open();
                                        } else {
                                          console.log(
                                            "setFieldValuepayor",
                                            values,
                                            item?.id
                                          );
                                          setFieldValue(
                                            "payor",
                                            values[item?.id],
                                            true
                                          );
                                          setFieldValue(
                                            "payorType",
                                            item?.name,
                                            true
                                          );
                                        }
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="payorType"
                                        // defaultChecked={"Transporter"}
                                        disabled={disabled}
                                        checked={
                                          values?.payorType == item?.name
                                        }
                                        id="payor"
                                        autoComplete={"off"}
                                        onChange={() => {}}
                                        value={values?.payor}
                                      />
                                      <div
                                        className="padding16L"
                                        value={item?.name}
                                      >
                                        {item?.name}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>

                            <div style={{ color: "red" }}>
                              {errors.payor && touched.payor ? (
                                <div>*{errors.payor}</div>
                              ) : null}
                            </div>
                          </div>
                        </FormGroup>
                      </FormGroup>
                      <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormGroup>
                          <label for="vrn" className="heading3 semiBold">
                            Vehicle No:
                          </label>
                          <div
                            className="custNamepadd3"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                            onClick={() => {
                              console.log("triptrtripIdipIdId", tripId);
                              if (!tripId) {
                                setOpen(true);
                              }
                            }}
                          >
                            <input
                              readOnly={tripId}
                              className={`inputBoxCust1 ${
                                touched.vrn && errors.vrn ? "is-invalid" : ""
                              }`}
                              type="text"
                              id="vrn"
                              name="vrn"
                              touched={touched.vrn}
                              errors={errors.vrn}
                              onBlur={handleBlur("vrn")}
                              onChange={(event) => {
                                setSearch(event.target.value);
                                setFieldValue("vrn", event.target.value, true);
                                if (event.target.value == "") {
                                  setFieldValue("fleetId", "", true);
                                }
                              }}
                              value={values.vrn}
                              autoFocus
                              placeholder="Select Vehicle"
                              autoComplete={"off"}
                            />
                            <div
                              className={`inputBoxCust2`}
                              onClick={addVehicle}
                              style={{ cursor: "pointer", marginLeft: 12 }}
                            >
                              +
                            </div>
                          </div>
                          <div style={{ position: "relative" }}>
                            {open ? (
                              <div className="driverSearchContainer2 containerShadow">
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0.3rem",
                                    right: "0.3rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setOpen(false);
                                  }}
                                >
                                  <SvgImage name="CloseButtonLogo" />
                                </div>
                                {allFleets?.map((item, i) => {
                                  console.log("allFleet1624531s", item);
                                  return (
                                    <>
                                      {/* {item?.Fastag !== null ? ( */}
                                      <div
                                        key={i}
                                        className="flexStyling padding8"
                                        onClick={(event) => {
                                          setOpen(false);
                                          // setUserId(event.target.value);
                                          setFieldValue(
                                            "vrn",
                                            item?.regNumber,
                                            true
                                          );
                                          setFieldValue(
                                            "fleetId",
                                            item?.fleetId,
                                            true
                                          );
                                          console.log(
                                            "item?.FleetDriverMapHistories?.length ",
                                            item?.FleetDriverMapHistories
                                              ?.length
                                          );
                                          setFieldValue(
                                            "driver",
                                            item?.FleetDriverMapHistories
                                              ?.length > 0
                                              ? item?.FleetDriverMapHistories[0]
                                                  ?.Driver?.User?.fullName
                                              : "",
                                            true
                                          );
                                          setFieldValue(
                                            "userId",
                                            item?.FleetDriverMapHistories
                                              ?.length > 0
                                              ? item?.FleetDriverMapHistories[0]
                                                  ?.Driver?.userId
                                              : "",
                                            true
                                          );
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          name="vrn"
                                          value={item?.fleetId}
                                          id={item?.fleetId}
                                          autoComplete={"off"}
                                        />
                                        <div
                                          className="padding16L"
                                          value={item?.fleetId}
                                        >
                                          {item?.regNumber}
                                        </div>
                                        {/* <div
                                  className="padding16L"
                                  style={{
                                    color:'blue',

                                  }}
                                >
                                  3 Days ago

                              </div> */}
                                      </div>
                                      {/* ) : (
                                <></>
                              )} */}
                                    </>
                                  );
                                })}

                                <div className="row margin0 padding16">
                                  <div
                                    className="col-2"
                                    style={{
                                      textAlign: "right",
                                      paddingTop: "0.1rem",
                                      cursor:
                                        offset > 0 ? "pointer" : "no-drop",
                                      opacity: offset > 0 ? "1" : "0.5",
                                    }}
                                    onClick={handlePrev}
                                  >
                                    <SvgImage name="PrevArrow" />
                                  </div>
                                  <div
                                    className="col-2 greenFont semiBold heading4 padding0L"
                                    style={{
                                      textAlign: "right",
                                      paddingTop: "0.3rem",
                                      cursor:
                                        offset > 0 ? "pointer" : "no-drop",
                                      opacity: offset > 0 ? "1" : "0.5",
                                    }}
                                    onClick={handlePrev}
                                  >
                                    Previous
                                  </div>
                                  <div className="col-4 row margin0 justify-content-center p-0">
                                    <div
                                      className="greenBoxCount Medium heading4 col-4"
                                      style={{
                                        width: "2rem",
                                        height: "2rem",
                                        alignItems: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      {offset / 10 + 1}
                                    </div>
                                    <div
                                      className=" col- padding0R"
                                      style={{
                                        paddingTop: "0.3rem",
                                        textAlign: "right",
                                      }}
                                    >
                                      of{" "}
                                      {vehCount % 10 == 0
                                        ? Math.floor(vehCount / 10)
                                        : Math.floor(vehCount / 10 + 1)}
                                    </div>
                                  </div>
                                  <div
                                    className="col-2 greenFont semiBold heading4 padding0R"
                                    style={{
                                      textAlign: "left",
                                      paddingTop: "0.3rem",
                                      cursor:
                                        vehCount - offset > 10
                                          ? "pointer"
                                          : "no-drop",
                                      opacity:
                                        vehCount - offset > 10 ? "1" : "0.5",
                                    }}
                                    onClick={handleNext}
                                  >
                                    Next
                                  </div>
                                  <div
                                    className="col-2 padding0L"
                                    style={{
                                      textAlign: "left",
                                      // marginLeft: "-1rem",
                                      paddingTop: "0.1rem",
                                      cursor:
                                        vehCount - offset > 10
                                          ? "pointer"
                                          : "no-drop",
                                      opacity:
                                        vehCount - offset > 10 ? "1" : "0.5",
                                    }}
                                    onClick={handleNext}
                                  >
                                    <SvgImage name="NextArrow" />
                                  </div>
                                </div>
                                {vehCount == 0 ? (
                                  <div className="heading3 semiBold padding16 padding24B">
                                    No Vehicle Available
                                  </div>
                                ) : fastVeh == undefined ? (
                                  <div className="heading3 semiBold padding16 padding24B">
                                    No Vehicle Available
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}

                            <div style={{ color: "red" }}>
                              {errors.fleetId && touched.fleetId ? (
                                <div>*{errors.fleetId}</div>
                              ) : null}
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup
                          style={{
                            marginRight: "110px",
                          }}
                        >
                          <label for="vrn" className="heading3 semiBold">
                            Driver:
                          </label>
                          <div
                            className="custNamepadd3"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                            onClick={() => {
                              setOpen1(true);
                            }}
                          >
                            <input
                              className={`inputBoxCust1 ${
                                touched.amount && errors.amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="text"
                              id="driver"
                              name="driver"
                              touched={touched.driver}
                              errors={errors.driver}
                              onBlur={handleBlur("driver")}
                              onChange={(event) => {
                                setSearchDriver(event.target.value);
                                setFieldValue(
                                  "driver",
                                  event.target.value,
                                  true
                                );
                                if (event.target.value == "") {
                                  setFieldValue("userId", "", true);
                                }
                              }}
                              value={values.driver}
                              autoFocus
                              placeholder="Select Driver"
                              autoComplete={"off"}
                            />
                            <div
                              className={`inputBoxCust2`}
                              id="addDriver"
                              onClick={addDriver}
                              style={{ cursor: "pointer", marginLeft: 12 }}
                            >
                              +
                            </div>
                          </div>
                          <div style={{ position: "relative" }}>
                            {open1 ? (
                              <div className="driverSearchContainer2 containerShadow">
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0.3rem",
                                    right: "0.3rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setOpen1(false);
                                  }}
                                >
                                  <SvgImage name="CloseButtonLogo" />
                                </div>
                                {driverList?.map((item, i) => {
                                  return (
                                    <>
                                      {item?.Fastag !== null ? (
                                        <div
                                          key={i}
                                          className="flexStyling padding8 "
                                          onClick={(event) => {
                                            setOpen1(false);
                                            setFieldValue(
                                              "driver",
                                              item?.User?.fullName,
                                              true
                                            );
                                            console.log("itemadas", item);
                                            setFieldValue(
                                              "userId",
                                              item?.User?.userId,
                                              true
                                            );
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name="driver"
                                            value={item?.User?.userId}
                                            id={item?.User?.userId}
                                            autoComplete={"off"}
                                          />
                                          <div className="padding16L">
                                            {item?.User?.fullName.length > 35
                                              ? item?.User?.fullName.split(
                                                  0,
                                                  35
                                                ) + "..."
                                              : item?.User?.fullName}
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                })}
                                {driverCount == 0 ? (
                                  <div className="heading3 semiBold padding16 padding24B">
                                    No driver Available
                                  </div>
                                ) : driverCount == undefined ? (
                                  <div className="heading3 semiBold padding16 padding24B">
                                    No driver Available
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div style={{ color: "red" }}>
                              {errors.userId && touched.userId ? (
                                <div>*{errors.userId}</div>
                              ) : null}
                            </div>
                          </div>
                        </FormGroup>
                      </FormGroup>

                      <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormGroup>
                          <label for="from" className="heading3 semiBold">
                            From
                          </label>
                          <div
                            className="custNamepadd3"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "flex-start",
                              // marginTop: "30px",
                            }}
                            // onClick={() => {
                            //   setOpen1(true);
                            // }}
                          >
                            <input
                              type="text"
                              name="sourceAddress"
                              id="sourceAddress"
                              placeholder={"Source City"}
                              className={`inputBoxCust1 ${
                                touched.sourceAddress && errors.sourceAddress
                                  ? "is-invalid"
                                  : ""
                              }`}
                              aria-label="Default select example"
                              onChange={(e) => {
                                setDisplay1(true);
                                setSearch1(e.target.value);
                                setFieldValue(
                                  "sourceAddress",
                                  e.target.value,
                                  true
                                );
                              }}
                              onClick={() => {
                                setDisplay1(true);
                              }}
                              value={values.sourceAddress}
                              onBlur={handleBlur("sourceAddress")}
                              errors={errors.sourceAddress}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  findSearch1();
                                }
                              }}
                              autoComplete={"off"}
                            ></input>
                            <div
                              className={`inputBoxCust2`}
                              onClick={addAddress}
                              style={{ cursor: "pointer", marginLeft: 12 }}
                            >
                              +
                            </div>
                          </div>

                          {display1 ? (
                            <div
                              className="padding16 newCashback whiteBackground"
                              style={{
                                position: "absolute",
                                width: "350px",
                                height: "275px",
                                zIndex: "90",
                              }}
                            >
                              <div
                                onClick={() => {
                                  setDisplay1(false);
                                  // setSearch1("");
                                  //dispatch(clearAllDriversByVrn());
                                  // setFieldValue("sourceAddress", "", true);
                                }}
                                style={{
                                  position: "absolute",
                                  right: "0.5rem",
                                  top: "0.5rem",
                                  cursor: "pointer",
                                }}
                              >
                                <SvgImage name="CloseButtonLogo" />
                              </div>
                              <div
                                style={{
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  height: "240px",
                                }}
                              >
                                {console.log(
                                  "savedAddresses11111",
                                  search1,
                                  savedAddresses
                                )}
                                {search1 == "" ? (
                                  savedAddresses?.map((data, i) => {
                                    let city = data?.cityName;
                                    let state = data?.stateName;
                                    let address = data?.address;
                                    console.log("savedAddresses", data);
                                    return (
                                      <div
                                        key={i}
                                        className="padding8 row"
                                        onClick={() => {
                                          // setcityId1(data?.cityName);
                                          setColor1(i);
                                          setDisplay1(false);
                                          //setUserId(data?.User?.userId);
                                          setFieldValue(
                                            "sourceAddress",
                                            `${city}`,
                                            true
                                          );
                                          setFieldValue(
                                            "source",
                                            data?.addressId,
                                            true
                                          );
                                        }}
                                      >
                                        {color1 === i ? (
                                          <div className="col-1">
                                            <SvgImage name="SelectedRadio" />
                                          </div>
                                        ) : (
                                          <div className="col-1">
                                            <SvgImage name="UnselectedRadio" />
                                          </div>
                                        )}
                                        {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                        <div
                                          className="col-11"
                                          style={{ textAlign: "left" }}
                                        >
                                          {city} - {address}
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <>
                                    {savedAddresses?.map((data, i) => {
                                      let city = data?.cityName;
                                      let state = data?.stateName;
                                      let address = data?.address;
                                      return (
                                        <div
                                          key={i}
                                          className="padding8 row"
                                          onClick={() => {
                                            // setcityId1(data?.cityName);
                                            setColor1(i);
                                            setDisplay1(false);
                                            //setUserId(data?.User?.userId);
                                            setFieldValue(
                                              "sourceAddress",
                                              `${city}`,
                                              true
                                            );
                                            setFieldValue(
                                              "source",
                                              data?.addressId,
                                              true
                                            );
                                          }}
                                        >
                                          {color1 === i ? (
                                            <div className="col-1">
                                              <SvgImage name="SelectedRadio" />
                                            </div>
                                          ) : (
                                            <div className="col-1">
                                              <SvgImage name="UnselectedRadio" />
                                            </div>
                                          )}
                                          {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                          <div
                                            className="col-11"
                                            style={{ textAlign: "left" }}
                                          >
                                            {city} - {address}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                                {savedAddresses?.length == 0 ? (
                                  <div className="">
                                    <div style={{ margin: "24px" }}>
                                      You have no addresses to select please add
                                      a new one
                                    </div>
                                    <div className="d-flex justify-content-center">
                                      <div
                                        className="greenButton d-flex justify-content-center align-items-center"
                                        style={{
                                          width: "200px",
                                          cursor: "pointer",
                                        }}
                                        onClick={addAddress}
                                      >
                                        <div>Add New Address</div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div style={{ color: "red" }}>
                            {errors.sourceAddress && touched.sourceAddress ? (
                              <div>*{errors.sourceAddress}</div>
                            ) : null}
                          </div>
                        </FormGroup>

                        <FormGroup style={{ marginRight: "110px" }}>
                          <label for="to" className="heading3 semiBold">
                            To
                          </label>
                          <div
                            className="custNamepadd3"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "flex-start",
                              // marginTop: "30px",
                            }}
                          >
                            <input
                              type="text"
                              name="destAddress"
                              id="destAddress"
                              placeholder={"Destination City"}
                              className={`inputBoxCust1 ${
                                touched.destAddress && errors.destAddress
                                  ? "is-invalid"
                                  : ""
                              }`}
                              aria-label="Default select example"
                              onChange={(e) => {
                                setDisplay2(true);
                                setSearch2(e.target.value);
                                setFieldValue(
                                  "destAddress",
                                  e.target.value,
                                  true
                                );
                              }}
                              onClick={() => {
                                setDisplay2(true);
                              }}
                              value={values.destAddress}
                              onBlur={handleBlur("destAddress")}
                              errors={errors.destAddress}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  findSearch2();
                                }
                              }}
                              autoComplete={"off"}
                            ></input>
                            <div
                              className={`inputBoxCust2`}
                              onClick={addAddress}
                              style={{ cursor: "pointer", marginLeft: 12 }}
                            >
                              +
                            </div>
                          </div>

                          {display2 ? (
                            <div
                              className="padding16 newCashback whiteBackground"
                              style={{
                                position: "absolute",
                                width: "350px",
                                height: "275px",
                                zIndex: "90",
                              }}
                            >
                              <div
                                onClick={() => {
                                  setDisplay2(false);
                                  // setSearch2("");
                                  //dispatch(clearAllDriversByVrn());
                                  // setFieldValue("destAddress", "", true);
                                }}
                                style={{
                                  position: "absolute",
                                  right: "0.5rem",
                                  top: "0.5rem",
                                  cursor: "pointer",
                                }}
                              >
                                <SvgImage name="CloseButtonLogo" />
                              </div>
                              <div
                                style={{
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  height: "240px",
                                }}
                              >
                                {search2 == "" ? (
                                  savedAddresses?.map((data, i) => {
                                    let city = data?.cityName;
                                    let state = data?.stateName;
                                    let address = data?.address;
                                    return (
                                      <div
                                        key={i}
                                        className="padding8 row"
                                        onClick={() => {
                                          // setcityId2(data?.cityName);
                                          setColor2(i);
                                          setDisplay2(false);
                                          //setUserId(data?.User?.userId);
                                          setFieldValue(
                                            "destAddress",
                                            data?.cityName,
                                            true
                                          );
                                          setFieldValue(
                                            "destination",
                                            data?.addressId,
                                            true
                                          );
                                        }}
                                      >
                                        {color2 === i ? (
                                          <div className="col-1">
                                            <SvgImage name="SelectedRadio" />
                                          </div>
                                        ) : (
                                          <div className="col-1">
                                            <SvgImage name="UnselectedRadio" />
                                          </div>
                                        )}
                                        {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                        <div
                                          className="col-11"
                                          style={{ textAlign: "left" }}
                                        >
                                          {city} - {address}
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <>
                                    {savedAddresses?.map((data, i) => {
                                      let city = data?.cityName;
                                      let state = data?.stateName;
                                      let address = data?.address;
                                      console.log(
                                        "savedAddresses",
                                        city,
                                        state,
                                        address
                                      );
                                      return (
                                        <div
                                          key={i}
                                          className="padding8 row"
                                          onClick={() => {
                                            // setcityId2(data?.cityName);
                                            setColor2(i);
                                            setDisplay2(false);
                                            //setUserId(data?.User?.userId);
                                            setFieldValue(
                                              "destination",
                                              data?.addressId,
                                              true
                                            );
                                            setFieldValue(
                                              "destAddress",
                                              `${city}`,
                                              true
                                            );
                                          }}
                                        >
                                          {color2 === i ? (
                                            <div className="col-1">
                                              <SvgImage name="SelectedRadio" />
                                            </div>
                                          ) : (
                                            <div className="col-1">
                                              <SvgImage name="UnselectedRadio" />
                                            </div>
                                          )}
                                          <div
                                            className="col-11"
                                            style={{ textAlign: "left" }}
                                          >
                                            {city} - {address}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                                {savedAddresses?.length == 0 ? (
                                  <div className="">
                                    <div style={{ margin: "24px" }}>
                                      You have no addresses to select please add
                                      a new one
                                    </div>
                                    <div className="d-flex justify-content-center">
                                      <div
                                        className="greenButton d-flex justify-content-center align-items-center"
                                        style={{
                                          width: "200px",
                                          cursor: "pointer",
                                        }}
                                        onClick={addAddress}
                                      >
                                        <div>Add New Address</div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div style={{ color: "red" }}>
                            {errors.destAddress && touched.destAddress ? (
                              <div>*{errors.destAddress}</div>
                            ) : null}
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  ) : (
                    <div>
                      {!isEdit ? (
                        <div
                          style={{
                            marginTop: "-20px",
                            marginBottom: "20px",
                            fontWeight: 200,
                          }}
                        >
                          {/* <div className="d-flex justify-content-between">
                            <div style={{ width: "25%" }}>
                              <label className="heading3 semiBold">
                                Transporter :
                              </label>
                              <div style={{ padding: "5px" }}>
                                {values.transportorName || "NA"}
                              </div>
                            </div>
                            <div style={{ width: "25%" }}>
                              <label className="heading3 semiBold">
                                Consignor :
                              </label>
                              <div style={{ padding: "5px" }}>
                                {values.consignorName || "NA"}
                              </div>
                            </div>
                            <div style={{ width: "25%" }}>
                              <label className="heading3 semiBold">
                                Consignee :
                              </label>
                              <div style={{ padding: "5px" }}>
                                {values.consigneeName || "NA"}
                              </div>
                            </div>
                            <div style={{ width: "25%" }}>
                              <label className="heading3 semiBold">
                                Payor :
                              </label>
                              <div style={{ padding: "5px" }}>
                                {values.payorType || "NA"}
                              </div>
                            </div>
                          </div>
                          <div
                            className="d-flex justify-content-between"
                            style={{ marginTop: "16px" }}
                          >
                            <div style={{ width: "25%" }}>
                              <label className="heading3 semiBold">
                                From :
                              </label>
                              <div style={{ padding: "5px" }}>
                                {values.sourceAddress || "NA"}
                              </div>
                            </div>
                            <div style={{ width: "25%" }}>
                              <label className="heading3 semiBold">To :</label>
                              <div style={{ padding: "5px" }}>
                                {values.destAddress || "NA"}
                              </div>
                            </div>
                            <div style={{ width: "25%" }}>
                              <label className="heading3 semiBold">
                                Vehicle :
                              </label>
                              <div style={{ padding: "5px" }}>
                                {values.vrn || "NA"}
                              </div>
                            </div>
                            <div style={{ width: "25%" }}>
                              <label className="heading3 semiBold">
                                Driver :
                              </label>
                              <div style={{ padding: "5px" }}>
                                {values.driver || "NA"}
                              </div>
                            </div>
                          </div> */}
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormGroup
                          style={{
                            alignItems: "right",
                          }}
                        >
                          {console.log("WeightWeightWeightWeight", values)}
                          <label
                            for="weight"
                            className="heading3 semiBold col-12"
                          >
                            Weight:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              type="text"
                              id="weight"
                              className={`inputBoxCust1 ${
                                touched.weight && errors.weight
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="weight"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "weight",
                                  event.target.value,
                                  true
                                );
                              }}
                              value={values.weight}
                              touched={touched.weight}
                              errors={errors.weight}
                              placeholder="Tonnage"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup
                          style={{
                            alignItems: "right",
                          }}
                        >
                          <label
                            for="amount"
                            className="heading3 semiBold col-12"
                          >
                            Amount
                          </label>
                          <div
                            className="custNamepadd3"
                            style={{ marginRight: "110px" }}
                          >
                            <input
                              type="text"
                              id="amount"
                              className={`inputBoxCust1 ${
                                touched.amount && errors.amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="lrNo"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "amount",
                                  event.target.value,
                                  true
                                );
                              }}
                              value={values.amount}
                              touched={touched.amount}
                              errors={errors.amount}
                              placeholder="Rupee"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>
                      </FormGroup> */}
                      <div>
                        <Table
                          className="TripSectionTable"
                          bordered="true"
                          id="driver_tables" //
                          // style={{ marginTop: "16px" }}
                        >
                          <thead>
                            <tr className="table-header-trip p-0 align-middle text-center">
                              <th className="p-0" style={{ width: "7%" }}>
                                <div style={{ marginLeft: "10px" }}>S.No</div>
                              </th>
                              <th
                                className="p-0"
                                style={{
                                  width: "30%",
                                  textAlignLast: "center",
                                }}
                              >
                                <div>Description</div>{" "}
                              </th>
                              <th
                                className="p-0"
                                style={{
                                  width: "16%",
                                  textAlignLast: "center",
                                }}
                              >
                                Quantity
                              </th>
                              <th
                                className="p-0"
                                style={{
                                  width: "20%",
                                  textAlignLast: "center",
                                }}
                              >
                                Weight/Quantity (kgs)
                              </th>
                              <th
                                className="p-0"
                                style={{
                                  width: "20%",
                                  textAlignLast: "center",
                                }}
                              >
                                Amount/Quantity
                              </th>
                              <th
                                className="p-0"
                                style={{
                                  width: "14%",
                                  textAlignLast: "center",
                                }}
                              >
                                Total Amount
                              </th>
                              <th
                                className="p-0"
                                style={{ width: "5%", textAlignLast: "center" }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody className="lowerBorderLineCust">
                            {formValues?.map((element, index) => (
                              <tr
                                className="table-row-manage-trip-new text4 align-middle text-center p-0"
                                id="addr0"
                                key={index}
                                onClick={() => {
                                  // window.location = "/Customers/" + 1;
                                  //   navigate("/Customers/" + 1);
                                }}
                              >
                                <td
                                  style={{
                                    width: "7%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                  }}
                                  className="p-0"
                                >
                                  <div style={{ marginLeft: "10px" }}>
                                    {index + 1}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    width: "30%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                  }}
                                  className="p-0"
                                >
                                  <input
                                    type="text"
                                    className="minBox3"
                                    name="desc"
                                    value={element.desc || ""}
                                    onChange={(e) =>
                                      handleChangeField(index, e)
                                    }
                                  />
                                </td>
                                <td
                                  style={{
                                    width: "16%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                  }}
                                  className="p-0"
                                >
                                  <input
                                    type="text"
                                    className="minBox3"
                                    name="quantity"
                                    value={element.quantity || ""}
                                    onChange={(e) =>
                                      handleChangeField(index, e)
                                    }
                                  />
                                </td>
                                <td
                                  style={{
                                    width: "20%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                  }}
                                  className="p-0"
                                >
                                  <input
                                    type="text"
                                    className="minBox3"
                                    name="weightPerQuantity"
                                    value={element.weightPerQuantity || ""}
                                    onChange={(e) =>
                                      handleChangeField(index, e)
                                    }
                                  />
                                </td>
                                <td
                                  style={{
                                    width: "16%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                  }}
                                  className="p-0"
                                >
                                  <input
                                    type="text"
                                    className="minBox3"
                                    name="amountPerQuantity"
                                    value={element.amountPerQuantity || ""}
                                    onChange={(e) => {
                                      handleChangeField(index, e);
                                    }}
                                  />
                                </td>
                                <td
                                  style={{
                                    width: "14%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                    textAlignLast: "center",
                                  }}
                                  className="p-0"
                                >
                                  {element.amountPerQuantity && element.quantity
                                    ? `₹${
                                        parseFloat(element.amountPerQuantity) *
                                        parseFloat(element.quantity)
                                      }`
                                    : `₹0`}
                                </td>
                                <td
                                  style={{
                                    width: "5%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                  }}
                                  className="p-0"
                                  onClick={() => {
                                    removeFormFields(index);
                                  }}
                                >
                                  <SvgImage name="deleteIcon2" />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-center">
                          <div
                            className="addItemDiv"
                            style={{ width: "100%" }}
                            onClick={addFormFields}
                          >
                            + Add Item
                          </div>
                        </div>
                      </div>
                      <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "20px",
                        }}
                      >
                        <FormGroup>
                          <label for="lrUrl" className="heading3 semiBold">
                            LR Copy
                          </label>
                          <div style={{ display: "flex" }}>
                            {!status ? (
                              <p
                                className="heading4 Regular"
                                style={{ margin: "10px 0 5px" }}
                              >
                                Upload Document
                              </p>
                            ) : (
                              <></>
                            )}
                            {!status ? (
                              <Button
                                className="add-attach-for-fastag-linked-vehicle-new"
                                type="button"
                              >
                                {imageLoader ? (
                                  <div style={{ zIndex: 10 }}>
                                    <ClipLoader
                                      color={"#2E7C7B"}
                                      loading={true}
                                      size={20}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <SvgImage
                                      name="AddAttachment"
                                      height="25"
                                      width="25"
                                    />
                                    <input
                                      type="file"
                                      style={{ width: "25px" }}
                                      // multiple
                                      // accept="image/*"
                                      accept="image/*"
                                      className="add-lr-attachment"
                                      onChange={(e) => {
                                        const img = e?.target.files[0];
                                        console.log("image", img?.name);

                                        const tempArray = Object.assign(
                                          [],
                                          values.docs
                                        );
                                        console.log("sel", tempArray);

                                        if (img == null) {
                                          //clear image
                                          setFieldValue("lrUrl", "", true);
                                          //for now  only one supported
                                        } else {
                                          console.log("upload", {
                                            img: img,
                                            uploadType: "aadharFront",
                                            isRes: true,
                                          });
                                          setImageLoader(true);
                                          dispatch(
                                            storeMedia(
                                              {
                                                img: img,
                                                uploadType: "aadharFront",
                                                isRes: false,
                                              },
                                              (res) => {
                                                setStatus(true);
                                                setDisplayName((prev) => [
                                                  { name: img?.name },
                                                ]);
                                                setImageLoader(false);
                                                if (res?.url) {
                                                  console.log("opt", res);
                                                  tempArray.push(
                                                    res?.data?.Location
                                                  );
                                                  setFieldValue(
                                                    "lrUrl",
                                                    res?.url,
                                                    true
                                                  );
                                                  // setFieldValue('docs', tempArray, true);
                                                  //for now  only one supported
                                                  setFieldValue(
                                                    "docs",
                                                    [res?.data?.Location],
                                                    true
                                                  );
                                                }
                                              }
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                                <div
                                  style={{
                                    margin: "0px -16px 2px -614px",
                                    color: "red",
                                  }}
                                >
                                  {errors.lrUrl && touched.lrUrl ? (
                                    <div>*{errors.lrUrl}</div>
                                  ) : null}
                                </div>
                              </Button>
                            ) : (
                              <></>
                            )}
                            <div className="selected-img">
                              {status ? (
                                displayName?.map((d) => {
                                  return (
                                    <div className="row">
                                      <div className="col-9">
                                        <p
                                          style={{
                                            color: "black",
                                            margin: "5px 0 0",
                                            // padding: "0",
                                          }}
                                        >
                                          {d.name}
                                        </p>
                                      </div>
                                      <div
                                        className="col-2"
                                        onClick={() => {
                                          setStatus(false);
                                        }}
                                      >
                                        <text>Change</text>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <label for="vrn" className="heading3 semiBold">
                            Date
                          </label>
                          <div
                            className="custNamepadd3 row"
                            style={{ width: "200px" }}
                          >
                            <div className="col-9">
                              <DatePickerField
                                classname="searchBar-input1"
                                name="startDate"
                                header="Select Start date"
                                value={values.startDate}
                                onChange={(v) => {
                                  setFieldValue("startDate", v, true);
                                }}
                                //onChange={(v) => setFieldValue("endDate", v, true)}
                                maxDateDate={
                                  values.endDate === "" ? "" : values.endDate
                                }
                                // maxDate={new Date()}
                                ref1={datepickerRefEnd}
                                style={{ width: "100px" }}
                              />
                            </div>
                            <div className="col-3">
                              <label
                                className="date-input"
                                for="startDate"
                                style={{
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleClickDatepickerIcon2();
                                }}
                              >
                                <SvgImage
                                  name="CalenderLogo"
                                  width="32"
                                  height="32"
                                  // classname="date-logo"
                                />
                              </label>
                            </div>
                          </div>
                          <div
                            className="heading4 Regular"
                            style={{ margin: "7px 0 0px 0", color: "red" }}
                          >
                            {errors.startDate && touched.startDate ? (
                              <div>*{errors.startDate}</div>
                            ) : null}
                          </div>
                        </FormGroup>
                        <FormGroup style={{ marginRight: "40px" }}>
                          <label for="invoiceUrl" className="heading3 semiBold">
                            Invoice copy
                          </label>
                          <div style={{ display: "flex" }}>
                            {!status2 ? (
                              <p
                                className="heading4 Regular"
                                style={{ margin: "10px 0 5px" }}
                              >
                                Upload Document
                              </p>
                            ) : (
                              <></>
                            )}
                            {!status2 ? (
                              <Button
                                className="add-attach-for-fastag-linked-vehicle-new"
                                type="button"
                              >
                                {imageLoader2 ? (
                                  <div style={{ zIndex: 10 }}>
                                    <ClipLoader
                                      color={"#2E7C7B"}
                                      loading={true}
                                      size={20}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <SvgImage
                                      name="AddAttachment"
                                      height="25"
                                      width="25"
                                    />
                                    <input
                                      type="file"
                                      style={{ width: "25px" }}
                                      // multiple
                                      // accept="image/*"
                                      accept="image/*"
                                      className="add-lr-attachment"
                                      onChange={(e) => {
                                        const img = e?.target.files[0];
                                        console.log("image", img?.name);

                                        const tempArray = Object.assign(
                                          [],
                                          values.docs
                                        );
                                        console.log("sel", tempArray);

                                        if (img == null) {
                                          //clear image
                                          setFieldValue("invoiceUrl", "", true);
                                          //for now  only one supported
                                        } else {
                                          console.log("upload", {
                                            img: img,
                                            uploadType: "invoiceUrl",
                                            isRes: true,
                                          });
                                          setImageLoader2(true);
                                          dispatch(
                                            storeMedia(
                                              {
                                                img: img,
                                                uploadType: "invoiceUrl",
                                                isRes: false,
                                              },
                                              (res) => {
                                                setStatus2(true);
                                                setDisplayName2((prev) => [
                                                  { name: img?.name },
                                                ]);
                                                setImageLoader2(false);
                                                if (res?.url) {
                                                  console.log("opt", res);
                                                  tempArray.push(
                                                    res?.data?.Location
                                                  );
                                                  setFieldValue(
                                                    "invoiceUrl",
                                                    res?.url,
                                                    true
                                                  );
                                                  // setFieldValue('docs', tempArray, true);
                                                  //for now  only one supported
                                                }
                                              }
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                                <div
                                  style={{
                                    margin: "0px -16px 2px -614px",
                                    color: "red",
                                  }}
                                >
                                  {errors.invoiceUrl && touched.invoiceUrl ? (
                                    <div>*{errors.invoiceUrl}</div>
                                  ) : null}
                                </div>
                              </Button>
                            ) : (
                              <></>
                            )}
                            <div className="selected-img">
                              {status2 ? (
                                displayName2?.map((d) => {
                                  return (
                                    <div className="row">
                                      <div className="col-9">
                                        <p
                                          style={{
                                            color: "black",
                                            margin: "5px 0 0",
                                            // padding: "0",
                                          }}
                                        >
                                          {d.name}
                                        </p>
                                      </div>
                                      <div
                                        className="col-2"
                                        onClick={() => {
                                          setStatus2(false);
                                        }}
                                      >
                                        <text>Change</text>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </FormGroup>
                      </FormGroup>
                      {isEdit ? (
                        <div>
                          {/* <FormGroup
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormGroup style={{}}>
                              <label
                                for="discount"
                                className="heading3 semiBold col-12"
                              >
                                Discount:
                              </label>
                              <div className="custNamepadd3">
                                <input
                                  type="text"
                                  id="discount"
                                  className={`inputBoxCust1 ${
                                    touched.discount && errors.discount
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="discount"
                                  onChange={(event) => {
                                    // setSearch(event.target.value);
                                    setFieldValue(
                                      "discount",
                                      event.target.value,
                                      true
                                    );
                                  }}
                                  value={values.discount}
                                  touched={touched.discount}
                                  errors={errors.discount}
                                  placeholder="Discount"
                                  autoComplete={"off"}
                                />
                              </div>
                            </FormGroup>

                            <FormGroup style={{}}>
                              <label
                                for="discount"
                                className="heading3 semiBold col-12"
                              >
                                Discount Remarks:
                              </label>
                              <div className="custNamepadd3">
                                <input
                                  type="text"
                                  id="discountRemarks"
                                  className={`inputBoxCust1 ${
                                    touched.discount && errors.discount
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="discountRemarks"
                                  onChange={(event) => {
                                    // setSearch(event.target.value);
                                    setFieldValue(
                                      "discountRemarks",
                                      event.target.value,
                                      true
                                    );
                                  }}
                                  value={values.discountRemarks}
                                  touched={touched.discountRemarks}
                                  errors={errors.discountRemarks}
                                  placeholder="Discount Remarks"
                                  autoComplete={"off"}
                                />
                              </div>
                            </FormGroup>
                            <FormGroup style={{}}>
                              <label
                                for="charges"
                                className="heading3 semiBold col-12"
                              >
                                Charges:
                              </label>
                              <div className="custNamepadd3">
                                <input
                                  type="text"
                                  id="charges"
                                  className={`inputBoxCust1 ${
                                    touched.charges && errors.charges
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="charges"
                                  onChange={(event) => {
                                    // setSearch(event.target.value);
                                    setFieldValue(
                                      "charges",
                                      event.target.value,
                                      true
                                    );
                                  }}
                                  value={values.charges}
                                  touched={touched.charges}
                                  errors={errors.charges}
                                  placeholder="Extra Charges"
                                  autoComplete={"off"}
                                />
                              </div>
                            </FormGroup>
                          </FormGroup> */}
                          <FormGroup
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* <FormGroup style={{}}>
                              <label
                                for="chargesRemarks"
                                className="heading3 semiBold col-12"
                              >
                                Charge Remarks:
                              </label>
                              <div className="custNamepadd3">
                                <input
                                  type="text"
                                  id="chargesRemarks"
                                  className={`inputBoxCust1 ${
                                    touched.chargesRemarks &&
                                    errors.chargesRemarks
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="chargesRemarks"
                                  onChange={(event) => {
                                    // setSearch(event.target.value);
                                    setFieldValue(
                                      "chargesRemarks",
                                      event.target.value,
                                      true
                                    );
                                  }}
                                  value={values.chargesRemarks}
                                  touched={touched.chargesRemarks}
                                  errors={errors.chargesRemarks}
                                  placeholder="Charge Remarks"
                                  autoComplete={"off"}
                                />
                              </div>
                            </FormGroup> */}

                            {/* <FormGroup>
                              <label for="vrn" className="heading3 semiBold">
                                End Date
                              </label>
                              <div
                                className="custNamepadd3 row"
                                style={{ width: "200px" }}
                              >
                                <div className="col-9">
                                  <DatePickerField
                                    classname="searchBar-input1"
                                    name="endtDate"
                                    header="Select End date"
                                    value={values.endDate}
                                    onChange={(v) => {
                                      setFieldValue("endDate", v, true);
                                    }}
                                    //onChange={(v) => setFieldValue("endDate", v, true)}
                                    minDate={
                                      values.startDate === ""
                                        ? ""
                                        : values.startDate
                                    }
                                    // maxDate={new Date()}
                                    ref1={datepickerRefStart}
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div className="col-3">
                                  <label
                                    className="date-input"
                                    for="endDate"
                                    style={{
                                      position: "relative",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleClickDatepickerIcon1();
                                    }}
                                  >
                                    <SvgImage
                                      name="CalenderLogo"
                                      width="32"
                                      height="32"
                                      // classname="date-logo"
                                    />
                                  </label>
                                </div>
                              </div>
                              <div
                                className="heading4 Regular"
                                style={{ margin: "7px 0 0px 0", color: "red" }}
                              >
                                {errors.endDate && touched.endDate ? (
                                  <div>*{errors.endDate}</div>
                                ) : null}
                              </div>
                            </FormGroup> */}

                            {/* <FormGroup style={{}}>
                              <label
                                for="discount"
                                className="heading3 semiBold col-12"
                              >
                                Discount:
                              </label>
                              <div className="custNamepadd3">
                                <input
                                  type="text"
                                  id="discount"
                                  className={`inputBoxCust1 ${
                                    touched.discount && errors.discount
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="discount"
                                  onChange={(event) => {
                                    // setSearch(event.target.value);
                                    setFieldValue(
                                      "discount",
                                      event.target.value,
                                      true
                                    );
                                  }}
                                  value={values.discount}
                                  touched={touched.discount}
                                  errors={errors.discount}
                                  placeholder="Discount"
                                  autoComplete={"off"}
                                />
                              </div>
                            </FormGroup> */}
                          </FormGroup>
                          <FormGroup
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* <FormGroup style={{}}>
                              <label
                                for="discount"
                                className="heading3 semiBold col-12"
                              >
                                Discount Remarks:
                              </label>
                              <div className="custNamepadd3">
                                <input
                                  type="text"
                                  id="discountRemarks"
                                  className={`inputBoxCust1 ${
                                    touched.discount && errors.discount
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="discountRemarks"
                                  onChange={(event) => {
                                    // setSearch(event.target.value);
                                    setFieldValue(
                                      "discountRemarks",
                                      event.target.value,
                                      true
                                    );
                                  }}
                                  value={values.discountRemarks}
                                  touched={touched.discountRemarks}
                                  errors={errors.discountRemarks}
                                  placeholder="Discount Remarks"
                                  autoComplete={"off"}
                                />
                              </div>
                            </FormGroup> */}
                            {/* <FormGroup>
                              <div>
                                <div className="heading3 semiBold">Charges</div>
                                <Table
                                  className="TripSectionTable"
                                  bordered="true"
                                  id="driver_tables" //
                                  // style={{ marginTop: "16px" }}
                                >
                                  <thead>
                                    <tr className="table-header-trip p-0 align-middle text-center">
                                      <th
                                        className="p-0"
                                        style={{ width: "7%" }}
                                      >
                                        <div style={{ marginLeft: "10px" }}>
                                          S.No
                                        </div>
                                      </th>
                                      <th
                                        className="p-0"
                                        style={{
                                          width: "30%",
                                          textAlignLast: "center",
                                        }}
                                      >
                                        <div>Remarks</div>{" "}
                                      </th>
                                      <th
                                        className="p-0"
                                        style={{
                                          width: "16%",
                                          textAlignLast: "center",
                                        }}
                                      >
                                        Amount
                                      </th>
                                      <th
                                        className="p-0"
                                        style={{
                                          width: "5%",
                                          textAlignLast: "center",
                                        }}
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody className="lowerBorderLineCust">
                                    {chargesFormValues?.map(
                                      (element, index) => (
                                        <tr
                                          className="table-row-manage-trip-new text4 align-middle text-center p-0"
                                          id="addr0"
                                          key={index}
                                          onClick={() => {
                                            // window.location = "/Customers/" + 1;
                                            //   navigate("/Customers/" + 1);
                                          }}
                                        >
                                          <td
                                            style={{
                                              width: "7%",
                                              borderLeft:
                                                "1px solid transparent",
                                              borderRight:
                                                " 1px solid transparent",
                                            }}
                                            className="p-0"
                                          >
                                            <div style={{ marginLeft: "10px" }}>
                                              {index + 1}
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              width: "30%",
                                              borderLeft:
                                                "1px solid transparent",
                                              borderRight:
                                                " 1px solid transparent",
                                            }}
                                            className="p-0"
                                          >
                                            <input
                                              type="text"
                                              className="minBox3"
                                              name="remarks"
                                              value={element.remarks || ""}
                                              onChange={(e) =>
                                                handleChangeFieldCharges(
                                                  index,
                                                  e
                                                )
                                              }
                                            />
                                          </td>
                                          <td
                                            style={{
                                              width: "16%",
                                              borderLeft:
                                                "1px solid transparent",
                                              borderRight:
                                                " 1px solid transparent",
                                            }}
                                            className="p-0"
                                          >
                                            <input
                                              type="number"
                                              className="minBox3"
                                              name="amount"
                                              value={element.amount || ""}
                                              onChange={(e) =>
                                                handleChangeFieldCharges(
                                                  index,
                                                  e
                                                )
                                              }
                                            />
                                          </td>
                                          <td
                                            style={{
                                              width: "5%",
                                              borderLeft:
                                                "1px solid transparent",
                                              borderRight:
                                                " 1px solid transparent",
                                            }}
                                            className="p-0"
                                            onClick={() => {
                                              removeChargesFormFields(index);
                                            }}
                                          >
                                            <SvgImage name="deleteIcon2" />
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="addItemDiv"
                                    style={{ width: "100%" }}
                                    onClick={addFormFieldsCharges}
                                  >
                                    + Add Item
                                  </div>
                                </div>
                              </div>
                            </FormGroup> */}
                          </FormGroup>
                        </div>
                      ) : (
                        <></>
                      )}
                      <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "20px",
                        }}
                      >
                        <FormGroup
                          style={{
                            alignItems: "right",
                          }}
                        >
                          {console.log("WeightWeightWeightWeight", values)}
                          <label
                            for="description"
                            className="heading3 semiBold col-12"
                          >
                            Lr Remarks:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              type="text"
                              id="description"
                              className={`inputBoxCust1 ${
                                touched.description && errors.description
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="description"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "description",
                                  event.target.value,
                                  true
                                );
                              }}
                              value={values.description}
                              touched={touched.description}
                              errors={errors.description}
                              placeholder="Lr Remarks"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup
                          style={{
                            alignItems: "right",
                          }}
                        >
                          <label
                            for="ewayBillNo"
                            className="heading3 semiBold col-12"
                          >
                            Eway Bill No:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              type="text"
                              id="ewayBillNo"
                              className={`inputBoxCust1 ${
                                touched.ewayBillNo && errors.ewayBillNo
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="ewayBillNo"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "ewayBillNo",
                                  event.target.value,
                                  true
                                );
                              }}
                              value={values.ewayBillNo}
                              touched={touched.ewayBillNo}
                              errors={errors.ewayBillNo}
                              placeholder="Eway Bill No"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup
                          style={{
                            alignItems: "right",
                          }}
                        >
                          <label
                            for="valueOfGoods"
                            className="heading3 semiBold col-12"
                          >
                            Value Of Goods:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              type="text"
                              id="valueOfGoods"
                              className={`inputBoxCust1 ${
                                touched.valueOfGoods && errors.valueOfGoods
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="valueOfGoods"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "valueOfGoods",
                                  event.target.value,
                                  true
                                );
                              }}
                              value={values.valueOfGoods}
                              touched={touched.valueOfGoods}
                              errors={errors.valueOfGoods}
                              placeholder="Value Of Goods"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  )}

                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "50px",
                      // marginTop: "50px",
                    }}
                  >
                    <FormGroup>
                      {nextPageOpen ? (
                        <Button
                          style={{ width: "150%" }}
                          className="notGenerateOpt text3 Medium btn btn-secondary"
                          onClick={() => {
                            setNextPageOpen(false);
                          }}
                        >
                          Back
                        </Button>
                      ) : (
                        <Button
                          style={{ width: "150%" }}
                          className="notGenerateOpt text3 Medium btn btn-secondary"
                          onClick={() => {
                            resetForm();
                          }}
                        >
                          Reset
                        </Button>
                      )}
                    </FormGroup>
                    <FormGroup>
                      {nextPageOpen ? (
                        <div>
                          <Button
                            type="submit"
                            style={{ width: "150%" }}
                            className="generateOtp text3 Medium btn btn-secondary"
                          >
                            Submit
                          </Button>
                        </div>
                      ) : (
                        <Button
                          style={{ width: "150%" }}
                          className="generateOtp text3 Medium btn btn-secondary"
                          onClick={() => {
                            console.log(
                              "submitsubmitsubmitsubmit",
                              Object.keys(errors).length > 0,
                              errors,
                              values
                            );
                            if (Object.keys(errors).length > 0) {
                              // setErrors(errors);
                            } else {
                              setNextPageOpen(true);
                            }
                          }}
                        >
                          Next
                        </Button>
                      )}
                    </FormGroup>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      {toastObject.toastStatus &&
      (location == "/lr" || location == "/lr/") &&
      toastObject?.message != "Customer Created Successfully" ? (
        <ToastV2
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            setReload(!reload);
            console.log("nmnnnnnnnnnmmmmppp", toastObject?.message);
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            console.log("nmnnnnnnnnnmmmmppp", toastObject?.message);
            handleClose();
            //  console.log("nmnnnnnnnnnmmmm", toastObject?.message);
          }}
          toggleModal_newFleetError={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            handleClose();
            setNextPageOpen(false);
            console.log("qwertqewrth", toastObject?.message);
          }}
          isOpen={toastObject?.toastStatus}
          name="trips"
          handleClose={() => {
            console.log("nmnnnnnnnnnmm111mm", toastObject?.message, reload);
            setReload(!reload);
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            console.log("reloadreloadreload", reload);
            handleClose();
            console.log("nmnnnnnnnnnmmmm", toastObject?.message);
          }}
        />
      ) : (
        <></>
      )}

      <AddDriverV2 toggle={addDriver} freight={driver} />
      <AddVehicleV2 toggle={addVehicle} freight={vehicle} />
      {customerAdd ? (
        <CreateCustomerV2
          toggle={addCustomer}
          modal={customerAdd}
          setModal={setCustomerAdd}
          // navigatedFrom={"createLRModal"}
        />
      ) : null}

      <AddAddress
        toggle={addAddress}
        address={address}
        setAddress={setAddress}
        location={location}
      />
    </div>
  );
}

export default CreateLRModal;
