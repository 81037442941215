import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Toast,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import AddFreight from "../../../TripsMainV2/Components/AddFreight";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import {
  createTrip,
  getCityAndState,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import { collectPaymentAction } from "../../../../services/Store/Actions/customerAction";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import InvoiceToast from "../../../subComponents/InvoiceToast";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import ManuallyInvoice from "../ManualInvoice";
import TripInvoiceDetail from "../tripInvoiceDetail";
import { useNavigate } from "react-router-dom";
import ToastV2 from "../../../subComponents/toastV2";

function CollectPayment(props) {
  const { toggle, modal, setModal, customerId } = props;
  const [openToast, setOpenToast] = useState(false);
  const [business, setbusiness] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const { customer } = useSelector((state) => state?.customer);
  const { ownerDetails } = useSelector((state) => state?.loginReducer);

  console.log("=======ownerDetails", ownerDetails);
  // setbusiness(ownerDetails?.businessName)
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("amount is required"),
    dueDate: Yup.string().required("Due Date is required"),
    // frequency: Yup.string()
    //   .required("Frequency is required")
    //   .matches(/^\d{1,9}$/, "Days between 1-9"),
  });

  const handleClose = () => {
    setModal(!modal);
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          // style={{ height: "80vh" }}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            style={{ marginTop: " 30px" }}
            //   className="PGCollectionBox"
          >
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Collect Payment{" "}
            </div>

            <Formik
              // isValid='true'
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                amount: "",
                dueDate: "",
                // frequency: "",
              }}
              onSubmit={(values) => {
                console.log("===>>>>init");
                values["customerId"] = customerId;
                let businessName = ownerDetails?.businessName;
                console.log("values----ownerDetails", businessName);
                dispatch(
                  collectPaymentAction(values, (resp) => {
                    console.log("values----resp", resp);
                    console.log("values----resp22", businessName);
                    let msg = `Hello ${customer.companyName}, Pay your due amount of Rs. ${values.amount} to ${businessName} using the below payment link.`;
                    const rediractionLink = `${resp?.linkr}`;
                    dispatch(
                      toastStatus({
                        toastStatus: true,
                        message: msg,
                        status: true,
                        rediractionLink: rediractionLink,
                        mobileNumber: customer?.phone,
                        emailId: customer?.email,
                      })
                    );
                    handleClose();
                  })
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "60px", padding: "0 0px 0 55px" }}
                >
                  <FormGroup>
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Customer Name
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <div className="col-12" style={{ padding: "5px" }}>
                            {customer?.companyName}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            GSTIN
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <div className="col-12" style={{ padding: "5px" }}>
                            {customer?.gstin ? customer?.gstin : "NA"}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Pending Amount
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <div className="col-12" style={{ padding: "5px" }}>
                            ₹{customer?.amount}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "20px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Enter Amount to be Collected
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="amount"
                            className={`inputBoxAddCust ${
                              touched.amount && errors.amount
                                ? "is-invalid"
                                : ""
                            }`}
                            name="amount"
                            onChange={handleChange("amount")}
                            value={values.amount}
                            touched={touched.amount}
                            errors={errors.amount}
                            placeholder="₹"
                          />
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Due Date
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="date"
                            id="dueDate"
                            className={`inputBoxAddCust ${
                              touched.dueDate && errors.dueDate
                                ? "is-invalid"
                                : ""
                            }`}
                            name="dueDate"
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            onChange={(event) => {
                              // setSearch(event.target.value);
                              setFieldValue(
                                "dueDate",
                                event.target.value,
                                true
                              );
                            }}
                            value={values.dueDate}
                            touched={touched.dueDate}
                            errors={errors.dueDate}
                            placeholder="enter Date"
                          />
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "20px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Set reminder frequency
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="frequency"
                            className={`inputBoxAddCust `}
                            name="frequency"
                            onChange={handleChange("frequency")}
                            value={values.frequency}
                            // touched={touched.frequency}
                            // errors={errors.frequency}
                            placeholder="Days"
                          />
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    style={{
                      padding: "10% 0 0 0",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{ width: "160px", borderRadius: "5px" }}
                      className="generateOtp textAddCust Medium btn btn-secondary"
                      // onClick={addFreight}
                    >
                      Next
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
        {/* {toastObject.toastStatus ? (
          <ToastV2
            success={toastObject?.status}
            message={toastObject?.message}
            toggleModal={() => {
              setOpenToast(false);
            }}
            isOpen={toastObject?.toastStatus}
            name="driver"
            handleClose={() => {
              dispatch(
                toastStatus({
                  toastStatus: false,
                  message: "",
                  status: true,
                })
              );
              window.location = `/Customers/${customerId}`;
            }}
          />
        ) : (
          <></>
        )} */}
      </Modal>
    </div>
  );
}

export default CollectPayment;
