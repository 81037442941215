import React, { useEffect, useState } from "react";
// import SvgImage from "../../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { sendOtp } from "../../../../services/Store/Actions/LoginAction";
import { sendAnalitics, toastStatus } from "../../../../services/Store/Actions/appActions";
import SvgImage from "../../../../Components/Icons/Icons";
import PinInput from "react-pin-input";
import { cleanOnboardingLoaderAction, setPinRegAction } from "../../../../services/Store/Actions/onboardingAction";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";


const SetPin=(props)=>{
    const validationSchema1 = Yup.object().shape({
        pin:Yup.string()
        .required("Pin is required")
        .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
      });

    const {setPinVal,setRegStatus} = props; 

    const dispatch= useDispatch();
    useEffect(()=>{
      dispatch(cleanOnboardingLoaderAction());
    },[])
    // const validationSchema2 = Yup.object().shape({
    //   confirmPin:Yup.string()
    //   .required("Pin is required")
    //   .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
    // });
    const { loader } = useSelector((state) => state?.onboarding);
    // const [otpStatus, setOtpStatus]= useState(false);
    // const [otp, setOtp]=useState();
      
      return (
        <>
        {/* {(!otpStatus)? */}
          <Formik
          enableReinitialize
          validationSchema={validationSchema1}
          initialValues={{
            pin: "",
          }}
          onSubmit={(values, actions) => {
            //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
            console.log("valuessss 1", values);
            // dispatch(sendAnalitics("GetOTP_web", values));
            setPinVal(values?.pin);
            setRegStatus(6);
            // setOtpStatus(true);
            // values.pin="";
            console.log("valuessss 2", values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
           
          }) => (
            <Form onSubmit={handleSubmit} className="InputForm2">
                
                  <FormGroup className="formPosition">
                <div className="heading1 semiBold">Set 4-digit PIN</div>
                <PinInput
                    length={4}
                    focus={true}
                    initialValue=""
                    secret
                    onChange={handleChange("pin")}
                    values={values.pin}
                    //handleChange={handleChange("code")}
                    type="numeric"
                    inputMode="number"
                    style={{
                        padding:"1.5rem 0 0.5rem"
                    // padding: "10px",
                    // marginLeft: "96px",
                    // marginTop: "28px",
                    }}
                    inputStyle={{
                    border: "1px solid #A7A7A7",
                    borderRadius: "8px",
                    marginRight: "2.5rem",
                    }}
                    //onComplete={(value, index) => {}}
                    autoSelect={false}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    onClick={()=>{
                    // dispatch(sendAnalitics("Pin_DC_MA_Web",{
                    //     pin_MA_to_DriverCard:values.pin
                    // }));
                    }}
                />
                <div className="text4" style={{ margin: "7px 0 0px 0px", color: "red" }}>
                    {errors.pin && touched.pin ? (
                    <div>{errors.pin}</div>
                    ) : null}
                </div>
           
                <Button
                  type="submit"
                  className="loginButton semiBold text2"
                  style={{
                    marginTop: "1rem",
                    background:
                      values.pin.length < 4
                        ? "#EFEFEF"
                        : loader
                        ? "#EFEFEF"
                        : "#2e7c7b",
                    color:
                      values.pin.length < 4
                        ? "#B5B5B5"
                        : loader
                        ? "#B5B5B5"
                        : "#ffffff",
                  }}
                  disabled={loader ? true : false}
                >
                  {loader ? "Please Wait" : "Verify OTP"}
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
        {/* :
        <Formik
          enableReinitialize
          validationSchema={validationSchema2}
          initialValues={{
            confirmPin: "",
          }}
          onSubmit={(values, actions) => {
            //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
            console.log("valuessss 3", values);
            // dispatch(sendAnalitics("GetOTP_web", values));
            if(values.confirmPin===otp){
              dispatch(
                setPinRegAction(
                  {
                    "pin": values?.confirmPin
                  },
                  (res) => {
                    //actions.setSubmitting(false);
                    console.log("SUCCESS", res);
                    if (res?.data?.type === "success") {
                      // let info= sessionStorage.getItem("_userInfo");
                      // storeDataToAsyncStorage("_user",  JSON.parse(info));
                      dispatch(toastStatus({
                              toastStatus: true,
                              message:"Pin set successfully",
                              status: true,
                            }));
                    }
                  }
                )
              );
            }else{
              actions.setFieldError("confirmPin", "Incorrect PIN. Please enter the same pin.");
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            
          }) => (
            <Form onSubmit={handleSubmit} className="InputForm2">
                
                  <FormGroup className="formPosition">
                <div className="heading1 semiBold">Re-confirm PIN</div>
                <PinInput
                    length={4}
                    initialValue=""
                    // secret
                    onChange={handleChange("confirmPin")}
                    values={values.confirmPin}
                    //handleChange={handleChange("code")}
                    type="numeric"
                    inputMode="number"
                    style={{
                        padding:"1.5rem 0 0.5rem"
                    // padding: "10px",
                    // marginLeft: "96px",
                    // marginTop: "28px",
                    }}
                    inputStyle={{
                    border: "1px solid #A7A7A7",
                    borderRadius: "8px",
                    marginRight: "2.5rem",
                    }}
                    //onComplete={(value, index) => {}}
                    autoSelect={false}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    onClick={()=>{
                    // dispatch(sendAnalitics("Pin_DC_MA_Web",{
                    //     pin_MA_to_DriverCard:values.pin
                    // }));
                    }}
                    focus={true}
                />
                <div className="text4" style={{ margin: "7px 0 0px 0px", color: "red" }}>
                    {errors.confirmPin && touched.confirmPin ? (
                    <div>{errors.confirmPin}</div>
                    ) : null}
                </div>
           
                <Button
                  type="submit"
                  className="loginButton semiBold text2"
                  style={{
                    marginTop: "1rem",
                    // background:"#2e7c7b",
                    // color:"#ffffff"
                    background:
                      values.confirmPin.length < 4
                        ? "#EFEFEF"
                        : loader
                        ? "#EFEFEF"
                        : "#2e7c7b",
                    color:
                      values.confirmPin.length < 4
                        ? "#B5B5B5"
                        : loader
                        ? "#B5B5B5"
                        : "#ffffff",
                  }}
                  disabled={loader ? true : false}
                >
                  {loader ? "Please Wait" : "Verify PIN"}
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
        } */}
        </>
      );  
}

export default SetPin;