import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Toast,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import AddFreight from "../../../TripsMainV2/Components/AddFreight";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import {
  createTrip,
  getCityAndState,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import ToastV2 from "../../../subComponents/toastV2";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import CollectPayment from "../CollectPayment";
import AddPayment from "../AddPayment";
import CreateInvoice from "../CreateInvoice";
import AddInvoice from "../AddInvoice";
import PaymentModal from "../../../Vendor/Components/Modal/PaymentModal";
import PinModal from "../../../Vendor/Components/Modal/PinModal";
import {
  cleanVendorTransaction,
  transferTorVendorAction,
  vendorTransactionAction,
} from "../../../../services/Store/Actions/beneficiaryAction";
import { wait } from "@testing-library/user-event/dist/utils";
import SuccessModal from "../../../Vendor/Components/Modal/SuccessModal";
import { getAllCustomersAction } from "../../../../services/Store/Actions/customerAction";
import SelectVendorModal from "../SelectVendorModal";

function VendorPayoutModal(props) {
  const { toggle, modal, setModal, customerId, vendorData } = props;
  const [mode, setMode] = useState(0);
  const [paymetModal, setPaymentModal] = useState(false);
  const [enterPin, setEnterPin] = useState(false);
  const [paymentData, setPaymentData] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [pinLoader, setPinLoader] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [failedMsg, setFailedMsg] = useState();
  const [addPayment, setAddPayment] = useState(false);
  const [selectVendorModal, setSelectVendorModal] = useState(false);
  const { customer } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    tripNo: Yup.string().required("TripNo is required"),
    source: Yup.string().required("Source is required"),

    destination: Yup.string().required("Destination is required"),

    fleetId: Yup.string().required("Vehicle is required"),

    userId: Yup.string().required("Driver is required"),

    startDate: Yup.string().required("StartDate is required"),
  });

  const handleClose = () => {
    setModal(!modal);
  };

  const payToVendorModal = () => {
    setPaymentModal(!paymetModal);
  };
  const handleAddPayment = () => {
    setAddPayment(!addPayment);
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
          // style={{height : "75vh"}}
        >
          <div>
            <div className="closeBtnWithBackground" onClick={handleClose}>
              <SvgImage
                classname="close-btn-text"
                style={{ width: "10%", height: "10%" }}
                name="crossBtnWIthBackground"
                width="20%"
                height="20%"
              />
            </div>
            <div
              style={{ marginTop: " 90px" }}
              className="row"
              //   className="PGCollectionBox"
            >
              <div className="PaymentsText col-12">Vendor Payment</div>
              <div className="col-12 row">
                <div
                  className="col-6"
                  style={{ padding: "75px 0 150px 25%" }}
                  onClick={handleAddPayment}
                >
                  <div className="squareDiv row">
                    <div
                      className="col-12"
                      style={{ textAlign: "center", paddingTop: "35px" }}
                    >
                      <SvgImage name="paymentReceived" />
                    </div>
                    <div className="col-12 addPayText">Add Payment</div>
                  </div>
                </div>
                <div
                  className="col-6"
                  style={{ padding: "75px 0 150px 10%" }}
                  onClick={payToVendorModal}
                >
                  <div className="squareDiv row">
                    <div
                      className="col-12"
                      style={{ textAlign: "center", paddingTop: "35px" }}
                    >
                      <SvgImage name="collectPaymentLogo" />
                    </div>
                    <div className="col-12 addPayText">Pay To Vendor</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {paymetModal && (
        <PaymentModal
          open={paymetModal}
          mode={mode}
          setMode={setMode}
          onHide={() => {
            setPaymentModal(false);
          }}
          toggleModal={() => {
            setPaymentModal(!paymetModal);
          }}
          handleClose={() => {
            setPaymentModal(false);
          }}
          onSubmit={(data) => {
            setPaymentModal(false);
            setEnterPin(true);
            setPaymentData(data);
            console.log("modeooooooo", mode);
          }}
          customer={vendorData}
        />
      )}
      {enterPin && (
        <PinModal
          open={enterPin}
          onHide={() => {
            setEnterPin(false);
          }}
          toggleModal={() => {
            setEnterPin(!enterPin);
          }}
          handleClose={() => {
            setEnterPin(false);
          }}
          pinLoader={pinLoader}
          onSubmit={(data) => {
            setPinLoader(true);
            dispatch(
              transferTorVendorAction(
                {
                  mode:
                    (vendorData?.vpaId != null || vendorData?.vpaId != "") &&
                    (vendorData?.bankAccountNumber != "" ||
                      vendorData?.bankAccountNumber != null)
                      ? mode == 0
                        ? "BANK_TRANSFER"
                        : "UPI"
                      : vendorData?.vpaId == "" || vendorData?.vpaId == null
                      ? "BANK_TRANSFER"
                      : "UPI",
                  pin: data?.pin?.toString(),
                  amount: paymentData?.amount?.toString(),
                  remark: paymentData?.remarks,
                  vendorId: vendorData?.id?.toString(),
                },
                (res) => {
                  setPinLoader(false);
                  console.log("res?.datatransferTorVendorAction", res);
                  if (res?.status == 200) {
                    setSuccessModal(true);
                    setSuccessText(
                      `₹${paymentData?.amount} has been payed to your vendor from Master Account.`
                    );
                    setEnterPin(false);
                  } else {
                    setFailedMsg(res?.data?.message);
                    setSuccessModal(false);
                    setEnterPin(false);
                    setFailedModal(true);
                  }
                }
              )
            );
          }}
          data={paymentData}
          isLogipeVendor={"1"}
        />
      )}
      {successModal && (
        <SuccessModal
          open={successModal}
          onHide={() => {
            setSuccessModal(false);
            setPaymentData({});
            setSuccessText("");
          }}
          toggleModal={() => {
            setSuccessModal(!successModal);
            setPaymentData({});
            setSuccessText("");
          }}
          handleClose={() => {
            setSuccessModal(false);
            setPaymentData({});
            setSuccessText("");
          }}
          text={successText}
        />
      )}
      {failedModal && (
        <Toast
          success={0}
          message={failedMsg || "Failed to Transfer Amount"}
          toggleModal={() => {
            setFailedModal(false);
            setFailedMsg();
          }}
          isOpen={failedModal}
          name="vendor"
          handleClose={() => {
            setFailedModal(false);
            setFailedMsg();
          }}
        />
      )}
      <AddPayment
        toggle={handleAddPayment}
        modal={addPayment}
        setModal={setAddPayment}
        customerId={customerId}
        vendorData={vendorData}
      />
    </div>
  );
}

export default VendorPayoutModal;
