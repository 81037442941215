import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";

function PricingDetails(props){
    
    const { HomeMrp, HomeDiscount, coinVal,setCoinStatus,coinStatus}= props;
  
    return (
      <div className="padding16 cartItem padding32LR" style={{borderRadius:"0.5rem"}}>
          <div className="row">
              <div className="col-6">MRP</div>
              <div className="col-6 rightAlignment padding16R">₹ {HomeMrp+HomeDiscount}</div>
          </div>
          <div className= {coinStatus?"row padding8T":"row bottomLine padding8T padding8B"} >
              <div className="col-6">Discount</div>
              <div className="col-6 rightAlignment padding16R discAmount">₹ {HomeDiscount}</div>
          </div>
          {
            coinStatus?
            <div className="row bottomLine padding8T padding8B" >
              <div className="col-6">LogiCoins</div>
              <div className="col-6 rightAlignment padding16R discAmount">₹ {coinVal}</div>
            </div>:<></>
          }
          <div className="row padding16T padding16B">
              <div className="col-6 Bold heading3">Total Amount</div>
              <div className="col-6 rightAlignment padding16R Bold heading2">₹ {coinStatus?(HomeMrp-coinVal):(HomeMrp)}</div>
          </div>
      </div>
    );
}

export default PricingDetails;