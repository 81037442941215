import React from 'react'
import SvgImage from '../../../Icons/Icons'
import './index.css'
export default function DetailCardHeader(props) {
  return (
    <div className='row padding16' style={{height:"50px",zIndex:50}}>
        <div className='col-6 logipeDetialCardHeader padding32L' >
           TransactionType
        </div >
        <div className='col-3 logipeDetialCardHeader' >
           Amount
        </div>
        <div className='col-3 logipeDetialCardHeader padding0L' >
           Date and Time
           {/* <div style={{paddingLeft:"4px", cursor:"pointer"}} onClick={props.handleClick}>
           <SvgImage name = "arrowUp"/>
           </div> */}
           
        </div>
    </div>
  )
}
