import React, { useEffect, useRef } from "react";
import { Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import SvgImage from "../../Components/Icons/Icons";
import "./routerbuttonV2.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const RouterButtonV2 = (props) => {
  //    const [buttonTextClass,setbuttonTextClass] = useState("buttonTextNonClicked");
  const navigate = useNavigate();
  let btnRef = useRef();
  let opacityRef = useRef();
  let opacityRef3 = useRef();
  let opacityRef2 = useRef();
  //console.log("location ---- ",location.pathname);

  //const navigate = useNavigate();

  // function handleClick(){
  //    console.log("I am clicked")
  //    if (buttonClass === "nonClicked"){
  //        setButtonClass("Clicked")
  //        setbuttonTextClass("buttonTextClicked")
  //    }Manage
  //        setButtonClass("nonClicked")
  //        setbuttonTextClass("buttonTextNonClicked")
  //    }

  // };

  // const name = props.name==null?"button":props.name
  const {
    title,
    showTitle,
    Title,
    showArrow,
    isRestricted,
    index,
    disable,
    link,
  } = props;
  // const [enable,setEnable] = useState(!disable);
  // console.log("disabledisabledisable",disable,link);
  console.log("useuseEffectEuseEffectffect222", disable, showTitle, Title);
  useEffect(() => {
    console.log("useuseEffectEuseEffectffect", disable, showTitle, Title);
    if (!disable) {
      if (!showTitle) {
        btnRef.current.style.width = "60px";
        opacityRef.current.style.opacity = 0;
        // opacityRef3.current.style.backgroundColor = '#fff';
        if (showArrow) {
          opacityRef2.current.style.opacity = 0;
        }
      } else {
        btnRef.current.style.width = "200px";
        opacityRef.current.style.opacity = 1;
        // opacityRef3.current.style.backgroundColor = 'green';
        if (showArrow) {
          opacityRef2.current.style.opacity = 1;
        }
      }
    }
  }, [showTitle, disable]);

  return (
    <div>
      {!disable ? (
        <div
          className="width60"
          ref={btnRef}
          // style={{ backgroundColor: "red" }}
        >
          <div
            className="button"
            onClick={() => {
              if (!isRestricted) {
                navigate(`/${props.link}`);
              }
            }}
            style={{
              height: title == "AppLogo" ? "40px" : "60px",
            }}
          >
            <div
              className={
                showTitle
                  ? props.active
                    ? "row activeHover"
                    : "row nonClickedHover"
                  : "row nonClickedV2"
              }
              style={{
                width: "200px",
                transition: "all 1s",
              }}
              ref={opacityRef3}
            >
              {/* <div className={"row"}> */}
              <div className={"col-2 firstHalf"}>
                <div
                  className={
                    props.active
                      ? "nonClicked2 Clicked"
                      : "nonClicked2 notClicked"
                  }
                >
                  <SvgImage
                    //   height="400"
                    name={props.title}
                    color={props.active ? "white" : "#485B7C"}
                  />
                </div>
              </div>
              {/* {showTitle && ( */}
              {/* <> */}
              <div
                className={`${
                  props.active
                    ? "col-7 buttonTextClickedV2"
                    : "col-7 buttonTextNonClickedV2"
                } opacity1`}
                ref={opacityRef}
              >
                {Title}
                {isRestricted ? (
                  <div style={{ color: "red" }}>{"\nRestricted"}</div>
                ) : null}
              </div>
              {showArrow && (
                <div className="col-1 pointingV2 opacity2" ref={opacityRef2}>
                  <SvgImage name="linkArrow" />
                </div>
              )}
              {/* </> */}
              {/* )} */}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default RouterButtonV2;
