import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import SvgImage from "../../../Icons/Icons";
import {
  Form,
  FormGroup,
  Button,
  Col,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import * as Yup from "yup";
import AddNewAccount from "../../../Modals/addNewAccount";
import CompanyAccountNotConnected from "./CompanyAccountNotConnected";
import CompanyAccountConnected from "./CompanyAccountConnected";
import { neftLinkedAccountsListAction } from "../../../../services/Store/Actions/masterWalletActions";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";

// const validationSchema = Yup.object().shape({
//   amount: Yup.number()
//     .required("Required")
//     .min(1, "Amount should be greater than 1"),
//   pin: Yup.string()
//     .required("Required")
//     .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
// });

function NEFTComponent(props) {
  const [addBeneficiary, setAddBeneficiary] = useState(false);
  function handleAddBeneficiary(opt) {
    setAddBeneficiary(true);
    //toggleModal();
  }
  function handleCloseAddBeneficiary() {
    setAddBeneficiary(false);
  }
  function toggleModalAddBeneficiary() {
    setAddBeneficiary(!addBeneficiary);
  }

  useEffect(()=>{
    dispatch(neftLinkedAccountsListAction());
    dispatch(sendAnalitics("NEFT/RTGS_MA_Web"));
  },[]);

  const neftLinkedAccountsList= useSelector((state)=>state?.masterWallet?.neftLinkedAccountsList);
  const {
    virtuAccount,
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    amount,
    ownerLogipeBalance,
    accountStatus,
    color,
    copyIcon,
    setCopyIcon,
    copyIconIfsc,
    setCopyIconIfsc,
  } = props;

  //console.log ("in the neft component-----------",ownerLogipeBalance);
  const dispatch = useDispatch();
  // const ownerBalance = ownerLogipeBalance === "" ? 0 : ownerLogipeBalance;
  console.log("blanace", virtuAccount);

  return (
    <div>
      <div>
        {!neftLinkedAccountsList?.[0] ? (
          <CompanyAccountNotConnected 
          handleClick={handleClick}
          btn={btn}  />
        ) : (
          <CompanyAccountConnected
          ownerLogipeBalance={ownerLogipeBalance}
          virtuAccount={virtuAccount}
          copyIcon={copyIcon}
          copyIconIfsc={copyIconIfsc}
          handleClick={handleClick}
          btn={btn} />
        )}
      </div>
    </div>
  );
}

export default NEFTComponent;
