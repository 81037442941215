import React, { useEffect, useState } from "react";
import "./index.css";
import { Button, Table } from "reactstrap";
import SvgImage from "../../Components/Icons/Icons";
import {
  cleanFleetTransactionHistory,
  getFastagVehicleHistoryStatement,
} from "../../services/Store/Actions/fleetActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Modal, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";
import AddingFilter from "../../Components/Modals/addingFilter";
import ReactPaginate from "react-paginate";
import { getFastagTransactionHistory } from "../../services/Store/Actions/fastagAction";

const FastagHistory = (props) => {
  const { open, handleClose, toggleModal, regNumber, fleetId } = props;
  let { id } = useParams();
  const [offset, setOffset] = useState(0);
  const [fetchMoreData, setFetchMoreData] = useState(true);
  const dispatch = useDispatch();
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [filter, setFilter] = useState(0);
  const [start, setStart]= useState("");
  const [end, setEnd]= useState("");

  const { fastagVehicleStatement } = useSelector((state) => state?.fleet);

  const closing1 = () => {
    setActive1(false);
  };

  const toggling1 = () => {
    setActive1(!active1);
  };

  const showing1 = () => {
    setActive1(true);
  };

  const closing2 = () => {
    setActive2(false);
  };

  const toggling2 = () => {
    setActive2(!active2);
  };

  const showing2 = () => {
    setActive2(true);
  };

  useEffect(() => {
    dispatch(cleanFleetTransactionHistory());
    setOffset(0);
    dispatch(
      getFastagTransactionHistory(fleetId, {
        offset: 0,
        search: '',
        sortAs: '',
        sortBy: '',
      }),
    );
  }, [id,props.open]);
  // const handleClose=props.handleClose;
  //console.log("fleetId",fleetId );
  const { fastagTransactionHistory, fleetHistoryList } = useSelector(
    (state) => state?.fastag
  );
  console.log("fastagTransactionHistory", fastagTransactionHistory);
  const totalEntries= fastagTransactionHistory?.count;
  const pageCount= (totalEntries%10)===0?(totalEntries/10):Math.floor(totalEntries/10)+1;  
    const handlePageClick = (event) => {
      console.log("Clickess");
      const newOffset = ((event.selected) * 10) ;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      if (filter===0){
        dispatch(
          getFastagTransactionHistory(fleetId, {
            offset: newOffset,
            from: "",
            to: "",
          }),
        );
      }
      else {
        console.log ("fastag-> filter",start,end);
        dispatch(
          getFastagTransactionHistory(fleetId, {
            offset:newOffset,
            from: moment(start).format("YYYY-MM-DD"),
            to: moment(end).format("YYYY-MM-DD"),
          })
        );
      }
      setOffset(newOffset);
    };  
  

  const filterFunc = (startDate, endDate) => {
    setOffset(0);
    setFilter(1);
    setStart(startDate);
    setEnd(endDate);
    console.log("start date", startDate);
    console.log("end date", endDate);
    dispatch(cleanFleetTransactionHistory());
    dispatch(
      getFastagTransactionHistory(fleetId, {
        offset: 0,
        from: moment(startDate).format("YYYY-MM-DD"),
        to: moment(endDate).format("YYYY-MM-DD"),
      })
    );
  };

  const downloadFunc = (startDate, endDate,mode) => {
    console.log("start date", startDate);
    console.log("end date", endDate);
    dispatch(
      getFastagVehicleHistoryStatement(fleetId, {
        from:  moment(startDate).format("YYYY-MM-DD"),
        to:  moment(endDate).format("YYYY-MM-DD"),
        mode:mode
      },(res) => {
        if (res?.data?.type === "success") {
          console.log("rrrrrrrrrrrrrrrr", res);
          window.open(res?.data?.data?.url);
          // window.location.replace(res?.data?.data?.link);
          //window.open();
          //window.location.reload();
        }
      })
    );
    //window.open(fastagVehicleStatement?.url);
  };


  const disableFilter=()=>{
    setOffset(0);
    setFilter(0);
    setStart("");
    setEnd("");
    dispatch(cleanFleetTransactionHistory());
    dispatch(
      getFastagTransactionHistory(fleetId, {
        offset: 0,
        from: "",
        to: "",
      })
    );
  }

  return (
    <Modal
      isOpen={open}
      onHide={handleClose}
      className=" all-transaction-main-modal"
      toggle={toggleModal}
      aria-labelledby="myModalLabel2"
    >

      <ModalBody className="transactionModalBody">
        <div className="PGCollectionBox">
          {/* <div className="row" onClick={handleClose}>
            <div className="col-10"></div>
            <div className="col-1 ">
              <SvgImage
                style={{ width: "10%", height: "10%" }}
                name="CloseButtonLogo"
                width="20%"
                height="20%"
              />
            </div>
            <div className="col-1">Close</div>
          </div> */}
          <div 
              className="closeEffect"
              onClick={handleClose}
              style={{cursor:"pointer"}}
              // // className=""
              >
                <SvgImage
                  classname = 'close-btn-text'
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
              {/* <div className="PGCollectionCloseText col-2">Close</div> */}
          
            
            <div className="d-flex justify-content-between align-items-center my-3">
            <div className="PGCollectionHeader pt-0">
              <div className="PGCollectionIcon">
                <SvgImage name="settlementLogo" />
              </div>
              <div className="PGCollectiontText1">
                Fastag History ({regNumber})
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="filterText m-3" onClick={showing1} style={{paddingTop:"0", paddingBottom:"0"}}>
                Filter
                <div className="filterImg">
                  <SvgImage name="filterIcon" />

                </div>
              </div>
              <div className="filterDates"> 
                <div className="heading3 Bold">Filter Applied: </div>
                <div className="FontNB" style={{paddingLeft:"8px"}}>
                  {moment(start).format("DD-MM-YYYY")=="Invalid date"?"None":moment(start).format("DD-MM-YYYY")+" to"} {moment(end).format("DD-MM-YYYY")=="Invalid date"?"":moment(end).format("DD-MM-YYYY")}
                  </div>
                <div style={{paddingLeft:"8px", position:"relative", top:"-3px", cursor:"pointer",
                visibility:moment(start).format("DD-MM-YYYY") == "Invalid date"?"hidden":"visible"}} 
                  onClick={disableFilter}>
                  <SvgImage
                    classname = 'close-btn-text'
                    // style={{ width: "10%", height: "10%" }}
                    name="CloseButtonLogo1"
                    width="20px"
                    height="20px"
                  />
                </div>
            </div>
              <AddingFilter
                active={active1}
                toggleModal={toggling1}
                handleClose={closing1}
                func={filterFunc}
                name="Filter"
              />
            </div>
            <div>
              {/* <div className="downloadText m-3" onClick={showing2}
                  style={{paddingLeft:"0px",marginLeft:"0px", paddingTop:"0", paddingBottom:"0"}}>
                <div>Download Statement</div>
                <div className="downloadImg" style={{ marginLeft: "10px" }}>
                  <SvgImage name="downloadImg" />
                </div>
              </div> */}
              
              <AddingFilter
                active={active2}
                toggleModal={toggling2}
                handleClose={closing2}
                func={downloadFunc}
                name="Download History"
              />
            </div>
          </div>
          {/* <div className="flexStyling new-row-for-download padding32L padding32R padding16B">
            <div className="flex1"></div>
            <div className="filterDates"> 
                <div className="heading3 Bold">Filter Applied: </div>
                <div className="FontNB" style={{paddingLeft:"8px"}}>
                  {moment(start).format("DD-MM-YYYY")=="Invalid date"?"None":moment(start).format("DD-MM-YYYY")+" to"} {moment(end).format("DD-MM-YYYY")=="Invalid date"?"":moment(end).format("DD-MM-YYYY")}
                  </div>
                <div style={{paddingLeft:"8px", position:"relative", top:"-3px", cursor:"pointer",
                visibility:moment(start).format("DD-MM-YYYY") == "Invalid date"?"hidden":"visible"}} 
                  onClick={disableFilter}>
                  <SvgImage
                    classname = 'close-btn-text'
                    // style={{ width: "10%", height: "10%" }}
                    name="CloseButtonLogo1"
                    width="20px"
                    height="20px"
                  />
                </div>
            </div>
          </div> */}
          <div  className="transitionContainerFastag">
          <div className="tableContaincer">
            <Table className="fastagTableCss">
              <thead >
                <tr className="table-header-customer-details">
                  <th><div style={{marginLeft:"1.5rem"}}>Reason</div></th>
                  <th>Amount</th>
                  <th>Date and Time</th>
                </tr>
              </thead>
              <tbody >
              {fastagTransactionHistory?.rows?.map((item, i) => {
                    let TransferType = item?.transferType;
                    let amount = item?.amount===null?"0.00":Number(item?.amount).toFixed(2);
                    //let company=item?.companyName;
                    let transactionTiming = item?.transactionDate;
                    let dateH = moment(transactionTiming).format("DD-MM-YYYY");
                    let timeH = moment(transactionTiming).format("hh:mm:ss a");
                    let description = item?.description==null?"N/A":item?.description;
                    let words= description.split(" ");
                    let lastWord= words[words.length-1];
                    console.log("---------------", words[0]);
                    let cases1= description=="Distributor>Corporate Customer"?"1":
                    (words[0]=="Issuer" && words[1]=="Debit")?"2":
                    words[0]=="Tag"|| words[0]=="tag"?"3":
                    words[0]=="Toll"?"4":
                    lastWord=="recharge"?"5":"6";
                  return (
                    <>
                    <tr key={i} >
                      <td style={{ whiteSpace:"normal",display:"flex",marginLeft:"-2.5rem"}}>
                      <div>
                          <SvgImage name={cases1=="1"?"masterRechargeIcon":
                          cases1=="2"?"transferWalletIcon":
                          cases1=="3"?"tagIssueIcon":
                          cases1=="4"?"transferWalletIcon":
                          cases1=="5"?"masterRechargeIcon":
                          "masterRechargeIcon"} />
                          </div>
                          <div className="padding8L" style={{margin:"auto 0px"}}>{description}</div>
                      </td>
                      <td className={TransferType === "credit" ?"greenColor":"redColor"} >
                        ₹{amount}
                      </td>
                      <td >
                        <div>{dateH}</div>
                        <div>{timeH}</div>
                      </td>
                    </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
            {pageCount>1?
                <div className="modalPagination">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                :<></>
            }
          </div>
          </div>
            {/* <div className="PGCollectionHeaderBar" style={{marginTop:"0px"}}>
              <PGCollectionBar No1="Reason" />
            </div> */}
            {/* <div className="cashbackListing">
              {fastagTransactionHistory?.rows?.map((item, i) => {
                let TransferType = item?.transferType;
                let amount = item?.amount===null?"0.00":Number(item?.amount).toFixed(2);
                //let company=item?.companyName;
                let transactionTiming = item?.transactionDate;
                let dateH = moment(transactionTiming).format("DD-MM-YYYY");
                let timeH = moment(transactionTiming).format("hh:mm:ss a");
                let description = item?.description;

                return (
                  <div key={i} className="PGCollectionCard" style={{}}>
                    <div className="PGCollectionCardBox">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className="PGCollectionCode"
                          style={{ whiteSpace:"normal"}}>{description}</div>
                        <div
                          className="PGCollectionCurrentBal1"
                          style={{
                            color:
                              TransferType === "credit" ? "#00E676" : "#F0222E",
                          }}
                        >
                          ₹{amount}
                        </div>
                        <div className="PGCollectionRequestDate">{dateH}</div>
                      </div>
                      <div className="row">
                        <div className="PGCollectionRequestTime1">{timeH}</div>
                      </div>
                      <div className="PGCollectionEndBar"></div>
                    </div>
                  </div>
                );
              })}
            </div> */}
            {
              // ((totalEntries-offset)>10)?
              //     <div className="OlderHistoryButton" onClick={handleOlderHistory}>
              //     <Button
              //       // className="butt-4"
              //       disabled={loader ? true : false}
              //       className="transactionButton"
              //     >
              //       {loader ? "Please Wait" : "Older History"}
              //       <div style={{ paddingLeft: "8px" }}>
              //         <SvgImage name="downArrow" />
              //       </div>
              //     </Button>
              //   </div>
              //   :<></>

              // pageCount>1?
              //   <div className="modalPagination">
              //     <ReactPaginate
              //       breakLabel="..."
              //       nextLabel=" >"
              //       onPageChange={handlePageClick}
              //       pageRangeDisplayed={5}
              //       pageCount={pageCount}
              //       previousLabel="<"
              //       renderOnZeroPageCount={null}
              //       breakClassName={"page-item"}
              //       breakLinkClassName={"page-link"}
              //       containerClassName={"pagination"}
              //       pageClassName={"page-item"}
              //       pageLinkClassName={"page-link"}
              //       previousClassName={"page-item"}
              //       previousLinkClassName={"page-link"}
              //       nextClassName={"page-item"}
              //       nextLinkClassName={"page-link"}
              //       activeClassName={"active"}
              //     />
              //   </div>
              //   :<></>
            }
            
          </div>
        
      </ModalBody>
    </Modal>
  );
};
export default FastagHistory; 