import { initializeApp } from "firebase/app";
// import { getMessaging } from "firebase/messaging/sw";
// import { onMessage } from "firebase/messaging";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { storeDataToAsyncStorage } from "./services/Utils/functions";



const firebaseConfig = {
  apiKey: "AIzaSyB8ygNDteGcodgPecm-U0wVZh6GeAtluNs",
  authDomain: "notificationpwa-560f5.firebaseapp.com",
  databaseURL: 'https://notificationpwa-560f5.firebaseio.com',
  projectId: "notificationpwa-560f5",
  storageBucket: "notificationpwa-560f5.appspot.com",
  messagingSenderId:  "112795476624",
  appId:  "1:112795476624:web:de24ca4fc1a11cd54fae15",
  measurementId: "G-G79CEXYX1W"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const getTokenFunc = (setTokenFound, setToken) => {
  return getToken(messaging, {vapidKey: 'BBtJh1RLgRu4pTdPZ9DtyNKQTrW40hSwsEX8GlzpbmDaq-mv6f-9c_E68Ug4bJ4s35QaBETbEYcFHJRgp6HHc8Y'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      storeDataToAsyncStorage("_DeviceToken", currentToken);
      setToken(currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});