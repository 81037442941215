import {
  CREATE_TRIP,
  CREATE_TRIP_SUCCESS,
  END_TRIP,
  END_TRIP_SUCCESS,
  GET_TRIPS,
  GET_TRIPS_SUCCESS,
  GET_TRIPS_BY_FLEET,
  GET_TRIPS_BY_FLEET_SUCCESS,
  DELETE_TRIP,
  DELETE_TRIP_SUCCESS,
  UPDATE_TRIP,
  UPDATE_TRIP_SUCCESS,
  FASTAG_VEHICLE_TRANSACTION_BY_FLEET,
  FASTAG_VEHICLE_TRANSACTION_BY_FLEET_SUCCESS,
  GET_TRANSACTIONS_AND_EXPENSE,
  GET_TRANSACTIONS_AND_EXPENSE_SUCCESS,
  ADD_CASH_GIVEN,
  ADD_CASH_GIVEN_SUCCESS,
  EDIT_CASH_GIVEN,
  EDIT_CASH_GIVEN_SUCCESS,
  CASH_TRANSACTIONS,
  CASH_TRANSACTIONS_SUCCESS,
  CLEAN_TRANSACTION_LIST,
  GET_CITY_AND_STATE,
  GET_CITY_AND_STATE_SUCCESS,
  CLEAR_TRIP_LIST,
  NO_FASTAG_FOR_FLEET,
  NO_CARD_FOR_DRIVER,
  GET_WEATHER_DATA,
  GET_WEATHER_DATA_SUCCESS,
  GET_ESTIMATED_FUEL,
  GET_ESTIMATED_FUEL_SUCCESS,
  GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION,
  GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION_SUCCESS,
  GET_TRIPS_ERROR,
  GET_M2P_CARD_TRANSACTIONS,
  GET_M2P_CARD_TRANSACTIONS_SUCCESS,
  CALCULATE_TRIP_TOLL,
  CALCULATE_TRIP_TOLL_SUCCESS,
  CLEAR_TRIP_DETAILS,
  CALCULATE_TRIP_TOLL_INTERNAL,
  CALCULATE_TRIP_TOLL_INTERNAL_SUCCESS,
  GET_TRIP_TRANSACTION,
  GET_TRIP_TRANSACTION_SUCCESS,
  GET_TRIPS_BY_USERID,
  GET_TRIPS_BY_USERID_SUCCESS,
  CLEAN_TRIPS_BY_USERID,
  TRIP_REPORT,
  TRIP_REPORT_SUCCESS,
  CLEAN_TRIP_REPORT,
  ADD_DRIVERV2_SUCCESS,
  ADD_DRIVERV2,
  ADD_FREIGHT,
  ADD_FREIGHT_SUCCESS,
  GET_TRIPS_NEW,
  GET_TRIPS_NEW_SUCCESS,
  GET_TRIPS_NEW_ERROR,
  EDIT_FREIGHT,
  EDIT_FREIGHT_SUCCESS,
  GET_FREIGHT_DETAILS,
  GET_FREIGHT_DETAILS_SUCCESS,
  FIND_ADDRESS_TRIP,
  FIND_ADDRESS_TRIP_SUCCESS,
  ADD_TRIP_ADDRESS,
  ADD_TRIP_ADDRESS_SUCCESS,
  TRIP_BULK_ACTIONS,
  TRIP_BULK_ACTIONS_SUCCESS,
  GET_ADDRESS_FROM_PINCODE,
  GET_ADDRESS_FROM_PINCODE_SUCCESS,
  CREATE_LR,
  CREATE_LR_SUCCESS,
  GET_LR_LIST,
  GET_LR_LIST_SUCCESS,
  EDIT_LR,
  EDIT_LR_SUCCESS,
  GET_PINCODE_DATA,
  GET_PINCODE_DATA_SUCCESS,
  MARK_INVOICE_AS_PAID,
  MARK_INVOICE_AS_PAID_SUCCESS,
  GEN_LR_NO,
  GEN_LR_NO_SUCCESS,
  FIND_ADDRESS_TRIP_V2,
  FIND_ADDRESS_TRIP_V2_SUCCESS,
  GET_SAVED_ADDRESS,
  GET_SAVED_ADDRESS_SUCCESS,
  ADD_CASH_EXPANSE,
  ADD_CASH_EXPANSE_SUCCESS,
  GET_ROUTES,
  GET_ROUTES_SUCCESS,
  ADD_ROUTE,
  ADD_ROUTE_SUCCESS,
  GET_DRIVER_LEDGER,
  CLEAR_DRIVER_LEDGER,
  GET_DRIVER_LEDGER_SUCCESS,
  GET_VEHICLE_LEDGER,
  CLEAR_VEHICLE_LEDGER,
  GET_VEHICLE_LEDGER_SUCCESS,
  GET_TRIP_LEDGER,
  CLEAR_TRIP_LEDGER,
  GET_TRIP_LEDGER_SUCCESS,
  CREATE_TRIP_EXPENSE,
  CREATE_TRIP_EXPENSE_SUCCESS,
  GET_EACH_ROUTE,
  GET_EACH_ROUTE_SUCCESS,
  ADD_DRIVER_EXPENSE,
  ADD_DRIVER_EXPENSE_SUCCESS,
  ADD_VEHICLE_EXPENSE,
  ADD_VEHICLE_EXPENSE_SUCCESS,
  EDIT_LEDGER_EXPENSE,
  EDIT_LEDGER_EXPENSE_SUCCESS,
  GET_VEHICLE_LAST_TRIP,
  GET_VEHICLE_LAST_TRIP_SUCCESS,
  EDIT_TRIP_EXPENSE,
  EDIT_TRIP_EXPENSE_SUCCESS,
  GET_TRIP_EXPENSE,
  GET_TRIP_EXPENSE_SUCCESS,
  START_TRIP_EXPENSE,
  START_TRIP_EXPENSE_SUCCESS,
  GET_SIGNED_URL_WITH_KEY,
  GET_SIGNED_URL_WITH_KEY_SUCCESS,
  UPDATE_LR_IN_TRIP,
  UPDATE_LR_IN_TRIP_SUCCESS,
  GET_VENDOR_LEDGER,
  GET_VENDOR_LEDGER_SUCCESS,
  CLEAR_VENDOR_LEDGER,
  CLEAR_LR_LIST,
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  fetchingTrips: false,
  tripsList: [],
  trips: {},
  tripsByFleet: [],
  fastagVehicleTransaction: {},
  transactionsAndExpenses: {},
  cashTransactions: {},
  fastagTransactionsList: [],
  cardTransactionsList: [],
  cashTransactionsList: [],
  cityAndStateList: [],
  noFastagForFleet: false,
  noCardForDriver: false,
  weatherData: {},
  estimatedFuel: {},
  tollBetweenSourceAndDestination: {},
  tripDetails: {},
  tripLoader: false,
  tripTransactions: {},
  tripTransactionsList: [],
  tripsByUserId: [],
  tripsCount: undefined,
  tripReport: {},
  tripsListNew: [],
  freightDetails: {},
  tripAddressDetails: [],
  addressByPincode: {},
  freightList: [],
  freightListCount: undefined,
  countAll:0,
  uninvoicedCount:0,
  invoicedCount:0,
  notInTripCount:0,
  inTripCount:0,
  pincodeData: {},
  lrNo: "",
  savedAddresses: [],
  savedAddressCount: 0,
  searchAddresses: [],
  searchAddressCount: 0,
  driverLedger: {},
  driverLedgerList: [],
  driverLedgerCount: 0,
  vehicleLedger: {},
  vehicleLedgerList: [],
  vehicleLedgerCount: 0,
  tripLedger: {},
  tripLedgerList: [],
  tripLedgerCount: 0,
  routeData: {},
  tripExpense: {},
  tripCardLedger: {},
  tripCardLedgerList: [],
  tripCardLedgerCount: {},
  tripFastagLedger: {},
  tripFastagLedgerList: [],
  tripFastagLedgerCount: {},
  tripCashLedger: {},
  tripCashLedgerList: [],
  tripCashLedgerCount: {},
  tripOMCLedger: {},
  tripOMCLedgerList: [],
  tripOMCLedgerCount: {},
  tripVendorLedger: {},
  tripVendorLedgerList: [],
  tripVendorLedgerCount: {},
  vendorLedger: {},
  vendorLedgerList: [],
  vendorLedgerCount: 0,
  routeLoader: false,
  countAll: 0,
  countNotStarted: 0,
  countComplete: 0,
  countRunning: 0,
  countDelayed: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRIPS_BY_USERID: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_TRIPS_BY_USERID_SUCCESS: {
      return {
        ...state,
        loader: false,
        tripsByUserId: action.payload,
      };
    }
    case CLEAN_TRIPS_BY_USERID: {
      return {
        ...state,
        tripsByUserId: [],
      };
    }
    case CREATE_TRIP: {
      return {
        ...state,
        loader: true,
      };
    }

    case CREATE_TRIP_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_TRIPS: {
      console.log("inTripsReducer");
      return {
        ...state,
        loader: true,
        fetchingTrips: true,
      };
    }

    case GET_TRIPS_SUCCESS: {
      return {
        ...state,
        loader: false,
        // tripsList: action.payload
        tripsCount: action?.payload?.count,
        tripsList: [...action.payload?.rows],
        fetchingTrips: false,
      };
    }

    case GET_ROUTES: {
      console.log("inTripsReducer");
      return {
        ...state,
        routeLoader: true,
      };
    }

    case GET_ROUTES_SUCCESS: {
      console.log("allDatajjs", action.payload);
      return {
        ...state,
        routeLoader: false,
        // tripsList: action.payload
        routesCount: action?.payload?.count,
        routesList: [...action.payload?.rows],
        fetchingTrips: false,
      };
    }

    case ADD_ROUTE: {
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_ROUTE_SUCCESS: {
      console.log("addData", action.payload);
      return {
        ...state,
        loader: false,
        // tripsList: action.payload
        addRouteResp: action?.payload?.data,
      };
    }

    case GET_TRIPS_ERROR: {
      return {
        ...state,
        loader: false,
        fetchingTrips: false,
      };
    }
    case GET_TRIPS_NEW: {
      console.log("inTripsReducer");
      return {
        ...state,
        loader: true,
        fetchingTrips: true,
      };
    }

    case GET_TRIPS_NEW_SUCCESS: {
      return {
        ...state,
        loader: false,
        tripsCount: action?.payload?.count,
        tripsListNew: action.payload?.rows,
        fetchingTrips: false,
        countAll: action?.payload?.countAll,
        countNotStarted: action?.payload?.countNotStarted,
        countComplete: action?.payload?.countComplete,
        countRunning: action?.payload?.countRunning,
        countDelayed: action?.payload?.countDelayed,
      };
    }

    case GET_TRIPS_NEW_ERROR: {
      return {
        ...state,
        loader: false,
        fetchingTrips: false,
      };
    }

    case CLEAR_TRIP_LIST: {
      return {
        ...state,
        tripsList: [],
        tripsListNew: [],
      };
    }

    case GET_TRIPS_BY_FLEET: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_TRIPS_BY_FLEET_SUCCESS: {
      return {
        ...state,
        loader: false,
        tripsByFleet: action.payload,
      };
    }

    case END_TRIP: {
      return {
        ...state,
        loader: true,
      };
    }

    case END_TRIP_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case DELETE_TRIP: {
      return {
        ...state,
        loader: true,
      };
    }

    case DELETE_TRIP_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case UPDATE_TRIP: {
      return {
        ...state,
        loader: true,
      };
    }

    case UPDATE_TRIP_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case FASTAG_VEHICLE_TRANSACTION_BY_FLEET: {
      return {
        ...state,
        loader: true,
      };
    }

    case FASTAG_VEHICLE_TRANSACTION_BY_FLEET_SUCCESS: {
      return {
        ...state,
        loader: false,
        noFastagForFleet: false,
        fastagVehicleTransaction: action.payload,
        fastagTransactionsList: action.payload.transHistory.rows,
      };
    }

    case GET_TRANSACTIONS_AND_EXPENSE: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_TRANSACTIONS_AND_EXPENSE_SUCCESS: {
      return {
        ...state,
        loader: false,
        noCardForDriver: false,
        transactionsAndExpenses: action.payload,
        cardTransactionsList: action.payload.rows,
      };
    }

    case CASH_TRANSACTIONS: {
      return {
        ...state,
        loader: true,
      };
    }

    case CASH_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        loader: false,
        cashTransactions: action.payload,
        cashTransactionsList: action.payload.data,
      };
    }

    case GET_CITY_AND_STATE: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_CITY_AND_STATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        cityAndStateList: action.payload,
      };
    }

    case CLEAN_TRANSACTION_LIST: {
      return {
        ...state,
        fastagVehicleTransaction: {},
        transactionsAndExpenses: {},
        cashTransactions: {},
        fastagTransactionsList: [],
        cardTransactionsList: [],
        cashTransactionsList: [],
        cityAndStateList: [],
      };
    }

    case ADD_CASH_GIVEN: {
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_CASH_GIVEN_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case EDIT_CASH_GIVEN: {
      return {
        ...state,
        loader: true,
      };
    }

    case EDIT_CASH_GIVEN_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case NO_FASTAG_FOR_FLEET: {
      return {
        ...state,
        loader: false,
        noFastagForFleet: true,
      };
    }
    case NO_CARD_FOR_DRIVER: {
      return {
        ...state,
        loader: false,
        noCardForDriver: true,
      };
    }

    case GET_WEATHER_DATA: {
      console.log("IN reducer");
      return {
        ...state,
        loader: true,
      };
    }

    case GET_WEATHER_DATA_SUCCESS: {
      console.log("IN reducer success", action.payload);
      return {
        ...state,
        loader: false,
        weatherData: action.payload,
      };
    }

    case GET_ESTIMATED_FUEL: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_ESTIMATED_FUEL_SUCCESS: {
      return {
        ...state,
        loader: false,
        estimatedFuel: action.payload,
      };
    }

    case GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_TOLL_BETWEEN_SOURCE_AND_DESTINATION_SUCCESS: {
      return {
        ...state,
        loader: false,
        tollBetweenSourceAndDestination: action.payload,
      };
    }

    case GET_M2P_CARD_TRANSACTIONS: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_M2P_CARD_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        loader: false,
        transactionsAndExpenses: action.payload,
        cardTransactionsList: action.payload.rows,
      };
    }

    case CALCULATE_TRIP_TOLL: {
      return {
        ...state,
        tripLoader: true,
      };
    }
    case CALCULATE_TRIP_TOLL_SUCCESS: {
      return {
        ...state,
        tripLoader: false,
        tripDetails: action?.payload,
      };
    }
    case CALCULATE_TRIP_TOLL_INTERNAL: {
      return {
        ...state,
        tripLoader: true,
      };
    }
    case CALCULATE_TRIP_TOLL_INTERNAL_SUCCESS: {
      return {
        ...state,
        tripLoader: false,
        tripDetails: action?.payload,
      };
    }
    case CLEAR_TRIP_DETAILS: {
      return {
        ...state,
        tripDetails: {},
        tripTransactionsList: [],
      };
    }

    case GET_TRIP_TRANSACTION: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_TRIP_TRANSACTION_SUCCESS: {
      return {
        ...state,
        loader: false,
        noFastagForFleet: false,
        tripTransactions: action?.payload,
        tripTransactionsList: action?.payload?.rows || [],
      };
    }
    case TRIP_REPORT: {
      return {
        ...state,
        loader: true,
      };
    }
    case TRIP_REPORT: {
      return {
        ...state,
        loader: false,
        tripReport: action?.payload,
      };
    }
    case CLEAN_TRIP_REPORT: {
      return {
        ...state,
        tripReport: {},
      };
    }

    case ADD_DRIVERV2: {
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_DRIVERV2_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case ADD_FREIGHT: {
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_FREIGHT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case EDIT_FREIGHT: {
      return {
        ...state,
        loader: true,
      };
    }

    case EDIT_FREIGHT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case GET_FREIGHT_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_FREIGHT_DETAILS_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        freightDetails: action?.payload?.data,
      };
    }

    case FIND_ADDRESS_TRIP: {
      return {
        ...state,
        loader: true,
      };
    }

    case FIND_ADDRESS_TRIP_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        tripAddressDetails: action?.payload,
      };
    }
    case ADD_TRIP_ADDRESS: {
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_TRIP_ADDRESS_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
      };
    }

    case TRIP_BULK_ACTIONS: {
      return {
        ...state,
        loader: true,
      };
    }

    case TRIP_BULK_ACTIONS_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
      };
    }
    case GET_ADDRESS_FROM_PINCODE: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_SIGNED_URL_WITH_KEY: {
      return {
        ...state,
        loader: true,
        singedUrl: action.payload.singedUrl,
      };
    }
    case GET_SIGNED_URL_WITH_KEY_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case TRIP_BULK_ACTIONS_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
      };
    }
    case GET_ADDRESS_FROM_PINCODE: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_ADDRESS_FROM_PINCODE_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        addressByPincode: action?.payload,
      };
    }
    case CREATE_LR: {
      return {
        ...state,
        loader: true,
      };
    }

    case CREATE_LR_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
      };
    }
    case GET_LR_LIST: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
      };
    }
    case GET_LR_LIST_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        freightList: action?.payload?.rows,
        freightListCount: action?.payload?.count,
        countAll: action?.payload?.countAll,
        uninvoicedCount: action?.payload?.uninvoicedCount,
        invoicedCount: action?.payload?.invoicedCount,
        notInTripCount: action?.payload?.notInTripCount,
        inTripCount: action?.payload?.inTripCount,
      };
    }
    case CLEAR_LR_LIST: {
      return {
        ...state,
        loader: false,
        freightList: [],
      };
    }

    case EDIT_LR: {
      return {
        ...state,
        loader: true,
      };
    }

    case EDIT_LR_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
      };
    }
    case GET_PINCODE_DATA: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_PINCODE_DATA_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        pincodeData: action?.payload,
      };
    }

    case MARK_INVOICE_AS_PAID: {
      return {
        ...state,
        loader: true,
      };
    }
    case MARK_INVOICE_AS_PAID_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case GEN_LR_NO: {
      return {
        ...state,
        loader: true,
      };
    }

    case GEN_LR_NO_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        lrNo: action?.payload?.data?.lrNo,
      };
    }
    case FIND_ADDRESS_TRIP_V2: {
      return {
        ...state,
        loader: true,
      };
    }

    case FIND_ADDRESS_TRIP_V2_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        searchAddresses: action?.payload?.rows,
        searchAddressCount: action?.payload?.count,
      };
    }
    case GET_SAVED_ADDRESS: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_SAVED_ADDRESS_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        savedAddresses: action?.payload?.rows,
        savedAddressCount: action?.payload?.count,
      };
    }
    case ADD_CASH_EXPANSE: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_DRIVER_LEDGER: {
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_CASH_EXPANSE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_DRIVER_LEDGER_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        driverLedger: action?.payload,
        driverLedgerList: action?.payload?.rows,
        driverLedgerCount: action?.payload?.count,
      };
    }
    case CLEAR_DRIVER_LEDGER: {
      return {
        ...state,
        driverLedger: {},
        driverLedgerList: [],
        driverLedgerCount: 0,
      };
    }
    case GET_VEHICLE_LEDGER: {
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_TRIP_EXPENSE: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_VEHICLE_LEDGER_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        vehicleLedger: action?.payload,
        vehicleLedgerList: action?.payload?.rows,
        vehicleLedgerCount: action?.payload?.count,
      };
    }
    case CLEAR_VEHICLE_LEDGER: {
      return {
        ...state,
        vehicleLedger: {},
        vehicleLedgerList: [],
        vehicleLedgerCount: 0,
      };
    }
    case GET_TRIP_LEDGER: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_TRIP_LEDGER_SUCCESS: {
      console.log("afsnaefuae", action);
      let Data = {
        ...state,
        loader: false,
        tripLedger: action?.response,
        tripLedgerList: action?.response?.rows,
        tripLedgerCount: action?.response?.count,
      };
      if (action?.payload?.mode == "0") {
        Data["tripCardLedger"] = action?.response;
        Data["tripCardLedgerList"] = action?.response?.rows;
        Data["tripCardLedgerCount"] = action?.response?.count;
      } else if (action?.payload?.mode == "1") {
        Data["tripFastagLedger"] = action?.response;
        Data["tripFastagLedgerList"] = action?.response?.rows;
        Data["tripFastagLedgerCount"] = action?.response?.count;
      } else if (action?.payload?.mode == "2") {
        Data["tripCashLedger"] = action?.response;
        Data["tripCashLedgerList"] = action?.response?.rows;
        Data["tripCashLedgerCount"] = action?.response?.count;
      } else if (action?.payload?.mode == "3") {
        Data["tripOMCLedger"] = action?.response;
        Data["tripOMCLedgerList"] = action?.response?.rows;
        Data["tripOMCLedgerCount"] = action?.response?.count;
      } else if (action?.payload?.mode == "4") {
        Data["tripVendorLedger"] = action?.response;
        Data["tripVendorLedgerList"] = action?.response?.rows;
        Data["tripVendorLedgerCount"] = action?.response?.count;
      }
      return Data;
    }
    case GET_EACH_ROUTE: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_EACH_ROUTE_SUCCESS: {
      console.log("afsnaefuae", action);
      return {
        ...state,
        loader: false,
        routeData: action?.payload,
      };
    }
    case CLEAR_TRIP_LEDGER: {
      return {
        ...state,
        tripLedger: {},
        tripLedgerList: [],
        tripLedgerCount: 0,
      };
    }
    case CREATE_TRIP_EXPENSE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case ADD_DRIVER_EXPENSE: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_DRIVER_EXPENSE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_VENDOR_LEDGER: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_VENDOR_LEDGER_SUCCESS: {
      return {
        ...state,
        loader: false,
        vendorLedger: action?.payload,
        vendorLedgerList: action?.payload?.rows,
        vendorLedgerCount: action?.payload?.count,
      };
    }
    case CLEAR_VENDOR_LEDGER: {
      return {
        ...state,
        vendorLedger: {},
        vendorLedgerList: [],
        vendorLedgerCount: 0,
      };
    }
    case EDIT_LEDGER_EXPENSE: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_LEDGER_EXPENSE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_VEHICLE_LAST_TRIP: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_VEHICLE_LAST_TRIP_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case EDIT_TRIP_EXPENSE: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_TRIP_EXPENSE_SUCCESS: {
      console.log("nnnjjjjjjjj", action?.payload);
      return {
        ...state,
        loader: false,
        tripExpense: action?.payload,
      };
    }
    case GET_TRIP_EXPENSE: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_TRIP_EXPENSE_SUCCESS: {
      return {
        ...state,
        loader: false,
        tripExpense: action?.payload,
      };
    }
    case START_TRIP_EXPENSE: {
      return {
        ...state,
        loader: true,
      };
    }
    case START_TRIP_EXPENSE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    ////
    case GET_SIGNED_URL_WITH_KEY: {
      return {
        ...state,
        loader: true,
        singedUrl: action.payload.singedUrl,
      };
    }
    case GET_SIGNED_URL_WITH_KEY_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case UPDATE_LR_IN_TRIP: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_LR_IN_TRIP_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    ////
    default: {
      return state;
    }
  }
};
