import {
  VEHICLE_HISTORY,
  VEHICLE_HISTORY_SUCCESS,
  FULL_VEHICLES_LIST,
  FULL_VEHICLES_LIST_SUCCESS,
  RECHARGE_FASTAG,
  RECHARGE_FASTAG_SUCCESS,
  ADD_VEHICLE,
  ADD_VEHICLE_SUCCESS,
  CLEAR_ALL_FLEET,
  CLEAN_VEHICLE_HISTORY,
  CLEAN_VEHCILE_LOADER,
} from "../storeTypes";
const INIT_STATE = {
  loader: false,
  vehicleReceivableHistory: [],
  vehicleReceivableHistoryList: [],
  fullVehicleList: [],
  rechargeFastag: {},
  addVehicle: {},
  allFleets: {},
  allFleetsList: [],
  dummy: "",
  fleetObject: {},
  addvehicleResp: {},
  fleetHome:{},
  fleetRechargeModal:{},
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VEHICLE_HISTORY: {
      return {
        ...state,
        loader: true,
      };
    }
    case VEHICLE_HISTORY_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleReceivableHistory: action?.payload,
        vehicleReceivableHistoryList: [
          ...state.vehicleReceivableHistoryList,
          action?.payload?.fleets,
        ],
      };
    }
    case CLEAN_VEHICLE_HISTORY: {
      //console.log("red-> fleet-- clean ");
      return {
        ...state,
        loader: false,
        vehicleReceivableHistory: [],
        vehicleReceivableHistoryList: [],
      };
    }
    case FULL_VEHICLES_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case CLEAR_ALL_FLEET: {
      return {
        ...state,
        allFleets: {},
        allFleetsList: [],
      };
    }
    case FULL_VEHICLES_LIST_SUCCESS: {
      console.log("fullVehicleListIsSaga3",action.payload);
      return {
        ...state,
        loader: false,
        allFleets: action.payload,
        allFleetsList: action?.payload?.rows,
      };
    }
    case RECHARGE_FASTAG: {
      return {
        ...state,
        loader: true,
      };
    }
    case RECHARGE_FASTAG_SUCCESS: {
      return {
        ...state,
        loader: false,
        rechargeFastag: action.payload,
      };
    }
    case ADD_VEHICLE: {
      console.log("Manage Vehiccle reducer ka saga tak aaya ki nhi");
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_VEHICLE_SUCCESS: {
      return {
        ...state,
        loader: false,
        addvehicleResp: action?.payload,
      };
    }
    case CLEAN_VEHCILE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default: {
      return state;
    }
  }
};
