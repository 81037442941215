import React, { useState } from "react";
// import "./index.css";
import SvgImage from "../../Icons/Icons";
import { Modal, ModalBody, Button } from "reactstrap";
// import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addTripBulkExcelUpload, addTripBulkExcel,getTripsNew } from "../../../services/Store/Actions/TripsAction";

const AddBulkTrip = (props) => {
  const {
    success,
    message,
    isOpen,
    onHide,
    toggleModal,
    handleClose,
    name,
    toggleModal_newFleetError,
    invoice,
    link,
    rediractionLink,
    mobileNumber,
    emailId,
  } = props;
  const openLink = () => {
    window.open(link, "_blank");
  };

  const [fileSave, setFileSave] = useState(null);
  const [fileName, setFileName] = useState("");
  const [route,setRoute] = useState("")
  const [loader, setLoader] = useState(false);
  const [bulkTransfer, setBulkTransfer] = useState(false);
  const [bulkTransferId, setBulkTransferId] = useState(null);
  const [openText, setOpenText] = useState(false);
  const [isSalary, setIsSalary] = useState(false);
  const dispatch = useDispatch();
  const customId1 = "custom-id-download page";
  const notify1 = () =>
    toast.success("Template was downloaded successfully.", {
      toastId: customId1,
      pauseOnHover: false,
    });
  const customId2 = "custom-id-upload page";
  const notify2 = () =>
    toast.success("Template was downloaded successfully.", {
      toastId: customId2,
      pauseOnHover: false,
    });
  const customId3 = "custom-id-Salary-dispersed";
  const notify3 = () =>
    toast.success("Trips added successfully", {
      toastId: customId3,
      pauseOnHover: false,
    });

  const downloadSalDisbursement = () => {
    setOpenText(!openText);
  };

  const uploadSheet = () => {
    console.log("llllllssss",route)
    dispatch(
        addTripBulkExcelUpload({ NoRoute : route }, (res) => {
        console.log(res);
        setLoader(false);
        if (res.status == 200) {
            window.open(res.data?.data?.url)
            notify2();
            handleClose()
        } else {
          toast.error(res.data?.message || "some error occured");
          handleClose()
        }
      })
    );
  };

  const bulkTransferCall = () => {
    setLoader(true);
    dispatch(
        addTripBulkExcel({ file: fileSave }, (res) => {
        console.log(res);
        setLoader(false);
        if (res.status == 200) {
            console.log("kkkkkkkksuccess")
            dispatch(
                getTripsNew({
                  offset: 0,
                  limit: 10,
                })
              );
        //   const blob = new Blob([res?.data]);
        //   const link = document.createElement("a");
        //   link.href = window.URL.createObjectURL(blob);
        //   link.download = `responseFile.xls`; //name to changed here
        //   link.click();
          notify3();
          handleClose()
        } else {
          toast.error(res.data?.message || "some error occured");
          handleClose()
        }
      })
    );
  };
  // console.log(
  //   "mesaage-------------",
  //   message,
  // message.includes("Error : Recharge limit by PG exceeded left")
  // );
  return (
    <Modal
      isOpen={isOpen}
      onHide={onHide}
      className=" modal-dialog-centered modal-dialog model-width-toast"
      // toggle={toggle}
      aria-labelledby="myModalLabel2"
      // style={{borderRadius : "10px"}}
    >
      <ModalBody
      //   className="transactionModalBody"
      //   style={{ marginTop: "25px" }}
      //   style={{borderRadius : "10px"}}
      >
        <div style={{ width: "auto" }}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "-10px",
              }}
            >
              {/* <FormCheck 
                    label={'Salary'}
                    onChange={()=>{
                        setIsSalary(!isSalary)
                    }}
                    value={isSalary}
                > 
                </FormCheck> */}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="col-lg-6"
                style={{ padding: "10px" }}
                onClick={downloadSalDisbursement}
              >
                <div
                  className="svgContainerUp"
                  style={{ margin: "0 23px", textAlign: "center" }}
                >
                  <SvgImage name="Download" />
                </div>
                <div className="Bold300" style={{ textAlign: "center" }}>
                  Download Template
                </div>
              </div>
              <div className="col-lg-6" style={{ padding: "10px" }}>
                <label for="file-upload2" class="custom-file-upload3">
                  <input
                    id="file-upload2"
                    type="file"
                    onChange={async (e) => {
                      const img = e?.target.files[0];
                      setFileName(img?.name);
                      setFileSave(img);
                      //   console.log("image", img);
                      setOpenText(false);
                      setBulkTransfer(1);
                      // notify2();
                    }}
                  />
                  <div
                    className="svgContainerUp"
                    style={{
                      margin: "0px 23px",
                      backgroundColor: bulkTransfer > 0 ? "antiquewhite" : null,
                      textAlign: "center",
                    }}
                  >
                    <SvgImage name="Upload" />
                  </div>
                  <div className="Bold300" style={{ textAlign: "center" }}>
                    Upload for bulk Trips
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* {bulkTransfer == 1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              <div style={{ margin: ".5rem" }}>
                <a
                  disabled={loader}
                  style={{ margin: ".5rem" }}
                  href={fileSave}
                >
                  {fileSave?.name || "test"}
                </a>
              </div>
              <div style={{}}>
                <input
                  type="text"
                  className=""
                  name="bulkTransferId"
                  placeholder="Enter Unique TransferId"
                  disabled={loader}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setBulkTransferId(e.target.value);
                  }}
                />
              </div>
              <div>
                <Button
                  className="greenButton"
                  style={{ margin: ".5rem" }}
                  onClick={bulkTransferCall}
                  disabled={loader}
                >
                  {" "}
                  Transfer
                </Button>
              </div>
            </div>
          )} */}
          {openText ? (
            <div className="row">
              <div className="col-4">No Of Trips</div>
              <div className="col-4">
                <input
                  type="text"
                  inputmode="numeric"
                  maxlength="19"
                  placeholder="xxxx"
                  onChange={(e) => {
                    console.log("kklllllll",e?.target?.value)
                    setRoute(e?.target?.value)
                  }}
                />
              </div>
              <div className="col-4">
                <button onClick={uploadSheet} type="submit"> submit</button>
              </div>
            </div>
          ) : bulkTransfer ? (
            <div className="row">
              <div className="col-8">{fileName}</div>
              <div style={{ textAlign: "center" }} className="col-4">
                <button  type="submit" onClick={bulkTransferCall}> submit</button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddBulkTrip;
