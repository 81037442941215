import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import LinkFastagIdToVehicle from "../../../Modals/linkFastagIdToVehicle";
import ManageDriverCardType1 from "../../../ManageDriver/SubComponents/ManageDriverTopCard/index";
import ManageVehicleNumbers from "../../SubComponents/manageVehicleNumbers/index";
import SvgImage from "../../../Icons/Icons";
import ShowroomModal from "../ShowRoom";
import {useParams,useNavigate} from 'react-router-dom';
import Requests from "../../Requests";

function ManageVehicleCardType1Handler(props) {
  const dispatch = useDispatch();
  const navigate= useNavigate();

  const [open, setOpen] = useState(false);
  const [textCard, setTextCard] = useState("");
  const [showroomModal, setShowroomModal] = useState(false);
  const [showRequest,setshowRequest] = useState(false);
  const allFleets = useSelector((state) => state?.manageVehicle?.allFleets);
  const { advancedVehicleList, advancedVehicleListCount } = useSelector(
    (state) => state?.fleet
  );
  const showroomModalFunc = () => {
    console.log("invoiceModal", showroomModal);
    setShowroomModal(!showroomModal);
  };

  const showRequests = () => {
    console.log("invoiceModal", showroomModal);
    setshowRequest(!showRequest);
  };
  let { id } = useParams();
  const navigateFunc = () => {
    console.log("idissss",id)
    console.log('fleedId...',id);
    navigate("/VehicleHistory",{state:{
      path: 'fleet',
      id: id,
    }});
  };
  
  console.log("objjjjjjjjjjj", props);
 

  function handleClose() {
    setOpen(false);
  }

  function toggleModal() {
    setOpen(!open);
  }
  const data = [
    {
      number: advancedVehicleListCount,
      text: "Vehicles Registered",
      type: null,
      show: true,
      className: "ManageCustomerCardType-1 firstCard",
    },
    {
      number: advancedVehicleListCount,
      text: "FASTag Not Linked",
      type: "any",
      show: false,
      className: "ManageCustomerCardType-1 centerCard",
    },
    {
      number: advancedVehicleListCount,
      text: "Unassigned Vehicles",
      type: "any",
      show: false,
      className: "ManageCustomerCardType-1 lastCard",
    },
  ];

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {data.map((item) => {
          return (
            <>
              {item?.show ? (
                <>
                  <div className={item.className}>
                    {" "}
                    {/*onClick={()=>{setTextCard(item.text)
                                setOpen(true)}} */}
                    <ManageVehicleNumbers
                      number={item.number}
                      text={item.text}
                      type={item.type}
                      total={advancedVehicleListCount}
                    />
                  </div>
                  {(
                    <div
                      className="ManageDriverCardType1Box"
                      style={{ width: "170px",marginLeft:"5px",marginRight:"5px" }}
                      onClick={showRequests}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <div className="row">
                        <div
                          className="col-5"
                          style={{ padding: "8px 0 0 21px" }}
                        >
                          <div
                            className="outside-Truck-logo-for-total-vehicle"
                            style={{
                              textAlign: "center",
                              paddingTop: "3px",
                              marginLeft: "3px",
                              marginTop: "7px"
                            }}
                          >
                            <SvgImage
                              name="cash1"
                              classname="Truck-logo-for-total-vehicle"
                            />
                          </div>
                        </div>
                        <div
                          className="showroom-for-vehicle-text col-6"
                          style={{ padding: "26px 0px 0px 4px" }}
                        >
                          {"Requests"}
                        </div>
                      </div>
                    </div>
                  ) }
                  {props.regNo ? (
                    <div
                      className="ManageDriverCardType1Box"
                      style={{ width: "170px" }}
                      onClick={showroomModalFunc}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <div className="row">
                        <div
                          className="col-5"
                          style={{ padding: "14px 0 0 21px" }}
                        >
                          <div
                            className="outside-Truck-logo-for-total-vehicle"
                            style={{
                              textAlign: "center",
                              paddingTop: "3px",
                            }}
                          >
                            <SvgImage
                              name="gaurage"
                              classname="Truck-logo-for-total-vehicle"
                            />
                          </div>
                        </div>
                        <div
                          className="showroom-for-vehicle-text col-6"
                          style={{ padding: "26px 0px 0px 4px" }}
                        >
                          {"Showroom"}
                        </div>
                      </div>
                    </div>
                  ) : null}
                   {props.regNo ? (
                    <div
                      className="ManageDriverCardType1Box"
                      style={{ width: "170px",marginLeft:"10px"}}
                      onClick={navigateFunc}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <div className="row">
                        <div
                          className="col-5"
                          style={{ padding: "8px 0 0 21px" }}
                        >
                          <div
                            className="outside-Truck-logo-for-total-vehicle"
                            style={{
                              textAlign: "center",
                              paddingTop: "3px",
                              marginLeft: "3px",
                              marginTop: "7px"
                            }}
                          >
                            <SvgImage
                              name="newCalendar"
                              classname="Truck-logo-for-total-vehicle"
                            />
                          </div>
                        </div>
                        <div
                          className="showroom-for-vehicle-text col-6"
                          style={{ padding: "26px 0px 0px 4px" }}
                        >
                          {"History"}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          );
        })}
        <ShowroomModal
          toggle={showroomModalFunc}
          modal={showroomModal}
          setModal={setShowroomModal}
          regNo={props.regNo}
          fleetId={id}
          // customerId={customerId}
        />

      <Requests
          toggle={showRequests}
          modal={showRequest}
          setModal={setshowRequest}
          regNo={props.regNo}
          fleetId={id}
          // customerId={customerId}
        />
        
      </div>
    </div>
  );
}
export default ManageVehicleCardType1Handler;
