import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../index.css";
import SvgImage from "../../../Icons/Icons";
import { Button, Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import PaymentsModal from "../PaymentModal";

import moment from "moment";
import {
  getLedgerListAction,
  clearAllTrips,
  downloadInvoiceAction,
  uploadReciptAction,
  downloadLedgerAction,
  deleteLedgerAction,
  getAllCustomersAction,
} from "../../../../services/Store/Actions/customerAction";
import Toast from "../../../subComponents/toast";
import { ClipLoader } from "react-spinners";
import {
  getSignedUrlApiAction,
  storeMedia,
} from "../../../../services/Store/Actions/appActions";
import AddingFilter from "../../../Modals/addingFilter";
import InvoiceModal from "../InvoiceModal";
import VendorPayoutModal from "../VendorPayoutModal";
import SelectVendorModal from "../SelectVendorModal";
import { toast, ToastContainer } from "react-toastify";
import ClearInvoiceConfirmationModal from "../ClearInvoiceConfirmationModal";
import {
  getLRList,
  markInvoiceAsPaid,
} from "../../../../services/Store/Actions/TripsAction";
function LadgerComponent(props) {
  const [offset, setOffset] = useState(0);
  let { customerId, customer, handleEditBankDetails, vendorDetails } = props;
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [vendorModal, setVendorModal] = useState(false);
  const [addInvoice, setAddInvoice] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [download, setDownload] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [loaderInvoiceId, setLoaderInvoiceId] = useState("");
  const [downloadLoader2, setDownloadLoader2] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [paymentModal, setPaymentModal] = useState(false);
  const { ledgerList, ledgerCount } = useSelector((state) => state.customer);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [isCheck, setIsCheck] = useState([]);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const [selectVendorModal, setSelectVendorModal] = useState(false);
  const [vendorData, setVendorData] = useState(false);
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  //   const offsetString =
  //   window.location.search === "" ? 0 : window.location.search.split("=")[2];
  // const offsetVal = Number(offsetString);
  //   const [offset, setOffset] = useState(offsetVal);
  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips, setIsTrips] = useState(0);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  useEffect(() => {
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
  }, [authUser, managerControlList]);

  function downloadFunc(startDate, endDate, mode) {
    setDownloadLoader(true);
    dispatch(
      downloadLedgerAction(
        {
          customerId: customerId,
          from: startDate,
          to: endDate,
        },
        (res) => {
          setDownloadLoader(false);
          if (res.status == 200) {
            window.open(res?.data?.data?.url);
          }
        }
      )
    );
  }
  const closing = () => {
    setDownload(false);
  };

  const toggling = () => {
    setDownload(!download);
  };
  useEffect(() => {
    dispatch(
      getLRList({
        customerId: customerId,
        offset: "", // TODO: add offset
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
        isActive: "3",
      })
    );
    return function cleanUp() {
      dispatch(clearAllTrips());
      setOffset(0);
      dispatch(clearAllTrips());
    };
  }, [customerId]);
  console.log("ledgerList", ledgerList);
  const invoiceModalFunc = () => {
    console.log("invoiceModal", invoiceModal);
    if (isCustomers == 3) setInvoiceModal(!invoiceModal);
  };
  const vendorModalFunc = () => {
    if (
      (vendorDetails?.bankAccountNumber && vendorDetails.ifsc) ||
      vendorDetails.vpaId
    ) {
      setSelectVendorModal(!selectVendorModal);
    } else {
      toast.info("Please Add bank details For Payment!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      handleEditBankDetails();
    }
  };

  const handlePaymentModal = () => {
    console.log("paymentModal", paymentModal);
    if (isCustomers == 3) setPaymentModal(!paymentModal);
  };
  const onHide = () => {
    setIsOpen(false);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const pageCount = ledgerCount / 10;
  const handlePageClick = (event) => {
    console.log("Clickess");
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };
  console.log("ledgerCount", ledgerCount);
  const dispatch = useDispatch();
  useEffect(() => {
    if (customerId !== undefined && customerId !== "") {
      dispatch(
        getLedgerListAction({
          customerId: customerId,
          offset: offset,
          search: "",
          transferType:
            activeTab == 0
              ? ""
              : activeTab == 1
              ? "debit"
              : activeTab == 2
              ? "credit"
              : activeTab == 3
              ? "paid"
              : activeTab == 4
              ? "unpaid"
              : "",
          sortAs: "",
          sortBy: "",
          from: from || "",
          to: to || "",
        })
      );
    }
  }, [activeTab, offset, from, to]);
  useEffect(() => {
    dispatch(
      getAllCustomersAction({
        offset: 0,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 4,
      })
    );
    return function cleanUp() {};
  }, [search]);
  // search value
  return (
    <div className="box2">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="box3 d-flex justify-content-between">
        <div className="d-flex">
          <div className="">
            <div className="headingText">From:</div>
            <input
              type="date"
              className="text-input-placeholder mt-2"
              placeholder={"Select Date"}
              onChange={(event) => {
                setFrom(event.target.value);
              }}
              max={to}
              value={from}
              // onkeypress={setStorage}
            />
          </div>
          <div className="ms-4">
            <div className="headingText">To:</div>
            <input
              type="date"
              className="text-input-placeholder mt-2"
              placeholder={"Select Date"}
              onChange={(event) => {
                setTo(event.target.value);
              }}
              min={from}
              value={to}
              // onkeypress={setStorage}
            />
          </div>
        </div>
        <div className="d-flex align-items-end">
          {/* <button
            className={
              "Medium text2 greenOutlineButton d-flex align-items-center "
            }
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={vendorModalFunc}
          >
            Vendor payout
          </button> */}
          <button
            className={
              "Medium text2 greenButton ms-3 d-flex align-items-center"
            }
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={invoiceModalFunc}
          >
            Add / Create Invoice
          </button>
          <button
            className={
              "Medium text2 greenButton ms-3 d-flex align-items-center"
            }
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={handlePaymentModal}
          >
            Collect Payment
          </button>
        </div>
      </div>
      <div className="d-flex box3 pt-0 justify-content-between">
        <div className="d-flex">
          <button
            className={
              activeTab == 0
                ? "Medium heading3 greenButton rounded-pill"
                : "Medium heading3 greenOutlineButton rounded-pill"
            }
            style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
            onClick={() => {
              setActiveTab(0);
              setOffset(0);
            }}
          >
            All
          </button>
          <button
            className={
              activeTab == 1
                ? "Medium heading3 greenButton rounded-pill ms-3"
                : "Medium heading3 greenOutlineButton rounded-pill ms-3"
            }
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={() => {
              setActiveTab(1);
              setOffset(0);
            }}
          >
            Only Debit
          </button>
          <button
            className={
              activeTab == 2
                ? "Medium heading3 greenButton rounded-pill ms-3"
                : "Medium heading3 greenOutlineButton rounded-pill ms-3"
            }
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={() => {
              setActiveTab(2);
              setOffset(0);
            }}
          >
            Only Credit
          </button>
          <button
            className={
              activeTab == 3
                ? "Medium heading3 greenButton rounded-pill ms-3"
                : "Medium heading3 greenOutlineButton rounded-pill ms-3"
            }
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={() => {
              setActiveTab(3);
              setOffset(0);
            }}
          >
            Paid Invoices
          </button>
          <button
            className={
              activeTab == 4
                ? "Medium heading3 greenButton rounded-pill ms-3"
                : "Medium heading3 greenOutlineButton rounded-pill ms-3"
            }
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={() => {
              setActiveTab(4);
              setOffset(0);
            }}
          >
            UnPaid Invoices
          </button>
        </div>
        <div className="d-flex align-items-center">
          <button
            className=" d-flex align-items-center text2 ms-3 headingText"
            style={{
              border: 0,
              backgroundColor: "#FFFFFF",
            }}
            onClick={() => {
              setDownload(true);
            }}
          >
            <div className="padding8R">
              <SvgImage name="downloadImgV2" />
            </div>
            Download Ledger
          </button>
        </div>
      </div>
      <div className="table-for-vehicle-receivable">
        <Table className="vehicleAssignedHistory">
          <thead
            className="th align-middle text-center"
            style={{ zIndex: 99, textAlignLast: "center" }}
          >
            <tr className="table-header-customer-details p-0 ">
              {/* <th className="p-0"></th> */}
              <th className="p-0"> Date</th>
              {/* <th className="p-0 col-2">Description</th> */}
              <th className="p-0">Remarks</th>
              <th className="p-0">Receipt</th>
              <th className="p-0">Debit</th>
              <th className="p-0">Credit</th>
              <th className="p-0">Closing Balance </th>
              <th className="p-0">Status </th>
              <th className="p-0 paddingimp0"></th>
            </tr>
          </thead>
          {/* <div className="tbodyVehicle-div"> */}
          {ledgerCount == 0 ? (
            <div className="padding32 textCenter">
              <SvgImage name="TripsMap" />
              <div className="Bold heading1 padding24T padding16B FontSuperBold primaryBlack">
                Easy Accounting
              </div>
              <div className="greyFont heading3">
                Create Easy invoices from generated Trips
              </div>
              <div className="greyFont heading3 padding32B">
                Collect payments and auto track them with Logipe
              </div>
              {/* <div className="d-flex justify-content-center"> */}
              <button
                className={"Medium heading3 greenButton"}
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                onClick={invoiceModalFunc}
              >
                Add / Create Invoice
              </button>
              <button
                className={"Medium heading3 greenButton ms-4"}
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                onClick={handlePaymentModal}
              >
                Collect Payment
              </button>
              {/* </div> */}
            </div>
          ) : (
            <tbody className="tbodyVehicle" style={{ textDecoration: "none" }}>
              {ledgerList?.map((items, i) => {
                console.log("-----------", i);
                return (
                  <>
                    <tr className="table-row-customer-details2">
                      {/* <td></td> */}
                      <td> {moment(items?.createdAt).format("DD-MM-YYYY")}</td>
                      {/* <td className="col-2">{items?.description || "NA"}</td> */}
                      <td>
                        {items?.Invoice?.invoiceNumber}{" "}
                        {items?.Invoice ? "-" : ""} {items?.remarks || "NA"}
                      </td>
                      <td>
                        <div>
                          {items?.receipt == "" || items?.receipt == null ? (
                            <>
                              {downloadLoader &&
                              loaderInvoiceId == items.invoiceId ? (
                                <div className=" downloadText p-0 align-items-center justify-content-center">
                                  <ClipLoader
                                    color={"#485B7C"}
                                    loading={true}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </div>
                              ) : (
                                <Button
                                  className="add-attach-for-fastag-linked-vehicle-new me-3"
                                  type="button"
                                  style={{
                                    padding: 0,
                                  }}
                                >
                                  <SvgImage
                                    name="uploadInvoices"
                                    height="21"
                                    width="21"
                                  />
                                  <input
                                    type="file"
                                    // multiple
                                    // accept="image/*"
                                    accept="image/*"
                                    className="add-lr-attachment p-0"
                                    style={{
                                      height: 16,
                                    }}
                                    onChange={(e) => {
                                      const img = e?.target.files[0];
                                      console.log("image", img?.name);
                                      // e.preventDefault();
                                      setDownloadLoader(true);
                                      setLoaderInvoiceId(items.invoiceId);
                                      // e.preventDefault();

                                      if (img == null) {
                                        //clear image
                                        //for now  only one supported
                                      } else {
                                        console.log("upload", {
                                          img: img,
                                          uploadType: "invoice",
                                          isRes: true,
                                        });
                                        dispatch(
                                          storeMedia(
                                            {
                                              img: img,
                                              uploadType: "invoice",
                                              isRes: false,
                                            },
                                            (res) => {
                                              if (res?.url) {
                                                console.log("opt", res);
                                                dispatch(
                                                  uploadReciptAction(
                                                    {
                                                      invoiceId:
                                                        items.invoiceId,
                                                      invoiceCopy: res?.url,
                                                    },
                                                    (res) => {
                                                      setLoaderInvoiceId("");
                                                      setDownloadLoader(false);
                                                      if (res.status == 200) {
                                                        dispatch(
                                                          getLedgerListAction({
                                                            customerId:
                                                              customerId,
                                                            offset: offset,
                                                            search: "",
                                                            transferType:
                                                              activeTab == 0
                                                                ? ""
                                                                : activeTab == 1
                                                                ? "debit"
                                                                : "credit",
                                                            sortAs: "",
                                                            sortBy: "",
                                                            from: from || "",
                                                            to: to || "",
                                                          })
                                                        );
                                                      }
                                                    }
                                                  )
                                                );
                                              }
                                            }
                                          )
                                        );

                                        // setFieldValue("amount", values.amount, true);
                                      }
                                    }}
                                  />
                                </Button>
                              )}
                            </>
                          ) : (
                            <div className="d-flex justify-content-center align-items-center">
                              {downloadLoader2 &&
                              loaderInvoiceId == items.invoiceId ? (
                                <div className=" downloadText p-0">
                                  <ClipLoader
                                    color={"#485B7C"}
                                    loading={true}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </div>
                              ) : (
                                <button
                                  style={{
                                    border: 0,
                                    backgroundColor: "#fff",
                                  }}
                                  onClick={() => {
                                    setDownloadLoader2(true);
                                    setLoaderInvoiceId(items.invoiceId);
                                    dispatch(
                                      getSignedUrlApiAction(
                                        { key: items.receipt },
                                        (res) => {
                                          setDownloadLoader2(false);
                                          setLoaderInvoiceId("");
                                          if (res.status == 200) {
                                            window.open(res?.data?.data);
                                          }
                                        }
                                      )
                                    );
                                  }}
                                >
                                  <SvgImage name="viewInvoices" />
                                </button>
                              )}
                              {console.log(
                                "loaderInvoiceId == items.invoiceId",
                                loaderInvoiceId,
                                items
                              )}
                              {downloadLoader &&
                              loaderInvoiceId == items.invoiceId ? (
                                <div className=" downloadText p-0 ms-2">
                                  <ClipLoader
                                    color={"#485B7C"}
                                    loading={true}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </div>
                              ) : (
                                <button
                                  style={{
                                    marginLeft: 10,
                                    border: 0,
                                    backgroundColor: "#fff",
                                  }}
                                  onClick={() => {
                                    setDownloadLoader(true);
                                    setLoaderInvoiceId(items.invoiceId);
                                    dispatch(
                                      getSignedUrlApiAction(
                                        { key: items.receipt },
                                        (res) => {
                                          setDownloadLoader(false);
                                          setLoaderInvoiceId("");
                                          if (res.status == 200) {
                                            window.open(res?.data?.data);
                                          }
                                        }
                                      )
                                    );
                                  }}
                                >
                                  <SvgImage name="downloadImgV2" />
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="dabitTxn">
                        {items.transferType == "debit" ? items?.amount : "-"}
                      </td>
                      <td className="craditTxn">
                        {items.transferType == "credit" ? items?.amount : "-"}
                      </td>
                      <td>{items?.closingBal}</td>
                      <td>
                        {items?.invoiceId ? (
                          items?.Invoice?.cleared ? (
                            "Cleared"
                          ) : (
                            <div
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecorationLine: "underline",
                              }}
                              onClick={() => {
                                setIsOpen(true);
                                console.log("sadfgf", items);
                                setInvoiceId(items?.invoiceId);
                              }}
                            >
                              Not Cleared
                            </div>
                          )
                        ) : (
                          <div>-</div>
                        )}
                      </td>
                      <td style={{ width: "50px" }} className="paddingimp0 ">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            flex: 1,
                            paddingTop: "24px",
                            paddingBottom: "24px",
                          }}
                        >
                          {/* <button
                            style={{
                              border: 0,
                              backgroundColor: "#fff",
                            }}
                            onClick={() => {}}
                          >
                            <SvgImage name="editLogo" width="22" height="22" />
                          </button> */}
                          {deleteLoader &&
                          loaderInvoiceId == items.invoiceId ? (
                            <div className=" downloadText p-0 ms-2">
                              <ClipLoader
                                color={"#485B7C"}
                                loading={true}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div>
                          ) : (
                            <button
                              style={{
                                // marginLeft: 16,
                                border: 0,
                                backgroundColor: "#fff",
                              }}
                              onClick={() => {
                                if (isCustomers == 3) {
                                  setDeleteLoader(true);
                                  dispatch(
                                    deleteLedgerAction(
                                      {
                                        customerId: customer.customerId,
                                        ledgerId: items.ledgerId,
                                      },
                                      (res) => {
                                        setDeleteLoader(false);
                                        if (res.status == 200) {
                                          dispatch(
                                            getLedgerListAction({
                                              customerId: customerId,
                                              offset: 0,
                                              search: "",
                                              transferType:
                                                activeTab == 0
                                                  ? ""
                                                  : activeTab == 1
                                                  ? "debit"
                                                  : "credit",
                                              sortAs: "",
                                              sortBy: "",
                                              from: from || "",
                                              to: to || "",
                                            })
                                          );
                                        }
                                      }
                                    )
                                  );
                                }
                              }}
                            >
                              <SvgImage name="deleteIcon2" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          )}

          {/* </div> */}
        </Table>
        {/* {
          olderHistoryStatus?
          <div className="olderTransactions-div" onClick={handleOlderTransactions}>
          Older History
          </div>:
        <></>
        } */}
        {pageCount > 1 ? (
          <div className="reactPagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          <></>
        )}
        <InvoiceModal
          toggle={invoiceModalFunc}
          modal={invoiceModal}
          setModal={setInvoiceModal}
          customerId={customerId}
        />

        <PaymentsModal
          toggle={handlePaymentModal}
          modal={paymentModal}
          setModal={setPaymentModal}
          offset={offset}
          customerId={customerId}
        />
        <VendorPayoutModal
          toggle={() => {
            setVendorModal(false);
          }}
          modal={vendorModal}
          setModal={setVendorModal}
          customerId={customerId}
          vendorData={vendorData}
        />
        {selectVendorModal && (
          <SelectVendorModal
            open={selectVendorModal}
            onHide={() => {
              setSelectVendorModal(false);
            }}
            toggleModal={() => {
              setSelectVendorModal(!selectVendorModal);
            }}
            handleClose={() => {
              setSelectVendorModal(false);
            }}
            onClickItem={(data) => {
              setVendorData({});
              setVendorData(data);
              setVendorModal(true);
              setSelectVendorModal(false);
            }}
            search={search}
            setSearch={setSearch}
          />
        )}
        {/* {toastObject.toastStatus ? (
          <Toast
            success={toastObject?.status}
            message={toastObject?.message}
            toggleModal={!toastObject?.toastStatus}
            isOpen={toastObject?.toastStatus}
            name="home"
            handleClose={() => {
              // dispatch(
              //   toastStatus({
              //     toastStatus: false,
              //     message: "",
              //     status: true,
              //   })
              // );
              // if (!toastObject?.toastStatus)
              window.location = `/Customers/${customer.customerId}`;
            }}
          />
        ) : (
          <></>
        )} */}
      </div>
      <ClearInvoiceConfirmationModal
        isOpen={isOpen}
        onHide={onHide}
        toggleModal={toggleModal}
        handleClose={handleClose}
        onPress={() => {
          dispatch(
            markInvoiceAsPaid(
              {
                invoiceId: invoiceId,
              },
              (res) => {
                if (res.status == 200) {
                  setInvoiceId("");
                  handleClose();
                  dispatch(
                    getLedgerListAction({
                      customerId: customerId,
                      offset: offset,
                      search: "",
                      transferType:
                        activeTab == 0
                          ? ""
                          : activeTab == 1
                          ? "debit"
                          : "credit",
                      sortAs: "",
                      sortBy: "",
                      from: from || "",
                      to: to || "",
                    })
                  );
                }
              }
            )
          );
        }}
      />
      <AddingFilter
        active={download}
        toggleModal={toggling}
        handleClose={closing}
        func={downloadFunc}
        name="Download Leders"
      />
    </div>
  );
}
export default LadgerComponent;
