import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../Icons/Icons";
import DatePicker from "react-datepicker";
import { getDocument } from "../../../services/Store/Actions/fastagAction";

// import { DatePickerField } from "../../../core/inputs/input";
// import { useRef } from "react";
// import AddFreight from "../../../TripsMainV2/Components/AddFreight";
// import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
// import {
//   createTrip,
//   getCityAndState,
// } from "../../../../services/Store/Actions/TripsAction";
// import moment from "moment";
// import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
// import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
// import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
// import ToastV2 from "../../../subComponents/toastV2";
// import { toastStatus } from "../../../../services/Store/Actions/appActions";
// import CollectPayment from "../CollectPayment";
// import AddPayment from "../AddPayment";
// import CreateInvoice from "../CreateInvoice";
// import AddInvoice from "../AddInvoice";

function ToolAndDocs(props) {
  const { toggle, modal, setModal, regNo,title,fleetId,isActive } = props;
  const [active, setactive] = useState('1');
  const { documents } = useSelector((state) => state.fastag);
  console.log('documentsdocuments',isActive)
  const dispatch = useDispatch();


  const handleClose = () => {
    setModal(!modal);
    setactive('1')
  };

  
  useEffect(() => {
  
    dispatch(getDocument({
      fleetId: fleetId,
      isActive:active,
      type:title=='Tool'?'1':'2'
    }))
    return function cleanUp() {
     console.log("callsse")
    };
  }, [title,active,fleetId]);
  
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
          // style={{height : "75vh"}}
        >
          <div>
            <div className="closeBtnWithBackground" onClick={handleClose}>
              <SvgImage
                classname="close-btn-text"
                style={{ width: "10%", height: "10%" }}
                name="crossBtnWIthBackground"
                width="20%"
                height="20%"
              />
            </div>
            <div
              style={{ marginTop: " 10px" }}
              className="row"
              //   className="PGCollectionBox"
            >
              <div className="showroomText col-12">{title}: {regNo}</div>             
            </div>
            <div
              style={{ marginTop: " 10px" }}
              className="row"
              //   className="PGCollectionBox"
            >
             {title=='Tool' && <Button style={{
                marginBottom:"10px"
              }} onClick={()=>{
                if(active=='1')
                setactive('0')
              else
                setactive('1')
              }}>{active=='1'?"Show History":"Show Current"}</Button> }            
            </div>
            {/* "initialImage": "https://storage.googleapis.com/logipe-assets/9483532972/1705909499677-.jpg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=storage%40server-369506.iam.gserviceaccount.com%2F20240125%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240125T080030Z&X-Goog-Expires=5400&X-Goog-SignedHeaders=host&X-Goog-Signature=8bc5832b914128fbb8c5a9e1a70e831cf79681fba8309435948823f51146340857f2ca548bb2a92979f75091d9be28f332b0366bc50f68a0834ccdf3108a2ac2c10aa3a41344270866d7afb860c913b9c75bc852ad4fb05a49c19d36b47338e806b8c2c8b3490632ab14566c322f053951b84d21e0189a0135f4b2cb26cf0b922a9095aa6bde8d7c5539070df6e3899db29bbc09e74d4bc5334af46948b05076a26cdaa9da67ae68ecf9736f636590876381af20995b22aee69377dc2864a165551d44b041f7613dac09fcb6003b8931144f62720e8af5ed44f9bca7b3fd40aaeaa35b7b7ce123b104bfdc11d17a413c96f3e267fef915888408e863bd0afa56",
                "finalImage": "https://storage.googleapis.com/logipe-assets/9483532972/1705909627743-.jpg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=storage%40server-369506.iam.gserviceaccount.com%2F20240125%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240125T080030Z&X-Goog-Expires=5400&X-Goog-SignedHeaders=host&X-Goog-Signature=7ee1f22bb17d515494b25924acae212a5473ef60c048387078ae2ce72c045e472e8e52aa2d4cbd03d34c19ef104292c2e2bcf35514d0348a12a60de83caac0b443c7a6a1757b6bad2a218fa450291089f4ad7d8dcc2a64f6619b90cc8437b81477978c885dbdf5cac3e865855a368289f9c4ccafb7ad594513410ea289b5363dd557c9fdae614906646abc6ed1356be979d4990ecbc016d93eb099c93cbf000b0d3046233116f1fd32f9f27787439e5bf7e67c3ed10733d8ca86fe781cdc73b7a4c21831f51b06972e88690386e637c6f715e7d19436c13c1cc737d1a5c93c15f2ad8c8d85e39b16dd422c39c0591d83aa1db60dacba00009bc868b6e1f6226a", */}
            
            {
            documents?.tools?.map((item,index)=>{
              
            return<>
            <div style={{
              // marginLeft: "250px",
              flexDirection:'row'
            }}>
                    <div className="detailImgContainer row">
                        <div className="imageColumn col-6">
                            <img src={item?.image} className="imgTyre"
                            style={{ height: "100%", alignItems: "centre" }} />
                        </div>
                        <div className="col-6">
                          <a style={{
                            color:'Blue'
                          }}>
                         {"Name: "}
                          </a>
                           {item?.key}
                          <br />
                          <a style={{
                            color:'Blue'
                          }}>
                         {" Driver Name: "}
                          </a>
                           {item?.driverName}
                          <br />

                          <a style={{
                            color:'Blue'
                          }}>
                         {" Expiry Date: "}
                          </a>
                           {item?.expireDate}
                          <br />

                          <a style={{
                            color:'Blue'
                          }}>
                         {" Kms Upto: "}
                          </a>
                           {item?.kms}
                          <br />


                          </div> 
                        
                      </div>
                      
                      
            </div>
            

            </>
            
            })
            }
            {documents?.tools?.length==0 ?  <div className="detailImgContainer row">
                        <div className="imageColumn col-6">
                            <img src={''} className="imgTyre"
                            style={{ height: "100%", alignItems: "centre" }} />
                        </div>
                        


                         
                      </div>:null}
          </div>
        </ModalBody>
      </Modal>
      {/* <CreateInvoice
        toggle={createInvoice}
        modal={invoiceModal}
        setModal={setInvoiceModal}
        customerId={customerId}
        isCheckAll={isCheckAll}
        setIsCheckAll={setIsCheckAll}
        isCheck={isCheck}
        setIsCheck={setIsCheck}
        customer={customer}
      />
      <AddInvoice
        toggle={addInvoiceFunc}
        modal={addInvoice}
        setModal={setAddInvoice}
      /> */}
    </div>
  );
}

export default ToolAndDocs;
