import React, { useState, useEffect, useRef } from "react";
import SvgImage from "../../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import {
  cleanLoginLoader,
  handleLoginSuccess,
  sendOtp,
  verifyOtp,
} from "../../services/Store/Actions/LoginAction";
import {
  storeDataToAsyncStorage,
  getDataFromAsyncStorage,
} from "../../services/Utils/functions";
import { useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";
import { sendAnalitics } from "../../services/Store/Actions/appActions";
import { Mixpanel } from "../../Mixpanel";

const EnterOTPForm = (props) => {
  const validationSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is Required"),
  });

  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state?.loginReducer);
  const [timer, setTimer] = useState("00:00");
  const [error, setError] = useState(false);
  const Ref = useRef(null);
  const Navigate = useNavigate();

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    //const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        //(hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e, val) => {
    setTimer(val);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = (value) => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + value);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime(30), "00:30");
  }, []);

  function handleResend() {
    //console.log ("yeh ph no", props.phoneNo);
    dispatch(sendAnalitics("ResendOTP_web"));
    setTimer("00:00");
    dispatch(
      sendOtp(
        {
          phone: props.phone,
          resend: "any",
        },
        (res) => {
          if (res?.data?.type === "success") {
            console.log("resend ho gaya");
          }
        }
      )
    );
    clearTimer(getDeadTime(60), "00:60");
  }
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        otp: "",
        phone: props.phone,
      }}
      onSubmit={(values, actions) => {
        //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
        console.log("valuessss", values);
        dispatch(sendAnalitics("Submit_web", values));
        dispatch(
          verifyOtp(values, async (res) => {
            if (res.status == 401) {
              actions.setFieldError(
                "otp",
                "Incorrect OTP. Please check again."
              );
              dispatch(cleanLoginLoader());
            } else if (res?.data?.data?.user?.userId) {
              console.log("snjsbjsbjsb",res.data?.data)
              console.log("TOKEN>>>", res.data?.data?.user?.type);
              if (res.data?.data?.user?.type === "2") {
                storeDataToAsyncStorage("_token", res.data?.data.token);
                storeDataToAsyncStorage("_user", res.data?.data);
                console.log("_branchName_branchName",res.data?.data?.branch?.branchName,res.data?.data?.branch)
                storeDataToAsyncStorage("_branchName", res.data?.data?.branch?.branchName);
                storeDataToAsyncStorage("_branchId", res.data?.data?.branch?.branchId);
                storeDataToAsyncStorage(
                  "_refreshToken",
                  res.data?.data.refreshToken
                );
                const x = Mixpanel.getDistinctId();
                if (x != res?.data?.data?.user?.userId) {
                  Mixpanel.alias(res?.data?.data?.user?.userId, x);
                }
                Mixpanel.identify(res.data?.data?.user?.userId);
                Mixpanel.people.set({
                  Phone: res.data?.data?.user?.phone,
                  $name: res.data?.data?.user?.fullName,
                  userId: res?.data?.data?.user?.userId,
                });
                //dispatch(getOwnerAccountDetails(),res => {});
                dispatch(handleLoginSuccess(res.data?.data));
                //dispatch(getOwnerDetails());

                Navigate("/home");
              } else if (res.data?.data?.user?.type === "1") {
                //setError(true);
                actions.setFieldError(
                  "otp",
                  "Cannot login through Driver's number"
                );
              } else if (res.data?.data?.user?.type === "3") {
                //setError(true);
                storeDataToAsyncStorage("_token", res.data?.data.token);
                storeDataToAsyncStorage("_user", res.data?.data);
                storeDataToAsyncStorage("_branchName", res.data?.data?.branch?.branchName);
                storeDataToAsyncStorage("_branchId", res.data?.data?.branch?.branchId);
                storeDataToAsyncStorage(
                  "_refreshToken",
                  res.data?.data.refreshToken
                );
                const x = Mixpanel.getDistinctId();
                if (x != res?.data?.data?.user?.userId) {
                  Mixpanel.alias(res?.data?.data?.user?.userId, x);
                }
                Mixpanel.identify(res.data?.data?.user?.userId);
                Mixpanel.people.set({
                  Phone: res.data?.data?.user?.phone,
                  $name: res.data?.data?.user?.fullName,
                  userId: res?.data?.data?.user?.userId,
                });
                //dispatch(getOwnerAccountDetails(),res => {});
                dispatch(handleLoginSuccess(res.data?.data));
                //dispatch(getOwnerDetails());

                Navigate("/home");
              }
            } else if(res.status == 429) {
              //setError(true);
              actions.setFieldError(
                "otp",
                "Please Wait 2 minutes and Try Again."
              );
            } else {
              //setError(true);
              actions.setFieldError(
                "otp",
                "Kindly register yourself to enjoy our services."
              );
            }
          })
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} className="InputForm">
          <FormGroup className="formPosition">
            <div className="Regular text3 greyFont">Welcome! 👋</div>
            <div className="heading1 semiBold">OTP verification</div>
            <div className="Regular text3 padding8T headText2" s>
              Enter the OTP sent to +91 {props.phone}
              <div
                className="editIcon"
                onClick={() => {
                  dispatch(sendAnalitics("ChangeNumber_web"));
                  props.setStatus("0");
                }}
              >
                <SvgImage name="editLogo" width="18" height="18" />
              </div>
            </div>
            <div className="otpInput">
              <PinInput
                length={4}
                initialValue=""
                secret={false}
                onChange={handleChange("otp")}
                type="numeric"
                value={values.otp}
                onBlur={handleBlur("otp")}
                touched={touched.otp}
                errors={errors.otp}
                inputMode="number"
                style={{
                  padding: "10px 0px 10px 0px",
                }}
                inputStyle={{
                  borderBottom: "1px solid #C8C8CA",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  marginLeft: "45px",
                  marginRight: "0px",
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                focus={true}
              />
              {/* <input
                  type="text"
                  id="otp"
                  className="input-body-3"
                  name="otp"
                  value={values.otp}
                  touched={touched.otp}
                  errors={errors.otp}
                  onBlur={handleBlur("otp")}
                  onChange={handleChange("otp")}
                  style={{ textAlign: "left", marginLeft: "0px" }}
                  placeholder="Phone Number"
                  maxLength="10"
                ></input> */}
            </div>
            <div className="padding16T text4">
              Resend OTP in <span className="greenText">{timer}</span>
            </div>
            <div
              className="text4"
              style={{
                margin: "0.3rem 0 0 0",
                color: "red",
                textAlign: "left",
              }}
            >
              {errors.otp && touched.otp ? <div>{errors.otp}</div> : null}
            </div>
            {/* <div className="redColor" style={{visibility:error?"visible":"hidden"}}>
              {error?"Cannot login through Driver's number":null}</div> */}
            <Button
              type="submit"
              className="loginButton"
              style={{
                marginTop: "1.5rem",
                background:
                  values.otp.length < 4
                    ? "#485B7C"
                    : loader
                    ? "#EFEFEF"
                    : "#485B7C",
                color:
                  values.otp.length < 4
                    ? "white"
                    : loader
                    ? "#B5B5B5"
                    : "#ffffff",
              }}
              disabled={loader ? true : false}
            >
              {loader ? "Please Wait" : "Verify OTP"}
            </Button>
            <div
              className="bottomTextLogin padding16T Regular text4"
              style={{ color: "#485B7C" }}
            >
              Didn’t recieve the code?
              <span
                style={{
                  color: timer === "00:00" ? "#485B7C" : "#B5B5B5",
                  cursor: timer === "00:00" ? "pointer" : "default",
                  paddingLeft: "0.5rem",
                }}
                className="Medium"
                onClick={handleResend}
              >
                Resend
              </span>
            </div>
            <div
              className="Regular heading4 col-12 padding16"
              style={{ textAlign: "center" }}
            >
              Don't have an account?{" "}
              <span
                className="semiBold "
                style={{ color: "#485B7C", cursor: "pointer" }}
                onClick={() => {
                  props.setIsRegistered(false);
                  props.setStatus("0");
                }}
              >
                Sign Up
              </span>
            </div>
            {/* <div className="bottomTextLogin padding16T Regular text4">
              <span className="bottomTextSpan Medium"
                onClick={()=>{
                  props.handleTermShow();
                }}>T&C </span> and{" "}
              <span className="bottomTextSpan Medium"
                onClick={()=>{
                  props.handlePrivacyShow();
                }} >Privacy Policy</span> apply
            </div> */}
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default EnterOTPForm;
