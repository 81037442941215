import {
  GET_FASTAG_WALLET_BALANCE,
  GET_FASTAG_WALLET_BALANCE_SUCCESS,
  GET_FASTAG_WALLET_TRANSACTION,
  GET_FASTAG_WALLET_TRANSACTION_SUCCESS,
  CLEAN_FASTAG_WALLET_TRANSACTION,
  TRANSFER_FASTAG_AMOUNT,
  TRANSFER_FASTAG_AMOUNT_SUCCESS,
  FASTAG_ERROR,
  GET_FASTAG_TRANSACTION_HISTORY,
  GET_FASTAG_TRANSACTION_HISTORY_SUCCESS,
  GET_VEHICLE_DETAILS_BY_VEHICLE_ID,
  GET_VEHICLE_DETAILS_BY_VEHICLE_ID_SUCCESS,
  GENERATE_FASTAG_HISTORY_STATEMENT,
  GENERATE_FASTAG_HISTORY_STATEMENT_SUCCESS,
  GET_VEHICLE_DETAILS,
  GET_VEHICLE_DETAILS_SUCCESS,
  CLEAN_VEHICLE_DETAILS,
  REISSUE_AVAILABLE,
  REISSUE_AVAILABLE_SUCCESS,
  SEND_FASTAG_OTP,
  SEND_FASTAG_OTP_SUCCESS,
  VERIFY_FASTAG_OTP,
  VERIFY_FASTAG_OTP_SUCCESS,
  FASTAG_ADD_CUSTOMER,
  FASTAG_ADD_CUSTOMER_SUCCESS,
  TAG_REISSUE,
  TAG_REISSUE_SUCCESS,
  REGISTER_YESBANK,
  REGISTER_YESBANK_SUCCESS,
  TOGGLE_AUTO_RECHARGE_SUCCESS,
  TOGGLE_AUTO_RECHARGE,
  CLEAN_FASTAG_REDUCER,
  AUTO_RECHARGE_MULTIPLE_SUCCESS,
  AUTO_RECHARGE_MULTIPLE,
  CREATE_ORDER_FOR_FLEET,
  CREATE_ORDER_FOR_FLEET_SUCCESS,
  CLEAN_FASTAG_TRANSACTION_HISTORY,
  IDFC_KYC_SEND_OTP,
  IDFC_KYC_SEND_OTP_SUCCESS,
  IDFC_KYC_VERIFY_OTP,
  IDFC_KYC_VERIFY_OTP_SUCCESS,
  IDFC_KYC_SAVE_IMAGE,
  IDFC_KYC_SAVE_IMAGE_SUCCESS,
  DRIVER_VEHICLE_HISTORY,
  DRIVER_VEHICLE_HISTORY_CLEAN,
  DRIVER_VEHICLE_HISTORY_SUCCESS,
  GET_TOOL,
  GET_TOOL_SUCCESS,
  GET_DOCUMENT,
  GET_DOCUMENT_SUCCESS,
  GET_CUSTOM_REQUEST,
  GET_CUSTOM_REQUEST_SUCCESS,
  GET_CUSTOM_REQUEST_CLEAN,
  UPDATE_CUSTOM_REQUEST,
  UPDATE_CUSTOM_REQUEST_SUCCESS,
  DELETE_CUSTOM_REQUEST,
  DELETE_CUSTOM_REQUEST_SUCCESS
} from "../storeTypes";

export function getFastagWalletBalance() {
  return {
    type: GET_FASTAG_WALLET_BALANCE,
  };
}

export function getFastagWalletBalanceSuccess(data) {
  return { type: GET_FASTAG_WALLET_BALANCE_SUCCESS, payload: data };
}

export function getFastagWalletTransaction(payload) {
  return {
    type: GET_FASTAG_WALLET_TRANSACTION,
    payload,
  };
}

export function getFastagWalletTransactionSuccess(data) {
  return { type: GET_FASTAG_WALLET_TRANSACTION_SUCCESS, payload: data };
}

export function cleanFastagWalletTransaction() {
  return { type: CLEAN_FASTAG_WALLET_TRANSACTION };
}

export const transferFastagTransferAmount = (obj, callback) => {
  return {
    type: TRANSFER_FASTAG_AMOUNT,
    payload: obj,
    callback,
  };
};
export const transferFastagTransferAmountSuccess = (msg) => {
  return {
    type: TRANSFER_FASTAG_AMOUNT_SUCCESS,
    msg,
  };
};

export function fastagError() {
  return { type: FASTAG_ERROR };
}

export const getFastagTransactionHistory = (fleetId, data) => {
  return {
    type: GET_FASTAG_TRANSACTION_HISTORY,
    fleetId,
    payload: data,
  };
};
export const getFastagTransactionHistorySuccess = (payload) => {
  return {
    type: GET_FASTAG_TRANSACTION_HISTORY_SUCCESS,
    payload,
  };
};

export function getVehicleDetailsByFleetId(id) {
  return {
    type: GET_VEHICLE_DETAILS_BY_VEHICLE_ID,
    payload: id,
  };
}

export function getVehicleDetailsByFleetIdSuccess(data) {
  return { type: GET_VEHICLE_DETAILS_BY_VEHICLE_ID_SUCCESS, payload: data };
}

export function getVehicleDetails(id) {
  return {
    type: GET_VEHICLE_DETAILS,
    payload: id,
  };
}

export function getVehicleDetailsSuccess(data) {
  return { type: GET_VEHICLE_DETAILS_SUCCESS, payload: data };
}


export function cleanVehicleDetails() {
  return { type: CLEAN_VEHICLE_DETAILS };
}



export function getFastagWalletStatement(data,callback) {
  console.log("fatagw", data);
  return {
    type: GENERATE_FASTAG_HISTORY_STATEMENT,
    payload: data,
    callback
  };
}
export function getFastagWalletStatementSuccess(data) {
  return {
    type: GENERATE_FASTAG_HISTORY_STATEMENT_SUCCESS,
    payload: data,
  };
}


export function reissueAvailableAction(payload) {
  return {
    type: REISSUE_AVAILABLE,
    payload,
  };
}

export function reissueAvailableActionSuccess(data) {
  return { type: REISSUE_AVAILABLE_SUCCESS, payload: data };
}


export function sendFastagOTPAction(payload) {
  return {
    type: SEND_FASTAG_OTP,
    payload,
  };
}

export function sendFastagOTPActionSuccess(data) {
  return { type: SEND_FASTAG_OTP_SUCCESS, payload: data };
}


export function verifyFastagOTPAction(payload,callback) {
  return {
    type: VERIFY_FASTAG_OTP,
    payload:payload,
    callback
  };
}

export function verifyFastagOTPActionSuccess(data) {
  return { type: VERIFY_FASTAG_OTP_SUCCESS, payload: data };
}

export function fastagAddCustomerAction(payload,callback) {
  return {
    type: FASTAG_ADD_CUSTOMER,
    payload:payload,
    callback
  };
}

export function fastagAddCustomerActionSuccess(data) {
  return { type: FASTAG_ADD_CUSTOMER_SUCCESS, payload: data };
}

export function vehicleTagReissueAction(payload,callback) {
  return {
    type: TAG_REISSUE,
    payload:payload,
    callback
  };
}

export function vehicleTagReissueActionSuccess(data) {
  return { type: TAG_REISSUE_SUCCESS, payload: data };
}


export function registerYesbankAction(payload,callback) {
  return {
    type: REGISTER_YESBANK,
    payload:payload,
    callback
  };
}

export function registerYesbankActionSuccess(data) {
  return { type: REGISTER_YESBANK_SUCCESS, payload: data };
}


export function autoRechargeToggleAction(payload,callBack) {
  return {
    type: TOGGLE_AUTO_RECHARGE,
    payload,
    callBack
  };
}

export function autoRechargeToggleActionSuccess(data) {
  console.log("succccccccccccessss");
  return { type: TOGGLE_AUTO_RECHARGE_SUCCESS, payload: data };
}



export function cleanFastagLoaderAction(data) {
  //console.log("succccccccccccessss");
  return { type: CLEAN_FASTAG_REDUCER, payload: data };
}

export function autoRechargeMultipleAction(payload) {
  return {
    type: AUTO_RECHARGE_MULTIPLE,
    payload,
  };
}

export function autoRechargeMultipleActionSuccess(data) {
  console.log("succccccccccccessss");
  return { type: AUTO_RECHARGE_MULTIPLE_SUCCESS, payload: data };
}
export function createOrderFleetAction(payload,callBack) {
  return {
    type: CREATE_ORDER_FOR_FLEET,
    payload,
    callBack
  };
}

export function createOrderFleetActionSuccess(data) {
  console.log("succccccccccccessss");
  return { type: CREATE_ORDER_FOR_FLEET_SUCCESS, payload: data };
}


export function cleanFastagTransactionHistory() {
  return { type: CLEAN_FASTAG_TRANSACTION_HISTORY };
}
export function idfcKycSendOtpAction(payload,callBack) {
  return {
    type: IDFC_KYC_SEND_OTP,
    payload,
    callBack
  };
}

export function idfcKycSendOtpActionSuccess(data) {
  return { type: IDFC_KYC_SEND_OTP_SUCCESS, payload: data };
}
export function idfcKycVerifyOtpAction(payload,callBack) {
  return {
    type: IDFC_KYC_VERIFY_OTP,
    payload,
    callBack
  };
}

export function idfcKycVerifyOtpActionSuccess(data) {
  return { type: IDFC_KYC_VERIFY_OTP_SUCCESS, payload: data };
}
export function idfcKycSaveImageAction(payload,callBack) {
  return {
    type: IDFC_KYC_SAVE_IMAGE,
    payload,
    callBack
  };
}

export function idfcKycSaveImageActionSuccess(data) {
  return { type: IDFC_KYC_SAVE_IMAGE_SUCCESS, payload: data };
}

export const getDriverVehicleHistory = payload => {
  console.log('PAYLOAD', payload);
  return {
    type: DRIVER_VEHICLE_HISTORY,
    payload,
  };
};
export const cleangetDriverVehicleHistory = () => {
  return {
    type: DRIVER_VEHICLE_HISTORY_CLEAN,
  };
};
export const getDriverVehicleHistorySuccess = payload => {
  return {
    type: DRIVER_VEHICLE_HISTORY_SUCCESS,
    payload,
  };
};

export const getDocument = payload => {
  console.log('PAYLOAD', payload);
  return {
    type: GET_DOCUMENT,
    payload,
  };
};
export const getDocumentSuccess = payload => {
  return {
    type: GET_DOCUMENT_SUCCESS,
    payload,
  };
};



export const getCustomRequest = payload => {
  return {
    type: GET_CUSTOM_REQUEST,
    payload,
  };
};

export const getCustomRequestSuccess = payload => {
  return {type: GET_CUSTOM_REQUEST_SUCCESS, payload};
};
export function cleanCustomRequest() {
  return {
    type: GET_CUSTOM_REQUEST_CLEAN,
  };
}

export const updateCustomRequest = (payload, callBack) => {
  return {
    type: UPDATE_CUSTOM_REQUEST,
    payload,
    callBack,
  };
};

export const updateCustomRequestSuccess = payload => {
  return {type: UPDATE_CUSTOM_REQUEST_SUCCESS, payload};
};

export const deleteCustomRequest = (payload, callBack) => {
  return {
    type: DELETE_CUSTOM_REQUEST,
    payload,
    callBack,
  };
};

export const deleteCustomRequestSuccess = payload => {
  return {type: DELETE_CUSTOM_REQUEST_SUCCESS, payload};
};
