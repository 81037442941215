
import {call, put, takeEvery} from 'redux-saga/effects';
import {
    getTransferCollectionsApi
} from '../../Utils/Apis';
import {processErrorAndRespond} from '../../Utils/functions';
import {
  getTransferCollectionSuccess,
} from '../Actions/transferAction';
import {
  GET_TRANSFER_COLLECTIONS
} from '../storeTypes';


function* getTransferCollectionSaga() {
  try {
    const response = yield call(getTransferCollectionsApi);
    //console.log("getWalletTransactionHistorySaga >>> ", response);
    if (response.data.type == 'success') {
      //console.log("dattt",response?.data?.data)
      yield put(getTransferCollectionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}

export function* watchTransferSagas() {
 
    yield takeEvery(GET_TRANSFER_COLLECTIONS, getTransferCollectionSaga);
}
