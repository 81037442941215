import {
    GET_TRANSFER_COLLECTIONS,
    GET_TRANSFER_COLLECTIONS_SUCCESS
  } from '../storeTypes';
  
  const INIT_STATE = {
    loader: false,
    collections :0,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_TRANSFER_COLLECTIONS: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_TRANSFER_COLLECTIONS_SUCCESS: {
        return {
          ...state,
          loader: false,
          collections : action?.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  