import "./index.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import AddBalanceButton from "../../Buttons/addBalancebutton";
import LogipeAccountAddBalanceModal from "../../../Modals/logipeAccountAddBalance";
import TransactionBox from "../../../subComponents/Transaction";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";

const MasterAccount = (props) => {
  let a = JSON.parse(sessionStorage.getItem("_apiList"))?.others;
  console.log("nextin----", a);
  for (var key in a) {
    console.log("Vr Key-", key);
    console.log("Vr val-", a[key]);
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sendAnalitics("Master_Acc_Web"));
  }, []);

  const {
    handleShow,
    title,
    handleClick,
    handleClose,
    btn,
    nextSection,
    proceed,
    show,
  } = props;
  const {
    copyIconIfsc,
    setCopyIconIfsc,
    copyIcon,
    setCopyIcon,
    open,
    toggleModal,
  } = props;
  const { ownerBalance } = useSelector((state) => state?.manageCard);
  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == null
      ? 0
      : ownerBalance?.instrument_balance?.balance;
  console.log("authUserauthUserauthUser", authUser?.user);
  return (
    <>
      <div className="headingLow semiBold">Master Account</div>
      <div className="row padding16">
        <div className="row">
          <div className="col-1 padding0">
            <SvgImage name="HomeBank" />
          </div>
          <div className="col-7 headingLow semiBold">
            ₹ {LogipeAccounBal?.toLocaleString('en-IN') || 0}
          </div>
          <div className="col-3">
            <AddBalanceButton
              Value="Add Balance"
              title="Master Account"
              onclick={handleShow}
            />
          </div>
        </div>
        {authUser?.user?.type == 3 ? (
          <div className="mt-4 p-0">
            <div className="semiBold col-8 padding0">Manager Account Limit:</div>
            <div className="col-6 headingLow semiBold">
              ₹ {managerControlList?.transferLimit?.toLocaleString('en-IN') || 0}
            </div>{" "}
          </div>
        ) : null}

        {
          <LogipeAccountAddBalanceModal
            copyIconIfsc={copyIconIfsc}
            setCopyIconIfsc={setCopyIconIfsc}
            copyIcon={copyIcon}
            setCopyIcon={setCopyIcon}
            open={open}
            toggleModal={toggleModal}
            title={title}
            handleClick={handleClick}
            handleClose={handleClose}
            btn={btn}
            nextSection={nextSection}
            proceed={proceed}
            ownerLogipeBalance={LogipeAccounBal}
          />
        }
        <div className="row padding0L padding0R" style={{ paddingTop: "1rem" }}>
          <div className="col-12">
            <TransactionBox
              openBox={show}
              type="Master Account Transfer"
              Title={title}
            />
          </div>
          
        </div>
      </div>
    </>
  );
};

export default MasterAccount;
