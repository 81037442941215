import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import moment from "moment";
import {
  editCustomerAction,
  getCustomerAction,
} from "../../../../services/Store/Actions/customerAction";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import ToastV2 from "../../../subComponents/toastV2";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import { useNavigate } from "react-router-dom";
import {
  findTripAddressV2,
  getSavedAddress,
} from "../../../../services/Store/Actions/TripsAction";
import AddAddress from "../../../LoadReceipt/Components/AddAddress";

function EditCustomer(props) {
  const { toggle, modal, setModal, customerId } = props;
  const [openToast, setOpenToast] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toastObject = useSelector((state) => state?.app?.toastObject);
  const { customer } = useSelector((state) => state?.customer);
  const { vendorDetails } = useSelector((state) => state?.beneficiary);
  const [display1, setDisplay1] = useState(false);
  const [search1, setSearch1] = useState("");
  const [color1, setColor1] = useState(-1);
  const [address, setAddress] = useState(false);
  const addAddress = () => setAddress(!address);
  const { savedAddresses } = useSelector((state) => state?.trip);
  console.log("====>>customer>>>", customer);
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Please enter your Customer name"),
    // phone: Yup.string()
    //   .required("Phone number must contain 10 digits")
    //   .matches(
    //     /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    //     "Phone number must contain 10 digits"
    //   ),
    address: Yup.string().required("Please enter your address"),
  });

  const handleClose = () => {
    setModal(!modal);
  };
  useEffect(() => {
    if (display1 == true) {
      // dispatch(findTripAddressV2({},(res)=>{
      //   console.log("findTripAddressV2",res);
      // }))
      dispatch(
        getSavedAddress({ address: search1 }, (res) => {
          console.log("getSavedAddress", res);
        })
      );
    }
  }, [display1, search1]);
  const findSearch1 = () => {
    setColor1(-1);
    dispatch(
      findTripAddressV2({
        offset: 0,
        search: search1,
      })
    );
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            style={{ marginTop: " 30px" }}
            //   className="PGCollectionBox"
          >
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Edit Customer
            </div>

            <Formik
              // isValid='true'
              enableReinitialize
              //   validationSchema={validationSchema}
              initialValues={{
                companyName: customer.companyName ? customer?.companyName : "",
                address: customer?.TripAddress?.address
                  ? `${customer?.TripAddress?.address}, ${customer?.TripAddress?.cityName} ${customer?.TripAddress?.stateName}`
                  : "",
                pincode: customer?.TripAddress?.pincode
                  ? customer?.TripAddress?.pincode
                  : "",
                phone: customer?.phone,
                panNo: customer.panNo ? customer?.panNo : "",
                gstin: customer.gstin ? customer?.gstin : "",
                city: customer?.TripAddress?.cityName
                  ? customer?.TripAddress?.cityName
                  : "",
                contactName: customer.contactName ? customer?.contactName : "",
                email: customer.email ? customer?.email : "",
                state: customer?.TripAddress?.stateName
                  ? customer?.TripAddress?.stateName
                  : "",
                accountNo: vendorDetails?.bankAccountNumber
                  ? vendorDetails?.bankAccountNumber
                  : "",
                ifsc: vendorDetails.ifsc ? vendorDetails?.ifsc : "",
                upiId: vendorDetails.vpaId ? vendorDetails?.vpaId : "",
                addressId: customer?.addressId ? customer?.addressId : "",
              }}
              onSubmit={(values) => {
                console.log("===>>>>init", values);
                // setOpenToast(true);
                values["customerId"] = customerId;
                dispatch(
                  editCustomerAction(values, (resp) => {
                    console.log("===>>>", resp);
                    // window.location = "/Customers";
                    if (resp?.status == 200) {
                      dispatch(
                        getCustomerAction({
                          customerId: customerId,
                        })
                      );
                    }
                  })
                );
                handleClose();
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "40px", padding: "0 0px 0 55px" }}
                >
                  <FormGroup>
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Customer Name
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="companyName"
                            className={`inputBoxAddCust ${
                              touched.companyName && errors.companyName
                                ? "is-invalid"
                                : ""
                            }`}
                            name="companyName"
                            value={values.companyName}
                            onChange={handleChange("companyName")}
                            onBlur={handleBlur("companyName")}
                            touched={touched.companyName}
                            errors={errors.companyName}
                            placeholder="Company Name"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.companyName && touched.companyName ? (
                              <div>*{errors.companyName}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            GSTIN
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.gstin && errors.gstin ? "is-invalid" : ""
                            }`}
                            name="gstin"
                            value={values.gstin}
                            onChange={handleChange("gstin")}
                            onBlur={handleBlur("gstin")}
                            touched={touched.gstin}
                            errors={errors.gstin}
                            placeholder="GSTIN"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.gstin && touched.gstin ? (
                              <div>*{errors.gstin}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Contact Person Name
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.contactName && errors.contactName
                                ? "is-invalid"
                                : ""
                            }`}
                            name="contactName"
                            value={values.contactName}
                            onChange={handleChange("contactName")}
                            onBlur={handleBlur("contactName")}
                            touched={touched.contactName}
                            errors={errors.contactName}
                            placeholder="Contact Person Name"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.contactName && touched.contactName ? (
                              <div>*{errors.contactName}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Phone
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="phone"
                            readonly=""
                            className={`inputBoxAddCust ${
                              touched.phone && errors.phone ? "is-invalid" : ""
                            }`}
                            style={{ background: "#2E7C7B0D" }}
                            name="phone"
                            value={values.phone}
                            onChange={handleChange("phone")}
                            onBlur={handleBlur("phone")}
                            touched={touched.phone}
                            errors={errors.phone}
                            placeholder="Phone"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.phone && touched.phone ? (
                              <div>*{errors.phone}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Email
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                            name="email"
                            value={values.email}
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            touched={touched.email}
                            errors={errors.email}
                            placeholder="Email Id"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.email && touched.email ? (
                              <div>*{errors.email}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <FormGroup>
                      <label for="addressId" className="heading3 semiBold">
                        Address
                      </label>
                      <div
                        className="custNamepadd3"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          name="address"
                          id="address"
                          placeholder={"Select Address"}
                          className={`inputBoxCust1 ${
                            touched.address && errors.address
                              ? "is-invalid"
                              : ""
                          }`}
                          aria-label="Default select example"
                          onChange={(e) => {
                            setDisplay1(true);
                            setSearch1(e.target.value);
                            setFieldValue("address", e.target.value, true);
                          }}
                          onClick={() => {
                            setDisplay1(true);
                          }}
                          value={values.address}
                          onBlur={handleBlur("address")}
                          errors={errors.address}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              findSearch1();
                            }
                          }}
                          autoComplete={"off"}
                        ></input>
                        <div
                              className={`inputBoxCust2`}
                              onClick={addAddress}
                              style={{ cursor: "pointer", marginLeft: 12 }}
                            >
                              +
                            </div>
                      </div>

                      {display1 ? (
                        <div
                          className="padding16 newCashback whiteBackground"
                          style={{
                            position: "absolute",
                            width: "350px",
                            height: "275px",
                            zIndex: "90",
                          }}
                        >
                          <div
                            onClick={() => {
                              setDisplay1(false);
                              // setSearch1("");
                              //dispatch(clearAllDriversByVrn());
                              // setFieldValue("sourceAddress", "", true);
                            }}
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            <SvgImage name="CloseButtonLogo" />
                          </div>
                          <div
                            style={{
                              overflowY: "scroll",
                              overflowX: "hidden",
                              height: "240px",
                            }}
                          >
                            {search1 == "" ? (
                              savedAddresses?.map((data, i) => {
                                let city = data?.cityName;
                                let state = data?.stateName;
                                let address = data?.address;
                                console.log("savedAddresses", data);
                                return (
                                  <div
                                    key={i}
                                    className="padding8 row"
                                    onClick={() => {
                                      // setcityId1(data?.cityName);
                                      setColor1(i);
                                      setDisplay1(false);
                                      //setUserId(data?.User?.userId);
                                      setFieldValue(
                                        "address",
                                        `${address}, ${city} - ${state}`,
                                        true
                                      );
                                      setFieldValue(
                                        "addressId",
                                        data?.addressId,
                                        true
                                      );
                                    }}
                                  >
                                    {color1 === i ? (
                                      <div className="col-1">
                                        <SvgImage name="SelectedRadio" />
                                      </div>
                                    ) : (
                                      <div className="col-1">
                                        <SvgImage name="UnselectedRadio" />
                                      </div>
                                    )}
                                    {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                    <div
                                      className="col-11"
                                      style={{ textAlign: "left" }}
                                    >
                                      {address}, {city} - {state}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                {savedAddresses?.map((data, i) => {
                                  let city = data?.cityName;
                                  let state = data?.stateName;
                                  let address = data?.address;
                                  return (
                                    <div
                                      key={i}
                                      className="padding8 row"
                                      onClick={() => {
                                        // setcityId1(data?.cityName);
                                        setColor1(i);
                                        setDisplay1(false);
                                        //setUserId(data?.User?.userId);
                                        setFieldValue(
                                          "address",
                                          `${address}, ${city} - ${state}`,
                                          true
                                        );
                                        setFieldValue(
                                          "addressId",
                                          data?.addressId,
                                          true
                                        );
                                      }}
                                    >
                                      {color1 === i ? (
                                        <div className="col-1">
                                          <SvgImage name="SelectedRadio" />
                                        </div>
                                      ) : (
                                        <div className="col-1">
                                          <SvgImage name="UnselectedRadio" />
                                        </div>
                                      )}
                                      {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                      <div
                                        className="col-11"
                                        style={{ textAlign: "left" }}
                                      >
                                        {address}, {city} - {state}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                            {savedAddresses?.length == 0 ? (
                              <div className="">
                                <div style={{ margin: "24px" }}>
                                  You have no addresses to select please add a
                                  new one
                                </div>
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="greenButton d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "200px",
                                      cursor: "pointer",
                                    }}
                                    onClick={addAddress}
                                  >
                                    <div>Add New Address</div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={{ color: "red" }}>
                        {errors.address && touched.address ? (
                          <div>*{errors.address}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      padding: "25px 0 0 0",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{ width: "160px", borderRadius: "5px" }}
                      className="generateOtp textAddCust Medium btn btn-secondary"
                      // onClick={addFreight}
                    >
                      Done
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>

      {toastObject.toastStatus &&
      toastObject?.message == "Customer Edit Successfully" ? (
        <ToastV2
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            setOpenToast(false);
          }}
          isOpen={toastObject?.toastStatus}
          name="driver"
          handleClose={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
                message: "",
                status: true,
              })
            );
            window.location = "/Customers";
            // navigate("/Customers");
          }}
        />
      ) : (
        <></>
      )}
      <AddAddress
        toggle={addAddress}
        address={address}
        setAddress={setAddress}
      />
    </div>
  );
}

export default EditCustomer;
