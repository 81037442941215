import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "./index.css";
import {
  vehicleHistory,
  cleanVehicleHistory,
} from "../../../../services/Store/Actions/manageVehicleAction";
import {
  autoRechargeToggleAction,
  cleanVehicleDetails,
  getFastagWalletBalance,
  getVehicleDetails,
} from "../../../../services/Store/Actions/fastagAction";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import SvgImage from "../../../Icons/Icons";
import { ToastContainer, toast } from "react-toastify";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import Map from "../Map";

function CustomerVehicleDetail(props) {
  console.log("this are props", props);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state, location } = useLocation();
  const { loader } = useSelector((state) => state?.fastag);
  //const customerData = useSelector((state)=>state)
  console.log("LKKKKKKKKKKKKKKKKKKKK", props.user);
  const vehicleData = useSelector((state) => state?.manageVehicle);
  const omc_balace = useSelector((state) => state?.omc?.omc_balace);
  let jio = omc_balace?.omcVehicles?.find((e) => e?.omcType == "jio");
  let bpcl = omc_balace?.omcVehicles?.find((e) => e?.omcType == "bpcl");
  let hpcl = omc_balace?.omcVehicles?.find((e) => e?.omcType == "hpcl");
  const [path, setPath] = useState([[]]);
  console.log("users as jio", omc_balace, omc_balace?.omcVehicles, jio);
  //console.log("Vehicle Data>>>>",vehicleData?.manageVehicle?.vehicleReceivableHistory.fleets)
  //console.log ("value--------", assignedDriversCount);
  //const name = " "
  //let name =" "
  let { id } = useParams();
  console.log("PropellerPropellerPropeller", id, state?.vehicle);

  useEffect(() => {
    setPath([[]]);
    if (
      id != undefined &&
      state?.vehicle?.location?.Lat &&
      state?.vehicle?.location?.Long
    ) {
      console.log("users as props",state?.vehicle?.location?.Long,state?.vehicle?.location?.Lat, state);
      setPath([[state?.vehicle?.location?.Lat, state?.vehicle?.location?.Long]]);
      //dispatch(vehicleHistory(id));
      // dispatch(getVehicleDetailsByFleetId(id));
      // dispatch(cleanVehicleDetails());
      // dispatch(getVehicleDetails(id));
      // if (props.omcBal) {
      //   dispatch(omcVehicleBalanceAction(id));
      //   dispatch(
      //     omcVehicleTransactionAction(id, {
      //       offset: "",
      //       limit: 10,
      //       search: "",
      //       minKyc: "",
      //       sortAs: "",
      //       sortBy: "",
      //     })
      //   );
      // }
    } else {
      //dispatch(cleanVehicleHistory());
    }
    // dispatch(vehicleHistory(props.user));
    // dispatch(getFastagWalletBalance());
  }, [id]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const { fastagWallet } = useSelector((state) => state?.fastag);

  const fastagWalletAmount =
    fastagWallet.balance == null ? 0 : fastagWallet.balance;

  const vehicleDetails = useSelector((state) => state?.fastag?.vehicleDetails);
  console.log("indoooooooooooo", vehicleDetails);
  //const vehicleInfo= vehicleDetails?.vehicle?.regDate;

  //console.log("regno<><><><><><><><>><><><>><><><><><>", historyData);
  function handleClose() {
    setOpen(false);
  }
  function handleShow() {
    setOpen(true);
  }
  function toggleModal() {
    setOpen(!open);
  }
  const [rechargeOmc, setRechargeOmc] = useState(false);
  const [mappingOmc, setMappingOmc] = useState(false);
  function closeRechargeOmc() {
    setRechargeOmc(false);
  }
  function openRechargeOmc() {
    setRechargeOmc(true);
  }
  function toggleRechargeOmc() {
    setRechargeOmc(!rechargeOmc);
  }
  function closeMappingOmc() {
    setMappingOmc(false);
  }
  function openMappingOmc() {
    setMappingOmc(true);
  }
  function toggleMappingOmc() {
    setMappingOmc(!mappingOmc);
  }
  const { advancedVehicleList, advancedVehicleListCount } = useSelector(
    (state) => state?.fleet
  );
  const val = advancedVehicleList?.filter(
    (element) => element.fleetId == id
  )[0];
  console.log("valllllllllllllllllll", val, id);

  const handleToggle = () => {
    if (!loader) {
      dispatch(
        autoRechargeToggleAction({
          fleetId: [id],
        })
      );
    }
  };
  const [openRechargeOne, setOpenRechargeOne] = useState(false);

  const showRechargeOne = () => {
    setOpenRechargeOne(true);
  };

  const toggleRechargeOne = () => {
    setOpenRechargeOne(!openRechargeOne);
  };

  const closeRechargeOne = () => {
    setOpenRechargeOne(false);
  };
  const autoRechargeState = fastagWallet?.autoRecharge;
  const fastagAutorechargeAmount = fastagWallet?.autoRechargeAmount;

  const customId = "custom-id-yes-main page";
  const notify = () =>
    toast.error(
      "You have disabled FASTag auto-recharge for all vehicles, kindly enable it!",
      {
        toastId: customId,
      }
    );

  const vehRegNo =
    !id || !state?.vehicle?.fleetId
      ? "Select Vehicle"
      : state?.vehicle?.regNumber;
  const tagId = state?.vehicle?.tagId;

  const [showroomModal, setShowroomModal] = useState(false);
  const [showRequest, setshowRequest] = useState(false);
  const showroomModalFunc = () => {
    console.log("invoiceModal", showroomModal);
    setShowroomModal(!showroomModal);
  };

  const showRequests = () => {
    console.log("invoiceModal", showroomModal);
    setshowRequest(!showRequest);
  };

  return (
    <>
      {!props.user || props.user == "" ? (
        <div class="whiteBackground">
          <SvgImage name="vehicleHomePage" height="700px" width="700px" />
        </div>
      ) : (
        <div
          className="main-customer-vehicle-detail"
          style={{
            opacity: vehRegNo === "Select Vehicle" ? "0.5" : "1",
            padding: "12px",
          }}
        >
          {/* {mapView==index &&  */}
          <div>
            <div className="">
              {/* <iframe
                width="620px"
                height="700px"
                style={{
                  border: "0",
                }}
                loading="lazy"
                src={
                  parseFloat(1)
                    ? `https://maps.google.com/maps?q=${state?.vehicle?.location?.Lat},${state?.vehicle?.location?.Long}&t=&z=15&ie=UTF8&iwloc=&output=embed`
                    : `https://www.google.com/maps/embed/v1/place?key=AIzaSyDY--FHBXDx-Y5R3OxIdrJC1apJhgrNfbg&q=0`
                }
              ></iframe> */}
              {path?.length > 0 && path[0]?.length > 0  ?                 <Map
                  fleetId={state?.vehicle?.fleetId}
                  latt={state?.vehicle?.location?.Lat}
                  longg={state?.vehicle?.location?.Long}
                  regNumber={vehRegNo}
                  path={path}
                  setPath={setPath}
                /> : <></>} 
              {/* ) : (
                <></>
              )} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default CustomerVehicleDetail;
