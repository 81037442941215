import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import moment from "moment";
import SvgImage from "../../Icons/Icons";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addFleet } from "../../../services/Store/Actions/fleetActions";
//import { useNavigate } from "react-router-dom";
import {
  sendAnalitics,
  storeMedia,
  updateProfileAction,
} from "../../../services/Store/Actions/appActions";
import { getOwnerCardDetails } from "../../../services/Store/Actions/manageCardActions";
import PhotoUpload from "../PhotoUpload";
import { getBranch, getBranchFleet, getBranchManager, getManagerList } from "../../../services/Store/Actions/UserManagementAction";
import ResetPasswordModal from "./ResetPasswordModal";
// import { fleetDetailsSuccess } from "../../../services/Store/Actions/fleetActions";
import { ClipLoader } from "react-spinners";
import AssignManager from "../../Branches/SubComponents/AssignManager";
import AssignBranch from "../../Branches/SubComponents/AssignBranch";
import { getDataFromAsyncStorage } from "../../../services/Utils/functions";
function OwnerDetails(props) {
  const { open, toggleModal, handleClose, handleLogout } = props;
  const validationSchema = Yup.object().shape({
    address1: Yup.string().required("Required"),
    pincode: Yup.number().typeError("Only Numbers are allowed").required("Required"),
    noOfFleets: Yup.string().required("Required"),
    // vehicleCategory: Yup.string().required("Required"),
  });
  const [imageLoader,setImageLoader] = useState(false)
  const { loader } = useSelector((state) => state?.app);

  const dispatch = useDispatch();
  const { ownerDetails } = useSelector((state) => state?.loginReducer);
  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const phone = obj?.user?.phone;
  const imgName = ownerDetails?.userImage ? true : false;
 


  const handleTerms = () => {
    dispatch(sendAnalitics("T&C_OwnerAcc_Home_web"));
    toggleModal();
    props.handleTermShow();
  };

  const handlePolicy = () => {
    dispatch(sendAnalitics("Privacy_OwnerAcc_Home_web"));
    toggleModal();
    props.handlePrivacyShow();
  };

  useEffect(() => {
    //dispatch(getOwnerCardDetails());
    console.log("sjbsjbsjbsjsbjjjj")
    dispatch(getManagerList());
    dispatch(
      getBranch({}, (res) => {
        console.log("snjsbsbs", res);
      })
    );
    dispatch(sendAnalitics("OwnerAcc_Home_web"));
  }, []);

  useEffect(() => {
    console.log("sbsbbsumanth")
    dispatch(
      getBranch({}, (res) => {
        console.log("snjsbsbs", res);
      })
    );

  }, [open]);

  const inputClicked = () => {};

  const noOfManagers=useSelector((state)=>state?.userManagement?.managerList?.result?.count);
  console.log("ssssssssssssssss", noOfManagers);
  const [status, setStatus] = useState(false);

  const [openReset, setOpenReset] = useState(false);
  const handleCloseReset = () => {
    setOpenReset(false);
  };

  const toggleModalReset=()=>{
    setOpenReset(!openReset);
  }

  const handleShowReset = () => {
    setOpenReset(true);
  };
  const [showAssignManager, setshowAssignManager] = useState(false);
  function toggleAssignManager() {
    dispatch(
      getBranch({}, (res) => {
        console.log("snjsbsbs", res);
      })
    );
    setshowAssignManager(!showAssignManager);
  }

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={()=>{
          setStatus(false);
          handleClose();
        }}
        className="positon settingModalMargin"
        toggle={()=>{
          setStatus(false);
          toggleModal();
        }}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Owner Profile</p>
            <Button
              type="button"
              className="close-btn"
              onClick={()=>{
                dispatch(sendAnalitics("close_OwnerAcc_Home_web"));
                setStatus(false);
                toggleModal();
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody className="padding0L">
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                address1: ownerDetails?.address1,
                pincode: ownerDetails?.pincode,
                noOfFleets: String(ownerDetails?.noOfFleets),
                userImage: ownerDetails?.userImage,
              }}
              onSubmit={(values, actions) => {
                //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
                //console.log("valuessss", values);
                dispatch(
                  updateProfileAction(values, (res) => {
                    actions.setSubmitting(false);
                    //console.log("dte", res);
                    if (res?.data?.type === "success") {
                      dispatch(sendAnalitics("Success_edit_OwnerAcc_Home_web"));
                      toggleModal();
                      //console.log("ressspp", res?.data?.data?.link);
                      // window.location.replace(res?.data?.data?.link);
                    }
                  })
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ position: "relative", top: "-1rem" }}
                >
                  <FormGroup>
                    <div className="row lightGreenBackground padding16 padding24L">
                      <div className="col-3">
                        <div className="padding8" style={{}}>
                          {imgName ? (
                            <img
                              className="profile-img1 "
                              src={values.userImage}
                              alt=" "
                              width="68px"
                              height="68px"
                            />
                          ) : (
                            <div className="profile-img1 ">
                              <SvgImage name="noImageIcon" />
                            </div>
                          )}
                        </div>
                        {status ? (
                          <Button
                            type="button"
                            className="buttonStyle"
                            // onClick={()=>{console.log("hey123456677")}}
                            style={{
                              position: "relative",
                              top: "-1rem",
                              left: "0.2rem",
                            }}
                            // onClick={()=>{<PhotoUpload setFieldValue={setFieldValue} values={values} />}}
                          >
                          {
                          imageLoader ? (
                            <div style={{ zIndex: 10 }}>
                              <ClipLoader
                                color={"#485B7C"}
                                loading={true}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div>
                          ) : (
                          <div>
                            <div style={{ position: "absolute" }}>
                              <SvgImage name="cameraIcon" />
                            </div>
                            <div
                              style={{
                                paddingLeft: "8px",
                                position: "absolute",
                                left: "1rem",
                              }}
                            >
                              Change
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              className="adding-btn"
                              onChange={(e) => {
                                //alert("hey");
                                const img = e?.target.files[0];
                                console.log("image", img?.name);
                                // e.preventDefault();
                                const tempArray = Object.assign(
                                  [],
                                  values.docs
                                );
                                if (img == null) {
                                  //clear image
                                  setFieldValue("userImage", "", true);
                                  //for now  only one supported
                                } else {
                                  console.log("upload", {
                                    img: img,
                                    uploadType: "aadharFront",
                                    isRes: true,
                                  });
                                  // e.preventDefault();
                                  // e.setSubmitting(false);
                                  // e.preventDefault();
                                  setImageLoader(true)
                                  dispatch(
                                    storeMedia(
                                      {
                                        img: img,
                                        uploadType: "aadharFront",
                                        isRes: false,
                                      },
                                      (res) => {
                                        setImageLoader(false)
                                        if (res?.url) {
                                          console.log("opt", res);
                                          tempArray.push(res?.data?.Location);
                                          console.log("opt1", tempArray);
                                          //setFieldValue("urlBack", res?.url, true);
                                          setFieldValue(
                                            "userImage",
                                            res?.data?.Location,
                                            true
                                          );
                                          // setFieldValue('docs', tempArray, true);
                                          //for now  only one supported
                                          //setFieldValue("docs", tempArray, true);
                                        }
                                      }
                                    )
                                  );
                                  // setFieldValue("amount", values.amount, true);
                                }
                              }}
                            />
                        </div>)}
                           
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-9">
                        <div className="FontBold">
                          {ownerDetails?.businessName?ownerDetails?.businessName:"Not Available"}
                        </div>
                        <div className="FontSmall">
                          Business Type: {ownerDetails?.businessType?ownerDetails?.businessType:"Not Available"}
                        </div>
                        <div className="FontSmall">
                          Owner: {ownerDetails?.fullName?ownerDetails?.fullName:"Not Available"}
                        </div>
                        <div className="FontSmall">Phone: {phone}</div>
                        <div className="FontSmall">
                          DOB: {ownerDetails?.dob?moment(ownerDetails?.dob).format("DD-MM-YYYY"):"Not Available"}
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  {status ? (
                    <>
                      <FormGroup style={{ position: "relative", top: "-1rem" }}>
                        <div className="sub-title-1">
                          <span class="circle-logo">1</span>
                          <label
                            htmlFor="address1"
                            className="sub-title-edit-vehicle-detial "
                          >
                            Address
                          </label>
                        </div>
                        <input
                          type="text"
                          id="address1"
                          className="input-body-3"
                          name="address1"
                          onChange={handleChange("address1")}
                          value={values.address1}
                          onBlur={()=>{
                            dispatch(sendAnalitics("Address_edit_OwnerAcc_Home_web",{
                              address:values.address1
                            }));
                            handleBlur("address1");
                          } }
                          touched={touched.address1}
                          errors={errors.address1}
                          placeholder="Enter your address"
                        />
                        <div className={`input-amount-for-link `}></div>
                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.address1 && touched.address1 ? (
                            <div>*{errors.address1}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <FormGroup style={{ position: "relative", top: "-1rem" }}>
                        <div className="sub-title-1">
                          <span class="circle-logo">2</span>
                          <label
                            htmlFor="pincode"
                            className="sub-title-edit-vehicle-detial "
                          >
                            Pin Code
                          </label>
                        </div>
                        <input
                          type="text"
                          id="pincode"
                          className="input-body-3"
                          name="pincode"
                          onChange={handleChange("pincode")}
                          value={values.pincode}
                          onBlur={()=>{
                            dispatch(sendAnalitics("pinCode_edit_OwnerAcc_Home_web"));
                            handleBlur("pincode")
                          }}
                          touched={touched.pincode}
                          errors={errors.pincode}
                          placeholder="Enter your pin"
                          maxLength="6"
                        />
                        <div className={`input-amount-for-link `}></div>
                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.pincode && touched.pincode ? (
                            <div>*{errors.pincode}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <FormGroup style={{ position: "relative", top: "-1rem" }}>
                        <div className="sub-title-1">
                          <span class="circle-logo">3</span>
                          <label
                            htmlFor="noOfFleets"
                            className="sub-title-edit-vehicle-detial "
                          >
                            Number of trucks
                          </label>
                        </div>
                        <input
                          type="text"
                          id="noOfFleets"
                          className="input-body-3"
                          name="noOfFleets"
                          onChange={handleChange("noOfFleets")}
                          value={values.noOfFleets}
                          onBlur={()=>{
                            dispatch(sendAnalitics("TrucksNo_edit_OwnerAcc_Home_web",{
                              noOfTrucks:values.noOfFleets
                            }));
                            handleBlur("noOfFleets")
                          } }
                          touched={touched.noOfFleets}
                          errors={errors.noOfFleets}
                          placeholder="Enter total no of trucks"
                        />
                        <div className={`input-amount-for-link `}></div>
                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.noOfFleets && touched.noOfFleets ? (
                            <div>*{errors.noOfFleets}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="padding32L"
                        style={{ position: "relative", top: "-2rem" }}
                      >
                        <Button
                          type="submit"
                          className="butt-4"
                          style={{
                            marginTop: "4rem",
                            marginLeft: "-27px",
                            width: "360px",
                          }}
                          disabled={loader ? true : false}
                          onClick={()=>{
                            dispatch(sendAnalitics("Save_edit_OwnerAcc_Home_web",{
                              address1: values.address1,
                              pincode:values.pincode,
                              noOfFleets: values.noOfFleets,
                              userImage: values.userImage,
                            }));
                          }}
                        >
                          {loader ? "Please Wait" : "Save"}
                        </Button>
                      </FormGroup>
                    </>
                  ) : (
                    <>
                  
                      
                      <div style={{textAlign:"right"}}>
                        <div className=" Bold heading2 padding24R "
                        onClick={handleShowReset}>Forgot password? <span className="forgotText">Click here</span></div>
                      </div>
        
                    
                    <div className="row padding16T" onClick={()=>{
                      dispatch(sendAnalitics("Edit_OwnerAcc_Home_web"));
                      setStatus(true);
                    }}>
                      <div className="col-7">
                      </div>
                      <div className="col-1 padding24L">
                        <SvgImage name="editLogo" width="18" height="18" />
                      </div>
                      <div className="col-4 padding8L padding0R" style={{color:"#485B7C", cursor:"pointer"}}>Edit Profile Details</div>
                    </div>
                      <div className="padding8T padding8 padding24L">
                        <div className="padding16 boxAddress">
                          <div className="FontBold">Address</div>
                          <div
                            className="FontSmall padding8T padding16B"
                            style={{ fontSize: "16px" }}
                          >
                            {ownerDetails?.address1?ownerDetails?.address1:"Not Available"}
                          </div>
                          <div className="FontBold">Pin Code</div>
                          <div
                            className="FontSmall padding8T padding16B"
                            style={{ fontSize: "16px" }}
                          >
                            {ownerDetails?.pincode?ownerDetails?.pincode:"Not Available"}
                          </div>
                          <div className="FontBold">No. of Trucks</div>
                          <div
                            className="FontSmall padding8T padding16B"
                            style={{ fontSize: "16px" }}
                          >
                            {ownerDetails?.noOfFleets?ownerDetails?.noOfFleets:"Not Available"}
                          </div>
                        </div>
                      </div>

                      <div className="padding24T padding8 padding24L" style={{paddingBottom:"4rem"}}>
                        <div className="padding16 boxAddress">
                          <div className="FontBold">User Management</div>
                          <div
                            className="FontSmall"
                            style={{ fontSize: "12px", color: "#A7A7A7" }}
                          >
                            Branch Name
                          </div>

                          <div className="row">
                            <div className="col-7 Bold padding8T" style={{ fontSize: "20px" }}>
                              {noOfManagers?noOfManagers:"0"}
                            </div>
                            <Button
                                type="button"
                                className="butt-4"
                                style={{
                                  marginLeft: "-60px",
                                  width: "136px",
                                  height:"40px",
                                  fontSize:"16px"
                                }}
                                disabled={false}
                                onClick={()=>{
                                  toggleAssignManager()
                                }}

                              >
                                {"Change Branch"}
                              </Button>


                            {/* <div className="col-5">
                            
                            </div> */}
                          </div>
                        </div>
                      </div>

                    </>
                  )}

                  {/* <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">5</span>
                    <label className="sub-title-edit-vehicle-detial ">
                    Attach Rc Photo
                    </label>
                  </div>
                  <div>
                    <p className="sub-head-body mt-0">
                      Upload format must be in jpg, png, jpeg & pdf
                    </p>
                    <Button className="add-attach">
                      <SvgImage name="AddAttachment" height="36" width="36" />
                    </Button>

                  </div>
                </FormGroup> */}
                </Form>
              )}
            
            </Formik>
            
            <div className="semiBold text3 versionText">Version 11.0.2</div>
            <div style={{ paddingLeft: "5rem", paddingTop: "1rem" }}>
              <Button className="logoutBtn" onClick={()=>{
                handleLogout();
                dispatch(sendAnalitics("logout_OwnerAcc_Home_web"));
                }}>
                <div className="row">
                <div className="col-5" style={{textAlign:"right"}}>
                  <SvgImage name="LogoutLogo"  heigth="20px"  width="20px" />
                </div>
                <div className="col-5" style={{textAlign:"left"}}>Logout</div>
                </div>
              </Button>
              <div
                className="row padding16T FontSmall"
                style={{ color: "#848484", textDecoration: "underline" }}
              >
                <div
                  className="col-6"
                  onClick={handlePolicy}
                  style={{ cursor: "pointer" }}
                >
                  Privacy Policy
                </div>
                <div
                  className="col-6 padding32L"
                  onClick={handleTerms}
                  style={{ cursor: "pointer" }}
                >
                  Terms & Conditions
                </div>
              </div>
            </div>
            <ResetPasswordModal
              handleClose={handleCloseReset}
              open={openReset}
              onHide={handleCloseReset}
              toggleModal={toggleModalReset}
            />
            <AssignBranch
                toggle={toggleAssignManager}
                modal={showAssignManager}
                setModal={setshowAssignManager}
                branches={["sbbsjbs","assas"]}
              />
            {/* <div className="space-div"></div> */}
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default OwnerDetails;
