import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { DatePickerField } from "../../core/inputs/input";
import SvgImage from "../../Icons/Icons";
import "./index.css";
import * as Yup from "yup";
import { getFastagWalletStatement } from "../../../services/Store/Actions/fastagAction";

const ReportFastagWallet = (props) => {
  const [selectAll, setSelectAll] = useState(true);
  const optionList = [
    "Credit",
    "Debit",
    "Closing Balance",
    "Remarks",
    "Fastag Wallet",
    "Card",
    "UPI",
    "LogiMart",
    "Insurance",
    "Subscription Charges",
    "Recharge",
    "Cashback Redeemed",
  ];
  const { loader } = useSelector((state) => state?.report);
  const [checkedState, setCheckedState] = useState(
    new Array(optionList.length).fill(true)
  );

  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    mode: Yup.string().test(
      "noDefaultMode",
      "Please select download format",
      (val) => val != "default"
    ),
  });

  const handleSelect = (position) => {
    if (selectAll) {
      setSelectAll(false);
    }
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const datepickerRefStart = useRef();
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };

  const dispatch = useDispatch();

  function downloadFileFromUrl(url, fileName) {
    fetch(url, {
      method: "GET",
      headers: {
        Origin: "https://storage.googleapis.com",
        "Content-Disposition": "attachment; filename='file.ext'",
      },
    //   responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob);
        // Create an anchor element and trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName; // Set the desired file name
        document.body.appendChild(link);
        link.click();

        // Clean up the Blob URL after the download is triggered
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  }
  
  return (
    <div className="whiteBackground containerShadow" style={{ height: "100%" }}>
      <Formik
        enableReinitialize
        initialValues={{
          startDate: "",
          endDate: "",
          mode: "",
          fieldVal: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log("values 1", values);
          console.log("values 2", selectAll, optionList, checkedState);
          const checkList = checkedState.find((ele) => ele == true);
          console.log("values 3", checkList);
          if (checkList == undefined && !selectAll) {
            console.log("error");
            actions.setFieldError(
              "fieldVal",
              "Please select atleast one parameter."
            );
          } else {
            const payload = {
              from: values?.startDate,
              to: values?.endDate,
              mode:values?.mode
            };
            
          
            dispatch(
                getFastagWalletStatement(
                payload,
                
                // {
                //   "debit": selectAll?"debit": checkedState[1]?"debit":!checkedState[0]?"debit":"any",
                //   "remarks": selectAll?"remarks": checkedState[3]?"remarks":"any",
                //   "closingBalance": selectAll?"closingBalance": checkedState[2]?"closingBalance":"any",
                //   "FastagWallet": selectAll?"FastagWallet": checkedState[4]?"FastagWallet":"any",
                //   "Card": selectAll?"Card": checkedState[5]?"Card":"any",
                //   "UPI": selectAll?"UPI": checkedState[6]?"UPI":"any",
                //   "logimart": selectAll?"logimart": checkedState[7]?"logimart":"any",
                //   "Subscription": selectAll?"Subscription": checkedState[9]?"Subscription":"any",
                //   "Insurance": selectAll?"Insurance": checkedState[8]?"Insurance":"any",
                //   "cashback": selectAll?"cashback": checkedState[11]?"cashback":"any",
                //   "Recharge": selectAll?"Recharge": checkedState[10]?"Recharge":"any",
                //   "credit": selectAll?"credit": checkedState[0]?"credit":!checkedState[1]?"credit":"any",
                // }
                (res) => {
                  console.log("reply", res);
                  window.open(res?.data?.data?.url);
                  // window.location.href = res.data.data.url;
                  // const fileUrl = res?.data?.data?.url;
                  // const fileName = "file.pdf";
                  // downloadFileFromUrl(fileUrl, fileName);
                }
              )
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <FormGroup
              className="padding24"
              style={{ paddingBottom: "0.5rem" }}
            >
              <div className="row ">
                <div className="col-5">
                  <div className="heading4 Regular padding8T padding8B">
                    Starting Date
                  </div>
                  <div className="searchBox" style={{ width: "100%" }}>
                    <DatePickerField
                      classname="searchBar-input"
                      name="startDate"
                      header="Select Start date"
                      value={values.startDate}
                      onChange={(v) => {
                        setFieldValue("startDate", v, true);
                      }}
                      maxDate={
                        values.endDate === "" ? new Date() : values.endDate
                      }
                      ref1={datepickerRefStart}
                    />
                    <label
                      className="date-input"
                      style={{
                        position: "relative",
                        top: "-5px",
                        left: "-1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickDatepickerIcon1();
                      }}
                    >
                      <SvgImage
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        // classname="date-logo"
                      />
                    </label>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.startDate && touched.startDate ? (
                      <div>*{errors.startDate}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-5">
                  <div className="heading4 Regular padding8T padding8B">
                    Ending Date
                  </div>
                  <div className="searchBox" style={{ width: "100%" }}>
                    <DatePickerField
                      classname="searchBar-input"
                      name="endDate"
                      header="Select End date"
                      value={values.endDate}
                      onChange={(v) => {
                        setFieldValue("endDate", v, true);
                      }}
                      //onChange={(v) => setFieldValue("endDate", v, true)}
                      minDate={values.startDate === "" ? "" : values.startDate}
                      maxDate={new Date()}
                      ref1={datepickerRefEnd}
                    />
                    <label
                      className="date-input"
                      for="endDate"
                      style={{
                        position: "relative",
                        top: "-5px",
                        left: "-1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickDatepickerIcon2();
                      }}
                    >
                      <SvgImage
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        // classname="date-logo"
                      />
                    </label>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.endDate && touched.endDate ? (
                      <div>*{errors.endDate}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </FormGroup>

            
            <FormGroup
              className="padding24  padding0T padding0B"
              style={{ height: "88%" }}
            >
              <div className="row" style={{ height: "100%" }}>
                {/* <div className="col-6" style={{ height: "100%" }}>
                  <div className="heading2 Bold padding16B padding24T">
                    Select Required Parameters
                  </div>
                  <div className="flexStyling padding8">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      value="allOptions"
                      onChange={(e) => {
                        const { value, checked } = e.target;
                        console.log("selected", value, checked);
                        setSelectAll(checked);
                        const newState= new Array(optionList.length).fill(checked);
                        setCheckedState(newState);
                      }}
                      className="form-check-input"
                    />
                    <div className="heading4 semiBold padding8L">
                      Select All 
                    </div>
                  </div>
                  {optionList.map((item, i) => {
                    return (
                      <div className="flexStyling padding8 LowBorder" key={i}>
                        <input
                          type="checkbox"
                          value={item}
                          checked={selectAll || checkedState[i]}
                          className="form-check-input"
                          onChange={() => {
                            handleSelect(i);
                          }}
                        />
                        <div className="heading4 semiBold padding8L">
                          {item}
                        </div>
                      </div>
                    );
                  })}
                  <div className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}>
                        {errors.fieldVal && touched.fieldVal ? (
                            <div>*{errors.fieldVal}</div>
                        ) : null}
                    </div>
                </div> */}
                {/* leftBorder */}
                <div
                  className="col-6  padding24L"
                  style={{ height: "100%" }}
                  onChange={(event) => {
                    console.log("valllll", event.target.value);
                    setFieldValue("mode", event.target.value, true);
                  }}
                >
                  <div className="heading2 Bold padding16B padding24T">
                    Select Report Format
                  </div>
                  <div className="flexStyling padding8 form-check LowBorder">
                    <input type="radio" id="pdfOpt" name="format" value="pdf" />
                    <label
                      className="heading4 semiBold padding8L form-check-label"
                      for="pdfOpt"
                    >
                      PDF
                    </label>
                  </div>
                  <div className="flexStyling padding8 form-check">
                    <input
                      type="radio"
                      id="excelOpt"
                      name="format"
                      value="csv"
                    />
                    <label
                      className="heading4 semiBold padding8L form-check-label"
                      for="excelOpt"
                    >
                      Excel Sheet
                    </label>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.mode && touched.mode ? (
                      <div>*{errors.mode}</div>
                    ) : null}
                  </div>
                  <div className="padding24T">
                    <Button
                        className="generateOtp semiBold heading2"
                        style={{
                        width: "70%",
                        background: loader ? "#EFEFEF" : "#485B7C",
                        color: loader ? "#B5B5B5" : "#ffffff",
                      }}
                      disabled={loader ? true : false}
                    >
                      {loader ? "Please Wait" : "Download Report"}
                    </Button>
                  </div>
                </div>
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReportFastagWallet;
