import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BusinessDetails from "../../LoginScreen/NewDesktopLogin/Components/Forms/BusinessDetails";
import BusinessDocs from "../../LoginScreen/NewDesktopLogin/Components/Forms/BusinessDocs";
import KYC from "../../LoginScreen/NewDesktopLogin/Components/Forms/KYC";
import SetPin from "../../LoginScreen/NewDesktopLogin/Components/Forms/SetPin";
import SetPinConfirm from "../../LoginScreen/NewDesktopLogin/Components/Forms/SetPinConfirm";
import OnboardingBar from "../../LoginScreen/NewDesktopLogin/Components/OnboardingBar";
import RightInfoPanel from "../../LoginScreen/RightInfoPanel";
import SvgImage from "../Icons/Icons";
import Toast from "../subComponents/toast";

const SignUp=()=>{

    let data = sessionStorage.getItem("_user");
    let userSession= JSON.parse(data)
    let userStatus=userSession?.user?.status;
    const [regStatus, setRegStatus] = useState(userStatus==0?2:userStatus==1?4:4);
    const toastObject = useSelector((state) => state?.app?.toastObject);
    // const [isRegistered, setIsRegistered] = useState(true);
    const statusList=["User Verification","Owner Details", "Business details", "Business Documents","KYC","Set PIN"];

    const data1=["Hurray!! You have unlocked Logimart & Insurance","Hurray!! You have unlocked Logimart, insurance & trips","You are all set!!"];
    const data2=["1 more step to unlock trips","1 more step to unlock scan & pay", "Set PIN to start securely transacting on LogiPe"]
    const handleGooglePlayButton = () => {
        window.open("https://play.google.com/store/apps/details?id=com.logipeapp");
    };

    const [busName, setBusName] = useState();
    const [busType, setBusType] = useState();
    const [truckNum, setTruckNum] = useState();
    const [panCard, setPanCard]= useState(userSession?.user?.pan);
    const [aadhaarNo, setAadhaarNo]= useState(userSession?.user?.aadhar);
    const [pinVal, setPinVal]= useState("");

    const navigate= useNavigate();
    useEffect(()=>{
        if(userStatus===3){
            navigate("/");
        }
    },[])
    


    return (
        <div className="row padding0 DesktopOuterContainer">
        <div className="col-lg-8 whiteColumn whiteHeight" style={{backgroundColor:"#FFFFFF"}}>
            <div className="row headingLogin">
            {/* <div className="col-6" onClick={()=>{
                window.open("https://www.logipe.com/");
            }} style={{cursor:"pointer"}}>
                <SvgImage name="logoLogin" />
            </div> */}
            {/* {
                isRegistered?
                <div className="heading2 subheading col-6">
                Don't have an account?{" "}
                <span className="Bold " style={{ color: "#2E7C7B", cursor:"pointer" }} onClick={()=>{
                setIsRegistered(false);
                setStatus("0");
                }}>
                Sign Up
                </span>
            </div>
            :
            <div className="heading2 subheading col-6">
                Already a customer?{" "}
            <span className="Bold " style={{ color: "#2E7C7B", cursor:"pointer"  }} onClick={()=>{
                setIsRegistered(true);
                setStatus("0");
                setRegStatus(0)
            }}>
                Log In
            </span>
            </div>
            } */}
            
            </div>
            {/* {
            isRegistered?
            <div>
            {status == "0" ? (
                <EnterNumberForm
                setStatus={setStatus}
                setPhone={setPhone}
                handleTermShow={handleTermShow}
                handlePrivacyShow={handlePrivacyShow}
                handleSignup={handleSignup}
                />
            ) : (
                <EnterOTPForm
                phone={phone}
                handleTermShow={handleTermShow}
                handlePrivacyShow={handlePrivacyShow}
                setStatus={setStatus}
                />
            )}
            </div>
            : */}
            <div className="padding24 padding16T">
                <div>
                <div className="padding16L Regular text3 greyFont">Welcome! 👋</div>
                <div className="padding16L heading1 semiBold greyFont">Create your account</div>
                <OnboardingBar regStatus={regStatus}/>
                {
                    (regStatus>2)?
                    <div className="padding16 padding32L">
                    <div className="row banner">
                    <div className="col-1">
                        {
                            (regStatus==5)?
                            <SvgImage name="allDoneLogo" />
                            :<SvgImage name="thumpsUp" />
                        }
                    </div>
                    <div className="col-8 ">
                        <div className="heading3">{(regStatus==5)?data1[2]:(regStatus==4)?data1[1]:data1[0]}</div>
                        <div className="greyFont textDiv">{(regStatus==5)?data2[2]:(regStatus==4)?data2[1]:data2[0]}</div>
                    </div>
                    </div>
                </div>
                :<></>
                }              
                {
                    regStatus==2?
                    <BusinessDetails
                    setRegStatus={setRegStatus} 
                    setBusName={setBusName}
                    setBusType={setBusType}
                    setTruckNum={setTruckNum}
                    />
                    :regStatus==3?
                    <BusinessDocs
                    setRegStatus={setRegStatus}
                    busName={busName}
                    busType={busType}
                    truckNum={truckNum}
                    />
                    :regStatus==4?
                    <KYC
                    setRegStatus={setRegStatus}
                    panCard={panCard}
                    setPanCard={setPanCard}
                    aadhaarNo={aadhaarNo}
                    setAadhaarNo={setAadhaarNo}
                    />
                    :regStatus==5?
                    <SetPin 
                    setRegStatus={setRegStatus}
                    setPinVal={setPinVal}/>
                    : <SetPinConfirm
                    setRegStatus={setRegStatus}
                    pinVal={pinVal}/>
                }
                </div>
                <div>

                </div>
            </div>
            {/* } */}
            
            <div className="lowerDiv">
            <div className="footDiv">
            <div className="Medium text4 footerText">
                <div className="padding8R">Check out LogiPe payments app</div>
                <div onClick={handleGooglePlayButton} style={{ cursor: "pointer" }}>
                <SvgImage name="playStoreLogin" />
                </div>
            </div>
            </div>

            </div>
            
        </div>
        <div className="col-lg-4 greenColumn padding0 greenHeight" style={{position:"relative"}}>
            <RightInfoPanel />
        </div>
        {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={!toastObject?.toastStatus}
          isOpen={toastObject?.toastStatus}
          name="signUp"
          handleClose={() => {
            // dispatch(
            //   toastStatus({
            //     toastStatus: false,
            //     message: "",
            //     status: true,
            //   })
            // );
            // if (!toastObject?.toastStatus)
            window.location = "/";
          }}
        />
      ) : (
        <></>
      )}
        
    </div>
    );
}

export default SignUp;