import React, { useState,useEffect } from "react";
import { Button, Form, FormGroup } from "reactstrap";
import PinInput from "react-pin-input";
import { Formik } from "formik";
import SvgImage from "../../../Icons/Icons";
import { useDispatch ,useSelector } from "react-redux";
import "./index.css";
import * as Yup from "yup";
import { createPaymentTokenAction, virtualAccountCredit } from "../../../../services/Store/Actions/receivableActions";
import { Link } from "react-router-dom";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import copy from "copy-to-clipboard";
import { qrcodeAction } from "../../../../services/Store/Actions/walletAction";
import { toast } from "react-toastify";

const QRAddBalance=(props)=>{
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    amount,
    ownerLogipeBalance,
    color,
  } = props;

  const dispatch = useDispatch();

  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.userId;
  const phoneSession = obj?.user?.phone;
  // const ownerBalance = ownerLogipeBalance === "" ? 0 : ownerLogipeBalance;
  //console.log("blanace", ownerBalance);
  const customId = "custom-id-qrCopied page";
  const notify = () => toast.success("QR Code was successfully copied.",{
    toastId:customId
  });
  const copyToClipboard = () => {
    copy("808080"+phoneSession+".06@cmsidfc");
    notify();
  }
  useEffect(()=>{
    dispatch(sendAnalitics("UPI_MA_Web"));
    dispatch(qrcodeAction());
  },[]);

  const { qrCode } = useSelector((state) => state?.wallet);
  console.log("QR code", qrCode);
  return (
    <div>
      <div className="card-wallet-icon-body1">
        <SvgImage
          name="BankLogo"
          height="43.81"
          width="43.81"
          classname="card-wallet-logo-BankLogo"
        />
        <div>
          <p className="body-sub-part1">Owner’s Master Account Balance</p>
          <p className="body-sub-part2">{"₹" + ownerLogipeBalance}</p>
        </div>
      </div>

      <div className="sub-title-1">
        <span class="circle-logo">1</span>
        <p className="sub-head1">Add Balance Via</p>
      </div>

      <div style={{marginLeft:"5rem", display:"flex"}}>
      <div  style={{padding:"0.5rem 0.3rem"}}>
          <Button className={btn === 0?"btnSelect":"btnNotSelect"} 
            onClick={() => handleClick(0)}>
            NEFT/RTGS
          </Button>
        </div>
        <div style={{padding:"0.5rem 0.3rem"}}>
          <Button className={btn === 1?"btnSelect":"btnNotSelect"} 
            onClick={() => handleClick(1)}>
            UPI
          </Button>
        </div>
        <div style={{padding:"0.5rem 0.3rem"}}>
          <Button className={btn === 2?"btnSelect":"btnNotSelect"} 
            onClick={() => handleClick(2)}>
            Net Banking/ Debit Card
          </Button>
        </div>
      </div>
      <div>
            <div className="sub-title-1">
              <span class="circle-logo">2</span>
              <p className="transfer-head">
              Collect funds via UPI
              </p>
            </div>
            <div style={{textAlign:"center", paddingTop:"2.5rem"}}>
                <div className="Bold heading4">Scan QR Code</div>
                <div >
                    <img src={qrCode} alt="No QR Code Available" style={{width:"14rem", height:"14rem"}} />
                </div>
                <div  className="Regular heading4">UPI ID : 808080{phoneSession}.06@cmsidfc</div>
                <div className="" onClick={copyToClipboard} style={{cursor:"pointer"}}>
                    {/* <div className="col-6 greenFont Regular heading4" style={{textAlign:"right"}}>Copy</div> */}
                    
                        <SvgImage name="CopyLogo" />
                    
                </div>
            </div>
        </div>
      
    </div>
  );
}

export default QRAddBalance;
