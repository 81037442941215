import React, { useEffect, useState } from "react";
import {
  getLogiPeAccount,
  clearCustomerRecievables,
  cleanCustomerAddLoader,
} from "../../services/Store/Actions/receivableActions";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import FullCustomerList from "./SubComponents/FullCustomerList";
import ManageDriverCardType1 from "../ManageDriver/SubComponents/ManageDriverTopCard";
import ManageCustomerAmountCard from "./SubComponents/manageCustomersAmountCard";
import ManageCustomerDashboard from "../ManageCustomers/SubComponents/ManageCustomerDashboard/index";
import ManageCustomerRightMidSection from "./SubComponents/ManageCustomerRightMidSection";
import Toast from "../subComponents/toast";
import { useNavigate } from "react-router-dom";
import {useParams} from 'react-router-dom';
import { toastStatus } from "../../services/Store/Actions/appActions";
function ManageCustomer(props) {
  const {sideBar} = props;
  const dispatch = useDispatch();
  const { logipeAccount } = useSelector((state) => state?.receivable);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const [updateBookingModalopen, setUpdateBookingModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth );
  const [open, setOpen] = useState(false);

  const [user, setUser] = useState("");

  const { allCustomers, allCustomersList } = useSelector(
    (state) => state?.receivable
  );

  console.log("customers - $$$$$$$$",allCustomers);
  let {id} = useParams();

  useEffect(() => {
    if(id!==undefined && id!==""){
      let val=allCustomers?.rows?.filter(element => element.customerId==id)[0];
      dispatch(clearCustomerRecievables());
      setUser(val?.customerId);
      console.log("ababababaab",val);
    }
  },[id,allCustomers])

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const navigate = useNavigate();
 

  useEffect(() => {
    dispatch(getLogiPeAccount());
  
  }, [updateBookingModalopen, open]);
  const data = [
    {
      text: "Total Balance",
      text_color: "#25252D",
      logo: "ManageCustomerBalanceLogo",
      className:"ManageCustomerCardType-1 firstCard",
      number: logipeAccount?.[0]?.totalBillAmount || 0,
    },
    {
      text: "Total Paid Balance",
      text_color: "#09BD7B",
      logo: "ManageCustomerPaidBalanceLogo",
      className:"ManageCustomerCardType-1 centerCard",
      number: logipeAccount?.[0]?.totalPaymentRecievedAmount || 0,
    },
    {
      text: "Total Pending Balance",
      text_color: "#FCC900",
      logo: "ManageCustomerPendingBalanceLogo",
      className:"ManageCustomerCardType-1 lastCard",
      number:
        logipeAccount?.[0]?.totalBillAmount -
          logipeAccount?.[0]?.totalPaymentRecievedAmount || 0,
    },
  ];

  // offset value
  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);

  // search value
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let searchParam = params.search===undefined?"":params.search;
  console.log("window.innerWidth",window.innerWidth);
  return (
    <div className="container " style={{marginLeft: sideBar? '210px' : '100px',maxWidth: sideBar ?windowWidth - 240 :windowWidth- 130 }}>
      <div className="row">
        <div className="col-lg-4 FullDriverDashboardBox">
          <div className="">
            <FullCustomerList 
            // handleUser={handleUser} 
            />
          </div>
        </div>
        <div className="col-lg-8 FullDriverDashboardBox">
          <div className="Manage-Customer-card-type-1-box">
            {data.map((item) => {
              return (
                <div className={item.className}>
                  <ManageCustomerAmountCard
                    text={item.text}
                    number={item.number}
                    color={item.text_color}
                    logo_name={item.logo}
                  />
                </div>
              );
            })}
          </div>
          <div className="manage-customer-dashboard-box">
            <ManageCustomerDashboard
              customer={user}
              updateBookingModalopen={updateBookingModalopen}
              setUpdateBookingModalOpen={setUpdateBookingModalOpen}
              open={open}
              setOpen={setOpen}
            />
          </div>
        </div>
      </div>
        {toastObject.toastStatus? 
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            dispatch(cleanCustomerAddLoader());
          }}
          isOpen={toastObject?.toastStatus}
          name="customer"
          handleClose={()=>{
            // dispatch(
            //   toastStatus({
            //     toastStatus: false,
            //     message: "",
            //     status: true,
            //   })
            // );
            // if (!toastObject?.toastStatus)
            //navigate(`/Customers/${id}`)
            //console.log("uuuuuuuuuuuuuuuuuuu", window.href.location);
              window.location='/Customers/'+id+"?search="+searchParam+"&offset="+offsetVal;
              //alert("close button clicked");
              // navigate("/Customers");
          }}
        />:
        <></>}
    </div>
  );
}
export default ManageCustomer;
