import "./index.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import CountAndLinkBox from "../CountAndLinkBox";
import CardWalletAllTransactionWallet from "../../../Modals/CardWalletAllTransactionModal";
import { cleanFastagWalletTransaction, getFastagWalletTransaction } from "../../../../services/Store/Actions/fastagAction";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";

const LowerDiv = (props) => {
  const { text, Title, SvgName1, SvgName2, subHead, text1, text2 } = props;
  const dispatch= useDispatch();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    //setProceed(0);
    //console.log("closed");
  };

  function toggleModal() {
    setOpen(!open);
    //setProceed(0);
  }

  const handleShow = () => {
    dispatch(sendAnalitics("Txn_FW_Web"));
    dispatch (cleanFastagWalletTransaction());
    dispatch(
            getFastagWalletTransaction({
            offset:0,
            limit:"",
            fromDate: "",
            toDate: "",
            sortBy:"",
            sortAs:""
            })
    );
    setOpen(true);
    //console.log(a);
  };

    const { fastagWallet } = useSelector((state) => state?.fastag);
    const fastagWalletAmount =
    fastagWallet?.balance == null ? 0 : fastagWallet?.balance;
    const allCardBalance = useSelector(
        (state) => state?.cardWallet?.allCardBalance?.data?.totalBal
      );
    const allCardsSpending = useSelector(
        (state) => state?.cardWallet?.allCardBalance?.data?.totalThisMonthSpending
      );
    const allCustomers= useSelector((state)=>state?.receivable?.logipeAccount?.[0])
    const totalPaid= allCustomers?.totalPaymentRecievedAmount;
    const totalPending= ((allCustomers?.totalBillAmount)- totalPaid);   
    //console.log("all drivers", logipeAccount);  
    
    const amountGreen=  Title === "FASTag Wallet"?
    fastagWalletAmount: Title === "Driver Cards"?
    allCardBalance:
    totalPaid===undefined?0:totalPaid;
  
    const amountRed= Title==="Driver Cards"?
    allCardsSpending:
    totalPending===undefined?0:totalPending;

    useEffect(()=>{
      dispatch(sendAnalitics("FW_Web"));
    },[])
//   const [open, setOpen] = useState(false);
//   const [refresh, setRefresh]= useState(false);

//   function handleClose() {
//     setRefresh(!refresh);
//     setOpen(false);
//   }
//   function toggleModal() {
//     setOpen(!open);
//     setRefresh(!refresh);
//   }
//   const handleShow = () => {
//     setOpen(true);
//     //console.log(a);
//   };
  
//   useEffect(()=>{
//     dispatch (cleanFastagWalletTransaction());
//     dispatch(
//         getFastagWalletTransaction({
//         offset:0,
//         limit:"",
//         fromDate: "",
//         toDate: "",
//         sortBy:"",
//         sortAs:""
//         })
//       );
//   },[])

//console.log("88888888888888888888", typeof amountGreen);
  return (
    <>
      <div className="row padding16B">
        <div className="col-1 padding8R">
          <SvgImage name={SvgName1} />
        </div>
        <div className="col-6 padding8B padding16L headingLow semiBold">
          {Title}
        </div>
        {Title === "FASTag Wallet" ? (
          <div className="col-5 greenFont heading3 semiBold pointing flexStyling"
                onClick={handleShow}>
            <div style={{ marginLeft: "-20px", marginTop: "4px" }}>
              All Transactions
            </div>
            <SvgImage name="ForwardArrow" height="32" width="25" className="" />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="row padding8T padding8B">
        <div className="col-1">
          <SvgImage name="cashIn" />
        </div>
        <div className="col-5 paddingCash">
          <div className="textDiv1 semiBold secondaryBlack">{text1}</div>
          <div className="heading2 semiBold">₹{Number(amountGreen).toFixed(2)}</div>
        </div>
        {Title !== "FASTag Wallet" ? (
          <>
            <div className="col-1">
              <SvgImage name="cashOut" />
            </div>
            <div className="col-5 paddingCash" >
              <div className="textDiv1 semiBold secondaryBlack">{text2}</div>
              <div className="heading2 semiBold">₹{Number(amountRed).toFixed(2)}</div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="padding16T">
        <CountAndLinkBox SvgName2={SvgName2} subHead={subHead} text={text} />
      </div>
      {
        <CardWalletAllTransactionWallet
        Title="Fastag Wallet"
        open={open}
        toggleModal={toggleModal}
        handleClose={handleClose}
        />
      }
    </>
  );
};

export default LowerDiv;
