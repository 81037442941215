import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import "./index.css";
import {
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";
import { useNavigate } from "react-router-dom";

function OnboardingBanner(props){

    const data1=[{icon:"bIcon1",title:"LogiPe Cards", detail:"Partnering with YBL, we have enabled seem-less transactions on the go for  you & your drivers. Set limits & use on anywhere including ATMs."},
    {icon:"bIcon2",title:"LogiPe FASTags", detail:'IDFC & YBL FASTags for a hassle-free journey, enabled with auto-recharge to avoid interruptions.'},
    // {icon:"bIcon3",title:"LogiPe Scan & Pay", detail:'Scan & Pay any QR code and pay in seconds. Enable Scan & Pay for your drivers via card.'},
    {icon:"bIcon4",title:"Receivables", detail:'Collect funds from your customers via our payment links & QR code. Send regular reminders to your customers for the same.'}]

    const data2=[{icon:"bIcon5",title:"LogiSure", detail:'Get guaranteed best quotes for renewing your commercial vehicle insurance on EMI from your preferred provide.'},
    {icon:"bIcon6",title:"LogiMart", detail:'Get the best deals on tyres & lubricants with guaranteed same day delivery.'},
    {icon:"bIcon7",title:"LogiPe Trips", detail:'Record the expenses of all your trips in real time. Get information on the cheapest & fastest routes and much more.'}]
    
    const { open, handleClose, toggleModal, onHide } = props;

    const navigate= useNavigate();
    const handleLogin=()=>{
        navigate("/");
    }
    return(
        <Modal
      isOpen={open}
      onHide={handleClose}
      className="myModalBanner all-transaction-main-modal"
      toggle={toggleModal}
      aria-labelledby="myModalLabel3"
    >

      <ModalBody className="transactionModalBody" style={{padding:"0px"}}>
        <div className="PGCollectionBox">
            <div 
            className="closeEffect"
            onClick={toggleModal}
            style={{cursor:"pointer",zIndex:"2000", position:"absolute", top:"0.5rem", right:"1rem"}}
            // // className=""
            >
                <SvgImage
                  classname = 'close-btn-text'
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
            </div>
            <div>
                <div style={{position:"absolute", top:"-1rem", zIndex:"1"}}>
                    <SvgImage name="bannerTopTri"/>
                </div>
                <div style={{position:"absolute", left:'25%', top:"-1.5rem", zIndex:"500"}}>
                    <SvgImage name="bannerRect1" />
                </div>
                <div style={{position:"absolute", left:'48%', top:"1%", zIndex:"500"}}>
                    <SvgImage name="bannerRect2" />
                </div>
                <div className="padding32T" style={{padding:"1rem 29rem", position:"relative", zIndex:"1000"}}>
                    <SvgImage name="bIconLaptop" />
                </div>
                <div className="bannerTitle Bold">LogiPe <span className="greenFont">Services</span></div>
                <div className="row padding16 padding8T padding8B">
                {
                    data1?.map((item, i)=>{
                        return(
                            <div className="col-4 padding8">
                                <div className="Bannerbox">
                                    <div>
                                        <SvgImage name={item?.icon} />
                                    </div>
                                    <div className="Bold heading2 padding8B">{item?.title}</div>
                                    <div className="Medium text3">{item?.detail}</div>
                                </div>
                            </div>
                        )
                        })
                    }
                </div>
                <div className="row " style={{padding:"0rem 9rem 0.5rem"}}>
                {
                    data2?.map((item, i)=>{
                        return(
                            <div className="col-4 padding8">
                                <div className="Bannerbox ">
                                    <div>
                                        <SvgImage name={item?.icon} />
                                    </div>
                                    <div className="Bold heading2 padding8B">{item?.title}</div>
                                    <div className="Medium text3">{item?.detail}</div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <div className={"paddingBtnBanner"} style={{textAlign:"center", alignItem:"center", }}>
                    <Button className="greenButton Medium text2 padding24L padding24R" style={{width:"18rem", height:"45px"}}
                    onClick={handleLogin}
                    >Get Started</Button>
                </div>
            </div>
            </div>
      </ModalBody>
      </Modal>
    )
}

export default OnboardingBanner;