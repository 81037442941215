import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import SvgImage from "../../../Icons/Icons";
import RecentTransactionBox from "../../../subComponents/recentTransaction";
import "./index.css";
import { getFastagTransactionHistory, reissueAvailableAction } from "../../../../services/Store/Actions/fastagAction";
import { useParams } from "react-router-dom";

function OmcVehicleDetail(props) {
  const { value, name, onclick, title, fleetId, tagId, bal, regNumber } = props;
  const omcBal = props?.bal == null ? false : true;
  console.log("bbbbbbbbbbbbbbbbbb", props.bal)
  const dispatch = useDispatch();
  const DATA = useSelector((state) => state);

  const { allFleets } = useSelector((state) => state?.manageVehicle);

  const allFleetsData = allFleets.rows;

  // const allFleetsWithoutFastag = allFleetsData?.filter(
  //   (data) => data?.Fastag === null
  // )?.length;

  const allFleetsWithFastag = allFleetsData?.filter(
    (data) => data?.Fastag !== null
  );
  const isReissueAvailable = useSelector(
    (state) => state?.fastag?.isReissueAvailable
  );
  let { id } = useParams();
  const fastagDetailsFleet = allFleetsWithFastag?.filter(
    (d) => d?.Fastag?.fleetId === id
  );

  const vehicleChosen = allFleetsData?.filter(
    (data) => data?.fleetId === fleetId
  );
  //console.log(" minBalAutoAlert",fastagDetailsFleet[0]?.Fastag?.tagDetails?.minBalAutoAlert);
  //console.log(" d?.FastagBalance",bal);
  // const lowBalanceStatus =
  // fastagDetailsFleet &&
  // Number(fastagDetailsFleet[0]?.Fastag?.tagDetails?.minBalAutoAlert) >
  //   Number(bal)
  //   ? true
  //   : false;
  const hotlistStatus = isReissueAvailable?.npci_ExcCode === 'Hotlisted' ? 1 : 0;
  const lowBalanceStatus = isReissueAvailable?.npci_ExcCode === "Low Balance" ? true : false;
  const hotlistAndLowBalance = isReissueAvailable?.npci_ExcCode === "Hotlisted, Low Balance" ? true : false;
  console.log(" lowBalanceStatus", isReissueAvailable);
  // console.log(" lowBalanceStatus", fastagDetailsFleet);
  //console.log("low",fastagDetailsFleet[0]);

  useEffect(() => {
    if(vehicleChosen!==undefined)
    {
      if(fleetId){
        dispatch(
          reissueAvailableAction({
            fleetId: fleetId,
            vrn: vehicleChosen[0]?.regNumber,
          })
        );
      }
    }
  }, [fleetId]);

  console.log("hoslisted status",hotlistStatus);
  return (
    <div>
      <Card className="main-card-fastag">
        <div className="row">
          <div className="col-5 padding0R ">
            <div className="row padding16 padding32T padding0R padding8L">
              <div className="col-2 leftPadding">
                <SvgImage name="blacktollBooth" />
              </div>
              <div className="col-10 padding0R">
                <CardTitle className=" Bold heading2">OMC Details</CardTitle>
              </div>
            </div>
          </div>
          {/* {hotlistAndLowBalance ? (
            <>
            <div className="col-7 padding32T padding0R row" style={{paddingLeft:"19px", }}>
            <div className="hotlist FontSmall" style={{ textAlign: "center",padding:"4px",height:"1.6rem" }}>VRN Hotlisted!</div>
            <button className="Low-balance-fastag " style={{ textAlign: "center",marginLeft:"4px", padding:"1px 6px" }}>Low Balance</button>
          </div>
          </>)
          : hotlistStatus === 1 ? (
            <div className="col-7 padding32T " style={{paddingLeft:"40px" }}>
            <div className="hotlist FontSmall" style={{ textAlign: "center",padding:"4px", height:"1.6rem" }}>VRN Hotlisted!</div>
          </div>
          ) :
          lowBalanceStatus ? (
            <div className="col-5 padding32T" style={{ textAlign: "center" }}>
              <button className="Low-balance-fastag " style={{ textAlign: "center",marginLeft:"6px", padding:"1px 6px" }}>Low Balance</button>
            </div>
          ) : 
            <></>
          } */}
        </div>
        <div className="row divPadding">
          <div
            className="current-omc-balance"
            // style={{ background: "#FFEAEE" }}
          >
            <p className="current-fastag-balance-amount">
              {omcBal ? (
                lowBalanceStatus ? (
                  <div
                    className="Bold heading2 padding8T"
                    style={{ color: "#FE2950" }}
                  >
                    {"₹ " + Number(props?.bal).toFixed(2)}
                  </div>
                ) : (
                  <div className="Bold heading2 padding8T">
                    {"₹ " + Number(props?.bal).toFixed(2)}
                  </div>
                )
              ) : (
                <>
                  <div
                    className="Bold heading1 padding16"
                    style={{ textAlign: "center" }}
                  >
                    FASTag Not Linked
                  </div>
                </>
              )}
              {/* ₹{props.bal == "" || props?.bal == null ? "0" : props?.bal} */}
            </p>
            <p className="FontNB" style={{ position: "relative", top: "-8px" }}>
              {omcBal ? "OMC Balance" : ""}
            </p>
          </div>
        </div>
        {omcBal == "" || omcBal == null ? (
          <></>
        ) : (
          <div className="row">
            <div className="col-12 recent-trans-box">
              <RecentTransactionBox
                type = "omc"
                Data={DATA?.omc?.omcTransaction}
                tagId={props?.tagId}
                fleetId={fleetId}
                regNumber={regNumber}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
export default OmcVehicleDetail;
