import {call, put, takeEvery} from 'redux-saga/effects';
import {
  getVirtuAccountApi,
  bankDirectTransferSagaApi,
  bankTransferAddBeneficiaryApi,
  getBeneficiaryApi,
  bankBeneficiaryTransferSagaApi,
  getCollectionsApi,
  getSettlementsApi,
  createVendorApi,
  getLogipeVendorDetailsApi,
  editVendorApi,
  getVendorApi,
  vendorTransactionsApi,
  vendorUploadBillApi,
  vendorGetSignedUrlApi,
  reportGenerateStatementForVendorApi,
  transferToVendorApi,
  getVendorDetailsApi
} from '../../Utils/Apis';
import {processErrorAndRespond} from '../../Utils/functions';
import {
  getVirtuAccountSuccess,
  bankDirectTransferSuccess,
  bankDirectTransferError,
  bankBeneficiaryTransferSuccess,
  bankTransferToBeneficiaryError,
  bankTransferBeneficiaryAddSuccess,
  bankTransferBeneficiaryError,
  getBeneficiarySuccess,
  getCollectionsSuccess,
  getSettlementsSuccess,
  createVendorSuccess,
  getLogipeVendorDetailsSuccess,
  editVendorSuccess,
  getVendorSuccess,
  vendorTransactionSuccess,
  vendorUploadBillSuccess,
  vendorGetSignedUrlSuccess,
  generateStatementForVendorSuccess,
  transferTorVendorSuccess,
  getVendorDetailsSuccess
} from '../Actions/beneficiaryAction';
import {
  GET_VIRTU_ACCOUNT,
  BANK_DIRECT_TRANSFER,
  BANK_TRANSFER_BENEFICIARY_ADD,
  GET_BENEFICIARY,
  BANK_TRANSFER_TO_ADDED_BENEFICIARY,
  GET_COLLECTIONS,
  GET_SETTLEMENTS,
  CREATE_VENDOR,
  GET_LOGIPE_VENDOR_DETAILS,
  EDIT_VENDOR,
  GET_VENDOR,
  VENDOR_TRANSACTION,
  VENDOR_UPLOAD_BILL,
  VENDOR_GET_SIGNED_URL,
  GENERATE_STATEMENT_FOR_VENDOR,
  TRANSFER_TO_VENDOR,
  GET_VENDOR_DETAILS,
} from '../storeTypes';
import { toastStatus } from '../Actions/appActions';


function* getVirtuAccountSaga() {
  try {
    const response = yield call(getVirtuAccountApi);
    if (response?.data?.type == 'success') {
      yield put(getVirtuAccountSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log('getVirtuAccountSaga Error.Response:- ', error?.response?.data);
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.SHORT);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* bankDirectTransferSaga(action) {
  try {
    const response = yield call(bankDirectTransferSagaApi, action.payload);
    if (response?.data?.type == 'success') {
      console.log('bankDirectTransferSaga', response.data);
      action.callBack(response);
      yield put(bankDirectTransferSuccess());
    }
  } catch (error) {
    yield put(bankDirectTransferError());
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
        status:400,
      });
    } else {
      action.callBack({
        message:error?.response?.data?.error
      });
    }
    //alert(`Error : ${error?.response?.data?.message}`);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}



function* bankBeneficiaryTransferSaga(action) {
  try {
    console.log("asmi-> success in saga", action.payload);    
    const response = yield call(bankBeneficiaryTransferSagaApi, action.payload);
    if (response?.data?.type == 'success') {
      console.log('bankBeneficiaryTransferSaga', response.data);
      action.callBack(response);
      yield put(bankBeneficiaryTransferSuccess());
    }
  } catch (error) {
    console.log("asmi-> error1 in saga", error); 
    yield put(bankTransferToBeneficiaryError());
    console.log("asmi-> error2 in saga", error); 
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
        status:400,
      });
    } else {
      action.callBack(error?.response?.data?.error);
    }
    // console.log(
    //   'bankDirectTransferError Error.Response:- ',
    //   error?.response?.data,
    // );
    //alert(`Error : ${error?.response?.data?.message}`);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}


function* bankTransferAddBeneficiarySaga(action) {
  try {
    const response = yield call(bankTransferAddBeneficiaryApi, action.payload);
    if (response?.data?.type == 'success') {
      console.log('bankTransferAddBeneficiarySaga', response.data);
      action.callBack(response.data);
      yield put(bankTransferBeneficiaryAddSuccess());
    }
  } catch (error) {
    yield put(bankTransferBeneficiaryError());
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data?.error);
    }
    // console.log(
    //   'bankTransferAddBeneficiarySaga Error.Response:- ',
    //   error?.response?.data,
    // );
    alert(`Error : ${error?.response?.data?.message}`);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* getBeneficiarySaga() {
  try {
    const response = yield call(getBeneficiaryApi);
    console.log('getBeneficiarySaga >>> ', response);
    if (response?.data?.type == 'success') {
      yield put(getBeneficiarySuccess(response?.data?.data));
    }
  } catch (error) {
    console.log('Error.Response:- ', error?.response?.data);
    //ToastAndroid.show(error?.response?.data.message, ToastAndroid.SHORT);
    console.log('API Error:- ', processErrorAndRespond(error));
  }
}


function* getCollectionsSaga(action) {
  try {
    console.log("inside sage for users", action);
    //console.log("api used", getUserDetailsApi);
    const response = yield call(getCollectionsApi);
    console.log ("saga for all cards");
    console.log("response all cards", response);
    if (response?.status == 200) {
      console.log("sagA", response?.data);
      yield put(getCollectionsSuccess(response?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    console.log("inside sage for errors in users");
    console.log("Error.Response getUSerDetails:- ", error.response?.data);
  }
}

function* getSettlementsSaga(action) {
  try {
    console.log("inside sage for users", action);
    //console.log("api used", getUserDetailsApi);
    const response = yield call(getSettlementsApi);
    console.log ("saga for all cards");
    console.log("response all cards", response);
    if (response?.status == 200) {
      console.log("sagA", response?.data);
      yield put(getSettlementsSuccess(response?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    console.log("inside sage for errors in users");
    console.log("Error.Response getUSerDetails:- ", error.response?.data);
  }
}

function* createVendorSaga(action) {
  try {
    console.log("createVendorSaga action", action);
    const response = yield call(createVendorApi,action?.payload);
    console.log("response createVendorSaga", response);
    action?.callBack(response)
    if (response?.status == 200) {
      console.log("sagA", response?.data);
      yield put(createVendorSuccess(response?.data));
      // yield put(
      //   toastStatus({
      //     toastStatus: true,
      //     message:"vendor created successfully",
      //     status: true,
      //   })
      // );
    }
  } catch (error) {
    action?.callBack(error?.response)
    // yield put(
    //   toastStatus({
    //     toastStatus: true,
    //     message: error?.response?.data?.message||"error in creating vendor",
    //     status: false,
    //   })
    // );
    console.log("inside sage for errors in users");
    console.log("Error.Response createVendorSaga:- ", error.response?.data);
  }
}
function* getLogipeVendorDetailsSaga(action) {
  try {
    const response = yield call(getLogipeVendorDetailsApi,action?.payload);
    console.log("response getLogipeVendorDetailsSaga", response);
    if (response?.status == 200) {
      yield put(getLogipeVendorDetailsSuccess(response?.data?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    console.log("inside sage for errors in users");
    console.log("Error.Response getLogipeVendorDetailsSaga:- ", error.response?.data);
  }
}
function* editVendorSaga(action) {
  try {
    const response = yield call(editVendorApi,action?.payload);
    console.log("response editVendorSaga", response);
    action?.callBack(response)
    if (response?.status == 200) {
      yield put(editVendorSuccess(response?.data));
      //action?.callBack(response);
      // yield put(
      //   toastStatus({
      //     toastStatus: true,
      //     message:"vendor details changed successfully",
      //     status: true,
      //   })
      // );
    }
  } catch (error) {
    action?.callBack(error?.response)
    console.log("error?.response",error?.response);
    // yield put(
    //   toastStatus({
    //     toastStatus: true,
    //     message: error?.response?.data?.message||"error in changing vendor details",
    //     status: false,
    //   })
    // );
    console.log("inside sage for errors in users");
    console.log("Error.Response editVendorSaga:- ", error.response?.data);
  }
}
function* getVendorSaga(action) {
  try {
    console.log("request getVendorSaga",action);
    const response = yield call(getVendorApi,action?.payload);
    console.log("response getViiiiendorSaga", response);
    action?.callBack(response)
    if (response?.status == 200) {
      yield put(getVendorSuccess(response?.data?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    console.log("inside sage for errors in users");
    console.log("Error.Response getVendorSaga:- ", error.response?.data);
  }
}
function* vendorTransactionSaga(action) {
  try {
    const response = yield call(vendorTransactionsApi,action?.payload );
    console.log("response vendorTransactionSaga", response);
    if (response?.status == 200) {
      yield put(vendorTransactionSuccess(response?.data));
    }
  } catch (error) {
    console.log("inside sage for errors in users");
    console.log("Error.Response vendorTransactionSaga:- ", error.response?.data);
  }
}
function* vendorUploadBillSaga(action) {
  try {
    const response = yield call(vendorUploadBillApi,action?.payload);
    console.log("response vendorUploadBillSaga", response);
    action?.callBack(response);
    if (response?.status == 200) {
      console.log("sagA", response?.data);
      yield put(vendorUploadBillSuccess(response?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    action?.callBack(error?.response);
    console.log("Error.Response vendorUploadBillSaga:- ", error.response?.data);
  }
}
function* vendorGetSignedUrlSaga(action) {
  try {
    const response = yield call(vendorGetSignedUrlApi,action?.payload);
    console.log("response vendorGetSignedUrlSaga", response);
    action?.callBack(response)
    if (response?.status == 200) {
      yield put(vendorGetSignedUrlSuccess(response?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    action?.callBack(error?.response)
    console.log("Error.Response vendorGetSignedUrlSaga:- ", error.response?.data);
  }
}
function* generateStatementForVendorSaga(action) {
  try {
    const response = yield call(reportGenerateStatementForVendorApi,action?.payload);
    console.log("response generateStatementForVendorSaga", response);
    action?.callBack(response)
    if (response?.status == 200) {
      console.log("sagA", response?.data);
      yield put(generateStatementForVendorSuccess(response?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    action?.callBack(error?.response)
    console.log("inside sage for errors in users");
    console.log("Error.Response generateStatementForVendorSaga:- ", error.response?.data);
  }
}
function* transferTorVendorSaga(action) {
  try {
    const response = yield call(transferToVendorApi,action?.payload);
    console.log("response transferTorVendorSaga", response);
    action?.callBack(response)
    if (response?.status == 200) {
      console.log("sagA", response?.data);
      yield put(transferTorVendorSuccess(response?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    action?.callBack(error?.response)
    console.log("inside sage for errors in users");
    console.log("Error.Response transferTorVendorSaga:- ", error.response?.data);
  }
}
function* getVendorDetailsSaga(action) {
  try {
    const response = yield call(getVendorDetailsApi,action?.payload);
    console.log("response getVendorDetailsSaga", response?.data);
    if (response?.status == 200) {
      console.log("sagA", response?.data);
      yield put(getVendorDetailsSuccess(response?.data?.data));
      //action?.callBack(response);
    }
  } catch (error) {
    // action?.callBack(error?.response)
    console.log("inside sage for errors in users");
    console.log("Error.Response getVendorDetailsSaga:- ", error.response?.data);
  }
}


export function* watchbeneficiarySagas() {
    yield takeEvery(GET_VIRTU_ACCOUNT, getVirtuAccountSaga);
    yield takeEvery(BANK_DIRECT_TRANSFER, bankDirectTransferSaga);
    yield takeEvery(
      BANK_TRANSFER_BENEFICIARY_ADD,
      bankTransferAddBeneficiarySaga,
    );
    yield takeEvery(GET_BENEFICIARY, getBeneficiarySaga);
    yield takeEvery(BANK_TRANSFER_TO_ADDED_BENEFICIARY, bankBeneficiaryTransferSaga);
    yield takeEvery (GET_COLLECTIONS, getCollectionsSaga);
    yield takeEvery(GET_SETTLEMENTS, getSettlementsSaga);
    yield takeEvery(CREATE_VENDOR, createVendorSaga);
    yield takeEvery(GET_LOGIPE_VENDOR_DETAILS, getLogipeVendorDetailsSaga);
    yield takeEvery(EDIT_VENDOR, editVendorSaga);
    yield takeEvery(GET_VENDOR, getVendorSaga);
    yield takeEvery(VENDOR_TRANSACTION, vendorTransactionSaga);
    yield takeEvery(VENDOR_UPLOAD_BILL, vendorUploadBillSaga);
    yield takeEvery(VENDOR_GET_SIGNED_URL, vendorGetSignedUrlSaga);
    yield takeEvery(GENERATE_STATEMENT_FOR_VENDOR, generateStatementForVendorSaga);
    yield takeEvery(TRANSFER_TO_VENDOR, transferTorVendorSaga);
    yield takeEvery(GET_VENDOR_DETAILS, getVendorDetailsSaga);
}


