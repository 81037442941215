import React from "react";
import './index.css';
import SvgImage from '../../Icons/Icons';
import { Modal, ModalBody } from "reactstrap";


const ContactSalesAgentToast = (props) => {
    const { success, message, isOpen, onHide, toggleModal, handleClose, name, toggleModal_newFleetError } = props;

    console.log("mesaage-------------", message);
    return (
        <Modal
            isOpen={isOpen}
            onHide={onHide}
            className="toastDiv settingModalMargin"
            toggle={toggleModal}
            aria-labelledby="myModalLabel2"
            style={{ width: "33rem", borderRadius: "10px" }}
        >
            <div className="modal-content" style={{ width: "33rem", background: "white", borderRadius: "10px" }} >
                <div >
                    <div className="toastCloseHeader" onClick={() => {
                        handleClose();
                    }}>
                        <div className="PGCollectionCloseIcon">
                            <SvgImage style={{ width: "10%", height: "10%" }} name="CloseButtonLogo" width="20%" height="20%" />
                        </div>
                        {/* <div className="PGCollectionCloseText" >
                        Close
                        </div> */}
                    </div>

                    <div className="toastSVGImage1 padding24 padding32T padding16B">
                        <SvgImage name="contactAgent" />
                    </div>
                    <div className="heading2 semiBold" style={{ textAlign: "center" }}>
                        Our Sales Agent Will Contact You Shortly !
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ContactSalesAgentToast;