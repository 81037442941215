import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { getCustomerAction } from "../../../../services/Store/Actions/customerAction";
import EditFreight from "../EditFreight";
import { BallTriangle } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import {
  getLRList,
  updateTripInLrAction,
} from "../../../../services/Store/Actions/TripsAction";
import Toast from "../../../subComponents/toast";
function ViewLr(props) {
  const [page, setPage] = useState(0);
  const { toggle, freight, setFreight, tripId, onSubmit } = props;
  const dispatch = useDispatch();
  const [editFreight, setEditFreight] = useState(false);
  const [toastModal, setToastModal] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastStatus, setToastStatus] = useState(false);
  const [offset, setOffset] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);
  let { freightList, freightListCount } = useSelector((store) => store?.trip);
  let totalExpenseCount = freightListCount === undefined ? 0 : freightListCount;
  let pageCount =
    totalExpenseCount % 10 === 0
      ? totalExpenseCount / 10
      : Math.floor(totalExpenseCount / 10) + 1;
  const toggleModalEditFreight = () => {
    setEditFreight(!editFreight);
  };
  const handleCloseFreight = () => {
    setFreight(!freight);
  };
  let columns;
  {
    freightList.map((i, k) => {
      console.log(i, k);
      columns = [
        {
          name: "LR No",
          selector: (i) => i.lrNo,
        },
        {
          name: "Vehicle No",
          selector: (i) => i?.Fleet?.regNumber,
        },
        {
          name: "From",
          selector: (i) => `${i?.consignorName}, ${i?.fromAddress?.cityName}`,
        },
        {
          name: "To",
          selector: (i) => `${i?.consigneeName}, ${i?.toAddress?.cityName}`,
        },
        {
          name: "Amount",
          selector: (i) => i?.freightAmount,
        },
        {
          name: "Weight",
          selector: (i) => i?.weight,
        },
        {
          name: "Payor",
          selector: (i) =>
            i?.customerId == i?.transportorId
              ? i?.transportorName
              : i?.customerId == i?.consignorId
              ? i?.consignorName
              : i?.consigneeName,
        },
      ];
    });
  }
  // useEffect(()=>{
  //   dispatch(
  //     getLRList({
  //       offset: 0,
  //       limit: 10,
  //       tripId: tripId
  //     })
  //   );
  // },[])
  const handlePageClick = (event) => {
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    console.log("event heree", event.selected);
    dispatch(
      getLRList({
        offset: newOffset,
        limit: 10,
        tripId: tripId,
      })
    );
    setOffset(newOffset);
  };
  return (
    <div>
      <Modal
        isOpen={freight}
        onHide={handleCloseFreight}
        className="modal-dialog-centered model-width-2"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody className="transactionModalBody" style={{ margin: "20px" }}>
          <div className="PGCollectionBox">
            <div className=/*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction">
              <div className="closeEffect" onClick={toggle}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
            </div>
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "0px" }}
            >
              Freight Details
            </div>
            {freightListCount == undefined ||
            (freightListCount > 0 && freightList?.length == 0) ? (
              <div
                className="mt-2 boxContainer"
                style={{
                  padding: 70,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BallTriangle
                  height={70}
                  width={70}
                  radius={5}
                  color="#2e7c7b"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={freightList}
                // fixedHeader
                fixedHeaderScrollHeight="450px"
                // selectableRows
                selectableRowsHighlight={true}
                highlightOnHover
                // pagination
                onRowClicked={(row) => {
                  // onSelect(row);
                }}
                selectableRows
                selectableRowsNoSelectAll
                onSelectedRowsChange={(selected) => {
                  if (selected.selectedCount > 0) {
                    setSelectedRow(selected.selectedRows);
                  }
                  if (selected.selectedCount == 0) {
                    setSelectedRow([]);
                  }
                }}
              />
            )}

            {/* { */}
            <div className="reactPagination leftAlign">
              <ReactPaginate
                breakLabel="..."
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
            <div className="d-flex justify-content-center">
              {freightListCount == undefined ||
              (freightListCount > 0 && freightList?.length == 0) ? (
                <></>
              ) : (
                <button
                  className="btn-edit-details "
                  style={{ backgroundColor: selectedRow?.length > 0 ? "red" : "grey", width: "250px" }}
                  disabled = {selectedRow?.length > 0 ? false : true}
                  onClick={() => {
                    // onSubmit(selectedRow);
                    dispatch(
                      updateTripInLrAction(
                        {
                          selectedLr: selectedRow,
                          status: 0,
                          tripId: tripId,
                        },
                        (res) => {
                          if (res?.status == 200) {
                            setToastMessage(
                              "Lorrie Recipt Removed Successfully"
                            );
                            setToastModal(true);
                            setToastStatus(true);
                          } else {
                            setToastMessage(
                              res?.data?.message || "Error in Lorrie Recipt"
                            );
                            setToastModal(true);
                            setToastStatus(false);
                          }
                        }
                      )
                    );
                  }}
                >
                  Remove From Trip
                </button>
              )}
              {/* <button
                className="btn-edit-details "
                style={{ backgroundColor: "red", width: "250px" }}
                onClick={() => {
                  // onSubmit(selectedRow);
                  dispatch(
                    updateTripInLrAction(
                      {
                        selectedLr: selectedRow,
                        status: 0,
                        tripId: tripId,
                      },
                      (res) => {
                        if (res?.status == 200) {
                          setToastMessage("Lorrie Recipt Removed Successfully");
                          setToastModal(true);
                          setToastStatus(true);
                        } else {
                          setToastMessage(
                            res?.data?.message || "Error in Lorrie Recipt"
                          );
                          setToastModal(true);
                          setToastStatus(false);
                        }
                      }
                    )
                  );
                }}
              >
                Remove From Trip
              </button> */}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <EditFreight
        toggle={toggleModalEditFreight}
        freight={editFreight}
        page={page}
        tripId={tripId}
        // setFreight={setFreight}
      />
      {toastModal ? (
        <Toast
          success={toastStatus}
          message={toastMessage}
          toggleModal={() => {
            setToastModal(false);
            dispatch(
              getLRList({
                offset: 0,
                limit: 10,
                tripId: tripId,
              })
            );
          }}
          isOpen={toastModal}
          name="trips"
          handleClose={() => {
            handleCloseFreight();
            setToastModal(false);
            dispatch(
              getLRList({
                offset: 0,
                limit: 10,
                tripId: tripId,
              })
            );
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default ViewLr;
