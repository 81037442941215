import React from "react";
import { Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import SvgImage from "../../Components/Icons/Icons";
import "./routerbuttonV3.css";

const RouterButtonV3 = (props) => {
  //    const [buttonTextClass,setbuttonTextClass] = useState("buttonTextNonClicked");

  //console.log("location ---- ",location.pathname);

  //const navigate = useNavigate();

  // function handleClick(){
  //    console.log("I am clicked")
  //    if (buttonClass === "nonClicked"){
  //        setButtonClass("Clicked")
  //        setbuttonTextClass("buttonTextClicked")
  //    }Manage
  //        setButtonClass("nonClicked")
  //        setbuttonTextClass("buttonTextNonClicked")
  //    }

  // };

  // const name = props.name==null?"button":props.name

  const { title, Title, showArrow, setActive } = props;
  return (
    <div
      style={{
        textDecoration: "none",
        width: "100%",
        height: title == "AppLogo" ? "40px" : "100%",
      }}
      onClick={setActive}
    >
      <div
        className="buttonV2"
        // onClick={handleClick}
        style={{
          height: title == "AppLogo" ? "40px" : "60px",
          width: "200px",
        }}
      >
        <div className={props.active ? "row activeHover":"row disableHover"}>
          {/* <div className={"row"}> */}
          <div className={"col-2 firstHalf"}>
            <div className={"nonClicked4 notClicked"}>
              <SvgImage
                //   height="400"
                name={props.title}
                color={props.active ? "white" : "#2E7C7B"}
              />
            </div>
          </div>
          <div
            className={
              props.active
                ? "col-8 buttonTextClickedV3"
                : "col-8 buttonTextNonClickedV3"
            }
          >
            {Title}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
export default RouterButtonV3;
