import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./index.css";
import { Form, FormGroup, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import TripModalV2 from "./Components/CreateTripV2";
import DatePicker from "react-datepicker";
import SvgImage from "../Icons/Icons";
import { DatePickerField } from "../core/inputs/input";
import TripList from "../Trips/Components/TripList";
import {
  findTripAddress,
  getLRList,
  getTripsNew,
} from "../../services/Store/Actions/TripsAction";
import Toast from "../subComponents/toast";
import { toastStatus } from "../../services/Store/Actions/appActions";
import { getVehicleListAction } from "../../services/Store/Actions/manageVehicleAction";
import { getDriverList } from "../../services/Store/Actions/driverslistActions";
import moment from "moment";
import { getCityAndStateAction } from "../../services/Store/Actions/omcActions";
import {
  removeItemFromAsyncStorage,
  storeDataToAsyncStorage,
} from "../../services/Utils/functions";
import { BallTriangle } from "react-loader-spinner";
import { actions } from "react-redux-form";
import { Button } from "reactstrap";
import EndTripModal from "../TripsDetailsV2/Components/EndTrip";
import ChooseRoute from "../LoadReceipt/Components/ChooseRoute";
import AddLr from "./Components/AddLr";
import CreateTripFromLR from "../ManageCustomersDetailsV2/SubComponents/CreateTripFromLR";
import PaymentDetails from "../ManageCustomersDetailsV2/SubComponents/PaymentDetails";
import AddBulkTrip from "./Components/AddBulkTrips";
function TripsMainV2(props) {
  const { sideBar } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let {
    tripsList,
    loader,
    fetchingTrips,
    tripsByUserId,
    tripsCount,
    tripsListNew,
    countAll,
    countNotStarted,
    countComplete,
    countRunning,
    countDelayed,
  } = useSelector((store) => store?.trip);
  const [item, setItem] = useState({});
  console.log("dasdad", tripsListNew);
  const [color1, setColor1] = useState(-1);
  const [color2, setColor2] = useState(-1);
  const [cityId1, setcityId1] = useState("");
  const [cityId2, setcityId2] = useState("");
  const [lr, setNewLr] = useState(true);
  const [openEndTripModal, setOpenEndTripModal] = useState(false);
  const toggleEndTripModal = () => {
    setOpenEndTripModal(
      ["2", "3", 2, 3].includes(isTrips) ? !openEndTripModal : 0
    );
  };
  const cityStateList = useSelector((state) => state?.omc?.cityStateList);
  console.log("lllllllllllllll", tripsListNew, tripsCount);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  console.log("adasdas", toastObject);
  const [all, setAll] = useState(true);
  const [fleetIdTemp, setFleetIdTemp] = useState("");
  const [driverIdTemp, setDriverIdTemp] = useState("");

  const [completed, setCompleted] = useState(false);
  const [pending, setPending] = useState(false);
  const [openLrList, setOpenLrList] = useState(false);
  const [running, setRunning] = useState(false);
  const [delayed, setDelayed] = useState(false);
  const [trip, setTrip] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { offset, setOffset } = props;
  const createTripV2 = () => setModal(isTrips == 3 ? !modal : 0);
  const [startDate, setStartdate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchSource, setSearchSource] = useState("");
  const [searchDestination, setSearchDestination] = useState("");
  const [chooseRouteModal, setChooseRouteModal] = useState(false);
  const [financialYearId, setFinancialYearId] = useState("");
  const [selectedLr, setSelectedLr] = useState([]);
  const showRouteModalToggle = () => setChooseRouteModal(true);
  const chooseRouteModalToggle = () => setChooseRouteModal(!chooseRouteModal);
  const toggleOpenLrList = () =>
    setOpenLrList(["3", 3].includes(isTrips) ? !openLrList : 0);
  const closeOpenLrList = () =>
    setOpenLrList(["3", 3].includes(isTrips) ? false : 0);
  const [createTripModal, setCreateTripModal] = useState(false);
  const [bulkSalary, setBulkSalary] = useState(false);
  const createTripModalToggle = () => setCreateTripModal(!createTripModal);
  const [paymentDetailsModal, setPaymentDetailsModal] = useState(false);
  const paymentDetailsModalToggle = () =>
    setPaymentDetailsModal(!paymentDetailsModal);
  const [routeData, setRouteData] = useState({});
  const [tripData, setTripData] = useState({});
  useEffect(() => {
    dispatch(findTripAddress());
  }, []);
  const tripAddress = useSelector((state) => state?.trip?.tripAddressDetails);
  const financialYearList = [
    {
      id: 1,
      title: "2022 - 23",
      from: new Date("04-01-2022"),
      to: new Date("03-31-2023"),
    },
    {
      id: 2,
      title: "2023 - 24",
      from: new Date("04-01-2023"),
      to: new Date("03-31-2024"),
    },
    {
      id: 3,
      title: "2024 - 25",
      from: new Date("04-01-2024"),
      to: new Date("03-31-2025"),
    },
  ];
  console.log("trip address here", tripAddress);
  // let {
  //   transactionsAndExpenses,
  //   fastagVehicleTransaction,
  //   cashTransactions,
  // } = useSelector((store) => store?.trip);
  // let totalExpense =  parseInt(fastagVehicleTransaction?.expense || 0) +
  //         parseInt(
  //           transactionsAndExpenses?.[
  //             parseInt(item?.cardType) > 0 ? "sumOfDebitEntries" : "expense"
  //           ] || 0
  //         ) +
  //         parseInt(cashTransactions?.sum || 0)

  //         console.log('fdafsa', totalExpense)

  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips, setIsTrips] = useState(0);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  useEffect(() => {
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
  }, [authUser, managerControlList]);

  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [display3, setDisplay3] = useState(false);
  const findSearch1 = () => {
    setColor1(-1);
    dispatch(
      findTripAddress({
        offset: 0,
        search: searchSource,
        // minKyc: "",
        // sortAs: "",
        // sortBy: "",
      })
    );
  };
  const findSearch2 = () => {
    setColor2(-1);
    dispatch(
      findTripAddress({
        offset: 0,
        search: searchDestination,
        // minKyc: "",
        // sortAs: "",
        // sortBy: "",
      })
    );
  };

  const defaultStates = [
    {
      cityId: 1130,
      cityName: "Delhi",
      cityCode: "",
      stateId: 32,
      lat: "28.557163",
      long: "77.163665",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Delhi",
      },
    },

    {
      cityId: 295,
      cityName: "Bengaluru",
      cityCode: "",
      stateId: 11,
      lat: "12.96557",
      long: "77.60625",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Karnataka",
      },
    },

    {
      cityId: 853,
      cityName: "Chennai",
      cityCode: "",
      stateId: 23,
      lat: "13.066682",
      long: "80.224741",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Tamil Nadu",
      },
    },
    {
      cityId: 383,
      cityName: "Kochi",
      cityCode: "",
      stateId: 12,
      lat: "9.956297",
      long: "76.332486",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Kerala",
      },
    },
    {
      cityId: 1750,
      cityName: "Kolkata",
      cityCode: null,
      stateId: 28,
      lat: "22.51829",
      long: "88.362951",
      createdAt: "2022-09-20 10:11:47",
      State: {
        stateName: "West Bengal",
      },
    },
    {
      cityId: 1083,
      cityName: "Chandigarh",
      cityCode: "",
      stateId: 30,
      lat: "30.728092",
      long: "76.7784",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Chandigarh",
      },
    },
    {
      cityId: 1670,
      cityName: "Hyderabad",
      cityCode: null,
      stateId: 24,
      lat: "17.390617",
      long: "78.470769",
      createdAt: "2022-09-20 10:11:47",
      State: {
        stateName: "Telangana",
      },
    },
    {
      cityId: 566,
      cityName: "Pune",
      cityCode: "",
      stateId: 14,
      lat: "18.519479",
      long: "73.870703",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Maharashtra",
      },
    },
    {
      cityId: 155,
      cityName: "Surat",
      cityCode: "",
      stateId: 7,
      lat: "21.196102",
      long: "72.815766",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Gujarat",
      },
    },
    {
      cityId: 154,
      cityName: "Ahmedabad",
      cityCode: "",
      stateId: 7,
      lat: "23.012369",
      long: "72.561743",
      createdAt: "2022-03-20 20:51:24",
      State: {
        stateName: "Gujarat",
      },
    },
    {
      cityId: 1658,
      cityName: "Madurai",
      cityCode: null,
      stateId: 23,
      lat: "9.93447",
      long: "78.133299",
      createdAt: "2022-09-20 10:11:47",
      State: {
        stateName: "Tamil Nadu",
      },
    },
    {
      cityId: 1641,
      cityName: "Jaipur",
      cityCode: null,
      stateId: 21,
      lat: "26.894094",
      long: "75.79277",
      createdAt: "2022-09-20 10:11:47",
      State: {
        stateName: "Rajasthan",
      },
    },
    {
      cityId: 1450,
      cityName: "Hisar",
      cityCode: null,
      stateId: 8,
      lat: "29.147086",
      long: "75.733876",
      createdAt: "2022-09-20 10:11:46",
      State: {
        stateName: "Haryana",
      },
    },
  ];

  const datepickerRefEnd = useRef();
  const datepickerRefStart = useRef();
  const [open, setOpen] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);

  const [search, setSearch] = useState("");
  const [searchDriver, setSearchDriver] = useState("");

  const [tripNoTemp, setTripNoTemp] = useState("");
  const [refresh, setRefresh] = useState(false);

  let totalExpenseCount = tripsCount === undefined ? 0 : tripsCount;
  let pageCount =
    totalExpenseCount % 10 === 0
      ? totalExpenseCount / 10
      : Math.floor(totalExpenseCount / 10) + 1;
  console.log("pageeee", pageCount);

  const allFleets = useSelector(
    (state) => state?.manageVehicle?.allFleets?.rows
  );

  console.log("ffdfdfdfdfd", allFleets);
  const vehCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );

  const driverList = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.rows
  );
  const driverCount = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.count
  );
  const fastVeh =
    vehCount > 0 ? allFleets?.find((ele) => ele?.Fastag !== null) : "value";
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };

  // console.log('startDate.', endTime: moment(endDate).format("MM/DD/YYYY"))
  // useEffect(() => {
  //   dispatch(
  //     getTripsNew({
  //       offset: 0,
  //       limit: 10,
  //       fleetId : fleetIdTemp
  //     })
  //   );
  //   function handleResize() {
  //     setWindowWidth(window.innerWidth);
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [fleetIdTemp]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    console.log("event heree", event.selected);
    let payload = {};
    if (pending) {
      payload["isActive"] = "1";
    }
    if (completed) {
      payload["isActive"] = "3";
    }
    if (running) {
      payload["isActive"] = "2";
    }
    if (delayed) {
      payload["isActive"] = "9";
    }
    dispatch(
      getTripsNew({
        ...payload,
        offset: newOffset,
        limit: 10,
        isActive: "",
        fleetId: fleetIdTemp,
        driverId: driverIdTemp,
        tripNo: tripNoTemp,
        startTime: moment(startDate).format("MM/DD/YYYY"),
        endTime: moment(endDate).format("MM/DD/YYYY"),
        source: searchSource,
        destination: searchDestination,
      })
    );
    setOffset(newOffset);
  };

  useEffect(() => {
    dispatch(
      getVehicleListAction({
        offset: "",
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [search]);

  useEffect(() => {
    dispatch(
      getDriverList({
        offset: "",
        search: searchDriver,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [searchDriver]);
  console.log("searchdasda", searchDestination, searchSource);

  useEffect(() => {
    removeItemFromAsyncStorage("row");

    // console.log('bhai flee', fleetIdTemp)
    let payload = {};
    if (pending) {
      payload["isActive"] = "1";
    }
    if (completed) {
      payload["isActive"] = "3";
    }
    if (running) {
      payload["isActive"] = "2";
    }
    if (delayed) {
      payload["isActive"] = "9";
    }
    dispatch(
      getTripsNew({
        ...payload,
        offset: 0,
        limit: 10,
        fleetId: fleetIdTemp,
        driverId: driverIdTemp,
        startTime: moment(startDate).format("MM/DD/YYYY"),
        endTime: moment(endDate).format("MM/DD/YYYY"),
        source: searchSource,
        destination: searchDestination,
        tripNo: tripNoTemp,
      })
    );
    console.log("pqwerewrpienndnfnsdfdsdf", startDate, endDate);
  }, [
    fleetIdTemp,
    driverIdTemp,
    startDate,
    endDate,
    searchDestination,
    searchSource,
    tripNoTemp,
    refresh,
  ]);

  const filterList = (k) => {
    if (k === "all") {
      setAll(true);
      setCompleted(false);
      setRunning(false);
      setDelayed(false);
      setPending(false);
      dispatch(
        getTripsNew({
          offset: 0,
          limit: 10,
          fleetId: fleetIdTemp,
          driverId: driverIdTemp,
          startTime: moment(startDate).format("MM/DD/YYYY"),
          endTime: moment(endDate).format("MM/DD/YYYY"),
          source: searchSource,
          destination: searchDestination,
          tripNo: tripNoTemp,
        })
      );
    } else if (k === "pending") {
      setAll(false);
      setCompleted(false);
      setRunning(false);
      setDelayed(false);
      setPending(true);
      // Tripcompleted()
      dispatch(
        getTripsNew({
          isActive: "1",
          offset: 0,
          limit: 10,
          fleetId: fleetIdTemp,
          driverId: driverIdTemp,
          startTime: moment(startDate).format("MM/DD/YYYY"),
          endTime: moment(endDate).format("MM/DD/YYYY"),
          source: searchSource,
          destination: searchDestination,
          tripNo: tripNoTemp,
        })
      );
    } else if (k === "completed") {
      setAll(false);
      setCompleted(true);
      setRunning(false);
      setDelayed(false);
      setPending(false);
      // Tripcompleted()
      dispatch(
        getTripsNew({
          isActive: "3",
          offset: 0,
          limit: 10,
          fleetId: fleetIdTemp,
          driverId: driverIdTemp,
          startTime: moment(startDate).format("MM/DD/YYYY"),
          endTime: moment(endDate).format("MM/DD/YYYY"),
          source: searchSource,
          destination: searchDestination,
          tripNo: tripNoTemp,
        })
      );
    } else if (k === "running") {
      setAll(false);
      setCompleted(false);
      setRunning(true);
      setDelayed(false);
      setPending(false);
      dispatch(
        getTripsNew({
          isActive: "2",
          offset: 0,
          limit: 10,
          fleetId: fleetIdTemp,
          driverId: driverIdTemp,
          startTime: moment(startDate).format("MM/DD/YYYY"),
          endTime: moment(endDate).format("MM/DD/YYYY"),
          source: searchSource,
          destination: searchDestination,
          tripNo: tripNoTemp,
        })
      );
    } else if (k === "delayed") {
      setAll(false);
      setCompleted(false);
      setRunning(false);
      setDelayed(true);
      setPending(false);
      dispatch(
        getTripsNew({
          isActive: "9",
          offset: 0,
          limit: 10,
          fleetId: fleetIdTemp,
          driverId: driverIdTemp,
          startTime: moment(startDate).format("MM/DD/YYYY"),
          endTime: moment(endDate).format("MM/DD/YYYY"),
          source: searchSource,
          destination: searchDestination,
          tripNo: tripNoTemp,
        })
      );
    }
  };
  const onSelect = (row) => {
    storeDataToAsyncStorage("row", row);
    setItem(row);
    navigate(`/trips/${row.tripId}`);
    console.log("dsadasddsadasddsadasddsadasd", row);

    // setItem(row);
    console.log("thissssssssssssss", row);
  };
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Only Numbers are allowed")
      .required("Required")
      .min(1, "Amount should be greater than 1"),
  });
  let columns;
  {
    tripsListNew.map((i, k) => {
      columns = [
        {
          name: "Trip No",
          selector: (i) => i.tripNo,
        },
        {
          name: "Vehicle No",
          selector: (i) => i?.Fleet?.regNumber,
        },
        {
          name: "From",
          selector: (i) =>
            i.source.length == 36
              ? i.sourceAddress?.cityName + ", " + i.sourceAddress?.stateName
              : i.source,
        },
        {
          name: "To",
          selector: (i) =>
            i.destination.length == 36
              ? i.destAddress?.cityName + ", " + i.destAddress?.stateName
              : i.destination,
        },
        // {
        //   name: "Remarks",
        //   selector: (i) => (i?.remarks ? i?.remarks : "NA"),
        // },
        // {
        //   name: "Total Expense",
        //   selector: (i) =>   0,
        // },
        {
          name: "Status",
          cell: (i) => (
            <div className="d-flex">
              <button
                type="button"
                className={
                  i.isActive == 1 ? "btn btn-primary" : "btn btn-success"
                }
                style={{
                  width: "110px",
                  backgroundColor:
                    i.isActive == 2
                      ? new Date() > new Date(i.expectedEndTime)
                        ? "red"
                        : "#4d5073"
                      : i.isActive == 1
                      ? "#0d6efd"
                      : "green",
                }}
                onClick={() => {
                  // i.isActive == 1
                  //   ? navigate(`/endTrip/${i.tripId}`, {
                  //       state: { tripData: i },
                  //     })
                  //   : null
                  setItem(i);
                  if (i.isActive == 2) {
                    dispatch(
                      getLRList({
                        offset: 0,
                        limit: 10,
                        isActive: "",
                        tripId: i.tripId,
                      })
                    );
                    toggleEndTripModal();
                  } else {
                    onSelect(i);
                  }
                  console.log("sadfgasdsadadsad");
                }}
              >
                {i.isActive == 2
                  ? "End Trip"
                  : i.isActive == 1
                  ? "Start Trip"
                  : "Completed"}
              </button>
              {i.isActive == 2 ? (
                new Date() > new Date(i.expectedEndTime) ? (
                  <div
                    style={{
                      alignItems: "center",
                      marginLeft: "12px",
                      alignContent: "center",
                    }}
                  >
                    Delayed
                  </div>
                ) : null
              ) : null}
            </div>
          ),
        },
      ];
    });
  }
  console.log("qqqqqqqqqqq", item);
  return (
    <div
      className="container topMargin sideBarMargin"
      style={{
        // paddingTop:"5.5rem",
        marginLeft: sideBar ? "220px" : "100px",
        maxWidth: sideBar ? windowWidth - 240 : windowWidth - 130,
      }}
    >
      <div
        className="main1"
        style={{ height: "1200px", backgroundColor: "white" }}
      >
        <h1 className="headingLow semiBold" style={{ marginBottom: "25px" }}>
          Trips
        </h1>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            vrn: "",
            company: "",
            status: "",
            driver: "",
            startDate: "",
            endDate: "",
            from: "",
            to: "",
            source: "",
            destination: "",
            tripNo: "",
            financialYear: "",
            financialYearFrom: "",
            financialYearTo: "",
          }}
          onSubmit={(values, actions) => {
            console.log("sdsadsad", values);
            actions.resetForm();
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "50px",
                }}
              >
                <FormGroup>
                  <label for="vrn" className="heading3 semiBold">
                    Vehicle No:
                  </label>
                  <div
                    // onClick={() => {
                    //   setOpen(true);
                    // }}
                    className="custNamepadd3"
                  >
                    <input
                      className={`inputBoxCust1 ${
                        touched.amount && errors.amount ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="vrn"
                      name="vrn"
                      touched={touched.vrn}
                      errors={errors.vrn}
                      // onBlur={handleBlur("vrn")}
                      onChange={(event) => {
                        setOpen(true);
                        setSearch(event.target.value);
                        setFieldValue("vrn", event.target.value, true);
                        if (!event.target.value) {
                          setFleetIdTemp("");
                        }
                      }}
                      value={values.vrn}
                      // autoFocus
                      placeholder="Select Vehicle"
                      autocomplete="off"
                      onFocus={() => {
                        setOpen(true);
                      }}
                      onBlur={() => {
                        setOpen(false);
                      }}
                      // placeholder="Enter Vechicle No"
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    {open ? (
                      <div
                        className="driverSearchContainer containerShadow"
                        style={{
                          width: "100%",
                          marginTop: "5px",
                          borderRadius: "8px",
                          border: "1px solid #E2E2E2",
                        }}
                      >
                        {/* <div
                          style={{
                            position: "absolute",
                            top: "0.3rem",
                            right: "0.5rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <SvgImage name="CloseButtonLogo" />
                        </div> */}
                        {allFleets?.map((item, i) => {
                          return (
                            <>
                              {/* {item?.Fastag !== null ? ( */}
                              <div
                                key={i}
                                className="flexStyling padding8 "
                                onClick={(event) => {
                                  setOpen(false);
                                  // setUserId(event.target.value);
                                  setFieldValue("vrn", item?.regNumber, true);
                                  setFieldValue("fleetId", item?.fleetId, true);
                                  setFleetIdTemp(item?.fleetId);
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                <input
                                  type="radio"
                                  name="vrn"
                                  value={item?.fleetId}
                                  id={item?.fleetId}
                                />
                                <div
                                  className="padding16L"
                                  value={item?.fleetId}
                                >
                                  {item?.regNumber}
                                </div>
                              </div>
                              {/* ) : (
                                    <></>
                                  )} */}
                            </>
                          );
                        })}
                        {vehCount == 0 ? (
                          <div className="heading3 semiBold padding16 padding24B">
                            No Vehicle Available
                          </div>
                        ) : fastVeh == undefined ? (
                          <div className="heading3 semiBold padding16 padding24B">
                            No Vehicle Available
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label for="vrn" className="heading3 semiBold">
                    Trip Number
                  </label>
                  <div className="custNamepadd3">
                    <input
                      type="text"
                      id="vrn"
                      className={`inputBoxCust1 ${
                        touched.tripNo && errors.tripNo ? "is-invalid" : ""
                      }`}
                      name="vrn"
                      // onChange={handleChange("tripNo")}
                      value={values.tripNo}
                      onChange={(event) => {
                        setTripNoTemp(event.target.value);
                        setFieldValue("tripNo", event.target.value, true);
                      }}
                      // onBlur={() => {
                      //   dispatch(
                      //     sendAnalitics("Enter_Amt_Web", {
                      //       upi_netBank_tripNo: values.tripNo,
                      //     })
                      //   );
                      //   handleBlur("tripNo");
                      // }}
                      touched={touched.tripNo}
                      errors={errors.tripNo}
                      placeholder="Enter TripNo"
                      autocomplete="off"
                    />
                  </div>
                </FormGroup>
                {/* <FormGroup>
                  <label for="vrn" className="heading3 semiBold">
                    Status
                  </label>
                  <input
                    type="text"
                    id="vrn"
                    className={`form-control-1 ${
                      touched.amount && errors.amount ? "is-invalid" : ""
                    }`}
                    name="vrn"
                    // onChange={handleChange("amount")}
                    value={values.status}
                    // onBlur={() => {
                    //   dispatch(
                    //     sendAnalitics("Enter_Amt_Web", {
                    //       upi_netBank_amount: values.amount,
                    //     })
                    //   );
                    //   handleBlur("amount");
                    // }}
                    touched={touched.amount}
                    errors={errors.amount}
                    placeholder="Select Status"
                  />
                </FormGroup> */}
                <FormGroup>
                  <label for="driver" className="heading3 semiBold">
                    Driver
                  </label>
                  <div
                    // onClick={() => {
                    //   setOpenDriver(true);
                    // }}
                    className="custNamepadd3"
                  >
                    <input
                      className={`inputBoxCust1 ${
                        touched.amount && errors.amount ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="driver"
                      name="driver"
                      touched={touched.driver}
                      errors={errors.driver}
                      // onBlur={handleBlur("driver")}
                      onChange={(event) => {
                        setOpenDriver(true);
                        setSearchDriver(event.target.value);
                        setFieldValue("driver", event.target.value, true);
                        if (!event.target.value) {
                          setDriverIdTemp("");
                        }
                      }}
                      value={values.driver}
                      // autoFocus
                      placeholder="Select Driver"
                      autocomplete="off"
                      onFocus={() => {
                        setOpenDriver(true);
                      }}
                      onBlur={() => {
                        setOpenDriver(false);
                      }}
                    />
                  </div>

                  <div style={{ position: "relative" }}>
                    {openDriver ? (
                      <div
                        className="driverSearchContainer containerShadow"
                        style={{
                          width: "100%",
                          marginTop: "5px",
                          borderRadius: "8px",
                          border: "1px solid #E2E2E2",
                        }}
                      >
                        {/* <div
                          style={{
                            position: "absolute",
                            top: "0.3rem",
                            right: "0.3rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpenDriver(false);
                          }}
                        >
                          <SvgImage name="CloseButtonLogo" />
                        </div> */}
                        {driverList?.map((item, i) => {
                          return (
                            <>
                              {item?.Fastag !== null ? (
                                <div
                                  key={i}
                                  className="flexStyling padding8 "
                                  onClick={(event) => {
                                    setOpenDriver(false);
                                    // setUserId(event.target.value);
                                    setFieldValue(
                                      "driver",
                                      item?.User?.fullName,
                                      true
                                    );
                                    setFieldValue(
                                      "userId",
                                      event.target.value,
                                      true
                                    );

                                    setDriverIdTemp(item?.User?.userId);
                                  }}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  <input
                                    type="radio"
                                    name="driver"
                                    value={item?.User?.userId}
                                    id={item?.User?.userId}
                                  />
                                  <div className="padding16L">
                                    {item?.User?.fullName.length > 35
                                      ? item?.User?.fullName.split(0, 35) +
                                        "..."
                                      : item?.User?.fullName}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                        {driverCount == 0 ? (
                          <div className="heading3 semiBold padding16 padding24B">
                            No driver Available
                          </div>
                        ) : driverCount == undefined ? (
                          <div className="heading3 semiBold padding16 padding24B">
                            No driver Available
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.userId && touched.userId ? (
                        <div>*{errors.userId}</div>
                      ) : null}
                    </div>
                  </div>
                </FormGroup>
                <button
                  className="greenButton"
                  style={{ width: "160px" }}
                  type="button"
                  onClick={() => {
                    setTripNoTemp("");
                    setDriverIdTemp("");
                    setSearchDriver("");
                    setFleetIdTemp("");
                    setStartdate("");
                    setEndDate("");
                    setSearchDestination("");
                    setSearchSource("");
                    resetForm();
                  }}
                >
                  Clear Filter
                </button>
              </FormGroup>

              <FormGroup
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "50px",
                }}
              >
                <FormGroup>
                  <label for="startDate" className="heading3 semiBold">
                    Start Date
                  </label>
                  <div className="custNamepadd3 row" style={{ width: "200px" }}>
                    <div className="col-9">
                      <DatePickerField
                        classname="searchBar-input1"
                        name="startDate"
                        header="Select Start date"
                        value={values.startDate}
                        onChange={(v) => {
                          setFieldValue("startDate", v, true);
                          setStartdate(v);
                        }}
                        //onChange={(v) => setFieldValue("endDate", v, true)}
                        maxDate={values.endDate === "" ? "" : values.endDate}
                        // maxDate={new Date()}
                        ref1={datepickerRefEnd}
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="col-3">
                      <label
                        className="date-input"
                        for="startDate"
                        style={{
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleClickDatepickerIcon2();
                        }}
                      >
                        <SvgImage
                          name="CalenderLogo"
                          width="32"
                          height="32"
                          // classname="date-logo"
                        />
                      </label>
                    </div>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.endDate && touched.endDate ? (
                      <div>*{errors.endDate}</div>
                    ) : null}
                  </div>
                  {/* <label for="vrn" className='heading3 semiBold'>
                      Start Date
                    </label> */}
                  {/* <input
                      type="text"
                      id="vrn"
                      className={`form-control-1 ${touched.amount && errors.amount ? "is-invalid" : ""
                        }`}
                      name="vrn"
                      // onChange={handleChange("amount")}
                      value={values.amount}
                      // onBlur={() => {
                      //   dispatch(
                      //     sendAnalitics("Enter_Amt_Web", {
                      //       upi_netBank_amount: values.amount,
                      //     })
                      //   );
                      //   handleBlur("amount");
                      // }}
                      touched={touched.amount}
                      errors={errors.amount}
                      placeholder="Select Start Date"
                    /> */}
                </FormGroup>
                <FormGroup>
                  <label for="endtDate" className="heading3 semiBold">
                    End Date
                  </label>
                  <div className="custNamepadd3 row" style={{ width: "200px" }}>
                    <div className="col-9">
                      <DatePickerField
                        classname="searchBar-input1"
                        name="endtDate"
                        header="Select End date"
                        value={values.endDate}
                        onChange={(v) => {
                          setFieldValue("endDate", v, true);
                          setEndDate(v);
                        }}
                        //onChange={(v) => setFieldValue("endDate", v, true)}
                        minDate={
                          values.startDate === "" ? "" : values.startDate
                        }
                        // maxDate={new Date()}
                        ref1={datepickerRefStart}
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="col-3">
                      <label
                        className="date-input"
                        for="endDate"
                        style={{
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleClickDatepickerIcon1();
                        }}
                      >
                        <SvgImage
                          name="CalenderLogo"
                          width="32"
                          height="32"
                          // classname="date-logo"
                        />
                      </label>
                    </div>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.endDate && touched.endDate ? (
                      <div>*{errors.endDate}</div>
                    ) : null}
                  </div>
                  {/* <label for="vrn" className='heading3 semiBold'>
                      Start Date
                    </label> */}
                  {/* <input
                      type="text"
                      id="vrn"
                      className={`form-control-1 ${touched.amount && errors.amount ? "is-invalid" : ""
                        }`}
                      name="vrn"
                      // onChange={handleChange("amount")}
                      value={values.amount}
                      // onBlur={() => {
                      //   dispatch(
                      //     sendAnalitics("Enter_Amt_Web", {
                      //       upi_netBank_amount: values.amount,
                      //     })
                      //   );
                      //   handleBlur("amount");
                      // }}
                      touched={touched.amount}
                      errors={errors.amount}
                      placeholder="Select Start Date"
                    /> */}
                </FormGroup>

                <FormGroup>
                  <label for="source" className="heading3 semiBold">
                    From
                  </label>
                  <div className="custNamepadd3">
                    <input
                      type="text"
                      name="source"
                      id="source"
                      placeholder={"Source City"}
                      className={`inputBoxCust1 ${
                        touched.amount && errors.amount ? "is-invalid" : ""
                      }`}
                      aria-label="Default select example"
                      onChange={(e) => {
                        setDisplay1(true);
                        console.log("e.target.value", e.target.value);
                        // setSearchSource(e.target.value);
                        setFieldValue("source", e.target.value, true);
                      }}
                      onClick={() => {
                        setDisplay1(true);
                      }}
                      value={values.source}
                      onBlur={handleBlur("source")}
                      errors={errors.source}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          findSearch1();
                        }
                      }}
                    ></input>
                  </div>
                  {display1 ? (
                    <div
                      className="padding16 newCashback whiteBackground"
                      style={{
                        position: "absolute",
                        width: "350px",
                        height: "275px",
                        zIndex: "90",
                      }}
                    >
                      <div
                        onClick={() => {
                          setDisplay1(false);
                          setSearchSource("");
                          //dispatch(clearAllDriversByVrn());
                          setFieldValue("source", "", true);
                        }}
                        style={{
                          position: "absolute",
                          right: "0.5rem",
                          top: "0.5rem",
                          cursor: "pointer",
                        }}
                      >
                        <SvgImage name="CloseButtonLogo" />
                      </div>
                      <div
                        style={{
                          overflowY: "scroll",
                          overflowX: "hidden",
                          height: "240px",
                        }}
                      >
                        {searchSource != "" ? (
                          tripAddress?.rows?.map((data, i) => {
                            let city = data?.cityName;
                            let state = data?.State?.stateName;
                            return (
                              <div
                                key={i}
                                className="padding8 row"
                                onClick={() => {
                                  setcityId1(data?.cityId);
                                  setColor1(i);
                                  setDisplay1(false);
                                  console.log("asdadsa", data?.cityName);
                                  //setUserId(data?.User?.userId);
                                  setSearchSource(data?.addressId);
                                  setFieldValue(
                                    "source",
                                    data?.cityName +
                                      "-" +
                                      data?.State?.stateName,
                                    true
                                  );
                                }}
                              >
                                {color1 === i ? (
                                  <div className="col-1">
                                    <SvgImage name="SelectedRadio" />
                                  </div>
                                ) : (
                                  <div className="col-1">
                                    <SvgImage name="UnselectedRadio" />
                                  </div>
                                )}
                                {/* <div className="col-1">
                          <SvgImage name="UnselectedRadio" />
                        </div> */}
                                <div
                                  className="col-11"
                                  style={{ textAlign: "left" }}
                                >
                                  {city} - {state}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>
                            {tripAddress?.rows?.map((data, i) => {
                              let city = data?.cityName;
                              let state = data?.stateName;

                              console.log("check status", city, state);
                              // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                              return (
                                <div
                                  key={i}
                                  className="padding8 row"
                                  onClick={() => {
                                    setcityId1(data?.cityId);
                                    setColor1(i);
                                    setDisplay1(false);
                                    //setUserId(data?.User?.userId);
                                    setSearchSource(data?.addressId);
                                    setFieldValue(
                                      "source",
                                      data?.cityName + "-" + data?.stateName,
                                      true
                                    );
                                  }}
                                >
                                  {color1 === i ? (
                                    <div className="col-1">
                                      <SvgImage name="SelectedRadio" />
                                    </div>
                                  ) : (
                                    <div className="col-1">
                                      <SvgImage name="UnselectedRadio" />
                                    </div>
                                  )}
                                  <div
                                    className="col-11"
                                    style={{ textAlign: "left" }}
                                  >
                                    {city} - {state}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </FormGroup>
                <FormGroup>
                  <label for="destination" className="heading3 semiBold">
                    To
                  </label>
                  <div className="custNamepadd3">
                    <input
                      type="text"
                      name="destination"
                      id="destination"
                      placeholder={"Destination City"}
                      className={`inputBoxCust1 ${
                        touched.amount && errors.amount ? "is-invalid" : ""
                      }`}
                      aria-label="Default select example"
                      onChange={(e) => {
                        setDisplay2(true);
                        console.log("e.target.value", e.target.value);
                        // setSearchDestination(e.target.value);
                        setFieldValue("destination", e.target.value, true);
                      }}
                      onClick={() => {
                        setDisplay2(true);
                      }}
                      value={values.destination}
                      onBlur={handleBlur("destination")}
                      errors={errors.destination}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          findSearch2();
                        }
                      }}
                    ></input>
                  </div>
                  {display2 ? (
                    <div
                      className="padding16 newCashback whiteBackground"
                      style={{
                        position: "absolute",
                        width: "350px",
                        height: "275px",
                        zIndex: "90",
                      }}
                    >
                      <div
                        onClick={() => {
                          setDisplay2(false);
                          setSearchDestination("");
                          //dispatch(clearAllDriversByVrn());
                          setFieldValue("destination", "", true);
                        }}
                        style={{
                          position: "absolute",
                          right: "0.5rem",
                          top: "0.5rem",
                          cursor: "pointer",
                        }}
                      >
                        <SvgImage name="CloseButtonLogo" />
                      </div>
                      <div
                        style={{
                          overflowY: "scroll",
                          overflowX: "hidden",
                          height: "240px",
                        }}
                      >
                        {searchDestination != "" ? (
                          tripAddress?.rows?.map((data, i) => {
                            let city = data?.cityName;
                            let state = data?.stateName;
                            return (
                              <div
                                key={i}
                                className="padding8 row"
                                onClick={() => {
                                  setcityId2(data?.cityId);
                                  setColor2(i);
                                  setDisplay2(false);
                                  //setUserId(data?.User?.userId);
                                  setSearchDestination(data?.addressId);
                                  setFieldValue(
                                    "destination",
                                    data?.cityName + "-" + data?.stateName,
                                    true
                                  );
                                }}
                              >
                                {color2 === i ? (
                                  <div className="col-1">
                                    <SvgImage name="SelectedRadio" />
                                  </div>
                                ) : (
                                  <div className="col-1">
                                    <SvgImage name="UnselectedRadio" />
                                  </div>
                                )}
                                {/* <div className="col-1">
                          <SvgImage name="UnselectedRadio" />
                        </div> */}
                                <div
                                  className="col-11"
                                  style={{ textAlign: "left" }}
                                >
                                  {city} - {state}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>
                            {tripAddress?.rows?.map((data, i) => {
                              let city = data?.cityName;
                              let state = data?.stateName;

                              console.log("check status", city, state);
                              // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                              return (
                                <div
                                  key={i}
                                  className="padding8 row"
                                  onClick={() => {
                                    setcityId2(data?.cityId);
                                    setColor2(i);
                                    setDisplay2(false);
                                    //setUserId(data?.User?.userId);
                                    setSearchDestination(data?.addressId);
                                    setFieldValue(
                                      "destination",
                                      data?.cityName + "-" + data?.stateName,
                                      true
                                    );
                                  }}
                                >
                                  {color2 === i ? (
                                    <div className="col-1">
                                      <SvgImage name="SelectedRadio" />
                                    </div>
                                  ) : (
                                    <div className="col-1">
                                      <SvgImage name="UnselectedRadio" />
                                    </div>
                                  )}
                                  <div
                                    className="col-11"
                                    style={{ textAlign: "left" }}
                                  >
                                    {city} - {state}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </FormGroup>
                <FormGroup>
                  <label for="financialYear" className="heading3 semiBold">
                    Select Financial Year
                  </label>
                  <div className="custNamepadd3">
                    <input
                      type="text"
                      name="financialYear"
                      id="financialYear"
                      placeholder={"Select Financial Year"}
                      className={`inputBoxCust1 ${
                        touched.financialYear && errors.financialYear
                          ? "is-invalid"
                          : ""
                      }`}
                      aria-label="Default select example"
                      onChange={(e) => {
                        setDisplay3(true);
                        if (!e.target.value) {
                          setFieldValue("financialYear", "", true);
                          setFieldValue("financialYearFrom", "", true);
                          setFieldValue("financialYearTo", "", true);
                        } else {
                          setFieldValue("financialYear", e.target.value, true);
                        }
                      }}
                      onClick={() => {
                        setDisplay3(true);
                      }}
                      value={values.financialYear}
                      onBlur={handleBlur("financialYear")}
                      errors={errors.financialYear}
                    ></input>
                  </div>
                  {display3 ? (
                    <div
                      className="padding16 newCashback whiteBackground"
                      style={{
                        position: "absolute",
                        width: "200px",
                        height: "275px",
                        zIndex: "90",
                      }}
                    >
                      <div
                        onClick={() => {
                          setDisplay3(false);
                          //dispatch(clearAllDriversByVrn());
                          setFieldValue("financialYearFrom", "", true);
                          setFieldValue("financialYearTo", "", true);
                          setFieldValue("financialYear", "", true);
                        }}
                        style={{
                          position: "absolute",
                          right: "0.5rem",
                          top: "0.5rem",
                          cursor: "pointer",
                        }}
                      >
                        <SvgImage name="CloseButtonLogo" />
                      </div>
                      <div
                        style={{
                          overflowY: "scroll",
                          overflowX: "hidden",
                          height: "240px",
                        }}
                      >
                        {financialYearList?.map((data, i) => {
                          let title = data?.title;
                          return (
                            <div
                              key={i}
                              className="padding8 row"
                              onClick={() => {
                                setFieldValue(
                                  "financialYear",
                                  data?.title,
                                  true
                                );
                                setFieldValue(
                                  "financialYearFrom",
                                  data?.from,
                                  true
                                );
                                setFieldValue(
                                  "financialYearTo",
                                  data?.to,
                                  true
                                );
                                setFinancialYearId(data?.id);
                                setStartdate(data?.from);
                                setEndDate(data?.to);
                                setDisplay3(false);
                              }}
                            >
                              {color2 === i ? (
                                <div className="col-1">
                                  <SvgImage name="SelectedRadio" />
                                </div>
                              ) : (
                                <div className="col-1">
                                  <SvgImage name="UnselectedRadio" />
                                </div>
                              )}
                              <div
                                className="col-9"
                                style={{ textAlign: "left" }}
                              >
                                {title}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </FormGroup>
              </FormGroup>
            </Form>
          )}
        </Formik>
        <div className="listAction">
          <div className="txnCategory">
            <button
              onClick={() => filterList("all")}
              className={
                all
                  ? "greenButtonNew heading4 Medium"
                  : "greenButtonNewNotSelected heading4 Medium"
              }
            >
              <div className="d-flex justify-content-between">
                <div style={{ paddingTop: "2px", marginRight: "8px" }}>All</div>
                <div className={` ${all ? "countAll" : "countAllInactive"}`}>
                  {countAll}
                </div>
              </div>
            </button>
            <button
              onClick={() => filterList("pending")}
              className={
                pending
                  ? "greenButtonNew heading4 Medium"
                  : " greenButtonNewNotSelected heading4 Medium"
              }
            >
              <div className="d-flex justify-content-between">
                <div style={{ paddingTop: "2px", marginRight: "8px" }}>
                  Not Started
                </div>
                <div
                  className={` ${pending ? "countAll" : "countAllInactive"}`}
                >
                  {countNotStarted}
                </div>
              </div>
            </button>
            <button
              onClick={() => filterList("completed")}
              className={
                completed
                  ? "greenButtonNew heading4 Medium"
                  : "greenButtonNewNotSelected heading4 Medium"
              }
            >
              <div className="d-flex justify-content-between">
                <div style={{ paddingTop: "2px", marginRight: "8px" }}>
                  Completed
                </div>
                <div
                  className={` ${completed ? "countAll" : "countAllInactive"}`}
                >
                  {countComplete}
                </div>
              </div>
            </button>
            <button
              onClick={() => filterList("running")}
              className={
                running
                  ? "greenButtonNew heading4 Medium"
                  : "greenButtonNewNotSelected heading4 Medium"
              }
            >
              <div className="d-flex justify-content-between">
                <div style={{ paddingTop: "2px", marginRight: "8px" }}>
                  Running
                </div>
                <div className={`${running ? "countAll" : "countAllInactive"}`}>
                  {countRunning}
                </div>
              </div>
            </button>
            <button
              onClick={() => filterList("delayed")}
              className={
                delayed
                  ? "greenButtonNew heading4 Medium"
                  : "greenButtonNewNotSelected heading4 Medium"
              }
            >
              <div className="d-flex justify-content-between">
                <div style={{ paddingTop: "2px", marginRight: "8px" }}>
                  Delayed
                </div>
                <div className={`${delayed ? "countAll" : "countAllInactive"}`}>
                  {countDelayed}
                </div>
              </div>
            </button>
          </div>
          <div className="rightAction">
            {/* <div class="dropdown" style={{ marginRight: "20px" }}>
              <button
                type="button"
                class="btnCatSelected btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                style={{ width: "90px" }}
                onClick={alert('Coming soon')}
                // disabled={true}
              >
                {" "}
                Action
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    response
                  </a>
                </li>
              </ul>
            </div> */}
            <div>
              <button
                type="button"
                className="greenButtonNew btn-secondary"
                // style={{ width: "160px" }}
                onClick={() => {
                  console.log("bulkSalarybulkSalary11", bulkSalary);
                  setBulkSalary(!bulkSalary);
                }}
              >
                + Add Bulk Trips
              </button>
              <button
                type="button"
                className="greenButtonNew btn-secondary"
                // style={{ width: "160px" }}
                onClick={toggleOpenLrList}
              >
                + Create New Trip
              </button>
            </div>
          </div>
        </div>
        {/* <Table style={{marginTop:'12px'}}>
                    <thead>
                      <tr className="table-header-customer-details">
                        <th>Trip No</th>
                        <th>Vehicle No</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Customer</th>
                        <th>Total Expense</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                        {tripsList && tripsList.map((entry, k)=>{
                            return(
                            <tr onClick={(ent)=>{onSelect(entry)}}>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">{entry.tripId}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">{entry.Fleet.regNumber}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">{entry.source}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">{entry.destination || "N/A"}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">{entry.customerName}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <p className="FontNB">₹{entry.cashExpanse}</p>
                                  </div>
                              </td>
                              <td>
                                  <div className="booking_title">
                                      <button type="button"  className="btnCatSelected" style={{background:"#09BD7B", width:'100px'}}>{entry.status || 'completed'}</button>
                                  </div>
                              </td>
                              </tr>)
                            
                          })                       
                      }
                      
                    </tbody>
                    </Table> */}
        {console.log("tripsCossssunt", tripsCount, tripsListNew)}
        {tripsCount == undefined ||
        (tripsCount > 0 && tripsListNew?.length == 0) ? (
          <div
            className="mt-2 boxContainer"
            style={{
              padding: 70,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BallTriangle
              height={70}
              width={70}
              radius={5}
              color="#ED752E"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={tripsListNew}
            customStyles={{
              headRow: {
                style: {
                  backgroundColor: "#ededf1",
                  fontSize: "16px",
                  fontWeight: "bold",
                },
              },
              cells: {
                style: { fontSize: "14px", minHeight: "50px" },
              },
            }}
            // fixedHeader
            // fixedHeaderScrollHeight='450px'
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            // pagination
            onRowClicked={(row) => {
              onSelect(row);
            }}
          />
        )}

        {/* { */}
        <div className="reactPagination leftAlign">
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
        {/* } */}
        {modal ? (
          <TripModalV2
            toggle={createTripV2}
            modal={modal}
            setModal={setModal}
            location={"/trips/"}
            setNewLr={setNewLr}
            toggleOpenLrList={closeOpenLrList}
            chooseRouteModalToggle={showRouteModalToggle}
            lr={"1"}
          />
        ) : null}

        {/* <TripModalV2
          toggle={createTripV2}
          modal={modal}
          setModal={setModal}
          location={"/trips/"}
          setNewLr={setNewLr}
          toggleOpenLrList={closeOpenLrList}
          chooseRouteModalToggle={showRouteModalToggle}
          lr={"1"}
        /> */}
        {openEndTripModal ? (
          <EndTripModal
            toggle={toggleEndTripModal}
            modal={openEndTripModal}
            setModal={setOpenEndTripModal}
            location={"/trips/"}
            tripId={item?.tripId}
            tripData={item}
            refreshList={() => {
              setRefresh(!refresh);
            }}
          />
        ) : null}
        {chooseRouteModal ? (
          <ChooseRoute
            toggle={chooseRouteModalToggle}
            createTrip={createTripModalToggle}
            modal={chooseRouteModal}
            setPaymentDetailsModal={setPaymentDetailsModal}
            setModal={setChooseRouteModal}
            setRouteData={setRouteData}
            // location={"/lr/"}
          />
        ) : null}

        {openLrList ? (
          <AddLr
            toggle={toggleOpenLrList}
            freight={openLrList}
            location={"/trips/"}
            onSubmit={(lrList) => {
              console.log("kkkjjjjjjjjj", lrList);
              chooseRouteModalToggle();
              toggleOpenLrList();
              setSelectedLr(lrList);
            }}
            onSubmit2={(lrList) => {
              console.log("kkkjjjjjjjjj", lrList);
              // chooseRouteModalToggle();
              // toggleOpenLrList();
              setSelectedLr(lrList);
            }}
            onSkip={() => {
              setChooseRouteModal(true);
              toggleOpenLrList();
            }}
            trip={"1"}
            // setFreight={setFreight}
          />
        ) : null}
        {createTripModal ? (
          <CreateTripFromLR
            toggle={createTripModalToggle}
            modal={createTripModal}
            setModal={setCreateTripModal}
            location={"/trip/"}
            selectedRow={selectedLr}
            setSelectedRow={setSelectedLr}
            setPaymentDetailsModal={setPaymentDetailsModal}
            routeData={routeData}
            setTripData={setTripData}
          />
        ) : null}

        {paymentDetailsModal ? (
          <PaymentDetails
            toggle={paymentDetailsModalToggle}
            tripData={tripData}
            modal={paymentDetailsModal}
            setModal={setPaymentDetailsModal}
            routeData={routeData}
            setRouteData={setRouteData}
            setItemTemp={setTripData}
            // location={"/lr/"}
          />
        ) : null}
      </div>

      {bulkSalary ? (
        <AddBulkTrip
          toggleModal={() => setBulkSalary(!bulkSalary)}
          isOpen={bulkSalary}
          handleClose={() => setBulkSalary(false)}
          // data={data}
        />
      ) : null}

      {/* {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
          }}
          isOpen={toastObject?.toastStatus}
          name="trips"
          handleClose={() => {
     
            window.location='/trips/';
            //alert("close button clicked");
          }}
        />
      ) : (
        <></>
      )} */}
    </div>
  );
}

export default TripsMainV2;
