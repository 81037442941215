import React, { useState, useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import AddNewCustomerButton from "../../../../Subcomponent/AddCustomerButton/index";
import SearchBar from "../../../subComponents/searchbar/index";
import AddNewVehicleModal from "../../../Modals/addNewVehicle/index";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { getManagerList } from "../../../../services/Store/Actions/UserManagementAction";
import ManagerDetailsCard from "../ManagerDetailsCard/index";

function FullManagerList(props) {
  const [open, setOpen] = useState(false);
  const { managerListing, managerList } = useSelector(
    (state) => state?.userManagement
  );
  function toggleModal() {
    setOpen(!open);
  }
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let searchParam = params.search;
  const [search, setSearch] = useState(
    searchParam == undefined ? "" : searchParam
  );
  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);
  const [offset, setOffset] = useState(offsetVal);
  const dispatch = useDispatch();
  const findSearch = () => {
    dispatch(
      getManagerList({
        offset: offsetVal,
        search: search,
        sortAs: "",
        sortBy: "",
      })
    );
  };
  useEffect(() => {
    dispatch(
      getManagerList({
        offset: offsetVal,
        search: search,
        sortAs: "",
        sortBy: "",
      })
    );
  }, [open, search]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleShow = () => {
    dispatch(sendAnalitics("AddManager_MV_FW_Web"));
    setOpen(true);
  };

  useEffect(() => {
    return function cleanUp() {
      setOffset(0);
    };
  }, []);
  const [color, setColor] = useState(-1);
  const counting = managerList;
  return (
    <div className="FullCustomerListBox">
      <div className="FullCustomerListTopBox">
        <div className="FullCustomerListHeader FontBold">Full Manager List</div>
        <div className="displayFlex"></div>
        {/* <div className="FullCustomerListAddButton" onClick={handleShow}>
          <AddNewCustomerButton name="Add New Manager" />
        </div> */}
      </div>
      {counting !== 0 ? (
        <>
          <div className="FUllListSearchBar">
            <SearchBar
              placeholder="Search for Manager"
              height="46"
              value={search}
              setSearch={setSearch}
              onChange={(event) => {
                //dispatch(clearAllDrivers());
                dispatch(
                  sendAnalitics("SearchBar_MV_FW_Web", {
                    searchVeh: event.target.value,
                  })
                );
                setSearch(event.target.value);
              }}
              findSearch={findSearch}
            />
          </div>
          <div className="customer-list">
            <ManagerDetailsCard
              search={search}
              offset={offset}
              setOffset={setOffset}
              nextTrigger={props.nextTrigger}
              allowTrigger={props.allowTrigger}
              color={color}
              setColor={setColor}
            />
          </div>

          <AddNewVehicleModal
            open={open}
            onHide={handleClose}
            toggleModal={toggleModal}
            setOffset={setOffset}
            setColor={setColor}
            search={search}
          />
        </>
      ) : (
        <div className="padding32 " style={{ paddingBottom: "30rem" }}>
          <div className="autoRechargeBtn padding16 heading3 Medium">
            No vehicle present. Please add them.
          </div>
        </div>
      )}
    </div>
  );
}
export default FullManagerList;
