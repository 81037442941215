import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddressDetailsAction, getOrderListAction } from "../../../../services/Store/Actions/ecomAction";
import SvgImage from "../../../Icons/Icons";
import OrderDetails from "../OrderDetails";
import "./index.css";

function MyOrders(props){

    const orderList=useSelector((state)=>state?.ecom?.orderList)
    const {setCrtBtn, setScreenBtn, openEmi, toggleModalEmi, showEmi, onHideEmi,handleCloseEmi}= props;
    const dispatch= useDispatch();
    const [selectedNum, setSelectedNum] = useState(0);
    const [addressId, setAddressId]= useState("");
    const [curOrder, setCurOrder] = useState();
    const [offset, setOffset] = useState(0);
    const count = useSelector((state) => state?.ecom?.orderList?.count);
    console.log("FullDriverDashboardBox",orderList )
    
    // open order details modal
    const [open, setOpen]= useState(false);

    const closeModal=()=>{
    setOpen(false);
    }

    const showModal=()=>{
    setOpen(true);
    }

    const toggleModal=()=>{
    setOpen(!open);
    }


    // functions

    useEffect(()=>{
        dispatch(getOrderListAction({
            limit: 5
            }, (res) => {
            //console.log("res12", res);
            if (res?.status == 200) {
                //console.log("address123",res?.data?.data?.data[0]?.addressId);
                setCurOrder(res?.data?.data?.data[0]);
                setAddressId(res?.data?.data?.data[0]?.addressId);
                dispatch(getAddressDetailsAction(res?.data?.data?.data[0]?.addressId, (res) => {
                console.log("res", res)
                }))
            }
            }))
    },[])

    const handleNext = () => {
        if ((count - offset) > 5) {
          setOffset(offset + 5);
          dispatch(getOrderListAction({
            limit: 5,
            offset: offset + 5
          }, (res) => {
            console.log("res", res);
            if (res?.status == 200) {
              setSelectedNum(0);
              setCurOrder(res?.data?.data?.data[0]);
              setAddressId(res?.data?.data?.data[0]?.addressId)
              dispatch(getAddressDetailsAction(res?.data?.data?.data[0]?.addressId, (res) => {
                console.log("res", res)
              }))
            }
          }))
        }
      }



      const handlePrev = () => {
        if (offset > 0) {
          setOffset(offset - 5);
          dispatch(getOrderListAction({
            limit: 5,
            offset: offset - 5
          }, (res) => {
            console.log("res", res);
            if (res?.status == 200) {
              setSelectedNum(0);
              setCurOrder(res?.data?.data?.data[0]);
              setAddressId(res?.data?.data?.data[0]?.addressId)
              dispatch(getAddressDetailsAction(res?.data?.data?.data[0]?.addressId, (res) => {
                console.log("res", res)
              }))
            }
          }))
        }
      }
      
      
    return(
    <>
    {
        (orderList?.data?.[0])?
        <>
        <div className="padding16 Bold heading1 padding0T ">My Orders</div>
        <div className="">
        {
            orderList?.data?.map((item, i) => {
                return (
                    <div className="padding16" key={i} style={{width:"50%"}}>
                    <div className={i == selectedNum ? "row cartItem borderCls borderRad7" : "row cartItem borderRad7"} 
                    onClick={() => {
                        setSelectedNum(i);
                        setCurOrder(item);
                        setAddressId(item?.addressId);
                        dispatch(getAddressDetailsAction(item?.addressId, (res) => {
                            console.log("res", res);
                            showModal();
                        }))
                        }}
                        style={{cursor:"pointer"}}>
                        <div className="col-5" style={{ backgroundColor: "#96A9CA", padding: "1.5rem", height: "13rem", borderRadius:"0.7rem" }}>
                        <img src={item?.products[0]?.image} className="imgTyre"
                            style={{ height: "100%", alignItems: "centre" }} />
                        </div>
                        <div className="col-6 padding16 paddingInOrders" style={{ position: "relative" }}>
                        {/* <div className="closeBtn"onClick={removeFromCart}><img src="/images/ecom/closeCross.svg" className="" /></div> */}
                        <div className=" Medium heading3 padding16R">{item?.products[0]?.details}</div>
                        <div className=" padding16T">
                            <div className="qtyBox">Qty: {item?.products[0]?.quantity}</div>
                        </div>
                        <div className="row">
                            {
                            (item?.status == 0) ?
                                <div className="flexStyling padding16T padding0LR">
                                    <SvgImage name="inprogress" />
                                    <div className="padding8L modelName">Order in progress</div>
                                </div>
                                : (item?.status == 1) ?
                                <div className="flexStyling padding16T padding0LR">
                                    <SvgImage name="completedOrder" />
                                    <div className="padding8L modelName">Payment done</div>
                                </div>
                                : (item?.status == 2) ?
                                    <div className="flexStyling padding16T padding0LR">
                                         <SvgImage name="cancelOrder" />
                                        <div className="padding8L modelName">Order cancelled</div>
                                    </div>
                                    : (item?.status == 5) ?
                                    <div className="flexStyling padding16T padding0LR">
                                        <SvgImage name="completedOrder" />
                                        <div className="padding8L modelName">Order delivered</div>
                                    </div>
                                    : <div className="flexStyling padding16T padding0LR">
                                        <SvgImage name="cancelOrder" />
                                        <div className="padding8L modelName">Order not placed</div>
                                    </div>
                            }
                        </div>
                        </div>
                        <div className="col-1 padding0 padding32T btnCursor" onClick={() => {
                        setSelectedNum(i);
                        setCurOrder(item);
                        setAddressId(item?.addressId);
                        dispatch(getAddressDetailsAction(item?.addressId, (res) => {
                            console.log("res", res);
                            showModal();
                        }))
                        }}>
                            <SvgImage name="NextArrow" />
                        </div>
                    </div>
                    </div>)
                })
            }
            
        </div>
        <div className="row padding16 padding24T">
            <div className="flexStyling col-lg-2 col-md-3 col-sm-4 col-xs-4 col-4 greenText addAddress padding0LR BtnCursor" onClick={handlePrev}
                style={{ color: (offset > 0 ? "#485B7C" : "#6C6C6C") }}>
                <div className="">
                    <SvgImage name="PrevArrow" />
                </div>
                <div className="padding8L" style={{paddingTop:"2px"}}>
                    Prev
                </div>
            </div>
            <div className=" col-lg-3 col-md-6 col-sm-4 col-xs-4 col-4">
                Showing {offset + 1} - {(offset + 5)>count?count:offset + 5} of {count}
            </div>
            <div className="flexStyling col-lg-2 col-md-3 col-sm-4 col-xs-4 col-4 greenText addAddress padding0LR BtnCursor" onClick={handleNext}
                style={{ color: ((count - offset) > 5 ? "#485B7C" : "#6C6C6C")}}>
                <div className="padding8R"  style={{paddingTop:"2px"}}>
                    Next
                </div>
                <div>
                    <SvgImage name="NextArrow" />
                </div>
            </div>
        </div>
        <OrderDetails 
            open={open}
            onHide={closeModal}
            toggleModal={toggleModal}
            handleClose={closeModal}
            curOrder={curOrder}
            addressId={addressId}
            openEmi={openEmi}
            onHideEmi={onHideEmi}
            toggleModalEmi={toggleModalEmi}
            handleCloseEmi={handleCloseEmi}
            showEmi={showEmi}
        />
        </>
        :
        <div style={{textAlign:"center", paddingTop:"5rem", paddingBottom:"8rem"}}>
            <div>
                <SvgImage name="noOrderLogo" />
            </div>
            <div className="Medium heading2 padding8T">Look like you haven’t order anything! Add</div>
            <div className="Medium heading2">something from the LogiMart</div>
            <div className="padding32T">
                <button className="padding8 Medium text1 greenOutlineButton1" onClick={()=>{
                    setCrtBtn("Tyres");
                    setScreenBtn("");
                }}>
                    Start Shopping
                </button>
            </div>
        </div>
    }    
    </>
    )

}

export default MyOrders;