import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { cleanExpenseWalletSummary, getExpenseWalletSummary } from "../../../../../../services/Store/Actions/driverslistActions";
import "./index.css";
import moment from "moment";
import { Button, Table } from "reactstrap";
import NoTransaction from "./NoTransaction";
import { cleanTransactionList, getM2pCardTransactions, getTransactionsAndExpense, getTripTransaction } from "../../../../../../services/Store/Actions/TripsAction";
import SvgImage from "../../../../../Icons/Icons";
import AddingFilter from "../../../../../Modals/addingFilter";


const TripCardTable = (props) => {
  const [offset, setOffset] = useState(0);
  const {cardChosen, setCardChosen}= props;
  const [active1, setActive1] = useState(false);
  const [filter, setFilter] = useState(0);
  const [start, setStart]= useState("");
  const [end, setEnd]= useState("");
  const cardExpense = useSelector((state) => state?.driverlist);
  const omcDriverData= useSelector((state)=>state?.omc?.omcDriverData);
  const cardType=omcDriverData?.diver?.User?.WalletAccount?.m2p?"2":omcDriverData?.diver?.User?.WalletAccount?.neoKred?.accountStatus=="4"?"1"
  :omcDriverData?.diver?.User?.WalletAccount?.neoKred?.accountStatus=="7"?"1":"0";
  console.log("omcDriverData 123", omcDriverData?.diver?.User?.WalletAccount?.m2p);
  const [fleetId,setFleetId]  = useState(omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.fleetId)
  let { id } = useParams();
  const { cardTransactionsList, cashTransactionsList, tripTransactionsList, transactionsAndExpenses,  } = useSelector(store => store?.trip);
  const dispatch= useDispatch();


  const closing1 = () => {
    setActive1(false);
  };

  const toggling1 = () => {
    setActive1(!active1);
  };

  const showing1 = () => {
    setActive1(true);
  };


  console.log("transactionsAndExpenses",transactionsAndExpenses);
  useEffect(() => {
    if (fleetId !== "" && fleetId !== undefined && fleetId !== null) {
      setOffset(0);
      dispatch(cleanTransactionList())
      if(cardType=="2" ){
        dispatch(getM2pCardTransactions({
          fleetId:fleetId,
          userId:id,
          startTime: "",
          endTime:  "",
          transType: "debit",
          limit: 10,
          offset: offset || 0,
          cardUserId:id,
      }))
    }
      else if (cardType=="1" )
      {

      dispatch(getTransactionsAndExpense({
        fleetId:fleetId,
        userId:id,
        startTime: "",
        endTime:  "",
        transType: "debit",
        limit: 10,
        offset: offset || 0,
        cardUserId:id,
    }));
    } 
    
  }else {
      setOffset(0);
      dispatch(cleanTransactionList())
    }
  }, [fleetId]);


  console.log("rrrrrrrrrrrrrrrr 1111", transactionsAndExpenses?.count,  transactionsAndExpenses);

  const trxnCount=transactionsAndExpenses?.count;

  const handlePrev=()=>{
    if(offset>0){
      setOffset(offset-10);
      if (filter===0){
        // dispatch(cleanFleetTransactionHistory());
        if(cardType=="2" ){
          dispatch(getM2pCardTransactions({
            fleetId:fleetId,
            userId:id,
            startTime: "",
            endTime:  "",
            transType: "debit",
            limit: 10,
            offset: offset-10,
            cardUserId:id,
        }))
      }
        else if (cardType=="1" )
        {
  
        dispatch(getTransactionsAndExpense({
          fleetId:fleetId,
          userId:id,
          startTime: "",
          endTime:  "",
          transType: "debit",
          limit: 10,
          offset: offset-10,
          cardUserId:id,
      }));
      } 
      }
      else {
        console.log ("fastag-> filter",start,end);
        // dispatch(cleanFleetTransactionHistory());
        setOffset(offset-10);
        if(cardType=="2" ){
          dispatch(getM2pCardTransactions({
            fleetId:fleetId,
            userId:id,
            startTime: moment(start).format("YYYY-MM-DD"),
            endTime:   moment(end).format("YYYY-MM-DD"),
            transType: "debit",
            limit: 10,
            offset: offset-10,
            cardUserId:id,
        }))
      }
        else if (cardType=="1" )
        {
  
        dispatch(getTransactionsAndExpense({
          fleetId:fleetId,
          userId:id,
          startTime: moment(start).format("YYYY-MM-DD"),
          endTime:   moment(end).format("YYYY-MM-DD"),
          transType: "debit",
          limit: 10,
          offset: offset-10,
          cardUserId:id,
      }));
      }         
      }
    }
  }


  const handleNext=()=>{
    if(trxnCount-offset>10){
      if (filter===0){
        setOffset(offset+10);
        if(cardType=="2" ){
          dispatch(getM2pCardTransactions({
            fleetId:fleetId,
            userId:id,
            startTime: "",
            endTime:  "",
            transType: "debit",
            limit: 10,
            offset: offset+10,
            cardUserId:id,
        }))
      }
        else if (cardType=="1" )
        {
  
        dispatch(getTransactionsAndExpense({
          fleetId:fleetId,
          userId:id,
          startTime: "",
          endTime:  "",
          transType: "debit",
          limit: 10,
          offset: offset+10,
          cardUserId:id,
      }));
      } 

      }
      else {
        setOffset(offset+10);
        console.log ("fastag-> filter",start,end);
        if(cardType=="2" ){
          dispatch(getM2pCardTransactions({
            fleetId:fleetId,
            userId:id,
            startTime: moment(start).format("YYYY-MM-DD"),
            endTime: moment(end).format("YYYY-MM-DD"),
            transType: "debit",
            limit: 10,
            offset: offset+10,
            cardUserId:id,
        }))
      }
        else if (cardType=="1" )
        {
  
        dispatch(getTransactionsAndExpense({
          fleetId:fleetId,
          userId:id,
          startTime: moment(start).format("YYYY-MM-DD"),
          endTime: moment(end).format("YYYY-MM-DD"),
          transType: "debit",
          limit: 10,
          offset: offset+10,
          cardUserId:id,
      }));
      } 
      }
    }
  }

  const filterFunc = (startDate, endDate) => {
    setOffset(0);
    setFilter(1);
    setStart(startDate);
    setEnd(endDate);
    console.log("start date", startDate);
    console.log("end date", endDate);
    dispatch(cleanTransactionList())
    if(cardType=="2" ){
      dispatch(getM2pCardTransactions({
        fleetId:fleetId,
        userId:id,
        startTime: moment(start).format("YYYY-MM-DD"),
        endTime: moment(end).format("YYYY-MM-DD"),
        transType: "debit",
        limit: 10,
        offset: 0,
        cardUserId:id,
     }))
    }
    else if (cardType=="1" )
    {

      dispatch(getTransactionsAndExpense({
        fleetId:fleetId,
        userId:id,
        startTime: moment(start).format("YYYY-MM-DD"),
        endTime: moment(end).format("YYYY-MM-DD"),
        transType: "debit",
        limit: 10,
        offset:0,
        cardUserId:id,
    }));
  } 
  };


  return (
    <>
     <div className=" flexStyling new-row-for-download padding16R padding16T" >
        <div className="Bold heading2 padding16 " style={{paddingLeft:"0"}}>Transaction History</div>
        <div className="flex1"></div>
        <div className="filterText" onClick={showing1} >
            Filter
            <div className="filterImg">
                <SvgImage name="filterIcon" />
            </div>
        </div>
        {
        <AddingFilter
          active={active1}
          toggleModal={toggling1}
          handleClose={closing1}
          func={filterFunc}
          name="Filter"
        />
        }
        {/* <div className=" downloadText" >
            Download Statement
            <div className="padding8L" >
            <SvgImage name="downloadImg" />
            </div>
            
        </div>
        {
        <AddingFilter
            
        />
        }    */}
    </div>
    <div className="row margin0 padding16B padding0L">
        <div className="col-2n5 padding0L" onClick={()=>{
            setCardChosen(0)
        }}>
            <Button className={cardChosen==0?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
            Card Transaction</Button>
        </div>
        <div className="col-2n5"  onClick={()=>{
            setCardChosen(1)
        }}>
            <Button className={cardChosen==1?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
            Cash Transaction</Button>
        </div>
        <div className="col-3"  onClick={()=>{
            setCardChosen(2)
        }}>
            <Button className={cardChosen==2?"generateOtp heading4 semiBold":"notGenerateOpt heading4 semiBold"}>
            FASTag Transaction</Button>
        </div>
    </div>
    {
        cardTransactionsList==undefined?
        <NoTransaction />
        :   cardTransactionsList?.length==0?
        <NoTransaction />
        :
        <>
     <Table className="DriverSectionTable" bordered="true" id="driver_tables">
        <thead >
        <tr className="table-header-customer-details-dr">
            <th style={{width:"13%"}} >Date & Time</th>
            <th > <div style={{marginLeft:"rem"}}>Transaction Details</div> </th>
            <th>Remark</th>
            <th style={{width:"13%"}}>Debit (INR)</th>
            <th style={{width:"13%"}}>Credit (INR)</th>
            <th style={{width:"19%"}}>Closing Balance (INR)</th>
        </tr>
        </thead>
        <tbody className="lowerBorderLine">
        {transactionsAndExpenses?.rows?.map((d) => {

            let remarks=d?.userRemarks;
            let details= d?.description;
            let amount=d?.amount;
            let type=d?.transferType;
            let date=moment(d?.transactionDate.split(" ")[0], [
              "YYYY-MM-DD",
            ]).format("DD-MM-YYYY");
            let time=moment(d?.transactionDate.split(" ")[1], [
              "hh:mm:ss",
            ]).format("hh:mm:ss a");

              return (
                <tr className="table-row-manage-driver-new text4">
                  <td style={{width:"13%"}}>
                    <div>{date}</div>
                    <div>{time}</div>
                  </td>
                  <td>{details}</td>
                  <td>{remarks}</td>
                  <td style={{width:"13%"}}>{type=="debit"?amount:"-"}</td>
                  <td style={{width:"13%"}}>{type=="credit"?amount:"-"}</td>
                  <td style={{width:"19%"}}>NA</td>
                </tr>
              );
            })} 
        </tbody>
      </Table>
      <div className="row margin0 padding16" >
          <div className="col-4" style={{textAlign:"right", paddingTop:"0.1rem", cursor:offset>0?"pointer":"no-drop" , opacity:offset>0?"1":"0.5"}}
            onClick={handlePrev}
            >
            <SvgImage name="PrevArrow" />
          </div>
          <div className="col-1 greenFont semiBold heading4 padding0L" style={{textAlign:"right", paddingTop:"0.3rem", cursor:offset>0?"pointer":"no-drop", opacity:offset>0?"1":"0.5"}} 
          onClick={handlePrev}>
              Previous
          </div>
          <div className="col-2 row margin0">
            <div className="greenBoxCount Medium heading4 col-4" style={{width:"2rem", height:"2rem", alignItems:"center", textAlign:"center"}}>{(offset/10)+1}</div>
            <div className=" col-8 padding0R"  style={{ paddingTop:"0.3rem", textAlign:"right"}}>of  {trxnCount%10==0?Math.floor((trxnCount/10)):Math.floor((trxnCount/10)+1)}</div>
          </div>
          <div className="col-1 greenFont semiBold heading4 padding0R" style={{textAlign:"left", paddingTop:"0.3rem", cursor:trxnCount-offset>10?"pointer":"no-drop",opacity:trxnCount-offset>10?"1":"0.5"}}
            onClick={handleNext}>
              Next
          </div>
          <div className="col-4 padding0L" style={{textAlign:"left", marginLeft:"-1rem", paddingTop:"0.1rem", cursor:trxnCount-offset>10?"pointer":"no-drop",opacity:trxnCount-offset>10?"1":"0.5"}}
              onClick={handleNext}>
            <SvgImage name="NextArrow" />
          </div>
      </div>
      </>
      }
    </>
  );
};

export default TripCardTable;


