import React from "react";
import './index.css'
import { useState } from "react";
import { Navbar } from "reactstrap";
function FullVehicleListSwitchBar()
{
    const [AllClass,setAllClass] = useState("FullVehicleListBarALL")
    const [LowClass,setLowClass] = useState("FullVehicleistBarLow")
    const [KYCClass,setKYCClass]=useState("FullVehicleListBarKYC")
    const [endBar,setEndBar] = useState("FullVehicleEndBar")
    function handle(a){
        if(AllClass =="FullVehicleListBarALL"){
            setAllClass("FullVehicleListBarALLClicked")
            setLowClass("FullVehicleistBarLow")
            setKYCClass("FullVehicleListBarKYC")

        }
        else{
            setAllClass("FullVehicleListBarALL")
            setLowClass("FullVehicleistBarLow")
            setKYCClass("FullVehicleListBarKYC")

        }
          console.log(a)
    }
    function handle2(){
        if(LowClass =="FullVehicleistBarLow"){
            setLowClass("FullVehicleistBarLowClicked")
            setAllClass("FullVehicleListBarALL")
            setKYCClass("FullVehicleListBarKYC")
        }
        else{
            setAllClass("FullVehicleListBarALL")
            setLowClass("FullVehicleistBarLow")
            setKYCClass("FullVehicleListBarKYC")

        }

    }
    function handle3(){
        if(KYCClass ==="FullVehicleListBarKYC"){
            setKYCClass("FullVehicleListBarKYCClicked")
            setAllClass("FullVehicleListBarALL")
            setLowClass("FullVehicleistBarLow")
        }
        else{
            setLowClass("FullVehicleistBarLow")
            setAllClass("FullVehicleListBarALL")
            setKYCClass("FullVehicleListBarKYC")

        }

    }
    
    return(
        <Navbar>
        <div className="FullVehicleList-Bar" >
            
        <div className={AllClass} onClick={(a)=>handle(a)}>
        All
        </div>
        <div className={LowClass} onClick={handle2}>
        Low Fastag Bal.
        </div>
        <div className={KYCClass} onClick={handle3}>
        Fastag Link Pending
        </div>
        <div className={endBar}></div>
        </div>
        </Navbar>
)
}
export default FullVehicleListSwitchBar