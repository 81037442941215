import React, { useEffect, useState } from "react";
import { Modal, ModalBody, FormGroup, Form, Table, Button } from "reactstrap";
import "./index.css";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";

import { editLR } from "../../../../services/Store/Actions/TripsAction";
import { ClipLoader } from "react-spinners";
import {
  getSignedUrlApiAction,
  storeMedia,
  toastStatus,
} from "../../../../services/Store/Actions/appActions";
import ToastV2 from "../../../subComponents/toastV2";

function UploadPodForLr(props) {
  const { toggle, modal, setModal, location, isEdit, freightId } = props;
  const dispatch = useDispatch();
  // const [statusPod, setStatusPod] = useState(false);
  // const [imageLoaderPod, setImageLoaderPod] = useState(false);
  // const [displayNamePod, setDisplayNamePod] = useState([]);
  const [status, setStatus] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [displayName, setDisplayName] = useState([]);
  let [freight, setFreight] = useState({});
  const [chargesFormValues, setChargesFormValues] = useState([
    { remarks: "", amount: "" },
  ]);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  let removeChargesFormFields = (index) => {
    const newFormValues = chargesFormValues.filter((item, i) => {
      return index != i;
    });
    console.log("newFormValues", newFormValues);
    setChargesFormValues(newFormValues);
  };
  let handleChangeFieldCharges = (i, e) => {
    let newFormValues = [...chargesFormValues];
    newFormValues[i][e.target.name] = e.target.value;
    setChargesFormValues(newFormValues);
  };
  let addFormFieldsCharges = () => {
    console.log("===><<<><><", chargesFormValues);
    setChargesFormValues([...chargesFormValues, { remarks: "", amount: "" }]);
  };
  let { freightList } = useSelector((store) => store?.trip);
  useEffect(() => {
    if (modal) {
      let filter = freightList?.find((elem) => elem.freightId == freightId);
      console.log("freightLisfreightListt", freightList);
      setFreight(filter);
      if(filter?.chargesData?.length>0){
        setChargesFormValues(filter?.chargesData);
      }
    } else if (!modal) {
    }
  }, [modal, freightList]);
  useEffect(() => {
    if (freight?.podUrl) {
      dispatch(
        getSignedUrlApiAction({ key: freight?.podUrl }, (res) => {
          // setDownloadLoader2(false);
          // setLoaderInvoiceId("");
          if (res.status == 200) {
            setStatus(true);
            setDisplayName((prev) => [
              { name: "pod uploaded", link: res?.data?.data },
            ]);
          }
        })
      );
    }
  }, [freight]);
  const handleClose = () => {
    setFreight({});
    setModal(!modal);
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // scrollable={true}

        centered={true}
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <div
                className="closeBtnWithBackgroundForTrip"
                onClick={handleClose}
              >
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="crossBtnWIthBackground"
                  width="20%"
                  height="20%"
                />
              </div>
              <div
                className="PGCollectiontText col-3 "
                style={{ marginLeft: "50px" }}
              >
                <text>Upload Pod</text>
              </div>
              <hr
                style={{ marginLeft: "25px", border: "2px solid #2E7C7B" }}
              ></hr>
            </div>
            <Formik
              // isValid='true'
              enableReinitialize
              initialValues={{
                freightId: freightId ? freightId : "",
                podUrl: freight ? freight.podUrl : null,
              }}
              onSubmit={(values) => {
                let objVal = {
                  freightId: freightId ? freightId : "",
                  chargesData: chargesFormValues,
                  podUrl: values?.podUrl,
                };
                dispatch(
                  editLR(objVal, (res) => {
                    if (res?.status == 200) {
                      // console.log('rererere',res)
                      // handleClose();
                      dispatch(
                        toastStatus({
                          toastStatus: true,
                          message: `LR has been edited successfully`,
                          status: true,
                        })
                      );
                      // setOpenToast(true);
                      console.log("...............", res);
                    } else if (res?.status == 201) {
                      // handleClose();
                      dispatch(
                        toastStatus({
                          toastStatus: true,
                          message: `Can Not Edit This Lr`,
                          status: false,
                        })
                      );
                    }
                  })
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ padding: "0 50px 0 50px" }}
                >
                  <div>
                    <FormGroup>
                      <div>
                        <div className="heading3 semiBold">Charges</div>
                        <Table
                          className="TripSectionTable"
                          bordered="true"
                          id="driver_tables" //
                          // style={{ marginTop: "16px" }}
                        >
                          <thead>
                            <tr className="table-header-trip p-0 align-middle text-center">
                              <th className="p-0" style={{ width: "7%" }}>
                                <div style={{ marginLeft: "10px" }}>S.No</div>
                              </th>
                              <th
                                className="p-0"
                                style={{
                                  width: "30%",
                                  textAlignLast: "center",
                                }}
                              >
                                <div>Remarks</div>{" "}
                              </th>
                              <th
                                className="p-0"
                                style={{
                                  width: "16%",
                                  textAlignLast: "center",
                                }}
                              >
                                Amount
                              </th>
                              <th
                                className="p-0"
                                style={{
                                  width: "5%",
                                  textAlignLast: "center",
                                }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody className="lowerBorderLineCust">
                            {chargesFormValues?.map((element, index) => (
                              <tr
                                className="table-row-manage-trip-new text4 align-middle text-center p-0"
                                id="addr0"
                                key={index}
                                onClick={() => {
                                  // window.location = "/Customers/" + 1;
                                  //   navigate("/Customers/" + 1);
                                }}
                              >
                                <td
                                  style={{
                                    width: "7%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                  }}
                                  className="p-0"
                                >
                                  <div style={{ marginLeft: "10px" }}>
                                    {index + 1}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    width: "30%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                  }}
                                  className="p-0"
                                >
                                  <input
                                    type="text"
                                    className="minBox3"
                                    name="remarks"
                                    value={element.remarks || ""}
                                    onChange={(e) =>
                                      handleChangeFieldCharges(index, e)
                                    }
                                  />
                                </td>
                                <td
                                  style={{
                                    width: "16%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                  }}
                                  className="p-0"
                                >
                                  <input
                                    type="number"
                                    className="minBox3"
                                    name="amount"
                                    value={element.amount || ""}
                                    onChange={(e) =>
                                      handleChangeFieldCharges(index, e)
                                    }
                                  />
                                </td>
                                <td
                                  style={{
                                    width: "5%",
                                    borderLeft: "1px solid transparent",
                                    borderRight: " 1px solid transparent",
                                  }}
                                  className="p-0"
                                  onClick={() => {
                                    removeChargesFormFields(index);
                                  }}
                                >
                                  <SvgImage name="deleteIcon2" />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-center">
                          <div
                            className="addItemDiv"
                            style={{ width: "100%" }}
                            onClick={addFormFieldsCharges}
                          >
                            + Add Item
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="podUrl" className="heading3 semiBold">
                        Upload Pod
                      </label>
                      <div style={{ display: "flex" }}>
                        {!status ? (
                          <p
                            className="heading4 Regular"
                            style={{ margin: "10px 0 5px" }}
                          >
                            Upload Document
                          </p>
                        ) : (
                          <></>
                        )}
                        {!status ? (
                          <Button
                            className="add-attach-for-fastag-linked-vehicle-new"
                            type="button"
                          >
                            {imageLoader ? (
                              <div style={{ zIndex: 10 }}>
                                <ClipLoader
                                  color={"#2E7C7B"}
                                  loading={true}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </div>
                            ) : (
                              <div>
                                <SvgImage
                                  name="AddAttachment"
                                  height="25"
                                  width="25"
                                />
                                <input
                                  type="file"
                                  style={{ width: "25px" }}
                                  // multiple
                                  // accept="image/*"
                                  accept="image/*"
                                  className="add-lr-attachment"
                                  onChange={(e) => {
                                    const img = e?.target.files[0];
                                    console.log("image", img?.name);

                                    const tempArray = Object.assign(
                                      [],
                                      values.docs
                                    );
                                    console.log("sel", tempArray);

                                    if (img == null) {
                                      //clear image
                                      setFieldValue("podUrl", "", true);
                                      //for now  only one supported
                                    } else {
                                      console.log("upload", {
                                        img: img,
                                        uploadType: "aadharFront",
                                        isRes: true,
                                      });
                                      setImageLoader(true);
                                      dispatch(
                                        storeMedia(
                                          {
                                            img: img,
                                            uploadType: "aadharFront",
                                            isRes: false,
                                          },
                                          (res) => {
                                            setStatus(true);
                                            setDisplayName((prev) => [
                                              { name: img?.name },
                                            ]);
                                            setImageLoader(false);
                                            if (res?.url) {
                                              console.log("opt", res);
                                              tempArray.push(
                                                res?.data?.Location
                                              );
                                              setFieldValue(
                                                "podUrl",
                                                res?.url,
                                                true
                                              );
                                              // setFieldValue('docs', tempArray, true);
                                              //for now  only one supported
                                              setFieldValue(
                                                "docs",
                                                [res?.data?.Location],
                                                true
                                              );
                                            }
                                          }
                                        )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                margin: "0px -16px 2px -614px",
                                color: "red",
                              }}
                            >
                              {errors.podUrl && touched.podUrl ? (
                                <div>*{errors.podUrl}</div>
                              ) : null}
                            </div>
                          </Button>
                        ) : (
                          <></>
                        )}
                        <div className="selected-img">
                          {status ? (
                            displayName?.map((d) => {
                              return (
                                <div className="row">
                                  <div className="col-9">
                                    <p
                                      style={{
                                        color: "black",
                                        margin: "5px 0 0",
                                        // padding: "0",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        window.open(d.link);
                                      }}
                                    >
                                      {d.name}
                                    </p>
                                  </div>
                                  <div
                                    className="col-2"
                                    onClick={() => {
                                      setStatus(false);
                                    }}
                                  >
                                    <text>Change</text>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        style={{ width: "70%" }}
                        className="generateOtp text3 Medium btn btn-secondary"
                      >
                        Submit
                      </Button>
                    </FormGroup>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      {toastObject.toastStatus && (location == "/lr" || location == "/lr/") ? (
        <ToastV2
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            handleClose();
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            console.log("nmnnnnnnnnnmmmm", toastObject?.message);
          }}
          toggleModal_newFleetError={() => {
            handleClose();
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            console.log("qwertqewrth", toastObject?.message);
          }}
          isOpen={toastObject?.toastStatus}
          name="trips"
          handleClose={() => {
            handleClose();
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default UploadPodForLr;
