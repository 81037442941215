import React from "react";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { Modal, ModalBody, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

const OnVendor = (props) => {
  const {
    success,
    message,
    isOpen,
    onHide,
    toggleModal,
    handleClose,
    company,
  } = props;
  // console.log(
  //   "mesaage-------------",
  //   message,
  // message.includes("Error : Recharge limit by PG exceeded left")
  // );
  const navigate = useNavigate();
  console.log("===>>>>>>", company && !company?.company?.isVendor);
  return company && !company?.company?.isVendor ? (
    <Modal
      isOpen={isOpen}
      onHide={onHide}
      className=" modal-dialog-centered modal-dialog model-width-toast"
      // toggle={toggle}
      aria-labelledby="myModalLabel2"
      // style={{borderRadius : "10px"}}
    >
      <ModalBody
      //   className="transactionModalBody"
      //   style={{ marginTop: "25px" }}
      //   style={{borderRadius : "10px"}}
      >
        <div>
          <div className="row">
            <div className="col-12">
              <div style={{ textAlign: "center", paddingTop: "10px" }}>
                <SvgImage name="successLogo" />
              </div>
            </div>
            <div className="col-12 successToastHeader">
              Please Contact Support For Vendor Payments!
            </div>
            <div className="col-12 successToastMsg">{message}</div>
            <div
              className="col-12 successToastMsg"
              style={{ padding: "45px 0 30px 0" }}
            >
              <Button
                type="submit"
                style={{ width: "160px", borderRadius: "5px" }}
                className="generateOtp textAddCust Medium btn btn-secondary"
                onClick={() => {
                  handleClose();
                }}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  ) : (
    <div>
        {
            console.log("nnnnnnnn11111")
        }
        {
            navigate("/vendor")
        }

    </div>
  );
};

export default OnVendor;
