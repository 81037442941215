import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLogicoinsAction } from "../../../../../services/Store/Actions/ecomAction";


const PricingBreakup=(props)=>{
  const { mrp, discount, logiCoins, status}= props;

  const coinsVal= useSelector((state)=>state?.ecom?.coinsVal?.data);
  let coins = coinsVal==undefined?0: coinsVal==null?0:Math.floor(coinsVal?.fuelCoinsBalance-coinsVal?.redeemedCoins);
  console.log("coinsVal", coinsVal, coins);
    const dispatch= useDispatch();

  useEffect(()=>{
    let token = localStorage.getItem("_token");
    console.log("token in cart page",token);
    if(token!=null){
      dispatch(getLogicoinsAction());
    }
  },[])

  return (
    <div className="padding16 cartItem padding32LR">
        <div className="row">
            <div className="col-6">MRP</div>
            <div className="col-6 rightAlignment padding16R">₹ {Number(mrp)+(Number(discount))}</div>
        </div>
        <div className="row padding16T " >
            <div className="col-6">Discount</div>
            <div className="col-6 rightAlignment padding16R discAmount">₹ {discount}</div>
        </div>
        <div className="row bottomLine padding16T padding16B" >
            <div className="col-6">LogiCoins discount</div>
            <div className="col-6 rightAlignment padding16R discAmount">{status==0?(logiCoins!=0?
            (Number(coins)>logiCoins?(logiCoins):coins)
            :0)
            :logiCoins}</div>
        </div>
        <div className="row padding16T padding16B">
            <div className="col-6">Total Amount</div>
            <div className="col-6 rightAlignment padding16R finalAmt">₹ {
            status==0?
            (logiCoins!=0?
            (Number(coins)>Number(logiCoins)?Number(mrp)-Number(logiCoins):Number(mrp)-Number(coins))
            :mrp)
            :Number(mrp)-Number(logiCoins)}</div>
        </div>
    </div>
  );
}


export default PricingBreakup;