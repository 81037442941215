import {
    GET_TRANSFER_COLLECTIONS,
    GET_TRANSFER_COLLECTIONS_SUCCESS
  } from '../storeTypes';

export const getTransferCollection = () => {
    return {
      type: GET_TRANSFER_COLLECTIONS,
    };
  };
  export const getTransferCollectionSuccess = payload => {
    return {
      type: GET_TRANSFER_COLLECTIONS_SUCCESS,
      payload,
    };
  };
  