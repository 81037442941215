import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  linkFLeet,
  getFleetFleetID,
  getDriverList,
  cleanFleet,
  getFleetTracker,
} from "../../../services/Store/Actions/driverslistActions";
const DriverListItem = (props) => {
  const number = props.number == null ? "N/A" : props.number;
  const name = props.name == null ? "N/A" : props.name;
  const toggleModal = props.toggleModal;
  // const [linkFleetObj, setLinkFleetObj] = useState({
  //     driverId: "",
  //     fleetId: ""
  // });
  // const response = useSelector((state) => state);
  // console.log(response);
  const reposme = useSelector((state) => state);
  //console.log("repsommmdsmd", reposme);

  const dispatch = useDispatch();

  const handleClick = () => {
    // dispatch(getFleetFleetID(props.fleetID));
    console.log("--------driverID",props);
    dispatch(
      linkFLeet(
        {
          driverId: props.driverId,
          fleetId: props.fleetID,
        },
        (res) => {
          if (res?.data?.type === "success") {
            console.log("successful");
            //navigate("/Customers")
            //window.location.reload();
            toggleModal();
            dispatch(cleanFleet())
            dispatch(
              getFleetTracker({
                offset: props.offset,
                limit: 7,
                search: '',
                minKyc: "",
                sortAs: "",
                sortBy: "",
              })
            );
          }
        }
      )
    );

    //window.location.reload(false);
    // dispatch(getFleetFleetID(props.fleetID));
  };
  return (
    <tr>
      <td>{name}</td>
      <td>{number}</td>

      <td>
        <div  className="ManageDriverVehicleButton padding0 col-1"
        onClick={handleClick}>Select</div>
      </td>
    </tr>
    // <div className="row padding16B padding16T Regular text3 bottomBorderStyle">
    //   <div className=" col-2 padding16L">{num}</div>
    //   <div className=" col-3 padding8L" >{fastag}</div>
    //   <div className=" col-3 padding8L">{name}</div>
    //   <div className=" col-2 padding0"
    //     style={{color:status==="Available"?"#31BF7A":"#F0222E"}}>{status}</div>
    //   <div className="ManageDriverVehicleButton padding0 col-1" onClick={handleClick}>
    //     Select
    //   </div>
    //   {/* <div className="PGCollectionEndBar" style={{left:"25px"}}></div> */}
    // </div>
  );
};
export default DriverListItem;
