import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";

import moment from "moment";
import {
  createCustomerAction,
  getAllCustomersAction,
} from "../../../../services/Store/Actions/customerAction";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import ToastV2 from "../../../subComponents/toastV2";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import { useNavigate } from "react-router-dom";
import {
  addRoute,
  addTripAddress,
  getAddressFromPincodeAction,
  getRoute,
  getSavedAddress,
} from "../../../../services/Store/Actions/TripsAction";
import Toast from "../../../subComponents/toast";
import AddAddress from "../../../LoadReceipt/Components/AddAddress";

function CreateRoutes(props) {
  const { toggle, modal, setModal, navigatedFrom, data } = props;
  console.log("navigatedFrom", data);
  const [openToast, setOpenToast] = useState(false);
  const [temp, setTemp] = useState(navigatedFrom);
  console.log("navigatedFrom2222", openToast);
  const [message, setMessage] = useState("");

  const [addressList, setAddressList] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [addressModal, setAddressModal] = useState(false);
  const addAddress = () => setAddressModal(!addressModal);
  const { savedAddresses } = useSelector((state) => state?.trip);
  const [color1, setColor1] = useState(-1);
  const [color2, setColor2] = useState(-1);
  const validationSchema = Yup.object().shape({
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
  });
  const handleClose = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (display1 == true) {
      // dispatch(findTripAddressV2({},(res)=>{
      //   console.log("findTripAddressV2",res);
      // }))
      dispatch(
        getSavedAddress({ address: search1 }, (res) => {
          console.log("getSavedAddress", res);
        })
      );
    }
  }, [display1, search1]);
  useEffect(() => {
    if (display2 == true) {
      dispatch(
        getSavedAddress({ address: search2 }, (res) => {
          console.log("getSavedAddress", res);
        })
      );
    }
  }, [display2, search2]);
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ marginTop: "25px" }}
          //   style={{borderRadius : "10px"}}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            style={{ marginTop: " 30px" }}
            //   className="PGCollectionBox"
          >
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              {data ? "Edit Route" : "Add Route"}
            </div>

            <Formik
              // isValid='true'
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                name: data?.name || "",
                driverExpected: data?.driverExpected?.toString() || "0",
                driverAdvance: data?.driverAdvance?.toString() || "0",
                ownerExpected: data?.ownerExpected?.toString() || "0",
                ownerAdvance: data?.ownerAdvance?.toString() || "0",
                fuelExpected: data?.fuelExpected?.toString() || "0",
                fuelAdvance: data?.fuelAdvance?.toString() || "0",
                fastagExpected: data?.fastagExpected?.toString() || "0",
                cashExpected: data?.cashExpected?.toString() || "0",
                cashAdvance: data?.cashAdvance?.toString() || "0",
                sDriverExpected: data?.sDriverExpected?.toString() || "0",
                sDriverAdvance: data?.sDriverAdvance?.toString() || "0",
                vc: data?.vc || "",
                from: data?.fromAddressRoute?.cityName || "",
                to: data?.toAddressRoute?.cityName || "",
                expectedDistanceKM: data?.expectedDistanceKM ? data?.expectedDistanceKM : data?.distanceCal ? parseFloat(data?.distanceCal).toFixed(2) : "",
                expectedTimeHR:  data?.expectedTimeHR ? data?.expectedTimeHR : data?.durationCal ? parseFloat(data?.durationCal/60).toFixed(2) : "",
                fromId: "",
                toId: "",
              }}
              onSubmit={(values, actions) => {
                console.log(
                  "===>>>>init",
                  values,
                  values?.name,
                  "33",
                  data?.name
                );
                let payload = {
                  name: values?.name ? values.name : data?.name,
                  driverExpected: values?.driverExpected
                    ? values.driverExpected?.toString()
                    : data?.driverExpected?.toString(),
                  driverAdvance: values?.driverAdvance
                    ? values.driverAdvance?.toString()
                    : data?.driverAdvance?.toString(),
                  ownerExpected: values?.ownerExpected
                    ? values.ownerExpected?.toString()
                    : data?.ownerExpected?.toString(),
                  ownerAdvance: values?.ownerAdvance
                    ? values.ownerAdvance?.toString()
                    : data?.ownerAdvance?.toString(),
                  fuelExpected: values?.fuelExpected
                    ? values.fuelExpected?.toString()
                    : data?.fuelExpected?.toString(),
                  fuelAdvance: values?.fuelAdvance
                    ? values.fuelAdvance?.toString()
                    : data?.fuelAdvance?.toString(),
                  fastagExpected: values?.fastagExpected
                    ? values.fastagExpected?.toString()
                    : data?.fastagExpected?.toString(),
                  cashExpected: values?.cashExpected
                    ? values.cashExpected?.toString()
                    : data?.cashExpected?.toString(),
                  cashAdvance: values?.cashAdvance
                    ? values.cashAdvance?.toString()
                    : data?.cashAdvance?.toString(),
                  sDriverExpected: values?.sDriverExpected
                    ? values.sDriverExpected?.toString()
                    : data?.sDriverExpected?.toString(),
                  sDriverAdvance: values?.sDriverAdvance
                    ? values.sDriverAdvance?.toString()
                    : data?.sDriverAdvance?.toString(),
                  vc: values?.vc ? values.vc : data?.vc,
                  from: values?.fromId ? values.fromId : data?.from,
                  to: values?.toId ? values.toId : data?.to,
                  expectedDistanceKM: values?.expectedDistanceKM
                    ? values.expectedDistanceKM?.toString()
                    : data?.expectedDistanceKM?.toString(),
                  expectedTimeHR: values?.expectedTimeHR
                    ? values.expectedTimeHR?.toString()
                    : data?.expectedTimeHR?.toString(),
                };
                if (data?.routeId) {
                  payload["routeId"] = data?.routeId ? data?.routeId : null;
                }

                dispatch(
                  addRoute(payload, (res) => {
                    setMessage(res?.data?.message);
                    setOpenToast(true);
                    console.log("valuesvaluesvalues", openToast);
                    // dispatch(
                    //   getRoute({
                    //     offset: "",
                    //     search: "",
                    //     minKyc: "",
                    //     sortAs: "",
                    //     sortBy: "",
                    //   })
                    // );
                  })
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "40px", padding: "0 0px 0 55px" }}
                >
                  <FormGroup>
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Route Name
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="name"
                            className={`inputBoxAddCust ${
                              touched.name && errors.name ? "is-invalid" : ""
                            }`}
                            name="name"
                            value={values.name || data?.name}
                            onChange={handleChange("name")}
                            onBlur={handleBlur("name")}
                            touched={touched.name}
                            errors={errors.name}
                            placeholder="Route Name"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.name && touched.name ? (
                              <div>*{errors.name}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <FormGroup className="col-4">
                        <label for="from" className="heading3 semiBold">
                          From
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <input
                            type="text"
                            name="from"
                            id="from"
                            placeholder={"from City"}
                            className={`form-control-1 ${
                              touched.from && errors.from ? "is-invalid" : ""
                            }`}
                            aria-label="Default select example"
                            onChange={(e) => {
                              setDisplay1(true);
                              setSearch1(e.target.value);
                              setFieldValue("from", e.target.value, true);
                            }}
                            onClick={() => {
                              setDisplay1(true);
                            }}
                            value={values.from}
                            autoComplete="off"
                            onBlur={handleBlur("from")}
                            errors={errors.from}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                // findSearch1();
                              }
                            }}
                          ></input>
                          <span
                            style={{ marginLeft: "20px", cursor: "pointer" }}
                            onClick={addAddress}
                          >
                            + Add Address
                          </span>
                        </div>
                        {display1 ? (
                          <div
                            className="padding16 newCashback whiteBackground"
                            style={{
                              position: "absolute",
                              width: "350px",
                              height: "275px",
                              zIndex: "90",
                            }}
                          >
                            <div
                              onClick={() => {
                                setDisplay1(false);
                                setSearch1("");
                                //dispatch(clearAllDriversByVrn());
                                // setFieldValue("from", "", true);
                                // setFieldValue("fromId", "", true);
                              }}
                              style={{
                                position: "absolute",
                                right: "0.5rem",
                                top: "0.5rem",
                                cursor: "pointer",
                              }}
                            >
                              <SvgImage name="CloseButtonLogo" />
                            </div>
                            <div
                              style={{
                                overflowY: "scroll",
                                overflowX: "hidden",
                                height: "240px",
                              }}
                            >
                              {console.log(
                                "savedAddresses11111",
                                search1,
                                savedAddresses
                              )}
                              {search1 == "" ? (
                                savedAddresses?.map((data, i) => {
                                  let city = data?.cityName;
                                  let state = data?.stateName;
                                  let address = data?.address;
                                  console.log(
                                    "savedAddresses",
                                    city,
                                    state,
                                    address
                                  );
                                  return (
                                    <div
                                      key={i}
                                      className="padding8 row"
                                      onClick={() => {
                                        // setcityId1(data?.cityName);
                                        setColor1(i);
                                        setDisplay1(false);
                                        //setUserId(data?.User?.userId);
                                        setFieldValue(
                                          "fromId",
                                          data?.addressId,
                                          true
                                        );
                                        setFieldValue(
                                          "from",
                                          `${address}, ${city} - ${state}`,
                                          true
                                        );
                                      }}
                                    >
                                      {color1 === i ? (
                                        <div className="col-1">
                                          <SvgImage name="SelectedRadio" />
                                        </div>
                                      ) : (
                                        <div className="col-1">
                                          <SvgImage name="UnselectedRadio" />
                                        </div>
                                      )}
                                      {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                      <div
                                        className="col-11"
                                        style={{ textAlign: "left" }}
                                      >
                                        {address}, {city} - {state}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <>
                                  {savedAddresses?.map((data, i) => {
                                    let city = data?.cityName;
                                    let state = data?.stateName;
                                    let address = data?.address;

                                    console.log("check status", city, state);
                                    // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                                    return (
                                      <div
                                        key={i}
                                        className="padding8 row"
                                        onClick={() => {
                                          // setcityId1(data?.cityName);
                                          setColor1(i);
                                          setDisplay1(false);
                                          //setUserId(data?.User?.userId);
                                          setFieldValue(
                                            "fromId",
                                            data?.addressId,
                                            true
                                          );
                                          setFieldValue(
                                            "from",
                                            `${address}, ${city} - ${state}`,
                                            true
                                          );
                                        }}
                                      >
                                        {color1 === i ? (
                                          <div className="col-1">
                                            <SvgImage name="SelectedRadio" />
                                          </div>
                                        ) : (
                                          <div className="col-1">
                                            <SvgImage name="UnselectedRadio" />
                                          </div>
                                        )}
                                        <div
                                          className="col-11"
                                          style={{ textAlign: "left" }}
                                        >
                                          {address}, {city} - {state}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                              {savedAddresses?.length == 0 ? (
                                <div className="">
                                  <div style={{ margin: "24px" }}>
                                    You have no addresses to select please add a
                                    new one
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <div
                                      className="greenButton d-flex justify-content-center align-items-center"
                                      style={{
                                        width: "200px",
                                        cursor: "pointer",
                                      }}
                                      onClick={addAddress}
                                    >
                                      <div>Add New Address</div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div style={{ margin: "7px 0 0px 0px", color: "red" }}>
                          {errors.from && touched.from ? (
                            <div>*{errors.from}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <FormGroup className="col-4">
                        <label for="to" className="heading3 semiBold">
                          To
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <input
                            type="text"
                            name="to"
                            id="to"
                            placeholder={"to City"}
                            className={`form-control-1 ${
                              touched.to && errors.to ? "is-invalid" : ""
                            }`}
                            aria-label="Default select example"
                            onChange={(e) => {
                              setDisplay2(true);
                              setSearch2(e.target.value);
                              setFieldValue("to", e.target.value, true);
                            }}
                            onClick={() => {
                              setDisplay2(true);
                            }}
                            value={values.to}
                            autoComplete="off"
                            onBlur={handleBlur("to")}
                            errors={errors.to}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                // findSearch2();
                              }
                            }}
                          ></input>
                          <span
                            style={{ marginLeft: "20px", cursor: "pointer" }}
                            onClick={addAddress}
                          >
                            + Add Address
                          </span>
                        </div>
                        {display2 ? (
                          <div
                            className="padding16 newCashback whiteBackground"
                            style={{
                              position: "absolute",
                              width: "350px",
                              height: "275px",
                              zIndex: "90",
                            }}
                          >
                            <div
                              onClick={() => {
                                setDisplay2(false);
                                setSearch2("");
                                //dispatch(clearAllDriversByVrn());
                                // setFieldValue("to", "", true);
                              }}
                              style={{
                                position: "absolute",
                                right: "0.5rem",
                                top: "0.5rem",
                                cursor: "pointer",
                              }}
                            >
                              <SvgImage name="CloseButtonLogo" />
                            </div>
                            <div
                              style={{
                                overflowY: "scroll",
                                overflowX: "hidden",
                                height: "240px",
                              }}
                            >
                              {search2 == "" ? (
                                savedAddresses?.map((data, i) => {
                                  let city = data?.cityName;
                                  let state = data?.stateName;
                                  let address = data?.address;
                                  return (
                                    <div
                                      key={i}
                                      className="padding8 row"
                                      onClick={() => {
                                        // setcityId1(data?.cityName);
                                        setColor2(i);
                                        setDisplay2(false);
                                        //setUserId(data?.User?.userId);
                                        setFieldValue(
                                          "toId",
                                          data?.addressId,
                                          true
                                        );
                                        setFieldValue(
                                          "to",
                                          `${address}, ${city} - ${state}`,
                                          true
                                        );
                                      }}
                                    >
                                      {color2 === i ? (
                                        <div className="col-1">
                                          <SvgImage name="SelectedRadio" />
                                        </div>
                                      ) : (
                                        <div className="col-1">
                                          <SvgImage name="UnselectedRadio" />
                                        </div>
                                      )}
                                      {/* <div className="col-1">
                      <SvgImage name="UnselectedRadio" />
                    </div> */}
                                      <div
                                        className="col-11"
                                        style={{ textAlign: "left" }}
                                      >
                                        {address}, {city} - {state}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <>
                                  {savedAddresses?.map((data, i) => {
                                    let city = data?.cityName;
                                    let state = data?.stateName;
                                    let address = data?.address;

                                    console.log("check status", city, state);
                                    // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                                    return (
                                      <div
                                        key={i}
                                        className="padding8 row"
                                        onClick={() => {
                                          // setcityId1(data?.cityName);
                                          setColor2(i);
                                          setDisplay2(false);
                                          //setUserId(data?.User?.userId);
                                          setFieldValue(
                                            "toId",
                                            data?.addressId,
                                            true
                                          );
                                          setFieldValue(
                                            "to",
                                            `${address}, ${city} - ${state}`,
                                            true
                                          );
                                        }}
                                      >
                                        {color2 === i ? (
                                          <div className="col-1">
                                            <SvgImage name="SelectedRadio" />
                                          </div>
                                        ) : (
                                          <div className="col-1">
                                            <SvgImage name="UnselectedRadio" />
                                          </div>
                                        )}
                                        <div
                                          className="col-11"
                                          style={{ textAlign: "left" }}
                                        >
                                          {address}, {city} - {state}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                              {savedAddresses?.length == 0 ? (
                                <div className="">
                                  <div style={{ margin: "24px" }}>
                                    You have no addresses to select please add a
                                    new one
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <div
                                      className="greenButton d-flex justify-content-center align-items-center"
                                      style={{
                                        width: "200px",
                                        cursor: "pointer",
                                      }}
                                      onClick={addAddress}
                                    >
                                      <div>Add New Address</div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div style={{ margin: "7px 0 0px 0px", color: "red" }}>
                          {errors.to && touched.to ? (
                            <div>*{errors.to}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Driver Expected
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.driverExpected && errors.driverExpected
                                ? "is-invalid"
                                : ""
                            }`}
                            name="driverExpected"
                            value={
                              values.driverExpected || data?.driverExpected
                            }
                            onChange={handleChange("driverExpected")}
                            onBlur={handleBlur("driverExpected")}
                            touched={touched.driverExpected}
                            errors={errors.driverExpected}
                            placeholder="driver expected"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.driverExpected && touched.driverExpected ? (
                              <div>*{errors.driverExpected}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Driver Advance
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.driverAdvance && errors.driverAdvance
                                ? "is-invalid"
                                : ""
                            }`}
                            name="driverAdvance"
                            value={values.driverAdvance || data?.driverAdvance}
                            onChange={handleChange("driverAdvance")}
                            onBlur={handleBlur("driverAdvance")}
                            touched={touched.driverAdvance}
                            errors={errors.driverAdvance}
                            placeholder="driverAdvance"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.driverAdvance && touched.driverAdvance ? (
                              <div>*{errors.driverAdvance}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Owner Expected
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.ownerExpected && errors.ownerExpected
                                ? "is-invalid"
                                : ""
                            }`}
                            name="ownerExpected"
                            value={values.ownerExpected || data?.ownerExpected}
                            onChange={handleChange("ownerExpected")}
                            onBlur={handleBlur("ownerExpected")}
                            touched={touched.ownerExpected}
                            errors={errors.ownerExpected}
                            placeholder="Owner Expected"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.ownerExpected && touched.ownerExpected ? (
                              <div>*{errors.ownerExpected}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Owner Advance
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.ownerAdvance && errors.ownerAdvance
                                ? "is-invalid"
                                : ""
                            }`}
                            name="ownerAdvance"
                            value={values.ownerAdvance || data?.ownerAdvance}
                            onChange={handleChange("ownerAdvance")}
                            onBlur={handleBlur("ownerAdvance")}
                            touched={touched.ownerAdvance}
                            errors={errors.ownerAdvance}
                            placeholder="ownerAdvance"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.ownerAdvance && touched.ownerAdvance ? (
                              <div>*{errors.ownerAdvance}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Fuel Expected
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.fuelExpected && errors.fuelExpected
                                ? "is-invalid"
                                : ""
                            }`}
                            name="fuelExpected"
                            value={values.fuelExpected || data?.fuelExpected}
                            onChange={handleChange("fuelExpected")}
                            onBlur={handleBlur("fuelExpected")}
                            touched={touched.fuelExpected}
                            errors={errors.fuelExpected}
                            placeholder="Fuel Expected"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.fuelExpected && touched.fuelExpected ? (
                              <div>*{errors.fuelExpected}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Fuel Advance
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.fuelAdvance && errors.fuelAdvance
                                ? "is-invalid"
                                : ""
                            }`}
                            name="fuelAdvance"
                            value={values.fuelAdvance || data?.fuelAdvance}
                            onChange={handleChange("fuelAdvance")}
                            onBlur={handleBlur("fuelAdvance")}
                            touched={touched.fuelAdvance}
                            errors={errors.fuelAdvance}
                            placeholder="fuelAdvance"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.fuelAdvance && touched.fuelAdvance ? (
                              <div>*{errors.fuelAdvance}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Fastag Expected
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.fastagExpected && errors.fastagExpected
                                ? "is-invalid"
                                : ""
                            }`}
                            name="fastagExpected"
                            value={
                              values.fastagExpected || data?.fastagExpected
                            }
                            onChange={handleChange("fastagExpected")}
                            onBlur={handleBlur("fastagExpected")}
                            touched={touched.fastagExpected}
                            errors={errors.fastagExpected}
                            placeholder="Fastag Expected"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.fastagExpected && touched.fastagExpected ? (
                              <div>*{errors.fastagExpected}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Cash Expected
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.cashExpected && errors.cashExpected
                                ? "is-invalid"
                                : ""
                            }`}
                            name="cashExpected"
                            value={values.cashExpected || data?.cashExpected}
                            onChange={handleChange("cashExpected")}
                            onBlur={handleBlur("cashExpected")}
                            touched={touched.cashExpected}
                            errors={errors.cashExpected}
                            placeholder="Cash Expected"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.cashExpected && touched.cashExpected ? (
                              <div>*{errors.cashExpected}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Cash Advance
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.cashAdvance && errors.cashAdvance
                                ? "is-invalid"
                                : ""
                            }`}
                            name="cashAdvance"
                            value={values.cashAdvance || data?.cashAdvance}
                            onChange={handleChange("cashAdvance")}
                            onBlur={handleBlur("cashAdvance")}
                            touched={touched.cashAdvance}
                            errors={errors.cashAdvance}
                            placeholder="cashAdvance"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.cashAdvance && touched.cashAdvance ? (
                              <div>*{errors.cashAdvance}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Second Driver Expected
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.sDriverExpected && errors.sDriverExpected
                                ? "is-invalid"
                                : ""
                            }`}
                            name="sDriverExpected"
                            value={
                              values.sDriverExpected || data?.sDriverExpected
                            }
                            onChange={handleChange("sDriverExpected")}
                            onBlur={handleBlur("sDriverExpected")}
                            touched={touched.sDriverExpected}
                            errors={errors.sDriverExpected}
                            placeholder="Second driver Expected"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.sDriverExpected &&
                            touched.sDriverExpected ? (
                              <div>*{errors.sDriverExpected}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Second Driver Advance
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="number"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.sDriverAdvance && errors.sDriverAdvance
                                ? "is-invalid"
                                : ""
                            }`}
                            name="sDriverAdvance"
                            value={
                              values.sDriverAdvance || data?.sDriverAdvance
                            }
                            onChange={handleChange("sDriverAdvance")}
                            onBlur={handleBlur("sDriverAdvance")}
                            touched={touched.sDriverAdvance}
                            errors={errors.sDriverAdvance}
                            placeholder="sDriverAdvance"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.sDriverAdvance && touched.sDriverAdvance ? (
                              <div>*{errors.sDriverAdvance}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            VC
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.vc && errors.vc ? "is-invalid" : ""
                            }`}
                            name="vc"
                            value={values.vc || data?.vc}
                            onChange={handleChange("vc")}
                            onBlur={handleBlur("vc")}
                            touched={touched.vc}
                            errors={errors.vc}
                            placeholder="VC"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.vc && touched.vc ? (
                              <div>*{errors.vc}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {data ? (
                        <div className="col-4 row">
                          <div className="col-12">
                            <label
                              for="expectedDistanceKM"
                              className="heading3 semiBold"
                            >
                              Expected Distance (in KM)
                            </label>
                          </div>
                          <div className="col-12" style={{ padding: "8px" }}>
                            <input
                              type="number"
                              id="expectedDistanceKM"
                              className={`inputBoxAddCust ${
                                touched.expectedDistanceKM &&
                                errors.expectedDistanceKM
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="expectedDistanceKM"
                              value={
                                values.expectedDistanceKM ||
                                data?.expectedDistanceKMvc
                              }
                              onChange={handleChange("expectedDistanceKM")}
                              onBlur={handleBlur("expectedDistanceKM")}
                              touched={touched.expectedDistanceKM}
                              errors={errors.expectedDistanceKM}
                              placeholder="Expected Distance in KM"
                            />
                            <div
                              style={{ margin: "7px 0 0px 5px", color: "red" }}
                            >
                              {errors.expectedDistanceKM &&
                              touched.expectedDistanceKM ? (
                                <div>*{errors.expectedDistanceKM}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {data ? (
                        <div className="col-4 row">
                          <div className="col-12">
                            <label
                              for="expectedTimeHR"
                              className="heading3 semiBold"
                            >
                              Expected Time (in HR)
                            </label>
                          </div>
                          <div className="col-12" style={{ padding: "8px" }}>
                            <input
                              type="number"
                              id="tripNo"
                              className={`inputBoxAddCust ${
                                touched.expectedTimeHR && errors.expectedTimeHR
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="expectedTimeHR"
                              value={
                                values.expectedTimeHR 
                              }
                              onChange={handleChange("expectedTimeHR")}
                              onBlur={handleBlur("expectedTimeHR")}
                              touched={touched.expectedTimeHR}
                              errors={errors.expectedTimeHR}
                              placeholder="Expected Time in HR"
                            />
                            <div
                              style={{ margin: "7px 0 0px 5px", color: "red" }}
                            >
                              {errors.expectedTimeHR &&
                              touched.expectedTimeHR ? (
                                <div>*{errors.expectedTimeHR}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup
                    style={{
                      padding: "25px 0 0 0",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{ width: "160px", borderRadius: "5px" }}
                      className="generateOtp textAddCust Medium btn btn-secondary"
                      // onClick={addFreight}
                    >
                      Done
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      {console.log("createLRModal", navigatedFrom, toastObject)}
      {toastObject.toastStatus && navigatedFrom != "createLRModal" ? (
        <ToastV2
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            setOpenToast(false);
          }}
          isOpen={toastObject?.toastStatus}
          name="driver"
          handleClose={(isOpen) => {
            if (navigatedFrom != "createLRModal") {
              dispatch(
                toastStatus({
                  toastStatus: false,
                  message: "",
                  status: true,
                })
              );
            } else {
              isOpen = false;
            }
          }}
        />
      ) : (
        <></>
      )}
      <Toast
        success={"1"}
        message={message}
        toggleModal={() => {}}
        isOpen={openToast}
        name="driver"
        handleClose={() => {
          // dispatch(
          //   toastStatus({
          //     toastStatus: false,
          //     message: "",
          //     status: true,
          //   })
          // );
          // if (!toastObject?.toastStatus)
          // window.location = "/Routes";
          setOpenToast(false);
          handleClose();
          dispatch(
            getRoute({
              offset: "",
              search: "",
              minKyc: "",
              sortAs: "",
              sortBy: "",
            })
          );
        }}
      />
      <AddAddress
        toggle={addAddress}
        address={addressModal}
        setAddress={setAddressModal}
      />
    </div>
  );
}

export default CreateRoutes;
