import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import "./index.css";
import {
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";
import CartItem from "./Components/CartItem";
import LogicoinOption from "./Components/LogicoinOption";
import PricingDetails from "./Components/PricingDetails";
import { getCartDetailsAction } from "../../../../services/Store/Actions/ecomAction";
import AddressDetails from "./Components/AddressDetails";
import Payment from "./Components/Payment";
import AddToCartModal from "../ProductDetails/Components/AddToCartModal";

function MyCart(props){

    const {handleClose,open, toggleModal, handleCloseEmi,openEmi, toggleModalEmi, showEmi, onHideEmi, setCrtBtn, setScreenBtn}= props;
    const {cartDetails}= useSelector((state)=>state?.ecom);
    const [coinVal, setCoinVal] = useState(0);
    const [coinStatus, setCoinStatus] = useState(false);
    const [addressId, setAddressId]= useState("");
    const [address, setAddress]= useState("");
    const {loader}= useSelector((state)=>state?.ecom);

    const dispatch= useDispatch();
    useEffect(()=>{
      dispatch(getCartDetailsAction((res) => {
        console.log("res", res);
        if (res?.status == 200) {
          // setCartBtn("Go to Cart");
        }
      }
      ))
    },[open])

    // open address details modal
    const [openAdd, setOpenAdd]= useState(false);

    const closeModalAdd=()=>{
    setOpenAdd(false);
    }

    const showModalAdd=()=>{
    setOpenAdd(true);
    }

    const toggleModalAdd=()=>{
    setOpenAdd(!openAdd);
    }

    // open payment modal
    const [openPay, setOpenPay]= useState(false);

    const closeModalPay=()=>{
    setOpenPay(false);
    }

    const showModalPay=()=>{
    setOpenPay(true);
    }

    const toggleModalPay=()=>{
    setOpenPay(!openPay);
    }

    console.log("cartDetails", cartDetails);
    let amtVal= coinStatus?(cartDetails?.amount-coinVal):(cartDetails?.amount);
    let addressval=address!=""?address?.address1+", "+address?.city+", "+address?.state+" India - "+address?.pincode:"";
    
    const handleSubmit=()=>{
      showModalPay();
    }
    
    return (
        <div>
          <Modal
            isOpen={open}
            onHide={()=>{
            //   setStatus(false);
              handleClose();
            }}
            className="positon settingModalMargin"
            toggle={()=>{
            //   setStatus(false);
              toggleModal();
            }}
            aria-labelledby="myModalLabel2"
          >
            <div
              className="modal-content"
              style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
            >
              <div className="modal-head-part">
                <p className="Add-balance-title">My Cart</p>
                <Button
                  type="button"
                  className="close-btn"
                  onClick={()=>{
                    // dispatch(sendAnalitics("close_OwnerAcc_Home_web"));
                    // setStatus(false);
                    toggleModal();
                  }}
                  aria-label="Close"
                >
                  <SvgImage name="CloseButtonLogo" />
                </Button>
              </div>
              <ModalBody className="padding8 padding0L ">
                {
                    (cartDetails?.resp?.[0])?
                    <>
                    <div 
                    // style={{height:cartDetails?.resp.length>1?"50%":"40%", overflowY:"scroll"}}
                    >
                    {
                      (cartDetails?.resp?.map((item, i) => {
                        console.log("item-- ", item)
                        return (
                          <CartItem key={i} prodId={item?.productId}
                            quantity={item?.quantity}
                            prodName={item?.productName}
                            prodImg={item?.image?.productUrl}
                            prodMRP={item?.price}
                            prodDiscount={item?.discountNumber}
                          />
                        )
                      }))
                    }
                    </div>
                    <div className="padding16" style={{ paddingTop: "1.5rem",marginLeft:"0.7rem" }}>
                      <LogicoinOption
                        coinsFromFinalAmt={cartDetails?.logiCoins}
                        setCoinVal={setCoinVal}
                        setCoinStatus={setCoinStatus}
                        coinStatus={coinStatus}
                      />
                    </div>
                    <div className="padding16"  style={{marginLeft:"0.7rem"}} >
                      <PricingDetails
                        HomeMrp={cartDetails?.amount}
                        HomeDiscount={cartDetails?.discount}
                        coinVal={coinVal}
                        setCoinStatus={setCoinStatus}
                        coinStatus={coinStatus}
                      />
                    </div>
                    <div className="padding16"  style={{marginLeft:"0.7rem"}}>
                      <div className="cartItem flexStyling padding16 Medium heading3" style={{borderRadius:"0.5rem"}} 
                        onClick={showModalAdd}>
                        <SvgImage name="gpsLogo" />
                        <div className="padding16L padding32R">{address==""?"Add delivery address":addressval.length>40?addressval.substring(0,40)+"...":addressval}</div>
                        <div style={{cursor:"pointer", position:"absolute", right:"2.5rem"}}>
                          <SvgImage name="nextArrow" />
                        </div>
                      </div>
                    </div>
                    <div className="padding16"  style={{marginLeft:"0.7rem"}}

                    >
                      <Button className="butt4" 
                        onClick={handleSubmit}
                        disabled={loader ? true : address==""?true:false}
                        style={{
                          background:
                          address==""
                          ? "#EFEFEF"
                          : loader
                          ? "#EFEFEF"
                          : "#485B7C",
                          color:
                          address==""
                          ? "#B5B5B5"
                          : loader
                          ? "#B5B5B5"
                          : "#ffffff",
                          margin:"0", width:"100%"
                        }}
                        >
                        {loader ? "Please Wait" : ("Pay ₹ "+ amtVal)}
                        </Button>
                    </div>
                    </>
                    :
                    <div style={{textAlign:"center", paddingTop:"5rem", paddingBottom:"8rem"}}>
                        <SvgImage name="noCartLogo" />
                        <div className="Medium heading2 padding16T">Your cart is empty! Add something</div>
                        <div className="Medium heading2"> from the LogiMart</div>
                        <div className="padding32T">
                            <Button className="padding8 Medium text1 greenOutlineButton1"
                              onClick={()=>{
                                setCrtBtn("Tyres");
                                setScreenBtn("");
                                toggleModal();
                              }}>
                                Start Shopping
                            </Button>
                        </div>
                    </div>

                }
              </ModalBody>
            </div>
          </Modal>
          <AddressDetails
            open={openAdd}
            onHide={closeModalAdd}
            toggleModal={toggleModalAdd}
            handleClose={closeModalAdd}
            setAddressId={setAddressId}
            setAddress={setAddress}
          />
          <Payment 
          open={openPay}
          onHide={closeModalPay}
          toggleModal={toggleModalPay}
          handleClose={closeModalPay}
          finalAmt={amtVal}
          cartDetails={cartDetails}
          coinVal={coinVal}
          coinStatus={coinStatus}
          addressval={addressval}
          addressId={addressId}
          openEmi={openEmi}
          onHideEmi={onHideEmi}
          toggleModalEmi={toggleModalEmi}
          handleCloseEmi={handleCloseEmi}
          showEmi={showEmi}
          toggleModalCart={toggleModal}
          />
        </div>
      );    
}

export default MyCart;