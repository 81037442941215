import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";

import SvgImage from "../../../Icons/Icons";

import Toast from "../../../subComponents/toast";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import Collapsible from "react-collapsible";
import DataTable from "react-data-table-component";
import {
  endTrip,
  getLRList,
  getTripExpenseAction,
  getTripLedger,
} from "../../../../services/Store/Actions/TripsAction";
import ViewTripCard from "../../../EndTrip/Components/ViewTripCard";
import ViewExpanseCard from "../../../EndTrip/Components/ViewExpanseCard";
import FreightUpdateRender from "../../../EndTrip/Components/FreightUpdateRender";
import { Formik } from "formik";
import { DatePickerField } from "../../../core/inputs/input";
import ManageDriverAddNewExpense from "../../../Modals/ManageDriverAddNewExpense";
import ManageDriverAddBalance from "../../../Modals/ManageDriverAddBalance";
import RechargeFastagButttonModal from "../../../Modals/rechargeFastagButton";
import OMCLoadModal from "../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCLoadModal";
import ChooseMethodModal from "../ChooseMethodModal";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";
import moment from "moment";
function EndTripModal(props) {
  const {
    toggle,
    modal,
    setModal,
    location,
    tripId,
    tripData,
    TripTotalExpenses,
    setItemTemp,
    sattlePayment,
    refreshList
  } = props;
  const dispatch = useDispatch();
  const [isOpenFASTag, setIsOpenFASTag] = useState(false);
  const [isOpenFuel, setIsOpenFuel] = useState(false);
  const [isVendorExpanse, setIsVendorExpanse] = useState(false);
  const [isOpenCard, setIsOpenCard] = useState(false);
  const [isOpenCash, setIsOpenCash] = useState(false);
  const [view, setView] = useState(false);
  const [openFreight, setOpenFreight] = useState(false);
  const [freightNo, setFreightNo] = useState(null);
  const [openEndTripModal2, setOpenEndTripModal2] = useState(false);
  const [openAddExpanse, setOpenAddExpanse] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [openFuel, setOpenFuel] = useState(false);
  const [open, setOpen] = useState(false);
  const [openChooseMethod, setOpenChooseModal] = useState(false);
  const {
    fastagVehicleTransaction,
    cashTransactions,
    freightList,
    freightListCount,
    tripExpense,
    tripCardLedger,
    tripCardLedgerList,
    tripCardLedgerCount,
    tripFastagLedger,
    tripFastagLedgerList,
    tripFastagLedgerCount,
    tripCashLedger,
    tripCashLedgerList,
    tripCashLedgerCount,
    tripOMCLedger,
    tripOMCLedgerList,
    tripOMCLedgerCount,
    tripVendorLedger,
    tripVendorLedgerList,
    tripVendorLedgerCount,
  } = useSelector((state) => state?.trip);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const { fastagWallet } = useSelector((state) => state?.fastag);
  const toggleModal3 = () => {
    setOpen3(!open3);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const rechargeCard = () => {
    setOpen3(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const toggleModal4 = () => {
    setOpen4(!open4);
  };
  const closeAddExpense = () => {
    setOpenAddExpanse(false);
  };
  const toggleAddExpense = () => {
    setOpenAddExpanse(!openAddExpanse);
  };
  const loadOmc = () => {
    setOpenFuel(true);
  };
  const handleCloseFuel = () => {
    setOpenFuel(false);
  };
  const toggleModalFuel = () => {
    setOpenFuel(!openFuel);
  };
  const chooseModal = () => {
    setOpenChooseModal(true);
  };
  const handleChooseModal = () => {
    setOpenChooseModal(false);
  };
  const toggleChooseModal = () => {
    setOpenChooseModal(!openChooseMethod);
  };
  const addExpense = () => {
    setOpen(true);
  };
  const handleClose2 = () => {
    setOpen(false);
  };
  const toggleModal2 = () => {
    setOpen(!open);
  };
  const fastagWalletAmount =
    fastagWallet.balance == null ? 0 : fastagWallet.balance;
  console.log("freightListfreightList", freightList);
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  let TotalCashExpacted = 0;
  for (let index = 0; index < tripExpense?.cashData?.length; index++) {
    const element = tripExpense?.cashData?.[index];
    TotalCashExpacted += parseInt(element?.expacted || 0);
  }
  const Expenses = [
    {
      type: "FASTag",
      totalExpenseExpected: tripExpense?.fastagTotal || 0,
      totalExpense: tripExpense?.totalFastagExpense || 0,
      open: () => {
        setIsOpenFASTag(!isOpenFASTag);
        if (!isOpenFASTag) {
          dispatch(
            getTripLedger(tripData?.tripId, {
              mode: "1",
            })
          );
        }
      },
      txns: tripFastagLedgerCount > 0 ? tripFastagLedgerList : [],
      isOpen: isOpenFASTag,
      // pay: () => {
      //   toggleModal4();
      // },
    },
    {
      type: "Fuel",
      totalExpenseExpected: tripExpense?.fuelTotal || 0,
      totalExpense: tripExpense?.totalFuelExpense || 0,
      open: () => {
        setIsOpenFuel(!isOpenFuel);
        console.log("asdfghadsfgesdf");
        if (!isOpenFuel) {
          dispatch(
            getTripLedger(tripData?.tripId, {
              mode: "3",
            })
          );
        }
      },
      txns: tripOMCLedgerCount > 0 ? tripOMCLedgerList : [],
      isOpen: isOpenFuel,
      pay: () => {
        // loadOmc();
        sattlePayment();
        // setIsOpenFuel(!isOpenFuel)
      },
    },
    {
      type: "Vendor",
      totalExpenseExpected: tripExpense?.vendorTotal || 0,
      totalExpense: tripExpense?.totalVendorExpense || 0,
      open: () => {
        setIsVendorExpanse(!isVendorExpanse);
        if (!isVendorExpanse) {
          dispatch(
            getTripLedger(tripData?.tripId, {
              mode: "4",
            })
          );
        }
      },
      txns: tripVendorLedgerCount ? tripVendorLedgerList : [],
      isOpen: isVendorExpanse,
      // pay: () => {
      //   toggleModal4();
      // },
    },
    {
      type: "Card",
      totalExpenseExpected: tripExpense?.driverTotal || 0,
      totalExpense: tripExpense?.totalCardExpense || 0,
      open: () => {
        setIsOpenCard(!isOpenCard);
        if (!isOpenCard) {
          dispatch(
            getTripLedger(tripData?.tripId, {
              mode: "0",
            })
          );
        }
      },
      txns: tripCardLedgerCount > 0 ? tripCardLedgerList : [],
      isOpen: isOpenCard,
      pay: () => {
        // rechargeCard();
        sattlePayment();
      },
    },
    {
      type: "Cash",
      totalExpenseExpected: TotalCashExpacted || 0,
      totalExpense: tripExpense?.totalCashExpense || 0,
      txns: tripCashLedgerCount > 0 ? tripCashLedgerList : [],
      open: () => {
        setIsOpenCash(!isOpenCash);
        if (!isOpenCash) {
          dispatch(
            getTripLedger(tripData?.tripId, {
              mode: "2",
            })
          );
        }
      },
      isOpen: isOpenCash,
      pay: () => {
        // toggleAddExpense();
        sattlePayment();
      },
    },
  ];

  const handleClose = () => {
    setModal(!modal);
    refreshList()
  };

  const txnListRender = (list) => {
    console.log("txnListRendertxnListRendertxnListRender", list);
    let columns = [];
    list.map((i, k) => {
      console.log(i, k);
      columns = [
        {
          name: "Transaction Date",
          selector: (i) => moment(i.createdAt).format("YYYY-MM-DD HH:mm"),
        },
        {
          name: "Amount",
          selector: (i) => i.amount,
        },
        {
          name: "Type",
          selector: (i) => i.expanseTag,
        },
        {
          name: "Description",
          selector: (i) => i.description,
        },
        // {
        //   name: "Status",
        //   cell: (i) => (
        //     <button
        //       type="button"
        //       // className="btn btn-success"
        //       style={{  }}
        //       // onClick={() => alert("The Trip is Completed")}
        //     >
        //       {i.invoiceId == null ? "UnInvoiced" : "Invoiced"}
        //     </button>
        //   ),
        // },
        // {
        //   name: "Trip",
        //   cell: (i) => (
        //     <button
        //       type="button"
        //       // className="btn btn-success"
        //       style={{  }}
        //       // onClick={() => alert("The Trip is Completed")}
        //     >
        //       {i.tripId == null ? "Unlinked" : "linked"}
        //     </button>
        //   ),
        // },
        {
          // name: "Edit",
          // cell: (i) => (
          //   <button
          //     type="button"
          //     className="btn btn-success"
          //     style={{}}
          //     onClick={() => {
          //       console.log("editLReditLReditLR", i);
          //       setFreightId(i.freightId);
          //       setIsEdit(true);
          //       editLR();
          //     }}
          //     disabled={i?.invoiceId != null}
          //   >
          //     {i?.invoiceId == null ? "" : "invoiced"}
          //   </button>
          // ),
        },
      ];
    });
    return (
      <div>
        <DataTable
          columns={columns}
          data={list}
          highlightOnHover
          onRowClicked={(row) => {}}
        />
      </div>
    );
  };
  let Earnings = 0;
  for (let index = 0; index < freightList?.length; index++) {
    Earnings = Earnings + freightList?.[index]?.freightAmount;
  }
  let Shortage = 0;
  for (let index = 0; index < freightList?.length; index++) {
    Shortage = Shortage + (freightList?.[index]?.shortage || 0);
  }
  let Penalties = 0;
  for (let index = 0; index < freightList?.length; index++) {
    Penalties = Penalties + freightList?.[index]?.charges;
  }
  let lastTrip = new Date(tripData?.expectedEndTime || new Date(null));
  let lastTrip6 = new Date(lastTrip.setHours(lastTrip.getHours() + 6));
  console.log("lastTrip6lastTrip6", tripData, lastTrip6);
  const FreightRender = (freight, i) => {
    console.log("freightRender", freight, i, freightNo);
    return (
      <div>
        <div style={{ marginTop: "2px" }}>
          {freightNo === i ? (
            <FreightUpdateRender
              freight={freight}
              view={view}
              tripId={tripData?.tripId}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width-2"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        scrollable={true}
        centered={true}
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "5px" }}
        >
          <div>
            <div className="">
              <div
                className="closeBtnWithBackgroundForTrip"
                onClick={handleClose}
              >
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="crossBtnWIthBackground"
                  width="20%"
                  height="20%"
                />
              </div>
              <div className="d-flex">
                <div className="PGCollectiontText col-9" style={{}}>
                  End Trip
                </div>
                <div className="PGCollectiontText col-3" style={{}}></div>
              </div>

              <hr style={{ border: "2px solid #ededf1" }}></hr>
            </div>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                gap: "50px",
              }}
            >
              {/* <ViewTripCard tripData={tripData} />
              <ViewExpanseCard
                tripData={tripData}
                tripExpense={{ ...tripExpense, Earnings, Shortage, Penalties }}
              /> */}
            </div>
            {openEndTripModal2 ? (
              <div style={{ paddingTop: "24px" }}>
                <Formik
                  // enableReinitialize
                  initialValues={{
                    endDate: tripData?.expectedEndTime || new Date(),
                    tripRemarks: "",
                    endKmReading:
                      parseInt(tripData?.startKmReading) +
                        parseInt(tripData?.expectedDistanceKM) || "",
                    // cardExpanse: tripExpense?.totalCardExpense || "0",
                    // fastagExpanse: tripExpense?.totalFastagExpense || "0",
                    // cashExpanse: tripExpense?.totalCashExpense || "0",
                    // fuelExpanse: tripExpense?.totalFuelExpense || "0",
                    // vendorExpanse: tripExpense?.totalVendorExpense || "0",
                  }}
                  onSubmit={(values, actions) => {
                    const payload = {
                      tripId: tripData?.tripId,
                      // userId: tripData?.userId,
                      // cardExpanse: values?.cardExpanse || "0.00",
                      // fastagExpanse: values?.fastagExpanse || "0.00",
                      // cashExpanse: values?.cashExpanse || "0.00",
                      // fuelExpanse: values?.fuelExpanse || "0.00",
                      endKmReading:
                        values.endKmReading || tripData?.endKmReading,
                      endTime: values.endDate || tripData?.expectedEndTime,
                      idleReason: values.tripRemarks,
                    };
                    dispatch(
                      endTrip(payload, (res) => {
                        console.log("adsfghaerstdfy4wetry", res?.data);
                        if (res?.status == "200") {
                          dispatch(
                            toastStatus({
                              toastStatus: true,
                              message: `Trip Ended successfully`,
                              status: true,
                            })
                          );
                        } else {
                          dispatch(
                            toastStatus({
                              toastStatus: true,
                              message:
                                res?.data?.message || `Error in End Trip`,
                              status: false,
                            })
                          );
                        }
                      })
                    );
                  }}
                >
                  {({
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    values,
                    touched,
                    isValid,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form
                      onSubmit={handleSubmit}
                      style={{ marginLeft: "10px" }}
                    >
                      {
                        console.log("tripDattripDattripDataaa",tripData)
                      }
                      {/* <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // gap: "40px",
                        }}
                      >
                        <FormGroup style={{ marginTop: "20px" }}>
                          <label className="heading3 semiBold col-12">
                            Fastag Expanse:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              readOnly={true}
                              type="text"
                              id="fastagExpanse"
                              className={`inputBoxCust1 ${
                                touched.fastagExpanse && errors.fastagExpanse
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="fastagExpanse"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "fastagExpanse",
                                  event.target.value,
                                  true
                                );
                              }}
                              value={values.fastagExpanse}
                              touched={touched.fastagExpanse}
                              errors={errors.fastagExpanse}
                              placeholder="Any Fastag Expanse"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>

                        <FormGroup style={{ marginTop: "20px" }}>
                          <label
                            for="cardExpanse"
                            className="heading3 semiBold col-12"
                          >
                            Card Expanse:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              readOnly={true}
                              type="text"
                              id="cardExpanse"
                              className={`inputBoxCust1 ${
                                touched.cardExpanse && errors.cardExpanse
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="cardExpanse"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "cardExpanse",
                                  event.target.value,
                                  true
                                );
                              }}
                              value={values.cardExpanse}
                              touched={touched.cardExpanse}
                              errors={errors.cardExpanse}
                              placeholder="Enter Card Expanse"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                          <label for="" className="heading3 semiBold col-12">
                            Cash Expanse:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              type="text"
                              readOnly={true}
                              id="cashExpanse"
                              className={`inputBoxCust1 ${
                                touched.cashExpanse && errors.cashExpanse
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="cashExpanse"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "cashExpanse",
                                  event.target.value,
                                  true
                                );
                              }}
                              value={values.cashExpanse}
                              touched={touched.cashExpanse}
                              errors={errors.cashExpanse}
                              placeholder="Enter Cash Expanse"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                          <label for="" className="heading3 semiBold col-12">
                            Fuel Expanse:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              type="text"
                              readOnly={true}
                              id="fuelExpanse"
                              className={`inputBoxCust1 ${
                                touched.fuelExpanse && errors.fuelExpanse
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="fuelExpanse"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "fuelExpanse",
                                  event.target.value,
                                  true
                                );
                              }}
                              value={values.fuelExpanse}
                              touched={touched.fuelExpanse}
                              errors={errors.fuelExpanse}
                              placeholder="Enter Fuel Expanse"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                          <label for="" className="heading3 semiBold col-12">
                            Vendor Expanse:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              type="text"
                              readOnly={true}
                              id="vendorExpanse"
                              className={`inputBoxCust1 ${
                                touched.vendorExpanse && errors.vendorExpanse
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="vendorExpanse"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "vendorExpanse",
                                  event.target.value,
                                  true
                                );
                              }}
                              value={values.vendorExpanse}
                              touched={touched.vendorExpanse}
                              errors={errors.vendorExpanse}
                              placeholder="Enter Vendor Expanse"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>
                      </FormGroup> */}
                      <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormGroup style={{ marginRight: "50px" }}>
                          <label className="heading3 semiBold">End Date</label>
                          <div
                            className="custNamepadd3 row"
                            style={{ width: "200px" }}
                          >
                            <div className="col-9">
                              <DatePickerField
                                classname="inputBoxCust1"
                                name="endDate"
                                showTimeInput={true}
                                header="Select End date"
                                value={values.endDate}
                                onChange={(v) => {
                                  console.log("v enddate", v);
                                  setFieldValue("endDate", v, true);
                                }}
                                //onChange={(v) => setFieldValue("endDate", v, true)}
                                minDate={
                                  values.startDate === ""
                                    ? ""
                                    : values.startDate
                                }
                                style={{ width: "100px" }}
                                ref={datepickerRefEnd}
                              />
                            </div>
                            <div className="col-3">
                              <label
                                className="date-input"
                                style={{
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleClickDatepickerIcon2();
                                }}
                              >
                                <SvgImage
                                  name="CalenderLogo"
                                  width="32"
                                  height="32"
                                  // classname="date-logo"
                                />
                              </label>
                            </div>
                          </div>
                          <div
                            className="heading4 Regular"
                            style={{ margin: "7px 0 0px 0", color: "red" }}
                          >
                            {errors.endDate && touched.endDate ? (
                              <div>*{errors.endDate}</div>
                            ) : null}
                          </div>
                        </FormGroup>
                        <FormGroup style={{ marginLeft: "50px" }}>
                          <label className="heading3 semiBold col-12">
                            End Km Reading:
                          </label>
                          <div className="custNamepadd3">
                            <input
                              type="number"
                              id="endKmReading"
                              className={`inputBoxCust1 ${
                                touched.endKmReading && errors.endKmReading
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="endKmReading"
                              onChange={(event) => {
                                // setSearch(event.target.value);
                                setFieldValue(
                                  "endKmReading",
                                  event.target.value,
                                  false
                                );
                              }}
                              value={values.endKmReading}
                              touched={touched.endKmReading}
                              errors={errors.endKmReading}
                              placeholder="Enter End Km Reading"
                              autoComplete={"off"}
                            />
                          </div>
                        </FormGroup>
                        {values.endDate > lastTrip6 ? (
                          <FormGroup style={{ marginLeft: "50px" }}>
                            <label className="heading3 semiBold col-12">
                              Trip Delayed Reason:
                            </label>
                            <div className="custNamepadd3">
                              <input
                                type="text"
                                id="tripRemarks"
                                className={`inputBoxCust1 ${
                                  touched.tripRemarks && errors.tripRemarks
                                    ? "is-invalid"
                                    : ""
                                }`}
                                style={{
                                  width: "400px",
                                }}
                                name="tripRemarks"
                                onChange={(event) => {
                                  // setSearch(event.target.value);
                                  setFieldValue(
                                    "tripRemarks",
                                    event.target.value,
                                    false
                                  );
                                }}
                                value={values.tripRemarks}
                                touched={touched.tripRemarks}
                                errors={errors.tripRemarks}
                                placeholder="Enter Trip Delayed Reason"
                                autoComplete={"off"}
                              />
                            </div>
                          </FormGroup>
                        ) : null}
                      </FormGroup>

                      <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="row">
                          <div
                            style={{
                              textAlign: "left",
                              display: openEndTripModal2 ? "block" : "none",
                              width: "40%",
                              marginRight: "18%",
                            }}
                          >
                            <button
                              // className="btn-edit-details"
                              // style={{ marginLeft: "20px" }}
                              style={{
                                width: "200px",
                                marginTop: "25px",
                              }}
                              className="generateOtp text3 Medium btn btn-secondary"
                              onClick={() => {
                                setOpenEndTripModal2(!openEndTripModal2);
                              }}
                            >
                              <text style={{ color: "white" }}>
                                {openEndTripModal2 ? "Previous" : "Next"}
                              </text>
                            </button>
                          </div>
                          <div style={{ textAlign: "right", width: "40%" }}>
                            <Button
                              type="submit"
                              style={{
                                width: "200px",
                                marginTop: "25px",
                              }}
                              className="generateOtp text3 Medium btn btn-secondary"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </div>
            ) : (
              <div>
                <h3 style={{ textAlign: "center" }}>Upload PODs</h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    textAlignLast: "center",
                    background: "#ededf1",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  <td style={{ width: "20%" }}>Lr No.</td>
                  <td style={{ width: "20%" }}>Source</td>
                  <td style={{ width: "20%" }}>Destination</td>
                  <td style={{ width: "25%" }}>Start Date</td>
                  <td style={{ width: "20%" }}>Amount</td>
                  <td style={{ width: "20%" }}>Weight</td>
                  <td style={{ width: "20%" }}>Pay By</td>
                  <td style={{ width: "20%" }}>{}</td>
                </div>
                {freightList.map((elem, i) => {
                  console.log("elelemeelemm", elem);
                  const payBy =
                    elem.customerId == elem.consignorId
                      ? elem.consignorName
                      : elem.customerId == elem.consigneeId
                      ? elem.consigneeName
                      : elem.transportorName;
                  return (
                    <div>
                      <Collapsible
                        trigger={
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: "50px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              textAlignLast: "center",
                              fontSize: "16px",
                            }}
                            onClick={() => {
                              setFreightNo(i);
                              setView(false);
                            }}
                          >
                            <td style={{ width: "20%" }}>
                              {elem?.lrNo}
                            </td>
                            <td style={{ width: "20%" }}>
                              {elem?.fromAddress?.cityName}
                            </td>
                            <td style={{ width: "20%" }}>
                              {elem?.toAddress?.cityName}
                            </td>
                            <td style={{ width: "25%" }}>{elem?.startDate}</td>
                            <td style={{ width: "20%" }}>
                              {elem?.freightAmount}
                            </td>
                            <td style={{ width: "20%" }}>{elem?.weight}</td>
                            <td style={{ width: "20%" }}>{payBy}</td>
                            <td style={{ width: "20%" }}>
                              {/* <button 
                        style={{width: "60px", height:"25px",borderRadius: "5px",backgroundColor: "#FFF",borderColor: "#2E7C7B",}}
                        onClick={() => {
                          console.log("onClickonClickonClick")
                          setIsOpen(!isOpen)}}
                      >
                      <text>View</text>
                      </button> */}
                              <button
                                style={{
                                  // width: "60px",
                                  // height: "25px",
                                  borderRadius: "5px",
                                  backgroundColor: "#FFF",
                                  borderColor: "#e9e9e9",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                                onClick={() => {
                                  setFreightNo(i);
                                  setView(false);
                                }}
                              >
                                <SvgImage
                                  name="AddAttachment"
                                  height="20"
                                  width="20"
                                />
                                <div style={{ marginLeft: "6px" }}>POD</div>
                              </button>
                            </td>
                          </div>
                        }
                        // triggerSibling="hskdg shkdg"
                        // onOpening={}
                      >
                        <div style={{ margin: "10px" }}>
                          {/* <FreightRender elem={elem} i={i} /> */}

                          <div style={{ marginTop: "2px" }}>
                            {freightNo === i ? (
                              <FreightUpdateRender
                                freight={elem}
                                view={view}
                                tripId={tripData?.tripId}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </Collapsible>
                    </div>
                  );
                })}
                <hr style={{ border: "2px solid #ededf1" }}></hr>
              </div>
            )}
            <div
              style={{
                textAlign: "center",
                display: !openEndTripModal2 ? "block" : "none",
              }}
            >
              <button
                // className="btn-edit-details"
                // style={{ marginLeft: "20px" }}
                style={{
                  width: "200px",
                  marginTop: "25px",
                }}
                className="generateOtp text3 Medium btn btn-secondary"
                onClick={() => {
                  setOpenEndTripModal2(!openEndTripModal2);
                }}
              >
                <text style={{ color: "white" }}>
                  {openEndTripModal2 ? "Previous" : "Next"}
                </text>
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {
        console.log("ppooppooppooppooppooppooppooppoopp",toastObject.toastStatus && location == "/trips/" )
      }
      {toastObject.toastStatus && location == "/trips/" ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          isOpen={toastObject?.toastStatus}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            handleClose();
            dispatch(
              getTripExpenseAction({ tripId: tripData?.tripId }, (res) => {
                console.log("getTripExpenseAction", res?.data?.data);
                if (res?.data == 200) {
                  storeDataToAsyncStorage("row", res?.data?.data?.tripData);
                  setItemTemp(res?.data?.data?.tripData);
                }
              })
            );
            setItemTemp();
            console.log("nmnnnnnnnnnmmmmppppppp", toastObject?.message);
          }}
          handleClose={()=>{
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            handleClose();
            dispatch(
              getTripExpenseAction({ tripId: tripData?.tripId }, (res) => {
                console.log("getTripExpenseAction", res?.data?.data);
                if (res?.data == 200) {
                  storeDataToAsyncStorage("row", res?.data?.data?.tripData);
                  setItemTemp(res?.data?.data?.tripData);
                }
              })
            );
            setItemTemp();
          }}
        />
      ) : (
        <></>
      )}
      <ManageDriverAddNewExpense
        userId={tripData?.userId}
        setDisplayName={"Add Expense"}
        displayName={"Add Expense"}
        open={openAddExpanse}
        toggleModal={toggleAddExpense}
        title="Logipe Account"
        handleClose={closeAddExpense}
        trip={true}
        itemTemp={tripData}
        color={"title-color2"}
      />
      <ManageDriverAddBalance
        open={open3}
        toggleModal={toggleModal3}
        remarks={`Paid for Trip ${tripData?.tripNo}`}
        title="Logipe Account"
        handleClose={handleClose3}
        color={"title-color2"}
        name="Add Balance"
        userId={tripData?.userId}
        cardType={tripData?.cardType}
        trip={true}
        driverName={tripData?.User?.fullName}
        itemTemp={tripData}
      />
      <RechargeFastagButttonModal
        open={open4}
        handleClose={handleClose4}
        toggleModal={toggleModal4}
        balance={fastagWalletAmount}
        no={tripData?.Fleet?.regNumber}
        fleetId={tripData?.fleetId}
        trip={true}
        itemTemp={tripData}
      />
      <OMCLoadModal
        userId={tripData?.userId}
        open={openFuel}
        toggleModal={toggleModalFuel}
        handleClose={handleCloseFuel}
        fleetId = {tripData?.fleetId}
        trip = {1}
      />
      <ChooseMethodModal
        tripData={tripData}
        open={openChooseMethod}
        toggleModal={toggleChooseModal}
        handleClose={handleChooseModal}
        handleCashPayment={addExpense}
        handleCardPayment={rechargeCard}
      />
    </div>
  );
}

export default EndTripModal;
