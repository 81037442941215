import {
    REDEEM_CASHBACK,
    REDEEM_CASHBACK_SUCCESS
  } from '../storeTypes';



export const redeemCashbackAction = (data,callback) => {
  console.log("action-> change pin ", data);
  return {
    type: REDEEM_CASHBACK,
    payload: data,
    callback
  };
};

export const redeemCashbackSuccessAction = (data) => {
  console.log("action-> change pin", data);
  return { type: REDEEM_CASHBACK_SUCCESS, payload: data };
};