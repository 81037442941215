import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductDetailAction } from "../../../../services/Store/Actions/ecomAction";
import SvgImage from "../../../Icons/Icons";
import "./index.css";

function DisplayLubricants(props){

    const {logimartProductList}= useSelector((state)=>state?.ecom);
    const { showModal, setProdId}= props;

    const dispatch= useDispatch();

    const loadProductDesc=(productId)=>{
        setProdId(productId);
        dispatch(getProductDetailAction(productId, (res)=>{
            if (res?.data?.type === "success") {}
            showModal();
        }))
    }

    return(
        <>
        {
            logimartProductList?.rows?.map((item, i)=>{
                let name = (item?.productName.length >27)?(item?.productName.substring(0,27)+"..."):(item?.productName);
                let mrp = item?.price;
                let sp = item?.price - item?.discountNumber;
                let image = item?.ProductImages[0]?.productUrl;
                let productId = item?.productId;
                let logiCoinsUsable= Math.floor(.025 * sp);
                return(
                <div className="col-lg-3 col-md-6 col-6 col-sm-6 logiMartItemContainer"  >
                    <div className="itemContainer linkTag" onClick={()=>loadProductDesc(productId)}>
                        <div className="imageDiv">
                            <img src={image} className="imgTyre" alt="shape-dot10" 
                            style={{height:"100%", alignItems:"centre"}}/>
                        </div>
                        <div className="infoDiv">
                            <div className="modelName">{name}</div>
                            <div className="row">
                                <div className="col-12 priceSP" >₹ {sp} <span className="priceMRP" >₹ {mrp}</span></div>
                        
                            </div>
                            {/* <div className="SaveWithCoins">
                                <div>Save upto ₹{logiCoinsUsable} using </div>
                                <div style={{paddingLeft:"0.3rem"}}>
                                    <SvgImage name="coins" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                )
            })
        }
        </>
    )
}

export default DisplayLubricants;