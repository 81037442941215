import React, { useState } from "react";
import { Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import PinInput from "react-pin-input";
import SvgImage from "../../Icons/Icons";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { bankDirectTransfer } from "../../../services/Store/Actions/beneficiaryAction";

import "./index.css";

function DirectBeneficiary(props) {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    ownerLogipeBalance,
    proceed,
    handleProceed,
  } = props;

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    amount: Yup.number().typeError("Only Numbers are allowed")
      .required("Required")
      .min(1, "Amount should be greater than 1"),
    pin: Yup.string()
      .required("Required")
      .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
  });

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-head-part">
          <p className="Add-balance-title">LogiPe Account</p>
          <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          <div className="card-wallet-icon-body1">
            <SvgImage
              name="BankLogo"
              height="43.81"
              width="43.81"
              classname="card-wallet-logo-BankLogo"
            />
            <div>
              <p className="body-sub-part1">Owner’s Master Account Balance</p>
              <p className="body-sub-part2">{"₹" + ownerLogipeBalance}</p>
            </div>
          </div>
          <div>
            <div className="sub-title-1">
              <span class="circle-logo">1</span>
              <p className="sub-head-3">Transfer Amount to</p>
            </div>
            <input
              type="button"
              className={
                btn === 0 ? "butt-1 btn-text" : "setting-btn-1 btn-text"
              }
              onClick={() => handleClick(0)}
              value="Card Wallet"
            />
            <input
              type="button"
              className={
                btn === 1 ? "setting-btn-2 btn-text" : "butt-2 btn-text"
              }
              onClick={() => handleClick(1)}
              value="Fastag Wallet"
            />
            <input
              type="button"
              className={
                btn === 2 ? "setting-btn-3 btn-text" : "butt-3 btn-text"
              }
              onClick={() => handleClick(2)}
              value="Bank A/C"
            />
          </div>

          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              fullName: "",
              acNo: "",
              bankAccountNumber: "",
              ifsc: "",
              amount: "",
              remarks: "any",
              pin: "",
            }}
            onSubmit={(values, actions) => {
              console.log("values onSubmit", values);
              dispatch(
                bankDirectTransfer(values, (res) => {
                  actions.setSubmitting(false);
                  if (res?.data?.type == "success") {
                    handleProceed(0);
                    toggleModal();
                    // alert("success");
                    window.location.reload();
                  }
                  // console.log('resresresres', res);
                })
              );
              // console.log("value +", driverUserId);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                {proceed === 0 ? (
                  <div>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">2</span>
                        <p className="sub-head-3">Bank Account Number</p>
                      </div>

                      <label for="html" className="label-body"></label>
                      <input
                        type="text"
                        id="acNo"
                        className="input-body-1"
                        name="acNo"
                        onChange={handleChange("acNo")}
                        value={values.acNo}
                        onBlur={handleBlur("acNo")}
                        touched={touched.acNo}
                        errors={errors.acNo}
                        placeholder="Enter account number"
                        maxlength="16"
                      ></input>
                      <div className="input-amount"></div>
                      {/* <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.acNo && touched.acNo ? (
                          <div>*{errors.acNo}</div>
                        ) : null}
                      </div> */}
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">3</span>
                        <p className="sub-head-3">
                          Re-enter Bank Account Number
                        </p>
                      </div>

                      <label for="html" className="label-body"></label>
                      <input
                        type="text"
                        id="bankAccountNumber"
                        className="input-body-1"
                        name="bankAccountNumber"
                        onChange={handleChange("bankAccountNumber")}
                        value={values.bankAccountNumber}
                        onBlur={handleBlur("bankAccountNumber")}
                        touched={touched.bankAccountNumber}
                        errors={errors.bankAccountNumber}
                        placeholder="Re-enter account number"
                        maxlength="16"
                      ></input>
                      <div className="input-amount"></div>
                      {/* <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.bankAccountNumber &&
                        touched.bankAccountNumber ? (
                          <div>*{errors.bankAccountNumber}</div>
                        ) : null}
                      </div> */}
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">4</span>
                        <p className="sub-head-3">Bank IFSC</p>
                      </div>

                      <label for="html" className="label-body"></label>
                      <input
                        type="text"
                        id="ifsc"
                        className="input-body-1"
                        name="ifsc"
                        onChange={handleChange("ifsc")}
                        value={values.ifsc}
                        onBlur={handleBlur("ifsc")}
                        touched={touched.ifsc}
                        errors={errors.ifsc}
                        placeholder="Enter Bank IFSC"
                      ></input>
                      <div className="input-amount"></div>
                      {/* <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.ifsc && touched.ifsc ? (
                          <div>*{errors.ifsc}</div>
                        ) : null}
                      </div> */}
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">5</span>
                        <p className="sub-head-3">Account Holder Name</p>
                      </div>

                      <label for="html" className="label-body"></label>
                      <input
                        type="text"
                        id="fullName"
                        className="input-body-1"
                        name="fullName"
                        onChange={handleChange("fullName")}
                        value={values.fullName}
                        onBlur={handleBlur("fullName")}
                        touched={touched.fullName}
                        errors={errors.fullName}
                        placeholder="Enter account holder name"
                      ></input>
                      <div className="input-amount"></div>
                      {/* <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.fullName && touched.fullName ? (
                          <div>*{errors.fullName}</div>
                        ) : null}
                      </div> */}
                      <button
                        className="butt4"
                        onClick={() => handleProceed(1)}
                      >
                        Proceed
                      </button>
                    </FormGroup>
                  </div>
                ) : (
                  //-----------------------------------------

                  <div>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">2</span>
                        <p className="sub-head-3">Enter amount</p>
                      </div>
                      <p className="sub-head-body">
                        Amount will be deducted from owner’s LogiPe account
                      </p>

                      <label for="html" className="label-body">
                        ₹
                      </label>
                      <input
                        type="text"
                        id="amount"
                        className="input-body-1"
                        name="amount"
                        onChange={handleChange("amount")}
                        value={values.amount}
                        onBlur={handleBlur("amount")}
                        touched={touched.amount}
                        errors={errors.amount}
                        placeholder="Enter amount"
                      ></input>
                      <div className="input-amount"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.amount && touched.amount ? (
                          <div>*{errors.amount}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">3</span>
                        <p className="sub-head-4">Enter 4-digit Security Pin</p>
                      </div>
                      <p className="sub-head-body">
                        Security pin is needed to confirm payment
                      </p>
                      <PinInput
                        length={4}
                        initialValue=""
                        secret
                        onChange={handleChange("pin")}
                        type="numeric"
                        value={values.pin}
                        onBlur={handleBlur("pin")}
                        touched={touched.pin}
                        errors={errors.pin}
                        inputMode="number"
                        style={{
                          padding: "10px",
                          marginLeft: "96px",
                          marginTop: "28px",
                        }}
                        inputStyle={{
                          border: "1px solid #A7A7A7",
                          borderRadius: "8px",
                          marginRight: "20px",
                        }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.pin && touched.pin ? (
                          <div>*{errors.pin}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <button type="submit" className="butt4">
                      Transfer To 
                      {/* {console.log('values h',values)} */}
                    </button>
                  </div>
                )}
                <div className="space-div"></div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default DirectBeneficiary;
