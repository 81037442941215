import React, { useEffect, useState } from "react";
import "./index.css";
import { clearAllDrivers, getAdvanceDriverList } from "../../../../services/Store/Actions/driverslistActions";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import { driverEditStatusAction } from "../../../../services/Store/Actions/appActions";
import ClipLoader from "react-spinners/ClipLoader";
import SearchBarV2 from "../../../subComponents/searchbarV2";

const DriverList = (props) => {
  const [search, setSearch] = useState("");
  const [rcolor, setRColor] = useState(-1);
  const [loader, setLoader] = useState("");
  const [activeStatus, setActiveStatus] = useState(1);
  const [assignedStatus, setAssignedStatus] = useState(3);
  const [offset, setOffset] = useState(0);
  
  useEffect(() => {
    dispatch(
      getAdvanceDriverList({
        offset: offset,
        search: search.trim(),
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: activeStatus,
        assignedStatus: assignedStatus,
      })
    );
  }, [search, activeStatus, assignedStatus]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allDriversAdvance, allDriverListAdvance, driverListLoader } =
    useSelector((state) => state?.driverlist);
  console.log(
    "asdsadsakhdsgfkwiweasdas",
    allDriverListAdvance,
    allDriversAdvance
  );
  console.log("asdsadsakhdsgfkwiwe");
  const handlePrevButton = () => {
    if (offset > 0) {
      setOffset(offset - 15);
      //console.log("offset", offset);
      dispatch(
        getAdvanceDriverList({
          offset: offset - 15,
          search: search.trim(),
          minKyc: "",
          sortAs: "",
          sortBy: "",
          activeStatus: activeStatus,
          assignedStatus: assignedStatus,
        })
      );
    }
    console.log("X--");
  };
  const handleNextButton = () => {
    if (allDriversAdvance?.count - offset > 15) {
      setOffset(offset + 15);
      dispatch(
        getAdvanceDriverList({
          offset: offset + 15,
          search: search.trim(),
          minKyc: "",
          sortAs: "",
          sortBy: "",
          activeStatus: activeStatus,
          assignedStatus: assignedStatus,
        })
      );
    }
    console.log("X++");
  };

  const handleClick = (e, i) => {
    console.log(
      "drivers ki list",
      `/DriversV2/${e.userId}?search=${search}&offset=${offset}`
    );
    navigate(
      `/DriversV2/${e.userId}?search=${search}&offset=${offset}`,
      {state: {driver: e}}
    );
  };

  let { id } = useParams();

  useEffect(() => {
    let urlId = window.location.pathname.split("/");

    if (urlId.length === 3) {
      if (urlId[2] != undefined && urlId[2] != "") {
        let i = allDriverListAdvance?.findIndex(
          (element) => element.userId === urlId[2]
        );

        setRColor(i);
      }
    }
  }, [id, allDriversAdvance]);

  return (
    
       <div>
          <div className="FUllListSearchBar" style={{marginTop: "-20px"}}>
            <SearchBarV2
              placeholder="Name, Phone, Vehicles"
              height="42"
              value={search}
              onChange={(event) => {
                dispatch(clearAllDrivers());
                setSearch(event.target.value);
              }}
            />
          </div>
          <div className="" style={{ marginTop: -16 }}>
            <button
              className={`Medium textDiv1 ${
                activeStatus == 3 && assignedStatus == 3
                  ? "greenButton"
                  : "greenOutlineButton"
              } rounded-pill ms-3`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setActiveStatus(3);
                setAssignedStatus(3);
              }}
            >
              All
            </button>
            <button
              className={`Medium textDiv1 ${
                activeStatus == 1 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setActiveStatus(1);
              }}
            >
              Active
            </button>
            <button
              className={`Medium textDiv1 ${
                activeStatus == 0 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setActiveStatus(0);
              }}
            >
              InActive
            </button>
            <button
              className={`Medium textDiv1 ${
                assignedStatus == 0 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setAssignedStatus(0);
              }}
            >
              Not Assigned
            </button>
            <button
              className={`Medium textDiv1 ${
                assignedStatus == 1 ? "greenButton" : "greenOutlineButton"
              } rounded-pill ms-2`}
              style={{
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                height: 30,
                marginTop: 16,
              }}
              onClick={(e) => {
                setAssignedStatus(1);
              }}
            >
              Assigned
            </button>
          </div>



    <div className="main-all-customer-list" style={{marginTop: "10px"}}>
      <div id="scrollableDivCustomer" className="div-table">
        <div className="div-table-row">
          {/* <div className="listHeaderCont">
            <div className="div-table-col width50 FontBold"> Driver Name</div>

            <div className="div-table-col width50 FontBold"> Total Balance</div>
          </div> */}
        </div>
        {driverListLoader ? (
          <div>
            <div
              className="text-center"
              style={{
                padding: 16,
              }}
            >
              <ClipLoader
                color={"#2E7C7B"}
                loading={true}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        ) : (
          <>
            {allDriverListAdvance?.map((data, i) => {
              console.log("advanceList",i,data);
              let color = " ";
              let accountType = Number(data?.AccountType);
              let bal = data?.walletBalance?.toFixed(2) || "N/A";
              let accountStatus = data?.walletAccountStatus;
              if (accountStatus < 3 || accountStatus > 4) {
                color = "#0d6efd";
              } else {
                color = "green";
              }
              console.log(bal,"accountType",accountType,"data?.walletAccountStatus",data?.walletAccountStatus );
              let totalBalVal =
                accountType === 0
                  ? "No card"
                  : accountType === 5
                    ? "Issue Card"
                  : data?.walletAccountStatus < 3
                  ? "No card"
                  : data?.walletAccountStatus == 3
                  ? "Card Not Linked"
                  : bal === null
                  ? "₹ 0"
                  : "₹ " + bal;

              const trimmedName = data.fullName.trim();

              const name = trimmedName.split(" ");
              const d = {
                firstName: name[0],
                lastName: name[name.length - 1],
              };
              const firstName_initials = d.firstName[0];
              const lastName_initials = d.lastName[0] + ".";
              return (
                <div
                  className={
                    rcolor === i
                      ? "div-table-row actve-button-chosen-for-driver"
                      : data?.isActive == 1
                      ? "div-table-row"
                      : "div-table-row disabledRow"
                  }
                  key={i}
                >
                  <div className="rowCont" onClick={() => handleClick(data, i)}>
                    <div className="div-table-col width60N FontNBList">
                      <div data-letters={firstName_initials.toUpperCase()}>
                        <span className=" FontNBList">
                          {d.firstName.length > 10
                            ? d.firstName.substring(0, 8) + ".."
                            : d.firstName}
                        </span>
                        <span className="FontNBList">
                          {" "}
                          {lastName_initials.toUpperCase()}
                        </span>
                      </div>
                      <div className="nameSubInfo2 FontSmall">
                        {data?.vrn === null ? "no vehicle" : data?.vrn}
                      </div>
                    </div>

                    <div
                      className={
                        totalBalVal == "Issue card"
                          ? "div-table-col width40N FontNBList"
                          : "div-table-col width40N FontNBList"
                      }
                    >
                      {accountType === 5 ? (
                        <>
                          <div
                            style={{
                              color: color,
                              paddingRight: "1rem",
                              width: "55%",
                              borderRadius: "4px",
                            }}
                            onClick={() => {
                              
                            }}
                            className={
                              totalBalVal == "Issue card"
                                ? "issueCardLink backFocus"
                                : ""
                            }
                          >
                            {totalBalVal}
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            color: color,
                            width: "55%",
                            borderRadius: "4px",
                          }}
                          onClick={() => {
                           
                          }}
                          className={
                            totalBalVal == "Issue card"
                              ? "issueCardLink  backFocus"
                              : ""
                          }
                        >
                          {totalBalVal}
                        </div>
                      )}
                    </div>
                    {/* <div className="div-table-col width15N">
                      {loader == data?.userId ? (
                        <div
                          className=""
                          style={{
                            // padding: 6,
                          }}
                        >
                          <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            // backgroundColor:
                            //   rcolor === i
                            //     ? "#f3f5fd"
                            //     : data?.isActive == 1
                            //     ? "white"
                            //     : "#F2F2F2",
                            border: 1,
                            padding: 6,
                          }}
                          onClick={() => {
                            setLoader(data?.userId);
                            dispatch(
                              driverEditStatusAction(
                                {
                                  userId: data?.userId,
                                  isActive: data?.isActive == 0 ? "1" : "0",
                                },
                                (res) => {
                                  setLoader("");
                                  if (res.status == 200) {
                                    dispatch(
                                      getAdvanceDriverList({
                                        offset: 0,
                                        search: search.trim(),
                                        minKyc: "",
                                        sortAs: "",
                                        sortBy: "",
                                        activeStatus: activeStatus,
                                        assignedStatus: assignedStatus,
                                      })
                                    );
                                  }
                                }
                              )
                            );
                          }}
                        >
                          {data?.isActive == 1 ? (
                            <SvgImage name="deleteIcon2" />
                          ) : (
                            <SvgImage name="restore" />
                          )}
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="nextRow ">
        <div className="nextRowCont ">
          <button
            className="PrevButton"
            onClick={handlePrevButton}
            style={{ opacity: offset > 0 ? "1" : "0.5" }}
          >
            <SvgImage name="PrevArrow" heigth="12px" width="12px" />
          </button>
          <button
            className="nextButton"
            onClick={handleNextButton}
            style={{
              opacity:
                allDriversAdvance?.count - offset > 15 ? "1" : "0.5",
            }}
          >
            <SvgImage name="NextArrow" heigth="12px" width="12px" />
          </button>
        </div>
      </div>
    </div>
    </div>

  );
};
export default DriverList;
