import React, { useEffect, useState } from "react";
import SvgImage from "../../../../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { sendOtp } from "../../../../services/Store/Actions/LoginAction";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { cleanOnboardingLoaderAction, downloadAadharXMLAction, getAadharOtpAction, updatePanAadharAction } from "../../../../services/Store/Actions/onboardingAction";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";


const KYCPart1=(props)=>{

  const {setPanCard, panCard,setRequestId, setAadhaarNo, setStatus, setRegStatus, aadhaarNo}= props;
    const validationSchema = Yup.object().shape({
        pan: Yup.string().required("Pan number is mandatory").matches(
          /^([0-9]){12}|([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
          'PAN number is incorrect. Please enter again',
      ),
        aadhaar: Yup.string()
        .required("Aadhaar number is mandatory").matches(/^([0-9]){12}$/, 'Aadhaar number must contain 12 digits'),
      });

    const { loader } = useSelector((state) => state?.onboarding);
    const dispatch = useDispatch();

    useEffect(()=>{
      let data = sessionStorage.getItem("_userInfo");
      let userSession= JSON.parse(data)
      if(userSession){
        setAadhaarNo(userSession?.user?.aadhar);
        setPanCard(userSession?.user?.pan);
      }
        dispatch(sendAnalitics("KYC_Onboarding_web"));
        dispatch(cleanOnboardingLoaderAction());
    },[])

    return (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            pan: panCard,
            aadhaar:aadhaarNo, 
          }}
          onSubmit={(values, actions) => {
            setStatus(1);
            setPanCard(values?.pan.trim());
            setAadhaarNo(values?.aadhaar.trim());
            //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
            console.log("valuessss", values);
            //dispatch(sendAnalitics("GetOTP_web", values));
            dispatch(
              (updatePanAadharAction(
                {
                  "pan": values?.pan.trim(),
                  "aadhar": values?.aadhaar.trim()
                },
                (res) => {
                  //actions.setSubmitting(false);
                  console.log("SUCCESS 1", res);
                  let data1 = sessionStorage.getItem("_user");
                  let data2 = sessionStorage.getItem("_userInfo");
                  if(data1){
                    let userSession= JSON.parse(data1);
                    let userObj={
                      "user":res?.data?.data,
                      "company":userSession?.company,
                      "refreshToken":userSession?.refreshToken,
                      "token":userSession?.token,
                      "owner":userSession?.owner
                    }
                    storeDataToAsyncStorage("_user", userObj);
                  }
                  if(data2){
                    let userSession= JSON.parse(data2);
                    let userObj={
                      "user":res?.data?.data,
                      "company":userSession?.company,
                      "refreshToken":userSession?.refreshToken,
                      "token":userSession?.token,
                      "owner":userSession?.owner
                    }
                    storeDataToAsyncStorage("_userInfo", userObj);
                  }
                  if (res?.data?.type === "success") {
                    console.log("SUCCESS aadhar and pan updated");
                    dispatch(getAadharOtpAction(values?.aadhaar, (res)=>{
                      console.log("SUCCESS RequestId", res);
                      if (res?.data?.type === "success") {
                        setRequestId(res?.data?.data?.requestId)
                        props.setStatus("1");
                      }
                    }))
                    // props.setPhone(values.phoneNumber);
                    
                  }
                }
              )
            ));
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit} className="InputForm2">
              <FormGroup className="formPosition">
                <div className="heading1 semiBold">Authorised signatory’s
                  <span className="skipText heading2" style={{marginLeft:"5rem"}}
                        onClick={()=>{
                          setRegStatus(5);
                        }}>Skip</span>
                </div>
                <div className="Regular text3 padding24T headText2">
                Authorised signatory's PAN number
                </div>
                <div className="padding8 padding0L">
                  <div className=" phoneNumberBox padding8">
                    {/* <div
                      className="col-2 padding0L boxWithBorder"
                      style={{ textAlign: "center" }}
                    >
                      <SvgImage name="countryCode" />
                    </div> */}
                    
                      <input
                        type="text"
                        id="pan"
                        className="input-body-4 Regular text3"
                        name="pan"
                        value={values.pan}
                        touched={touched.pan}
                        errors={errors.pan}
                        onBlur={()=>{
                        //   dispatch(sendAnalitics("EnterPhone_web", values));
                          handleBlur("pan");
                        }}
                        onChange={handleChange("pan")}
                        style={{
                          textAlign: "left",
                          marginLeft: "0px",
                          width: "100%",
                        }}
                        placeholder="Enter business name"
                        autofocus
                      ></input>
                   
                  </div>
                </div>
                <div
                  className="text4"
                  style={{
                    // margin: "7px 0 0px 50px",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {errors.pan && touched.pan ? (
                    <div>{errors.pan}</div>
                  ) : null}
                </div>
                <div className="Regular text3 padding8T headText2">
                Authorised signatory's aadhaar number
                </div>
                <div className="padding8 padding0L">
                  <div className=" phoneNumberBox padding8">
                    {/* <div
                      className="col-2 padding0L boxWithBorder"
                      style={{ textAlign: "center" }}
                    >
                      <SvgImage name="countryCode" />
                    </div> */}
                   
                      <input
                        type="text"
                        id="aadhaar"
                        className="input-body-4 Regular text3"
                        name="aadhaar"
                        value={values.aadhaar}
                        touched={touched.aadhaar}
                        errors={errors.aadhaar}
                        onBlur={()=>{
                        //   dispatch(sendAnalitics("EnterPhone_web", values));
                          handleBlur("aadhaar");
                        }}
                        onChange={handleChange("aadhaar")}
                        style={{
                          textAlign: "left",
                          marginLeft: "0px",
                          width: "100%",
                        }}
                        placeholder="Enter business type"
                        autofocus
                      ></input>
                  
                  </div>
                </div>
                <div
                  className="text4"
                  style={{
                    // margin: "7px 0 0px 50px",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {errors.aadhaar && touched.aadhaar ? (
                    <div>{errors.aadhaar}</div>
                  ) : null}
                </div>
                <Button
                  type="submit"
                  className="loginButton semiBold text2"
                  style={{
                    marginTop: "1rem",
                    // background:"#2e7c7b",
                    // color:"#ffffff"
                    background:
                      values.aadhaar==""
                        ? "#EFEFEF"
                      :values.pan==""
                      ? "#EFEFEF"
                        : loader
                        ? "#EFEFEF"
                        : "#2e7c7b",
                    color:
                        values.aadhaar==""
                          ? "#B5B5B5"
                        :values.pan==""
                        ? "#B5B5B5"
                        : loader
                        ? "#B5B5B5"
                        : "#ffffff",
                  }}
                  disabled={loader ? true : false}
                >
                  {loader ? "Please Wait" : "Submit"}
                  
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      );  

}

export default KYCPart1;