import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import { Button } from "reactstrap";
import SvgImage from "../../../../../Icons/Icons";



const NoTransaction=(props)=>{

    return(<div >
        <div className="centralEle " style={{padding:"4.5rem 0 4.5rem"}}>
            <div className="padding32T">
                <SvgImage name="noTransactionLogo" />
            </div>
            <div className="Medium heading3 padding16T greyFont">No transaction recorded! </div>
        </div>
    </div>)
}

export default NoTransaction;
