import React, { useEffect, useRef, useState } from "react";
import SvgImage from "../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import {
  sendAnalitics,
  storeMedia,
  toastStatus,
} from "../services/Store/Actions/appActions";
import { storeDataToAsyncStorage } from "../services/Utils/functions";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  createOrderFleetAction,
  idfcKycSaveImageAction,
  idfcKycSendOtpAction,
  idfcKycVerifyOtpAction,
} from "../services/Store/Actions/fastagAction";
import { io } from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
const DirectFastagKyc = (props) => {
  const validationSchema = Yup.object().shape({
    // vrn: Yup.string().required("Vrn is required"),
    // remarks: Yup.string().required("Remark is required"),
  });
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(0);
  const [uploadStatus1, setUploadStatus1] = useState(false);
  const [uploadStatus2, setUploadStatus2] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [displayName1, setDisplayName1] = useState("");
  const [displayName2, setDisplayName2] = useState("");
  const [otp, setOtp] = useState(false);
  const [sendOtpRespons, setSendOtpRespons] = useState({});
  const [verifyOtpRespons, setVerifyOtpRespons] = useState({});
  const [refrenceId, setRefrenceId] = useState(null);
  const [phone, setPhone] = useState(null);
  const { uploadType } = useSelector((state) => state?.app);
  let { fleetId } = useParams();
  const [vrn, setVrn] = useState(fleetId?.toUpperCase());

  // const { loader } = useSelector((state) => state?.loginReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  function callBackOtp(res) {
    console.log(res);
    setLoader(false);
    if (res?.status == "200") {
      setSendOtpRespons(res?.data?.data);
      setStatus(1);
    } else if (res?.status == "220") {
      setStatus(res?.data?.data?.status);
      setSendOtpRespons(res?.data?.data);
      setVerifyOtpRespons(res?.data?.data);
    }
  }
  function callBackOtpVerify(res) {
    console.log(res);
    setLoader(false);
    if (res?.status == "200") {
      setStatus(2);
      setVerifyOtpRespons(res?.data?.data);
    }
  }
  function callBackUploadDocument(res) {
    console.log(res);
    setLoader(false);
    if (res?.status == "200") {
      setStatus(3);
    }
  }
  // useEffect(() => {
  //   dispatch(sendAnalitics("IDFC_KYC_WEB"));

  // }, []);
  // const handleClickUpiApp = (item) => {

  //   setLoader2(true);
  // };

  return (
    <div className="Login-Screen_box">
      <div className="padding16">
        {/* <div className="row" id="loginDesktop">
          <div style={{ textAlign: "center", padding: "2.5rem" }}>
            <div className="Bold heading4">
              Please use Mobile For Transaction
            </div>
          </div>
        </div> */}

        <div className="h-full mb-5 mt-2">
          <div className="d-flex justify-content-between">
            {status == 1 || status == 2 ? (
              <div
                className="Medium text2 greenOutlineButton d-flex align-items-center"
                style={{
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (status != 0) {
                    setStatus(status - 1);
                  }
                }}
              >
                Back
              </div>
            ) : (
              <div></div>
            )}

            <div className="textLast">
              <SvgImage name="AppLogo" />
            </div>
          </div>

          {status < 1 ? (
            <>
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={{
                  amount: "",
                  vrn: vrn || "",
                }}
                innerRef={formRef}
                onSubmit={(values, actions) => {
                  console.log("submit form");
                  setLoader(true);
                  dispatch(
                    idfcKycSendOtpAction({ vrn: values?.vrn }, (res) => {
                      setLoader(false);
                      callBackOtp(res);
                    })
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form
                    onSubmit={handleSubmit}
                    style={{
                      // padding: 50,
                      paddingBottom: 20,
                    }}
                  >
                    <FormGroup className="mt-3">
                      <div className="Bold heading3">Hello sir,</div>
                      <div className="d-flex vrnHeading semiBold text-break flex-wrap align-items-center mb-3">
                        <div className="">Complete KYC for Vehicle</div>
                        <div className="Bold ms-2 " style={{}}>
                          {vrn}
                        </div>
                      </div>
                      {vrn ? null : (
                        <div>
                          <div className="Bold text-center">
                            Enter Vehicle Number
                          </div>
                          <div className="d-flex justify-content-center">
                            <div
                              className="phoneNumberBox padding8 mt-3 "
                              style={{ width: 200, textAlignLast: "center" }}
                            >
                              {/* <div
                        className="col-2 padding0L boxWithBorder"
                        style={{ textAlign: "center" }}
                      >
                        <SvgImage name="countryCode" />
                      </div> */}
                              <div
                                className="col-9 padding0L"
                                style={{ marginLeft: "1rem" }}
                              >
                                <input
                                  type="text"
                                  id="vrn"
                                  className="input-body-4 Regular text3"
                                  name="vrn"
                                  value={values.vrn}
                                  touched={touched.vrn}
                                  errors={errors.vrn}
                                  onBlur={() => {
                                    dispatch(
                                      sendAnalitics(
                                        "SearchVrnForKyc_web",
                                        values
                                      )
                                    );
                                    handleBlur("vrn");
                                  }}
                                  autoCapitalize="characters"
                                  onChange={handleChange("vrn")}
                                  style={{
                                    textAlign: "left",
                                    marginLeft: "0px",
                                    width: "150px",
                                  }}
                                  placeholder="Enter Vehicle Number"
                                  autofocus
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </FormGroup>
                    <Button
                      disabled={loader}
                      type="submit"
                      className="greenButton Verify-Pin-Btn"
                    >
                      Send OTP
                    </Button>
                  </Form>
                )}
              </Formik>

              {/* <SvgImage name={"ManageAppAndPortal"} /> */}
            </>
          ) : status == 1 ? (
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                otp: "",
                requestId: sendOtpRespons?.otpData?.requestId || "",
                txnNo: sendOtpRespons?.otpData?.txnNo || "",
                txnTime: sendOtpRespons?.otpData?.txnTime || "",
                vrn: sendOtpRespons?.tagData?.vrn || "",
                custId: sendOtpRespons?.tagData?.custId || "",
                phone: sendOtpRespons?.tagData?.phone || "",
              }}
              // innerRef={formRef}
              onSubmit={(values, actions) => {
                const payload = {
                  requestId: values?.requestId,
                  txnNo: values?.txnNo,
                  otp: values?.otp,
                  txnTime: values?.txnTime,
                  vrn: values?.txnTime,
                  custId: values?.custId,
                  phone: values?.phone,
                };
                setLoader(true);
                dispatch(
                  idfcKycVerifyOtpAction(payload, (res) => {
                    setLoader(false);
                    callBackOtpVerify(res);
                  })
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{
                    // padding: 50,
                    paddingBottom: 20,
                  }}
                >
                  <FormGroup className="mt-3">
                    <div className="Bold heading3">Hello sir,</div>
                    <div className="d-flex vrnHeading semiBold text-break flex-wrap align-items-center mb-3">
                      <div className="">Enter OTP sent on</div>
                      <div className="Bold ms-2 " style={{}}>
                        {values?.phone}
                      </div>
                    </div>

                    <div>
                      <div className="Bold text-center">Enter OTP</div>
                      <div className="d-flex justify-content-center">
                        <div
                          className="phoneNumberBox padding8 mt-3 "
                          style={{ width: 200, textAlignLast: "center" }}
                        >
                          {/* <div
                        className="col-2 padding0L boxWithBorder"
                        style={{ textAlign: "center" }}
                      >
                        <SvgImage name="countryCode" />
                      </div> */}
                          <div
                            className="col-9 padding0L"
                            style={{ marginLeft: "1rem" }}
                          >
                            <input
                              type="text"
                              id="otp"
                              className="input-body-4 Regular text3"
                              name="otp"
                              value={values.otp}
                              touched={touched.otp}
                              errors={errors.otp}
                              onBlur={() => {
                                dispatch(
                                  sendAnalitics("SearchotpForKyc_web", values)
                                );
                                handleBlur("otp");
                              }}
                              autoCapitalize="characters"
                              onChange={handleChange("otp")}
                              style={{
                                textAlign: "left",
                                marginLeft: "0px",
                                width: "150px",
                              }}
                              placeholder="Enter OTP"
                              autofocus
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <Button
                    disabled={loader}
                    type="submit"
                    className="greenButton Verify-Pin-Btn"
                  >
                    Verify OTP
                  </Button>
                </Form>
              )}
            </Formik>
          ) : status == 2 ? (
            <>
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={{
                  refrenceId: refrenceId || "",
                  vehicleWithVrn: "",
                  vehicleWithFastag: "",
                  requestId: sendOtpRespons?.otpData?.requestId || "",
                  txnNo: sendOtpRespons?.otpData?.txnNo || "",
                  txnTime: sendOtpRespons?.otpData?.txnTime || "",
                  vrn: sendOtpRespons?.tagData?.vrn || "",
                  custId: sendOtpRespons?.tagData?.custId || "",
                  phone: sendOtpRespons?.tagData?.phone || "",
                }}
                // innerRef={formRef}
                onSubmit={(values, actions) => {
                  console.log("otp form submit", sendOtpRespons);
                  const payload = {
                    requestId: values?.requestId,
                    txnNo: values?.txnNo,
                    txnTime: values?.txnTime,
                    vrn: values?.vrn,
                    custId: values?.custId,
                    phone: values?.phone,
                    image1: values?.vehicleWithVrn,
                    image2: values?.vehicleWithFastag,
                  };
                  setLoader(true);
                  dispatch(
                    idfcKycSaveImageAction(payload, (res) => {
                      setLoader(false);
                      callBackUploadDocument(res);
                    })
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form
                    onSubmit={handleSubmit}
                    style={{
                      // padding: 50,
                      paddingBottom: 20,
                    }}
                  >
                    <FormGroup className="mt-3">
                      <div className="Bold heading3">Hello sir,</div>
                      <div className="d-flex vrnHeading semiBold text-break flex-wrap align-items-center mb-3">
                        <div className="">
                          Upload Your Documents For Complete Your Vehicle Kyc
                        </div>
                      </div>

                      <div style={{}}>
                        <div className="Bold " style={{ marginBottom: "12px" }}>
                          Upload Vehicle Image With Vehicle Number
                        </div>
                        {!uploadStatus1 ? (
                          <Button
                            className="add-attach-for-fastag-linked-vehicle"
                            type="submit"
                            style={{ top: "0" }}
                            // disabled={Formik.setSubmitting}
                          >
                            {imageLoader && uploadType == "vehicleWithVrn" ? (
                              <div style={{ zIndex: 10 }}>
                                <ClipLoader
                                  color={"#485B7C"}
                                  loading={true}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </div>
                            ) : (
                              <div>
                                <SvgImage
                                  name="AddAttachment"
                                  height="36"
                                  width="36"
                                />

                                <input
                                  type="file"
                                  accept="image/*"
                                  // multiple
                                  // accept="image/*"
                                  className="add-attachment-btn"
                                  onChange={(e) => {
                                    const img = e?.target.files[0];
                                    console.log("image", img?.name);
                                    if (img == null) {
                                      //clear image
                                      setFieldValue("vehicleWithVrn", "", true);
                                      //for now  only one supported
                                    } else {
                                      setImageLoader(true);
                                      dispatch(
                                        storeMedia(
                                          {
                                            img: img,
                                            uploadType: "vehicleWithVrn",
                                            isRes: false,
                                            token: verifyOtpRespons?.token,
                                          },
                                          (res) => {
                                            setImageLoader(false);
                                            if (res?.url) {
                                              setFieldValue(
                                                "vehicleWithVrn",
                                                res?.data?.Location,
                                                true
                                              );
                                              setDisplayName1(img?.name);
                                              setUploadStatus1(true);
                                            }
                                          }
                                        )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}

                            <div
                              style={{
                                margin: "7px 0 0px 5px",
                                color: "red",
                              }}
                            >
                              {errors.vehicleWithVrn &&
                              touched.vehicleWithVrn ? (
                                <div>*{errors.vehicleWithVrn}</div>
                              ) : null}
                            </div>
                          </Button>
                        ) : displayName1 ? (
                          <div className="row">
                            <div
                              className="col-10 heading4 Regular"
                              //   style={{ paddingTop: "0.3rem" }}
                            >
                              <p
                                style={{
                                  margin: "0 0 0 0.5rem",
                                  color: "black",
                                }}
                              >
                                {displayName1}
                              </p>
                            </div>
                            <div
                              className="col-2"
                              onClick={(e) => {
                                setUploadStatus1(false);
                                setDisplayName1("");
                                // e.preventDefault();
                              }}
                              style={{ paddingBottom: "0.5rem" }}
                            >
                              <SvgImage name="CloseButtonLogo" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div style={{ marginTop: "12px" }}>
                        <div className="Bold " style={{ marginBottom: "12px" }}>
                          Upload Vehicle Image With Vehicle Number
                        </div>
                        {!uploadStatus2 ? (
                          <Button
                            className="add-attach-for-fastag-linked-vehicle"
                            type="submit"
                            style={{ top: "0" }}
                            // disabled={Formik.setSubmitting}
                          >
                            {imageLoader &&
                            uploadType == "vehicleWithFastag" ? (
                              <div style={{ zIndex: 10 }}>
                                <ClipLoader
                                  color={"#485B7C"}
                                  loading={true}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </div>
                            ) : (
                              <div>
                                <SvgImage
                                  name="AddAttachment"
                                  height="36"
                                  width="36"
                                />

                                <input
                                  type="file"
                                  accept="image/*"
                                  // multiple
                                  // accept="image/*"
                                  className="add-attachment-btn"
                                  onChange={(e) => {
                                    const img = e?.target.files[0];
                                    console.log("image", img?.name);
                                    if (img == null) {
                                      //clear image
                                      setFieldValue(
                                        "vehicleWithFastag",
                                        "",
                                        true
                                      );
                                      //for now  only one supported
                                    } else {
                                      setImageLoader(true);
                                      dispatch(
                                        storeMedia(
                                          {
                                            img: img,
                                            uploadType: "vehicleWithFastag",
                                            isRes: false,
                                            token: verifyOtpRespons?.token,
                                          },
                                          (res) => {
                                            setImageLoader(false);
                                            if (res?.url) {
                                              setFieldValue(
                                                "vehicleWithFastag",
                                                res?.data?.Location,
                                                true
                                              );
                                              setDisplayName2(img?.name);
                                              setUploadStatus2(true);
                                            }
                                          }
                                        )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}

                            <div
                              style={{
                                margin: "7px 0 0px 5px",
                                color: "red",
                              }}
                            >
                              {errors.vehicleWithFastag &&
                              touched.vehicleWithFastag ? (
                                <div>*{errors.vehicleWithFastag}</div>
                              ) : null}
                            </div>
                          </Button>
                        ) : displayName2 ? (
                          <div className="row">
                            <div
                              className="col-10 heading4 Regular"
                              //   style={{ paddingTop: "0.3rem" }}
                            >
                              <p
                                style={{
                                  margin: "0 0 0 0.5rem",
                                  color: "black",
                                }}
                              >
                                {displayName2}
                              </p>
                            </div>
                            <div
                              className="col-2"
                              onClick={(e) => {
                                setUploadStatus2(false);
                                setDisplayName2("");
                                // e.preventDefault();
                              }}
                              style={{ paddingBottom: "0.5rem" }}
                            >
                              <SvgImage name="CloseButtonLogo" />
                            </div>
                          </div>
                        ) : null}
                        <div className="padding16B padding8L padding16T">
                          Upload format must be in jpg/png/ jpeg
                        </div>
                      </div>
                    </FormGroup>
                    <Button
                      disabled={loader}
                      type="submit"
                      className="greenButton Verify-Pin-Btn"
                    >
                      Update Kyc
                    </Button>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <div className="h-full mb-5 mt-2">
              <div className="text-center my-5">
                <SvgImage name="successLogo" />
                <div
                  className="SuccessText"
                  style={{
                    margin: 20,
                  }}
                >
                  Success!
                </div>
                <div className="mx-5">
                  Kyc Updated Successfully for Vehicle {vrn}
                </div>
              </div>
              <div className="greenBg ">
                <div>
                  {/* <SvgImage name={"PhoneAndLP"} /> */}
                  <div className="textClassRecharge my-3 mb-5">
                    Recharge and check you Fastag balance easily on Logipe App
                  </div>
                  <SvgImage name="playStoreLogin" />
                  <div className="textClassRecharge mt-5">
                    Easily Manage your Driver and fleet expenses with Logipe
                    Starting today
                  </div>
                </div>
              </div>
            </div>
          )}
          {status <= 2 ? (
            <div className="greenBg ">
              <div>
                <SvgImage name={"PhoneAndLP"} />
                <div className="textClassRecharge my-3">
                  Manage Drivers and Vehicle expenses with an easy-to-use
                  dashboard and App
                </div>
                <SvgImage name="playStoreLogin" />
                <div className="text2Class mt-4">
                  Try web version for enhanced experience
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default DirectFastagKyc;
