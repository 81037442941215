// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { useParams, useLocation, useNavigate } from "react-router-dom";
// import { BallTriangle } from "react-loader-spinner";
// import DataTable from "react-data-table-component";
// import { Button } from "reactstrap";
// import { getTripsNew } from "../../../../services/Store/Actions/TripsAction";
// import "./index.css";
// import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";
// import DriverDashboardAddExpense from "../../../Modals/DriverDashboardAddExpense";
// import DriverAddExpense from "../../../Modals/DriverAddExpense";

// const TripTable = (props) => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   let { id } = useParams();
//   let { tripsCount, tripsListNew } = useSelector((store) => store?.trip);
//   const [addExpense, setAddExpense] = useState(false);
//   const [addSingleExpense, setAddSingleExpense] = useState(false);
//   const [tripData, setTripData] = useState("");

//   const closeAddExpense = () => {
//     setAddExpense(false);
//   };

//   const toggleAddExpense = () => {
//     setAddExpense(!addExpense);
//   };

//   const showAddExpense = (i) => {
//     setTripData(i)
//     setAddExpense(true);
//   };

//   const closeAddSingleExpense = () => {
//     setAddSingleExpense(false);
//   };

//   const toggleAddSingleExpense = () => {
//     setAddSingleExpense(!addSingleExpense);
//   };

//   const showAddSingleExpense = (i) => {
//     setTripData(i)
//     setAddSingleExpense(true);
//   };
//   console.log("dasdad", tripsListNew);
//   // const { sideBar, setItem, item } = props;

//   useEffect(() => {
//     if (props?.selected === 1)
//       dispatch(
//         getTripsNew({
//           offset: 0,
//           limit: 10,
//           driverId: id,
//           // startTime: moment(startDate).format("MM/DD/YYYY"),
//           // endTime: moment(endDate).format("MM/DD/YYYY"),
//           // search: search,
//           // status: status,
//         })
//       );
//   }, []);
//   const onSelect = (row) => {
//     storeDataToAsyncStorage("row", row);
//     // setItem(row);
//     navigate(`/trips/${row.tripId}`);
//     console.log("dsadasd", row);

//     // setItem(row);
//     console.log("thissssssssssssss", row);
//   };

//   console.log("rtripsListNewtripsListNewtripsListNewr", tripsListNew);
//   let columns;
//   {
//     tripsListNew.map((i, k) => {
//       columns = [
//         {
//           name: "Trip No",
//           selector: (i) => i.tripNo,
//         },
//         // {
//         //     name: "Vehicle No",
//         //     selector: (i) => i?.Fleet?.regNumber,
//         // },
//         {
//           name: "From",
//           selector: (i) =>
//             i.source.length == 36
//               ? i.sourceAddress?.cityName + ", " + i.sourceAddress?.stateName
//               : i.source,
//         },
//         {
//           name: "Pending POD",
//           width: "150px",
//           cell: (i) => (
//             <div>
//               <span>{i?.pendingPOD ? i?.pendingPOD : "2"}</span>
//               <Button
//                 type="button"
//                 className="Button3"
//                 style={{ width: "76px" }}
//                 onClick={() => {
//                   console.log("editLReditLReditLR", i);
//                 }}
//               >
//                 Submit
//               </Button>
//             </div>
//           ),
//         },
//         {
//           name: "Total Amount",
//           selector: (i) => i.TripExpense.driverTotal,
//         },
//         {
//           name: "Paid",
//           selector: (i) => i.TripExpense.totalDriverExpense,
//         },
//         {
//           name: "Status",
//           selector: (i) =>
//             i?.isActive && new Date(i?.startDate) <= new Date()
//               ? "Running"
//               : i?.isActive && new Date(i?.startDate) > new Date()
//               ? "Not Started"
//               : "Ended",
//         },
//         {
//           name: "",
//           width: "100px",
//           cell: (i) => (
//             <Button
//               type="button"
//               className="Button3"
//               style={{}}
//               onClick={() => {
//                 console.log("editLReditLReditLR", i);
//                 showAddExpense(i)
//               }}
//               // disabled={i?.isActive}
//             >
//               {"Pay"}
//             </Button>
//           ),
//         },
//         {
//           name: "",
//           width: "130px",
//           cell: (i) => (
//             <Button
//               type="button"
//               className="Button1"
//               style={{}}
//               onClick={() => {
//                 console.log("editLReditLReditLR", i);
//                 showAddSingleExpense(i)
//               }}
//               // disabled={i?.isActive}
//             >
//               {"Add Entry"}
//             </Button>
//           ),
//         },
//         {
//           name: "",
//           width: "120px",
//           cell: (i) => (
//             <Button
//               type="button"
//               className="Button3"
//               style={{}}
//               onClick={() => {
//                 console.log("editLReditLReditLR", i);
//               }}
//               // disabled={i?.isActive}
//             >
//               {"Settle"}
//             </Button>
//           ),
//         },
//       ];
//     });
//   }
//   return (
//     <div>
//       <div></div>
//       {console.log("tripsCount", tripsCount)}
//       {tripsCount == undefined ||
//       (tripsCount > 0 && tripsListNew?.length == 0) ? (
//         <div
//           className="mt-2 boxContainer"
//           style={{
//             padding: 70,
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <BallTriangle
//             height={70}
//             width={70}
//             radius={5}
//             color="#2e7c7b"
//             ariaLabel="ball-triangle-loading"
//             wrapperClass={{}}
//             wrapperStyle=""
//             visible={true}
//           />
//         </div>
//       ) : (
//         <DataTable
//           columns={columns}
//           data={tripsListNew}
//           highlightOnHover
//           // pagination
//           onRowClicked={(row) => {
//             onSelect(row);
//           }}
//         />
//       )}
//       <DriverDashboardAddExpense
//         open={addExpense}
//         tripId={tripData?.tripId}
//         toggleModal={toggleAddExpense}
//         handleClose={closeAddExpense}
//         name="Add Balance"
//         userId={id}
//         driverBal={"SCDa"}
//         cardType={1}
//         driverName={props.driverName}
//       />
//       <DriverAddExpense
//         open={addSingleExpense}
//         tripId={tripData?.tripId}
//         toggleModal={toggleAddSingleExpense}
//         handleClose={closeAddSingleExpense}
//         name="Add Balance"
//         userId={id}
//         driverBal={"SCDa"}
//         cardType={1}
//         driverName={props.driverName}
//       />
//     </div>
//   );
// };
// export default TripTable;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { useParams, useNavigate } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import { AiOutlinePlus } from "react-icons/ai";
import { Button, Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import { DatePickerField } from "../../../core/inputs/input";
import {
  getTripsNew,
  clearTripList,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import DriverDashboardAddExpense from "../../../Modals/DriverDashboardAddExpense";
import DriverAddExpense from "../../../Modals/DriverAddExpense";
import ManageDriverAddBalance from "../../../Modals/ManageDriverAddBalance";
import DriverSettleModal from "../../Modals/DriverSettleModal";
import AddPodModal from "../../../TripsDetailsV2/Components/AddPod";
import AddPod from "../../../TripsDetailsV2/Components/AddPod";
import { Default, Roller } from "react-awesome-spinners";
import {
  removeItemFromAsyncStorage,
  storeDataToAsyncStorage,
} from "../../../../services/Utils/functions";

const listing = ["Pay", "Expense", "Disbursement", "Details", "Settle"];
function TripTable(props) {
  let { customerId, setTripNos, activeTab, customer, driverName } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [offset, setOffset] = useState(0);
  const [color, setColor] = useState(-1);
  const [vehicleNo, setVehicleNo] = useState("");
  const [isCheckAllTrips, setIsCheckAllTrips] = useState(false);
  const [displayAction, setDisplayAction] = useState(-1);
  const [openAction, setOpenAction] = useState(false);
  const [method, setMethod] = useState(false);
  const [isCheckTrips, setIsCheckTrips] = useState({});
  const [isCheckTripsLength, setIsCheckTripsLength] = useState(0);
  const [open5, setOpen5] = useState(false);
  const [itemTemp, setItemTemp] = useState({});
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [search, setSearch] = useState("");
  const [tripType, setTripType] = useState("");
  const [open2, setOpen2] = useState(false); // driver settle
  const [editFreight, setEditFreight] = useState(false);
  const [vrn, setVrn] = useState("");
  const [filter, setFilter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [transferType, setTransferType] = useState(0); //  1 - expense 2 - disbursement
  const [showCard, setshowCard] = useState(false); //  1 - expense 2 - disbursement
  const [settleAmount, setSettleAmount] = useState(false);
  let { tripsCount, tripsListNew, loader } = useSelector(
    (store) => store?.trip
  );
  const [addExpense, setAddExpense] = useState(false);
  const [addSingleExpense, setAddSingleExpense] = useState(false);
  const [tripData, setTripData] = useState("");
  const [open, setOpen] = useState(false); //For pay driver
  const [pod, addPod] = useState(false); //For pay driver

  console.log("loaderloaderloader", loader);
  const closeAddExpense = () => {
    setTransferType(0);
    setshowCard(false);
    setSettleAmount(false);
    setAddExpense(false);
  };

  const toggleAddExpense = () => {
    setTransferType(0);
    setshowCard(false);
    setSettleAmount(false);
    setAddExpense(!addExpense);
  };

  const showAddExpense = (i, val) => {
    setTransferType(val);
    setTripData(i);
    setAddExpense(true);
  };

  const closeAddSingleExpense = () => {
    setAddSingleExpense(false);
  };

  const toggleAddSingleExpense = () => {
    setAddSingleExpense(!addSingleExpense);
  };

  const showAddSingleExpense = (i) => {
    setTripData(i);
    setAddSingleExpense(true);
  };
  // console.log("FASTagTableFASTagTableFASTagTableFASTagTable",id, fastagTransactionHistory);
  const openActions = (i) => {
    if (i == displayAction) setDisplayAction(-1);
    else setDisplayAction(i);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const toggleModal2 = () => {
    setOpen2(!open2);
  };

  const showModal2 = () => {
    setOpen2(true);
  };

  const handlePodClose = () => {
    dispatch(clearTripList());
    dispatch(
      getTripsNew({
        offset: offset,
        limit: 10,
        driverId: id,
        isActive:
          tripType == "0"
            ? 2
            : tripType == "1"
            ? 3
            : tripType == "2"
            ? 1
            : tripType == "3"
            ? 5
            : tripType == "4"
            ? 9
            : "",
        startTime: from,
        endTime: to,
        // startTime: moment(startDate).format("MM/DD/YYYY"),
        // endTime: moment(endDate).format("MM/DD/YYYY"),
        // search: search,
        // status: status,
      })
    );
    addPod(false);
  };

  const togglePodModal = () => {
    addPod(!pod);
  };

  const showPodModal = (i) => {
    console.log("pppppppppp");
    setTripData(i);
    addPod(true);
  };

  const handleSelection = (i, item, trip) => {
    console.log("kjjjjjjjjj", i, item, trip);
    if (i == 0) {
      setTripData(trip);
      showModal(true);
    }
    if (i == 1) {
      setTripData(trip);
      showAddExpense(trip, 1);
    }
    if (i == 2) {
      setTripData(trip);
      showAddExpense(trip, 2);
    }
    if (i == 3) {
      storeDataToAsyncStorage("row", trip);
      // setItem(row);
      navigate(`/trips/${trip.tripId}`);
      console.log("dsadasddsadasddsadasddsadasd", trip);

      // setItem(row);
      console.log("thissssssssssssss", trip);
      navigate(`/trips/${trip?.tripId}`);
    }
    if (i == 4) {
      setTripData(trip);
      setshowCard(true);
      setSettleAmount(
        (parseFloat(trip?.TripExpense?.driverTotal) -
          parseFloat(trip?.TripExpense?.totalDriverExpense) <
        0
          ? "0"
          : parseFloat(trip?.TripExpense?.driverTotal) -
            parseFloat(trip?.TripExpense?.totalDriverExpense)) || 0
      );
      showAddExpense(trip, 2);
    }
    setDisplayAction(-1);
  };
  const txnCount = tripsCount;
  const txnRows = tripsListNew;
  console.log(
    "ffdfdfdfdfd",
    txnCount,
    txnRows,
    txnCount == undefined,
    txnCount == 0,
    txnCount > 0 && txnRows?.length == 0
  );
  const pageCount =
    txnCount % 10 === 0 ? txnCount / 10 : Math.floor(txnCount / 10) + 1;

  const handlePageClick = (event) => {
    console.log("Clickess", event, pageCount);
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };
  // const tripData = [];
  let { id } = useParams();
  const fetchUsers = async (page) => {
    console.log("kljkkkk", page.selected);
    setOffset(page.selected * 10);
    setLoading(true);
    dispatch(
      getTripsNew(
        {
          offset: page.selected * 10,
          limit: 10,
          driverId: id,
          isActive:
            tripType == "0"
              ? 2
              : tripType == "1"
              ? 3
              : tripType == "2"
              ? 1
              : tripType == "3"
              ? 5
              : tripType == "4"
              ? 9
              : "",
          startTime: from,
          endTime: to,
        },
        () => {
          setLoading(false);
        }
      )
    );
    setLoading(false);
  };
  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  useEffect(() => {
    console.log("kkklllll", offset);
    if (id !== "" && id !== undefined && id !== null) {
      setOffset(0);
      dispatch(clearTripList());
      dispatch(
        getTripsNew({
          offset: 0,
          limit: 10,
          driverId: id,
          isActive:
            tripType == "0"
              ? 2
              : tripType == "1"
              ? 3
              : tripType == "2"
              ? 1
              : tripType == "3"
              ? 5
              : tripType == "4"
              ? 9
              : "",
          startTime: from,
          endTime: to,
          // startTime: moment(startDate).format("MM/DD/YYYY"),
          // endTime: moment(endDate).format("MM/DD/YYYY"),
          // search: search,
          // status: status,
        })
      );
      setLoading(true);
    } else {
      setOffset(0);
      dispatch(clearTripList());
    }
  }, [from, to, tripType]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const onSelect = (row) => {
    // storeDataToAsyncStorage("row", row);
    // navigate(`/trips/${row.tripId}`);
    // console.log("dsadasd", row);
    // // setItem(row);
    // console.log("thissssssssssssss", row);
  };
  return (
    <div className="box2">
      <div className="box3 d-flex">
        {/* <div className="ms-4">
          <div className="headingText">Date Range:</div>
          <input
            type="text"
            className="text-input-placeholder mt-2"
            placeholder={"Enter Date Range"}
            onChange={() => {}}
            value={""}
            // onkeypress={setStorage}
          />
        </div> */}
        <div className="row">
          <div className="headingText col-12">Date Range:</div>
          <div className="col-12 row mt-2">
            <div className="col-5 row">
              {/* <input
                type="date"
                className="text-input-placeholder mt-2"
                placeholder={"Select Date"}
                onChange={(event) => {
                  setFrom(event.target.value);
                }}
                style={{width: "200px"}}
                max={to}
                value={from}
                // onkeypress={setStorage}
              /> */}
              <div className="col-9">
                <DatePickerField
                  classname="searchBar-input1"
                  name="startDate"
                  header="From"
                  value={from}
                  onChange={(v) => {
                    setFrom(v);
                  }}
                  //onChange={(v) => setFieldValue("endDate", v, true)}
                  // maxDate={
                  //   values.endDate === "" ? "" : values.endDate
                  // }
                  // maxDate={new Date()}
                  // ref1={datepickerRefEnd}
                  style={{ width: "100px" }}
                />
              </div>
              <div className="col-3" style={{ margin: "0 0 0 -12px" }}>
                <label
                  className="date-input"
                  for="startDate"
                  style={{
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    //   handleClickDatepickerIcon2();
                  }}
                >
                  <SvgImage
                    name="CalenderLogo"
                    width="32"
                    height="32"
                    // classname="date-logo"
                  />
                </label>
              </div>
            </div>

            <div className="col-5 row">
              <div className="col-9">
                <DatePickerField
                  classname="searchBar-input1"
                  name="startDate"
                  header="To"
                  value={to}
                  onChange={(v) => {
                    console.log("ppppoooooo", v);
                    setTo(v);
                  }}
                  //onChange={(v) => setFieldValue("endDate", v, true)}
                  // maxDate={
                  //   values.endDate === "" ? "" : values.endDate
                  // }
                  // maxDate={new Date()}
                  // ref1={datepickerRefEnd}
                  style={{ width: "100px" }}
                />
              </div>
              <div className="col-3" style={{ margin: "0 0 0 -15px" }}>
                <label
                  className="date-input"
                  for="startDate"
                  style={{
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    //   handleClickDatepickerIcon2();
                  }}
                >
                  <SvgImage
                    name="CalenderLogo"
                    width="32"
                    height="32"
                    // classname="date-logo"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="ms-4">
          <div className="headingText">To:</div>

        </div> */}
      </div>
      <div className="row box3 pt-0 justify-content-between">
        <div className="col-12 col-md-10">
          <button
            className={`Medium heading3 ${
              tripType == "" ? "greenButtonMini" : "greenOutlineButtonMini"
            } rounded-pill`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("");
            }}
          >
            All
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "0" ? "greenButtonMini" : "greenOutlineButtonMini"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("0");
              // setOffset(0);
            }}
          >
            Running
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "1" ? "greenButtonMini" : "greenOutlineButtonMini"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("1");
              // setOffset(0);
            }}
          >
            Completed
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "2" ? "greenButtonMini" : "greenOutlineButtonMini"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("2");
              // setOffset(2);
            }}
          >
            Not started
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "3" ? "greenButtonMini" : "greenOutlineButtonMini"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("3");
              // setOffset(3);
            }}
          >
            Idle
          </button>
          <button
            className={`Medium heading3 ${
              tripType == "4" ? "greenButtonMini" : "greenOutlineButtonMini"
            } rounded-pill ms-3`}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={(e) => {
              setTripType("4");
              // setOffset(3);
            }}
          >
            Delayed
          </button>
        </div>
        <div className="col-12 col-md-2 align-items-center">
          <button
            className=" d-flex align-items-center text2 headingText"
            style={{
              border: 0,
              // marginTop: "10px",
              backgroundColor: "#FFFFFF",
            }}
            onClick={() => {
              // setDownload(true);
            }}
          >
            <div className="padding8R">
              <SvgImage name="downloadImgV2" />
            </div>
            Download
          </button>
        </div>
      </div>
      <div className="table-for-vehicle-receivable">
        <Table className="vehicleAssignedHistory">
          <thead
            className="th align-middle text-center"
            style={{ zIndex: 99, textAlignLast: "center" }}
          >
            <tr
              className="table-header-Fastag-details"
              style={{ fontSize: "13px" }}
            >
              {/* <th className="p-0" style={{ width: "10%" }}>
                Lr No
              </th> */}
              <th className="p-0" style={{ width: "15%" }}>
                Trip Number
              </th>
              <th className="p-0">From</th>
              <th className="p-0">Start Date</th>
              {/* <th className="p-0">Tonnage</th> */}
              <th className="p-0" style={{ width: "12%" }}>
                Pending PODs
              </th>
              <th className="p-0" style={{ width: "15%" }}>
                Total Expense
              </th>
              {/* <th className="p-0">Trip No</th> */}
              <th className="p-0">Total Paid</th>
              <th className="p-0">Status</th>
              <th className="p-0"></th>
            </tr>
          </thead>
          {/* <div className="tbodyVehicle-div"> */}
          {
            // !loader ? (
            //   <div className="h-full mt-5 text-center">
            //   <Default color={"#2e7c7b"} />
            // </div>

            // ) :
            txnCount == undefined ||
            txnCount == 0 ||
            (txnCount > 0 && txnRows?.length == 0) ? (
              <div className="padding32 textCenter">
                <SvgImage name="TripsMap" />
                {/* <div className="Bold heading1 padding24T padding16B FontSuperBold primaryBlack">
                Create Trip
              </div> */}
                <div
                  className="greyFont heading3"
                  style={{ marginTop: "10px" }}
                >
                  There are no records to
                </div>
                <div className="greyFont heading3 padding32B">display</div>
                {/* <button
                className={"Medium heading3 greenButton"}
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                onClick={() => {
                  setOpen2(true);
                }}
              >
                Create Trip
              </button> */}
              </div>
            ) : (
              <tbody
                className="tbodyVehicle"
                style={{ textDecoration: "none" }}
              >
                {txnRows?.map((items, i) => {
                  // console.log("setIsCheckTrips", isCheckTrips);
                  let pendingPOD = items?.Freights?.filter(
                    (i) => i.podUrl == null
                  )?.length;
                  console.log("pppppitems", items?.Freights);
                  return (
                    <>
                      <tr className="table-row-customer-details3">
                        <td
                          className="p-3"
                          onClick={() => {
                            onSelect(items);
                          }}
                          style={{ width: "15%" }}
                        >
                          {items?.tripNo}
                        </td>
                        {/* <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items);
                        }}
                      >
                        {items?.Trip?.Fleet?.regNumber}
                      </td> */}
                        <td
                          className="p-3"
                          onClick={() => {
                            onSelect(items);
                          }}
                        >
                          {items?.sourceAddress?.cityName}
                        </td>
                        <td
                          className="p-3"
                          onClick={() => {
                            onSelect(items);
                          }}
                        >
                          {items?.expectedStartTime
                            ? moment(items?.expectedStartTime).format(
                                "DD MMM YY"
                              )
                            : ""}
                        </td>
                        <td
                          className="p-3"
                          onClick={() => {
                            onSelect(items);
                          }}
                          style={{ width: "12%" }}
                        >
                          <div className="row">
                            <div className="col-4">{pendingPOD || 0}</div>
                            <div className="col-8">
                              <Button
                                className="Button4 fontNM12"
                                onClick={() => showPodModal(items)}
                              >
                                {console.log("pendingPODppp", pendingPOD)}
                                {pendingPOD == 0 ? "Details" : "submit"}
                              </Button>
                            </div>
                          </div>
                        </td>
                        {/* <td
                        className="p-3"
                        onClick={() => {
                          onSelect(items?.Trip);
                        }}
                      >
                        {items?.tonnage}
                      </td> */}
                        <td
                          className="p-3"
                          onClick={() => {
                            onSelect(items?.Trip);
                          }}
                          style={{ width: "15%" }}
                        >
                          {"₹" + (items?.TripExpense?.driverTotal || 0)}
                        </td>
                        <td
                          className="p-3"
                          onClick={() => {
                            onSelect(items?.Trip);
                          }}
                        >
                          {"₹" + (items?.TripExpense?.totalDriverExpense || 0)}
                        </td>
                        <td
                          className="p-3"
                          onClick={() => {
                            onSelect(items);
                          }}
                        >
                          <button
                            type="button"
                            class="btn fontNM10"
                            style={{
                              width: "60px",
                              padding: "0px",
                              backgroundColor:
                                items?.isActive == 1
                                  ? "rgb(245 163 10)"
                                  : items?.isActive == 2
                                  ? "rgb(13, 110, 253)"
                                  : items?.isActive == 3
                                  ? "rgb(46, 124, 123)"
                                  : items?.isActive == 5
                                  ? "rgb(46, 124, 123)"
                                  : items?.isActive == 0
                                  ? "rgb(46, 124, 123)"
                                  : "rgb(46, 124, 123)",
                            }}
                          >
                            {items?.isActive == 1
                              ? "Not Started"
                              : items?.isActive == 2
                              ? "Running"
                              : items?.isActive == 3
                              ? "Completed"
                              : items?.isActive == 5
                              ? "Idle"
                              : items?.isActive == 0
                              ? "Deleted"
                              : "Delayed"}
                          </button>{" "}
                        </td>
                        <td
                          className="p-3"
                          onClick={() => {
                            onSelect(items?.Trip);
                          }}
                        >
                          <div>
                            <button
                              className={
                                "Medium text2 greenOutlineButton d-flex align-items-center"
                              }
                              style={
                                {
                                  // paddingLeft: "0.6rem",
                                  // paddingRight: "1rem",
                                  // width: "70px",
                                }
                              }
                              onClick={() => openActions(i)}
                            >
                              <div
                                className="me-1 "
                                style={{
                                  fontWeight: 500,
                                }}
                              >
                                Actions
                                {/* {color != -1 ? listing[color] : "Action"} */}
                              </div>
                              <div>
                                <SvgImage name="downArrow2" />
                              </div>
                            </button>
                            {/* {console.log("kkoooooo",displayAction)} */}
                            {displayAction == i ? (
                              <div
                                className={"dropDownDivAction2"}
                                style={{ height: `120px`, minWidth: "100px" }}
                              >
                                <div style={{ marginTop: "12px" }}>
                                  {listing.length > 0 ? (
                                    listing?.map((item, idx) => {
                                      return (
                                        <div
                                          key={idx}
                                          className="row listItems2"
                                          onMouseDown={(e) =>
                                            e.preventDefault()
                                          }
                                          onClick={() => {
                                            handleSelection(idx, item, items);
                                          }}
                                          // style={{ padding: "0 0 10px 0" }}
                                        >
                                          {isCheckTrips.length == 0 ? (
                                            <div
                                              className="col-9"
                                              style={{ color: "#c4c4c4" }}
                                            >
                                              {item}
                                            </div>
                                          ) : color === i &&
                                            isCheckTrips.length > 1 &&
                                            (i == 0 || i == 1) ? (
                                            <div
                                              className="col-9"
                                              style={{ color: "#c4c4c4" }}
                                            >
                                              {item}
                                            </div>
                                          ) : color === i ? (
                                            <div
                                              className="col-9"
                                              style={{
                                                color: "white",
                                                backgroundColor: "#2e7c7b",
                                                border: "1px solid #2e7c7b",
                                                borderRadius: "6px",
                                              }}
                                            >
                                              {item}
                                            </div>
                                          ) : isCheckTrips.length > 1 &&
                                            (i == 0 || i == 1) ? (
                                            <div
                                              className="col-9"
                                              style={{ color: "#c4c4c4" }}
                                            >
                                              {item}
                                            </div>
                                          ) : (
                                            <div
                                              className="col-9"
                                              style={{ color: "#2e7c7b" }}
                                            >
                                              {item}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                        {/* <td
                        className="p-3 align-items-center"
                        onClick={() => {
                          onSelect(items?.Trip);
                        }}
                      >
                        <div className="statusBtn">
                          {items?.Trip?.isActive == "0"
                            ? "Completed"
                            : "Running"}
                        </div>
                      </td> */}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            )
          }

          {/* </div> */}
        </Table>

        {/* {
          olderHistoryStatus?
          <div className="olderTransactions-div" onClick={handleOlderTransactions}>
          Older History
          </div>:
        <></>
        } */}
        {console.log("pagecunt", pageCount)}
        {pageCount >= 1 ? (
          <div className="reactPagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageChange}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          <></>
        )}
        <ManageDriverAddBalance
          open={open}
          toggleModal={toggleModal}
          handleClose={handleClose}
          name="Add Balance"
          userId={id}
          // driverBal={bal}
          cardType={1}
          itemTemp={tripData}
          driverName={props.driverName}
        />

        <DriverDashboardAddExpense
          open={addExpense}
          toggleModal={toggleAddExpense}
          handleClose={closeAddExpense}
          name="Add Balance"
          userId={id}
          driverBal={"SCDa"}
          cardType={1}
          driverName={props.driverName}
          accountStatus={props.accountStatus}
          modalName="Driver Name"
          itemTemp={tripData}
          value={props.driverName}
          transferType={transferType}
          showCard={showCard}
          settleAmount={settleAmount}
          tripKey={settleAmount ? "final" : ""}
        />
        {/* <DriverSettleModal
          open={open2}
          toggleModal={toggleModal2}
          handleClose={handleClose2}
          name="Add Balance"
          userId={id}
          cardType={1}
          driverName={props.driverName}
          itemTemp={tripData}
          accountStatus={props.accountStatus}
        /> */}
        <AddPod
          toggle={togglePodModal}
          modal={pod}
          setModal={addPod}
          handleClose={handlePodClose}
          name="Add Balance"
          userId={id}
          // driverBal={bal}
          cardType={1}
          tripData={tripData}
          tripId={tripData?.tripId}
          driverName={props.driverName}
        />
      </div>
    </div>
  );
}
export default TripTable;
