import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import SvgImage from "../Icons/Icons";
import TripsComponent from "./SubComponents/TripsComponent";
import LadgerComponent from "./SubComponents/LadgerComponent";
import { getCustomerAction } from "../../services/Store/Actions/customerAction";
import moment from "moment";
import EditCustomer from "../ManageCustomersV2/SubComponents/EditCustomer";
import { toastStatus } from "../../services/Store/Actions/appActions";
import InvoiceToast from "../subComponents/InvoiceToast";
import { Toast } from "reactstrap";
import ToastV2 from "../subComponents/toastV2";
import EditBankDetails from "./SubComponents/EditBankDetails";
import { getVendorDetailsAction } from "../../services/Store/Actions/beneficiaryAction";
function ManageCustomersDetailsV2(props) {
  const { sideBar } = props;
  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const { totalTrips } = useSelector((state) => state?.customer);
  const [tripNos, setTripNos] = useState("");
  const offsetVal = Number(offsetString);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [editCust, setEditCust] = useState(false);
  const [editBankDetails, setEditBankDetails] = useState(false);
  const [isTrips, setIsTrips] = useState(0);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  const [activeTab, setActiveTab] = useState(
    ["1", "2", "3", 1, 2, 3].includes(isTrips) ? 0 : 2
  );
  console.log("isTripisTripss", isTrips, activeTab);
  const [offset, setOffset] = useState(offsetVal);
  const { customer } = useSelector((state) => state?.customer);
  const { vendorDetails } = useSelector((state) => state?.beneficiary);
  const [openToast, setOpenToast] = useState(false);
  const toastObject = useSelector((state) => state?.app?.toastObject);

  // const {customer} = useSelector(state => state?.customer )
  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);

  useEffect(() => {
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
  }, [authUser, managerControlList]);
  useEffect(() => {
    setActiveTab(["1", "2", "3", 1, 2, 3].includes(isTrips) ? 0 : 2);
  }, [isTrips]);
  console.log("===customer", customer);
  let { id } = useParams();

  useEffect(() => {
    if (id !== undefined && id !== "") {
      dispatch(
        getCustomerAction({
          customerId: id,
        })
      );
    }
  }, [id]);
  function handleEditDetails() {
    if (["2", "3", 2, 3].includes(isCustomers)) setEditCust(!editCust);
  }
  function handleEditBankDetails() {
    if (["2", "3", 2, 3].includes(isCustomers))
      setEditBankDetails(!editBankDetails);
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // search value
  console.log("window.innerWidth", window.innerWidth);
  return (
    <div
      className="container topMargin sideBarMargin"
      style={{
        marginLeft: sideBar ? "220px" : "100px",
        maxWidth: sideBar ? windowWidth - 260 : windowWidth - 130,
      }}
    >
      <div className="container2">
        <div>
          <div className="row align-items-center">
            <div className="col-9 headingBox">
              <button
                style={{
                  transform: "rotate(180deg)",
                  //   width: "32px",
                  //   height: "32px",
                  background: "white",
                  border: 0,
                }}
                className="align-self-center"
                onClick={() => {
                  window.history.back();
                }}
              >
                <SvgImage
                  name="ForwardArrow"
                  height="32px"
                  width="32px"
                  color={"#000"}
                />
              </button>
              <div className="heading1 align-self-center ">
                {customer?.companyName}
              </div>
              <div className="textClass  align-self-center ms-3">
                {customer?.TripAddress?.address}
              </div>
            </div>
            <div
              className="col-3 d-flex justify-content-end"
              style={{
                textAlign: "end",
              }}
            >
              {/* <button
                className={"Medium text2 greenOutlineButton"}
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                onClick={handleEditBankDetails}
              >
                Edit Bank Details
              </button> */}
              <button
                className={"Medium text2 greenOutlineButton"}
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                onClick={handleEditDetails}
              >
                Edit Details
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4 ms-2">
          <div className="col-3">
            <div className="d-flex justify-content-between">
              <div className=" textClass">Contact Person</div>
              <div className=" textClass text-end">
                {customer?.contactName || "N/A"}
              </div>
            </div>
            <div className="d-flex mt-2 justify-content-between">
              <div className=" textClass">Email ID</div>
              <div className=" text-end textClass">
                {customer?.email || "N/A"}
              </div>
            </div>
            <div className="d-flex mt-2 justify-content-between">
              <div className=" textClass">Phone</div>
              <div className=" text-end textClass">
                {customer?.phone || "N/A"}
              </div>
            </div>
            <div className="d-flex mt-2 justify-content-between">
              <div className=" textClass">GSTIN</div>
              <div className=" text-end textClass">
                {customer?.gstin || "N/A"}
              </div>
            </div>
          </div>
          {/* <div className="divider2" /> */}
          <div className="col-8">
            <div className="d-flex justify-content-between">
              <div className="viewClass text-center  me-4" style={{ flex: 1 }}>
                <div className="textClass2"> Total Earnings</div>
                <div className="textClass3">₹{customer.earning?.toLocaleString('en-IN')}</div>
              </div>
              <div className="viewClass text-center me-4" style={{ flex: 1 }}>
                <div className="textClass2"> Closing Balance</div>
                <div className="textClass3">
                  ₹{customer?.amount ? customer?.amount?.toLocaleString('en-IN') : "0"}
                </div>
              </div>
              <div className="viewClass text-center  me-4" style={{ flex: 1 }}>
                <div className="textClass2"> Last Payment Date</div>
                <div className="textClass3">
                  {customer?.lastPaymentDate
                    ? moment(customer?.lastPaymentDate).format("MMM DD YYYY")
                    : "N/A"}
                </div>
              </div>
              <div className="viewClass text-center " style={{ flex: 1 }}>
                <div className="textClass2"> Total Freights</div>
                <div className="textClass3">
                  {totalTrips
                    ? totalTrips?.reduce((sum, i) => sum + i.count, 0) || 0
                    : 0}
                  {/* <p style={{ fontSize: "16px", marginBottom: "0" }}>
                    P{" "}
                    {totalTrips
                      ? totalTrips?.filter((i) => i.status == 0)[0]?.count || 0
                      : 0}{" "}
                    R{" "}
                    {totalTrips
                      ? totalTrips?.filter((i) => i.status == 1)[0]?.count || 0
                      : 0}{" "}
                    C{" "}
                    {totalTrips
                      ? totalTrips?.filter((i) => i.status == 2)[0]?.count || 0
                      : 0}
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottomPart">
          <div className="d-flex ">
            <button
              className={activeTab == 0 ? "activeTab tab" : "inActiveTab tab"}
              onClick={() => {
                if (["1", "2", "3", 1, 2, 3].includes(isTrips)) {
                  setActiveTab(0);
                }
              }}
            >
              Freights
            </button>
            {/* <button
              className={activeTab == 1 ? "activeTab tab" : "inActiveTab tab"}
              onClick={() => {
                setActiveTab(1);
              }}
            >
              Trips By
            </button> */}
            <button
              className={activeTab == 2 ? "activeTab tab" : "inActiveTab tab"}
              onClick={() => {
                setActiveTab(2);
              }}
            >
              Ledger
            </button>
          </div>
          {activeTab == 0 ? (
            <TripsComponent
              customerId={id}
              setTripNos={setTripNos}
              customer={customer}
            />
          ) : activeTab == 1 ? (
            <TripsComponent customerId={id} setTripNos={setTripNos} activeTab />
          ) : (
            <LadgerComponent
              customerId={id}
              customer={customer}
              vendorDetails={vendorDetails}
              handleEditBankDetails={handleEditBankDetails}
            />
          )}
        </div>
      </div>
      <EditCustomer
        toggle={handleEditDetails}
        modal={editCust}
        setModal={setEditCust}
        customerId={id}
      />
      <EditBankDetails
        toggle={handleEditBankDetails}
        modal={editBankDetails}
        setModal={setEditBankDetails}
        customerId={id}
      />

      {toastObject.toastStatus ? (
        <ToastV2
          success={toastObject?.status}
          message={toastObject?.message}
          invoice={toastObject?.invoice}
          link={toastObject?.link}
          rediractionLink={toastObject?.rediractionLink}
          mobileNumber={toastObject?.mobileNumber}
          emailId={toastObject?.emailId}
          toggleModal={() => {
            // setOpenToast(false);
          }}
          isOpen={toastObject?.toastStatus}
          name="driver"
          // link={link}
          handleClose={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
                message: "",
                status: true,
              })
            );
            toastObject.callBack();
            // if (toastObject?.status) {
            //   window.location = `/Customers/${id}`;
            // }

            navigate(`/Customers/${id}`);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
export default ManageCustomersDetailsV2;
