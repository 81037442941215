import React from "react";
import { Formik, ErrorMessage } from "formik";
import { Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import PinInput from "react-pin-input";
import SvgImage from "../../Icons/Icons";
import "./index.css";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import {
  editCardControl,
  getCardControl,
  newEditCardControlAction,
  newGetCardControlAction,
} from "../../../services/Store/Actions/driverslistActions";
import { M2PCardControlAction, M2pEditCardControlAction } from "../../../services/Store/Actions/m2pAction";
import { toastStatus } from "../../../services/Store/Actions/appActions";
const ManageDriverEditCardControlModal = (props) => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state?.driverlist);
  const { open, toggleModal, handleClose, name } = props;
  let TransactionType = name.split(" ");
  TransactionType = TransactionType[0];
  TransactionType = TransactionType.toLowerCase();
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">{name}</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
              disabled={loader ? true : false}
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          {/* <hr /> */}
          <ModalBody>
            <Formik
              enableReinitialize
              initialValues={{
                userId: props.userId,
                txnType: TransactionType,
                limitValue:"",
              }}
              validationSchema={Yup.object().shape({
                limitValue: Yup.number().typeError("Only Numbers are allowed")
                  .min(1, "Amount should be greater than 1")
                  .required("Amount is required"),
                // PIN: Yup.string()
                //   .required("* Required")
                //   .matches(/^[0-9]{4}$/, "* Pin Must be exactly 4 digits"),
                // dob: Yup.string()
                //   .required('Expense Date is required'),
                // password: Yup.string()
                //   .min(6, 'Password must be at least 6 characters')
                //   .required('Password is required'),
                // confirmPassword: Yup.string()
                //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
                //   .required('Confirm Password is required')
              })}
              onSubmit={(values, actions) => {
                console.log("valuessss", values);
                if (props.cardType === "1") {
                  dispatch(
                    newEditCardControlAction(props.userId, values, (res) => {
                      actions.setSubmitting(false);
                      if (res?.data?.type === "success") {
                        console.log("successful");

                        dispatch(newGetCardControlAction(props.userId));
                        toggleModal();
                      }
                    })
                  );
                } else {
                  if (Number(values.limitValue)>500000){
                    dispatch(
                      toastStatus({
                        toastStatus: true,
                        message: `Error : Limit Can't Be More Than 500000`,
                        status: false,
                      })
                    );
                  }else
                  {
                    dispatch(
                      M2pEditCardControlAction(values,(res) => {
                        actions.setSubmitting(false);
                        if (res?.data?.type === "success") {
                          console.log("successful");
  
                          dispatch(M2PCardControlAction(props.userId));
                          toggleModal();
                        }
                      })
                    );
                  }
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <p className="sub-head-3">Enter amount</p>
                    </div>
                    <p className="sub-head-body">
                      Amount will be set as the new limit
                    </p>

                    <label for="html" className="label-body">
                                        ₹
                                    </label>
                    <input
                      type="text"
                      id="limitValue"
                      className={
                        "input-body-1"
                      }
                      name="limitValue"
                      value={values.limitValue}
                      touched={touched.limitValue}
                      errors={errors.limitValue}
                      onBlur={handleBlur("limitValue")}
                      onChange={handleChange("limitValue")}
                      placeholder="Enter amount"
                    ></input>
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.limitValue && touched.limitValue ? (
                          <div>*{errors.limitValue}</div>
                        ) : null}
                        </div>
                    <Button
                      type="submit"
                      className="butt4"
                      disabled={loader ? true : false}
                    >
                      {loader ? "Please Wait" : "Update Limit"}
                    </Button>
                  </FormGroup>
                  {/* <div>
                  <div className="sub-title-1">
                    <span class="circle-logo">2</span>
                    <p className="sub-head-4">Enter 4-digit Security Pin</p>
                  </div>
                  <p className="sub-head-body">
                    Security pin is needed to confirm payment
                  </p>
                  <PinInput
                    name="PIN"
                    length={4}
                    initialValue=""
                    secret
                    onChange={(value, index) =>
                      setFieldValue("PIN", value, true)
                    }
                    type="numeric"
                    inputMode="number"
                    style={{
                      padding: "10px",
                      marginLeft: "96px",
                      marginTop: "28px",
                    }}
                    inputStyle={{
                      border: "1px solid #A7A7A7",
                      borderRadius: "8px",
                      marginRight: "20px",
                    }}
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  <div
                    style={{
                      color: "#dc3545",
                      fontSize: "14px",
                      marginLeft: "6.5rem",
                    }}
                    className={
                      +(errors.PIN && touched.PIN ? " is-invalid" : "")
                    }
                  >
                    {" "}
                    <ErrorMessage className="is-invalid" name="PIN" />
                  </div>
                  <button type="submit" className="butt4">
                    Update Limit
                  </button>
                </div> */}
                </Form>
              )}
            </Formik>

            {/* <div className="space-div"></div> */}
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};
export default ManageDriverEditCardControlModal;
