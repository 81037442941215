import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { Formik } from "formik";
import PinInput from "react-pin-input";
import "./index.css";
import * as Yup from "yup";
import SvgImage from "../../../Icons/Icons";
import { changeUpiManager } from "../../../../services/Store/Actions/UserManagementAction";
import { toastStatus } from "../../../../services/Store/Actions/appActions";

function ChangeTransferLimit(props) {
  const dispatch = useDispatch();
  const { open, toggleModal, handleClose, balance, fastagBal } = props;
  const [loader, setLoader] = useState(false);
  const [assignedAssigned, setAsssignedStatus] = useState(0);
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Only Numbers are allowed")
      .required("Required")
      .min(1, "Amount should be greater than 1"),
  });

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons1 settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Transfer Limit</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <Formik
              enableReinitialize
              initialValues={{
                amount: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                setLoader(true);
                dispatch(
                  changeUpiManager(
                    {
                      userId: props?.user?.userId,
                      amount: values?.amount,
                      type: `${assignedAssigned}`
                    },
                    (res) => {
                      setLoader(false);
                      if (res.status == 200) {
                        dispatch(
                          toastStatus({
                            toastStatus: true,
                            message: "Transfer Limit Changed Successfully",
                            status: true,
                          })
                        );
                      } else {
                        dispatch(
                          toastStatus({
                            toastStatus: true,
                            message: "Error in changing transfer limit",
                            status: false,
                          })
                        );
                      }
                    }
                  )
                );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <label className="sub-head-3 ">Manager Name</label>
                    </div>
                    <div className="row">
                      <div className="col-10">
                        <p className="input-body-3 ">{props?.user?.fullName}</p>
                      </div>

                      <div className="col-2">
                        <SvgImage name="lockLogo" />
                      </div>
                    </div>

                    <div className="input-amount-for-link"></div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-title-recharge-fastag-Id">
                        Enter amount
                      </p>
                    </div>
                    <label for="html" className="label-body">
                      ₹
                    </label>
                    <input
                      type="text"
                      id="html"
                      className="input-body-1"
                      name="amount"
                      value={values.amount}
                      touched={touched.amount}
                      errors={errors.amount}
                      onBlur={handleBlur("amount")}
                      onChange={handleChange("amount")}
                      placeholder="Enter amount"
                    ></input>
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <div style={{
                    marginTop:"40px",
                    marginLeft: "80px"
                  }}>
                    <button
                      className={`Medium textDiv1 ${
                        assignedAssigned == 0
                          ? "greenButton"
                          : "greenOutlineButton"
                      } rounded-pill ms-2`}
                      style={{
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        height: 30,
                        marginTop: "-16px",
                        marginBottom: "10px",
                      }}
                      onClick={(e) => {
                        setAsssignedStatus(0);
                      }}
                    >
                      One Time Limit
                    </button>
                    <button
                      className={`Medium textDiv1 ${
                        assignedAssigned == 1
                          ? "greenButton"
                          : "greenOutlineButton"
                      } rounded-pill ms-2`}
                      style={{
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        height: 30,
                        marginTop: "-16px",
                        marginBottom: "10px",
                      }}
                      onClick={(e) => {
                        setAsssignedStatus(1);
                      }}
                    >
                      Daily Limit
                    </button>
                    <button
                      className={`Medium textDiv1 ${
                        assignedAssigned == 2
                          ? "greenButton"
                          : "greenOutlineButton"
                      } rounded-pill ms-2`}
                      style={{
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        height: 30,
                        marginTop: "-16px",
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        console.log("snjssbbb12222");
                        setAsssignedStatus(2);
                      }}
                    >
                      Monthly Limit
                    </button>
                  </div>
                  <FormGroup>
                    <div>
                      <button
                        type="submit"
                        className="butt-recharge"
                        disabled={loader ? true : false}
                        style={{ marginTop: "60px" }}
                        onClick={() => {}}
                      >
                        {loader ? "Please Wait" : "Recharge Transfer Limit"}
                      </button>
                    </div>
                  </FormGroup>
                  {/* <div className="space-div"></div> */}
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
export default ChangeTransferLimit;
