import React, { useEffect } from "react";
import "./index.css";
import SvgImage from "../../../Icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import { getWalletBalAction } from "../../../../services/Store/Actions/LogipeCardActions";
import ClipLoader from "react-spinners/ClipLoader";

function ManageLogipeCardType1(props) {
  const color = props.color == 1 ? "#31BF7A" : "#FE2950";
  const text = props?.text == null ? " " : props?.text;
  const logo = props?.logo == null ? " " : props?.logo;
  const balanceRecord = useSelector(
    (state) => state?.LogipeCard?.balanceRecord
  );
  const balanceRecordLoader = useSelector((state) => state?.LogipeCard?.loader);
  //console.log(":;;;;;;;;;",balanceRecord);
  const bal1 = balanceRecord?.balance === null ? 0 : balanceRecord?.balance;
  const bal2 =
    balanceRecord?.thisMonthSpending === null
      ? 0
      : balanceRecord?.thisMonthSpending;
  const bal = text === "Current Balance" ? bal1 : bal2;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWalletBalAction(props.userId));
  }, []);
  return (
    <div className="row whiteBackground padding8 cardTopBox padding16T padding16B">
      <div className="col-2">
        <SvgImage width="43" height="43" name={logo} />
      </div>
      <div className="col-8 padding16L semiBold heading1">
        <div style={{ color: color }}>
          {balanceRecordLoader ? (
            <ClipLoader
              color={"#485B7C"}
              loading={balanceRecordLoader}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            "₹ " + Number(bal).toFixed(2)
          )}
        </div>
        <div className="Medium text3">{text}</div>
      </div>
    </div>
  );
}
export default ManageLogipeCardType1;
