import { call, put, takeEvery } from "redux-saga/effects";
import {
  addCustomerPost,
  getCustomersAPI,
  logipeAccountAPI,
  getCustomerByIDAPI,
  getCustomerRecievablesAPI,
  addNewBookingBillPost,
  billDetailsAPI,
  createPaymentLinkPostApi,
  updateBillDetailsApi,
  virtualAccountCreditApi,
  updatePaymentApi,
  createPaymentTokenApi,
} from "../../Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import {
  addCustomerSuccess,
  getCustomersSuccess,
  getLogiPeAccountSuccess,
  getCustomersByIDSuccess,
  getCustomerRecievablesSuccess,
  addNewBookingBillSuccess,
  getBillDetailsSuccess,
  createPaymentLinkSuccess,
  updateBillDetailsSuccess,
  virtualAccountCreditSuccess,
  updatePaymentActionSuccess,
  createPaymentTokenSuccessAction,
} from "../Actions/receivableActions";
import {
  ADD_CUSTOMER,
  GET_CUSTOMERS,
  LOGIPE_ACCOUNT,
  GET_CUSTOMERS_BY_ID,
  GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID,
  ADD_NEW_BOOKING_BILL,
  BILL_DETAILS,
  CREATE_PAYMENT_LINK,
  UPDATE_BILL_DETAILS,
  VIRTUAL_ACCOUNT_CREDIT,
  UPDATE_PAYMENT,
  CREATE_PAYMENT_TOKEN,
} from "../storeTypes";
import {
  toastStatus,
  toastStatusSuccess,
} from "../../../services/Store/Actions/appActions";

function* addCustomerSaga(action) {
  try {
    const response = yield call(addCustomerPost, action.payload);
    console.log("data", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(addCustomerSuccess());
      // yield put(
      //   toastStatusSuccess({
      //     toastStatus: true,
      //     message: "New customer was added successfully",
      //     status: true,
      //   })
      // );
    }
  } catch (error) {
    console.log('dafeefqe',error?.response?.data)
    action.callback(error?.response);
    //console.log('Error.Response:- ', error.response.data);
    // yield put(
    //   toastStatus({
    //     toastStatus: true,
    //     message: `Error : ${error?.response?.data?.message}`,
    //     status: false,
    //   })
    // );
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getCustomersSaga(action) {
  try {
    const response = yield call(getCustomersAPI, action.payload);
    //console.log("receive", response);
    if (response.data.type == "success") {
      // action.callback(response);
      yield put(getCustomersSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getCustomersSaga Error.Response:- ", error.response.data);
    //Alert.alert("Error", error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* receivableAccountSaga(action) {
  try {
    const response = yield call(logipeAccountAPI);

    if (response.data.type == "success") {
      yield put(getLogiPeAccountSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getCustomersByIDSaga(action) {
  try {
    const response = yield call(getCustomerByIDAPI, action.payload);
    //console.log("rEsponse",response);
    if (response.data.type == "success") {
      // action.callback(response);
      yield put(getCustomersByIDSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert("Error", error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getCustomerRecievablesSaga(action) {
  try {
    console.log("idddd", action.payload);
    const response = yield call(getCustomerRecievablesAPI, action.payload);

    if (response?.data?.type == "success") {
      // action.callback(response);
      yield put(getCustomerRecievablesSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* addNewBookingBillSaga(action) {
  try {
    const response = yield call(addNewBookingBillPost, action.payload);

    if (response.data.type == "success") {
      action.callback(response);
      yield put(addNewBookingBillSuccess());
      console.log("reached till toast");
      yield put(
        toastStatus({
          toastStatus: true,
          message: "New booking bill was added successfully",
          status: true,
        })
      );
      console.log("reached after toast");
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    action.callback(error.response.data);
    //alert(`Error : $ {error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* billDetailsSaga(action) {
  try {
    const response = yield call(billDetailsAPI, action.payload);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(getBillDetailsSuccess(response?.data?.data));

    }
  } catch (error) {
    console.log("billDetailsSaga Error.Response:- ", error.response.data);
    //Alert.alert("Error", error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* createPaymentLinkSaga(action) {
  try {
    const response = yield call(createPaymentLinkPostApi, action.payload);
    console.log("billDetailsSaga", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(createPaymentLinkSuccess());
    }
  } catch (error) {
    console.log("createPaymentLinkSaga Error.Response:- ", error.response.data);
    action.callback(error.response.data);
    // alert(`Error : $ {error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* updateBillDetailsSaga(action) {
  try {
    const response = yield call(updateBillDetailsApi, action.payload);
    console.log("billDetailsSaga", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(updateBillDetailsSuccess());
      yield put(
        toastStatusSuccess({
          toastStatus: true,
          message: "Booking details updated successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log("updateBillDetails Error.Response:- ", error.response.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    action.callback(error.response.data);
    // alert(`Error : $ {error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* virtualAccountCreditSaga(action) {
  try {
    const response = yield call(virtualAccountCreditApi, action.payload);
    //console.log("billDetailsSaga", response);
    if (response.data.type == "success") {
      //console.log("billDetailsSaga", response);
      action?.callback(response);
      yield put(virtualAccountCreditSuccess());
    }
  } catch (error) {
    console.log("createPaymentLinkSaga Error.Response:- ", error.response.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    //action.callback(error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* updatePaymentSaga(action) {
  try {
    const response = yield call(updatePaymentApi, action.payload);
    console.log("data", response);
    if (response.data.type == "success") {
      action?.callback(response);
      yield put(updatePaymentActionSuccess(response?.data?.data));
      yield put(
        toastStatusSuccess({
          toastStatus: true,
          message: "Payment details was added successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    //alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* createPaymentTokenSaga(action){
  try {
    const response = yield call(createPaymentTokenApi, action.payload);
    //console.log("billDetailsSaga", response);
    if (response.data.type == "success") {
      //console.log("billDetailsSaga", response);
      action?.callback(response);
      yield put(createPaymentTokenSuccessAction());
    }
  } catch (error) {
    console.log("createPaymentLinkSaga Error.Response:- ", error);
    if(action.payload?.typeOfOrder=="8"){
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Error : ${error?.response?.data?.message}`+" You can view this order in My Orders section to retry payment.",
          status: false,
        })
      );
    }else{
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Error : ${error?.response?.data?.message}`,
          status: false,
        })
      );
    }
    
    //action.callback(error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


export function* watchReceivableSagas() {
  yield takeEvery(ADD_CUSTOMER, addCustomerSaga);
  yield takeEvery(GET_CUSTOMERS, getCustomersSaga);
  yield takeEvery(LOGIPE_ACCOUNT, receivableAccountSaga);
  yield takeEvery(GET_CUSTOMERS_BY_ID, getCustomersByIDSaga);
  yield takeEvery(
    GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID,
    getCustomerRecievablesSaga
  );
  yield takeEvery(ADD_NEW_BOOKING_BILL, addNewBookingBillSaga);
  yield takeEvery(BILL_DETAILS, billDetailsSaga);
  yield takeEvery(CREATE_PAYMENT_LINK, createPaymentLinkSaga);
  yield takeEvery(UPDATE_BILL_DETAILS, updateBillDetailsSaga);
  yield takeEvery(VIRTUAL_ACCOUNT_CREDIT, virtualAccountCreditSaga);
  yield takeEvery(UPDATE_PAYMENT, updatePaymentSaga);
  yield takeEvery(CREATE_PAYMENT_TOKEN, createPaymentTokenSaga)
}
