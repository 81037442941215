import React, { useState } from "react";
import SvgImage from "../../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import EnterNumberForm from "../EnterNumberForm";
import EnterOTPForm from "../EnterOTPForm";
import RightInfoPanel from "../RightInfoPanel";
import PrivacyModal from "../../Components/Modals/PrivacyModal";
import TermsConditionsModal from "../../Components/Modals/TermsConditionsModal";
import OnboardingBar from "./Components/OnboardingBar";
import EnterNumber from "./Components/Forms/EnterNumber";
import EnterOTP from "./Components/Forms/EnterOTP";
import OwnerDetails from "./Components/Forms/OwnerDetails";
import BusinessDetails from "./Components/Forms/BusinessDetails";
import BusinessDocs from "./Components/Forms/BusinessDocs";
import KYC from "./Components/Forms/KYC";
import SetPin from "./Components/Forms/SetPin";
import SetPinConfirm from "./Components/Forms/SetPinConfirm";
import Toast from "../../Components/subComponents/toast";
import LoginScreenPassword from "../LoginScreenPassword";
import ForgotPassword from "./Components/ForgotPassword";

function NewDesktopLogin() {
  const [status, setStatus] = useState("0");
  const [regStatus, setRegStatus] = useState(0);
  const [phone, setPhone] = useState("");
  const [isRegistered, setIsRegistered] = useState(true);
  const statusList = [
    "User Verification",
    "Owner Details",
    "Business details",
    "Business Documents",
    "KYC",
    "Set PIN",
  ];

  const data1 = [
    "Hurray!! You have unlocked Logimart & Insurance",
    "Hurray!! You have unlocked Logimart, insurance & trips",
    "You are all set!!",
  ];
  const data2 = [
    "1 more step to unlock trips",
    "1 more step to unlock scan & pay",
    "Set PIN to start securely transacting on LogiPe",
  ];
  const handleGooglePlayButton = () => {
    window.open("https://play.google.com/store/apps/details?id=com.logipeapp");
  };

  const toastObject = useSelector((state) => state?.app?.toastObject);
  const [busName, setBusName] = useState();
  const [busType, setBusType] = useState();
  const [truckNum, setTruckNum] = useState();
  const [panCard, setPanCard] = useState("");
  const [aadhaarNo, setAadhaarNo] = useState("");
  const [pinVal, setPinVal] = useState("");
  // for privacy policy modal
  const [openP, setOpenP] = useState(false);
  const togglePrivacyModal = () => {
    setOpenP(!openP);
  };
  const handlePrivacyClose = () => {
    setOpenP(false);
  };
  const handlePrivacyShow = () => {
    setOpenP(true);
  };
  // for terms and conditions modal
  const [openT, setOpenT] = useState(false);
  const toggleTermModal = () => {
    setOpenT(!openT);
  };
  const handleTermClose = () => {
    setOpenT(false);
  };
  const handleTermShow = () => {
    setOpenT(true);
  };

  const handleSignup = () => {
    setIsRegistered(false);
    setStatus("0");
  };

  return (
    <div className="row padding0 DesktopOuterContainer">
      <div className="col-lg-8 whiteColumn whiteHeight">
        <div className="row headingLogin">
          <div className="col-6">
            <div
              className=""
              onClick={() => {
                window.open("https://www.logipe.com/");
              }}
              style={{ cursor: "pointer", width: "300px", height: "53px" }}
            >
              <SvgImage name="AppLogo" />
            </div>
          </div>
          {isRegistered ? (
            <>
            {/* <div className="col-6">
              {status == "0" ? (
                <div>
                  <div className="heading2 subheading col-12">
                    Don't have an account?{" "}
                    <span
                      className="Bold "
                      style={{ color: "#2E7C7B", cursor: "pointer" }}
                      onClick={() => {
                        setIsRegistered(false);
                        setStatus("0");
                      }}
                    >
                      Sign Up
                    </span>
                  </div>
                  <div className="heading2 subheading col-12">
                    Login with Password?{" "}
                    <span
                      className="Bold "
                      style={{ color: "#2E7C7B", cursor: "pointer" }}
                      onClick={() => {
                        setIsRegistered(true);
                        setStatus("5");
                        setRegStatus(0);
                      }}
                    >
                      Click Here
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="heading2 subheading col-12">
                    Don't have an account?{" "}
                    <span
                      className="Bold "
                      style={{ color: "#2E7C7B", cursor: "pointer" }}
                      onClick={() => {
                        setIsRegistered(false);
                        setStatus("0");
                      }}
                    >
                      Sign Up
                    </span>
                  </div>
                  <div className="heading2 subheading col-12">
                    Login with Otp?{" "}
                    <span
                      className="Bold "
                      style={{ color: "#2E7C7B", cursor: "pointer" }}
                      onClick={() => {
                        setIsRegistered(true);
                        setStatus("0");
                        setRegStatus(0);
                      }}
                    >
                      Click here
                    </span>
                  </div>
                </div>
              )}
            </div> */}
            </>
          ) : (
            <div className="heading2 subheading col-6">
              Already a customer?{" "}
              <span
                className="Bold "
                style={{ color: "#ED752E", cursor: "pointer" }}
                onClick={() => {
                  setIsRegistered(true);
                  setStatus("0");
                  setRegStatus(0);
                }}
              >
                Log In
              </span>
            </div>
          )}
        </div>
        {isRegistered ? (
          <div>
            {status == "0" ? (
              <EnterNumberForm
                setStatus={setStatus}
                setPhone={setPhone}
                // handleTermShow={handleTermShow}
                // handlePrivacyShow={handlePrivacyShow}
                handleSignup={handleSignup}
                setIsRegistered={setIsRegistered}
              />
            ) : status == "5" ? (
              <LoginScreenPassword
                setStatus={setStatus}
                setPhone={setPhone}
                // handleTermShow={handleTermShow}
                // handlePrivacyShow={handlePrivacyShow}
                setIsRegistered={setIsRegistered}
              />
            ) : status == "6" ? (
              <ForgotPassword
                setStatus={setStatus}
                setPhone={setPhone}
                // handleTermShow={handleTermShow}
                // handlePrivacyShow={handlePrivacyShow}
                setIsRegistered={setIsRegistered}
              />
            ) : (
              <EnterOTPForm
                phone={phone}
                // handleTermShow={handleTermShow}
                // handlePrivacyShow={handlePrivacyShow}
                setStatus={setStatus}
                setIsRegistered={setIsRegistered}
              />
            )}
          </div>
        ) : (
          <div className="padding24 padding16T">
            <div>
              <div className="padding16L Regular text3 greyFont">
                Welcome! 👋
              </div>
              <div className="padding16L heading1 semiBold greyFont">
                Create your account
              </div>
              <OnboardingBar regStatus={regStatus} />
              {regStatus > 2 ? (
                <div className="padding16 padding32L">
                  <div className="row banner">
                    <div className="col-1">
                      {regStatus == 5 ? (
                        <SvgImage name="allDoneLogo" />
                      ) : (
                        <SvgImage name="thumpsUp" />
                      )}
                    </div>
                    <div className="col-8 ">
                      <div className="heading3">
                        {regStatus == 5
                          ? data1[2]
                          : regStatus == 4
                          ? data1[1]
                          : data1[0]}
                      </div>
                      <div className="greyFont textDiv">
                        {regStatus == 5
                          ? data2[2]
                          : regStatus == 4
                          ? data2[1]
                          : data2[0]}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {regStatus == 0 ? (
                status == "0" ? (
                  <EnterNumber
                    setStatus={setStatus}
                    setPhone={setPhone}
                    // handleTermShow={handleTermShow}
                    // handlePrivacyShow={handlePrivacyShow}
                    
                  />
                ) : (
                  <EnterOTP
                    phone={phone}
                    handleTermShow={handleTermShow}
                    handlePrivacyShow={handlePrivacyShow}
                    setStatus={setStatus}
                    setRegStatus={setRegStatus}
                    setPanCard={setPanCard}
                    setAadhaarNo={setAadhaarNo}
                    // setBusName={setBusName}
                    // setBusType={setBusType}
                    // setTruckNum={setTruckNum}
                  />
                )
              ) : regStatus == 1 ? (
                <OwnerDetails setRegStatus={setRegStatus} />
              ) : regStatus == 2 ? (
                <BusinessDetails
                  setRegStatus={setRegStatus}
                  setBusName={setBusName}
                  setBusType={setBusType}
                  setTruckNum={setTruckNum}
                />
              ) : regStatus == 3 ? (
                <BusinessDocs
                  setRegStatus={setRegStatus}
                  busName={busName}
                  busType={busType}
                  truckNum={truckNum}
                />
              ) : regStatus == 4 ? (
                <KYC
                  setRegStatus={setRegStatus}
                  panCard={panCard}
                  setPanCard={setPanCard}
                  aadhaarNo={aadhaarNo}
                  setAadhaarNo={setAadhaarNo}
                />
              ) : regStatus == 5 ? (
                <SetPin setRegStatus={setRegStatus} setPinVal={setPinVal} />
              ) : (
                <SetPinConfirm setRegStatus={setRegStatus} pinVal={pinVal} />
              )}
            </div>
            <div></div>
          </div>
        )}

        <div className="lowerDiv">
          <div className="row">
            <div className="Medium text4 footerText col-8">
              <div className="padding8R">Check out LogiPe payments app</div>
              <div
                onClick={handleGooglePlayButton}
                style={{ cursor: "pointer" }}
              >
                <SvgImage name="playStoreLogin" />
              </div>
            </div>
            <div className="col-4">
              <div className="bottomTextLogin padding16T Regular text4">
                <span
                  className="bottomTextSpan Medium"
                  onClick={() => {
                    handleTermShow();
                  }}
                >
                  T&C{" "}
                </span>{" "}
                and{" "}
                <span
                  className="bottomTextSpan Medium"
                  onClick={() => {
                    handlePrivacyShow();
                  }}
                >
                  Privacy Policy
                </span>{" "}
                apply
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-lg-4 greenColumn padding0 greenHeight"
        style={{ position: "relative" }}
      >
        <RightInfoPanel />
      </div>
      <PrivacyModal
        handleClose={handlePrivacyClose}
        open={openP}
        toggleModal={togglePrivacyModal}
      />

      <TermsConditionsModal
        handleClose={handleTermClose}
        open={openT}
        toggleModal={toggleTermModal}
      />
      {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={!toastObject?.toastStatus}
          isOpen={toastObject?.toastStatus}
          name="signUp"
          handleClose={() => {
            // dispatch(
            //   toastStatus({
            //     toastStatus: false,
            //     message: "",
            //     status: true,
            //   })
            // );
            // if (!toastObject?.toastStatus)
            window.location = "/";
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
export default NewDesktopLogin;
