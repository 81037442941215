import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { toastStatus } from "../../services/Store/Actions/appActions";
import { cleanEcomLoader, getCartDetailsAction, getOrderListAction, getProductListingAction } from "../../services/Store/Actions/ecomAction";
import SvgImage from "../Icons/Icons";
import LoginHeader from "../subComponents/LoginHeader";
import SearchBar from "../subComponents/searchbar";
import Toast from "../subComponents/toast";
import DisplayLubricants from "./Components/DisplayLubricants";
import DisplayTyres from "./Components/DisplayTyres";
import LoginModal from "./Components/LoginModal";
import MyCart from "./Components/MyCart";
import MyOrders from "./Components/MyOrders";
import OnboardingBanner from "./Components/OnboardingBanner";
import OrderDetails from "./Components/OrderDetails";
import ProductDetails from "./Components/ProductDetails";
import { BallTriangle } from 'react-loader-spinner'

import './index.css'

const LogiMart=(props)=>{

    const toastObject = useSelector((state) => state?.app?.toastObject);
    const {user, sideBar}= props;
    const obj = JSON.parse(user);
    const userIdSession = obj?.user?.userId;
    const [crtBtn, setCrtBtn]= useState("Tyres");
    const [screenBtn, setScreenBtn]= useState("");
    const [prodId, setProdId]= useState();
    const [tyreLimit, setTyreLimit]=useState(0);
    const [lubLimit, setLubLimit]=useState(0);
    const [search, setSearch]=useState("");
    const [loginStatus, setLoginStatus] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth );

    const {logimartProductList, productDetails,productLoader}= useSelector((state)=>state?.ecom);
    const [loader,setLoader] = useState(false);
    const dispatch= useDispatch();
    useEffect(()=>{
        setTyreLimit(0);
        setLubLimit(0);
        
        dispatch(getCartDetailsAction((res) => {
            console.log("res", res);
            if (res?.status == 200) {
              // setCartBtn("Go to Cart");
            }
          }
          ))
    },[])
    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    console.log("tyre lists 1", logimartProductList);
    console.log("tyre lists 2", logimartProductList);

    const {cartDetails}= useSelector((state)=>state?.ecom);
    const itemNum= cartDetails?.resp?.[0]!=undefined? cartDetails?.resp.length:0;
    const productCount=logimartProductList?.count;
    const loadTyres=(search, offset, limit)=>{
        setTyreLimit(limit);
        console.log("asdsadadlubs1")
        setLoader(true)
        dispatch(getProductListingAction({
            search:search,
            offset:offset,
            limit:limit,
            type:0
        },(res)=>{
            setLoader(false);
        }));
    }

    const loadLub=(search, offset, limit)=>{
        setLubLimit(limit);
        setLoader(true)
        console.log("asdsadadlubs");
        dispatch(getProductListingAction({
            search:search,
            offset:offset,
            limit:limit,
            type:2
        },()=>{
            setLoader(false)
        }));
    }


    const handleLoadMore=()=>{
        if(crtBtn=="Tyres"){
            loadTyres("",0,tyreLimit+12);
        }else{
            loadLub("", 0, lubLimit+12);
        }
    }

    const findSearch=()=>{
        if(crtBtn=="Tyres"){
            loadTyres(search,0,12);
        }else{
            loadLub(search, 0,12);
        }
    }

    useEffect(()=>{
        if(crtBtn=="Tyres"){
            loadTyres(search,0,12);
        }else{
            loadLub(search, 0,12);
        }
    },[search])

    const handleMyOrders=()=>{
        // dispatch(getOrderListAction({

        // }, (res)=>{
        //     console.log("res", res);

        // }))
    }

    // open product details modal
    const [open, setOpen]= useState(false);

    const closeModal=()=>{
    setOpen(false);
    }

    const showModal=()=>{
    setOpen(true);
    }

    const toggleModal=()=>{
    setOpen(!open);
    }



    // open my cart modal
    const [open1, setOpen1]= useState(false);

    const closeModal1=()=>{
    setOpen1(false);
    }

    const showModal1=()=>{
    setOpen1(true);
    }

    const toggleModal1=()=>{
    setOpen1(!open1);
    }


    // open my order detailed view of order
    const [open2, setOpen2]= useState(false);

    const closeModal2=()=>{
    setOpen2(false);
    }

    const showModal2=()=>{
    setOpen2(true);
    }

    const toggleModal2=()=>{
    setOpen2(!open2);
    }

    // open my order detailed view of order
    const [open3, setOpen3]= useState(false);

    const closeModal3=()=>{
    setOpen3(false);
    }

    const showModal3=()=>{
    setOpen3(true);
    }

    const toggleModal3=()=>{
    setOpen3(!open3);
    }

    // open emi form
    const [open4, setopen4]= useState(false);

    const closeModal4=()=>{
    setopen4(false);
    }

    const showModal4=()=>{
    setopen4(true);
    }

    const toggleModal4=()=>{
    setopen4(!open4);
    }

    // open login form
    const [open5, setopen5]= useState(false);

    const closeModal5=()=>{
    setopen5(false);
    }

    const showModal5=()=>{
    setopen5(true);
    }

    const toggleModal5=()=>{
    setopen5(!open5);
    }

    return(
       <>
       {
        (!user)?
        <LoginHeader 
        setLoginStatus={setLoginStatus}
        showModal={showModal5}/>:<></>
       }
       <div className="container topMargin sideBarMargin" style={{marginLeft: sideBar? '230px' : '100px',maxWidth: sideBar ?windowWidth - 260 :windowWidth- 130}} >
        {
            (!user)?
            <div className="TopBanner row" style={{position:"relative"}}>
                <div style={{position:"absolute", left:'45%', top:"0"}}>
                    <SvgImage name="bannerRect1" />
                </div>
                <div style={{position:"absolute", left:'50%', bottom:"0"}}>
                    <SvgImage name="bannerRect2" />
                </div>
                <div style={{position:"absolute", left:'20%', bottom:"0"}}>
                    <SvgImage name="bannerRect3" />
                </div>
                <div style={{position:"absolute", left:'75%', top:"0"}}>
                    <SvgImage name="bannerRect4" />
                </div>
                <div style={{position:"absolute", left:'83%', top:"0", zIndex:"100"}}>
                    <SvgImage name="bannerRect1" />
                </div>
                <div className="Bold heading2 col-4">
                    <div>Unlock all the benefits of LogiPe's</div>
                    <div className="padding16B">Expense Management!</div>
                    <button className="whiteBackground Bold text4 startBtn padding8" onClick={showModal3}>
                        Get Started
                    </button>
                </div>
                <div className="flex1 col-5"></div>
                <div className="col-3" style={{textAlign:"right",  zIndex:"400"}}>
                    <SvgImage name="yellowBannerIcon" />
                </div>
            </div>
            :<></>
        }
        <div className="padding16 row padding0L">
            <div className="col-1 padding0L padding0R" onClick={()=>{
                loadTyres("",0,12);
                // setTyreLimit(0);
                setCrtBtn("Tyres");
                setScreenBtn("");
            }}>
                <button className={crtBtn=="Tyres"?"Medium text2 greenButton padding16L padding16R":"Medium text2 greenOutlineButton padding16L padding16R"}>
                    Tyres</button>
            </div>
            <div className="col-2 padding0L" style={{marginLeft:"-0.5rem"}} onClick={()=>{
                loadLub("", 0, 12);
                // setLubLimit(0);
                setCrtBtn("Oil & Lubricants");
                setScreenBtn("");
            }}>
                <button className={crtBtn==="Oil & Lubricants"?"greenButton Medium text2 padding8L padding8R":"greenOutlineButton Medium text2 padding8L padding8R"}>
                    Oil & Lubricants</button>
            </div>
        </div>
        <div className="whiteBackground padding16 row">
            <div className="row padding0R">
                {
                    (crtBtn!=="")?
                    <div className="col-8 padding8T">
                        <SearchBar
                            placeholder="Search for products"
                            height="46"
                            value={search}
                            onChange={(event) => {
                            // dispatch(clearAllDrivers());
                            setSearch(event.target.value);
                            }}
                            findSearch={findSearch}
                        />
                    </div>
                    :<div className="col-8 padding8T"></div>
                }
                <div className="col-1"></div>
                <div className="col-3 padding0" style={{display:"flex", paddingLeft:"1rem", position:"relative"}}>
                    <div className="padding8 padding16T" onClick={()=>{
                        // setScreenBtn("My Cart");
                        showModal1();
                    }}>
                        <button className={screenBtn=="My Cart"?"Medium text2 greenButton":"Medium text2 greenOutlineButton"} style={{paddingLeft:"1rem", paddingRight:"1rem"}}>
                            My Cart</button>
                    </div>
                    {
                        itemNum!==0?
                        <div className="ItemNum">{itemNum}</div>
                        :<></>
                    }
                    
                    <div className="padding8 padding16T" onClick={()=>{
                        setScreenBtn("My Orders");
                        setCrtBtn("");
                        handleMyOrders();
                    }}>
                        <button className={screenBtn=="My Orders"?"Medium text2 greenButton":"Medium text2 greenOutlineButton"} style={{paddingLeft:"1rem", paddingRight:"1rem"}}>
                            My Orders</button>
                    </div>
                </div>
            </div>
            <div className="heading2 Bold padding16T padding16B">{crtBtn}</div>
            <div className="row">
                {
                    productLoader && tyreLimit<=12?
                    <div className="mt-2 boxContainer" style={{padding:70,justifyContent:'center',alignItems:'center'}}>
            <BallTriangle
              height={70}
              width={70}
              radius={5}
              color="#ED752E"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </div>
          :
                    crtBtn=="Tyres"?
                    <DisplayTyres
                    showModal={showModal}
                    setProdId={setProdId}
                    />
                    :crtBtn=="Oil & Lubricants"?
                    <DisplayLubricants
                    showModal={showModal}
                    setProdId={setProdId}
                    />
                    :<MyOrders 
                    setCrtBtn={setCrtBtn}
                    setScreenBtn={setScreenBtn}
                    openEmi={open4}
                    onHideEmi={closeModal4}
                    toggleModalEmi={toggleModal4}
                    handleCloseEmi={closeModal4}
                    showEmi={showModal4}
                    />
                }
            </div>
            { 
                (productCount>=lubLimit) && !loader ?
                <div className="flexRow Bold heading2 loadingText" style={{padding:"1rem ",justifyContent:'center',alignItems:'center'}}
                onClick={handleLoadMore}>Load More 
                <div className="padding8L">
                    <SvgImage name="nextArrow" />
                </div> 
                </div>
                :loader&& !(productLoader && tyreLimit<=12)
                ?<>
                <div className="mt-2 boxContainer" style={{padding:"1rem",justifyContent:'center',alignItems:'center'}}>
                <BallTriangle
                    height={20}
                    width={20}
                    radius={5}
                    color="#ED752E"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                    />
                </div>
                </>
                :<></>
            }
            
        </div>
       </div>
       <OrderDetails 
            open={open2}
            onHide={closeModal2}
            toggleModal={toggleModal2}
            handleClose={closeModal2}
            openEmi={open4}
            onHideEmi={closeModal4}
            toggleModalEmi={toggleModal4}
            handleCloseEmi={closeModal4}
            showEmi={showModal4}
       />
       <ProductDetails 
            open={open}
            onHide={closeModal}
            toggleModal={toggleModal}
            handleClose={closeModal}
            user={user}
            userIdSession={userIdSession}
            prodId={prodId}
            showModal1={showModal1}
            showModal={showModal5}
       />
       <MyCart 
            open={open1}
            onHide={closeModal1}
            toggleModal={toggleModal1}
            handleClose={closeModal1}
            openEmi={open4}
            onHideEmi={closeModal4}
            toggleModalEmi={toggleModal4}
            handleCloseEmi={closeModal4}
            showEmi={showModal4}
            setCrtBtn={setCrtBtn}
            setScreenBtn={setScreenBtn}
            // openEmi={open5}
            // onHideEmi={closeModal5}
            // toggleModalEmi={toggleModal5}
            // handleCloseEmi={closeModal5}
            // showEmi={showModal5}
       />
       <OnboardingBanner 
            open={open3}
            onHide={closeModal3}
            toggleModal={toggleModal3}
            handleClose={closeModal3}
       />
        {toastObject.toastStatus ? (
            <Toast
            success={toastObject?.status}
            message={toastObject?.message}
            toggleModal={()=>{
                dispatch(
                    toastStatus({
                      toastStatus: false,
                    })
                  );
                //dispatch(cleanEcomLoader());
            }}
            isOpen={toastObject?.toastStatus}
            name="logimart"
            handleClose={() => {
                // dispatch(
                //   toastStatus({
                //     toastStatus: false,
                //     message: "",
                //     status: true,
                //   })
                // );
                // if (!toastObject?.toastStatus)
                window.location = "/logimart";
            }}
            />
        ) : (
            <></>
        )}
       <LoginModal
       open={open5}
       onHide={closeModal5}
       toggleModal={toggleModal5}
       handleClose={closeModal5}
       loginStatus={loginStatus}
       setLoginStatus={setLoginStatus}
       />
       <ToastContainer theme="dark" position="top-right"
          closeOnClick pauseOnHover
          autoClose={4000}/>
       </> 
    )
}

export default LogiMart;