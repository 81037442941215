import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, FormGroup, Form } from "reactstrap";
import "./index.css";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import {
  editTripExpenseAction,
  getTripExpenseAction,
  getVehicleLastTripAction,
  startTripExpenseAction,
} from "../../../../services/Store/Actions/TripsAction";
import Toast from "../../../subComponents/toast";
import EnterPinModal from "../EnterPinModal";
import { ClipLoader } from "react-spinners";

function StartTripModal(props) {
  const {
    toggle,
    modal,
    setModal,
    tripData,
    status,
    advancePaymentData,
    handleCloseAdvance,
    setItemTemp,
    vendorId,
  } = props;
  const [openToast, setOpenToast] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openToastFailure, setOpenToastFailure] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [openToastFailureText, setOpenToastFailureText] = useState(
    "Error in starting trip"
  );

  const [openPinModal, setOpenPinModal] = useState(false);

  const dispatch = useDispatch();
  const datepickerRefEnd = useRef();
  const [vehicleTripDetail, setVehicleTripDetail] = useState({});
  let lastTrip = new Date(vehicleTripDetail?.trips?.[0]?.endDate || new Date());
  let lastTrip6 = new Date(lastTrip.setHours(lastTrip.getHours() + 6));
  const handleToggleModal = () => {
    setOpenPinModal(!openPinModal);
  };
  const handleCloseModal = () => {
    setOpenPinModal(false);
  };
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClose = () => {
    setModal(false);
  };
  useEffect(() => {
    dispatch(
      getVehicleLastTripAction({ fleetId: tripData?.fleetId }, (res) => {
        if (res.status == 200) {
          console.log("getVehicleLastTripAction", res?.data?.data);
          setVehicleTripDetail(res?.data?.data);
        }
      })
    );
  }, [tripData?.fleetId]);
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // scrollable={true}
        centered={true}
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px" }}
        >
          <div className="PGCollectionBox">
            <div className=/*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction">
              <div className="closeEffect" onClick={handleClose}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
            </div>
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Start Trip
            </div>

            <Formik
              // isValid='true'
              enableReinitialize
              // validationSchema={validationSchema}
              initialValues={{
                startTime: tripData?.expectedStartTime || "",
                startKmReading: tripData?.startKmReading || "",
                idleReason: "",
              }}
              onSubmit={(values) => {
                console.log("poiupoi", status);
                if (status == "1") {
                  handleToggleModal();
                } else {
                  let data = {
                    tripId: tripData?.tripId,
                    driverAdvance: advancePaymentData[1].advance || 0,
                    driverTotal: advancePaymentData[1].expacted || 0,
                    vendorAdvance: advancePaymentData[3].advance || 0,
                    vendorTotal: advancePaymentData[3].expacted || 0,
                    fuelAdvance: advancePaymentData[0].advance || 0,
                    fuelTotal: advancePaymentData[0].expacted || 0,
                    fastagTotal: advancePaymentData[2].expacted || 0,
                    cashTotal: vendorId
                    ? advancePaymentData[4].expacted
                    : advancePaymentData[3].expacted || 0,
                    // totalCardExpense: advancePaymentData[1].expacted || 0,
                    // totalCashExpense: advancePaymentData[4].expacted || 0,
                    // totalFuelExpense: advancePaymentData[0].expacted || 0,
                    // totalFastagExpense: advancePaymentData[2].expacted || 0,
                    // totalDriverExpense: advancePaymentData[1].expacted || 0,
                    cashData: advancePaymentData?.filter(
                      (item) => item.header == "Cash"
                    ),
                  };
                  setLoader(true);
                  dispatch(
                    editTripExpenseAction(data, (res) => {
                      console.log("editTripExpenseAction", res);
                      setLoader(false);
                      if (res.status == 200) {
                        let payload = {
                          tripId: tripData?.tripId,
                          startTime: values?.startTime || new Date(),
                          startKmReading: values?.startKmReading,
                          idleReason: values?.idleReason,
                          pay: status,
                        };
                        dispatch(
                          startTripExpenseAction(payload, (res) => {
                            console.log("asdfghewrwqertewr", res);
                            if (res?.status == 200) {
                              setOpenToast(true);
                              // handleClose();
                              setToastMsg("Trip Started Successfully");
                              handleCloseModal();
                            } else {
                              setOpenToastFailure(true);
                              setOpenToastFailureText(
                                res?.data?.message || "Error in Trip Start"
                              );
                            }
                          })
                        );
                      } else {
                        setOpenToastFailure(true);
                        setOpenToastFailureText("Error Save Details");
                      }
                    })
                  );
                }
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "50px", padding: "0 75px 0 50px" }}
                >
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormGroup>
                      <label className="heading3 semiBold">Start Date</label>
                      <div className="searchBox" style={{ width: "200px" }}>
                        <DatePickerField
                          classname="searchBar-input"
                          name="startTime"
                          header="Select Start date"
                          value={values.startTime}
                          onChange={(v) => {
                            setFieldValue("startTime", v, true);
                          }}
                          ref1={datepickerRefEnd}
                          style={{ width: "100px" }}
                          showTimeInput={true}
                        />
                        <label
                          className="date-input"
                          for="startTime"
                          style={{
                            position: "relative",
                            top: "-5px",
                            left: "-2rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleClickDatepickerIcon2();
                          }}
                        >
                          <SvgImage
                            name="CalenderLogo"
                            width="32"
                            height="32"
                          />
                        </label>
                      </div>
                      <div
                        className="heading4 Regular"
                        style={{ margin: "7px 0 0px 0", color: "red" }}
                      >
                        {errors.startTime && touched.startTime ? (
                          <div>*{errors.startTime}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label className="">Odometer Reading:</label>
                      <input
                        type="text"
                        id="startKmReading"
                        className={`form-control-1 ${
                          touched.startKmReading && errors.startKmReading
                            ? "is-invalid"
                            : ""
                        }`}
                        name="startKmReading"
                        onChange={(event) => {
                          // setSearch(event.target.value);
                          setFieldValue(
                            "startKmReading",
                            event.target.value,
                            true
                          );
                        }}
                        value={values.startKmReading}
                        touched={touched.startKmReading}
                        errors={errors.startKmReading}
                        placeholder="Enter Odometer reading"
                      />
                    </FormGroup>
                  </FormGroup>
                  {lastTrip6 < values?.startTime ? (
                    <FormGroup style={{ flex: 1 }}>
                      <label for="idleReason" className="heading3 semiBold">
                        Enter Idle Reason:
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <textarea
                          rows={2}
                          type="text"
                          id="idleReason"
                          className={`input-body-new ${
                            touched.idleReason && errors.idleReason
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{ width: "100%", marginTop: "16px" }}
                          name="idleReason"
                          onChange={handleChange("idleReason")}
                          value={values.idleReason}
                          touched={touched.idleReason}
                          errors={errors.idleReason}
                          placeholder="Please enter idle reason"
                        />
                      </div>
                    </FormGroup>
                  ) : null}

                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "25px",
                      marginTop: "30px",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{ width: "25%" }}
                      className="generateOtp text3 Medium btn btn-secondary"
                    >
                      {loader ? (
                        <>
                          <ClipLoader
                            color={"#fff"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <Toast
        success={openToast}
        message={toastMsg || "Trip Started Successfully"}
        isOpen={openToast}
        name="trips"
        handleClose={() => {
          setOpenToast(false);
          handleClose();
          handleCloseAdvance();
          dispatch(
            getTripExpenseAction({ tripId: tripData?.tripId }, (res) => {
              if (res?.status == 200) {
                if (res?.data?.data?.trip) {
                  setItemTemp(res?.data?.data?.trip);
                }
              }
            })
          );
        }}
      />
      <Toast
        success={false}
        message={openToastFailureText}
        isOpen={openToastFailure}
        name="trips"
        handleClose={() => {
          setOpenToastFailure(false);
          handleClose();
          handleCloseAdvance();
        }}
      />
      <EnterPinModal
        toggleModal={handleToggleModal}
        handleClose={handleCloseModal}
        open={openPinModal}
        onSubmit={(values) => {
          let data = {
            tripId: tripData?.tripId,
            driverAdvance: advancePaymentData[1].advance || 0,
            driverTotal: advancePaymentData[1].expacted || 0,
            vendorAdvance: advancePaymentData[3].advance || 0,
            vendorTotal: advancePaymentData[3].expacted || 0,
            fuelAdvance: advancePaymentData[0].advance || 0,
            fuelTotal: advancePaymentData[0].expacted || 0,
            fastagTotal: advancePaymentData[2].expacted || 0,
            cashTotal: vendorId
              ? advancePaymentData[4].expacted
              : advancePaymentData[3].expacted || 0,
            // totalCardExpense: advancePaymentData[1].expacted || 0,
            // totalCashExpense: advancePaymentData[4].expacted || 0,
            // totalFuelExpense: advancePaymentData[0].expacted || 0,
            // totalFastagExpense: advancePaymentData[2].expacted || 0,
            // totalDriverExpense: advancePaymentData[1].expacted || 0,
            cashData: advancePaymentData?.filter(
              (item) => item.header == "Cash"
            ),
          };

          dispatch(
            editTripExpenseAction(data, (res) => {
              console.log("editTripExpenseAction", res);
              if (res.status == 200) {
                let payload = {
                  pin: values?.pin,
                  tripId: tripData?.tripId,
                  startTime: new Date(),
                  pay: "1",
                };

                dispatch(
                  startTripExpenseAction(payload, (res) => {
                    if (res?.status == 200) {
                      setOpenToast(true);
                      handleClose();
                      setToastMsg(
                        "Advance payment to driver added Successfully"
                      );
                      handleCloseModal();
                    } else {
                      setOpenToastFailure(true);
                      setOpenToastFailureText(
                        res?.data?.message || "Error in Advance payment"
                      );
                    }
                  })
                );
              } else {
                setOpenToastFailure(true);
                setOpenToastFailureText(
                  res?.data?.message || "Error Save Details"
                );
              }
            })
          );
        }}
      />
    </div>
  );
}

export default StartTripModal;
