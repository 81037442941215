import React, { useState } from "react";
import { Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import PinInput from "react-pin-input";
import { Formik } from "formik";
import SvgImage from "../../Icons/Icons";
import { transferWalletTransferAmount } from "../../../services/Store/Actions/cardWalletAction";
import { transferFastagTransferAmount } from "../../../services/Store/Actions/fastagAction";
import { useDispatch ,useSelector} from "react-redux";
import "./index.css";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  amount: Yup.number().typeError("Only Numbers are allowed")
    .required("Required")
    .min(1, "Amount should be greater than 1"),
  pin: Yup.string()
    .required("Required")
    .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
});

function CardWalletAddBalanceModal(props) {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    amount,
    ownerLogipeBalance,
    color,
  } = props;
  const {loader} = useSelector(
    (state) => state?.fastag
  );
  const dispatch = useDispatch();
  // const ownerBalance = ownerLogipeBalance === "" ? 0 : ownerLogipeBalance;
  //console.log("blanace", ownerBalance);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-content" style={{width:"500px"}} >
        <div className="modal-head-part">
          <p className="Add-balance-title">{title} - Add Balance</p>
          <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          <div className="card-wallet-main-body">
            <div className="card-wallet-body1">
              <SvgImage
                name="BankLogo"
                height="43.81"
                width="43.81"
                classname="bank-card-wallet"
              />
              <div
                className="card-wallet-main-sub-body"
                style={{ position: "relative", top: "-41px", left: "-15px" }}
              >
                <p className="card-wallet-sub-body1">LogiPe Account</p>
                <p className="card-wallet-sub-body2">
                  {"₹" + ownerLogipeBalance}
                </p>
              </div>
            </div>

            <div className={"card-wallet-body2 " + color}>
              {title === "Card Wallet" ? (
                <SvgImage
                  name="cardWalletLogo"
                  height="43.81"
                  width="43.28"
                  classname="card-wallet-logo-cardWallet"
                />
              ) : (
                <SvgImage
                  name="tollBooth"
                  height="43.81"
                  width="43.28"
                  classname="card-wallet-logo-cardWallet"
                />
              )}
              <div className="card-wallet-main-sub-body">
                <p className="card-wallet-sub-body1">{title}</p>
                <p className="card-wallet-sub-body2">{"₹" + amount}</p>
              </div>
            </div>

            <span class="circle-logo-forward-arrow">
              <SvgImage name="ForwardArrow" height="36" width="36" />
            </span>
          </div>

          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              amount: "",
              pin: "",
            }}
            onSubmit={(values, actions) => {
              //console.log("values onSubmit", values);

              if (title === "Card Wallet") {
                dispatch(
                  transferWalletTransferAmount(values, (res) => {
                    console.log("transfer wallet call back", res.data);
                    actions.setSubmitting(false);
                    if (res?.data?.type === "success") {
                      
                      handleClose();
                      //alert("Money added succesfully")
                      
                    }
                  })
                );
              }
              if (title === "Fastag Wallet") {
                console.log("Fastag WalletFastag WalletFastag Wallet");
                dispatch(
                  transferFastagTransferAmount(values, (res) => {
                    console.log("transfer fastag call back", res.data);
                    actions.setSubmitting(false);
                    if (res?.data?.type === "success") {
                      handleClose();
                      //alert("Money added succesfully")
                      
                    }
                  })
                );
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">1</span>
                    <p className="sub-head-3">Enter amount</p>
                  </div>
                  <p className="sub-head-body">
                    Amount will be deducted from owner’s LogiPe account
                  </p>

                  <label for="html" className="label-body">
                    ₹
                  </label>
                  <input
                    type="text"
                    id="amount"
                    className={`input-body `}
                    name="amount"
                    onChange={handleChange("amount")}
                    value={values.amount}
                    onBlur={handleBlur("amount")}
                    touched={touched.amount}
                    errors={errors.amount}
                    placeholder="Enter amount"
                  />
                  <div className="input-amount"></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.amount && touched.amount ? (
                      <div>*{errors.amount}</div>
                    ) : null}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">2</span>
                    <p className="sub-head-4">Enter 4-digit Security Pin</p>
                  </div>
                  <p className="sub-head-body">
                    Security pin is needed to confirm payment
                  </p>
                  <PinInput
                    length={4}
                    initialValue=""
                    secret
                    onChange={handleChange("pin")}
                    values={values.pin}
                    //handleChange={handleChange("code")}
                    type="numeric"
                    inputMode="number"
                    style={{
                      padding: "10px",
                      marginLeft: "96px",
                      marginTop: "28px",
                    }}
                    inputStyle={{
                      border: "1px solid #A7A7A7",
                      borderRadius: "8px",
                      marginRight: "20px",
                    }}
                    //onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.pin && touched.pin ? (
                      <div>*{errors.pin}</div>
                    ) : null}
                  </div>
                  {/* <button type="submit" className="butt4">
                    Add Balance to {title}
                  </button> */}
                  <div style={{padding:"80px 0px 10px 60px", margin:"0" }}>
                  <button type="submit" className="butt4" disabled={loader?true:false} 
                    style={{margin:"0" }}>
                      
                      {loader?"Please Wait":`Add Balance to ${title}`}

                  </button>
                  </div>
                </FormGroup>
                {/* <div className="space-div"></div> */}
              </Form>
            )}
          </Formik>
        </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default CardWalletAddBalanceModal;
