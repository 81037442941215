import React, { useState, useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import AutorechargeHome from "../../../Modals/AutorechargeHome";
import AutorechargeSelectVehicles from "../../../Modals/AutorechargeSelectVehicles";
import AutorechargeConfirm from "../../../Modals/AutorechargeConfirm";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { advancedVehicleListAction } from "../../../../services/Store/Actions/fleetActions";
import Requests from "../../../Modals/Requests";
import { useNavigate } from "react-router-dom";
import CardWalletAddBalanceModal from "../../../Modals/cardWalletAddBalance";
function VehicleHeader(props) {
  const navigate= useNavigate();

  const [open, setOpen] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);
  const [assignedStatus, setAsssignedStatus] = useState(3);
  const { ownerBalance } = useSelector((state) => state?.manageCard); //LOgipe CARD BALANCE
  const LogipeAccounBal =
  ownerBalance?.instrument_balance?.balance == null
    ? 0
    : ownerBalance?.instrument_balance?.balance;  
  const { advancedVehicleList, advancedVehicleListCount } = useSelector(
    (state) => state?.fleet
  );

  const [ftWRechargeModal, setFtWRechargeModal] = useState(false);
function toggleftWRechargeModal(){
  setFtWRechargeModal(!ftWRechargeModal)
}
  
  // const handleUserId = (val) => {
  //   handleUser(val);
  //   //onsole.log("useState change ho rha hai kya yahi ", val);
  // };

  const urlSearchParams = new URLSearchParams(window.location.search);

  const params = Object.fromEntries(urlSearchParams.entries());
  // console.log("url split", searchParam);

  let searchParam = params.search;

  console.log("url split", searchParam);

  const [search, setSearch] = useState(
    searchParam == undefined ? "" : searchParam
  );

  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);
  const [offset, setOffset] = useState(offsetVal);

  const dispatch = useDispatch();
  const [rechargeRank, setRechargeRank] = useState(0);

  //console.log("NEWMOON",Data[0].regNumber)

  const [fleetId, setFleetId] = useState("");

  // auto recharge home page
  const [openAutoRecharge, setOpenAutoRecharge] = useState(false);

  const showRecharge = () => {
    setOpenAutoRecharge(true);
  };

  const toggleRecharge = () => {
    setOpenAutoRecharge(!openAutoRecharge);
    if (openAutoRecharge) {
      // dispatch(
      //   getVehicleListAction({
      //     offset: offsetVal,
      //     search: search.trim(),
      //     limit: 15,
      //     minKyc: "",
      //     sortAs: "",
      //     sortBy: "",
      //   })
      // );
      dispatch(
        advancedVehicleListAction({
          offset: offsetVal,
          search: search.trim(),
          limit: 15,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          activeStatus: activeStatus,
          assignedStatus: assignedStatus,
        })
      );
    }
  };

  const closeRecharge = () => {
    setOpenAutoRecharge(false);
    // dispatch(
    //   getVehicleListAction({
    //     offset: offsetVal,
    //     search: search.trim(),
    //     limit: 15,
    //     minKyc: "",
    //     sortAs: "",
    //     sortBy: "",
    //   })
    // );
    dispatch(
      advancedVehicleListAction({
        offset: offsetVal,
        search: search.trim(),
        limit: 15,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: activeStatus,
        assignedStatus: assignedStatus,
      })
    );
  };

  // auto recharge select vehicles
  const [openRechargeVehicles, setOpenRechargeVehicles] = useState(false);

  const showRechargeVeh = () => {
    setOpenRechargeVehicles(true);
  };

  const toggleRechargeVeh = () => {
    setOpenRechargeVehicles(!openRechargeVehicles);
    if (openRechargeVehicles) {
      // dispatch(
      //   getVehicleListAction({
      //     offset: offsetVal,
      //     search: search.trim(),
      //     limit: 15,
      //     minKyc: "",
      //     sortAs: "",
      //     sortBy: "",
      //   })
      // );
      dispatch(
        advancedVehicleListAction({
          offset: offsetVal,
          search: search.trim(),
          limit: 15,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          activeStatus: activeStatus,
          assignedStatus: assignedStatus,
        })
      );
    }
  };

  const closeRechargeVeh = () => {
    setOpenRechargeVehicles(false);
    // dispatch(
    //   getVehicleListAction({
    //     offset: offsetVal,
    //     search: search.trim(),
    //     limit: 15,
    //     minKyc: "",
    //     sortAs: "",
    //     sortBy: "",
    //   })
    // );
    dispatch(
      advancedVehicleListAction({
        offset: offsetVal,
        search: search.trim(),
        limit: 15,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        activeStatus: activeStatus,
        assignedStatus: assignedStatus,
      })
    );
  };

  // auto recharge specific vehicle
  const [openRechargeOne, setOpenRechargeOne] = useState(false);
  const [rechargeFleetId, setRechargeFleetId] = useState("");
  const showRechargeOne = (fleetId) => {
    setRechargeFleetId(fleetId);
    setOpenRechargeOne(true);
  };

  const toggleRechargeOne = () => {
    setOpenRechargeOne(!openRechargeOne);
  };

  const closeRechargeOne = () => {
    setOpenRechargeOne(false);
  };

  const [vehNo, setVehNo] = useState("");
  const [limit, setLimit] = useState(0);
  const [showroomModal, setShowroomModal] = useState(false);
  const [showRequest,setshowRequest] = useState(false);
  const showroomModalFunc = () => {
    console.log("invoiceModal", showroomModal);
    setShowroomModal(!showroomModal);
  };

  const showRequests = () => {
    console.log("invoiceModal", showroomModal);
    setshowRequest(!showRequest);
  };
  const vehiclehistory = ()=>{
    navigate("/VehicleHistory",{state:{
      path: 'all',
    }});
  }

  const { fastagWallet } = useSelector((state) => state?.fastag);
  console.log("-----------------", fastagWallet);
  const autoRechargeState = fastagWallet?.autoRecharge;

  const counting = advancedVehicleListCount;
  return (
    
 

    <div className="row semiBold" style={{marginBottom: "10px"}}>
      <div className="col-md-6 vehicleHeadCard autoRechargeBtn " >
          <div className="row">
          <div className="col-md-9" style={{padding: "5px"}}>
            
            <div className="row semiBold" style={{paddingLeft: "5px"}}>
                <div className="col-md-8">FASTag Wallet Balance:</div>
                <div className="col-md-4"> ₹ {fastagWallet?.balance}</div>
            </div>
            <div style={{marginTop: "10px"}}>
              <div
                className="row  heading3 semiBold"
              >
                <div className="col-1" style={{ marginTop:"5x" }}>
                  <SvgImage name="rechargeIcon" />
                </div>
                <div className="col-7 semiBold" style={{ alignContent:"center",marginTop:"5x" }}>
                  Auto Recharge FASTag
                </div>
                <div
                  className="col-4 semiBold"
                  style={{ color: "#2E7C7B", cursor: "pointer" }}
                  onClick={showRecharge}
                >
                  {autoRechargeState ? "ON  " : "OFF  "}
                  <SvgImage name="linkArrow" paddedLeft="" />
                </div>
                {/* <div className="col-1" style={{ marginTop:"9px"  }}>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{display:"flex", alignItems:"center"}}>
                <button className="ButtonHead" onClick={toggleftWRechargeModal}>
                    Recharge
                </button>
          </div>
          </div>
         

        </div>
        
        <div className="col-md-3 vehicleHeadCard" style={{display: "flex", alignItems:"center"}}>
         <div className="row" style={{display: "flex", alignItems:"center"}}>
              <div className="col-md-1" style={{ padding: "4px" }}>
                <SvgImage name="newCalendarV2" />
              </div>
              <div className="col-md-10" style={{ padding: "4px 4px 4px 20px" }}>
                All Vehicle History
              </div>
              <div
                className="col-md-1"
                style={{ padding: "4px", color: "#485B7C", cursor: "pointer",textAlign:'right' }}
                onClick={vehiclehistory}
              >
                <SvgImage name="linkArrow" paddedLeft="6px"  />
              </div>
            </div>

        </div>
        <div className="col-md-3 vehicleHeadCard" style={{display: "flex", alignItems:"center"}}>
          <div
            onClick={showRequests}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
          <div className="row" style={{display: "flex", alignItems:"center"}}>
            <div
              className="col-md-3"
            >
                <SvgImage
                  name="cash1"
                  classname="Truck-logo-for-total-vehicle"
                />
            </div>
            <div className="col-md-8">
              All Requests
            </div>
            <div
                className="col-md-1"
                style={{ padding: "4px", color: "#485B7C", cursor: "pointer",textAlign:'right' }}
                onClick={vehiclehistory}
              >
                <SvgImage name="linkArrow" paddedLeft="6px"  />
              </div>
          </div>
          </div>

        </div>

        {openAutoRecharge ? (
            <AutorechargeHome
              open={openAutoRecharge}
              onHide={closeRecharge}
              toggleModal={toggleRecharge}
              autoState={autoRechargeState ? true : false}
              showRechargeVeh={showRechargeVeh}
              showRechargeOne={showRechargeOne}
              setVehNo={setVehNo}
              setLimit={setLimit}
              setVehRank={setRechargeRank}
            />
          ) : null}
           {openRechargeVehicles ? (
            <AutorechargeSelectVehicles
              open={openRechargeVehicles}
              onHide={closeRechargeVeh}
              toggleModal={toggleRechargeVeh}
            />
          ) : null}
           <AutorechargeConfirm
            open={openRechargeOne}
            onHide={closeRechargeOne}
            toggleModal={toggleRechargeOne}
            vehNo={vehNo}
            limit={limit}
            vehRank={rechargeRank}
            fleetId={rechargeFleetId}
          />
          <Requests
          toggle={showRequests}
          modal={showRequest}
          setModal={setshowRequest}
          regNo={props.regNo}
          fleetId={fleetId}
          // customerId={customerId}
        />
         <CardWalletAddBalanceModal
            open={ftWRechargeModal}
            toggleModal={toggleftWRechargeModal}
            title={"Fastag Wallet"}
            amount={fastagWallet?.balance}
            handleClose={toggleftWRechargeModal}
            ownerLogipeBalance={LogipeAccounBal}
          />
    </div>
  );
}
export default VehicleHeader;
