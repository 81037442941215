import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addFleet } from "../../../services/Store/Actions/fleetActions";
import { useNavigate } from "react-router-dom";
import { getVehicleListAction } from "../../../services/Store/Actions/manageVehicleAction";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";
// import { fleetDetailsSuccess } from "../../../services/Store/Actions/fleetActions";

const vehCatData = [
  { name: "Two Wheeler", value: 1 },
  { name: "Three Wheeler - Passenger", value: 2 },
  { name: "Three Wheeler - Freight", value: 3 },
  { name: "Car / Jeep / Van", value: 4 },
  { name: "Light Commercial Vehicle - 2 Axle", value: 5 },
  { name: "Light Commercial Vehicle - 3 Axle", value: 6 },
  { name: "Bus 2 Axle", value: 7 },
  { name: "Bus 3 Axle", value: 8 },
  { name: "Mini Bus", value: 9 },
  { name: "Truck 2 Axle", value: 10 },
  { name: "Truck 3 Axle", value: 11 },
  { name: "Truck 4 Axle", value: 12 },
  { name: "Truck 5 Axle", value: 13 },
  { name: "Truck 6 Axle", value: 14 },
  { name: "Truck Multi Axle ( 7 and above )", value: 15 },
  { name: "Earth moving machinery", value: 16 },
  { name: "Heavy Construction machinery", value: 17 },
  { name: "Tractor", value: 18 },
  { name: "Tractor with trailor", value: 19 },
  { name: "Tata Ace or similar mini light commercial vehicle", value: 20 },
];

function AddNewVehicleModal(props) {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    setRegNumber,
    setVehCat,
    setFleetId,
    setOffset,
    setColor,
  } = props;
  const validationSchema = Yup.object().shape({
    vehicleNumber: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z0-9]+$/, "Enter correct vehicle number"),
    // vehicleCategory: Yup.string().required("Required"),
  });
  const navigate = useNavigate();
  const { loader } = useSelector((state) => state?.fleet);

  const dispatch = useDispatch();
  // const fleetId = useSelector(
  //   (state) => state?.fleet?.fleetAdded?.dataValues?.fleetId
  // );
  // const regNo = useSelector(
  //   (state) => state?.fleet?.fleetAdded?.dataValues?.regNumber
  // );
  // const vehCat = useSelector(
  //   (state) => state?.fleet?.fleetAdded?.dataValues?.fleetCategory
  // );
  const count = useSelector((state) => state?.manageVehicle?.allFleets?.count);
  const offsetVal =
    (count + 1) % 15 === 0
      ? ((count + 1) / 15 - 1) * 15
      : Math.floor((count + 1) / 15) * 15;
  //console.log("fleet added",fleetAdded);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-content" style={{ width: "510px" }}>
          <div className="modal-head-part">
            <p className="Add-balance-title">Add New Vehicle</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                vehicleNumber: "",
                vehicleCategory: "",
              }}
              onSubmit={(values, actions) => {
                //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
                console.log("valuessss", values);

                dispatch(
                  addFleet(
                    {
                      vehicleNumber: values?.vehicleNumber,
                    },
                    (response) => {
                      actions.setSubmitting(false);
                      console.log("respppppppppp", response);
                      if (response?.type === "success") {
                        setRegNumber(response?.data?.dataValues?.regNumber);
                        setFleetId(response?.data?.dataValues?.fleetId);
                        setVehCat(response?.data?.dataValues?.fleetCategory);
                        dispatch(
                          getVehicleListAction({
                            offset: offsetVal,
                            search: "",
                            limit: 15,
                            minKyc: "",
                            sortAs: "",
                            sortBy: "",
                          })
                        );
                        console.log("/VehiclesV2/aaaaa",`/VehiclesV2/${response?.data?.dataValues?.fleetId}`)
                        setColor(count);
                        setOffset(offsetVal);
                        toggleModal();
                        navigate(
                          `/VehiclesV2/${response?.data?.dataValues?.fleetId}`
                        );
                        props.setOpenLink(true);

                        //window.location.reload();
                      } else {
                        //alert(response);
                      }
                    }
                  )
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <label className="sub-title-edit-vehicle-detial ">
                        Enter Vehicle Number
                      </label>
                    </div>
                    <input
                      type="text"
                      id="vehicleNumber"
                      className="input-body-3"
                      name="vehicleNumber"
                      value={values.vehicleNumber}
                      touched={touched.vehicleNumber}
                      errors={errors.vehicleNumber}
                      onBlur={()=>{
                        dispatch(sendAnalitics("Num_AddVeh_MV_FW_Web ",{
                          newVehNumber:values.vehicleNumber
                        }));
                        handleBlur("vehicleNumber");
                      } }
                      onChange={handleChange("vehicleNumber")}
                      placeholder="Enter vehicle number"
                    ></input>
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.vehicleNumber && touched.vehicleNumber ? (
                        <div>*{errors.vehicleNumber}</div>
                      ) : null}
                    </div>

                    <Button
                      type="submit"
                      className="butt-4"
                      style={{ marginTop: "4rem", marginLeft: "-27px" }}
                      disabled={loader ? true : false}
                      onClick={()=>{
                        dispatch(sendAnalitics("Sub_AddVeh_MV_FW_Web"));
                      }}
                    >
                      {loader ? "Please Wait" : "Add New Vehicle"}
                    </Button>
                  </FormGroup>

                  {/* <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">5</span>
                    <label className="sub-title-edit-vehicle-detial ">
                    Attach Rc Photo
                    </label>
                  </div>
                  <div>
                    <p className="sub-head-body mt-0">
                      Upload format must be in jpg, png, jpeg & pdf
                    </p>
                    <Button className="add-attach">
                      <SvgImage name="AddAttachment" height="36" width="36" />
                    </Button>

                  </div>
                </FormGroup> */}
                  <div></div>
                </Form>
              )}
            </Formik>
            {/* <div className="space-div"></div> */}
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default AddNewVehicleModal;
