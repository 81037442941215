import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import RecentTransactionBox from "../../../subComponents/recentTransaction";
import "./index.css";
import moment from "moment";
import {
  cleanVehicleHistory,
  vehicleHistory,
} from "../../../../services/Store/Actions/manageVehicleAction";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import SearchBar from "../../../subComponents/searchbar";
import { DatePickerField } from "../../../core/inputs/input";
import SelectVendorModal from "../Modal/SelectVendorModal";
import {
  cleanVendorTransaction,
  generateStatementForVendorAction,
  transferTorVendorAction,
  vendorGetSignedUrlAction,
  vendorTransactionAction,
  vendorUploadBillAction,
} from "../../../../services/Store/Actions/beneficiaryAction";
import PaymentModal from "../Modal/PaymentModal";
import PinModal from "../Modal/PinModal";
import SuccessModal from "../Modal/SuccessModal";
import { wait } from "@testing-library/user-event/dist/utils";
import NoTransaction from "../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/TabularData/NoTransaction.js";
import { ClipLoader } from "react-spinners";
import Toast from "../../../subComponents/toast";
function TransactionHistory(props) {
  const [offset, setOffset] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [selectVendorModal, setSelectVendorModal] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [offset2, setOffset2] = useState(0);
  const [enterPin, setEnterPin] = useState(false);
  const [paymentData, setPaymentData] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [vendorData, setVendorData] = useState(false);
  const [paymetModal, setPaymentModal] = useState(false);
  const [pinLoader, setPinLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [failedMsg, setFailedMsg] = useState();
  const { isLogipeVendor, search, setSearch } = props;
  const dispatch = useDispatch();
  const { vendorTransactionList, vendorTransaction } = useSelector(
    (state) => state?.beneficiary
  );
  const trxnCount = vendorTransaction?.count;
  console.log("vendorTransactionList", vendorTransactionList);
  const handlePageClick = (event) => {
    console.log("Clickess");
    const newOffset = event.selected * 7;
    setOffset(newOffset);
  };
  const datepickerRefStart = useRef();
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };

  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips,setIsTrips] = useState(0); 
  const [isCustomers,setIsCustomers] = useState(0); 
  const [isVendor,setIsVendor] = useState(0); 
  useEffect(()=>{
    setIsTrips(authUser?.company?.isTrips && authUser?.user?.type==2?3: authUser?.company?.isTrips && managerControlList?.tripAccess?managerControlList?.tripAccess:0)
    setIsCustomers(authUser?.company?.isCustomers && authUser?.user?.type==2?3: authUser?.company?.isCustomers && managerControlList?.customerAccess?managerControlList?.customerAccess:0)
    setIsVendor(authUser?.company?.isVendor && authUser?.user?.type==2?3: authUser?.company?.isVendor && managerControlList?.vendorAccess?managerControlList?.vendorAccess:0)
  },[authUser,managerControlList])


  useEffect(() => {
    dispatch(
      vendorTransactionAction({
        limit: 7,
        offset: offset,
        from: "",
        to: "",
        isLogipeVendor: isLogipeVendor,
      })
    );
    return () => {
      dispatch(cleanVendorTransaction());
      setStartDate("");
      setEndDate("");
      setVendorData({});
      setPaymentData({});
    };
  }, []);
  useEffect(() => {
    dispatch(cleanVendorTransaction());
    setStartDate("");
    setEndDate("");
    setVendorData({});
    setPaymentData({});
    dispatch(
      vendorTransactionAction({
        limit: 7,
        offset: offset,
        from: "",
        to: "",
        isLogipeVendor: isLogipeVendor,
      })
    );
  }, [isLogipeVendor]);
  useEffect(() => {
    dispatch(cleanVendorTransaction());
    dispatch(
      vendorTransactionAction({
        limit: 7,
        offset: offset,
        from: startDate || "",
        to: endDate || "",
        isLogipeVendor: isLogipeVendor,
      })
    );
  }, [startDate, endDate, isLogipeVendor]);
  function findSearch() {}
  const handlePrev = () => {
    if (offset > 0) {
      dispatch(cleanVendorTransaction());
      setOffset(offset - 7);
      dispatch(
        vendorTransactionAction({
          limit: 7,
          offset: offset - 7,
          from: startDate || "",
          to: endDate || "",
          isLogipeVendor: isLogipeVendor,
        })
      );
    }
  };
  const handleNext = () => {
    console.log("offset,trxnCount", offset, trxnCount);
    if (offset + 7 < trxnCount) {
      dispatch(cleanVendorTransaction());
      setOffset(offset + 7);
      dispatch(
        vendorTransactionAction({
          limit: 7,
          offset: offset + 7,
          from: startDate || "",
          to: endDate || "",
          isLogipeVendor: isLogipeVendor,
        })
      );
    }
  };
  return (
    <div className="vendorBg containerShadow" style={{ paddingTop: "1px" }}>
      {selectVendorModal && (
        <SelectVendorModal
          open={selectVendorModal}
          onHide={() => {
            setSelectVendorModal(false);
          }}
          toggleModal={() => {
            setSelectVendorModal(!selectVendorModal);
          }}
          handleClose={() => {
            setSelectVendorModal(false);
          }}
          onClickItem={(data) => {
            setVendorData({});
            setVendorData(data);
            setPaymentModal(true);
            setSelectVendorModal(false);
          }}
          search={search}
          setSearch={setSearch}
        />
      )}
      {paymetModal && (
        <PaymentModal
          open={paymetModal}
          onHide={() => {
            setPaymentModal(false);
            setVendorData({});
          }}
          toggleModal={() => {
            setVendorData({});
            setPaymentModal(!paymetModal);
          }}
          handleClose={() => {
            setVendorData({});
            setPaymentModal(false);
          }}
          onSubmit={(data) => {
            setPaymentModal(false);
            setEnterPin(true);
            setPaymentData(data);
          }}
          vendorData={vendorData}
        />
      )}
      {enterPin && (
        <PinModal
          open={enterPin}
          onHide={() => {
            setVendorData({});
            setEnterPin(false);
          }}
          toggleModal={() => {
            setVendorData({});
            setEnterPin(!enterPin);
          }}
          handleClose={() => {
            setVendorData({});
            setEnterPin(false);
          }}
          pinLoader={pinLoader}
          onSubmit={(data) => {
            setPinLoader(true);
            dispatch(
              transferTorVendorAction(
                {
                  mode: vendorData?.vpaId == "" ? "BANK_TRANSFER" : "UPI",
                  pin: data?.pin?.toString(),
                  amount: paymentData?.amount?.toString(),
                  remark: paymentData?.remarks,
                  vendorId: vendorData?.id?.toString(),
                },
                (res) => {
                  setPinLoader(false);
                  console.log(res?.data);
                  setVendorData({});
                  if (res?.status == 200) {
                    setSuccessModal(true);
                    setSuccessText(
                      `₹${paymentData?.amount} has been payed to your vendor from Master Account.`
                    );
                    setEnterPin(false);
                    wait(1000).then(() => {
                      dispatch(cleanVendorTransaction());
                      dispatch(
                        vendorTransactionAction({
                          limit: 7,
                          offset: offset,
                          from: "",
                          to: "",
                          isLogipeVendor: isLogipeVendor,
                        })
                      );
                    });
                  } else {
                    setFailedMsg(res?.data?.message);
                    setSuccessModal(false);
                    setEnterPin(false);
                    setFailedModal(true);
                  }
                }
              )
            );
          }}
          data={paymentData}
          isLogipeVendor={isLogipeVendor}
        />
      )}
      {successModal && (
        <SuccessModal
          open={successModal}
          onHide={() => {
            setSuccessModal(false);
            setPaymentData({});
            setSuccessText("");
          }}
          toggleModal={() => {
            setSuccessModal(!successModal);
            setPaymentData({});
            setSuccessText("");
          }}
          handleClose={() => {
            setSuccessModal(false);
            setPaymentData({});
            setSuccessText("");
          }}
          text={successText}
        />
      )}
      {failedModal && (
        <Toast
          success={0}
          message={failedMsg || "Failed to Transfer Amount"}
          toggleModal={() => {
            setFailedModal(false);
            setFailedMsg();
          }}
          isOpen={failedModal}
          name="vendor"
          handleClose={() => {
            setFailedModal(false);
            setFailedMsg();
          }}
        />
      )}
      <div className="row">
        <div className="col-4">
          <div className="Select-Date-Vendor">
            <p className="Select-Date-Heading">Select Date</p>
            <div className="row selectDate-input-Bg">
              <div className="col-4">
                <DatePickerField
                  classname="selectDate-input-vendor"
                  name="startDate"
                  header="Start date"
                  value={startDate}
                  onChange={(v) => {
                    setStartDate(v);
                  }}
                  onFocus={() => {
                    handleClickDatepickerIcon1();
                  }}
                  maxDate={endDate === "" ? new Date() : endDate}
                  ref1={datepickerRefStart}
                />
              </div>
              <div className="col-3">
                <p className="" style={{ textAlign: "right" }}>
                  To
                </p>
              </div>
              <div className="col-4" style={{ padding: 0 }}>
                <DatePickerField
                  classname="selectDate-input-vendor"
                  name="endDate"
                  header="End date"
                  value={endDate}
                  onChange={(v) => {
                    setEndDate(v);
                  }}
                  onFocus={() => {
                    handleClickDatepickerIcon2();
                  }}
                  minDate={startDate === "" ? "" : startDate}
                  maxDate={new Date()}
                  ref1={datepickerRefEnd}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 transfer-box-2">
          <div className="Transfer-To-Logipe-Vendor-Bg pt-2">
            <SvgImage name={"TransferToLogipeVendor"} />
          </div>
          <div>
            <button
              className="greenButton Transfer-Btn"
              onClick={() => {
                if(["2","3",2,3].includes(isVendor)) setSelectVendorModal(true);
              }}
              style={{
                marginTop: "12px",
                paddingRight: "20px",
                paddingLeft: "20px",
              }}
            >
              <text style={{}}>Transfer</text>
            </button>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="row align-items-center;">
          <div className="col-9">
            <p className="vendor-List-title">Transaction History</p>
          </div>
          <div className="col-3 pt-2">
            {trxnCount != 0 && (
              <>
                {downloadLoader ? (
                  <div className=" downloadText">
                    <ClipLoader
                      color={"#485B7C"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />{" "}
                    downloading ...
                  </div>
                ) : (
                  <div
                    className=" downloadText"
                    onClick={() => {
                      setDownloadLoader(true);
                      dispatch(
                        generateStatementForVendorAction(
                          {
                            from: startDate || "",
                            to: endDate || "",
                            isLogipeVendor: isLogipeVendor,
                          },
                          (res) => {
                            setDownloadLoader(false);
                            if (res?.status) {
                              window.open(res?.data?.data?.url);
                            }
                          }
                        )
                      );
                    }}
                  >
                    <div style={{ marginRight: "8px" }}>
                      <SvgImage name="downloadImgV2" />
                    </div>
                    Download Statement
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {trxnCount == undefined ? (
        <NoTransaction />
      ) : trxnCount == 0 ? (
        <NoTransaction />
      ) : (
        <div className="table-for-Vendor">
          <Table className="vehicleAssignedHistory">
            <thead className="th" style={{ zIndex: 99 }}>
              <tr className="table-header-vendor-details">
                <th className="vendor-transaction-header col-2">Date & Time</th>
                <th className="vendor-transaction-header col-2">
                  Beneficiary Name
                </th>
                <th className="vendor-transaction-header col-2">UTR No.</th>
                
                <th className="vendor-transaction-header col-1.5">
                  Payment Mode
                </th>
                <th className="vendor-transaction-header">Amount</th>
                <th className="vendor-transaction-header">Status</th>
                
                
                <th className="col-2 vendor-transaction-header">
                  Upload / View Invoice
                </th>
              </tr>
            </thead>
            {/* <div className="tbodyVehicle-div"> */}
            <tbody className="tbodyVendor" style={{ textDecoration: "none" }}>
              {vendorTransactionList?.map((items, i) => {
                console.log("-----------", i);
                return (
                  <>
                    {i < 7 && i >= 0 ? (
                      <tr className="table-row-vendor-details table-row-transaction-history ">
                        <td
                          className="col-2"
                          style={{
                            verticalAlign: "middle",
                            borderRightWidth: 1,
                          }}
                        >
                          {moment(items?.createdAt).format(
                            "DD/MM/YYYY, hh:mm a"
                          )}
                        </td>
                        <td
                          className="col-2"
                          style={{
                            verticalAlign: "middle",
                            borderRightWidth: 1,
                          }}
                        >
                          {items?.beneficiaryName}
                        </td>
                        <td
                          className="col-2"
                          style={{
                            verticalAlign: "middle",
                            borderRightWidth: 1,
                            wordBreak: "break-all"
                          }}
                        >
                          {items?.bank_ref_no}
                        </td>
                        
                        
                        <td
                          className="col-1.5"
                          style={{
                            verticalAlign: "middle",
                            borderRightWidth: 1,
                            wordBreak:"break-all"
                          }}
                        >
                          {items?.payment_mode}
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                            borderRightWidth: 1,
                          }}
                        >
                          ₹{items?.amount}
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                            borderRightWidth: 1,
                            color:
                              items?.status == "Success"
                                ? "#09BD7B"
                                : "#E63946",
                          }}
                        >
                          {items?.status}
                        </td>
                        
                        <td
                          className="col-2"
                          style={{
                            verticalAlign: "middle",
                            borderRightWidth: 1,
                          }}
                        >
                          <div className="row">
                            <div className="col-1 upload-Icon" type="button">
                              <SvgImage name="uploadIconV2" />
                              <div style={{ position: "absolute" }}>
                                <input
                                  type="file"
                                  // accept="image/*"
                                  className="add-attachment-btn-4"
                                  onChange={async (e) => {
                                    const img = e?.target.files[0];
                                    // setFileUp( e?.target.files[0]);
                                    console.log("image", img?.name);
                                    if (img !== null) {
                                      //notify2();
                                      dispatch(
                                        vendorUploadBillAction(
                                          {
                                            transaction_id:
                                              items?.transaction_id,
                                            img: img,
                                          },
                                          (res) => {
                                            if (res?.type == "success") {
                                              setSuccessModal(true);
                                              setSuccessText(
                                                `Image Uploaded Successfully`
                                              );
                                              wait(1400).then(() => {
                                                dispatch(
                                                  cleanVendorTransaction()
                                                );
                                                dispatch(
                                                  vendorTransactionAction({
                                                    limit: 7,
                                                    offset: offset,
                                                    from: "",
                                                    to: "",
                                                    isLogipeVendor:
                                                      isLogipeVendor,
                                                  })
                                                );
                                              });
                                            } else {
                                              setFailedMsg(res?.data?.message);
                                              setSuccessModal(false);
                                              setEnterPin(false);
                                              setFailedModal(true);
                                            }
                                          }
                                        )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            {items?.doc_url != null && (
                              <div
                                className="col-1"
                                onClick={() => {
                                  dispatch(
                                    vendorGetSignedUrlAction(
                                      { key: items?.doc_url },
                                      (res) => {
                                        console.log(
                                          "vendorGetSignedUrlAction",
                                          res
                                        );
                                        if (res?.status == 200) {
                                          window.open(
                                            res?.data?.data,
                                            "_blank",
                                            "noopener,noreferrer"
                                          );
                                        }
                                      }
                                    )
                                  );
                                }}
                              >
                                <SvgImage name="ViewInvoice" />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </tbody>
            {/* </div> */}
          </Table>
          {/* {
          olderHistoryStatus?
          <div className="olderTransactions-div" onClick={handleOlderTransactions}>
          Older History
          </div>:
        <></>
        } */}
          {trxnCount > 0 && (
            <div className="row margin0 padding16">
              <div
                className="col-4"
                style={{
                  textAlign: "right",
                  paddingTop: "0.1rem",
                  cursor: offset > 0 ? "pointer" : "no-drop",
                  opacity: offset > 0 ? "1" : "0.5",
                }}
                onClick={handlePrev}
              >
                <SvgImage name="PrevArrow" />
              </div>
              <div
                className="col-1 greenFont semiBold heading4 padding0L"
                style={{
                  textAlign: "right",
                  paddingTop: "0.3rem",
                  cursor: offset > 0 ? "pointer" : "no-drop",
                  opacity: offset > 0 ? "1" : "0.5",
                }}
                onClick={handlePrev}
              >
                Previous
              </div>
              <div className="col-2 row margin0 justify-content-center">
                <div
                  className="greenBoxCount Medium heading4 col-4"
                  style={{
                    width: "2rem",
                    height: "2rem",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {offset / 7 + 1}
                </div>
                <div
                  className=" col-4 padding0R"
                  style={{ paddingTop: "0.3rem", textAlign: "right" }}
                >
                  of{" "}
                  {trxnCount % 7 == 0
                    ? Math.floor(trxnCount / 7)
                    : Math.floor(trxnCount / 7 + 1)}
                </div>
              </div>
              <div
                className="col-1 greenFont semiBold heading4 padding0R"
                style={{
                  textAlign: "left",
                  paddingTop: "0.3rem",
                  cursor: trxnCount - offset > 7 ? "pointer" : "no-drop",
                  opacity: trxnCount - offset > 7 ? "1" : "0.5",
                }}
                onClick={handleNext}
              >
                Next
              </div>
              <div
                className="col-4 padding0L"
                style={{
                  textAlign: "left",
                  marginLeft: "-1rem",
                  paddingTop: "0.1rem",
                  cursor: trxnCount - offset > 7 ? "pointer" : "no-drop",
                  opacity: trxnCount - offset > 7 ? "1" : "0.5",
                }}
                onClick={handleNext}
              >
                <SvgImage name="NextArrow" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default TransactionHistory;
