// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import "./index.css";

// useEffect(() => {
//     if (id !== "" && id !== undefined && id !== null) {
//       setOffset(0);
//       dispatch(cleanExpenseWalletSummary());
//       dispatch(
//         getExpenseWalletSummary(id, {
//           offset: 0,
//           fromDate: "",
//           toDate: "",
//           sortAs: "",
//           sortBy: "",
//         })
//       );
//     } else {
//       setOffset(0);
//       dispatch(cleanExpenseWalletSummary());
//     }
//   }, [id]);

//   console.log("rrrrrrrrrrrrrrrr", cardExpense?.expenseWalletSummary?.count, cardExpense);

//   const trxnCount = cardExpense?.expenseWalletSummary?.count;


// let columns;
// {
//   tripsListNew.map((i, k) => {
//     columns = [
//       {
//         name: "Trip No",
//         selector: (i) => i.tripNo,
//       },
//       {
//         name: "Vehicle No",
//         selector: (i) => i?.Fleet?.regNumber,
//       },
//       {
//         name: "From",
//         selector: (i) =>( i.source.length == 36 ? i.sourceAddress?.cityName + ", "+i.sourceAddress?.stateName : i.source),
//       },
//       {
//         name: "To",
//         selector: (i) => (i.destination.length == 36 ? i.destAddress?.cityName +", "+ i.destAddress?.stateName : i.destination),
//       },
//       {
//         name: "Remarks",
//         selector: (i) => (i?.remarks ? i?.remarks : "NA"),
//       },
//     ];
//   });
// }
const SalaryTable=(props)=>{
    return <></>
}
export default SalaryTable