import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";

import SvgImage from "../../../Icons/Icons";

import Toast from "../../../subComponents/toast";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import Collapsible from "react-collapsible";
import DataTable from "react-data-table-component";
import {
  endTrip,
  getLRList,
  getTripExpenseAction,
  getTripLedger,
} from "../../../../services/Store/Actions/TripsAction";
import ViewTripCard from "../../../EndTrip/Components/ViewTripCard";
import ViewExpanseCard from "../../../EndTrip/Components/ViewExpanseCard";
import FreightUpdateRender from "../../../EndTrip/Components/FreightUpdateRender";
import { Formik } from "formik";
import { DatePickerField } from "../../../core/inputs/input";
import ManageDriverAddNewExpense from "../../../Modals/ManageDriverAddNewExpense";
import ManageDriverAddBalance from "../../../Modals/ManageDriverAddBalance";
import RechargeFastagButttonModal from "../../../Modals/rechargeFastagButton";
import OMCLoadModal from "../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCLoadModal";
import ChooseMethodModal from "../ChooseMethodModal";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";
import moment from "moment";
function AddPod(props) {
  const {
    toggle,
    modal,
    setModal,
    location,
    tripId,
    tripData,
    TripTotalExpenses,
    setItemTemp,
    sattlePayment,
    handleClose
  } = props;
  const dispatch = useDispatch();
  const [isOpenFASTag, setIsOpenFASTag] = useState(false);
  const [isOpenFuel, setIsOpenFuel] = useState(false);
  const [isVendorExpanse, setIsVendorExpanse] = useState(false);
  const [isOpenCard, setIsOpenCard] = useState(false);
  const [isOpenCash, setIsOpenCash] = useState(false);
  const [view, setView] = useState(false);
  const [openFreight, setOpenFreight] = useState(false);
  const [freightNo, setFreightNo] = useState(null);
  const [openEndTripModal2, setOpenEndTripModal2] = useState(false);
  const [openAddExpanse, setOpenAddExpanse] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [openFuel, setOpenFuel] = useState(false);
  const [open, setOpen] = useState(false);
  const [openChooseMethod, setOpenChooseModal] = useState(false);
  const { freightList, tripExpense } = useSelector((state) => state?.trip);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const { fastagWallet } = useSelector((state) => state?.fastag);
  const toggleModal3 = () => {
    setOpen3(!open3);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const rechargeCard = () => {
    setOpen3(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const toggleModal4 = () => {
    setOpen4(!open4);
  };
  const closeAddExpense = () => {
    setOpenAddExpanse(false);
  };
  const toggleAddExpense = () => {
    setOpenAddExpanse(!openAddExpanse);
  };
  const loadOmc = () => {
    setOpenFuel(true);
  };
  const handleCloseFuel = () => {
    setOpenFuel(false);
  };
  const toggleModalFuel = () => {
    setOpenFuel(!openFuel);
  };
  const chooseModal = () => {
    setOpenChooseModal(true);
  };
  const handleChooseModal = () => {
    setOpenChooseModal(false);
  };
  const toggleChooseModal = () => {
    setOpenChooseModal(!openChooseMethod);
  };
  const addExpense = () => {
    setOpen(true);
  };
  const handleClose2 = () => {
    setOpen(false);
  };
  const toggleModal2 = () => {
    setOpen(!open);
  };
  console.log("freightListfreightList", tripData);
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  let TotalCashExpacted = 0;
  for (let index = 0; index < tripExpense?.cashData?.length; index++) {
    const element = tripExpense?.cashData?.[index];
    TotalCashExpacted += parseInt(element?.expacted || 0);
  }

  // const handleClose = () => {
  //   setModal(!modal);
  // };

  useEffect(() => {
    console.log("getLRListgetLRList", tripId);
    if (tripId && tripId != "undefined") {
      dispatch(
        getLRList(
          {
            tripId: tripId,
          },
          (res) => {
            console.log("getLRListgetLRList", res);
          }
        )
      );
      // dispatch(sendAnalitics(""));
    }
  }, [tripId]);

  let Earnings = 0;
  for (let index = 0; index < freightList?.length; index++) {
    Earnings = Earnings + freightList?.[index]?.freightAmount;
  }
  let Shortage = 0;
  for (let index = 0; index < freightList?.length; index++) {
    Shortage = Shortage + (freightList?.[index]?.shortage || 0);
  }
  let Penalties = 0;
  for (let index = 0; index < freightList?.length; index++) {
    Penalties = Penalties + freightList?.[index]?.charges;
  }
  let lastTrip = new Date(tripData?.expectedEndTime || new Date());
  let lastTrip6 = new Date(lastTrip.setHours(lastTrip.getHours() + 6));
  // const FreightRender = (freight, i) => {
  //   console.log("freightRender", freight, i, freightNo);
  //   return (
  //     <div>
  //       <div style={{ marginTop: "2px" }}>
  //         {freightNo === i ? (
  //           <FreightUpdateRender
  //             freight={freight}
  //             view={view}
  //             tripId={tripData?.tripId}
  //           />
  //         ) : (
  //           <></>
  //         )}
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width-2"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        scrollable={true}
        centered={true}
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "5px" }}
        >
          <div>
            <div className="">
              <div
                className="closeBtnWithBackgroundForTrip"
                onClick={handleClose}
              >
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="crossBtnWIthBackground"
                  width="20%"
                  height="20%"
                />
              </div>
              <div className="d-flex">
                <div className="PGCollectiontText col-9" style={{}}>
                  Submit Pod
                </div>
                {/* <div className="PGCollectiontText col-3" style={{}}>
                  <button
                    className="btn-edit-details"
                    style={{ marginLeft: "20px" }}
                    onClick={() => {
                      setOpenEndTripModal2(!openEndTripModal2);
                    }}
                  >
                    <text style={{ color: "white" }}>
                      {openEndTripModal2 ? "View Details" : "End Trip"}
                    </text>
                  </button>
                </div> */}
              </div>

              <hr style={{ border: "2px solid #2E7C7B" }}></hr>
            </div>
            {/* <div
              style={{
                display: "flex",
                // justifyContent: "center",
                gap: "50px",
              }}
            >
              <ViewTripCard tripData={tripData} />
              <ViewExpanseCard
                tripData={tripData}
                tripExpense={{ ...tripExpense, Earnings, Shortage, Penalties }}
              />
            </div> */}
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "40px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  textAlignLast: "center",
                }}
              >
                <td style={{ width: "20%" }}>Source</td>
                <td style={{ width: "20%" }}>Destination</td>
                <td style={{ width: "25%" }}>Start Date</td>
                <td style={{ width: "20%" }}>Amount</td>
                <td style={{ width: "20%" }}>Weight</td>
                <td style={{ width: "20%" }}>Pay By</td>
                <td style={{ width: "20%" }}>{}</td>
              </div>
              {freightList?.length > 0 ? (
                freightList?.map((elem, i) => {
                  console.log("elelemeelemm", elem);
                  const payBy =
                    elem.customerId == elem.consignorId
                      ? elem.consignorName
                      : elem.customerId == elem.consigneeId
                      ? elem.consigneeName
                      : elem.transportorName;
                  return (
                    <div>
                      <Collapsible
                        trigger={
                          <div
                            className="trigger"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: "50px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              textAlignLast: "center",
                            }}
                            onClick={() => {
                              setFreightNo(i);
                              setView(false);
                            }}
                          >
                            <td style={{ width: "20%" }}>
                              {elem?.fromAddress?.cityName}
                            </td>
                            <td style={{ width: "20%" }}>
                              {elem?.toAddress?.cityName}
                            </td>
                            <td style={{ width: "25%" }}>{elem?.startDate}</td>
                            <td style={{ width: "20%" }}>
                              {elem?.freightAmount}
                            </td>
                            <td style={{ width: "20%" }}>{elem?.weight}</td>
                            <td style={{ width: "20%" }}>{payBy}</td>
                            <td style={{ width: "20%" }}>
                              <button
                                style={{
                                  // width: "60px",
                                  // height: "25px",
                                  borderRadius: "5px",
                                  backgroundColor: "#FFF",
                                  borderColor: "#2E7C7B",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                                onClick={() => {
                                  setFreightNo(i);
                                  setView(false);
                                }}
                              >
                                <SvgImage
                                  name="AddAttachment"
                                  height="20"
                                  width="20"
                                />
                                <div style={{ marginLeft: "6px" }}>POD</div>
                              </button>
                            </td>
                          </div>
                        }
                        // triggerSibling="hskdg shkdg"
                        // onOpening={}
                      >
                        <div style={{ margin: "10px" }}>
                          {/* <FreightRender elem={elem} i={i} /> */}

                          <div style={{ marginTop: "2px" }}>
                            {freightNo === i ? (
                              <FreightUpdateRender
                                freight={elem}
                                view={view}
                                tripId={tripData?.tripId}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </Collapsible>
                    </div>
                  );
                })
              ) : (
                <div  style={{textAlign : "center"}}>
                  
                  <div
                    className="greyFont heading3"
                    style={{ marginTop: "10%" }}
                  >
                    There are no records to
                  </div>
                  <div className="greyFont heading3 padding32B">display</div>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
      {toastObject.toastStatus && location == "/trips/" ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            handleClose();
            dispatch(
              getTripExpenseAction({ tripId: tripData?.tripId }, (res) => {
                console.log("getTripExpenseAction", res?.data?.data);
                if (res?.data == 200) {
                  storeDataToAsyncStorage("row", res?.data?.data?.tripData);
                  setItemTemp(res?.data?.data?.tripData);
                }
              })
            );
            setItemTemp();
            console.log("nmnnnnnnnnnmmmm", toastObject?.message);
          }}
        />
      ) : (
        <></>
      )}
      <ChooseMethodModal
        tripData={tripData}
        open={openChooseMethod}
        toggleModal={toggleChooseModal}
        handleClose={handleChooseModal}
        handleCashPayment={addExpense}
        handleCardPayment={rechargeCard}
      />
    </div>
  );
}

export default AddPod;
