import {
    GET_M2P_USER_DETAILS,
    GET_M2P_USER_DETAILS_SUCCESS,
    GET_M2P_CARD_DETAILS,
    GET_M2P_CARD_DETAILS_SUCCESS,
    M2P_TRANSACTION_HISTORY,
    M2P_TRANSACTION_HISTORY_SUCCESS,
    M2P_TRANSACTION_HISTORY_CLEAN,
    M2P_WITHDRAW_FROM_CARD,
    M2P_WITHDRAW_FROM_CARD_SUCCESS,
    GET_M2P_CARD_CONTROLS,
    GET_M2P_CARD_CONTROLS_SUCCESS,
    EDIT_M2PCARD_CONTROLS,
    EDIT_M2PCARD_CONTROLS_SUCCESS,
    M2P_SET_PIN,
    M2P_SET_PIN_SUCCESS,
    M2P_OTP,
    M2P_OTP_SUCCESS,
    M2P_CONTACT_INFO,
    M2P_CONTACT_INFO_SUCCESS,
    M2P_REGISTER,
    M2P_REGISTER_SUCCESS,
    M2P_VKYC_URL_SUCCESS,
    M2P_OWNER_ADDRESS_SUCCESS,
    M2P_OWNER_ADDRESS,
    M2P_VKYC_URL,
    M2P_MONTHLY_BALANCE,
    M2P_MONTHLY_BALANCE_SUCCESS,
    M2P_DOWNLOAD_WALLET_HISTORY_SUCCESS,
    M2P_DOWNLOAD_WALLET_HISTORY
  } from "../storeTypes";
  
  const INIT_STATE = {
    loader: false,
    userDetails:{},
    m2pCardDetails:{},
    m2pTransactions:[],
    m2pTransactionsList:[],
    m2pCardControls:{},
    m2pSetPin:{},
    register:{},
    V_KycUrl:{},
    m2pOwnerAddress:{},
    m2pMonthlyBal:{},
    m2pWalletHistoryUrl:{},
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_M2P_USER_DETAILS: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_M2P_USER_DETAILS_SUCCESS: {
        return {
          ...state,
          loader: false,
          userDetails: action?.payload,
        };
      }
      case GET_M2P_CARD_DETAILS: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_M2P_CARD_DETAILS_SUCCESS: {
        return {
          ...state,
          loader: false,
          m2pCardDetails: action?.payload,
        };
      }
      case M2P_TRANSACTION_HISTORY: {
        //console.log("red-> fleet-- ");
        return {
          ...state,
          loader: true,
        };
      }
      case M2P_TRANSACTION_HISTORY_SUCCESS: {
        console.log("red-> success - m2p",action?.payload,);
        return {
          ...state,
          loader: false,
          m2pTransactions: action?.payload,
          m2pTransactionsList: [...state.m2pTransactionsList, ...action?.payload?.rows],
        };
      }
      case M2P_TRANSACTION_HISTORY_CLEAN:{
        return {
          ...state,
          loader: false,
          m2pTransactions: [],
          m2pTransactionsList: [],
        };
      }
      case M2P_WITHDRAW_FROM_CARD: {
        return {
          ...state,
          loader: true,
        };
      }
      case M2P_WITHDRAW_FROM_CARD_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case GET_M2P_CARD_CONTROLS: {
        return {
          ...state,
          loader: true,
        };
      }
      case GET_M2P_CARD_CONTROLS_SUCCESS: {
        return {
          ...state,
          loader: false,
          m2pCardControls:action?.payload,
        };
      }
      case EDIT_M2PCARD_CONTROLS: {
        return {
          ...state,
          loader: true,
        };
      }
      case EDIT_M2PCARD_CONTROLS_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case M2P_SET_PIN: {
        return {
          ...state,
          loader: true,
        };
      }
      case M2P_SET_PIN_SUCCESS: {
        return {
          ...state,
          loader: false,
          m2pSetPin: action?.payload,
        };
      }
      case M2P_OTP: {
        return {
          ...state,
          loader: true,
        };
      }
      case M2P_OTP_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case M2P_CONTACT_INFO: {
        return {
          ...state,
          loader: true,
        };
      }
      case M2P_CONTACT_INFO_SUCCESS: {
        return {
          ...state,
          loader: false,
        };
      }
      case M2P_REGISTER: {
        return {
          ...state,
          loader: true,
        };
      }
      case M2P_REGISTER_SUCCESS: {
        return {
          ...state,
          loader: false,
          register: action?.payload,
        };
      }
      case M2P_VKYC_URL: {
        return {
          ...state,
          loader: true,
        };
      }
      case M2P_VKYC_URL_SUCCESS: {
        return {
          ...state,
          loader: false,
          V_KycUrl: action?.payload,
        };
      }
      case M2P_OWNER_ADDRESS: {
        return {
          ...state,
          loader: true,
        };
      }
      case M2P_OWNER_ADDRESS_SUCCESS: {
        return {
          ...state,
          loader: false,
          m2pOwnerAddress: action?.payload,
        };
      }
      case M2P_MONTHLY_BALANCE: {
        return {
          ...state,
          loader: true,
        };
      }
      case M2P_MONTHLY_BALANCE_SUCCESS: {
        return {
          ...state,
          loader: false,
          m2pMonthlyBal: action?.payload,
        };
      }
      case M2P_DOWNLOAD_WALLET_HISTORY: {
        return {
          ...state,
          loader: true,
        };
      }
      case M2P_DOWNLOAD_WALLET_HISTORY_SUCCESS: {
        return {
          ...state,
          loader: false,
          m2pWalletHistoryUrl: action?.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  