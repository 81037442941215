import {
  ADD_FLEET,
  ADD_FLEET_SUCCESS,
  GET_FLEET_BY_FLEET_ID,
  GET_FLEET_BY_FLEET_ID_SUCCESS,
  CLEAN_FASTAG_TRANSACTION_HISTORY,
  GENERATE_MASTER_ACCOUNT_STATEMENT_SUCCESS,
  GENERATE_MASTER_ACCOUNT_STATEMENT,
  GENERATE_FASTAG_VEHICLE_STATEMENT,
  GENERATE_FASTAG_VEHICLE_STATEMENT_SUCCESS,
  CLEAN_FLEET_LOADER,
  UPDATE_FLEET,
  UPDATE_FLEET_SUCCESS,
  ADVANCED_VEHICLE_LIST,
  ADVANCED_VEHICLE_LIST_SUCCESS
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  fleetAdded:{},
  fleet: [],
  fleetFastagWallet: {},
  masterAccount: [],
  fastagVehicleStatement: {},
  advancedVehicleList:[],
  advancedVehicleListCount:null,
  fleetListLoader: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_FLEET: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_FLEET_SUCCESS: {
      return {
        ...state,
        loader: false,
        // fleetAdded:action.payload,
      };
    }

    case GET_FLEET_BY_FLEET_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_FLEET_BY_FLEET_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
        fleet: action.payload,
      };
    }
    case CLEAN_FASTAG_TRANSACTION_HISTORY: {
      console.log("red-> fleet-- clean ");
      return {
        ...state,
        loader: false,
      };
    }
    case GENERATE_MASTER_ACCOUNT_STATEMENT: {
      console.log("red", action.payload);
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_MASTER_ACCOUNT_STATEMENT_SUCCESS: {
      //console.log("red-> fleet-- ");
      return {
        ...state,
        loader: false,
        masterAccountStatement: action.payload,
      };
    }
    case GENERATE_FASTAG_VEHICLE_STATEMENT: {
      // console.log("red",action.payload);
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_FASTAG_VEHICLE_STATEMENT_SUCCESS: {
      //console.log("red-> fleet-- ");
      return {
        ...state,
        loader: false,
        fastagVehicleStatement: action.payload,
      };
    }
    case CLEAN_FLEET_LOADER:{
      console.log("mesaage------------- reducer loader");
      return {
          ...state,
          loader:false,
      }
    }
    case UPDATE_FLEET: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_FLEET_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case ADVANCED_VEHICLE_LIST:{
      return {
        ...state,
        loader:true,
        fleetListLoader:true,

      }
    }
    case ADVANCED_VEHICLE_LIST_SUCCESS:{
      return {
        ...state,
        loader:false,
        advancedVehicleList:action?.payload?.result,
        advancedVehicleListCount:action?.payload?.count,
        fleetListLoader:false,
      }
    }
    default: {
      return state;
    }
  }
};
