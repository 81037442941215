import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./index.css";
import ManageDriverCardType4 from "../../../../Subcomponent/ManageDriverCardType4";
import LogipeCard2 from "../../../../Subcomponent/LogipeCard2";
import OwnerLogipeCard from "../OwnerLogipeCard/index";
import { getDriverBal } from "../../../../services/Store/Actions/driverslistActions";
import ManageLogipeMidCard from "../ManageLogipeMidCard";
const ManageLogipeCardMidSection = (props) => {
  console.log("asmi-> yeh bhej raeh hain", props.activeStatus);
  const dispatch = useDispatch();
  //const cardDetails= props.cardDetails.details
  const DATA = useSelector((state) => state);
  useEffect(() => {
    dispatch(getDriverBal(props.userId));
  }, []);
  console.log("DATA", props.userId);
  const balance = DATA?.driverlist?.bal;

  const data = [
    {
      title: "Add card Balance",
      logo: "topUpWallet",
    },
    { title: "Withdraw card Balance", logo: "withdrawBalanceLogo" },
  ];
  let a = -20;
  let b = "";
  const disabledStyle = {};
  return (
    <>
      <div className="paddingLR row whiteBackground cardTopBox">
        <div
          className="col-lg-7 col-md-12 padding32T padding32B"
        >
          <OwnerLogipeCard
            //   userId={props.userId}
            fullName={props.fullName}
            //activeStatus = {props.activeStatus}
            setActiveStatus={props.setActiveStatus}
            //   cardNumber = {cardDetails?.cardNumber}
            //cvv = {cardDetails?.cvv}
            //   expiryDate = {cardDetails?.expiryDate}
          />
        </div>

        <div className="col-lg-5 col-md-12 padding32T padding32B">
          <div className="row">
          {data.map((item) => {
            return (
              <div
                style={{padding:".75rem"}}
                className="col-lg-12 col-md-6"
              >
                <ManageLogipeMidCard
                  name={item.title}
                  driverBal={balance}
                  userId={props.userId}
                  logo={item.logo}
                  activeStatus={props.activeStatus}
                  bal={props.bal}
                  fullName={props.fullName}
                />
              </div>
            );
          })}
          </div>
        </div>
      </div>
    </>
  );
};
export default ManageLogipeCardMidSection;
