import React,{useState} from 'react';
// import SvgIcon from 'react-native-svg-icon';
import Svg from '../Icons/svg'; // point to your svgs.js wherever that may be

function SvgImage(props){

    const {name,classname,height,width,color, onClick} = props;

    return(
        <Svg icon = {name} classname = {classname} height = {height} width = {width} color = {color} onClick={onClick}/>
    )
}

export default SvgImage;