import React, { useEffect, useState } from "react";
import "./index.css";
import SvgImage from "../../../Icons/Icons";
import CreateCustomerV2 from "../CreateCustomerV2";
import { useSelector } from "react-redux";
// const customerImg = require("../../assets/images/Screenshot 2023-10-07 at 10.06.46 PM.png"); // with require

function SalesHomePage(props) {
  const { sideBar } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [createCust, setCreateCust] = useState(false);
  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips, setIsTrips] = useState(0);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  useEffect(() => {
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
  }, [authUser, managerControlList]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function toggleCreateCust() {
    if (isCustomers == 3) setCreateCust(!createCust);
  }

  return (
    <div
      className="container sideBarMargin"
      style={{
        marginLeft: sideBar ? "230px" : "100px",
        maxWidth: sideBar ? windowWidth - 260 : windowWidth - 130,
      }}
    >
      <div
        className="row"
        style={{
          backgroundColor: "#FFFFFF",
        }}
      >
        <div className="col-12">
          <div className="row">
            <div className="col-12 salesText">Sales</div>
            <div className="col-12 salesDesc">
              Introducing Sales Section by Logipe, Now Manage your Accounting
              and Operation easily with Logipe
            </div>
          </div>
        </div>
        <div className="col-12 imgMainDiv">
          <div className="row">
            <div className="col-12 imgAll">
              {/* <img src={customerImg} className="imgMain"/> */}
              <div>
                <SvgImage name={"saleshomeimage1"} width={"30%"} height={247} />
                <SvgImage name={"saleshomeimage2"} width={"40%"} height={225} />
                <SvgImage name={"saleshomeimage3"} width={"30%"} height={247} />
              </div>
            </div>
            <div className="col-12 customerText">Manage Customer</div>
          </div>
        </div>
        <div className="col-12" style={{ padding: "33px" }}>
          <div className="row">
            <div className="col-3">
              <div className="row">
                <div className="col-12 pinIcon">
                  <SvgImage name="pinLocation2" color="#2E7C7B" />
                </div>
                <div className="col-12 pinIconTitle mt-2 Bold">Create Trip</div>
                <div className="col-12 pinIconSubTitle">
                  create to manage expense easily Get trip sheet in 1-click
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-12 pinIcon">
                  <SvgImage name="pinLocation2" color="#2E7C7B" />
                </div>
                <div className="col-12 pinIconTitle mt-2 Bold">
                  Add Customer Info
                </div>
                <div className="col-12 pinIconSubTitle">
                  Get all trips of customer at once Access customer ledger
                  easily
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-12 pinIcon">
                  <SvgImage name="pinLocation2" color="#2E7C7B" />
                </div>
                <div className="col-12 pinIconTitle mt-2 Bold">
                  Create Invoice
                </div>
                <div className="col-12 pinIconSubTitle">
                  Create invoice of completed Trips in 1 click
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-12 pinIcon">
                  <SvgImage name="pinLocation2" color="#2E7C7B" />
                </div>
                <div className="col-12 pinIconTitle mt-2 Bold">
                  Collect Payments
                </div>
                <div className="col-12 pinIconSubTitle">
                  Use Logipe PG to collect payments and auto maintain ledger
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 textcenter">
          <button
            className="greenButton Medium text3 startBtn"
            onClick={toggleCreateCust}
          >
            <div className="startNow">Add Now</div>
          </button>
        </div>
      </div>

      <CreateCustomerV2
        toggle={toggleCreateCust}
        modal={createCust}
        setModal={setCreateCust}
      />
    </div>
  );
}
export default SalesHomePage;
