import { MANAGER_LIST, MANAGER_LIST_SUCCESS,
  MANAGER_GET_CONTROL,
  MANAGER_GET_CONTROL_SUCCESS, 
  EDIT_CONTROL_MANAGER,
  EDIT_CONTROL_MANAGER_SUCCESS,
  CHANGE_UPI_MANAGER,
  CHANGE_UPI_MANAGER_SUCCESS,
  GET_BRANCH,
  GET_BRANCH_SUCCESS,
  ADD_BRANCH,
  ADD_BRANCH_SUCCESS,
  LINK_BRANCH_MANAGER_SUCCESS,
  LINK_BRANCH_MANAGER,
  LINK_BRANCH_FLEET_SUCCESS,
  LINK_BRANCH_FLEET,
  LINK_BRANCH_DRIVER,
  LINK_BRANCH_DRIVER_SUCCESS,
  GET_BRANCH_DRIVER,
  GET_BRANCH_DRIVER_SUCCESS,
  GET_BRANCH_FLEET,
  GET_BRANCH_FLEET_SUCCESS,
  GET_BRANCH_MANAGER,
  GET_BRANCH_MANAGER_SUCCESS,
  GET_BRANCH_ID,
  GET_BRANCH_ID_SUCCESS,
  SET_BRANCH_LIMIT,
  SET_BRANCH_LIMIT_SUCCESS,
  DELETE_BRANCH,
  DELETE_BRANCH_SUCCESS} from "../storeTypes";

export const getManagerList = (data) => {
  console.log("getManagerList->action");
  return {
    type: MANAGER_LIST,
    payload: data,
  };
};

export const getManagerListSuccess = (payload) => {
  return {
    type: MANAGER_LIST_SUCCESS,
    payload,
  };
};

export const managerGetControlAction = (data) => {
  console.log("getManagerList->action");
  return {
    type: MANAGER_GET_CONTROL,
    payload: data,
  };
};

export const managerGetControlActionSuccess = (payload) => {
  return {
    type: MANAGER_GET_CONTROL_SUCCESS,
    payload,
  };
};

export function editControlManager(obj, callback) {
  console.log('action object', obj);
  return {
    type: EDIT_CONTROL_MANAGER,
    obj,
    callback,
  };
}
export function editControlManagerSuccess() {
  return {
    type: EDIT_CONTROL_MANAGER_SUCCESS,
  };
}
export function changeUpiManager(payload, callBack) {
  return {
    type: CHANGE_UPI_MANAGER,
    payload,
    callBack,
  };
}
export function changeUpiManagerSuccess(payload) {
  return {
    type: CHANGE_UPI_MANAGER_SUCCESS,
    payload,
  };
}




export function getBranch(payload, callBack) {
  return {
    type: GET_BRANCH,
    payload,
    callBack,
  };
}
export function getBranchSuccess(payload) {
  return {
    type: GET_BRANCH_SUCCESS,
    payload,
  };
}
export function deleteBranch(payload, callBack) {
  return {
    type: DELETE_BRANCH,
    payload,
    callBack,
  };
}
export function deleteBranchSuccess() {
  return {
    type: DELETE_BRANCH_SUCCESS,
  };
}

export function addBranch(payload, callBack) {
  return {
    type: ADD_BRANCH,
    payload,
    callBack,
  };
}
export function addBranchSuccess(payload) {
  return {
    type: ADD_BRANCH_SUCCESS,
    payload,
  };
}

export function linkBranchManager(payload, callBack) {
  return {
    type: LINK_BRANCH_MANAGER,
    payload,
    callBack,
  };
}
export function linkBranchManagerSuccess(payload) {
  return {
    type: LINK_BRANCH_MANAGER_SUCCESS,
    payload,
  };
}

export function linkBranchFleet(payload, callBack) {
  return {
    type: LINK_BRANCH_FLEET,
    payload,
    callBack,
  };
}
export function linkBranchFleetSuccess(payload) {
  return {
    type: LINK_BRANCH_FLEET_SUCCESS,
    payload,
  };
}

export function setBranchLimit(payload, callBack) {
  return {
    type: SET_BRANCH_LIMIT,
    payload,
    callBack,
  };
}
export function setBranchLimitSuccess(payload) {
  return {
    type: SET_BRANCH_LIMIT_SUCCESS,
    payload,
  };
}


export function linkBranchDriver(payload, callBack) {
  return {
    type: LINK_BRANCH_DRIVER,
    payload,
    callBack,
  };
}
export function linkBranchDriverSuccess(payload) {
  return {
    type: LINK_BRANCH_DRIVER_SUCCESS,
    payload,
  };
}

export function getBranchDriver(payload, callBack) {
  return {
    type: GET_BRANCH_DRIVER,
    payload,
    callBack,
  };
}
export function getBranchDriverSuccess(payload) {
  return {
    type: GET_BRANCH_DRIVER_SUCCESS,
    payload,
  };
}

export function getBranchFleet(payload, callBack) {
  return {
    type: GET_BRANCH_FLEET,
    payload,
    callBack,
  };
}
export function getBranchFleetSuccess(payload) {
  return {
    type: GET_BRANCH_FLEET_SUCCESS,
    payload,
  };
}

export function getBranchManager(payload, callBack) {
  console.log("snbsbsbs")
  return {
    type: GET_BRANCH_MANAGER,
    payload,
    callBack,
  };
}
export function getBranchManagerSuccess(payload) {
  return {
    type: GET_BRANCH_MANAGER_SUCCESS,
    payload,
  };
}

export function getBranchById(payload, callBack) {
  return {
    type: GET_BRANCH_ID,
    payload,
    callBack,
  };
}
export function getBranchByIdSuccess(payload) {
  return {
    type: GET_BRANCH_ID_SUCCESS,
    payload,
  };
}