import {
  GET_ALL_CUSTOMER,
  GET_ALL_CUSTOMER_SUCCESS,
  CLEAN_ALL_CUSTOMER,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  CLEAN_CUSTOMER,
  ADD_PAYMENT_RECIVED,
  ADD_PAYMENT_RECIVED_SUCCESS,
  CREATE_EXTERNAL_INVOICE,
  CREATE_EXTERNAL_INVOICE_SUCCESS,
  CREATE_LOGIPE_INVOICE,
  CREATE_LOGIPE_INVOICE_SUCCESS,
  DELETE_LEDGER,
  DELETE_LEDGER_SUCCESS,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_LEDGER,
  DOWNLOAD_LEDGER_SUCCESS,
  EDIT_EXTERNAL_INVOICE,
  EDIT_EXTERNAL_INVOICE_SUCCESS,
  EDIT_LOGIPE_INVOICE,
  EDIT_LOGIPE_INVOICE_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_LEDGER_LIST,
  GET_LEDGER_LIST_SUCCESS,
  UPLOAD_RECIPT,
  UPLOAD_RECIPT_SUCCESS,
  GET_ALL_TRIPS,
  GET_ALL_TRIPS_SUCCESS,
  CLEAN_ALL_TRIP,
  COLLECT_PAYMENT,
  COLLECT_PAYMENT_SUCCESS,
  EDIT_CUSTOMER,
  EDIT_CUSTOMER_SUCCESS,
  ADD_VENDOR_PAYMENT,
  ADD_VENDOR_PAYMENT_SUCCESS,
  GET_INVOICES_LIST,
  GET_INVOICES_LIST_SUCCESS,
  ADD_NEW_PHONE_FW_SUCCESS,
  ADD_NEW_PHONE_FW
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  customerList: [],
  customerNo: "",
  customer: {},
  loader: false,
  ledgerList: [],
  ledgerCount: 5,
  tripList: [],
  tripCount: "",
  totalTrips:"",
  invoiceList: [],
  invoiceCount: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CUSTOMER: {
      return {
        ...state,
        loader: true,
      };
    }
    case CLEAN_ALL_CUSTOMER: {
      return {
        ...state,
        customerNo: "",
        customerList: [],
      };
    }

    case GET_ALL_CUSTOMER_SUCCESS: {
      console.log(action.payload);
      return {
        ...state,
        customerNo: action.payload?.count,
        customerList: action?.payload?.rows,
        loader: false,
      };
    }
    case CREATE_CUSTOMER: {
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        newCust: action.payload?.data,
        loader: false,
      };
    }

    /////

    case ADD_NEW_PHONE_FW: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_NEW_PHONE_FW_SUCCESS: {
      return {
        ...state,
        newCust: action.payload?.data,
        loader: false,
      };
    }

    ////

    case GET_CUSTOMER: {
      return {
        ...state,
        loader: true,
      };
    }
    case CLEAN_CUSTOMER: {
      return {
        ...state,
        customer: {},
        totalTrips:"",
      };
    }

    case GET_CUSTOMER_SUCCESS: {
      console.log(action.payload);
      return {
        ...state,
        customer: action.payload?.data?.customer,
        totalTrips: action.payload?.data?.tripsData,
        loader: false,
      };
    }
    case GET_LEDGER_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_LEDGER_LIST_SUCCESS: {
      console.log("ledgerListaction.payload", action.payload);
      return {
        ...state,
        loader: false,
        ledgerList: action.payload.rows,
        ledgerCount: action.payload.count || 0,
      };
    }
    case GET_INVOICES_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_INVOICES_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        invoiceList: action.payload.rows,
        invoiceCount: action.payload.count || 0,
      };
    }
    case DELETE_LEDGER: {
      return {
        ...state,
        loader: true,
      };
    }
    case DELETE_LEDGER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case CREATE_LOGIPE_INVOICE: {
      console.log("createLogipeInvoiceAction 2");
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_LOGIPE_INVOICE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case CREATE_EXTERNAL_INVOICE: {
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_EXTERNAL_INVOICE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case DOWNLOAD_INVOICE: {
      return {
        ...state,
        loader: true,
      };
    }
    case DOWNLOAD_INVOICE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_INVOICE: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_INVOICE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case EDIT_LOGIPE_INVOICE: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_LOGIPE_INVOICE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case EDIT_EXTERNAL_INVOICE: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_EXTERNAL_INVOICE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case UPLOAD_RECIPT: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPLOAD_RECIPT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case ADD_PAYMENT_RECIVED: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_PAYMENT_RECIVED_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case DOWNLOAD_LEDGER: {
      return {
        ...state,
        loader: true,
      };
    }
    case DOWNLOAD_LEDGER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_ALL_TRIPS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CLEAN_ALL_TRIP: {
      return {
        ...state,
        tripCount: "",
        tripList: [],
      };
    }

    case GET_ALL_TRIPS_SUCCESS: {
      console.log("GET_ALL_TRIPS_SUCCESS", action.payload);
      return {
        ...state,
        tripCount: action.payload?.count,
        tripList: action?.payload?.rows,
        loader: false,
      };
    }
    case COLLECT_PAYMENT: {
      return {
        ...state,
        loader: true,
      };
    }
    case COLLECT_PAYMENT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case EDIT_CUSTOMER: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_CUSTOMER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case ADD_VENDOR_PAYMENT: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_VENDOR_PAYMENT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    default: {
      return state;
    }
  }
};
