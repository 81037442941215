import {
  VEHICLE_HISTORY,
  VEHICLE_HISTORY_SUCCESS,
  CLEAN_VEHICLE_HISTORY,
  FULL_VEHICLES_LIST,
  FULL_VEHICLES_LIST_SUCCESS,
  RECHARGE_FASTAG,
  RECHARGE_FASTAG_SUCCESS,
  ADD_VEHICLE,
  ADD_VEHICLE_SUCCESS,
  CLEAR_ALL_FLEET,
  CLEAN_VEHCILE_LOADER,
} from "../storeTypes";
export function clearAllFleets() {
  return {
    type: CLEAR_ALL_FLEET,
  };
}
export const vehicleHistory = (payload) => {
  return {
    type: VEHICLE_HISTORY,
    payload,
  };
};
export const vehicleHistorySuccess = (payload) => {
  return {
    type: VEHICLE_HISTORY_SUCCESS,
    payload,
  };
};

export const cleanVehicleHistory = () => {
  //console.log("action-> fleet-- clean");
  return {
    type: CLEAN_VEHICLE_HISTORY,
  };
};

export const getVehicleListAction = (payload) => {
  console.log(
    "Actions------yaha tak aa rha hai kya bhai Actions<><><><<><><><><><><<><><>"
  );
  return {
    type: FULL_VEHICLES_LIST,
    payload
  };
};
export const getVehicleListSuccess = (payload) => {
  console.log("fullVehicleListIsSaga2",payload);
  return {
    type: FULL_VEHICLES_LIST_SUCCESS,
    payload,
  };
};
export const rechargeFastag = (payload, callback) => {
  return {
    type: RECHARGE_FASTAG,
    payload,
    callback,
  };
};
export const rechargeFastagSuccess = (payload) => {
  return {
    type: RECHARGE_FASTAG_SUCCESS,
    payload,
  };
};
export const addVehicle = (obj, callback) => {
  return {
    type: ADD_VEHICLE,
    payload: obj,
    callback,
  };
};
export const addVehicleSuccess = (data) => {
  return {
    type: ADD_VEHICLE_SUCCESS,
    payload: data,
  };
};

export const cleanVehicleLoader = () => {
  return {
    type: CLEAN_VEHCILE_LOADER,
  };
};
