import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Field, ErrorMessage } from "formik";
import "./index.css";
import SvgImage from '../../Icons/Icons'
import * as Yup from "yup";

const options = [
  { label: "One", value: 0, id: 1 },
  { label: "Two", value: 1, id: 2 },
  { label: "Three", value: 2, id: 3 },
];
const option1 = [
  { label: "One", value: 0, id: 1 },
  { label: "Two", value: 1, id: 2 },
  { label: "Three", value: 2, id: 3 },
];
function LinkFastagIdToVehicle(props) {
   const { open, toggleModal, title, handleClick, handleClose, btn } = props;
  const validationSchema = Yup.object().shape({
    vehicleNumber: Yup.number().typeError("Only Numbers are allowed")
      .required("Required")
      .min(0, "Type must be selected"),
    fastagId: Yup.string().required("Required"),
    vehicleCategory: Yup.number().typeError("Only Numbers are allowed")
      .required("Required")
      .min(0, "Type must be selected"),
    engineNumber: Yup.number().typeError("Only Numbers are allowed")
      .required("Required")
      .min(0, "Number must be entered"),
    dob: Yup.string().required("Required"),
    
  });
  
  
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-head-part">
          <p className="Add-balance-title">link fastag ID to vehicle</p>
          <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          <Formik
          enableReinitialize
          validationSchema={validationSchema}
            initialValues={{
              vehicleNumber: -1,
              fastagId: "",
              vehicleCategory: -1,
              engineNumber:"",
              dob: "",
              docs:"",
            }}

            // onSubmit={(values, actions) => {
            //   values.dob = moment(values.dob).format("DD-MM-YYYY");
            //   //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
            //   console.log("valuessss", values);
            //   dispatch(
            //     addExpenseToWallet(values, userId, (res) => {
            //       actions.setSubmitting(false);
            //       console.log("HERE", res);
            //       if (res?.data?.type === "success") {
            //         console.log("successful vaiii");
            //         //navigate("/Customers")
            //         toggleModal();
            //       }
            //     })
            //   );
              
            // }}
>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">1</span>
                <p className="sub-title-edit-vehicle-detial">Enter Vehicle Number</p>
              </div>
              <Field
                    component="select"
                    placeholder={"Select"}
                    class="form-select select-option"
                    aria-label="Default select example"
                    name="vehicleNumber"
                    onChange={handleChange("vehicleNumber")}
                    value={values.vehicleNumber}
                    onBlur={handleBlur("vehicleNumber")}
                    errors={errors.vehicleNumber}
                  >
                    <option value="" disabled selected>
                      '--Select--'
                    </option>
                    {option1.map((opt) => {
                      return (
                        <option
                          value={opt.value}
                          onChange={(v) =>
                            setFieldValue("vehicleNumber", v, true)
                          }
                        >
                          {opt.label}
                        </option>
                      );
                    })}
                  </Field>
              {/* <select
                class="form-select select-option"
                aria-label="Default select example"
                onChange={(e) =>
                  setFieldValue("vehicleNumber", e.target.value, true)
                }
              >
                <option selected>Enter here or select</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select> */}
              <div className="input-amount-for-link"></div>
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.customerType && touched.customerType ? (
                      <div>*{errors.customerType}</div>
                    ) : null}
                  </div>
            </FormGroup>
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">2</span>
                <label className="sub-title-edit-vehicle-detial ">Select New Fastag ID</label>
              </div>
              <input
                type="text"
                id="html"
                className="input-body-3"
                name="fastagId"
                value={values.fastagId}
                touched={touched.fastagId}
                errors={errors.fastagId}
                onBlur={handleBlur("fastagId")}
                onChange={handleChange("fastagId")}
                placeholder="Enter fastag ID"
              ></input>
              <div className="input-amount-for-link"></div>
            </FormGroup> 
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">3</span>
                <label
                      htmlFor="customerType"
                      className="sub-title-edit-vehicle-detial"
                    >
                      Select vehicle Category 
                    </label>
              </div>
              <Field
                    component="select"
                    placeholder={"Enter here or select"}
                    class="form-select select-option"
                    aria-label="Default select example"
                    name="vehicleCategory"
                    onChange={handleChange("vehicleCategory")}
                    value={values.vehicleCategory}
                    onBlur={handleBlur("vehicleCategory")}
                    errors={errors.vehicleCategory}
                  >
                    <option value="" disabled selected>
                      '--Select--'
                    </option>
                    {options.map((opt) => {
                      return (
                        <option
                          value={opt.value}
                          onChange={(v) =>
                            setFieldValue("vehicleCategory", v, true)
                          }
                        >
                          {opt.label}
                        </option>
                      );
                    })}
                  </Field>
              {/* <select
                class="form-select select-option"
                aria-label="Default select example"
                onChange={(e) =>
                  setFieldValue("vehicleCategory", e.target.value, true)
                }
              >
                <option selected>Enter here or select</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select> */}
              <div className="input-amount-for-link"></div>
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.vehicleCategory && touched.vehicleCategory ? (
                      <div>*{errors.vehicleCategory}</div>
                    ) : null}
                  </div>
            </FormGroup>
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">4</span>
                <label className="sub-title-edit-vehicle-detial ">Vehicle Engine Number</label>
              </div>
              <input
                type="text"
                id="html"
                className="input-body-3"
                name="engineNumber"
                value={values.engineNumber}
                touched={touched.engineNumber}
                errors={errors.engineNumber}
                onBlur={handleBlur("engineNumber")}
                onChange={handleChange("engineNumber")}
                placeholder="Vehicle Engine Number"
              ></input>
              <div className="input-amount-for-link"></div>
            </FormGroup>


            <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">5</span>
                    <label className="sub-head-3 ">Enter Vehicle Registration Date</label>
                  </div>
                  <label className="date-input">
                    <DatePicker
                      name="dob"
                      className={'input-body-3 form-control' + (errors.dob && touched.dob ? ' is-invalid' : '')}
                      value={values.dob}
                      onChange={(v) => setFieldValue("dob", v, true)}
                    />
                    <SvgImage
                    style={{ marginRight: "1rem" }}
                      name="CalenderLogo"
                      width="32"
                      height="32"
                      classname="date-logo"
                    />
                  </label>

                  <div className="input-amount-for-link"></div>
                  <ErrorMessage name="dob" component="div" className="invalid-feedback" />
            </FormGroup>



            <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">6</span>
                    <label className="sub-head-3 ">
                      Attachments RC photo
                    </label>
                  </div>
                  <div>
                    <p className="sub-head-body mt-0">
                      Upload format must be in jpg, png, jpeg & pdf
                    </p>
                    <Button className="add-attach">
                      <SvgImage name="AddAttachment" height="36" width="36" />
                    </Button>
                  </div>
            </FormGroup>
            <div className="link-fastagId-to-vehicle-btn">
              <Button type="submit" className="butt-Update-Vehicle-Details" >
                Link Fastag ID
              </Button>
            </div>
            <div></div>
          </Form>
          )}
          </Formik>
          <div className="space-div"></div>
        </ModalBody>
      </Modal>
    </div>
  );
}
export default LinkFastagIdToVehicle;