import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SvgImage from "../../../../Icons/Icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { getVehicleListAction } from "../../../../../services/Store/Actions/manageVehicleAction";
import { getDriverBal } from "../../../../../services/Store/Actions/driverslistActions";
import { useParams } from "react-router-dom";
import { getTripsAction } from "../../../../../services/Store/Actions/omcActions";
import {
  getTrips,
  getTripsByUserId,
} from "../../../../../services/Store/Actions/TripsAction";
import { getWalletBalAction } from "../../../../../services/Store/Actions/LogipeCardActions";

import ClipLoader from "react-spinners/ClipLoader";

const GreenInfoCards = (props) => {
  const { setCurrOption, currOption } = props;
  const dispatch = useDispatch();
  const omcDriverData = useSelector((state) => state?.omc?.omcDriverData);
  const omcDriverWallet = useSelector(
    (state) => state?.omc?.omcDriverData?.diver?.User?.WalletAccount
  );
  const balanceRecord = useSelector(
    (state) => state?.LogipeCard?.balanceRecord
  );  //console.log(":;;;;;;;;;",balanceRecord);
  const bal1 = (!balanceRecord?.balance && balanceRecord?.balance === null) ? omcDriverWallet?.neoKred?.balance : balanceRecord?.balance;
  console.log("currOptrrrion", balanceRecord);

  const { id } = useParams();

  console.log("currOption", currOption);
  useEffect(() => {
    if (omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.regNumber) {
      dispatch(
        getVehicleListAction({
          offset: 0,
          search:
            omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet
              ?.regNumber,
          limit: 15,
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    }
  }, [omcDriverData]);

  useEffect(() => {
    console.log("0009999",id);
    dispatch(getDriverBal(id));
    if(omcDriverWallet?.neoKred?.accountStatus == 4){
      dispatch(getWalletBalAction(id));
    }
    dispatch(
      getTripsByUserId(id, {
        offset: 0,
        limit: 10,
      })
    );
  }, [id]);
  const bal = omcDriverWallet?.m2p
    ? "₹ " + omcDriverWallet?.m2p?.balance
    : omcDriverWallet?.neoKred?.accountStatus == 4
    ?  
      "₹ " + Number(bal1).toFixed(2)
    : omcDriverWallet?.neoKred?.accountStatus == 7
    ? "₹ " + omcDriverWallet?.neoKred?.balance
    : "N/A";

  const fleetVal = useSelector((state) => state?.manageVehicle?.allFleets);
  const fastagBal = omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet
    ?.regNumber
    ? fleetVal?.rows?.[0]?.Fastag?.balance
      ? "₹ " + fleetVal?.rows?.[0]?.Fastag?.balance
      : "₹ 0"
    : "N/A";
  console.log(
    "data-driver",
    omcDriverData?.diver?.FleetDriverMapHistories?.[0]?.Fleet?.regNumber,
    omcDriverWallet,
    fleetVal
  );

  const driverBal = useSelector((state) => state?.driverlist?.bal);
  console.log("driverBal", driverBal);

  const tripList = useSelector((state) => state?.trip?.tripsByUserId?.rows);
  const omcLoader = useSelector((state) => state?.LogipeCard?.loader)
  const fastagLoader = useSelector((state) => state?.manageVehicle?.loader);
  const cashLoader = useSelector((state) => state?.driverlist?.loader);
  const tripLoader = useSelector((state) => state?.trip?.loader);
  const Options = [
    {
      name: "Cards",
      icon: "creditCardlogo",
      balance: bal,
    },
    {
      name: "FASTag",
      icon: "ftDrLogo",
      balance: fastagBal,
    },
    {
      name: "Cash",
      icon: "cashDrLogo",
      balance: "₹ " + driverBal?.cashBalance,
    },
    {
      name: "Trips",
      icon: "tripDrLogo",
      balance: tripList?.[0]?.lrNumber ? tripList?.[0]?.lrNumber : "NA",
    },
  ];

  const handleSelect = (i) => {
    if (currOption != i) {
      setCurrOption(i);
    }
  };
  return (
    <>
      {Options?.map((option, i) => {
        return (
          <div className="col-6" style={{ padding: "0.5rem 0.3rem 0.5rem" }}>
            <div
              className={
                currOption != i
                  ? "padding8 notSelectedCard"
                  : "padding8 selectedCard"
              }
              onClick={() => {
                handleSelect(i);
              }}
            >
              <div
                className="flexStyling padding16B"
                style={{ position: "relative" }}
              >
                <div>
                  <SvgImage
                    name={option?.icon}
                    color={currOption != i ? "#485B7C" : "#FFFFFF"}
                  />
                </div>
                <div className="semiBold heading2 padding8L">
                  {option?.name}
                </div>
                <div
                  className=""
                  style={{
                    position: "absolute",
                    right: "0.5rem",
                    top: "0.1rem",
                  }}
                >
                  <SvgImage
                    name="nextArrow2"
                    color={currOption != i ? "#485B7C" : "#FFFFFF"}
                    width="15"
                    height="15"
                  />
                </div>
              </div>
              <div
                className={
                  currOption != i
                    ? "Medium heading4 greyShadeText1"
                    : "Medium heading4 greyShadeText2"
                }
              >
                {i != 3 ? "Balance" : "LR Number"}
              </div>
              <div className="Medium heading3">{
                i==0?omcLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={omcLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:option?.balance:
                i==1?fastagLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={fastagLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:option?.balance
                :i==2?cashLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={cashLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:option?.balance
                :tripLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={tripLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:option?.balance
                }</div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default GreenInfoCards;
