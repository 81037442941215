import React from "react";
import "./index.css";

function AddBalanceButton(props){

    const {Value,onclick,title} = props;
    return (
    <div>
        <input type= "button" className="greenButton Medium text3" value={Value} onClick = {onclick}/>
    </div>


    );
}

export default AddBalanceButton;