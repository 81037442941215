import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  NavLink,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Alert } from "bootstrap";
import { getDriverList, getDriverList2 } from "../../../../../../../services/Store/Actions/driverslistActions";
import SvgImage from "../../../../../../Icons/Icons";
import { getVehicleListAction } from "../../../../../../../services/Store/Actions/manageVehicleAction";
import { calculateTripTollInternal, updateTrip,createTrip } from "../../../../../../../services/Store/Actions/TripsAction";
import { getCustomers } from "../../../../../../../services/Store/Actions/receivableActions";


const AddNewTripForm2 = (props) => {
  const { data, toggleModal, item, type } = props;
  const { loader } = useSelector((state) => state?.omc);

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    driverName: Yup.string().required("Required"),
    vrn: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z0-9]+$/, "Enter correct vehicle number"),
    Cash: Yup.number().typeError("Only Numbers are allowed")
      .min(0, "Amount should not be negative")
      .required("Amount is required"),
    DriversCard: Yup.string().required("Required"),
    CustomerName: Yup.string(),
    CustomerNumber: Yup.number().typeError("Only Numbers are allowed"),
    StartKm: Yup.number().typeError("Only Numbers are allowed"),
    endKm:Yup.number().typeError("Only Numbers are allowed")
  });

  const [search4, setSearch4] = useState("");
  const [display4, setDisplay4] = useState(false);
  const [color1, setColor1] = useState(-1);
  const [color2, setColor2] = useState(-1);
  const [color3, setColor3] = useState(-1);
  const [userId, setUserId] = useState("");
  const [cardUserId, setCardUserId] = useState("");
  const [fleetId, setFleetId] = useState("");
  const [vCat, setVCat] = useState("");
  const [search1, setSearch1] = useState("");
  const [display1, setDisplay1] = useState(false);
  const [search2, setSearch2] = useState("");
  const [display2, setDisplay2] = useState(false);
  const [search3, setSearch3] = useState("");
  const [display3, setDisplay3] = useState(false);
  const [color4, setColor4] = useState("")
  const { allCustomers, allCustomersList } = useSelector(
    (state) => state?.receivable
  );
  const findSearch1 = () => {
    setColor1(-1);
    dispatch(
      getDriverList2({
        offset: 0,
        search: search1,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };
  const findSearch4 = () => {
    setColor4(-1);
    dispatch(
      getCustomers({
        offset: 0,
        search: search4,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };
  useEffect(() => {
    setColor4(-1);
    dispatch(
      getCustomers({
        offset: 0,
        search: search4,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, [search4]);
  const findSearch2 = () => {
    setColor2(-1);
    dispatch(
      getVehicleListAction({
        offset: 0,
        search: search2,
        limit: 10,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };

  const findSearch3 = () => {
    setColor2(-1);
    dispatch(
      getDriverList2({
        offset: 0,
        search: search3,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };

  useEffect(() => {
    setColor1(-1);
    dispatch(
      getDriverList2({
        offset: 0,
        search: search1,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, [search1]);

  useEffect(() => {
    setColor2(-1);
    dispatch(
      getVehicleListAction({
        offset: 0,
        search: search2,
        limit: 10,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, [search2]);

  useEffect(() => {
    setColor3(-1);
    // dispatch(getDriverList({
    //   offset: 0,
    //   search: '',
    //   minKyc: "",
    //   sortAs: "",
    //   sortBy: "",
    //   limit: 10
    // }))
    dispatch(
      getDriverList2({
        offset: 0,
        search: search3,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit:300
      })
    );
  }, [search3]);


  const newDriverList = useSelector((state) => state?.driverlist?.newDriverList);
  const driverCount = newDriverList?.divers?.count;
  const driverListing = newDriverList?.divers?.rows;
  // const { allDrivers } = useSelector((state) => state?.driverlist);
  // const allDriverList = allDrivers?.divers?.rows;
  const driverWithKYC = driverListing?.filter(
    (data) => data?.WalletAccount?.neoKred?.accountStatus == "4" || data?.WalletAccount?.m2p || data?.WalletAccount?.neoKred?.accountStatus == "7"
  );
  const driverCountWithKYC = driverWithKYC? driverWithKYC.length:0;
  console.log('TRIP- ver', driverWithKYC)
  const fleetVal = useSelector((state) => state?.manageVehicle?.allFleets);
  console.log("getVehicleListAction--1", fleetVal?.rows?.[0]?.Fastag);
  console.log("listinggggg", props?.item);
  const custName = driverWithKYC?.filter(
    (data) => data?.User?.userId == item?.cardUserId
  );
  console.log('TRPISCHECK',custName?.[0]?.User?.userId)
  let driverCardName = custName?.[0]?.User?.fullName
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        driverName: item?.User?.fullName || "",
        vrn: item?.vrn ? item?.vrn : item?.Fleet?.regNumber || "",
        DriversCard: driverCardName||"",
        Cash: item?.cashExpanse || "",
        CustomerName: item?.customerName || "",
        CustomerNumber: item?.customerNumber || "",
        StartKm: item?.startKmReading || "",
        endKm: item?.endKmReading || ""
      }}
      onSubmit={(values, actions) => {
        const objVal = {
          "userIdForIM": "any",
          "source": String(data?.source),
          "destination": String(data?.destination),
          "fleetId": item?.fleetId || fleetId,
          "userId": item?.userId || userId,
          "startTime": data?.startDate,
          "endTime": data?.endDate,
          "lrNumber": data?.LRNumber,
          "cardUserId": custName?.[0]?.User?.userId||cardUserId,
          "cardType": "any",
          "vehicleCategory": vCat,
          "customerName": values?.CustomerName,
          "customerNumber": values?.CustomerNumber,
          "startKmReading": values?.StartKm,
          "tripId": item?.tripId,
          "endKmReading": values.endKm  
        };
        console.log("values onSubmit", objVal, item);
        if (type == 1) {
          dispatch(updateTrip(objVal, res => {
            actions.setSubmitting(false);
            if (res?.data?.type === 'success') {
              // dispatch(calculateTripTollInternal({
              //   from: values.source,
              //   to: values.destination,
              //   flon: res?.data?.data?.sLong,
              //   flat: res?.data?.data?.sLat,
              //   tlon: res?.data?.data?.sLat,
              //   tlat: res?.data?.data?.sLong,
              //   VC: res?.data?.data?.vehicleCategory,
              // })
              // );
              toggleModal()
            }
            toggleModal()
          }))
        }
        else {
          dispatch(createTrip(objVal, (res) => {
            if (res?.status == 200) {
              toggleModal();
            }
          }))
        }

      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <div className="sub-title-1">
              <span class="circle-logo">1</span>
              <p className="sub-head-3">Select Driver</p>
            </div>
            <label for="html" className="label-body"></label>
            <input
              type="text"
              id="driverName"
              className="input-body-3"
              name="driverName"
              style={{ marginLeft: "0" }}
              onClick={() => { setDisplay1(true) }}
              onChange={(event) => {
                setDisplay1(true);
                console.log("eeeeeeeeeeeeeeeee", event.target.value);
                // setDisplay(true);
                setSearch1(event.target.value);
                setFieldValue(
                  "driverName",
                  event.target.value,
                  true
                );
              }}
              value={values.driverName}
              onBlur={handleBlur("source")}
              touched={touched.driverName}
              errors={errors.driverName}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  findSearch1();
                }
              }}
              placeholder="--- Select ---"
            />
            <div className="input-amount"></div>
            {display1 ? (
              <div
                className="padding16 newCashback whiteBackground"
                style={{
                  position: "absolute",
                  width: "350px",
                  height: "330px",
                  marginLeft: "95px",
                  zIndex: "90",
                }}
              >
                <div onClick={() => {
                  setDisplay1(false);
                  setSearch1("");
                  //dispatch(clearAllDriversByVrn());
                  setFieldValue(
                    "driverName",
                    "",
                    true
                  );
                }}
                  style={{ position: "absolute", right: "0.5rem", top: "0.5rem", cursor: "pointer" }}>
                  <SvgImage name="CloseButtonLogo" />
                </div>
                <div
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "240px",
                  }}
                >
                  {(driverCount == undefined) ?
                    <></> :
                    driverCount != 0 ?
                      driverListing?.map((data, i) => {
                        let name = data?.User?.fullName.length > 30 ? data?.User?.fullName.split(0, 30) + "..." : data?.User?.fullName;
                        let phone = data?.User?.phone;

                        //console.log ("check status",city, state);  
                        // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                        return (
                          <div
                            key={i}
                            className="padding8 row"
                            onClick={() => {
                              // setcityId1(data?.cityId);
                              setColor1(i);
                              setDisplay1(false);
                              setUserId(data?.User?.userId);
                              setFieldValue(
                                "driverName",
                                name + "-" + phone,
                                true
                              );
                            }}
                          >
                            {color1 === i ? (
                              <div className="col-1">
                                <SvgImage name="SelectedRadio" />
                              </div>
                            ) : (
                              <div className="col-1">
                                <SvgImage name="UnselectedRadio" />
                              </div>
                            )}
                            {/* <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div> */}
                            <div
                              className="col-11"
                              style={{ textAlign: "left" }}
                            >
                              {name} - {phone}
                            </div>
                          </div>
                        );
                      })
                      : <div>No Driver Found</div>}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
              {errors.driverName && touched.driverName ? (
                <div>*{errors.driverName}</div>
              ) : null}
            </div>
          </FormGroup>
          <FormGroup>
            <div className="sub-title-1">
              <span class="circle-logo">2</span>
              <label
                htmlFor="lastName"
                className="sub-title-edit-vehicle-detial "
              >
                Select Vehicle
              </label>
            </div>
            <label for="html" className="label-body"></label>
            <input
              type="text"
              id="vrn"
              className="input-body-3"
              name="vrn"
              style={{ marginLeft: "0" }}
              onChange={(event) => {
                setDisplay2(true);
                console.log("eeeeeeeeeeeeeeeee", event.target.value);
                // setDisplay(true);
                setSearch2(event.target.value);
                setFieldValue(
                  "vrn",
                  event.target.value,
                  true
                );
              }}
              onClick={() => { setDisplay2(true) }}

              value={values.vrn}
              onBlur={handleBlur("vrn")}
              touched={touched.vrn}
              errors={errors.vrn}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  findSearch2();
                }
              }}
              placeholder="--- Select ---"
            />
            <div className="input-amount"></div>
            {display2 ? (
              <div
                className="padding16 newCashback whiteBackground"
                style={{
                  position: "absolute",
                  width: "350px",
                  height: "330px",
                  marginLeft: "95px",
                  zIndex: "90",
                }}
              >
                <div onClick={() => {
                  setDisplay2(false);
                  setSearch2("");
                  //dispatch(clearAllDriversByVrn());
                  setFieldValue(
                    "vrn",
                    "",
                    true
                  );
                }}
                  style={{ position: "absolute", right: "0.5rem", top: "0.5rem", cursor: "pointer" }}>
                  <SvgImage name="CloseButtonLogo" />
                </div>
                <div
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "240px",
                  }}
                >
                  {(fleetVal?.count == undefined) ?
                    <></> :
                    fleetVal?.count != 0 ?
                      fleetVal?.rows?.map((data, i) => {
                        let fleetId = data?.fleetId;
                        let vrnVal = data?.regNumber;
                        let fleetCategory = data?.fleetCategory;
                        //console.log ("check status",city, state);  
                        // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                        return (
                          <div
                            key={i}
                            className="padding8 row"
                            onClick={() => {
                              // setcityId(data?.cityId);
                              setColor2(i);
                              setDisplay2(false);
                              setFleetId(fleetId);
                              setVCat(fleetCategory);
                              setFieldValue(
                                "vrn",
                                vrnVal,
                                true
                              );
                            }}
                          >
                            {color2 === i ? (
                              <div className="col-1">
                                <SvgImage name="SelectedRadio" />
                              </div>
                            ) : (
                              <div className="col-1">
                                <SvgImage name="UnselectedRadio" />
                              </div>
                            )}
                            {/* <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div> */}
                            <div
                              className="col-11"
                              style={{ textAlign: "left" }}
                            >
                              {vrnVal}
                            </div>
                          </div>
                        );
                      })
                      : <div>No Vehicle Found</div>}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
              {errors.vrn && touched.vrn ? (
                <div>*{errors.vrn}</div>
              ) : null}
            </div>
          </FormGroup>
          <FormGroup>
            <div className="sub-title-1">
              <span class="circle-logo">3</span>
              <label
                htmlFor="DriversCard"
                className="sub-title-edit-vehicle-detial"
              >
                Select Drivers Card
              </label>
            </div>
            <label for="html" className="label-body"></label>
            <input
              type="text"
              onClick={() => { setDisplay3(true) }}

              id="DriversCard"
              className="input-body-3"
              name="DriversCard"
              style={{ marginLeft: "0" }}
              onChange={(event) => {
                setDisplay3(true);
                console.log("eeeeeeeeeeeeeeeee", event.target.value);
                // setDisplay(true);
             
                setSearch3(event.target.value);
                setFieldValue(
                  "DriversCard",
                  event.target.value,
                  true
                );
              }}
              value={values.DriversCard}
              onBlur={handleBlur("DriversCard")}
              touched={touched.DriversCard}
              errors={errors.DriversCard}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  findSearch3();
                }
              }}
              placeholder="--- Select ---"
            />
            <div className="input-amount"></div>
            {display3 ? (
              <div
                className="padding16 newCashback whiteBackground"
                style={{
                  position: "absolute",
                  width: "350px",
                  height: "330px",
                  marginLeft: "95px",
                  zIndex: "90",
                }}
              >
                <div onClick={() => {
                  setDisplay3(false);
                  setSearch3("");
                  //dispatch(clearAllDriversByVrn());
                  setFieldValue(
                    "DriversCard",
                    "",
                    true
                  );
                }}
                  style={{ position: "absolute", right: "0.5rem", top: "0.5rem", cursor: "pointer" }}>
                  <SvgImage name="CloseButtonLogo" />
                </div>
                <div
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "240px",
                  }}
                >
                  {(driverCountWithKYC == undefined) ?
                    <></> :
                    driverCountWithKYC != 0 ?
                      driverWithKYC?.map((data, i) => {
                        let name = data?.User?.fullName.length > 30 ? data?.User?.fullName.split(0, 30) + "..." : data?.User?.fullName;
                        let phone = data?.User?.phone;

                        //console.log ("check status",city, state);  
                        // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                        return (
                          <div
                            key={i}
                            className="padding8 row"
                            onClick={() => {
                              // setcityId(data?.cityId);

                              setColor3(i);
                              setDisplay3(false);
                              setCardUserId(data?.User?.userId);
                              setFieldValue(
                                "DriversCard",
                                name + "-" + phone,
                                true
                              );
                            }}
                          >
                            {color3 === i ? (
                              <div className="col-1">
                                <SvgImage name="SelectedRadio" />
                              </div>
                            ) : (
                              <div className="col-1">
                                <SvgImage name="UnselectedRadio" />
                              </div>
                            )}
                            {/* <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div> */}
                            <div
                              className="col-11"
                              style={{ textAlign: "left" }}
                            >
                              {name} - {phone}
                            </div>
                          </div>
                        );
                      })
                      : <div>No Driver Found</div>}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
              {errors.DriversCard && touched.DriversCard ? (
                <div>*{errors.DriversCard}</div>
              ) : null}
            </div>
          </FormGroup>
          <FormGroup>
            <div className="sub-title-1">
              <span class="circle-logo">4</span>
              <label
                htmlFor="Cash"
                className="sub-title-edit-vehicle-detial "
              >
                Cash Given To driver
              </label>
            </div>
            <input
              type="text"
              id="Cash"
              className="input-body-3"
              name="Cash"
              onChange={handleChange("Cash")}
              value={values.Cash}
              onBlur={handleBlur("Cash")}
              touched={touched.Cash}
              errors={errors.Cash}
              placeholder="Enter Cash Given To driver"
            />
            <div className={`input-amount-for-link `}></div>
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
              {errors.Cash && touched.Cash ? (
                <div>*{errors.Cash}</div>
              ) : null}
            </div>
          </FormGroup>
          <FormGroup>
            <div className="sub-title-1">
              <span class="circle-logo">5</span>
              <label
                htmlFor="CustomerName"
                className="sub-title-edit-vehicle-detial "
              >
                Customer Name (optional)
              </label>
            </div>
            <label for="html" className="label-body"></label>
            <input
              type="text"
              id="CustomerName"
              className="input-body-3"
              name="CustomerName"
              style={{ marginLeft: "0" }}
              onChange={(event) => {
                setDisplay4(true);
                console.log("eeeeeeeeeeeeeeeee", event.target.value);
                // setDisplay(true);
                setSearch4(event.target.value);
                setFieldValue(
                  "CustomerName",
                  event.target.value,
                  true
                );
              }}
              onClick={() => { setDisplay4(true) }}
              value={values.CustomerName}
              onBlur={handleBlur("CustomerName")}
              touched={touched.CustomerName}
              errors={errors.CustomerName}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  findSearch4();
                }
              }}
              placeholder="--- Select ---"
            />
            <div className="input-amount"></div>
            {display4 ? (
              <div
                className="padding16 newCashback whiteBackground"
                style={{
                  position: "absolute",
                  width: "350px",
                  height: "330px",
                  marginLeft: "95px",
                  zIndex: "90",
                }}
              >
                <div onClick={() => {
                  setDisplay4(false);
                  setSearch4("");
                  //dispatch(clearAllDriversByVrn());
                  setFieldValue(
                    "CustomerName",
                    "",
                    true
                  );
                }}
                  style={{ position: "absolute", right: "0.5rem", top: "0.5rem", cursor: "pointer" }}>
                  <SvgImage name="CloseButtonLogo" />
                </div>
                <div
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "240px",
                  }}
                >
                  {(allCustomers?.count == undefined) ?
                    <></> :
                    allCustomers?.count != 0 ?
                      allCustomers?.rows?.map((data, i) => {
                        let name = data?.firstName + " " + data?.lastName;
                        //console.log ("check status",city, state);  
                        // let cardStatus=Number(data?.WalletAccount?.accountStatus);
                        return (
                          <div
                            key={i}
                            className="padding8 row"
                            onClick={() => {
                              // setcityId(data?.cityId);
                              setColor4(i);
                              setDisplay4(false);
                              // setFleetId(fleetId);
                              // setVCat(fleetCategory);
                              setFieldValue(
                                "CustomerName",
                                name,
                                true
                              );
                            }}
                          >
                            {color4 === i ? (
                              <div className="col-1">
                                <SvgImage name="SelectedRadio" />
                              </div>
                            ) : (
                              <div className="col-1">
                                <SvgImage name="UnselectedRadio" />
                              </div>
                            )}
                            {/* <div className="col-1">
                            <SvgImage name="UnselectedRadio" />
                          </div> */}
                            <div
                              className="col-11"
                              style={{ textAlign: "left" }}
                            >
                              {name}
                            </div>
                          </div>
                        );
                      })
                      : <div>No Customer Found</div>}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
              {errors.CustomerName && touched.CustomerName ? (
                <div>*{errors.CustomerName}</div>
              ) : null}
            </div>
          </FormGroup>
          <FormGroup>
            <div className="sub-title-1">
              <span class="circle-logo">6</span>
              <label
                htmlFor="CustomerNumber"
                className="sub-title-edit-vehicle-detial "
              >
                Customer Number (optional)
              </label>
            </div>
            <input
              type="text"
              id="CustomerNumber"
              className="input-body-3"
              name="CustomerNumber"
              onChange={handleChange("CustomerNumber")}
              value={values.CustomerNumber}
              onBlur={handleBlur("CustomerNumber")}
              touched={touched.CustomerNumber}
              errors={errors.CustomerNumber}
              placeholder="Enter Customer Phone Number"
              maxlength="10"

            />
            <div className={`input-amount-for-link `}></div>
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
              {errors.CustomerNumber && touched.CustomerNumber ? (
                <div>*{errors.CustomerNumber}</div>
              ) : null}
            </div>
          </FormGroup>
          {type == 1 ? <>
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">7</span>
                <label
                  htmlFor="StartKm"
                  className="sub-title-edit-vehicle-detial "
                >
                  Start KM Reading (Optional)
                </label>
              </div>
              <input
                type="text"
                id="StartKm"
                className="input-body-3"
                name="StartKm"
                onChange={handleChange("StartKm")}
                value={values.StartKm}
                onBlur={handleBlur("StartKm")}
                touched={touched.StartKm}
                errors={errors.StartKm}
                placeholder="Enter Start KM Reading"
              />
              <div className={`input-amount-for-link `}></div>
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.StartKm && touched.StartKm ? (
                  <div>*{errors.StartKm}</div>
                ) : null}
              </div>
            </FormGroup>
          </> : null}
          <FormGroup>
          {
            type==1?
          <>
            <div className="sub-title-1">
              <span class="circle-logo">8</span>
              <label
                htmlFor="endKm"
                className="sub-title-edit-vehicle-detial "
              >
                End KM Reading (Optional)
              </label>
            </div>
            <input
              type="text"
              id="endKm"
              className="input-body-3"
              name="endKm"
              onChange={handleChange("endKm")}
              value={values.endKm}
              onBlur={handleBlur("endKm")}
              touched={touched.endKm}
              errors={errors.endKm}
              placeholder="Enter End KM Reading"
            />
            <div className={`input-amount-for-link `}></div>
            <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
              {errors.endKm && touched.endKm ? (
                <div>*{errors.endKm}</div>
              ) : null}
            </div>
            </>
            :<></>
          }
            <Button
              type="submit"
              className="butt-4"
              style={{ marginTop: "90px" }}
              disabled={loader ? true : false}
              onClick={handleSubmit}
            >
              {loader ? "Please Wait" : type == 1 ? 'Update Trip' : 'Create Trip'}

              {/* Add Customer */}
            </Button>
          </FormGroup>
          <div></div>
        </Form>
      )}
    </Formik>

  );
}

export default AddNewTripForm2;
