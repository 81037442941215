import NetworkClient from "./NetworkClient";
import axios from "axios";
import {
  BASE_ACCOUNT_URL,
  BASE_FASTAG_URL,
  BASE_URL,
  BASE_WALLET_URL,
  BASE_CASH_BACK_URL,
  BASE_ACCOUNT_URL_2,
  BASE_WALLET_URL_2,
  BASE_URL_2,
  BASE_FASTAG_URL_3,
  BASE_FASTAG_URL_4,
  BASE_FASTAG_URL_7,
  BASE_M2P_URL,
  BASE_FASTAG_URL_5,
  BASE_FASTAG_URL_2,
  PreProd_BASE_URL,
  PreProd_BASE_URL_3,
  BASE_URL_3,
  BASE_INTERNAL_URL,
  BASE_INTERNAL_URL_PREPROD,
  BASE_URL_4,
  BASE_URL_ECOM,
  PreProd_BASE_URL_account,
  PreProd_BASE_URL_wallet,
  PreProd_BASE_URL_fastag,
  BASE_URL_V5,
  BASE_URL_V2,
  PreProd_BASE_URL_wallet_2,
  BASE_WALLET_URL_3,
  BASE_URL_V3,
  Prod_BASE_URL_1,
  BASE_ACCOUNT_URL_3,
  BASE_URL_V4,
  BASE_URL_V6,
  Prod_BASE_URL_TRANSACTIONS,
  BASE_OMC_URL,
  BASE_URL_V2_L,
  PreProd_BASE_URL_account_2,
  PreProd_BASE_URL_2,
} from "./globals";
import { URLS } from "./urls";
import { getDataFromAsyncStorage } from "./functions";
import { ObjectSchema } from "yup";

export async function Apis() {
  return NetworkClient.get(`${BASE_URL}${URLS.getPendingDriversRequestsList}`);
}

//receivable APISS-----
export async function addCustomerPost(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_ACCOUNT_URL}${URLS.addCustomer}`, obj);
}

export async function getCustomersAPI(obj) {
  console.log("api offset", obj);
  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.getCustomers}?search=${
      obj?.search || ""
    }&limit=${obj?.limit || 15}&offset=${obj?.offset || 0}&sortBy=${
      obj?.sortBy || ""
    }&sortAs=${obj?.sortAs || ""}&minKyc=${obj?.minKyc || ""}`
  );
}

export async function logipeAccountAPI() {
  return NetworkClient.get(`${BASE_ACCOUNT_URL}${URLS.logipeAccount}`);
  // console.log("api - step");
  //return NetworkClient.post(`${BASE_ACCOUNT_URL}${URLS.addCustomer}`, obj);
}

export async function getCustomerByIDAPI(ID) {
  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.GET_CUSTOMERS_BY_ID}${ID}`
  );
}

export async function getCustomerRecievablesAPI(obj) {
  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.getCustomerRecievables}/${
      obj.customerId
    }?search=${obj?.search || ""}&limit=10&offset=${obj?.offset || 0}&sortBy=${
      obj?.sortBy || ""
    }&sortAs=${obj?.sortAs || ""}&minKyc=${obj?.minKyc || ""}`
  );
}

export async function addNewBookingBillPost(obj) {
  return NetworkClient.post(`${BASE_ACCOUNT_URL}${URLS.addBookingBill}`, obj);
}

export async function billDetailsAPI(ID) {
  return NetworkClient.get(`${BASE_ACCOUNT_URL}${URLS.billDetails}${ID}`);
}

export async function createPaymentLinkPostApi(obj) {
  //console.log("aan",obj);
  return NetworkClient.post(
    `${BASE_ACCOUNT_URL}${URLS.createPaymentLink}`,
    obj
  );
}

export async function virtualAccountCreditApi(obj) {
  //console.log("aan",obj);
  return NetworkClient.post(
    `${BASE_ACCOUNT_URL}${URLS.virtualAccountCredit}`,
    obj
  );
}

export async function updateBillDetailsApi(obj) {
  return NetworkClient.post(
    `${BASE_ACCOUNT_URL}${URLS.updateBillDetails}`,
    obj
  );
}

export async function getDriversList(obj) {
  // console.log("api - step");
  console.log(
    `${BASE_URL_3}${URLS.getDrivers}?search=${
      obj?.search || ""
    }&limit=15&offset=${obj?.offset || 0}&sortBy=${obj?.sortBy || ""}&sortAs=${
      obj?.sortAs || ""
    }&minKyc=${obj?.minKyc || ""}`
  );
  return NetworkClient.get(
    `${BASE_URL_3}${URLS.getDrivers}?search=${obj?.search || ""}&limit=${
      obj?.limit || 15
    }&offset=${obj?.offset || 0}&sortBy=${obj?.sortBy || ""}&sortAs=${
      obj?.sortAs || ""
    }&minKyc=${obj?.minKyc || ""}`
  );
}

export async function getDriverBal(ID) {
  // console.log("api - step");
  return NetworkClient.get(`${BASE_WALLET_URL}${URLS.driverBal}/${ID}`);
}

export async function expenseSumm(ID, obj) {
  console.log("offset here", obj);
  // console.log("api - step");
  return NetworkClient.get(
    `${BASE_WALLET_URL}${URLS.expenseSumm}/${ID}?search=${
      obj?.search || ""
    }&limit=10&offset=${obj?.offset || 0}&minKyc=${obj?.minKyc || ""}`
  );
}

export async function getFleet(obj) {
  console.log("**********************", obj.search);
  // console.log("api - step");
  console.log(
    "api res here",
    `${BASE_URL_V4}${URLS.getFleet}?search=${obj?.search || ""}&limit=${
      obj?.limit || 15
    }&offset=${obj?.offset || 0}&sortBy=${obj?.sortBy || ""}&sortAs=${
      obj?.sortAs || ""
    }&minKyc=${obj?.minKyc || ""}`
  );
  return NetworkClient.get(
    `${BASE_URL_V4}${URLS.getFleet}?search=${obj?.search || ""}&limit=${
      obj?.limit || 15
    }&offset=${obj?.offset || 0}&sortBy=${obj?.sortBy || ""}&sortAs=${
      obj?.sortAs || ""
    }&minKyc=${obj?.minKyc || ""}&fleetType=${
      obj?.fleetType || 0
    }&activeStatus=${
      obj?.activeStatus >= 0 ? obj?.activeStatus : undefined
    }&assignedStatus=${
      obj?.assignedStatus >= 0 ? obj?.assignedStatus : undefined
    }`
  );
}

export async function addMoney(obj, ID) {
  // console.log("from api", obj);
  // console.log("api - step",ID);
  // console.log("api - step");
  return NetworkClient.post(`${BASE_WALLET_URL}${URLS.addMoney}/${ID}`, obj);
}
export async function linkBankToLogipeAccount(obj) {
  // console.log("from api", obj);
  // console.log("api - step",ID);
  // console.log("api - step");
  return NetworkClient.post(
    `${BASE_ACCOUNT_URL}${URLS.linkBankToLogipeAccount}`,
    obj
  );
}

export async function withdrawMoneyFromWallet(obj, ID) {
  // console.log("from api", obj);
  // console.log("api - step",ID);
  // console.log("api - step");
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.withdrawMoneyFromWallet}/${ID}`,
    obj
  );
}

export async function getCardDetails(ID) {
  console.log("from api", ID);
  // console.log("api - step");
  return NetworkClient.get(`${BASE_WALLET_URL}${URLS.getCardDetails}/${ID}`);
}

export async function getExpenseWalletSummary(userId, obj) {
  // console.log("api - step");
  // console.log("api - step");
  // return NetworkClient.get(
  //   `${BASE_WALLET_URL}${URLS.expenseWalletSummary}/${ID}`
  // );
  console.log(
    "wwwwwwwwwwwwwww",
    `${BASE_WALLET_URL}${URLS.expenseWalletSummary}/${userId}?fromDate=${
      obj?.fromDate || ""
    }&toDate=${obj?.toDate || ""}&limit=10&offset=${obj?.offset || 0}&sortBy=${
      obj?.sortBy || ""
    }&sortAs=${obj?.sortAs || ""}&isSalaryWalletTxn=${obj?.isSalaryWallet}`
  );

  return NetworkClient.get(
    `${BASE_WALLET_URL}${URLS.expenseWalletSummary}/${userId}?fromDate=${
      obj?.fromDate || ""
    }&toDate=${obj?.toDate || ""}&limit=10&offset=${obj?.offset || 0}&sortBy=${
      obj?.sortBy || ""
    }&sortAs=${obj?.sortAs || ""}&isSalaryWalletTxn=${
      obj?.isSalaryWallet
    }&transferType=${obj?.transferType || ""}`
  );
}

export async function salaryWalletSummaryApi(phone, obj) {
  // console.log("api - step");
  // console.log("api - step");
  // return NetworkClient.get(
  //   `${BASE_WALLET_URL}${URLS.expenseWalletSummary}/${ID}`
  // );

  // return NetworkClient.get(
  //   `${BASE_WALLET_URL}${URLS.SALARY_WALLET_SUMMARY}/${phone}?&limit=10&offset=${obj?.offset || 0}&sortBy=${
  //     obj?.sortBy || ""
  //   }&sortAs=${obj?.sortAs || ""}}`
  // );

  return NetworkClient.get(
    `${BASE_WALLET_URL}${URLS.SALARY_WALLET_SUMMARY}/${phone}?limit=${
      obj?.limit || 15
    }&offset=${obj?.offset || 0}&sortBy=${obj?.sortBy || ""}&sortAs=${
      obj?.sortAs || ""
    }`
  );
}

export async function payDriverSalaryApi(obj) {
  return NetworkClient.post(
    `${Prod_BASE_URL_TRANSACTIONS}${URLS.uploadSalaryCreditSheet}`,
    obj
  );
}

export async function editSalaryWalletApi(obj) {
  return NetworkClient.post(
    `${PreProd_BASE_URL_wallet_2}${URLS.editSalaryWalletDetails}`,
    obj
  );
}

export async function addExpenseToWallet(obj, ID) {
  // console.log("from api", obj);
  console.log("from api", obj);
  // console.log("api - step");
  return NetworkClient.post(
    `${BASE_WALLET_URL_2}${URLS.addExpenseToWallet}/${ID}`,
    obj
  );
}

export async function linkFLeet(obj) {
  // console.log("from api", obj);
  // console.log("from api", obj);
  console.log("fleet obj from api", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.linkFleet}`, obj);
}

//MasterWallet API
export async function getMasterWalletBalanceApi() {
  return NetworkClient.get(`${BASE_WALLET_URL}${URLS.getMasterWalletBalance}`);
}
export async function transactionHistoryApi(obj) {
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.transactionHistory}?offset=${
      obj?.offset || 1
    }&limit=${obj?.limit || 10}`,
    obj
  );
}

//ManageWallet APis
export async function getOwnerFetchBalanceAPI() {
  return NetworkClient.get(`${BASE_ACCOUNT_URL}${URLS.FETCH_BALANCE}`);
}

//Wallet APis
export async function getWalletTransactionHistoryApi(obj) {
  console.log(
    "RPG",
    `${BASE_ACCOUNT_URL}${URLS.getWalletTransactionHistory}?limit=10&offset=${
      obj?.offset || 0
    }&to=${obj?.to || ""}&from=${obj?.from || ""}&userId=${
      obj?.userId || ""
    }&isManager=${obj?.userId != "" ? 1 : ""}`,
    obj
  );
  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.getWalletTransactionHistory}?limit=10&offset=${
      obj?.offset || 0
    }&to=${obj?.to || ""}&from=${obj?.from || ""}&managerUserId=${
      obj?.userId || ""
    }&isManager=${obj?.userId && obj?.userId != "" ? 1 : ""}`,
    obj
  );
}

//Fastag APis
export async function getFastagWalletBalanceApi() {
  return NetworkClient.get(
    `${BASE_FASTAG_URL}${URLS.GET_FASTAG_WALLET_BALANCE}`
  );
}
export async function getFastagWalletTransactionApi(obj) {
  console.log(
    "RCBHATERHERE",
    `${BASE_FASTAG_URL}${URLS.GET_FASTAG_WALLET_TRANSACTION}?offset=${
      obj?.offset || 0
    }&limit=${obj?.limit || 10}&toDate=${obj?.toDate || ""}&fromDate=${
      obj?.fromDate || ""
    }&sortBy=${obj?.sortBy || ""}&sortAs=${obj?.sortAs || ""}&managerUserId=${
      obj?.userId || ""
    }&isManager=${obj?.userId != "" ? 1 : ""} `
  );
  return NetworkClient.get(
    `${BASE_FASTAG_URL}${URLS.GET_FASTAG_WALLET_TRANSACTION}?offset=${
      obj?.offset || 0
    }&limit=${obj?.limit || 10}&toDate=${obj?.toDate || ""}&fromDate=${
      obj?.fromDate || ""
    }&sortBy=${obj?.sortBy || ""}&sortAs=${obj?.sortAs || ""}&managerUserId=${
      obj?.userId || ""
    }&isManager=${obj?.userId && obj?.userId != "" ? 1 : ""} `,
    obj
  );
}
export async function getFastagTransactionHistoryAPI(fleetId, obj) {
  // console.log(
  //   `${BASE_FASTAG_URL}${
  //     URLS.getFastageVehicleTransactionHistory
  //   }${fleetId}?search=${obj?.search || ''}&limit=10&offset=${
  //     obj?.offset || 0
  //   }&sortBy=${obj?.sortBy || ''}&sortAs=${obj?.sortAs || ''}&minKyc=${
  //     obj?.minKyc || ''
  //   }`,
  // );

  return NetworkClient.get(
    `${BASE_FASTAG_URL}${
      URLS.getFastageVehicleTransactionHistory
    }${fleetId}?search=${obj?.search || ""}&limit=10&offset=${
      obj?.offset || 0
    }&sortBy=${obj?.sortBy || ""}&sortAs=${obj?.sortAs || ""}${
      obj?.from && obj?.from != null ? `&from=${obj?.from}` : ``
    }${obj?.to && obj?.to != null ? `&to=${obj?.to}` : ``}&responseType=${
      obj?.responseType || ""
    }`
  );
}

export async function transferFastagTransferAmountApi(obj) {
  console.log(
    "Ashwani wala url->",
    BASE_ACCOUNT_URL + URLS.transferFastagTransferAmount
  );
  return NetworkClient.post(
    `https://aks-api.logipe.com/transactions/api/v1/account/transaction/fastag/recharge/tag`,
    obj
  );
}

export async function getVehicleDetailsByFleetIdApi(vehicleId) {
  return NetworkClient.post(
    `${BASE_FASTAG_URL}${URLS.GET_VEHICLE_DETAILS_BY_FLEET_ID}${vehicleId}`
  );
}

export async function getVehicleDetailsApi(vehicleId) {
  return NetworkClient.post(
    `${BASE_FASTAG_URL_5}${URLS.getVehicleDetails}${vehicleId}`
  );
}

//Transfer Apis
export async function getTransferCollectionsApi(obj) {
  console.log("api - step");
  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.transferCollections}`,
    obj
  );
  // console.log("from api", obj);
  // // console.log("api - step");
  // return NetworkClient.post(
  //     `${BASE_WALLET_URL}${URLS.addExpenseToWallet}/${ID}`,
  //     obj
  // );
}

export async function getCardControl(ID) {
  // console.log("api - step");
  return NetworkClient.get(`${BASE_WALLET_URL}${URLS.getCardControl}/${ID}`);
}

export async function editCardControl(obj) {
  console.log("obj from api is here 1112111", obj);
  // console.log("from api", obj);
  // console.log("api - step");
  return NetworkClient.post(`${BASE_WALLET_URL}${URLS.editCardControl}`, obj);
}

//Owner Logipe Card APIs
export async function ChangeCardStatusAPI(id) {
  //console.log("isdnsndsjdjasdnajcnadsjcnjadcnjadnc", id)
  return NetworkClient.post(`${BASE_WALLET_URL}${URLS.changeCardStatus}${id}`);
}
export async function walletWithdrawMoneyApi(userId, obj) {
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.walletWithdrawMoney}${userId}`,
    obj
  );
}

export async function walletAddMoneyApi(userId, obj) {
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.walletAddMoney}${userId}`,
    obj
  );
}

// export async function editCardControlAPI(obj) {
//   return NetworkClient.post(`${BASE_WALLET_URL}${URLS.editCardControl}`, obj);
// }
// export async function getCardControlAPI(id) {
//   return NetworkClient.get(`${BASE_WALLET_URL}${URLS.getCardControl}${id}`);
// }
export async function getWalletSummaryAPI(userId, obj) {
  console.log(`${BASE_WALLET_URL}${URLS.getWalletSummary}${userId}`);
  return NetworkClient.get(
    `${BASE_WALLET_URL}${URLS.getWalletSummary}${userId}?limit=10&offset=${
      obj?.offset || 0
    }&sortBy=${obj?.sortBy || ""}&sortAs=${obj?.sortAs || ""}&fromDate=${
      obj?.fromDate || ""
    }&toDate=${obj?.toDate || ""}`
  );
}
export async function getCardDetailsApi(obj) {
  console.log("object", obj);
  console.log(`${BASE_WALLET_URL}${URLS.cardDetails}${obj}`);
  return NetworkClient.post(`${BASE_WALLET_URL}${URLS.cardDetails}${obj}`);
}
export async function getExpenseDetailsApi(obj) {
  //console.log("API CALLING",obj)
  console.log(`${BASE_WALLET_URL}${URLS.getExpenseDetails}${obj}`);
  return NetworkClient.get(`${BASE_WALLET_URL}${URLS.getExpenseDetails}${obj}`);
}
export async function getBalanceDetailsApi(obj) {
  console.log("object", obj);
  console.log(`${BASE_WALLET_URL}${URLS.getBalanceDetail}${obj}`);
  return NetworkClient.get(`${BASE_WALLET_URL}${URLS.getBalanceDetail}${obj}`);
}

export async function vehicleHistoryApis(ID) {
  console.log(`${BASE_URL}${URLS.vehicleHistory}/${ID}`);
  return NetworkClient.get(`${BASE_URL}${URLS.vehicleHistory}/${ID}`);
}

export async function rechargeFastagAPI(obj) {
  console.log("OSDOSKDOKDSODKOSd", obj);
  return NetworkClient.post(
    `https://aks-api.logipe.com/transactions/api/v1/fastag/transaction/wallet/fastag/recharge`,
    obj
  );
}
export async function addVehicleAPI(obj) {
  return NetworkClient.post(`${BASE_FASTAG_URL_7}${URLS.addVehicle}`, obj);
}
//Login Page
export async function sendOtpApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.sendOtp}`, obj);
}
export async function verifyOtpApi(obj) {
  console.log(obj);
  return NetworkClient.post(`${BASE_URL}${URLS.verifyOtp}`, {
    phone: obj.phone,
    otp: obj.otp,
  });
}

export async function loginWithPasswordApi(obj) {
  console.log(obj);
  // return NetworkClient.post(`${BASE_URL}${URLS.loginWithPassword}`, {
  //   phone: obj.phone,
  //   password: obj.password,
  // });

  return NetworkClient.post(
    `https://aks-api.logipe.com/api/api/v1/login/loginWithPassword`,
    {
      phone: obj.phone,
      password: obj.password,
    }
  );
}

export async function getOwnerProfileForEdit() {
  return NetworkClient.get(`${BASE_URL}${URLS.getProfile}`);
}

export async function getOwnerAccountDetails() {
  return NetworkClient.get(`${BASE_URL}${URLS.ownerAccountDetails}`);
}
export async function apiLoginRequest(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.logIn}`, obj);
}

// Card Wallet
export async function transferWalletTransferAmountApi(obj) {
  console.log(
    "path here",
    `${BASE_WALLET_URL}${URLS.transferWalletTransferAmount}`
  );
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.transferWalletTransferAmount}`,
    obj
  );
}

export async function updateCardRequestApi(obj) {
  console.log(
    "path here",
    `${BASE_WALLET_URL}${URLS.updateCardRequests}/${obj?.requestId}/${obj?.status}`
  );
  return NetworkClient.put(
    `${BASE_WALLET_URL}${URLS.updateCardRequests}${obj?.requestId}/${obj?.status}`,
    obj?.obj
  );
}

export async function getCardRequestApi(obj) {
  console.log("path here", `${BASE_WALLET_URL}${URLS.cardRequests}2/2/0`);
  return NetworkClient.get(`${BASE_WALLET_URL}${URLS.cardRequests}2/2/0`, obj);
}

//Cash_BAck
export async function fetchCashBackBalanceAPI() {
  return NetworkClient.get(
    `${BASE_CASH_BACK_URL}${URLS.FETCH_CASH_BACK_BALANCE}`
  );
}

export async function getOwnerCardDetailsAPI(userId) {
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.getownerCardDetails}/${userId}`
  );
}

//beneficiary
export async function getVirtuAccountApi() {
  return NetworkClient.get(`${BASE_ACCOUNT_URL}${URLS.GET_VIRTU_ACCOUNT}`);
}
export async function bankDirectTransferSagaApi(obj) {
  return NetworkClient.post(
    `${BASE_ACCOUNT_URL}${URLS.BANK_DIRECT_TRANSFER}`,
    obj
  );
}

export async function bankBeneficiaryTransferSagaApi(obj) {
  return NetworkClient.post(
    `${BASE_ACCOUNT_URL}${URLS.BANK_TRANSFER_TO_ADDED_BENEFICIARY}`,
    obj
  );
}

export async function bankTransferAddBeneficiaryApi(obj) {
  return NetworkClient.post(
    `${BASE_ACCOUNT_URL}${URLS.BANK_TRANSFER_BENEFICIARY_ADD}`,
    obj
  );
}
export async function getBeneficiaryApi() {
  return NetworkClient.post(`${BASE_ACCOUNT_URL}${URLS.getBeneficiary}`);
}
// APP APIS
export async function getAllStatepApi() {
  return NetworkClient.get(`${BASE_URL}${URLS.getState}`);
}
export async function getCityByStateIDApi(id) {
  return NetworkClient.get(`${BASE_URL}${URLS.getCity}${id}`);
}
export async function storeMediaFileApi(obj) {
  console.log("file dt", obj);
  const img = obj?.img;
  const isRes = obj?.isRes;
  const body = new FormData();
  let tempToken = obj?.token;
  body.append("file", img);

  //console.log('filee',img?.split("/")[img.split("/")?.length - 1])

  //console.log("filee",body);
  if (isRes) {
    let token = await getDataFromAsyncStorage("_token");
    if (!token) {
      token = await getDataFromAsyncStorage("_temptoken");
    }
    if (tempToken) {
      token = tempToken;
    }
    return fetch("https://aks-api.logipe.com/api/api/v1/media/upload", {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        otherHeader: "foo",
        "x-access-token": token,
        requesttype: "newRegistration",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log("isRes error", e);
        return e;
      });
  } else {
    let token = await getDataFromAsyncStorage("_token");
    if (tempToken) {
      token = tempToken;
    }
    return fetch("https://aks-api.logipe.com/api/api/v1/media/upload", {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        otherHeader: "foo",
        "x-access-token": token,
      },
      body: body,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("res in api", res);
        return res;
      })
      .catch((e) => {
        console.log("no Res error", e);
        return e;
      });
  }
}

//Fleet---
export async function addFleetApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.ADD_FLEET}`, obj);
}

export async function getFleetFleetID(obj) {
  return NetworkClient.get(`${BASE_URL}${URLS.getFleetByFleetId}/${obj}`);
}

// new APIs

export async function allCardBalanceApi(obj) {
  return NetworkClient.get(`${BASE_WALLET_URL}${URLS.allCardBalance}`);
}

export async function getCollectionsApi(obj) {
  return NetworkClient.get(`${BASE_ACCOUNT_URL_2}${URLS.getCollections}`);
}

export async function getSettlementsApi(obj) {
  console.log(`${BASE_ACCOUNT_URL}${URLS.getSettlements}`);
  return NetworkClient.get(`${BASE_ACCOUNT_URL}${URLS.getSettlements}`);
}

export async function getCashbackListApi(obj) {
  return NetworkClient.get(
    `${BASE_CASH_BACK_URL}${URLS.getCashbackList}?search=${
      obj?.search || ""
    }&limit=${obj?.limit || 10}&offset=${obj?.offset || ""}&sortBy=${
      obj?.sortBy || ""
    }&sortAs=${obj?.sortAs || ""}&minKyc=${obj?.minKyc || ""}`
  );
}

export async function transferToCardApi(obj, ID) {
  return NetworkClient.post(
    `${Prod_BASE_URL_TRANSACTIONS}${URLS.transferToCard}/${ID}`,
    obj
  );
}

export async function withdrawFromCardApi(obj, ID) {
  return NetworkClient.post(
    `${BASE_WALLET_URL_2}${URLS.withdrawFromCard}/${ID}`,
    obj
  );
}

export async function changePinApi(obj) {
  return NetworkClient.post(`${BASE_WALLET_URL}${URLS.setPin}/${obj}`);
}

// export async function getFleetTransactionHistoryApi(obj) {
//   return NetworkClient.get(
//     `${BASE_FASTAG_URL}${URLS.getFleetTransactionHistory}/${obj}`
//   );
// }

// export async function getFleetTransactionHistoryApi(fleetId, obj) {
//   return NetworkClient.get(
//     `${BASE_FASTAG_URL}${
//       URLS.getFleetTransactionHistory
//     }/${fleetId}?limit=10&offset=${obj?.offset || 0}&from=${
//       obj?.from || ""
//     }&to=${obj?.to || ""}`
//   );
// }
export async function getMasterAccountReportApi(obj) {
  console.log(
    "apimaster",
    `${BASE_ACCOUNT_URL}${URLS.generateMasterAccountStatement}?from=${
      obj?.from || ""
    }&to=${obj?.to || ""}&managerUserId=${obj?.userId || ""}&isManager=${
      obj?.userId != "" ? 1 : ""
    }`
  );
  if (obj?.mode == "csv") {
    return NetworkClient.get(
      `${BASE_ACCOUNT_URL}${URLS.generateMasterAccountStatement}?from=${
        obj?.from || ""
      }&to=${obj?.to || ""}&mode=${obj?.mode || ""}&managerUserId=${
        obj?.userId || ""
      }&isManager=${obj?.userId && obj?.userId != "" ? 1 : ""}`
    );
  }

  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.generateMasterAccountStatement}?from=${
      obj?.from || ""
    }&to=${obj?.to || ""}&managerUserId=${obj?.userId || ""}&isManager=${
      obj?.userId != "" ? 1 : ""
    }`
  );
}
export async function getSalaryWalletStatementApi(obj) {
  if (obj?.mode == "csv") {
    return NetworkClient.get(
      `${BASE_ACCOUNT_URL_2}${URLS.generateMasterAccountStatement}?from=${
        obj?.from || ""
      }&to=${obj?.to || ""}&mode=${obj?.mode || ""}&managerUserId=${
        obj?.userId || ""
      }&isManager=${obj?.userId && obj?.userId != "" ? 1 : ""}`
    );
  }

  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.generateMasterAccountStatement}?from=${
      obj?.from || ""
    }&to=${obj?.to || ""}&managerUserId=${obj?.userId || ""}&isManager=${
      obj?.userId != "" ? 1 : ""
    }`
  );
}

export async function getRecentCardTransactionApi(obj) {
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.getRecentCardTransaction}`,
    obj
  );
}

export async function redeemCashbackApi(obj) {
  console.log("CALLED");
  return NetworkClient.post(`${BASE_CASH_BACK_URL}${URLS.redeemCashback}`, obj);
}

export async function getFastagWalletReportApi(obj) {
  console.log("uu", obj);
  if (obj?.mode == "csv") {
    return NetworkClient.get(
      `${BASE_FASTAG_URL_2}${URLS.generateFastagWalletStatement}?from=${
        obj?.from || ""
      }&to=${obj?.to || ""}&mode=${obj?.mode || ""}&managerUserId=${
        obj?.userId || ""
      }&isManager=${obj?.userId && obj?.userId != "" ? 1 : ""}`,
      obj
    );
  }
  return NetworkClient.get(
    `${BASE_FASTAG_URL}${URLS.generateFastagWalletStatement}?from=${
      obj?.from || ""
    }&to=${obj?.to || ""}&mode=${obj?.mode || ""}`,
    obj
  );
}

export async function getFastagVehicleReportApi(fleetId, obj) {
  if (obj?.mode == "csv") {
    return NetworkClient.get(
      `${BASE_FASTAG_URL_2}${
        URLS.generateFastagVehicleStatement
      }/${fleetId}?from=${obj?.from || ""}&to=${obj?.to || ""}&mode=${
        obj?.mode || ""
      }`,
      obj
    );
  }
  return NetworkClient.get(
    `${BASE_FASTAG_URL}${URLS.generateFastagVehicleStatement}/${fleetId}?from=${
      obj?.from || ""
    }&to=${obj?.to || ""}&mode=${obj?.mode || ""}`,
    obj
  );
}
export async function getDriverListReportApi(userId, obj) {
  console.log("jigs3eqdq", obj);
  // if (obj?.mode == "csv") {
  //   return NetworkClient.get(
  //     `${BASE_WALLET_URL_2}${URLS.generateDriverListStatement}${userId}?from=${
  //       obj?.from || ""
  //     }&to=${obj?.to || ""}&mode=${obj?.mode || ""}`
  //   );
  // }
  // return NetworkClient.get(
  //   `${BASE_WALLET_URL}${URLS.generateDriverListStatement}${userId}?from=${
  //     obj?.from || ""
  //   }&to=${obj?.to || ""}&mode=${obj?.mode || ""}`
  // );

  if (userId && userId != "") {
    return NetworkClient.post(
      `${BASE_WALLET_URL}${URLS.filterWalletReport}?from=${obj?.from}&to=${
        obj?.to
      }&mode=${obj?.mode ? obj?.mode : ""}`,
      { userId: userId }
    );
  } else {
    return NetworkClient.post(
      `${BASE_WALLET_URL}${URLS.filterWalletReport}?from=${obj?.from}&to=${
        obj?.to
      }&mode=${obj?.mode ? obj?.mode : ""}`
    );
  }
}

// new card APIs

export async function newWithdrawApi(obj, ID) {
  return NetworkClient.post(
    `${Prod_BASE_URL_TRANSACTIONS}${URLS.withdrawMoneyFromWallet}/${ID}`,
    obj
  );
}

export async function newCardControlApi(ID) {
  // console.log("api - step");
  return NetworkClient.get(`${BASE_WALLET_URL_2}${URLS.newCardControl}/${ID}`);
}

export async function newEditCardControlApi(userId, obj) {
  console.log("edit ", userId);
  console.log("edit", obj);
  // console.log("from api", obj);
  // console.log("api - step");
  return NetworkClient.post(
    `${BASE_WALLET_URL_2}${URLS.newEditCardControl}/${userId}`,
    obj
  );
}

export async function getWalletBalApi(ID) {
  // console.log("api - step");
  return NetworkClient.get(`${BASE_WALLET_URL}${URLS.getCardBal}/${ID}`);
}

export async function kycDoneApi(obj) {
  //console.log("edit ", userId);
  //console.log("edit", obj);
  // console.log("from api", obj);
  // console.log("api - step");
  return NetworkClient.post(`${BASE_WALLET_URL}${URLS.kycPending}`, obj);
}

// get expense image

export async function getExpenseImageAPI(typeExp, id) {
  console.log("jigs", id);
  return NetworkClient.get(
    `${BASE_WALLET_URL}${URLS.getExpenseImage}/${typeExp}/${id}`
  );
}

// fastag isReissueAvailable
export async function isReissueAvailableAPI(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${BASE_FASTAG_URL_3}${URLS.isReissueAvailable}`,
    obj
  );
}

export async function sendFastagOTPAPI(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_FASTAG_URL}${URLS.sendFastagOTP}`, obj);
}

export async function verifyFastagOTPAPI(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_FASTAG_URL}${URLS.verifyFastagOTP}`, obj);
}

export async function fastagAddCustomerAPI(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${BASE_FASTAG_URL_4}${URLS.fastagAddCustomer}`,
    obj
  );
}

export async function vehicleTagReissueAPI(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_FASTAG_URL}${URLS.vehicleTagReissue}`, obj);
}

// m2p APIs

export async function getM2pUserDetails(id) {
  console.log("jigs", id);
  return NetworkClient.get(`${BASE_M2P_URL}${URLS.userDetails}/${id}`);
}

export async function ownerCardTypeApi() {
  console.log("jigs");
  return NetworkClient.get(`${BASE_URL_2}${URLS.ownerCard}`);
}

export async function getM2pCardDetailsApi(id) {
  console.log("jigs", id);
  return NetworkClient.get(`${BASE_M2P_URL}${URLS.cardDetails}/${id}`);
}

export async function withdrawFromM2pCardApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_M2P_URL}${URLS.withdrawFromM2pCard}`, obj);
}

export async function m2pCardControlsApi(id) {
  console.log("jigs", id);
  return NetworkClient.get(`${BASE_M2P_URL}${URLS.m2pCardControls}/${id}`);
}

export async function m2pEditCardControlsApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_M2P_URL}${URLS.m2pEditControl}`, obj);
}

export async function m2pSetPinApi(id) {
  console.log("jigs", id);
  return NetworkClient.get(`${BASE_M2P_URL}${URLS.m2pSetPin}/${id}`);
}

export async function getM2PHistoryAPI(userId, obj) {
  return NetworkClient.get(
    `${BASE_M2P_URL}${URLS.m2pTransactionHistory}${userId}?limit=10&offset=${
      obj?.offset || 0
    }&sortBy=${obj?.sortBy || ""}&sortAs=${obj?.sortAs || ""}&startDate=${
      obj?.startDate || ""
    }&endDate=${obj?.endDate || ""}`
  );
}

export async function downloadM2PHistoryAPI(userId, obj) {
  return NetworkClient.get(
    `${BASE_M2P_URL}${URLS.m2pWalletHistoryDownload}${userId}?mode=${
      obj?.mode || ""
    }&from=${obj?.from || ""}&to=${obj?.to || ""}`
  );
}

//receivable api
export async function updatePaymentApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_ACCOUNT_URL}${URLS.updatePayment}`, obj);
}

//fastag api

export async function registerYesbankApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_FASTAG_URL_5}${URLS.registerYesBank}`, obj);
}

export async function toggleAutorechargeApi(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${BASE_FASTAG_URL_3}${URLS.autoRechargeToggle}`,
    obj
  );
}
//updating profile

export async function updateProfileAPI(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL}${URLS.updateProfile}`, obj);
}

export async function deleteExpenseApi(transactionId) {
  return NetworkClient.delete(
    `${BASE_WALLET_URL}${URLS.deleteExpense}${transactionId}`
  );
}

export async function editExpenseApi(id, obj) {
  return NetworkClient.post(`${BASE_WALLET_URL}${URLS.editExpense}${id}`, obj);
}

export async function autorechargeMultipleApi(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${BASE_FASTAG_URL_3}${URLS.autoRechargeToggle}`,
    obj
  );
}

// pre-prod api for manager list
export async function getManagerListApi(obj) {
  console.log("getManagerList->api");
  return NetworkClient.get(
    `${BASE_URL}${URLS.managerList}?limit=100&offset=${
      obj?.offset || 0
    }&sortBy=${obj?.sortBy || ""}&sortAs=${obj?.sortAs || ""}&search=${
      obj?.search || ""
    }`
  );
}

// m2p apis for registering drivers

export async function m2pOTPApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_M2P_URL}${URLS.m2pOTP}`, obj);
}

export async function m2pContactInfoApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_M2P_URL}${URLS.m2pContactInfo}`, obj);
}

export async function m2pRegisterApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_M2P_URL}${URLS.m2pRegister}`, obj);
}

export async function m2pVKYCApi(id) {
  console.log("jigs", id);
  return NetworkClient.get(`${BASE_M2P_URL}${URLS.m2pVKYC}/${id}`);
}

export async function m2pOwnerAddressApi(id) {
  return NetworkClient.get(`${BASE_M2P_URL}${URLS.m2pOwnerAddress}`);
}

//auto-fill city and state

export async function autoFillCityStateApi(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${PreProd_BASE_URL}${URLS.autoFillCityState}`,
    obj
  );
}

//for monthly spending

export async function m2pMonthlyBalanceApi(id) {
  console.log("jigs", id);
  return NetworkClient.get(`${BASE_M2P_URL}${URLS.m2pMonthlyBalance}/${id}`);
}

// list of accounts linked for master account neft

export async function neftAccountListApi() {
  return NetworkClient.get(`${BASE_ACCOUNT_URL}${URLS.neftAccountList}`);
}

//UPI, net-banking, etc transfer
export async function createPaymentTokenApi(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `https://aks-api.logipe.com/transactions/api/v1/orders/create/order/cashfree`,
    obj
  );
  //return NetworkClient.post(`https://gke-apip.logipe.com/account/api/v2/${URLS.createPaymentToken}`, obj);
}

//getDriversListWithVrn

export async function getDriversListWithVrnApi(obj) {
  if (obj?.searchByName)
    return NetworkClient.get(
      `${BASE_URL_3}${URLS.getDriversListWithVrn}?searchByName=${
        obj?.searchByName || ""
      }&limit=${obj?.limit || 100}`
    );
  if (obj?.searchByFleetAssigned)
    return NetworkClient.get(
      `${BASE_URL_3}${URLS.getDriversListWithVrn}?searchByFleetAssigned=${
        obj?.searchByFleetAssigned || ""
      }&limit=${obj?.limit || 100}`
    );
}

export async function qrcodeApi() {
  return NetworkClient.get(`${BASE_ACCOUNT_URL}${URLS.qrcode}`);
}
// APIs for notifications in web

export async function addDeviceIdApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_INTERNAL_URL}${URLS.addDeviceId}`, obj);
}

export async function getDeviceIdDataApi() {
  console.log("api - step");
  return NetworkClient.get(`${BASE_INTERNAL_URL}${URLS.getDeviceIdData}`);
}

export async function deleteDeviceIdApi(id) {
  console.log("api - step");
  return NetworkClient.put(`${BASE_INTERNAL_URL}${URLS.deleteDeviceId}${id}`);
}

// APIs for onboarding
export async function ownerDetailsApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_4}${URLS.ownerDetails}`, obj);
}

export async function businessDetailsApi(obj) {
  console.log("business details api - step", obj);
  return NetworkClient.post(`${BASE_URL_4}${URLS.businessDetails}`, obj);
}

export async function updatePanAadharApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_4}${URLS.updatePanAadhar}`, obj);
}

export async function getAadharOtpApi(aadhaar) {
  console.log("api - step");
  return NetworkClient.get(`${BASE_URL}${URLS.getAadharOtp}${aadhaar}`);
}

export async function panAuthApi(pan) {
  console.log("api - step");
  return NetworkClient.get(`${BASE_URL}${URLS.panAuth}${pan}`);
}

export async function downloadAadharXMLApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL}${URLS.downloadAadharXML}`, obj);
}

export async function setPinRegApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL}${URLS.setPinReg}`, obj);
}

export async function refreshTokenApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL}${URLS.refreshToken}`, obj);
}

//ecom APIs

export async function getCartDetailsApi() {
  console.log("api - step");
  return NetworkClient.get(`${BASE_URL_ECOM}${URLS.getCartDetails}`);
}

export async function addToCartApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_ECOM}${URLS.addToCart}`, obj);
}

export async function removeToCartApi(id, obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_ECOM}${URLS.removeToCart}${id}`, obj);
}

export async function getOrderListApi(obj) {
  console.log("api - step");
  return NetworkClient.get(
    `${BASE_URL_ECOM}${URLS.getOrderList}?search=${obj?.search || ""}&limit=${
      obj?.limit || 5
    }&offset=${obj?.offset || 0}&sortBy=${obj?.sortBy || ""}&sortAs=${
      obj?.sortAs || ""
    }&minKyc=${obj?.minKyc || ""}&type=${obj?.type || ""}`
  );
}

export async function createOrderApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_ECOM}${URLS.createOrder}`, obj);
}

export async function getOrderApi(id) {
  console.log("idhere", id);
  return NetworkClient.get(`${BASE_URL_ECOM}${URLS.getOrder}${id.id}`);
}

export async function addAddressDetailsApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_ECOM}${URLS.addAddressDetails}`, obj);
}

export async function getAddressListApi() {
  console.log("api - step");
  return NetworkClient.get(`${BASE_URL_ECOM}${URLS.getAddressList}`);
}

export async function getAddressDetailsApi(id) {
  console.log("api - step");
  return NetworkClient.get(`${BASE_URL_ECOM}${URLS.getAddressDetails}${id}`);
}

export async function getProductDetailApi(id) {
  console.log("api - step");
  return NetworkClient.get(`${BASE_URL_ECOM}${URLS.getProductDetail}${id}`);
}

export async function getLogiCoinsApi(obj) {
  console.log("api - hi5", obj);
  return NetworkClient.get(`${BASE_CASH_BACK_URL}${URLS.getLogicoins}`);
}

// for payment gateway for cashfree
// export async function createPaymentTokenApi(obj) {
//   console.log("api - step");
//   return NetworkClient.post(`${BASE_ACCOUNT_URL}${URLS.createPaymentToken}`, obj);
// }

// get in touchand ask for help getInTouch
export async function getInTouchApi(id, obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_ECOM}${URLS.getInTouch}${id}`, obj);
}

export async function generateRecieptApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_ECOM}${URLS.generateReciept}`, obj);
}

export async function getProductListingApi(obj) {
  console.log("api - hi5", obj);
  return NetworkClient.get(
    `${BASE_URL_ECOM}${URLS.getProductListing}?search=${
      obj?.search || ""
    }&limit=${obj?.limit || 10}&offset=${obj?.offset || 0}&sortBy=${
      obj?.sortBy || ""
    }&sortAs=${obj?.sortAs || ""}&minKyc=${obj?.minKyc || ""}&type=${
      obj?.type || ""
    }`
  );
}

export async function freshDeskTicketApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_2}${URLS.freshDeskTicket}`, obj);
}

export async function orderPaymentApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_ECOM}${URLS.orderPayment}`, obj);
}

export async function ticketWithoutAuthApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL_3}${URLS.ticketWithoutAuth}`, obj);
}

export async function uploadSalaryApi(obj) {
  console.log("file dt", obj);
  const img = obj?.file;
  // const phone = obj?.phone;
  const isRes = obj?.isRes;
  const body = new FormData();
  body.append("file", img);
  //body.append("userIdForIM", phone);
  //console.log('filee',img?.split("/")[img.split("/")?.length - 1])

  //console.log("filee",body);
  if (isRes) {
    // let token = await getDataFromAsyncStorage("_token");
    // if (!token) {
    //   token = await getDataFromAsyncStorage("_temptoken");
    // }
    // return fetch(BASE_INTERNAL_URL + URLS.uploadFile, {
    //   method: "POST",
    //   headers: {
    //     // "Content-Type": "multipart/form-data",
    //     otherHeader: "foo",
    //     "x-access-token": token,
    //     requesttype: "newRegistration",
    //   },
    //   body: body,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((e) => {
    //     console.log("isRes error", e);
    //     return e;
    //   });
  } else {
    const token = await getDataFromAsyncStorage("_token");
    return NetworkClient.post(
      `${PreProd_BASE_URL_wallet_2}${URLS.uploadSalary}`,
      body
    );
    // return fetch('https://aks-api.logipe.com/wallet/api/v1/' + URLS.uploadSalary, {
    //   method: "POST",
    //   headers: {
    //     // "Content-Type": "multipart/form-data",
    //     otherHeader: "foo",
    //     "x-access-token": token,
    //   },
    //   body: body,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((e) => {
    //     console.log("no Res error", e);
    //     return e;
    //   });
  }
}

export async function downloadSalaryApi(obj) {
  console.log("api - hi5", obj);
  return NetworkClient.get(`${PreProd_BASE_URL_wallet}${URLS.downloadSalary}`);
}

export async function managerGetControlApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL}${URLS.managerGetControl}`, obj);
}

export async function addNicknameApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL}${URLS.addNickname}`, obj);
}

//password reset

export async function resetPasswordApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_URL}${URLS.resetPassword}`, obj);
}
// report filters
export async function filterMAReportApi(obj) {
  console.log("api - step");
  return NetworkClient.get(
    `${BASE_ACCOUNT_URL_2}${URLS.filterMAReport}?from=${obj?.from}&to=${
      obj?.to
    }&mode=${obj?.mode ? obj?.mode : ""}`
  );
}

export async function filterWalletReportApi(userId, obj) {
  console.log("filterWalletReportApi");
  if (userId && userId != "") {
    return NetworkClient.post(
      `${BASE_WALLET_URL}${URLS.filterWalletReport}?from=${obj?.from}&to=${
        obj?.to
      }&mode=${obj?.mode ? obj?.mode : ""}`,
      { userId: userId }
    );
  } else {
    return NetworkClient.post(
      `${BASE_WALLET_URL}${URLS.filterWalletReport}?from=${obj?.from}&to=${
        obj?.to
      }&mode=${obj?.mode ? obj?.mode : ""}`
    );
  }
}

export async function filterFastagReportApi(obj) {
  console.log(
    "api - step-------",
    `https://gke-apip.logipe.com/fastag/api/v2/${URLS.filterFastagReport}`
  );
  return NetworkClient.post(
    `${BASE_FASTAG_URL_2}${URLS.filterFastagReport}?from=${
      obj?.from || ""
    }&to=${obj?.to || ""}&mode=${obj?.mode || ""}`,
    obj
  );
}

// export async function filterFastagReportApi(fleetId, obj) {
//   console.log("api - step");
//   return NetworkClient.post(`${BASE_FASTAG_URL}${URLS.filterFastagReport}${fleetId}`, obj);
// }

export async function filterExpenseReportApi(userId, obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.filterExpenseReport}${userId}`,
    obj
  );
  // return NetworkClient.get(`${PreProd_BASE_URL}${URLS.downloadSalary}`);
}

export async function managerReportApi(userId, obj) {
  console.log("sjbdjbdksbdskljb", `${BASE_URL}${URLS.managerReport}`);
  return NetworkClient.post(`${BASE_URL}${URLS.managerReport}`, obj);
  // return NetworkClient.get(`${PreProd_BASE_URL}${URLS.downloadSalary}`);
}

export async function bulkTransferSampleApi(obj) {
  console.log("api - step");
  return NetworkClient.get(
    `${Prod_BASE_URL_TRANSACTIONS}${URLS.bulkTransferSheet}`
  );
}
export async function bulkTransferUploadApi(obj) {
  console.log("api - step");
  console.log("api   aaya");
  const img = obj?.file;
  const body = new FormData();
  body.append("file", img);
  body.append("bulkTransferId", obj.bulkTransferId);
  return NetworkClient.post(
    `${Prod_BASE_URL_TRANSACTIONS}${URLS.bulkTransferSheetUpload}`,
    body
  );
}

// report filters
// export async function filterMAReportApi(obj) {
//   console.log("api - step");
//   return NetworkClient.post(`${PreProd_BASE_URL_account}${URLS.filterMAReport}`, obj);
// }

// export async function filterWalletReportApi(userId, obj) {
//   console.log("api - step");
//   return NetworkClient.post(`${PreProd_BASE_URL_wallet}${URLS.filterWalletReport}${userId}`, obj);
// }

// export async function filterFastagReportApi(fleetId, obj) {
//   console.log("api - step");
//   return NetworkClient.post(`${PreProd_BASE_URL_fastag}${URLS.filterFastagReport}${fleetId}`, obj);
// }

// omc apis
export async function getOmcVehiclesListApi() {
  console.log("api - step");
  return NetworkClient.get(`${BASE_WALLET_URL}${URLS.getOmcVehiclesList}`);
}

export async function omcVehicleBalanceApi(id) {
  console.log("api - step");
  return NetworkClient.get(`${BASE_OMC_URL}${URLS.omcVehicleBalance}${id}`);
}

export async function omcVehicleTransactionApi(id, obj) {
  console.log("api - step");
  return NetworkClient.get(
    `https://aks-api.logipe.com/omc/api/v1/omcVehicle/omcWalletTransaction/${id}?limit=${
      obj?.limit || 10
    }&offset=${obj?.offset || 0}&from=${obj?.from || ""}&to=${
      obj?.to || ""
    }&transferType=${obj?.transferType || ""}`
  );
}

export async function omcLoadMoneyApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_ACCOUNT_URL}${URLS.omcLoadMoney}`, obj);
}

export async function omcWithdrawMoneyApi(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${BASE_WALLET_URL_2}${URLS.omcWithdrawMoney}`,
    obj
  );
}

export async function omcGetVehicleStatusApi(id) {
  console.log("api - step");
  return NetworkClient.get(
    `${BASE_WALLET_URL}${URLS.omcGetVehicleStatus}${id}`
  );
}

export async function omcDriverDataApi(id) {
  console.log("api - step getting info--4");
  return NetworkClient.get(`${BASE_URL_2}${URLS.omcDriverData}${id}`);
}

export async function omcChangeNumApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_OMC_URL}${URLS.omcChangeNum}`, obj);
}

//Trips

export async function createTripApi(obj) {
  console.log(obj);
  return NetworkClient.post(`${BASE_URL_V2}${URLS.createTrip}`, obj);
}
export async function getTripsApi(obj) {
  const { limit, offset, search, isActive } = obj;
  console.log("INAPIS33", obj);
  const url =
    search && search.length > 0
      ? `${BASE_URL}${URLS.getTrips}?limit=${limit}&offset&search=${search}${
          isActive ? `&isActive=${isActive}` : ``
        }`
      : `${BASE_URL}${URLS.getTrips}?limit=${limit}&offset=${offset}${
          isActive ? `&isActive=${isActive}` : ``
        }`;
  console.log("TRIPURL", url);
  return NetworkClient.get(url);
}

export async function getRoutesApi(obj) {
  const { limit, offset, search, isActive } = obj;
  console.log("INAPIS333", obj);
  const url =
    search && search.length > 0
      ? `https://aks-api.logipe.com/api/api/v2/${
          URLS.getRoutes
        }?limit=${limit}&offset=${offset}&search=${search}${
          isActive ? `&isActive=${isActive}` : ``
        }`
      : `https://aks-api.logipe.com/api/api/v2/${
          URLS.getRoutes
        }?limit=${limit}&offset=${offset}${
          isActive ? `&isActive=${isActive}` : ``
        }`;
  console.log("TRIPURL", url);
  return NetworkClient.post(url);
}

export async function addRouteApi(obj) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.addRoutes}`, obj);
}
export async function getTripsNewApi(obj) {
  const {
    limit,
    offset,
    search,
    isActive,
    fleetId,
    driverId,
    startTime,
    endTime,
    source,
    destination,
    tripNo,
    tripId,
  } = obj;
  console.log("dasdas", obj);
  const url = `${BASE_URL_V2}${URLS.getTripsNew}?limit=${limit || 10}&offset=${
    offset || 0
  }&search=${search || ""}${isActive ? `&isActive=${isActive}` : ``}${
    fleetId ? `&fleetId=${fleetId}` : ``
  }${source ? `&source=${source}` : ``}${
    destination ? `&destination=${destination}` : ``
  }${driverId ? `&driverId=${driverId}` : ``}${
    startTime && startTime != "Invalid date" ? `&startTime=${startTime}` : ``
  }${endTime && endTime != "Invalid date" ? `&endTime=${endTime}` : ``}${
    tripNo ? `&tripNo=${tripNo}` : ``
  }&tripId=${tripId || ""}`;
  return NetworkClient.get(url);
}
export async function getTripsByFleetApi(id) {
  return NetworkClient.get(`${BASE_URL}${URLS.getTripsByFleet}${id}`);
}
export async function endTripApi(obj) {
  const { tripId } = obj;
  return NetworkClient.patch(`${BASE_URL_V2}${URLS.endTrip}${tripId}`, obj);
}
export async function deleteTripApi(id) {
  return NetworkClient.delete(`${BASE_URL}${URLS.endTrip}${id}`);
}
export async function updateTripApi(obj) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.updateTrip}`, obj);
}
export async function fastagVehicleTransactionByFleetApi(obj) {
  const { startTime, endTime, limit, fleetId, offset, transType } = obj;
  return NetworkClient.get(
    `${BASE_FASTAG_URL_2}${
      URLS.getFastageVehicleTransactionHistory
    }${fleetId}?limit=${limit}&offset=${offset}&from=${startTime || ""}&to=${
      endTime || ""
    }&transType=${transType || ""}`
  );
}
export async function getTransactionsAndExpenseApi(obj) {
  const { startTime, endTime, limit, userId, offset, cardUserId } = obj;
  return NetworkClient.get(
    `${BASE_WALLET_URL}${URLS.getTransactionsAndExpense}${cardUserId}?from=${startTime}&to=${endTime}&limit=${limit}&offset=${offset}`
  );
}
export async function cashTransactionsApi(obj) {
  const { startTime, endTime, limit, userId, offset } = obj;
  return NetworkClient.get(
    `${BASE_WALLET_URL_2}${URLS.cashTransactions}?createdByUserId=${userId}&startTime=${startTime}&endTime=${endTime}&offset=${offset}&limit=${limit}`
  );
}
export async function addCashGivenApi(obj) {
  const id = obj.userId;
  return NetworkClient.post(`${BASE_WALLET_URL}${URLS.addCashGiven}${id}`, obj);
}
export async function editCashGivenApi(id, obj) {
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.editCashGiven}${id}`,
    obj
  );
}

export async function getCityAndStateApi(obj) {
  const { limit, offset, sortBy, sortAs, search } = obj;
  return NetworkClient.get(
    `${BASE_URL}${URLS.getCityAndState}?search=${search}`
  );
}

export async function mapGetBalanceApi(obj) {
  return NetworkClient.post(`${BASE_FASTAG_URL_2}${URLS.MAP_GET_BALANCE}`, obj);
}

export async function getWeatherDataApi(obj) {
  const { source, destination } = obj;
  // console.log('IN API URLL',`${BASE_URL}${URLS.getWeatherData}?souce=${source}&destination=${destination}`);
  return NetworkClient.get(
    `${BASE_URL}${URLS.getWeatherData}?source=${source}&destination=${destination}`
  );
}

export async function getM2pCardTransactionsApi(obj) {
  const { cardUserId, limit, offset, startTime, endTime } = obj;
  return NetworkClient.get(
    `${BASE_M2P_URL}${
      URLS.M2P_CARD_TRANSACTIONS
    }${cardUserId}?limit=10&offset=${
      offset || 0
    }&startDate=${startTime}&endDate=${endTime}`
  );
}
export async function calculateTripTollApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.CALCULATE_TRIP_TOLL}`, obj);
}
export async function calculateTripTollInternalApi(obj) {
  console.log("obj", obj);
  return NetworkClient.post(
    `${BASE_INTERNAL_URL}${URLS.CALCULATE_TRIP_TOLL_INTERNAL}`,
    obj
  );
}

export async function getTripTransactionApi(obj) {
  const { startTime, endTime, limit, fleetId, offset } = obj;
  console.log("obj=>", obj);
  console.log(
    "url=>",
    `${BASE_FASTAG_URL}${
      URLS.getTripTransaction
    }${fleetId}?limit=${limit}&offset=${offset}&from=${""}&to=${""}&transType=debit`
  );
  return NetworkClient.get(
    `${BASE_FASTAG_URL}${
      URLS.getTripTransaction
    }${fleetId}?limit=${limit}&offset=${offset}&from=${""}&to=${""}&transType=debit`
  );
}

export async function getTripReportApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.GET_TRIP_REPORT}`, obj);
}

export async function getTripsByUserId(obj) {
  return NetworkClient.get(`${BASE_URL}${URLS.TRIP_BY_USERID}${obj}`);
}
export async function omcRegisterDriverApi(id) {
  console.log("api - step");
  return NetworkClient.get(
    `${PreProd_BASE_URL_wallet_2}${URLS.omcRegisterDriver}${id}`
  );
}

//trips

export async function driverAddBeneficiaryApi(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.driverAddBeneficiary}`,
    obj
  );
}

export async function getDriverBeneficiaryApi(obj) {
  console.log("api - step");
  return NetworkClient.get(
    `${BASE_WALLET_URL}${URLS.getDriverBeneficiary}?limit=${
      obj?.limit || 10
    }&offset=${obj?.offset || 0}&search=${obj?.search || 0}`
  );
}

export async function driverTransferToBeneficiaryApi(id, obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.driverTransferToBeneficiary}${id}`,
    obj
  );
}

export async function addCardBeneficiaryApi(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${PreProd_BASE_URL_wallet}${URLS.addCardBeneficiary}`,
    obj
  );
}

export async function fuelWalletREchargeApi(obj) {
  console.log("api - step_fuel_wallet");
  return NetworkClient.post(
    `${Prod_BASE_URL_TRANSACTIONS}${URLS.fuelWalletREcharge}`,
    obj
  );
}

export async function downloadSalaryCreditSheetApi(obj) {
  console.log("api - hi5", obj);
  // return NetworkClient.get(
  //   `${PreProd_BASE_URL_wallet}${URLS.downloadSalaryCreditSheet}`
  // );
  return NetworkClient.get(
    `${BASE_WALLET_URL}${URLS.downloadSalaryCreditSheet}`
  );
}

export async function uploadSalaryCreditSheetApi(obj) {
  console.log("file dt", obj);
  const img = obj?.file;
  // const phone = obj?.phone;
  const isRes = obj?.isRes;
  const body = new FormData();
  body.append("file", img);
  body.append("bulkTransferId", obj.bulkTransferId);
  //body.append("userIdForIM", phone);
  //console.log('filee',img?.split("/")[img.split("/")?.length - 1])

  //console.log("filee",body);
  if (isRes) {
    // let token = await getDataFromAsyncStorage("_token");
    // if (!token) {
    //   token = await getDataFromAsyncStorage("_temptoken");
    // }
    // return fetch(BASE_INTERNAL_URL + URLS.uploadFile, {
    //   method: "POST",
    //   headers: {
    //     // "Content-Type": "multipart/form-data",
    //     otherHeader: "foo",
    //     "x-access-token": token,
    //     requesttype: "newRegistration",
    //   },
    //   body: body,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((e) => {
    //     console.log("isRes error", e);
    //     return e;
    //   });
  } else {
    const token = await getDataFromAsyncStorage("_token");
    return NetworkClient.post(
      `${Prod_BASE_URL_TRANSACTIONS}${URLS.uploadSalaryCreditSheet}`,
      body
    );
    //   method: "POST",
    //   headers: {
    //     // "Content-Type": "multipart/form-data",
    //     otherHeader: "foo",
    //     "x-access-token": token,
    //   },
    //   body: body,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((e) => {
    //     console.log("no Res error", e);
    //     return e;
    //   });
  }
}

export async function getSalaryWalletDetailsApi(obj) {
  console.log(`${URLS.salaryWalletDetails}`, "getSalaryWalletDetailsApi", obj);
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.salaryWalletDetails}`,
    obj
  );
}
export async function editSalaryWalletDetailsApi(obj) {
  console.log("editSalaryWalletDetailsApi", obj);
  return NetworkClient.post(
    `${PreProd_BASE_URL_wallet_2}${URLS.editSalaryWalletDetails}`,
    obj
  );
}

export async function createSalaryWalletApi(obj) {
  console.log("createSalaryWalletApi", obj);
  return NetworkClient.post(
    `${PreProd_BASE_URL_wallet_2}${URLS.createSalaryWallet}`,
    obj
  );
}

export async function addDriverV2Api(obj) {
  return NetworkClient.post(`${BASE_URL_V3}${URLS.addDriverV2}`, obj);
}
export async function addFreightApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.addFreight}`, obj);
}

// export async function getCityAndStateApi(obj) {
//   console.log("api - step");
//   return NetworkClient.get(`${BASE_URL}${URLS.getCityAndState}?limit=${obj?.limit || 10}&offset=${obj?.offset || 0}&search=${obj?.search || 0}`);
// }

// export async function createTripApi(obj) {
//   console.log("api - step");
//   return NetworkClient.post(`${BASE_URL}${URLS.createTrip}`,obj);
// }

export async function reportGenerateStatementForVendorApi(obj) {
  console.log("api - step");
  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.reportGenerateStatementForVendor}?from=${
      obj?.from || ""
    }&to=${obj?.to || ""}&vendorId=${obj?.vendorId || ""}&isLogipeVendor=${
      obj?.isLogipeVendor || ""
    }`
  );
}
export async function getLogipeVendorDetailsApi(obj) {
  console.log("api - step");
  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.getLogipeVendorDetails}${obj?.phone}`
  );
}
export async function vendorTransactionsApi(obj) {
  console.log("api - step");
  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.vendorTransactions}?search=${
      obj?.search || ""
    }&limit=${obj?.limit || 10}&offset=${obj?.offset || 0}&from=${
      obj?.from || ""
    }&to=${obj?.to || ""}&vendorId=${obj?.vendorId || ""}&isLogipeVendor=${
      obj?.isLogipeVendor || ""
    }`
  );
  // return NetworkClient.get(
  //   `${BASE_ACCOUNT_URL_2}${URLS.vendorTransactions}?search=${
  //     obj?.search || ""
  //   }&limit=${obj?.limit || 10}&offset=${obj?.offset || 0}&from=${
  //     obj?.from || ""
  //   }&to=${obj?.to || ""}&vendorId=${obj?.vendorId || ""}&isLogipeVendor=${
  //     obj?.isLogipeVendor || ""
  //   }`
  // );
}

export async function createVendorApi(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${PreProd_BASE_URL_account_2}${URLS.createVendor}`,
    obj
  );
}
export async function editVendorApi(obj) {
  console.log("api - step");
  return NetworkClient.post(`${BASE_ACCOUNT_URL}${URLS.editVendor}`, obj);
}
export async function vendorUploadBillApi(obj) {
  console.log("file dt", obj);
  const img = obj?.img;
  const body = new FormData();
  body.append("file", img);
  body.append("transaction_id", obj?.transaction_id);
  console.log("body", img, body);
  const token = await getDataFromAsyncStorage("_token");
  return fetch(`${BASE_ACCOUNT_URL}${URLS.vendorUploadBill}`, {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      otherHeader: "foo",
      "x-access-token": token,
    },
    body: body,
  })
    .then((res) => res.json())
    .then((res) => {
      console.log("res in api", res);
      return res;
    })
    .catch((e) => {
      console.log("no Res error", e);
      return e;
    });
}
export async function vendorGetSignedUrlApi(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `${BASE_ACCOUNT_URL}${URLS.vendorGetSignedUrl}`,
    obj
  );
}
export async function getVendorApi(obj) {
  console.log("api - step", obj);
  return NetworkClient.post(
    `${PreProd_BASE_URL_account}${URLS.getVendor}?limit=${
      obj?.limit || 10
    }&offset=${obj?.offset || 0}&name=${obj?.search || ""}&vendorType=${
      obj?.vendorType || ""
    }`,
    {
      isLogipeVendor: obj?.isLogipeVendor,
      vendorId: obj?.vendorId,
    }
  );
}
export async function transferToVendorApi(obj) {
  console.log("api - step");
  return NetworkClient.post(
    `https://aks-api.logipe.com/transactions/api/v1/account/transaction/vendor/transfer`,
    obj
  );
}
export async function updateFleetApi(obj) {
  return NetworkClient.post(
    `${BASE_URL}${URLS.updateFleet}/${obj.fleetId}`,
    obj
  );
}
export async function getFleetTrackerApi(obj) {
  console.log("**********************", obj.search);
  // console.log("api - step");
  console.log(
    "api res here",
    `${BASE_URL_V5}${URLS.getFleetTracker}?search=${obj?.search || ""}&limit=${
      obj?.limit || 6
    }&offset=${obj?.offset || 0}&sortBy=${obj?.sortBy || ""}&sortAs=${
      obj?.sortAs || ""
    }&minKyc=${obj?.minKyc || ""}`
  );
  return NetworkClient.get(
    `${BASE_URL_V6}${URLS.getFleetTracker}?search=${obj?.search || ""}&limit=${
      obj?.limit || 6
    }&offset=${obj?.offset || 0}&sortBy=${obj?.sortBy || ""}&sortAs=${
      obj?.sortAs || ""
    }&minKyc=${obj?.minKyc || ""}&fleetType=${obj?.fleetType || 0}`
  );
}
export async function generateFreshdeskTicketV2Api(payload) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.freshDeskTicket}`, payload);
}

export async function editFreightApi(obj) {
  return NetworkClient.post(`${BASE_URL}freight/editFreightDetails`, obj);
}

export async function getFreightDetailsApi(obj) {
  return NetworkClient.post(
    `${BASE_URL}freight/getFreightDetailsFromTripId`,
    obj
  );
}

export async function tripActionBulkApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.tripBulkActions}`, obj);
}

//sales
export async function getAllCustomersApi(payload) {
  console.log("==---===", payload);
  return NetworkClient.get(
    // `${BASE_URL_V2}${URLS.getAllCustomers}?phone=${payload?.phone || ""}&search2=${
    `${BASE_URL_V2_L}${URLS.getAllCustomers}?phone=${
      payload?.phone || ""
    }&search2=${payload?.companyName || ""}&limit=${
      payload?.limit || 10
    }&offset=${payload?.offset || 0}&sortBy=${payload?.sortBy || ""}&search=${
      payload?.search || ""
    }&sortAs=${payload?.sortAs || ""}`
  );
}
export async function createCustomerApi(payload) {
  return NetworkClient.post(`${BASE_URL}${URLS.createCustomer}`, payload);
  // return NetworkClient.post(
  //   `http://192.168.1.6:9001/api/api/v1/sales/createCustomer`,
  //   payload
  // );
}
/////

export async function addNewPhoneFWApi(payload) {
  return NetworkClient.post(
    `https://aks-api.logipe.com/omc/api/v1/omcVehicle/registerOwnerHpcl`,
    payload
  );
}

/////
export async function getCustomerApi(payload) {
  return NetworkClient.get(
    `${BASE_URL_V2}${URLS.getCustomer}/${payload?.customerId}`
  );
}
export async function getLedgerListApi(obj) {
  return NetworkClient.get(
    `${BASE_URL_V2}${URLS.getLedgerList}/${obj.customerId}?search=${
      obj?.search || ""
    }&limit=${obj?.limit || 10}&offset=${obj?.offset || 0}&sortBy=${
      obj?.sortBy || ""
    }&sortAs=${obj?.sortAs || ""}&from=${obj?.from || ""}&to=${
      obj?.to || ""
    }&transferType=${obj?.transferType || ""}`
  );
}
export async function deleteLedgerApi(payload) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.deleteLedger}`, payload);
}
export async function createLogipeInvoiceApi(payload) {
  // return NetworkClient.post(`${BASE_URL}${URLS.createLogipeInvoice}`, payload);

  return NetworkClient.post(`${BASE_URL_V2}sales/createLogipeInvoice`, payload);
}
export async function createExternalInvoiceApi(payload) {
  return NetworkClient.post(
    `${BASE_URL_V2}${URLS.createExternalInvoice}`,
    payload
  );
}
export async function downloadInvoiceApi(payload) {
  return NetworkClient.post(`${BASE_URL}${URLS.downloadInvoice}`, payload);
}
export async function editLogipeInvoiceApi(payload) {
  return NetworkClient.post(`${BASE_URL}${URLS.editLogipeInvoice}`, payload);
}
export async function getInvoiceApi(payload) {
  return NetworkClient.post(
    `${BASE_URL}${URLS.getInvoice}/${payload?.invoiceId}`
  );
}
export async function editExternalInvoiceApi(payload) {
  return NetworkClient.post(`${BASE_URL}${URLS.editExternalInvoice}`, payload);
}
export async function uploadReciptApi(payload) {
  return NetworkClient.post(`${BASE_URL}${URLS.uploadRecipt}`, payload);
}
export async function addPaymentRecivedApi(payload) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.addPaymentRecived}`, payload);
}
export async function downloadLedgerApi(payload) {
  return NetworkClient.post(
    `${BASE_URL}${URLS.downloadLedger}?from=${payload?.from || ""}&to=${
      payload?.to || ""
    }`,
    payload
  );
}

export async function getAllTripsApi(payload) {
  console.log("===<<<payload<<<<", payload);
  return NetworkClient.get(
    `${BASE_URL_V2}${URLS.getAllTrips}/${payload?.customerId}?search=${
      payload?.search || ""
    }&limit=${payload?.limit || 10}&offset=${payload?.offset || 0}&fleetId=${
      payload?.fleetId || ""
    }&from=${payload?.from || ""}&to=${payload?.to || ""}&isActive=${
      payload?.isActive || ""
    }&type=${payload?.type || ""}`
  );
}
export async function getSignedUrlApi(payload) {
  return NetworkClient.post(`${BASE_URL}${URLS.GET_SIGNED_URL}`, payload);
}
export async function collectPaymentApi(payload) {
  return NetworkClient.post(`${BASE_URL}${URLS.collectPayment}`, payload);
}
export async function editCustomerApi(payload) {
  return NetworkClient.post(
    `${BASE_URL_V2}${URLS.editCustomer}/${payload?.customerId}`,
    payload
  );
}
export async function advancedDriverListApi(obj) {
  console.log(
    "ADVANCEDAPI",
    `${BASE_URL_V5}${URLS.ADVANCED_DRIVERLIST_URL}?search=${obj?.search}&offset=${obj?.offset}&limit=15`
  );
  return NetworkClient.post(
    `${BASE_URL_V5}${URLS.ADVANCED_DRIVERLIST_URL}?search=${
      obj?.search
    }&offset=${obj?.offset}&limit=15&activeStatus=${
      obj?.activeStatus >= 0 ? obj?.activeStatus : undefined
    }&assignedStatus=${
      obj?.assignedStatus >= 0 ? obj?.assignedStatus : undefined
    } `
  );
}

export async function findTripAddressApi(obj) {
  console.log(
    "findTripAddressApi",
    `${BASE_URL}${URLS.FIND_TRIP_ADDRESS}?addressNickName=${obj?.search}&offset=${obj?.offset}&limit=15`
  );
  return NetworkClient.get(
    `${BASE_URL}${URLS.FIND_TRIP_ADDRESS}?addressNickName=${obj?.search}&offset=${obj?.offset}&limit=15`
  );
}

export async function addVendorPaymentApi(payload) {
  return NetworkClient.post(
    `${BASE_ACCOUNT_URL}${URLS.ADD_VENDOR_PAYMENT}`,
    payload
  );
}
export async function advancedVehicleListApi(obj) {
  return NetworkClient.get(
    `${BASE_URL_V5}${URLS.ADVANCED_VEHICLE_LIST_URL}?search=${
      obj?.search
    }&offset=${obj?.offset}&limit=10&activeStatus=${
      obj?.activeStatus >= 0 ? obj?.activeStatus : undefined
    }&assignedStatus=${
      obj?.assignedStatus >= 0 ? obj?.assignedStatus : undefined
    }`
  );
}
///////
export async function getFuelWalletBalApi() {
  return NetworkClient.post(
    `https://aks-api.logipe.com/omc/api/v1/fuelWallet/FuelWalletBalance`
  );
}
export async function getFuelWalletHistoryApi(obj) {
  return NetworkClient.post(
    `https://aks-api.logipe.com/omc/api/v1/fuelWallet/FuelWalletHistory?limit=${
      obj?.limit || 10
    }&offset=${obj?.offset || 0}`
  );
}
export async function fuelWalletLimitUpdateApi(obj) {
  return NetworkClient.post(
    `https://gke-apip.logipe.com/omc/api/v1/fuelWallet/setNumberLimit`,
    obj
  );
}
export async function getPincodeApi(obj) {
  return NetworkClient.post(
    `https://aks-api.logipe.com/api/api/v1/utility/getPincode`,
    obj
  );
}
////////
export async function getVendorDetailsApi(payload) {
  return NetworkClient.get(
    `${BASE_ACCOUNT_URL}${URLS.getVendorDetails}/${payload?.customerId}`
  );
}
export async function fleetEditStatusApi(payload) {
  return NetworkClient.post(`${BASE_URL}${URLS.fleetEditStatus}`, payload);
}
export async function driverEditStatusApi(payload) {
  return NetworkClient.post(`${BASE_URL}${URLS.driverEditStatus}`, payload);
}
export async function editControlManagerApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.EDIT_CONTROL_MANAGER}`, obj);
}
export async function changeManagerLimit(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.CHANGE_UPI_MANAGER}`, obj);
}

export async function getBranchListApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.GET_BRANCH}`, obj);
}
export async function deleteBranchListApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.DELETE_BRANCH}`, obj);
}

export async function addbranchApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.ADD_BRANCH}`, obj);
}

export async function linkBranchManagerApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.LINK_BRANCH_MANAGER}`, obj);
}

export async function linkBranchFleetApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.LINK_BRANCH_FLEET}`, obj);
}

export async function linkBranchDriverApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.LINK_BRANCH_DRIVER}`, obj);
}

export async function getBranchDriverApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.GET_BRANCH_DRIVER}`, obj);
}

export async function getBranchManagerApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.GET_BRANCH_MANAGER}`, obj);
}

export async function getBranchFleetApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.GET_BRANCH_FLEET}`, obj);
}

export async function getBranchByIDApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.GET_BRANCH_ID}`, obj);
}

export async function setBranchLimitApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.SET_BRANCH_LIMIT}`, obj);
}

export async function getStateFromPincodeApi(obj) {
  return NetworkClient.get(
    `${BASE_URL}${URLS.getStateFromPincode}?pincode=${obj?.pincode}`
  );
}
export async function createOrderForFleetApi(obj) {
  return NetworkClient.post(
    `https://aks-api.logipe.com/transactions/api/v1/orders/create/createOrderForFleet/idfc`,
    obj,
    { "x-access-token": "AKIA4AF33VLJ7JL34E32" }
  );
}

export async function driverVehicleHistoryApi(obj) {
  console.log("getDriverVehicleStatusApi obj ----- >>> ", obj);

  return NetworkClient.get(
    `${BASE_FASTAG_URL}${URLS.DRIVER_VEHICLE_HISTORY}/${obj?.id}/${
      obj?.type
    }?limit=${obj?.limit || 10}&offset=${obj?.offset || 0}&search=${
      obj?.search || ""
    }`
  );
}

export async function getDocumentApi(obj) {
  console.log("getDriverVehicleStatusApi obj ----- >>> ", obj);

  return NetworkClient.get(
    `${BASE_FASTAG_URL}${URLS.GET_DOCUMENT}/${obj?.fleetId}/${obj?.type}/${obj?.isActive}`
  );
}

export async function getCustomRequestApi(obj) {
  console.log("getCustomRequestApi", obj);
  return NetworkClient.get(
    `${BASE_URL}${URLS.GET_CUSTOM_REQUEST}${obj?.status}/${obj?.type}/${obj?.isOwner}`
  );
}
export async function updateCustomRequestApi(obj) {
  console.log("updateCustomRequestApi", obj);
  return NetworkClient.post(
    `${Prod_BASE_URL_TRANSACTIONS}${URLS.UPDATE_CUSTOM_REQUEST}${obj?.requestId}/${obj?.status}`
  );
}

export async function deleteCustomRequestApi(obj) {
  console.log(
    "deleteCustomRequestApi",
    obj,
    `${BASE_URL}${URLS.DELETE_CUSTOM_REQUEST}${obj?.requestId}`
  );
  return NetworkClient.get(
    `${BASE_URL}${URLS.DELETE_CUSTOM_REQUEST}${obj?.requestId}`
  );
}

export async function salaryWalletListApi(obj) {
  // console.log(
  //   "ADVANCEDAPI",
  //   `${BASE_URL_V5}${URLS.ADVANCED_DRIVERLIST_URL}?search=${obj?.search}&offset=${obj?.offset}&limit=15`
  // );
  // return NetworkClient.post(
  //   `${BASE_URL_V5}${URLS.ADVANCED_DRIVERLIST_URL}?search=${
  //     obj?.search || ""
  //   }&limit=${obj?.limit || 15}&offset=${obj?.offset || 0}&sortBy=${
  //     obj?.sortBy || ""
  //   }&sortAs=${obj?.sortAs || ""}`
  // );
  return NetworkClient.post(
    `${BASE_WALLET_URL}${URLS.SALARY_WALLET_LIST}?search=${
      obj?.search || ""
    }&limit=${obj?.limit || 15}&offset=${obj?.offset || 0}&sortBy=${
      obj?.sortBy || ""
    }&sortAs=${obj?.sortAs || ""}`
  );
}
export async function idfcKycSendOtpApi(obj) {
  return NetworkClient.post(`${BASE_FASTAG_URL}${URLS.IDFC_KYC_SEND_OTP}`, obj);
}
export async function idfcKycVerifyOtpApi(obj) {
  return NetworkClient.post(
    `${BASE_FASTAG_URL}${URLS.IDFC_KYC_VERIFY_OTP}`,
    obj
  );
}
export async function idfcKycSaveImagesApi(obj) {
  return NetworkClient.post(
    `${BASE_FASTAG_URL}${URLS.IDFC_KYC_SAVE_IMAGE}`,
    obj
  );
}
export async function getInvoiceListApi(obj) {
  return NetworkClient.get(
    `${BASE_URL_V2}${URLS.getLedgerList}/${obj.customerId}?search=${
      obj?.search || ""
    }&limit=${obj?.limit || 5}&offset=${obj?.offset || 0}&sortBy=${
      obj?.sortBy || ""
    }&sortAs=${obj?.sortAs || ""}&from=${obj?.from || ""}&to=${
      obj?.to || ""
    }&transferType=${obj?.transferType || ""}`
  );
}
export async function updateLrInTripApi(obj) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.UPDATE_LR_IN_TRIP}`, obj);
}
export async function signedUrlWithKeyApi(obj) {
  console.log("signedUrlWithKeyApi in API", obj);
  return NetworkClient.post(
    `${BASE_URL}media/signedUrlWithKey`,
    { key: obj?.key }
    // { userId: obj?.userId }
  );
}
export async function startTripExpenseApi(obj) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.START_TRIP_EXPENSE}`, obj);
}
export async function addTripAddressApi(obj) {
  console.log("addTripAddressApi", `${BASE_URL}${URLS.ADD_TRIP_ADDRESS}`);
  return NetworkClient.post(`${BASE_URL_V2_L}${URLS.ADD_TRIP_ADDRESS}`, obj);
}
export async function findTripAddressV2Api(obj) {
  console.log(
    "findTripAddressApi",
    `${BASE_URL_V2_L}${URLS.FIND_TRIP_ADDRESS}?address=${obj?.search}&offset=${obj?.offset}&limit=10`
  );
  return NetworkClient.get(
    `${BASE_URL_V2_L}${URLS.FIND_TRIP_ADDRESS}?address=${obj?.search}&offset=${obj?.offset}&limit=10`
  );
}
export async function getSavedAddressApi(obj) {
  // console.log("addTripAddressApi", `${BASE_URL}${URLS.ADD_TRIP_ADDRESS}`);
  return NetworkClient.get(
    `${BASE_URL_V2_L}${URLS.getSavedAddress}?address=${obj?.address || ""}`,
    obj
  );
}
export async function addCashExpanseApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.ADD_CASH_EXPANSE}`, obj);
}

export async function getDriverLedgerApi(userId, obj) {
  // console.log("addTripAddressApi", `${BASE_URL}${URLS.ADD_TRIP_ADDRESS}`);
  if (userId)
    return NetworkClient.get(
      `${BASE_URL}${URLS.getDriverLedger}/${userId}?offset=${
        obj?.offset
      }&limit=10&from=${obj?.from || ""}&to=${obj?.to || ""}&transferType=${
        obj?.transferType || ""
      }`,
      obj
    );
  else return Error("userId is required...NetworkClient");
}
export async function getVehicleLedgerApi(fleetId, obj) {
  // console.log("addTripAddressApi", `${BASE_URL}${URLS.ADD_TRIP_ADDRESS}`);
  if (fleetId)
    return NetworkClient.get(
      `${BASE_URL}${URLS.getVehicleLedger}/${fleetId}?offset=${
        obj?.offset
      }&limit=10&from=${obj?.from || ""}&to=${obj?.to || ""}&transferType=${
        obj?.transferType || ""
      }`,
      obj
    );
  else return Error("fleetId is required...NetworkClient");
}
export async function getTripLedgerApi(tripId, obj) {
  // console.log("addTripAddressApi", `${BASE_URL}${URLS.ADD_TRIP_ADDRESS}`);
  if (tripId)
    return NetworkClient.get(
      `${BASE_URL}${URLS.getTripLedger}/${tripId}?offset=${
        obj?.offset || 0
      }&limit=10&mode=${obj?.mode ? obj?.mode : ""}`
    );
  else return Error("tripId is required...NetworkClient");
}
export async function createTripExpanseApi(obj) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.CREATE_TRIP_EXPENSE}`, obj);
}
export async function getEachRouteApi(obj) {
  return NetworkClient.get(
    `${BASE_URL_V2}${URLS.getEachRoutes}/${obj?.routeId}`,
    obj
  );
}
export async function addDriverExpanseApi(obj) {
  return NetworkClient.post(`${BASE_URL}${URLS.ADD_DRIVER_EXPENSE}`, obj);
}

export async function getVendorLedgerApi(vendorId, obj) {
  return NetworkClient.get(
    `${BASE_URL}${URLS.GET_VENDOR_LEDGER}/${vendorId}?offset=${obj?.offset}&limit=${obj?.limit}`
  );
  // return NetworkClient.get(
  //   `${PreProd_BASE_URL}${URLS.GET_VENDOR_LEDGER}/${vendorId}?offset=${obj?.offset}&limit=${obj?.limit}`
  // );
}

export async function editLedgerExpenseAPi(obj) {
  return NetworkClient.post(
    `${PreProd_BASE_URL}${URLS.EDIT_LEDGER_EXPENSE}`,
    obj
  );
}
export async function getVehicleLastTripAPi(obj) {
  return NetworkClient.get(
    `${BASE_URL_V2}${URLS.GET_VEHICLE_LAST_TRIP}/${obj.fleetId}`,
    obj
  );
}
export async function editTripExpenseApi(obj) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.EDIT_TRIP_EXPENSE}`, obj);
}
export async function getTripExpenseApi(obj) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.GET_TRIP_EXPENSE}`, obj);
}
export async function createLRApi(obj) {
  return NetworkClient.post(`${BASE_URL_V2_L}${URLS.createLR}`, obj);
}
export async function getLRListApi(obj) {
  return NetworkClient.post(
    `${BASE_URL_V2_L}${URLS.getLRList}?limit=${obj?.limit || 10}&offset=${
      obj?.offset || 0
    }`,
    obj
  );
}
export async function editLRApi(obj) {
  return NetworkClient.post(`${BASE_URL_V2_L}${URLS.editLR}`, obj);
}
export async function getPinCodeDataApi(obj) {
  return NetworkClient.get(
    `${BASE_URL_V2_L}${URLS.getPinCodeData}/${obj.pincode}`
  );
}
export async function markInvoiceAsPaidApi(obj) {
  return NetworkClient.post(`${BASE_URL_V2}${URLS.markInvoiceAsPaid}`, obj);
}
export async function generateLrNoApi() {
  return NetworkClient.get(`${BASE_URL_V2_L}${URLS.generateLrNo}`);
}

export async function bulkFuelTransferUploadApi(obj) {
  console.log("api - step");
  console.log("api   aaya");
  const img = obj?.file;
  const body = new FormData();
  body.append("file", img);
  body.append("vendorId", obj.vendorId);
  return NetworkClient.post(
    `https://gke-apip.logipe.com/api/api/v2/${URLS.bulkFuelTransferSheetUpload}`,
    body
  );
  // return NetworkClient.post(
  //   `${BASE_URL_V2_L}${URLS.bulkFuelTransferSheetUpload}`,
  //   body
  // );
}

export async function addRouteBulkExcelUploadApi(obj) {
  // console.log("api sss- step" ,`http://localhost:9001/api/api/v1/${URLS.addRouteBulkExcelUpload}`,obj);
  // console.log("api   aaya");
  // const img = obj?.file;
  // const body = new FormData();
  // body.append("file", img);
  // body.append("NoRoute", obj.NoRoute);
  return NetworkClient.post(`${BASE_URL}${URLS.addRouteBulkExcelUpload}`, obj);
  // return NetworkClient.post(
  //   `${BASE_URL_V2_L}${URLS.bulkFuelTransferSheetUpload}`,
  //   body
  // );
}

export async function addRouteBulkExcelApi(obj) {
  console.log("api - step");
  console.log("api   aaya");
  const img = obj?.file;
  const body = new FormData();
  body.append("file", img);
  return NetworkClient.post(`${BASE_URL}${URLS.addRouteBulkExcel}`, body);
  // return NetworkClient.post(
  //   `${BASE_URL_V2_L}${URLS.bulkFuelTransferSheetUpload}`,
  //   body
  // );
}

export async function addTripBulkExcelUploadApi(obj) {
  // console.log("api - step");
  // console.log("api   aaya");
  // const img = obj?.file;
  // const body = new FormData();
  // body.append("file", img);
  // body.append("NoRoute", obj.NoRoute);
  return NetworkClient.post(`${BASE_URL}${URLS.addTripBulkExcelUpload}`, obj);
  // return NetworkClient.post(
  //   `${BASE_URL_V2_L}${URLS.bulkFuelTransferSheetUpload}`,
  //   body
  // );
}

export async function addTripBulkExcelApi(obj) {
  console.log("api - step");
  console.log("api   aaya");
  const img = obj?.file;
  const body = new FormData();
  body.append("file", img);
  return NetworkClient.post(`${BASE_URL}${URLS.addTripBulkExcel}`, body);
  // return NetworkClient.post(
  //   `${BASE_URL_V2_L}${URLS.bulkFuelTransferSheetUpload}`,
  //   body
  // );
}
