import {
  GET_FASTAG_WALLET_BALANCE,
  GET_FASTAG_WALLET_BALANCE_SUCCESS,
  GET_FASTAG_WALLET_TRANSACTION,
  GET_FASTAG_WALLET_TRANSACTION_SUCCESS,
  CLEAN_FASTAG_WALLET_TRANSACTION,
  TRANSFER_FASTAG_AMOUNT,
  TRANSFER_FASTAG_AMOUNT_SUCCESS,
  GET_FASTAG_TRANSACTION_HISTORY,
  GET_FASTAG_TRANSACTION_HISTORY_SUCCESS,
  GET_VEHICLE_DETAILS_BY_VEHICLE_ID,
  GET_VEHICLE_DETAILS_BY_VEHICLE_ID_SUCCESS,
  GENERATE_FASTAG_HISTORY_STATEMENT,
  GENERATE_FASTAG_HISTORY_STATEMENT_SUCCESS,
  GET_VEHICLE_DETAILS,
  GET_VEHICLE_DETAILS_SUCCESS,
  CLEAN_VEHICLE_DETAILS,
  REISSUE_AVAILABLE,
  REISSUE_AVAILABLE_SUCCESS,
  SEND_FASTAG_OTP,
  SEND_FASTAG_OTP_SUCCESS,
  VERIFY_FASTAG_OTP,
  VERIFY_FASTAG_OTP_SUCCESS,
  FASTAG_ADD_CUSTOMER,
  FASTAG_ADD_CUSTOMER_SUCCESS,
  TAG_REISSUE,
  TAG_REISSUE_SUCCESS,
  REGISTER_YESBANK,
  REGISTER_YESBANK_SUCCESS,
  TOGGLE_AUTO_RECHARGE_SUCCESS,
  TOGGLE_AUTO_RECHARGE,
  CLEAN_FASTAG_REDUCER,
  AUTO_RECHARGE_MULTIPLE_SUCCESS,
  AUTO_RECHARGE_MULTIPLE,
  CREATE_ORDER_FOR_FLEET,
  CREATE_ORDER_FOR_FLEET_SUCCESS,
  CLEAN_FASTAG_TRANSACTION_HISTORY,
  IDFC_KYC_SEND_OTP,
  IDFC_KYC_SEND_OTP_SUCCESS,
  IDFC_KYC_VERIFY_OTP,
  IDFC_KYC_VERIFY_OTP_SUCCESS,
  IDFC_KYC_SAVE_IMAGE,
  IDFC_KYC_SAVE_IMAGE_SUCCESS,
  DRIVER_VEHICLE_HISTORY,
  DRIVER_VEHICLE_HISTORY_CLEAN,
  DRIVER_VEHICLE_HISTORY_SUCCESS,
  GET_TOOL,
  GET_TOOL_SUCCESS,
  GET_DOCUMENT,
  GET_DOCUMENT_SUCCESS,
  GET_CUSTOM_REQUEST,
  GET_CUSTOM_REQUEST_SUCCESS,
  UPDATE_CUSTOM_REQUEST,
  UPDATE_CUSTOM_REQUEST_SUCCESS,
  DELETE_CUSTOM_REQUEST,
  DELETE_CUSTOM_REQUEST_SUCCESS
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  getOtpLoader: false,
  otpObject: {},
  fastagWallet: {},
  fastagTransaction: [],
  fastagTransactionList: [],
  fastagTransactionHistory: {},
  fastagTransactionHistoryList: [],
  vehicleDetailsByVehicleID: {},
  fastagWalletStatement: {},
  count: 0,
  vehicleDetails:[],
  isReissueAvailable:{},
  otpFastagVerify:{},
  sendFastagOtp:{},
  fastagAddCustomer:{},
  tagReissue:{},
  registerYesbank:{},
  driverVehicleHistory: {},
  driverVehicleHistoryList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FASTAG_WALLET_BALANCE: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_FASTAG_WALLET_BALANCE_SUCCESS: {
      return {
        ...state,
        loader: false,
        fastagWallet: action.payload,
      };
    }
    case GET_FASTAG_WALLET_TRANSACTION: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_FASTAG_WALLET_TRANSACTION_SUCCESS: {
      return {
        ...state,
        loader: false,
        fastagTransaction: action.payload.allTransactions.rows,
        fastagTransactionList: [
          ...state.fastagTransactionList,
          ...action.payload.allTransactions.rows,
        ],
        count: action.payload.allTransactions.count,
      };
    }
    case CLEAN_FASTAG_WALLET_TRANSACTION: {
      return {
        ...state,
        loader: false,
        fastagTransaction: [],
        fastagTransactionList: [],
        count: 0,
      };
    }
    case TRANSFER_FASTAG_AMOUNT: {
      return {
        ...state,
        loader: true,
      };
    }
    case TRANSFER_FASTAG_AMOUNT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_FASTAG_TRANSACTION_HISTORY: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_FASTAG_TRANSACTION_HISTORY_SUCCESS: {
      return {
        ...state,
        loader: false,
        fastagTransactionHistory: action.payload,
        fastagTransactionHistoryList: [
          ...state.fastagTransactionHistoryList,
          ...action?.payload?.rows,
        ],
      };
    }
    case CLEAN_FASTAG_TRANSACTION_HISTORY: {
      return {
        ...state,
        loader: false,
        fastagTransactionHistory: {},
        fastagTransactionHistoryList: [ ],
      };
    }
    case GET_VEHICLE_DETAILS_BY_VEHICLE_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_VEHICLE_DETAILS_BY_VEHICLE_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleDetailsByVehicleID: action.payload,
      };
    }
    case GET_VEHICLE_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_VEHICLE_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleDetails: action.payload,
      };
    }
    case CLEAN_VEHICLE_DETAILS: {
      console.log("cleaned vehicle details");
      return {
        ...state,
        loader: false,
        vehicleDetails: [],
      };
    }
    case GENERATE_FASTAG_HISTORY_STATEMENT: {
      console.log("red", action.payload);
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_FASTAG_HISTORY_STATEMENT_SUCCESS: {
      //console.log("red-> fleet-- ");
      return {
        ...state,
        loader: false,
        fastagWalletStatement: action.payload,
      };
    }
    case REISSUE_AVAILABLE: {
      return {
        ...state,
        loader: true,
      };
    }
    case REISSUE_AVAILABLE_SUCCESS: {
      return {
        ...state,
        loader: false,
        isReissueAvailable: action.payload,
      };
    }
    case SEND_FASTAG_OTP: {
      return {
        ...state,
        loader: true,
      };
    }
    case SEND_FASTAG_OTP_SUCCESS: {
      return {
        ...state,
        loader: false,
        sendFastagOtp:action.payload,
      };
    }
    case VERIFY_FASTAG_OTP: {
      return {
        ...state,
        loader: true,
      };
    }
    case VERIFY_FASTAG_OTP_SUCCESS: {
      return {
        ...state,
        loader: false,
        otpFastagVerify: action.payload,
      };
    }
    case FASTAG_ADD_CUSTOMER: {
      return {
        ...state,
        loader: true,
      };
    }
    case FASTAG_ADD_CUSTOMER_SUCCESS: {
      return {
        ...state,
        loader: false,
        fastagAddCustomer: action.payload,
      };
    }
    case TAG_REISSUE: {
      return {
        ...state,
        loader: true,
      };
    }
    case TAG_REISSUE_SUCCESS: {
      return {
        ...state,
        loader: false,
        tagReissue: action.payload,
      };
    }
    case REGISTER_YESBANK: {
      return {
        ...state,
        loader: true,
      };
    }
    case REGISTER_YESBANK_SUCCESS: {
      return {
        ...state,
        loader: false,
        registerYesbank: action.payload,
      };
    }
    case TOGGLE_AUTO_RECHARGE: {
      return {
        ...state,
        loader: true,
      };
    }
    case TOGGLE_AUTO_RECHARGE_SUCCESS: {
      console.log("successs reducer", action.payload);
      return {
        ...state,
        loader: false,
      };
    }
    case CLEAN_FASTAG_REDUCER: {
      //console.log("successs reducer", action.payload);
      return {
        ...state,
        loader: false,
      };
    }
    case AUTO_RECHARGE_MULTIPLE: {
      return {
        ...state,
        loader: true,
      };
    }
    case AUTO_RECHARGE_MULTIPLE_SUCCESS: {
      //console.log("successs reducer", action.payload);
      return {
        ...state,
        loader: false,
      };
    }
    case CREATE_ORDER_FOR_FLEET: {
      return {
        ...state,
        loader: true,
      };
    }
    case DRIVER_VEHICLE_HISTORY: {
      return {
        ...state,
        loader: true,
      };
    }
    case DRIVER_VEHICLE_HISTORY_CLEAN: {
      return {
        ...state,
        loader: false,
        driverVehicleHistory: {},
        driverVehicleHistoryList: [],
      };
    }
    case DRIVER_VEHICLE_HISTORY_SUCCESS: {
      console.log('DRIVER_VEHICLE_HISTORY_SUCCESS',action.payload)
      return {
        ...state,
        driverVehicleHistory: action?.payload,
        driverVehicleHistoryList: [
          ...state.driverVehicleHistoryList,
          ...action?.payload?.rows,
        ],
        loader: false,
      };
    }
    case CREATE_ORDER_FOR_FLEET_SUCCESS: {
      //console.log("successs reducer", action.payload);
      return {
        ...state,
        loader: false,
      };
    }
    case IDFC_KYC_SEND_OTP: {
      return {
        ...state,
        loader: true,
      };
    }
    case IDFC_KYC_SEND_OTP_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case IDFC_KYC_VERIFY_OTP: {
      return {
        ...state,
        loader: true,
      };
    }
    case IDFC_KYC_VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case IDFC_KYC_SAVE_IMAGE: {
      return {
        ...state,
        loader: true,
      };
    }
    case IDFC_KYC_SAVE_IMAGE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_DOCUMENT: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_DOCUMENT_SUCCESS: {
      return {
        ...state,
        documents: action?.payload,
        loader: false,
      };
    }
    case GET_CUSTOM_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_CUSTOM_REQUEST_SUCCESS: {
      console.log(
        'GET_CUSTOM_REQUEST_SUCCESS',
        JSON.stringify(action?.payload),
      );
      return {
        ...state,
        loader: false,
        customRequest: action?.payload,
      };
    }
    case UPDATE_CUSTOM_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_CUSTOM_REQUEST_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case DELETE_CUSTOM_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }
    case DELETE_CUSTOM_REQUEST_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    default: {
      return state;
    }
  }
};
