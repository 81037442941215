import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import moment from "moment";
import SvgImage from "../../Icons/Icons";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addFleet } from "../../../services/Store/Actions/fleetActions";
//import { useNavigate } from "react-router-dom";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";
// import { fleetDetailsSuccess } from "../../../services/Store/Actions/fleetActions";

function PrivacyModal(props) {
  const { open, toggleModal, handleClose } = props;

  const dispatch= useDispatch();
  return (
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-content" style={{ width: "510px",  overflowY:"scroll", overflowX:"hidden" }}>
          <div className="modal-head-part">
            <p className="Add-balance-title">Privacy Policy</p>
            <Button
              type="button"
              className="close-btn"
              onClick={()=>{
                dispatch(sendAnalitics("T&Close_Privacy_OwnerAcc_Home_web"));
                toggleModal();
              } }
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="privacyPolicy FontSmall padding16" >
              <div className="padding16B">
                9.1 You may be asked to provide your personal information to
                avail our services or to gain benefit of this website. We will
                take every reasonable effort not to share any of the
                transportation details, personal information you have provided
                to us at the time of registration, booking of the order, except
                as required under any Applicable Law, or to the limited extent
                necessary for us to place a truck and provide necessary service
                through our service partners/market hires, may also combine it
                with other information to provide and improve our products,
                services, content and advertising. We will bear no liability for
                the consequences in any event where your information has been
                willingly or inadvertently shared by you with any third party.
              </div>
              <div className="padding16B">
                9.2 You hereby expressly consent to receive communications from
                us through your registered phone number and/or e-mail address if
                any. You consent to be contacted by Us via phone calls, emails
                and/ or SMS notifications. You agree that any communication so
                received by you from us will not amount to spam, unsolicited
                communication or a violation of your registration on the
                national do not call registry.
              </div>
              <div className="padding16B">
                9.3 You shall not forge headers or other wise manipulate
                identifiers in order to disguise the origin of any message or
                transmittal you send to us through the application or any
                service offered by us through this Website.
              </div>
              <div className="padding16B">
                9.4 You shall not use this website or any content provided
                herewith for any purpose that is unlawful or prohibited by these
                Terms and Conditions or to solicit the performance of any
                illegal activity that infringes our rights or that of any third
                party.
              </div>
              <div className="padding16B">
                9.5 You shall not use the website in any manner that could
                damage, disable, overburden, block or impair any of the server
                connected to this website. You shall not attempt to or gain
                unauthorised access to the website or any other products or
                features through hacking, impersonation, password mining or by
                any other means.
              </div>
              <div className="padding16B">
                9.6 You shall understand that LogiPe has all right to disclose
                any information (including the identity of the person/s
                providing information or materials on the website) as necessary
                to satisfy any law, regulation or as sought by any Govt. Agency.
                In this case, you expressly authorise us to share such
                information of yours/ entity you are representing to such Govt.
                Agency
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
  );
}

export default PrivacyModal;
