import axios from "axios";
import {getDataFromAsyncStorage} from './functions';

// import store from "../store";
// import { showAppLoader } from "../store/actions/application.actions";

class NetworkClient {
    constructor() {
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };


        let service = axios.create({
            headers,
        });

        service.interceptors.request.use(async(config) => {

    
            const token = await getDataFromAsyncStorage('_token');
            const branchId = await getDataFromAsyncStorage('_branchId');
            console.log("Tokanvasjghvjashvjsavbjshv",token)
            if (token) {
                config.headers["x-access-token"] = token;
                config.headers["x-branchId"] = branchId;
            }
            return config;
        });

        service.interceptors.response.use(this.handleSuccess, this.handleError);

        this.service = service;
    }

    loggedIn() {
        // const token = this.getToken();
        // return !!token && !isTokenExpired(token); // handwaiving here
        return true;
    }

    getToken() {
        return "";
        // return localStorage.getItem('id_token')
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        return Promise.reject(error);
    };

    get(path, headers) {
        // console.log("PATH___", path)
        let a= JSON.parse(sessionStorage.getItem("_apiList"))?.others;
            // console.log("nextin----",a)
            let newPath=path;
            Object.entries(a).forEach(entry => {
                console.log("entry",entry);
                if(entry.length==2){
                    const {key, value} = entry[1]||{};
                    console.log("entry------ ",key, path, path.includes(key));
                    if (path.includes(key)) {
                    const tt = path.split(key);
                    if (tt.length > 1) {
                        newPath = value + tt[1];
                    }
                    }
                }
              });
        return this.service
            .get(newPath, {
                headers: headers,
            })
            .then((response) => response);
    }

    /*
      get(path, callback, headers) {
            // store().dispatch(showAppLoader());
            return this.service.get({

                method: 'GET',
                url: path,
                responseType: 'json',
                headers: headers?headers:{}
            }
            ).then(
                (response) => callback(response.status, response)
            );
        }
        */

    patch(path, payload, headers) {
        return this.service
            .request({
                method: "PATCH",
                url: path,
                responseType: "json",
                data: payload,
                headers: headers,
            })
            .then(response => response);
    }
    post(path, payload, headers) {
        // console.log("PATH___", path)
        let a= JSON.parse(sessionStorage.getItem("_apiList"))?.others;
            console.log("nextin----",a,path)
            let newPath=path;
            Object.entries(a).forEach(entry => {
                console.log("entry",entry);
                if(entry.length==2){
                    const {key, value} = entry[1]||{};
                    console.log("entry------ ",key, path, path.includes(key));
                    if (path.includes(key)) {
                    const tt = path.split(key);
                    if (tt.length > 1) {
                        newPath = value + tt[1];
                    }
                    }
                }
              });
        console.log("ssssssssssssssssssssssssss- final path", newPath);
        return this.service
            .request({
                method: "POST",
                url: newPath,
                responseType: "json",
                data: payload,
                headers: headers,
            })
            .then((response) => response);
    }

    put(path, payload, headers) {
        // console.log("PATH___", path)
        let a= JSON.parse(sessionStorage.getItem("_apiList"))?.others;
            console.log("nextin----",a,path)
            let newPath=path;
            Object.entries(a).forEach(entry => {
                console.log("entry",entry);
                if(entry.length==2){
                    const {key, value} = entry[1]||{};
                    console.log("entry------ ",key, path, path.includes(key));
                    if (path.includes(key)) {
                    const tt = path.split(key);
                    if (tt.length > 1) {
                        newPath = value + tt[1];
                    }
                    }
                }
              });
        console.log("ssssssssssssssssssssssssss- final path", newPath);
        return this.service
            .request({
                method: "PUT",
                url: newPath,
                responseType: "json",
                data: payload,
                headers: headers,
            })
            .then((response) => response);
    }

    
    // put(path, payload, callback, headers){
    //     return this.service
    //         .request({
    //             method: "PUT",
    //             url: path,
    //             responseType: "json",
    //             data: payload,
    //             headers: headers,
    //         })
    //         .then((response) => callback(response.status, response.data));
    // }
    delete(path, payload, callback, headers){
        return this.service
            .request({
                method: "DELETE",
                url: path,
                responseType: "json",
                data: payload,
                headers: headers,
            })
            .then((response) => response);
    }
}

export default new NetworkClient();