import {
  FT_REPORT_FILTER,
  FT_REPORT_FILTER_SUCCESS,
  MA_REPORT_FILTER,
  MA_REPORT_FILTER_SUCCESS,
  WALLET_REPORT_FILTER,
  WALLET_REPORT_FILTER_SUCCESS,
  CASH_EXPENSE_STATEMENT,
  CASH_EXPENSE_STATEMENT_SUCCESS,
  MANAGER_REPORT,
  MANAGER_REPORT_SUCCESS
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CASH_EXPENSE_STATEMENT: {
      return {
        ...state,
        loader: true,
      };
    }
    case CASH_EXPENSE_STATEMENT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case MA_REPORT_FILTER: {
      return {
        ...state,
        loader: true,
      };
    }
    case MA_REPORT_FILTER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case WALLET_REPORT_FILTER: {
      return {
        ...state,
        loader: true,
      };
    }
    case WALLET_REPORT_FILTER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case FT_REPORT_FILTER: {
      return {
        ...state,
        loader: true,
      };
    }
    case FT_REPORT_FILTER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case MANAGER_REPORT: {
      return {
        ...state,
        loader: true,
      };
    }
    case MANAGER_REPORT_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    default: {
      return state;
    }
  }
};
