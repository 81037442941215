import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import {
  linkFLeet,
  getFleetFleetID,
  getDriverList,
} from "../../services/Store/Actions/driverslistActions";
const ManageDriverVehicleDetailsCard = (props) => {
  const number = props.number == null ? "N/A" : props.number;
  const fastagId = props.ID == null ? "N/A" : props.ID;
  const name = props.name == null ? "N/A" : props.name;
  const status = props.status == null ? "Available" : props.status;
  const color = props.color == null ? "#2E7C7B" : props.color;
  const toggleModal = props.toggleModal;
  // const [linkFleetObj, setLinkFleetObj] = useState({
  //     driverId: "",
  //     fleetId: ""
  // });
  // const response = useSelector((state) => state);
  // console.log(response);
  const reposme = useSelector((state) => state);
  //console.log("repsommmdsmd", reposme);

  const dispatch = useDispatch();

  const handleClick = () => {
    // dispatch(getFleetFleetID(props.fleetID));
    dispatch(
      linkFLeet(
        {
          driverId: props.driverID,
          fleetId: props.fleetID,
        },
        (res) => {
          if (res?.data?.type === "success") {
            console.log("successful");
            //navigate("/Customers")
            //window.location.reload();
            toggleModal();
            props.setVehicleNumber(true);
            dispatch(getFleetFleetID(props.fleetID));
          }
        }
      )
    );
    dispatch(
      getDriverList({
        offset: "",
        search: "",
        limit: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );

    //window.location.reload(false);
    // dispatch(getFleetFleetID(props.fleetID));
  };
  const num = number.length > 10 ? number.substring(0, 10) + ".." : number;
  const fastag1 =
    fastagId.length > 12 ? fastagId.substring(0, 12) : fastagId; 
  const fastag2 =
  fastagId.length > 12 ? fastagId.substring(12, fastagId.length) :"";  
  const driverName = name.length > 20 ? name.substring(0, 20) + ".." : name;
  return (
    <tr>
      <td>{num}</td>
      <td>
        <div>{fastag1}</div>
        <div>{fastag2}</div>
      </td>
      <td>{name}</td>
      <td className={status === "Available" ? "greenColor" : "redColor"}  >
        {status}
      </td>
      <td>
        <div  className="ManageDriverVehicleButton padding0 col-1"
        onClick={handleClick}>Select</div>
      </td>
    </tr>
    // <div className="row padding16B padding16T Regular text3 bottomBorderStyle">
    //   <div className=" col-2 padding16L">{num}</div>
    //   <div className=" col-3 padding8L" >{fastag}</div>
    //   <div className=" col-3 padding8L">{name}</div>
    //   <div className=" col-2 padding0"
    //     style={{color:status==="Available"?"#31BF7A":"#F0222E"}}>{status}</div>
    //   <div className="ManageDriverVehicleButton padding0 col-1" onClick={handleClick}>
    //     Select
    //   </div>
    //   {/* <div className="PGCollectionEndBar" style={{left:"25px"}}></div> */}
    // </div>
  );
};
export default ManageDriverVehicleDetailsCard;
