import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Table,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import AddFreight from "../../../TripsMainV2/Components/AddFreight";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import {
  createTrip,
  getCityAndState,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import {
  createLogipeInvoiceAction,
  getLedgerListAction,
} from "../../../../services/Store/Actions/customerAction";
import InvoiceToast from "../../../subComponents/InvoiceToast";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import ManuallyInvoice from "../ManualInvoice";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import NotesModal from "../NotesModal";

function TripInvoiceDetailManual(props) {
  const {
    toggle,
    modal,
    setModal,
    isCheck,
    tripSelect,
    setTripSelect,
    discountPercent,
    setDiscountPercent,
    discount,
    setDiscount,
    taxesIGST,
    settaxesIGST,
    taxesIGSTPercent,
    settaxesIGSTPercent,
    taxesCGST,
    settaxesCGST,
    taxesCGSTPercent,
    settaxesCGSTPercent,
    subTotal,
    setsubTotal,
    total,
    setTotal,
    quantity,
    setQuantity,
    tripInvoice,
    customerId,
    setIgstAdded,
    setCgstAdded,
    igstAdded,
    cgstAdded,
    setSgstAdded,
    sgstAdded,
    taxesSGST,
    settaxesSGST,
    taxesSGSTPercent,
    settaxesSGSTPercent,
    handleClosePre,
    setExtraCharges,
    extraCharges,
  } = props;
  const { customer } = useSelector((state) => state?.customer);
  const [selectManually, setSelectManually] = useState(false);
  const [radioBtn, setRadioBtn] = useState(true);
  const [notesModal, setNotesModal] = useState(false);
  const [textNote, setTextNote] = useState("");
  const toastObject = useSelector((state) => state?.app?.toastObject);

  const [tripDetail, setTripDetail] = useState(false);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [link, setLink] = useState("");
  console.log("====isCheck11=====", total, isCheck, tripSelect);
  // const handleSelectManually = () => {
  //   console.log("=======mmm", !selectManually);
  //   setSelectManually(!selectManually);
  // };

  useEffect(() => {
    // setQuantity("0");
    // setsubTotal("0");
    // setTotal("0");
    // let set = tripSelect?.map((items, i) => {
    //   setQuantity(0);
    //   setsubTotal(0 + parseFloat(items?.freightAmount));
    //   setTotal(0 + parseFloat(items?.freightAmount));
    // });
    console.log("==subTotal== ", subTotal, total);
  }, []);
  console.log("====>>>>>>", total);

  // useEffect(() => {
  //   setTotal(
  //     parseFloat(total) -
  //       (parseFloat(discount || 0) -
  //         // (parseFloat(subTotal) * (parseFloat(discountPercent) / 100)) -
  //         parseFloat(taxesIGST || 0) -
  //         // (parseFloat(subTotal) * (parseFloat(taxesIGSTPercent) / 100)) -
  //         parseFloat(taxesCGST || 0))
  //     // (parseFloat(subTotal) * (parseFloat(taxesCGSTPercent) / 100)))
  //   );
  //   console.log(
  //     "====ppppppp",
  //     parseFloat(total || 0),
  //     parseFloat(discount || 0),
  //     // (parseFloat(subTotal) * (parseFloat(discountPercent) / 100)) -
  //     parseFloat(taxesIGST || 0),
  //     // (parseFloat(subTotal) * (parseFloat(taxesIGSTPercent) / 100)) -
  //     parseFloat(taxesCGST || 0)
  //   );
  // }, [discount, taxesIGST, taxesCGST]);
  const handleClose = () => {
    setDiscountPercent("");
    setDiscount("");
    setExtraCharges("");
    settaxesIGST("");
    settaxesIGSTPercent("");
    settaxesCGST("");
    settaxesCGSTPercent("");
    setQuantity("");
    setsubTotal("");
    setTotal("");
    settaxesSGST("");
    settaxesSGSTPercent("");
    setIgstAdded(false)
    setCgstAdded(false)
    setSgstAdded(false)
    setModal(!modal);
  };

  function toggleTripDetail() {
    setTripDetail(!tripDetail);
  }

  const handleToast = () => {
    if (!loader) {
      setLoader(true);
      console.log("sadfghesdgfhewrty", {
        customerId,
        amount: total.toString(),
        cgst: radioBtn || cgstAdded ? 0 : taxesCGST,
        cgstPercent: radioBtn || cgstAdded ? 0 : taxesCGSTPercent,
        igst: !radioBtn || igstAdded ? 0 : taxesIGST,
        igstPercent: !radioBtn || igstAdded ? 0 : taxesIGSTPercent,
        sgst: radioBtn || sgstAdded ? 0 : taxesSGST,
        sgstPercent: radioBtn || sgstAdded ? 0 : taxesSGSTPercent,
        discount: discount,
        extraCharges: extraCharges,
        discountPercent: discountPercent,
        tripInvoice: tripInvoice,
        detailsArray: tripSelect,
        textNote: textNote,
      });
      dispatch(
        createLogipeInvoiceAction(
          {
            customerId,
            amount: total.toString(),
            cgst: radioBtn || cgstAdded ? 0 : taxesCGST,
            cgstPercent: radioBtn || cgstAdded ? 0 : taxesCGSTPercent,
            igst: !radioBtn || igstAdded ? 0 : taxesIGST,
            igstPercent: !radioBtn || igstAdded ? 0 : taxesIGSTPercent,
            sgst: radioBtn || sgstAdded ? 0 : taxesSGST,
            sgstPercent: radioBtn || sgstAdded ? 0 : taxesSGSTPercent,
            discount: discount,
            extraCharges: extraCharges,
            discountPercent: discountPercent,
            tripInvoice: tripInvoice,
            detailsArray: tripSelect,
            textNote: textNote,
          },
          (resp) => {
            setLoader(false);
            console.log("==>>>resp?.link>>>", resp);
            setTripSelect([{ desc: "", hsn: "", quantity: "", amount: "" }]);
            setLink(resp?.link);
            dispatch(
              toastStatus({
                toastStatus: true,
                message: "Your Invoice has been created successfully.",
                status: true,
                invoice: true,
                emailId: customer.email,
                link: resp?.link,
                callBack: () => {
                  handleClose();
                  handleClosePre();
                  dispatch(
                    getLedgerListAction({
                      customerId: customerId,
                      offset: 0,
                      search: "",
                      transferType: "",
                      sortAs: "",
                      sortBy: "",
                      from: "",
                      to: "",
                    })
                  );
                },
              })
            );
          }
        )
      );
    }

    // setOpenToast(true);
  };
  function toggleNotesModal() {
    setNotesModal(!notesModal);
  }
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          // style={{height : "80vh"}}
          className="transactionModalBody"
          //   style={{ height: "80vh" }}
          //   style={{borderRadius : "10px"}}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            style={{ marginTop: " 30px" }}
            //   className="PGCollectionBox"
          >
            {" "}
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Invoice Details
            </div>
            <div>
              <Table
                className="TripSectionTable"
                bordered="true"
                id="driver_tables" //
                style={{ height: "30vh", marginTop: "20px" }}
              >
                <thead>
                  <tr className="table-header-trip p-0 align-middle text-center">
                    <th className="p-0" style={{ width: "7%" }}>
                      <div style={{ marginLeft: "10px" }}>S.No</div>
                    </th>
                    <th className="p-0" style={{ width: "30%" }}>
                      <div>Description</div>{" "}
                    </th>
                    <th className="p-0" style={{ width: "16%" }}>
                      HSN Code
                    </th>
                    <th className="p-0" style={{ width: "16%" }}>
                      Quantity
                    </th>
                    <th className="p-0" style={{ width: "16%" }}>
                      Amount
                    </th>
                    <th className="p-0" style={{ width: "14%" }}>
                      Total Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="lowerBorderLineCust">
                  {tripSelect?.map((items, i) => {
                    console.log("-----------", items);
                    return (
                      <tr
                        className="table-row-manage-trip-new text4 align-middle text-center p-0"
                        onClick={() => {
                          // window.location = "/Customers/" + 1;
                          //   navigate("/Customers/" + 1);
                        }}
                      >
                        <td
                          style={{
                            width: "7%",
                            borderLeft: "1px solid transparent",
                            borderRight: " 1px solid transparent",
                          }}
                          className="p-0"
                        >
                          <div style={{ marginLeft: "10px" }}>{i + 1}</div>
                        </td>
                        <td
                          style={{
                            width: "30%",
                            borderLeft: "1px solid transparent",
                            borderRight: " 1px solid transparent",
                          }}
                          className="p-0"
                        >
                          {items?.desc}
                        </td>
                        <td
                          style={{
                            width: "16%",
                            borderLeft: "1px solid transparent",
                            borderRight: " 1px solid transparent",
                          }}
                          className="p-0"
                        >
                          {items?.hsn}
                        </td>
                        <td
                          style={{
                            width: "16%",
                            borderLeft: "1px solid transparent",
                            borderRight: " 1px solid transparent",
                          }}
                          className="p-0"
                        >
                          {items?.quantity}
                        </td>
                        <td
                          style={{
                            width: "16%",
                            borderLeft: "1px solid transparent",
                            borderRight: " 1px solid transparent",
                          }}
                          className="p-0"
                        >
                          ₹{items?.amount}
                        </td>
                        <td
                          style={{
                            width: "14%",
                            borderLeft: "1px solid transparent",
                            borderRight: " 1px solid transparent",
                          }}
                          className="p-0"
                        >
                          ₹
                          {parseFloat(items?.amount) *
                            parseFloat(items?.quantity)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Table
                className="TripSectionTable"
                bordered="true"
                id="driver_tables" //
                // style={{ height: "30vh" }}
              >
                <thead>
                  <tr className="table-header-trip p-0 align-middle text-center">
                    <th className="p-0" style={{ width: "10%" }}>
                      <div style={{ marginLeft: "10px" }}>Sub Total</div>
                    </th>
                    <th className="p-0" style={{ width: "30%" }}></th>
                    <th className="p-0" style={{ width: "16%" }}></th>
                    <th className="p-0" style={{ width: "16%" }}>
                      {quantity}
                    </th>
                    <th className="p-0" style={{ width: "14%" }}>
                      ₹{subTotal}
                    </th>
                    <th className="p-0" style={{ width: "16%" }}>
                      ₹{total}
                    </th>
                  </tr>
                </thead>
              </Table>
              <div className="table-Div">
                <Table
                  className="TripSectionTable"
                  bordered="true"
                  id="driver_tables" //
                  // style={{ height: "30vh" }}
                >
                  <thead>
                    <tr className="table-header-trip-without-color p-0 align-middle text-center">
                      <th className="p-0" style={{ width: "30%" }}>
                        <div style={{ marginLeft: "16px" }}>Discount</div>
                      </th>
                      <th className="p-0" style={{ width: "10%" }}></th>
                      <th className="p-0" style={{ width: "16%" }}></th>
                      <th className="p-0" style={{ width: "16%" }}></th>
                      <th className="p-0" style={{ width: "14%" }}>
                        <input
                          type="number"
                          className="minBox"
                          placeholder="0%"
                          min="0"
                          max={"100"}
                          value={discountPercent ? discountPercent : ""}
                          onChange={(e) => {
                            console.log(
                              "====vvvvvssss",
                              e.target.value,
                              discount
                            );
                            if (e.target.value && e.target.value != "0") {
                              setDiscountPercent(e.target.value);
                              let temp =
                                parseFloat(total) *
                                (parseFloat(e.target.value) / 100);
                              setDiscount(temp.toFixed(2));
                              // setTotal(parseFloat(total) - (parseFloat(total) *  (parseFloat(e.target.value)/100)));
                            } else {
                              setDiscountPercent(0);
                              setDiscount(0);
                            }
                          }}
                        />

                        {discountPercent ? "%" : ""}
                      </th>
                      <th className="p-0" style={{ width: "16%" }}>
                        <input
                          type="number"
                          className="minBox2"
                          placeholder="₹ 0"
                          min="0"
                          max={total}
                          value={discount ? discount : ""}
                          onChange={(e) => {
                            console.log("====vvvvv", e.target.value);
                            if (e.target.value && e.target.value != "0") {
                              // setTotal(parseFloat(total) - parseFloat(e.target.value));
                              setDiscount(e.target.value);
                              let temp =
                                (parseFloat(e.target.value) /
                                  parseFloat(total)) *
                                100;
                              setDiscountPercent(temp.toFixed(2));
                            } else {
                              setDiscount(0);
                              setDiscountPercent(0);
                            }
                          }}
                        />
                      </th>
                    </tr>
                    <tr className="table-header-trip-without-color p-0 align-middle text-center">
                      <th className="p-0" style={{ width: "30%" }}>
                        <div style={{ marginLeft: "16px" }}>Extra charges</div>
                      </th>
                      <th className="p-0" style={{ width: "10%" }}></th>
                      <th className="p-0" style={{ width: "16%" }}></th>
                      <th className="p-0" style={{ width: "16%" }}></th>
                      <th className="p-0" style={{ width: "14%" }}></th>
                      <th className="p-0" style={{ width: "16%" }}>
                        <input
                          type="number"
                          className="minBox2"
                          placeholder="₹ 0"
                          min="0"
                          value={extraCharges ? extraCharges : ""}
                          onChange={(e) => {
                            console.log("====vvvvv", e.target.value);
                            if (e.target.value && e.target.value != "0") {
                              // setTotal(parseFloat(total) - parseFloat(e.target.value));
                              setExtraCharges(e.target.value);
                            } else {
                              setExtraCharges(0);
                            }
                          }}
                        />
                      </th>
                    </tr>
                  </thead>
                </Table>
              </div>
              <div className="table-Div">
                {" "}
                <Table
                  className="TripSectionTable"
                  bordered="true"
                  id="driver_tables" //
                  // style={{ height: "30vh" }}
                >
                  <thead style={{ marginLeft: "20px" }}>
                    <tr className="table-header-trip-without-color p-0 align-middle text-center">
                      <th className="p-0" style={{ width: "4%" }}>
                        <div>
                          <input
                            type="radio"
                            checked={radioBtn}
                            onChange={(e) => {
                              console.log("===<<<<<nmtknikn1");
                              setRadioBtn(true);
                            }}
                          />
                        </div>
                      </th>
                      <th className="p-0" style={{ width: "15%" }}>
                        <div style={{ marginLeft: "10px" }}>Taxes</div>
                      </th>
                      <th className="p-0" style={{ width: "20%" }}>
                        IGST
                      </th>
                      <th className="p-0" style={{ width: "35%" }}>
                        <div className="row">
                          <div className="col-1">
                            <input
                              type="checkbox"
                              value={igstAdded}
                              disabled={!radioBtn}
                              onClick={() => {
                                setIgstAdded(!igstAdded);
                              }}
                            />
                          </div>
                          <div className="col-10">Added in Freight Amount</div>
                        </div>
                      </th>
                      <th className="p-0" style={{ width: "14%" }}>
                        <input
                          type="number"
                          className="minBox"
                          placeholder="0%"
                          min="0"
                          max={"100"}
                          disabled={igstAdded || !radioBtn}
                          value={
                            !igstAdded && taxesIGSTPercent
                              ? taxesIGSTPercent
                              : ""
                          }
                          onChange={(e) => {
                            console.log("====vvvvv", e.target.value);
                            if (e.target.value && e.target.value != "0") {
                              // setTotal(parseFloat(total) + (parseFloat(total) * (parseFloat(e.target.value)/100)));
                              settaxesIGSTPercent(e.target.value);
                              let temp =
                                (parseFloat(total) -
                                  parseFloat(discount || 0) +
                                  parseFloat(extraCharges || 0)) *
                                (parseFloat(e.target.value) / 100);
                              console.log("asdfgasdf", temp, temp.toFixed(2));
                              settaxesIGST(temp.toFixed(2));
                            } else {
                              settaxesIGSTPercent(0);
                              settaxesIGST(0);
                            }
                          }}
                        />
                        {taxesIGSTPercent ? "%" : ""}
                      </th>
                      <th className="p-0" style={{ width: "16%" }}>
                        <input
                          type="number"
                          className="minBox2"
                          placeholder="₹ 0"
                          min="0"
                          disabled={igstAdded || !radioBtn}
                          max={total}
                          value={!igstAdded && taxesIGST ? taxesIGST : ""}
                          onChange={(e) => {
                            console.log("====vvvvv", e.target.value);
                            if (e.target.value && e.target.value != "0") {
                              // setTotal(parseFloat(total) + parseFloat(e.target.value));
                              settaxesIGST(e.target.value);
                              let temp =
                                (parseFloat(e.target.value) /
                                  (parseFloat(total) -
                                    parseFloat(discount || 0) +
                                    parseFloat(extraCharges || 0))) *
                                100;
                              settaxesIGSTPercent(temp.toFixed(2));
                            } else {
                              settaxesIGST(0);
                              settaxesIGSTPercent(0);
                            }
                          }}
                        />
                      </th>
                    </tr>
                  </thead>
                </Table>
              </div>
              <div className="table-Div">
                {" "}
                <Table
                  className="TripSectionTable"
                  bordered="true"
                  id="driver_tables" //
                  // style={{ height: "30vh" }}
                >
                  <thead style={{ marginLeft: "20px", width: "100%" }}>
                    <tr className="table-header-trip-without-color p-0 align-middle text-center">
                      <th className="p-0" style={{ width: "4%" }}>
                        <div>
                          <input
                            type="radio"
                            checked={!radioBtn}
                            onChange={(e) => {
                              console.log("===<<<<<nmtknikn1");
                              setRadioBtn(false);
                            }}
                          />
                        </div>
                      </th>
                      <th className="p-0" style={{ width: "15%" }}>
                        <div style={{ marginLeft: "10px" }}>Taxes</div>
                      </th>
                      <th className="p-0" style={{ width: "20%" }}>
                        CGST
                      </th>
                      <th className="p-0" style={{ width: "35%" }}>
                        <div className="row">
                          <div className="col-1">
                            <input
                              type="checkbox"
                              value={cgstAdded}
                              disabled={radioBtn}
                              onClick={() => {
                                setCgstAdded(!cgstAdded);
                              }}
                            />
                          </div>
                          <div className="col-10">Added in Freight Amount</div>
                        </div>
                      </th>
                      <th className="p-0" style={{ width: "14%" }}>
                        <input
                          type="number"
                          className="minBox"
                          placeholder="0%"
                          min="0"
                          max={"100"}
                          disabled={cgstAdded || radioBtn}
                          value={
                            !cgstAdded && taxesCGSTPercent
                              ? taxesCGSTPercent
                              : ""
                          }
                          onChange={(e) => {
                            console.log("====vvvvv", e.target.value);
                            if (e.target.value && e.target.value != "0") {
                              // setTotal(parseFloat(total) + (parseFloat(total) * (parseFloat(e.target.value)/100)));
                              settaxesCGSTPercent(e.target.value);
                              let temp =
                                (parseFloat(total) -
                                  parseFloat(discount || 0) +
                                  parseFloat(extraCharges || 0)) *
                                (parseFloat(e.target.value) / 100);
                              settaxesCGST(temp.toFixed(2));
                            } else {
                              settaxesCGSTPercent(0);
                              settaxesCGST(0);
                            }
                          }}
                        />
                        {taxesCGSTPercent ? "%" : ""}
                      </th>
                      <th className="p-0" style={{ width: "16%" }}>
                        <input
                          type="number"
                          className="minBox2"
                          placeholder="₹ 0"
                          min="0"
                          disabled={cgstAdded || radioBtn}
                          max={total}
                          value={!cgstAdded && taxesCGST ? taxesCGST : ""}
                          onChange={(e) => {
                            console.log("====vvvvv", e.target.value);
                            if (e.target.value && e.target.value != "0") {
                              settaxesCGST(e.target.value);
                              let temp =
                                (parseFloat(e.target.value) /
                                  (parseFloat(total) -
                                    parseFloat(discount || 0) +
                                    parseFloat(extraCharges || 0))) *
                                100;
                              settaxesCGSTPercent(temp.toFixed(2));
                            } else {
                              settaxesCGST(0);
                              settaxesCGSTPercent(0);
                            }
                          }}
                        />
                      </th>
                    </tr>
                    <tr className="table-header-trip-without-color p-0 align-middle text-center">
                      <th className="p-0" style={{ width: "4%" }}>
                        <div></div>
                      </th>
                      <th className="p-0" style={{ width: "15%" }}>
                        <div style={{ marginLeft: "10px" }}>Taxes</div>
                      </th>
                      <th className="p-0" style={{ width: "20%" }}>
                        SGST
                      </th>
                      <th className="p-0" style={{ width: "35%" }}>
                        <div className="row">
                          <div className="col-1">
                            <input
                              type="checkbox"
                              value={sgstAdded}
                              disabled={radioBtn}
                              onClick={() => {
                                setSgstAdded(!sgstAdded);
                              }}
                            />
                          </div>
                          <div className="col-10">Added in Freight Amount</div>
                        </div>
                      </th>
                      <th className="p-0" style={{ width: "14%" }}>
                        <input
                          type="number"
                          className="minBox"
                          placeholder="0%"
                          min="0"
                          max={"100"}
                          disabled={sgstAdded || radioBtn}
                          value={
                            !sgstAdded && taxesSGSTPercent
                              ? taxesSGSTPercent
                              : ""
                          }
                          onChange={(e) => {
                            console.log("====vvvvv", e.target.value);
                            if (e.target.value && e.target.value != "0") {
                              // setTotal(parseFloat(total) + (parseFloat(total) * (parseFloat(e.target.value)/100)));
                              settaxesSGSTPercent(e.target.value);
                              let temp =
                                (parseFloat(total) -
                                  parseFloat(discount || 0) +
                                  parseFloat(extraCharges || 0)) *
                                (parseFloat(e.target.value) / 100);
                              console.log("asdfgasdf", temp, temp.toFixed(2));
                              settaxesSGST(temp.toFixed(2));
                            } else {
                              settaxesSGSTPercent(0);
                              settaxesSGST(0);
                            }
                          }}
                        />
                        {taxesSGSTPercent ? "%" : ""}
                      </th>
                      <th className="p-0" style={{ width: "16%" }}>
                        <input
                          type="number"
                          className="minBox2"
                          placeholder="₹ 0"
                          min="0"
                          disabled={sgstAdded || radioBtn}
                          max={total}
                          value={!sgstAdded && taxesSGST ? taxesSGST : ""}
                          onChange={(e) => {
                            console.log("====vvvvv", e.target.value);
                            if (e.target.value && e.target.value != "0") {
                              // setTotal(parseFloat(total) + parseFloat(e.target.value));
                              settaxesSGST(e.target.value);
                              let temp =
                                (parseFloat(e.target.value) /
                                  (parseFloat(total) -
                                    parseFloat(discount || 0) +
                                    parseFloat(extraCharges || 0))) *
                                100;
                              settaxesSGSTPercent(temp.toFixed(2));
                            } else {
                              settaxesSGST(0);
                              settaxesSGSTPercent(0);
                            }
                          }}
                        />
                      </th>
                    </tr>
                  </thead>
                </Table>
              </div>

              <Table
                className="TripSectionTable"
                bordered="true"
                id="driver_tables" //
                // style={{ height: "30vh" }}
              >
                <thead>
                  <tr className="table-header-trip p-0 align-middle text-center">
                    <th className="p-0" style={{ width: "10%" }}>
                      <div style={{ marginLeft: "10px" }}>Total</div>
                    </th>
                    <th className="p-0" style={{ width: "30%" }}></th>
                    <th className="p-0" style={{ width: "16%" }}></th>
                    <th className="p-0" style={{ width: "16%" }}></th>
                    <th className="p-0" style={{ width: "14%" }}>
                      {/* ₹{total && total != "0" ? total.toFixed(2) : "0"} */}
                      {textNote.length > 0 ? (
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              color: "#ffc40c",
                              backgroundColor: "#fffacd",
                              borderRadius: 8,
                              borderColor: "#ffc40c",
                              border: "1px solid ",
                              textAlign: "center",
                              marginRight: "12px",
                            }}
                          >
                            Notes added
                          </div>
                        </div>
                      ) : null}
                    </th>
                    <th className="p-0" style={{ width: "16%" }}>
                      ₹
                      {total && total != "0"
                        ? (
                            parseFloat(total) -
                            (parseFloat(discount || 0) -
                              parseFloat(extraCharges || 0) -
                              (radioBtn
                                ? parseFloat(!igstAdded ? taxesIGST || 0 : 0)
                                : parseFloat(!cgstAdded ? taxesCGST || 0 : 0) +
                                  parseFloat(!sgstAdded ? taxesSGST || 0 : 0)))
                          )?.toFixed(2)
                        : "0"}
                    </th>
                  </tr>
                </thead>
              </Table>

              <div
                style={{
                  padding: "25px 0 0 0",
                  textAlign: "center",
                }}
              >
                <Button
                  style={{
                    width: "160px",
                    borderRadius: "5px",
                    backgroundColor: "white",
                    color: "#2E7C7B",
                    marginRight: 24,
                    borderColor: "#2E7C7B",
                  }}
                  className="generateOtp textAddCust Medium btn btn-secondary"
                  onClick={() => {
                    setNotesModal(true);
                  }}
                >
                  {textNote.length > 0 ? "Edit Notes" : "Add Notes"}
                </Button>
                <Button
                  type="submit"
                  style={{ width: "160px", borderRadius: "5px" }}
                  className="generateOtp textAddCust Medium btn btn-secondary"
                  onClick={handleToast}
                >
                  {loader ? (
                    <>
                      <ClipLoader
                        color={"#fff"}
                        loading={true}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>

        {/* {toastObject.toastStatus ? (
          <InvoiceToast
            success={toastObject?.status}
            message={toastObject?.message}
            toggleModal={() => {
              setOpenToast(false);
            }}
            isOpen={toastObject?.toastStatus}
            name="driver"
            link={link}
            handleClose={() => {
              dispatch(
                toastStatus({
                  toastStatus: false,
                  message: "",
                  status: true,
                })
              );
              window.location = `/Customers/${customerId}`;
              // navigate("/Customers");
            }}
          />
        ) : (
          <></>
        )} */}
      </Modal>
      <NotesModal
        toggle={toggleNotesModal}
        setModal={setNotesModal}
        modal={notesModal}
        setTextNote={setTextNote}
        textNote={textNote}
      />
    </div>
  );
}

export default TripInvoiceDetailManual;
