import React, { useEffect } from "react";
import "./index.css";
import { BsArrowRight } from "react-icons/bs";
import { IconContext } from "react-icons";
import { useState } from "react";
import Switch from "react-switch";
import {
  editCardControl,
  getCardControl,
  changeCardStatus,
} from "../../../../services/Store/Actions/driverslistActions";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerCardDetails } from "../../../../services/Store/Actions/manageCardActions";
import { getCardDetails } from "../../../../services/Store/Actions/driverslistActions";
import ClipLoader from "react-spinners/ClipLoader";

const LogipeCard2 = (props) => {
  console.log("props------->>>>>>", props);
  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userId = obj?.user?.userId;
  const cardData = useSelector(
    (state) => state?.driverlist?.cardDetails?.details
  );
  const cardDataLoader = useSelector(
    (state) => state?.driverlist?.loader
  );
  const expiry = cardData?.expiryDate == null ? "N/A" : cardData?.expiryDate;
  const cardNumber =
    cardData?.cardNumber == null ? "N/A" : cardData?.cardNumber;
  // console.log("didi", cardData);

  const cardName = props.fullName == null ? "Null" : props.fullName;
  //const cardBalance = props.balance==null?"Null":props.balance;
  const cvv = props.cvv == null ? "XXX" : props.cvv;
  //const active= (props?.activeStatus==null?false:props?.activeStatus)
  const cardStatus = useSelector(
    (state) => state?.manageCard?.OwnercardDetails?.accountStatus
  );
  //console.log(",,,,,,,,,,", cardStatus);
  const active = cardStatus === 4 ? true : false;
  console.log("value of active------------", active);
  //const bool = props.status==null?false:props.status

  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(getCardControl(userId));
    dispatch(getOwnerCardDetails(userId));
    dispatch(getCardDetails(userId));
  }, []);
  //const value = useSelector(state=>state?.driverlist?.cardControl?.card?.isActive)

  // const cardValue = useSelector(state=>state?.driverlist?.cardControl?.wallet?.accountStatus);
  // console.log("values",value)
  props.setActiveStatus(active);
  // console.log("Nazis ",cardValue)
  function handleChange(val) {
    //  let TransactionType = title.split(" ")

    //  TransactionType = TransactionType[0]
    //  TransactionType = TransactionType.toLowerCase()
    //console.log("Testing",props.activeStatus)
    if (cardStatus === 4) {
      dispatch(
        changeCardStatus(userId, (res) => {
          // actions.setSubmitting(false);
          //  console.log("Change Card Status",res?.type)
          if (res?.type === "success") {
            console.log("successfullll******");
            //navigate("/Customers")
            // toggleModal();
            props.setActiveStatus(!val);

            dispatch(getCardControl(userId));
          }
        })
      );
    }
  }
  //console.log("tyyppppppppppppppppppppp", typeof cardNumber);
  const arrayCard1= cardNumber==="N/A"?"N/A":cardNumber.substring(0,4);
  const arrayCard2= cardNumber==="N/A"?"N/A":cardNumber.substring(4,8);
  const arrayCard3= cardNumber==="N/A"?"N/A":cardNumber.substring(8,12);
  const arrayCard4= cardNumber==="N/A"?"N/A":cardNumber.substring(12,16);
  const correctCardNum= arrayCard1==="N/A"?"N/A":arrayCard1+"-"+arrayCard2+"-"+arrayCard3+"-"+arrayCard4
  return (
    <div className="ownerCardDiv" style={{ opacity: active ? "1" : "0.5", margin:"auto" }}>
      <div className="padding32T padding16L cardOwnerName padding24B" >{cardName}</div>
      {/* <div className="LogipeCard2Active">
              {active?"Active":"Not Active"}
           </div>
           <div className="LogipeCard2Slider">
            <div className="logipe-card-toggle-slider" style={{pointerEvents:"auto"}}>
            <Switch
           // disabled={active?false:true}
            checked={value}
            onChange={()=>handleChange(value)}
            onColor="#EBF2F2"
            onHandleColor="#2E7C7B"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            activeBoxShadow={""}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            height={15}
            width={40}
            className="react-switch"
            id="material-switch"
          />

           </div>  

        </div> */}
      {/* <div className="borderGreenInCard padding8">
        <div className="OwnerLogipeCardHeader">
          1% cashback on every card transaction
        </div>
      </div> */}
      <div className="cardOwnerName padding16L padding16B">PERSONAL CARD</div>
      <div className="padding32T padding16L whiteColor">{cardDataLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={cardDataLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:correctCardNum}</div>
      <div className="row padding8T padding16L">
        <div className="cardOwnerName col-lg-3 col-md-4 FontSmall1">Expiry Date</div>
        <div className="cardOwnerName col-lg-3 col-md-4 FontSmall1">CVV</div>
      </div>
      <div className="row padding16L">
        <div className="whiteColor col-lg-3 col-md-4">{cardDataLoader?<ClipLoader
                          color={"#485B7C"}
                          loading={cardDataLoader}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />:expiry}</div>
        <div className="whiteColor col-lg-3 col-md-4">{cvv}</div>
      </div>
    </div>
  );
};
export default LogipeCard2;
