import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  LOGIPE_ACCOUNT,
  LOGIPE_ACCOUNT_SUCCESS,
  GET_CUSTOMERS_BY_ID,
  GET_CUSTOMERS_BY_ID_SUCCESS,
  GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID,
  GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID_SUCCESS,
  ADD_NEW_BOOKING_BILL,
  ADD_NEW_BOOKING_BILL_SUCCESS,
  BILL_DETAILS,
  BILL_DETAILS_SUCCESS,
  CREATE_PAYMENT_LINK,
  CREATE_PAYMENT_LINK_SUCCESS,
  UPDATE_BILL_DETAILS,
  UPDATE_BILL_DETAILS_SUCCESS,
  CLEAN_CUSTOMERS,
  CLEAR_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID,
  VIRTUAL_ACCOUNT_CREDIT,
  VIRTUAL_ACCOUNT_CREDIT_SUCCESS,
  CLEAN_CUSTOMER_LOADER,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_TOKEN,
  CREATE_PAYMENT_TOKEN_SUCCESS
} from "../storeTypes";

export const addCustomer = (obj, callback) => {
  return {
    type: ADD_CUSTOMER,
    payload: obj,
    callback,
  };
};
export const addCustomerSuccess = (data) => {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    payload: data,
  };
};

export const getCustomers = (data) => {
  return {
    type: GET_CUSTOMERS,
    payload: data,
    
  };
};

export const getCustomersSuccess = (payload) => {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    payload,
  };
};
export const cleanCustomers = () => {
  return {
    type: CLEAN_CUSTOMERS,
  };
};

export const getLogiPeAccount = () => {
  return {
    type: LOGIPE_ACCOUNT,
  };
};
export const getLogiPeAccountSuccess = (payload) => {
  return {
    type: LOGIPE_ACCOUNT_SUCCESS,
    payload,
  };
};

export const getCustomersByID = ID => {
  return {
    type: GET_CUSTOMERS_BY_ID,
    payload: ID,
  };
};
export const getCustomersByIDSuccess = payload => {
  return {
    type: GET_CUSTOMERS_BY_ID_SUCCESS,
    payload,
  };
};
export const clearCustomerRecievables = () => {
  return {
    type: CLEAR_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID,
  };
};

export const getCustomerRecievables = obj => {
  return {
    type: GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID,
    payload: obj,
  };
};
export const getCustomerRecievablesSuccess = payload => {
  return {
    type: GET_CUSTOMER_RECIEVABLES_BY_CUSTOMER_ID_SUCCESS,
    payload,
  };
};

export const addNewBookingBill = (obj, callback) => {
  return {
    type: ADD_NEW_BOOKING_BILL,
    payload: obj,
    callback,
  };
};

export const addNewBookingBillSuccess = obj => {
  return {
    type: ADD_NEW_BOOKING_BILL_SUCCESS,
    payload: obj,
  };
};

export const getBillDetails = (obj,callback) => {
  console.log("asmi-- ", obj);
  return {
    type: BILL_DETAILS,
    payload: obj,
    callback,
  };
};
export const getBillDetailsSuccess = payload => {
  return {
    type: BILL_DETAILS_SUCCESS,
    payload,
  };
};

export const createPaymentLink = (obj, callback) => {
  return {
    type: CREATE_PAYMENT_LINK,
    payload: obj,
    callback,
  };
};
export const createPaymentLinkSuccess = obj => {
  return {
    type: CREATE_PAYMENT_LINK_SUCCESS,
    payload: obj,
  };
};

export const virtualAccountCredit = (obj, callback) => {
  return {
    type: VIRTUAL_ACCOUNT_CREDIT,
    payload: obj,
    callback,
  };
};
export const virtualAccountCreditSuccess = () => {
  return {
    type: VIRTUAL_ACCOUNT_CREDIT_SUCCESS,
  };
};

export const updateBillDetails = (obj,callback) => {
  return {
    type: UPDATE_BILL_DETAILS,
    payload: obj,
    callback,
  };
};
export const updateBillDetailsSuccess = (payload) => {
  return {
    type: UPDATE_BILL_DETAILS_SUCCESS,
    payload,
  };
};

export const cleanCustomerAddLoader = () => {
  return {
    type:CLEAN_CUSTOMER_LOADER,
  };
};



export const updatePaymentAction= (obj,callback) => {
  return {
    type:UPDATE_PAYMENT ,
    payload: obj,
    callback
  };
};
export const updatePaymentActionSuccess = (data) => {
  return {
    type: UPDATE_PAYMENT_SUCCESS,
    payload: data,
  };
};

export const createPaymentTokenAction = (obj, callback) => {
  //console.log("payment token -", obj, callback)
  return {
    type: CREATE_PAYMENT_TOKEN,
    payload: obj,
    callback,
  };
};
export const createPaymentTokenSuccessAction = (data) => {
  return {
    type: CREATE_PAYMENT_TOKEN_SUCCESS,
    payload: data,
  };
};