import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ToastV2 from "../subComponents/toastV2";
import { toastStatus } from "../../services/Store/Actions/appActions";
import SvgImage from "../Icons/Icons";
import "./index.css";
import { BallTriangle } from "react-loader-spinner";
import { getRoute } from "../../services/Store/Actions/TripsAction";
import CreateRoutes from "./SubComponents/CreateRoutes";
import AddBulkRoutes from "./SubComponents/AddBulkRoutes";

const moment = require("moment");
const customerImg = require("../../assets/images/Screenshot 2023-10-07 at 10.06.46 PM.png"); // with require

function RoutesComponent(props) {
  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);
  const { sideBar, salesHome, setSalesHome } = props;
  const [bulkSalary, setBulkSalary] = useState(false);
  const [createCust, setCreateCust] = useState(false);
  const [offset, setOffset] = useState(offsetVal);
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const toastObject = useSelector((state) => state?.app?.toastObject);
  // const { customerList, customerNo, newCust, loader } = useSelector(
  //   (state) => state?.customer
  // );
  // if (customerNo && customerNo != "" && customerNo != 0) {
  //   setSalesHome(false);
  // }
  let {
    routesList,
    routesCount,
    routeLoader
  } = useSelector((store) => store?.trip);
  // console.log("===pppp>>>>", customerList, customerNo, salesHome);
  const [data,setData] = useState()
  function toggleCreateCust() {
    setData()
    setCreateCust(!createCust);
  }

  console.log("===routesList>>>>", (parseFloat((offset)*10) + parseFloat(parseFloat(0) + 1)),(offset))

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   dispatch(
  //     getRoute({
  //       offset: "",
  //       search: "",
  //       minKyc: "",
  //       sortAs: "",
  //       sortBy: "",
  //       // limit: "12",
  //     })
  //   );
  //   return function cleanUp() {
  //     dispatch(clearAllCustomers());
  //     setOffset(0);
  //     dispatch(clearAllCustomers());
  //   };
  // }, []);
  useEffect(() => {
    console.log("kkklllll",companyName)
    dispatch(
      getRoute({
        offset: "",
        search: companyName,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
    // return function cleanUp() {
    //   dispatch(clearAllCustomers());
    //   setOffset(0);
    //   dispatch(clearAllCustomers());
    // };
  }, [companyName]);

  const handlePrevButton = () => {
    if (offset > 0) {
      setOffset(offset - 10);
      dispatch(
        getRoute({
          offset: offset - 10,
          companyName: companyName,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          // limit: "12",
        })
      );
    }
  };

  const handleNextButton = () => {
    console.log("djbdjbdjbdjbd")
    if (routesCount - offset > 10) {
      setOffset(offset + 10);
      dispatch(
        getRoute({
          offset: offset + 10,
          phone: phone,
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    }
  };

  return (
    <div className="topMargin">
      {routeLoader ? (
        <>
          <div
            className="container sideBarMargin"
            style={{
              marginLeft: sideBar ? "230px" : "100px",
              maxWidth: sideBar ? windowWidth - 260 : windowWidth - 130,
              // height: "100vh",
              backgroundColor: "#FFFFFF",
              height: 400,
            }}
          >
            <div
              className="boxContainer"
              style={{
                padding: 70,
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 130,
              }}
            >
              <BallTriangle
                height={70}
                width={70}
                radius={5}
                color="#2e7c7b"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          { (
            <div
              className="container sideBarMargin"
              style={{
                marginLeft: sideBar ? "230px" : "100px",
                maxWidth: sideBar ? windowWidth - 260 : windowWidth - 130,
                // height: "100vh",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div>
              <div className="custText">Routes</div>
                <div className="d-flex justify-content-between mx-4">
                  <div className="d-flex">
                  <div className="d-flex">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-12 custNamepadd">Route Name</div>
                        <div className="col-12 custNamepadd2">
                          <input
                            className="inputBoxCust"
                            placeholder="Enter Route Name"
                            value={companyName}
                            autoFocus={companyName != "" ? true : false}
                            onChange={(e) => {
                              setOffset(0);
                              setCompanyName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-6">
                      <div className="row">
                        <div className="col-12 custNamepadd">Destination/Source</div>
                        <div className="col-12 custNamepadd2">
                          <input
                            className="inputBoxCust"
                            placeholder="Enter Destination/Source"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  </div>
                  
                  <div className="addCustcenter">
                  <button
                      className="greenButton Medium text3 AddCustStartBtn"
                      onClick={() => {
                        console.log("bulkSalarybulkSalary11", bulkSalary);
                        setBulkSalary(!bulkSalary);
                      }}
                    >
                      <div className="AddCust">+ Add Bulk Routes</div>
                    </button>
                    <button
                      className="greenButton Medium text3 AddCustStartBtn"
                      onClick={toggleCreateCust}
                    >
                      <div className="AddCust">+ Add Route</div>
                    </button>
                  </div>
                </div>
                <div className="RouteTableDiv">
                  <Table
                    className="CustSectionTable"
                    // id="CustSectionTable1"
                    bordered="true"
                  >
                    <thead>
                      <tr className="table-header-cust" style={{ fontSize: "14px" }}>
                        <th style={{ width: "7%" }}>S. No</th>
                        <th style={{ width: "20%" }}>
                          <div>Route Name</div>{" "}
                        </th>
                        <th style={{ width: "7%" }}>VC</th>
                        <th style={{ width: "11%" }}>Driver Expected</th>
                        <th style={{ width: "11%" }}>Driver Advance</th>
                        {/* <th style={{ width: "11%" }}>Driver Final</th> */}
                        <th style={{ width: "11%" }}>Fuel Expected</th>
                        <th style={{ width: "11%" }}>Fuel Advance</th>
                        {/* <th style={{ width: "11%" }}>Fuel Final</th> */}
                        <th style={{ width: "11%" }}>Cash Expected</th>
                        <th style={{ width: "11%" }}>Cash Advance</th>
                        {/* <th style={{ width: "11%" }}>Cash Final</th> */}
                        <th style={{ width: "11%" }}>Fastag Expected</th>
                      </tr>
                    </thead>
                    <tbody
                      className="lowerBorderLineCust"
                      style={{ height: "560px" }}
                    >
                      {routesList?.map((data, idx) => (
                        <tr
                          className="table-row-manage-cust-new text4"
                          key={idx}
                          onClick={() => {
                            console.log('press mat ke')
                            setData(data)
                            setCreateCust(!createCust);
                            // window.location = "/Customers/" + 1;
                            // navigate("/Customers/" + data?.customerId);
                          }}
                        >
                          <td
                            style={{
                              width: "7%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            <div>{(parseFloat((offset/10)*10) + parseFloat(parseFloat(idx) + 1))}</div>
                          </td>
                          <td
                            style={{
                              width: "20%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.name}
                          </td>
                          <td
                            style={{
                              width: "7%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.vc}
                          </td>
                          <td
                            style={{
                              width: "11%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.driverExpected}
                          </td>
                          <td
                            style={{
                              width: "11%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.driverAdvance}
                          </td>
                          {/* <td
                            style={{
                              width: "11%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.driverFinal}
                          </td> */}

                          <td
                            style={{
                              width: "11%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.fuelExpected}
                          </td>
                          <td
                            style={{
                              width: "11%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.fuelAdvance}
                          </td>
                          {/* <td
                            style={{
                              width: "11%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.fuelFinal}
                          </td> */}

                          <td
                            style={{
                              width: "11%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.cashExpected}
                          </td>
                          <td
                            style={{
                              width: "11%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.cashAdvance}
                          </td>
                          {/* <td
                            style={{
                              width: "11%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.cashFinal}
                          </td> */}
                          <td
                            style={{
                              width: "11%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.fastagExpected}
                          </td>
                          
                          
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="nextRow ">
                    <div className="nextRowCont ">
                      <button
                        className="PrevButton"
                        onClick={handlePrevButton}
                        style={{ opacity: offset > 0 ? "1" : "0.5" }}
                      >
                        <SvgImage name="PrevArrow" heigth="12px" width="12px" />
                      </button>
                      <button
                        className="nextButton"
                        onClick={handleNextButton}
                        style={{
                          opacity: routesCount - (offset) > 10 ? "1" : "0.5",
                        }}
                      >
                        <SvgImage name="NextArrow" heigth="12px" width="12px" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <CreateRoutes
                toggle={toggleCreateCust}
                modal={createCust}
                setModal={setCreateCust}
                data={data}
              />
              <AddBulkRoutes
                toggleModal={() => setBulkSalary(!bulkSalary)}
                isOpen={bulkSalary}
                handleClose={() => setBulkSalary(false)}
                // data={data}
              />
              {/* <PaymentsModal 
          toggle={toggleCreateCust} modal={createCust} setModal={setCreateCust} 
        /> */}
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default RoutesComponent;
