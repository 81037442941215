import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import SvgImage from "../../Icons/Icons";
import PinInput from "react-pin-input";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getVirtuAccount } from "../../../services/Store/Actions/beneficiaryAction";
import AddNewAccount from "../../Modals/addNewAccount";
import "./index.css";
import * as Yup from "yup";
import {
  bankDirectTransfer,
  bankBeneficiaryTransfer,
} from "../../../services/Store/Actions/beneficiaryAction";
import { sendAnalitics, toastStatus } from "../../../services/Store/Actions/appActions";

const SelectedTransferAmount = (props) => {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    proceed,
    nextSection,
    ownerLogipeBalance,
    beneficiaryDetails,
    rcolor,
    setRColor,
    selectedBeneficiaryDetails,
    setSelectedBeneficiaryDetails,
    addBeneficiary,
    setAddBeneficiary,
    formProceed,
    setFormProceed,
  } = props;
  const { loader } = useSelector((state) => state?.beneficiary);

  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    amount: Yup.number().typeError("Only Numbers are allowed")
      .required("Required")
      .min(1, "Amount should be greater than 1"),
    pin: Yup.string()
      .required("Required")
      .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
  });

  function handleProceed(i) {
    setFormProceed(i);
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        benificiaryId: selectedBeneficiaryDetails,
        // ,fullName: selectedBeneficiaryDetails?.fullName,
        // acNo: selectedBeneficiaryDetails?.accNumber,
        // bankAccountNumber: selectedBeneficiaryDetails?.accNumber,
        // ifsc: selectedBeneficiaryDetails?.ifscNo,
        amount: "",
        remarks: "any",
        pin: "",
      }}
      onSubmit={(values, actions) => {
        console.log("values onSubmit", values);
        dispatch(
          sendAnalitics("Bene_Trns_from_MA_API_Web", {
            BeneficiaryTrxn_amount: values.amount,
            remarks: values.remarks,
          })
        );
        dispatch(
          bankBeneficiaryTransfer(values, (res) => {
            console.log("resp-> asmi", res);
            //actions.setSubmitting(false);

            if (res?.data?.type == "success") {
              handleProceed(0);
              toggleModal();
              dispatch(
                sendAnalitics("Success_Bene_Trns_from_MA_Web", {
                  BeneficiaryTrxn_amount: values.amount,
                  remarks: values.remarks,
                })
              );
              dispatch(
                toastStatus({
                  toastStatus: true,
                  message: "Money transfer successfully to Beneficiary",
                  status: true,
                })
              );
              //alert("Money transfer successfully to Beneficiary")
            } else if (res?.status === 400) {
              dispatch(
                toastStatus({
                  toastStatus: true,
                  message:
                    "Sorry, Money transfer to Beneficiary failed. " +
                    res?.message,
                  status: false,
                })
              );
            } else {
              dispatch(
                toastStatus({
                  toastStatus: true,
                  message:
                    "Sorry, Money transfer to Beneficiary failed." +
                    res?.message,
                  status: false,
                })
              );
            }
            console.log("resresresres", res);
          })
        );
        setFormProceed(0);
        // console.log("value +", driverUserId);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">1</span>
                <p className="sub-head-3">Enter amount</p>
              </div>
              <p className="sub-head-body">
                Amount will be deducted from owner’s Logipe account
              </p>

              <label for="html" className="label-body">
                ₹
              </label>
              <input
                type="text"
                id="amount"
                className="input-body-1"
                name="amount"
                onChange={handleChange("amount")}
                value={values.amount}
                onBlur={handleBlur("amount")}
                touched={touched.amount}
                errors={errors.amount}
                placeholder="Enter amount"
              ></input>
              <div className="input-amount"></div>
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.amount && touched.amount ? (
                  <div>*{errors.amount}</div>
                ) : null}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="sub-title-1">
                <span class="circle-logo">2</span>
                <p className="sub-head-4">Enter 4-digit Security Pin</p>
              </div>
              <p className="sub-head-body">
                Security pin is needed to confirm payment
              </p>
              <PinInput
                length={4}
                initialValue=""
                secret
                onChange={handleChange("pin")}
                type="numeric"
                value={values.pin}
                onBlur={handleBlur("pin")}
                touched={touched.pin}
                errors={errors.pin}
                inputMode="number"
                style={{
                  padding: "10px",
                  marginLeft: "96px",
                  marginTop: "28px",
                }}
                inputStyle={{
                  border: "1px solid #A7A7A7",
                  borderRadius: "8px",
                  marginRight: "20px",
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.pin && touched.pin ? <div>*{errors.pin}</div> : null}
              </div>
            </FormGroup>

            <div>
              <button
                type="submit"
                className="butt-recharge butt4"
                disabled={loader ? true : false}
                onClick={()=>{
                      dispatch(
                    sendAnalitics("Bene_Trns_from_MA_Web", {
                      BeneficiaryTrxn_amount: values.amount,
                      remarks: values.remarks,
                    })
                  );
                  }}
              >
                {loader ? "Please Wait" : "Transfer To Account"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SelectedTransferAmount;
