import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SvgImage from "../../Icons/Icons";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";

function ListModalV2(props) {
  const dispatch = useDispatch();

  const [height, setHeight] = useState("");
  const {
    handlingDivSelect,
    handleKeyPress,
    text,
    listing,
    divName,
    color,
    setColor,
    loading,
    // vehicleClass,
    // setVehicleClass,
    setFieldValue,
    display,
    setDisplay,
    userId,
    parameter,
    setId,
    Id,
    minWidth,
    setFieldParameter    
  } = props;
  const [select, setSelect] = useState(-1);

  // const vehicleDetails = useSelector(
  //   (state) => state?.dashboard?.vehicleDetails?.data?.response?.vehicleCategory
  // );

  useEffect(() => {
    if (listing?.length == 0) {
      setHeight(parseFloat(32 + 35));
    } else setHeight(parseFloat(listing?.length) * 32 + 35);
  }, [listing]);

  // console.log("status haa", vehicleDetails);
  console.log("list modal inside---> ***", listing, minWidth);
  const handleSelection = (i, item) => {
 
    setSelect(i);
    setColor(i);
    setFieldValue("vrn", item.vrn, true);
    //   setFieldValue("driverName", item.fullName?.toUpperCase(), true);
    setDisplay(!display);
    setFieldValue(setFieldParameter, item[parameter], true);
    setId(item.fleetId);
    // if (divName === "vehicle") {
    //   // dispatch(getVehicleDetails({ fleetId: item.fleetId }));
    //   //console.log ("asmi- val of vehicle- BEFORE", displayVehicles);

    //   handlingDivSelect(item.regNumber, item.fleetId, "", divName, item);
    //   setDisplayVehicles(!displayVehicles);
    //   // setVehicleClass(vehicleDetails);
    // } else if (divName === "driver") {
    //   //console.log ("asmi- val of driver- BEFORE", displayDrivers);
    //   handlingDivSelect(
    //     item.User.fullName,
    //     item.User.phone,
    //     item.User.userId,
    //     divName,
    //     item
    //   );
    // }
  };

  return (
    <div className={"dropDownDiv111"} style={{ height: `${height}px`,minWidth : `${minWidth}px` }}>
      {/* <div className="search-Bar1"> */}
      {/* <div className="search-bar-logo1">
          <SvgImage name="searchLogo" />
        </div> */}
      {/* <input
          type="text"
          className="search-bar-input1"
          placeholder={props.placeholder}
          onKeyUp={(e) => {
            console.log("this is clicked", e.target.value);
            // if (divName === "driver") dispatch(cleanDriverList());
            // else if (divName === "vehicle") dispatch(cleanVehicleList());
            let newV = e.target.value;
            handleKeyPress(newV, divName);
          }}
        /> */}
      {/* </div> */}

      {/* <p className="dropDownText">{text}</p> */}
      <div style={{ marginTop: "25px" }}>
        {listing?.length > 0 ? (
          listing?.map((item, i) => {
            let reqValue;
            reqValue = item[parameter];
            console.log("====mmmnmmm1111",reqValue)
            return (
              <div
                key={i}
                className="row listItems DriverName"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  handleSelection(i, item);
                }}
                style={{ padding: "0 0 10px 0" }}
              >
                {color === i ? (
                  <div className="col-1">
                    <SvgImage name="selected" />
                  </div>
                ) : (
                  <div className="col-1">
                    <SvgImage name="unSelected" />
                  </div>
                )}
                                  {/* <div className="col-11">{reqValue}</div> */}
                {color === i ? (
                  <div className="col-11" style={{textAlign : "initial"}}>{reqValue}</div>
                ) : (
                  <div className="col-11" style={{textAlign : "initial"}}>{reqValue}</div>
                )}
              </div>
            );
          })
        ) : loading ? (
          <div className="row listItems DriverName">
            <p className="col-11" style={{ textAlign: "center" }}>
              Loading
            </p>
          </div>
        ) : (
          <div className="row listItems DriverName">
            <p className="col-11" style={{ textAlign: "center" }}>
              No Vehicle Found
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListModalV2;
