import {
  CLEAN_ECOM_LOADER,
  GET_CART_DETAILS,
  GET_CART_DETAILS_SUCCESS,
  ADD_TO_CART,
  ADD_TO_CART_SUCCESS,
  REMOVE_TO_CART,
  REMOVE_TO_CART_SUCCESS,
  GET_ORDER_LIST,
  GET_ORDER_LIST_SUCCESS,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  ADD_ADDRESS_DETAILS,
  ADD_ADDRESS_DETAILS_SUCCESS,
  GET_ADDRESS_LIST,
  GET_ADDRESS_LIST_SUCCESS,
  GET_ADDRESS_DETAILS,
  GET_ADDRESS_DETAILS_SUCCESS,
  GET_COINS_AVAILABLE,
  GET_COINS_AVAILABLE_SUCCESS,
  SUPPORT_TICKET,
  SUPPORT_TICKET_SUCCESS,
  GENERATE_RECEIPT,
  GENERATE_RECEIPT_SUCCESS,  
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  FRESHDESK_TICKET,
  FRESHDESK_TICKET_SUCCESS,
  ORDER_PAYMENT,
  ORDER_PAYMENT_SUCCESS,
  FRESHDESK_TICKET_ECOM,
  FRESHDESK_TICKET_ECOM_SUCCESS
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  addressList: [],
  orderList: [],
  addressDetails: {},
  coinsVal: "",
  cartDetails: {},
  logimartProductList:[],
  logimartProductList:[],
  productDetails:{},
  productLoader: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // case TOAST_STATUS: {
    //   return {
    //     ...state,
    //     toastLoading: !toastLoading,
    //   };
    // }
    case FRESHDESK_TICKET_ECOM: {
      console.log("hi2")
      return {
        ...state,
        loader: true,
      };
    }
    case FRESHDESK_TICKET_ECOM_SUCCESS: {
      console.log("hi3")
      return {
        ...state,
        loader: false,
      };
    }
    case ORDER_PAYMENT: {
      console.log("hi2")
      return {
        ...state,
        loader: true,
      };
    }
    case ORDER_PAYMENT_SUCCESS: {
      console.log("hi3")
      return {
        ...state,
        loader: false,
      };
    }
    case FRESHDESK_TICKET: {
      console.log("hi2")
      return {
        ...state,
        loader: true,
      };
    }
    case FRESHDESK_TICKET_SUCCESS: {
      console.log("hi3")
      return {
        ...state,
        loader: false,
      };
    }
    case GET_PRODUCT_LIST: {
      console.log("hi2")
      return {
        ...state,
        loader: true,
        productLoader: true,
      };
    }
    case GET_PRODUCT_LIST_SUCCESS: {
      console.log("hi3")
      return {
        ...state,
        logimartProductList: action?.payload?.data?.products?.rows,
        loader: false,
        productLoader: false,
      };
    }

    case GET_PRODUCT_DETAILS: {
      console.log("hi2")
      return {
        ...state,
        loader: true,
      };
    }
    case GET_PRODUCT_DETAILS_SUCCESS: {
      console.log("hi3")
      return {
        ...state,
        productDetails:action?.payload?.data?.products?.rows,
        loader: false,
      };
    }
    case GENERATE_RECEIPT: {
      console.log("hi2")
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_RECEIPT_SUCCESS: {
      console.log("hi3")
      return {
        ...state,
        loader: false,
      };
    }
    case SUPPORT_TICKET: {
      console.log("hi2")
      return {
        ...state,
        loader: true,
      };
    }
    case SUPPORT_TICKET_SUCCESS: {
      console.log("hi3")
      return {
        ...state,
        loader: false,
      };
    }
    case GET_COINS_AVAILABLE: {
      console.log("hi2")
      return {
        ...state,
        loader: true,
      };
    }
    case GET_COINS_AVAILABLE_SUCCESS: {
      console.log("hi3")
      return {
        ...state,
        loader: false,
        coinsVal: action?.payload?.data
      };
    }
    case CLEAN_ECOM_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_CART_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_CART_DETAILS_SUCCESS: {
      Object.assign(state.cartDetails,action?.payload?.data);
      return {
        ...state,
        loader: false,
        cartDetails: { 
          ...state.cartDetails,   
          resp: action?.payload?.data?.resp            
        }
      };
    }
    case ADD_TO_CART: {
      console.log("ADD_TO_CART 1", action);
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_TO_CART_SUCCESS: {
      console.log("ADD_TO_CART 2", action);
      return {
        ...state,
        loader: false,
      };
    }
    case REMOVE_TO_CART: {
      return {
        ...state,
        loader: true,
      };
    }
    case REMOVE_TO_CART_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_ORDER_LIST: {
      console.log("order list reducer")
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ORDER_LIST_SUCCESS: {
      console.log("order list reducer", action?.payload)
      return {
        ...state,
        loader: false,
        orderList: action?.payload?.data
      };
    }
    case CREATE_ORDER: {
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_ORDER: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ORDER_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case ADD_ADDRESS_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_ADDRESS_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case GET_ADDRESS_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ADDRESS_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        addressList: action?.payload?.data?.data
      };
    }
    case GET_ADDRESS_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ADDRESS_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        addressDetails: action?.payload?.data
      };
    }
    default: {
      return state;
    }
  }
};
