import { all } from "redux-saga/effects";
import { appSaga } from "./appSaga";
import { watchReceivableSagas } from "./receivableSaga";
import { watchDriversListSaga } from "./driverlistSaga";
import { watchMasterWalletSagas } from "./masterWalletSaga";
import { watchManageSummarySagas } from "./manageCardSaga";
import { watchFastagSagas } from "./fastagSaga";
import { watchTransferSagas } from "./transferSaga";
import { watchManageLogipeCardSage } from "./LogipeCardSage";
import { watchVehicleTransactionHistory } from "./manageVehicleSaga";
import { watchCardWalletSagas } from "./cardWalletSaga";
import { watchbeneficiarySagas } from "./beneficiarySaga"; 
import { watchFleetSagas } from "./fleetSaga";
import {watchWalletSagas} from './walletSaga';
import {watchCashbackSaga} from "./cashbackSaga";
import { watchM2pSagas } from "./m2pSaga";
import { watchOnboardingSagas} from "./onboardingSaga";
import { watchUserManagementSagas } from "./UserManagementSaga";
import { watchAuthSagas } from "./loginSaga";
import { ecomSaga } from "./ecomSaga";
import { reportSaga } from "./reportSaga";
import { watchOmcSagas } from "./omcSaga";
import { watchTripSagas } from './TripSaga';
import { watchcustomerSagas } from "./customerSaga";

export default function* rootSaga() {
  yield all([
    appSaga(),
    watchReceivableSagas(),
    watchDriversListSaga(),
    watchMasterWalletSagas(),
    watchManageSummarySagas(),
    watchFastagSagas(),
    watchTransferSagas(),
    watchManageLogipeCardSage(),
    watchVehicleTransactionHistory(),
    watchbeneficiarySagas(),
    watchAuthSagas(),
    watchCardWalletSagas(),
    watchFleetSagas(),
    watchWalletSagas(),
    watchCashbackSaga(),
    watchM2pSagas(),
    watchUserManagementSagas(),
    watchOnboardingSagas(),
    ecomSaga(),
    reportSaga(),
    watchOmcSagas(),
    watchTripSagas(),
    watchcustomerSagas()
  ]);
}
