import mixpanel from 'mixpanel-browser';
mixpanel.init('32f6d29a0205aab90248b297675ae809');

//let env_check = process.env.NODE_ENV === 'production';
let env_check = true;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (new_id,existing_id) => {
    if (env_check) mixpanel.alias(new_id, existing_id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  getDistinctId: ()=>{
    if (env_check) mixpanel.get_distinct_id();
  }
};

export let Mixpanel = actions;