import { Button, NavLink } from "reactstrap";
import React, { Component ,useState} from "react";
import SearchBar from "../searchbar";
import "./index.css";
import { useNavigate } from "react-router-dom";
import RouterButton from "../../../Subcomponent/RouterButton/RouterButton";
import HeaderRight from "../headerRight";
import Home from "../../Homepage/home";
import SvgImage from "../../Icons/Icons";



function LoginHeader(props) {
  const { accountName, img, accountTitle, companyName, setLoginStatus } = props;
  const [act,setAct] = useState();

  const navigate= useNavigate()
  const handleLogin=()=>{
    setLoginStatus(false);
    props.showModal();
  }
  return (
    <div className="main-header " style={{zIndex:"402"}}>
      <div className="rel flex">
        <div className="logo">
        {/* <NavLink className="navbar-item" activeClassName="is-active" to={Home} >
              <SvgImage name="AppLogo" />
            </NavLink>           */}
            <RouterButton className="applogolink" title = 'AppLogo' link = "home" active = {act === 1 ? true : false}/>

        </div>
       

        {/* <SearchBar /> */}
        <div className="flex1"></div>
        <div className="right-info padding16 padding8T" style={{paddingRight:"3rem"}}>
          <div>
            <button className="notificationLogo">
              <SvgImage name='NotificationLogo' />
            </button>
          </div>
          <div>
            <button className="greenButton Medium text3 flexStyling padding8" onClick={handleLogin}>
              <SvgImage name="loginLogo" />
             <div className="padding8L" style={{marginTop:"-0.1rem"}}>Log In</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginHeader;
