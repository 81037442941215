import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { DatePickerField } from "../../core/inputs/input";
import SvgImage from "../../Icons/Icons";
import "./index.css";
import * as Yup from "yup";
import {
  filterExpenseReportAction,
  filterWalletReportAction,
} from "../../../services/Store/Actions/reportActions";
import { getDriverList } from "../../../services/Store/Actions/driverslistActions";
import Toast from "../../subComponents/toast";
import { toastStatus } from "../../../services/Store/Actions/appActions";
import moment from "moment";

const DriverOptions = (props) => {
  const [selectAll, setSelectAll] = useState(true);
  const [selectAllDrivers, setSelectAllDrivers] = useState(false);
  const optionList1 = [
    "Credit",
    "Debit",
    "Remarks",
    "POS Machine",
    "ATM",
    "Online",
    "Recharge",
    "POS Charges",
    "Refund (Merchant)",
    "Balance Withdrawal",
  ];
  const optionList2 = [
    "Credit",
    "Debit",
    "Remarks",
    "Cash Given",
    "Other expenses",
  ];
  const [search, setSearch] = useState("");
  // const [userId, setUserId]= useState("");
  const [open, setOpen] = useState(false);
  const { loader } = useSelector((state) => state?.report);
  const [checkedState1, setCheckedState1] = useState(
    new Array(optionList1.length).fill(true)
  );
  const [checkedState2, setCheckedState2] = useState(
    new Array(optionList2.length).fill(true)
  );
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    // userIDVal: Yup.string().required("Please select driver name from list."),
    mode: Yup.string().test(
      "noDefaultMode",
      "Please select download format",
      (val) => val != "default"
    ),
  });
  const handleSelect1 = (position) => {
    if (selectAll) {
      setSelectAll(false);
    }
    const updatedCheckedState = checkedState1.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState1(updatedCheckedState);
  };

  const handleSelect2 = (position) => {
    if (selectAll) {
      setSelectAll(false);
    }
    const updatedCheckedState = checkedState2.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState2(updatedCheckedState);
  };

  const datepickerRefStart = useRef();
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };

  const dispatch = useDispatch();
  const driverList = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.rows
  );
  const driverCount = useSelector(
    (state) => state?.driverlist?.allDrivers?.divers?.count
  );
  console.log("error", driverCount);
  useEffect(() => {
    dispatch(
      getDriverList({
        offset: "",
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        limit: 10,
      })
    );
  }, [search]);
  return (
    <div className="whiteBackground containerShadow" style={{ height: "100%" }}>
      <Formik
        enableReinitialize
        initialValues={{
          startDate: "",
          endDate: "",
          mode: "default",
          fieldVal: "",
          driverName: "",
          userIDVal: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log("values 1", values);
          console.log("values 2", selectAll, optionList1, checkedState2);
          const checkList1 = checkedState1.find((ele) => ele == true);
          const checkList2 = checkedState2.find((ele) => ele == true);
          console.log("values 3", checkList1, checkList2);
          // if(checkList1==undefined && checkList2==undefined && !selectAll){
          //     console.log("error");
          //     actions.setFieldError("fieldVal", "Please select atleast one parameter.");
          // }else{
          // if(selectAll || checkList1!=undefined){
          let payload = {
            from: moment(values?.startDate).format("YYYY-MM-DD"),
            to: moment(values?.endDate).format("YYYY-MM-DD"),
          };

          if (values?.mode == "excel") {
            payload["mode"] = 1;
          }

          console.log("payload for vehicle", payload);
          dispatch(
            filterWalletReportAction(
              values.userIDVal,
              payload,
              //     {
              //     "debit": selectAll?"debit": checkedState1[1]?"debit":!checkedState1[0]?"debit":"any",
              //     "remarks": selectAll?"remarks": checkedState1[2]?"remarks":"any",
              //     "POSMachine": selectAll?"POSMachine": checkedState1[3]?"POSMachine":"any",
              //     "ATM": selectAll?"ATM": checkedState1[4]?"ATM":"any",
              //     "balanceWithdrawal": selectAll?"balanceWithdrawal": checkedState1[9]?"balanceWithdrawal":"any",
              //     "online":selectAll?"online": checkedState1[5]?"online":"any",
              //     "POSCharges": selectAll?"POSCharges": checkedState1[7]?"POSCharges":"any",
              //     "refund": selectAll?"refund": checkedState1[8]?"refund":"any",
              //     "recharge": selectAll?"recharge": checkedState1[6]?"recharge":"any",
              //     "credit": selectAll?"credit": checkedState1[0]?"credit":!checkedState1[1]?"credit":"any",
              // }
              (res) => {
                console.log("reply", res);
                window.open(res?.data?.data?.url);
              }
            )
          );
          // }
          // if(selectAll || checkList2!=undefined){
          //     dispatch(filterExpenseReportAction(values.userIDVal,{
          //         "debit": selectAll?"debit": checkedState2[1]?"debit":!checkedState2[0]?"debit":"any",
          //         "remarks":selectAll?"remarks": checkedState2[2]?"remarks":"any",
          //         "cashGiven": selectAll?"cashGiven": checkedState2[3]?"cashGiven":"any",
          //         "otherExpense": selectAll?"otherExpense": checkedState2[4]?"otherExpense":"any",
          //         "credit": selectAll?"credit": checkedState2[0]?"credit":!checkedState2[1]?"credit":"any",
          //     }, (res)=>{
          //         console.log("reply", res);
          //         window.open(res?.data?.data?.url)
          //     }))
          // }

          // }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <FormGroup className="padding24 padding0B">
              <div className="d-flex">
                <div className="heading4 Regular padding8T padding8B">
                  Select Driver
                </div>
                <div className="flexStyling padding8 ms-2">
                  <input
                    type="checkbox"
                    checked={selectAllDrivers}
                    value="allOptions"
                    onChange={(e) => {
                      const { checked } = e.target;
                      setSelectAllDrivers(checked);
                    }}
                    className="form-check-input"
                  />
                  <div className="heading4 semiBold padding8L">
                    Download Report For All Drivers{" "}
                  </div>
                </div>
              </div>
              <div
                className="searchBox"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <input
                  className="searchBar-input"
                  type="text"
                  id="driverName"
                  name="driverName"
                  touched={touched.driverName}
                  errors={errors.driverName}
                  onBlur={handleBlur("driverName")}
                  style={{ width: "80%" }}
                  placeholder="Enter driver's name"
                  onChange={(event) => {
                    setSearch(event.target.value);
                    setFieldValue("driverName", event.target.value, true);
                  }}
                  value={values.driverName}
                  autoFocus
                />
              </div>
              <div style={{ position: "relative" }}>
                {open ? (
                  <div className="driverSearchContainer containerShadow">
                    <div
                      style={{
                        position: "absolute",
                        top: "0.3rem",
                        right: "0.3rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <SvgImage name="CloseButtonLogo" />
                    </div>
                    {driverList.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="flexStyling padding8 "
                          onClick={(event) => {
                            setOpen(false);
                            // setUserId(event.target.value);
                            setFieldValue(
                              "driverName",
                              item?.User?.fullName,
                              true
                            );
                            setFieldValue(
                              "userIDVal",
                              event.target.value,
                              true
                            );
                          }}
                        >
                          <input
                            type="radio"
                            name="drivername"
                            value={item?.User?.userId}
                            id={item?.User?.userId}
                          />
                          <div className="padding16L">
                            {item?.User?.fullName.length > 35
                              ? item?.User?.fullName.split(0, 35) + "..."
                              : item?.User?.fullName}
                          </div>
                        </div>
                      );
                    })}
                    {driverCount == 0 ? (
                      <div className="heading3 semiBold padding16 padding24B">
                        No Driver Available
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="heading4 Regular"
                style={{ margin: "7px 0 0px 0", color: "red" }}
              >
                {errors.userIDVal && touched.userIDVal ? (
                  <div>*{errors.userIDVal}</div>
                ) : null}
              </div>
            </FormGroup>
            <FormGroup
              className="padding24 padding0T"
              style={{ paddingBottom: "0.5rem" }}
            >
              <div className="row ">
                <div className="col-5">
                  <div className="heading4 Regular padding8T padding8B">
                    Starting Date
                  </div>
                  <div className="searchBox" style={{ width: "100%" }}>
                    <DatePickerField
                      classname="searchBar-input"
                      name="startDate"
                      header="Select Start date"
                      value={values.startDate}
                      onChange={(v) => {
                        setFieldValue("startDate", v, true);
                      }}
                      maxDate={
                        values.endDate === "" ? new Date() : values.endDate
                      }
                      ref1={datepickerRefStart}
                    />
                    <label
                      className="date-input"
                      style={{
                        position: "relative",
                        top: "-5px",
                        left: "-1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickDatepickerIcon1();
                      }}
                    >
                      <SvgImage
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        // classname="date-logo"
                      />
                    </label>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.startDate && touched.startDate ? (
                      <div>*{errors.startDate}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-5">
                  <div className="heading4 Regular padding8T padding8B">
                    Ending Date
                  </div>
                  <div className="searchBox" style={{ width: "100%" }}>
                    <DatePickerField
                      classname="searchBar-input"
                      name="endDate"
                      header="Select End date"
                      value={values.endDate}
                      onChange={(v) => {
                        setFieldValue("endDate", v, true);
                      }}
                      //onChange={(v) => setFieldValue("endDate", v, true)}
                      minDate={values.startDate === "" ? "" : values.startDate}
                      maxDate={new Date()}
                      ref1={datepickerRefEnd}
                    />
                    <label
                      className="date-input"
                      for="endDate"
                      style={{
                        position: "relative",
                        top: "-5px",
                        left: "-1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickDatepickerIcon2();
                      }}
                    >
                      <SvgImage
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        // classname="date-logo"
                      />
                    </label>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.endDate && touched.endDate ? (
                      <div>*{errors.endDate}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup
              className="padding24  padding0T padding0B"
              style={{ height: "79%" }}
            >
              <div className="row" style={{ height: "100%" }}>
                {/* <div className="col-6" style={{height:"100%"}}>
                    <div className="heading2 Bold padding16B padding24T">Select Required Parameters</div>
                    <div className="flexStyling padding8">
                        <Input type="checkbox" 
                            checked={selectAll}
                            value="allOptions"
                            onChange={(e)=>{
                            const {value ,checked } = e.target;
                            console.log("selected", value, checked);
                            setSelectAll(checked);
                            const newState1= new Array(optionList1.length).fill(checked);
                            const newState2= new Array(optionList2.length).fill(checked);
                            setCheckedState1(newState1);
                            setCheckedState2(newState2);
                            }}
                            className="form-check-input"/>
                        <div className="heading4 semiBold padding8L">Select All </div>
                    </div>
                    <div className="flexStyling padding8 padding0L LowBorder">
                        <div className="heading4 semiBold padding8L">Card</div>
                        <div className="padding16L" style={{position:"relative", top:"-0.2rem"}}>
                            <SvgImage name="downArrowGreen" />
                        </div>
                    </div>
                    {
                        optionList1.map((item,i)=>{
                            return(
                                <div className="flexStyling padding8 LowBorder" key={i}>
                                <input type="checkbox" value={item} checked={selectAll || checkedState1[i] }
                                    className="form-check-input"
                                    onChange={()=>{
                                        handleSelect1(i);
                                    }}/>
                                <div className="heading4 semiBold padding8L">{item}</div>
                            </div>
                            )
                        })
                    }
                    <div className="flexStyling padding8 padding24T LowBorder">
                        <div className="heading4 semiBold padding0L">Cash</div>
                        <div className="padding16L"  style={{position:"relative", top:"-0.2rem"}}>
                            <SvgImage name="downArrowGreen" />
                        </div>
                    </div> 
                    {
                        optionList2.map((item,i)=>{
                            return(
                                <div className="flexStyling padding8 LowBorder" key={i}>
                                <input type="checkbox" value={item} checked={selectAll || checkedState2[i] }
                                    className="form-check-input"
                                    onChange={()=>{
                                        handleSelect2(i);
                                    }}/>
                                <div className="heading4 semiBold padding8L">{item}</div>
                            </div>
                            )
                        })
                    }
                    <div className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}>
                        {errors.fieldVal && touched.fieldVal ? (
                            <div>*{errors.fieldVal}</div>
                        ) : null}
                    </div>  
                </div> */}
                <div
                  className="col-6 padding24L"
                  style={{ height: "100%" }}
                  onChange={(event) => {
                    //console.log("valllll", event.target.value);
                    setFieldValue("mode", event.target.value, true);
                  }}
                >
                  <div className="heading2 Bold padding16B padding24T">
                    Select Report Format
                  </div>
                  <div className="flexStyling padding8 form-check LowBorder">
                    <input type="radio" id="pdfOpt" name="format" value="pdf" />
                    <label
                      className="heading4 semiBold padding8L form-check-label"
                      for="pdfOpt"
                    >
                      PDF
                    </label>
                  </div>
                  <div className="flexStyling padding8 form-check">
                    <input
                      type="radio"
                      id="excelOpt"
                      name="format"
                      value="excel"
                    />
                    <label
                      className="heading4 semiBold padding8L form-check-label"
                      for="excelOpt"
                    >
                      Excel Sheet
                    </label>
                  </div>
                  <div
                    className="heading4 Regular"
                    style={{ margin: "7px 0 0px 0", color: "red" }}
                  >
                    {errors.mode && touched.mode ? (
                      <div>*{errors.mode}</div>
                    ) : null}
                  </div>
                  <div className="padding24T">
                    <Button
                      className="generateOtp semiBold heading2"
                      style={{
                        width: "70%",
                        background:
                          loader || !(values?.userIDVal || selectAllDrivers)
                            ? "#EFEFEF"
                            : "#485B7C",
                        color:
                          loader || !(values?.userIDVal || selectAllDrivers)
                            ? "#B5B5B5"
                            : "#ffffff",
                      }}
                      disabled={
                        loader || !(values?.userIDVal || selectAllDrivers)
                          ? true
                          : false
                      }
                    >
                      {loader ? "Please Wait" : "Download Report"}
                    </Button>
                  </div>
                </div>
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
      {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={!toastObject?.toastStatus}
          isOpen={toastObject?.toastStatus}
          name="home"
          handleClose={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
                message: "",
                status: true,
              })
            );
            // if (!toastObject?.toastStatus)
            //window.location = "/";
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DriverOptions;
