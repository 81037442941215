import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import DriverDashboardManageExpense from "../SubComponents/DriverDashBoardManageExpense";
import FullDriverList from "../SubComponents/FullDriverList";
import ManageDriverCardType1 from "../SubComponents/ManageDriverTopCard";
import { getDriverList2 } from "../../../services/Store/Actions/driverslistActions";
import Toast from "../../subComponents/toast";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { kycDoneAction } from "../../../services/Store/Actions/walletAction";
import { toastStatus } from "../../../services/Store/Actions/appActions";
import { ToastContainer } from "react-toastify";
import DriverInfo from "../SubComponents/NewDriverSideSection/SubComponents/DriverInfo";
import DriverSideLowerDiv from "../SubComponents/NewDriverSideSection/DriverSideLowerDiv";
import SvgImage from "../../Icons/Icons";

const FullDriverDashboardManageExpense = (props) => {
  const { sideBar } = props;
  let location = useLocation();
  const [userId, setUserId] = useState("");
  const [driverDetails, setDriverDetails] = useState({
    name: "Select Driver",
    phone: "",
  });
  const [VehicleNumber, setVehicleNumber] = useState(false);
  const [driverID, setDriverID] = useState("");
  const [driverCardStatus, setDriverCardStatus] = useState(0);
  const [cardType, setCardType] = useState("1");
  const [driverFleet, setDriverFleet] = useState("Driver not selected");
  const driversCount = useSelector((state) => state?.driverlist?.user?.count);
  const [next, setnext] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //console.log("DRIVERSSS count", driversCount)

  // const userHandler = (userId) => {
  //   setUserId(userId);
  //   //  console.log("userid herE", userId);
  // };
  const { allDrivers, allDriverList } = useSelector(
    (state) => state?.driverlist
  );
  const { allDriversAdvance, allDriverListAdvance } = useSelector(
    (state) => state?.driverlist
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newDriverList = useSelector((state) => state);
  const driverCount = newDriverList?.driverlist?.newDriverList?.divers?.count;
  const newList = newDriverList?.driverlist?.newDriverList?.divers?.rows;
  const [noKYC, setNoKYC] = useState(0);
  const allDriversWithKYC = newList?.filter(
    (data) =>
      data?.User?.WalletAccount?.accountStatus == "4" ||
      data?.User?.WalletAccount?.accountStatus === "3" ||
      data?.User?.WalletAccount?.accountStatus === "7"
  )?.length;

  // const allDriversWithoutKYC = newList?.filter(
  //   (data) =>
  //     data?.User?.WalletAccount?.accountStatus === "1" ||
  //     data?.User?.WalletAccount?.accountStatus === "2" ||
  //     data?.User?.WalletAccount?.accountStatus === "0" ||
  //     data?.User?.WalletAccount?.accountStatus === ""
  // )?.length;

  //console.log("allDriversWithoutKYC", allDriversWithoutKYC);
  console.log("allDriversWithKYC", allDriversWithKYC);

  console.log("===================================", allDrivers);
  console.log("new driver list", driverCount);

  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.userId;

  useEffect(() => {
    console.log("11111");
    if (driverDetails.name == "All Drivers") {
      navigate("/Drivers/bulkTransfer");
      console.log("2222");
    }
    // console.log("HHHHHHHHHHH",id,location.pathname);
    // if(id==undefined && location.pathname=="/Drivers/bulkTransfer")
    // {
    //   console.log("HHHHHHHHHHH99999");
    //   setDriverDetails({
    //     name: "All Driver",
    //     phone: "",
    //   })
    // }
  }, [driverDetails, location.pathname]);
  useEffect(() => {
    // dispatch(getDriverList2());
    // addCustomer(values, (res) => {
    //   console.log("addCustomer call back", res.data);
    //   actions.setSubmitting(false);
    //   if (res?.data?.type === "success") {
    //     handleClose();
    //     //window.location.reload();

    //     //alert("success");
    //   } else {
    //     handleClose();
    //     // alert("error");
    //   }
    // })
    dispatch(
      kycDoneAction(
        {
          userId: userIdSession,
        },
        (res) => {
          console.log("addCustomer call back", res.data);
          //actions.setSubmitting(false);
          // if (res?.data?.type === "success") {
          //   //handleClose();
          //   //window.location.reload();

          //   //alert("success");
          // } else {
          //   handleClose();
          //   // alert("error");
          // }
        }
      )
    );
  }, []);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (id == undefined && location.pathname == "/Drivers") {
      setDriverDetails({
        name: "Select Driver",
        phone: "",
      });
    }
  }, [location.pathname]);
  const kycDone = useSelector((state) => state?.wallet?.kycDone);
  console.log("kyc done", kycDone);
  const data1 = [
    {
      number: "Drivers Onboarded",
      text: driverCount ? driverCount : null,
      LogoName: "driverOnboardedLogo",
      className: "ManageCustomerCardType-1 firstCard",
    },
    {
      number: "Drivers KYC Pending",
      className: "ManageCustomerCardType-1 centerCard",
      text: driverCount - kycDone,
      LogoName: "driverKYCPendingLogo",
    },
    // {
    //   number: "Drivers KYC Pending",
    //   text:
    //     allDriversWithKYC === null || allDriversWithKYC === undefined
    //       ? 0
    //       : allDriversWithoutKYC,
    //   LogoName: "driverKYCPendingLogo",
    // },
  ];

  const toastObject = useSelector((state) => state?.app?.toastObject);

  const nextTrigger = () => {
    setnext(false);
  };
  const allowTrigger = () => {
    setnext(true);
  };
  let { id } = useParams();
  console.log("hhhhhhhhhhhhhhh00", id);
  useEffect(() => {
    if (id !== undefined && id !== "" && next) {
      let val = allDriverListAdvance?.filter(
        (element) => element.userId == id
      )[0];
      setUserId(val?.userId);
      setDriverFleet(val?.vrn);
      setDriverID(val?.driverId);
      const accountSt = Number(val?.walletAccountStatus);
      console.log("===============st", accountSt);
      //neoCred=1, m2p=2;
      let cardVar =
        Number(val?.AccountType) == 5
          ? "2"
          : Number(val?.AccountType) == 1
          ? "1"
          : "1";
      console.log("===============type", cardVar);
      setDriverCardStatus(accountSt);
      setCardType(cardVar);
      setDriverDetails({
        name: val?.fullName,
        phone: val?.phone,
        nickname: val?.nickName,
      });
      //setVehicleNumber()
      // props.setDriverFleet(e?.User?.fleet);
      // props.setDriverID(e?.driverId);
      // props.setDriverCardStatus(e?.User?.WalletAccount?.accountStatus);
      // props.userHandler(e?.User?.userId);
      // props.setDriverDetails({ name: e?.User?.fullName, phone: e?.User?.phone });
      //console.log("ababababaab",val);
    }
  }, [id, allDriversAdvance]);

  console.log(
    "medha",
    driverID,
    VehicleNumber,
    userId,
    driverCardStatus,
    driverDetails
  );

  // offset value
  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);

  // search value
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let searchParam = params.search === undefined ? "" : params.search;
  return (
    <div
      className="container topMargin sideBarMargin"
      style={{
        marginLeft: sideBar ? "220px" : "100px",
        maxWidth: sideBar ? windowWidth - 240 : windowWidth - 130,
      }}
    >
      <div className="row">
        <div className="col-lg-4 FullDriverDashboardBox">
          <div
            className="FUllListSearchBar leftTopDiv"
            onClick={() => {
              setDriverDetails({
                name: "All Drivers",
                phone: "",
              });
            }}
            style={{ margin: "20px 12px 20px 0", cursor: "pointer" }}
          >
            <div className="bulkSalarySvgHeading">
              <div
                style={{
                  backgroundColor: "#FFF",
                  padding: "4px",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
              >
                <SvgImage name="payBulkSalaryLogo" />
              </div>
              <div className="FontBoldMedium Bold">Transfer Driver Bulk</div>
            </div>
            <SvgImage name="rightArrow" />
          </div>
          <FullDriverList
            VehicleNumber={VehicleNumber}
            driverFleet={driverFleet}
            // setDriverFleet={setDriverFleet}
            // setDriverID={setDriverID}
            // setDriverCardStatus={setDriverCardStatus}
            userId={userId}
            // userHandler={userHandler}
            driverDetails={driverDetails}
            nextTrigger={nextTrigger}
            allowTrigger={allowTrigger}
            // setDriverDetails={setDriverDetails}
          />
        </div>
        <div className="col-lg-8 FullDriverDashboardBox">
          {/* <div className="Manage-Customer-card-type-1-box">
            {data1.map((item) => {
              console.log("item here", item);
              return (
                <div key={item.text} className={item.className}>
                  <ManageDriverCardType1
                    allDriverList={allDriverList}
                    name={item.LogoName}
                    text={item.number}
                    number={item.text}
                  />
                </div>
              );
            })}
          </div> */}
          <div className="">
            {/* <DriverDashboardManageExpense
              setVehicleNumber={setVehicleNumber}
              driverFleet={driverFleet}
              driverID={driverID}
              driverDetails={driverDetails}
              userId={userId}
              driverCardStatus={driverCardStatus}
              cardType={cardType}
            /> */}
          </div>
          <div className="whiteBackground">
            <DriverSideLowerDiv
              cardType={cardType}
              driverDetails={driverDetails}
              driverCardStatus={driverCardStatus}
              userId={userId}
              driverFleet={driverFleet}
              setVehicleNumber={setVehicleNumber}
              driverID={driverID}
            />
          </div>
        </div>
      </div>
      
      {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
          }}
          isOpen={toastObject?.toastStatus}
          name="driver"
          handleClose={() => {
            // dispatch(
            //   toastStatus({
            //     toastStatus: false,
            //     message: "",
            //     status: true,
            //   })
            // );
            // if (!toastObject?.toastStatus)
            id == undefined
              ? (window.location = "/Drivers/bulkTransfer")
              : (window.location =
                  "/Drivers/" +
                  id +
                  "?search=" +
                  searchParam +
                  "&offset=" +
                  offsetVal);
            //navigate("/Drivers");
          }}
        />
      ) : (
        <></>
      )}
      <ToastContainer
        theme="dark"
        position="top-right"
        closeOnClick
        pauseOnHover
        autoClose={4000}
      />
    </div>
  );
};
export default FullDriverDashboardManageExpense;
