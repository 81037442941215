import { call, put, takeEvery } from "redux-saga/effects";
import {
  addbranchApi,
  changeManagerLimit,
  deleteBranchListApi,
  editControlManagerApi,
  getBranchByIDApi,
  getBranchDriverApi,
  getBranchFleetApi,
  getBranchListApi,
  getBranchManagerApi,
  getManagerListApi,
  linkBranchDriverApi,
  linkBranchFleetApi,
  linkBranchManagerApi,
  managerGetControlApi,
  setBranchLimitApi,
} from "../../Utils/Apis";

import { processErrorAndRespond } from "../../Utils/functions";
import {
  addBranchSuccess,
  changeUpiManagerSuccess,
  deleteBranchSuccess,
  editControlManagerSuccess,
  getBranchByIdSuccess,
  getBranchDriverSuccess,
  getBranchFleetSuccess,
  getBranchManagerSuccess,
  getBranchSuccess,
  getManagerListSuccess,
  linkBranchDriverSuccess,
  linkBranchFleetSuccess,
  linkBranchManagerSuccess,
  managerGetControlAction,
  managerGetControlActionSuccess,
  setBranchLimitSuccess,
} from "../Actions/UserManagementAction";
import {
  ADD_BRANCH,
  CHANGE_UPI_MANAGER,
  DELETE_BRANCH,
  EDIT_CONTROL_MANAGER,
  GET_BRANCH,
  GET_BRANCH_DRIVER,
  GET_BRANCH_FLEET,
  GET_BRANCH_ID,
  GET_BRANCH_MANAGER,
  LINK_BRANCH_DRIVER,
  LINK_BRANCH_FLEET,
  LINK_BRANCH_MANAGER,
  MANAGER_GET_CONTROL,
  MANAGER_LIST,
  SET_BRANCH_LIMIT,
} from "../storeTypes";

function* getManagerListSaga(action) {
  try {
    const response = yield call(getManagerListApi, action.payload);
    console.log(
      "getWalletTransactionHistorySaga123",
      response?.data?.data?.result
    );
    if (response.data.type == "success") {
      //console.log("dattt",response?.data?.data)
      yield put(getManagerListSuccess(response?.data?.data?.result));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* managerGetControlSaga(action) {
  try {
    const response = yield call(managerGetControlApi, action.payload);
    //console.log("getWalletTransactionHistorySaga >>> ", response);
    if (response.data.type == "success") {
      //console.log("dattt",response?.data?.data)
      yield put(managerGetControlActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* editControlManagerSaga(action) {
  try {
    const response = yield call(editControlManagerApi, action?.obj);
    // console.log('editControlManagerApi Response',response?.data)
    if (response?.status == 200) {
      yield put(editControlManagerSuccess());
      action?.callback(response?.data);
    }
  } catch (error) {
    action?.callback(error?.response?.data);
    // yield(put(managerError()));
  }
}
function* changeManagerUpiSaga(action) {
  try {
    const response = yield call(changeManagerLimit, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(changeUpiManagerSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}

function* getBranchList(action) {
  try {
    const response = yield call(getBranchListApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(getBranchSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}
function* deleteBranchSaga(action) {
  try {
    const response = yield call(deleteBranchListApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(deleteBranchSuccess());
    }
  } catch (error) {
    yield put(deleteBranchSuccess());
    action.callBack(error?.response);
  }
}

function* addBranch(action) {
  try {
    const response = yield call(addbranchApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(addBranchSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}

function* linkBranchManager(action) {
  try {
    const response = yield call(linkBranchManagerApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(linkBranchManagerSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}

function* linkBranchFleet(action) {
  try {
    const response = yield call(linkBranchFleetApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(linkBranchFleetSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}

function* linkBranchDriver(action) {
  try {
    const response = yield call(linkBranchDriverApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(linkBranchDriverSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}

function* getBranchDriver(action) {
  try {
    const response = yield call(getBranchDriverApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(getBranchDriverSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}

function* getBranchFleet(action) {
  try {
    const response = yield call(getBranchFleetApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(getBranchFleetSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}


function* getBranchManager(action) {
  try {
    console.log('summnannanban', action);
    const response = yield call(getBranchManagerApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(getBranchManagerSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}

function* getBranchById(action) {
  try {
    const response = yield call(getBranchByIDApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(getBranchByIdSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}

function* setBranchLimit(action) {
  try {
    const response = yield call(setBranchLimitApi, action.payload);
    console.log('changemanagerupidudbd', response);
    action.callBack(response);
    if (response?.data?.type == 'success') {
      
      yield put(setBranchLimitSuccess(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error?.response);
  }
}

export function* watchUserManagementSagas() {
  yield takeEvery(MANAGER_LIST, getManagerListSaga);
  yield takeEvery(GET_BRANCH, getBranchList);
  yield takeEvery(DELETE_BRANCH, deleteBranchSaga);
  yield takeEvery(ADD_BRANCH, addBranch);
  yield takeEvery(LINK_BRANCH_MANAGER, linkBranchManager);
  yield takeEvery(LINK_BRANCH_FLEET, linkBranchFleet);
  yield takeEvery(SET_BRANCH_LIMIT, setBranchLimit);
  yield takeEvery(LINK_BRANCH_DRIVER, linkBranchDriver);
  yield takeEvery(GET_BRANCH_DRIVER, getBranchDriver);
  yield takeEvery(GET_BRANCH_FLEET, getBranchFleet);
  yield takeEvery(GET_BRANCH_MANAGER, getBranchManager);
  yield takeEvery(GET_BRANCH_ID, getBranchById);
  yield takeEvery(MANAGER_GET_CONTROL, managerGetControlSaga);
  yield takeEvery(EDIT_CONTROL_MANAGER, editControlManagerSaga);
  yield takeEvery(CHANGE_UPI_MANAGER, changeManagerUpiSaga);
}
