import React, { useEffect, useState } from "react";
import "./index.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import SvgImage from "../../../../Components/Icons/Icons";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { cleanLoginLoader, handleLoginSuccess, resetPasswordAction, sendOtp, verifyOtp } from "../../../../services/Store/Actions/LoginAction";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";
import { useNavigate } from "react-router-dom";


const ResetPasswordForm= (props) => {


    const validationSchema = Yup.object().shape({
      password: Yup.string()
        .required("Password must have atleast 8 characters")
        .matches(
          /^[A-Za-z0-9]+$/,
          "Password must contain one number and one alphabet. No special character is allowed."
        ).test('passwordLen', 'Password must have atleast 8 characters', val => val.length>=8),
      confirmPassword:Yup.string().required("Password must have atleast 8 characters").oneOf([Yup.ref('password'), null], "Does not match the above password").test('passwordLen', 'Password must have atleast 8 characters', val => val.length>=8)
    });

    const dispatch=useDispatch();
    const [pwType, setPwType]= useState("password");
    const [showText, setShowText]= useState("ShowEyeIcon");
    const { loader } = useSelector((state) => state?.loginReducer);

    const Navigate= useNavigate();

    const togglePassword=()=>{
      if(pwType=="password"){
        setPwType("text");
        setShowText("HideEyeIcon")
      }else{
        setPwType("password");
        setShowText("ShowEyeIcon")
      }
    }

    return (<Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        password: "",
        confirmPassword:""
      }}
      onSubmit={(values, actions) => {
        console.log("val- reset", values);
        dispatch(resetPasswordAction({
          password:values?.password
        }, (res)=>{
          console.log("res reset", res);
          dispatch(handleLoginSuccess(props?.validationData));
          Navigate("/home");
          storeDataToAsyncStorage("_user", props?.validationData);
        }))
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} >
            {/* <FormGroup className="formPosition">
                <div className="Regular text3 greyFont">Welcome! 👋</div>
                <div className="heading1 semiBold">Sign in to resent password</div>
            </FormGroup> */}
            <FormGroup className="formPosition" >
            <div className="Regular text3 padding24T headText2 flexStyling">
                <div>Enter new password</div>
                <div
                  className=" padding0R padding8L Tooltip"
                  style={{ textAlign: "right" }}
                >
                  <SvgImage name="infoIcon" width={18} height={18}  />
                  <span className="tooltiptext" style={{marginLeft:"1rem"}}>Password should be atleast 8 characters long and must contain one alphabet and one number. No special character is allowed.</span>
                </div>
            </div>
            <div className="padding16 padding0L padding8T padding8B" style={{paddingLeft:"0rem"}}>
              <div className=" phoneNumberBox padding8 flexStyling">
                  <input
                    type={pwType}
                    id="password"
                    className="input-body-4 Regular text3"
                    name="password"
                    value={values.password}
                    touched={touched.password}
                    errors={errors.password}
                    onBlur={()=>{
                      dispatch(sendAnalitics("EnterPhone_web", values));
                      handleBlur("password");
                    }}
                    onChange={handleChange("password")}
                    style={{
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "84%",
                    }}
                    placeholder="Enter new password"
                    // minLength="8"
                    autofocus
                  ></input>
                  <div className="greenFont semiBold showPW1" onClick={togglePassword}><SvgImage name={showText} /></div>
              </div>
            </div>
            <div
              className="text4"
              style={{
                // margin: "7px 0 0px 50px",
                color: "red",
                textAlign: "left",
              }}
            >
              {errors.password && touched.password ? (
                <div>{errors.password}</div>
              ) : null}
            </div>
            </FormGroup>
            <FormGroup className="formPosition">
            <div className="Regular text3 headText2">
                Confirm new password
            </div>
            <div className="padding16 padding0L padding8T padding8B" style={{paddingLeft:"0rem"}}>
              <div className=" phoneNumberBox padding8">
                  <input
                    type="password"
                    id="confirmPassword"
                    className="input-body-4 Regular text3"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    touched={touched.confirmPassword}
                    errors={errors.confirmPassword}
                    onBlur={()=>{
                      dispatch(sendAnalitics("EnterPhone_web", values));
                      handleBlur("confirmPassword");
                    }}
                    onChange={handleChange("confirmPassword")}
                    style={{
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "80%",
                    }}
                    placeholder="Confirm new password"
                    // minLength="8"
                    autofocus
                  ></input>
               
              </div>
            </div>
            <div
              className="text4"
              style={{
                // margin: "7px 0 0px 50px",
                color: "red",
                textAlign: "left",
              }}
            >
              {errors.confirmPassword && touched.confirmPassword ? (
                <div>{errors.confirmPassword}</div>
              ) : null}
            </div>
            </FormGroup>
            <FormGroup className="formPosition">
            <Button
              type="submit"
              className="loginButton semiBold text2"
              style={{
                marginTop: "1rem",
                background:
                  // values.password.length < 8
                  //   ? "#EFEFEF":
                     loader
                    ? "#EFEFEF"
                    : "#2e7c7b",
                color:
                  // values.password.length < 8
                  //   ? "#B5B5B5":
                    loader
                    ? "#B5B5B5"
                    : "#ffffff",
              }}
              disabled={loader ? true : false}
            >
              {loader ? "Please Wait" : "Reset Password"}
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>)
  }

export default ResetPasswordForm;