import React, { useEffect, useState } from "react";
import SvgImage from "../../../../Components/Icons/Icons";
import "./index.css";
import { Button, Form, FormGroup } from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { sendOtp } from "../../../../services/Store/Actions/LoginAction";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import {
  businessDetailsAction,
  cleanOnboardingLoaderAction,
} from "../../../../services/Store/Actions/onboardingAction";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";

const BusinessDocs = (props) => {
  const validationSchema = Yup.object().shape({
    gstNum: Yup.string()
      .required("GST number is mandatory")
      .matches(
        /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
        "GST number is incorrect. Please enter again"
      ),
    gstPhotoUrl: Yup.string().required("GST Photo is mandatory"),
  });

  const { loader } = useSelector((state) => state?.onboarding);
  const dispatch = useDispatch();

  const [gstImg, setGstImg] = useState(false);
  const [displayName, setDisplayName] = useState([]);
  const { truckNum, busType, busName, setRegStatus } = props;
  useEffect(() => {
    dispatch(sendAnalitics("BusinessDocsOnboarding_web"));
    dispatch(cleanOnboardingLoaderAction());
  }, []);

  const handleSkipSubmit = () => {
    dispatch(
      businessDetailsAction(
        {
          businessName: busName.trim(),
          businessType: busType.trim(),
          noOfFleets: truckNum.trim(),
          gstCertificate: "",
          gstImage: "",
        },
        (res) => {
          console.log("SUCCESS", res);
          if (res?.data?.type === "success") {
            console.log("SUCCESS otp send");
            let data1 = sessionStorage.getItem("_user");
            let data2 = sessionStorage.getItem("_userInfo");
            if (data1) {
              let userSession = JSON.parse(data1);
              let userObj = {
                user: res?.data?.data?.user,
                company: res?.data?.data?.company,
                refreshToken: userSession?.refreshToken,
                token: userSession?.token,
                owner: userSession?.owner,
              };
              storeDataToAsyncStorage("_user", userObj);
            }
            if (data2) {
              let userSession = JSON.parse(data2);
              let userObj = {
                user: res?.data?.data?.user,
                company: res?.data?.data?.company,
                refreshToken: userSession?.refreshToken,
                token: userSession?.token,
                owner: userSession?.owner,
              };
              storeDataToAsyncStorage("_userInfo", userObj);
            }
            setRegStatus(4);
          }
        }
      )
    );
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        gstNum: "",
        gstPhotoUrl: "",
      }}
      onSubmit={(values, actions) => {
        //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
        console.log("valuessss", values);
        //dispatch(sendAnalitics("GetOTP_web", values));
        dispatch(
          businessDetailsAction(
            {
              businessName: busName.trim(),
              businessType: busType.trim(),
              noOfFleets: truckNum,
              gstCertificate: values.gstNum.trim(),
              gstImage: values.gstPhotoUrl.trim(),
            },
            (res) => {
              //actions.setSubmitting(false);
              console.log("SUCCESS", res);
              if (res?.data?.type === "success") {
                console.log("SUCCESS otp send");
                let data1 = sessionStorage.getItem("_user");
                let data2 = sessionStorage.getItem("_userInfo");
                if (data1) {
                  let userSession = JSON.parse(data1);
                  let userObj = {
                    user: res?.data?.data?.user,
                    company: res?.data?.data?.company,
                    refreshToken: userSession?.refreshToken,
                    token: userSession?.token,
                    owner: userSession?.owner,
                  };
                  storeDataToAsyncStorage("_user", userObj);
                }
                if (data2) {
                  let userSession = JSON.parse(data2);
                  let userObj = {
                    user: res?.data?.data?.user,
                    company: res?.data?.data?.company,
                    refreshToken: userSession?.refreshToken,
                    token: userSession?.token,
                    owner: userSession?.owner,
                  };
                  storeDataToAsyncStorage("_userInfo", userObj);
                }
                setRegStatus(4);
              }
            }
          )
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} className="InputForm2">
          <FormGroup className="formPosition">
            <div className="heading1 semiBold">
              Business Documents
              <span
                className="skipText heading2"
                style={{ marginLeft: "5rem" }}
                onClick={() => {
                  // setRegStatus(4);
                  handleSkipSubmit();
                }}
              >
                Skip
              </span>
            </div>
            <div className="Regular text3 padding24T headText2">GST Number</div>
            <div className="padding8 padding0L">
              <div className="phoneNumberBox padding8">
                {/* <div
                      className="col-2 padding0L boxWithBorder"
                      style={{ textAlign: "center" }}
                    >
                      <SvgImage name="countryCode" />
                    </div> */}
                <input
                  type="text"
                  id="gstNum"
                  className="input-body-4 Regular text3"
                  name="gstNum"
                  value={values.gstNum}
                  touched={touched.gstNum}
                  errors={errors.gstNum}
                  onBlur={() => {
                    //   dispatch(sendAnalitics("EnterPhone_web", values));
                    handleBlur("gstNum");
                  }}
                  onChange={handleChange("gstNum")}
                  style={{
                    textAlign: "left",
                    marginLeft: "0px",
                    width: "100%",
                  }}
                  placeholder="Enter GST number"
                  autofocus
                ></input>
              </div>
            </div>
            <div
              className="text4"
              style={{
                // margin: "7px 0 0px 50px",
                color: "red",
                textAlign: "left",
              }}
            >
              {errors.gstNum && touched.gstNum ? (
                <div>{errors.gstNum}</div>
              ) : null}
            </div>
            <div
              className="Regular text3 padding8T headText2"
              style={{ color: "#2E7C7B" }}
            >
              Upload GST photo
            </div>
            <div>
              {!gstImg ? (
                <button
                  className="add-attach-for-fastag-linked-vehicle"
                  //onClick={fileUpload}
                  type="button"
                  style={{ cursor: "pointer", top: "1rem" }}
                >
                  <SvgImage
                    name="AddAttachment"
                    height="36"
                    width="36"
                    classname="attach-logo"
                  />
                  <input
                    type="file"
                    // multiple
                    accept="image/*"
                    className="add-attachment-btn"
                    onChange={async (e) => {
                      //e.preventDefault();
                      setGstImg(true);
                      const img1 = e?.target.files[0];
                      console.log("image", img1?.name);
                      setDisplayName(
                        (prev) => [{ name: img1?.name }]
                        //  [
                        //   ...prev,
                        //   { name: img?.name },
                        // ]
                      );

                      const tempArray = Object.assign([], values.docs);
                      console.log("sel", tempArray);

                      if (img1 == null) {
                        const findIndex = values.docs.findIndex(
                          (e) => e == values?.pickedImage
                        );

                        if (findIndex !== -1) {
                          tempArray.splice(findIndex, 1);
                          setFieldValue("gstNum", tempArray, true);
                          // setFieldValue("pickedImage", "", true);
                        }
                      } else {
                        console.log("upload", {
                          img: img1,
                          uploadType: "aadharFront",
                          isRes: true,
                        });

                        const obj = {
                          img: img1,
                          uploadType: "aadharFront",
                          isRes: true,
                        };
                        const img = obj?.img;
                        const isRes = obj?.isRes;
                        const body = new FormData();
                        body.append("file", img);

                        if (isRes) {
                          let token = sessionStorage.getItem("_token");
                          token = JSON.parse(token);
                          console.log("totottotoken", token);
                          if (!token) {
                            token = sessionStorage.getItem("_refreshToken");
                          }
                          const respp = await fetch(
                            "https://aks-api.logipe.com/api/api/v1/media/upload",
                            {
                              method: "POST",
                              headers: {
                                // "Content-Type": "multipart/form-data",
                                otherHeader: "foo",
                                "x-access-token": token,
                                requesttype: "newRegistration",
                              },
                              body: body,
                            }
                          );
                          const res = await respp.json();
                          tempArray.push(res?.data?.data?.Location);
                          // setFieldValue("pickedImage", res?.data?.url, true);
                          setFieldValue(
                            "gstPhotoUrl",
                            res?.data?.data?.Location,
                            true
                          );
                          // console.log("doneeee1",res?.data?.data?.Location);
                        }
                      }
                    }}
                  />
                </button>
              ) : (
                <></>
              )}
            </div>
            {gstImg == false ? (
              <div className="Regular text3 padding24T headText2">
                Upload format must be in jpg, png, jpeg & pdf
              </div>
            ) : (
              displayName?.map((d) => {
                return (
                  <div className="row padding16L ">
                    <div
                      className="col-9 Regular text3 "
                      style={{
                        paddingTop: "0.5rem",
                        textAlign: "left",
                        paddingLeft: "0",
                      }}
                    >
                      <p
                        style={{
                          color: "black",
                        }}
                      >
                        {d.name}
                      </p>
                    </div>
                    <div
                      className="col-2"
                      onClick={(e) => {
                        setGstImg(false);
                        setFieldValue("gstPhotoUrl", "", true);
                        // e.preventDefault();
                      }}
                      style={{ paddingTop: "0.3rem", textAlign: "right" }}
                    >
                      <SvgImage name="CloseButtonLogo" />
                    </div>
                  </div>
                );
              })
            )}

            <div
              className="text4"
              style={{
                // margin: "7px 0 0px 50px",
                color: "red",
                textAlign: "left",
              }}
            >
              {errors.gstPhotoUrl && touched.gstPhotoUrl ? (
                <div>{errors.gstPhotoUrl}</div>
              ) : null}
            </div>
            <Button
              type="submit"
              className="loginButton semiBold text2"
              style={{
                marginTop: "1rem",
                // background:"#2e7c7b",
                // color:"#ffffff"
                background:
                  values.gstNum == ""
                    ? "#EFEFEF"
                    : values.gstPhotoUrl == ""
                    ? "#EFEFEF"
                    : loader
                    ? "#EFEFEF"
                    : "#2e7c7b",
                color:
                  values.gstNum == ""
                    ? "#B5B5B5"
                    : values.gstPhotoUrl == ""
                    ? "#B5B5B5"
                    : loader
                    ? "#B5B5B5"
                    : "#ffffff",
              }}
              disabled={loader ? true : false}
            >
              {loader ? "Please Wait" : "Submit"}
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessDocs;
