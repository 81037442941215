import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  NavLink,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { bankTransferBeneficiaryAdd, cleanBeneficiaryLoader } from "../../../services/Store/Actions/beneficiaryAction";
import { cleanMasterWalletLoader, linkBankAccountToLogipe } from "../../../services/Store/Actions/masterWalletActions";
//import Toast from "../../subComponents/toast";
import { toastStatus } from "../../../services/Store/Actions/appActions";
import { driverAddBeneficiaryAction, getDriverBeneficiaryAction } from "../../../services/Store/Actions/omcActions";

function AddNewAccount(props) {
  const { open, toggleModal, title, handleClick, handleClose, btn, caller, newToggleModal } = props;
  const navigate = useNavigate();
  
  const dispatch = useDispatch();

  const loaderBeneficiaryAdd = useSelector((state) => state?.beneficiary?.loader);
  const loaderLinkBank  = useSelector((state) => state?.masterWallet?.loader);
  console.log("loader loaderBeneficiaryAdd", loaderBeneficiaryAdd);
  console.log("loader loaderLinkBank", loaderLinkBank);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Required"),
    acNo: Yup.number().typeError("Only Numbers are allowed").required("Account number must contain atleast 8 digits"),
    bankAccountNumber: Yup.number().typeError("Only Numbers are allowed").oneOf([Yup.ref('acNo'), null], "Does not match the above account number").required("Account number must contain atleast 8 digits"),
    // ifsc: Yup.string().required("Bank IFSC must contain 11 digits").matches(/^[a-zA-Z0-9]+$/, "Enter correct Bank IFSC code"),
  });

  // styling and variables for adding toast
  // const [ toastOpen, setToastOpen] = useState(false);
  // const [successStatus, setSuccessStatus] = useState (false);
  // const [toastMsg, setToastMsg]= useState("");
  // const toggleToastModal=()=>{
  //   setToastOpen(!toastOpen);
  // }
  
  // const handleToastClose = () => {
  //   setToastOpen(false);
  //   //handleClose();
  //   toggleModal();
  //   if(caller!=="neftBankLink")
  //     newToggleModal();
  //   console.log("closed");
  // };
  
  // const handleToastShow = (a) => {  
  //   setToastOpen(true);
  //   console.log(a);
  // };

  useEffect(()=>{
    dispatch(cleanBeneficiaryLoader());
    dispatch(cleanMasterWalletLoader());
  },[])
  
  const {id}=useParams();
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-content" style={{width:"530px", overflowY:"scroll", overflowX:"hidden"}} >
        <div className="modal-head-part">
          <p className="Add-balance-title">{caller=="neftBankLink"?"Add Account Details":caller=="driverBeneficiary"?"Add New Account Details":"Add New Account Details"}</p>
          <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              fullName: "",
              acNo: "",
              bankAccountNumber: "",
              ifsc: "",
            }}
            onSubmit={(values, actions) => {
              console.log("values onSubmit", values);
              const record2 = {
                bankAccountNumber: values.bankAccountNumber,
                ifsc: values.ifsc,
                accountHolderName:values.fullName
              };
              const record = {
                fullName: values.fullName,
                bankAccountNumber: values.bankAccountNumber,
                ifsc: values.ifsc,
              };
              caller=="beneficiary"? 
              (dispatch(
                bankTransferBeneficiaryAdd(record, (data) => {
                  actions.setSubmitting(false);
                  if (data?.type == "success") {
                    //alert("successfully");
                    dispatch(
                      toastStatus({
                        toastStatus: true,
                        message: "Beneficiary Added Successfully",
                        status: true,
                      })
                    );
                    toggleModal();
                    newToggleModal();
                  }
                  else{
                    dispatch(
                      toastStatus({
                        toastStatus: true,
                        message:"Sorry, Failed to Add Beneficiary",
                        status: false,
                      })
                    );
                    toggleModal();
                    newToggleModal();
                  }
                })
              ))
              :caller=="neftBankLink"?
              (dispatch (linkBankAccountToLogipe(record2, (data) => {
                console.log ("success response 1", data)
                  actions.setSubmitting(false);
                  if (data?.data?.type == "success") {
                    //alert("successfully");
                    dispatch(
                      toastStatus({
                        toastStatus: true,
                        message: "Bank Acount linked Successfully",
                        status: true,
                      })
                    );
                    toggleModal();
                    console.log ("success response 2")
                    //toggleModal();
                  }
                  else{
                    dispatch(
                      toastStatus({
                        toastStatus: true,
                        message:"Sorry, Failed to link bank acount",
                        status: false,
                      })
                    );
                    toggleModal();
                  }
                })))
                :(
                  dispatch(driverAddBeneficiaryAction({
                    "beneficiaryName": values.fullName,
                    "accountNumber": values.bankAccountNumber,
                    "ifsc": values.ifsc,
                    "userId": id,
                    "vpa": "any"
                  },(res)=>{
                      console.log("res", res)
                      if(res?.status==200){
                        dispatch(getDriverBeneficiaryAction({
                          offset: 0,
                          search: "",
                          limit:10
                        }))
                        toggleModal();
                        newToggleModal();
                      }
                    }))
                )
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">1</span>
                    <label
                      htmlFor="acNo"
                      className="sub-title-edit-vehicle-detial "
                    >
                      Bank Account Number
                    </label>
                  </div>
                  <input
                    type="text"
                    id="acNo"
                    className="input-body-3"
                    name="acNo"
                    onChange={handleChange("acNo")}
                    value={values.acNo}
                    onBlur={handleBlur("acNo")}
                    touched={touched.acNo}
                    errors={errors.acNo}
                    placeholder="Enter account number"
                    maxlength = "16"
                    minLength="8"
                  />
                  <div className="input-amount-for-link"></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.acNo && touched.acNo ? (
                      <div>*{errors.acNo}</div>
                    ) : null}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">2</span>
                    <label
                      htmlFor="bankAccountNumber"
                      className="sub-title-edit-vehicle-detial "
                    >
                      Re-enter Bank Account Number
                    </label>
                  </div>

                  <input
                    type="text"
                    id="bankAccountNumber"
                    className="input-body-3"
                    name="bankAccountNumber"
                    onChange={handleChange("bankAccountNumber")}
                    value={values.bankAccountNumber}
                    onBlur={handleBlur("bankAccountNumber")}
                    touched={touched.bankAccountNumber}
                    errors={errors.bankAccountNumber}
                    placeholder="Re-enter account number"
                    maxlength = "16"
                    minLength="8"
                  />
                  <div className="input-amount-for-link"></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.bankAccountNumber && touched.bankAccountNumber ? (
                          <div>*{errors.bankAccountNumber}</div>
                        ) : null}
                      </div>
                </FormGroup>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">3</span>
                    <label
                      htmlFor="ifsc"
                      className="sub-title-edit-vehicle-detial "
                    >
                      Bank IFSC
                    </label>
                  </div>

                  <input
                    type="text"
                    id="ifsc"
                    className="input-body-3"
                    name="ifsc"
                    onChange={handleChange("ifsc")}
                    value={values.ifsc}
                    onBlur={handleBlur("ifsc")}
                    touched={touched.ifsc}
                    errors={errors.ifsc}
                    placeholder="Enter Bank IFSC"
                    maxLength="11"
                    minLength="11"
                  />
                  <div className="input-amount-for-link"></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.ifsc && touched.ifsc ? (
                          <div>*{errors.ifsc}</div>
                        ) : null}
                      </div>
                </FormGroup>

                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">4</span>
                    <label
                      htmlFor="fullName"
                      className="sub-title-edit-vehicle-detial "
                    >
                      Account Holder Name
                    </label>
                  </div>

                  <input
                    type="text"
                    id="fullName"
                    className="input-body-3"
                    name="fullName"
                    onChange={handleChange("fullName")}
                    value={values.fullName}
                    onBlur={handleBlur("fullName")}
                    touched={touched.fullName}
                    errors={errors.fullName}
                    placeholder="Enter account holder name"
                  />
                  <div className="input-amount-for-link"></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.fullName && touched.fullName ? (
                          <div>*{errors.fullName}</div>
                        ) : null}
                      </div>

                  <Button
                    type="submit"
                    className="butt-4"
                    style={{ marginTop: "90px" }}
                    disabled={caller!="neftBankLink"?(loaderBeneficiaryAdd ? true : false):(loaderLinkBank ? true : false)}
                  >
                    {caller!="neftBankLink"?(loaderBeneficiaryAdd ? "Please Wait" : "Add New Account"):(loaderLinkBank ? "Please Wait" : "Add New Account")}
                    {/* {loader ? "Please Wait" : "Transfer To Account"} */}
                    
                  </Button>
                  {
                    // <Toast success={successStatus}
                    // message={toastMsg}
                    // isOpen={toastOpen}
                    // onHide={handleToastClose}
                    // toggle={toggleToastModal}
                    // handleClose={handleToastClose}
                    // />
                  }
                </FormGroup>
                <div></div>
              </Form>
            )}
          </Formik>
          {/* <div className="space-div"></div> */}
        </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default AddNewAccount;
