import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import SvgImage from "../../Icons/Icons";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "./index.css";

import { useDispatch } from "react-redux";
import {
  addMoney,
  expenseSumm,
  getAdvanceDriverList,
  getExpenseWalletSummary,
} from "../../../services/Store/Actions/driverslistActions";
import moment from "moment";
// import { allCardsBalance } from "../../../services/Store/Actions/cardWalletAction";
import { getMasterWalletBalance } from "../../../services/Store/Actions/masterWalletActions";
import { fetchBalance } from "../../../services/Store/Actions/manageCardActions";
import { transferToCard } from "../../../services/Store/Actions/manageCardActions";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";
import { getCardRequest, updateCardRequest } from "../../../services/Store/Actions/cardWalletAction";
const ManageDriverAddBalance = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    toggleModal,
    handleClose,
    name,
    userId,
    driverName,
    remarks,
    amount,
    itemTemp,
    status,
    tripKey,
    search,
    offset,
    apiCall,
    requestId
  } = props;

  useEffect(() => {
    dispatch(fetchBalance());
  }, [userId]);
  const { loader } = useSelector((state) => state?.manageCard);
  const { ownerBalance } = useSelector((state) => state?.manageCard); //LOgipe CARD BALANCE

  const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == null
      ? 0
      : ownerBalance?.instrument_balance?.balance;
  // useEffect(() => {}, [props]);
  console.log("ManageDriverAddBalance", props);

  // useEffect(() => {}, [userId]);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">{name}</p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                if (!loader) {
                  toggleModal();
                }
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="card-wallet-icon-body2">
              <SvgImage
                name="cardWalletLogo"
                height="43.81"
                width="43.81"
                classname="card-wallet-logo-BankLogo"
              />
              <div>
                <p className="body-sub-part1-ab">Master Account Balance</p>
                <p className="body-sub-part2-ab">₹{LogipeAccounBal}</p>
              </div>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                amount: amount || "",
                pin: "",
                driverName: driverName,
                remark: remarks ? remarks : "",
                tripId: itemTemp?.tripNo || "",
              }}
              validationSchema={Yup.object().shape({
                amount: Yup.number()
                  .typeError("Only Numbers are allowed")
                  .min(1, "Amount should be greater than 1")
                  .required("Amount is required"),
                pin: Yup.string()
                  .required("* Required")
                  .matches(/^[0-9]{4}$/, "* Pin Must be exactly 4 digits"),
                // dob: Yup.string()
                //   .required('Expense Date is required'),
                // password: Yup.string()
                //   .min(6, 'Password must be at least 6 characters')
                //   .required('Password is required'),
                // confirmPassword: Yup.string()
                //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
                //   .required('Confirm Password is required')
              })}
              //validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                // values.bookingDate = moment(values.bookingDate).format(
                //   "DD-MM-YYYY"
                // );
                //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
                if (userId) {
                  dispatch(
                    sendAnalitics("Add_Bal_DriverCard_API_Web", {
                      amt: values.amount,
                      pin: values.pin,
                      remark: values.remark,
                      userId: userId,
                    })
                  );
                  console.log("valuessss", props.cardType);
                  if (props.cardType === "1") {
                    let payload = { ...values};
                    if (status) {
                      let qqq = status == 1 ? "Settle" : "advance";
                      payload["sattlementStatus"] = qqq;
                    }
                    if (itemTemp?.tripId) {
                      payload["tripId"] = itemTemp?.tripId;
                    }
                    if (tripKey && tripKey != "") {
                      payload["tripKey"] = tripKey;
                    }
                    dispatch(
                      transferToCard(payload, userId, (res) => {
                        actions.setSubmitting(false);
                        dispatch(
                          sendAnalitics("Success_Add_Bal_DriverCard_Web", {
                            amt: values.amount,
                            pin: values.pin,
                            remark: values.remark,
                            userId: userId,
                          })
                        );
                        if (res?.status == 200) {
                          console.log("successfulsuccessfulsuccessful");
                          //navigate("/Customers")
                          dispatch(getExpenseWalletSummary(userId));
                          // window.location.reload();
                          toggleModal();
                          if(apiCall){
                            dispatch(updateCardRequest({
                              requestId:requestId,
                              status:2,
                              obj:{
                                "amountPaid":amount
                              }
                            },(res)=>{
                              console.log("svhagfhgsvhssghv",res)
                              dispatch(getCardRequest({},()=>{}))
                              
                            }))
                          }
                          dispatch(
                            getAdvanceDriverList({
                              search: search,
                              offset: offset,
                              search: '',
                              minKyc: "",
                              sortAs: "",
                              sortBy: "",
                              activeStatus:1,
                              assignedStatus:3
                            })
                          );
                        }
                      })
                    );
                  } else {
                    let finalData = {
                      amount: values?.amount,
                      pin: values?.pin,
                      remark: values?.remark,
                      walletCategory: 2,
                    };
                    if (itemTemp?.tripId) {
                      finalData["tripId"] = itemTemp?.tripId;
                    }
                    if (values?.otherRemarks) {
                      finalData["otherRemarks"] = values?.otherRemarks;
                    }
                    if (status) {
                      let qqq = status == 1 ? "Settle" : "advance";
                      finalData["sattlementStatus"] = qqq;
                    }
                    if (tripKey && tripKey != "") {
                      finalData["tripKey"] = tripKey;
                    }
                    dispatch(
                      transferToCard(finalData, userId, (res) => {
                        actions.setSubmitting(false);
                        dispatch(
                          sendAnalitics("Success_Add_Bal_DriverCard_Web", {
                            amt: values.amount,
                            pin: values.pin,
                            remark: values.remark,
                            userId: userId,
                          })
                        );
                        if (res?.status == 200) {
                          console.log("successfulddbdhbdhbd");
                          //navigate("/Customers")
                          if(apiCall){
                            dispatch(updateCardRequest({
                              requestId:requestId,
                              status:2,
                              obj:{
                                "amountPaid":amount
                              }
                            },(res)=>{
                              console.log("svhagfhgsvhssghv",res)
                              dispatch(getCardRequest({},()=>{}))
                              
                            }))
                          }
                          dispatch(getExpenseWalletSummary(userId));
                          // window.location.reload();
                          toggleModal();
                          dispatch(
                            getAdvanceDriverList({
                              search: search,
                              offset: offset,
                              search: '',
                              minKyc: "",
                              sortAs: "",
                              sortBy: "",
                              activeStatus:1,
                              assignedStatus:3
                            })
                          );
                        }
                      })
                    );
                  }
                } else {
                  toast.error("please select user again.");
                }

                //   // addMoney(values, userId, (res) => {
                //   //   actions.setSubmitting(false);
                //   //   if (res?.data?.type === "success") {
                //   //     console.log("successful");
                //   //     //navigate("/Customers")
                //   //     dispatch(expenseSumm(userId));
                //   //     toggleModal();
                //   //   }
                //   // })
                // );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <p className="sub-head-3">Enter amount</p>
                    </div>
                    <p className="sub-head-body">
                      Amount will be deducted from owner’s LogiPe account
                    </p>

                    <input
                      type="text"
                      id="html"
                      className={
                        "input-body-1 form-control" +
                        (errors.amount && touched.amount ? " is-invalid" : "")
                      }
                      name="amount"
                      value={values.amount}
                      touched={touched.amount}
                      errors={errors.amount}
                      onBlur={handleBlur("amount")}
                      onChange={handleChange("amount")}
                    ></input>
                    <div className="input-amount"></div>
                    <ErrorMessage
                      name="amount"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-head-3">Enter remarks</p>
                    </div>
                    <p className="sub-head-body">
                      Enter the the remarks/comments
                    </p>

                    <input
                      type="text"
                      id="html"
                      className={
                        "input-body-1 form-control" +
                        (errors.remark && touched.remark ? " is-invalid" : "")
                      }
                      name="remark"
                      value={values.remark}
                      touched={touched.remark}
                      errors={errors.remark}
                      onBlur={handleBlur("remark")}
                      onChange={handleChange("remark")}
                    ></input>
                    <div className="input-amount"></div>
                    <ErrorMessage
                      name="remark"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">3</span>
                      <p className="sub-head-3">Driver Name</p>
                    </div>
                    {/* <p className="sub-head-body">
                      Enter the the remarks/comments
                    </p> */}

                    <input
                      type="text"
                      id="html"
                      className={
                        "input-body-1 form-control" +
                        (errors.driverName && touched.driverName
                          ? " is-invalid"
                          : "")
                      }
                      name="driverName"
                      value={values.driverName}
                      touched={touched.driverName}
                      errors={errors.driverName}
                      onBlur={handleBlur("driverName")}
                      onChange={handleChange("driverName")}
                    ></input>
                    <div className="input-amount"></div>
                    <ErrorMessage
                      name="driverName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">4</span>
                      <p className="sub-head-3">TripId (Optional)</p>
                    </div>
                    {/* <p className="sub-head-body">
                      Enter the the remarks/comments
                    </p> */}

                    <input
                      type="text"
                      id="html"
                      className={
                        "input-body-1 form-control" +
                        (errors.tripId && touched.tripId ? " is-invalid" : "")
                      }
                      name="tripId"
                      value={values.tripId}
                      touched={touched.tripId}
                      errors={errors.tripId}
                      onBlur={handleBlur("tripId")}
                      onChange={handleChange("tripId")}
                    ></input>
                    <div className="input-amount"></div>
                    <ErrorMessage
                      name="tripId"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">5</span>
                      <p className="sub-head-4">Enter 4-digit Security Pin</p>
                    </div>
                    <p className="sub-head-body">
                      Security pin is needed to confirm payment
                    </p>
                    <PinInput
                      name="pin"
                      length={4}
                      initialValue=""
                      secret
                      onChange={(value, index) =>
                        setFieldValue("pin", value, true)
                      }
                      type="numeric"
                      inputMode="number"
                      style={{
                        padding: "10px",
                        marginLeft: "96px",
                        marginTop: "28px",
                      }}
                      inputStyle={{
                        border: "1px solid #A7A7A7",
                        borderRadius: "8px",
                        marginRight: "20px",
                      }}
                      onComplete={(value, index) => {}}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    <div
                      style={{
                        color: "#dc3545",
                        fontSize: "14px",
                        marginLeft: "6.5rem",
                      }}
                      className={
                        +(errors.pin && touched.pin ? " is-invalid" : "")
                      }
                    >
                      {" "}
                      <ErrorMessage className="is-invalid" name="pin" />
                    </div>

                    <Button
                      type="submit"
                      className="butt4"
                      disabled={loader ? true : false}
                      onClick={() => {
                        dispatch(
                          sendAnalitics("Add_Bal_DriverCard_fromMA_Web", {
                            amt: values.amount,
                            pin: values.pin,
                            remark: values.remark,
                            userId: userId,
                          })
                        );
                      }}
                    >
                      {loader ? "Please Wait" : "Add Card Balance"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>

            {/* <div className="space-div"></div> */}
          </ModalBody>
        </div>
        <ToastContainer
          theme="light"
          position="top-right"
          closeOnClick
          pauseOnHover
          autoClose={4000}
        />
      </Modal>
    </div>
  );
};
export default ManageDriverAddBalance;
