import React, { useEffect, useRef, useState } from "react";
import RouterButton from "../../../Subcomponent/RouterButton/RouterButton";
import { NavLink, Link, useLocation } from "react-router-dom";
import "./index.css";
import { useParams } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import RouterButtonV2 from "../../../Subcomponent/RouterButtonV2/RouterButtonV2";
import RouterButtonV3 from "../../../Subcomponent/RouterButtonV3/RouterButtonV3";
import { useSelector } from "react-redux";
import SvgImage from "../../Icons/Icons";

function SideBar(props) {
  const [act, setAct] = useState();
  const [windowHeight, setWindowheight] = useState(window.innerHeight);
  const [open, setOpen] = useState(false);
  const { sideBar, setSideBar, act2, setAct2, authUser } = props;
  const { managerControlList } = useSelector((state) => state?.userManagement);

  const [isTrips, setIsTrips] = useState(0);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  const [isFuelWallet, setIsisFuelWallet] = useState(0);
  const [isGps, setIsGps] = useState(0);

  useEffect(() => {
    console.log(
      "useuseEffectEuseEffectffect",
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
    setIsisFuelWallet(
      authUser?.company?.fuelWallet && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.fuelWallet && managerControlList?.omc
        ? managerControlList?.omc
        : 0
    );
    setIsGps(
      authUser?.company?.customFeatures?.includes(7)
        ? 1
        : 0
    );
  }, [authUser, managerControlList]);
  let location = useLocation();
  let ref = useRef();

  useEffect(() => {
    console.log("pathname location in sidebar", location.pathname);

    let parameter = location.pathname?.split("/")[1];
    console.log("split in sidebar", parameter);
    parameter === "vehicles"
      ? setAct(5)
      : parameter === "fuelMain"
      ? setAct(17)
      : parameter === "LogipeCard"
      ? setAct(2)
      : parameter === "Customers"
      ? setAct(3)
      : parameter === "Drivers"
      ? setAct(4)
      : parameter === "logimart"
      ? setAct(6)
      : parameter === "trips"
      ? setAct(7)
      : parameter === "vendor"
      ? setAct(10)
      : parameter === "report"
      ? setAct(8)
      : parameter == "salary"
      ? setAct(9)
      : parameter == "tracker"
      ? setAct(11)
      : parameter == "manager"
      ? setAct(12)
      : parameter == "lr"
      ? setAct(13)
      : parameter == "DriversV2"
      ? setAct(14)
      : parameter == "Branches"
      ? setAct(19)
      : parameter == "VehiclesV2"
      ? setAct(15) : parameter == "Routes"
      ? setAct(18) 
      : parameter == "GPS"
      ? setAct(20) : setAct(1);
  }, [location.pathname]);

  useEffect(() => {
    console.log("act value in sidebar", act);
  }, [act]);
  //console.log("path",lcn);

  function isRestricted(title) {
    return authUser?.user?.type == 3 && !managerControlList?.[title];
  }

  useEffect(() => {
    console.log("act value in sidebar", act);
  }, [act]);
  useEffect(() => {
    function handleResize() {
      setWindowheight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const sidebarRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const sidebar = sidebarRef.current;
      const offsetTop = sidebar.offsetTop;

      // if (window.scrollY > offsetTop) {
      //   setIsFixed(true);
      // } else {
      //   setIsFixed(false);
      // }

      // Check if the user has scrolled to the bottom of the page
      const isBottom =
        window.innerHeight + window.scrollY - 100 >= ref.current.clientHeight;
      // console.log(
      //   "sadfg",
      //   isBottom,
      //   ref.current.clientHeight > window.innerHeight,
      //   window.innerHeight + window.scrollY - 100,
      //   ref.current.clientHeight,
      //   window.innerHeight - ref.current.clientHeight - 70
      // );
      if (ref.current.clientHeight > window.innerHeight && isBottom) {
        sidebarRef.current.style.position = "sticky";
        sidebarRef.current.style.top = `${
          window.innerHeight - ref.current.clientHeight
        }px`;
      } else if (ref.current.clientHeight <= window.innerHeight) {
        sidebarRef.current.style.position = "sticky";
        sidebarRef.current.style.top = `${70}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const closeSideBar = () => {
    setSideBar(!sideBar);
  };
  return (
    <div ref={sidebarRef}>
      <div>
        <div
          ref={ref}
          className="side-bar col width90"
          style={
            {
              // width: sideBar ? "215px" : "90px",
            }
          }
          onMouseEnter={() => {
            setSideBar(true);
            ref.current.style.width = "215px";
          }}
          // onMouseLeave={() => {
          //   setSideBar(false);
          //   ref.current.style.width = "90px";
          // }}
        >
          <div>
            <button
              className="showMenu"
              onClick={() => {
                setSideBar(!sideBar);
                if (sideBar) {
                  ref.current.style.width = "90px";
                } else {
                  ref.current.style.width = "215px";
                }
              }}
              style={{ backgroundColor: "#fff" }}
            >
              <SvgImage
                //   height="400"
                name={sideBar ? "crossIcon" : "menuIcon"}
              />
            </button>
          </div>

          {/* <Menu onOpen={handleIsOpen} onClose={handleIsOpen} isOpen={open} > */}
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="HomeLogo"
            link="home"
            Title="Homepage"
            active={act === 1 ? true : false}
          />
          {/* <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="salary"
            link="salary"
            Title="Salary"
            isRestricted={isRestricted("salaryWallet")}
            active={act === 9 ? true : false}
          /> */}
          {/* <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="ManageLogiPeCardLogo"
            link="LogipeCard"
            Title="Card"
            isRestricted={isRestricted("wallet")}
            active={act === 2 ? true : false}
          /> */}
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="ManageCustomerLogo"
            link="Customers"
            Title="Customers"
            isRestricted={isRestricted("customer")}
            active={act === 3 ? true : false}
            disable={!isCustomers}
          />
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="ManageCustomerLogo"
            link="GPS"
            Title="GPS"
            // isRestricted={isRestricted("customer")}
            active={act === 20 ? true : false}
            disable={!isGps}
          />
          
          {/* <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="ManageDriverLogo"
            link="Drivers"
            Title="Drivers"
            isRestricted={isRestricted("wallet")}
            active={act === 4 ? true : false}
          />
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="ManageVehicleLogo"
            link="vehicles"
            Title="Vehicles"
            isRestricted={isRestricted("fastag")}
            active={act === 5 ? true : false}
          /> */}
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="ManageDriverLogo"
            link="DriversV2"
            Title="Drivers"
            // isRestricted={isRestricted("trips")}
            active={act === 14 ? true : false}
            // disable={!isTrips}
          />

          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="ManageVehicleLogo"
            link="VehiclesV2"
            Title="Vehicles"
            // isRestricted={isRestricted("trips")}
            active={act === 15 ? true : false}
            // disable={!isTrips}
          />
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="ccardnew"
            link="fuelMain"
            Title="Fuel Wallet"
            isRestricted={isRestricted("omc")}
            active={act === 17 ? true : false}
            disable={!isFuelWallet}
          />
          {/* <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="logimartIcon"
            link="logimart"
            Title="LogiMart"
            isRestricted={isRestricted("ecom")}
            active={act === 6 ? true : false}
          /> */}
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="ManageVehicleLogo"
            link="trips"
            Title="Trips"
            isRestricted={isRestricted("tripAccess")}
            active={act === 7 ? true : false}
            disable={!isTrips}
          />
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="routes"
            link="Routes"
            Title="Routes"
            isRestricted={isRestricted("tripAccess")}
            active={act === 18 ? true : false}
            disable={!isTrips}
          />
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="reportIcon"
            link="lr"
            Title="Load Receipt"
            isRestricted={isRestricted("tripAccess")}
            active={act === 13 ? true : false}
            disable={!isTrips}
          />
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="reportIcon"
            link="report"
            Title="Report"
            active={act === 8 ? true : false}
          />
          {authUser?.user?.type == 2 ? (
            <RouterButtonV2
              showTitle={sideBar}
              closeSideBar={closeSideBar}
              title="managerOptIcon"
              link="manager"
              Title="Manager"
              active={act === 12 ? true : false}
            />
          ) : null}
          {
            <RouterButtonV2
              showTitle={sideBar}
              closeSideBar={closeSideBar}
              title="managerOptIcon"
              link="Branches"
              Title="Branches"
              active={act === 19 ? true : false}
            />
          }
          {isVendor ? (
            <RouterButtonV2
              closeSideBar={closeSideBar}
              title="vendorIcon"
              link="vendor"
              Title="Vendor"
              active={act === 10 ? true : false}
              showTitle={sideBar}
              showArrow={true}
              disable={!isVendor}
            />
          ) : null}
          <RouterButtonV2
            showTitle={sideBar}
            closeSideBar={closeSideBar}
            title="trackerIcon"
            link="tracker"
            Title="Tracker"
            active={act === 11 ? true : false}
          />

          {/* </Menu> */}
        </div>
        {act === 10 ? (
          <>
            <div
              className="side-bar3 col sidebar-height"
              style={{ zIndex: 1, height: ref.current.clientHeight }}
            ></div>
            <div
              className="side-bar2 col sidebar-height"
              style={{ width: "210px", height: ref.current.clientHeight }}
              // onMouseEnter={() => {
              //   setSideBar(true);
              // }}
              // onMouseLeave={() => {
              //   setSideBar(false);
              // }}
            >
              {/* <div className="side-bar2-upper-part">
              <RouterButtonV3
                closeSideBar={closeSideBar}
                title="logipeVendor"
                Title="LogiPe Vendor"
                setActive={() => setAct2(0)}
                active={act2 === 0 || act2 === 1 ? true : false}
              />
              <RouterButtonV3
                closeSideBar={closeSideBar}
                title="manageVendor"
                Title="Manage Vendor"
                setActive={() => setAct2(1)}
                active={act2 === 0 || act2 === 1 ? true : false}
              />
              <RouterButtonV3
                closeSideBar={closeSideBar}
                title="vendorHistory"
                Title="Transactions"
                setActive={() => setAct2(2)}
                active={act2 === 2 ? true : false}
              />
            </div> */}
              <div>
                <RouterButtonV3
                  closeSideBar={closeSideBar}
                  title="otherVendor"
                  Title="Vendor"
                  setActive={() => setAct2(3)}
                  active={act2 === 3 || act2 === 4 ? true : false}
                  disable={!isVendor}
                />
                <RouterButtonV3
                  closeSideBar={closeSideBar}
                  title="manageVendor"
                  Title="Manage Vendor"
                  setActive={() => setAct2(4)}
                  active={act2 === 3 || act2 === 4 ? true : false}
                  disable={!isVendor}
                />
                <RouterButtonV3
                  closeSideBar={closeSideBar}
                  title="vendorHistory"
                  Title="Transactions"
                  setActive={() => setAct2(5)}
                  active={act2 === 5 ? true : false}
                  disable={!isVendor}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
export default SideBar;
