import React, { useEffect, useRef, useState } from "react";
import SvgImage from "../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import {
  sendAnalitics,
  toastStatus,
} from "../services/Store/Actions/appActions";
import { storeDataToAsyncStorage } from "../services/Utils/functions";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { createOrderFleetAction } from "../services/Store/Actions/fastagAction";
import { io } from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import { Default } from "react-awesome-spinners";
const DirectRecharge = (props) => {
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("Amount is required"),
    // remarks: Yup.string().required("Remark is required"),
  });
  const [payment, setPayment] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [amount, setAmount] = useState(-1);

  const [paymentData, setPaymentData] = useState({});
  const toastObject = useSelector((state) => state?.app?.toastObject);
  let { fleetId } = useParams();
  const [vrn, setVrn] = useState(fleetId);
  const [showText, setShowText] = useState("ShowEyeIcon");
  // const { loader } = useSelector((state) => state?.loginReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();

  useEffect(() => {
    dispatch(sendAnalitics("LoginPage_web"));
    let socket = io("https://gke-socket.logipe.com/", {
      query: {
        token: paymentData?.key,
        companyId: paymentData?.companyId,
      },
      transports: ["websocket"],
    });
    socket.on(`order/${paymentData?.orderId}`, (data) => {
      toast.success("Fastag Payment Successfull.", {
        toastId: paymentData?.orderId,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // window.location = `/recharge/${fleetId}/success`;
      setLoader2(false);
      navigate(`/recharge/${vrn}/success`);
    });
  }, [paymentData]);
  const handleClickUpiApp = (item) => {
    const payeeAddress = paymentData?.vpa;
    const payeeName = paymentData?.name;
    const trxnNote = paymentData?.orderId || "Fastag Recharge from direct link";
    const payeeAmount = amount;
    const currencyCode = "INR";
    const tr = paymentData?.orderId;

    let url = (
      item?.url +
      "pay?pa=" +
      payeeAddress +
      "&pn=" +
      payeeName +
      "&mc=" +
      "6012" +
      "&tn=" +
      trxnNote +
      "&tr=" +
      tr +
      "&am=" +
      payeeAmount +
      "&cu=" +
      currencyCode
    ).replace(/\s/g, "+");
    // console.log("payeeAddress", paymentData, url);
    console.log("sdsdsdsdsds");
    props?.setItem({ ...paymentData, amount });
    window.location.assign(url);
    setLoader2(true);
  };
  const upiApps = [
    {
      id: 1,
      appName: "Google Pay",
      url: "gpay://",
      width: "2rem",
      height: "2rem",
      svgName: "gpay",
    },
    {
      id: 2,
      appName: "PhonePe",
      url: "phonepe://",
      width: "2rem",
      height: "2rem",
      svgName: "phonePe",
    },
    // {
    //   id: 3,
    //   appName: "Axis Pay",
    //   width: "4rem",
    //   height: "2rem",
    //   imgUrl:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Axis_Bank_logo.svg/600px-Axis_Bank_logo.svg.png",
    // },
    {
      id: 4,
      appName: "Paytm",
      url: "paytm://",
      width: "3rem",
      height: "2rem",
      svgName: "paytm",
    },
  ];
  return (
    <div className="Login-Screen_box">
      <div className="padding16">
        <div className="row" id="loginDesktop">
          <div style={{ textAlign: "center", padding: "2.5rem" }}>
            <div className="Bold heading4">
              Please use Mobile For Transaction
            </div>
          </div>
        </div>
        {loader2 ? (
          <div id="loginMobile" className="h-full mt-5 text-center">
            <Default color={"#2e7c7b"} />
          </div>
        ) : (
          <div id="loginMobile" className="h-full mb-5 mt-2">
            <div className="textLast">
              <SvgImage name="AppLogo" />
            </div>
            {!payment ? (
              <>
                <Formik
                  enableReinitialize
                  validationSchema={validationSchema}
                  initialValues={{
                    amount: "",
                    fleetId: fleetId || "",
                  }}
                  innerRef={formRef}
                  onSubmit={(values, actions) => {
                    //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
                    setLoader(true);
                    dispatch(
                      createOrderFleetAction(
                        {
                          batchAmt: values?.amount,
                          typeOfOrder: "4",
                          recieverUserId: values.fleetId,
                          fleetId: values.fleetId,
                          upi: true,
                        },
                        (res) => {
                          setLoader(false);
                          setVrn(values.fleetId);
                          if (res.status == 200) {
                            setAmount(values?.amount);
                            setPaymentData(res?.data?.data);
                            setPayment(true);
                          }
                        }
                      )
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form
                      onSubmit={handleSubmit}
                      style={{
                        // padding: 50,
                        paddingBottom: 20,
                      }}
                    >
                      <FormGroup className="mt-3">
                        <div className="Bold heading3">Hello sir,</div>
                        <div className="d-flex vrnHeading semiBold text-break flex-wrap align-items-center mb-3">
                          <div className="">
                            Enter Amount to recharge vehicle
                          </div>
                          <div className="Bold ms-2 " style={{}}>
                            {fleetId}
                          </div>
                        </div>
                        {fleetId ? null : (
                          <div>
                            <div className="Bold text-center">
                              Enter Vehicle Number And Amount
                            </div>
                            <div className="d-flex justify-content-center">
                              <div
                                className="phoneNumberBox padding8 mt-3 "
                                style={{ width: 200, textAlignLast: "center" }}
                              >
                                {/* <div
                        className="col-2 padding0L boxWithBorder"
                        style={{ textAlign: "center" }}
                      >
                        <SvgImage name="countryCode" />
                      </div> */}
                                <div
                                  className="col-9 padding0L"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  <input
                                    type="text"
                                    id="fleetId"
                                    className="input-body-4 Regular text3"
                                    name="fleetId"
                                    value={values.fleetId}
                                    touched={touched.fleetId}
                                    errors={errors.fleetId}
                                    onBlur={() => {
                                      dispatch(
                                        sendAnalitics("EnterAmount_web", values)
                                      );
                                      handleBlur("fleetId");
                                    }}
                                    autoCapitalize="characters"
                                    onChange={handleChange("fleetId")}
                                    style={{
                                      textAlign: "left",
                                      marginLeft: "0px",
                                      width: "150px",
                                    }}
                                    placeholder="Enter Vehicle Number"
                                    autofocus
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div>
                          {fleetId ? (
                            <div className="Bold text-center">
                              Recharge Amount
                            </div>
                          ) : null}

                          <div className="d-flex justify-content-center">
                            <div
                              className="phoneNumberBox padding8 mt-3 "
                              style={{ width: 200, textAlignLast: "center" }}
                            >
                              {/* <div
                        className="col-2 padding0L boxWithBorder"
                        style={{ textAlign: "center" }}
                      >
                        <SvgImage name="countryCode" />
                      </div> */}
                              <div
                                className="col-9 padding0L"
                                style={{ marginLeft: "1rem" }}
                              >
                                <input
                                  type="number"
                                  id="amount"
                                  className="input-body-4 Regular text3"
                                  name="amount"
                                  value={values.amount}
                                  touched={touched.amount}
                                  errors={errors.amount}
                                  onBlur={() => {
                                    dispatch(
                                      sendAnalitics("EnterAmount_web", values)
                                    );
                                    handleBlur("amount");
                                  }}
                                  onChange={handleChange("amount")}
                                  style={{
                                    textAlign: "left",
                                    marginLeft: "0px",
                                    width: "150px",
                                  }}
                                  placeholder="Enter Amount"
                                  autofocus
                                ></input>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center">
                            <Button
                              type="submit"
                              className="loginButton semiBold text2"
                              style={{
                                marginTop: "1rem",
                                background: loader ? "#EFEFEF" : "#2e7c7b",
                                color: loader ? "#B5B5B5" : "#ffffff",
                                width: 200,
                              }}
                              // disabled={loader ? true : false}
                            >
                              {loader ? "Please Wait" : "Done"}
                            </Button>
                          </div>
                        </div>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>

                {/* <SvgImage name={"ManageAppAndPortal"} /> */}
                <div className="greenBg ">
                  <div>
                    <SvgImage name={"PhoneAndLP"} />
                    <div className="textClassRecharge my-3">
                      Manage Drivers and Vehicle expenses with an easy-to-use
                      dashboard and App
                    </div>
                    <SvgImage name="playStoreLogin" />
                    <div className="text2Class mt-4">
                      Try web version for enhanced experience
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="" style={{ textAlign: "center" }}>
                <div className="upiAppBorder my-4" style={{ padding: 10 }}>
                  <div className="d-flex justify-content-between align-items-center ">
                    <div className="Bold heading3">Name</div>
                    <div className="semiBold textDiv1">{paymentData.name}</div>
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="Bold heading3">Phone Number</div>
                    <div className="semiBold textDiv1">{paymentData.phone}</div>
                  </div> */}
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="Bold heading3">Vehicle Number</div>
                    <div className="semiBold textDiv1">{vrn}</div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="Bold heading3">Fastag Balance</div>
                    <div className="semiBold textDiv1">
                      Rs. {paymentData.balance}
                    </div>
                  </div>
                </div>
                <div className="Bold heading3">Amount</div>
                <div className="d-flex justify-content-center my-3">
                  <div
                    className="Bold heading2 greenBg2 ms-0"
                    style={{ color: "#fff" }}
                  >
                    ₹ {amount}
                  </div>
                </div>
                <div className="Bold heading4">Select Mode of Payment </div>
                <div
                  style={{ margin: 14 }}
                  className="d-flex justify-content-around flex-wrap upiAppBorder"
                >
                  {upiApps?.map((item, index) => {
                    console.log("====>>>>", item.svgName);
                    return (
                      <div
                        className="p-2 m-1"
                        onClick={() => {
                          handleClickUpiApp(item);
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          {/* <div className="p-2 upiAppBorder"> */}
                          <img
                            src={require(`./../assets/images/${item.svgName}.png`)}
                            alt="No App Available"
                            style={{ width: 35, height: 35 }}
                          />
                          {/* <SvgImage name={item.svgName} /> */}
                          {/* </div> */}
                        </div>

                        <div className="mt-2 textDiv2 Medium">
                          {item.appName}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default DirectRecharge;
