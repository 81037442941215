import {
  FETCH_BALANCE,
  FETCH_BALANCE_SUCCESS,
  FETCH_CASH_BACK_BALANCE,
  FETCH_CASH_BACK_BALANCE_SUCCESS,
  OWNER_CARD_DETAILS,
  OWNER_CARD_DETAILS_SUCCESS,
  GET_CASHBACK_LIST,
  GET_CASHBACK_LIST_SUCCESS,
  CLEAN_CASHBACK_LIST,
  TRANSFER_TO_CARD,
  TRANSFER_TO_CARD_SUCCESS,
  WITHDRAW_FROM_CARD,
  WITHDRAW_FROM_CARD_SUCCESS,
  CHANGE_PIN,
  CHANGE_PIN_SUCCESS,
  RECENT_CARD_TRANSACTIONS,
  RECENT_CARD_TRANSACTIONS_SUCCESS,
  NEW_WITHDRAW_MONEY,
  NEW_WITHDRAW_MONEY_SUCCESS,
  GET_OWNERCARD,
  GET_OWNERCARD_SUCCESS
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  walletSummary: [],
  cardDetails: {},
  OwnercardDetails: [],
  ownerSummary: {},
  ownerBalance: {},
  cardContolDetails: {},
  cashBack: {},
  cashbackList: [],
  allCashbackList:[],
  url: "",
  recentCardTransactions: {},
  ownerCardType:{}
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_BALANCE: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_BALANCE_SUCCESS: {
      return {
        ...state,
        loader: false,
        ownerBalance: action.payload,
      };
    }

    case FETCH_CASH_BACK_BALANCE: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_CASH_BACK_BALANCE_SUCCESS: {
      return {
        ...state,
        loader: false,
        cashBack: action.payload,
      };
    }

    case OWNER_CARD_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case OWNER_CARD_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        OwnercardDetails: action.payload,
      };
    }
    case GET_CASHBACK_LIST: {
      console.log("all cards-reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case GET_CASHBACK_LIST_SUCCESS: {
      console.log("all cards- reducer success");
      return {
        ...state,
        loader: false,
        cashbackList: action?.payload,
        allCashbackList:[...state.allCashbackList, ...action?.payload?.data?.data?.rows]
      };
    }
    case CLEAN_CASHBACK_LIST: {
      //console.log("all cards- reducer success");
      return {
        ...state,
        loader: false,
        cashbackList: [],
        allCashbackList:[]
      };
    }
    case TRANSFER_TO_CARD: {
      console.log("all cards-reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case TRANSFER_TO_CARD_SUCCESS: {
      console.log("all cards- reducer success");
      return {
        ...state,
        loader: false,
      };
    }
    case WITHDRAW_FROM_CARD: {
      console.log("all cards-reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case WITHDRAW_FROM_CARD_SUCCESS: {
      console.log("all cards- reducer success");
      return {
        ...state,
        loader: false,
      };
    }
    case CHANGE_PIN: {
      console.log("change pin-reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case CHANGE_PIN_SUCCESS: {
      console.log("change pin- reducer success");
      return {
        ...state,
        loader: false,
        url: action?.payload,
      };
    }
    case RECENT_CARD_TRANSACTIONS: {
      return {
        ...state,
        loader: true,
      };
    }
    case RECENT_CARD_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        loader: true,
        recentCardTransactions: action?.payload,
      };
    }
    case NEW_WITHDRAW_MONEY: {
      console.log("withdraw-reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case NEW_WITHDRAW_MONEY_SUCCESS: {
      console.log("withdraw- reducer success");
      return {
        ...state,
        loader: false,
      };
    }
    case GET_OWNERCARD: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_OWNERCARD_SUCCESS: {
      return {
        ...state,
        loader: false,
        ownerCardType: action?.payload,
      };
    }
    default: {
      return state;
    }
  }
};
