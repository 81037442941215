import React from "react";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { Modal, ModalBody } from "reactstrap";

const Toast = (props) => {
  const {
    success,
    message,
    isOpen,
    onHide,
    toggleModal,
    handleClose,
    name,
    toggleModal_newFleetError,
  } = props;

  console.log(
    "mesaage-------------",
    message,
    message.includes("Error : Recharge limit by PG exceeded left")
  );
  return (
    <Modal
      isOpen={isOpen}
      onHide={onHide}
      className="toastDiv settingModalMargin"
      //toggle={toggleModal}
      aria-labelledby="myModalLabel2"
      style={{ width: "31.5rem" }}
    >
      <div
        className="modal-content"
        style={{ width: "31.5rem", background: "white" }}
      >
        {success ? (
          <div>
            <div
              className="toastCloseHeader"
              onClick={() => {
                if (
                  message === "New customer was added successfully" ||
                  message === "Limit has been updated successfully"
                )
                  toggleModal();
                else if (message === "KYC For Yesbank Was Successful")
                  toggleModal();
                else handleClose();
              }}
            >
              <div className="PGCollectionCloseIcon">
                <SvgImage
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                  onClick={() => {
                    if (
                      message === "New customer was added successfully" ||
                      message === "Limit has been updated successfully"
                    )
                      toggleModal();
                    else if (message === "KYC For Yesbank Was Successful")
                      toggleModal();
                    else handleClose();
                  }}
                />
              </div>
              {/* <div className="PGCollectionCloseText" >
                        Close
                        </div> */}
            </div>

            <div className="toastSVGImage">
              <SvgImage name="SuccessToast" />
            </div>
            <div className="toastText">{message}</div>
          </div>
        ) : (
          <div>
            <div
              className="toastCloseHeader"
              onClick={() => {
                if (
                  (name === "vehicle") &
                  (message !== "Error : Fleet is already registered")
                )
                  toggleModal();
                else if (message === "Image not available") toggleModal();
                else if (message == "Error : Fleet is already registered")
                  toggleModal_newFleetError();
                else if (
                  message.includes("Error : Recharge limit by PG exceeded left")
                )
                  toggleModal();
                else handleClose();
              }}
            >
              <div
                className="PGCollectionCloseIcon"
                onClick={() => {
                  if (
                    (name === "vehicle") &
                    (message !== "Error : Fleet is already registered")
                  )
                    toggleModal();
                  else if (message === "Image not available") toggleModal();
                  else if (message == "Error : Fleet is already registered")
                    toggleModal_newFleetError();
                  else if (
                    message.includes(
                      "Error : Recharge limit by PG exceeded left"
                    )
                  )
                    toggleModal();
                  else handleClose();
                }}
              >
                <SvgImage
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
              {/* <div className="PGCollectionCloseText" >
                        Close
                        </div> */}
            </div>
            <div
              className="toastSVGImage"
              style={{
                top: message.includes(
                  "Error : Recharge limit by PG exceeded left"
                )
                  ? "-3.5rem"
                  : "-2.5rem",
              }}
            >
              <SvgImage name="FailureToast" />
            </div>
            <div
              className="toastText"
              style={{
                top: message.includes(
                  "Error : Recharge limit by PG exceeded left"
                )
                  ? "-3rem"
                  : "-1rem",
              }}
            >
              {message}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Toast;
