import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import {
  vehicleHistory,
  getVehicleListAction,
  rechargeFastag,
  cleanVehicleLoader,
} from "../../services/Store/Actions/manageVehicleAction";
import FullVehicleList from "./SubComponents/FullVehicleList/index";
import CustomerVehicleDetail from "./SubComponents/CustomerVehicleDetail/index";
import { useParams } from "react-router-dom";
import Toast from "../subComponents/toast";
import { toastStatus } from "../../services/Store/Actions/appActions";
import { cleanFleetLoader } from "../../services/Store/Actions/fleetActions";
import { cleanFastagLoaderAction } from "../../services/Store/Actions/fastagAction";

//import { getFleet } from "../../services/Store/Actions/driverslistActions";
function GpsTracker(props) {
  const {sideBar} = props;
  const dispatch = useDispatch();
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const DATA = useSelector((state) => state);
  const [user, setUser] = useState("");
  const [fasTagBal, setFastTagBal] = useState("");
  const [omcBal, setOmcBal] = useState("");
  const [sub, setSub] = useState("");
  const [tagId, setTagId] = useState("");
  const [regNo, setRegNo] = useState("");
  const [fleetId, setFleetId] = useState("");
  const [fastagType, setFastagType]= useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth );
  //  const data_history=useSelector((state)=>state)

  //console.log("main data toh ye hai", DATA);

  const [fetchMoreData, setFetchMoreData] = useState(true);
  const [offset, setOffset] = useState(5);
  const [name, setName] = useState("");
  const [next, setNext] = useState(true);

  const [fetchMore, setFetchMore] = useState(true);

  const vehicleData = useSelector((state) => state?.manageVehicle);
  const {allFleetTrackerList,loader,allFleetTracker} = useSelector((state) => state?.driverlist);

  // useEffect(() => {
  //   dispatch(
  //     getVehicleListAction({
  //       offset: "",
  //       limit: 100,
  //       search: "",
  //       minKyc: "",
  //       sortAs: "",
  //       sortBy: "",
  //     })
  //   );
  // }, []);
  // console.log("checking the DATA",DATA);

  let { id } = useParams();
  console.log("ppp", id);

  const { advancedVehicleList,advancedVehicleListCount } = useSelector(
    (state) => state?.fleet
  );

  //console.log("Alllllllll",allFleets)
  const nextTrigger = () => {
    setNext(false);
  };
  const allowTrigger = () => {
    setNext(true);
  };
  useEffect(() => {
    console.log("akakakakak", advancedVehicleList);
    if (id !== undefined && id !== "" && next) {
      let val = allFleetTrackerList?.filter((element) => element.fleetId == id)[0];
      setUser(val?.driverName);
      setFleetId(val?.fleetId);
      setRegNo(val?.regNumber);
      setTagId(val?.tagId);
      setFastTagBal(val?.fastagBalance);
      setFastagType(val?.vehicleCategory)
      setName(val?.driverName);
      setOmcBal(val?.omcBalance)
      setSub(val?.isSub)
      //console.log("ababababaab",val);
    }
  }, [id, allFleetTrackerList]);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // function handleUser() {
  //   console.log("Checking The DATA", id);
  //   // setUser(val?.name);
  //   // setFleetId(val?.fleetId);
  //   // setRegNo(val?.regNumber);
  //   // setTagId(val?.Fastag?.tagId);
  //   // setFastTagBal(val?.Fastag?.balance);
  //   // setName(val?.FleetDriverMapHistories[0]?.Driver?.User?.fullName);
  //   //console.log("val here", fleetId);
  // }

  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);

  // search value
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let searchParam = params.search===undefined?"":params.search;
  const [vehRank, setVehRank] = useState(0);
  return (
    <div className="container topMargin sideBarMargin" style={{marginLeft: sideBar? '230px' : '110px',maxWidth: sideBar ?windowWidth - 260 : windowWidth- 130 }}>
      <div className="row">
        <div className="col-lg-5 FullDriverDashboardBox">
          <FullVehicleList
            //handleUser={handleUser}
            handleFastagBal={setFastTagBal}
            handleRegNo={setRegNo}
            nextTrigger={nextTrigger}
            allowTrigger={allowTrigger}
            vehRank={vehRank}
            setVehRank={setVehRank}
            //handleTagId={handleTagId}
          />
        </div>
        <div className="col-lg-7">
          {/* <div className="manage-vehicle-card-Type-1">
            <VehicleHeader
              Data={DATA}
              user={user}
            />
          </div> */}
          <div className="">
            <CustomerVehicleDetail
              name={name}
              Data={DATA}
              user={fleetId}
              bal={fasTagBal}
              regNo={regNo}
              tagId={tagId}
              vehRank={vehRank}
              fastagType={fastagType}
              omcBal = {omcBal}
              sub={sub}
              sideBar = {sideBar}
            />
          </div>
        </div>
      </div>
      {/* {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            console.log("mesaage-------------other error");
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            dispatch(cleanVehicleLoader());
            dispatch(cleanFastagLoaderAction());
          }}
          toggleModal_newFleetError={() => {
            console.log("mesaage-------------new fleet error");
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
            dispatch(cleanFleetLoader());
          }}
          isOpen={toastObject?.toastStatus}
          name="vehicle"
          handleClose={() => {
            // dispatch(
            //   toastStatus({
            //     toastStatus: false,
            //     message: "",
            //     status: true,
            //   })
            // );
            // if (!toastObject?.toastStatus)
            //window.location="/Vehicles"
            //window.location = "/Vehicles/" + id;
            window.location='/VehiclesV2/'+id+"?search="+searchParam+"&offset="+offsetVal;
            //alert("close button clicked");
          }}
        />
      ) : (
        <></>
      )} */}
    </div>
  );
}
export default GpsTracker;
