import {
  GET_ALL_CUSTOMER,
  GET_ALL_CUSTOMER_SUCCESS,
  CLEAN_ALL_CUSTOMER,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  CLEAN_CUSTOMER,
  ADD_PAYMENT_RECIVED,
  ADD_PAYMENT_RECIVED_SUCCESS,
  CREATE_EXTERNAL_INVOICE,
  CREATE_EXTERNAL_INVOICE_SUCCESS,
  CREATE_LOGIPE_INVOICE,
  CREATE_LOGIPE_INVOICE_SUCCESS,
  DELETE_LEDGER,
  DELETE_LEDGER_SUCCESS,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_LEDGER,
  DOWNLOAD_LEDGER_SUCCESS,
  EDIT_EXTERNAL_INVOICE,
  EDIT_EXTERNAL_INVOICE_SUCCESS,
  EDIT_LOGIPE_INVOICE,
  EDIT_LOGIPE_INVOICE_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_LEDGER_LIST,
  GET_LEDGER_LIST_SUCCESS,
  UPLOAD_RECIPT,
  UPLOAD_RECIPT_SUCCESS,
  GET_ALL_TRIPS,
  GET_ALL_TRIPS_SUCCESS,
  CLEAN_ALL_TRIP,
  COLLECT_PAYMENT,
  COLLECT_PAYMENT_SUCCESS,
  EDIT_CUSTOMER,
  EDIT_CUSTOMER_SUCCESS,
  ADD_VENDOR_PAYMENT,
  ADD_VENDOR_PAYMENT_SUCCESS,
  GET_INVOICES_LIST,
  GET_INVOICES_LIST_SUCCESS,
  ADD_NEW_PHONE_FW,
  ADD_NEW_PHONE_FW_SUCCESS,
} from "../storeTypes";

export const getAllCustomersAction = (data) => {
  console.log("action-> getAllCustomersAction ", data);
  return {
    type: GET_ALL_CUSTOMER,
    payload: data,
  };
};

export const getLedgerListAction = (data) => {
  return {
    type: GET_LEDGER_LIST,
    payload: data,
  };
};
export const getInvoiceListAction = (data) => {
  return {
    type: GET_INVOICES_LIST,
    payload: data,
  };
};

export const getAllCustomersSuccessAction = (data) => {
  console.log("action-> getAllCustomersSuccessAction", data);
  return { type: GET_ALL_CUSTOMER_SUCCESS, payload: data };
};

export function clearAllCustomers() {
  return {
    type: CLEAN_ALL_CUSTOMER,
  };
}

export const createCustomerAction = (data, callback) => {
  console.log("action-> createCustomerAction ", data);
  return {
    type: CREATE_CUSTOMER,
    payload: data,
    callback,
  };
};

export const createCustomerSuccessAction = (data) => {
  console.log("action-> createCustomerSuccessAction", data);
  return { type: CREATE_CUSTOMER_SUCCESS, payload: data };
};

/////  // Add new phone to fuel wallet

export const addNewPhoneFwAction = (data, callback) => {
  console.log("action-> addNewPhoneFwAction ", data);
  return {
    type: ADD_NEW_PHONE_FW,
    payload: data,
    callback,
  };
};

export const addNewPhoneFwActionSuccess = (data) => {
  console.log("action-> addNewPhoneFwActionSuccess", data);
  return { type: ADD_NEW_PHONE_FW_SUCCESS, payload: data };
};

/////

export const getCustomerAction = (data, callback = () => {}) => {
  console.log("action-> getCustomerAction ", data);
  return {
    type: GET_CUSTOMER,
    payload: data,
    callback,
  };
};

export const getCustomerSuccessAction = (data) => {
  console.log("action-> getCustomerSuccessAction", data);
  return { type: GET_CUSTOMER_SUCCESS, payload: data };
};

export function clearCustomer() {
  return {
    type: CLEAN_CUSTOMER,
  };
}
export const getLedgerListSuccessAction = (data) => {
  return { type: GET_LEDGER_LIST_SUCCESS, payload: data };
};
export const getInvoiceListSuccessAction = (data) => {
  return { type: GET_INVOICES_LIST_SUCCESS, payload: data };
};
export const deleteLedgerAction = (data, callBack) => {
  return {
    type: DELETE_LEDGER,
    payload: data,
    callBack,
  };
};

export const deleteLedgerSuccessAction = (data) => {
  return { type: DELETE_LEDGER_SUCCESS, payload: data };
};
export const createLogipeInvoiceAction = (data, callBack) => {
  console.log("createLogipeInvoiceAction 1");
  return {
    type: CREATE_LOGIPE_INVOICE,
    payload: data,
    callBack,
  };
};

export const createLogipeInvoiceSuccessAction = (data) => {
  return { type: CREATE_LOGIPE_INVOICE_SUCCESS, payload: data };
};
export const createExternalInvoiceAction = (data, callBack) => {
  return {
    type: CREATE_EXTERNAL_INVOICE,
    payload: data,
    callBack,
  };
};

export const createExternalInvoiceSuccessAction = (data) => {
  return { type: CREATE_EXTERNAL_INVOICE_SUCCESS, payload: data };
};
export const downloadInvoiceAction = (data, callBack) => {
  return {
    type: DOWNLOAD_INVOICE,
    payload: data,
    callBack,
  };
};

export const downloadInvoiceSuccessAction = (data) => {
  return { type: DOWNLOAD_INVOICE_SUCCESS, payload: data };
};

export const getInvoiceAction = (data) => {
  return {
    type: GET_INVOICE,
    payload: data,
  };
};

export const getInvoiceSuccessAction = (data) => {
  return { type: GET_INVOICE_SUCCESS, payload: data };
};
export const editLogipeInvoiceAction = (data) => {
  return {
    type: EDIT_LOGIPE_INVOICE,
    payload: data,
  };
};

export const editLogipeInvoiceSuccessAction = (data) => {
  return { type: EDIT_LOGIPE_INVOICE_SUCCESS, payload: data };
};
export const editExternalInvoiceAction = (data) => {
  return {
    type: EDIT_EXTERNAL_INVOICE,
    payload: data,
  };
};

export const editExternalInvoiceSuccessAction = (data) => {
  return { type: EDIT_EXTERNAL_INVOICE_SUCCESS, payload: data };
};
export const uploadReciptAction = (data) => {
  return {
    type: UPLOAD_RECIPT,
    payload: data,
  };
};

export const uploadReciptSuccessAction = (data) => {
  return { type: UPLOAD_RECIPT_SUCCESS, payload: data };
};
export const addPaymentRecivedAction = (data, callBack) => {
  return {
    type: ADD_PAYMENT_RECIVED,
    payload: data,
    callBack,
  };
};

export const addPaymentRecivedSuccessAction = (data) => {
  return { type: ADD_PAYMENT_RECIVED_SUCCESS, payload: data };
};
export const downloadLedgerAction = (data, callBack) => {
  return {
    type: DOWNLOAD_LEDGER,
    payload: data,
    callBack,
  };
};

export const downloadLedgerSuccessAction = (data) => {
  return { type: DOWNLOAD_LEDGER_SUCCESS, payload: data };
};

export const getAllTripsAction = (data) => {
  console.log("action-> getAllTripsAction ", data);
  return {
    type: GET_ALL_TRIPS,
    payload: data,
  };
};

export const getAllTripsSuccessAction = (data) => {
  console.log("action-> getAllTripsSuccessAction", data);
  return { type: GET_ALL_TRIPS_SUCCESS, payload: data };
};

export function clearAllTrips() {
  return {
    type: CLEAN_ALL_TRIP,
  };
}

export const collectPaymentAction = (data, callBack) => {
  return {
    type: COLLECT_PAYMENT,
    payload: data,
    callBack,
  };
};

export const collectPaymentSuccessAction = (data) => {
  return { type: COLLECT_PAYMENT_SUCCESS, payload: data };
};

export const editCustomerAction = (data, callBack) => {
  return {
    type: EDIT_CUSTOMER,
    payload: data,
    callBack,
  };
};

export const editCustomerSuccessAction = (data) => {
  return { type: EDIT_CUSTOMER_SUCCESS, payload: data };
};

export const addVendorPaymentAction = (data, callBack) => {
  return {
    type: ADD_VENDOR_PAYMENT,
    payload: data,
    callBack,
  };
};

export const addVendorPaymentSuccessAction = () => {
  return { type: ADD_VENDOR_PAYMENT_SUCCESS };
};
