import React, { useEffect } from "react";
import './index.css'
import PGCollectionBar from "../PGCollectionBar";
import PGCollectionButton from "../PGCollectionButton";
import PGCollectionCard from "../PGCollectionCard";
import SearchBar from "../PGCollectionSearchBar";
import {IoIosCloseCircleOutline} from "react-icons/io";
import {FcManager} from "react-icons/fc";
import {Button} from 'reactstrap'
import SvgImage from "../../Components/Icons/Icons";
import {getCollections, getSettlements, cleanCollection } from "../../services/Store/Actions/beneficiaryAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Modal, ModalBody } from "reactstrap";

const SettlementHistory=(props)=>{
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(
            getCollections()
        );
        dispatch(
            getSettlements()
          );
    },[]);
    const handleClose=props.handleClose;
    const {isOpen, onHide,toggle, settlementsList }= props;
    const collections= useSelector ((state)=> state?.beneficiary?.collections?.data);
    console.log ("collections",collections);

    const settlements= useSelector ((state)=>state?.beneficiary?.settlements?.data);
    console.log("settlements----",settlements);

    const handleSearch=(val)=>{
        dispatch(cleanCollection());
        dispatch(getCollections());
    };

    return(
        <Modal
      isOpen={isOpen}
      onHide={onHide}
      className=" all-transaction-main-modal"
      toggle={toggle}
      aria-labelledby="myModalLabel2"
    >
         <ModalBody className="transactionModalBody">
        <div className="PGCollectionBox">
            <div className="PGCollectionclose" onClick={handleClose}>
                
               <div className="PGCollectionCloseIcon">
               <SvgImage style ={{width:"10%",height:"10%"}}name = "CloseButtonLogo" width="20%"height="20%" />

               </div>
                <div className="PGCollectionCloseText" >
                    Close
                </div>
                    </div>
            <div>
                <div className="PGCollectionHeader">
                 
                    {/* <div className="PGCollectionIcon" >
                    <FcManager size={30}/>
                    </div> */}
                    <div className="PGCollectionIcon">
                        <SvgImage name ="settlementLogo"/>
                    </div>
                    <div className="PGCollectiontText">Settlement History</div>
                    
                </div>
                {/* <div className="PGCollectionSearchBar">
                    <SearchBar findSearch={handleSearch}/>
                </div> */}
                <div className="PGCollectionHeaderBar">
                <div className="PGCollectionBarDetails row">
                    <div className="SettlementCurBalance col-6">Amount</div>
                    <div className="SettlementReqDate col-6">Date and Time</div>
                </div>
                </div>
                <div className="cashbackListing">
                    {settlementsList?.map((item,i)=>{
                        // let name=item?.firstName+" "+item?.lastName;
                        // let amount=item?.amount;
                        // let company=item?.companyName;
                        // let transactionTiming=item?.createdAt;
                        // let dateH= moment(transactionTiming).format("DD-MM-YYYY");
                        // let timeH= moment(transactionTiming).format("hh:mm:ss a");
                        // let remarks= item?.purpose;

                        // return (<div key={i} className="PGCollectionCard" style={{}}>
                        // <PGCollectionCard name={name}
                        // companyName={company}
                        // amount={amount}
                        // transactionDate={dateH}
                        // transactionTime={timeH}
                        // remarks={remarks}/>
                        // </div>);
                    })}
                    
                    
                </div>
            </div>
        </div>
        </ModalBody>
    </Modal>
    )

}
export default SettlementHistory; 