import { call, put, takeEvery } from "redux-saga/effects";
import { downloadM2PHistoryAPI, getM2pCardDetailsApi, getM2PHistoryAPI, getM2pUserDetails, m2pCardControlsApi, m2pContactInfoApi, m2pEditCardControlsApi, m2pMonthlyBalanceApi, m2pOTPApi, m2pOwnerAddressApi, m2pRegisterApi, m2pSetPinApi, m2pVKYCApi, withdrawFromM2pCardApi } from "../..//Utils/Apis";

import { processErrorAndRespond } from "../../Utils/functions";
import { M2PCardControlActionSuccess, M2PCardDetailsActionSuccess, M2pCardWithdrawActionSuccess, m2pContactInfoActionSuccess, M2pEditCardControlActionSuccess, m2pMonthlyBalanceActionSuccess, m2pOTPActionSuccess, m2pOwnerAddressActionSuccess, m2pRegisterActionSuccess, M2PSetPinActionSuccess, M2PTransactionsActionSuccess, M2pUserDetailsActionSuccess, m2pVKYCActionSuccess, m2pWalletHistoryDownloadActionSuccess } from "../Actions/m2pAction";
import { EDIT_M2PCARD_CONTROLS, GET_M2P_CARD_CONTROLS, GET_M2P_CARD_DETAILS, GET_M2P_USER_DETAILS, M2P_CONTACT_INFO, M2P_DOWNLOAD_WALLET_HISTORY, M2P_MONTHLY_BALANCE, M2P_OTP, M2P_OWNER_ADDRESS, M2P_REGISTER, M2P_SET_PIN, M2P_TRANSACTION_HISTORY, M2P_VKYC_URL, M2P_WITHDRAW_FROM_CARD } from "../storeTypes";

import { toastStatus } from "../../../services/Store/Actions/appActions";

function* m2pUserDetailsSaga(action) {
  try {
    const response = yield call(getM2pUserDetails, action?.payload);
    if (response.data.type == "success") {
      yield put(M2pUserDetailsActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "getOwnerFetchBalanceSaga Error.Response:- ",
      error.response.data
    );
    //Alert.alert('get Owner Summary API Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* m2pCardDetailsSaga(action) {
  try {
    const response = yield call(getM2pCardDetailsApi, action?.payload);
    if (response.data.type == "success") {
      yield put(M2PCardDetailsActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "getOwnerFetchBalanceSaga Error.Response:- ",
      error.response.data
    );
    //Alert.alert('get Owner Summary API Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* getM2PTransactionHistorySaga(action) {
  try {
    console.log("saga-> fleet-- enter ");
    const response = yield call(
      getM2PHistoryAPI,
      action?.userId,
      action?.payload
    );
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      console.log("saga->inside");
      if(action?.callback){
        action?.callback(response);
      }
      yield put(M2PTransactionsActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error in m2p transactions:-  ", processErrorAndRespond(error));
  }
}

function* M2PCardWithdrawSaga(action) {
  try {
    const response = yield call(
      withdrawFromM2pCardApi,
      action?.payload
    );
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(M2pCardWithdrawActionSuccess(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Money was withdrawn from card successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    //console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error in m2p:-  ", processErrorAndRespond(error));
  }
}


function* M2PCardControlSaga(action) {
  try {
    const response = yield call(
      m2pCardControlsApi,
      action?.payload
    );
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      console.log("saga->inside");
      yield put(M2PCardControlActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error in m2p transactions:-  ", processErrorAndRespond(error));
  }
}


function* M2PEditCardControlSaga(action) {
  try {
    const response = yield call(
      m2pEditCardControlsApi,
      action?.payload,
    );
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(M2pEditCardControlActionSuccess(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Limit has been updated successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    //console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error in m2p:-  ", processErrorAndRespond(error));
  }
}


function* M2PSetPinSaga(action) {
  try {
    const response = yield call(
      m2pSetPinApi,
      action?.payload
    );
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(M2PSetPinActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error in m2p transactions:-  ", processErrorAndRespond(error));
  }
}



function* m2pOTPSaga(action) {
  try {
    const response = yield call(
      m2pOTPApi,
      action?.payload
    );
    //console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      //console.log("saga->inside");
      yield put(m2pOTPActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error in m2p transactions:-  ", processErrorAndRespond(error));
  }
}

function* m2pContactInfoSaga(action) {
  try {
    const response = yield call(
      m2pContactInfoApi,
      action?.payload
    );
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(m2pContactInfoActionSuccess(response?.data?.data));
    }
  } catch (error) {
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error in m2p transactions:-  ", processErrorAndRespond(error));
  }
}

function* m2pRegisterSaga(action) {
  try {
    const response = yield call(
      m2pRegisterApi,
      action?.payload
    );
    console.log("saga-> fleet-- ", response);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(m2pRegisterActionSuccess(response?.data?.data));
    }
  } catch (error) {
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    //console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error in m2p transactions:-  ", processErrorAndRespond(error));
  }
}


function* m2pVKYCSaga(action) {
  try {
    const response = yield call(m2pVKYCApi, action?.payload);
    if (response?.data?.type == "success") {
      action.callback(response);
      yield put(m2pVKYCActionSuccess(response?.data?.data));
    }
  } catch (error) {
    // console.log(
    //   "getOwnerFetchBalanceSaga Error.Response:- ",

    // );
    //Alert.alert('get Owner Summary API Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* m2pOwnerAddressSaga(action) {
  try {
    const response = yield call(m2pOwnerAddressApi, action?.payload);
    if (response.data.type == "success") {
      yield put(m2pOwnerAddressActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "getOwnerFetchBalanceSaga Error.Response:- ",
      error.response.data
    );
    //Alert.alert('get Owner Summary API Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* m2pMonthlyBalanceSaga(action) {
  try {
    const response = yield call(m2pMonthlyBalanceApi, action?.payload);
    if (response.data.type == "success") {
      yield put(m2pMonthlyBalanceActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log(
      "getOwnerFetchBalanceSaga Error.Response:- ",
      error.response.data
    );
    //Alert.alert('get Owner Summary API Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* m2pWalletHistoryDownloadSaga(action) {
  try {
    const response = yield call(downloadM2PHistoryAPI, action?.userId,action?.payload);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(m2pWalletHistoryDownloadActionSuccess(response?.data?.data));
    }
  } catch (error) {
    //Alert.alert('get Owner Summary API Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
export function* watchM2pSagas() {
  yield takeEvery(GET_M2P_USER_DETAILS, m2pUserDetailsSaga);
  yield takeEvery(GET_M2P_CARD_DETAILS, m2pCardDetailsSaga);
  yield takeEvery(M2P_TRANSACTION_HISTORY, getM2PTransactionHistorySaga);
  yield takeEvery(M2P_WITHDRAW_FROM_CARD, M2PCardWithdrawSaga);
  yield takeEvery(GET_M2P_CARD_CONTROLS, M2PCardControlSaga);
  yield takeEvery(EDIT_M2PCARD_CONTROLS, M2PEditCardControlSaga);
  yield takeEvery(M2P_SET_PIN, M2PSetPinSaga);
  yield takeEvery(M2P_REGISTER, m2pRegisterSaga);
  yield takeEvery(M2P_CONTACT_INFO, m2pContactInfoSaga);
  yield takeEvery(M2P_OTP, m2pOTPSaga);
  yield takeEvery(M2P_VKYC_URL, m2pVKYCSaga);
  yield takeEvery(M2P_OWNER_ADDRESS, m2pOwnerAddressSaga);
  yield takeEvery(M2P_MONTHLY_BALANCE, m2pMonthlyBalanceSaga);
  yield takeEvery(M2P_DOWNLOAD_WALLET_HISTORY,m2pWalletHistoryDownloadSaga)
}
