import React, { useEffect, useState } from "react";
import SvgImage from "../../Icons/Icons";
import "./index.css";
import moment from "moment";
import CardWalletAllTransactionWallet from "../../Modals/CardWalletAllTransactionModal";
import { useDispatch, useSelector } from "react-redux";
import { transactionHistory } from "../../../services/Store/Actions/masterWalletActions";
import { getFastagWalletTransaction, cleanFastagWalletTransaction } from "../../../services/Store/Actions/fastagAction";
import { getWalletTransactionHistory, cleanWalletTransactionHistory } from "../../../services/Store/Actions/walletAction";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";
function TransactionBox(props) {
  const { type, receiver, Title, openBox } = props;
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();
  function handleShow() {
    dispatch(sendAnalitics("Txn_MA_Web"));
    setOpen(true);
  }
  function handleClose() {
    setRefresh(!refresh);
    setOpen(false);
  }
  function toggleModal() {
    setOpen(!open);
    setRefresh(!refresh);
  }

  useEffect(() => {
    if (Title === "Driver cards") {
      dispatch(
        transactionHistory({
          limit: "",
          search: "",
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    }

    if (Title === "Fastag Wallet") {
      dispatch(cleanFastagWalletTransaction());
      dispatch(
        getFastagWalletTransaction({
          offset: 0,
          limit: "",
          fromDate: "",
          toDate: "",
          sortBy: "",
          sortAs: ""
        })
      );
    }

    if (Title === "Master Account") {
      dispatch(cleanWalletTransactionHistory());
      dispatch(getWalletTransactionHistory({
        offset: 0,
        from: "",
        to: "",
        userId: ""
      }));
    }
  }, [openBox, refresh]);

  const { masterWalletTransactionHistory } = useSelector(
    //CARD WALLET BALANCE and History
    (state) => state?.masterWallet
  );

  const { fastagTransaction } = useSelector((state) => state?.fastag);

  const { walletTransactionHistory } = useSelector((state) => state?.wallet);
  const RecentCardTransaction = useSelector(
    (state) => state?.manageCard?.recentCardTransactions?.Transaction
  );

  const logipeAccountHistory =
    walletTransactionHistory?.instrument_statement?.records;
  //console.log("so",logipeAccountHistory);
  const history =
    Title === "Driver cards"
      ? RecentCardTransaction
      : Title === "Fastag Wallet"
        ? fastagTransaction
        : logipeAccountHistory;


  const { fastagTransactionList } = useSelector((state) => state?.fastag);

  const { walletTransactionHistoryList } = useSelector((state) => state?.wallet);
  const historyList =
    Title === "Driver cards"
      ? RecentCardTransaction
      : Title === "Fastag Wallet"
        ? fastagTransactionList
        : walletTransactionHistoryList;

  const transHistoryActive = historyList?.length > 0 ? 1 : 0;

  // const transHistoryActive = history?.length > 0 ? 1 : 0;


    console.log("TitleTiTitletle", Title,transHistoryActive);
  return (
    <div className={"mainn-container container padding8"}>
      {transHistoryActive === 1 ? (
        <>
          <div className="row">
            <div className="col-6">
              <p className="clickArrow heading2 semiBold">Last Transaction</p>
            </div>
            <div className="col-6 flexStyling homeCard-Transaction" onClick={handleShow}>
              <p className="clickArrow heading3 semiBold greenFont" style={{fontSize : "15px"}}>All Transactions</p>
              <SvgImage
                name="ForwardArrow"
                height="32"
                width="32"
                className=""
              />
            </div>
            {/* {
              <CardWalletAllTransactionWallet
                history={history}
                Title={Title}
                open={open}
                toggleModal={toggleModal}
                handleClose={handleClose}
              />
            } */}
          </div>

          <div className="Box row">
            <div className="col-3">
              <SvgImage
                name={
                  type === "Master Account Transfer"
                    ? "CreditCardLogo"
                    : type === "Balance Transfer"
                      ? "PinkCreditCardLogo"
                      : "FastagWalletLogo"
                }
                classname={"Transaction-logo"}
              />
            </div>

            <div className="col-5 Regular textDiv">
              <p className="text-left ">{type}</p>
              <p className="text-left-bottom secondaryBlack">
                {Title === "Card Wallet"
                  ? historyList[0]?.merchantName
                  : Title === "Fastag Wallet"
                    ? receiver
                    : historyList[0]?.remarks?.substring(0, 25) + "..."}
              </p>
            </div>
            <div className="col-4 Regular text3">
              <p className="amountt">
                {"₹ " + (historyList[0]?.amount === null ? 0.00 : historyList[0]?.amount.toFixed(2))}
              </p>
              <p className="days secondaryBlack">
                {Title === "Card Wallet"
                  ? moment(historyList[0]?.transactionDate).fromNow()
                  : Title === "Fastag Wallet"
                    ? moment(historyList[0]?.createdAt).fromNow()
                    : moment(historyList[0]?.transaction_time).fromNow()}
              </p>
            </div>
          </div>
        </>
      ) : (
        <div style={{ position: "relative", top: "37px", left: "81px" }}>
          <p>No Transaction History</p>
        </div>)
        // ) : (
        //   <div className="text-center pt-4">
        //     You do not have any card. Complete Driver KYC on app to get LogiPe
        //     card.
        //   </div>
        // )}
      }
      <CardWalletAllTransactionWallet
        history={history}
        Title={Title}
        open={open}
        toggleModal={toggleModal}
        handleClose={handleClose}
      />
    </div>
  );
}

export default TransactionBox;
