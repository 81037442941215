import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import "./index.css";
import GreenInfoCards from "./SubComponents/GreenInfoCards";
import FastagComponent from "./SubComponents/RightBoxComponents/FastagComponent";
import CashComponent from "./SubComponents/RightBoxComponents/CashComponent";
import AddingFilter from "../../../Modals/addingFilter";
import { Table } from "reactstrap";
import DriverInfo from "./SubComponents/DriverInfo";
import FuelCard from "./SubComponents/Banners/FuelCard";
import NoDriverSelected from "./SubComponents/NoDriverSelected";
import CardComponent from "./SubComponents/RightBoxComponents/CardComponent"
import NeocredCardTable from "./SubComponents/TabularData/NeocredCardTable";
import M2PCardTable from "./SubComponents/TabularData/M2PCardTable";
import FastagTable from "./SubComponents/TabularData/FastagTable";
import CashTable from "./SubComponents/TabularData/CashTable";
import TripTable from "./SubComponents/TabularData/TripTable";
import FuelCardStatus from "./SubComponents/Banners/FuelCardStatus";
import { useParams } from "react-router-dom";
import { omcDriverDataAction } from "../../../../services/Store/Actions/omcActions";
import CardsTable from "./SubComponents/TabularData/CardsTable";
import TripComponent from "./SubComponents/RightBoxComponents/TripSubComponents/TripComponent";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";
import BulkTransfer from "../../ManageDriver/BulkTransfer";

const DriverSideLowerDiv=(props)=>{

    const [ currOption, setCurrOption]=useState(0);
    const {cardType, driverDetails, driverCardStatus, userId, driverFleet,setVehicleNumber, driverID}= props;

    const {id}= useParams();

    console.log("card type", id, cardType, driverCardStatus);

    const dispatch= useDispatch();
    useEffect(()=>{
        const jsonValue = sessionStorage.getItem("_driverId");
        console.log("get token",jsonValue);
        let driverId= jsonValue != null ? JSON.parse(jsonValue) : null;
        if(driverId){
            console.log("insideeeeeeeee",jsonValue);
            dispatch(omcDriverDataAction(driverId)) 
        }
    },[id] )


    const omcDriverData= useSelector((state)=>state?.omc?.omcDriverData);

    
    useEffect(()=>{

        if(id){
            const cardnumObj = sessionStorage.getItem("_cardNum");
            const cardNumber = JSON.parse(cardnumObj);
            if(cardNumber)
                setCurrOption(cardNumber);
        }
        // else{
        //     setCurrOption(0);
        // }
    },[])


    const handleCardSelection=(num)=>{
        storeDataToAsyncStorage("_cardNum", num);
        setCurrOption(num);
    }
    console.log("omcDriverData", omcDriverData);
    return(
        <div className="padding16">
            {
                driverDetails?.name=="Select Driver"?
                <NoDriverSelected />
                : driverDetails?.name=="All Drivers"?
                <BulkTransfer/>:
            <>
            <DriverInfo 
              driverDetails={driverDetails}
              driverFleet={driverFleet}
              setVehicleNumber={setVehicleNumber}
              driverID={driverID}
              userId={id}
              cardType={cardType}
            />
            <div className="row padding16T" style={{margin:"0", }}>
                <div className="col-6 row padding0" style={{margin:"0", height: "15rem"}}>
                    <GreenInfoCards 
                        currOption={currOption}
                        setCurrOption={handleCardSelection}/>
                    
                    {/* {
                        currOption==0?
                        (
                            omcDriverData?.diver?.User?.omcDetails?
                            <FuelCardStatus />
                            :<FuelCard />
                        )
                        :<></>
                    } */}
                </div>
                <div className="col-6 padding8 padding0R" >
                    <div className="whiteBackground whiteBack" >
                        <div className="">
                        {
                            currOption==0?
                            <CardComponent
                                cardType={cardType}
                                driverDetails={driverDetails}
                                driverCardStatus={driverCardStatus}
                                userId={id}
                            />
                            :currOption==1?
                            <FastagComponent />
                            :currOption==2?
                            <CashComponent />
                            :<TripComponent  />
                        }
                        </div>
                        
                    </div>
                </div>
            </div>
            {
                currOption==0?
                    // cardType==1?
                    // <NeocredCardTable />
                    // :<M2PCardTable />
                    <CardsTable 
                    cardType={cardType}/>
                :currOption==1?
                <FastagTable />
                :currOption==2?
                <CashTable />
                :<TripTable />
            }
            </>
             }
        </div>
    )
}

export default DriverSideLowerDiv;