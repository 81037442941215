import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
// import { ToggleSlider } from "react-toggle-slider";
import ManageDriverEditCardControlModal from "../ManageDriverEditCardControlModal";
import { useSelector, useDispatch } from "react-redux";
import {
  getCardControl,
  changeCardStatus,
  editCardControl,
  newGetCardControlAction,
  newEditCardControlAction,
} from "../../../services/Store/Actions/driverslistActions";
import EnterPinModal from "../enterPinModal";
import { changePin } from "../../../services/Store/Actions/manageCardActions";
import Switch from "react-switch";
import { padding } from "@mui/system";
import { M2PCardControlAction, M2pEditCardControlAction, M2PSetPinAction } from "../../../services/Store/Actions/m2pAction";

const CardControlsModal = (props) => {
  const { loader } = useSelector((state) => state?.driverlist);
  const {m2pLoader} = useSelector((state)=>state?.m2p);
  const { open, toggleModal, title, handleClick, handleClose, btn,cardType } = props;
  const [editOpen, setEditOpen] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editBtn, setEditBtn] = useState(false);

  const [pinOpen, setPinOpen] = useState(false);
  const [pinShow, setPinShow] = useState(false);
  const [pinBtn, setPinBtn] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemStatus, setItemStatus] = useState("");
  const dispatch = useDispatch();
  const cardDetailsResponse = useSelector(
    (state) => state?.driverlist?.cardControl?.card
  );
  const [cardControlItemStatus, setCatdControlItemStatus] = useState(true);

  const m2pCardControls= useSelector((state)=>state?.m2p?.m2pCardControls);
  console.log("*********", m2pCardControls);

  // useEffect(() => {
  //   dispatch(getCardControl(props.userId));
  // }, [
  //   props.userId,
  //   cardDetailsResponse?.isActive,
  //   cardControlItemStatus,
  //   cardDetailsResponse?.atmTransactionLimit,
  // ]);

  useEffect(() => {
    if(cardType==="1")
      dispatch(newGetCardControlAction(props.userId));
    else
      dispatch(M2PCardControlAction(props.userId));
    // dispatch (
    //   changePin(props.userId)
    // );
  }, []);

  const url = useSelector((state) => state?.manageCard?.url?.details?.url);
  console.log("url", url);


  const handleSetPin = () => {
    dispatch(changePin(props.userId,(res) => {
      if (res?.data?.type === "success") {
        console.log("rrrrrrrrrrrrrrrr", res);
        window.open(res?.data?.data?.details?.url);
        // window.location.replace(res?.data?.data?.link);
        //window.open();
        //window.location.reload();
      }
    }));
    //window.open(url);
  };

  const handleSetPinM2p=()=>{
    dispatch(M2PSetPinAction(props.userId,(res) => {
      console.log("aaaaaaaaa", res);
      if (res?.data?.type === "success") {
        console.log("rrrrrrrrrrrrrrrr", res);
        window.open(res?.data?.data?.result);
        // window.location.replace(res?.data?.data?.link);
        //window.open();
        //window.location.reload();
      }
    }))
  }
  const newCardInfo = useSelector(
    (state) => state?.driverlist?.newCardInfo?.walletControl
  );

  const data = [
    {
      Name: "ATM Withdrawal",
      Edit: "Edit",
      logo: "atmWithdrawl",
      Remarks: cardType==="1"?newCardInfo?.atmTransactionLimit:m2pCardControls?.atmTransactionLimit,
      RightElement: "Toggle",
      status: cardType==="1"?newCardInfo?.atmTransactionStatus:m2pCardControls?.atmTransactionStatus,
    },
    // {
    //   Name: "UPI Transaction",
    //   Edit: "Edit",
    //   logo: "CreditCardLogo",
    //   Remarks: "Limit not set",
    //   RightElement: "Toggle",
    //   status: true
    // },
    // {
    //   Name: "Fuel Transaction",
    //   Edit: "Edit",
    //   logo: "fuelTransactionLogo",
    //   Remarks: cardDetailsResponse?.fuelTransactionLimit,
    //   RightElement: "Toggle",
    //   status: cardDetailsResponse?.fuelTransactionStatus,
    // },
    {
      Name: "Online Transaction",
      Edit: "Edit",
      logo: "onlineTransactionLogo",
      Remarks: cardType==="1"?newCardInfo?.onlineTransactionLimit:m2pCardControls?.onlineTransactionLimit,
      RightElement: "Toggle",
      status: cardType==="1"?newCardInfo?.onlineTransactionStatus:m2pCardControls?.onlineTransactionStatus,
    },
    // {
    //   Name: "Tap to Pay",
    //   Edit: "Edit",
    //   logo: "CreditCardLogo",
    //   Remarks: "Limit not set",
    //   RightElement: "Toggle",
    //   status: true
    // },
    {
      Name: "POS",
      Edit: "Edit",
      logo: "otherPosLogo",
      Remarks: cardType==="1"?newCardInfo?.posTransactionLimit:m2pCardControls?.posTransactionLimit,
      RightElement: "Toggle",
      status: cardType==="1"?newCardInfo?.posTransactionStatus:m2pCardControls?.posTransactionStatus,
    },
  ];



  function toggleModalEdit() {
    setEditOpen(!editOpen);
  }

  function handleClickEdit() {
    setEditBtn(!editBtn);
  }

  const handleCloseEdit = () => {
    setEditOpen(false);
  };

  const handleShowEdit = () => {
    setEditOpen(true);
  };

  // for enter pin

  function toggleModalPin() {
    setPinOpen(!pinOpen);
  }

  function handleClickPin() {
    setPinBtn(!pinBtn);
  }

  const handleClosePin = () => {
    setPinOpen(false);
  };

  const handleShowPin = () => {
    setPinOpen(true);
  };

  const [cardStatus, setCardStatus] = useState(!cardDetailsResponse?.isActive);
  console.log("status---->", cardDetailsResponse?.isActive);

  function handleChange() {
    //console.log("before submit", cardDetails?.driverlist?.cardControl?.card?.isActive);
    dispatch(
      changeCardStatus(props.userId, (res) => {
        if (res?.type === "success") {
          // console.log("successfullll******");

          //navigate("/Customers")
          // toggleModal();
          //props.setActiveStatus(!val)

          dispatch(getCardControl(props.userId));
          //console.log("After submit", cardDetails?.driverlist?.cardControl?.card?.isActive);
        }
      })
    );
  }

  function handleToggal(val, title, e) {
    let TransactionType = title.split(" ");
    TransactionType = TransactionType[0];
    TransactionType = TransactionType.toLowerCase();

    if (!loader) {
      dispatch(
        newEditCardControlAction(
          props.userId,
          {
            userId: props.userId,
            txnType: TransactionType,
            limitValue: "0",
          },
          (res) => {
            console.log("Testing Card Controls", res);
            if (res?.data?.type === "success") {
              console.log("success");
              dispatch(newGetCardControlAction(props.userId));
            }
          }
        )
      );
    }
  }

  function handleToggal1(val, title, e) {
    let TransactionType = title.split(" ");
    TransactionType = TransactionType[0];
    TransactionType = TransactionType.toLowerCase();

    if (!m2pLoader) {
      dispatch(
        M2pEditCardControlAction(
          {
            userId: props.userId,
            txnType: TransactionType,
            limitValue: "0",
          },
          (res) => {
            console.log("Testing Card Controls", res);
            if (res?.data?.type === "success") {
              console.log("success");
              dispatch(M2PCardControlAction(props.userId));
            }
          }
        )
      );
    }
  }


  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Bold heading1 padding32T padding32L padding16B">
              Card Controls
            </p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>

          <ModalBody>
            {/* <div className="card-wallet-icon-body3">
            <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
              <SvgImage
                name="CardLogo"
                height="50"
                width="50"
                classname="card-control-logo"
              />
            </div>

            <div>
              <p
                className="body-sub-part1"
                style={{ color: "#25252D", marginLeft: "10px" }}
              >
                Card Status
              </p>
              <p
                className="body-sub-part2-here"
                style={{
                  color: "#2E7C7B",
                  fontSize: "16px",
                  marginLeft: "15px",
                }}
              >
                {cardDetailsResponse?.isActive === true
                  ? "ACTIVE"
                  : "NOT ACTIVE"}
              </p>
            </div>
          </div> */}
            {/* <div
            className="card-control-toggle-slider"
            style={{ padding: "0 0 20 20" }}
          > */}
            {/* <ToggleSlider
              barBackgroundColor="rgba(46, 124, 123, 0.2)"
              handleBackgroundColor="white"
              barBackgroundColorActive="#2E7C7B"
              //active={cardDetailsResponse?.isActive}
              onToggle={() => {
                //setCardStatus (!cardStatus);
                dispatch(changeCardStatus(props.userId, (res) => {
                  // actions.setSubmitting(false);
                  if (res?.data?.type === "success") {
                    console.log("successfullll******");
                    //navigate("/Customers")
                    toggleModal();
                    dispatch(getCardControl(props.userId))

                  }
                }))
              }}
            /> */}
            {/* <Switch
              // disabled={active?false:true}
              checked={cardDetailsResponse?.isActive}
              onChange={() => handleChange()}
              onColor="#EBF2F2"
              onHandleColor="#2E7C7B"
              handleDiameter={24}
              uncheckedIcon={false}
              checkedIcon={false}
              activeBoxShadow={""}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              height={15}
              width={40}
              className="react-switch"
              id="material-switch"
            />
          </div> */}
            <div
              className="card-wallet-icon-body3 row"
              style={{
                background: "#fff",
                paddingTop: "2rem",
                paddingLeft: "1rem",
                paddingBottom: "1.5rem",
                // marginTop: "2rem",
                // marginLeft: "1rem",
              }}
            >
              <div className="col-2">
                <SvgImage
                  name="changeAtmPin"
                  height="43.81"
                  width="43.81"
                  classname="card-control-logo"
                  color="yellow"
                />
              </div>

              <div className="col-6">
                <div
                  className="semiBold heading2"
                  style={{ paddingBottom: "0.1rem" }}
                >
                  Change ATM Pin
                </div>
                <div>
                  {/* <div className="text3 Regular secondaryBlack " style={{}}>
                    Last updated on 31-03-2021
                  </div> */}
                </div>
              </div>
              <div className=" col-4" style={{ color: "#2E7C7B", cursor:"pointer" }}>
                <a onClick={()=>{
                  if(cardType==="1"){
                    handleSetPin();
                  }else{
                    handleSetPinM2p();
                  }
                } }>Change PIN</a>
              </div>
            </div>

            {data.map((item) => {
              return (
                <>
                  <div
                    className="card-wallet-icon-body3 row"
                    style={{ background: "#fff" }}
                  >
                    <div
                      className="col-2"
                      style={{ paddingTop: "2rem", paddingLeft: "2rem" }}
                    >
                      <SvgImage
                        name={item.logo}
                        height="43.81"
                        width="43.81"
                        classname="card-control-logo"
                      />
                    </div>
                    <div
                      className="fontCardControl col-6"
                      style={{
                        display: "block",
                        paddingTop: "2rem",
                        paddingLeft: "25px",
                      }}
                    >
                      <div className="semiBold heading2">{item.Name}</div>
                      <div style={{ display: "flex" }}>
                        <div className="text2 Regular">₹{item.Remarks}</div>
                        <div></div>
                        {/* <div
                          onClick={() => {
                            if (item.status){
                              handleShowEdit();
                              setItemName(item.Name);
                            }
                          }}
                          className="card-control-value-edit"
                          style={{cursor:(item.status? "pointer":"default")}}
                        >
                          {" "}
                          <svg
                            style={{ marginRight: "6px" }}
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.18754 0.942531C7.38092 0.739219 7.64322 0.625 7.91671 0.625C8.05213 0.625 8.18622 0.653042 8.31133 0.707524C8.43644 0.762006 8.55012 0.841861 8.64588 0.942531C8.74163 1.0432 8.81759 1.16271 8.86941 1.29424C8.92124 1.42578 8.94791 1.56675 8.94791 1.70912C8.94791 1.85149 8.92124 1.99246 8.86941 2.12399C8.81759 2.25553 8.74163 2.37504 8.64588 2.47571L2.56946 8.86394L0.625 9.375L1.11111 7.33076L7.18754 0.942531Z"
                              stroke="#2E7C7B"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {item.Edit}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-control-toggle-slider padding8T col-4"
                    style={{ color: "#2E7C7B" }}
                  >
                    {/* <ToggleSlider
                    active={cardDetailsResponse?.isActive===false? false:item.status}
                    style={{ border: "1px solid black" }}
                    onToggle={(e) => dispatch(editCardControl({
                      userId: props.userId,
                      PIN: "1111",
                      limitAmount: item.Remarks,
                      expenseType: item.Name,
                      isActive: !(item.status)
                    }))}
                    barBackgroundColor="rgba(46, 124, 123, 0.2)"
                    handleBackgroundColor="white"
                    barBackgroundColorActive="#2E7C7B"
                  /> */}
                    <Switch
                      // disabled={active?false:true}
                      checked={item.status}
                      // onChange={(e) => {
                      //   setCatdControlItemStatus(false);
                      //   setItemStatus(item.status);
                      //   setItemName(item.Name);
                      //   handleShowPin();
                      // dispatch(
                      //   editCardControl(
                      //     {
                      //       userId: props.userId,
                      //       PIN: "3333",
                      //       // limitAmount: item.Remarks,
                      //       expenseType:
                      //         item.Name == "ATM Withdrawal"
                      //           ? "atm"
                      //           : item.Name == "Fuel Transaction"
                      //           ? "fuel"
                      //           : item.Name == "Online Transaction"
                      //           ? "online"
                      //           : item.Name == "Other POS"
                      //           ? "pos"
                      //           : null,
                      //       isActive: !item.status,
                      //     },
                      //     (res) => {
                      //       console.log("bhai here", res);
                      //       if (res?.status == 200) {
                      //         // console.log("successfullll******");

                      //         //navigate("/Customers")
                      //         // toggleModal();
                      //         //props.setActiveStatus(!val)

                      //         dispatch(getCardControl(props.userId));
                      //         //console.log("After submit", cardDetails?.driverlist?.cardControl?.card?.isActive);
                      //       }
                      //     }
                      //   )
                      // <enterPinNModal
                      //   userId={props.userId}
                      //   open={editOpen}
                      //   toggleModal={toggleModalEdit}
                      //   // title="Logipe Account"
                      //   name={itemName}
                      //   handleClick={handleClickEdit}
                      //   handleClose={handleCloseEdit}
                      //   btn={editBtn}
                      // />;
                      // }}
                      onChange={(e) =>{
                        if(cardType==="1")
                          handleToggal(item.status, item.Name, e);
                        else
                          handleToggal1(item.status, item.Name, e);
                      } }
                      onColor="#EBF2F2"
                      onHandleColor="#2E7C7B"
                      handleDiameter={24}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      activeBoxShadow={""}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      height={15}
                      width={40}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </>
              );
            })}
            {/* <div className="space-div"></div> */}
          </ModalBody>
        </div>
      </Modal>
      <ManageDriverEditCardControlModal
        userId={props.userId}
        open={editOpen}
        toggleModal={toggleModalEdit}
        // title="Logipe Account"
        name={itemName}
        handleClick={handleClickEdit}
        handleClose={handleCloseEdit}
        btn={editBtn}
        cardType={props.cardType}
      />
      <EnterPinModal
        itemName={itemName}
        itemStatus={itemStatus}
        userId={props.userId}
        open={pinOpen}
        toggleModal={toggleModalPin}
        // title="Logipe Account"
        name={itemName}
        handleClick={handleClickPin}
        handleClose={handleClosePin}
        btn={pinBtn}
      />
    </div>
  );
};
export default CardControlsModal;
