import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import LastTrip from "./LastTrip";
import NoTripData from "./NoTripData";


const TripComponent=(props)=>{


    const tripList= useSelector((state)=>state?.trip?.tripsByUserId);
    console.log("trippsss", tripList);
    return(
        <>
        {
            tripList?.rows?.[0]?
            <LastTrip />
            :<NoTripData />
        }
            {/*  */}
            
        </>
    )
}

export default TripComponent;