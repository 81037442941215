import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Modal, ModalBody } from "reactstrap";
import "./index.css";
import SvgImage from "../../../Icons/Icons";
import { Formik } from "formik";
import * as Yup from "yup";
import { fetchBalance } from "../../../../services/Store/Actions/manageCardActions";
import SuccessModal from "./SuccessModal";
const validationSchema = Yup.object().shape({
  amount: Yup.string()
    .required("Required")
    .matches(/^[0-9]$/, "amount must be a number"),
});
function PaymentModal(props) {
  const {
    open,
    onHide,
    toggleModal,
    handleClose,
    vendorData,
    onSubmit,
    mode,
    setMode,
    remarks,
  } = props;
  const { ownerBalance } = useSelector((state) => state?.manageCard);
  const [selectAll, setSelectAll] = useState(true);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [amount, setAmount] = useState("");
  const optionList = [
    "Credit",
    "Debit",
    "Closing Balance",
    "Remarks",
    "Fastag Wallet",
    "Card",
    "UPI",
    "LogiMart",
    "Insurance",
    "Subscription Charges",
    "Recharge",
    "Cashback Redeemed",
  ];
  console.log("pppp000", vendorData);
  useEffect(() => {
    dispatch(fetchBalance());
  }, []);

  const [checkedState, setCheckedState] = useState(
    new Array(optionList.length).fill(true)
  );

  const handleSelect = (position) => {
    if (selectAll) {
      setSelectAll(false);
    }
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };
  return (
    <Modal
      isOpen={open}
      onHide={onHide}
      toggle={toggleModal}
      className="Payment-Amount-Modal settingModalMargin"
      //   toggle={toggle}
      aria-labelledby="myModalLabel2"
    >
      <div
        className="closeRemove"
        onClick={() => {
          // Router.reload();
          handleClose();
        }}
      >
        <SvgImage name="CloseButtonLogo" />
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          amount: amount,
          remarks: remarks || "",
        }}
        // validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log("herere");
          if (values?.amount > 0) onSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div className="Ent-Pin-Box " style={{ textAlign: "center" }}>
                <SvgImage name={"userImage"} />
                <div className="ENT-Security-Pin-Title">{vendorData?.name}</div>
                <p className="ENT-Security-Pin-subTitle">{vendorData?.phone}</p>
                <p className="heading2">
                  Account Balance: ₹{ownerBalance?.instrument_balance?.balance}
                </p>
                <div>
                  <input
                    type={"number"}
                    id={`amount`}
                    className="Amount-Entry-Field"
                    name={`amount`}
                    value={values?.amount}
                    touched={touched?.amount}
                    errors={errors?.amount}
                    onBlur={() => {
                      //   dispatch(sendAnalitics("EnterPhone_web", values));
                      handleBlur("amount");
                    }}
                    onChange={handleChange(`amount`)}
                    placeholder={"₹0"}
                    autofocus
                  />
                  <div className="row justify-content-center">
                    <div className="col-3">
                      <button
                        className="greenOutlineButton"
                        onClick={() => {
                          setAmount("5000");
                        }}
                        style={{
                          marginTop: "20px",
                          height: "25px",
                          width: "100%",
                        }}
                      >
                        <text className="Edit-Vendor-Details" style={{}}>
                          +5000
                        </text>
                      </button>
                    </div>
                    <div className="col-3">
                      <button
                        className="greenOutlineButton"
                        onClick={() => {
                          setAmount("10000");
                        }}
                        style={{
                          marginTop: "20px",
                          height: "25px",
                          width: "100%",
                        }}
                      >
                        <text className="Edit-Vendor-Details" style={{}}>
                          +10000
                        </text>
                      </button>
                    </div>
                    <div className="col-3">
                      <button
                        className="greenOutlineButton"
                        onClick={() => {
                          setAmount("50000");
                        }}
                        style={{
                          marginTop: "20px",
                          height: "25px",
                          width: "100%",
                        }}
                      >
                        <text className="Edit-Vendor-Details" style={{}}>
                          +50000
                        </text>
                      </button>
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`input-body Remarks-With_Bg`}
                    name="remarks"
                    onChange={handleChange("remarks")}
                    value={values.remarks}
                    onBlur={() => {
                      handleBlur("remarks");
                    }}
                    touched={touched.remarks}
                    errors={errors.remarks}
                    placeholder="Enter remarks"
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              {vendorData?.isLogipeVendor == 0 ? (
                vendorData?.vpaId && vendorData?.bankAccountNumber ? (
                  <div style={{ height: "100%" }}>
                    <div
                      className="heading2 Bold"
                      style={{ textAlign: "center" }}
                    >
                      Select Mode
                    </div>

                    <div className="row">
                      <div className="col-6" style={{ textAlign: "center" }}>
                        <button
                          className={
                            mode == 0
                              ? "greenButtonSelect"
                              : "greenOutlineButtonSelect"
                          }
                          style={{
                            marginTop: "12px",
                            height: "25px",
                          }}
                          onClick={() => {
                            // setActive(0)
                            setMode(0);
                          }}
                        >
                          <text
                            className={
                              mode == 0
                                ? "Pay-Vendor-Details"
                                : "Edit-Vendor-Details"
                            }
                            style={{ fontSize: "18px" }}
                          >
                            Bank Transfer
                          </text>
                        </button>
                      </div>
                      <div className="col-6" style={{ textAlign: "center" }}>
                        <button
                          className={
                            mode == 1
                              ? "greenButtonSelect"
                              : "greenOutlineButtonSelect"
                          }
                          style={{
                            marginTop: "12px",
                            height: "25px",
                          }}
                          onClick={() => {
                            setMode(1);
                          }}
                        >
                          <text
                            className={
                              mode == 1
                                ? "Pay-Vendor-Details"
                                : "Edit-Vendor-Details"
                            }
                            style={{ fontSize: "18px" }}
                          >
                            UPI Transfer
                          </text>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </FormGroup>

            <FormGroup
              style={{
                justifyContent: "center",
                textAlignLast: "center",
              }}
            >
              <button
                type="submit"
                className="greenButton Verify-Pin-Btn"
                style={{
                  margin: "0 0 0 0",
                  background:
                    loader ||
                    ownerBalance?.instrument_balance?.balance < values.amount
                      ? "grey"
                      : "#485B7C",
                }}
                disabled={
                  loader ||
                  ownerBalance?.instrument_balance?.balance < values.amount
                    ? true
                    : false
                }
                onClick={handleSubmit}
                onSubmit={handleSubmit}
              >
                {loader ? "Please Wait" : "Submit"}
              </button>
            </FormGroup>
            <div></div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default PaymentModal;
