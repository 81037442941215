import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { rechargeFastag } from "../../../services/Store/Actions/manageVehicleAction";
import { Formik } from "formik";
import PinInput from "react-pin-input";
import SvgImage from "../../Icons/Icons";
import "./index.css";
import * as Yup from "yup";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";

function RechargeFastagButttonModal(props) {
  const dispatch = useDispatch();
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    balance,
    amount,
    fastagBal,
    remarks,
    status,
    tripKey,
    itemTemp
  } = props;

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Only Numbers are allowed")
      .required("Required")
      .min(1, "Amount should be greater than 1"),
    pin: Yup.string()
      .required("Required")
      .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
  });
  const { loader } = useSelector((state) => state?.manageVehicle);

  console.log("ok", props.fleetId);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons1 settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Fastag Wallet</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="card-wallet-icon-body3">
              <SvgImage
                name="tollBooth"
                height="43.81"
                width="43.81"
                classname="card-wallet-logo-BankLogo"
              />
              <div>
                <p className="body-sub-part1">Fastag Wallet Balance</p>
                <p className="body-sub-part2">{"₹" + balance}</p>
              </div>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                amount: amount || "",
                pin: "",
                remark: remarks || "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                dispatch(
                  sendAnalitics("recharge_FT_fromFtW_API_Web", {
                    amt: values.amount,
                    pin: values.pin,
                    remark: values.remark,
                    fleetId: props.fleetId,
                  })
                );
                const data = {
                  fleetId: props.fleetId,
                  amount: values.amount,
                  pin: values.pin,
                  remark: values.remark,
                  tripKey : tripKey ? tripKey : "",
                  tripId : itemTemp?.tripId ? itemTemp?.tripId : ""
                };
                console.log("valuessss>>>>>>", data);
                if (status) {
                  let qqq = status == 1 ? "Settle" : "advance";
                  data["sattlementStatus"] = qqq;
                }
                dispatch(
                  rechargeFastag(data, (response) => {
                    console.log("DATAREQ", data);
                    if (response?.type == "success") {
                      console.log("SuccessFul", response);
                      toggleModal();
                      dispatch(
                        sendAnalitics("Success_recharge_FT_fromFtW_Web", {
                          amt: values.amount,
                          pin: values.pin,
                          remark: values.remark,
                          fleetId: props.fleetId,
                        })
                      );
                    }
                  })
                );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <label className="sub-head-3 ">Vehicle Number</label>
                    </div>
                    <div className="row">
                      <div className="col-10">
                        <p className="input-body-3 ">{props.no}</p>
                      </div>

                      <div className="col-2">
                        <SvgImage name="lockLogo" />
                      </div>
                    </div>

                    <div className="input-amount-for-link"></div>
                    <p className="current-fastag-balance-for-recharge-wallet-id">
                      {`Current Fastag Balance: ₹ ${fastagBal}`}
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-title-recharge-fastag-Id">
                        Enter amount
                      </p>
                    </div>
                    <label for="html" className="label-body">
                      ₹
                    </label>
                    <input
                      type="text"
                      id="html"
                      className="input-body-1"
                      name="amount"
                      value={values.amount}
                      touched={touched.amount}
                      errors={errors.amount}
                      onBlur={handleBlur("amount")}
                      onChange={handleChange("amount")}
                      placeholder="Enter amount"
                    ></input>
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-head-3">Remarks</p>
                    </div>
                    <label for="html" className="label-body"></label>
                    <input
                      type="text"
                      id="remark"
                      className="input-body"
                      style={{ width: "70%" }}
                      name="remark"
                      onChange={handleChange("remark")}
                      value={values.remark}
                      onBlur={() => {
                        handleBlur("remark");
                      }}
                      touched={touched.remark}
                      errors={errors.amoremarkunt}
                      placeholder="Enter Remark"
                    ></input>
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.remark && touched.remark ? (
                        <div>*{errors.remark}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div>
                      <div className="sub-title-1">
                        <span class="circle-logo">3</span>
                        <p className="sub-head-4">Enter 4-digit Security Pin</p>
                      </div>
                      <p className="sub-head-body">
                        Security pin is needed to confirm payment
                      </p>
                      <PinInput
                        length={4}
                        initialValue=""
                        secret
                        onChange={handleChange("pin")}
                        type="numeric"
                        value={values.pin}
                        onBlur={handleBlur("pin")}
                        touched={touched.pin}
                        errors={errors.pin}
                        inputMode="number"
                        style={{
                          padding: "10px",
                          marginLeft: "96px",
                          marginTop: "28px",
                        }}
                        inputStyle={{
                          border: "1px solid #A7A7A7",
                          borderRadius: "8px",
                          marginRight: "20px",
                        }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                    </div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.pin && touched.pin ? (
                        <div>*{errors.pin}</div>
                      ) : null}
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="butt-recharge"
                        disabled={loader ? true : false}
                        style={{ marginTop: "60px" }}
                        onClick={() => {
                          dispatch(
                            sendAnalitics("recharge_FT_fromFtW_Web", {
                              amt: values.amount,
                              pin: values.pin,
                              remark: values.remark,
                              fleetId: props.fleetId,
                            })
                          );
                        }}
                      >
                        {loader ? "Please Wait" : "Recharge Fastag ID"}
                      </button>
                    </div>
                  </FormGroup>
                  {/* <div className="space-div"></div> */}
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
export default RechargeFastagButttonModal;
