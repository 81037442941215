import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";
import { getLogicoinsAction } from "../../../../../services/Store/Actions/ecomAction";
import SvgImage from "../../../../Icons/Icons";

function LogicoinOption(props){
  const {HomeMrp, HomeDiscount,setCoinVal,setCoinStatus,coinStatus, coinsFromFinalAmt}= props;
  //const [status, setStatus]= useState(0);
  const coinsVal= useSelector((state)=>state?.ecom?.coinsVal?.data);

  let coins = coinsVal==undefined?0: coinsVal==null?0:Math.floor((coinsVal?.fuelCoinsBalance-coinsVal?.redeemedCoins)<0?0:coinsVal?.fuelCoinsBalance-coinsVal?.redeemedCoins);
  console.log("coinsVal",coinsVal);
  
  // let otherCoinVal= (0.025 * Number(HomeMrp));

  console.log('yegygeygrygrgurhguhgu', props?.coinsFromFinalAmt);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getLogicoinsAction());
    // let token = localStorage.getItem("_token");
    // console.log("token in cart page",token);
    // if(token!=null){
    //   setStatus(1);
      
    // }
  },[])

  useEffect(()=>{
   console.log("otherCoinVal coinsss---", coinsFromFinalAmt, coins);
    // console.log("coinsss---", (otherCoinVal>coins)?coins:otherCoinVal);
    setCoinVal(coinsFromFinalAmt>coins?coins:coinsFromFinalAmt)
  },[coins,coinsFromFinalAmt]);

  const handleCheck=(e)=>{
    const { checked } = e.target;
    setCoinStatus(checked);
  }
  return (
    <div className="cartItem" style={{borderRadius:"0.7rem"}}>
        <div className="coinInfo" style={{borderRadius:"0.7rem 0.7rem 0 0"}}>Save extra by using LogiCoins</div>
        <div className="padding16">
            <div className="flexStyling semiBold heading2">
                <input
                    type="checkbox"
                    name="logiCoin"
                    onChange={handleCheck}
                    value={coinStatus}
                   
                    />
                <div className="padding16L" style={{paddingTop:"0"}}>Pay using LogiCoins</div>
            </div>
            <div className="greyFont2 Regular textDiv padding32L">Available Balance:   <SvgImage name="coins" />  {(coinsFromFinalAmt>coins?coins:coinsFromFinalAmt)}</div>
        </div>
    </div>
  );
}

export default LogicoinOption;