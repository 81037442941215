import React, { Component, useEffect, useState } from "react";
import {
    Button,
    Form,
    FormGroup,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { sendOtp } from "../../../../../services/Store/Actions/LoginAction";


const EnterNumbers=(props)=>{
    const [cart, setCart] = useState();

    const {setFirstName, setMiddleName, setLastName}= props;
    const { loader } = useSelector((state) => state?.ecom)
    console.log("loader----", loader)
    const dispatch = useDispatch();
    // const data=[{
    //   name:"pr1"
    // },{
    //   name:"pr1"
    // },{
    //   name:"pr1"
    // }]

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required("First name is required"),
        lastName: Yup.string()
            .required("Last name is required"),
        phoneNumber: Yup.string()
            .required("Phone number must contain 10 digits")
            .matches(
                /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                "Phone number must contain 10 digits"
            ),
    });

    return (
        <div className="row whiteBackground col-12" style={{ margin: "0" }}>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                className="col-12"
                initialValues={{
                    firstName: "",
                    middleName:"",
                    lastName:"",
                    phoneNumber: "",
                }}
                onSubmit={(values, actions) => {
                    //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
                    console.log("valuessss", values);
                    dispatch(
                        sendOtp(
                            {
                                phone: values.phoneNumber,
                                resend: "any",
                            },
                            (res) => {
                                //actions.setSubmitting(false);
                                console.log("SUCCESS", res);
                                if (res?.data?.type === "success") {
                                    console.log("SUCCESS otp send");
                                    setFirstName(values.firstName.trim());
                                    setMiddleName(values.middleName.trim());
                                    setLastName(values.lastName.trim());
                                    props.setPhone(values.phoneNumber);
                                    props.setLoginStatus(true);
                                }
                            }
                        )
                    );
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <Form onSubmit={handleSubmit} className="InputForm" style={{paddingTop:"3rem"}}>
                        <FormGroup className="formPosition">
                            <div className="Regular text3 greyFont">Welcome! 👋</div>
                            <div className="heading1 semiBold">Log in to your account</div>
                            <div className="Regular text3 padding24T headText2 padding8B">
                                First Name
                            </div>
                            <div className="searchBox" style={{ width: "100%" }}>
                                <input
                                    type="text"
                                    id="firstName"
                                    className="searchInput Regular text3"
                                    name="firstName"
                                    value={values.firstName}
                                    touched={touched.firstName}
                                    errors={errors.firstName}
                                    onBlur={handleBlur("firstName")}
                                    onChange={handleChange("firstName")}
                                    style={{
                                        textAlign: "left",
                                        marginLeft: "0px",
                                        width: "95%",
                                    }}
                                    placeholder="Enter first name"
                                    autoFocus
                                ></input>
                            </div>
                            <div
                                className="text4"
                                style={{
                                    // margin: "7px 0 0px 50px",
                                    color: "red",
                                    textAlign: "left",
                                }}
                            >
                                {errors.firstName && touched.firstName ? (
                                    <div>{errors.firstName}</div>
                                ) : null}
                            </div>
                            <div className="Regular text3 padding24T headText2 padding8B">
                            Middle Name
                            </div>
                            <div className="searchBox" style={{ width: "100%" }}>
                                <input
                                    type="text"
                                    id="middleName"
                                    className="searchInput Regular text3"
                                    name="middleName"
                                    value={values.middleName}
                                    touched={touched.middleName}
                                    errors={errors.middleName}
                                    onBlur={handleBlur("middleName")}
                                    onChange={handleChange("middleName")}
                                    style={{
                                        textAlign: "left",
                                        marginLeft: "0px",
                                        width: "95%",
                                    }}
                                    placeholder="Enter middle name"
                                    autoFocus
                                ></input>
                            </div>
                            <div
                                className="text4"
                                style={{
                                    // margin: "7px 0 0px 50px",
                                    color: "red",
                                    textAlign: "left",
                                }}
                            >
                                {errors.middleName && touched.middleName ? (
                                    <div>{errors.middleName}</div>
                                ) : null}
                            </div>
                            <div className="Regular text3 padding24T headText2 padding8B">
                            Last Name
                            </div>
                            <div className="searchBox" style={{ width: "100%" }}>
                                <input
                                    type="text"
                                    id="lastName"
                                    className="searchInput Regular text3 "
                                    name="lastName"
                                    value={values.lastName}
                                    touched={touched.lastName}
                                    errors={errors.lastName}
                                    onBlur={handleBlur("lastName")}
                                    onChange={handleChange("lastName")}
                                    style={{
                                        textAlign: "left",
                                        marginLeft: "0px",
                                        width: "95%",
                                    }}
                                    placeholder="Enter last name"
                                    autoFocus
                                ></input>
                            </div>
                            <div
                                className="text4"
                                style={{
                                    // margin: "7px 0 0px 50px",
                                    color: "red",
                                    textAlign: "left",
                                }}
                            >
                                {errors.lastName && touched.lastName ? (
                                    <div>{errors.lastName}</div>
                                ) : null}
                            </div>
                            <div className="Regular text3 padding24T headText2 padding8B">
                                Phone number
                            </div>
                            <div className="searchBox" style={{ width: "100%" }}>
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    className="searchInput Regular text3"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    touched={touched.phoneNumber}
                                    errors={errors.phoneNumber}
                                    onBlur={handleBlur("phoneNumber")}
                                    onChange={handleChange("phoneNumber")}
                                    style={{
                                        textAlign: "left",
                                        marginLeft: "0px",
                                        width: "95%",
                                    }}
                                    placeholder="Enter phone number"
                                    maxLength="10"
                                    autoFocus
                                ></input>
                            </div>
                            <div
                                className="text4"
                                style={{
                                    // margin: "7px 0 0px 50px",
                                    color: "red",
                                    textAlign: "left",
                                }}
                            >
                                {errors.phoneNumber && touched.phoneNumber ? (
                                    <div>{errors.phoneNumber}</div>
                                ) : null}
                            </div>
                            <Button
                                type="submit"
                                className="loginButton semiBold text2"
                                style={{
                                    marginTop: "1rem",
                                    background:
                                        values.phoneNumber.length < 10
                                            ? "#EFEFEF"
                                        :values.firstName==""
                                        ?"#EFEFEF"
                                        :values.lastName==""
                                        ?"#EFEFEF"
                                            : loader
                                                ? "#EFEFEF"
                                                : "#485B7C",
                                    color:
                                        values.phoneNumber.length < 10
                                            ? "#B5B5B5"
                                            :values.firstName==""
                                            ?"#B5B5B5"
                                            :values.lastName==""
                                            ?"#B5B5B5"
                                            : loader
                                                ? "#B5B5B5"
                                                : "#ffffff",
                                }}
                                disabled={loader ? true : false}
                            >
                                {loader ? "Please Wait" : "Send OTP"}
                            </Button>
                            {/* <div className="bottomTextLogin padding16T Regular text4">
              <span
                className="bottomTextSpan Medium"
                onClick={() => {
                  props.handleTermShow();
                }}
              >
                T&C{" "}
              </span>{" "}
              and{" "}
              <span
                className="bottomTextSpan Medium"
                onClick={() => {
                  props.handlePrivacyShow();
                }}
              >
                Privacy Policy
              </span>{" "}
              apply
            </div> */}
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </div>
    );
}


export default EnterNumbers;