import React, { useEffect, useState } from "react";
import "./index.css";
import SvgImage from "../Icons/Icons";
import VendorEmpty from "./Components/VendorEmpty";
import AddNewVendor from "./Components/AddNewVendor";
import VendorList from "./Components/VendorList";
import TransactionHistory from "./Components/TransactionHistory";
import VendorDetails from "./Components/VendorDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanVendorList,
  getVendorAction,
  vendorTransactionAction,
} from "../../services/Store/Actions/beneficiaryAction";
import { useNavigate } from "react-router-dom";

const Vendor = (props) => {
  const { path } = props;
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const [showVendor, setShowVendor] = useState(false);
  const [vendorData, setVendorData] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { vendorList, vendorCount } = useSelector((state) => state?.beneficiary);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips,setIsTrips] = useState(0); 
  const [isCustomers,setIsCustomers] = useState(0); 
  const [isVendor,setIsVendor] = useState(0); 
  useEffect(()=>{
    setIsTrips(authUser?.company?.isTrips && authUser?.user?.type==2?3: authUser?.company?.isTrips && managerControlList?.tripAccess?managerControlList?.tripAccess:0)
    setIsCustomers(authUser?.company?.isCustomers && authUser?.user?.type==2?3: authUser?.company?.isCustomers && managerControlList?.customerAccess?managerControlList?.customerAccess:0)
    setIsVendor(authUser?.company?.isVendor && authUser?.user?.type==2?3: authUser?.company?.isVendor && managerControlList?.vendorAccess?managerControlList?.vendorAccess:0)
  },[authUser,managerControlList])

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    dispatch(cleanVendorList());
    dispatch(
      getVendorAction({
        isLogipeVendor: path == "0" || path == "1" || path == "2" ? "1" : "0",
        limit: 7,
        offset: 0,
        search: search
      })
    );
    return () => {
      dispatch(cleanVendorList());
    };
  }, [path,search]);
  useEffect(() => {
    setShowVendor(false);
    setShowEmptyScreen(false);
    setVendorData({});
  }, [path]);

  const onPress = () => {
    if(isVendor==3) setShow(true);
  };
  const backPress = () => {
    setShow(false);
  };
  console.log("path", path);
  return (
    <div
      className="container topMargin"
      style={{
        height: "100%",
        marginLeft: "270px",
        maxWidth: windowWidth - 360,
      }}
    >
      {path == "0" || path == "1" ? (
        (vendorCount > 0 || search != '') ? (
          showVendor ? (
            <VendorDetails vendorData={vendorData} path={path} setShowVendor={setShowVendor}/>
          ) : (
            <VendorList
              onClickItem={(val) => {
                console.log("dsdasdasadas");
                setShowVendor(true);
                setVendorData(val);
              }}
              path={path}
              setShowEmptyScreen={setShowEmptyScreen}
              setSearch={setSearch}
              search={search}
            />
          )
        ) : show ? (
          <AddNewVendor backPress={backPress} path={path}/>
        ) : (
          <VendorEmpty
            onPress={onPress}
            path={path}
            setShowEmptyScreen={setShowEmptyScreen}
          />
        )
      ) : path == "2" ? (
        <TransactionHistory
          isLogipeVendor={path == "0" ||path == "1" || path == "2" ? "1" : "0"}
          search={search}
          setSearch={setSearch}
        />
      ) :(path == "3" || path == "4") ? (
        (vendorCount > 0 || search != '') ? (
          showVendor ? (
            <VendorDetails vendorData={vendorData} path={path} setShowVendor={setShowVendor}/>
          ) : (
            <VendorList
              onClickItem={(val) => {
                console.log("dsdasdasadas");
                setShowVendor(true);
                setVendorData(val);
              }}
              path={path}
              setShowEmptyScreen={setShowEmptyScreen}
              setSearch={setSearch}
              search={search}
            />
          )
        ) : show ? (
          <AddNewVendor backPress={backPress} path={path} />
        ) : (
          <VendorEmpty
            onPress={onPress}
            path={path}
            setShowEmptyScreen={setShowEmptyScreen}
          />
        )
      ) : path == "5" ? (
        <TransactionHistory
          isLogipeVendor={path == "0" || path == "1" || path == "2" ? "1" : "0"}
          search={search}
          setSearch={setSearch}
        />
      ) : (
        <VendorEmpty
          onPress={onPress}
          path={path}
          setShowEmptyScreen={setShowEmptyScreen}
        />
      )}
    </div>
  );
};

export default Vendor;
