import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../../../../../Icons/Icons";




function ExistingBeneficiaryInfo(props) {

  const { setStatus } = props;

  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state?.omc);
  console.log("button status", loader);

  const validationSchema = Yup.object().shape({
    // nickName: Yup.number()
    //   .typeError("Only Numbers are allowed")
    //   .min(1, "Amount should be greater than 1")
    //   .required("Amount is required"),
    nickName: Yup.string()
      .required("Nick name is required")
    //   .test(
    //     "noDefaultValue",
    //     "Please select expense type",
    //     (val) => val != "default"
    //   ),
    // dob: Yup.string().required("Expense Date is required"),
    // remarks: Yup.string().required("Remark is required"),
  });

  const driverBeneficiaryList=useSelector((state)=>state?.omc?.driverBeneficiaryList);

  return (
    <>
        <div className="padding24B padding24T padding16L" style={{paddingRight:"3rem"}}>
            <div className="ticketBox row margin0 padding16" style={{borderRadius:"0.5rem"}}>
                <div className="col-1">
                    <SvgImage name="peopleLogo1"/>
                </div>
                <div className="Medium heading3 col-10 padding24L flexStyling" style={{alignItems:"center"}} >
                Request to change beneficiary
                </div>
                <div className="col-1">
                    <SvgImage name="nextArrowSmall" />
                </div>
            </div>
        </div>
        <div className="Bold heading2 padding16L padding32B">Existing Beneficiary</div>
        <div className="row margin0 padding16 padding32B" >
            <div className="col-2 semiBold heading2">
                <div className="greenCirleInitial">{driverBeneficiaryList?.rows?.[0]?.name?.[0]}</div>
            </div>
            <div className="col-5 padding8L flexStyling" style={{alignItems:"center"}}>
                <div className="semiBold heading3">{driverBeneficiaryList?.rows?.[0]?.name?driverBeneficiaryList?.rows?.[0]?.name?.length>30?
                driverBeneficiaryList?.rows?.[0]?.name.split(0,30)+"...":driverBeneficiaryList?.rows?.[0]?.name:"NA"}</div>
            {/* <div className="Medium heading4 greyFont"></div> */}
            </div>
            <div className="col-5 centralItems flexStyling">
                <div className="semiBold heading3">{driverBeneficiaryList?.rows?.[0]?.accountNumber?driverBeneficiaryList?.rows?.[0]?.accountNumber:"NA"}</div>
            </div>
        </div>
        <div style={{position:"absolute", bottom:"2rem"}}>
            <button
                type="submit"
                className="butt-4"
                style={{
                margin: "15% 0 0 1.5rem",
                background:"#2e7c7b",
                left:"30px"
                }}
                onClick={()=>{
                    setStatus(1);
                }}
                // disabled={loader ? true : false}
            >Proceed
                {/* {loader ? "Please Wait" : "Continue"} */}
            </button>
        </div>
    </>
  );
}

export default ExistingBeneficiaryInfo;
