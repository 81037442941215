import React from "react";
import "./index.css";
import { IoIosAddCircle } from "react-icons/io";

function AddNewCustomerButtonV2(props) {
  const name = props.name == null ? "Add New Customer" : props.name;
  return (
    <div className="AddNewCustomerBox2 row" style={{ margin: 0,padding: "8px 0 0 0"}}>
      <div className="AddNewCustomerIcon col-3">
          <IoIosAddCircle size={20} color="#4D5073" />
      </div>
      <div className="col-9 fontNM13" style={{ color: "#4D5073",padding : "2px 0 0 5px" }} >
        {name}
      </div>
    </div>
  );
}
export default AddNewCustomerButtonV2;
