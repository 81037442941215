import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import "./index.css";
import { useDispatch } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { useNavigate } from "react-router-dom";

function NotesModal(props) {
  const { toggle, modal, setModal, textNote, setTextNote } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const handleClose = () => {
    setModal(!modal);
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          // style={{height : "80vh"}}
          className="transactionModalBody"
          //   style={{ height: "80vh" }}
          //   style={{borderRadius : "10px"}}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            style={{ marginTop: " 20px" }}
            //   className="PGCollectionBox"
          >
            {" "}
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Add Notes
            </div>
            <div
              className="PGCollectiontText"
              style={{
                marginLeft: "50px",
                marginRight: "50px",
                marginTop: " 20px",
                marginBottom: "30px",
              }}
            >
              <textarea
                // type="textarea"
                cols={4}
                id="Notes"
                rows={6}
                className={`input-body-new`}
                name="notes"
                onChange={(e) => {
                  console.log("====vvvvv", e.target.value);
                  setTextNote(e.target.value);
                }}
                value={textNote}
                placeholder="Add your text note"
              />
            </div>
          </div>
          <div
            style={{
              padding: "0 0 10px 0",
              textAlign: "center",
            }}
          >
            <Button
              type="submit"
              style={{ width: "160px", borderRadius: "5px" }}
              className="generateOtp textAddCust Medium btn btn-secondary"
              onClick={handleClose}
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default NotesModal;
