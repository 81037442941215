import React from "react";
import './index.css'
import SvgImage from "../../../Icons/Icons";
import OwnerTransactionHistoryTable from "../OwnerTransactionHistoryTable";
import DetailCardHeader from "../detailCardHeader";
function ManageLogipeCardBottom(props){
    console.log("reeeeee")
      return(
          <div className="manage-logipe-card-bottom-box">
              <div className="manage-logipe-card-header">
                  <div className="manage-logipe-card-details-header-icon ">
                  <SvgImage name="logipeCardTransactionLogo" width='43'height='43'/>
                  </div>
              <div className="manage-logipe-card-details-header ">
           LogiPe Card Transactions
           </div>
              </div>
         
           <div className="ManageLogipeCardDetailsPos">
           <div style={{paddingLeft:"12px",paddingTop:"20px"}}>
            {/* <DetailCardHeader/> */}
           </div>
           <div style={{position:"relative",top:"-20px"}}>
           <OwnerTransactionHistoryTable
           userId={props.userId}
           />

           </div>
                      </div>
           {/* <div className="ManageLogipeDetailsCardMain">
           <ManageLogipeCardDetailsCard/>
           </div> */}
          </div>
      )
}
export default ManageLogipeCardBottom