import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import SvgImage from "../../Icons/Icons";
import "./index.css";

import { useDispatch, useSelector } from "react-redux";
import {
  getAdvanceDriverList,
  getExpenseWalletSummary,
} from "../../../services/Store/Actions/driverslistActions";
import {
  fetchBalance,
  newWithdrawMoneyAction,
} from "../../../services/Store/Actions/manageCardActions";

import { withdrawFromCard } from "../../../services/Store/Actions/manageCardActions";
import {
  M2pCardWithdrawAction,
  M2PTransactionsAction,
} from "../../../services/Store/Actions/m2pAction";
import {toastStatus, sendAnalitics } from "../../../services/Store/Actions/appActions";
import ToastV2 from "../../subComponents/toastV2";
import Toast from "../../subComponents/toast";
const ManageDriverWithdrawBalance = (props) => {
  const dispatch = useDispatch();
  const loader1 = useSelector((state) => state?.manageCard?.loader);
  const loader2 = useSelector((state) => state?.m2p?.loader);
  const toastObject = useSelector((state) => state?.app?.toastObject);

  const { open, toggleModal, handleClose, name, userId, driverName ,search, offset} = props;
  useEffect(() => {
    dispatch(fetchBalance());
  }, [userId]);

  const { ownerBalance } = useSelector((state) => state?.manageCard); //LOgipe CARD BALANCE

  const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == null
      ? 0
      : ownerBalance?.instrument_balance?.balance;
  // useEffect(() => {}, [props]);
  console.log("yo*", LogipeAccounBal);
  const cardName =
    props.driverDetails.name == null ? "NA" : props.driverDetails.name;
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">{name}</p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                console.log("ManageDriverWithdrawBalanceManageDriverWithdrawBalance");
                  if (!loader1) {
                    toggleModal();
                  }
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="card-wallet-icon-body2">
              <SvgImage
                name="cardWalletLogo"
                height="43.81"
                width="43.81"
                classname="card-wallet-logo-BankLogo"
              />
              <div>
                <p className="body-sub-part1-ab">Master Account Balance</p>
                <p className="body-sub-part2-ab">₹{LogipeAccounBal}</p>
              </div>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                amount: "",
                pin: "",
                driverName: cardName,
                remark: "",
              }}
              validationSchema={Yup.object().shape({
                amount: Yup.number()
                  .typeError("Only Numbers are allowed")
                  .min(1, "Amount should be greater than 1")
                  .required("Amount is required"),
                pin: Yup.string()
                  .required("* Required")
                  .matches(/^[0-9]{4}$/, "* Pin Must be exactly 4 digits"),
                // dob: Yup.string()
                //   .required('Expense Date is required'),
                // password: Yup.string()
                //   .min(6, 'Password must be at least 6 characters')
                //   .required('Password is required'),
                // confirmPassword: Yup.string()
                //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
                //   .required('Confirm Password is required')
              })}
              //validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                // values.bookingDate = moment(values.bookingDate).format(
                //   "DD-MM-YYYY"
                // );
                //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
                console.log("valuessss via withdraw", values);
                dispatch(
                  sendAnalitics("Withdraw_Bal_DriverCard_API_Web", {
                    amt: values.amount,
                    pin: values.pin,
                    remark: values.remark,
                    userId: userId,
                  })
                );
                  dispatch(
                    newWithdrawMoneyAction(values, userId, (res) => {
                      actions.setSubmitting(false);
                      dispatch(
                        sendAnalitics("Success_Withdraw_Bal_DriverCard_Web", {
                          amt: values.amount,
                          pin: values.pin,
                          remark: values.remark,
                          userId: userId,
                        })
                      );
                      if (res?.status == 200) {
                        console.log("successful");
                        //navigate("/Customers")
                        // window.location.reload();
                        toggleModal();
                        dispatch(
                          getAdvanceDriverList({
                            search: search,
                            offset: offset,
                            search: '',
                            minKyc: "",
                            sortAs: "",
                            sortBy: "",
                            activeStatus:1,
                            assignedStatus:3
                          })
                        );
                        dispatch(
                          toastStatus({
                            toastStatus: true,
                            message: `withdrawn successfull`,
                            status: true,
                          })
                        );
                        dispatch(getExpenseWalletSummary(userId));
                      }else{
                        dispatch(
                          toastStatus({
                            toastStatus: true,
                            message: `withdrawn failed`,
                            status: false,
                          })
                        );
                      }
                    })
                  );
                  

                //toggleModal();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <p className="sub-head-3">Enter amount</p>
                    </div>
                    <p className="sub-head-body">
                      Amount will be deducted from driver's LogiPe account
                    </p>

                    {/* <label for="html" className="label-body">
                    ₹
                  </label> */}
                    <input
                      type="text"
                      id="html"
                      className={
                        "input-body-1 form-control" +
                        (errors.amount && touched.amount ? " is-invalid" : "")
                      }
                      name="amount"
                      value={values.amount}
                      touched={touched.amount}
                      errors={errors.amount}
                      onBlur={handleBlur("amount")}
                      onChange={handleChange("amount")}
                    ></input>
                    <div className="input-amount"></div>
                    <ErrorMessage
                      name="amount"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <p className="sub-head-3">Driver Name</p>
                    </div>
                    {/* <p className="sub-head-body">
                      Enter the the remarks/comments
                    </p> */}

                    <input
                      type="text"
                      id="html"
                      className={
                        "input-body-1 form-control" +
                        (errors.driverName && touched.driverName
                          ? " is-invalid"
                          : "")
                      }
                      name="driverName"
                      value={values.driverName}
                      touched={touched.driverName}
                      errors={errors.driverName}
                      onBlur={handleBlur("driverName")}
                      onChange={handleChange("driverName")}
                    ></input>
                    <div className="input-amount"></div>
                    {/* <ErrorMessage
                      name="driverName"
                      component="div"
                      className="invalid-feedback"
                    /> */}
                  </div>
                  <div>
                    <div className="sub-title-1">
                      <span class="circle-logo">4</span>
                      <p className="sub-head-4">Enter 4-digit Security Pin</p>
                    </div>
                    <p className="sub-head-body">
                      Security pin is needed to confirm payment
                    </p>
                    <PinInput
                      length={4}
                      initialValue=""
                      secret
                      onChange={(value, index) =>
                        setFieldValue("pin", value, true)
                      }
                      type="numeric"
                      inputMode="number"
                      style={{
                        padding: "10px",
                        marginLeft: "96px",
                        marginTop: "28px",
                      }}
                      inputStyle={{
                        border: "1px solid #A7A7A7",
                        borderRadius: "8px",
                        marginRight: "20px",
                      }}
                      onComplete={(value, index) => {}}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    <div
                      style={{
                        color: "#dc3545",
                        fontSize: "14px",
                        marginLeft: "6.5rem",
                      }}
                      className={
                        +(errors.pin && touched.pin ? " is-invalid" : "")
                      }
                    >
                      {" "}
                      <ErrorMessage className="is-invalid" name="pin" />
                    </div>
                    <Button
                      type="submit"
                      className="butt4"
                      disabled={
                         loader1
                            ? true
                            : false
                      }
                      onClick={() => {
                        dispatch(
                          sendAnalitics("Withdraw_Bal_DriverCard_fromMA_Web", {
                            amt: values.amount,
                            pin: values.pin,
                            remark: values.remark,
                            userId: userId,
                          })
                        );
                      }}
                    >
                      {loader1
                          ? "Please Wait"
                          : "Withdraw Card Balance"
                       }
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>

            {/* <div className="space-div"></div> */}
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};
export default ManageDriverWithdrawBalance;
