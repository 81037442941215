import {
  FETCH_BALANCE,
  FETCH_BALANCE_SUCCESS,
  FETCH_CASH_BACK_BALANCE,
  FETCH_CASH_BACK_BALANCE_SUCCESS,
  OWNER_CARD_DETAILS,
  OWNER_CARD_DETAILS_SUCCESS,
  GET_CASHBACK_LIST,
  GET_CASHBACK_LIST_SUCCESS,
  CLEAN_CASHBACK_LIST,
  TRANSFER_TO_CARD,
  TRANSFER_TO_CARD_SUCCESS,
  WITHDRAW_FROM_CARD,
  WITHDRAW_FROM_CARD_SUCCESS,
  CHANGE_PIN,
  CHANGE_PIN_SUCCESS,
  RECENT_CARD_TRANSACTIONS,
  RECENT_CARD_TRANSACTIONS_SUCCESS,
  NEW_WITHDRAW_MONEY,
  NEW_WITHDRAW_MONEY_SUCCESS,
  GET_OWNERCARD,
  GET_OWNERCARD_SUCCESS
} from "../storeTypes";

export function fetchBalance() {
  return {
    type: FETCH_BALANCE,
  };
}
export function fetchBalanceSuccess(data) {
  return { type: FETCH_BALANCE_SUCCESS, payload: data };
}

export function fetchCashBackBalance() {
  return {
    type: FETCH_CASH_BACK_BALANCE,
  };
}
export function fetchCashBackBalanceSuccess(data) {
  return { type: FETCH_CASH_BACK_BALANCE_SUCCESS, payload: data };
}

export const getOwnerCardDetails = (id) => {
  return {
    type: OWNER_CARD_DETAILS,
    payload: id,
  };
};
export const getOwnerCardDetailsSuccess = (payload) => {
  return {
    type: OWNER_CARD_DETAILS_SUCCESS,
    payload,
  };
};

export const getCashbackList = (data) => {
  console.log("action-> all cards", data);
  return {
    type: GET_CASHBACK_LIST,
    payload: data,
  };
};

export const getCashbackListSuccess = (data) => {
  console.log("action-> userdetails-- success", data);
  return { type: GET_CASHBACK_LIST_SUCCESS, payload: data };
};

export const cleanCashbackList = () => {
  //console.log("action-> all cards", data);
  return {
    type: CLEAN_CASHBACK_LIST,
  };
};
export const transferToCard = (obj, ID, callback) => {
  console.log("action-> all cards");
  return {
    type: TRANSFER_TO_CARD,
    payload: obj,
    ID,
    callback,
  };
};

export const transferToCardSuccess = () => {
  console.log("action-> userdetails-- success");
  return { type: TRANSFER_TO_CARD_SUCCESS };
};

export const withdrawFromCard = (obj, ID, callback) => {
  console.log("action-> all cards");
  return {
    type: WITHDRAW_FROM_CARD,
    payload: obj,
    ID,
    callback,
  };
};

export const withdrawFromCardSuccess = () => {
  console.log("action-> userdetails-- success");
  return { type: WITHDRAW_FROM_CARD_SUCCESS };
};

export const changePin = (data, callback) => {
  console.log("action-> change pin ", data);
  return {
    type: CHANGE_PIN,
    payload: data,
    callback
  };
};

export const changePinSuccess = (data) => {
  console.log("action-> change pin", data);
  return { type: CHANGE_PIN_SUCCESS, payload: data };
};

export const getRecentCardTransaction = (data) => {
  return {
    type: RECENT_CARD_TRANSACTIONS,
    payload: data,
  };
};

export const getRecentCardTransactionSuccess = (data) => {
  return {
    type: RECENT_CARD_TRANSACTIONS_SUCCESS,
    payload: data,
  };
};


export const newWithdrawMoneyAction = (obj, ID, callback) => {
  console.log("action-> withdraw");
  return {
    type: NEW_WITHDRAW_MONEY,
    payload: obj,
    ID,
    callback,
  };
};

export const newWithdrawMoneySuccessAction = () => {
  console.log("action-> withdraw-- success");
  return { type: NEW_WITHDRAW_MONEY_SUCCESS };
};

export const ownerCardAction = (data) => {
  return {
    type: GET_OWNERCARD,
  };
};

export const ownerCardActionSuccess = (data) => {
  return {
    type: GET_OWNERCARD_SUCCESS,
    payload: data,
  };
};
