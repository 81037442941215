import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PinInput from "react-pin-input";
import SvgImage from "../../../../../Icons/Icons";
import { omcLoadMoneyAction } from "../../../../../../services/Store/Actions/omcActions";
import { useParams } from "react-router-dom";
import {
  sendAnalitics,
  toastStatus,
} from "../../../../../../services/Store/Actions/appActions";
import { transferTorVendorAction } from "../../../../../../services/Store/Actions/beneficiaryAction";

const OMCLoadModal = (props) => {
  const {
    open,
    toggleModal,
    handleClose,
    amount,
    status,
    itemTemp,
    tripKey,
    advancePayment,
    fleetId,
trip
  } = props;

  const dispatch = useDispatch();

  const loader1 = useSelector((state) => state?.omc?.loader);
  const loader2 = useSelector((state) => state?.beneficiary?.loader);
  const loader =
    advancePayment?.length > 0 &&
    advancePayment[0]["type"] == "Vendor" &&
    advancePayment[0]["vendor"]
      ? loader2
      : loader1;

  // console.log("button status11oooooo", tripKey);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Only Numbers are allowed")
      .required("Required")
      .min(1, "Amount should be greater than 1"),
    pin: Yup.string()
      .required("Required")
      .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
  });

  let { id } = useParams();
  // console.log("nnmnmmnmnm", fleetId,trip);
  // if(props.userId){
  //   id = props.userId
  // }

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Add Money in Fuel Card</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody style={{ position: "relative" }}>
            <Formik
              enableReinitialize
              initialValues={{
                amount: amount || "",
                pin: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                // console.log(
                //   "pppppooooiiiiii",
                //   values,
                //   advancePayment[0]["header"],
                //   advancePayment[0]["type"],
                //   advancePayment[0]["vendor"]
                // );
                if (
                  advancePayment?.length > 0 &&
                  advancePayment[0]["header"] == "Fuel"
                ) {
                  if (
                    advancePayment[0]["type"] == "Vendor" &&
                    advancePayment[0]["vendor"]
                  ) {
                    let vendorData = advancePayment[0]["vendor"];
                    dispatch(
                      transferTorVendorAction(
                        {
                          mode:
                            vendorData?.vpaId == "" ? "BANK_TRANSFER" : "UPI",
                          pin: values.pin?.toString(),
                          amount: values.amount?.toString(),
                          remark: "test",
                          vendorId: vendorData?.id?.toString(),
                          tripId: itemTemp?.tripId ? itemTemp?.tripId : "",
                          tripKey: tripKey ? tripKey : "",
                        },
                        (res) => {
                          toggleModal();
                          if (res?.status == 200) {
                            dispatch(
                              toastStatus({
                                toastStatus: true,
                                message:
                                  "Money was transfer successfully to the Vendor",
                                status: true,
                              })
                            );
                          } else {
                            dispatch(
                              toastStatus({
                                toastStatus: true,
                                message: res?.data?.message || "Error",
                                status: false,
                              })
                            );
                          }
                        }
                      )
                    );
                  } else {
                    dispatch(
                      toastStatus({
                        toastStatus: true,
                        message: "Please select A vendor",
                        status: false,
                      })
                    );
                  }
                } else {
                  dispatch(
                    sendAnalitics("OMCLoadMoney_DC_API_Web", {
                      amt: values.amount,
                      pin: values.pin,
                      userId: id,
                    })
                  );
                  let payload = {
                    fleetId: trip == 1 ? fleetId : id,
                    amount: values?.amount,
                    pin: values?.pin,
                    remarks: "test",
                    tripId: itemTemp?.tripId ? itemTemp?.tripId : "",
                    tripKey: tripKey ? tripKey : "",
                  };
                  if (status) {
                    let qqq = status == 1 ? "Settle" : "advance";
                    payload["sattlementStatus"] = qqq;
                  }
                  dispatch(
                    omcLoadMoneyAction(payload, (res) => {
                      console.log("res --", res);
                      if (res?.status == 200) {
                        dispatch(
                          sendAnalitics("Success_OMCLoadMoney_DC_Web", {
                            amt: values.amount,
                            pin: values.pin,
                            userId: id,
                          })
                        );
                        toggleModal();
                        dispatch(
                          toastStatus({
                            toastStatus: true,
                            message:
                              "Money was added successfully in the Fuel Card",
                            status: true,
                          })
                        );
                      } else {
                        dispatch(
                          toastStatus({
                            toastStatus: true,
                            message: res?.data?.message || "Error",
                            status: false,
                          })
                        );
                      }
                    })
                  );
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <label className="sub-head-3 ">Amount</label>
                    </div>
                    <label for="html" className="label-body">
                      ₹
                    </label>
                    <input
                      type="text"
                      id="amount"
                      className={`input-body `}
                      name="amount"
                      onChange={handleChange("amount")}
                      value={values.amount}
                      onBlur={() => {
                        // dispatch(sendAnalitics("Amt_DC_MA_Web", {
                        //   amt_MA_to_DriverCard:values.amount
                        // }));
                        handleBlur("amount");
                      }}
                      touched={touched.amount}
                      errors={errors.amount}
                      placeholder="Enter amount"
                      readOnly={tripKey ? true : false}
                    />
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  {/* <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <label className="sub-head-3 ">Select Card</label>
                    </div>
                    <input
                      type="text"
                      id="driverName"
                      className={`input-body-3 `}
                      name="driverName"
                      onChange={handleChange("driverName")}
                      value={values.driverName}
                      onBlur={()=>{
                        // dispatch(sendAnalitics("remark_DC_MA_Web", {
                        //   amt_MA_to_DriverCard:values.remark
                        // }));
                        handleBlur("driverName");
                      }}
                      touched={touched.driverName}
                      errors={errors.driverName}
                      placeholder="Select"
                    />
                    <div className="input-amount"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.driverName && touched.driverName ? (
                        <div>*{errors.driverName}</div>
                      ) : null}
                    </div>
                  </FormGroup> */}
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">3</span>
                      <p className="sub-head-4 ">Enter 4-digit Security Pin</p>
                    </div>
                    <p className="sub-head-body">
                      Security pin is needed to confirm payment
                    </p>
                  <PinInput
                    length={4}
                    initialValue=""
                    secret
                    onChange={handleChange("pin")}
                    type="numeric"
                    value={values.pin}
                    onBlur={handleBlur("pin")}
                    touched={touched.pin}
                    errors={errors.pin}
                    inputMode="number"
                    style={{
                      padding: "10px",
                      marginLeft: "96px",
                      marginTop: "20px",
                      marginBottom: "20rem",
                    }}
                    inputStyle={{
                      border: "1px solid #A7A7A7",
                      borderRadius: "8px",
                      marginRight: "20px",
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.pin && touched.pin ? (
                      <div>*{errors.pin}</div>
                    ) : null}
                  </div>
                  </FormGroup>
                  <FormGroup style={{ position: "absolute", bottom: "1rem" }}>
                    <button
                      type="submit"
                      className="butt-4"
                      style={{
                        margin: "15% 0 0 0",
                        background: loader ? "grey" : "#485B7C",
                      }}
                      disabled={loader ? true : false}
                      onClick={() => {
                        dispatch(
                          sendAnalitics("OMCLoadMoney_DC_Web", {
                            amt: values.amount,
                            pin: values.pin,
                            userId: id,
                          })
                        );
                      }}
                    >
                      {loader ? "Please Wait" : "Submit"}
                    </button>
                  </FormGroup>
                  <div></div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default OMCLoadModal;
