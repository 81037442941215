

import { Formik } from "formik";
import {
  Button,
  FormGroup,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

function TripExpanseSummary(props) {
    const {tripData, freight} =  props
    const dispatch = useDispatch();
    const customId = "custom-id-KYCPendinshgdjfag page";
    const notify = () => toast.error("Kindly Update All Freights.",{
        toastId:customId 
    });
return (
    <div className="endTripCard" style={{width: "800px"}}>
        <div style={{alignSelf:"center", fontWeight: 600}}><text>Clear Expenses</text></div>
        <div style={{margin: "20px"}}>
            <div style={{ display: "flex",flexDirection: "row" ,justifyContent: "space-between", marginRight: "350px"}}>
                <div>
                    <div style={{fontWeight: 600}}> Driver Expenses </div>
                    <div style={{marginTop: "10px"}}>{freight?.consignorName ? freight?.consignorName : "Rs. 10000"} </div>
                </div>
                <div>
                    < div style={{fontWeight: 600}}> Advance Paid: </div>
                    <div style={{marginTop: "10px"}}>{freight?.consigneeName ? freight?.consigneeName : 'Rs. 5000'} </div>
                    
                </div>
                <div>
                    <div style={{fontWeight: 600}}> Incentive: </div>
                    <div style={{marginTop: "10px"}}>{freight?.transportorName ? freight?.transportorName : "Rs. 2000"} </div>
                </div>
            </div>
            <div style={{marginTop: "-33px"}}>
                <Formik
                    // enableReinitialize
                    initialValues={{
                        amount: "",
                    }}
                    onSubmit={(values, actions) => {
                        console.log("sdsadsad", values);
                        let objVal = {
                            amount: values?.amount,
                        };
                        dispatch(
                        
                        );
                    }}
                    >
                    {({
                        handleSubmit,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit} style={{display:"flex",marginLeft: "500px" }}>
                        
                            <FormGroup
                                style={{
                                    display: "flex",
                                    flexDirection:"row",
                                }}
                            >
                                <FormGroup style={{ marginTop: "-22.5px", marginRight: "10px", marginLeft: "-10px" }}>
                                    <div  className="heading3 semiBold col-12">
                                        Amount:
                                    </div>
                                    <div className="custNamepadd3" onClick={()=> {
                                        console.log("custNamepadd3custNamepadd3custNamepadd3");
                                        notify()}}>
                                    <input
                                        type="text"
                                        id="amount"
                                        className={`inputBoxCust1 ${
                                        touched.amount && errors.amount ? "is-invalid" : ""
                                        }`}
                                        name="amount"
                                        onChange={(event) => {
                                        // setSearch(event.target.value);
                                        
                                        setFieldValue("amount", event.target.value, true);
                                        }}
                                        value={values.amount}
                                        touched={touched.amount}
                                        errors={errors.amount}
                                        placeholder="Amount( Rs. )"
                                        autoComplete={"off"}
                                    />
                                    </div>
                                </FormGroup>

                                <FormGroup style={{ marginTop: "-10px"}}>
                                    <div>
                                        <Button
                                            type="submit"
                                            style={{width: "100%",}}
                                            className="generateOtp text3 Medium btn btn-secondary">   Pay
                                        </Button> 
                                    </div>
                                </FormGroup>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </div>
            <div style={{}}>
                <Formik
                    // enableReinitialize
                    initialValues={{
                        amount: "",
                    }}
                    onSubmit={(values, actions) => {
                        console.log("sdsadsad", values);
                        let objVal = {
                            amount: values?.amount,
                        };
                        dispatch(
                        
                        );
                    }}
                    >
                    {({
                        handleSubmit,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit} style={{display:"flex" , marginLeft: "10px"}}>
                        
                            <FormGroup
                                style={{
                                    display: "flex",
                                }}
                            >
                                <FormGroup style={{ marginTop: "-22.5px", marginRight: "10px", marginLeft: "-10px" }}>
                                    <div  className="heading3 semiBold col-12">
                                        Fuel Expense:
                                    </div>
                                    <div className="custNamepadd3">
                                    <input
                                        type="text"
                                        id="amount"
                                        className={`inputBoxCust1 ${
                                        touched.amount && errors.amount ? "is-invalid" : ""
                                        }`}
                                        name="amount"
                                        onChange={(event) => {
                                        // setSearch(event.target.value);
                                        setFieldValue("amount", event.target.value, true);
                                        }}
                                        value={values.amount}
                                        touched={touched.amount}
                                        errors={errors.amount}
                                        placeholder="Amount( Rs. )"
                                        autoComplete={"off"}
                                    />
                                    </div>
                                </FormGroup>
                                <FormGroup style={{ marginTop: "-22.5px", marginRight: "10px", marginLeft: "-10px" }}>
                                    <div  className="heading3 semiBold col-12">
                                        Fuel Burned:
                                    </div>
                                    <div className="custNamepadd3">
                                    <input
                                        type="text"
                                        id="amount"
                                        className={`inputBoxCust1 ${
                                        touched.amount && errors.amount ? "is-invalid" : ""
                                        }`}
                                        name="amount"
                                        onChange={(event) => {
                                        // setSearch(event.target.value);
                                        setFieldValue("amount", event.target.value, true);
                                        }}
                                        value={values.amount}
                                        touched={touched.amount}
                                        errors={errors.amount}
                                        placeholder="Liters"
                                        autoComplete={"off"}
                                    />
                                    </div>
                                </FormGroup>
                                
                            </FormGroup>
                                <FormGroup style={{ marginTop: "-10px", marginRight:"-175px"}}>
                                    <Button
                                        type="submit"
                                        style={{width: "400%",}}
                                        className="generateOtp text3 Medium btn btn-secondary">  End Trip
                                    </Button> 
                                </FormGroup>
                        </Form>
                    )}
                </Formik>
            </div>
            <div >
                
            </div>
        </div>
        <ToastContainer
            theme="dark"
            position="top-right"
            closeOnClick
            pauseOnHover
            autoClose={4000}
          />
    </div>
    )
}
export default TripExpanseSummary