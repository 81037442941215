import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Modal, ModalBody } from "reactstrap";
import "./index.css";
import SvgImage from "../../../Icons/Icons";
import { Formik } from "formik";
import PinInput from "react-pin-input";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  pin: Yup.string()
    .required("Required")
    .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
});
function PinModal(props) {
  const {
    open,
    onHide,
    toggleModal,
    handleClose,
    onSubmit,
    data,
    pinLoader,
    isLogipeVendor,
  } = props;
  const { vendor } = useSelector((state) => state?.beneficiary);
  const dispatch = useDispatch();
  console.log("isLogipeVendor", isLogipeVendor);
  return (
    <Modal
      isOpen={open}
      onHide={onHide}
      toggle={toggleModal}
      className="Pin-Modal settingModalMargin"
      //   toggle={toggle}
    >
      <div
        className="closeRemove"
        onClick={() => {
          // Router.reload();
          handleClose();
        }}
      >
        <SvgImage name="CloseButtonLogo" />
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          pin: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          onSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div className="Ent-Pin-Box">
                <p className="ENT-Security-Pin-Title">
                  Enter 4-digit Security Pin
                </p>
              </div>
              <p className="ENT-Security-Pin-subTitle">
                {isLogipeVendor == "1"
                  ? `₹${data?.amount} will be deducted from your Master Account`
                  : `₹${
                    parseFloat(data?.amount) + parseFloat(vendor?.payout_fee)
                    } will be deducted from your Master Account`}
              </p>
              <PinInput
                length={4}
                initialValue=""
                secret
                onChange={handleChange("pin")}
                type="numeric"
                value={values.pin}
                onBlur={handleBlur("pin")}
                touched={touched.pin}
                errors={errors.pin}
                inputMode="number"
                style={{
                  padding: "10px",
                  marginLeft: "96px",
                  marginTop: "20px",
                }}
                inputStyle={{
                  border: "1px solid #A7A7A7",
                  borderRadius: "8px",
                  marginRight: "20px",
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                {errors.pin && touched.pin ? <div>*{errors.pin}</div> : null}
              </div>
              {isLogipeVendor == "1" ? null : (
                <p
                  className="ENT-Security-Pin-subTitle"
                  style={{ color: "red" }}
                >
                   Payout Fees ₹{vendor?.payout_fee} will deducted from Master Account
                </p>
              )}
            </FormGroup>
            <FormGroup
              style={{
                justifyContent: "center",
                textAlignLast: "center",
              }}
            >
              <button
                type="submit"
                className="greenButton Verify-Pin-Btn"
                style={{
                  margin: "5% 0 0 0",
                  background: pinLoader ? "grey" : "#485B7C",
                }}
                disabled={pinLoader ? true : false}
                onClick={() => {}}
              >
                {pinLoader ? "Please Wait" : "Submit"}
              </button>
            </FormGroup>
            <div></div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default PinModal;
