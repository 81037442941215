import React, { useEffect, useState } from "react";
import "./index.css";
import PGCollectionBar from "../PGCollectionBar";
import PGCollectionButton from "../PGCollectionButton";
import PGCollectionCard from "../PGCollectionCard";
import SearchBar from "../PGCollectionSearchBar";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FcManager } from "react-icons/fc";
import { Button } from "reactstrap";
import SvgImage from "../../Components/Icons/Icons";
import {
  getCollections,
  getSettlements,
  cleanCollection,
} from "../../services/Store/Actions/beneficiaryAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Modal, ModalBody, Table } from "reactstrap";
import SettlementHistory from "../PGCollection/SettlementHistory";
import { sendAnalitics } from "../../services/Store/Actions/appActions";

const PGCollection = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCollections());
    dispatch(getSettlements());
    dispatch(sendAnalitics("Col_Web"));
  }, []);
  const [settlementDiv, setSettlementDiv] = useState(false);
  const handleClose = props.handleClose;
  const { isOpen, onHide, toggle } = props;
  const {
    settlementHandleClose,
    settlementIsOpen,
    settlementOnHide,
    settlementToggle,
    settlementShow,
  } = props;
  const collections = useSelector(
    (state) => state?.beneficiary?.collections?.data
  );
  console.log("collections", collections);

  const settlements = useSelector(
    (state) => state?.beneficiary?.settlements?.data
  );
  console.log("settlements----", settlements);

  // const handleSearch=(val)=>{
  //     dispatch(cleanCollection());
  //     dispatch(getCollections());
  // };

  const handleSettlement = () => {
    // toggle();
    settlementShow();
    console.log("values", isOpen, settlementIsOpen);
  };

  const collectionLength= collections!=undefined?collections.length:0;
  console.log("tttttttttttttttttttttttt",collectionLength);
  return (
    <Modal
      isOpen={isOpen}
      onHide={onHide}
      className=" all-transaction-main-modal"
      toggle={toggle}
      aria-labelledby="myModalLabel2"
    >
      <ModalBody className="transactionModalBody">
        <div className="PGCollectionBox">
          <div className="PGCollectionclose" onClick={()=>{
              dispatch(sendAnalitics("Close_Col_Web"));
               handleClose();
          }}>
            <div className="PGCollectionCloseIcon">
              <SvgImage
                style={{ width: "10%", height: "10%" }}
                name="modal fade right"
                width="20%"
                height="20%"
              />
            </div>
            {/* <div className="PGCollectionCloseText">Close</div> */}
          </div>
          <div>
            <div className="PGCollectionHeader">
              {/* <div className="PGCollectionIcon" >
                    <FcManager size={30}/>
                    </div> */}
              <div className="PGCollectionIcon">
                <SvgImage name="settlementLogo" />
              </div>
              <div className="PGCollectiontText1">
                Collections & Settlements
              </div>
              {/* <div className="PGCollectionButton">
                             <PGCollectionButton/>
                     </div> */}
              {/* <div className="linkToSettlement" onClick={handleSettlement}>Settlements History ></div> */}
              {
                <SettlementHistory
                  handleClose={settlementHandleClose}
                  isOpen={settlementIsOpen}
                  onHide={settlementOnHide}
                  toggle={settlementToggle}
                  settlementsList={settlements}
                />
              }
            </div>
            {/* <div className="PGCollectionSearchBar">
                    <SearchBar findSearch={handleSearch}/>
                </div> */}
                {
                  collectionLength!==0?
                  <div className="transitionContainerCollection">
              <div className="tableContaincer">
                <Table className="collectionTableCss">
                  <thead>
                    <tr className="table-header-customer-details">
                      <th>Customer Name</th>
                      <th>Amount</th>
                      <th>Date and Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {collections?.map((item, i) => {
                      let name =
                        item?.Customer?.firstName +
                        " " +
                        item?.Customer?.lastName;
                      let amount = item?.amount;
                      let company = item?.Customer?.companyName;
                      let transactionTiming = item?.createdAt;
                      let dateH =
                        moment(transactionTiming).format("DD-MM-YYYY");
                      let timeH =
                        moment(transactionTiming).format("hh:mm:ss a");
                      let remarks = item?.purpose;
                      return (
                        <>
                          <tr>
                            <td>
                              <div>{name}</div>
                              <div>
                                {company === undefined
                                  ? "Not Available"
                                  : company}
                              </div>
                            </td>
                            <td className="greenColor">₹{amount.toFixed(2)}</td>
                            <td>
                              <div>{dateH}</div>
                              <p>{timeH}</p>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>:
            <div style={{textAlign:"center", paddingTop:"10rem"}}>
              <div>
              <SvgImage name="noCollectionIcon" />
              </div>
              <div className="heading1 semiBold colorText padding16" >No Collections</div>
              <div className="regular heading2 colorText" >Collections made via payment links take</div>
              <div className="regular heading2 colorText">upto 24 hours to settle.</div>
              <div className="regular heading2 colorText">View collections here.</div>
            </div>
                }
            
            {/* <div className="PGCollectionHeaderBar">
              <PGCollectionBar />
            </div> */}
            {/* <div className="cashbackListing">
              {collections?.map((item, i) => {
                let name =
                  item?.Customer?.firstName + " " + item?.Customer?.lastName;
                let amount = item?.amount;
                let company = item?.Customer?.companyName;
                let transactionTiming = item?.createdAt;
                let dateH = moment(transactionTiming).format("DD-MM-YYYY");
                let timeH = moment(transactionTiming).format("hh:mm:ss a");
                let remarks = item?.purpose;

                return (
                  <div key={i} className="PGCollectionCard" style={{}}>
                    <PGCollectionCard
                      name={name}
                      companyName={company}
                      amount={amount}
                      transactionDate={dateH}
                      transactionTime={timeH}
                      remarks={remarks}
                    />
                  </div>
                );
              })}
            </div> */}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default PGCollection;
