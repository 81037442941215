import { Button, NavLink } from "reactstrap";
import React, { Component ,useState} from "react";
import SvgImage from "../../Icons/Icons";
import SearchBar from "../searchbar";
import "./index.css";

import RouterButton from "../../../Subcomponent/RouterButton/RouterButton";
import HeaderRight from "../headerRight";
import Home from "../../Homepage/home";
function Header(props) {
  const { accountName, img, accountTitle, companyName } = props;
  const [act,setAct] = useState();

  return (
    <div className="main-header " style={{zIndex:2}}>
      <div className="rel flex">
        <div className="logo">
        {/* <NavLink className="navbar-item" activeClassName="is-active" to={Home} >
              <SvgImage name="AppLogo" />
            </NavLink>           */}
            <RouterButton className="applogolink" title = 'AppLogo' link = "home" active = {act === 1 ? true : false}/>

        </div>
       

        {/* <SearchBar /> */}
        <div className="flex1"></div>
        <HeaderRight />
      </div>
    </div>
  );
}
export default Header;
