import React, { useEffect, useState } from "react";
import "./index.css";
import SvgImage from "../../../Icons/Icons";
import { Formik, Field } from "formik";
import { Form, FormGroup, Button } from "reactstrap";
import * as Yup from "yup";
import {
  cleanVendorList,
  createVendorAction,
  editVendorAciton,
  getLogipeVendorDetailsAction,
  getLogipeVendorDetailsSuccess,
  getVendorAction,
} from "../../../../services/Store/Actions/beneficiaryAction";
import { useDispatch, useSelector } from "react-redux";
import SuccessModal from "../Modal/SuccessModal";
import Toast from "../../../subComponents/toast";
import { useNavigate } from "react-router-dom";
const AddNewVendor = (props) => {
  const { backPress, path, oldDetails } = props;
  const isLogipeVendor = path == "0" || path == "1" || path == "2" ? "1" : "0";

  const validationSchema = Yup.object().shape({
    vendorName: Yup.string()
      .required("Vendor Name is Required")
      .matches(/^[a-zA-Z0-9 ]+$/, "Enter correct Vendor name"),
    phone: Yup.string()
      .required("Phone is Required")
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Phone number must contain 10 digits"
      ),
    bankAccountName: Yup.string().matches(
      /^[a-zA-Z ]+$/,
      "Enter correct Account Holder name"
    ),
    // bankAccountNumber:
    // isLogipeVendor == "1" ? Yup.string().matches(
    //   /^[8][0][8][0][8][0][6-9]{1}[0-9]{9}$/,
    //   "Enter correct Logipe Account Holder name"
    // ) : "",
    // bankAccountNumber: Yup.string().matches(
    //   /^[0-9]+$/,
    //   "Please enter a vailed Account number"
    // ),
    // ifsc: Yup.string().matches(
    //   /^([A-Za-z]{4}\d{7})+$/,
    //   "Enter correct Bank IFSC code"
    // ),
    vpaId: Yup.string().matches(
      /^([a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64})+$/,
      "Please enter a vailid upi id"
    ),
    // gst: Yup.string().required("gst is Required")
  });

  const [loader, setLoader] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [failedError, setFailedError] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { LogipeVendorDetails } = useSelector((state) => state?.beneficiary);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyDetails = [
    {
      title: "Company / Vendor name",
      value: "vendorName",
      placeHolder: "Type comp. or vendor name",
    },
    { title: "Phone Number", value: "phone", placeHolder: "Type phone number" },
    {
      title: "GST Number (Optional)",
      value: "gst",
      placeHolder: "Type GST number",
    },
    {
      title: "Address (Optional)",
      value: "address",
      placeHolder: "Type your full address",
    },
    {
      title: "Vendor Type",
      value: "vendorType",
      placeHolder: "Please select Vendor Type",
    },
  ];
  const bankDetails = [
    {
      title: "Bank Account Name",
      value: "bankAccountName",
      placeHolder: "Type name as per bank ",
    },
    {
      title: "Bank Account Number",
      value: "bankAccountNumber",
      placeHolder: "Type bank account number",
    },
    {
      title: "IFSC Code",
      value: "ifsc",
      placeHolder: "Type bank IFSC code",
    },
    {
      title: "Upi Id",
      value: "vpaId",
      placeHolder: "Type your Upi Id",
    },
  ];
  const defaultStates = [
    {
      value: "4",
      text: "Fuel Station",
    },
    {
      value: "3",
      text: "Vehicle Services",
    },
    {
      value: "1",
      text: "Others",
    },
    {
      value: "2",
      text: "Fleet Owner",
    }
  ];
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    return () => {
      dispatch(getLogipeVendorDetailsSuccess({}));
    };
  }, []);
  console.log(
    "LogipeVendorDetails",
    LogipeVendorDetails,
    LogipeVendorDetails?.vpaId
  );
  return (
    <div className="vendorBg containerShadow">
      {successModal && (
        <SuccessModal
          open={successModal}
          onHide={() => {
            setSuccessModal(false);
            backPress();
            dispatch(cleanVendorList());
            dispatch(
              getVendorAction({
                isLogipeVendor: isLogipeVendor,
                limit: 7,
                offset: 0,
              })
            );
          }}
          toggleModal={() => {
            setSuccessModal(!successModal);
            backPress();
            dispatch(cleanVendorList());
            dispatch(
              getVendorAction({
                isLogipeVendor: isLogipeVendor,
                limit: 7,
                offset: 0,
              })
            );
          }}
          handleClose={() => {
            setSuccessModal(false);
            backPress();
            dispatch(cleanVendorList());
            dispatch(
              getVendorAction({
                isLogipeVendor: isLogipeVendor,
                limit: 7,
                offset: 0,
              })
            );
          }}
          text={"Vendor details have been added successfully."}
        />
      )}
      {failedModal && (
        <Toast
          success={0}
          message={failedError || "Failed to add Vendor"}
          toggleModal={() => {
            setFailedModal(false);
          }}
          isOpen={failedModal}
          name="vendor"
          handleClose={() => {
            setFailedModal(false);
          }}
        />
      )}
      <div className="p-4">
        <div className="d-flex align-items-center mb-4">
          <div
            style={{
              transform: "rotate(180deg)",
              width: "32px",
              height: "32px",
            }}
            onClick={backPress}
          >
            <SvgImage name="ForwardArrow" height="32px" width="32px" />
          </div>
          <div className="heading1 FontBig ms-2">
            {isLogipeVendor == "0"
              ? "Add Vendor Details"
              : "Add Logipe Vendor Details"}
          </div>
        </div>

        <div
          className="border-top divider"
          style={{ borderColor: "#E2E2E2" }}
        />
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            vendorName: oldDetails?.name || LogipeVendorDetails?.name || "",
            phone: oldDetails?.phone || LogipeVendorDetails?.phone || "",
            gst: oldDetails?.gstNumber || "",
            address: oldDetails?.address || "",
            bankAccountName:
              oldDetails?.bankAccountName ||
              LogipeVendorDetails?.bankAccountName ||
              "",
            bankAccountNumber:
              oldDetails?.bankAccountNumber ||
              LogipeVendorDetails?.bankAccountNumber ||
              "",
            ifsc: oldDetails?.ifsc || LogipeVendorDetails?.ifsc || "",
            vpaId: oldDetails?.vpaId || LogipeVendorDetails?.vpaId || "",
            vendorType:
              oldDetails?.vendorType || LogipeVendorDetails?.vendorType || "",
          }}
          onSubmit={(values, actions) => {
            console.log("valuessss", values);
            setLoader(true);
            const payload = {
              id: oldDetails?.id?.toString(),
              vpaId: values?.vpaId == "" ? undefined : values?.vpaId,
              bankAccountNumber:
                values?.bankAccountNumber == ""
                  ? undefined
                  : values?.bankAccountNumber?.toString(),
              isLogipeVendor: isLogipeVendor,
              phone:
                values?.phone?.toString() == ""
                  ? undefined
                  : values?.phone?.toString(),
              name: values?.vendorName == "" ? null : values?.vendorName,
              bankAccountName:
                values?.bankAccountName == ""
                  ? undefined
                  : values?.bankAccountName,
              ifsc: values?.ifsc == "" ? undefined : values?.ifsc,
              gstNumber: values?.gst == "" ? undefined : values?.gst,
              address: values?.address == "" ? undefined : values?.address,
              vendorType : values?.vendorType == "" ? "1" : values?.vendorType
            };
            if (oldDetails?.id) {
              dispatch(
                editVendorAciton(payload, (res) => {
                  setLoader(false);
                  console.log("asdasdasdsad ");
                  if (res?.status == 200) {
                    setSuccessModal(true);
                  } else {
                    dispatch(cleanVendorList());
                    setSuccessModal(false);
                    dispatch(
                      getVendorAction({
                        isLogipeVendor: isLogipeVendor,
                        limit: 7,
                        offset: 0,
                      })
                    );
                  }
                })
              );
            } else {
              dispatch(
                createVendorAction(payload, (res) => {
                  setLoader(false);
                  console.log("asdasdasdsad ");
                  if (res?.status == 200) {
                    setSuccessModal(true);
                  } else {
                    setFailedError(res?.data?.message);
                    setFailedModal(true);
                  }
                })
              );
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <div className="mt-4">
                  <div className="companyDetailsHeading">Company Details</div>
                  <div className="background-box row mt-3">
                    {companyDetails?.map((item, index) => {
                      return (
                        <div className="col mt-5">
                          <div className="NameHeading">{item?.title}</div>
                          {item?.value == "vendorType" ? (
                            <div className="padding24T">
                              <Field
                                component="select"
                                placeholder={"--Select--"}
                                class="form-select select-option1"
                                aria-label="Default select example"
                                name="source"
                                onChange={handleChange(`${item?.value}`)}
                                value={values[`${item?.value}`]}
                                onBlur={handleBlur("source")}
                                errors={errors.source}
                              >
                                <option value="" disabled selected>
                                  --Select--
                                </option>
                                {defaultStates?.map((opt) => {
                                  // console.log("lllllkkkkk",values)
                                  return (
                                    <option
                                      value={opt?.value}
                                      // onChange={(v) => {
                                      //   console.log("pooppppppp", v);
                                      //   // setSourceName(v?.city);
                                      //   setFieldValue(
                                      //     "vendorType",
                                      //     v?.cityName,
                                      //     true
                                      //   );
                                      //   setFieldValue(
                                      //     "vendorType",
                                      //     (v?.cityId).toString(),
                                      //     true
                                      //   );
                                      // }}
                                    >
                                      {opt?.text}
                                    </option>
                                  );
                                })}
                              </Field>
                            </div>
                          ) : (
                            <div className="padding24T">
                              <input
                                type={
                                  // item?.value == "phone" ? "number" : "text"
                                  "text"
                                }
                                id={`${item?.value}`}
                                className="input-body-5"
                                name={`${item?.value}`}
                                value={values[`${item?.value}`]}
                                touched={touched[`${item?.value}`]}
                                errors={errors[`${item?.value}`]}
                                onBlur={() => {
                                  //   dispatch(sendAnalitics("EnterPhone_web", values));
                                  handleBlur(`${item?.value}`);
                                  if (
                                    item?.value == "phone" &&
                                    isLogipeVendor == "1"
                                  ) {
                                    dispatch(
                                      getLogipeVendorDetailsAction({
                                        phone: values[`${item?.value}`],
                                      })
                                    );
                                  }
                                }}
                                onChange={handleChange(`${item?.value}`)}
                                placeholder={item?.placeHolder}
                                autofocus
                              />
                            </div>
                          )}

                          <div
                            className="text4"
                            style={{
                              // margin: "7px 0 0px 50px",
                              color: "red",
                              textAlign: "left",
                            }}
                          >
                            {errors[`${item?.value}`] &&
                            touched[`${item?.value}`] ? (
                              <div>{errors[`${item?.value}`]}</div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-5">
                  <div className="companyDetailsHeading">Bank Details</div>
                  <div className="background-box row mt-3">
                    {bankDetails?.map((item, index) => {
                      return (
                        <div className="col mt-4">
                          <div className="NameHeading">{item?.title}</div>
                          <div className="padding24T">
                            <input
                              type={
                                // item?.value == "bankAccountNumber"
                                //   ? "text"
                                "text"
                              }
                              id={`${item?.value}`}
                              className="input-body-5"
                              name={`${item?.value}`}
                              value={values[`${item?.value}`]}
                              touched={touched[`${item?.value}`]}
                              errors={errors[`${item?.value}`]}
                              onBlur={() => {
                                //   dispatch(sendAnalitics("EnterPhone_web", values));
                                handleBlur(`${item?.value}`);
                              }}
                              onChange={handleChange(`${item?.value}`)}
                              placeholder={item?.placeHolder}
                              autofocus
                            />
                          </div>
                          <div
                            className="text4"
                            style={{
                              // margin: "7px 0 0px 50px",
                              color: "red",
                              textAlign: "left",
                            }}
                          >
                            {errors[`${item?.value}`] &&
                            touched[`${item?.value}`] ? (
                              <div>{errors[`${item?.value}`]}</div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="text-center" style={{}}>
                  <Button
                    className="greenButton"
                    onClick={handleSubmit}
                    style={{
                      paddingLeft: "10rem",
                      paddingRight: "10rem",
                      marginTop: "50px",
                      border: 0,
                      background: loader ? "#EFEFEF" : "#485B7C",
                      color: loader ? "#B5B5B5" : "#ffffff",
                    }}
                    disabled={loader}
                  >
                    {loader ? "Please Wait" : "Submit"}
                  </Button>
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddNewVendor;
