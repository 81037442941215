import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import AddFreight from "../../../TripsMainV2/Components/AddFreight";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import {
  createTrip,
  getCityAndState,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import ToastV2 from "../../../subComponents/toastV2";
import { toastStatus } from "../../../../services/Store/Actions/appActions";
import TripInvoice from "../TripInvoice";
import ManuallyInvoice from "../ManualInvoice";
import TripInvoiceDetail from "../tripInvoiceDetail";
import TripInvoiceDetailManual from "../TripInvoiceDetailManual";

function CreateInvoice(props) {
  const {
    toggle,
    modal,
    setModal,
    customerId,
    isCheckAll,
    setIsCheckAll,
    isCheck,
    setIsCheck,
    customer,
  } = props;
  const [tripSelect, setTripSelect] = useState([]);
  const [discountPercent, setDiscountPercent] = useState("");
  const [discount, setDiscount] = useState("");
  const [extraCharges, setExtraCharges] = useState("");
  const [taxesIGST, settaxesIGST] = useState("");
  const [igstAdded, setIgstAdded] = useState(false);
  const [cgstAdded, setCgstAdded] = useState(false);
  const [sgstAdded, setSgstAdded] = useState(false);
  const [taxesIGSTPercent, settaxesIGSTPercent] = useState("");
  const [taxesCGST, settaxesCGST] = useState("");
  const [taxesCGSTPercent, settaxesCGSTPercent] = useState("");
  const [taxesSGST, settaxesSGST] = useState("");
  const [taxesSGSTPercent, settaxesSGSTPercent] = useState("");
  const [selectManually, setSelectManually] = useState(false);
  const [tripDetail, setTripDetail] = useState(false);
  const [tripDetailManual, setTripDetailManual] = useState(false);

  const [openToast, setOpenToast] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [formValues, setFormValues] = useState([
    { desc: "", hsn: "", quantity: "", amount: "" },
  ]);

  const [subTotal, setsubTotal] = useState("");
  const [total, setTotal] = useState("");

  const dispatch = useDispatch();
  const handleSelectManually = () => {
    console.log("=======mmm", !selectManually);
    setSelectManually(!selectManually);
  };

  const handleClose = () => {
    setFormValues([{ desc: "", hsn: "", quantity: "", amount: "" }]);
    setTripSelect([]);
    setModal(!modal);
  };

  function toggleTripDetail() {
    setQuantity("0");
    setsubTotal("0");
    setTotal("0");
    let q = 0,
      st = 0,
      t = 0;
    let set = tripSelect?.map((items, i) => {
      q = parseFloat(q) + parseFloat(items?.quantity || 1);
      st = parseFloat(st) + parseFloat(items?.freightAmount);
      t =
        parseFloat(t) +
        parseFloat(items?.quantity || 1) * parseFloat(items?.freightAmount) -
        parseFloat(items?.charges || 0);
    });
    setQuantity(q);
    setsubTotal(st);
    setTotal(t);
    console.log("==subTota11111l== ", subTotal, total);
    if (t) {
      setTripDetail(!tripDetail);
    }
  }

  function toggleTripDetailManually() {
    setQuantity("0");
    setsubTotal("0");
    setTotal("0");
    let q = 0,
      st = 0,
      t = 0;
    let set = formValues?.map((items, i) => {
      q = parseFloat(q) + parseFloat(items?.quantity);
      st = parseFloat(st) + parseFloat(items?.amount);
      t =
        parseFloat(t) + parseFloat(items?.quantity) * parseFloat(items?.amount);
    });
    setQuantity(q);
    setsubTotal(st);
    setTotal(t);
    console.log("==subTota11111l== ", subTotal, total);
    if (t > 0) {
      setTripDetailManual(!tripDetailManual);
    }
  }
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // style={{borderRadius : "10px"}}
      >
        <ModalBody
          className="transactionModalBody"
          //   style={{ height: "80vh" }}
          //   style={{borderRadius : "10px"}}
        >
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div
            style={{ marginTop: " 30px" }}
            //   className="PGCollectionBox"
          >
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Create Invoice
            </div>

            <div>
              <div className="row">
                <div className="col-6 row" style={{ padding: "50px 0 0 72px" }}>
                  <div className="col-12">
                    <label for="vrn" className="heading3 semiBold">
                      Customer Name
                    </label>
                  </div>
                  <div className="col-12" style={{ padding: "15px" }}>
                    {customer?.companyName}
                  </div>
                </div>
                <div className="col-6 row" style={{ padding: "50px 0 0 0px" }}>
                  <div className="col-12">
                    <label for="vrn" className="heading3 semiBold">
                      GSTIN
                    </label>
                  </div>
                  <div className="col-12" style={{ padding: "15px" }}>
                    {customer?.gstin}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 row" style={{ padding: "15px 0 0 60px" }}>
                  <div className="col-1">
                    <input
                      type="radio"
                      name="trips"
                      checked={!selectManually}
                      id="radio1"
                      onChange={(e) => {
                        console.log("===<<<<<nmtknikn");
                        handleSelectManually();
                      }}
                    />
                  </div>
                  <div className="col-9">Select Freights to Invoice</div>
                </div>
                <div className="col-6 row" style={{ padding: "15px 0 0 0px" }}>
                  <div className="col-1">
                    <input
                      type="radio"
                      name="trips2"
                      id="radio2"
                      checked={selectManually}
                      onChange={(e) => {
                        console.log("===<<<<<nmtknikn1");
                        handleSelectManually();
                      }}
                    />
                  </div>
                  <div className="col-9">Enter Details Manually</div>
                </div>
              </div>
              {!selectManually ? (
                <>
                  
                  <div style={{ padding: "30px 0 0 0px" }}>
                    <TripInvoice
                      isCheckAll={isCheckAll}
                      setIsCheckAll={setIsCheckAll}
                      isCheck={isCheck}
                      setIsCheck={setIsCheck}
                      tripSelect={tripSelect}
                      setTripSelect={setTripSelect}
                      customerId={customerId}
                    />
                  </div>
                  <div
                    style={{
                      padding: "25px 0 0 0",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{ width: "160px", borderRadius: "5px" }}
                      className="generateOtp textAddCust Medium btn btn-secondary"
                      onClick={toggleTripDetail}
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ padding: "30px 0 0 0px" }}>
                    <ManuallyInvoice
                      formValues={formValues}
                      setFormValues={setFormValues}
                    />
                    <div
                      style={{
                        padding: "25px 0 0 0",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        style={{ width: "160px", borderRadius: "5px" }}
                        className="generateOtp textAddCust Medium btn btn-secondary"
                        onClick={toggleTripDetailManually}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>

      <TripInvoiceDetail
        toggle={toggleTripDetail}
        isCheck={isCheck}
        modal={tripDetail}
        setModal={setTripDetail}
        tripSelect={tripSelect}
        setTripSelect={setTripSelect}
        discountPercent={discountPercent}
        setDiscountPercent={setDiscountPercent}
        discount={discount}
        setDiscount={setDiscount}
        taxesIGST={taxesIGST}
        settaxesIGST={settaxesIGST}
        taxesIGSTPercent={taxesIGSTPercent}
        settaxesIGSTPercent={settaxesIGSTPercent}
        taxesCGST={taxesCGST}
        settaxesCGST={settaxesCGST}
        taxesCGSTPercent={taxesCGSTPercent}
        settaxesCGSTPercent={settaxesCGSTPercent}
        taxesSGST={taxesSGST}
        settaxesSGST={settaxesSGST}
        taxesSGSTPercent={taxesSGSTPercent}
        settaxesSGSTPercent={settaxesSGSTPercent}
        setSgstAdded={setSgstAdded}
        sgstAdded={sgstAdded}
        subTotal={subTotal}
        setsubTotal={setsubTotal}
        total={total}
        setTotal={setTotal}
        quantity={quantity}
        setQuantity={setQuantity}
        customerId={customerId}
        tripInvoice={"1"}
        setIgstAdded={setIgstAdded}
        setCgstAdded={setCgstAdded}
        setExtraCharges={setExtraCharges}
        extraCharges={extraCharges}
        igstAdded={igstAdded}
        cgstAdded={cgstAdded}
        handleClosePre={handleClose}
      />

      <TripInvoiceDetailManual
        handleClosePre={handleClose}
        toggle={toggleTripDetailManually}
        isCheck={isCheck}
        setIgstAdded={setIgstAdded}
        setExtraCharges={setExtraCharges}
        extraCharges={extraCharges}
        setCgstAdded={setCgstAdded}
        igstAdded={igstAdded}
        cgstAdded={cgstAdded}
        modal={tripDetailManual}
        setModal={setTripDetailManual}
        tripSelect={formValues}
        setTripSelect={setFormValues}
        discountPercent={discountPercent}
        setDiscountPercent={setDiscountPercent}
        discount={discount}
        setDiscount={setDiscount}
        taxesIGST={taxesIGST}
        settaxesIGST={settaxesIGST}
        taxesIGSTPercent={taxesIGSTPercent}
        settaxesIGSTPercent={settaxesIGSTPercent}
        taxesCGST={taxesCGST}
        settaxesCGST={settaxesCGST}
        taxesCGSTPercent={taxesCGSTPercent}
        settaxesCGSTPercent={settaxesCGSTPercent}
        subTotal={subTotal}
        setsubTotal={setsubTotal}
        total={total}
        setTotal={setTotal}
        quantity={quantity}
        setQuantity={setQuantity}
        customerId={customerId}
        tripInvoice={"0"}
        taxesSGST={taxesSGST}
        settaxesSGST={settaxesSGST}
        taxesSGSTPercent={taxesSGSTPercent}
        settaxesSGSTPercent={settaxesSGSTPercent}
        setSgstAdded={setSgstAdded}
        sgstAdded={sgstAdded}
      />
    </div>
  );
}

export default CreateInvoice;
