import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import DatePicker from "react-datepicker";
import { DatePickerField } from "../../../core/inputs/input";
import { useRef } from "react";
import AddFreight from "../../../TripsMainV2/Components/AddFreight";
import { getDriverList } from "../../../../services/Store/Actions/driverslistActions";
import {
  createTrip,
  getCityAndState,
} from "../../../../services/Store/Actions/TripsAction";
import moment from "moment";
import { getCityAndStateAction } from "../../../../services/Store/Actions/omcActions";
import AddDriverV2 from "../../../TripsMainV2/Components/AddDriverV2";
import AddVehicleV2 from "../../../TripsMainV2/Components/AddVehicleV2";
import ToastV2 from "../../../subComponents/toastV2";
import { toastStatus } from "../../../../services/Store/Actions/appActions";

function ManuallyInvoice(props) {
  const { formValues, setFormValues } = props;
  const { customer } = useSelector((state) => state?.customer);
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    console.log("===><<<><><", formValues);
    setFormValues([
      ...formValues,
      { desc: "", hsn: "", quantity: "", amount: "" },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let [rows, setRows] = useState([
    {
      key: 1,
    },
  ]);

  const handleAddRow = (key) => {
    const item = {
      key: key,
    };
    setRows([...rows, item]);
  };
  return (
    <div>
      <Table
        className="TripSectionTable"
        bordered="true"
        id="driver_tables" //
        style={{ height: "30vh", marginTop: "20px" }}
      >
        <thead>
          <tr className="table-header-trip p-0 align-middle text-center">
            <th className="p-0" style={{ width: "7%" }}>
              <div style={{ marginLeft: "10px" }}>S.No</div>
            </th>
            <th className="p-0" style={{ width: "30%" }}>
              <div>Description</div>{" "}
            </th>
            <th className="p-0" style={{ width: "16%" }}>
              HSN Code
            </th>
            <th className="p-0" style={{ width: "16%" }}>
              Quantity
            </th>
            <th className="p-0" style={{ width: "16%" }}>
              Amount
            </th>
            <th className="p-0" style={{ width: "14%" }}>
              Total Amount
            </th>
          </tr>
        </thead>
        <tbody className="lowerBorderLineCust">
          {formValues.map((element, index) => (
            <tr
              className="table-row-manage-trip-new text4 align-middle text-center p-0"
              id="addr0"
              key={index}
              onClick={() => {
                // window.location = "/Customers/" + 1;
                //   navigate("/Customers/" + 1);
              }}
            >
              <td
                style={{
                  width: "7%",
                  borderLeft: "1px solid transparent",
                  borderRight: " 1px solid transparent",
                }}
                className="p-0"
              >
                <div style={{ marginLeft: "10px" }}>{index + 1}</div>
              </td>
              <td
                style={{
                  width: "30%",
                  borderLeft: "1px solid transparent",
                  borderRight: " 1px solid transparent",
                }}
                className="p-0"
              >
                <input
                  type="text"
                  className="minBox3"
                  name="desc"
                  value={element.desc || ""}
                  onChange={(e) => handleChange(index, e)}
                />
              </td>
              <td
                style={{
                  width: "16%",
                  borderLeft: "1px solid transparent",
                  borderRight: " 1px solid transparent",
                }}
                className="p-0"
              >
                <input
                  type="text"
                  className="minBox3"
                  name="hsn"
                  value={element.hsn || ""}
                  onChange={(e) => handleChange(index, e)}
                />
              </td>
              <td
                style={{
                  width: "16%",
                  borderLeft: "1px solid transparent",
                  borderRight: " 1px solid transparent",
                }}
                className="p-0"
              >
                <input
                  type="text"
                  className="minBox3"
                  name="quantity"
                  value={element.quantity || ""}
                  onChange={(e) => handleChange(index, e)}
                />
              </td>
              <td
                style={{
                  width: "16%",
                  borderLeft: "1px solid transparent",
                  borderRight: " 1px solid transparent",
                }}
                className="p-0"
              >
                <input
                  type="text"
                  className="minBox3"
                  name="amount"
                  value={element.amount || ""}
                  onChange={(e) => handleChange(index, e)}
                />
              </td>
              <td
                style={{
                  width: "14%",
                  borderLeft: "1px solid transparent",
                  borderRight: " 1px solid transparent",
                }}
                className="p-0"
              >
                {element.amount && element.quantity ? `₹${parseFloat(element.amount) * parseFloat(element.quantity)}` : `₹0`}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="addItemDiv" onClick={addFormFields}>
        + Add Item
      </div>
    </div>
  );
}

export default ManuallyInvoice;
