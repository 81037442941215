import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import { useParams, useLocation,useSearchParams } from "react-router-dom";
import CardTable from "../CardTable";
import TripsTable from "../TripsTable";
import LedgerTable from "../LedgerTable";
import SalaryTable from "../SalaryTable";
import SvgImage from "../../../Icons/Icons";
import ManageDriverAddBalance from "../../../Modals/ManageDriverAddBalance";
import ManageDriverWithdrawBalance from "../../../Modals/ManageDriverWithdrawBalance";
import NoDriverSelected from "../../../../Components/ManageDriver/SubComponents/NewDriverSideSection/SubComponents/NoDriverSelected";
import {
  getTripsNew,
  clearTripList,
} from "../../../../services/Store/Actions/TripsAction";
const DriverMain = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  let { state, location } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const offset = searchParams.get('offset')
  const search = searchParams.get('search')
  const [selected, setSelected] = useState(0);
  let { tripsCount, tripsListNew } = useSelector((store) => store?.trip);
  const { allDriversAdvance, allDriverListAdvance, driverListLoader } =
    useSelector((state) => state?.driverlist);
  const driver = allDriverListAdvance?.find(elem => elem?.userId == id)
  console.log(allDriverListAdvance,"driverdriverdriverdriver",driver);
  if (!state?.driver) {
    console.log("idididdddd", id);
  }
  const [open2, setOpen2] = useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };

  const toggleModal2 = () => {
    setOpen2(!open2);
  };

  const showModal2 = () => {
    setOpen2(true);
  };

  //handle add to card transfer
  const [open3, setOpen3] = useState(false);

  const handleClose3 = () => {
    setOpen3(false);
  };

  const toggleModal3 = () => {
    setOpen3(!open3);
  };

  const showModal3 = () => {
    setOpen3(true);
  };

  useEffect(() => {
    if (id !== "" && id !== undefined && id !== null) {
      dispatch(
        getTripsNew({
          offset: 0,
          limit: 10,
          driverId: id,
          // startTime: moment(startDate).format("MM/DD/YYYY"),
          // endTime: moment(endDate).format("MM/DD/YYYY"),
          // search: search,
          // status: status,
        })
      );
    } else {
      dispatch(clearTripList());
    }
  }, [id]);

  // const {driver} = state
  const bal = driver?.walletBalance?.toFixed(2) || "N/A";
  return (
    <div
      className="contMain"
      style={{ background: "#FFFFFF", padding: "12px 0px 0px 25px" }}
    >
      {!driver ? (
        <NoDriverSelected />
      ) : (
        <>
          <div className="fontNB24">
            {driver?.fullName}
            <span>
              {driver?.vrn ? <text> ({driver?.vrn} )</text> : ""}
            </span>
          </div>
          <div className="row" style={{ margin: "15px 0 25px 0" }}>
            <div className="col-md-4" style={{ padding: "5px" }}>
              <div
                className="cardInfo3"
                // style={{margin: "20px 0px 30px 0px"}}
              >
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="driverHeader" style={{ color: "#4D5073" }}>
                    Card Balance
                  </div>
                  <div style={{ fontSize: 12 }}>
                    Details <SvgImage name="rightArrow" />
                  </div>
                </div>
                <div className="driverHeader">
                  {driver?.walletAccountStatus < 3
                    ? "No card"
                    : driver?.walletAccountStatus == 3
                    ? "Card Not Linked"
                    : bal === null
                    ? "₹ 0"
                    : "₹ " + bal}
                </div>
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <button
                    className={"cardButton"}
                    style={{ color: "#FFFFFF" }}
                    onClick={showModal3}
                  >
                    Recharge
                  </button>
                  <button
                    className={"cardButton"}
                    style={{ color: "#FFFFFF" }}
                    onClick={showModal2}
                  >
                    Withdraw
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4" style={{ padding: "5px" }}>
              <div className="cardInfo3">
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="driverHeader" style={{ color: "#4D5073" }}>
                    Driver Details
                  </div>
                  <div style={{ fontSize: 12 }}>
                    Details
                    <SvgImage name="rightArrow" />
                  </div>
                </div>
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="fontNM16">Vehicle No</div>
                  <div className="fontNM16">{driver?.vrn}</div>
                </div>
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="fontNM16">Current Trip</div>
                  <div className="fontNM16">
                    {driver?.currentTrip
                      ? driver?.currentTrip
                      : "TR-0120"}
                  </div>
                </div>
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="fontNM16">Phone</div>
                  <div className="fontNM16">{driver?.phone}</div>
                </div>
              </div>
            </div>
            <div className="col-md-4" style={{ padding: "5px" }}>
              <div className=" cardInfo3">
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    className="vehicleHeaderV2 col-8"
                    style={{ color: "#4D5073" }}
                  >
                    Performance{" "}
                    {props.sideBar ? (
                      <></>
                    ) : (
                      <span style={{ fontSize: 12, paddingTop: "5px" }}>
                        (this month)
                      </span>
                    )}
                  </div>
                  <div
                    className="col-4"
                    style={{ fontSize: 12, textAlign: "end" }}
                  >
                    Details
                    <SvgImage name="rightArrow" />
                  </div>
                </div>
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="fontNR16">Total Trips</div>
                  <div className="">{tripsCount || 0}</div>
                </div>
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="fontNR16">Payout Done</div>
                  <div className="">34500</div>
                </div>
                <div
                  className="flexStyling"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="fontNR16">Kms Travelled</div>
                  <div className="">100</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flexStyling">
            <button
              className={selected == 0 ? "ButtonNew" : "ButtonNewInactive"}
              onClick={() => setSelected(0)}
            >
              Card
            </button>
            <button
              className={selected == 1 ? "ButtonNew" : "ButtonNewInactive"}
              onClick={() => setSelected(1)}
            >
              Trips
            </button>
            <button
              className={selected == 2 ? "ButtonNew" : "ButtonNewInactive"}
              onClick={() => setSelected(2)}
            >
              Ledger
            </button>
            {/* <button
              className={selected == 3 ? "ButtonNew" : "ButtonNewInactive"}
              onClick={() => setSelected(3)}
            >
              Salary & Incentive
            </button> */}
          </div>
          <div className="boxTxn1" style={{ marginRight: "15px" }}>
            {selected === 0 ? (
              <CardTable selected={selected} userId={id} />
            ) : selected === 1 ? (
              <TripsTable
                selected={selected}
                userId={id}
                driverName={driver?.fullName}
                accountStatus={driver?.walletAccountStatus}
              />
            ) : selected === 2 ? (
              <LedgerTable
                selected={selected}
                userId={id}
                driverName={driver?.fullName}
                accountStatus={driver?.walletAccountStatus}
              />
            ) : selected === 3 ? (
              <SalaryTable selected={selected} userId={id} />
            ) : (
              <></>
            )}
          </div>
          <ManageDriverAddBalance
            open={open3}
            toggleModal={toggleModal3}
            handleClose={handleClose3}
            name="Add Balance"
            userId={id}
            driverBal={bal}
            cardType={1}
            driverName={driver?.fullName}
            offset={offset}
            search={search}
          />

          <ManageDriverWithdrawBalance
            open={open2}
            toggleModal={toggleModal2}
            handleClose={handleClose2}
            name="Withdraw Balance"
            userId={id}
            driverBal={bal}
            cardType={1}
            driverDetails={{ ...driver, name: driver?.fullName }}
            driverName={driver?.fullName}
            offset={offset}
            search={search}
          />
        </>
      )}
      {/* {
           props.bulkSalary ? <BulkTransfer/> :  !driver ? <NoDriverSelected /> :      
           <div className="col-lg-9 FullDriverDashboardBox">
          <DriverMain
          />
          </div>
        } */}
        
    </div>
  );
};

export default DriverMain;
