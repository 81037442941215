
import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import SvgImage from "../../Icons/Icons";
import PinInput from "react-pin-input";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getVirtuAccount } from "../../../services/Store/Actions/beneficiaryAction";
import AddNewAccount from "../../Modals/addNewAccount";
import "./index.css";
import * as Yup from "yup";
import { bankDirectTransfer, bankBeneficiaryTransfer } from "../../../services/Store/Actions/beneficiaryAction";
import {getBeneficiary} from "../../../services/Store/Actions/beneficiaryAction";
import { sendAnalitics, toastStatus } from "../../../services/Store/Actions/appActions";



const ShowingBeneficiaries=(props)=>{

  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    proceed,
    nextSection,
    ownerLogipeBalance,
    setBeneficiaryName,
    setShowList,
    setDisplayScreen,
    rcolor,
        setRColor,
        selectedBeneficiaryDetails,
        setSelectedBeneficiaryDetails,
        addBeneficiary,
        setAddBeneficiary,
        formProceed,
        setFormProceed
  } = props;

  const dispatch = useDispatch();
  const { beneficiaryDetails } = useSelector((state) => state?.beneficiary);

  useEffect(()=>{
    console.log("inside showing bene");
    dispatch (
      getBeneficiary()
    );
    dispatch(sendAnalitics("Add_Bene_Trns_BA_MA_Web"));
  },[]);
  //const [rcolor, setRColor] = useState(-1);
  //const [selectedBeneficiaryDetails, setSelectedBeneficiaryDetails] = useState();

  const handleBeneficiaryClick= (data,name, i)=> {
    setRColor(i);
    setSelectedBeneficiaryDetails(data);
    setBeneficiaryName(name);
    dispatch(sendAnalitics("Sel_Ben_Trns_BA_MA_Web",{
      selectedBeneficiaryName:name,
      selectedBeneficiaryData:data,
    }));
    //console.log ("Diksha choti bachhi hain",rcolor);
  }
  //const [addBeneficiary, setAddBeneficiary] = useState(false);
  const handleAddBeneficiary=(opt)=>{
    setAddBeneficiary(true);
    //toggleModal();
  }

  // const [formProceed, setFormProceed] = useState(0);
  const handleProceed=(i)=> {

    if (rcolor===-1)
      {
        dispatch(
          toastStatus({
            toastStatus: true,
            message:"Please select the beneficiary",
            status: false,
          })
        )
      }
      //alert("please select the beneficiary");
    else{
      setFormProceed(i);
    setShowList(0);
    setDisplayScreen(2);
    }  

  }

  const toggleModalAddBeneficiary=()=>{
    setAddBeneficiary(!addBeneficiary);
  }

  const handleCloseAddBeneficiary=()=>{
    setAddBeneficiary(false);
    setFormProceed(0);
  }


  



    return (<>
        <div>
          <div>
            <div className="main-body-beneficiary-details row margin0">
              <div className="col-2">
                <span class="circle-logo">1</span>
              </div>
              <div className="col-10">
                <p className="main-body-beneficiary-details-title">
                  Linked Bank Details (Beneficiaries)
                </p>
              </div>

                <div className="">
                  <p className="sub-head2-body  " style={{margin:"4px 0 0 5rem"}}>
                    Select any one or add new bank details for adding
                    balance to owner’s LogiPe account
                  </p>
                </div>
                <div className="beneficiary-details-main ">
                  {beneficiaryDetails?.map((data, i) => {
                    console.log("beneficiary wala data", data);
                    const acno =
                      "xx" + data.bankAccountNumber?.substring(8);
                    //const accNumber  = data?.bankAccountNumber;
                    //const ifscNo = data?.ifsc;
                    
                    const beneficiaryData= data?.beneficiaryId;
                      // accNumber: data?.bankAccountNumber,
                      // ifscNo: data?.ifsc,
                      // fullName: data?.fullName,
                    
                    //const acno = 'xxxx' + data.bankAccountNumber;

                    return (
                      <div
                        key={i}
                        className="row beneficiary-details-sub-heading "
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleBeneficiaryClick(beneficiaryData,data?.fullName, i)
                        }
                      >
                        <p className="col-2">
                          {rcolor === i ? (
                            <SvgImage name="SelectedRadio" />
                          ) : (
                            <SvgImage name="UnselectedRadio" />
                          )}
                        </p>
                        <p className="col-10">
                          {data.fullName + "," + " " + acno}
                        </p>
                      </div>
                    );
                  })}

                  <br />
                </div>
            </div>
          </div>
          <div
            className="sub-title-1 padding24T padding8L"
            onClick={handleAddBeneficiary}
            style={{ cursor: "pointer", paddingLeft:"0.4rem" }}
          >
            <SvgImage name="PlusCircle" classname="plus-circle-logo" />
            <p className="radio-label-2">Add New Account Details</p>
          </div>
          {
            <AddNewAccount
              open={addBeneficiary}
              toggleModal={toggleModalAddBeneficiary}
              newToggleModal={toggleModal}
              handleClose={handleCloseAddBeneficiary}
              //handleClick={handleClick}
              //btn={btn}
              ownerLogipeBalance={ownerLogipeBalance}
              caller="beneficiary"
            />
          }
        </div>
        <button className="butt4" onClick={() => {
          dispatch(sendAnalitics("Proceed_Trns_BA_MA_Web"));
          handleProceed(0)}}
          
          style={{marginTop:"40px"}}>
          Proceed
        </button>
      </>);
};



export default ShowingBeneficiaries;