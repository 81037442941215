import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import "./index.css";
import { useTab } from "@mui/base";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../Icons/Icons";
import moment from "moment";
import ToastV2 from "../../subComponents/toastV2";
import {
  getPincode,
  storeMedia,
  toastStatus,
} from "../../../services/Store/Actions/appActions";
import { useNavigate } from "react-router-dom";
import {
  addNewPhoneFwAction,
  createCustomerAction,
} from "../../../services/Store/Actions/customerAction";
import { ClipLoader } from "react-spinners";

const listing = ["Pay", "Entry", "Details", "Settle"];
function AddFuelNewPhone(props) {
  const { toggleModal, modal, handleClose } = props;
  const [openToast, setOpenToast] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState([]);
  const [displayAction, setDisplayAction] = useState(false);
  const [pincodeData, setPincodeData] = useState();

  const [status, setStatus] = useState(false);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const {pincode} = useSelector((state) => state?.app);
  console.log("lllllll",pincode)
  // const validationSchema = Yup.object().shape({
  //   fullName: Yup.string().required("Please enter your Customer name"),
  //   phone: Yup.string()
  //     .required("Phone number must contain 10 digits")
  //     .matches(
  //       /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  //       "Phone number must contain 10 digits"
  //     ),
  //   pincode: Yup.number()
  //     .typeError("Only Numbers are allowed")
  //     .required("Pin code is required"),
  //   address: Yup.string().required("Please enter your address"),
  //   pan: Yup.string().required("Please enter your Pan Number"),
  //   panUrl: Yup.string().required("Please enter your panUrl"),
  // });

  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
        // style={{borderRadius : "10px"}}
      >
        <ModalBody className="transactionModalBody">
          <div className="closeBtnWithBackground" onClick={handleClose}>
            <SvgImage
              classname="close-btn-text"
              style={{ width: "10%", height: "10%" }}
              name="crossBtnWIthBackground"
              width="20%"
              height="20%"
            />
          </div>
          <div style={{ marginTop: " 30px" }}>
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Add Details
            </div>

            <Formik
              // isValid='true'
              enableReinitialize
              // validationSchema={validationSchema}
              initialValues={{
                fullName: "",
                address: "",
                pincode: "",
                phone: "",
                pan: "",
                panUrl: "",
                // gstin: "",
                // city: "",
                // contactName: "",
                // email: "",
                // state: "",
                // accountNo: "",
                // ifsc: "",
                // upiId: "",
              }}
              onSubmit={(values) => {
                // setOpenToast(true);
                let data = {
                  ...values,
                  address1: values?.address,
                  firstName: values?.fullName?.split(" ")[0],
                  lastName: values?.fullName?.split(" ")[1]
                    ? values?.fullName?.split(" ")[1]
                    : values?.fullName?.split(" ")[0],
                    districtId : pincodeData?.district?.districtId,
                    stateId : pincodeData?.state?.hpclCode,
                    city : pincodeData?.city,
                    state : pincodeData?.state?.stateName,
                };
                console.log("hhhhhinit", data);
                dispatch(
                  addNewPhoneFwAction(data, (resp) => {
                    console.log("jjjjiiiiiii", resp);
                  })
                );
                handleClose();
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "40px", padding: "0 0px 0 55px" }}
                >
                  <FormGroup>
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Full Name
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="fullName"
                            className={`inputBoxAddCust ${
                              touched.fullName && errors.fullName
                                ? "is-invalid"
                                : ""
                            }`}
                            name="fullName"
                            value={values.fullName}
                            onChange={handleChange("fullName")}
                            onBlur={handleBlur("fullName")}
                            touched={touched.fullName}
                            errors={errors.fullName}
                            placeholder="Full Name"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.fullName && touched.fullName ? (
                              <div>*{errors.fullName}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Pan No
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.pan && errors.pan ? "is-invalid" : ""
                            }`}
                            name="pan"
                            value={values.pan}
                            onChange={handleChange("pan")}
                            onBlur={handleBlur("pan")}
                            touched={touched.pan}
                            errors={errors.pan}
                            placeholder="Pan No"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.pan && touched.pan ? (
                              <div>*{errors.pan}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Phone
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.phone && errors.phone ? "is-invalid" : ""
                            }`}
                            name="phone"
                            value={values.phone}
                            onChange={handleChange("phone")}
                            onBlur={handleBlur("phone")}
                            touched={touched.phone}
                            errors={errors.phone}
                            placeholder="Phone"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.phone && touched.phone ? (
                              <div>*{errors.phone}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {/* <FormGroup style={{ marginRight: "110px" }}> */}

                      <div className="col-4 row">
                        <label for="vrn" className="heading3 semiBold">
                          Upload Pancard
                          {!status ? (
                            <div className="row">
                              <div
                                className="heading3 Regular col-8"
                                style={{ margin: "10px 0 5px" }}
                              >
                                Upload Document
                              </div>{" "}
                              {!status ? (
                                <div
                                  className="col-3"
                                  // type="button"
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                  }}
                                >
                                  {imageLoader ? (
                                    <div style={{ zIndex: 10 }}>
                                      <ClipLoader
                                        color={"#485B7C"}
                                        loading={true}
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                      />
                                    </div>
                                  ) : (
                                    <div style={{}}>
                                      <input
                                        type="file"
                                        // multiple
                                        accept="image/*"
                                        // className="add-lr-attachment"
                                        style={{
                                          opacity: 0,
                                          width: "40px",
                                          cursor: "pointer",
                                          position: "relative",
                                          zIndex: 1,
                                        }}
                                        onChange={(e) => {
                                          const img = e?.target.files[0];
                                          console.log("image", img?.name);

                                          const tempArray = Object.assign(
                                            [],
                                            values.docs
                                          );
                                          console.log("sel", tempArray);

                                          if (img == null) {
                                            //clear image
                                            setFieldValue(
                                              "pickedImage",
                                              "",
                                              true
                                            );
                                            //for now  only one supported
                                          } else {
                                            console.log("upload", {
                                              img: img,
                                              uploadType: "panUrl",
                                              isRes: true,
                                            });
                                            setImageLoader(true);
                                            dispatch(
                                              storeMedia(
                                                {
                                                  img: img,
                                                  uploadType: "panUrl",
                                                  isRes: false,
                                                },
                                                (res) => {
                                                  setStatus(true);
                                                  setDisplayName(
                                                    (prev) => [
                                                      { name: img?.name },
                                                    ]
                                                    //  [
                                                    //   ...prev,
                                                    //   { name: img?.name },
                                                    // ]
                                                  );
                                                  setImageLoader(false);
                                                  if (res?.url) {
                                                    console.log("opt", res);
                                                    tempArray.push(
                                                      res?.data?.Location
                                                    );
                                                    setFieldValue(
                                                      "panUrl",
                                                      res?.url,
                                                      true
                                                    );
                                                    // setFieldValue('docs', tempArray, true);
                                                    //for now  only one supported
                                                    // setFieldValue(
                                                    //   "panUrl",
                                                    //   [res?.data?.Location],
                                                    //   true
                                                    // );
                                                  }
                                                }
                                              )
                                            );
                                          }
                                        }}
                                      />
                                      <SvgImage
                                        name="AddAttachment"
                                        height="25"
                                        width="25"
                                        classname="plusButton"
                                      />{" "}
                                    </div>
                                  )}

                                  <div
                                    style={{
                                      margin: "0px -16px 2px -614px",
                                      color: "red",
                                    }}
                                  >
                                    {errors.vehicleRcPhoto &&
                                    touched.vehicleRcPhoto ? (
                                      <div>*{errors.vehicleRcPhoto}</div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </label>
                        <div className="selected-img">
                          {status ? (
                            displayName?.map((d) => {
                              return (
                                <div className="row">
                                  <div className="col-4">
                                    <p
                                      style={{
                                        color: "black",
                                        margin: "5px 0 0",
                                        // padding: "0",
                                      }}
                                    >
                                      {d.name}
                                    </p>
                                  </div>
                                  <div
                                    className="col-2"
                                    onClick={() => {
                                      setStatus(false);
                                    }}
                                  >
                                    <SvgImage
                                      name="CloseButtonLogo"
                                      height="25"
                                      width="25"
                                    />
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/* </FormGroup> */}
                    </div>
                  </FormGroup>

                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="col-4 row">
                      <div className="col-12">
                        <label for="vrn" className="heading3 semiBold">
                          Address
                        </label>
                      </div>
                      <div className="col-12" style={{ padding: "8px" }}>
                        <input
                          type="text"
                          id="tripNo"
                          className={`inputBoxAddCust ${
                            touched.address && errors.address
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{ width: "466px" }}
                          name="address"
                          value={values.address}
                          onChange={handleChange("address")}
                          onBlur={handleBlur("address")}
                          touched={touched.address}
                          errors={errors.address}
                          placeholder="Address"
                        />
                        <div style={{ margin: "7px 0 0px 5px", color: "red" }}>
                          {errors.address && touched.address ? (
                            <div>*{errors.address}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "space-between",
                    //   marginTop: "30px",
                    // }}
                    style={{
                      padding: "0px 0 0 0",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            Pincode
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.pincode && errors.pincode
                                ? "is-invalid"
                                : ""
                            }`}
                            name="pincode"
                            value={values.pincode}
                            onChange={(e) => {
                              setDisplayAction(true);
                              console.log("jjkkkk",e.target.value)
                              dispatch(getPincode({search : e.target.value}))
                              setFieldValue(
                                "pincode",
                                e.target.value,
                                true
                              );
                            }}
                            // onBlur={() => {
                            //   setDisplayAction(false);
                            // }}
                            touched={touched.pincode}
                            errors={errors.pincode}
                            placeholder="Pincode"
                            // onFocus={() => {
                            //   setDisplayAction(true);
                            // }}
                          />
                          {displayAction ? (
                            <div
                              className={"dropDownDivAction2"}
                              style={{ height: `120px`, minWidth: "63px" }}
                            >
                              <div style={{ marginTop: "12px" }}>
                                {pincode?.data?.District?.length > 0 ? (
                                  pincode?.data?.District?.map((item, idx) => {
                                    return (
                                      <div
                                        key={idx}
                                        className="row listItems2"
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={() => {
                                          setFieldValue(
                                            "pincode",
                                            item?.pincode,
                                            true
                                          );
                                          setPincodeData(item)
                                          setDisplayAction(false);
                                        }}
                                        // style={{ padding: "0 0 10px 0" }}
                                      >
                                        <div
                                          className="col-9"
                                          style={{ color: "#2e7c7b" }}
                                        >
                                          {item?.pincode+ " | " + (item?.postOffice?.split(" ")?.length > 1 ? item?.postOffice?.split(" ")[0] : item?.postOffice)}
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.pincode && touched.pincode ? (
                              <div>*{errors.pincode}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            City
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.city && errors.city ? "is-invalid" : ""
                            }`}
                            name="city"
                            value={values.city}
                            onChange={handleChange("city")}
                            onBlur={handleBlur("city")}
                            touched={touched.city}
                            errors={errors.city}
                            placeholder="City"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.city && touched.city ? (
                              <div>*{errors.city}</div>
                            ) : null}
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-4 row">
                        <div className="col-12">
                          <label for="vrn" className="heading3 semiBold">
                            State
                          </label>
                        </div>
                        <div className="col-12" style={{ padding: "8px" }}>
                          <input
                            type="text"
                            id="tripNo"
                            className={`inputBoxAddCust ${
                              touched.state && errors.state ? "is-invalid" : ""
                            }`}
                            name="state"
                            value={values.state}
                            onChange={handleChange("state")}
                            onBlur={handleBlur("state")}
                            touched={touched.state}
                            errors={errors.state}
                            placeholder="State"
                          />
                          <div
                            style={{ margin: "7px 0 0px 5px", color: "red" }}
                          >
                            {errors.state && touched.state ? (
                              <div>*{errors.state}</div>
                            ) : null}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </FormGroup>
                  <FormGroup
                    style={{
                      padding: "25px 0 0 0",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{ width: "160px", borderRadius: "5px" }}
                      className="generateOtp textAddCust Medium btn btn-secondary"
                      // onClick={addFreight}
                    >
                      Done
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>

      {toastObject.toastStatus &&
      toastObject?.message == "Customer Created Successfully" ? (
        <ToastV2
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            setOpenToast(false);
          }}
          isOpen={toastObject?.toastStatus}
          name="driver"
          handleClose={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
                message: "",
                status: true,
              })
            );
            // window.location = "/Customers";
            // navigate("/Customers");
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default AddFuelNewPhone;
