import { 
  LOGIN_SUCCESS,
  LOGIN,
  SEND_OTP,
  SEND_OTP_SUCCESS,
  LOGIN_WITH_PASSWORD,
  LOGIN_WITH_PASSWORD_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  GET_OWNER_ACCOUNT_DETAILS,
  GET_OWNER_ACCOUNT_DETAILS_SUCCESS,
  GET_OWNER_DETAILS,
  GET_OWNER_DETAILS_SUCCESS,
  CLEAR_AUTH_STORE,PIN_VERIFY_SUCCESS,
  REQUEST_LOGIN_SUCCESS,
  CLEAN_LOGIN_LOADER,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS
 } from "../storeTypes";

export function resetPasswordAction(payload,callback){
  return{
    type:RESET_PASSWORD,
    payload:payload,
    callback

  }    
}
export function resetPasswordActionSuccess(payload){
return{
    type:RESET_PASSWORD_SUCCESS,
    payload
}
}
export function login(payload,callback){
      return{
        type:LOGIN,
        payload:payload,
        callback
  
      }    
}
export function loginSuccess(payload){
    return{
        type:LOGIN_SUCCESS,
        payload
    }
}

export function loginWithPassword(payload,callBack){
  return{
    type:LOGIN_WITH_PASSWORD,
    payload:payload,
    callBack,
  }    
}
export function loginWithPasswordSuccess(payload){
return{
    type:LOGIN_WITH_PASSWORD_SUCCESS,
    payload
}
}

export function handleLoginSuccess(token) {
  return {type: REQUEST_LOGIN_SUCCESS, payload: token};
}

export function sendOtp(obj, callBack) {
  console.log("otp-> sdsdsdsdsdsd",obj);
  return {
    type: SEND_OTP,
    payload:obj,
    callBack
  };
}

export function sendOtpSuccess(msg) {
  return {type: SEND_OTP_SUCCESS, payload: msg};
}

export function verifyOtp(verifyObj, callBack) {
  return {
    type: VERIFY_OTP,
    verifyObj,
    callBack,
  };
}

export function verifyOtpSuccess(data) {
  return {type: VERIFY_OTP_SUCCESS, payload:data};
}


export function getOwnerDetails() {
  //console.log("hit hit hit ");
  return {
    type: GET_OWNER_DETAILS,
  };
}
export function getOwnerDetailsSuccess(ownerDetails) {
  return {
    type: GET_OWNER_DETAILS_SUCCESS,
    payload: ownerDetails,
  };
}


export const getOwnerAccountDetails = () => {
  return {
    type: GET_OWNER_ACCOUNT_DETAILS,
  };
};
export const getOwnerAccountDetailsSuccess = payload => {
  return {
    type: GET_OWNER_ACCOUNT_DETAILS_SUCCESS,
    payload,
  };
};

export const clearAuthStore = () => {
  return {
    type: CLEAR_AUTH_STORE,
  };
}

export function pinVerifySuccess(payload) {
  return {type: PIN_VERIFY_SUCCESS, payload: payload};
}

export const cleanLoginLoader=()=>{
  return{
    type:CLEAN_LOGIN_LOADER,
  }    
}