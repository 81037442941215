import React, { useEffect, useState } from "react";
import SvgImage from "../../../../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { sendOtp } from "../../../../services/Store/Actions/LoginAction";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { cleanOnboardingLoaderAction } from "../../../../services/Store/Actions/onboardingAction";


const BusinessDetails=(props)=>{
    const validationSchema = Yup.object().shape({
        businessName: Yup.string().required("Business name is mandatory"),
        businessType: Yup.string().required("Business type is mandatory").test(
        "noDefaultFormatNeed",
        "Please select download format",
        (val) => val != "default"),
        // businessType: Yup.string()
        // .required("Business type is mandatory"),
        truckNum: Yup.number()
        .required("Truck Number is mandatory").min(1, "Number of trucks should be greater than 0"),
      });

    const {setBusName,setBusType, setTruckNum, setRegStatus}= props;  
    const { loader } = useSelector((state) => state?.onboarding);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(cleanOnboardingLoaderAction());
        dispatch(sendAnalitics("BusinessDetailsOnboarding_web"));
    },[])


    return (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            businessName: "",
            businessType:"default", 
            truckNum:""
          }}
          onSubmit={(values, actions) => {
            //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
            console.log("valuessss", values);
            //dispatch(sendAnalitics("GetOTP_web", values));
            setBusName(values.businessName.trim());
            setBusType(values.businessType.trim());
            setTruckNum(values.truckNum.trim());
            setRegStatus(3);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit} className="InputForm1">
              <FormGroup className="formPosition">
                <div className="heading1 semiBold">Business Details 
                 
                </div>
                <div className="Regular text3 padding8T headText2">
                    Business Name
                </div>
                <div className="padding8 padding0L" >
                  <div className=" phoneNumberBox padding8">
                    {/* <div
                      className="col-2 padding0L boxWithBorder"
                      style={{ textAlign: "center" }}
                    >
                      <SvgImage name="countryCode" />
                    </div> */}
                    
                      <input
                        type="text"
                        id="businessName"
                        className="input-body-4 Regular text3"
                        name="businessName"
                        value={values.businessName}
                        touched={touched.businessName}
                        errors={errors.businessName}
                        onBlur={()=>{
                        //   dispatch(sendAnalitics("EnterPhone_web", values));
                          handleBlur("businessName");
                        }}
                        onChange={handleChange("businessName")}
                        style={{
                          textAlign: "left",
                          marginLeft: "0px",
                          width: "100%",
                        }}
                        placeholder="Enter business name"
                        autofocus
                      ></input>
                    </div>
                  </div>
                
                <div
                  className="text4"
                  style={{
                    // margin: "7px 0 0px 50px",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {errors.businessName && touched.businessName ? (
                    <div>{errors.businessName}</div>
                  ) : null}
                </div>
                <div className="Regular text3 padding8T headText2">
                    Business Type
                </div>
                <div className="padding8 padding0L" >
                  {/* <div className=" phoneNumberBox padding8">
                      <input
                        type="text"
                        id="businessType"
                        className="input-body-4 Regular text3"
                        name="businessType"
                        value={values.businessType}
                        touched={touched.businessType}
                        errors={errors.businessType}
                        onBlur={()=>{
                        //   dispatch(sendAnalitics("EnterPhone_web", values));
                          handleBlur("businessType");
                        }}
                        onChange={handleChange("businessType")}
                        style={{
                          textAlign: "left",
                          marginLeft: "0px",
                          width: "100%",
                        }}
                        placeholder="Enter business type"
                        autofocus
                      ></input>
                    
                  </div> */}
                  <select
                    class="phoneNumberBox padding8 Regular text3"
                    aria-label="Default select example"
                    value={values.businessType}
                    defaultValue={values.businessType}
                    onChange={(e) =>{
                      setFieldValue("businessType", e.target.value, true)
                      }
                    }
                    placeholder="Select here"
                  >
                    <option value="default" disabled hidden>Select Here</option>
                    <option value="One person company">One person company</option>
                    <option value="Partnership firm registration (LLP)">Partnership firm registration (LLP)</option>
                    <option value="Private limited company (Pvt.Ltd.)">Private limited company (Pvt.Ltd.)</option>
                  </select>
                </div>
                <div
                  className="text4"
                  style={{
                    // margin: "7px 0 0px 50px",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {errors.businessType && touched.businessType ? (
                    <div>{errors.businessType}</div>
                  ) : null}
                </div>
                <div className="Regular text3 padding8T headText2">
                    Total number of trucks
                </div>
                <div className="padding8 padding0L" >
                  <div className=" phoneNumberBox padding8">
                    {/* <div
                      className="col-2 padding0L boxWithBorder"
                      style={{ textAlign: "center" }}
                    >
                      <SvgImage name="countryCode" />
                    </div> */}
                    
                      <input
                        type="text"
                        id="truckNum"
                        className="input-body-4 Regular text3"
                        name="truckNum"
                        value={values.truckNum}
                        touched={touched.truckNum}
                        errors={errors.truckNum}
                        onBlur={()=>{
                        //   dispatch(sendAnalitics("EnterPhone_web", values));
                          handleBlur("truckNum");
                        }}
                        onChange={handleChange("truckNum")}
                        style={{
                          textAlign: "left",
                          marginLeft: "0px",
                          width: "210px",
                        }}
                        placeholder="Enter number of trucks"
                        autofocus
                      ></input>
                   
                  </div>
                </div>
                <div
                  className="text4"
                  style={{
                    // margin: "7px 0 0px 50px",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {errors.truckNum && touched.truckNum ? (
                    <div>{errors.truckNum}</div>
                  ) : null}
                </div>
                <Button
                  type="submit"
                  className="loginButton semiBold text2"
                  style={{
                    marginTop: "1rem",
                    // background:"#2e7c7b",
                    // color:"#ffffff"
                    background:
                        values.businessName==""
                        ? "#EFEFEF"
                        :values.businessType==""
                          ? "#EFEFEF" 
                        :values.truckNum==""
                        ? "#EFEFEF"   
                        : loader
                        ? "#EFEFEF"
                        : "#2e7c7b",
                    color:
                        values.businessName==""
                        ? "#B5B5B5"
                        :values.businessType==""
                          ? "#B5B5B5" 
                        :values.truckNum==""
                        ? "#B5B5B5"   
                        : loader
                        ? "#B5B5B5"
                        : "#ffffff",
                  }}
                  disabled={loader ? true : false}
                >
                  {loader ? "Please Wait" : "Submit"}
                  
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      );  
}

export default BusinessDetails;