import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SvgImage from "../../../../Icons/Icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import VehicleList from "../../../VehicleList";
import { Modal, ModalBody } from "reactstrap";
import { m2pMonthlyBalanceAction, M2pUserDetailsAction } from "../../../../../services/Store/Actions/m2pAction";
import { getDriverBal } from "../../../../../services/Utils/Apis";
import ChangeNickNameModal from "./ChangeNickNameModal";

const DriverInfo=(props)=>{

    const {driverDetails, userId, cardType, driverFleet}= props;
    const data1={
            nickname:"Raju",
            contact: 7602864000,
            exp:"58,0000.00", 
            veh:"WB27A 4748"
        }
    const dispatch= useDispatch();
    useEffect(()=>{
        console.log ("m2pppp");
        if(userId!=""){
            //dispatch(M2pUserDetailsAction(userId));
            //dispatch(m2pMonthlyBalanceAction(userId));
        }
        if(userId!=""&& cardType==1){
            //dispatch(getDriverBal(userId));
        }
    },[userId])

    const [open, setOpen] = useState(false);
    //const [proceed, setProceed] = useState(0);
    
    const toggleModal=()=>{
        setOpen(!open);
        // setProceed(0);
    }
    
    const handleClose = () => {
        setOpen(false);
        // setProceed(0);
    };
    
    const handleShow = () => {
        setOpen(true);
    };

    // for handling nickname modal 
    const [openName, setOpenName] = useState(false);
    //const [proceed, setProceed] = useState(0);
    
    const toggleModalName=()=>{
        setOpenName(!openName);
        // setProceed(0);
    }
    
    const handleCloseName = () => {
        setOpenName(false);
        // setProceed(0);
    };
    
    const handleShowName = () => {
        setOpenName(true);
    };

    //const driverBal = useSelector((state) => state?.driverlist?.bal);
    //const m2pBal = useSelector((state) => state?.driverlist?.bal);
    //const m2pMonthlySpend=useSelector((state) => state?.m2p?.m2pMonthlyBal?.response?.thisMonthSpending);

    //console.log("driver test",cardType ,driverBal, m2pMonthlySpend )
    //const monthlyExp= cardType ==2?(m2pMonthlySpend== null?0: m2pMonthlySpend):driverBal.monthlySpend == null ? 0 : driverBal.monthlySpend;
    return(
      <>
        <div className="row whiteBackground boxShadowIntro" style={{margin:"0", borderRadius:"0.5rem"}}>
            <div className="col-8 padding16 flexStyling">
                <SvgImage name="driverPageIcon" />
                <div className="padding16L">
                    <div className="flexStyling">
                      <div className="Bold heading2">{driverDetails?.name==null?"Select Driver":driverDetails?.name}</div>
                      <div className="greyFont Bold heading2 padding16L">
                        ({driverDetails?.nickname?driverDetails?.nickname:"NA"})
                      </div>
                      <div onClick={handleShowName} style={{cursor:"pointer"}}>
                        <SvgImage name="editLogo" width="23" height="23" />
                      </div>
                    </div>
                    <div className="Regular heading2">Contact: {driverDetails.phone==null?"N/A":driverDetails.phone}</div>
                </div>
            </div>
            <div className="col-4 padding8 padding16T driverCardColor flexStyling" 
            style={{ borderRadius:"0 0.5rem 0.5rem 0"}}
            onClick={()=>{
                    handleShow()
            }}>
                <SvgImage name="driverPageIconSmall" />
                <div className="padding8L padding8R">
                    <div className="Regular heading4">Assigned Vehicle</div>
                    <div className={driverFleet == null?"Bold heading4":driverFleet == "Driver not selected"?"Bold heading4":"Bold heading3"}>
                        {driverFleet == null ? "Yet to be Assigned" :driverFleet}</div>
                </div>
                <SvgImage name="editLogo" width="18" height="18" />
                <div className="semiBold heading4 greenFont" style={{cursor:"pointer"}}>Change</div>
            </div>
            {
        <Modal
          isOpen={open}
          onHide={handleClose}
          className="PGCollection-modal"
          toggle={toggleModal}
          aria-labelledby="myModalLabel2"
        >
          {/* <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button> */}
          <ModalBody style={{paddingTop:"0"}}>
            <div className="PGCollectionBox">
            <div 
                className= /*"PGCollectionclose */ "row close-btn-for-transaction padding16T"
                onClick={toggleModal}>
                  <div className="col-10"></div>
                <div className="col-1"  style={{textAlign:"right" }}            >
                  <SvgImage
                    classname = 'close-btn-text'
                    style={{ width: "10%", height: "10%" }}
                    name="CloseButtonLogo"
                    width="20%"
                    height="20%"
                  />
                </div>
                <div className="col-1 padding0L" >Close</div>
              </div>
              <VehicleList setVehicleNumber={props.setVehicleNumber} driverID={props.driverID} handleClick={handleClose} toggleModal={toggleModal} />
            </div>
          </ModalBody>
        </Modal>
      }
        </div>
        <ChangeNickNameModal 
          open={openName}
          toggleModal={toggleModalName}
          handleClose={handleCloseName}
          driverName={driverDetails?.name}
          phone={driverDetails.phone}
          nickname={driverDetails?.nickname?driverDetails?.nickname:"NA"}
          />
        </>
    )
}

export default DriverInfo;