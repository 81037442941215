import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  NavLink,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import { addCustomer } from "../../../services/Store/Actions/receivableActions";
import {
  getAllState,
  getCityByStateId,
} from "../../../services/Store/Actions/appActions";
import * as Yup from "yup";
import { Alert } from "bootstrap";

const options = [
  { label: "Shipper", value: 0, id: 1 },
  { label: "Broker", value: 1, id: 2 },
  { label: "Transporter", value: 2, id: 3 },
];

function AddNewCustomerModal(props) {
  const { open, toggleModal, title, handleClick, handleClose, btn } = props;
  const {loader} = useSelector((state) => state?.receivable);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    customerType: Yup.number("Only Numbers are allowed")
      .required("Required")
      .min(0, "Type must be selected"),
    phone: Yup.number().typeError("Only Numbers are allowed").required("Required"),
    companyName: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
  });



  const { allState, cityByStateId } = useSelector((state) => state?.app);
  useEffect(() => {
    dispatch(getAllState());
  }, []);

  // console.log("state", allState);
  // console.log("city", cityByStateId);

  const handleCity = (Id) => {
    console.log("acccity", Id);
    getCityByStateId(Id);
  };

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons2 settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-content" style={{width:"530px", overflowY:"scroll", overflowX:"hidden"}} >
        <div className="modal-head-part">
          <p className="Add-balance-title">Add New Customer</p>

          <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button>
        </div>
        <ModalBody>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              firstName: "",
              lastName: "",
              customerType: "",
              phone: "",
              companyName: "",
              state: "",
              city: "",
            }}
            onSubmit={(values, actions) => {
              console.log("values onSubmit", values);

              dispatch(
                addCustomer(values, (res) => {
                  console.log("addCustomer call back", res.data);
                  actions.setSubmitting(false);
                  if (res?.data?.type === "success") {
                    handleClose();
                    //window.location.reload();

                    //alert("success");
                  } else {
                    handleClose();
                    // alert("error");
                  }
                })
              );
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">1</span>
                    <label
                      htmlFor="firstName"
                      className="sub-title-edit-vehicle-detial "
                    >
                      Customer's First Name
                    </label>
                  </div>
                  <input
                    type="text"
                    id="firstName"
                    className="input-body-3"
                    name="firstName"
                    onChange={handleChange("firstName")}
                    value={values.firstName}
                    onBlur={handleBlur("firstName")}
                    touched={touched.firstName}
                    errors={errors.firstName}
                    placeholder="First Name"
                  />
                  <div className={`input-amount-for-link `}></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.firstName && touched.firstName ? (
                      <div>*{errors.firstName}</div>
                    ) : null}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">2</span>
                    <label
                      htmlFor="lastName"
                      className="sub-title-edit-vehicle-detial "
                    >
                      Customer's Last Name
                    </label>
                  </div>

                  <input
                    type="text"
                    id="lastName"
                    className="input-body-3"
                    name="lastName"
                    onChange={handleChange("lastName")}
                    value={values.lastName}
                    onBlur={handleBlur("lastName")}
                    touched={touched.lastName}
                    errors={errors.lastName}
                    placeholder="Last Name"
                  />
                  <div className={`input-amount-for-link `}></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.lastName && touched.lastName ? (
                      <div>*{errors.lastName}</div>
                    ) : null}
                  </div>
                </FormGroup>

                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">3</span>
                    <label
                      htmlFor="customerType"
                      className="sub-title-edit-vehicle-detial"
                    >
                      Customer Type
                    </label>
                  </div>
                  <Field
                    component="select"
                    placeholder={"--Select--"}
                    class="form-select select-option"
                    aria-label="Default select example"
                    name="customerType"
                    onChange={handleChange("customerType")}
                    value={values.customerType}
                    onBlur={handleBlur("customerType")}
                    errors={errors.customerType}
                  >
                    <option value="" disabled selected>
                      --Select--
                    </option>
                    {options.map((opt) => {
                      return (
                        <option
                          value={opt.value}
                          onChange={(v) =>
                            setFieldValue("customerType", v, true)
                          }
                        >
                          {opt.label}
                        </option>
                      );
                    })}
                  </Field>
                  <div className={`input-amount-for-link `}></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.customerType && touched.customerType ? (
                      <div>*{errors.customerType}</div>
                    ) : null}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">4</span>
                    <label
                      htmlFor="phone"
                      className="sub-title-edit-vehicle-detial "
                    >
                      Customer Number
                    </label>
                  </div>

                  <input
                    type="text"
                    id="phone"
                    className="input-body-3"
                    name="phone"
                    onChange={handleChange("phone")}
                    value={values.phone}
                    onBlur={handleBlur("phone")}
                    touched={touched.phone}
                    errors={errors.phone}
                    placeholder="Enter Customer Phone Number"
                    maxlength="10"
                  />
                  <div className={`input-amount-for-link `}></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.phone && touched.phone ? (
                      <div>*{errors.phone}</div>
                    ) : null}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">5</span>
                    <label
                      htmlFor="companyName"
                      className="sub-title-edit-vehicle-detial "
                    >
                      Company Name
                    </label>
                  </div>
                  <input
                    type="text"
                    id="companyName"
                    className="input-body-3"
                    name="companyName"
                    onChange={handleChange("companyName")}
                    value={values.companyName}
                    onBlur={handleBlur("companyName")}
                    touched={touched.companyName}
                    errors={errors.companyName}
                    placeholder="Enter Company Name"
                  />
                  <div className={`input-amount-for-link `}></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.companyName && touched.companyName ? (
                      <div>*{errors.companyName}</div>
                    ) : null}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">6</span>
                    <label
                      htmlFor="state"
                      className="sub-title-edit-vehicle-detial "
                    >
                      State
                    </label>
                  </div>
                  <Field
                    component="select"
                    placeholder={"--Select--"}
                    class="form-select select-option"
                    aria-label="Default select example"
                    name="state"
                    options={allState}
                    onChange={(e) => {
                      console.log("acity", e.target.value);
                      const valueSelect = e.target.value;

                      const stateId = allState?.find(
                        (opt) => opt.stateName === valueSelect
                      ).stateId;
                      console.log("select", stateId);

                      dispatch(getCityByStateId(stateId));
                      setFieldValue("state", e.target.value, true);
                      // setFieldValue("city", "");
                      // console.log("acity", value.stateId);
                    }}
                    // onChange={allState?.map((value) => {
                    //     console.log("acity",value.stateId);
                    //     //dispatch(getCityByStateId(value.stateId));
                    //     setFieldValue('state', value.stateName, true);
                    //     setFieldValue('city', '');
                    // })}
                    value={values.state}
                    onBlur={handleBlur("state")}
                    errors={errors.state}
                  >
                    <option value="" disabled selected>
                      --Select--
                    </option>
                    {allState?.map((opt) => {
                      return (
                        <option
                          value={opt?.stateName}

                          // onClick = {handleCity(opt?.stateId)}
                        >
                          {opt.stateName}
                        </option>
                      );
                    })}
                  </Field>
                  <div className={`input-amount-for-link `}></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.state && touched.state ? (
                      <div>*{errors.state}</div>
                    ) : null}
                  </div>
                </FormGroup>

                <FormGroup>
                  <div className="sub-title-1">
                    <span class="circle-logo">7</span>
                    <label
                      htmlFor="city"
                      className="sub-title-edit-vehicle-detial "
                    >
                      City
                    </label>
                  </div>
                  <Field
                    component="select"
                    placeholder={"--Select--"}
                    class="form-select select-option"
                    aria-label="Default select example"
                    name="city"
                    onChange={handleChange("city")}
                    value={values.city}
                    onBlur={handleBlur("city")}
                    errors={errors.city}
                  >
                    <option value="" disabled selected>
                      --Select--
                    </option>
                    {cityByStateId?.map((opt) => {
                      return (
                        <option
                          value={opt?.cityName}
                          onChange={(v) => {
                            setFieldValue("city", v, true);
                          }}
                        >
                          {opt?.cityName}
                        </option>
                      );
                    })}
                  </Field>
                  <div className={`input-amount-for-link `}></div>
                  <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                    {errors.city && touched.city ? (
                      <div>*{errors.city}</div>
                    ) : null}
                  </div>
                  <Button
                    type="submit"
                    className="butt-4"
                    style={{ marginTop: "90px" }}
                    disabled={loader?true:false}
                  >
                  {loader?"Please Wait":"Add Customer"}

                    {/* Add Customer */}
                  </Button>
                </FormGroup>
                <div></div>
              </Form>
            )}
          </Formik>
          {/* <div className="space-div"></div> */}
        </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default AddNewCustomerModal;
