import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, FormGroup, Form } from "reactstrap";
import "./index.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import { useRef } from "react";
import { storeMedia } from "../../../../services/Store/Actions/appActions";
import { getCustomers } from "../../../../services/Store/Actions/receivableActions";
import {
  addFreight,
  editFreight,
  getTripsNew,
} from "../../../../services/Store/Actions/TripsAction";
import AddBalance from "../AddBalances";
import Toast from "../../../subComponents/toast";
import AddNewCustomerModal from "../../../Modals/AddNewCustomerModal";
import AddNewCustomerV2Modal from "../../../Modals/newCustomerModal";
import CreateCustomerV2 from "../../../ManageCustomersV2/SubComponents/CreateCustomerV2";
import { ClipLoader } from "react-spinners";
function EditFreight(props) {
  console.log("adfajnejcajkec", props);
  const [openToast, setOpenToast] = useState(false);
  const [openText, setOpenText] = useState(
    "Freight Details added successfully"
  );
  const [openTextFreight, setOpenTextFreight] = useState(
    "Error adding freight details"
  );

  const [openToastFreight, setOpenToastFreight] = useState(false);

  const [open, setOpen] = useState(false);
  const [openCustomer, setOpenCustomer] = useState(false);
  const [search, setSearch] = useState("");
  const [openVendor, setOpenVendor] = useState(false);
  const freightDetails = useSelector((state) => state?.trip?.freightDetails);
  const { toggle, freight, setFreight, page } = props;
  const [vehicle, setVehicle] = useState(false);
  const toggleAddVehicle1 = () => setVehicle(!vehicle);
  const [driverV2, setDriverV2] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const toggleAddDriverV2 = () => setDriverV2(!driverV2);
  const [fr_Details, setFr_Details] = useState(false);
  const toggleFreightDetails = () => setFr_Details(!fr_Details);
  const dispatch = useDispatch();
  const datepickerRefEnd = useRef();
  const datepickerRefStart = useRef();
  const [status, setStatus] = useState(false);
  const [displayName, setDisplayName] = useState([]);
  const { customerList, customerNo } = useSelector((state) => state?.customer);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const { customer } = useSelector((state) => state?.customer);
  const handleCloseCustomer = () => {
    setAddNewCustomer(false);
  };

  const addNewCustomerOnclick = () => setAddNewCustomer(!addNewCustomer);
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const closeBtn = (
    <button
      className="close"
      style={{
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        backgroundColor: "#FFF",
        borderColor: "#2E7C7B",
        display: "flex",
        justifyContent: "space-evenly",
      }}
      onClick={toggle}
      type="button"
    >
      <div className="x">X</div>
    </button>
  );
  const validationSchema = Yup.object().shape({
    freightAmount: Yup.number()
      .typeError("Only Numbers are allowed")
      .required("Required")
      .min(1, "Amount should be greater than 1"),
  });
  const handleCloseFreight = () => {
    setFreight(!freight);
  };
  useEffect(() => {
    // console.log("yeyey", search);
    dispatch(
      getCustomers({
        offset: 0,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, [search]);
  const { allCustomers, allCustomersList } = useSelector(
    (state) => state?.receivable
  );
  console.log("========>>>>>>>>>", allCustomers);
  return (
    <div>
      <Modal
        isOpen={freight}
        onHide={handleCloseFreight}
        className="all-transaction-main-modal"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px" }}
        >
          <div className="PGCollectionBox">
            <div className=/*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction">
              <div className="closeEffect" onClick={toggle}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
            </div>
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Edit Freight Details
            </div>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                freightAmount: freightDetails?.[page]?.freightAmount || "",
                lrNo: freightDetails?.[page]?.lrNo || "",
                tripId: props?.tripId,
                lrUrl: freightDetails?.[page]?.lrUrl || "",
                customerName: freightDetails?.[page]?.customerName || "",
                customerId: freightDetails?.[page]?.customerId || "",
                vendorId: freightDetails?.[page]?.vendorId || "",
                vendorName: freightDetails?.[page]?.vendorName || "",
              }}
              onSubmit={(values) => {
                console.log("fffffffffffff", values);
                dispatch(
                  editFreight(values, (res) => {
                    console.log("bhasaidna", res);

                    if (res?.status == "200") {
                      // console.log('rererere',res)
                      setOpenToast(true);
                      setOpenText(res?.data?.message);
                      // setFreight(!freight);
                      console.log("...............", res);
                      // alert("success");
                    } else {
                      setOpenTextFreight(res?.data?.message);
                      setOpenToastFreight(true);
                    }
                  })
                );
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "50px", padding: "0 75px 0 50px" }}
                >
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "30px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <FormGroup>
                        <label for="vrn" className="heading3 semiBold">
                          Customer Name
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                          onClick={() => {
                            setOpenCustomer(true);
                          }}
                        >
                          <input
                            className={`form-control-1 ${
                              touched.amount && errors.amount
                                ? "is-invalid"
                                : ""
                            }`}
                            type="text"
                            id="customerName"
                            name="customerName"
                            touched={touched.customerName}
                            errors={errors.customerName}
                            onBlur={handleBlur("customerName")}
                            value={values.customerName}
                            onChange={(event) => {
                              setSearch(event.target.value);
                              setFieldValue(
                                "customerName",
                                event.target.value,
                                true
                              );
                            }}
                            // autoFocus
                            placeholder="Select Customer"
                          />
                        </div>
                        <div style={{ position: "relative" }}>
                          {openCustomer ? (
                            <div className="driverSearchContainer containerShadow">
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0.3rem",
                                  right: "0.3rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpenCustomer(false);
                                }}
                              >
                                <SvgImage name="CloseButtonLogo" />
                              </div>
                              {customerList?.map((item, i) => {
                                return (
                                  <>
                                    <div
                                      key={i}
                                      className="flexStyling padding8 "
                                      onClick={(event) => {
                                        setOpenCustomer(false);
                                        // setUserId(event.target.value);
                                        setFieldValue(
                                          "customerName",
                                          item?.companyName,
                                          true
                                        );
                                        setFieldValue(
                                          "customerId",
                                          item?.customerId,
                                          true
                                        );
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="customerId"
                                        value={item?.customerId}
                                        id={item?.customerId}
                                      />
                                      <div
                                        className="padding8L"
                                        value={item?.customerId}
                                      >
                                        {item?.companyName}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                              {customerNo == 0 ? (
                                <div className="heading3 semiBold padding16 padding24B">
                                  No Customer Available
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.customerName && touched.customerName ? (
                            <div>*{errors.customerName}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      {/* <FormGroup style={{ marginLeft: "20px" }}>
                        <label for="vrn" className="heading3 semiBold">
                          Vendor Name
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                          onClick={() => {
                            setOpenVendor(true);
                          }}
                        >
                          <input
                            className={`form-control-1 ${
                              touched.vendorName && errors.vendorName
                                ? "is-invalid"
                                : ""
                            }`}
                            type="text"
                            id="customerName"
                            name="customerName"
                            touched={touched.vendorName}
                            errors={errors.vendorName}
                            onBlur={handleBlur("vendorName")}
                            value={values.vendorName}
                            onChange={(event) => {
                              setSearch(event.target.value);
                              setFieldValue(
                                "vendorName",
                                event.target.value,
                                true
                              );
                            }}
                            // autoFocus
                            placeholder="Select Vendor"
                          />
                        </div>
                        <div style={{ position: "relative" }}>
                          {openVendor ? (
                            <div className="driverSearchContainer containerShadow">
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0.3rem",
                                  right: "0.3rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpenVendor(false);
                                }}
                              >
                                <SvgImage name="CloseButtonLogo" />
                              </div>
                              {customerList?.map((item, i) => {
                                return (
                                  <>
                                    <div
                                      key={i}
                                      className="flexStyling padding8 "
                                      onClick={(event) => {
                                        setOpenVendor(false);
                                        // setUserId(event.target.value);
                                        setFieldValue(
                                          "vendorName",
                                          item?.companyName,
                                          true
                                        );
                                        setFieldValue(
                                          "vendorId",
                                          item?.customerId,
                                          true
                                        );
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="customerId"
                                        value={item?.customerId}
                                        id={item?.customerId}
                                      />
                                      <div
                                        className="padding8L"
                                        value={item?.customerId}
                                      >
                                        {item?.companyName}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                              {customerNo == 0 ? (
                                <div className="heading3 semiBold padding16 padding24B">
                                  No Customer Available
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.customerName && touched.customerName ? (
                            <div>*{errors.customerName}</div>
                          ) : null}
                        </div>
                      </FormGroup> */}
                      <span
                        id="addDriver"
                        style={{ marginLeft: "20px", cursor: "pointer" }}
                        onClick={addNewCustomerOnclick}
                      >
                        + Add Customer
                      </span>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <label for="freightAmount" className="heading3 semiBold">
                      Freight Amount
                    </label>
                    <input
                      type="text"
                      id="freightAmount"
                      className={`form-control-1 ${
                        touched.freightAmount && errors.freightAmount
                          ? "is-invalid"
                          : ""
                      }`}
                      name="freightAmount"
                      // onChange={handleChange("amount")}
                      value={values.freightAmount}
                      // onBlur={() => {
                      //   dispatch(
                      //     sendAnalitics("Enter_Amt_Web", {
                      //       upi_netBank_amount: values.freightAmount,
                      //     })
                      //   );
                      //   handleBlur("freightAmount");
                      // }}
                      onChange={(event) => {
                        // setSearch(event.target.value);
                        setFieldValue(
                          "freightAmount",
                          event.target.value,
                          true
                        );
                      }}
                      touched={touched.freightAmount}
                      errors={errors.freightAmount}
                      placeholder="Enter Amount"
                    />
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.freightAmount && touched.freightAmount ? (
                        <div>*{errors.freightAmount}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "25px",
                    }}
                  >
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label className="heading3 semiBold">LR number</label>
                      <input
                        type="text"
                        id="lrNo"
                        className={`form-control-1 ${
                          touched.lrNo && errors.lrNo ? "is-invalid" : ""
                        }`}
                        name="lrNo"
                        // onChange={handleChange("amount")}
                        // onBlur={() => {
                        //   dispatch(
                        //     sendAnalitics("Enter_Amt_Web", {
                        //       upi_netBank_amount: values.lrNo,
                        //     })
                        //   );
                        //   handleBlur("lrNo");
                        // }}
                        onChange={(event) => {
                          // setSearch(event.target.value);
                          setFieldValue("lrNo", event.target.value, true);
                        }}
                        value={values.lrNo}
                        touched={touched.lrNo}
                        errors={errors.lrNo}
                        placeholder="Enter LR No"
                      />
                    </FormGroup>
                    <FormGroup style={{ marginRight: "110px" }}>
                      <label for="vrn" className="heading3 semiBold">
                        LR Copy
                        {!status ? (
                          <p
                            className="heading3 Regular"
                            style={{ margin: "10px 0 5px" }}
                          >
                            Upload Document
                          </p>
                        ) : (
                          <></>
                        )}
                      </label>
                      {!status ? (
                        <Button
                          className="add-attach-for-fastag-linked-vehicle-new"
                          type="button"
                        >
                          {imageLoader ? (
                            <div style={{ zIndex: 10 }}>
                              <ClipLoader
                                color={"#485B7C"}
                                loading={true}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div>
                          ) : (
                            <div>
                              <SvgImage
                                name="AddAttachment"
                                height="25"
                                width="25"
                              />
                              <input
                                type="file"
                                // multiple
                                // accept="image/*"
                                accept="image/*"
                                className="add-lr-attachment"
                                onChange={(e) => {
                                  const img = e?.target.files[0];
                                  console.log("image", img?.name);

                                  const tempArray = Object.assign(
                                    [],
                                    values.docs
                                  );
                                  console.log("sel", tempArray);

                                  if (img == null) {
                                    //clear image
                                    setFieldValue("pickedImage", "", true);
                                    //for now  only one supported
                                  } else {
                                    console.log("upload", {
                                      img: img,
                                      uploadType: "aadharFront",
                                      isRes: true,
                                    });
                                    setImageLoader(true);
                                    dispatch(
                                      storeMedia(
                                        {
                                          img: img,
                                          uploadType: "aadharFront",
                                          isRes: false,
                                        },
                                        (res) => {
                                          setStatus(true);
                                          setDisplayName(
                                            (prev) => [{ name: img?.name }]
                                            //  [
                                            //   ...prev,
                                            //   { name: img?.name },
                                            // ]
                                          );
                                          setImageLoader(false);
                                          if (res?.url) {
                                            console.log("opt", res);
                                            tempArray.push(res?.data?.Location);
                                            setFieldValue(
                                              "pickedImage",
                                              res?.url,
                                              true
                                            );
                                            // setFieldValue('docs', tempArray, true);
                                            //for now  only one supported
                                            setFieldValue(
                                              "docs",
                                              [res?.data?.Location],
                                              true
                                            );
                                          }
                                        }
                                      )
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                          <div
                            style={{
                              margin: "0px -16px 2px -614px",
                              color: "red",
                            }}
                          >
                            {errors.vehicleRcPhoto && touched.vehicleRcPhoto ? (
                              <div>*{errors.vehicleRcPhoto}</div>
                            ) : null}
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <div className="selected-img">
                        {status ? (
                          displayName?.map((d) => {
                            return (
                              <div className="row">
                                <div className="col-9">
                                  <p
                                    style={{
                                      color: "black",
                                      margin: "5px 0 0",
                                      // padding: "0",
                                    }}
                                  >
                                    {d.name}
                                  </p>
                                </div>
                                <div
                                  className="col-2"
                                  onClick={() => {
                                    setStatus(false);
                                  }}
                                >
                                  <SvgImage
                                    name="CloseButtonLogo"
                                    height="25"
                                    width="25"
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "25px",
                      marginTop: "100px",
                    }}
                  >
                    {/* <Button
                      style={{ width: "25%" }}
                      className="notGenerateOpt text4 Medium btn btn-secondary"
                      onClick={toggle}
                    >
                      Back
                    </Button> */}
                    <Button
                      type="submit"
                      style={{ width: "25%" }}
                      className="generateOtp text3 Medium btn btn-secondary"
                    >
                      Submit
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      {/* <AddBalance /> */}
      <Toast
        success={true}
        message={openText}
        // toggleModal={() => {
        //   dispatch(
        //     toastStatus({
        //       toastStatus: false,
        //     })
        //   );
        // }}
        isOpen={openToast}
        name="trips"
        handleClose={() => {
          // setFreight(!freight);
          window.location = `/trips/${props?.tripId}`;
          dispatch(
            getTripsNew({
              offset: 0,
              limit: 10,
            })
          );
          //alert("close button clicked");
        }}
      />

      <Toast
        success={false}
        message={openTextFreight}
        // toggleModal={() => {
        //   dispatch(
        //     toastStatus({
        //       toastStatus: false,
        //     })
        //   );
        // }}
        isOpen={openToastFreight}
        name="trips"
        handleClose={() => {
          setOpenToastFreight(false);
          // setFreight(!freight);
          // window.location='/trips/';
          //alert("close button clicked");
        }}
      />
      <CreateCustomerV2
        toggleModal={addNewCustomerOnclick}
        modal={addNewCustomer}
        handleClose={handleCloseCustomer}
        setModal={setAddNewCustomer}
        // tripId={tripIdTemp}
        // setFreight={setFreight}
      />
    </div>
  );
}

export default EditFreight;
