import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Field, ErrorMessage } from "formik";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { verifyFastagOTPAction } from "../../../services/Store/Actions/fastagAction";
import { m2pContactInfoAction, m2pOwnerAddressAction, m2pRegisterAction, m2pVKYCAction } from "../../../services/Store/Actions/m2pAction";
import { autoFillCityStateAction } from "../../../services/Store/Actions/appActions";

function CardDriverDetails(props) {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    mobileNo,
    handleShowKYC,
  } = props;

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(m2pOwnerAddressAction());
  },[])

  const validationSchema1 = Yup.object().shape({
    otp: Yup.string()
      .required("* Required")
      .matches(/^[0-9]{6}$/, "* Otp must be exactly 6 digits"),
  });
  const validationSchema2 = Yup.object().shape({
    pan: Yup.string()
      .required("* Required")
      .matches(
        /^[a-zA-Z0-9]{10}$/,
        "* PAN Number must be exactly 10 characters"
      ),
  });
  const validationSchema3 = Yup.object().shape({
    kit: Yup.string()
      .required("* Required")
      .matches(/^[0-9]{10}$/, "* Kit Number must be exactly 10 digits"),
  });

  const validationSchema4 = Yup.object().shape({
    address: Yup.string().required("* Required"),
    pincode: Yup.number().typeError("Only Numbers are allowed").required("* Required"),
    //.matches(/^[0-9]{6}$/, "* Pin code must be exactly 6 digits"),
    city: Yup.string().required("* Required"),
    state: Yup.string().required("* Required"),
  });

  //   const userr = sessionStorage.getItem("_user");
  //   const obj = JSON.parse(userr);
  //   const mob = obj?.user?.phone;

  const [pageStatus, setPageStatus] = useState("0");
  const [otp, setOtp] = useState("");
  const [pan, setPan] = useState("");
  const [kit, setKit] = useState("");
  const m2pOwnerAddress= useSelector((state)=>state?.m2p?.m2pOwnerAddress?.userDetails);
  //console.log("00000000000000000000", m2pOwnerAddress);

  let { id } = useParams();
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={() => {
          setPageStatus("0");
          handleClose();
        }}
        className="positons settingModalMargin"
        toggle={() => {
          setPageStatus("0");
          toggleModal();
        }}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">
              {pageStatus == "3" ? "Add Delivery Address" : "Add Card Details"}
            </p>
            <div
              className="padding16"
              style={{
                paddingTop: "20px",
                paddingLeft: pageStatus == "3" ? "70px" : "120px",
              }}
            >
              <SvgImage name="livquikLogo" />
            </div>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                setPageStatus("0");
                toggleModal();
              }}
              aria-label="Close"
              style={{ top: "25px" }}
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>

          <ModalBody>
            {pageStatus == "0" ? (
              <Formik
                enableReinitialize
                validationSchema={validationSchema1}
                initialValues={{
                  otp: "",
                }}
                onSubmit={(values, actions) => {
                  setPageStatus("1");
                  setOtp(values.otp);
                  // const payload = {
                  //   requestId: otpData?.requestId,
                  //   txnNo:otpData?.txnNo,
                  //   otp:values.pin ,
                  //   txnTime:otpData?.txnTime,
                  // };
                  // //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
                  // console.log("valuessss", values);
                  // dispatch(
                  //   verifyFastagOTPAction(payload, (res) => {
                  //     actions.setSubmitting(false);
                  //     console.log("HERE", res);
                  //     if (res?.data?.type === "success") {
                  //       //console.log("successful vaiii");
                  //       handleShowKYC();

                  //     }
                  //   })
                  // );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <FormGroup>
                        <div className="sub-title-1">
                          <p
                            className="sub-head-neww"
                            style={{ textTransform: "inherit" }}
                          >
                            Enter OTP sent on {mobileNo}
                          </p>
                        </div>
                        {/* <p className="sub-head-body">
                        {} 
                      </p> */}
                        <PinInput
                          length={6}
                          initialValue=""
                          secret
                          onChange={handleChange("otp")}
                          type="numeric"
                          value={values.otp}
                          onBlur={handleBlur("otp")}
                          touched={touched.otp}
                          errors={errors.otp}
                          inputMode="number"
                          style={{
                            padding: "10px",
                            marginLeft: "15px",
                            marginTop: "28px",
                          }}
                          inputStyle={{
                            border: "1px solid #A7A7A7",
                            borderRadius: "8px",
                            marginRight: "20px",
                          }}
                          autoSelect={true}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        />
                        <div style={{ margin: "7px 0 0px 30px", color: "red" }}>
                          {errors.otp && touched.otp ? (
                            <div>{errors.otp}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <button
                        type="submit"
                        className="butt4"
                        style={{ marginLeft: "80px" }}
                      >
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : pageStatus == "1" ? (
              <Formik
                enableReinitialize
                validationSchema={validationSchema2}
                initialValues={{
                  pan: "",
                }}
                onSubmit={(values, actions) => {
                  setPageStatus("2");
                  setPan(values.pan);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <FormGroup>
                        <div className="sub-title-1">
                          <p
                            className="sub-head-neww"
                            style={{ textTransform: "inherit" }}
                          >
                            PAN Number
                          </p>
                        </div>
                        {/* <p className="sub-head-body">
                      {} 
                    </p> */}
                        <input
                          type="text"
                          id=""
                          className="input-body-3"
                          name="pan"
                          value={values.pan}
                          touched={touched.pan}
                          errors={errors.pan}
                          onBlur={handleBlur("pan")}
                          onChange={handleChange("pan")}
                          placeholder="Enter PAN number"
                          style={{ fontSize: "18px", marginLeft: "25px" }}
                          maxLength="10"
                        ></input>
                        <div
                          className="input-amount-for-link"
                          style={{ marginLeft: "25px" }}
                        ></div>
                        <div style={{ margin: "7px 0 0px 26px", color: "red" }}>
                          {errors.pan && touched.pan ? (
                            <div>{errors.pan}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <button
                        type="submit"
                        className="butt4"
                        style={{ marginLeft: "30px" }}
                      >
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : pageStatus == "2" ? (
              <Formik
                enableReinitialize
                validationSchema={validationSchema3}
                initialValues={{
                  kit: "",
                }}
                onSubmit={(values, actions) => {
                  setPageStatus("3");
                  setKit(values.kit);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <FormGroup>
                        <div className="sub-title-1">
                          <p
                            className="sub-head-neww"
                            style={{ textTransform: "inherit" }}
                          >
                            Kit Number
                          </p>
                        </div>
                        {/* <p className="sub-head-body">
                      {} 
                    </p> */}
                        <input
                          type="text"
                          id=""
                          className="input-body-3"
                          name="kit"
                          value={values.kit}
                          touched={touched.kit}
                          errors={errors.kit}
                          onBlur={handleBlur("kit")}
                          onChange={handleChange("kit")}
                          placeholder="Enter Kit Number"
                          style={{ fontSize: "18px", marginLeft: "25px" }}
                          maxLength="10"
                        ></input>
                        <div
                          className="input-amount-for-link"
                          style={{ marginLeft: "25px" }}
                        ></div>
                        <div style={{ margin: "7px 0 0px 26px", color: "red" }}>
                          {errors.kit && touched.kit ? (
                            <div>{errors.kit}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <button
                        type="submit"
                        className="butt4"
                        style={{ marginLeft: "30px" }}
                      >
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                enableReinitialize
                validationSchema={validationSchema4}
                initialValues={{
                  address: m2pOwnerAddress?.address1,
                  pincode: m2pOwnerAddress?.pincode,
                  city: m2pOwnerAddress?.city,
                  state: m2pOwnerAddress?.state,
                }}
                onSubmit={(values, actions) => {
                  
                  const payload1={
                    userId:id,
                    otp:otp,
                    kitNo:kit,
                    pan:pan
                  }  
                  const payload2 = {
                    address: values.address,
                    pincode: values.pincode,
                    city: values.city,
                    state: values.city,
                    userId: id,
                  };

                  dispatch(
                    m2pRegisterAction(payload1, (res)=>{
                        console.log("heyyyyyyyyyyyyyyy1",res);
                        if (res?.data?.type === "success") {
                            dispatch(m2pContactInfoAction(payload2, (res)=>{
                                console.log("heyyyyyyyyyyyyyyy2",res);
                                if (res?.data?.type === "success") {
                                    dispatch(m2pVKYCAction(id,(res)=>{
                                      console.log("heyyyyyyyyyyyyyyy2",res);
                                      if (res?.data?.type === "success") {
                                        toggleModal();
                                        props.openVKyc();
                                      }
                                    }));
                                }
                            }))
                        } 
                    })
                  )
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <FormGroup>
                        <div className="sub-title-1">
                          <span class="circle-logo">1</span>
                          <label
                            htmlFor="address"
                            className="sub-title-edit-vehicle-detial "
                          >
                            Address
                          </label>
                        </div>
                        <input
                          type="text"
                          id="address"
                          className="input-body-3"
                          name="address"
                          onChange={handleChange("address")}
                          value={values.address}
                          onBlur={handleBlur("address")}
                          touched={touched.address}
                          errors={errors.address}
                          placeholder="Enter your address"
                        />
                        <div className="input-amount-for-link"></div>
                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.address && touched.address ? (
                            <div>{errors.address}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="sub-title-1">
                          <span class="circle-logo">2</span>
                          <label
                            htmlFor="pincode"
                            className="sub-title-edit-vehicle-detial "
                          >
                            Pin Code
                          </label>
                        </div>
                        <input
                          type="text"
                          id="pincode"
                          className="input-body-3"
                          name="pincode"
                          onChange={handleChange("pincode")}
                          value={values.pincode}
                          onKeyUp={(e)=>{
                            if (values.pincode.length==6){
                                dispatch(autoFillCityStateAction({
                                    pincode:values.pincode
                                }, (res)=>{
                                    console.log("pin codeeeeeeee", res?.data?.data);
                                    setFieldValue("city", res?.data?.data?.District, true);
                                    setFieldValue("state", res?.data?.data?.State, true);
                                }))
                            }
                        }}
                          onBlur={handleBlur("pincode")}
                          touched={touched.pincode}
                          errors={errors.pincode}
                          placeholder="Enter your pin code"
                          maxlength="6"
                        />
                        <div className="input-amount-for-link"></div>
                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.pincode && touched.pincode ? (
                            <div>{errors.pincode}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="sub-title-1">
                          <span class="circle-logo">3</span>
                          <label
                            htmlFor="city"
                            className="sub-title-edit-vehicle-detial "
                          >
                            City
                          </label>
                        </div>
                        <input
                          type="text"
                          id="city"
                          className="input-body-3"
                          name="city"
                          onChange={handleChange("city")}
                          value={values.city}
                          onBlur={handleBlur("city")}
                          touched={touched.city}
                          errors={errors.city}
                          placeholder="Enter city's name"
                        />
                        <div className="input-amount-for-link"></div>
                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.city && touched.city ? (
                            <div>{errors.city}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="sub-title-1">
                          <span class="circle-logo">4</span>
                          <label
                            htmlFor="state"
                            className="sub-title-edit-vehicle-detial "
                          >
                            State
                          </label>
                        </div>
                        <input
                          type="text"
                          id="state"
                          className="input-body-3"
                          name="state"
                          onChange={handleChange("state")}
                          value={values.state}
                          onBlur={handleBlur("state")}
                          touched={touched.state}
                          errors={errors.state}
                          placeholder="Enter state's name"
                        />
                        <div className="input-amount-for-link"></div>
                        <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                          {errors.state && touched.state ? (
                            <div>{errors.state}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <button
                        type="submit"
                        className="butt4"
                        //style={{marginLeft:"30px"}}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            <div className="space-div"></div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
export default CardDriverDetails;
