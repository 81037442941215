import { call, put, takeEvery } from "redux-saga/effects";
import {
  filterExpenseReportApi,
  filterFastagReportApi,
  filterMAReportApi,
  filterWalletReportApi,
  managerReportApi,
} from "../../Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import {} from "../Actions/appActions";
import {
  CASH_EXPENSE_STATEMENT,
  FT_REPORT_FILTER,
  MANAGER_REPORT,
  MA_REPORT_FILTER,
  WALLET_REPORT_FILTER,
} from "../storeTypes";
import { Mixpanel } from "../../../Mixpanel";
import {
  filterExpenseReportActionSuccess,
  filterFastagReportActionSuccess,
  filterMAReportActionSuccess,
  filterWalletReportActionSuccess,
  getManagerReportSuccess,
} from "../Actions/reportActions";
import { toastStatus } from "../../../services/Store/Actions/appActions";

function* filterMAReportSaga(action) {
  try {
    const response = yield call(filterMAReportApi, action.payload);
    // console.log("Add expense to wallet from saga", response);
    if (response.data.type == "success") {
      // console.log("mazeeeeee");
      action.callback(response);
      yield put(filterMAReportActionSuccess(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Master Account report was generated successfully.",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* filterWalletReportSaga(action) {
  try {
    const response = yield call(
      filterWalletReportApi,
      action.userId,
      action.payload
    );
    // console.log("Add expense to wallet from saga", response);
    if (response.data.type == "success") {
      if (response.data.message == "wallet not found") {
        yield put(
          toastStatus({
            toastStatus: true,
            message: `Error : ${response.data.message}`,
            status: false,
          })
        );
      }
      // console.log("mazeeeeee");
      action.callback(response);
      yield put(filterWalletReportActionSuccess(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Driver's report was generated successfully.",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* filterFastagReportSaga(action) {
  try {
    const response = yield call(filterFastagReportApi, action.payload);
    // console.log("Add expense to wallet from saga", response);
    if (response.data.type == "success") {
      // console.log("mazeeeeee");
      action.callback(response);
      yield put(filterFastagReportActionSuccess(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Vehicle's report was generated successfully.",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* filterExpenseReportSaga(action) {
  try {
    const response = yield call(
      filterExpenseReportApi,
      action.userId,
      action.payload
    );
    // console.log("Add expense to wallet from saga", response);
    if (response.data.type == "success") {
      // console.log("mazeeeeee");
      action.callback(response);
      yield put(filterExpenseReportActionSuccess(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Cash expenses's report was generated successfully.",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* managerReportSaga(action) {
  try {
    const response = yield call(
      managerReportApi,
      action?.userId,
      action.payload
    );
    // console.log("Add expense to wallet from saga", response);
    if (response.data.type == "success") {
      // console.log("mazeeeeee");
      action.callback(response);
      yield put(getManagerReportSuccess(response));
      if (response?.data?.data?.url) {
        yield put(
          toastStatus({
            toastStatus: true,
            message: "Cash expenses's report was generated successfully.",
            status: true,
          })
        );
      } else {
        yield put(
          toastStatus({
            toastStatus: true,
            message: "Transaction Not Found.",
            status: true,
          })
        );
      }
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

export function* reportSaga() {
  yield takeEvery(MA_REPORT_FILTER, filterMAReportSaga);
  yield takeEvery(WALLET_REPORT_FILTER, filterWalletReportSaga);
  yield takeEvery(FT_REPORT_FILTER, filterFastagReportSaga);
  yield takeEvery(MANAGER_REPORT, managerReportSaga);
  yield takeEvery(CASH_EXPENSE_STATEMENT, filterExpenseReportSaga);
}
