import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { cleanVehicleLoader } from "../../services/Store/Actions/manageVehicleAction";
import { useParams } from "react-router-dom";
import Toast from "../subComponents/toast";
import { toastStatus } from "../../services/Store/Actions/appActions";
import { cleanFleetLoader } from "../../services/Store/Actions/fleetActions";
import { cleanFastagLoaderAction } from "../../services/Store/Actions/fastagAction";
import FullManagerList from "./SubComponents/FullManagerList/index";
import CustomerManagerDetails from "./SubComponents/CustomerManagerDetails/index";

//import { getFleet } from "../../services/Store/Actions/driverslistActions";
function UserManagement(props) {
  const { sideBar } = props;
  const dispatch = useDispatch();
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const [user, setUser] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [next, setNext] = useState(true);

  let { id } = useParams();
  console.log("ppp", id);

  const { managerListing, managerList } = useSelector(
    (state) => state?.userManagement
  );

  //console.log("Alllllllll",allFleets)
  const nextTrigger = () => {
    setNext(false);
  };
  const allowTrigger = () => {
    setNext(true);
  };
  useEffect(() => {
    if (id !== undefined && id !== "" && next) {
      let val = managerListing?.filter(
        (element) => element?.User?.userId == id
      )[0];
      setUser(val?.User);
      //console.log("ababababaab",val);
    }
  }, [id, managerListing]);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);

  // search value
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let searchParam = params.search === undefined ? "" : params.search;
  const [vehRank, setVehRank] = useState(0);
  return (
    <div
      className="container topMargin sideBarMargin"
      style={{
        marginLeft: sideBar ? "220px" : "100px",
        maxWidth: sideBar ? windowWidth - 240 : windowWidth - 130,
      }}
    >
      <div className="row">
        <div className="col-lg-4 FullDriverDashboardBox">
          <FullManagerList
            nextTrigger={nextTrigger}
            allowTrigger={allowTrigger}
          />
        </div>
        <div className="col-lg-8 FullDriverDashboardBox">
          <div>
            <CustomerManagerDetails user={user} />
          </div>
        </div>
      </div>
      {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            console.log("mesaage-------------other error");
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
          }}
          isOpen={toastObject?.toastStatus}
          name="manager"
          handleClose={() => {
            // dispatch(
            //   toastStatus({
            //     toastStatus: false,
            //     message: "",
            //     status: true,
            //   })
            // );
            // if (!toastObject?.toastStatus)
            //window.location="/Vehicles"
            //window.location = "/Vehicles/" + id;
            window.location =
              "/manager/" +
              id +
              "?search=" +
              searchParam +
              "&offset=" +
              offsetVal;
            //alert("close button clicked");
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
export default UserManagement;
