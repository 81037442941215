import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import SvgImage from "../../../../Icons/Icons";
import "./index.css";
import moment from "moment";
import AddNewVendor from "../../AddNewVendor";
import {
  cleanVendorTransaction,
  generateStatementForVendorAction,
  transferTorVendorAction,
  vendorGetSignedUrlAction,
  vendorTransactionAction,
  vendorUploadBillAction,
} from "../../../../../services/Store/Actions/beneficiaryAction";
import { wait } from "@testing-library/user-event/dist/utils";
import NoTransaction from "../../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/TabularData/NoTransaction";
import { ClipLoader } from "react-spinners";

function VendorTransactions(props) {
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState(0);
  const [offset, setOffset] = useState(0);
  const [enterPin, setEnterPin] = useState(false);
  const [paymentData, setPaymentData] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [failedMsg, setFailedMsg] = useState();
  const [editDetails, setEditDetails] = useState(false);
  const [paymetModal, setPaymentModal] = useState(false);
  const [fleetModal, setFleetModal] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [pinLoader, setPinLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [show, setShow] = useState(true);

  const { vendorTransactionList, vendorTransaction } = useSelector(
    (state) => state?.beneficiary
  );
  const { vendorData, path, setShowVendor } = props;

  const dispatch = useDispatch();
  const trxnCount = vendorTransaction?.count;
  console.log("vendorTransactionList", vendorTransactionList);
  const handlePrev = () => {
    if (offset > 0) {
      dispatch(cleanVendorTransaction());
      setOffset(offset - 5);
      dispatch(
        vendorTransactionAction({
          limit: 5,
          offset: offset - 5,
          from: startDate || "",
          to: endDate || "",
          vendorId: vendorData?.id,
        })
      );
    }
  };
  const handleNext = () => {
    console.log("offset,trxnCount", offset, trxnCount,vendorData);
    if (offset + 5 < trxnCount) {
      dispatch(cleanVendorTransaction());
      setOffset(offset + 5);
      dispatch(
        vendorTransactionAction({
          limit: 5,
          offset: offset + 5,
          from: startDate || "",
          to: endDate || "",
          vendorId: vendorData?.id,
        })
      );
    }
  };
  const datepickerRefStart = useRef();
  const handleClickDatepickerIcon1 = () => {
    const datepickerElement = datepickerRefStart.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  const datepickerRefEnd = useRef();
  const handleClickDatepickerIcon2 = () => {
    const datepickerElement = datepickerRefEnd.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  function findSearch() {}
  if (editDetails) {
    return (
      <AddNewVendor
        backPress={() => {
          setEditDetails(false);
          setShowVendor(false);
        }}
        oldDetails={vendorData}
        path={path}
      />
    );
  } else {
    return (
      <div className="vendorBg containerShadow">

        {trxnCount == undefined ? (
          <NoTransaction />
        ) : trxnCount == 0 ? (
          <NoTransaction />
        ) : (
          <div className="table-for-Vendor mt-0">
            <Table className="vehicleAssignedHistory">
              <thead className="th" style={{ zIndex: 99 }}>
                <tr className="table-header-vendor-details">
                  <th className="vendor-transaction-header col-2">
                    Date & Time
                  </th>
                  <th className="vendor-transaction-header col-2">
                    Beneficiary Name
                  </th>
                  <th className="vendor-transaction-header col-2">UTR No.</th>

                  <th className="vendor-transaction-header col-1.5">
                    Payment Mode
                  </th>
                  <th className="vendor-transaction-header">Amount</th>
                  <th className="vendor-transaction-header">Status</th>

                  <th className="col-2 vendor-transaction-header">
                    Upload / View Invoice
                  </th>
                </tr>
              </thead>
              {/* <div className="tbodyVehicle-div"> */}
              <tbody className="tbodyVendor" style={{ textDecoration: "none" }}>
                {vendorTransactionList?.map((items, i) => {
                  console.log("-----------", i);
                  return (
                    <>
                      {i < 5 && i >= 0 ? (
                        <tr className="table-row-vendor-details table-row-transaction-history">
                          <td
                            className="col-2 "
                            style={{
                              verticalAlign: "middle",
                              borderRightWidth: 1,
                            }}
                          >
                            {moment(items?.createdAt).format(
                              "DD/MM/YYYY, hh:mm a"
                            )}
                          </td>
                          <td
                            className="col-2 "
                            style={{
                              verticalAlign: "middle",
                              borderRightWidth: 1,
                            }}
                          >
                            {items?.beneficiaryName}
                          </td>
                          <td
                            className="col-2"
                            style={{
                              verticalAlign: "middle",
                              borderRightWidth: 1,
                            }}
                          >
                            {items?.bank_ref_no}
                          </td>

                          <td
                            className="col-1.5 "
                            style={{
                              verticalAlign: "middle",
                              borderRightWidth: 1,
                              wordBreak: "break-all",
                            }}
                          >
                            {items?.payment_mode}
                          </td>
                          <td
                            style={{
                              verticalAlign: "middle",
                              borderRightWidth: 1,
                            }}
                          >
                            ₹{items?.amount}
                          </td>
                          <td
                            className=""
                            style={{
                              verticalAlign: "middle",
                              borderRightWidth: 1,
                              color:
                                items?.status == "Success"
                                  ? "#09BD7B"
                                  : "#E63946",
                            }}
                          >
                            {items?.status}
                          </td>

                          <td
                            className="col-2 "
                            style={{
                              verticalAlign: "middle",
                              borderRightWidth: 1,
                            }}
                          >
                            <div className="row">
                              <div className="col-1 upload-Icon" type="button">
                                <SvgImage name="uploadIconV2" />
                                <div style={{ position: "absolute" }}>
                                  <input
                                    type="file"
                                    // accept="image/*"
                                    className="add-attachment-btn-4"
                                    onChange={async (e) => {
                                      const img = e?.target.files[0];
                                      // setFileUp( e?.target.files[0]);
                                      console.log("image", img?.name);
                                      if (img !== null) {
                                        //notify2();
                                        dispatch(
                                          vendorUploadBillAction(
                                            {
                                              transaction_id:
                                                items?.transaction_id,
                                              img: img,
                                            },
                                            (res) => {
                                              if (res?.type == "success") {
                                                setSuccessModal(true);
                                                setSuccessText(
                                                  `Image Uploaded Successfully`
                                                );
                                                wait(1400).then(() => {
                                                  dispatch(
                                                    cleanVendorTransaction()
                                                  );
                                                  dispatch(
                                                    vendorTransactionAction({
                                                      limit: 5,
                                                      offset: offset,
                                                      from: "",
                                                      to: "",
                                                      vendorId: vendorData?.id,
                                                    })
                                                  );
                                                });
                                              } else {
                                                setFailedMsg(
                                                  res?.data?.message
                                                );
                                                setFailedModal(true);
                                              }
                                            }
                                          )
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              {items?.doc_url != null && (
                                <div
                                  className="col-1"
                                  onClick={() => {
                                    dispatch(
                                      vendorGetSignedUrlAction(
                                        { key: items?.doc_url },
                                        (res) => {
                                          console.log(
                                            "vendorGetSignedUrlAction",
                                            res
                                          );
                                          if (res?.status == 200) {
                                            window.open(
                                              res?.data?.data,
                                              "_blank",
                                              "noopener,noreferrer"
                                            );
                                          }
                                        }
                                      )
                                    );
                                  }}
                                >
                                  <SvgImage name="ViewInvoice" />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </tbody>
              {/* </div> */}
            </Table>
            {/* {
          olderHistoryStatus?
          <div className="olderTransactions-div" onClick={handleOlderTransactions}>
          Older History
          </div>:
        <></>
        } */}
            {trxnCount > 0 && (
              <div className="row margin0 padding16">
                <div
                  className="col-4"
                  style={{
                    textAlign: "right",
                    paddingTop: "0.1rem",
                    cursor: offset > 0 ? "pointer" : "no-drop",
                    opacity: offset > 0 ? "1" : "0.5",
                  }}
                  onClick={handlePrev}
                >
                  <SvgImage name="PrevArrow" />
                </div>
                <div
                  className="col-1 greenFont semiBold heading4 padding0L"
                  style={{
                    textAlign: "right",
                    paddingTop: "0.3rem",
                    cursor: offset > 0 ? "pointer" : "no-drop",
                    opacity: offset > 0 ? "1" : "0.5",
                  }}
                  onClick={handlePrev}
                >
                  Previous
                </div>
                <div className="col-2 row margin0 justify-content-center">
                  <div
                    className="greenBoxCount Medium heading4 col-4"
                    style={{
                      width: "2rem",
                      height: "2rem",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {offset / 5 + 1}
                  </div>
                  <div
                    className=" col-4 padding0R"
                    style={{ paddingTop: "0.3rem", textAlign: "right" }}
                  >
                    of{" "}
                    {trxnCount % 5 == 0
                      ? Math.floor(trxnCount / 5)
                      : Math.floor(trxnCount / 5 + 1)}
                  </div>
                </div>
                <div
                  className="col-1 greenFont semiBold heading4 padding0R"
                  style={{
                    textAlign: "left",
                    paddingTop: "0.3rem",
                    cursor: trxnCount - offset > 5 ? "pointer" : "no-drop",
                    opacity: trxnCount - offset > 5 ? "1" : "0.5",
                  }}
                  onClick={handleNext}
                >
                  Next
                </div>
                <div
                  className="col-4 padding0L"
                  style={{
                    textAlign: "left",
                    marginLeft: "-1rem",
                    paddingTop: "0.1rem",
                    cursor: trxnCount - offset > 5 ? "pointer" : "no-drop",
                    opacity: trxnCount - offset > 5 ? "1" : "0.5",
                  }}
                  onClick={handleNext}
                >
                  <SvgImage name="NextArrow" />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default VendorTransactions;
