import {
  GET_DRIVER_BAL,
  GET_DRIVER_BAL_SUCCESS,
  GET_DRIVERS_LIST,
  GET_DRIVERS_LIST_SUCCESS,
  EXPENSE_SUMM,
  EXPENSE_SUMM_SUCCESS,
  CLEAN_EXPENSE_SUMM,
  ADD_MONEY_TO_WALLET,
  ADD_MONEY_TO_WALLET_SUCCESS,
  WITHDRAW_MONEY_FROM_WALLET,
  WITHDRAW_MONEY_FROM_WALLET_SUCCESS,
  GET_CARD_DETAILS,
  GET_CARD_DETAILS_SUCCESS,
  GET_EXPENSE_WALLET_SUMMARY,
  GET_EXPENSE_WALLET_SUMMARY_SUCCESS,
  CLEAN_EXPENSE_WALLET_SUMMARY,
  ADD_EXPENSE_TO_WALLET,
  ADD_EXPENSE_TO_WALLET_SUCCESS,
  GET_CARD_CONTROL,
  GET_CARD_CONTROL_SUCCESS,
  EDIT_CARD_CONTROL,
  EDIT_CARD_CONTROL_SUCCESS,
  CLEAR_ALL_DRIVERS,
  LINK_FLEET,
  LINK_FLEET_SUCCESS,
  CHANGE_CARD_STATUS,
  CHANGE_CARD_STATUS_SUCCESS,
  CLEAN_FLEET,
  GET_FLEET_BY_FLEET_ID,
  GET_FLEET_BY_FLEET_ID_SUCCESS,
  GET_DRIVER_LIST_2,
  GET_DRIVER_LIST_2_SUCCESS,
  GENERATE_DRIVER_WALLET_STATEMENT,
  GENERATE_DRIVER_WALLET_STATEMENT_SUCCESS,
  NEW_EDIT_CARD_CONTROL,
  NEW_EDIT_CARD_CONTROL_SUCCESS,
  NEW_GET_CARD_CONTROL,
  NEW_GET_CARD_CONTROL_SUCCESS,
  GET_DRIVERS_LIST_HOME,
  GET_DRIVERS_LIST_HOME_SUCCESS,
  SEARCH_BY_VRN,
  SEARCH_BY_VRN_SUCCESS,
  CLEAR_ALL_DRIVERS_BY_VRN,
  DOWNLOAD_SALARY_SUCCESS,
  DOWNLOAD_SALARY,
  UPLOAD_SALARY,
  UPLOAD_SALARY_SUCCESS,
  ADD_NICKNAME_SUCCESS,
  ADD_NICKNAME,
  DOWNLOAD_SALARY_CREDIT_SHEET,
  DOWNLOAD_SALARY_CREDIT_SHEET_SUCCESS,
  UPLOAD_SALARY_CREDIT_SHEET,
  UPLOAD_SALARY_CREDIT_SHEET_SUCCESS,
  GET_SALARY_WALLET_DETAILS,
  GET_SALARY_WALLET_DETAILS_SUCCESS,
  EDIT_SALARY_WALLET_DETAILS,
  EDIT_SALARY_WALLET_DETAILS_SUCCESS,
  CREATE_SALARY_WALLET,
  CREATE_SALARY_WALLET_SUCCESS,
  GET_FLEET_LIST_TRACKER,
  GET_FLEET_LIST_TRACKER_SUCCESS,
  GENERATE_FRESH_DESK_TICKET_V2,
  GENERATE_FRESH_DESK_TICKET_V2_SUCCESS,
  PAY_DRIVER_SALARY,
  PAY_DRIVER_SALARY_SUCCESS,
  EDIT_SALARY_WALLET,
  EDIT_SALARY_WALLET_SUCCESS,
  GENERATE_SALARY_WALLET_STATEMENT,
  GENERATE_SALARY_WALLET_STATEMENT_SUCCESS,
  CLEAN_SALARY_WALLET_DETAILS,
  GET_DRIVERS_LIST_ADVANCE,
  GET_DRIVERS_LIST_ADVANCE_SUCCESS,
  CLEAR_ALL_DRIVERS_ADVANCE,
  GET_ALL_SALARY_WALLET,
  GET_ALL_SALARY_WALLET_SUCCESS,
  CLEAR_ALL_SALARY_WALLET,
  GET_SALARY_WALLET_SUMMARY,
GET_SALARY_WALLET_SUMMARY_SUCCESS,
CLEAN_SALARY_WALLET_SUMMARY
} from "../storeTypes";

export const addNicknameAction = (payload, callback) => {
  return {
    type: ADD_NICKNAME,
    payload: payload,
    callback,
  };
};
export const addNicknameActionSuccess = (data) => {
  return {
    type: ADD_NICKNAME_SUCCESS,
    payload: data,
  };
};

export const downloadSalaryAction = (payload, callback) => {
  return {
    type: DOWNLOAD_SALARY,
    payload: payload,
    callback,
  };
};
export const downloadSalaryActionSuccess = (data) => {
  return {
    type: DOWNLOAD_SALARY_SUCCESS,
    payload: data,
  };
};

export const uploadSalaryAction = (payload, callback) => {
  return {
    type: UPLOAD_SALARY,
    payload: payload,
    callback,
  };
};
export const uploadSalaryActionSuccess = (data) => {
  return {
    type: UPLOAD_SALARY_SUCCESS,
    payload: data,
  };
};

export const searchByVrnAction = (payload) => {
  return {
    type: SEARCH_BY_VRN,
    payload: payload,
  };
};
export const searchByVrnActionSuccess = (data) => {
  return {
    type: SEARCH_BY_VRN_SUCCESS,
    payload: data,
  };
};

export function clearAllDriversByVrn() {
  return {
    type: CLEAR_ALL_DRIVERS_BY_VRN,
  };
}

export const getDriverList = (payload) => {
  return {
    type: GET_DRIVERS_LIST,
    payload: payload,
  };
};
export const getDriverListSuccess = (data) => {
  return {
    type: GET_DRIVERS_LIST_SUCCESS,
    payload: data,
  };
};

export const getDriverListHome = (payload) => {
  return {
    type: GET_DRIVERS_LIST_HOME,
    payload: payload,
  };
};
export const getDriverListHomeSuccess = (data) => {
  return {
    type: GET_DRIVERS_LIST_HOME_SUCCESS,
    payload: data,
  };
};

export function clearAllDrivers() {
  return {
    type: CLEAR_ALL_DRIVERS,
  };
}

// driver balance actions
export const getDriverBal = (ID) => {
  return {
    type: GET_DRIVER_BAL,
    payload: ID,
  };
};
export const getDriverBalSuccess = (data) => {
  return {
    type: GET_DRIVER_BAL_SUCCESS,
    payload: data,
  };
};

// manage expense summary

export const expenseSumm = (ID, obj, callback) => {
  console.log("id hererrrr", obj);
  return {
    type: EXPENSE_SUMM,
    payload: ID,
    obj: obj,
    callback,
  };
};
export const expenseSummSuccess = (data) => {
  return {
    type: EXPENSE_SUMM_SUCCESS,
    payload: data,
  };
};

export const cleanExpenseSumm = () => {
  return {
    type: CLEAN_EXPENSE_SUMM,
  };
};

//fleet details

// Add money to wallet
export const addMoney = (obj, ID, callback) => {
  // console.log("yeo", obj, ID);
  return {
    type: ADD_MONEY_TO_WALLET,
    payload: obj,
    ID,
    callback,
  };
};
export const addMoneySuccess = () => {
  // console.log("bhai ye kya ha", obj);
  return {
    type: ADD_MONEY_TO_WALLET_SUCCESS,
  };
};

// Withdraw money from wallet
export const withdrawMoneyFromWallet = (obj, ID, callback) => {
  // console.log("yeo", obj, ID);
  return {
    type: WITHDRAW_MONEY_FROM_WALLET,
    payload: obj,
    ID,
    callback,
  };
};
export const withdrawMoneyFromWalletSuccess = () => {
  // console.log("bhai ye kya ha", obj);
  return {
    type: WITHDRAW_MONEY_FROM_WALLET_SUCCESS,
  };
};

// get card details

export const getCardDetails = (ID) => {
  return {
    type: GET_CARD_DETAILS,
    payload: ID,
  };
};
export const getCardDetailsSuccess = (data) => {
  return {
    type: GET_CARD_DETAILS_SUCCESS,
    payload: data,
  };
};

// get expense wallet summary

export const getExpenseWalletSummary = (userId, data, callback) => {
  console.log("data passed------>>>>>>>>", userId);
  return {
    type: GET_EXPENSE_WALLET_SUMMARY,
    userId: userId,
    payload: data,
    callback,
  };
};
export const getExpenseWalletSummarySuccess = (data) => {
  return {
    type: GET_EXPENSE_WALLET_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const cleanExpenseWalletSummary = () => {
  return {
    type: CLEAN_EXPENSE_WALLET_SUMMARY,
  };
};

// Add expense to wallet
export const addExpenseToWallet = (obj, ID, callback) => {
  console.log("yeo", ID, obj);
  return {
    type: ADD_EXPENSE_TO_WALLET,
    payload: obj,
    ID,
    callback,
  };
};
export const addExpenseToWalletSuccess = () => {
  // console.log("bhai ye kya ha", obj);
  return {
    type: ADD_EXPENSE_TO_WALLET_SUCCESS,
  };
};

// get card controls

export const getCardControl = (ID) => {
  return {
    type: GET_CARD_CONTROL,
    payload: ID,
  };
};
export const getCardControlSuccess = (data) => {
  return {
    type: GET_CARD_CONTROL_SUCCESS,
    payload: data,
  };
};

// edit card controls

export const editCardControl = (obj, callback) => {
  console.log("yeo", obj);
  return {
    type: EDIT_CARD_CONTROL,
    payload: obj,

    callback,
  };
};
export const editCardControlSuccess = () => {
  // console.log("bhai ye kya ha", obj);
  return {
    type: EDIT_CARD_CONTROL_SUCCESS,
  };
};

// link fleet

export const linkFLeet = (obj, callback) => {
  console.log("link fleet obj recieved", obj);
  return {
    type: LINK_FLEET,
    payload: obj,
    callback,
  };
};
export const linkFleetSuccess = () => {
  // console.log("bhai ye kya ha", obj);
  return {
    type: LINK_FLEET_SUCCESS,
  };
};

// change card status
export function changeCardStatus(id, callBack) {
  return {
    type: CHANGE_CARD_STATUS,
    payload: id,
    callBack,
  };
}
export function changeCardStatusSuccess(data) {
  return { type: CHANGE_CARD_STATUS_SUCCESS, payload: data };
}

// clean fleet
export const cleanFleet = () => {
  return {
    type: CLEAN_FLEET,
  };
};

export const getFleetFleetID = (obj) => {
  console.log("obj is here yo", obj);
  return {
    payload: obj,
    type: GET_FLEET_BY_FLEET_ID,
  };
};

export const getFleetByFleetIDSuccess = (data) => {
  console.log("data success", data);
  return {
    type: GET_FLEET_BY_FLEET_ID_SUCCESS,
    payload: data,
  };
};

export const getDriverList2 = (payload) => {
  return {
    type: GET_DRIVER_LIST_2,
    payload: payload,
  };
};
export const getDriverList2Success = (data) => {
  return {
    type: GET_DRIVER_LIST_2_SUCCESS,
    payload: data,
  };
};
export const getDriverListStatement = (userId, data, callback) => {
  console.log("jishs", userId, data);
  return {
    type: GENERATE_DRIVER_WALLET_STATEMENT,
    userId: userId,
    payload: data,
    callback,
  };
};
export const getdriverListStatementSuccess = (data) => {
  return {
    type: GENERATE_DRIVER_WALLET_STATEMENT_SUCCESS,
    payload: data,
  };
};

// new EDIT card controls

export const newEditCardControlAction = (userId, obj, callback) => {
  console.log("yeo", obj);
  return {
    type: NEW_EDIT_CARD_CONTROL,
    userId: userId,
    payload: obj,
    callback,
  };
};
export const newEditCardControlActionSuccess = () => {
  // console.log("bhai ye kya ha", obj);
  return {
    type: NEW_EDIT_CARD_CONTROL_SUCCESS,
  };
};

// NEW get card controls

export const newGetCardControlAction = (ID, obj) => {
  return {
    type: NEW_GET_CARD_CONTROL,
    payload: ID,
  };
};
export const newGetCardControlActionSuccess = (data) => {
  return {
    type: NEW_GET_CARD_CONTROL_SUCCESS,
    payload: data,
  };
};

export const downloadSalaryCreditSheet = (payload, callback) => {
  return {
    type: DOWNLOAD_SALARY_CREDIT_SHEET,
    payload: payload,
    callback,
  };
};
export const downloadSalaryCreditSheetSuccess = (data) => {
  return {
    type: DOWNLOAD_SALARY_CREDIT_SHEET_SUCCESS,
    payload: data,
  };
};

export const uploadSalaryCreditSheet = (payload, callback) => {
  return {
    type: UPLOAD_SALARY_CREDIT_SHEET,
    payload: payload,
    callback,
  };
};
export const uploadSalaryCreditSheetSuccess = (data) => {
  return {
    type: UPLOAD_SALARY_CREDIT_SHEET_SUCCESS,
    payload: data,
  };
};

export const getSalaryWalletDetails = (payload, callback) => {
  return {
    type: GET_SALARY_WALLET_DETAILS,
    payload: payload,
    callback,
  };
};
export const getSalaryWalletDetailsSuccess = (data) => {
  return {
    type: GET_SALARY_WALLET_DETAILS_SUCCESS,
    payload: data,
  };
};
export const cleanSalaryWalletDetails = () => {
  return {
    type: CLEAN_SALARY_WALLET_DETAILS,
  };
};

export const editSalaryWalletDetails = (payload, callback) => {
  return {
    type: EDIT_SALARY_WALLET_DETAILS,
    payload: payload,
    callback,
  };
};
export const editSalaryWalletDetailsSuccess = (data) => {
  return {
    type: EDIT_SALARY_WALLET_DETAILS_SUCCESS,
    payload: data,
  };
};

export const createSalaryWallet = (payload, callback) => {
  return {
    type: CREATE_SALARY_WALLET,
    payload: payload,
    callback,
  };
};
export const createSalaryWalletSuccesss = (data) => {
  return {
    type: CREATE_SALARY_WALLET_SUCCESS,
    payload: data,
  };
};

export const getFleetTracker = (payload) => {
  console.log("*****1228****", payload);
  return {
    type: GET_FLEET_LIST_TRACKER,
    payload: payload,
  };
};
export const getFleetTrackerSuccess = (data) => {
  // console.log("fleet response from actions", data);

  return {
    type: GET_FLEET_LIST_TRACKER_SUCCESS,
    payload: data,
  };
};
export function generateFreshdeskTicketV2(payload, callBack) {
  return {
    type: GENERATE_FRESH_DESK_TICKET_V2,
    payload: payload,
    callBack,
  };
}
export function generateFreshdeskTicketV2Success(payload) {
  return {
    type: GENERATE_FRESH_DESK_TICKET_V2_SUCCESS,
    payload: payload,
  };
}

export function payDriverSalaryAction(obj, callBack) {
  console.log("action ", obj);
  return {
    type: PAY_DRIVER_SALARY,
    payload: obj,
    callBack,
  };
}
export function payDriverSalarySuccess(data) {
  return { type: PAY_DRIVER_SALARY_SUCCESS, payload: data };
}

export function editSalaryWalletAction(obj, callBack) {
  return {
    type: EDIT_SALARY_WALLET,
    payload: obj,
    callBack,
  };
}
export function editSalaryWalletSuccess(data) {
  return { type: EDIT_SALARY_WALLET_SUCCESS, payload: data };
}

export function getSalaryStatement(data, callback) {
  console.log("api", data);
  return {
    type: GENERATE_SALARY_WALLET_STATEMENT,
    payload: data,
    callback,
  };
}
export function getSalaryWalletStatementSuccess(data) {
  return {
    type: GENERATE_SALARY_WALLET_STATEMENT_SUCCESS,
    payload: data,
  };
}

export function getAdvanceDriverList(payload) {
  return {
    type: GET_DRIVERS_LIST_ADVANCE,
    payload,
  };
}
export function getAdvanceDriverListSuccess(payload) {
  return {
    type: GET_DRIVERS_LIST_ADVANCE_SUCCESS,
    payload,
  };
}
export function cleanAdvanceDriverList() {
  return {
    type: CLEAR_ALL_DRIVERS_ADVANCE,
  };
}
export const getSalaryWalletList = (payload) => {
  return {
    type: GET_ALL_SALARY_WALLET,
    payload: payload,
  };
};
export const getSalaryWalletListSuccess = (data) => {
  return {
    type: GET_ALL_SALARY_WALLET_SUCCESS,
    payload: data,
  };
};

export function clearAllSalaryWalletList() {
  return {
    type: CLEAR_ALL_SALARY_WALLET,
  };
}

export const getSalaryWalletSummary = (phone, data, callback) => {
  return {
    type: GET_SALARY_WALLET_SUMMARY,
    phone: phone,
    payload: data,
    callback,
  };
};
export const getSalaryWalletSummarySuccess = (data) => {
  return {
    type: GET_SALARY_WALLET_SUMMARY_SUCCESS,
    payload: data,
  };
};

export function clearSalaryWalletSummary() {
  return {
    type: CLEAN_SALARY_WALLET_SUMMARY,
  };
}
