import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useDispatch } from "react-redux";
import EnterNumbers from "./Components/EnterNumbers";
import EnterOtps from "./Components/EnterOtps";
import { cleanEcomLoader } from "../../../../services/Store/Actions/ecomAction";
import LoginHeaderSmall from "./Components/LoginHeaderSmall";
import "./index.css"
import SvgImage from "../../../Icons/Icons";


const LoginModal=(props)=>{

  // const [cart, setCart] = useState();
  // const [loginStatus, setLoginStatus] = useState(false);
  const [phone, setPhone] = useState();
  const [firstName, setFirstName] = useState();
  const [middleName, setMiddleName] = useState();
  const [lastName, setLastName] = useState();
  const dispatch= useDispatch();
  const { handleClose, open, toggleModal, loginStatus, setLoginStatus } =props;

  useEffect(() => {
    dispatch(cleanEcomLoader());
    // let cartStored = localStorage.getItem("_cart");
    // console.log("get cart", cartStored);
    // let cartVal = cartStored != null ? JSON.parse(cartStored) : null;
    // setCart(cartVal);
  }, [])
  // const data=[{
  //   name:"pr1"
  // },{
  //   name:"pr1"
  // },{
  //   name:"pr1"
  // }]

  return (
    <>
    <Modal
      isOpen={open}
      onHide={handleClose}
      className="all-transaction-main-modal"
      toggle={toggleModal}
      aria-labelledby="myModalLabel2"
    >
      <ModalBody className="transactionModalBody"
        style={{ padding: "0" }}
      >
        <div className="PGCollectionBox">

          <div
            className="closeEffect"
            onClick={() => {
              handleClose();
            }}
            style={{zIndex:"1000", top:"10px", right:"20px"}}
          >
            <SvgImage name="CloseButtonLogo" />
          </div>

          <div className=" row" style={{height:"100%"}}>
            <LoginHeaderSmall />
            {
              (!loginStatus) ?
              <EnterNumbers setPhone={setPhone} setLoginStatus={setLoginStatus}
              setFirstName={setFirstName} 
              setMiddleName={setMiddleName}
              setLastName={setLastName}
              /> :
              <EnterOtps phone={phone} setLoginStatus={setLoginStatus}
              firstName={firstName}
              middleName={middleName}
              lastName={lastName}
              toggle={toggleModal}
              />
            }
          </div>
        </div>
      </ModalBody>
    </Modal>
    </>
  );
}


export default LoginModal;