import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addFleet } from "../../../services/Store/Actions/fleetActions";
import { useNavigate, useParams } from "react-router-dom";
// import { fleetDetailsSuccess } from "../../../services/Store/Actions/fleetActions";
import Switch from "react-switch";
import SearchBar from "../../subComponents/searchbar";
import { autoRechargeToggleAction } from "../../../services/Store/Actions/fastagAction";
import { m2pOTPAction } from "../../../services/Store/Actions/m2pAction";

function CardSelectModal(props) {
  const { open, toggleModal, handleClose, vehNo, limit, vehRank, fleetId,openM2p,showingSalesToastModal }=props;
  const validationSchema = Yup.object().shape({
    amount: Yup.number().typeError("Only Numbers are allowed").required("Required"),
    // vehicleCategory: Yup.string().required("Required"),
  });
  //const {loader} = useSelector((state)=>state?.fastag);
  //console.log("fleet added",fleetAdded);
  const dispatch = useDispatch();
 
  let { id } = useParams();
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Select Driver's Card</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody style={{ padding: "0" }}>
            <div className="padding24 padding32L padding32R ">
              <div className="cardContainer padding16 padding32B padding32T">
                <div
                  className="cardLivquik"
                  style={{
                    marginLeft: "2rem",
                    marginRight: "2rem",
                    color: "#FFFFFF",
                    padding: "20px",
                  }}
                >
                  <div className="row ">
                    <div className="col-8">
                      <SvgImage name="logipeLogo" />
                    </div>
                    <div className="col-4 padding16L">
                      <SvgImage name="livquikLogo" />
                    </div>
                  </div>
                  <div
                    className="Bold heading2"
                    style={{ paddingTop: "2.8rem" }}
                  >
                    4873 4983 4837 1234
                  </div>
                  <div className="row" style={{ paddingTop: "2.8rem" }}>
                    <div
                      className="col-6 semiBold text3"
                      style={{ paddingTop: "5px" }}
                    >
                      MAYANK AGARWAL
                    </div>
                    <div className="col-6 padding16L row">
                      <div
                        className="col-6 Bold  padding0R"
                        style={{ fontSize: "10px", paddingLeft: "50px" }}
                      >
                        Exp. Date
                      </div>
                      <div
                        className="col-6 Bold "
                        style={{ fontSize: "12px", paddingTop: "5px" }}
                      >
                        07/25
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="padding16 semiBold text2"
                  style={{ textAlign: "center" }}
                >
                  Keep the following driver documents ready
                </div>
                <div
                  className="row"
                  style={{ padding: "0 3rem", marginTop: "-1.4rem" }}
                >
                  <div className="col-4">
                    <div>
                      <SvgImage name="aadhaarIcon" />
                    </div>
                    <div
                      className="textFont"
                      style={{
                        padding: "0 1.2rem",
                        marginTop: "-1.5rem",
                        textAlign: "center",
                      }}
                    >
                      Aadhar Card Number
                    </div>
                  </div>
                  <div className="col-4">
                    <div>
                      <SvgImage name="aadhaarPhoneIcon" />
                    </div>
                    <div
                      className="textFont"
                      style={{
                        padding: "0 0.7rem",
                        marginTop: "-1.5rem",
                        textAlign: "center",
                      }}
                    >
                      Aadhaar Linked Phone for OTP
                    </div>
                  </div>
                  <div className="col-4">
                    <div>
                      <SvgImage name="panIcon" />
                    </div>
                    <div
                      className="textFont"
                      style={{
                        padding: "0 1.2rem",
                        marginTop: "-1.5rem",
                        textAlign: "center",
                      }}
                    >
                      PAN Card Photo
                    </div>
                  </div>
                </div>
                <div
                  className="row "
                  style={{ padding: "1.5rem 3rem", color: "#616161" }}
                >
                  <div
                    className="col-4 semiBold padding8 padding16L"
                    style={{ fontSize: "12px", lineHeight: "16px" }}
                  >
                    One Time Cost :
                  </div>
                  <div
                    className="col-4 heading1 Regular padding32L"
                    style={{ textDecoration: "line-through" }}
                  >
                    {" "}
                    ₹799
                  </div>
                  <div
                    className="col-4 padding8L"
                    style={{ textAlign: "center" }}
                  >
                    <div className="Bold heading2" style={{ color: "#2E7C7B" }}>
                      ₹299
                    </div>
                    <div style={{ fontSize: "10px" }}>Limited Period Offer</div>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="butt-4"
                    style={{
                      background: "#2e7c7b",
                      left:"30px",
                      width:"370px"
                      //background: loader ? "grey" : "#2e7c7b",
                    }}
                    //disabled={loader ? true : false}
                    onClick={()=>{
                      toggleModal();
                      openM2p();
                      dispatch(m2pOTPAction({
                        userId:id,
                      }))
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
              <div className="row padding24" style={{ color: "#A7A7A7" }}>
                <div className="col-5 line1"></div>
                <div
                  className="col-2 semiBold orText" >
                  OR
                </div>
                <div className="col-5 line2"></div>
              </div>
              <div className="cardContainer padding16 padding32B padding32T">
                <div
                  className="cardLivquik"
                  style={{
                    marginLeft: "2rem",
                    marginRight: "2rem",
                    color: "#FFFFFF",
                    padding: "20px",
                  }}
                >
                  <div className="row ">
                    <div className="col-8">
                      <SvgImage name="logipeLogo" />
                    </div>
                    <div className="col-4 padding16L">
                      <SvgImage name="yesBankTag" />
                    </div>
                  </div>
                  <div
                    className="Bold heading2"
                    style={{ paddingTop: "2.8rem" }}
                  >
                    4873 4983 4837 1234
                  </div>
                  <div className="row" style={{ paddingTop: "2.8rem" }}>
                    <div
                      className="col-6 semiBold text3"
                      style={{ paddingTop: "5px" }}
                    >
                      MAYANK AGARWAL
                    </div>
                    <div className="col-6 padding16L row">
                      <div
                        className="col-6 Bold  padding0R"
                        style={{ fontSize: "10px", paddingLeft: "50px" }}
                      >
                        Exp. Date
                      </div>
                      <div
                        className="col-6 Bold "
                        style={{ fontSize: "12px", paddingTop: "5px" }}
                      >
                        07/25
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="padding16 semiBold text2 padding8B"
                  style={{ textAlign: "center" }}
                >
                  Yes Bank Cards involve a biometric KYC
                </div>
                <div className="Medium padding24L padding16R text3" style={{color:"#6C6C6C", textAlign:"center"}}>our sales agent will contact you for the card's issuance.</div>
                <div
                  className="row "
                  style={{ padding: "1.5rem 3rem 0.5rem", color: "#616161" }}
                >
                  <div
                    className="col-4 semiBold padding8 padding24L"
                    style={{ fontSize: "12px", lineHeight: "16px" }}
                  >
                    One Time Cost :
                  </div>
                  <div
                    className="col-4 heading1 Regular padding32L"
                    style={{ textDecoration: "line-through" }}
                  >
                    {" "}
                    ₹799
                  </div>
                  <div
                    className="col-4 padding8L"
                    style={{ textAlign: "center" }}
                  >
                    <div className="Bold heading2" style={{ color: "#2E7C7B" }}>
                      ₹299
                    </div>
                    <div style={{ fontSize: "10px" }}>Limited Period Offer</div>
                  </div>
                </div>
                <div className="Regular text3 padding24B padding0T" style={{color:"#6C6C6C", textAlign:"center", fontSize:"13px" }}>
                  Waived off with card transactions worth ₹30,000 on diesel</div>
                <div>
                  <button
                    type="submit"
                    className="butt-4"
                    style={{
                      background: "#2e7c7b",
                      left:"30px", 
                      width:"370px"
                      //background: loader ? "grey" : "#2e7c7b",
                    }}
                    onClick={()=>{
                      toggleModal();
                      showingSalesToastModal();
                    }}
                    //disabled={loader ? true : false}
                  >
                    Contact Sales Agent
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default CardSelectModal;
