import React, { useEffect, useState } from "react";
import SvgImage from "../../../../Components/Icons/Icons";
import "./index.css";
import {
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { sendAnalitics } from "../../../../services/Store/Actions/appActions";
import { cleanOnboardingLoaderAction, ownerDetailsAction, refreshTokenAction } from "../../../../services/Store/Actions/onboardingAction";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";


const OwnerDetails=(props)=>{
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is mandatory").matches(/^[a-zA-Z]+$/, "Enter correct first name"),
        lastName: Yup.string()
        .required("Last name is mandatory").matches(/^[a-zA-Z]+$/, "Enter correct last name"),
        middleName:Yup.string().matches(/^[a-zA-Z]+$/, "Enter correct middle name"),
      });

    const { loader } = useSelector((state) => state?.onboarding);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(sendAnalitics("OwnerDetailsOnboarding_web"));
        dispatch(cleanOnboardingLoaderAction());
    },[])

    let refreshToken= sessionStorage.getItem("_refreshToken");
    let tokenOld=JSON.parse(refreshToken)
    console.log("tttttoken", typeof tokenOld, tokenOld);
    return (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            firstName: "",
            lastName:"", 
            middleName:""
          }}
          onSubmit={(values, actions) => {
            //console.log("date",moment(values.bookingDate).format('DD-MM-YYYY'))
            console.log("valuessss", values);
            //dispatch(sendAnalitics("GetOTP_web", values));
            dispatch(
              ownerDetailsAction(
                {
                  "firstName": values?.firstName.trim(),
                  "lastName": values?.lastName.trim(),
                  "middleName": values?.middleName.trim()
                },
                (res) => {
                  //actions.setSubmitting(false);
                  console.log("SUCCESS", res);
                  if (res?.data?.type === "success") {
                    console.log("SUCCESS");
                    let refreshToken= sessionStorage.getItem("_refreshToken");
                    let tokenOld=JSON.parse(refreshToken)
                    dispatch(refreshTokenAction({
                      "refreshToken":tokenOld
                    },(res)=>{
                      console.log("new token", res);
                      storeDataToAsyncStorage("_token", res.data?.data.token);
                      storeDataToAsyncStorage("_userInfo", res.data?.data);
                      // storeDataToAsyncStorage("_userInfo", res.data?.data);
                      storeDataToAsyncStorage(
                        "_refreshToken",
                        res.data?.data.refreshToken
                      );
                      props.setRegStatus(2)
                    }))
                  }
                }
              )
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit} className="InputForm1">
              <FormGroup className="formPosition">
                <div className="heading1 semiBold">Owner Details</div>
                <div className="Regular text3 padding24T headText2">
                  First Name
                </div>
                <div className="padding8 padding0L">
                  <div className=" phoneNumberBox1 padding8">
                    {/* <div
                      className="col-2 padding0L boxWithBorder"
                      style={{ textAlign: "center" }}
                    >
                      <SvgImage name="countryCode" />
                    </div> */}
                 
                      <input
                        type="text"
                        id="firstName"
                        className="input-body-4 Regular text3"
                        name="firstName"
                        value={values.firstName}
                        touched={touched.firstName}
                        errors={errors.firstName}
                        onBlur={()=>{
                        //   dispatch(sendAnalitics("EnterPhone_web", values));
                          handleBlur("firstName");
                        }}
                        onChange={handleChange("firstName")}
                        style={{
                          textAlign: "left",
                          marginLeft: "0px",
                          width: "100%",
                        }}
                        placeholder="Enter first name"
                        autofocus
                      ></input>
                    
                  </div>
                </div>
                <div
                  className="text4"
                  style={{
                    // margin: "7px 0 0px 50px",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {errors.firstName && touched.firstName ? (
                    <div>{errors.firstName}</div>
                  ) : null}
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="Regular text3 padding16T headText2">Middle Name</div>
                        <div className="padding8 padding0L" >
                            <div className=" phoneNumberBox1 padding8">
                                {/* <div
                                className="col-2 padding0L boxWithBorder"
                                style={{ textAlign: "center" }}
                                >
                                <SvgImage name="countryCode" />
                                </div> */}
                             
                                <input
                                    type="text"
                                    id="middleName"
                                    className="input-body-4 Regular text3"
                                    name="middleName"
                                    value={values.middleName}
                                    touched={touched.middleName}
                                    errors={errors.middleName}
                                    onBlur={()=>{
                                    // dispatch(sendAnalitics("EnterPhone_web", values));
                                    handleBlur("middleName");
                                    }}
                                    onChange={handleChange("middleName")}
                                    style={{
                                    textAlign: "left",
                                    marginLeft: "0px",
                                    width: "100%",
                                    }}
                                    placeholder="Enter middle name"
                                   
                                    autofocus
                                ></input>
                               
                            </div>
                        </div>
                        <div
                            className="text4"
                            style={{
                                // margin: "7px 0 0px 50px",
                                color: "red",
                                textAlign: "left",
                            }}
                            >
                            {errors.middleName && touched.middleName ? (
                                <div>{errors.middleName}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="Regular text3 padding16T headText2">Last Name</div>
                        <div className="padding8 padding0L" >
                            <div className=" phoneNumberBox1 padding8">
                                {/* <div
                                className="col-2 padding0L boxWithBorder"
                                style={{ textAlign: "center" }}
                                >
                                <SvgImage name="countryCode" />
                                </div> */}
                                
                                <input
                                    type="text"
                                    id="lastName"
                                    className="input-body-4 Regular text3"
                                    name="lastName"
                                    value={values.lastName}
                                    touched={touched.lastName}
                                    errors={errors.lastName}
                                    onBlur={()=>{
                                    // dispatch(sendAnalitics("EnterPhone_web", values));
                                    handleBlur("lastName");
                                    }}
                                    onChange={handleChange("lastName")}
                                    style={{
                                    textAlign: "left",
                                    marginLeft: "0px",
                                    width: "100%",
                                    }}
                                    placeholder="Enter last name"
                                   
                                    autofocus
                                ></input>
                                
                            </div>
                        </div>
                        <div
                            className="text4"
                            style={{
                                // margin: "7px 0 0px 50px",
                                color: "red",
                                textAlign: "left",
                            }}
                            >
                            {errors.lastName && touched.lastName ? (
                                <div>{errors.lastName}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Button
                  type="submit"
                  className="loginButton semiBold text2"
                  style={{
                    marginTop: "1rem",
                    // background:"#2e7c7b",
                    // color:"#ffffff"
                    background:
                      values.firstName==""
                        ? "#EFEFEF"
                      :values.lastName==""
                        ? "#EFEFEF"  
                        : loader
                        ? "#EFEFEF"
                        : "#2e7c7b",
                    color:
                    values.firstName==""
                      ? "#B5B5B5"
                      :values.lastName==""
                      ? "#B5B5B5"  
                        : loader
                        ? "#B5B5B5"
                        : "#ffffff",
                  }}
                  disabled={loader ? true : false}
                >
                  {loader ? "Please Wait" : "Submit"}
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      );  
}

export default OwnerDetails;