import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import { Table } from "reactstrap";
import { IoIosCloseCircleOutline } from "react-icons/io";
import PGCollectionButton from "../../../Subcomponent/PGCollectionButton";
import SearchBar from "../../subComponents/searchbar";
import ManageDriverVehicleListBar from "../../../Subcomponent/ManageDriverVehicleListBar";
import ManageDriverVehicleDetailsCard from "../../../Subcomponent/ManageDriverVehicleDetailsCard";
import {
  cleanFleet,
  getDriverList,
  clearAllDrivers,
} from "../../../services/Store/Actions/driverslistActions";
import { padding } from "@mui/system";
import { Button } from "reactstrap";
import SvgImage from "../../Icons/Icons";
import ReactPaginate from "react-paginate";
import { Modal, ModalBody } from "reactstrap";
import DriverListItem from "./DriverListItem";
const DriverList = (props) => {
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const { loader } = useSelector((state) => state?.driverlist);
  const {handleClose,toggleModal,open} = props
  const dispatch = useDispatch();
  const { allDrivers, allDriverList } = useSelector(
    (state) => state?.driverlist
  );
  const fleetList = useSelector((state) => state?.driverlist?.allFleet?.rows);
  const totalExpenseCount = useSelector(
    (state) => state?.driverlist?.allFleet?.count
  );
  const pageCount =
    totalExpenseCount % 10 === 0
      ? totalExpenseCount / 10
      : Math.floor(totalExpenseCount / 10) + 1;

  const findSearch = () => {
    dispatch(
      getDriverList({
        offset: 0,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };

  useEffect(() => {
    console.log("useEffect 1");
    setOffset(0);
    //console.log ("useEffect 2")
    //console.log("sss", search);
    dispatch(clearAllDrivers());
    //console.log("sss", search);
    dispatch(
      getDriverList({
        offset: 0,
        limit: 10,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, [search]);

  console.log("***************idhr brp999", search);

  
  const handlePageClick = (event) => {
    console.log("Clickess");
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    dispatch(
      getDriverList({
        offset: newOffset,
        limit: 10,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
   
    setOffset(newOffset);
  };

  return (
      <>
      <Modal
          isOpen={open}
          onHide={handleClose}
          className="PGCollection-modal"
          toggle={toggleModal}
          aria-labelledby="myModalLabel2"
        >
          {/* <Button
            type="button"
            className="close-btn"
            onClick={toggleModal}
            aria-label="Close"
          >
            <SvgImage name="CloseButtonLogo" />
          </Button> */}
          <ModalBody style={{paddingTop:"0"}}>
            <div className="PGCollectionBox">
            <div 
                className= /*"PGCollectionclose */ "row close-btn-for-transaction padding16T"
                onClick={toggleModal}>
                  <div className="col-10"></div>
                <div className="col-1"  style={{textAlign:"right" }}            >
                  <SvgImage
                    classname = 'close-btn-text'
                    style={{ width: "10%", height: "10%" }}
                    name="CloseButtonLogo"
                    width="20%"
                    height="20%"
                  />
                </div>
                <div className="col-1 padding0L" >Close</div>
              </div>
             {/* <div
                className="VehicleListclose"
                onClick={props.toggleModal}
                style={{ cursor: "pointer" }}
            > */}
                {/* <div className="VehicleListCloseIcon" 
                >
                <IoIosCloseCircleOutline size={27} 
                onClick={props.toggleModal}
                style={{ cursor: "pointer" }}/>
                </div> */}

                {/* <div className="VehicleListCloseText">Close</div>
            </div> */}
                <div className="row padding32L ">
                <div className="col-1 padding16 padding32L">
                    <img
                    src={
                        require("../../../web-assets/images/noun-truck-2300420 1.svg")
                        .default
                    }
                    width="35"
                    heihgt="35"
                    />
                </div>
                <div className="padding16 col-5 Bold heading1">Full Driver List</div>
                {/* <div className="VehicleListButton">
                <PGCollectionButton name="Add New Vehicle" />
                </div> */}
                </div>
                {/* <div className="VehicleListCloseIcon" 
                >
                <IoIosCloseCircleOutline size={27} 
                onClick={props.toggleModal}
                style={{ cursor: "pointer" }}/>
                </div> */}
                <div className="padding32" style={{ paddingLeft: "3rem", paddingTop:"1rem", paddingBottom:"1rem" }}>
                <SearchBar
                    placeholder="Search for Driver"
                    // style={{ width: "828px" }}
                    width={820}
                    height="46"
                    value={search}
                    onChange={(event) => {
                    dispatch(clearAllDrivers());
                    setSearch(event.target.value);
                    console.log("32645235273", search);
                    }}
                    findSearch={findSearch}
                />
                </div>
                {/* <div className="VehicleListBar">
                <ManageDriverVehicleListBar />
            </div> */}
                {/* <div
                className=""
                style={{ zIndex: 90, paddingLeft: "3rem", paddingRight: "3rem" }}
                >
                <div
                    className="row padding16 padding32L Bold text3"
                    style={{
                    height: "50px",
                    zIndex: 50,
                    background: "rgba(46, 124, 123, 0.1)",
                    }}
                >
                    <div className="col-2 padding0">Vehicle Number</div>
                    <div className="col-3 padding0">FASTag ID</div>
                    <div className="col-3 padding0">Assigned Driver</div>
                    <div className="col-2 padding0">Status</div>
                    <div className="col-1 padding0 ">Action</div>
                </div>
                </div> */}
                <div className="transitionContainerDrivers">
                <div className="tableContaincerDrivers" >
                <Table className="driverTable">
                    <thead>
                    <tr className="table-header-customer-details">
                        <th >Driver Name</th>
                        <th >Phone</th>
                        <th >Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allDriverList?.map((items, i) => {
                      console.log("itemsallDriverList",items);
                        return (
                        <DriverListItem
                        toggleModal={props.toggleModal}
                        fleetID={props?.item?.fleetId || ''}
                        driverId={items.driverId}
                        offset={props.offset}
                        name={
                            items?.User?.fullName
                        }
                        number={items?.User.phone}
                        />
                        );
                    })}
                    </tbody>
                </Table>
                {pageCount > 1 ? (
                <div className="modalPagination">
                    <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                    />
                </div>
                ) : (
                <></>
                )}
                </div>
                </div>
            </div>
          </ModalBody>
        </Modal>
        

      
    </>
  );
};
export default DriverList;
