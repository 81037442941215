import { call, put, takeEvery } from "redux-saga/effects";
import {
  addCardBeneficiaryApi,
  createTripApi,
  driverAddBeneficiaryApi,
  driverTransferToBeneficiaryApi,
  fuelWalletREchargeApi,
  getCityAndStateApi,
  getDriverBeneficiaryApi,
  getFuelWalletBalApi,
  getOmcVehiclesListApi,
  omcChangeNumApi,
  omcDriverDataApi,
  omcGetVehicleStatusApi,
  omcLoadMoneyApi,
  omcRegisterDriverApi,
  omcVehicleBalanceApi,
  omcVehicleTransactionApi,
  omcWithdrawMoneyApi,
  getFuelWalletHistoryApi,
  fuelWalletLimitUpdateApi
} from "../../Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import { toastStatus } from "../Actions/appActions";
import {
  addCardBeneficiaryActionSuccess,
  createTripActionSuccess,
  driverAddBeneficiaryActionSuccess,
  driverTransferToBeneficiaryActionSuccess,
  getCityAndStateActionSuccess,
  getDriverBeneficiaryActionSuccess,
  getOmcVehiclesListActionSuccess,
  omcDriverDataActionSuccess,
  omcGetVehicleStatusActionSuccess,
  omcLoadMoneyActionSuccess,
  omcRegisterDriverActionSuccess,
  omcVehicleBalanceActionSuccess,
  omcVehicleTransactionActionSuccess,
  omcWithdrawMoneyActionSuccess,
  omcChangeNumActionSuccess,
  fuelwalletRechargeActionSuccess,
  getFuelWalletbalSuccess,
  fuelWalletLimitUpdateSuccess,
  getFuelWalletHistorySuccess
} from "../Actions/omcActions";
import {
  ADD_DRIVER_CARD_BENEFICIARY,
  DRIVER_ADD_BENEFICIARY,
  DRIVER_BANK_TRANSFER,
  FUEL_WALLET_RECHARGE,
  FUEL_WALLET_RECHARGE_SUCCESS,
  GET_CITY_STATE,
  GET_DRIVER_BENEFICIARY,
  GET_FUEL_WALLET_BAL,
  OMC_BALANCE,
  OMC_DRIVER_DATA,
  OMC_LOAD_MONEY,
  OMC_NUMBER_CHANGE,
  OMC_REGISTER_DRIVER,
  OMC_TRANSACTIONS,
  OMC_VEHICLE_LIST,
  OMC_VEH_STATUS,
  OMC_WITHDRAW_MONEY,
  GET_FUEL_WALLET_HISTORY,
  FUEL_WALLET_LIMIT_UPDATE
} from "../storeTypes";

function* omcVehicleBalanceSaga(action) {
  try {
    const response = yield call(omcVehicleBalanceApi, action?.userId);
    console.log("data", response);
    if (response.data.type == "success") {
      // action.callback(response);
      yield put(omcVehicleBalanceActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* omcLoadMoneySaga(action) {
  try {
    const response = yield call(omcLoadMoneyApi, action?.payload);
    console.log("data", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Successfully`,
          status: true,
        })
      );
      yield put(omcLoadMoneyActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("data checkkkkk", error);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* omcWithdrawMoneySaga(action) {
  try {
    const response = yield call(omcWithdrawMoneyApi, action?.payload);
    console.log("data", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(omcWithdrawMoneyActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
  }
}

function* omcVehicleTransactionSaga(action) {
  try {
    const response = yield call(
      omcVehicleTransactionApi,
      action?.userId,
      action?.payload
    );
    console.log("dataomcVehicleTransactionSaga", response);
    if (response.data.type == "success") {
      // if(action.callback){
      //   action.callback(response);
      // }
      //
      yield put(omcVehicleTransactionActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getOmcVehiclesListSaga(action) {
  try {
    const response = yield call(getOmcVehiclesListApi);
    console.log("data", response);
    if (response.data.type == "success") {
      //action.callback(response);
      yield put(getOmcVehiclesListActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* omcGetVehicleStatusSaga(action) {
  try {
    const response = yield call(omcGetVehicleStatusApi, action?.userId);
    console.log("data", response);
    if (response.data.type == "success") {
      // action.callback(response);
      yield put(omcGetVehicleStatusActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* omcDriverDataSaga(action) {
  try {
    console.log("api - step getting info-- 2");
    const response = yield call(omcDriverDataApi, action?.driverId);
    console.log("data- omc", response);
    if (response.data.type == "success") {
      // action.callback(response);
      yield put(omcDriverDataActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* omcChangeNumSaga(action) {
  try {
    const response = yield call(omcChangeNumApi, action?.payload);
    console.log("data111133333311111", response);

    if (response.data.type == "success") {
      console.log("data1111333333", response);
      action.callback(response);
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Vehicle Map Successfully`,
          status: true,
        })
      );
      yield put(omcChangeNumActionSuccess(response?.data));
    }
  } catch (error) {
    console.log("data11113333332222", error);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* omcRegisterDriverSaga(action) {
  try {
    const response = yield call(omcRegisterDriverApi, action?.payload);
    console.log("data", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(omcRegisterDriverActionSuccess(response?.data?.data));
    } else if (response?.status == 251) {
      action.callback(response?.data?.error?.data);
      yield put(omcRegisterDriverActionSuccess(response?.data?.error?.data));
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action?.callback(error);
    } else {
      action?.callback(error);
    }
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getCityAndStateSaga(action) {
  try {
    console.log("api - step getting info-- 2");
    const response = yield call(getCityAndStateApi, action?.payload);
    console.log("data- omc", response);
    if (response.data.type == "success") {
      // action.callback(response);
      yield put(getCityAndStateActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* driverAddBeneficiarySaga(action) {
  try {
    console.log("api - step getting info-- 2");
    const response = yield call(driverAddBeneficiaryApi, action?.payload);
    console.log("data- omc", response);
    if (response.data.type == "success") {
      action.callback(response);
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Beneficiary added successfully`,
          status: true,
        })
      );
      yield put(driverAddBeneficiaryActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getDriverBeneficiarySaga(action) {
  try {
    console.log("api - step getting info-- 2");
    const response = yield call(getDriverBeneficiaryApi, action?.payload);
    console.log("data- omc", response);
    if (response.data.type == "success") {
      yield put(getDriverBeneficiaryActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* driverTransferToBeneficiarySaga(action) {
  try {
    console.log("api - step getting info-- 2");
    const response = yield call(
      driverTransferToBeneficiaryApi,
      action?.userId,
      action?.payload
    );
    console.log("data- omc", response);
    if (response.data.type == "success") {
      action?.callback(response);
      yield put(driverTransferToBeneficiaryActionSuccess(response?.data?.data));
    }
  } catch (error) {
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* addCardBeneficiarySaga(action) {
  try {
    console.log("api - step getting info-- 2");
    const response = yield call(addCardBeneficiaryApi, action?.payload);
    console.log("data- omc", response);
    if (response.data.type == "success") {
      action?.callback(response);
      yield put(addCardBeneficiaryActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* fuelWalletRechargeSaga(action) {
  try {
    console.log("api - step getting info-- 2 - fuel-wallet");
    const response = yield call(fuelWalletREchargeApi, action?.payload);
    console.log("data- omc", response);
    if (response.data.type == "success") {
      action?.callback(response);
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Recharge Successfully`,
          status: true,
        })
      );
      yield put(fuelwalletRechargeActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("data- omc111", error);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

// get fuel wallet balance
function* getFuelWalletBalSaga(action) {
  try {
    const response = yield call(getFuelWalletBalApi, action.payload);
    console.log("getFuelWalletBalSaga >>> ", response?.data);
    if (response.data.type == "success") {
      console.log("success", response?.data?.data);
      yield put(getFuelWalletbalSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getFuelWalletHistorySaga(action) {
  try {
    const response = yield call(getFuelWalletHistoryApi, action.payload);
    console.log("getFuelWalletBalSaga >>> ", response?.data);
    if (response.data.type == "success") {
      console.log("success", response?.data?.data);
      yield put(getFuelWalletHistorySuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


function* fuelWalletLimitUpdateSaga(action) {
  try {
    const response = yield call(fuelWalletLimitUpdateApi, action.payload);
    console.log("getFuelWalletBalSaga >>> ", response?.data);
    action?.callback(response);
    if (response.data.type == "success") {
      console.log("success", response?.data?.data);
      yield put(
        toastStatus({
          toastStatus: true,
          message: `Limit Update Successfully`,
          status: true,
        })
      );
      yield put(fuelWalletLimitUpdateSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ",error, error.response.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data?.message}`,
        status: false,
      })
    );
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}


export function* watchOmcSagas() {
  yield takeEvery(OMC_BALANCE, omcVehicleBalanceSaga);
  yield takeEvery(OMC_LOAD_MONEY, omcLoadMoneySaga);
  yield takeEvery(OMC_TRANSACTIONS, omcVehicleTransactionSaga);
  yield takeEvery(OMC_VEHICLE_LIST, getOmcVehiclesListSaga);
  yield takeEvery(OMC_VEH_STATUS, omcGetVehicleStatusSaga);
  yield takeEvery(OMC_WITHDRAW_MONEY, omcWithdrawMoneySaga);
  yield takeEvery(OMC_DRIVER_DATA, omcDriverDataSaga);
  yield takeEvery(OMC_NUMBER_CHANGE, omcChangeNumSaga);
  yield takeEvery(OMC_REGISTER_DRIVER, omcRegisterDriverSaga);
  yield takeEvery(GET_CITY_STATE, getCityAndStateSaga);
  yield takeEvery(DRIVER_ADD_BENEFICIARY, driverAddBeneficiarySaga);
  yield takeEvery(GET_DRIVER_BENEFICIARY, getDriverBeneficiarySaga);
  yield takeEvery(DRIVER_BANK_TRANSFER, driverTransferToBeneficiarySaga);
  yield takeEvery(ADD_DRIVER_CARD_BENEFICIARY, addCardBeneficiarySaga);
  yield takeEvery(FUEL_WALLET_RECHARGE, fuelWalletRechargeSaga);
  yield takeEvery(GET_FUEL_WALLET_BAL, getFuelWalletBalSaga);
  yield takeEvery(GET_FUEL_WALLET_HISTORY, getFuelWalletHistorySaga);
  yield takeEvery(FUEL_WALLET_LIMIT_UPDATE, fuelWalletLimitUpdateSaga);
}
