import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, Form, Table } from "reactstrap";
import "./index.css";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import {
  editTripExpenseAction,
  getTripExpenseAction,
} from "../../../../services/Store/Actions/TripsAction";
import Toast from "../../../subComponents/toast";
import {
  getVendorAction,
  cleanVendorList,
} from "../../../../services/Store/Actions/beneficiaryAction";
import SelectVendorModal from "../../../Vendor/Components/Modal/SelectVendorModal";
import { storeDataToAsyncStorage } from "../../../../services/Utils/functions";
import StartTripModal from "../StartTripModal";
import ManageDriverAddBalance from "../../../Modals/ManageDriverAddBalance";
import RechargeFastagButttonModal from "../../../Modals/rechargeFastagButton";
import OMCLoadModal from "../../../ManageDriver/SubComponents/NewDriverSideSection/SubComponents/Banners/OMCLoadModal";
import VendorPaymentModal from "../../../Modals/VendorPayment";
import AddCashExpense from "../../../Modals/AddCashExpense";
import { v4 as uuid } from "uuid";
import DriverDashboardAddExpense from "../../../Modals/DriverDashboardAddExpense";
import {
  omcVehicleBalanceAction,
} from "../../../../services/Store/Actions/omcActions";
function SattlePaymentDetails(props) {
  const {
    toggle,
    modal,
    setModal,
    tripData,
    routeData,
    startTrip,
    setRouteData,
    tripExpense,
    setItemTemp,
    fuelVandor,
    fleetVendor,
  } = props;
  const [state, setState] = useState(0);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectVendorModal, setSelectVendorModal] = useState(false);
  const [vendorType, setVendorType] = useState(4);
  const [vendorData, setVendorData] = useState({});
  const [openPinModal, setOpenPinModal] = useState(false);
  const [amount, setAmount] = useState(false);
  const [startTripModal, setStartTripModal] = useState(false);
  const [payStatus, setPayStatus] = useState("");
  const [status, setStatus] = useState(0);
  const [openToast, setOpenToast] = useState(false);
  const [openFailToast, setOpenFailToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [openFuel, setOpenFuel] = useState(false);
  const [tripKey, setTripKey] = useState("");
  const [singleCashData, setSingleCashData] = useState("");


  const [advancePayment, setAdvancePayment] = useState();
  const { fastagWallet } = useSelector((state) => state?.fastag);
  const fastagWalletAmount =
    fastagWallet.balance == null ? 0 : fastagWallet.balance;
  const omc_balace = useSelector((state) => state?.omc?.omc_balace);
  console.log("omcccccc",omc_balace)

  const handleClose6 = () => {
    setOpen6(false);
  };
  const toggleModal6 = () => {
    setOpen6(!open6);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };
  const toggleModal5 = () => {
    setOpen5(!open5);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const toggleModal4 = () => {
    setOpen4(!open4);
  };
  const toggleModal3 = () => {
    setOpen3(!open3);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const rechargeCard = () => {
    setOpen3(true);
  };
  const loadOmc = () => {
    setOpenFuel(true);
  };
  const handleCloseFuel = () => {
    setOpenFuel(false);
  };
  const toggleModalFuel = () => {
    setOpenFuel(!openFuel);
  };
  function startTripModalToggle() {
    setStartTripModal(!startTripModal);
  }
  console.log("tripExpense?.cashData", advancePayment,tripExpense);
  useEffect(() => {
    dispatch(omcVehicleBalanceAction(tripData?.fleetId));
    let xxx = [
      {
        header: "Fuel",
        type: tripExpense?.fuelFinalMode == 3 ? "OMC" : "Vendor" || "",
        vendor: tripExpense?.fuelFinalMode == 3 ? null : fuelVandor,
        expacted: tripExpense?.fuelTotal || "0",
        advance: tripExpense?.fuelAdvance || "0",
        totalExpense: tripExpense?.totalFuelExpense || "0",
        onPayAdvance: () => {
          loadOmc()
        },
        advanceStatus: tripExpense?.fuelAdvanceStatus || false,
        payable:
          parseInt(tripExpense?.fuelTotal || 0) -
            parseInt(tripExpense?.totalFuelExpense || 0) || "0",
      },
      {
        header: "Driver",
        type: "Card",
        vendor: {},
        expacted: tripExpense?.driverTotal || "0",
        advance: tripExpense?.driverAdvance || "0",
        totalExpense: tripExpense?.totalCardExpense || "0",
        advanceStatus: tripExpense?.driverAdvanceStatus || false,
        onPayAdvance: () => {
          rechargeCard();
        },
        payable:
          parseInt(tripExpense?.driverTotal || 0) -
            parseInt(tripExpense?.totalCardExpense || 0) || "0",
      },
      {
        header: "Fastag",
        type: "Fastag",
        vendor: {},
        expacted: tripExpense?.fastagTotal || "0",
        advance: tripExpense?.fastagAdvance || "0",
        advanceStatus: tripExpense?.fastagStatus || false,
        totalExpense: tripExpense?.totalFastagExpense || "0",
        onPayAdvance: () => {
          setOpen4(true);
        },
        payable:
          parseInt(tripExpense?.fastagTotal || 0) -
            parseInt(tripExpense?.totalFastagExpense || 0) || "0",
      },
    ];
    console.log("fleetVendor", fleetVendor);
    if (fleetVendor?.id) {
      console.log("fleetVendor2", fleetVendor);
      xxx.push({
        header: "Vendor",
        type: "Vendor",
        vendor: fleetVendor,
        expacted: tripExpense?.vendorTotal || "0",
        advance: tripExpense?.vendorAdvance || "0",
        advanceStatus: tripExpense?.vendorAdvanceStatus || false,
        onPayAdvance: () => {
          setOpen5(true);
        },
        totalExpense: tripExpense?.totalVendorExpanse || "0",
        payable:
          parseInt(tripExpense?.vendorTotal || 0) -
            parseInt(tripExpense?.totalVendorExpanse || 0) || "0",
      });
    }
    if (tripExpense?.cashData?.length > 0) {
      console.log(
        "tripExpense?.cashData22",
        xxx,
        tripExpense?.cashData?.length
      );
      tripExpense?.cashData.map((e) => {
        e["onPayAdvance"] = () => {
          setOpen6(true);
        };
        e["payable"] =
          parseInt(e?.expacted || 0) - parseInt(e?.totalExpense || 0) || "0";
      });
      console.log(
        "tripExpense?.cashData221",
        tripExpense?.cashData,
        tripExpense?.cashData?.length
      );
      xxx = [...xxx, ...tripExpense?.cashData];
    } else {
      xxx = [
        ...xxx,
        {
          id: uuid(),
          header: "Cash",
          type: "",
          vendor: null,
          givenTo: "",
          expacted: "0",
          advance: "0",
          totalExpense: "0",
          payable: "0",
          key: "",
          expactedStatus: 0,
          advanceStatus: 0,
          onPayAdvance: () => {
            setOpen6(true);
          },
        },
      ];
    }
    console.log("tripExpense?.cashData11", xxx, tripExpense?.cashData?.length);
    setAdvancePayment(xxx);
  }, [tripExpense]);
  console.log("routeadvancePaymentData", tripData);
  useEffect(() => {
    dispatch(
      getVendorAction({
        isLogipeVendor: "0",
        limit: 4,
        offset: 0,
        search: search,
        vendorType: vendorType,
      })
    );
  }, [vendorType]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    setModal(!modal);
  };
  const handleToggleModal = () => {
    setOpenPinModal(!openPinModal);
  };
  const handleCloseModal = () => {
    setOpenPinModal(false);
  };
  const paymentType = {
    Fuel: omc_balace ? [{ value: "Vendor" }, { value: "OMC" }] : [{ value: "Vendor" }],
    Driver: [{ value: "Card" }],
    Fastag: [{ value: "Fastag" }],
    Vendor: [{ value: "Vendor" }],
    Cash: [
      { value: "Food" },
      { value: "Service" },
      { value: "Fuel" },
      { value: "Salary" },
      { value: "Fastag" },
      { value: "Others" },
      { value: "Vendor" },
    ],
  };
  const givenTo = {
    Cash: [{ value: "Vendor" }, { value: "Driver" }],
  };
  let handleChangeField = (i, e) => {
    console.log("pppoooooooooo", e.target.value, i);
    let newFormValues = [...advancePayment];
    newFormValues[i][e.target.name] = e.target.value;
    setAdvancePayment(newFormValues);
  };
  let addFormFields = () => {
    setAdvancePayment([
      ...advancePayment,
      {
        id: uuid(),
        header: "Cash",
        type: "",
        vendor: null,
        expacted: "0",
        advance: "0",
        key: "",
        payable: "0",
        totalExpense: "0",
        onPayAdvance: () => {
          setOpen6(true);
        },
        expactedStatus: 0,
        advanceStatus: 0,
      },
    ]);
  };
  let removeFormFields = (index) => {
    const newFormValues = advancePayment.filter((item, i) => {
      return index != i;
    });
    console.log("newFormValues", newFormValues);
    setAdvancePayment(newFormValues);
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        onHide={handleClose}
        className=" modal-dialog-centered model-width-2"
        toggle={toggle}
        aria-labelledby="myModalLabel2"
        // scrollable={true}
        style={{ width: "100%" }}
        centered={true}
      >
        <ModalBody
          className="transactionModalBody"
          style={{ marginTop: "25px", width: "100%" }}
        >
          <div>
            <div className=/*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction">
              <div className="closeEffect" onClick={handleClose}>
                <SvgImage
                  classname="close-btn-text"
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
            </div>
            <div
              className="PGCollectiontText col-9"
              style={{ marginLeft: "50px" }}
            >
              Payment Details
            </div>
            <Formik
              // isValid='true'
              enableReinitialize
              // validationSchema={validationSchema}
              initialValues={{
                fuelWalletAmount: routeData?.fuelAdvance || "",
                expectedFuelLiter: routeData?.fuelExpected || "",
                fuelPaymentType: "OMC",
                advanceDriver: routeData?.sDriverAdvance || "",
                driverSalary: routeData?.sDriverExpected || "",
                driverIncentive: "",
                driverPaymentType: "Salary",
                fastagAmount: routeData?.fastagAdvance || "",
                expactedFastagUses: routeData?.fastagExpected || "",
                vendorAmount: "",
                vendorIncentive: "",
                totalVendorExpanse: "",
                cashExpanceData: [
                  {
                    givenTo: "driver",
                    expanseType: "",
                    amount: routeData?.cashExpected || "",
                  },
                ],
              }}
              onSubmit={(values) => {
                // setState(1);

                console.log("onSubmitonSubmit", values);
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "20px", padding: "0 50px 0 50px" }}
                >
                  <Table
                    className="TripSectionTable"
                    bordered="true"
                    id="driver_tables" //
                    style={{ height: "30vh", marginTop: "20px" }}
                  >
                    <thead>
                      <tr className="table-header-trip p-0 align-middle text-center">
                        <th className="p-0" style={{ width: "15%" }}>
                          <div style={{ textAlignLast: "center" }}>For</div>{" "}
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "15%", textAlignLast: "center" }}
                        >
                          type
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "25%", textAlignLast: "center" }}
                        >
                          Vendor
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "25%", textAlignLast: "center" }}
                        >
                          Expected
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "20%", textAlignLast: "center" }}
                        >
                          Advance
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "20%", textAlignLast: "center" }}
                        >
                          Total
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "20%", textAlignLast: "center" }}
                        >
                          Payable Amount
                        </th>
                        <th
                          className="p-0"
                          style={{ width: "20%", textAlignLast: "center" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="lowerBorderLineCust">
                      {advancePayment?.map((items, index) => {
                        console.log("---------sdasdadadadasd--", items);
                        let type = items?.type;
                        return (
                          <tr
                            className="table-row-manage-trip-new text4 align-middle text-center p-0"
                            id="addr0"
                            key={index}
                            onClick={() => {
                              // window.location = "/Customers/" + 1;
                              //   navigate("/Customers/" + 1);
                            }}
                          >
                            <td
                              style={{
                                width: "15%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                            >
                              <div
                                className="FontMedium"
                                style={{ textAlignLast: "center" }}
                              >
                                {" "}
                                {items.header}
                              </div>
                            </td>
                            <td
                              style={{
                                width: "15%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                              }}
                              className="p-0"
                            >
                              <div
                                // className="d-flex"
                                style={{
                                  alignItems: "center",
                                  // justifyContent: "center",
                                }}
                              >
                                <input
                                  type="text"
                                  className="minBox3"
                                  name="type"
                                  placeholder="Choose a type"
                                  value={type || ""}
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedIndex(index);
                                  }}
                                  onChange={(e) => handleChangeField(index, e)}
                                  autoComplete="off"
                                />

                                {open && selectedIndex == index ? (
                                  <div
                                    className="paymentTypeSelect containerShadow"
                                    style={{ width: "200px" }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0.3rem",
                                        right: "0.3rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setOpen(false);
                                      }}
                                    >
                                      <SvgImage name="CloseButtonLogo" />
                                    </div>
                                    {paymentType[items.header]?.map(
                                      (item, i) => {
                                        return (
                                          <>
                                            {/* {item?.Fastag !== null ? ( */}
                                            <div
                                              key={i}
                                              className="flexStyling padding8"
                                              onClick={(event) => {
                                                handleChangeField(index, event);
                                                setOpen(false);
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                name="type"
                                                onChange={(e) =>
                                                  handleChangeField(index, e)
                                                }
                                                value={item?.value}
                                                checked={item?.value == type}
                                                id={item?.value}
                                                autoComplete={"off"}
                                              />
                                              <div
                                                className="padding16L"
                                                value={item?.value}
                                              >
                                                {item?.value}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>
                            <td
                              style={{
                                width: "25%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                                justifyContent: "center",
                              }}
                              className="p-0"
                            >
                              <div
                                className="d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {items.header == "Cash" && type == "Vendor" ? (
                                  <div style={{ marginRight: "6px" }}>
                                    Vendor
                                  </div>
                                ) : null}

                                <input
                                  type="text"
                                  className="minBox3"
                                  name="vendorId"
                                  style={{
                                    width:
                                      items.header == "Cash" && type == "Vendor"
                                        ? "70%"
                                        : "90%",
                                  }}
                                  placeholder={
                                    type == "Vendor"
                                      ? "Pick a vendor"
                                      : "Not Required"
                                  }
                                  onClick={() => {
                                    setSelectVendorModal(true);
                                    setSelectedIndex(index);
                                    if (items.header == "Fuel") {
                                      // dispatch(cleanVendorList())
                                      setVendorType(4);
                                    } else if (items.header == "Vendor") {
                                      // dispatch(cleanVendorList())
                                      setVendorType(2);
                                    }
                                  }}
                                  disabled={type == "Vendor" ? false : true}
                                  value={
                                    type == "Vendor" ? items?.vendor?.name : ""
                                  }
                                  // onChange={(e) => handleChangeField(index, e)}
                                />
                              </div>
                            </td>
                            <td
                              style={{
                                width: "25%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                                justifyContent: "center",
                              }}
                              className="p-0"
                            >
                              <div
                                className="d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <input
                                  type="number"
                                  className="minBox3"
                                  name="expacted"
                                  placeholder="₹ 0"
                                  value={items.expacted || ""}
                                  onChange={(e) => handleChangeField(index, e)}
                                />
                              </div>
                            </td>
                            <td
                              style={{
                                width: "20%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                                justifyContent: "center",
                              }}
                              className="p-0"
                            >
                              <div
                                className="d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <input
                                  type="number"
                                  className="minBox3"
                                  name="advance"
                                  placeholder="₹ 0"
                                  value={items.advance || ""}
                                  onChange={(e) => handleChangeField(index, e)}
                                />
                                {items?.advanceStatus ? (
                                  <div
                                    style={{
                                      marginLeft: "5%",
                                      position: "absolute",
                                      fontSize: "10px",
                                      backgroundColor: "#FFFACD",
                                      color: "#FF7F50",
                                      borderWidth: "1px",
                                      borderColor: "#FF7F50",
                                      borderStyle: "solid",
                                      borderRadius: "8px",
                                      paddingLeft: "6px",
                                      paddingRight: "6px",
                                    }}
                                  >
                                    paid
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      marginLeft: "5%",
                                      position: "absolute",
                                      fontSize: "10px",
                                      backgroundColor: "#2E7C7B",
                                      color: "#FFF",
                                      borderRadius: "4px",
                                      paddingLeft: "6px",
                                      paddingRight: "6px",
                                      cursor: "pointer",
                                      // zIndex: 12,
                                    }}
                                    onClick={() => {
                                      if(items.header == "Fuel"){
                                        if(items.type == "Vendor") setOpen5(true);
                                        else loadOmc()
                                      }
                                      else items.onPayAdvance();
                                      setAmount(items?.advance);
                                      setSingleCashData(items);
                                      setTripKey("advance");
                                      setStatus(1);
                                    }}
                                  >
                                    Pay
                                  </div>
                                )}
                              </div>
                            </td>
                            <td
                              style={{
                                width: "20%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                                justifyContent: "center",
                              }}
                              className="p-0"
                            >
                              <div
                                className="d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <input
                                  type="number"
                                  className="minBox3"
                                  name="totalExpense"
                                  placeholder="₹ 0"
                                  value={items.totalExpense || ""}
                                  onChange={(e) => handleChangeField(index, e)}
                                  readOnly
                                />
                              </div>
                            </td>
                            <td
                              style={{
                                width: "20%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                                justifyContent: "center",
                              }}
                              className="p-0"
                            >
                              <div
                                className="d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <input
                                  type="number"
                                  className="minBox3"
                                  name="payable"
                                  placeholder="₹ 0"
                                  value={items.payable || ""}
                                  onChange={(e) => handleChangeField(index, e)}
                                  readOnly
                                />
                              </div>
                            </td>
                            <td
                              style={{
                                width: "20%",
                                borderLeft: "1px solid transparent",
                                borderRight: " 1px solid transparent",
                                justifyContent: "center",
                              }}
                              className="p-0"
                            >
                              <div
                                className="d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setStatus(0);
                                    if(items.header == "Fuel"){
                                      if(items.type == "Vendor") setOpen5(true);
                                      else loadOmc()
                                    }
                                    else items.onPayAdvance();
                                    setTripKey("");
                                    setSingleCashData(items);
                                    // setAmount(items?.payable);
                                  }}
                                  style={{
                                    fontSize: "12px",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    backgroundColor: "#2E7C7B",
                                    color: "#fff",
                                    borderColor: "#2E7C7B",
                                  }}
                                >
                                  Pay
                                </Button>
                                <Button
                                  onClick={() => {
                                    setStatus(2);
                                    if(items.header == "Fuel"){
                                      if(items.type == "Vendor") setOpen5(true);
                                      else loadOmc()
                                    }
                                    else items.onPayAdvance();
                                    setSingleCashData(items);
                                    setAmount(items?.payable);
                                    setTripKey("final");
                                  }}
                                  style={{
                                    fontSize: "12px",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    backgroundColor: "#2E7C7B",
                                    color: "#fff",
                                    borderColor: "#2E7C7B",
                                    marginLeft: "12px",
                                  }}
                                >
                                  Settle
                                </Button>
                              </div>
                            </td>
                            {items.header == "Cash" ? (
                              <td
                                style={{
                                  width: "5%",
                                  borderLeft: "1px solid transparent",
                                  borderRight: " 1px solid transparent",
                                }}
                                className="p-0"
                                onClick={() => {
                                  removeFormFields(index);
                                }}
                              >
                                <SvgImage name="deleteIcon2" />
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                      <div className="d-flex justify-content-center">
                        <div
                          className="addItemDiv3"
                          // style={{zIndex:10 }}
                          onClick={addFormFields}
                        >
                          + Add Item For Cash
                        </div>
                      </div>
                    </tbody>
                  </Table>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // gap: "25px",
                      margin: "20px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        console.log(
                          "asdasdasdasdasdasdasdas========",
                          openToast
                        );
                        let data = {
                          tripId: tripData?.tripId,
                          driverAdvance: advancePayment[1]?.advance || 0,
                          driverTotal: advancePayment[1]?.expacted || 0,
                          vendorAdvance: advancePayment[3]?.advance || 0,
                          vendorTotal: advancePayment[3]?.expacted || 0,
                          fuelAdvance: advancePayment[0]?.advance || 0,
                          fuelTotal: advancePayment[0]?.expacted || 0,
                          fuelVendorId: advancePayment?.[0]?.vendor?.id || 0,
                          fastagTotal: advancePayment[2]?.expacted || 0,
                          cashTotal: advancePayment[4]?.expacted || 0,
                          cashData: advancePayment?.filter(
                            (item) => item.header == "Cash"
                          ),
                          // totalCardExpense: advancePayment[1].expacted || 0,
                          // totalCashExpense: advancePayment[4].expacted || 0,
                          // totalFuelExpense: advancePayment[0].expacted || 0,
                          // totalFastagExpense: advancePayment[2].expacted || 0,
                          // totalDriverExpense: advancePayment[1].expacted || 0,
                        };

                        dispatch(
                          editTripExpenseAction(data, (res) => {
                            console.log("editTripExpenseAction", res);
                            if (res.status == 200) {
                              setToastMsg(
                                "Advance Payment Details saved successfully"
                              );
                              // handleClose();
                              setOpenToast(true);
                            }
                          })
                        );
                        // dispatch(createTripExpense(data, () => {}));
                      }}
                      style={{ width: "70%" }}
                      className="generateOtp text3 Medium btn btn-secondary"
                    >
                      Save Details
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <SelectVendorModal
        open={selectVendorModal}
        onHide={() => {
          setSelectVendorModal(false);
        }}
        toggleModal={() => {
          setSelectVendorModal(!selectVendorModal);
        }}
        handleClose={() => {
          setSelectVendorModal(false);
        }}
        onClickItem={(data) => {
          setVendorData({});
          setVendorData(data);
          setSelectVendorModal(false);
          let newFormValues = [...advancePayment];
          newFormValues[selectedIndex]["vendor"] = { ...data };
          setSelectedIndex(0);
          setAdvancePayment(newFormValues);
        }}
        search={search}
        setSearch={setSearch}
        vendorType={vendorType}
      />

      <Toast
        success={true}
        message={toastMsg}
        isOpen={openToast}
        // name="trips"
        handleClose={() => {
          setOpenToast(false);
          handleClose();

          dispatch(
            getTripExpenseAction({ tripId: tripData?.tripId }, (res) => {
              if (res?.status == 200) {
                if (res?.data?.data?.trip) {
                  storeDataToAsyncStorage("row", res?.data?.data?.trip);
                }
              }
            })
          );
          navigate(`/trips/${tripData?.tripId}`);
        }}
      />
      <Toast
        success={false}
        message={toastMsg}
        isOpen={openFailToast}
        // name="trips"
        handleClose={() => {
          setOpenFailToast(false);
          // handleClose();
          // setRouteData({});
          // storeDataToAsyncStorage("row", tripData);
          // navigate(`/trips/${tripData?.tripId}`);
        }}
      />
      <ManageDriverAddBalance
        open={open3}
        amount={amount}
        toggleModal={toggleModal3}
        status={status}
        remarks={`${
          status == 1 ? "Advance " : status == 2 ? "Settle " : ""
        }Payment for Trip ${tripData?.tripNo}`}
        title="Logipe Account"
        handleClose={handleClose3}
        color={
          "LogipeAccount" === "Card Wallet" ? "title-color1" : "title-color2"
        }
        name="Add Balance"
        userId={tripData?.userId}
        cardType={tripData?.cardType}
        trip={true}
        driverName={tripData?.User?.fullName}
        itemTemp={tripData}
        tripKey={tripKey}
      />
      <RechargeFastagButttonModal
        open={open4}
        amount={amount}
        status={status}
        remarks={`${
          status == 1 ? "Advance " : status == 2 ? "Settle " : ""
        }Payment for Trip ${tripData?.tripNo}`}
        handleClose={handleClose4}
        toggleModal={toggleModal4}
        balance={fastagWalletAmount}
        no={tripData?.Fleet?.regNumber}
        fleetId={tripData?.fleetId}
        trip={true}
        itemTemp={tripData}
        tripKey={tripKey}
      />

      <OMCLoadModal
        amount={amount}
        status={status}
        userId={tripData?.userId}
        open={openFuel}
        toggleModal={toggleModalFuel}
        handleClose={handleCloseFuel}
        tripKey={tripKey}
        itemTemp={tripData}
        advancePayment={advancePayment}
        fleetId = {tripData?.fleetId}
        trip = {1}
      />
      <VendorPaymentModal
        amount={amount}
        status={status}
        userId={tripData?.userId}
        open={open5}
        toggleModal={toggleModal5}
        handleClose={handleClose5}
        tripKey={tripKey}
        itemTemp={tripData}
        advancePayment={advancePayment}
      />
      {/* <AddCashExpense
        amount={amount}
        status={status}
        remarks={`${
          status == 1 ? "Advance " : status == 2 ? "Settle " : ""
        }Payment for Trip ${tripData?.tripNo}`}
        // userId={tripData?.userId}
        open={open6}
        toggleModal={toggleModal6}
        handleClose={handleClose6}
        tripKey={tripKey}
        itemTemp={tripData}
        advancePayment={advancePayment}
        singleCashData={singleCashData}
      /> */}

      <DriverDashboardAddExpense
        open={open6}
        toggleModal={toggleModal6}
        handleClose={handleClose6}
        name="Add Balance"
        userId={tripData?.userId}
        driverBal={"SCDa"}
        cardType={1}
        driverName={props.driverName}
        accountStatus={props.accountStatus}
        remarks={`${
          status == 1 ? "Advance " : status == 2 ? "Settle " : ""
        }Payment for Trip ${tripData?.tripNo}`}
        modalName={singleCashData && singleCashData?.type == "Vendor" && singleCashData?.vendor?.name ? "Vendor Name" : "Trip Number"}
        itemTemp={tripData}
        value={singleCashData && singleCashData?.type == "Vendor" && singleCashData?.vendor?.name ? singleCashData?.vendor?.name :tripData.tripNo}
        transferType="2"
        settleAmount={amount}
        tripKey={tripKey}
        expenseData = {singleCashData}
        vendorId={singleCashData && singleCashData?.type == "Vendor" && singleCashData?.vendor ? singleCashData?.vendor?.id : null}
        // expType = {"trip"}
      />
    </div>
  );
}

export default SattlePaymentDetails;
