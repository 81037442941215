import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import Toast from "../../subComponents/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toastStatus } from "../../../services/Store/Actions/appActions";
import { ToastContainer } from "react-toastify";
import DriverMain from "../SubComponents/DriverMain";
import SvgImage from "../../Icons/Icons";
import BulkTransfer from "../SubComponents/BulkTransfer";
import DriverList from "../SubComponents/DriverList";
import Requests from "../Requests";
import { getCardRequest } from "../../../services/Store/Actions/cardWalletAction";

const DriverDashboard = (props) => {
  const { sideBar } = props;
  let { state, location } = useLocation();
  const [bulkSalary, setBulkSalary] = useState(false);
  const [requests, setRequests] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { allDriversAdvance, allDriverListAdvance } = useSelector(
    (state) => state?.driverlist
  );
  const [showRequest,setshowRequest] = useState(false);
  const showRequests = () => {
    setshowRequest(!showRequest);
  };
  // if(!state?.driver){
  //     console.log("idididdddd",id);
  // }

  console.log(allDriversAdvance, allDriverListAdvance);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newDriverList = useSelector((state) => state);
  const driverCount = newDriverList?.driverlist?.newDriverList?.divers?.count;
  const newList = newDriverList?.driverlist?.newDriverList?.divers?.rows;

  console.log("allDriversWithKYC", newList);

  console.log("new driver list", driverCount);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    console.log("skbskasjshjkhgfc")
    dispatch(getCardRequest({},(res)=>{
      console.log("cardRequestreposnseeee",res)
    }))

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  return (
    <div
      className="container topMargin sideBarMargin"
      style={{
        marginLeft: sideBar ? "230px" : "110px",
        maxWidth: sideBar ? windowWidth - 240 : windowWidth - 130,
      }}
    >
      <div className="row">
        <div
          className="col-lg-3 FullDriverDashboardBox"
          style={{ background: "#FFFFFF", borderRadius: "10px 0px 0px 0px" }}
        >
          <div
            className="FUllListSearchBar leftTopDiv"
            onClick={() => {
              console.log("bulkSalarybulkSalary11", bulkSalary);
              setBulkSalary(!bulkSalary);
            }}
            style={{ margin: "10px 12px 20px 11px", cursor: "pointer" }}
          >
            <div className="bulkSalarySvgHeading">
              <div
                style={{
                  backgroundColor: "#FFF",
                  padding: "4px",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
              >
                <SvgImage name="payBulkSalaryLogo" />
              </div>
              <div className="FontNS18">Transfer Driver Bulk</div>
            </div>
            <SvgImage name="rightArrow" />
          </div>
          {bulkSalary ? <BulkTransfer /> : <></>}
          <div
            className="FUllListSearchBar leftTopDiv"
            onClick={showRequests}
            style={{ margin: "10px 12px 20px 11px", cursor: "pointer" }}
          >
            <div className="bulkSalarySvgHeading">
              <div
                style={{
                  backgroundColor: "#FFF",
                  padding: "4px",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
              >
                <SvgImage name="savingsIcon" />
              </div>
              <div className="FontNS18">Card Requests</div>
            </div>
            <SvgImage name="rightArrow" />
          </div>
          <DriverList />
        </div>
        {/* {
           bulkSalary ? <BulkTransfer/> :  !state?.driver ? <NoDriverSelected /> :      
           <div className="col-lg-9 FullDriverDashboardBox">
          <DriverMain
          />
          </div>
        } */}
        <div className="col-lg-9 FullDriverDashboardBox">
          <DriverMain bulkSalary={bulkSalary} sideBar={sideBar} />
        </div>
      </div>
      
      {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
          }}
          handleClose={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
          }}
          isOpen={toastObject?.toastStatus}
          name="driver"
        />
      ) : (
        <></>
      )}
      <ToastContainer
        theme="dark"
        position="top-right"
        closeOnClick
        pauseOnHover
        autoClose={4000}
      />

<Requests
          toggle={showRequests}
          modal={showRequest}
          setModal={setshowRequest}
          regNo={'props.regNo'}
          fleetId={'fleetId'}
          // customerId={customerId}
        />
    </div>

    
  );
};
export default DriverDashboard;
