//import {Alert} from 'react-native';
import { call, put, takeEvery } from "redux-saga/effects";
import {
  getMasterWalletBalanceApi,
  transactionHistoryApi,
  linkBankToLogipeAccount,
  neftAccountListApi,
} from "../../Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import {
  getMasterWalletBalanceSuccess,
  transactionHistorySuccess,
  linkBankAccountToLogipeSuccess,
  neftLinkedAccountsListActionSuccess,
} from "../Actions/masterWalletActions";
import {
  GET_MASTERWALLET_BALANCE,
  MASTERWALLET_TRANSACTION_HISTORY,
  LINK_BANK_ACCOUNT_TO_LOGIPE,
  NEFT_LINKED_ACCOUNTS_LIST,
} from "../storeTypes";

function* linkBankAccountToLogipeSaga(action) {
  try {
    const response = yield call(linkBankToLogipeAccount, action.payload);
    console.log("success---0909",response );
    if (response?.data?.type == "success") {
      console.log("success",response );
      action.callback(response);
      yield put(linkBankAccountToLogipeSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getBalaneSaga Error.Response:- ", error);
    // alert(`Error : ${error.response.data.message}`);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getBalaneSaga() {
  try {
    const response = yield call(getMasterWalletBalanceApi);
    if (response.data.type == "success") {
      yield put(getMasterWalletBalanceSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("getBalaneSaga Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* transactionHistorySaga(action) {
  try {
    const response = yield call(transactionHistoryApi, action.payload);
    if (response.data.type == "success") {
      //console.log('RESULT', response.data.data);
      yield put(transactionHistorySuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* neftLinkedAccountsListSaga(action) {
  try {
    const response = yield call(neftAccountListApi, action.payload);
    if (response.data.type == "success") {
      //console.log('RESULT', response.data.data);
      yield put(neftLinkedAccountsListActionSuccess(response?.data?.data));
    }
  } catch (error) {
    console.log("Error.Response:- ", error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

export function* watchMasterWalletSagas() {
  yield takeEvery(GET_MASTERWALLET_BALANCE, getBalaneSaga);
  yield takeEvery(MASTERWALLET_TRANSACTION_HISTORY, transactionHistorySaga);
  yield takeEvery(LINK_BANK_ACCOUNT_TO_LOGIPE, linkBankAccountToLogipeSaga);
  yield takeEvery(NEFT_LINKED_ACCOUNTS_LIST,neftLinkedAccountsListSaga);
}
