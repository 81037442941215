import { call, put, takeEvery } from "redux-saga/effects";

import {
  ADD_PAYMENT_RECIVED,
  CREATE_EXTERNAL_INVOICE,
  CREATE_LOGIPE_INVOICE,
  DELETE_LEDGER,
  DOWNLOAD_INVOICE,
  DOWNLOAD_LEDGER,
  EDIT_EXTERNAL_INVOICE,
  EDIT_LOGIPE_INVOICE,
  GET_INVOICE,
  GET_LEDGER_LIST,
  UPLOAD_RECIPT,
  GET_ALL_CUSTOMER,
  CREATE_CUSTOMER,
  GET_CUSTOMER,
  GET_ALL_TRIPS,
  COLLECT_PAYMENT,
  EDIT_CUSTOMER,
  ADD_VENDOR_PAYMENT,
  GET_INVOICES_LIST,
  ADD_NEW_PHONE_FW,
} from "../storeTypes";
import {
  createExternalInvoiceSuccessAction,
  createLogipeInvoiceSuccessAction,
  deleteLedgerSuccessAction,
  downloadInvoiceSuccessAction,
  getLedgerListSuccessAction,
  getLedgerSuccessAction,
  getInvoiceSuccessAction,
  editLogipeInvoiceSuccessAction,
  editExternalInvoiceSuccessAction,
  uploadReciptSuccessAction,
  addPaymentRecivedSuccessAction,
  downloadLedgerSuccessAction,
  getAllCustomersSuccessAction,
  createCustomerSuccessAction,
  getCustomerSuccessAction,
  getAllTripsSuccessAction,
  collectPaymentSuccessAction,
  editCustomerSuccessAction,
  addVendorPaymentSuccessAction,
  getInvoiceListSuccessAction,
  addNewPhoneFwActionSuccess,
} from "../Actions/customerAction";
import {
  addPaymentRecivedApi,
  createExternalInvoiceApi,
  createLogipeInvoiceApi,
  deleteLedgerApi,
  downloadInvoiceApi,
  downloadLedgerApi,
  editExternalInvoiceApi,
  editLogipeInvoiceApi,
  getInvoiceApi,
  getLedgerListApi,
  uploadReciptApi,
  getAllCustomersApi,
  createCustomerApi,
  getCustomerApi,
  getAllTripsApi,
  collectPaymentApi,
  editCustomerApi,
  addVendorPaymentApi,
  getInvoiceListApi,
  addNewPhoneFWApi,
} from "../../Utils/Apis";
import { processErrorAndRespond } from "../../Utils/functions";
import { toastStatusSuccess } from "../Actions/appActions";
import { toastStatus } from "../../../services/Store/Actions/appActions";

function* customerListSaga(action) {
  try {
    //console.log("sdfsfghjhgf",action)
    const response = yield call(getAllCustomersApi, action.payload);
    console.log("IDM", response);

    if (response.data.type == "success") {
      //   action.payload = response;
      yield put(getAllCustomersSuccessAction(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("driver Error ", processErrorAndRespond(error));
  }
}

function* getCustomerSaga(action) {
  try {
    //console.log("sdfsfghjhgf",action)
    const response = yield call(getCustomerApi, action.payload);
    console.log("IDM", response);

    if (response.data.type == "success") {
      //   action.payload = response;
      yield put(getCustomerSuccessAction(response?.data));
      action?.callback(response?.data);
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    action?.callback(error.response?.data);
    console.log("driver Error ", processErrorAndRespond(error));
  }
}

function* createCustomerSaga(action) {
  try {
    const response = yield call(createCustomerApi, action.payload);
    console.log("createCustomerSaga", response);
    if (response.data.type == "success") {
      // console.log("mazeeeeee");
      action.callback(response);
      yield put(createCustomerSuccessAction(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Customer Created Successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getLedgerListSaga(action) {
  try {
    const response = yield call(getLedgerListApi, action?.payload);
    console.log("getLedgerListSaga ", response?.data);
    if (response?.data?.type == "success") {
      yield put(getLedgerListSuccessAction(response?.data?.data));
    }
  } catch (error) {
    // yield put(getLedgerListSuccessAction());
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getInvoiceListSaga(action) {
  try {
    const response = yield call(getInvoiceListApi, action?.payload);
    console.log("getInvoiceListSaga ", response?.data);
    if (response?.data?.type == "success") {
      yield put(getInvoiceListSuccessAction(response?.data?.data));
    }
  } catch (error) {
    // yield put(getInvoiceListSuccessAction());
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* deleteLedgerSaga(action) {
  try {
    const response = yield call(deleteLedgerApi, action?.payload);
    action.callBack(response);
    yield put(
      toastStatus({
        toastStatus: true,
        message: "Ledger Deleted Successfully.",
        status: true,
      })
    );
    if (response?.data?.type == "success") {
      yield put(deleteLedgerSuccessAction(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* createLogipeInvoiceSaga(action) {
  try {
    console.log("createLogipeInvoiceAction 3", action);
    const response = yield call(createLogipeInvoiceApi, action?.payload);
    action?.callBack(response?.data?.data);
    if (response?.data?.type == "success") {
      yield put(createLogipeInvoiceSuccessAction(response?.data?.data));
    }
  } catch (error) {
    action?.callBack(error?.response?.data);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* createExternalInvoiceSaga(action) {
  try {
    const response = yield call(createExternalInvoiceApi, action?.payload);
    if (response?.data?.type == "success") {
      action.callBack(response);
      yield put(createExternalInvoiceSuccessAction(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error.response);
    yield put(
      toastStatusSuccess({
        toastStatus: true,
        message: error?.response?.data?.message || "Error in Creating Invoice",
        status: false,
      })
    );
    console.log("API Error:- ", error?.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* downloadInvoiceSaga(action) {
  try {
    const response = yield call(downloadInvoiceApi, action?.payload);
    action.callBack(response);
    if (response?.data?.type == "success") {
      yield put(downloadInvoiceSuccessAction(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* getInvoiceSaga(action) {
  try {
    const response = yield call(getInvoiceApi, action?.payload);
    if (response?.data?.type == "success") {
      yield put(getInvoiceSuccessAction(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* editLogipeInvoiceSaga(action) {
  try {
    const response = yield call(editLogipeInvoiceApi, action?.payload);
    if (response?.data?.type == "success") {
      yield put(editLogipeInvoiceSuccessAction(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* editExternalInvoiceSaga(action) {
  try {
    const response = yield call(editExternalInvoiceApi, action?.payload);
    if (response?.data?.type == "success") {
      yield put(editExternalInvoiceSuccessAction(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* uploadReciptSaga(action) {
  try {
    const response = yield call(uploadReciptApi, action?.payload);
    if (response?.data?.type == "success") {
      yield put(uploadReciptSuccessAction(response?.data?.data));
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* addPaymentRecivedSaga(action) {
  try {
    const response = yield call(addPaymentRecivedApi, action?.payload);
    action.callBack(response);
    if (response?.data?.type == "success") {
      yield put(addPaymentRecivedSuccessAction(response?.data?.data));
    }
  } catch (error) {
    action.callBack(error.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* downloadLedgerSaga(action) {
  try {
    const response = yield call(downloadLedgerApi, action?.payload);
    action.callBack(response);
    if (response?.data?.type == "success") {
      yield put(downloadLedgerSuccessAction());
    }
  } catch (error) {
    action.callBack(error.response);
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getAllTripsSaga(action) {
  try {
    //console.log("sdfsfghjhgf",action)
    const response = yield call(getAllTripsApi, action.payload);
    console.log("IDM", response);

    if (response.data.type == "success") {
      //   action.payload = response;
      yield put(getAllTripsSuccessAction(response?.data?.data));
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    console.log("driver Error ", processErrorAndRespond(error));
  }
}

function* collectPaymentSaga(action) {
  try {
    const response = yield call(collectPaymentApi, action?.payload);
    if (response?.data?.type == "success") {
      action.callBack(response?.data?.data);
      yield put(collectPaymentSuccessAction(response?.data?.data));
      // yield put(
      //   toastStatus({
      //     toastStatus: true,
      //     message: "Collect Payment Successfully",
      //     status: true,
      //   })
      // );
    }
  } catch (error) {
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* editCustomerSaga(action) {
  try {
    const response = yield call(editCustomerApi, action?.payload);
    if (response?.data?.type == "success") {
      action.callBack(response);
      yield put(editCustomerSuccessAction(response?.data?.data));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Customer Edit Successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}
function* addVendorPaymentSaga(action) {
  try {
    const response = yield call(addVendorPaymentApi, action?.payload);
    if (response?.data?.type == "success") {
      action.callBack(response);
      yield put(addVendorPaymentSuccessAction());
    }
  } catch (error) {
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* addNewPhoneFWSaga(action) {
  try {
    const response = yield call(addNewPhoneFWApi, action.payload);
    console.log("createCustomerSaga", response);
    if (response.data.type == "success") {
      // console.log("mazeeeeee");
      action.callback(response);
      yield put(addNewPhoneFwActionSuccess(response));
      yield put(
        toastStatus({
          toastStatus: true,
          message: "Phone Number Added Successfully",
          status: true,
        })
      );
    }
  } catch (error) {
    //console.log('Error.Response:- ', error.response.data);
    //Alert.alert('Error', error.response.data.message);
    yield put(
      toastStatus({
        toastStatus: true,
        message: `Error : ${error?.response?.data.message}`,
        status: false,
      })
    );
    console.log("API Error:- ", processErrorAndRespond(error));
  }
}

export function* watchcustomerSagas() {
  yield takeEvery(GET_ALL_CUSTOMER, customerListSaga);
  yield takeEvery(CREATE_CUSTOMER, createCustomerSaga);
  yield takeEvery(GET_CUSTOMER, getCustomerSaga);
  yield takeEvery(GET_LEDGER_LIST, getLedgerListSaga);
  yield takeEvery(GET_INVOICES_LIST, getInvoiceListSaga);
  yield takeEvery(DELETE_LEDGER, deleteLedgerSaga);
  yield takeEvery(CREATE_LOGIPE_INVOICE, createLogipeInvoiceSaga);
  yield takeEvery(CREATE_EXTERNAL_INVOICE, createExternalInvoiceSaga);
  yield takeEvery(DOWNLOAD_INVOICE, downloadInvoiceSaga);
  yield takeEvery(GET_INVOICE, getInvoiceSaga);
  yield takeEvery(EDIT_LOGIPE_INVOICE, editLogipeInvoiceSaga);
  yield takeEvery(EDIT_EXTERNAL_INVOICE, editExternalInvoiceSaga);
  yield takeEvery(UPLOAD_RECIPT, uploadReciptSaga);
  yield takeEvery(ADD_PAYMENT_RECIVED, addPaymentRecivedSaga);
  yield takeEvery(DOWNLOAD_LEDGER, downloadLedgerSaga);
  yield takeEvery(GET_ALL_TRIPS, getAllTripsSaga);
  yield takeEvery(EDIT_CUSTOMER, editCustomerSaga);
  yield takeEvery(COLLECT_PAYMENT, collectPaymentSaga);
  yield takeEvery(ADD_VENDOR_PAYMENT, addVendorPaymentSaga);
  //
  yield takeEvery(ADD_NEW_PHONE_FW, addNewPhoneFWSaga);
}
