import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../../Icons/Icons";
import { addNicknameAction } from "../../../../../services/Store/Actions/driverslistActions";
import { useParams } from "react-router-dom";
import { toastStatus } from "../../../../../services/Store/Actions/appActions";


const ChangeNickNameModal=(props)=>{
  const { open, toggleModal, handleClose, driverName, phone,nickname } = props;

  const dispatch = useDispatch();
  const {id}= useParams();
  const { loader } = useSelector((state) => state?.omc);
  //console.log("button status", loader);

  // const validationSchema = Yup.object().shape({
  //   // nickName: Yup.number()
  //   //   .typeError("Only Numbers are allowed")
  //   //   .min(1, "Amount should be greater than 1")
  //   //   .required("Amount is required"),
  //   nickName: Yup.string()
  //     .required("Nick name is required")
  //   //   .test(
  //   //     "noDefaultValue",
  //   //     "Please select expense type",
  //   //     (val) => val != "default"
  //   //   ),
  //   // dob: Yup.string().required("Expense Date is required"),
  //   // remarks: Yup.string().required("Remark is required"),
  // });

  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Driver’s Details</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody style={{position:"relative"}}>
            <Formik
              enableReinitialize
              initialValues={{
                phone: phone,
                nickName: nickname!=="NA"?nickname:"",
              }}
              // validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                dispatch(addNicknameAction({
                  userId:id,
                  nickName:values.nickName,
                  phone : values.phone,
                },(res)=>{
                  if(res?.status==200){
                    toggleModal();
                    
                  }
                }))

              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit} >
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">1</span>
                      <label className="sub-head-3 ">Full Name</label>
                    </div>
                    {/* <input
                      type="text"
                      id="remarks"
                      className={
                        "input-body-3 form-control" +
                        (errors.amount && touched.amount ? " is-invalid" : "")
                      }
                      // style={{ marginLeft: "94px" }}
                      name="remarks"
                      value={values.remarks}
                      touched={touched.remarks}
                      errors={errors.remarks}
                      onBlur={handleBlur("remarks")}
                      onChange={handleChange("remarks")}
                      placeholder="Enter your remarks"
                    ></input>
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.remarks && touched.remarks ? (
                        <div>*{errors.remarks}</div>
                      ) : null}
                    </div> */}
                    <div className="input-body-3">{driverName}</div>
                    <div className="input-amount-for-link"></div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">2</span>
                      <label className="sub-head-3 ">Phone Number</label>
                    </div>
                    <input
                      type="text"
                      id="remarks"
                      className={
                        "input-body-3 form-control" +
                        (errors.amount && touched.amount ? " is-invalid" : "")
                      }
                      // style={{ marginLeft: "94px" }}
                      name="phone"
                      value={values.phone}
                      touched={touched.phone}
                      errors={errors.phone}
                      onBlur={handleBlur("phone")}
                      onChange={handleChange("phone")}
                      placeholder="Enter your phone"
                    ></input>
                    {/* <div className="input-amount-for-link"></div> */}
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.phone && touched.phone ? (
                        <div>*{errors.phone}</div>
                      ) : null}
                    </div>
                    {/* <div className="input-body-3">{phone}</div> */}
                    <div className="input-amount-for-link"></div>
                  </FormGroup>
                  <FormGroup>
                    <div className="sub-title-1">
                      <span class="circle-logo">3</span>
                      <label className="sub-head-3 ">Nick Name</label>
                    </div>
                    <input
                      type="text"
                      id="nickName"
                      className="input-body-3 form-control"
                      // style={{ marginLeft: "94px" }}
                      name="nickName"
                      value={values.nickName}
                      touched={touched.nickName}
                      errors={errors.nickName}
                      onBlur={handleBlur("nickName")}
                      onChange={handleChange("nickName")}
                      placeholder="Type your drivers nick name"
                    ></input>
                    <div className="input-amount-for-link"></div>
                    <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                      {errors.nickName && touched.nickName ? (
                        <div>*{errors.nickName}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup style={{position:"absolute", bottom:"1rem"}}>
                    <button
                      type="submit"
                      className="butt-4"
                      style={{
                        margin: "15% 0 0 0",
                        background: loader ? "grey" : "#485B7C",
                      }}
                      disabled={loader ? true : false}
                    >
                      {loader ? "Please Wait" : "Save"}
                    </button>
                  </FormGroup>
                  <div></div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default ChangeNickNameModal;
