import React, { useState, useEffect } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import PinInput from "react-pin-input";
import SvgImage from "../../Icons/Icons";
import { Formik } from "formik";
import { transferWalletTransferAmount } from "../../../services/Store/Actions/cardWalletAction";
import { transferFastagTransferAmount } from "../../../services/Store/Actions/fastagAction";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import * as Yup from "yup";
import { getBeneficiary } from "../../../services/Store/Actions/beneficiaryAction";
import DirectBeneficiary from "../directBeneficiaryModal";
import AddNewAccount from "../../Modals/addNewAccount";
import ShowingBeneficiaries from "./ShowingBeneficiaries";
import DirectTransfer from "./DirectTransfer";
import SelectedTransferAmount from "./selectedTransferAmount";
// import Toast from "../../subComponents/toast";
import {
  sendAnalitics,
  toastStatus,
} from "../../../services/Store/Actions/appActions";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError("Only Numbers are allowed")
    .required("Required")
    .min(1, "Amount should be greater than 1"),
  pin: Yup.string()
    .required("Required")
    .matches(/^[0-9]{4}$/, "Pin Must be exactly 4 digits"),
});

function LogipeAccountButtons(props) {
  const {
    open,
    toggleModal,
    title,
    handleClick,
    handleClose,
    btn,
    showList,
    setShowList,
    setDisplayScreen,
    displayScreen,
    proceed1,
    setProceed1,
    newProceed,
    setNewProceed,
    setEnterButton,
    enterButton,
  } = props;
  const { loader } = useSelector((state) => state?.fastag);
  const [rcolor, setRColor] = useState(-1);
  const [beneficiaryName, setBeneficiaryName] = useState("--");
  const [selectedBeneficiaryDetails, setSelectedBeneficiaryDetails] =
    useState();
  const [addBeneficiary, setAddBeneficiary] = useState(false);
  const [formProceed, setFormProceed] = useState(0);
  const toastObject = useSelector((state) => state?.app?.toastObject);
  console.log("toastobject is here", toastObject);

  function handleProceed(i) {
    setFormProceed(i);
  }

  useEffect(() => {
    setFormProceed(0);
  }, []);

  //console.log("thik hi hoga",title);
  const dispatch = useDispatch();

  const [errorPin, setErrorPin] = useState("");

  const handleClickOnPin = () => {
    //console.log("I'm clicked");
    dispatch(sendAnalitics("Pin_Trns_FT_MA_Web"));
    setErrorPin("");
  };

  //const [proceed, setProceed] = useState(0);
  // function handleProceed(val) {
  //   setProceed1(val);
  // }

  const [showBeneficiary, setShowBeneficiary] = useState(false);

  const handleShowBeneficiary = (opt) => {
    //setShowBeneficiary(true);
    //toggleModal();
    setShowList(1);
    setDisplayScreen(1);
  };
  function handleCloseBeneficiary() {
    setShowBeneficiary(false);
  }
  // function toggleModalBeneficiary() {
  //   setShowBeneficiary(!showBeneficiary);
  // }

  const [showDirectBeneficiary, setShowDirectBeneficiary] = useState(false);
  function handleShowDirectBeneficiary() {
    //setShowDirectBeneficiary(true);
    setDisplayScreen(3);
    setEnterButton(0);
    //handleClose();
  }
  function handleCloseDirectBeneficiary() {
    setShowDirectBeneficiary(false);
  }
  function toggleModalDirectBeneficiary() {
    setShowDirectBeneficiary(!showDirectBeneficiary);
  }

  const { beneficiaryDetails } = useSelector((state) => state?.beneficiary);

  const { ownerBalance } = useSelector((state) => state?.manageCard);
  const LogipeAccounBal =
    ownerBalance?.instrument_balance?.balance == null
      ? 0
      : ownerBalance?.instrument_balance?.balance;
  //console.log("acc",beneficiaryDetails)

  useEffect(() => {
    //alert("inside");
    dispatch(getBeneficiary());
    // setShowList(0);
    // setDisplayScreen(0);
  }, []);

  const handleNewProceed = (val, name) => {
    //setNewProceed (val);
    //alert("inside the handle proceed")
    setEnterButton(1);
    setBeneficiaryName(name);
  };

  //const []

  // const handleBenificiaryButton =()=>{
  //   setShowList(1);
  // }

  const handleBeneficiaryClick = (data, i) => {
    setRColor(i);
    setSelectedBeneficiaryDetails(data);
    //console.log ("Diksha choti bachhi hain",rcolor);
  };

  const [respStatus, setRespStatus] = useState(false);
  // const notify = (msg)=>{
  //    if (respStatus)
  //     return toast.success(msg, {autoClose:false});
  //   else
  //     return toast.error(msg, {autoClose:false});
  // }

  // const notify = (msg) => toast(msg, { autoClose: false });

  const { fastagWallet } = useSelector((state) => state?.fastag);
  const fastagWalletAmount =
    fastagWallet?.balance == null ? 0 : fastagWallet?.balance;
  
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">
              {btn === 1 || btn === 0
                ? "FASTag Wallet- Add Balance"
                : "Bank Account- Add Balance"}
            </p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                if (btn === 1 || btn === 0) {
                  dispatch(sendAnalitics("Cut_Trns_FT_MA_Web"));
                } else {
                  dispatch(sendAnalitics("Cut_Trns_BA_MA_Web"));
                }
                setBeneficiaryName("--");
                if (btn === 1 || btn === 0) {
                  dispatch(sendAnalitics("Cut_Trns_FT_MA_Web"));
                } else {
                  dispatch(sendAnalitics("Cut_Trns_BA_MA_Web"));
                }
                toggleModal();
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <div className="card-wallet-main-body">
              <div
                className="card-wallet-body-new row greenModalBackground"
                style={{ paddingLeft: "10px", paddingTop: "20px" }}
              >
                <div classname=" col-6" style={{ width: "30%" }}>
                  <SvgImage name="modalBank" />
                </div>
                <div
                  className="col-5"
                  style={{
                    position: "relative",
                    left: "-15px",
                    width: "160px",
                  }}
                >
                  <p className="card-wallet-sub-body-new">Master Account</p>
                  <p className="card-wallet-sub-body2">
                    {"₹ " + LogipeAccounBal.toFixed(2)}
                  </p>
                </div>
              </div>

              <div
                className={"card-wallet-body2 row orangeModalBAckground"}
                style={{ paddingLeft: "15px", paddingTop: "20px" }}
              >
                <div classname=" col-4" style={{ width: "30%" }}>
                  <SvgImage
                    name={
                      btn === 1 || btn === 0 ? "fastagTransfer" : "bankTransfer"
                    }
                    classname="bank-card-wallet"
                  />
                </div>
                <div
                  className="col-5"
                  style={{
                    position: "relative",
                    left: "-15px",
                    width: "9.4rem",
                  }}
                >
                  <p className="card-wallet-sub-body-new">
                    {btn === 1 || btn === 0 ? "FASTag Wallet" : "Beneficiary"}
                  </p>
                  <p
                    className="card-wallet-sub-body2"
                    style={{
                      fontSize:
                        btn === 1 || btn === 0
                          ? "20px"
                          : beneficiaryName.length <= 10
                          ? "20px"
                          : beneficiaryName.length <= 15
                          ? "16px"
                          : "14px",
                      fontWeight:
                        btn === 1 || btn === 0
                          ? "400"
                          : beneficiaryName.length > 10
                          ? "600"
                          : "400",
                    }}
                  >
                    {btn === 1 || btn === 0
                      ? "₹ " + fastagWalletAmount
                      : beneficiaryName}
                  </p>
                </div>
              </div>
              <span class="circle-logo-forward-arrow" style={{ left: "246px" }}>
                <SvgImage name="ForwardArrow" height="36" width="36" />
              </span>
            </div>
            {btn === 1 || btn === 0 ? (
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={{
                  amount: "",
                  pin: "",
                  remark:"",
                }}
                onSubmit={(values, actions) => {
                  console.log("values onSubmit", values);
                  if (title === "card wallet") {
                    console.log("");
                    dispatch(
                      transferWalletTransferAmount(values, (res) => {
                        //console.log("transfer wallet call back", res?.status);
                        actions.setSubmitting(false);
                        if (res?.data?.type === "success") {
                          handleClose();

                          console.log(" success ho gaya !!!");
                        } else if (res?.status === 400) {
                          console.log("error aaya");
                        } else {
                          handleClose();
                        }
                      })
                    );
                  } else {
                    dispatch(sendAnalitics("Trns_FT_MA_API_Web",{
                      amount: values.amount,
                      remarks: values.remark,
                    }));
                    dispatch(
                      transferFastagTransferAmount(values, (res) => {
                        console.log("transfer fastag call back", res?.status);
                        actions.setSubmitting(false);
                        if (res?.data?.type === "success") {
                          dispatch(sendAnalitics("Success_Trns_FT_MA_Web",{
                      amount: values.amount,
                      remarks: values.remark,
                    }));
                          dispatch(
                            toastStatus({
                              toastStatus: true,
                              message:
                                "Money added succesfully to Fastag Wallet",
                              status: true,
                            })
                          );
                          handleClose();
                          // notify("Money added succesfully to Fastag Wallet");

                          //console.log("fastag transfer success ho gaya !!!");
                        } else if (res?.status === 400) {
                          dispatch(
                            toastStatus({
                              toastStatus: true,
                              message: "Please Enter Correct Pin",
                              status: false,
                            })
                          );
                          handleClose();
                          //setErrorPin("*Enter Correct Pin");
                          console.log("error aaya");
                        } else {
                          handleClose();
                          dispatch(
                            toastStatus({
                              toastStatus: true,
                              message:
                                "Sorry, the Transaction was unsuccessful",
                              status: false,
                            })
                          );
                          // notify("Sorry, the Transaction was unsuccessful");
                        }
                      })
                    );
                  }
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">1</span>
                        <p className="sub-head-3">Enter amount</p>
                      </div>
                      <p className="sub-head-body">
                        Amount will be deducted from owner’s LogiPe account
                      </p>
                      <label for="html" className="label-body">
                        ₹
                      </label>
                      <input
                        type="text"
                        id="amount"
                        className="input-body-1"
                        name="amount"
                        onChange={handleChange("amount")}
                        value={values.amount}
                        onBlur={()=>{
                          dispatch(sendAnalitics("Amt_Trns_FT_MA_Web",{
                            Amt_Trns_MA_to_FTwallet:values.amount,
                          }));
                          handleBlur("amount");
                        }}
                        touched={touched.amount}
                        errors={errors.amount}
                        placeholder="Enter amount"
                      ></input>
                      <div className="input-amount"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.amount && touched.amount ? (
                          <div>*{errors.amount}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="sub-title-1">
                        <span class="circle-logo">2</span>
                        <p className="sub-head-3">Remarks</p>
                      </div>
                      <label for="html" className="label-body">
                      </label>
                      <input
                        type="text"
                        id="remark"
                        className="input-body"
                        name="remark"
                        onChange={handleChange("remark")}
                        value={values.remark}
                        onBlur={()=>{
                          handleBlur("remark");
                        }}
                        touched={touched.remark}
                        errors={errors.amoremarkunt}
                        placeholder="Enter Remark"
                      ></input>
                      <div className="input-amount"></div>
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.remark && touched.remark ? (
                          <div>*{errors.remark}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup onClick={()=>{
                          handleClickOnPin(values.pin);
                        }}>
                      <div className="sub-title-1">
                        <span class="circle-logo">3</span>
                        <p className="sub-head-4">Enter 4-digit Security Pin</p>
                      </div>
                      <p className="sub-head-body">
                        Security pin is needed to confirm payment
                      </p>
                      <PinInput
                        length={4}
                        initialValue=""
                        secret
                        onChange={handleChange("pin")}
                        values={values.pin}
                        type="numeric"
                        inputMode="number"
                        style={{
                          padding: "10px",
                          marginLeft: "80px",
                          marginTop: "28px",
                        }}
                        inputStyle={{
                          border: "1px solid #A7A7A7",
                          borderRadius: "8px",
                          marginRight: "20px",
                        }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        onClick={()=>{
                          handleClickOnPin(values.pin);
                        }}
                      />
                      <div style={{ margin: "7px 0 0px 96px", color: "red" }}>
                        {errors.pin && touched.pin ? (
                          <div>*{errors.pin}</div>
                        ) : null}
                        {errorPin}
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="butt4"
                          disabled={loader ? true : false}
                          onClick={()=>{
                            dispatch(sendAnalitics("Recharge_FTW_Trns_FT_MA_Web", {
                              Amt:values.amount,
                              Pin:values.pin,
                              Remark:values.remark
                            })) 
                          }}
                        >
                          {loader ? "Please Wait" : "Recharge FASTag Wallet"}
                        </button>
                      </div>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            ) : displayScreen === 0 ? (
              <>
                <p className="sub-head-3">Transfer Amount To :</p>
                <Card
                  className="beneficiary-card"
                  style={{ marginTop: "2rem", marginLeft: "-2.5rem" }}
                >
                  <CardBody>
                    <div
                      className="bottom-card-title row "
                      onClick={handleShowBeneficiary}
                    >
                      <div className="col-2">
                        <div className="rectangle-for-logo">
                          <SvgImage name="PeopleLogo" classname="people-Logo" />
                        </div>
                      </div>
                      <div className="col-9">Add Beneficiary</div>
                      <div className="col-1">
                        <SvgImage name="ForwardLogo" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="beneficiary-card"
                  style={{ marginTop: "22px", marginLeft: "-2.5rem" }}
                >
                  <CardBody>
                    <div
                      className="bottom-card-title row"
                      onClick={handleShowDirectBeneficiary}
                    >
                      <div className="col-2">
                        <div className="rectangle-for-logo">
                          <SvgImage name="PeopleLogo" classname="people-Logo" />
                        </div>
                      </div>
                      <div className="col-9">
                        Direct Transfer to Beneficiary
                      </div>
                      <div className="col-1">
                        <SvgImage name="ForwardLogo" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            ) : displayScreen === 1 ? (
              <ShowingBeneficiaries
                title={title}
                open={showBeneficiary}
                toggleModal={handleClose}
                handleClose={handleCloseBeneficiary}
                handleClick={handleClick}
                btn={btn}
                ownerLogipeBalance={LogipeAccounBal}
                setShowList={setShowList}
                setDisplayScreen={setDisplayScreen}
                rcolor={rcolor}
                setRColor={setRColor}
                selectedBeneficiaryDetails={selectedBeneficiaryDetails}
                setSelectedBeneficiaryDetails={setSelectedBeneficiaryDetails}
                addBeneficiary={addBeneficiary}
                setAddBeneficiary={setAddBeneficiary}
                formProceed={formProceed}
                setFormProceed={setFormProceed}
                setBeneficiaryName={setBeneficiaryName}
              />
            ) : displayScreen === 2 ? (
              <SelectedTransferAmount
                title={title}
                open={showBeneficiary}
                toggleModal={handleClose}
                handleClose={handleCloseBeneficiary}
                handleClick={handleClick}
                btn={btn}
                ownerLogipeBalance={LogipeAccounBal}
                beneficiaryDetails={beneficiaryDetails}
                rcolor={rcolor}
                setRColor={setRColor}
                selectedBeneficiaryDetails={selectedBeneficiaryDetails}
                setSelectedBeneficiaryDetails={setSelectedBeneficiaryDetails}
                addBeneficiary={addBeneficiary}
                setAddBeneficiary={setAddBeneficiary}
                formProceed={formProceed}
                setFormProceed={setFormProceed}
              />
            ) : displayScreen === 3 ? (
              <DirectTransfer
                title={title}
                open={showDirectBeneficiary}
                toggleModal={handleClose}
                handleClose={handleCloseDirectBeneficiary}
                handleClick={handleClick}
                newProceed={newProceed}
                setNewProceed={setNewProceed}
                handleNewProceed={handleNewProceed}
                btn={btn}
                ownerLogipeBalance={LogipeAccounBal}
                enterButton={enterButton}
                setEnterButton={setEnterButton}
              />
            ) : (
              <></>
            )}
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default LogipeAccountButtons;
