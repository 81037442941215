import React, { useEffect, useState } from "react";
import "./index.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";

import { useNavigate } from "react-router-dom";
import SvgImage from "../../Icons/Icons";
import { handleLoginSuccess, resetPasswordAction } from "../../../services/Store/Actions/LoginAction";
import { storeDataToAsyncStorage } from "../../../services/Utils/functions";
import { toastStatus } from "../../../services/Store/Actions/appActions";

const ResetPasswordModal = (props) => {

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password must have exactly 8 characters")
      .matches(
        /^[A-Za-z0-9]+$/,
        "Password must contain one number and one alphabet"
      )
      .test(
        "passwordLen",
        "Password must have exactly 8 characters",
        (val) => val.length == 8
      ),
    confirmPassword: Yup.string()
      .required("Password must have exactly 8 characters")
      .oneOf([Yup.ref("password"), null], "Does not match the above password")
      .test(
        "passwordLen",
        "Password must have exactly 8 characters",
        (val) => val.length >= 8
      ),
  });

  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state?.loginReducer);
  const { open, handleClose, toggleModal } = props;

  const Navigate = useNavigate();
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon sideModal settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{
            width: "500px",
            backgroundColor: "white",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Reset Password</p>
            <Button
              type="button"
              className="close-btn"
              onClick={handleClose}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              onSubmit={(values, actions) => {
                console.log("val- reset", values);
                dispatch(
                  resetPasswordAction(
                    {
                      password: values?.password,
                    },
                    (res) => {
                      console.log("res reset", res);
                    //   dispatch(handleLoginSuccess(props?.validationData));
                    //   Navigate("/home");
                    //   storeDataToAsyncStorage("_user", props?.validationData);
                      dispatch(toastStatus({
                              toastStatus: true,
                              message:"Password has been reset successfully",
                              status: true,
                            }));
                    }
                  )
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* <FormGroup className="formPosition">
                <div className="Regular text3 greyFont">Welcome! 👋</div>
                <div className="heading1 semiBold">Sign in to resent password</div>
            </FormGroup> */}
                  <FormGroup className="formPosition">
                    <div className="Regular text3 padding24T headText2">
                      Enter new password
                    </div>
                    <div
                      className="padding16 padding0L"
                      style={{ paddingLeft: "0rem" }}
                    >
                      <div className=" phoneNumberBox padding8">
                        <input
                          type="text"
                          id="password"
                          className="input-body-4 Regular text3"
                          name="password"
                          value={values.password}
                          touched={touched.password}
                          errors={errors.password}
                          onBlur={() => {
                            //dispatch(sendAnalitics("EnterPhone_web", values));
                            handleBlur("password");
                          }}
                          onChange={handleChange("password")}
                          style={{
                            textAlign: "left",
                            marginLeft: "0px",
                            width: "210px",
                          }}
                          placeholder="Enter new password"
                          // minLength="8"
                          autofocus
                        ></input>
                      </div>
                    </div>
                    <div
                      className="text4"
                      style={{
                        // margin: "7px 0 0px 50px",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {errors.password && touched.password ? (
                        <div>{errors.password}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup className="formPosition">
                    <div className="Regular text3 headText2">
                      Confirm new password
                    </div>
                    <div
                      className="padding16 padding0L"
                      style={{ paddingLeft: "0rem" }}
                    >
                      <div className=" phoneNumberBox padding8">
                        <input
                          type="text"
                          id="confirmPassword"
                          className="input-body-4 Regular text3"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          touched={touched.confirmPassword}
                          errors={errors.confirmPassword}
                          onBlur={() => {
                            //dispatch(sendAnalitics("EnterPhone_web", values));
                            handleBlur("confirmPassword");
                          }}
                          onChange={handleChange("confirmPassword")}
                          style={{
                            textAlign: "left",
                            marginLeft: "0px",
                            width: "210px",
                          }}
                          placeholder="Confirm new password"
                          // minLength="8"
                          autofocus
                        ></input>
                      </div>
                    </div>
                    <div
                      className="text4"
                      style={{
                        // margin: "7px 0 0px 50px",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <div>{errors.confirmPassword}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup className="formPosition">
                    <Button
                      type="submit"
                      className="loginButton semiBold text2"
                      style={{
                        marginTop: "1rem",
                        background:
                          // values.password.length < 8
                          //   ? "#EFEFEF":
                          loader ? "#EFEFEF" : "#485B7C",
                        color:
                          // values.password.length < 8
                          //   ? "#B5B5B5":
                          loader ? "#B5B5B5" : "#ffffff",
                      }}
                      disabled={loader ? true : false}
                    >
                      {loader ? "Please Wait" : "Reset Password"}
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default ResetPasswordModal;
