import React, { useEffect, useState } from "react";
import Switch from 'react-switch'
import "./index.css";
import { ToggleSlider } from "react-toggle-slider";
import { useDispatch, useSelector } from "react-redux";
import { getCardDetails, changeCardStatus,getCardControl } from "../../services/Store/Actions/driverslistActions";
const LogipeCard2 = (props) => {
  const dispatch = useDispatch();
  const cardDetails = useSelector((state) => state);
  const {userId, driverCardStatus, driverDetails}= props;
  console.log("Card Details here *****", cardDetails);
  const cardName = driverDetails.name == null ? "NA" : driverDetails.name;
  const cvv = props.cvv == null ? "XXX" : props.cvv;

  const expiryDate =
    cardDetails?.driverlist?.cardDetails?.details?.expiryDate == null
      ? "NA"
      : cardDetails?.driverlist?.cardDetails?.details?.expiryDate;

  const cardNumber =
    cardDetails?.driverlist?.cardDetails?.details?.cardNumber == null
      ? "NA"
      : cardDetails?.driverlist?.cardDetails?.details?.cardNumber;
  const correctCard=cardNumber==="NA"?"NA":cardNumber.substring(0,4)+" "+
  cardNumber.substring(4,8)+" "+cardNumber.substring(8,12)+" "+cardNumber.substring(12,16);
  const status = driverCardStatus===4||7?true:false;
  useEffect(() => {
    dispatch(getCardDetails(userId));
  }, [props.userId,status]);

  //const [dCardStatus, setDCardStatus]= useState(cardDetails?.driverlist?.cardControl?.card?.isActive);
  //const cardStatus_driver= props.driverCardStatus === "NULL"? "NOT ACTIVE": props.driverCardStatus > 3? "ACTIVE": "NOT ACTIVE";
  //const [dCardStatus, setDCardStatus] = useState(cardStatus_driver);

  function handleChange(){
    //setDCardStatus(dCardStatus==="ACTIVE"?"NOT ACTIVE":"ACTIVE");  

    // dispatch(editCardControl({
    //         userId: props.userId,
    //         PIN: "1111",
    //         limitAmount: item.Remarks,
    //         expenseType: item.Name,
    //         isActive: !(item.status)
    //       }))

        console.log("before submit", cardDetails?.driverlist?.cardControl?.card?.isActive);
        dispatch(changeCardStatus(props.userId, (res) => {
          // actions.setSubmitting(false);
        //  console.log("Change Card Status",res?.type)
          if (res?.type === "success") {
            // console.log("successfullll******");

            //navigate("/Customers")
            // toggleModal();
           //props.setActiveStatus(!val)

           dispatch(getCardControl(props.userId));
           console.log("After submit", cardDetails?.driverlist?.cardControl?.card?.isActive);
          }
        }))
            
    }


//dCardStatus==="Active"?
//style={{opacity:activeStatus!==4?"0.5":"1"}}
  return (
    <div className="LogipeCard2Box" style={{opacity:status===true?"1":"0.5"}}>
      <div className="LogipeCard2Name">{cardName}</div>
      {/* <div className="LogipeCard2Active cardStatusPos">
        {status===true?"ACTIVE":"NOT ACTIVE"}
      </div> */}
      {/* <div className="LogipeCard2Slider">
      <Switch
           // disabled={active?false:true}
            checked={status}
            onChange={()=>handleChange(status)}
            onColor="#EBF2F2"
            onHandleColor="#2E7C7B"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            activeBoxShadow={""}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            height={15}
            width={40}
            className="react-switch"
            id="material-switch"
          />      </div> */}
      <div className="LogipeCard2Header">DRIVER CARD</div>
      <div className="LogipeCard2CardNumber">{correctCard}</div>
      <div className="LogipeCard2ExpiryDate">Expiry Date</div>
      <div className="LogipeCard2CVV">CVV</div>
      <div className="LogipeCard2Date">{expiryDate}</div>
      <div className="LogipeCard2CVVN">{cvv}</div>
    </div>
  );
};
export default LogipeCard2;
