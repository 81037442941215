import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import ManageLogipeCardMidSection from "../SubComponents/LogipeCardMiddleCard";
import ManageLogipeCardBottom from "../SubComponents/LogipeCardTransactions";
import ManageLogipeCardRight from "../SubComponents/LogipeCardRightTab";
import ManageLogipeCardType1 from "../SubComponents/LogipeCardTopBalanceCard/index";
import {
  getCardDetails,
  getBalanceDetails,
  cleanWalletSummary,
  getWalletSummary,
} from "../../../services/Store/Actions/LogipeCardActions";
import { useDispatch, useSelector } from "react-redux/es/exports";
import Toast from "../../subComponents/toast";
import { getCardControl } from "../../../services/Utils/Apis";
import {
  getOwnerCardDetails,
  ownerCardAction,
} from "../../../services/Store/Actions/manageCardActions";
import OwnerTransactionHistoryTable from "../SubComponents/OwnerTransactionHistoryTable";
import SvgImage from "../../Icons/Icons";
import AddingFilter from "../../Modals/addingFilter";
import {
  cleanExpenseWalletSummary,
  getDriverListStatement,
  getExpenseWalletSummary,
} from "../../../services/Store/Actions/driverslistActions";
import moment from "moment";
import { toastStatus } from "../../../services/Store/Actions/appActions";

function FullManageLogipeCard(props) {
  const {sideBar} = props
  const dispatch = useDispatch();
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const [disabled, setDisabled] = useState(false);
  const allStar = useSelector((state) => state);
  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userId = obj?.user?.userId;
  console.log("user id", userId);
  //const userId="11c40995-5ada-4e0d-8dfa-0cdb40187901";
  //const userId = allStar?.loginReducer?.authUser?.user?.userId;
  const [activeStatus, setActiveStatus] = useState(false);
  const fullName = obj?.user?.fullName;
  const { driverStatementList } = useSelector((state) => state?.driverlist);
  const { walletSummary, walletSummaryList } = useSelector(
    (state) => state?.LogipeCard
  );
  const History = walletSummary?.rows;
  console.log("card history", History);
  // for the filter and download:

  const [offset, setOffset] = useState(0);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [filter, setFilter] = useState(0);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const closing1 = () => {
    setActive1(false);
  };

  const toggling1 = () => {
    setActive1(!active1);
  };

  const showing1 = () => {
    setActive1(true);
  };

  const closing2 = () => {
    setActive2(false);
  };

  const toggling2 = () => {
    setActive2(!active2);
  };

  const showing2 = () => {
    setActive2(true);
  };

  const handleOlderExpense = async () => {
    setOffset(offset + 10);
    dispatch(
      getWalletSummary(userId, {
        offset: offset + 10,
        fromDate: moment(start).format("YYYY-MM-DD"),
        toDate: moment(end).format("YYYY-MM-DD"),
        sortBy: "",
        sortAs: "",
      })
    );
  };

  //const fullName="sanayama"
  useEffect(() => {
    //dispatch(getBalanceDetails(userId));
    dispatch(cleanWalletSummary());
    dispatch(
      getWalletSummary(userId, {
        offset: 0,
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
    console.log("heyyyyyyyyyyyyyyyyy");
    dispatch(ownerCardAction());
    //dispatch(getCardDetails(userId));
    //dispatch(getOwnerCardDetails(userId));

    //console.log("1st re-rendering");
    // dispatch(getWalletSummary (userId, {
    //   offset: 0 ,
    //   search: '',
    //   minKyc: '',
    //   sortAs: '',
    //   sortBy: '',
    // }))
  }, []);
  //const cardData = allStar?.LogipeCard?.cardDetails;
  // console.log("USERID", allStar);
  // const balance = allStar?.LogipeCard?.balanceDetails?.walletBalance;
  // const monthlySpend = allStar?.LogipeCard?.balanceDetails?.monthlySpend;

  // console.log("balance", allStar);
  // console.log("monthly Spend", balance);
  // console.log("getCardContorls", allStar.LogipeCard);
  const disabledStyle = { pointerEvents: "none", opacity: "0.5" };

  // const userr = sessionStorage.getItem("_user");
  // console.log("ggggg",userr);

  // const walletBalRes = useSelector(
  //   //CARD WALLET BALANCE and History
  //   (state) => state?.masterWallet?.balance
  // );

  //console.log("wallet wala bal", response);
  const filterFunc = (startDate, endDate) => {
    setOffset(0);
    setFilter(1);
    setStart(startDate);
    setEnd(endDate);
    console.log("start date", startDate);
    console.log("end date", endDate);
    dispatch(cleanWalletSummary());
    dispatch(
      getWalletSummary(userId, {
        offset: 0,
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
        sortBy: "",
        sortAs: "",
      })
    );
  };

  const downloadFunc = (startDate, endDate, mode) => {
    console.log("start date", startDate);
    console.log("end date", endDate);
    dispatch(
      getDriverListStatement(
        userId,
        {
          from: moment(startDate).format("YYYY-MM-DD"),
          to: moment(endDate).format("YYYY-MM-DD"),
          mode: mode,
        },
        (res) => {
          if (res?.data?.type === "success") {
            console.log("rrrrrrrrrrrrrrrr", res);
            window.open(res?.data?.data?.url);
            // window.location.replace(res?.data?.data?.link);
            //window.open();
            //window.location.reload();
          }
        }
      )
    );
    //window.open(driverStatementList?.url);
  };

  const disableFilter = () => {
    setOffset(0);
    setFilter(0);
    setStart("");
    setEnd("");
    dispatch(cleanWalletSummary());
    dispatch(
      getWalletSummary(userId, {
        offset: 0,
        fromDate: "",
        toDate: "",
        sortBy: "",
        sortAs: "",
      })
    );
  };

  const ownerCardType = useSelector(
    (state) => state?.manageCard?.ownerCardType
  );
  const neocredStatus = ownerCardType[0]?.[0]?.accountStatus;
  const m2pStatus = ownerCardType[1]?.[0]?.accountStatus;
  console.log("yyyyyyyyyyyyyyyyyyyyyyyyyy",neocredStatus)
  // const cardStatus = useSelector(
  //   (state) => state?.manageCard?.OwnercardDetails?.accountStatus
  // );
  // console.log(",,,,,,,,,,", cardStatus);
  // const cardAvailable = cardStatus === 4 ? true : false;
  return (
    <div className="main-home topMargin sideBarMargin" style={{marginLeft: sideBar? '230px' : '100px'}}>
      {neocredStatus === "4" || neocredStatus === "7" ? (
        <div className="row ">
          <div className="col-lg-8 col-md-12">
            <div className="topCardContainer row padding16L padding16R">
              <div className="col-6 borderDiv padding32R">
                <ManageLogipeCardType1
                  // bal={balance}
                  text="Current Balance"
                  color="1"
                  logo="nounAmountLogo"
                  userId={userId}
                />
              </div>
              <div className="col-6 borderDiv padding32L">
                <ManageLogipeCardType1
                  // bal={monthlySpend}
                  text="Monthly Spending "
                  logo="lowPriceLogo"
                  userId={userId}
                />
              </div>
            </div>
            <div className="padding32T padding16L padding16R padding32B">
              <ManageLogipeCardMidSection
                //cardDetails={cardData}
                activeStatus={activeStatus}
                setActiveStatus={setActiveStatus}
                fullName={fullName}
                userId={userId}
                // bal={balance}
              />
            </div>
            <div className="padding8T">
              <div className="whiteBackground borderRound">
                  <div className="row padding16  padding0R">
                    <div className="col-1 padding16" style={{ marginLeft: "0" }}>
                      <SvgImage
                        name="logipeCardTransactionLogo"
                        width="43"
                        height="43"
                      />
                    </div>
                    <div
                      className="col-5 Bold heading2 padding16"
                      style={{ marginLeft: "0" }}
                    >
                      LogiPe Card Transactions
                    </div>
                    <div className="col-2 padding0R">
                      <div
                        className="filterText"
                        style={{ paddingRight: "0px", textAlign: "right" }}
                        onClick={showing1}
                      >
                        Filter
                        <div className="filterImg">
                          <SvgImage name="filterIcon" />
                        </div>
                      </div>
                      <AddingFilter
                        active={active1}
                        toggleModal={toggling1}
                        handleClose={closing1}
                        func={filterFunc}
                        name="Filter"
                      />
                    </div>
                    <div className="col-4 padding0L align-self-center">
                      {/* <div
                        className="downloadText "
                        style={{ paddingLeft: "0px" }}
                        onClick={showing2}
                      >
                        <div>Download Statement</div>
                        <div
                          className="downloadImg"
                          style={{ marginLeft: "10px" }}
                        >
                          <SvgImage name="downloadImg" />
                        </div>
                      </div> */}
                  {/* <div className="flexStyling new-row-for-download"> */}
                    <div className="filterDates">
                      <div className="heading3 Bold">Filter Applied: </div>
                      <div className="FontNB" style={{ paddingLeft: "8px" }}>
                        {moment(start).format("DD-MM-YYYY") == "Invalid date"
                          ? "None"
                          : moment(start).format("DD-MM-YYYY") + " to"}{" "}
                        {moment(end).format("DD-MM-YYYY") == "Invalid date"
                          ? ""
                          : moment(end).format("DD-MM-YYYY")}
                      </div>
                      <div
                        style={{
                          paddingLeft: "8px",
                          position: "relative",
                          top: "-3px",
                          cursor: "pointer",
                          visibility:moment(start).format("DD-MM-YYYY") == "Invalid date"?"hidden":"visible"
                        }}
                        onClick={disableFilter}
                      >
                        <SvgImage
                          classname="close-btn-text"
                          // style={{ width: "10%", height: "10%" }}
                          name="CloseButtonLogo1"
                          width="20px"
                          height="20px"
                        />
                      </div>
                    </div>
                  {/* </div> */}
                    <AddingFilter
                      active={active2}
                      toggleModal={toggling2}
                      handleClose={closing2}
                      func={downloadFunc}
                      name="Download History"
                    />
                  </div>
                </div>
                
                <div className="ManageLogipeCardDetailsPos">
                  <OwnerTransactionHistoryTable
                    userId={userId}
                    filter={filter}
                    History={History}
                    offset={offset}
                    start={start}
                    end={end}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 ">
            <ManageLogipeCardRight
              status={activeStatus}
              setStatus={setActiveStatus}
              userId={userId}
            />
          </div>
        </div>
      ) : ( neocredStatus !== undefined || neocredStatus !== "4"  || neocredStatus !== "" || neocredStatus !== "7" )?(
        <div>
          <div className="row ">
            <div className="col-lg-8 col-md-12">
              <div className="topCardContainer row padding16L padding16R">
                <div className="col-6 borderDiv padding32R">
                  <ManageLogipeCardType1
                    // bal={balance}
                    text="Current Balance"
                    color="1"
                    logo="nounAmountLogo"
                    userId={userId}
                  />
                </div>
                <div className="col-6 borderDiv padding32L">
                  <ManageLogipeCardType1
                    // bal={monthlySpend}
                    text="Monthly Spending "
                    logo="lowPriceLogo"
                    userId={userId}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="padding8 padding16T">
            <div className="whiteBackground kycNotDone paddingForCenter" >
              <div style={{padding:"2.5rem"}}>
                <SvgImage name="kycLockLogo" />
              </div>
              <div className="heading2 regular noKycText" style={{padding:"0 2.5rem"}}>
              Complete KYC to issue a 
              </div>
              <div className="heading2 regular noKycText" style={{padding:"0 2.5rem"}}>
              LogiPe prepaid card. Enjoy seamless transactions
              </div>
              <div className="heading2 regular noKycText" style={{padding:"0 2.5rem 2.5rem"}}>
              & cashback benefits.
              </div>
            </div>
          </div>
        </div>
      ):<></>}

      {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
          }}
          isOpen={toastObject?.toastStatus}
          name="logipeCard"
          handleClose={() => {
            // dispatch(
            //   toastStatus({
            //     toastStatus: false,
            //     message: "",
            //     status: true,
            //   })
            // );
            // if (!toastObject?.toastStatus)
            window.location = "/LogipeCard";
            //navigate("/Drivers");
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
export default FullManageLogipeCard;
