import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../Icons/Icons";
import "./index.css";
import { Button, Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import { generateRecieptAction, getAddressDetailsAction, getInTouchAction, getOrderAction } from "../../../../services/Store/Actions/ecomAction";
import PricingBreakup from "./Components/PricingBreakup";
import Payment from "../MyCart/Components/Payment";

function OrderDetails(props) {
  const { handleClose, open, toggleModal, curOrder, openEmi, toggleModalEmi, showEmi, onHideEmi,handleCloseEmi, addressId } = props;
  const addressDetails = useSelector((state) => state?.ecom?.addressDetails);
  const {loader}= useSelector((state)=>state?.ecom);
  const dispatch= useDispatch();
  const coinsVal = useSelector((state) => state?.ecom?.coinsVal?.data);
  let coins = coinsVal == undefined ? 0 : coinsVal == null ? 0 : Math.floor(coinsVal?.fuelCoinsBalance - coinsVal?.redeemedCoins);
  const [btnDeactivated, setBtnDeactivated] = useState(false);
  const [amtVal, setAmtVal]= useState("");
  console.log("addressDetails", addressDetails);
  const cartDetails= useSelector((state)=>state?.ecom?.cartDetails);
  console.log("cartDetails orders", cartDetails);


  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.userId;
  const fullNameSession = obj?.user?.fullName;
  const phoneSession = obj?.user?.phone;



  let orderCartDetails={
    amount:Number(curOrder?.amount),
    discount:Number(curOrder?.discount)
  }

  let addressval=addressDetails!=""?addressDetails?.data?.address1+", "+addressDetails?.data?.city+", "+addressDetails?.data?.state+" India - "+addressDetails?.data?.pincode:"";
  //toast for successful ticket raise
  const customId = "custom-id-raise-ticket page";
  const notify = () => toast.success("Ticket raised successfully for enquiry.",{
    toastId:customId
  });

  const raiseTicket = (orderId, price) => {
    dispatch(getInTouchAction(
      orderId,
      {
        "userId": userIdSession,
        "expectedPrice": String(price),
        "time": String(new Date(Date.now()))
      },
      (res) => {
        if (res?.status == 200) {
          notify();
        }
      }
    ))
  }

   // open payment modal
   const [openPay, setOpenPay]= useState(false);

   const closeModalPay=()=>{
   setOpenPay(false);
   }

   const showModalPay=()=>{
   setOpenPay(true);
   }

   const toggleModalPay=()=>{
   setOpenPay(!openPay);
   }

  const retryPayment = (orderId, amount) => {
    showModalPay();
  }

  const pdfGenrate = (orderId) => {
    dispatch(getOrderAction({
      id: orderId
    }, (res) => {
      if (res?.status == 200) {
        console.log("res", res?.data?.data);
        let FinalAmt = (res?.data?.data?.amount)
        let discount = (res?.data?.data?.discount)
        let items = (res?.data?.data?.products)
        let invoiceDate = (res?.data?.data?.date);
        dispatch(getAddressDetailsAction(res?.data?.data?.addressId, (res) => {
          if (res?.status == 200) {

            let list = []
            console.log("resp--->>>>>", res, items);
            let address = res?.data?.data?.data?.address1 + ", " + res?.data?.data?.data?.city + "- " + res?.data?.data?.data?.pincode + " " + res?.data?.data?.data?.state;
            for (let i = 0; i < items.length; i++) {
              list.push({
                itemName: items[i]?.details,
                rate: (items[i]?.amount + (items[i]?.discount * items[i]?.quantity)) / items[i]?.quantity,
                quatity: items[i]?.quantity,
                discount: items[i]?.discount,
                total: items[i]?.amount,
              })
            }
            console.log("list--->>>>>", list);
            dispatch(generateRecieptAction({
              orderId: orderId,
              name: fullNameSession,
              phone: phoneSession,
              address: address,
              item: list
            }, (resp) => {
              console.log("res->", resp, resp?.data?.data?.url)
              window.open(resp?.data?.data?.url);
            }))
          }
        }));
      }
    }))
  }


  return (
    <div>
      <Modal
        isOpen={open}
        onHide={() => {
          //   setStatus(false);
          handleClose();
        }}
        className="positon settingModalMargin"
        toggle={() => {
          //   setStatus(false);
          toggleModal();
        }}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "510px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Order Detail</p>
            <Button
              type="button"
              className="close-btn"
              onClick={() => {
                // dispatch(sendAnalitics("close_OwnerAcc_Home_web"));
                // setStatus(false);
                toggleModal();
              }}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody className="padding24T padding24L">
            <div
              className="cartDetails2 borderRad7"
              style={{
                height: curOrder?.products.length == 1 ? "920px" : "1050px",
              }}
            >
              <div className="padding16T padding8L greyFont text3">
                Order ID: {curOrder?.orderId}
              </div>
              <hr className="hrPosition" />
              <div
                className="cartItemContainer1 borderRad7"
                style={{
                  height: curOrder?.products.length == 1 ? "22%" : "30%",
                }}
              >
                {curOrder?.products?.map((item, i) => {
                  return (
                    <div className="padding8 padding16L padding16R">
                      <div className="row  cartItem borderRad7" >
                        <div
                          className="col-5 borderRad7"
                          style={{
                            backgroundColor: "#96A9CA",
                            padding: "1rem",
                            height: "10rem",
                          }}
                        >
                          <img
                            src={item?.image}
                            style={{ height: "100%", alignItems: "centre", width:"100%" }}
                          />
                        </div>
                        <div className="col-7 padding16">
                          <div className="padding8B Medium text2">{item?.details}</div>
                          <div className="greyFont text3">
                            Quantity: {item?.quantity}
                          </div>
                          <div className="greyFont text3">
                            Original Price: ₹{" "}
                            {item?.amount + item?.discount * item?.quantity}
                          </div>
                          <div className="greyFont text3">
                            Discount: ₹ {item?.discount * item?.quantity}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <hr className="hrPosition" />
              <div className="row padding24LR">
                <div className="col-lg-3 col-md-4 col-sm-4 col-4 padding0R padding0L">
                  <div className="text2">Order Placed</div>
                  <div className="greyFont text3 ">{curOrder?.date.split(" ")[0]}</div>
                </div>
                <div
                  className="hrPosition1 col-lg-6 col-md-4 col-sm-4 col-4"
                  style={{
                    color:
                      curOrder?.status == 0
                        ? "#F9B906"
                        : curOrder?.status == 1
                        ? "#09BD7B"
                        : curOrder?.status == 2
                        ? "#E63946"
                        : curOrder?.status == 5
                        ? "#09BD7B"
                        : "#E63946",
                  }}
                ></div>
                {/* <hr className="hrPosition1 col-6" style={{color:curOrder?.status==0?"#F9B906":
                    curOrder?.status==1?"#F9B906":
                    curOrder?.status==2?"#E63946":
                    curOrder?.status==5?"#09BD7B":
                    "#E63946"}}/> */}
                {curOrder?.status == 0 ? (
                  <div className="col-lg-3 col-md-4 col-sm-4 col-4 text2 ">
                    Pending
                  </div>
                ) : curOrder?.status == 1 ? (
                  <div className="col-lg-3 col-md-4 col-sm-4 col-4 text2">
                    Payment Done
                  </div>
                ) : curOrder?.status == 2 ? (
                  <div className="col-lg-3 col-md-4 col-sm-4 col-4 text2">
                    Rejected/ Cancelled
                  </div>
                ) : curOrder?.status == 5 ? (
                  <div className="col-lg-3 col-md-4 col-sm-4 col-4 text2 ">
                    Completed
                  </div>
                ) : (
                  <div className="col-lg-3 col-md-4 col-sm-4 col-4 text2 ">
                    Rejected/ Cancelled
                  </div>
                )}
              </div>
              <hr className="hrPosition" />
              <div className="padding32LR-2 padding8T padding8B" style={{width:"70%", margin:"auto"}}>
                <div
                  className="boxBtn padding8T padding8B row"
                  onClick={() => {
                    //handleShow3();
                  }}
                >
                  <div
                    className="col-7 rightAlignment semiBold heading3"
                    onClick={() => {
                      raiseTicket(
                        curOrder?.orderId,
                        (curOrder?.amount -curOrder?.discount -curOrder?.logiCoins)
                      );
                    }}
                  >
                    I need help
                  </div>
                  <div className="col-5">
                    <SvgImage name="supportIcon" />
                  </div>
                </div>
              </div>
              <hr className="hrPosition" />
              <div className="Bold text2 padding16 padding0T">Delivery location</div>
              <div className="padding16L text3">{addressDetails?.data?.address1}</div>
              <div className="padding16L text3">
                {addressDetails?.data?.city +
                  ", " +
                  addressDetails?.data?.state +
                  " - " +
                  addressDetails?.data?.pincode}
              </div>
              <hr className="hrPosition" />
              <div className=" padding16L Bold text2">Price breakup</div>
              <div className="padding16L padding16T" >
                <PricingBreakup
                  status={curOrder?.status}
                  mrp={curOrder?.amount}
                  discount={curOrder?.discount}
                  logiCoins={curOrder?.logiCoins}
                />
              </div>
              {curOrder?.status == 0 ? (
                <div
                  className="padding16T"
                  onClick={() => {
                    // console.log("clicked", curOrder?.orderId,
                    // curOrder?.status==0?
                    //   (curOrder?.logiCoins!=0?
                    //   (Number(coins)>(curOrder?.logiCoins)?Number(mrp)-(curOrder?.logiCoins):Number(mrp)-Number(coins))
                    //   :mrp)
                    //   :Number(mrp)-Number(curOrder?.logiCoins) );
                    setAmtVal(curOrder?.status == 0
                      ? curOrder?.logiCoins != 0
                        ? Number(coins) > curOrder?.logiCoins
                          ? Number(curOrder?.amount) - curOrder?.logiCoins
                          : Number(curOrder?.amount) - Number(coins)
                        : curOrder?.amount
                      : Number(curOrder?.amount) - Number(curOrder?.logiCoins))
                    retryPayment(
                      curOrder?.orderId,
                      curOrder?.status == 0
                        ? curOrder?.logiCoins != 0
                          ? Number(coins) > curOrder?.logiCoins
                            ? Number(curOrder?.amount) - curOrder?.logiCoins
                            : Number(curOrder?.amount) - Number(coins)
                          : curOrder?.amount
                        : Number(curOrder?.amount) - Number(curOrder?.logiCoins)
                    );
                  }}
                >
                  <button
                    className="quoteButtonDark"
                    disabled={btnDeactivated ? true : loader ? true : false}
                    style={{
                      background: loader
                        ? "#EFEFEF"
                        : btnDeactivated
                        ? "#EFEFEF"
                        : "#485B7C",
                    }}
                  >
                    {" "}
                    {loader ? "Please wait" : "Try Payment Again"}
                  </button>
                </div>
              ) : curOrder?.status == 2 ? (
                <div
                  className="padding16T"
                  onClick={() => {
                    setAmtVal(curOrder?.status == 0
                      ? curOrder?.logiCoins != 0
                        ? Number(coins) > curOrder?.logiCoins
                          ? Number(curOrder?.amount) - curOrder?.logiCoins
                          : Number(curOrder?.amount) - Number(coins)
                        : curOrder?.amount
                      : Number(curOrder?.amount) - Number(curOrder?.logiCoins))
                    // console.log("clicked", curOrder?.orderId,
                    // curOrder?.status==0?
                    //   (logiCoins!=0?
                    //   (Number(coins)>(curOrder?.logiCoins)?Number(mrp)-(curOrder?.logiCoins):Number(mrp)-Number(coins))
                    //   :mrp)
                    //   :Number(mrp)-Number(curOrder?.logiCoins) );
                    retryPayment(
                      curOrder?.orderId,
                      curOrder?.status == 0
                        ? curOrder?.logiCoins != 0
                          ? Number(coins) > curOrder?.logiCoins
                            ? Number(curOrder?.amount) - curOrder?.logiCoins
                            : Number(curOrder?.amount) - Number(coins)
                          : curOrder?.amount
                        : Number(curOrder?.amount) - Number(curOrder?.logiCoins)
                    );
                  }}
                >
                  <button
                    className="quoteButtonDark"
                    disabled={btnDeactivated ? true : loader ? true : false}
                    style={{
                      background: loader
                        ? "#EFEFEF"
                        : btnDeactivated
                        ? "#EFEFEF"
                        : "#485B7C",
                    }}
                  >
                    {loader ? "Please wait" : "Try Payment Again"}
                  </button>
                </div>
              ) : (
                <></>
              )}

              {curOrder?.status == 5 || curOrder?.status == 1 ? (
                <div style={{ paddingTop: "1rem" }}>
                  <button
                    className="quoteButtonDark"
                    onClick={() => {
                      pdfGenrate(curOrder?.orderId);
                    }}
                  >
                    Download Reciept
                  </button>
                </div>
              ) : (
                <></>
              )}

              
            </div>
          </ModalBody>
        </div>
      </Modal>
      <Payment 
          open={openPay}
          onHide={closeModalPay}
          toggleModal={toggleModalPay}
          handleClose={closeModalPay}
          finalAmt={amtVal}
          cartDetails={orderCartDetails}
          coinVal={curOrder?.status == 0
            ? curOrder?.logiCoins != 0
              ? Number(coins) > curOrder?.logiCoins
                ? curOrder?.logiCoins
                : Number(coins)
              : 0
            :Number(curOrder?.logiCoins)}
          coinStatus={curOrder?.status == 0?false:true}
          addressval={addressval}
          addressId={addressId}
          openEmi={openEmi}
          onHideEmi={onHideEmi}
          toggleModalEmi={toggleModalEmi}
          handleCloseEmi={handleCloseEmi}
          showEmi={showEmi}
          toggleModalCart={toggleModal}
          />
    </div>
  );
}

export default OrderDetails;
