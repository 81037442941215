import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import CreateCustomerV2 from "./SubComponents/CreateCustomerV2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustomersAction,
  clearAllCustomers,
} from "../../services/Store/Actions/customerAction";
import ToastV2 from "../subComponents/toastV2";
import { toastStatus } from "../../services/Store/Actions/appActions";
import SalesHomePage from "./SubComponents/SalesHomePage";
import SvgImage from "../Icons/Icons";
import "./index.css";
import { BallTriangle } from "react-loader-spinner";
import AddLr from "../TripsMainV2/Components/AddLr";

const moment = require("moment");
const customerImg = require("../../assets/images/Screenshot 2023-10-07 at 10.06.46 PM.png"); // with require

function ManageCustomerV2(props) {
  const offsetString =
    window.location.search === "" ? 0 : window.location.search.split("=")[2];
  const offsetVal = Number(offsetString);
  const { sideBar, salesHome, setSalesHome } = props;
  const [createCust, setCreateCust] = useState(false);
  const [offset, setOffset] = useState(offsetVal);
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const toastObject = useSelector((state) => state?.app?.toastObject);
  const { customerList, customerNo, newCust, loader } = useSelector(
    (state) => state?.customer
  );
  if (customerNo && customerNo != "" && customerNo != 0) {
    setSalesHome(false);
  }
  console.log("===pppp>>>>", customerList, customerNo, salesHome);
  function toggleCreateCust() {
    if (isCustomers == 3) setCreateCust(!createCust);
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { authUser } = useSelector((state) => state?.loginReducer);
  const { managerControlList } = useSelector((state) => state?.userManagement);
  const [isTrips, setIsTrips] = useState(0);
  const [isCustomers, setIsCustomers] = useState(0);
  const [isVendor, setIsVendor] = useState(0);
  useEffect(() => {
    setIsTrips(
      authUser?.company?.isTrips && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isTrips && managerControlList?.tripAccess
        ? managerControlList?.tripAccess
        : 0
    );
    setIsCustomers(
      authUser?.company?.isCustomers && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isCustomers && managerControlList?.customerAccess
        ? managerControlList?.customerAccess
        : 0
    );
    setIsVendor(
      authUser?.company?.isVendor && authUser?.user?.type == 2
        ? 3
        : authUser?.company?.isVendor && managerControlList?.vendorAccess
        ? managerControlList?.vendorAccess
        : 0
    );
  }, [authUser, managerControlList]);
  console.log("loader && ", loader, companyName == "", phone == "");
  useEffect(() => {
    dispatch(
      getAllCustomersAction({
        offset: "",
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
        // limit: "12",
      })
    );

    return function cleanUp() {
      dispatch(clearAllCustomers());
      setOffset(0);
      dispatch(clearAllCustomers());
    };
  }, []);
  useEffect(() => {
    if (customerNo && customerNo != "" && customerNo != 0) {
      setSalesHome(false);
    }
  }, [customerNo]);
  useEffect(() => {
    dispatch(
      getAllCustomersAction({
        offset: "",
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
        // limit: "12",
        phone: phone,
        companyName: companyName,
      })
    );
    return function cleanUp() {
      dispatch(clearAllCustomers());
      setOffset(0);
      dispatch(clearAllCustomers());
    };
  }, [phone, companyName]);

  const handlePrevButton = () => {
    if (offset > 0) {
      setOffset(offset - 10);
      dispatch(
        getAllCustomersAction({
          offset: offset - 10,
          companyName: companyName,
          minKyc: "",
          sortAs: "",
          sortBy: "",
          // limit: "12",
        })
      );
    }
  };

  const handleNextButton = () => {
    if (customerNo - offset > 10) {
      setOffset(offset + 10);
      dispatch(
        getAllCustomersAction({
          offset: offset + 10,
          phone: phone,
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    }
  };

  return (
    <div className="topMargin">
      {loader && companyName == "" && phone == "" ? (
        <>
          <div
            className="container sideBarMargin"
            style={{
              marginLeft: sideBar ? "230px" : "100px",
              maxWidth: sideBar ? windowWidth - 260 : windowWidth - 130,
              // height: "100vh",
              backgroundColor: "#FFFFFF",
              height: 400,
            }}
          >
            <div
              className="boxContainer"
              style={{
                padding: 70,
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 130,
              }}
            >
              <BallTriangle
                height={70}
                width={70}
                radius={5}
                color="#ED752E"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {!salesHome ? (
            <div
              className="container sideBarMargin"
              style={{
                marginLeft: sideBar ? "230px" : "100px",
                maxWidth: sideBar ? windowWidth - 260 : windowWidth - 130,
                // height: "100vh",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div>
                <div className="custText">Customers</div>
                <div className="d-flex justify-content-between mx-4">
                  <div className="d-flex">
                    <div className="col-6">
                      <div className="row FontNS16">
                        <div className="col-12 custNamepadd">Company Name:</div>
                        <div className="col-12 custNamepadd2">
                          <input
                            className="inputBoxCust"
                            placeholder="Enter Company Name"
                            value={companyName}
                            autoFocus={companyName != "" ? true : false}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row FontNS16">
                        <div className="col-12 custNamepadd">Phone:</div>
                        <div className="col-12 custNamepadd2">
                          <input
                            className="inputBoxCust"
                            placeholder="Enter Phone Number"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="addCustcenter">
                    <button
                      className="greenButton Medium text3 AddCustStartBtn"
                      onClick={toggleCreateCust}
                    >
                      <div className="AddCust">+ Create Customer</div>
                    </button>
                  </div>
                </div>
                <div style={{ margin: "32px 32px 0 32px" }}>
                  <Table
                    className="CustSectionTableNew"
                    // id="CustSectionTable1"
                    bordered="true"
                  >
                    <thead
                      style={{
                        overflow: "hidden",
                        borderRadius: "12px 12px 0px 0px",
                      }}
                    >
                      <tr className="table-header-cust ">
                        <th style={{ width: "6%" }} className="">
                          S. No
                        </th>
                        <th style={{ width: "14%" }} className="">
                          {" "}
                          <div>Customer</div>{" "}
                        </th>
                        <th style={{ width: "9%" }} className="">
                          Contact
                        </th>
                        <th style={{ width: "12%" }} className="">
                          {/* <div className="row">
                            <div className="col-8">
                            Total Earnings
                            </div>
                            <div className="col-4" style={{padding : "10px 0 0 15px"}}>  
                             <SvgImage name="sortFilter"/>
                            </div>
                          </div> */}
                          Total Earnings
                        </th>
                        <th style={{ width: "12%" }} className="">
                        {/* <div className="row">
                            <div className="col-8">
                            Closing Balance
                            </div>
                            <div className="col-4" style={{padding : "10px 0 0 15px"}}>  
                             <SvgImage name="sortFilter"/>
                            </div>
                          </div> */}
                          Closing Balance
                        </th>
                        <th style={{ width: "12%" }} className="">
                        {/* <div className="row">
                            <div className="col-8">
                            Last Payment
                            </div>
                            <div className="col-4" style={{padding : "10px 0 0 15px"}}>  
                             <SvgImage name="sortFilter"/>
                            </div>
                          </div> */}
                          Last Payment
                        </th>
                        <th style={{ width: "14%" }} className="">
                        {/* <div className="row">
                            <div className="col-8">
                            Last Payment
                            </div>
                            <div className="col-4" style={{padding : "10px 0 0 15px"}}>  
                             <SvgImage name="sortFilter"/>
                            </div>
                          </div> */}
                          Last Payment Date
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      className="lowerBorderLineCust"
                      style={{ height: "560px" }}
                    >
                      {customerList?.map((data, idx) => (
                        <tr
                          className="table-row-manage-cust-new-1 text4"
                          style={{
                            borderWidth:"0px",
                            borderBottom:"1px solid #E2E2E2",
                            // borderCollapse: "collapse",
                          }}
                          key={idx}
                          onClick={() => {
                            // window.location = "/Customers/" + 1;
                            navigate("/Customers/" + data?.customerId);
                          }}
                        >
                          <td
                            style={{
                              width: "6%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            <div>{idx + 1}</div>
                          </td>
                          <td
                            style={{
                              width: "14%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.companyName}
                          </td>
                          <td
                            style={{
                              width: "9%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.phone}
                          </td>
                          <td
                            style={{
                              width: "12%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                              textAlign : "right !important"
                            }}
                          >
                            {"₹ " + data?.earning?.toLocaleString('en-IN') || 0}
                          </td>
                          <td
                            style={{
                              width: "12%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                              textAlign : "right !important"
                            }}
                          >
                            {"₹ " + data?.amount?.toLocaleString('en-IN') || 0}
                          </td>
                          <td
                            style={{
                              width: "12%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                              textAlign : "right !important"

                            }}
                          >
                            {"₹ " + (data?.Ledgers[0]?.amount?.toLocaleString('en-IN') || 0)}
                          </td>
                          <td
                            style={{
                              width: "14%",
                              borderLeft: "1px solid transparent",
                              borderRight: " 1px solid transparent",
                            }}
                          >
                            {data?.lastPaymentDate
                              ? moment(data?.lastPaymentDate).format(
                                  "DD, MMM, YY"
                                )
                              : "N/A"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="nextRow ">
                    <div
                      className="nextRowCont "
                      style={{ borderTopWidth: "0px" }}
                    >
                      <button
                        className="PrevButton"
                        onClick={handlePrevButton}
                        style={{ opacity: offset > 0 ? "1" : "0.5" }}
                      >
                        <SvgImage name="PrevArrow" heigth="12px" width="12px" />
                      </button>
                      <button
                        className="nextButton"
                        onClick={handleNextButton}
                        style={{
                          opacity: customerNo - offset > 10 ? "1" : "0.5",
                        }}
                      >
                        <SvgImage name="NextArrow" heigth="12px" width="12px" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <CreateCustomerV2
                toggle={toggleCreateCust}
                modal={createCust}
                setModal={setCreateCust}
              />
              {/* <PaymentsModal 
          toggle={toggleCreateCust} modal={createCust} setModal={setCreateCust} 
        /> */}
            </div>
          ) : (
            <SalesHomePage sideBar={sideBar} />
          )}
        </>
      )}
    </div>
  );
}
export default ManageCustomerV2;
