import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import SvgImage from "../../Icons/Icons";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addFleet } from "../../../services/Store/Actions/fleetActions";
import { useNavigate } from "react-router-dom";
import {
  getVehicleListAction,
} from "../../../services/Store/Actions/manageVehicleAction";
// import { fleetDetailsSuccess } from "../../../services/Store/Actions/fleetActions";
import Switch from "react-switch";
import SearchBar from "../../subComponents/searchbar";
import { Checkbox } from "@material-ui/core";
import { autoRechargeMultipleAction, cleanFastagLoaderAction } from "../../../services/Store/Actions/fastagAction";
import { sendAnalitics } from "../../../services/Store/Actions/appActions";

function AutorechargeSelectVehicles(props) {
  const { open, toggleModal, handleClose, autoState } = props;
  const validationSchema = Yup.object().shape({
    amount: Yup.number().typeError("Only Numbers are allowed")
      .required("Required"),
    // vehicleCategory: Yup.string().required("Required"),
  });
  const { loader } = useSelector((state) => state?.fastag);

  const [selectedAll, setSelectedAll] = useState(false);

  //console.log("fleet added",fleetAdded);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [listNo, setListNo] = useState(0);
  const [prevButtonPressed, setPrevButtonPressed]= useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setOffset(0);
    dispatch(
      getVehicleListAction({
        offset: "",
        limit: 15,
        search: search.trim(),
        minKyc: "",
        sortAs: "",
        sortBy: "",
        fleetType: "1",
      })
    );
  }, [open, search]);

  const findSearch = () => {
    dispatch(
      getVehicleListAction({
        offset: 0,
        search: search.trim(),
        limit: 15,
        minKyc: "",
        sortAs: "",
        sortBy: "",
        fleetType: "1",
      })
    );
  };

  useEffect(() => {
    setSearch("");
    dispatch(cleanFastagLoaderAction());
    setListNo(0);
  }, [open]);

  const fleetRechargeModal = useSelector(
    (state) => state?.manageVehicle?.allFleets?.rows
  );
  const fleetCount = useSelector(
    (state) => state?.manageVehicle?.allFleets?.count
  );
  const numberOfVeh= fleetRechargeModal!=undefined? fleetRechargeModal.length:0;
  console.log("fleet added", numberOfVeh,listNo);

  useEffect(()=>{
    if (prevButtonPressed)
    {
      console.log ("fleet added subtracting------",numberOfVeh)
      setListNo(listNo- numberOfVeh);
    }
  },[numberOfVeh])
  //console.log("fleet added", fleetRechargeModal);

  const handlePrevButton = () => {
    //props.nextTrigger();
    if (offset > 0) {
      dispatch(sendAnalitics("LN_ChangeLimit_AR_MV_web"));
      setOffset(offset - 15);
      //setSerialNum(serialNum - 15);
      //console.log("offset", offset);
      dispatch(
        getVehicleListAction({
          offset: offset - 15,
          limit: 15,
          search: search.trim(),
          minKyc: "",
          sortAs: "",
          sortBy: "",
          fleetType: "1",
        })
      );
      setPrevButtonPressed(true);
    }
    //console.log("X--");
  };

  const handleNextButton = () => {
    if (fleetCount - offset > 15) {
      dispatch(sendAnalitics("RN_ChangeLimit_AR_MV_web"));
      setPrevButtonPressed(false);
      setOffset(offset + 15);
      setListNo(listNo+ fleetRechargeModal.length);
      //setSerialNum(serialNum + 15);
      //console.log("offset", offset);
      dispatch(
        getVehicleListAction({
          offset: offset + 15,
          limit: 15,
          search: search.trim(),
          minKyc: "",
          sortAs: "",
          sortBy: "",
          fleetType: "1",
        })
      );
    }
    //console.log("X++");
  };
 
  const [fleetSelected, setFleetSelected]= useState([]);

  const handleCheckbox=(val, fleetId)=>{
     if(val){
        setFleetSelected([...fleetSelected, fleetId]);
        console.log("fllllllllleeeets", fleetSelected);
     }
     else{
        setFleetSelected(fleetSelected.filter((e)=>e!=fleetId))
        console.log("fllllllllleeeets", fleetSelected);
     }
  }

  const { fastagWallet } = useSelector((state) => state?.fastag);
  const fastagAutorechargeAmount =fastagWallet?.autoRechargeAmount;
  //tempArray.push(res?.data?.Location);
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positon settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Auto Recharge : Select Vehicles</p>
            <Button
              type="button"
              className="close-btn"
              onClick={()=>{
                dispatch(sendAnalitics("Close_ChangeLimit_AR_MV_web"));
                toggleModal();
              } }
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody style={{ padding: "0" }}>
            <div
              className="padding16 padding32L"
              style={{ backgroundColor: "#96A9CA" }}
            >
              <div className="heading3 semiBold padding16B">
                Set Limit for FASTag
              </div>
              <div className="FontSmall">
                Minimum balance for the FASTag would be maintained to the limit
                set
              </div>
            </div>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                amount: "",
                pin: "",
              }}
              onSubmit={(values, actions) => {
                if (selectedAll){
                  let payload={
                    fleetId:[],
                    minAmount:String(values.amount)
                  }
                  dispatch(autoRechargeMultipleAction(payload));
                }else{
                  let payload={
                    fleetId:fleetSelected,
                    minAmount:String(values.amount)
                  }
                  dispatch(autoRechargeMultipleAction(payload));
                }
                toggleModal();
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="heading2 semiBold padding16T padding8B padding32L">
                      Set Amount
                    </div>
                    <label for="html" className="label-body" style={{margin: "10px 9px 0 35px"}}>
                      ₹
                    </label>
                    <input
                      type="number"
                      id="amount"
                      style={{ marginLeft: "0", fontSize: "16px" }}
                      className={`input-body`}
                      name="amount"
                      onChange={handleChange("amount")}
                      value={values.amount}
                      onBlur={()=>{
                        dispatch(sendAnalitics("Amt_ChangeLimit_AR_MV_web",{
                          amt_setLimit:values.amount
                        }));
                        handleBlur("amount")
                      } }
                      touched={touched.amount}
                      errors={errors.amount}
                      placeholder="Enter limit amount"
                    />
                    <div className="input-amount-lining"></div>
                    <div style={{ margin: "7px 0 0px 30px", color: "red" }}>
                      {errors.amount && touched.amount ? (
                        <div>*{errors.amount}</div>
                      ) : null}
                    </div>
                    <div
                      className="line-separation"
                      style={{ marginTop: "2rem" }}
                    ></div>
                  </FormGroup>
                  <FormGroup className="padding16 padding24L padding32R padding0B">
                    <div className="row">
                      <div className="col-7 FontSmall semiBold padding24L">
                        All FASTags
                      </div>
                      <div className="col-5 FontSmall row">
                        <div className="col-6 padding8R semiBold">
                          Select All
                        </div>
                        <div
                          className="col-4 padding8L"
                          onClick={() => {
                            dispatch(sendAnalitics("selectAll_ChangeLimit_AR_MV_web"))
                            
                            if(selectedAll){
                              setFleetSelected([]);
                            }
                            setSelectedAll(!selectedAll);
                          }}
                          style={{ marginTop: "-2px" }}
                        >
                          <SvgImage
                            name={
                              selectedAll
                                ? "rechargeSelected"
                                : "rechargeUnselected"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="padding24L padding24R">
                    <SearchBar
                      placeholder="Search for vehicles"
                      height="46"
                      width="94%"
                      value={search}
                      setSearch={setSearch}
                      onChange={(event) => {
                        //dispatch(clearAllDrivers());
                        dispatch(sendAnalitics("Search_ChangeLimit_AR_MV_web",{
                          search:event.target.value
                        }));
                        setSearch(event.target.value);
                      }}
                      findSearch={findSearch}
                    />
                  </div>
                  <div className="padding16 padding8T padding24L">
                    {fleetRechargeModal?.map((d, i) => {
                      let vehNo =
                      d?.fleet?.regNumber != undefined
                        ? d?.fleet?.regNumber?.length > 10
                          ? d?.fleet?.regNumber.substring(0, 8) + ".."
                          : d?.fleet?.regNumber
                        : "";
                    // let vehNo = d?.tagId!= undefined?
                    //   d?.tagId.length > 10
                    //     ?".."+ d?.tagId.substring(d?.tagId.length-10, d?.tagId.length)
                    //     : d?.tagId:"";
                    
                    let limit = d?.autoRechargeAmount;
                    let vehStatus = d?.autoRecharge;
                    let bank = d?.fasTagType;
                      return limit !== undefined ? (
                        <FormGroup key={offset + i + 1}>
                          <div className="rowCont row" style={{cursor:"default"}}>
                            <div
                              class="div-table-col width70 FontNB col-6" >
                              {/* <div data-letters={firstName_initials.toUpperCase()}> */}
                              <div data-letters-recharge={listNo + i + 1}  style={{ display: "flex" }}>
                                <span className=" FontNB">{vehNo}</span>
                                <div
                                  style={{
                                    paddingLeft: "0.5rem",
                                    position: "relative",
                                    top: "-5px",
                                  }}
                                >
                                  <SvgImage
                                    name={
                                      bank === "YesBank"
                                        ? "yesBankTag"
                                        : "idfcBankTag"
                                    }
                                  />
                                </div>
                              </div>
                              <div class="vehSubInfo FontSmall">₹{limit=="500"?fastagAutorechargeAmount:limit}</div>
                            </div>
                            <Checkbox
                              className="col-3"
                              value={d?.fleetId}
                              checked={selectedAll || fleetSelected.includes(d?.fleetId)}
                              // name={vehNo}
                              // id={vehNo+(offset + i + 1)}
                              //checked={selectedAll || Object.id.value}
                              sx={{
                                color: "#6c6c6c",
                                "&.Mui-checked": {
                                  color: "#2E7C7B",
                                },
                              }}
                              onChange={e => {
                                //console.log("iiiiiiiiiiiiiiiiiiiiiii", this.id.checked);
                                handleCheckbox(e.target.checked, d?.fleetId);
                                dispatch(sendAnalitics("SelectGroup_ChangeLimit_AR_MV_web",{
                                  groupSelected:fleetSelected
                                }))
                                //this.setState({isTrue: e.target.checked});
                              }}
                            />
                            {/* <div
                              className="col-3 padding16 padding32L"
                              onClick={() => {
                                alert("hey");
                                if(vehStatus)
                                vehStatus=false;
                                else 
                                vehStatus=true;
                              }}
                              style={{ marginTop: "-2px" }}
                            >
                              <SvgImage
                                name={
                                  vehStatus
                                    ? "rechargeSelected"
                                    : "rechargeUnselected"
                                }
                              />
                            </div> */}
                          </div>
                          <div className="endingLine"></div>
                        </FormGroup>
                      ) : (
                        <></>
                      );
                    })}
                  </div>
                  <div className="row padding8B">
                    <div className="col-8"></div>
                    <div
                      className="col-1 padding24L"
                      onClick={handlePrevButton}
                      style={{
                        opacity: offset > 0 ? "1" : "0.5",
                        cursor: "pointer",
                      }}
                    >
                      <SvgImage name="prevArrowSmall" />
                    </div>
                    <div
                      className="col-2"
                      onClick={handleNextButton}
                      style={{
                        opacity: fleetCount - offset > 50 ? "1" : "0.5",
                        cursor: "pointer",
                      }}
                    >
                      <SvgImage name="nextArrowSmall" />
                    </div>
                  </div>
                  <FormGroup className="padding16B">
                    <Button
                      type="submit"
                      className="butt-4"
                      style={{ marginTop: "2rem", marginLeft: "-27px" }}
                      disabled={loader ? true : false}
                      onClick={()=>{
                        dispatch(sendAnalitics("Confirm_ChangeLimit_AR_MV_web"))
                      }}
                    >
                      {loader ? "Please Wait" : "Confirm"}
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default AutorechargeSelectVehicles;
