import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const ProctedRout = (props) => {
  console.log(
    "@yahoo",
    props,
    props.user != "null" && props.user != null && !(props?.disable == true)
  );
  if (props.user != "null" && props.user != null && !(props?.disable == true)) {
    return props.component;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProctedRout;
