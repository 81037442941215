import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Toast from "../subComponents/toast";
import { toastStatus } from "../../services/Store/Actions/appActions";
import { ToastContainer } from "react-toastify";
import SalarySideDiv from "./Components/SalarySideDiv";
import { downloadSalaryAction, downloadSalaryCreditSheet, getSalaryWalletDetails, uploadSalaryAction,getSalaryWalletList,clearAllSalaryWalletList,getExpenseWalletSummary,getSalaryWalletSummary } from "../../services/Store/Actions/driverslistActions";
import SvgImage from "../Icons/Icons";
import { toast } from "react-toastify";
import FullDriverSalaryList from "./Components/FullDriverSalaryList";

function Salary(props) {
    const {sideBar} = props
    const [item,setItem] = useState({});
    const dataVal= sessionStorage.getItem("_trips");
    const tripData= dataVal? JSON.parse(dataVal):null ;
    const [salaryId,setSalaryId] = useState('');
    const [driverDetails, setDriverDetails] = useState("Select Driver");
    const { allDrivers, allDriverList,salaryWallets,
      salaryWalletsCount, } = useSelector(
      (state) => state?.driverlist
    );
    const [offset,setOffset] = useState(tripData?.offset?tripData?.offset:0)
    console.log('ITEMS',item)
    let { id } = useParams();
    const toastObject = useSelector((state) => state?.app?.toastObject);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth );
    const dispatch = useDispatch();
    const [userId, setUserId] = useState("");
    const [VehicleNumber, setVehicleNumber] = useState(false);
    const [driverID, setDriverID] = useState("");
    const [driverFleet, setDriverFleet] = useState("Driver not selected");
    const navigate = useNavigate();

    const [next, setnext] = useState(true);
    const nextTrigger = () => {
      setnext(false);
    };
    const allowTrigger = () => {
      setnext(true);
    };
    useEffect(()=>{
        // const dataVal= sessionStorage.getItem("_trips");
        // const tripData= dataVal? JSON.parse(dataVal):null ;
        // console.log('stores session',tripData)
        // setOffset(tripData?.offset?tripData?.offset:0);
        // setItem(tripData?.item);
    },[])

    useEffect(() => {
      console.log("namananananan",id);
      if (id != undefined && id != "" && next) {
        // setUserId(val?.User?.userId);
        // setDriverFleet(val?.User?.fleet);
        // setDriverID(val?.driverId);
        console.log("namananananan111",id);
        setDriverDetails("Driver");
        dispatch(getSalaryWalletDetails({phone: id}));
        dispatch(getSalaryWalletSummary(id, {        
          offset: 0,
          sortAs: "",
          sortBy: "",}))
        // const accountSt= Number(val?.WalletAccount?.m2p?.accountStatus)>0?val?.WalletAccount?.m2p?.accountStatus:
        // Number(val?.WalletAccount?.neoKred?.accountStatus)>=3?val?.WalletAccount?.neoKred?.accountStatus:
        // val?.WalletAccount?.neoKred?.accountStatus!==undefined? val?.WalletAccount?.neoKred?.accountStatus:
        // val?.WalletAccount?.m2p?.accountStatus;
        // console.log("===============st",accountSt);
        //neoCred=1, m2p=2;
        // let cardVar= Number(val?.WalletAccount?.m2p?.accountStatus)>0?"2":
        // Number(val?.WalletAccount?.neoKred?.accountStatus)>=3?"1":
        // val?.WalletAccount?.neoKred?.accountStatus!==undefined? "1":"2";
        // console.log("===============type",cardVar);
        // setDriverCardStatus(accountSt);
        // setCardType(cardVar);
        //setVehicleNumber()
        // props.setDriverFleet(e?.User?.fleet);
        // props.setDriverID(e?.driverId);
        // props.setDriverCardStatus(e?.User?.WalletAccount?.accountStatus);
        // props.userHandler(e?.User?.userId);
        // props.setDriverDetails({ name: e?.User?.fullName, phone: e?.User?.phone });
        //console.log("ababababaab",val);
      }
    }, [id, salaryWallets]);
    const downloadSal = () => {
      dispatch(downloadSalaryAction("", (res) => {
        console.log("downloadSalaryAction", res?.data)
        if (res?.status == 200) {
          const blob = new Blob([res?.data])
          const link = document.createElement("a");
         link.href = window.URL.createObjectURL(blob);
         link.download = `file.xls`; //name to changed here
         link.click();
          notify1();
        }
      }))
    }
  
    const downloadSalDisbursement = () => {
      dispatch(downloadSalaryCreditSheet("", (res) => {
        console.log("downloadSalaryCreditSheet", res)
        if (res?.status == 200) {
          const blob = new Blob([res?.data])
          const link = document.createElement("a");
         link.href = window.URL.createObjectURL(blob);
         link.download = `file.xls`; //name to changed here
         link.click();
          notify1();
        }
      }))
    }
    const customId1 = "custom-id-download page";
  const notify1 = () => toast.success("Salary disbursement data was downloaded successfuly.", {
    toastId: customId1
  });


  const customId2 = "custom-id-upload page";
  const notify2 = () => toast.success("Salary disbursement data was uploaded successfuly.", {
    toastId: customId2
  });
  
 // offset value
 const offsetString =
 window.location.search === "" ? 0 : window.location.search.split("=")[2];
const offsetVal = Number(offsetString);

// search value
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
let searchParam = params.search===undefined?"":params.search;
  useEffect(() => {
    function handleResize() {
        setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // const handleBulkSalary = () =>{
  //   // setDriverDetails({
  //   //   name: "All Drivers",
  //   //   phone: "",
  //   // });
  //   navigate('/Salary/bulkSalary');
  // }

  useEffect(() => {
    console.log('11111');
    if (driverDetails == 'All Drivers') {
      navigate('/salary/bulkSalary');
      console.log('2222')
    }
  }, [driverDetails]);

  const handleBulkSalary = () => {
    setDriverDetails('All Drivers');
  };

    return (
        <div className="container topMargin sideBarMargin" style={{marginLeft: sideBar? '230px' : '100px',maxWidth: sideBar ?windowWidth - 240 :windowWidth- 130 }}>
        <div className="row">       
          <div className="col-lg-4 FullDriverDashboardBox bg-white">
          <div className="FUllListSearchBar leftTopDiv" onClick={handleBulkSalary} style={{margin:'20px 12px 20px 0', cursor:'pointer'}}>
            <div className="bulkSalarySvgHeading">
              <div style={{backgroundColor:'#FFF', padding:'4px', borderRadius:'4px', marginRight:'10px'}}>
                <SvgImage name="payBulkSalaryLogo" />
              </div>
              <div className="FontBoldMedium Bold">Pay Bulk Salary</div>
            </div>
            <SvgImage name="rightArrow" />
          </div>
            {/* <div className="row padding16L padding16R padding8T padding8B">
              <div className="col-6 padding8">
                <button className="salaryBtn1 flexCls text3" type="button" onClick={downloadSal}>
                  <SvgImage name="downIcon" />
                  <div className="padding8L">
                    Salary Sample
                  </div>
                </button>
              </div>
              <div className="col-6 padding8">
                <button className="salaryBtn1 flexCls text3" type="button" >
                  <SvgImage name="uploadIcon" />
                  <div className="padding8L">
                    Salary Creation
                  </div>
                  <input
                    type="file"
                    // accept="image/*"
                    className='add-attachment-btn-2'
                    onChange={async (e) => {
                      const img = e?.target.files[0];
                      // setFileUp( e?.target.files[0]);
                      console.log("image", img?.name);
                      if (img !== null) {
                        //notify2();
                        dispatch(uploadSalaryAction({
                          isRes: false,
                          file: e?.target.files[0],
                        }, (res) => {
                          console.log("resp for file upload", res.type);
                          if (res?.type == "success") {
                            console.log("entereed")
                            //handleRefresh();
                            //alert("Data uploaded successfully")
                            notify2();
                          }
                        }))
                      }
                    }}
                  />
                </button>
              </div>
            </div>


             <div className="row padding16L padding16R padding8T padding8B">
              <div className="col-6 padding8">
                <button className="salaryBtn2 flexCls text3" type="button" onClick={downloadSalDisbursement}>
                  <SvgImage name="downIcon" />
                  <div className="padding8L">
                    Salary Disbursement
                  </div>
                </button>
              </div>
              <div className="col-6 padding8">
                <button className="salaryBtn2 flexCls text3" type="button" >
                  <SvgImage name="uploadIcon" />
                  <div className="padding8L">
                    Salary Disbursement
                  </div>
                  <input
                    type="file"
                    // accept="image/*"
                    className='add-attachment-btn-2'
                    onChange={async (e) => {
                      const img = e?.target.files[0];
                      // setFileUp( e?.target.files[0]);
                      console.log("image", img?.name);
                      if (img !== null) {
                        //notify2();
                        dispatch(uploadSalaryAction({
                          isRes: false,
                          file: e?.target.files[0],
                        }, (res) => {
                          console.log("resp for file upload", res.type);
                          if (res?.type == "success") {
                            console.log("entereed")
                            //handleRefresh();
                            //alert("Data uploaded successfully")
                            notify2();
                          }
                        }))
                      }
                    }}
                  />
                </button>
              </div>
            </div>  */}
            <FullDriverSalaryList
            nextTrigger={nextTrigger}
            allowTrigger={allowTrigger}
             />
          </div>
          <div className="col-lg-8 FullDriverDashboardBox">
            <div className="whiteBackground">
              <SalarySideDiv
                cardType={"m2p"}
                // driverDetails={{name :'cubcuwbc'}}
                driverCardStatus={"dfs"}
                // userId={"fdfdfd"}
                driverFleet={driverFleet}
                // setVehicleNumber={"dfsafd"}
                // driverID={"fd3eadsa"}

                // cardType={cardType}
                driverDetails={driverDetails}
                // driverCardStatus={driverCardStatus}
                userId={userId}
                // driverFleet={driverFleet}
                setVehicleNumber={setVehicleNumber}
                driverID={driverID}
              />
            </div>
          </div>

          {toastObject.toastStatus ? (
        <Toast
          success={toastObject?.status}
          message={toastObject?.message}
          toggleModal={() => {
            dispatch(
              toastStatus({
                toastStatus: false,
              })
            );
          }}
          isOpen={toastObject?.toastStatus}
          name="driver"
          handleClose={() => {
            // dispatch(
            //   toastStatus({
            //     toastStatus: false,
            //     message: "",
            //     status: true,
            //   })
            // );
            // if (!toastObject?.toastStatus)
            id == undefined ? window.location='/salary/bulkSalary' : window.location='/salary/'+id+"?search="+searchParam+"&offset="+offsetVal
            //navigate("/Drivers");
          }}
        />
      ) : (
        <></>
      )}
      <ToastContainer theme="dark" position="top-right"
          closeOnClick pauseOnHover
          autoClose={4000}/>
        </div>
      </div>
    )
}
export default Salary