import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import { useNavigate, NavLink } from "react-router-dom";
import SvgImage from "../../../Icons/Icons";
import EditVehicleDetailModal from "../../../Modals/editVehicleDetails";
import { getVehicleDetailsByFleetId } from "../../../../services/Store/Actions/fastagAction";
import "./index.css";
import { useSelect } from "@mui/base";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function VehicleDetails(props) {
  const {
    vehicleNumber,
    linkedFastag,
    assignedDrive,
    category,
    registrationDate,
    rcPhoto,
    fleetId,
  } = props;
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  function toggleModal() {
    setOpen(!open);
  }
  function handleShow() {
    setOpen(true);
  }
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { vehicleDetailsByVehicleID, loader } = useSelector(
    (state) => state?.fastag
  );

  const vehicleDetails = useSelector((state) => state?.fastag?.vehicleDetails);
  const regDate = vehicleDetails?.vehicle?.regDate;
  const vehCategory =
    vehicleDetails?.vehicle?.vehicleCategory === undefined
      ? "N/A"
      : vehicleDetails?.vehicle?.vehicleCategory;
  //console.log("vehicle details", vehicleDetails?.vehicle?.regDate.split("T")?.[0]);
  // const regDateActive = vehicleDetailsByVehicleID?.regDate ? true : false

  //console.log("jkjjjkj", vehicleDetailsByVehicleID);

  //console.log("initially", vehicleNumber);
  const LinkFastag =
    linkedFastag == "" || linkedFastag == null ? "N/A" : linkedFastag;
  const VehicleNumber =
    vehicleNumber == "" || vehicleNumber == null ? "N/A" : props.vehicleNumber;
  //console.log("AssignedDriver",typeof assignedDrive)
  const AssignedDriver =
    assignedDrive == "" || assignedDrive == null ? true : false;

  const handleViewImage = (val) => {
    console.log("vallllllll", val);
    window.open(val);
  };

  return (
    <div>
      <Card className="main-card-vehicles padding16L padding16R">
        <div className="row">
          <div className="col-8">
            <div
              className="row padding16 padding32T"
              style={{ paddingLeft: "12px" }}
            >
              <div className="col-2">
                <SvgImage name="vehicleDivLogo" />
              </div>

              <div className="col-10">
                <CardTitle className="Bold heading2">Vehicle Details</CardTitle>
              </div>
            </div>
          </div>
          <div
            className="col-4 padding16 padding32T padding8R"
            style={{ textAlign: "right" }}
          >
            <SvgImage
              name={
                props.fastagType === "idfc"
                  ? "idfcBankTag"
                  : props.fastagType === "YesBank"
                  ? "yesBankTag"
                  : ""
              }
            />
          </div>
          {/* <div className="col-5 main-edit-detail-button">
            <NavLink to="" className="edit-detail-button" onClick={handleShow}>
              Edit Details
            </NavLink>
            <EditVehicleDetailModal open={open} toggleModal={toggleModal} handleClose={handleClose}/>
          </div> */}
        </div>

        {/* <div className="row FontNB paddingBtm">
          <div className="col-6">Vehicle Number</div>
          <div className="col-6" style={{ textAlignLast: "end" }}>
            {VehicleNumber}
          </div>
        </div> */}

        {/* <div className="row FontNB paddingBtm">
          <div className="col-7 ">Linked FASTag ID</div>
          <div className="col-5" style={{ textAlignLast: "end" }}>
            {LinkFastag}
          </div>
        </div> */}

        <div className="row FontNB paddingBtm">
          <div className="col-7 ">Assigned Driver</div>
          {AssignedDriver ? (
            <div className="col-5" style={{ textAlignLast: "end" }}>
              N/A
            </div>
          ) : (
            <div className="col-5" style={{ textAlignLast: "end" }}>
              {assignedDrive?.split(" ")[0]}
            </div>
          )}
          {/* <div className="col-5" style={{ textAlignLast: "end" }}>
            {AssignedDriver ? (
              <p className="all-value">N/A</p>
            ) : (
              <p
                className="all-value"
                style={{
                  // textDecorationLine: "underline",
                  // color: "#2E7C7B",
                }}
              >
                {assignedDrive}
              </p>
            )}
            
          </div> */}
        </div>

        <div className="row FontNB paddingBtm">
          <div className="col-7 ">Vehicle Category</div>
          <div className="col-5" style={{ textAlignLast: "end" }}>
            {vehCategory}
          </div>
        </div>

        <div className="row FontNB paddingBtm">
          <div className="col-7 ">Registration Date</div>
          <div className="col-5" style={{ textAlignLast: "end" }}>
            {linkedFastag != "" && linkedFastag != null ? (
              loader ? (
                <div style={{ margin: "0 0 0 95px" }}></div>
              ) : (
                <>
                  {/* {moment(vehicleDetailsByVehicleID?.regDate).format(
                      "DD/MM/YYYY"
                    )} */}
                  {regDate === undefined
                    ? "N/A"
                    : regDate === null
                    ? "N/A"
                    : regDate.split("T")?.[0]}
                </>
              )
            ) : (
              "N/A"
            )}
          </div>
        </div>

        <div className="row FontNB paddingBtm">
          <div className="col-7 ">
            <p>RC Photo</p>
          </div>
          <div className="col-5" style={{ textAlignLast: "end" }}>
            {linkedFastag != "" && linkedFastag != null ? (
              loader ? (
                <div style={{ margin: "0 0 0 95px" }}></div>
              ) : (
                <div>
                  {LinkFastag ? (
                    <div
                      style={{
                        textDecorationLine: "underline",
                        color: "#485B7C",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleViewImage(vehicleDetailsByVehicleID?.rcImage)
                      }
                    >
                      view
                    </div>
                  ) : (
                    "Not added"
                  )}
                </div>
              )
            ) : (
              "Not added"
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default VehicleDetails;
