import React, { useEffect, useState } from "react";
import "./DriverRequestFrame.css";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FcManager } from "react-icons/fc";
import { IconContext } from "react-icons";
import DriverRequestCusotmerCard from "../DriverRequestCustomerCard/DriverRequestCustomerCard";
import DriverRequestType from "../DriverRequestType/DriverRequestType";
import ApproveDeclineButton from "../ApproveDeclineButton";
import DriverBalanceStatus from "../DriverBalanceStatus";
import { Button } from "reactstrap";
import SvgImage from "../../Components/Icons/Icons";
import {
  getFuelWalletHistory,
} from "../../services/Store/Actions/omcActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Modal, ModalBody, Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import { sendAnalitics } from "../../services/Store/Actions/appActions";

const FuelWalletHistory = (props) => {
  const handleClose = props.handleClose;
  const dispatch = useDispatch();
  //const [offset, setOffset]= useState(0);
  const { offset, setOffset } = props;
  const { loader } = useSelector((state) => state?.omc);
  useEffect(() => {
    // dispatch(cleanCashbackList());
    setOffset(0);
    dispatch(
      getFuelWalletHistory({
        offset: 0,
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, []);

  const { isOpen, onHide, toggle } = props;
  const cashbackList = useSelector(
    (state) => state?.omc?.FuelWalletTransaction?.data?.data?.rows
  );
  const FuelWalletTransaction = useSelector(
    (state) => state?.omc?.FuelWalletTransaction?.rows
  );
  const totalExpenseCount = useSelector(
    (state) => state?.manageCard?.FuelWalletTransaction?.count
  );
  const pageCount =
    totalExpenseCount % 10 === 0
      ? totalExpenseCount / 10
      : Math.floor(totalExpenseCount / 10) + 1;
  //
  //console.log("cashback-- list", cashbackList);
  console.log("all cashback-- list", FuelWalletTransaction);
  //console.log("offset cashback-- list", offset)
  const { cashBack } = useSelector((state) => state?.manageCard);
  const cashBackBalance =
    cashBack?.data?.balance === undefined ? 0.0 : cashBack?.data?.balance;
  const cashBackRedeem =
    cashBack?.data?.redeem === undefined
      ? 0.0
      : Number(cashBack?.data?.redeem).toFixed(2);
  const remainingBal = (cashBackBalance - cashBackRedeem).toFixed(2);

  const handleOlderTransaction = () => {
    setOffset(offset + 10);
    dispatch(
      getFuelWalletHistory({
        offset: offset + 10,
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };
  const handlePageClick = (event) => {
    console.log("Clickess");
    dispatch(sendAnalitics("PageClick_History_CB_Web",{
      pageClicked:event.selected
    }));
    const newOffset = event.selected * 10;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    dispatch(
      getFuelWalletHistory({
        offset: newOffset,
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
    setOffset(newOffset);
  };

  return (
    <Modal
      isOpen={isOpen}
      onHide={onHide}
      className="all-transaction-main-modal"
      toggle={toggle}
      aria-labelledby="myModalLabel2"
    >
      <ModalBody className="transactionModalBody" style={{padding:"0.5rem 1rem 1rem"}}>
        <div className="PGCollectionBox">
        <div 
          className= /*"PGCollectionclose */ "closeEffectRow close-btn-for-transaction"
          >
              <div 
              className="closeEffect"
              onClick={()=>{
                dispatch(sendAnalitics("Close_History_CB_Web")); 
                handleClose();
              }}
              // // className=""
              >
                <SvgImage
                  classname = 'close-btn-text'
                  style={{ width: "10%", height: "10%" }}
                  name="CloseButtonLogo"
                  width="20%"
                  height="20%"
                />
              </div>
              {/* <div className="PGCollectionCloseText col-2">Close</div> */}
            </div>

          <div>
            <div className="DriverRequestHeader row padding32T">
              <div className="DriverRequestIcon col-1">
                <SvgImage name="cashbackLogo" />
              </div>
              <div className="DriverRequestText col-4">Fuel Wallet History</div>
              {/* <div className="DriverRequestIcon1 col-1">
                <SvgImage name="cashbackWalletLogo" />
              </div>
              <div className="DriverRequestText1 col-6">
                Total Cashback Earned: ₹{cashBackBalance}
              </div> */}
            </div>
            <div className="row" style={{padding:"0 4rem 1rem"}}>
              <div className="col-1"></div>
            </div>
          </div>
          {/* <div className="DriverRequestCard">
          <DriverBalanceStatus />
        </div> */}
          {/* <div className="row new-row-for-download"  >
            <div className="col-6"></div>
            <div className="col-3 filterText">
              Filter
              <div>
                <SvgImage name="filterIcon" />
              </div>
            </div>
            <div className="col-3 downloadText">
              <div>
              <img src="/download.svg" />

               
                </div>
              Download Statement
            </div>
          </div> */}
          <div className="transitionContainerCashback">
          <div className="tableContaincer">
            <Table className="cashbackTableSetting">
              <thead>
                <tr className="table-header-customer-details">
                  <th > <div style={{marginLeft:"1.5rem"}}>Transactions</div>  </th>
                  <th>Earned/Redeemed</th>
                  <th>Payment Date</th>
                </tr>
              </thead>
              <tbody>
                {FuelWalletTransaction?.map((item, i) => {
                  return (
                    <>
                      <tr>
                        <td style={{display:"flex",marginLeft:"-2.5rem"}}>
                          <div>
                          <SvgImage name={"transferCardIcon"} />
                          </div>
                          <div className="padding8L" style={{margin:"auto 0px"}}>{item?.description}</div>
                          </td>
                        <td className={item?.transferType === "credit"
                                  ? "greenColor"
                                  : "redColor"}
                            
                        >
                          ₹{item?.amount.toFixed(2)}
                        </td>
                        <td>
                          <div>{item?.createdAt}</div>
                          {/* <div>{time}</div> */}
                        </td>
                      </tr>
                      <tr></tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
            {pageCount > 1 ? (
            <div className="modalPagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          ) : (
            <></>
          )}
          </div>
          </div>
          {/* <div className="cashbackListing1">
            {cashbackList?.map((item, i) => {
              const date =
                item?.createdAt === null
                  ? "Not Available"
                  : moment(item?.createdAt).format("DD-MM-YYYY");
              const time =
                item?.createdAt === null
                  ? "Not Available"
                  : moment(item?.createdAt).format("hh:mm:ss a");
              const cashback = item?.cashbackAmount;
              const transactionName = item?.title;

              return (
                <>
                  <div className="row gx-0 cashbackBox ">
                    <div className="cashbackNameClass col-5">
                      {transactionName}
                    </div>
                    <div
                      className="cashbackAmount col-3"
                      style={{
                        color:
                          item?.transferType === "credit"
                            ? "#00E676"
                            : "#F0222E",
                      }}
                    >
                      ₹{cashback.toFixed(2)}
                    </div>
                    <div className="cashbackDate col-4">{date}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 cashbackMode"></div>
                    <div className="col-6 cashbackTime">{time}</div>
                  </div>
                  <hr
                    className="horizontalLine"
                    style={{ marginLeft: "4.7rem" }}
                  />
                </>
              );
            })}
          </div> */}
          {/* <div className="RequestTitle">
          <div className="Details">Driver Details</div>
          <div className="CurBalance">Current Bal.</div>
          <div className="ReqDate">Request Date</div>
          <div className="ReqBalance">Request Bal.</div>
        </div> */}
          {/* <div className="Requests">
          <div>
            <div>
              <DriverRequestCusotmerCard />
            </div>
            <div style={{ left: "630px", position: "absolute", top: "15px" }}>
              <ApproveDeclineButton name="Approve " />
            </div>
            <div style={{ left: "740px", position: "absolute", top: "15px" }}>
              <ApproveDeclineButton name="Decline " />
            </div>
          </div>

          <div style={{ top: "100px", position: "absolute" }}>
            <div>
              <DriverRequestCusotmerCard />
            </div>
            <div style={{ left: "630px", position: "absolute", top: "15px" }}>
              <ApproveDeclineButton name="Approve " />
            </div>
            <div style={{ left: "740px", position: "absolute", top: "15px" }}>
              <ApproveDeclineButton name="Decline " />
            </div>
          </div>
          <div style={{ top: "200px", position: "absolute" }}>
            <div>
              <DriverRequestCusotmerCard />
            </div>
            <div style={{ left: "630px", position: "absolute", top: "15px" }}>
              <ApproveDeclineButton name="Approve " />
            </div>
            <div style={{ left: "740px", position: "absolute", top: "15px" }}>
              <ApproveDeclineButton name="Decline " />
            </div>
          </div>
          <div style={{ top: "300px", position: "absolute" }}>
            <div>
              <DriverRequestCusotmerCard />
            </div>
            <div style={{ left: "630px", position: "absolute", top: "15px" }}>
              <ApproveDeclineButton name="Approve " />
            </div>
            <div style={{ left: "740px", position: "absolute", top: "15px" }}>
              <ApproveDeclineButton name="Decline " />
            </div>
          </div>
        </div> */}
          
          {/* {
          ((totalExpenseCount-offset)>10)?
          <div className="OlderHistoryButton" onClick={handleOlderTransaction}
          // style={{top:"2.5rem"}}
          >
          <Button
             disabled={loader?true:false}
              className="transactionButton"              
              >
          {loader?"Please Wait":"Older Expenses"}
          <div style={{paddingLeft:"8px"}}><SvgImage
          name="downArrow"
              /></div>
          
              </Button>
          </div>:
          <></>
        } */}
          {/* <div
              className="view-all-transaction-customer-receeivabl"
              onClick={handleOlderTransaction}
              // style={{paddingTop:"0.5rem"}}
            >
             
                Older history
          </div> */}
        </div>
      </ModalBody>
    </Modal>
  );
};
export default FuelWalletHistory;
