import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Alert,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import SvgImage from "../../../../../Icons/Icons";
import { getManagerList } from "../../../../../../services/Store/Actions/UserManagementAction";
import SearchBar from "../../../../../subComponents/searchbar";
import { getDriverList2 } from "../../../../../../services/Store/Actions/driverslistActions";
import {
  cleanOmcLoaderAction,
  omcChangeNumAction,
  omcRegisterDriverAction,
} from "../../../../../../services/Store/Actions/omcActions";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function OMCChangeOTPNum(props) {
  const { open, toggleModal, handleClose } = props;
  const [status, setStatus] = useState(0);
  const [offset, setOffset] = useState(0);
  const [offset1, setOffset1] = useState(0);
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");

  const dispatch = useDispatch();

  const { loader } = useSelector((state) => state?.omc);
  //console.log("button status", loader);

  const validationSchema = Yup.object().shape({
    // nickName: Yup.number()
    //   .typeError("Only Numbers are allowed")
    //   .min(1, "Amount should be greater than 1")
    //   .required("Amount is required"),
    otpNumber: Yup.string().required("Please select one number"),
    //   .test(
    //     "noDefaultValue",
    //     "Please select expense type",
    //     (val) => val != "default"
    //   ),
    // dob: Yup.string().required("Expense Date is required"),
    // remarks: Yup.string().required("Remark is required"),
  });
  const newDriverList = useSelector((state) => state);
  const driverCount = newDriverList?.driverlist?.newDriverList?.divers?.count;
  const driverListing = newDriverList?.driverlist?.newDriverList?.divers?.rows;

  useEffect(() => {
    setStatus(0);
    setOffset(0);
    setOffset1(0);
  }, [open]);

  useEffect(() => {
    dispatch(
      getManagerList({
        offset: 0,
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
    dispatch(
      getDriverList2({
        offset: 0,
        search: "",
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, []);

  useEffect(() => {
    setOffset(0);
    dispatch(
      getManagerList({
        offset: 0,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, [search]);

  useEffect(() => {
    setOffset1(0);
    dispatch(
      getDriverList2({
        offset: 0,
        search: search1,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  }, [search1]);

  const findSearch = () => {
    setOffset(0);
    dispatch(
      getManagerList({
        offset: 0,
        search: search,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };

  const findSearch1 = () => {
    setOffset1(0);
    dispatch(
      getDriverList2({
        offset: 0,
        search: search1,
        minKyc: "",
        sortAs: "",
        sortBy: "",
      })
    );
  };
  const managerList = useSelector(
    (state) => state?.userManagement?.managerList
  );
  const managerCount = managerList?.result?.count;
  console.log("managerList driver", managerList?.result?.rows);

  const handlePrevButton = () => {
    if (offset > 0) {
      setOffset(offset - 10);
      dispatch(
        getManagerList({
          offset: offset - 10,
          search: search,
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    }
  };

  const handlePrevButton1 = () => {
    if (offset1 > 0) {
      setOffset1(offset1 - 10);
      dispatch(
        getDriverList2({
          offset: offset1 - 10,
          search: search1,
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    }
  };

  const handleNextButton = () => {
    if (managerCount - offset > 10) {
      setOffset(offset + 10);
      dispatch(
        getManagerList({
          offset: offset + 10,
          search: search,
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    }
  };

  const handleNextButton1 = () => {
    if (driverCount - offset1 > 10) {
      setOffset1(offset1 + 10);
      dispatch(
        getDriverList2({
          offset: offset1 + 10,
          search: search,
          minKyc: "",
          sortAs: "",
          sortBy: "",
        })
      );
    }
  };

  const userr = sessionStorage.getItem("_user");
  const obj = JSON.parse(userr);
  const userIdSession = obj?.user?.phone;
  console.log("driver lists", driverCount);

  //toast for disabling redeem cashback button
  const customId = "custom-id-error page";
  const notify = (msg) =>
    toast.error(msg, {
      toastId: customId,
    });

  const { id } = useParams();
  return (
    <div>
      <Modal
        isOpen={open}
        onHide={handleClose}
        className="positons settingModalMargin"
        toggle={toggleModal}
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-content"
          style={{ width: "530px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-head-part">
            <p className="Add-balance-title">Choose Number To Get OTP</p>
            <Button
              type="button"
              className="close-btn"
              onClick={toggleModal}
              aria-label="Close"
            >
              <SvgImage name="CloseButtonLogo" />
            </Button>
          </div>
          <ModalBody style={{ position: "relative" }}>
            <Formik
              enableReinitialize
              initialValues={{
                otpNumber: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                console.log("result000", values);
                // dispatch(omcRegisterDriverAction(values.otpNumber,(res)=>{
                //   console.log("res reg", res);
                //   if(res?.OMCDriverId){
                //     dispatch(omcChangeNumAction({
                //       "userId":id,
                //       "OMCDriverId": res?.OMCDriverId
                //     }),(res)=>{
                //       console.log("res reg final", res);
                //       if(res?.status==200){
                //         toggleModal();
                //       }
                //     })
                //   }
                // else {
                //   console.log("res reg", res?.response?.data?.message);
                //   if(res?.response?.data?.message){
                //     notify(res?.response?.data?.message);
                //     dispatch(cleanOmcLoaderAction());
                //   }else if(res?.response?.status == 400){
                //     notify("Error in sending otp to this mobile number.");
                //     dispatch(cleanOmcLoaderAction());
                //   }else {
                //     console.log("res reg wim", res);
                //   }
                // }
                // }))
                dispatch(
                  omcChangeNumAction(
                    {
                      fleetId: id,
                      phone: values.otpNumber,
                    },
                    (res) => {
                      console.log("res reg final", res);
                      if (res?.status == 200) {
                        toggleModal();
                      }
                    }
                  )
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  {status == 0 ? (
                    <>
                      <FormGroup>
                        <div className="padding16 padding32R">
                          <div className="row margin0 padding16 greyBox">
                            <div className="col-10 flexStyling">
                              <div className="greenBackBox">
                                <SvgImage name="drOptIcon" />
                              </div>
                              <div className="padding16L centralItems Bold heading2 flexStyling">
                                Driver
                              </div>
                            </div>
                            {/* <div className="col-2 padding8L flexStyling">
                              <input
                                type="radio"
                                id="driver"
                                name="otpNumber"
                                value="Driver"
                              /> 
                              </div> */}
                            <div
                              className="col-2 padding8L centralItems flexStyling"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setFieldValue("otpNumber", "", true);
                                setStatus(2);
                              }}
                            >
                              <SvgImage name="nextArrow" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="padding16 padding32R"
                          onChange={(event) => {
                            console.log("valllll123", event.target.value);
                            setFieldValue(
                              "otpNumber",
                              event.target.value,
                              true
                            );
                          }}
                        >
                          <div className="row margin0 padding16 greyBox">
                            <div className="col-10 flexStyling">
                              <div className="greenBackBox">
                                <SvgImage name="ownerOptIcon" />
                              </div>
                              <div className="padding16L centralItems Bold heading2 flexStyling">
                                Owner
                              </div>
                            </div>
                            <div className="col-2 padding8L flexStyling">
                              <input
                                type="radio"
                                id="Owner"
                                name="otpNumber"
                                value={userIdSession}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="padding16 padding32R">
                          <div className="row margin0 padding16 greyBox">
                            <div className="col-10 flexStyling">
                              <div className="greenBackBox">
                                <SvgImage
                                  name="managerOptIcon"
                                  width="36"
                                  height="36"
                                />
                              </div>
                              <div className="centralItems padding16L Bold heading2 flexStyling">
                                Manager
                              </div>
                            </div>
                            <div
                              className="col-2 padding8L centralItems flexStyling"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setFieldValue("otpNumber", "", true);
                                setStatus(1);
                              }}
                            >
                              <SvgImage name="nextArrow" />
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </>
                  ) : status == 1 ? (
                    <FormGroup>
                      <div className="padding16">
                        <SearchBar
                          placeholder="Search for managers"
                          height="46"
                          value={search}
                          onChange={(event) => {
                            setSearch(event.target.value);
                          }}
                          findSearch={findSearch}
                        />
                      </div>
                      <div className="row margin0 lowerBorderLine">
                        <div className="Bold heading3  padding8T padding16B col-7">
                          All Managers
                        </div>
                        <div
                          className="greenFont semiBold heading3 col-5 padding8T padding8B"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setStatus(0);
                            setFieldValue("otpNumber", "", true);
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <>
                        {managerList?.result?.rows?.map((item, i) => {
                          return (
                            <div
                              className="row margin0 padding16 lowerBorderLine"
                              onChange={(event) => {
                                console.log("valllll123", event.target.value);
                                setFieldValue(
                                  "otpNumber",
                                  event.target.value,
                                  true
                                );
                              }}
                            >
                              <div className="col-2 semiBold heading2">
                                <div className="greenCirleInitial">
                                  {item?.User?.fullName?.[0]}
                                </div>
                              </div>
                              <div className="col-8 padding8L">
                                <div className="semiBold heading3">
                                  {item?.User?.fullName}
                                </div>
                                <div className="Medium heading4 greyFont">
                                  {item?.User?.phone}
                                </div>
                              </div>
                              <div className="col-2 centralItems flexStyling">
                                <input
                                  type="radio"
                                  name="otpNumber"
                                  value={item?.User?.phone}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                      <div className="nextRow ">
                        <div className="padding16T padding8B ">
                          <button
                            className="PrevButton"
                            onClick={handlePrevButton}
                            style={{ opacity: offset > 0 ? "1" : "0.5" }}
                          >
                            <SvgImage
                              name="PrevArrow"
                              heigth="12px"
                              width="12px"
                            />
                          </button>
                          <button
                            className="nextButton"
                            onClick={handleNextButton}
                            style={{
                              opacity: managerCount - offset > 10 ? "1" : "0.5",
                            }}
                          >
                            <SvgImage
                              name="NextArrow"
                              heigth="12px"
                              width="12px"
                            />
                          </button>
                        </div>
                      </div>
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <div className="padding16">
                        <SearchBar
                          placeholder="Search for drivers"
                          height="46"
                          value={search1}
                          onChange={(event) => {
                            setSearch1(event.target.value);
                          }}
                          findSearch={findSearch1}
                        />
                      </div>
                      <div className="row margin0 lowerBorderLine">
                        <div className="Bold heading3  padding8T padding16B col-7">
                          All Drivers
                        </div>
                        <div
                          className="greenFont semiBold heading3 col-5 padding8T padding8B"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setStatus(0);
                            setFieldValue("otpNumber", "", true);
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <>
                        {driverListing?.map((item, i) => {
                          return (
                            <div
                              className="row margin0 padding16 lowerBorderLine"
                              onChange={(event) => {
                                console.log("valllll123", event.target.value);
                                setFieldValue(
                                  "otpNumber",
                                  event.target.value,
                                  true
                                );
                              }}
                            >
                              <div className="col-2 semiBold heading2">
                                <div className="greenCirleInitial">
                                  {item?.User?.fullName?.[0]}
                                </div>
                              </div>
                              <div className="col-8 padding8L">
                                <div className="semiBold heading3">
                                  {item?.User?.fullName}
                                </div>
                                <div className="Medium heading4 greyFont">
                                  {item?.User?.phone}
                                </div>
                              </div>
                              <div className="col-2 centralItems flexStyling">
                                <input
                                  type="radio"
                                  name="otpNumber"
                                  value={item?.User?.phone}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                      <div className="nextRow ">
                        <div className="padding16T padding8B ">
                          <button
                            className="PrevButton"
                            onClick={handlePrevButton1}
                            style={{ opacity: offset1 > 0 ? "1" : "0.5" }}
                          >
                            <SvgImage
                              name="PrevArrow"
                              heigth="12px"
                              width="12px"
                            />
                          </button>
                          <button
                            className="nextButton"
                            onClick={handleNextButton1}
                            style={{
                              opacity: driverCount - offset1 > 10 ? "1" : "0.5",
                            }}
                          >
                            <SvgImage
                              name="NextArrow"
                              heigth="12px"
                              width="12px"
                            />
                          </button>
                        </div>
                      </div>
                    </FormGroup>
                  )}
                  <FormGroup
                    style={{
                      position: "relative",
                      bottom: "1rem",
                      paddingTop: status == 0 ? "10rem" : "0",
                    }}
                  >
                    <button
                      type="submit"
                      className="butt-4"
                      style={{
                        margin: "15% 0 0 0",
                        background: loader
                          ? "grey"
                          : values.otpNumber == ""
                          ? "grey"
                          : "#2e7c7b",
                        left: "30px",
                      }}
                      disabled={loader ? true : false}
                    >
                      {loader ? "Please Wait" : "Continue"}
                    </button>
                  </FormGroup>
                  <div></div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default OMCChangeOTPNum;
