import React, { useState } from "react";
import SvgImage from "../../../Icons/Icons";
import "./index.css";
import { FcManager } from "react-icons/fc";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import PinInput from "react-pin-input";
const ManageVehicleNumbers = (props) => {
  const number = props.number == null ? "0" : props.number;
  const text = props.text == null ? "Total Drivers Onboarded" : props.text;
  const type = props.type == null ? null : props.type;
  let element = null;
  if (type) {
    element = <div className="ManageDriverCardType1Extra">out of 34</div>;
  } 

  const totalFleet = props.total === '' || props.total === null ? 0 : props.total;

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [btn, setBtn] = useState(false);
  const [active, setActive] = useState(0);
  const [comp, setComp] = useState("Logipe Account");
  const [proceed, setProceed] = useState(0);
  function toggleModal() {
    setOpen(!open);
    setProceed(0);
  }

  function handleClick() {
    setBtn(!btn);
  }

  const handleClose = () => {
    setOpen(false);
    setProceed(0);
  };

  const handleShow = () => {
    setOpen(true);
  };

  return (
    <div className="ManageDriverCardType1Box">
      <div className="padding8" style={{ display: "flex", flexDirection: "row" }}>
        <div className="ManageDriverTyp1IconBox">
          <div className="ManageDriverType1Icon">
            {props.text === "Vehicles Registered" ? (
              <div className="outside-Truck-logo-for-total-vehicle">
                <SvgImage
                  name="TruckLogo"
                  classname="Truck-logo-for-total-vehicle"
                />
              </div>
            ) : props.text === "FASTag Not Linked" ? (
              <div className="outside-toll-logo-for-not-linked">
                <SvgImage
                  name="noFastagLogo"
                  classname="toll-logo-for-not-linked"
                />
              </div>
            ) : (
              <div className="outside-vehicle-logo-for-unassigned">
                <SvgImage
                  classname="vehicle-logo-for-unassigned"
                  name="noDriverLogo"
                  color="#936AE0"
                />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="Manage-numbers-for-vehicle col-12 row">
            <p className="Manage-numbers-for-vehicle-no-text col-3">{number} 
              <span className="FontSmall padding8L">{type ? (number !="0"?`out of ${totalFleet}`:"") : ""}</span> </p>
          
          </div>
          <div className="Manage-numbers-for-vehicle-text col-12">{text}</div>
        </div>
      </div>
    </div>
  );
};
export default ManageVehicleNumbers;
